          
<template>
  <div class="vehicleswiki" :style="FilterProp ?'margin-top:126px;padding-bottom:50px;height: 100%;':'height: 100%;'">
    <!-- <v-navigation-drawer app v-if="ActiveSitelisting"  v-model="ActiveSiteListingDialog">
      <v-card v-if="ActiveSitelisting">
        
      </v-card>
    </v-navigation-drawer> -->
    <v-dialog v-model="MessageGroupDialog" width="400" persistent>
        
                       
                      <v-card width="400">
                        <v-card-title class="mediumoverline">
                          <v-avatar
                            class="Grouptitle Avatar mx-4"
                            :size="50"
                            color="white"
                            tile
                          >
                            <v-img contain :src="logo"></v-img
                          ></v-avatar>
                          {{ name }} <v-spacer></v-spacer>                          
                           <v-icon  x-large @click="DeactivateMessageGroupDialog()" color="green" class="soloactionicon my-1">mdi-close</v-icon>
                        </v-card-title>
                         <v-row class="justify-center" style="padding:10px;">
                          <v-rating v-if="SiteRatingActive"
                            v-model="SiteRating" class="justify-center"
                            background-color="amber lighten-1"
                            color="amber lighten-1"
                            
                          ></v-rating>
                        </v-row>
                        <v-card-text>
                          
                          <ContentEditableField
                            :FieldObject="NewMessage" :FieldName="'message'"
                            :FieldValue="NewMessage.message" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                        </v-card-text>
                       
                        <v-card-actions>
                          <v-spacer>
                          </v-spacer>
                          <v-btn @click="SendMessagetoSiteOwner()">Send Message</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
    <v-card  max-width="400" min-width="300" style="position: fixed;z-index:10;top: 30px;left: 10px;" v-if="ActiveSitelisting && ActiveSiteListingDialog">
      <v-img class="background" 
      height="150"  :src="CurrentEntity.id  === 'Templates' ? 
      ActiveSitelisting.Picture : ActiveSitelisting.Logo && ActiveSitelisting.Logo.fileurl ? ActiveSitelisting.Logo.fileurl : ActiveSitelisting.Logo"/>
      <v-btn dark @click="ActivateSiteListing(ActiveSitelisting)" fab icon absolute right top style="margin-top:25px;">
        <v-icon color="red">mdi-close</v-icon>
      </v-btn>
        <v-tabs centered>
          <v-tab>Overview
          </v-tab>
          <v-tab-item>
          <!-- <v-card-text> -->
       
            
          <v-card-title :style="CurrentEntity.id !== 'Templates' ? 'padding-bottom:10px;' : 
          'padding-bottom:30px;'" class="mediumoverline">
              {{CurrentEntity.id !== 'Templates' ? ActiveSitelisting.Company_Name : 
              ActiveSitelisting.Name}}
          </v-card-title>
          <v-card-title v-if="CurrentEntity.id !== 'Templates'" class="subtleoverline">
              {{AvgRatings}}
              <v-rating small dense 
                  :value="AvgRatings" class="justify-center"
                  background-color="amber lighten-1"
                  color="amber lighten-1"
                  readonly half-increments
                  ></v-rating>
            
          </v-card-title>
          <v-card-subtitle >
              {{ActiveSitelisting.Description}}
          </v-card-subtitle>
      <!-- </v-card-text> -->
      <v-card-text  v-if="CurrentEntity.id !== 'Templates'" >
        <v-list-item @click="item.Method ? CurrentEntity.id === 'Templates' ? ActivateTemplateSite(ActiveSitelisting) : 
        !ActiveSitelisting.ClientAppURL ? ConfirmActivateSite(ActiveSitelisting) : '' : ''" v-for="item in ActiveSiteLisingItems" :key="item.itemObjKey"
        :href="ActiveSitelisting.ClientAppURL && item.Prop === 'Site' ? ActiveSitelisting.ClientAppURL : ''" target="new"
        >
          <v-list-item-avatar :color="item.Color ? item.Color : 'purple'">
            <v-icon dark>{{item.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-html="item.Value">
          </v-list-item-content>
                 
        </v-list-item>
      </v-card-text>
      <v-card-actions  v-if="CurrentEntity.id === 'Templates'">
        <v-spacer>
        </v-spacer>
        <v-btn @click="ActivateTemplateSite(ActiveSitelisting)" outlined>View
        </v-btn>
      </v-card-actions> 
          </v-tab-item>
          <v-tab v-if="CurrentEntity.id !== 'Templates'">Ratings
          </v-tab>
          <v-tab-item v-if="CurrentEntity.id !== 'Templates'">
            <RatingComponent  :CanViewReplies="CanViewReplies"
            :Ratings="SiteRatings" :ItemName="ActiveSitelisting.Company_Name" :CanWriteReview="userLoggedIn" @EmitReviewMethod="EmitReviewMethod" :CanSendMessage="userLoggedIn"
            @EmitSendMessageMethod="EmitSendMessageMethod"/>
          </v-tab-item>
         </v-tabs> 
      </v-card>
      <!-- <v-layout row class="fill-height"> -->
        
              <v-col :style="'padding: 0px;height: 100%;'" :cols="12">
               
              <v-card tile :class="CurrentEntityBoxTransparency" :elevation="CurrentEntityElevation"  height="100%">
               <v-pagination
                :total-visible="9"
                    v-model="DataTableListingspage"
                    circle class="recordtoolbar" color="background links--text"
                    :length="Math.ceil(FilteredComputedDataTableListings.length/9)"
                ></v-pagination>
             
                <ParallaxTab v-if="ProductBrandParallax" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"
                    :tab="ProductBrandParallax" @OpenDialog="OpenDialog" :i="i"
                    />
                    <!-- ActiveFilters {{ActiveFilters}} -->
                    <!-- AdditionalFilters {{AdditionalFilters}} -->
                  <v-row style="padding:20px;">
                  <div :class="FlexClasses" v-for="item in ComputedDataTableListingsPaginated.slice(0, 9)"
                    :key="item.itemObjKey" :style="MiniView && CurrentEntity.CategoricalListType !== 'Web Apps' ? '' : 'padding:10px;'">
                        <VacancyListingItem v-if="CurrentEntity.CategoricalListType === 'Vacancies'"
                        :vac="item" :System="System" :SystemEntities="SystemEntities" :DirView="true"
                        />
                       <ClassifiedListingListItem v-if="CurrentEntity.CategoricalListType !== 'Wiki Listing' && 
                       CurrentEntity.CategoricalListType !== 'Web Apps' && CurrentEntity.CategoricalListType !== 'Vacancies'"
                       :TaggedFields="CurrentEntity.TaggedFields" :TagField="CurrentEntity.TagField" :CurrentEntity="CurrentEntity"
                       :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription" @PushActiveProduct="PushActiveProduct"
                       :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                       :FeatureList="FeatureList" :CompareItems="CompareItems" :IMGIsCarousel="CurrentEntity.IMGIsCarousel" :CartItems="CartItems"
                       :FlexXLRG="FlexXLRG" :FlexLarge="FlexLarge" :FlexMedium="FlexMedium" :FlexSmall="FlexSmall" :FlexXSmall="FlexXSmall"	
                       />
                       <WikiListingItem v-if="CurrentEntity.CategoricalListType === 'Wiki Listing'" :TaggedFields="CurrentEntity.TaggedFields" :TagField="CurrentEntity.TagField" 
                       :CurrentEntity="CurrentEntity"
                       :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription"
                       :HideIMG="CurrentEntity.HideIMG"
                       :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                       :FeatureList="FeatureList" :CompareItems="CompareItems"
                       :IMGField="CurrentEntity.IMGField" :DescriptionField="CurrentEntity.DescriptionField" :TitleField="CurrentEntity.TitleField"
                       />
                       <SiteListingItem v-if="CurrentEntity.CategoricalListType === 'Web Apps' && CurrentEntity.id !== 'Templates'"
                      :item="item" @ActivateSiteListing="ActivateSiteListing" @ActivateViewportBuilder="ActivateViewportBuilder"
                       :FlexXLRG="FlexXLRG" :FlexLarge="FlexLarge" :FlexMedium="FlexMedium" :FlexSmall="FlexSmall" :FlexXSmall="FlexXSmall"	
                       />
                        <TemplateListingItem v-if="CurrentEntity.CategoricalListType === 'Web Apps' && CurrentEntity.id === 'Templates'"
                      :item="item" @ActivateSiteListing="ActivateSiteListing"
                       :FlexXLRG="FlexXLRG" :FlexLarge="FlexLarge" :FlexMedium="FlexMedium" :FlexSmall="FlexSmall" :FlexXSmall="FlexXSmall"	
                       />
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <!-- <div style="background-color: white;padding: 50px;">
              <v-icon size="300" v-for="icon in Icons" :key="icon.itemObjKey">
               {{icon}} </v-icon>
              </div> -->
      <!-- </v-layout> -->

  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ClassifiedListingListItem from '@/components/Directories/ClassifiedListingListItem';
import SiteListingItem from '@/components/Directories/SiteListingItem';
import TemplateListingItem from '@/components/Directories/TemplateListingItem';

import RatingComponent from "@/components/WebPages/RenderComponents/RatingComponent";
import WikiListingItem from '@/components/Directories/WikiListingItem';
import VacancyListingItem from '@/components/Directories/VacancyListingItem';
import ParallaxTab from '@/components/WebPages/RenderComponents/ParallaxTabReadOnly';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
  props: ['LinksGroupsearch','ViewType','DefaultView','ShowPagination','AppisDarkMode','FilterProp','FilterField','FilterRecord','Record','KeyWord','KeyWords','KeyWordObj','WebDevelopers',
  ,'ClassifiedsClasses','mainclass','subclass','ActiveFilters','AdditionalFilters','NavList','CurrentEntity','thirdclass','FeatureList','CompareItems','CartItems'],
  components: {
      ClassifiedListingListItem,
      SiteListingItem,
      TemplateListingItem,
      WikiListingItem,
      VacancyListingItem,
      ParallaxTab,
      RatingComponent,
      ContentEditableField
  },
  data() {
    return {
      
            CalledRatings: false,
            ItemRatings: [],
            MessagefromUser: '',
      MessageGroupDialog: false,
      SiteRatingActive: false,
      SiteRating: 3,
      NewMessage: {
        recipientid: '',
        senderid: '',
        message: '',
        Created_On: '',
        Read: false
      },
      DefaultNewMessage: {
        recipientid: '',
        senderid: '',
        message: '',
        Created_On: '',
        Read: false
      },
      SiteRatings: [],
      ActiveSitelisting: '',
      ActiveSiteListingDialog: false,
      Icons: ['mdi-account-search','mdi-briefcase-search','mdi-shield-search','mdi-cloud-search','mdi-office-building','mdi-account'],
      PageContentFont: 'Montserrat',
        PageHeadersFontSize: 42,
        PageHeadersFont: 'Raleway',
        HTMLBox: {
                BoxTransparency: 'transparent',
                RowColor: '#00ffff',									
                Name: 'HTML Box',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: ``,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#ffffff'									
                },									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Elevation: 0,
                HeaderFontSize: 50,		
                //DisplayasChip: true						
            },
        CompareItems: [],
        NEWLinksGroupsearch: '',
        DataTableListings: [],
        DataTableListingspage: 1,
        FeatureListisLookup: false,
        FeatureLookupProp: '',
        FlexXLRG:3,									
        FlexLarge: 4,									
        FlexMedium: 4,									
        FlexSmall: 6,			
    }
  },

  
  
  created(){ 
    //console.log(this.$route.query)
    this.$emit('UpdateComputedKeyWords',this.ComputedKeyWords)
    let newvalue = this.System
        //console.log(this.System)
        if(newvalue.AppDefaultHeader){
            this.PageHeadersFont = newvalue.AppDefaultHeader.Name
            this.PageHeadersFontSize = newvalue.AppDefaultHeader.SizeMultiplier
            }
            if(newvalue.AppDefaultBody){
            this.PageContentFont = newvalue.AppDefaultBody.Name
            }
      this.GetClassifiedListings()
      this.FeatureList  = this.CurrentEntity.FeatureList
      
      //console.log(this.CurrentEntity)
      if(this.CurrentEntity.FeatureListType === 'Lookup'){
        this.FeatureListisLookup = true
        this.FeatureLookupProp = this.CurrentEntity.FeatureListLookupField.Name
        this.GetFeatureListEntries(db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('Directories').doc(this.CurrentEntity.id).collection(this.CurrentEntity.FeatureListLookupField.RelatedBuildID))
      }
  },
  computed: {
    FlexXSmall(){
      return this.CurrentEntity && this.CurrentEntity.CategoricalListType === 'Web Apps' ? 12 : 6
    },
    AvgRatings(){
        let length = this.SiteRatings.length
        if(length > 0){
            let total = this.SiteRatings.map(rating => {
                return rating.Rating
            }).reduce((a, b) => a + b, 0)
            return total/length
        }
        else{
            return 0
        }
    },
    ActiveSiteLisingItems(){
      let items = [
        {title: 'Address',Prop: 'Company_Address',Value: '',icon: 'mdi-map-marker'},
        {title: 'Telephone',Prop: 'Telephone_Number',Value: '',icon: 'mdi-phone'},
        {title: 'Site',Prop: 'Site',Value: '',icon: 'mdi-search-web',Method: 'ConfirmActivateSite'},
        // {title: 'Website',Prop: 'Company_Address',Value: '',icon: 'mdi-search-web'},
        // {title: 'Website',Prop: 'Company_Address',Value: '',icon: 'mdi-search-web'},
        // {title: 'Website',Prop: 'Company_Address',Value: '',icon: 'mdi-search-web'},
        // {title: 'Website',Prop: 'Company_Address',Value: '',icon: 'mdi-search-web'}
      ]
      return this.ActiveSitelisting ? 
      items.map(arritem => {
        let val = this.ActiveSitelisting[arritem.Prop]
        if(typeof val !== 'undefined'){
          arritem.Value = val
        }
        if(arritem.Prop === 'Site'){
          arritem.Value = this.ActiveSitelisting.SiteType ? this.ActiveSitelisting.SiteType : 'Site'
          if(this.ActiveSitelisting.SiteType){
            let cat = this.$store.state.WebAppTypes.find(obj => obj.Type === this.ActiveSitelisting.SiteType)
            arritem.Color = cat.Color
            arritem.icon = cat.Icon
          }
        }
        return arritem
      }).filter(arritem => {
        return arritem.Value
      }) : 
      []
    },
    ActiveSuiteid(){
          return this.$store.state.ActiveSuiteid
        },
    FlexClasses(){
      return this.CurrentEntity && this.CurrentEntity.CategoricalListType === 'Vacancies' ? 
      'flex xl'+6+' lg'+6+' md'+6+' sm'+12+' xs'+12+'' :
      'flex xl'+this.FlexXLRG+' lg'+this.FlexLarge+' md'+this.FlexMedium+' sm'+this.FlexSmall+' xs'+this.FlexXSmall+''
    },
    UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
    RouteQuery(){
      return this.$route.query
    },
    RouteQueries(){
        let arr = []
        if(this.RouteQuery){
          for(var prop in this.RouteQuery) {
            arr.push({Prop: prop,Value: this.RouteQuery[prop]})
          }
        }
        return arr
      },
    MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    VuexStoreCatNavlist(){
      return this.CurrentEntity && this.CurrentEntity.CategoricalListType === 'Social Groups' && this.$route.name !== 'DirectoryBuilder' ? this.$store.state.CatNavlists.find(obj => obj.EntityID === this.CurrentEntity.id+'_'+this.$route.meta.PageID) :
       this.$store.state.CatNavlists.find(obj => obj.EntityID === this.CurrentEntity.id)    
    },
    FeaturedMembers(){
      return this.$store.state.FeaturedMembersArray
    },
    VuexStoreStateData(){
        //return this.CurrentEntity && this.CurrentEntity.id === 'Featured_Members' ? this.FeaturedMembers : this.VuexStoreCatNavlist ? this.VuexStoreCatNavlist.Data : []
        return this.VuexStoreCatNavlist ? this.VuexStoreCatNavlist.Data : []
    },
    System(){
      return this.$store.state.SystemObj
    },
    ProductBrandParallax(){
            if(this.BrandFilter && this.BrandFilterLogo){
                let brand = this.BrandFilter
               let defaulttab = {									
                RowColor: '#ffffff',									
                Name: 'Parallax',									
                SectionType: true,									
                Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-image-area',									
                Height: 200,									
                Elements: [],									
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            }
            defaulttab.IMG = brand.Logo
            let titletab = Object.assign({},this.HTMLBox)
            titletab.Title = brand.Name
            if(brand.Store_Read_MoreLink){
                //titletab.ChipRoute = brand.WikiPath
                titletab.HasActionButton = true
                titletab.ButtonAlignment = 'justify-end'
                titletab.ButonColor = {
                    hex: '#ffffff',
                    hexa: '#ffffff'
                }
                titletab.Outlined = true
                titletab.Fancy = true
                if(!brand.Store_Writeup){
                    titletab.ActionButtonName = 'View'
                    titletab.ActionButtonType = 'URL'
                    titletab.ActionButtonRoute = brand.Store_Read_MoreLink   
                }
                else{
                    let actioncontenttab = Object.assign({},this.HTMLBox)
                    actioncontenttab.HasActionButton = true
                    actioncontenttab.ButtonAlignment = 'justify-end'
                    actioncontenttab.ButonColor = {
                        hex: '',
                        hexa: ''
                    }
                    actioncontenttab.ActionButtonName = 'View'
                    actioncontenttab.ActionButtonType = 'URL'
                    actioncontenttab.ActionButtonRoute = brand.Store_Read_MoreLink
                    actioncontenttab.Description = brand.Store_Writeup
                    actioncontenttab.Title = brand.Name
                    if(brand.Logo){
                        actioncontenttab.HasHeaderImage = true
                        actioncontenttab.Justify = 'justify-center'
                        actioncontenttab.HeaderImage = brand.Logo
                    }
                    titletab.ContentDialogType = {
                        ComponentName: 'HTML Box'
                    }
                    //console.log(actioncontenttab)
                    titletab.ActionButtonName = 'Read More'
                    titletab.ActionButtonType = 'Content Dialog'                    
                    titletab.ContentDialogBox = actioncontenttab
                }
                
            }
            //console.log(titletab)
            defaulttab.Elements.push(titletab)
            return defaulttab 
            }
    },
    BrandFilter(){
      return this.ActiveFilters.find(obj => obj.BrandFilter)
    },
    BrandFilterLogo(){
      return this.BrandFilter && this.BrandFilter.Logo ? this.BrandFilter.Logo : ''
    },
    CurrentEntityElevation(){
      return this.CurrentEntity.CategoricalListType ==='Store Products' ? 0 : ''
    },
    CurrentEntityBoxTransparency(){
      return this.CurrentEntity.CategoricalListType ==='Store Products' ? '' : ''
    },
    ComputedKeyWords(){
      return this.FilteredComputedDataTableListings.map(item => {
        let keywords = []
        if(item.KeyWords){
          keywords = item.KeyWords
        }
        return keywords
      }).flat()
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    StoreSearch(){
      if(this.CurrentEntity.CategoricalListType === 'Store Products'){
        return this.$store.state.StoreSearch
      }
    },
    
      ComputedDataTableListingsPaginated(){
        let displayrange = 9*this.DataTableListingspage-9
        return this.FilteredComputedDataTableListings.slice(displayrange, displayrange+9)
      },
      FilteredComputedDataTableListings(){
        return this.ComputedDataTableListings
        .filter(item => {
          if(this.CurrentEntity.QualifiedListings && this.CurrentEntity.QualifiedListings.length > 0){
            return this.CurrentEntity.QualifiedListings.includes(item.Status_Reason.ID)
          }
          else{
            return item
          }
        }).filter(item => {
          if(this.KeyWord){
            return item.KeyWords && item.KeyWords.includes(this.KeyWord)
          }
          else if(this.KeyWordObj){
            let itemwords = []
            //console.log(itemwords,this.KeyWordObj)
            if(item.KeyWords){
              itemwords = item.KeyWords.map(kw => {
              return kw.id
            })
            }
            return itemwords.includes(this.KeyWordObj.id)
          }
          else{
            return item
          }
        })
        .filter(item => {
          //console.log(this.StoreSearch)
          if(this.StoreSearch){
            return item.Title.toLowerCase().includes(this.StoreSearch.toLowerCase())
          }
          else{
            return item
          }
        }).filter(item => {
          if(this.WebDevelopers){
            return item.IsWebDeveloper || item.IsSiteCreator
          }
          else{
            return item
          }
        })     
      },
      ComputedDataTableListings(){
        if(this.$route.name === 'WikiCollectionBuilder'){
          return this.VuexStoreStateData.map(obj => {
            let newobj = Object.assign({},obj)
            return newobj
          })
        }
        else{
          //console.log('this.VuexStoreStateData',this.VuexStoreStateData)
          return this.VuexStoreStateData.map(obj => {
            let newobj = Object.assign({},obj)
            return newobj
          }).map(obj => {
          if(this.CurrentEntity.id === 'Group_Categories'){
                    if(obj.logo){
                      obj.Photo = {
                        ThumbURL: obj.logo
                      }
                    }
                    if(obj.DefaultPage && obj.DefaultPage.Type === 'Group Page'){
                      obj.Path = '/Group-Page/'+obj.id+'/'+obj.DefaultPage.Name
                    }
                    else{
                      obj.Path = '/Group/'+obj.id
                    }
                    obj.Title = obj.name
                  }
                  else if(this.CurrentEntity.id === 'Systems'){
                    obj.Photo = {
                        ThumbURL: obj.Logo
                      }
                    obj.Path = '/Your-Domain/'+obj.id
                  }
                  else if(this.CurrentEntity.id === 'Templates' && obj.Picture){
                    obj.Photo = {
                        ThumbURL: obj.Picture
                      }
                    obj.Path = '/MarketplaceTemplate/'+obj.id
                    //console.log('obj',obj)
                  }
                  else if(this.CurrentEntity.id === 'Business_Members'){
                    obj.Photo = {
                        ThumbURL: obj.Profile_Photo
                      }
                    obj.Path = '/BusinessMember/'+obj.id
                  }
                  else if(this.CurrentEntity.id === 'Featured_Members'){
                    obj.Photo = {
                        ThumbURL: ''
                      }
                      if(obj.Profile_Photo){
                        obj.Photo = obj.Profile_Photo
                      }
                    obj.Path = '/FeaturedMember/'+obj.id+'/'+obj.DefaultPage
                    obj.Title = obj.Full_Name
                    obj.Description = obj.Overview
                  }
                  else if(this.CurrentEntity.id === 'Vacancies'){
                    obj.Photo = {
                        ThumbURL: ''
                      }
                      if(obj.Company && obj.Company.Logo){
                        obj.Photo.ThumbURL = obj.Company.Logo
                      }
                    obj.Path = '/Vacancy/'+obj.id
                    obj.Title = obj.Title
                    obj.Description = obj.Introduction
                  }
                  else if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                      obj.Description = obj.DescriptiveText
                    obj.Path = '/StoreItem/'+this.CurrentEntity.id+'/'+obj.id
                  }
                  return obj
        })
          .map(clsfd => {
           //console.log(this.ActiveFilters)
            clsfd.Render = true
            this.ActiveFilters.map(filter => {
              //console.log(filter)
              if(typeof clsfd[filter.PropName] === 'undefined' && !filter.IsOptionList){
                clsfd.Render = false
              }
              else if(!filter.IsOptionList){
                if(filter.Type === 'Option Set'){
                    //console.log(clsfd[filter.PropName].ID,filter.ID)
                  if(clsfd[filter.PropName].ID !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Lookup'){
                    //console.log(clsfd[filter.PropName].ID,filter.ID)
                  if(clsfd[filter.PropName].id !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Single Option Filter'){
                  if(filter.Field.Type === 'Keyword'){
                    let clsfdwords = clsfd[filter.PropName].map(kw => {
                      return kw.id
                    })
                    if(!clsfdwords.includes(filter.ID)){
                      clsfd.Render = false
                    }
                  }
                  else if(filter.Field.Type === 'Lookup'){
                    if(clsfd[filter.PropName].id !== filter.ID){
                      clsfd.Render = false
                    }
                  }
                  else if(filter.Field.Type === 'Radio Group'){
                    let matches = clsfd[filter.PropName].map(opt => {
                      return opt.ID
                    })
                    if(!matches.includes(filter.ID)){
                      clsfd.Render = false
                    }
                  }
                 else{
                    if(clsfd[filter.PropName].ID !== filter.ID){
                      clsfd.Render = false
                    }
                  }
                }
                
                else if(filter.Type === 'Text Search' && filter.ActiveFilter){
                  if(!clsfd[filter.PropName] || clsfd[filter.PropName] && !clsfd[filter.PropName].toLowerCase().includes(filter.ActiveFilter.toLowerCase())){
                    clsfd.Render = false
                  }
                }
              }
              else if(filter.Type === 'Number Field' && filter.IsOptionList){
                  if(clsfd[filter.PropName] < filter.Range[0] || clsfd[filter.PropName] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Lookup' && filter.IsOptionList){
                  if(!clsfd[filter.PropName] || clsfd[filter.PropName] && clsfd[filter.PropName].ID !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Radio Group' && filter.IsOptionList){
                  //console.log(filter)
                  if(!clsfd[filter.PropName]){
                    clsfd.Render = false
                  }
                  else{
                    let matches = clsfd[filter.PropName].map(opt => {
                      return opt.ID
                    })
                    if(!matches.includes(filter.ID)){
                      clsfd.Render = false
                    }
                  }
                  }
                  else if(filter.Type === 'Keyword' && filter.IsOptionList){
                  //console.log(filter)
                  if(!clsfd[filter.PropName]){
                    clsfd.Render = false
                  }
                  else{
                    let matches = clsfd[filter.PropName].map(opt => {
                      return opt.id
                    })
                    if(!matches.includes(filter.ID)){
                      clsfd.Render = false
                    }
                  }
                  }
              })
            this.AdditionalFilters.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(typeof clsfd[filter.Field.Name] === 'undefined'){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            this.NavList.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(typeof clsfd[filter.Field.Name] === 'undefined'){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            //console.log(this.FeatureList)
            this.FeatureList.map(feature => {
              
              
                if(feature.Filter){
                  
                  if(!this.FeatureListisLookup && typeof clsfd[feature.Name] === 'undefined'){
                    //console.log(clsfd.Title,feature.Name,clsfd[feature.Name],typeof clsfd[feature.Name] === 'undefined',clsfd.Render)
                    clsfd.Render = false
                  }
                  
                  else if(this.FeatureListisLookup && typeof clsfd[this.FeatureLookupProp] === 'undefined' || this.FeatureListisLookup && clsfd[this.FeatureLookupProp] && !feature.Matches.includes(clsfd[this.FeatureLookupProp].id) ){
                  clsfd.Render = false
                  }
                  // console.log(clsfd.Title,feature.Name,clsfd[feature.Name],typeof clsfd[feature.Name] === 'undefined',clsfd.Render)
                  //console.log(clsfd[this.FeatureLookupProp],feature.Matches)
                }
            })
            //console.log(this.RouteQueries)
            this.RouteQueries.map(routequery => {
              let prop = routequery.Prop
              let val = routequery.Value
              let navlist = this.CurrentEntity.NavList.find(obj => obj.Field && obj.Field.id === prop)
              //actually this match only works for Single Option filter
              if(!navlist){
                //now this check would pass for treeview
                navlist = this.CurrentEntity.NavList.find(obj => obj.NavTreeField === prop)
                if(!navlist){
                  //if still no match we ahve to search for Filters type , which is a damn tricckya rray
                  let filtertypes = this.CurrentEntity.NavList.filter(type => {
                    return type.Type === 'Filter List'
                  })
                  filtertypes.map(filtyp => {
                    navlist = filtyp.Filters.find(obj => obj.id === prop)
                  })
                  //okay we should relaly "break" but nevermind it would now assign the last match
                  if(navlist){
                    let field = navlist
                    if(field.Type === 'Lookup' || field.Type === 'Option Set'){
                      let match = field.Options.find(obj => obj.Name === val)
                      
                      if(!match){
                        clsfd.Render = false
                      }
                      else{
                        if(!clsfd[field.id] || clsfd[field.id] && !clsfd[field.id]
                        || clsfd[field.id] && clsfd[field.id] && clsfd[field.id].ID !== match.ID){
                        clsfd.Render = false
                      }
                      }
                      
                    }
                  }
                  else{
                    //I don't know, maybe just a dud query
                  }
                }
                else{
                  let match = navlist.TreeList.find(obj => obj.name === val)
                  if(!match){
                      clsfd.Render = false
                    }
                    else{
                    if(!clsfd[prop] || clsfd[prop] && clsfd[prop].ID !== match.id){
                        clsfd.Render = false
                      }
                    }
                }
              }
              else{
                let field = navlist.Field 
                if(field.Type === 'Lookup' || field.Type === 'Option Set'){
                  let match = field.Options.find(obj => obj.Name === val)
                  
                  if(!match){
                    clsfd.Render = false
                  }
                  else{
                    if(!clsfd[field.id] || clsfd[field.id] && !clsfd[field.id]
                    || clsfd[field.id] && clsfd[field.id] && clsfd[field.id].ID !== match.ID){
                    clsfd.Render = false
                  }
                  }
                  
                }
              }
            })
            
            return clsfd       
          })
          .filter(clsfd => {
            return clsfd.Render
          })
          .filter(clasfd => {
              if(this.thirdclass){
                return clasfd[this.mainclass.LevelFieldName] && clasfd[this.mainclass.LevelFieldName].ID === this.mainclass.ID && clasfd[this.subclass.LevelFieldName] && clasfd[this.subclass.LevelFieldName].ID === this.subclass.ID
                && clasfd[this.thirdclass.LevelFieldName] && clasfd[this.thirdclass.LevelFieldName].ID === this.thirdclass.ID
              }
              else if(this.subclass){
                
                return clasfd[this.mainclass.LevelFieldName] && clasfd[this.mainclass.LevelFieldName].ID === this.mainclass.ID && clasfd[this.subclass.LevelFieldName] && clasfd[this.subclass.LevelFieldName].ID === this.subclass.ID
              }
              else if(this.mainclass){
                return clasfd[this.mainclass.LevelFieldName] && clasfd[this.mainclass.LevelFieldName].ID === this.mainclass.ID
              }
              else{
                return clasfd
              }
          })
        }
          
      },
      
    
    },
  
  watch: {    
    VuexStoreStateData: {
            handler: function(newvalue, oldvalue) {
             if(newvalue !== oldvalue){
               
               this.FixArrayItems()
             }
            },
            deep: true
        },
    KeyWord: {
            handler: function(oldvalue, newvalue) {
             if(oldvalue !== newvalue){
               this.DataTableListingspage = 1
             }
            },
            deep: true
        },
    ComputedKeyWords: {
            handler: function(oldvalue, newvalue) {
              this.$emit('UpdateComputedKeyWords',this.ComputedKeyWords)
            },
            deep: true
        },
  },
  
  methods: {
    TogglePublicNavbarEditing(){
      this.$emit('TogglePublicNavbarEditing')
    },
    ConfirmActivateSite(site){
      confirm('This will navigate to the site "'+site.Company_Name+'". Do you want to navigate there now?') && this.ActivateSite(site)
    },
    ActivateTemplateSite(site){
      this.$router.push('/MarketplaceTemplate/'+site.id)
      this.TogglePublicNavbarEditing()
    },
    ActivateSite(site){
      if(this.CurrentEntity.id === 'Templates'){
        //console.log(site)
        //this.$store.commit('setActiveMarketTemplate',site) 
        //this.$router.push('/') 
        this.ActivateTemplateSite()
      }
      else{
        this.$store.commit('setActiveSuiteid',site.id) 
      this.$router.push('/') 
      }
      
    },
    ActivateSiteListing(site){
      if(this.ActiveSitelisting && this.ActiveSitelisting.id === site.id){
      this.ActiveSiteListingDialog = false
        this.ActiveSitelisting = ''
      }
      else if(this.ActiveSitelisting && this.ActiveSitelisting.id !== site.id){
      this.ActiveSiteListingDialog = false
      this.ActiveSitelisting = ''
      this.ActivateListing(site)
      }
      else{
        this.ActivateListing(site)
      }
      
    },
    ActivateListing(site){
      this.ActiveSitelisting = site
      this.SiteRatings = []
      this.GetSiteRatings()
      this.ActiveSiteListingDialog = true
    },
    GetSiteRatings(){
      //we neerd to do one better than usermessages, regardless what reasons you have to believe it's a good idea...
            db.collection('usermessages').where('IsSiteRating','==',true).where('SiteSender','==',false).where('relatedid','==',this.ActiveSitelisting.id).onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    item.Replies = []
                    this.SiteRatings.push(item)
                    if(this.CanViewReplies){
                            db.collection('usermessages').where('IsSiteRating','==',true).where('SiteSender','==',true).where('Replytoid','==',item.id).onSnapshot(replyres => {
                                const rechanges = replyres.docChanges();
                                rechanges.forEach(rechange => {
                                if (rechange.type === 'added') {
                                    let reply = {
                                    ...rechange.doc.data(),
                                    id: rechange.doc.id
                                    }
                                    item.Replies.push(reply)
                                }
                                })
                            })  
                            }
                }  
                })
                //console.log('this.SentMessages',this.SentMessages,this.AllUserConversations,this.AllUserConversationsAscending)
            })
            },
    
    EmitReviewMethod(){
      this.ActivateGroupRating()
      },
      EmitSendMessageMethod(){
      this.ActivateMessagetoSiteOwner()
      },
      ActivateGroupRating(){
      this.SiteRatingActive = true
      this.ActivateMessagetoSiteOwner()
      },
      ActivateMessagetoSiteOwner(){      
      //this.DefaultNewMessage.recipientid = 'icMHmsfU9XMr8ZMjFoSIJXrvLdY2',
      //who?????no createdby and now BU either? who? has to eb setup
      this.DefaultNewMessage.senderid = this.userLoggedIn.id,
      //this.NewMessage.recipientid = 'icMHmsfU9XMr8ZMjFoSIJXrvLdY2',
      this.NewMessage.recipientid = this.ActiveSitelisting.Ownerid,
      this.NewMessage.relatedid = this.ActiveSitelisting.id,
      this.NewMessage.topicid = 'Systems'
      this.NewMessage.relatedtype = 'Systems'
      this.NewMessage.Title = this.ActiveSitelisting.Company_Name,
      // this.NewMessage.grouplogo = this.GroupData.logo,
      this.NewMessage.OfficialRepresentative = false
      this.NewMessage.IsSiteRating = true
      this.NewMessage.SiteSender = false
      if(this.userLoggedIn.Profile_Photo){
        this.NewMessage.Profile_Photo = this.userLoggedIn.Profile_Photo
      }
      this.NewMessage.UserName = this.userLoggedIn.Full_Name
      this.MessageGroupDialog = true
      },
      DeactivateMessageGroupDialog(){
      this.MessageGroupDialog = false
      this.SiteRatingActive = false
      this.SiteRating = 3
      this.NewMessage = Object.assign({},this.DefaultNewMessage)
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
    SendMessagetoSiteOwner(){
      let NewMessage = Object.assign({},this.NewMessage)
      NewMessage.Created_On = new Date()
      if(this.SiteRatingActive){
        NewMessage.IsSiteRating = true
        NewMessage.Rating = this.SiteRating
      }
       db.collection('usermessages').add(NewMessage).then(doc => {
          this.DeactivateMessageGroupDialog()         
       })
    },
    ActivateTreefromChild(clss){
      
      this.$emit('ActivateTreefromChild',clss)
    },
    PushActiveProduct(item){
            this.$emit('PushActiveProduct',item)
        },
    GetFeatureListEntries(query){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
            if (change.type === 'added') {
              let featureitem = {
                ...change.doc.data(),
                id: change.doc.id
            }
            this.FeatureList.map(feature => {
              if(!feature.Matches){
                feature.Matches = []
              }
              if(featureitem[feature.id] && !feature.Matches.includes(featureitem.id)){
                feature.Matches.push(featureitem.id)
              }
              return feature
            })
            }
          })
      })
    },
    AddtoCompare(item){
      this.$emit('AddtoCompare',item)
    },
      ActivateViewportBuilder(item){
        if(this.$route.name !== 'WikiCollectionBuilder'){
          this.$emit('ActivateViewportBuilder',item,'Classified Listing')
        }
        else{
          this.$emit('ActivateViewportBuilder',item,'Wiki Listing')
        }
            
        },
      GetClassifiedListings(){
        //console.log('GetClassifiedListings')
        let vm = this
          let array = []
          let query = ''
          if(this.CurrentEntity.CategoricalListType === 'Wiki Listing'){
            //alert('yay')
            query = db.collection(this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'wiki')
            if(this.CurrentEntity.IsFiltered && this.CurrentEntity.FilterField && this.CurrentEntity.FilterbyCurrent){
              query = query.where(this.CurrentEntity.FilterField.Name+'id','==',this.Record.id)
            }
          }
          else if(this.CurrentEntity.CategoricalListType === 'Classified Listing'){
            query = db.collection(this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings')
          }
          else if(this.CurrentEntity.CategoricalListType === 'Store Products'){
            query = db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()+'store')
          }
          else if(this.CurrentEntity.CategoricalListType === 'Vacancies'){
            query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).where('PublishStatus','==','Published')
          }
          else if(this.CurrentEntity.CategoricalListType === 'Social Groups'){
            
            if(this.CurrentEntity && this.CurrentEntity.ListPrimaryField && this.CurrentEntity.ListPrimaryField.LevelFieldName){
             let primarymatch = this.CurrentEntity.ListPrimaryField.LevelFieldName+'.ID'
             let privacytypes = ['Public']
              if(this.$route.meta && this.$route.meta.RouteEventID === 1027 && this.userLoggedIn){
                privacytypes.push('All Site Members')
                if(!this.UserisGuest){
                  privacytypes.push('Non Guest Site Members')
                }
              }
            query = query = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups')
              .where('GroupPrivacy','in',privacytypes).where('GroupPublishStatus','==','Published').where(primarymatch,'==',this.CurrentEntity.ListPrimaryField.ID) 
            
            let pageid = ''
              if(this.$route.meta && this.$route.meta.PageID){
              pageid = this.$route.meta.PageID
              query = query.where('ActiveDirectoryPages','array-contains',pageid)
              }
            }
            else{
              let privacytypes = ['Public']
              if(this.$route.meta && this.$route.meta.RouteEventID === 1027 && this.userLoggedIn){
                privacytypes.push('All Site Members')
                if(!this.UserisGuest){
                  privacytypes.push('Non Guest Site Members')
                }
              }
              
              query = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups')
              .where('GroupPrivacy','in',privacytypes).where('GroupPublishStatus','==','Published')
              let pageid = ''
              if(this.$route.meta && this.$route.meta.PageID){
              pageid = this.$route.meta.PageID
              }
              if(pageid){
                query = query.where('ActiveDirectoryPages','array-contains',pageid)
              }
            }
            
          }
          else if(this.CurrentEntity.CategoricalListType === 'Business Members'){
            query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessmembers')
            
          }
          else if(this.CurrentEntity.CategoricalListType === 'Featured Members'){
            query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').where('Published','==',true)
            if(!this.userLoggedIn){
              query = query.where('Public','==',true)
            }
            
          }
          else if(this.CurrentEntity.CategoricalListType === 'Web Apps'){
            
            query = this.CurrentEntity.id === 'Templates' ? 
            db.collection('marketplacetemplates').where('Featured','==',true).where('PublishStatus','==','Published').where('Category.Name','==','Site') : 
            db.collection('Websites').where(firebase.firestore.FieldPath.documentId(), '!=', process.env.VUE_APP_RA_PROVIDER_ID).where('Is_Directory_Listing','==',true)
            
          }
          if(this.CurrentEntity.QualifiedListings && this.CurrentEntity.QualifiedListings.length > 0){
           query = query.where('Status_Reason.ID','in',this.CurrentEntity.QualifiedListings)
          }
          if(!this.userLoggedIn && this.CurrentEntity.CategoricalListType !== 'Social Groups' &&
          this.CurrentEntity.CategoricalListType !== 'Store Products' && this.CurrentEntity.CategoricalListType !== 'Featured Members'
          && this.CurrentEntity.CategoricalListType !== 'Web Apps'&& this.CurrentEntity.CategoricalListType !== 'Vacancies'){
            query = query.where('PublishType','==','Public')
          }
          if(this.CurrentEntity){
              array = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'Array']
              if(this.CurrentEntity.CategoricalListType === 'Store Products'){
              }
              let storeobjpayl = {
                EntityID: this.CurrentEntity.id,
                Collection: '',
                Data: [],
                Query: query,
                CategoricalListType: this.CurrentEntity.CategoricalListType
              }
              if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                storeobjpayl.Collection = this.CurrentEntity.id.split('_').join('').toLowerCase()+'store'
              }
              else if(this.CurrentEntity.CategoricalListType === 'Social Groups' && this.$route.meta.PageID){
                storeobjpayl.EntityID = this.CurrentEntity.id+'_'+this.$route.meta.PageID
              }
              //console.log(storeobjpayl)
              this.$store.commit('SetCatNavlistTableQuery',storeobjpayl)
                if(this.VuexStoreCatNavlist && this.VuexStoreStateData.length > 0){
                  this.FixArrayItems()
                }
                else{
                  //console.log('seems mofo calling')
                    let storedatapayload = {
                    EntityID: this.CurrentEntity.id,
                    Query: query,
                    CategoricalListType: this.CurrentEntity.CategoricalListType
                  }
                  
                  if(this.CurrentEntity.CategoricalListType === 'Social Groups' && this.$route.meta.PageID){
                    storedatapayload.EntityID = this.CurrentEntity.id+'_'+this.$route.meta.PageID
                  }
                  this.$store.dispatch('GetCatNavlistDataArray',storedatapayload).then(result => {
                     //console.log(storedatapayload,vm.$store.state)
                  })
                 
                }
          }
          
      },
      FixArrayItems(){
        //console.log('FixArrayItems')
        this.DataTableListings = this.VuexStoreStateData.map(obj => {
          let newobj = Object.assign({},obj)
          //console.log('newobj',newobj)
          return newobj
        }).map(obj => {
          if(this.CurrentEntity.id === 'Group_Categories'){
                    if(obj.logo){
                      obj.Photo = {
                        ThumbURL: obj.logo
                      }
                    }
                    if(obj.DefaultPage && obj.DefaultPage.Type === 'Group Page'){
                      obj.Path = '/Group-Page/'+obj.id+'/'+obj.DefaultPage.Name
                    }
                    else{
                      obj.Path = '/Group/'+obj.id
                    }
                    obj.Title = obj.name
                  }
                  else if(this.CurrentEntity.id === 'Business_Members'){
                    obj.Photo = {
                        ThumbURL: obj.Profile_Photo
                      }
                    obj.Path = '/BusinessMember/'+obj.id
                  }
                  else if(this.CurrentEntity.id === 'Featured_Members'){
                    obj.Photo = {
                        ThumbURL: ''
                      }
                      if(obj.Profile_Photo){
                        obj.Photo = obj.Profile_Photo
                      }
                    obj.Path = '/FeaturedMember/'+obj.id+'/'+obj.DefaultPage
                    obj.Title = obj.Full_Name
                    obj.Description = obj.Overview
                  }
                  else if(this.CurrentEntity.id === 'Vacancies'){
                    obj.Photo = {
                        ThumbURL: ''
                      }
                      if(obj.Company && obj.Company.Logo){
                        obj.Photo = obj.Company.Logo
                      }
                    obj.Path = '/Vacancy/'+obj.id
                    obj.Title = obj.Full_Name
                    obj.Description = obj.Overview
                  }
                  else if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                      obj.Description = obj.DescriptiveText
                    obj.Path = '/StoreItem/'+this.CurrentEntity.id+'/'+obj.id
                  }
                  return obj
        })
              
      },
    
    
      
  
  }
}

</script>

 <style>
 .hovercard {
     max-height: 200px;
 }
.hovercard:hover {
    background-color: #eb371b;
    max-height: 350px;
}
.hovercard .hovercardstitle {
 color: #eb371b; 
 font-family: 'Roboto', sans-serif;
 text-transform: uppercase; 
 font-size: 1em;
 letter-spacing: 0.1666666667;
   
}
.hovercard:hover .hovercardstitle {
 color: white; 
 font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;  
}
.hovercard .hovercardbutton {
 visibility: hidden 
}
.hovercard .hovercardimage{
height: 70px;
}
.hovercard:hover .hovercardimage{
height: 200px;
}
.hovercard:hover .hovercardbutton {
 visibility: visible;   
}

</style>
<style scoped>

</style>
          
            
        