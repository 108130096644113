<template>
 <v-card tile flat height="100%" width="100%" > 
      <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[POPDFFunctionData]"		:PassedFunction="POPDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
         <v-layout class="justify-center background">
          <div style="padding-top: 20px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
            <v-card-title  class="justify-space-between recordtoolbar white--text">
            {{ComputedCompany.Company_Name}}
        </v-card-title>
        <v-tabs v-model="CompanyTabs" class="siteconfigcardtabs" style="background-color: #FFFFFF00;" v-if="!ActiveAccount && !ActiveUser">
              
                <v-tab>Statement
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00 !important;">
                
                <AccountStatement :ActiveAccount="''" :CompanyView="true" :CompanyRecord="ComputedCompany" :CompanyBillingAccounts="CompanyBillingAccounts"
                />
               </v-tab-item>
                <v-tab>Transactions
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00 !important;">
                  <AccountTransactions :ActiveAccount="''" :CompanyView="true" :CompanyRecord="ComputedCompany" :CompanyBillingAccounts="CompanyBillingAccounts"
                  :SystemEntities="SystemEntities" :System="System"
                />  
               </v-tab-item>
            <v-tab class="transparent">Company Information
               </v-tab>
               
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;">
                    <v-card-subtitle class="mediumoverline" style="padding-top:30px;">
                        Company Information
                    </v-card-subtitle>
                    <v-card  class="siteconfigcard"  width="100%">
                    <v-card-text >    
                        <v-row class="justify-space-between"
                            no-gutters
                        >
                            <v-col  :cols="MiniView ? 12: 6" style="align-self: flex-start;"
                            >     
                          <div>
                        <FieldValueEditerComponent v-for="field in CompanyHeaders" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="ActiveCompany" :field="field" @onPhotoFileselected="onPhotoFileselected"
                        :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                        @UploadFileSelect="UploadFileSelect"
                            />
                        </div>  
                            </v-col>
                            <v-col :cols="MiniView ? 12: 6" style="align-self: center;">
                                  <!-- <FieldsForm :FormFields="[Invoicing_Address]" :Validating="Validating" :DataTableView="false" @save="ProgressWindow"
                            @UploadFileSelect="UploadFileSelect" :DataObject="ActiveAccount" :tab="{}" /> -->
                                   <FieldValueEditerComponent style="padding: 0px;" v-for="field in [Invoicing_Address]" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="ActiveCompany" :field="field" @onPhotoFileselected="onPhotoFileselected"
                        :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                        @UploadFileSelect="UploadFileSelect"
                            />
                             </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn dark color="warning">
                            Cancel
                        </v-btn> -->
                        <v-spacer>
                        </v-spacer>
                        <v-btn @click="UpdateMyCompany()" dark color="success">
                            Save
                        </v-btn>
                    </v-card-actions>
                    </v-card>
               </v-tab-item>
               <v-tab class="transparent">
                <v-badge v-if="UserAlerts !== 0"
                color="red"
                :content="UserAlerts"
              >
                Users
              </v-badge>
              <span v-if="UserAlerts === 0">Users
              </span>
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;">
                <v-list-item v-if="UserCanInvite" @click="InviteFieldValueUser()"  class="listoutline">
                  <v-list-item-title  class="subtleoverline">
                    Invite Users
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-reply</v-icon>
                  </v-list-item-action>
                </v-list-item>
                   <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="SelectActiveUser" :AdditionalHeaders="[]" :ShowExpand="false"
                    :UsersLookupArray="CompanyUsers" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
                    />
                <v-card elevation="0" class="siteconfigcard">
                  <v-card-subtitle class="mediumoverline">
                    User Invites
                </v-card-subtitle>
                <v-card-text >
                  <UserInvites :System="System" :SystemEntities="SystemEntities"/>
                </v-card-text>
                </v-card>
               </v-tab-item>
                <!-- <v-tab class="transparent">Billing Accounts
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;">
                <v-list-item @click="SelectActiveAccount(account)" v-for="account in CompanyBillingAccounts" :key="account.itemObjKey">
                  {{account.Client_Reference}}
              </v-list-item>
              <v-list-item v-if="CompanyBillingAccounts && CompanyBillingAccounts.length === 0">
                There are no accounts for {{ComputedCompany.Company_Name}}
                </v-list-item>
               </v-tab-item> -->
        </v-tabs>
        <div v-if="ActiveUser && !ActiveAccount">
           <v-card-title  class="justify-space-between recordtoolbar white--text">
           {{ActiveUser.Full_Name}}<v-spacer>
            </v-spacer>
            <v-btn @click="ActiveUser = ''">All Users</v-btn>
        </v-card-title>
        <v-tabs class="siteconfigcardtabs" style="background-color: #FFFFFF00;">
         
           <v-tab class="transparent">Billing Accounts
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;">
                <!-- perhaps the big Ui thing is simply to see each account as v-expansion -panel, with alert badge, and dorpdown gives alert infosheet...? -->
                <v-expansion-panels v-if="ActiveUser && ActiveUser.BillingAccounts.length > 0">
                  <v-expansion-panel  v-for="account in ActiveUser.BillingAccounts" :key="account.itemObjKey">
                    <v-expansion-panel-header>
                      {{account.Client_Reference}}
                      <template v-slot:actions>
                        <v-icon color="error" v-if="account.Alerts > 0">
                          mdi-alert-circle
                        </v-icon>
                        <v-icon color="success" v-if="account.Alerts === 0">
                          mdi-check
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-btn @click="SelectActiveAccount(account)" outlined>View Account</v-btn>
                      <div v-if="account.Alerts === 0">
                        This Account has no alerts
                      </div>
                      <div v-else>
                         <InfoSheet v-for="elmnt in AccountAlertItems(account)" :key="elmnt.itemObjKey"							
                            :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />	
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              <v-list-item v-if="ActiveUser && ActiveUser.BillingAccounts.length === 0">
                There are no accounts for {{ActiveUser.Full_Name}}
                </v-list-item>
               </v-tab-item>
        </v-tabs>
        </div>
        <ActiveBillingAccount v-if="ActiveAccount && ActiveUser" @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="ClientStoreOrders"
          :SubscriptionPackages="SubscriptionPackages" :ActiveAccount="ActiveAccount" @DeactivateActiveAccountView="DeactivateActiveAccountView"
          @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />        
          </div>
         </v-layout>
 </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import AccountStatement from '@/components/Client/AccountStatement'
import AccountTransactions from '@/components/Client/AccountTransactions'
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
import UserLookupTable from '@/components/General/UserLookupTable'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import ActiveBillingAccount from '@/components/Client/ActiveBillingAccount'
import UserInvites from '@/components/Dashboards/UserInvites'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';	
export default {
    props: ['System','SystemEntities','ClientStoreOrders','SubscriptionPackages','CompanyRecord'],
    components: {PDFExporter,FieldValueEditerComponent,UserLookupTable,ActiveBillingAccount,UserInvites,AccountStatement,AccountTransactions,InfoSheet},
    data() {
        return {
          CompanyTabs: 0,
          alertelmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	
          Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          ActiveUser: '',
          ActiveAccount: '',
            snackbar: false,
            snackbartext: '',
            CreditTokens: [],
            CreditTokenTransactions: [],
            AccountDebits: [],
            AccountCredits: [],
            SalesInvoices: [],
            SalesQuotes: [],
            OrderLineItems: [],
          ActiveCompany: '',
          CompanyFields: [
          {Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text', IsMandatory: true},
          {Name: 'Company_Email',DisplayName: 'Company Email',Type: 'Common Field',CommonFieldType: 'Email', IsMandatory: true},
          {Name: 'Company_Phone',DisplayName: 'Company Phone',Type: 'Common Field',CommonFieldType: 'Telephone Number', IsMandatory: true},
          {Name: 'Company_Website',DisplayName: 'Company Website (Optional)',Type: 'Common Field',CommonFieldType: 'URL Link'},
          {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text',IsContentEditable: true, IsMandatory: true},
        ],
        CompanyBillingAccounts: [],
        UserHeaders: [
                {text: 'Full Name', value: 'Full_Name', class: 'overline'},
                {text: 'Business Unit', value: 'Business_Unit.Name', class: 'overline'},
                {text: 'Alerts',value: 'Alerts',class: 'overline'}
            ],
        }
    },	
    computed:{
      ComputedCompany(){
        return this.CompanyRecord ? this.CompanyRecord : this.MyCompany
      },
      UserAlerts(){
        return this.CompanyUsers.map(user => {
          return user.Alerts
        }).reduce((a, b) => a + b, 0)
      },
      UserbasedCompanyBillingAccounts(){
        return this.CompanyBillingAccounts.filter(ba => {
          return this.ActiveUser && ba.Userid === this.ActiveUser.id
        })
      },
      ActiveAccountRef(){
            return this.ActiveAccount && this.ActiveAccount.id ? db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.ActiveAccount.id) : ''
        },
      BillingTransactions(){
            return this.SalesQuotes.concat(this.SalesInvoices)
        },
      UserCanInvite(){
        return this.userLoggedIn && this.userLoggedIn.CanInviteUsers || this.userIsAdmin
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
      CompanyUsers(){
        return this.UsersArray.filter(user => {
          return user.Companyid === this.ComputedCompany.id
        }).map(user => {
          let userobj = Object.assign({},user)
          if(typeof userobj.Alerts === 'undefined'){
            userobj.Alerts = 0
          }
          userobj.BillingAccounts = this.CompanyBillingAccounts.filter(ba => {
            return ba.Userid === userobj.id
          }).map(ba => {
            let baobj = Object.assign({},ba)
            if(typeof baobj.Alerts === 'undefined'){
              baobj.Alerts = 0
            }
            if(typeof baobj.AlertItems === 'undefined'){
              baobj.AlertItems = []
            }
            if(baobj.Billing_Type && baobj.Billing_Type.Name === 'Credit' &&!baobj.Credit_Limit || 
              baobj.Billing_Type && baobj.Billing_Type.Name === 'Credit' && baobj.Credit_Limit === 0){
              baobj.Alerts = baobj.Alerts-1+2
              baobj.AlertItems.push({
                Description: 'This account does not have a Credit Limit set. Set it now?',
                Color: 'red',
                AlertIcon: 'mdi-alert',
                HasActionButton: false,
                RoutePath: '/'
              })
            }
            return baobj
          })
          //okay so can this comp user set credit limit? UserisCompanyAdmin
          //now, is there a user with a Credit_Limit of 0???
          userobj.Alerts = userobj.Alerts+userobj.BillingAccounts.map(trans => {
                  let total = Number(trans.Alerts)
                  return total
              }).reduce((a, b) => a + b, 0)
          //okay daft but we have the amount of acitons. Would rpefer an action. Possible even so require on heaeders and shit how to interact?
          return userobj
        })
      },
      UsercanEditCreditLimit(){
            return this.UserisCompanyAdmin && this.ComputedCompany && this.ComputedCompany.Account_Limits_by_Admin
        },
      CompanyHeaders(){
            // let headers = ['Account_Name','Account_Email','Account_Phone','Client_Reference','Account_Website']
            // return this.CurrentEntity.AllFields.filter(field => {
            //     return headers.includes(field.Name)
            // }).map(field => {
            //     if(field.id === 'Client_Reference'){
            //         field.DisplayName === 'My Reference'
            //     }
            //     return field
            // })
            return this.CompanyFields
        },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      UserisGuest(){
        return this.userLoggedIn ? this.userLoggedIn.IsGuestUser : 'undefined'     
      },
      CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
      },
      SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      MyCompany(){
      return this.$store.state.MyCompany
    },
      UserisCompanyAdmin(){
        return this.$store.state.UserisCompanyAdmin
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.ActiveCompany = Object.assign({},this.ComputedCompany)
      this.PrepareAddressField('Invoicing_Address')
      this.GetCompanyBillingAccounts()
    },
    methods:{
      AccountAlertItems(account){
        return account.AlertItems.map(item => {
                let elmnt = Object.assign({},this.alertelmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
      PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : '',
            [field.Name+'_Street_Name'] : '',
            [field.Name+'_Suburb'] : '',
            [field.Name+'_City'] : '',
            [field.Name+'_Postal_Code'] : '',
            [field.Name+'_State_Province'] : '',
            [field.Name+'_Country_Region'] : ''
        }
        //console.log(field)
        },    
      SelectActiveUser(user){
        this.ActiveUser = user
      },
      EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },	
      DeactivateActiveAccountView(){
        this.ActiveAccount = ''
      },
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean)
      },
      SelectActiveAccount(account){
            this.ActiveAccount = Object.assign({},account)
        },
        DeselectActiveAccount(){
            this.ActiveAccount = ''
        },
      InviteFieldValueUser(fieldname){
        //this.$store.commit('SetSocialItemInteractMethodProp',{RelatedObj: this.RelatedObj,RelatedField: fieldname}) 
        this.$store.commit('SetSocialItemInteractMethod','AcceptInviteUserDialog')
      },
      GetCompanyBillingAccounts(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').where('Companyid','==',this.ActiveCompany.id).onSnapshot(res=>{
          const changes = res.docChanges();
                    changes.forEach(change => {
                    if (change.type === 'added') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        this.CompanyBillingAccounts.push(tokenobj)
                    }
                    if (change.type === 'modified') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        let oncheck = this.CompanyBillingAccounts.find(obj => obj.id === tokenobj.id)
                        if(oncheck){
                            let index = this.CompanyBillingAccounts.indexOf(oncheck)
                            this.CompanyBillingAccounts.splice(index,1,tokenobj)
                        }
                    }
                    if (change.type === 'removed') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        let oncheck = this.CompanyBillingAccounts.find(obj => obj.id === tokenobj.id)
                        if(oncheck){
                            let index = this.CompanyBillingAccounts.indexOf(oncheck)
                            this.CompanyBillingAccounts.splice(index,1)
                        }
                    }
                    })
        })
      },
      UpdateMyCompany(){
        let updobj = {
          User: this.ActiveCompany.User,
          Userid: this.ActiveCompany.Userid
        }
        this.CompanyHeaders.map(header => {
          if(typeof this.ActiveCompany[header.Name] !== 'undefined'){
            updobj[header.Name] = this.ActiveCompany[header.Name]
          }
        })
        this.Invoicing_Address.FieldBreakdown.map((brk,brkindex) => {
          if(typeof this.ActiveCompany[brk.Name] !== 'undefined'){
            updobj[brk.Name] = this.ActiveCompany[brk.Name]
          }
        })
        //console.log(updobj)
        //still need "Company_NameQuery" sure and snackbar but okay for now
        //still need logo featuredphoto or something I guess
        //yes could add all these fields to the entity and push to template sure
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('usercompanies').doc(this.ActiveCompany.id).update(updobj)
      }
    }
}
</script>

<style>

</style>



