<template>
  <!-- <div style="padding-bottom:100px;"> -->
   <div>
    <MemberCompanyDialog v-if="NewCompanyDialog" @SelectCompany="SelectCompany"
            :System="System" :SystemEntities="SystemEntities"
            @DeactivateNewCompanyDialog="DeactivateNewCompanyDialog"
                />

    <v-card height="100%"
    class="mx-auto"
  >
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{currentTitle}}</v-list-item-title>
              <v-list-item-subtitle>{{currentSubTitle}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action><v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="step"
      ></v-avatar>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn color="red" :disabled="step === 5" @click="CancelStoreProduct()" class="soloactionicon" icon>
            <v-icon>mdi-close
            </v-icon>
          </v-btn>
            </v-list-item-action>
          </v-list-item>
          
    </v-card-title>
        <v-divider></v-divider>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text v-if="!Refreshing">
          <v-img :height="EditingProduct ? 250 : 250" contain :src="ActiveProduct.IMG ? ActiveProduct.IMG : 
          System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')">
                <v-row v-if="EditingProduct" style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding:0px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">        
                <input style="display: none;" type="file" name="advertsingleimg" ref="advertsingleimg"
                 @change="SelectAdvertIMG($event)" :accept="AllAllowedFileTypes">
                <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('AdvertIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
            </v-img>     
            <v-list-item>
          <v-btn v-if="!ActiveProduct.Company" @click="ActivateNewCompanyDialog()" width="100%"><v-icon>mdi-plus</v-icon> Add Company</v-btn>
           <v-btn v-if="ActiveProduct.Company" @click="ActivateNewCompanyDialog()" width="100%">{{ActiveProduct.Company.Company_Name}}</v-btn>
          </v-list-item>    
          <v-list-item>
           <v-text-field outlined v-model="ActiveProduct.Title" label="Title"/>
          </v-list-item>
           <v-list-item>
             <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" 
                :Record="ActiveProduct" :field="CategoryField" @onPhotoFileselected="onPhotoFileselected"
                :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" 
                :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                @UploadFileSelect="UploadFileSelect"
                />
           <!-- <v-select :disabled="!userIsAdmin" outlined v-model="ActiveProduct.Category" :items="AllProductCategories" item-text="Name"  label="Category"/> -->
          </v-list-item>
          <v-list-item>
            <v-card-text>
            <ContentEditableField :FieldID="'Advert_Description'"
            :FieldObject="ActiveProduct" :FieldName="'Description'"
            :FieldValue="ActiveProduct.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-card-text>
          </v-list-item>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="2">
        <v-card-text v-if="step === 2">
          <v-layout row class="justify-center" :style="MiniView ? 'padding:15px;padding-bottom: 80px' : 'padding: 15px;padding-bottom: 80px'">
                <SignupPackages :System="System" :SystemEntities="SystemEntities" :ViewOnly="false"
          :SignUpLabel="'Select'" @SignupwithPackage="SignupwithPackage" :FullWidth="true" :Shorter="true"
        :SubscriptionPackages="CategorizedAdvertPackages"/>
          </v-layout>
              </v-card-text>
      </v-window-item>
       <v-window-item :value="3">
        <v-card-text v-if="PackagePlugin === 'Site_Events'">
              <NewEventForm :SiteModerators="[]" :System="System" :SystemEntities="SystemEntities" :AccessRoles="AccessRoles"
              :ProvidedSubject="ActiveProduct.Title" :ProvidedDescription="ActiveProduct.Description" :FromAdvert="true"
              @AddNewEvent="AppendAdvert" :ProvidedImage="ActiveProduct.IMG" :ProvidedIMGFile="SelectedImage" :UpdateAdvert="UpdateAdvert"
              />
              </v-card-text>
        <v-card-text v-else-if="PackagePlugin === 'Featured_Members'">
          <div v-if="HasFeatMem">
           <v-card rounded>
            <v-img :height="250" contain :src="ActiveProduct.IMG ? ActiveProduct.IMG : 
                System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')">
                      <v-row v-if="EditingProduct" style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding:0px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">        
                      <input style="display: none;" type="file" name="advertsingleimg" ref="advertsingleimg"
                      @change="SelectAdvertIMG($event)" :accept="AllAllowedFileTypes">
                      <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('AdvertIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                  </v-row>
                  </v-img>
                  <v-card-subtitle class="subtleoverline" style="font-weight: bold;">
                    {{ActiveProduct.Title}}
                  </v-card-subtitle>      
                <v-card-text >
                  <!-- <v-card-subtitle style="font-weight: bold;">
                  Description
                  </v-card-subtitle> -->
                  <v-card-subtitle class="caption" v-html="ActiveProduct.Description">
                  </v-card-subtitle>
              </v-card-text>
          </v-card>
          </div>
          <div v-if="!HasFeatMem">
             <FeaturedMemberOverview  @ActivateProcessing="ActivateProcessing" :ProvidedMember="ProvidedMember"
          :System="System" :SystemEntities="SystemEntities" :FeaturedMemberid="userLoggedIn.id" :HidePage="true"
         />
          </div>
        </v-card-text>
        <v-card-text v-else>
          <!-- HERE is where we put link and tel number AND....Sepficify I have link or not -->
               <v-combobox chips item-text="Name" label="KeyWords" multiple
               outlined  v-model="ActiveProduct.KeyWords" placeholder="Select KeyWords"
                :items="KeyWordOptions"/>
                <!-- 1) TEL NR 2) LINK -->
                 <v-text-field outlined dense placeholder="Contact Number"
                label="Type in Contact Number" prepend-icon="mdi-phone"
                v-model="ActiveProduct.TelephoneNumber" :rules="[$store.state.formrules.required,$store.state.formrules.telnr]"/>
                <v-select @change="Refresh()" outlined dense return-object placeholder="Select the link type from the options" item-text="Name"
                  label="Page Link Type" v-model="ActiveProduct.LinkType" :items="PageLinkTypes"/>
                <!-- okay technically, when we do certain things it should not matter. PackagePlugin Applies.
                For an Event, technically should ask PRIOR create of event, but for now, create Event every time, therefore not such option relevant
                so if the Category plugin provides for a "page" above and below disabled -->
                <v-text-field v-if="ActiveProduct.LinkType && ActiveProduct.LinkType.ID !== 1000001 || SystemAutoProvide"
                outlined dense placeholder="Link" :disabled="LinkDisabled"
                :label="ActiveProduct && ActiveProduct.LinkType && ActiveProduct.LinkType.Name ? ActiveProduct.LinkType.Name : 'Type in Link'" prepend-icon="mdi-link"
                v-model="ActiveProduct.Link" :rules="[$store.state.formrules.required,$store.state.formrules.url]"/>
        </v-card-text>
       </v-window-item>
      <v-window-item :value="4">
        <v-card-text class="bootstrap-form-input" v-if="!Refreshing && PackagePlugin">
               <!-- HERE is where we put link and tel number AND....Sepficify I have link or not -->
               <v-combobox chips item-text="Name" label="KeyWords" multiple
               outlined  v-model="ActiveProduct.KeyWords" placeholder="Select KeyWords"
                :items="KeyWordOptions"/>
                <!-- 1) TEL NR 2) LINK -->
                 <v-text-field outlined dense placeholder="Contact Number"
                label="Type in Contact Number" prepend-icon="mdi-phone"
                v-model="ActiveProduct.TelephoneNumber" :rules="[$store.state.formrules.required,$store.state.formrules.telnr]"/>
                <v-select @change="Refresh()" outlined dense return-object placeholder="Select the link type from the options" item-text="Name"
                  label="Page Link Type" v-model="ActiveProduct.LinkType" :items="PageLinkTypes"/>
                <!-- okay technically, when we do certain things it should not matter. PackagePlugin Applies.
                For an Event, technically should ask PRIOR create of event, but for now, create Event every time, therefore not such option relevant
                so if the Category plugin provides for a "page" above and below disabled -->
                <v-text-field v-if="ActiveProduct.LinkType && ActiveProduct.LinkType.ID !== 1000001 || SystemAutoProvide"
                outlined dense placeholder="Link" :disabled="LinkDisabled"
                :label="ActiveProduct && ActiveProduct.LinkType && ActiveProduct.LinkType.Name ? ActiveProduct.LinkType.Name : 'Type in Link'" prepend-icon="mdi-link"
                v-model="ActiveProduct.Link" :rules="[$store.state.formrules.required,$store.state.formrules.url]"/>
        </v-card-text>
        <v-card-text class="bootstrap-form-input" v-if="!Refreshing && !PackagePlugin">
        <SystemTicketsLogging :SystemEntities="SystemEntities" :db="ClientDB"
        :AutoTicket="AutoTicket" v-if="!ActiveProduct.SystemTicketid"
        @CancelSubmitSystemTicket="CancelSubmitSystemTicket" @ProvideTicket="CloseAdvertTicket"
        :UserRecord="userLoggedIn" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
        </v-card-text>
      </v-window-item>
      <v-window-item :value="5">
        <v-card-text>
           <div class="pa-4 text-center">
          <v-img
            class="mb-4"
            contain
            height="128"
            src="@/assets/logo.png"
          ></v-img>
          <h3 class="text-h6 font-weight-light mb-2">
            Your Advert is Created! {{SelectedPackage ? 'You will be redirected to the payment page shortly.' : ''}}
          </h3>
          <span v-if="!SelectedPackage" class="text-caption grey--text">Thanks for signing up!</span>
        </div>
        <!-- Publish Now does nothing as yet but in pricniple yes working -->
          <v-btn :disabled="SelectedPackage" @click="CloseandReactivateAdvert()">
            Publish Now
          </v-btn>
          <!-- 
          Here we go, very simply put.
          1. Advert is Ready when
            1.1 External Link 
            1.2 FM and FM already created
            1.3 Event, and linked to existing event
          2. NOT READY (now choose to DIY)
            2.1 Auto DIY - FM
            2.2 DIY Opt - Eent, LP, BP/WS
           -->
        </v-card-text>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="step === 1 || step === 5"
        text
        @click="step = PreviousStep"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="NextDisabled"
        :color="NextStep ? 'warning' : 'primary'"
        depressed
        @click="AdvanceStep()"
      >
        {{NextStep === 5 ? 'Create' : 'Next'}}
      </v-btn>
    </v-card-actions>
  </v-card>
   </div>
  <!-- </div> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import AdvertDirectoryEdit from '@/components/SuitePlugins/AdvertDirectory/AdvertDirectoryEdit';
import AdvertDirectorySingle from '@/components/SuitePlugins/AdvertDirectory/AdvertDirectorySingle';
import NewEventForm from '@/components/SuitePlugins/Events/NewEventForm';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import FeaturedMemberOverview from '@/components/SuitePlugins/FeaturedMembers/FeaturedMemberOverview';
import SiteListing from '@/components/SuitePlugins/WebAppBuilder/SiteListing';
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'
import MemberCompanyDialog from '@/components/Database/Fields/Hybrid/MemberCompanyDialog'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
export default {
    props: ['System','SystemEntities','SubscriptionPackages','Directories',
    'AdvertsCollectionRef','ActiveProduct'],
    components: {SignupPackages,MemberCompanyDialog,SystemTicketsLogging,
    ContentEditableField,AdvertDirectoryEdit,AdvertDirectorySingle,NewEventForm,FieldValueEditerComponent,FeaturedMemberOverview,SiteListing},
    data() {
        return {
          AutoTicket: {},
          NewCompanyDialog: false,
          SelectedPackage: '',
          Company: {
            Company_Name: '',
            Description: '',
            Slogan: '',
            SiteEmail: '',
            SiteTelephoneNumber: '',
            GoogleMapsLocation: '',
            Logo: '',
          },
          DefaultCompany: {
            Company_Name: '',
            Description: '',
            Slogan: '',
            SiteEmail: '',
            SiteTelephoneNumber: '',
            GoogleMapsLocation: '',
            Logo: '',
          },
          Refreshing: false,
          UpdateAdvert: false,
            AccessRoles: [
                {
                    ID: 1,
                    Name: 'Public',

                },
                {
                    ID: 2,
                    Name: 'Site Members',

                },
                {
                    ID: 4,
                    Name: 'Invite Only',

                },
                ],
        email: '',
        password: '',
        rePassword: '',
        Title: '',

        Name: '',
        Surname: '',
        BusinessUnit: null,
            step: 1,
            EditingProduct: true,
            // ActiveProduct: {},
            OptionTypeFilter: 0,
            ProductOptionDialog: false,
            NewOptionSetIndex: -1,
            NewOptionSet: {
                Name: ''
            },
            DefaultNewOptionSet: {
                Name: ''
            },
          editedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',            
          },
          defaulteditedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',
          },
          menu: false,
          LinkTypes: [
          {ID: 1000001, Name: 'No Link'},
          {ID: 1000002, Name: this.System.Name+' Platform Link'},
          {ID: 1000003, Name: 'External Link'},
          {ID: 1000004, Name: 'Google Maps Location'},
        ],
        DefaultCol: {
                Flat: true,
                Transparent: true,
                Elevation: 0,
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 12,									
                FlexXSmall: 12,
                Name: 'Some Col',
            },
        }
    },	
    computed:{
      AutoTicketTitle(){
          return this.PackagePlugin === 'Site_Events' ? this.ActiveProduct.Title+' - Landing Page' : 
              this.PackagePlugin === 'Featured_Members' ? this.ActiveProduct.Title+' - FM Page' : this.ActiveProduct.Title+' Business Page'
        },
      ClientDB(){
        return db
      },
      PreviousStep(){
        if(this.step === 2){
          return 1
        }
        else if(this.step === 3){
          return 2
        }
        else if(this.step === 4){
          return 3
        }
        else if(this.step === 5){
          if(this.PackagePlugin){
            return 4
          }
          else if(this.ActiveProduct.LinkType && this.ActiveProduct.LinkType.ID === 1000002){
            //look we may also skip step 4 here lest you want to recreate the ticket bnut anywho
            return 4
          }
          else{
            // we don't need a link, or external or maps link so skip step 4
            return 3
          }
        }
        else if(this.step === 4){
          return 5
        }
      },
      NextStep(){
        if(this.step === 1){
          return 2
        }
        else if(this.step === 2){
          return 3
        }
        else if(this.step === 3){
          if(this.PackagePlugin){
            return 4
          }
          else if(this.ActiveProduct.LinkType && this.ActiveProduct.LinkType.ID === 1000002){
            return 4
          }
          else{
            // we don't need a link, or external or maps link so skip step 4
            return 5
          }
        }
        else if(this.step === 4){
          return 5
        }
      },
      CategorizedAdvertPackages(){
        return this.ActiveProduct ? this.AdvertPackages.filter(pack => {
          return !pack.CategoryFilter || 
          pack.CategoryFilter && this.ActiveProduct.Product_Group && this.ActiveProduct.Product_Group.ID === pack.CategoryFilter.ID || 
          pack.CategoryFilter && this.ActiveProduct.Product_Class && this.ActiveProduct.Product_Class.ID === pack.CategoryFilter.ID || 
          pack.CategoryFilter && this.ActiveProduct.Product_Type && this.ActiveProduct.Product_Type.ID === pack.CategoryFilter.ID
        }) : this.AdvertPackages
      },
      AdvertPackages(){
        return this.SubscriptionPackages.filter(pack => {
          return pack.Type && pack.Type.Name === 'Advert Package'
        })
        // .map(pck => {
        //   let props = ['id','Name','Description','Pluginid','Templateid]
        //   let packobj = {}
        //   props.map(prp => {
        //     if(typeof pack[prp] !== 'undefined'){
        //       packobj[prp] = pack[prp]
        //     }
        //   })
        //   return packobj
        // })
      },
      UserAssetStorageRef(){
        return 'UserAssets/'+this.userLoggedIn.id
        //db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userassets')
      },
      UserAssetRef(){
        return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userassets')
      },
      LinkLabel(){
        return this.ActiveProduct && this.ActiveProduct.LinkType && this.ActiveProduct.LinkType.Name ? this.ActiveProduct.LinkType.Name : 'Type in Link'
        },
      LinkDisabled(){
        let inactiveoptions = [1000001]
        return this.ActiveProduct && this.ActiveProduct.LinkType && inactiveoptions.includes(this.ActiveProduct.LinkType.ID)
      },
      NextDisabled(){
        //step 2 cannot "next" must select pack
        return this.step === 5 || this.step === 2 && !this.SelectedPackage || 
        !this.ActiveProduct.Product_Group ||  !this.ActiveProduct.Title ||  !this.ActiveProduct.Description
        // ||  !this.ActiveProduct.IMG
      },
      PageLinkTypes(){
        return this.LinkTypes.filter(option => {
          //okay technically it means we allow now Without Link for Organisation. We should not want to do this but...I get it.
          return this.PackagePlugin !== 'Featured_Members' || option.ID !== 1000001 && option.ID !== 1000004 
        })
      },
      ProvidedMember(){
        let slave = {}
        let master = this.ActiveProduct
        slave.Props = [{MasterProp: 'Description',SlaveProp: 'Overview'},{MasterProp: 'IMGFile',SlaveProp: 'Profile_Photo'}]
        slave.Props.map(prp => {
          if(typeof master[prp.MasterProp] !== 'undefined'){
            slave[prp.SlaveProp] = master[prp.MasterProp]
          }
        })
        return slave
      },
      HasFeatMem(){
        return this.UserFeaturedMemberSnap && this.UserFeaturedMemberSnap.Full_Name
      },
      RequireAdvertAppend(){
        return this.step === 3 && this.PackagePlugin === 'Site_Events' || 
        this.step === 3 && this.PackagePlugin === 'Featured_Members' && !this.UserFeaturedMemberSnap
      },
      UserFeaturedMemberSnap(){
        return this.$store.state.UserFeaturedMemberSnap
      },
      FeaturedMemberDefaultPage(){
        return this.$store.state.FeaturedMemberDefaultPage
      },
      OwnerObj(){
        return {          
                  Created_By: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                  Created_Byid: this.userLoggedIn.id,
                  Owner: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                  Ownerid: this.userLoggedIn.id,
                  Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                  Modified_Byid: this.userLoggedIn.id,
          }
      },
      SystemAutoProvide(){
        return this.PackagePlugin === 'Site_Events' || this.PackagePlugin === 'Featured_Members'
      },
      AdvertLookupObj(){
        return {
              id: this.ActiveProduct.id,
              identifier: 'Title',
              Title: this.ActiveProduct.Title, 
              list: 'adverts',
              single: 'advert',
              LookupType: 'System Lookup'  
            }
      },
      LookupObj(){
        if(this.PackagePlugin === 'Site_Events'){    
            let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('siteevents').doc()
            let newdocid = ref.id
            return {
              id: newdocid,
              identifier: 'name',
              name: this.ActiveProduct.AppendObj.name, 
              list: 'siteevents',
              single: 'siteevent',
              LookupType: 'Social Lookup'  
            }
        }
      },
        CategoryPlugin(){
          //no longer used, replaced with PackagePlugin all over but kept for reference
          return this.ActiveProduct.Product_Group ? this.ActiveProduct.Product_Group.Pluginid : ''
        },
        PackagePlugin(){
          return this.SelectedPackage && this.SelectedPackage.Pluginid ? this.SelectedPackage.Pluginid : ''
        },
        passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Password must match'
            },
      UserFullName(){
      return this.Name+' '+this.Surname
    },
    NeedsLink(){
      return !this.PackagePlugin && this.ActiveProduct.LinkType && this.ActiveProduct.LinkType.ID === 1000002
    },
        currentSubTitle () {
        switch (this.step) {
          case 1: return 'Enter Advert Information'
          case 2: return 'Select the package you require'
          case 3: return this.PackagePlugin ? 'Capture info for associated '+this.CreatingItem : 'Setup Advert Buttons and Links'
          case 4: return this.NeedsLink ? 'Log Ticket' : 'Setup Advert Buttons and Links' 
          case 5: return 'Signup Info'
          default: return 'Account created'
        }
      },
        currentTitle () {
        switch (this.step) {
          case 1: return 'Advert Info'
          case 2: return 'Select Package'
          case 3: return this.PackagePlugin ? this.CreatingItem+' Info' : 'Advert Buttons'
          case 4: return this.NeedsLink ? 'Log a Support ticket to get your Link created' : 'Advert Buttons'
          case 5: return 'Signup Info'
          default: return 'Account created'
        }
      },
        CreatingItem(){
          return this.PackagePlugin === 'Site_Events' ? 'Event' : 
          this.PackagePlugin === 'Featured_Members' ? 'Member' : 'Company'
        },
        KeyWordOptions(){
            return this.$store.state.SiteKeyWordOptions.filter(kw => {
                return kw.Type && kw.Type.Name === 'Site' || kw.Type && kw.Type.Name === 'Advert'
            })
        },
        UnusedCategoryOptions(){
            return this.ProductCategory && this.ProductCategory.AdditionalOptions.filter(opt => {
                return !this.ProductAdditionalOptions.find(obj => obj.Name === opt.Name) 
            })
        },
        ProductAdditionalOptions(){
            return this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
        },
      CategoryField(){
        return this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
        this.AdvertDirectory.CategoryField : ''
      },
      AdvertDirectory(){
            return this.$store.state.AdvertDirectory
        },
      AllProductCategories(){
        return this.ProductsCatsLevel3.concat(this.ProductsCatsLevel2,this.ProductCategories)
      },
      ProductsCatsLevel3(){
            return this.ProductsCatsLevel2.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductsCatsLevel2(){
            return this.ProductCategories.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
        ProductCategory(){
            return this.ActiveProduct && this.ActiveProduct.Category && this.ProductCategories.find(obj => obj.id === this.ActiveProduct.Category.id) ? 
            this.ProductCategories.find(obj => obj.id === this.ActiveProduct.Category.id) : ''
        },
        ProductRef(){
            return this.AdvertsCollectionRef.doc(this.ActiveProduct.id)
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ComputedStoreProducts(){
          return this.StoreProducts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
    },
    watch: {
      NeedsLink(v){   
          if(v){
            // let ref = this.AdvertsCollectionRef.doc()
            // this.ActiveProduct.id = ref.id
            this.AutoTicket = {
              Title: this.AutoTicketTitle,
              Description: 'I need a page for my "'+this.ActiveProduct.Product_Group.Name.toUpperCase()+'" Advert "'+this.ActiveProduct.Title+'" ('+this.ActiveProduct.id+').',
              Priority: {ID: 1000003, Name: 'High',Color: 'red'}
            }
            // this.PageRequest = {
            //   ...this.OwnerObj,
            //   Created_On: new Date(),
            //   Modified_On: new Date()
            // }
            //TicketDialog
          }
        },
      PackagePlugin: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                if(newvalue === 'Featured_Members' && this.UserFeaturedMemberSnap && this.UserFeaturedMemberSnap.Profile_Photo){
                  this.ActiveProduct.IMG = this.UserFeaturedMemberSnap.Profile_Photo.fileurl
                  //this.$emit('RefreshMenu')
                }
              }              
            },deep: true
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignProductIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(!this.ActiveProduct.id){
        let ref = this.AdvertsCollectionRef.doc()
        this.ActiveProduct.id = ref.id
      }
        
    },
    methods:{
      SelectCompany(comp){
            let compbj = {
                Company_Name: comp.Company_Name,
                id: comp.id
            }
            this.ActiveProduct.Company = compbj
            this.ActiveProduct.Companyid = compbj.id
            this.DeactivateNewCompanyDialog()
            this.Refresh()
            console.log(this.ActiveProduct)
        },
      ActivateNewCompanyDialog(){
        this.NewCompanyDialog = true
      },
      DeactivateNewCompanyDialog(){
        this.NewCompanyDialog = false
      },
      SignupwithPackage(pack){
        this.SelectedPackage = JSON.parse(JSON.stringify(pack))
        this.AdvanceStep()
      },
      QuickSaveFile(payload){
        let field = payload.field
        let UploadObject = payload.UploadObject
        this.UserAssetRef.add(UploadObject).then(newdoc => {
         this.Company[field.Name] = payload.UploadObject
         this.Refresh()
         //console.log(this.Company)    
        })
             
      },
      UpdateQuickSave(payload){
          let record = payload.Record
        let fields = payload.Fields
        fields.map(field => {
            if(typeof record[field.Name] !== 'undefined'){
              this.Company[field.Name] = record[field.Name] 
            }
        })
        //console.log(this.Company)

      },
      Refresh(){
        this.Refreshing = true
        //this.step = 2
        setTimeout(() => {
          this.Refreshing = false
        //this.step = 3
        }, 20);
      },
      CloseandReactivateAdvert(){
        this.$emit('CloseandReactivateAdvert')
      },
      CancelStoreProduct(){
        this.$emit('CancelStoreProduct')
      },
      AdvanceStep(){
        //can' make head or tails now,
        if(this.step === 1){
          if(this.PackagePlugin === 'Featured_Members' && this.FeaturedMemberDefaultPage){
              this.ActiveProduct.Link = this.System.ClientAppURL+'/FeatutedMember/'+this.userLoggedIn.id+'/'+this.FeaturedMemberDefaultPage
              this.step = this.NextStep
          }
          else{
          this.step = this.NextStep
          }
        }
        else if(this.step === 2){
          //console.log(this.PackagePlugin)
          //remember it's only signup package, and package selecte din step 2 so nothing to do here, just go next step
          //purpose being - user can taggle back and forth without createing anything you check?
          //IN FACT!!!!! IF NOT PLUGIN, MAKE STEP 4!!
          this.step = this.NextStep
        }
        else if(this.step === 3){
          if(this.SystemAutoProvide){
            this.ActiveProduct.LinkType = this.PageLinkTypes.find(obj => obj.ID === 1000002)
            if(this.PackagePlugin === 'Featured_Members' && this.HasFeatMem){
              let defpage = 'Home'
              this.ActiveProduct.Link = 'FeaturedMember/'+this.ActiveProduct.Ownerid+'/'+defpage
              if(this.UserFeaturedMemberSnap.Mobile_Number){
               this.ActiveProduct.TelephoneNumber = this.UserFeaturedMemberSnap.Mobile_Number 
              }
              if(this.UserFeaturedMemberSnap.KeyWords){
                let keywords = this.UserFeaturedMemberSnap.KeyWords.map(kw => {
                  let match = this.KeyWordOptions.find(obj => obj.Type.Name === kw.Name)
                  return match ? match : kw.Name
                  return 
                })
                //console.log(keywords)
               this.ActiveProduct.KeyWords = keywords 
              }              
            }
          }
          else{
            //Company although nothing to put in yet this.Company
            this.Company.Company_Name = this.ActiveProduct.Title
          }
          if(this.RequireAdvertAppend){
           this.UpdateAdvert = true
            setTimeout(() => {
              this.UpdateAdvert = false
            }, 20); 
          }
          else{
            if(this.NextStep === 5){
              this.ProcessAdvert()
              //but there is an exception sir!!! LEST we should not be able to go "back" on step 5
            }
            else{
             this.step = this.NextStep 
            }            
          }
          
        }
        else{
          this.ProcessAdvert()
          }
      },
      ProcessAdvert(){
        // let ref = this.AdvertsCollectionRef.doc()
          // this.ActiveProduct.id = ref.id
          let ownerobj = {...this.OwnerObj,Created_On: new Date(),Modified_On: new Date()}
          this.ActiveProduct.AppendObj = {...this.ActiveProduct.AppendObj,...ownerobj}
          if(this.PackagePlugin === 'Site_Events'){
            this.ActiveProduct.AppendObj = {
            ...this.ActiveProduct.AppendObj,
            creatorfullname: this.userLoggedIn.Full_Name,
            creatorid: this.userLoggedIn.id,
            moderatorrolesarrayDBRules: {[this.userLoggedIn.id] : true},
            moderatorrolesarrayQuery: [this.userLoggedIn.id],
            FollowingUserIDKeys: [{[this.userLoggedIn.id] : true}],
            FollowingUserIDStrings: [this.userLoggedIn.id],
            FollowingUsers: [{...ownerobj.Modified_By}],
            EventImageStorageRef: this.ActiveProduct.IMGStorageRef,
            LookupObj: this.AdvertLookupObj
            }
          }
          if(this.SelectedPackage){
            this.ActiveProduct.Subscription_Package = this.SelectedPackage
            this.ActiveProduct.Subscription_Packageid = this.SelectedPackage.id
          }
          
          this.CheckSaveStoreProduct(true)
          //console.log(this.ActiveProduct)
      },
      signUp() {
        let vm = this
        const user = {
          Email: this.email,
          password: this.password,
          Title: this.Title,
          Name: this.Name,
          Surname: this.Surname,
          Gender: this.Gender,
          IsGuestUser: true,
          Full_Name: this.Name+' '+this.Surname,
          siteid: this.$store.state.ActiveSuiteid
        }
        if(this.System.Default_Member_Group){
          user.MemberGroup = this.System.Default_Member_Group
        }
        if(this.SelectedPackage && this.SelectedPackage.MemberGroup){
          user.MemberGroup = this.SelectedPackage.MemberGroup
        }
        //console.log(user)
        user.Advert = this.ActiveProduct
        //okay some slight fixes here
        //date objects, like event start and end, perfect - but on functions gotta make from time value to new date right...
         //then the EventImage please...also then EventImageStorageRef
         //and user stuff POST user create - FollowingUserIDKeys,FollowingUserIDStrings,FollowingUsers,
         //Moderators, Modified_By, Owner, creator,moderatorrolesarrayDBRules, moderatorrolesarrayQuery
         //okay but also now the real work comes
         //signup happens with Advert so now how do we make advert happen?
         //create of user doc, ensuring we create with Advert objt?
        //console.log(user)
        // vm.$emit('ActivateProcessing',true)
        // this.$store.dispatch('signUpAction', user).then(result => {
        //   vm.$emit('ActivateProcessing',false)
        //this.step++
        // })

      },
        AppendAdvert(AppendObj){
            //console.log('AppendAdvert',AppendObj)
          this.ActiveProduct.AppendObj = AppendObj
          this.ActiveProduct.LookupObj = this.LookupObj
          this.step = this.NextStep
        },
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
        AddCategoryOptiontoProduct(opt){
            let options = this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
            let newinteger = 1000000+options.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: opt.Name,
                DisplayName: opt.Name,
                Type: 'Option Set',
                Options: opt.Options,
                lastassignedinteger: 1000000+opt.Options.length,
            }
            options.push(newopt)
            this.ProductRef.update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
                this.CancelProductOptionDialog()
            })
        },
        SaveNewAdditionalOptionSet(){
            let options = this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
            if(this.NewOptionSetIndex === -1){
            let newinteger = 1000000+options.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: this.NewOptionSet.Name,
                DisplayName: this.NewOptionSet.Name,
                Type: 'Option Set',
                Options: this.NewOptionSet.Options.map((opt,opti) => {
                    return {ID: 1000001+opti,Name: opt}
                }),
                lastassignedinteger: 1000000+this.NewOptionSet.Options.length,
            }
            options.push(newopt)
            this.ProductRef.update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
                this.CancelProductOptionDialog()
            })
            }
            else{
                console.log('yeah update only')
            }
           
        },
        CancelProductOptionDialog(){
            this.ProductOptionDialog = false
            this.NewOptionSet = Object.assign({},this.DefaultNewOptionSet)
            this.NewOptionSetIndex = -1
        },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      AssignProductIMG(){
        this.ActiveProduct.IMG = this.SelectedImage.fileurl
            this.AdvertsCollectionRef.doc(this.ActiveProduct.id).update({
                IMG: this.ActiveProduct.IMG
            })
        this.$store.commit('setIMGProp','')
      },
      CheckSaveStoreProduct(asnew){
        if(this.ActiveProduct.Category){
            if(typeof this.ActiveProduct.Category.ID === 'undefined'){
                this.AddCategory(this.ActiveProduct.Category).then(newcat => {
                    this.ActiveProduct.Category = newcat
                    this.SaveStoreProduct()
                })
            }
            else{
               this.SaveStoreProduct(asnew) 
            }
        }
        else{
            this.SaveStoreProduct(asnew)
        }
      },
      AddCategory(cat){
        
        let vm = this
        return new Promise(function(resolve, reject) {	
            let field = vm.CategoryField ? vm.CategoryField : {}
            let options = field.Options ? field.Options : []
            let newinteger = 1000000+options.length
            newinteger++
            let newcat = {
                ID: newinteger,
                Name: cat,
                DisplayName: cat,
                AdditionalOptions: []
            }
            options.push(newcat)
            field.lastassignedinteger = newinteger
            db.collection('Stores').doc(vm.AdvertDirectory.id).update({
                CategoryField: field
            }).then(newcatdoc => {
                resolve(newcat)
            })
        })
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      SaveStoreProduct(asnew){
        //Original_Recordid
        let updobj = {}
        let props = ['Category','Title','Description','Price','KeyWords','TelephoneNumber','Link']
        //console.log(props)
        props.map(prp => {
            if(typeof this.ActiveProduct[prp] !== 'undefined'){
                updobj[prp] = this.ActiveProduct[prp]
            }
        })
        if(asnew){
          updobj = this.ActiveProduct
        }
        //console.log(updobj)
        let unregisteredkeywords = updobj.KeyWords.filter(kw => {
                return !kw.id
            })
        if(unregisteredkeywords.length > 0){
            unregisteredkeywords.map((kw,kwindex) => {
                    let path = db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords')
                    let ref = path.doc()
                    let newdocid = ref.id
                    let user = this.userLoggedIn
                    let ownerobj = {          
                            Created_By: {Full_Name: user.Full_Name, id: user.id},
                            Created_Byid: user.id,
                            Owner: {Full_Name: user.Full_Name, id: user.id},
                            Ownerid: user.id,
                            Created_On: new Date(),
                            Modified_By: {id: user.id, Name: user.Name, Surname: user.Surname, Full_Name: user.Full_Name},
                            Modified_Byid: user.id,
                            Modified_On: new Date(),
                    }
                    let kwobj = {
                        Name: kw,
                        Type: {ID: 1000004, Name: 'Advert'},
                        Original_Recordid: this.ActiveProduct.id,
                        ...ownerobj,
                        id: newdocid
                    }
                    console.log(kwobj)
                    db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords').doc(newdocid).set(kwobj).then(newkwdoc => {
                    let sitekwobj = updobj.KeyWords.find(obj => obj === kwobj.Name)
                    // console.log(updobj,sitekwobj)
                    // sitekwobj = kwobj
                    let index = updobj.KeyWords.indexOf(sitekwobj)
                    updobj.KeyWords.splice(index,1,kwobj)
                    if(kwindex-1+2 === unregisteredkeywords.length){
                        this.UpdateSearchandSite(updobj,this.ProductRef,asnew)
                    }
                })
            })
        }
        else{
            this.UpdateSearchandSite(updobj,this.ProductRef,asnew)
        }
        // this.ProductRef.update(updobj).then(newprod => {
        // //   this.CancelStoreProduct(true)
        //     this.ActivateSnackbar(true,'Successfully updated the product!') 
        // })
      },
      GetSiteKeyWordsSearchQuery(KeyWords,query){
        let vm = this
        return new Promise(function(resolve, reject) {
            KeyWords.map((kw,i) => {
                let title = kw.Name
                //console.log('kw',kw,title)
                    vm.$store.dispatch('CreateSearchTitle',kw.Name).then(searchtitle => {
                    query = query.concat(searchtitle)
                    if(i-1+2 === KeyWords.length){
                        resolve(query)
                    }
                    })
                })
        })
        },
      FinalizeAdvert(){
        this.step = this.NextStep
        //this.$emit('CancelStoreProduct')
        //okay so if SelectedPackage then acceptedmethods on SiteAdvertsList = 'SignupwithPackage' applies.
        //HOWEVER....okay yes agrred, so...what's the deal, what now?
        if(this.SelectedPackage){
        this.$store.commit('SetSocialItemInteractMethodProp',this.SelectedPackage) 
        this.$store.commit('SetSocialItemInteractMethod','SignupwithPackage')
        }
      },
      UpdateSearchandSite(site,siteref,asnew){
        //console.log('UpdateSearchandSite')
            let query = []
        this.$store.dispatch('CreateSearchTitle',site.Title).then(searchtitle => {
            query = searchtitle
            if(site.KeyWords && site.KeyWords.length > 0){
            this.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                query = query.concat(kwsearch)
                site.SearchQuery = query
                if(asnew){
                  siteref.set(site).then(upddoc => {
                    this.FinalizeAdvert()
                      //this.CancelUpdateSiteInfo()
                  })
                }
                else{
                  siteref.update(site).then(upddoc => {
                      //this.CancelUpdateSiteInfo()
                  })
                }
            })
            }
            else{
                site.SearchQuery = query
                if(asnew){
                  siteref.set(site).then(upddoc => {
                    this.FinalizeAdvert()
                      //this.CancelUpdateSiteInfo()
                  })
                }
                else{
                  siteref.update(site).then(upddoc => {
                      //this.CancelUpdateSiteInfo()
                  })
                }
            }
            
        })
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(FieldObject,prop,value)
    },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    }
}
</script>

<style>

</style>



