<template>
  <div>
    <v-card-title v-if="EditingProduct">
        Quick Preview
    </v-card-title>
    <v-card rounded style="overflow-x: hidden;">
        <!-- <v-row class="justify-center">
        <v-col cols="12"> -->
      <v-img :height="EditingProduct ? '' : 150" contain :src="ActiveProduct.IMG ? ActiveProduct.IMG : 
          System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')">
                <v-row v-if="EditingProduct" style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding:0px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">        
                <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('ProductIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
            </v-img>
            <v-card-text >
                <div class="caption" v-html="ActiveProduct.Description">
                        </div>
            </v-card-text>    
         <!--  <v-card-text >
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Details
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card-subtitle style="font-weight: bold;">
                        Description
                        </v-card-subtitle>
                        <v-card-subtitle class="caption" v-html="ActiveProduct.Description">
                        </v-card-subtitle>
                    <v-card-text v-if="!EditingProduct">
                        Now we previewing, so now it is real, show all additional fields etc
                    </v-card-text>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            </v-card-text> -->
        <!-- </v-col>
        <v-col cols="6"> -->
             <v-list-item class="justify-space-between">
                <v-list-item-content>
                <v-list-item-title class="mediumoverline red--text">
              {{CurrencyFormatter(ActiveProduct[PriceField],$store.state.DefaultCurrency.Currency)}}
                </v-list-item-title>
                <v-list-item-subtitle>
                {{ActiveProduct.Title}}                    
                </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> 
            <v-list-item class="justify-space-between">
              <v-text-field :disabled="PreventAdd" label="Qty" placeholder="Insert Qty" dense outlined v-model.number="ActiveProduct.Qty" />
            </v-list-item> 
            <v-list-item v-for="field in ActiveProduct.AdditionalOptions" :key="field.itemObjKey" class="justify-space-between">
                <v-select :disabled="PreventAdd" :label="field.DisplayName" :placeholder="'Insert '+field.DisplayName" :items="field.Options"
                item-text="Name"
                dense outlined return-object v-model="ActiveProduct[field.Name]" />
            </v-list-item>
        <!-- </v-col>
        </v-row> -->
        <v-card-actions >
            <v-btn :disabled="PreventAdd" @click="AddtoCart(ActiveProduct)" dark color="success"><v-icon>mdi-plus</v-icon>Add
            </v-btn>
        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
export default {
    props: ['System','SystemEntities','Product','EditingProduct','StoreProducts','ProductCategories',
    'ProductCollectionRef','ShippingGroupsRef','CategoriesRef','PriceField','PreventAdd',
    'UnreadNotifications','SystemPhotoLibraries','SystemDocumentLibraries','ActiveTemplate','DocumentationLibraries','Directories'],
    components: {},
    data() {
        return {
          ActiveProduct: '',
            SelectedQty: 1,
          editedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',            
          },
          defaulteditedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',
          },
          menu: false,
        }
    },	
    computed:{
        CartItems(){
          return this.$store.state.CartItems
        },
        ProductRef(){
            return this.ProductCollectionRef.doc(this.ActiveProduct.id)
        },
        ProductCollectionRef(){
            return db.collection('Stores').doc(this.$store.state.ActiveSuiteid).collection('storeproducts')
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ComputedStoreProducts(){
          return this.StoreProducts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignProductIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      
        System: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id && !this.ActiveTemplate){
                     this.SystemInstance = this.System
                }
            },deep: true
        },
    },
    created(){
      this.ActiveProduct = JSON.parse(JSON.stringify(this.Product))
      if(typeof this.ActiveProduct.Qty === 'undefined'){
        this.ActiveProduct.Qty = 1
      }
    },
    methods:{
    AddtoCart(ActiveProduct){
        let prod = JSON.parse(JSON.stringify(ActiveProduct))
        //prod.Qty = this.SelectedQty
        prod.Unit_Price = prod.Price
        prod.Item_Name = prod.Title
        prod.DescriptiveText = prod.Description
        //console.log(ActiveProduct,this.ActiveProduct)
        let props = ['Qty']
        let cartitems = this.$store.state.CartItems
        cartitems.push(prod)
        //this.$store.commit('setCartItems',cartitems)
        //console.log(this.$store.state.CartItems)
        this.$emit('DeactivateDialog')
    },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      AssignProductIMG(){
        this.ActiveProduct.IMG = this.SelectedImage.fileurl
            this.ProductCollectionRef.doc(this.ActiveProduct.id).update({
                IMG: this.ActiveProduct.IMG
            })
        this.$store.commit('setIMGProp','')
      },
      CancelStoreProduct(openafter){
        this.menu = false
        this.editedProduct = Object.assign({},this.defaulteditedProduct)
        if(openafter){
          setTimeout(() => {
            this.menu = true
          }, 500);
        }
      },
      SaveStoreProduct(){
        this.ProductRef.set(this.ActiveProduct).then(newprod => {
        //   this.CancelStoreProduct(true)
        let snackmsg = 'Successfully updated the product!'
            this.ActivateSnackbar(true) 
        })
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      GoBack(){
        this.ActiveLibTypeComp = ''
      },
      ActivateLibTypComponent(prodtype){
        this.ActiveLibTypeComp = prodtype
      },
    }
}
</script>

<style>

</style>



