<template>
<span>
    <v-btn @click="ActivateViewportBuilder(item)" v-if="$route.name === 'WikiCollectionBuilder' && !ViewPortBuilder" icon><v-icon>mdi-cogs</v-icon></v-btn>
   <v-card
            class="mx-auto"
            :dark="AppisDarkMode"
            max-width="600"
            style="padding:5px;"
            >
        <v-img v-if="!HideIMG && IMGField && item[IMGField]"
                :src="item[IMGField]"
                contain
                height="200px"
                >
                <v-btn depressed style="position:absolute;top:20px;" tile color="links" dark v-if="TagField"><FieldViewerComponent :field="TagField" :Record="item"
                /></v-btn>
                </v-img>
                    <v-img v-if="!HideIMG && !IMGField || !HideIMG && !item[IMGField]"
                    src="@/assets/ImageHolder.png"
                contain
                height="200px"
                >
                <v-btn depressed style="position:absolute;top:20px;" tile color="links" dark v-if="TagField" ><FieldViewerComponent :field="TagField" :Record="item"
                /></v-btn>
                </v-img>
                <v-list dense class="transparent">
                <v-list-item v-if="ShowPrice"  class="mediumoverline links--text">
                    {{CurrencyFormatter(item.Price,'ZAR','R')}}
                </v-list-item>
                    <v-list-item v-if="!HideTitle" class="overline primary--text">
                    <span v-if="TitleField">{{item[TitleField]}}</span>
                    <span v-if="!TitleField">Some Title</span>
                    <!-- {{item.DisplayTitle}} -->
                </v-list-item>
                </v-list>
        <v-card-subtitle v-if="TaggedFields && TaggedFields.length > 0" class="caption primary white--text mx-1 my-1" >
            <span v-for="(tag,tagindex) in TaggedFields" :key="tag.itemObjKey">
                <FieldViewerComponent :field="tag" :Record="item"
                /><span v-if="tagindex-1+2 < TaggedFields.length"> || </span>
            </span>
        </v-card-subtitle>
        <v-card  flat class="transparent background mx-3 my-2" height="100" v-if="!HideDescription">
        <v-card-subtitle v-if="DescriptionField && item[DescriptionField]" v-html="item[DescriptionField].substr(0,120)" class="caption mx-1 my-1" >
            
        </v-card-subtitle>
        <v-card-subtitle v-if="!DescriptionField"  class="caption mx-1 my-1" >
            Here is a Description. You can either hide this, or choose teh field value to propogate it
        </v-card-subtitle>
        </v-card>
            <v-card-actions class="justify-end" v-if="HasLocation && item.Location">
            <v-btn icon @click="ViewMapItem(item.Location.AddressLine1,item.Location.AddressLine2,item.Location.AddressLineCity,item.Location.AddressLinePostalCode,item.Location.AddressLineProvince,item.Location.AddressLineCountry)"><v-icon>mdi-google-maps</v-icon></v-btn>{{item.Location.Name}}
            <v-btn icon :href="'tel:'+item.Location.Telephone"><v-icon>mdi-phone</v-icon></v-btn>{{item.Location.Telephone}}
        </v-card-actions>
            <v-card-actions class="justify-end">
            <v-btn v-if="FeatureList && FeatureList.length > 0" dark small :disabled="OnCompare(item)" @click="AddtoCompare(item)">Add to Compare</v-btn><v-btn dark small :to="'/'+CurrentEntity.SingleName.split(' ').join('')+'-Wiki/'+item.id">View</v-btn>
        </v-card-actions>
    </v-card>
</span>
</template>

<script>
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';

export default {
    props: ['AppisDarkMode','item','ViewPortBuilder','HasLocation','TaggedFields','TagField','HideIMG','HidePrice','HideTitle','HideDescription','CurrentEntity','FeatureList','CompareItems',
    'DescriptionField','TitleField','IMGField'],
    components: {
        FieldViewerComponent
    },
    data(){
        return {

        }
    },
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    },
    created(){

    },
    methods:{
        OnCompare(item){
            //console.log(this.CompareItems)
            let match = this.CompareItems.find(obj => obj.id === item.id)
            if(!match){
                return false
            }
            else{
                return true
            }
        },
        AddtoCompare(item){
            this.$emit('AddtoCompare',item)
        },
        ActivateViewportBuilder(item){
            this.$emit('ActivateViewportBuilder',item)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
    }

}
</script>

<style>

</style>