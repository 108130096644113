<template>
  <div>
    <v-menu
      top
      :offset-x="false"
    >
      <template v-slot:activator="{ on, attrs }">
      <v-badge overlap 
          :color="CartItems.length === 0 ? 'grey' : 'blue'"
          :content="CartItems.length"
        >
        <v-btn v-bind="attrs"
          v-on="on" small fab icon>
          <v-icon>
        mdi-shopping
      </v-icon>
      </v-btn>
        </v-badge>
      </template>
      <v-card rounded max-width="350" min-width="300">
        <v-card-title class="mediumoverline">
          Cart Items ({{CartItems.length}})
        </v-card-title>
        <v-card-text>
           <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="CartItems"
                    :item-height="70"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                    <v-list>
                      <v-list-item >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{item.Title}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-html="item.Description">
                          <!-- <div v-html="item.Description"/> -->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{item.Qty}}
                      </v-list-item-action>
                    </v-list-item>
                    </v-list>
                  </template>
           </v-virtual-scroll>
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="CheckoutorEnquire()" dark outlined color="success">
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-menu>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        PluginDataBase(){
          return this.$store.state.PluginDataBase
        },
        CartItems(){
        return this.$store.state.CartItems
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{        
    CheckoutorEnquire(){
      if(this.PluginDataBase.Online_Store && this.PluginDataBase.Online_Store.Active){
        this.$router.push('/Checkout')
      }
      else{
        this.$router.push('/Checkout')
        //alert('we must submit a web form with Cart Items')
      }
    },
    }
}
</script>

<style>

</style>



