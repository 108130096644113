
<template>
  <div>
     <v-dialog v-model="aboutdialog" width="400">
                      <template v-slot:activator="{ on }">
                        <v-icon style="position: fixed; bottom: 130px; right: 50px;z-index:100;" fab v-if="$route.name !== 'GroupPage'" x-large v-on="on" color="green" class="soloactionicon my-1">mdi-help</v-icon>
                      </template>
                      <v-card>
                        <v-card-title class="mediumoverline">
                          <v-avatar
                            class="Grouptitle Avatar mx-4"
                            :size="50"
                            color="white"
                            tile
                            v-if="!selectedGroupImage"
                          >
                            <v-img contain :src="logo"></v-img
                          ></v-avatar>
                          <v-avatar
                            class="profile Avatar"
                            :size="50"
                            color="white"
                            tile
                            v-if="selectedGroupImage"
                          >
                            <v-img
                              contain
                              :src="GroupImagelocalURL"
                              width="150px"
                            ></v-img
                          ></v-avatar>
                          {{ name }} <v-spacer></v-spacer>
                          <v-icon color="red" @click="aboutdialog = false"
                            >mdi-close</v-icon
                          >
                        </v-card-title>
                        <v-card-text v-html="Description"> </v-card-text>
                      </v-card>
                    </v-dialog>
    <v-dialog v-model="MessageGroupDialog" width="400" persistent>
        
                       
                      <v-card width="400">
                        <v-card-title class="mediumoverline">
                          <v-avatar
                            class="Grouptitle Avatar mx-4"
                            :size="50"
                            color="white"
                            tile
                          >
                            <v-img contain :src="logo"></v-img
                          ></v-avatar>
                          {{ name }} <v-spacer></v-spacer>                          
                           <v-icon  x-large @click="DeactivateMessageGroupDialog()" color="green" class="soloactionicon my-1">mdi-close</v-icon>
                        </v-card-title>
                         <v-row class="justify-center" style="padding:10px;">
                          <v-rating v-if="GroupRatingActive"
                            v-model="GroupRating" class="justify-center"
                            background-color="amber lighten-1"
                            color="amber lighten-1"
                            
                          ></v-rating>
                        </v-row>
                        <v-card-text>
                          
                          <ContentEditableField
                            :FieldObject="NewMessage" :FieldName="'message'"
                            :FieldValue="NewMessage.message" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                        </v-card-text>
                       
                        <v-card-actions>
                          <v-spacer>
                          </v-spacer>
                          <v-btn @click="SendMessagetoGroup()">Send Message</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                   
   <!--  <v-layout column class="createnotice-container" v-if="userisContentAdmin">
      
      
      
      <v-icon
        @click="AddSocialItemtoNotice()"
        class="soloactionicon white"
        x-large
        color="warning"
        >mdi-clipboard-alert</v-icon
      > 
      
    </v-layout>-->
    <v-card tile
      flat
      :class="ShowGroupsasPortal ? 'transparent align-content-center' : 'transparent align-content-center my-6'"
      v-if="!GroupForbidden"
      :style="userLoggedIn ? 'padding-bottom: 60px' : 'padding-bottom: 35px'"
    >
      <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
        <span>Awesome! You updated the Group.</span>
        <v-btn color="white" @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-layout justify-center v-if="this.$route.name === 'GroupPage'">
        <v-flex xs12 s12 m12 lg12 v-if="SlugTab">
          <!-- <SocialSharing v-if="SlugTab.TabName === 'Wall'"
                    :ShareItemDialog="ShareItemDialog"
                    @CancelShareItemDialog="CancelShareItemDialog"
                    :LinkID="groupid"
                    :FriendsList="FriendsList"
                    :InternalLinkType="InternalLinkType"
                    :LinkComponent="LinkComponent"
                    :UserRecord="UserRecord"
                    :collectionname="'groups'"
                    :SiteModerators="SiteModerators"
                  /> -->
                  <SocialSitePosts  v-if="SlugTab.TabName === 'Wall'"
                :UsersArray="UsersArray" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :UserMemberObj="UserMemberObj" :PostsbyApproval="PostsbyApproval"
                :UserObj="RelatedGroupAdministrators[0]"
                :dark="AppisDarkMode"
                class="mx-3"
                :WallType="'Group'"
                :ShareName="GroupData.name"
                :UserRecord="UserRecord"
                :UserorGroupID="groupid"
                :UserorGroupView="true"
              />
               <component
                v-if="SlugTab.TabType === 'Group Component'"
                :ArticlesUpdateExternal="ArticlesUpdateExternal" :pushedgroupmembers="computedgroupmembers"
                :ArticlesExternalfirebaseConfig="ArticlesExternalfirebaseConfig"
                :ClientAppURL="ClientAppURL"
                :ClientFireStore="ClientFireStore"
                :GroupCategory="GroupCategory"
                :logo="logo"
                :is="'Group' + SlugTab.Name"
                :CanCreate="SlugTab.CanCreate"
                :CanView="CanView"
                :RelatedObj="RelatedObj"
                :UserRecord="UserRecord"
                :UsersArray="UsersArray"
                 :System="System"
                :UserisAdministrator="UserisAdministrator"
              >
              </component>
              <LandingPageSingle 
              :LoadedPageIcon="SlugTab.PageIcon" :LoadedPageBGGradient="SlugTab.PageBGGradient" :LoadedPageBGAsset="SlugTab.PageBGAsset" :LoadedPageBGColor="SlugTab.PageBGColor" :LoadedPageName="SlugTab.PageName"
              :LoadedPageRoute="SlugTab.PageRoute" :LoadedPageOverview="SlugTab.PageOverview" :LoadedPublishType="SlugTab.PublishType" :LoadedPageContentFont="SlugTab.PageContentFont"
              :LoadedPageHeadersFontSize="SlugTab.PageHeadersFontSize" :LoadedPageHeadersFont="SlugTab.PageHeadersFont"                          
              :DataSingleTabs="SlugTab.PageTabs" v-if="SlugTab.TabType === 'Group Page'"
              :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
              :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing"
              @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
              @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
              :EntityDataRef="EntityDataRef" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
              :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
              :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="'UpdateAccount'"
                @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
                :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
                :DynamicWikiData="$route.params.slug? CurrentEntity : DynamicRecordData" :Business_Unit="Business_Unit"
                :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
                :ActivitiesArray="ActivitiesArray" :RADB="RADB"
                :PrimaryFieldName="PrimaryFieldName"
              :ActiveEntityID="$route.meta.EntityID"/>
            
          
        </v-flex>
      </v-layout>
      <v-layout row v-if="GroupData && GroupData.GroupPublishStatus === 'Unpaid' && UserisAdministrator && $route.name !== 'GroupPage'" class="justify-center" style="margin-top: 40px;padding-bottom: 40px;">
        
         <v-flex xs12 s12 m12 :class="ShowGroupsasPortal ? 'lg12' : 'lg10'">
          <v-alert width="100%"
            border="top"
            :colored-border="true"
            :icon="'mdi-alert'"
            :elevation="6"
            :color="'red'"
            
          >
          <v-card-text >
            This Group has payment issues. Please resolve the outstanding Payment Amount to regain access
          </v-card-text>
          <!-- <v-card-actions v-if="tab.HasActionButton">
            <v-btn :to="tab.RoutePath">Go</v-btn>
          </v-card-actions> -->
          </v-alert>
         </v-flex>
        </v-layout>
      <v-layout justify-center v-if="$route.name !== 'GroupPage'">
        <v-flex xs12 s12 m12 :class="ShowGroupsasPortal ? 'lg12' : 'lg10'">
          <v-card tile light color="primary" class="align-content-center" v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm || !ActivePage">
            <v-img
              :src="logo"
              max-height="350px"
              gradient="to top, rgba(12,12,40,0.9), rgba(12,12,12,0.5), rgba(12,12,40,0.9)"
            >
              <v-row  v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
                <v-col>
                  <v-btn
                    dark
                    @click="updateGroupPublishStatus('Unpaid',GroupData.GroupPublishStatus)"
                    color="warning"
                    v-if="UserisMonetizationOfficer && GroupData && GroupData.GroupPublishStatus === 'Published'"
                  >
                    Deactivate (non Payment)<v-icon>mdi-alert</v-icon>
                  </v-btn>
                   <v-btn
                    dark
                    @click="updateGroupPublishStatus('Published',GroupData.GroupPublishStatus)"
                    color="success"
                    v-if="userIsMonetizationOfficer && GroupData && GroupData.GroupPublishStatus === 'Request Publish'"
                  >
                    Approve<v-icon>mdi-publish</v-icon>
                  </v-btn>
                  <v-btn
                    dark
                    @click="updateGroupPublishStatus(GroupData.PreviousStatus,'Unpaid')"
                    color="success"
                    v-if="userIsMonetizationOfficer && GroupData && GroupData.GroupPublishStatus === 'Unpaid'"
                  >
                    Reactivate (Payment Recieved)<v-icon>mdi-alert</v-icon>
                  </v-btn>
                  <v-btn
                    dark
                    @click="EditGroup()"
                    color="#e4003b"
                    v-if="UserisAdministrator"
                  >
                    Edit
                  </v-btn>
                   
                  <v-btn @click="ShareBlog()" v-if="GroupData && GroupData.GroupPublishStatus === 'Published'"
                    >Share<v-icon>mdi-share-circle</v-icon></v-btn
                  >
                  <SocialSharing v-if="userLoggedIn && !$store.state.TemplateView"
                    :ShareItemDialog="ShareItemDialog"
                    @CancelShareItemDialog="CancelShareItemDialog"
                    :LinkID="groupid"
                    :FriendsList="FriendsList"
                    :InternalLinkType="InternalLinkType"
                    :LinkComponent="LinkComponent"
                    :UserRecord="UserRecord"
                    :collectionname="'groups'"
                    :SiteModerators="SiteModerators"
                  /> </v-col
                ><v-spacer></v-spacer><v-spacer></v-spacer
                ><v-spacer></v-spacer>
                <v-col>
                  <v-card-actions>
                    <v-btn
                      dark
                      @click="ProcessMembershipStatus(true)"
                      color="#048abf"
                      v-if="UserCanJoin"
                    >
                      Join
                    </v-btn>
                    <v-btn
                      dark
                      @click="ProcessMembershipStatus(true, true)"
                      color="#048abf"
                      v-if="UserCanJoinviaInvite"
                    >
                      Join (Invited)
                    </v-btn>
                    <v-btn
                      dark
                      @click="IgnoreInvite()"
                      color="#048abf"
                      v-if="UserCanJoinviaInvite"
                    >
                      Ignore Invite
                    </v-btn>
                    <v-btn
                      dark
                      color="#048abf" 
                      v-if="UserisPendingMember"
                    >
                     Membership {{UserMemberObj.Status}}
                    </v-btn>
                    <v-btn
                      dark
                      @click="ProcessMembershipStatus(false)"
                      color="#048abf"
                      v-if="UserisActiveMember"
                    >
                      Leave Group
                    </v-btn>
                    <v-btn v-if="GroupDashboardAvailable" :to="'/GroupDashboard/'+this.groupid" target="_blank">
                           <v-icon color="blue">mdi-monitor-dashboard</v-icon>Dashboard
                         </v-btn>
                    
                  </v-card-actions> </v-col
              ></v-row>
              <h1 id="Grouptitle" class="Grouptitle white--text">{{ name }}</h1>
              <h4
                id="Grouptitle"
                class="Grouptitle white--text mx-4"
                v-if="GroupCategory"
              >
                {{ GroupCategory.Name }}
                <v-icon v-if="EndorsingNetwork" light color="white" x-large
                  >mdi-cloud-check</v-icon
                >
              </h4>
              <v-avatar
                class="Grouptitle Avatar mx-4"
                :size="$vuetify.breakpoint.smAndUp ? 164 : 128"
                color="white"
                tile
                v-if="!selectedGroupImage"
              >
                <v-img contain :src="logo"></v-img
              ></v-avatar>
            </v-img>
            <v-avatar
              class="profile Avatar"
              :size="$vuetify.breakpoint.smAndUp ? 164 : 128"
              color="white"
              tile
              v-if="selectedGroupImage"
            >
              <v-img contain :src="GroupImagelocalURL" width="150px"></v-img
            ></v-avatar>
            <input
              style="display: none"
              type="file"
              @change="onGroupImageselected($event)"
              ref="fileInputer"
            />
            <v-btn
              dark
              v-if="
                (!logo && !selectedGroupImage && UserisAdministrator) ||
                (!logo && !selectedGroupImage && userIsAdmin)
              "
              color="#e4003b"
              class="font-weight-light"
              @click="$refs.fileInputer.click()"
              >Upload Picture</v-btn
            >
            <v-btn
              dark
              v-if="selectedGroupImage"
              color="#e4003b"
              class="font-weight-light"
              @click="onUploadGroupImageFile()"
              >Save Picture</v-btn
            >
            <v-btn
              dark
              v-if="selectedGroupImage"
              color="#048abf"
              class="font-weight-light"
              @click="closeGroupImage()"
              >Cancel</v-btn
            >
            <!-- <v-card light color="#f8f8f8" class="align-content-center" tile>
              <span v-html="Description"></span>
            </v-card> -->
          </v-card>
          <v-card v-if="!UserisActiveMember && !UserhasAccess && !$store.state.TemplateView" tile>
            <v-card-title
              >This Group Access level is set to {{ GroupPrivacy }}
              <v-icon>mdi-lock</v-icon></v-card-title
            >
            <v-card-text v-if="GroupisPrivate"
              >You may request access to the Group
              <v-icon>mdi-lock-open-outline</v-icon> here
              <v-btn color="#e4003b">request access</v-btn></v-card-text
            >
            <v-card-text v-if="GroupisMembersOnly"
              >You may request access to the Group
              <v-icon>mdi-lock-open-outline</v-icon> by joining the group
              <v-btn color="#e4003b" @click="AddMember()"
                >Join</v-btn
              ></v-card-text
            >
          </v-card>
          <v-card v-if="$store.state.TemplateView" tile>
            <v-card-title class="mediumoverline"
              >The below content comes from the {{ActiveSuiteTemplate ? ActiveSuiteTemplate.Name : ActiveMarketTemplate.Name}} template, purely for illustration purposes. 
              Once your Suite is launched, Groups can have their own Articles, Blogs, Classifieds, Events, Forums, Meetups,Polls and more.
              <v-btn @click="OpenAboutDialog('AboutGroupsDialog')" x-large outlined>Read More</v-btn> </v-card-title
            >
            <v-card-text v-if="UserisTemplateOwner"
              >
              <UserLookup v-if="$store.state.TemplateView && GroupData" :UsersLookupArray="TemplateUsers"  :ModelProp="'Owner'" :RecordObj="GroupData" @UpdateUserLookupProp="UpdateUserLookupProp"
                :FieldLabel="'Owner (Sample Users)'" :Rules="[$store.state.formrules.required]"
                /> 
            </v-card-text>
          </v-card>
          <!-- <v-tabs v-if="GroupTabs && GroupTabs.length > 0">
            <v-tab v-for="tab in GroupTabs" :key="tab.itemObjKey" class="socialmenufont--text"> {{tab.TabName}} </v-tab>
            <v-tab-item v-for="tab in GroupTabs" :key="tab.itemObjKey">
              <span v-if="tab.TabName === 'Wall'">
                <SocialSitePosts
                :UsersArray="UsersArray"
                :UserObj="RelatedGroupAdministrators[0]"
                :dark="AppisDarkMode"
                class="mx-3"
                :WallType="'Group'"
                :ShareName="GroupData.name"
                :UserRecord="UserRecord"
                :UserorGroupID="groupid"
                :UserorGroupView="true"
              />
              </span>
              <span v-else-if="tab.TabType === 'Group Component'">
                <v-btn
                  v-if="tab.NewPath && tab.CanCreate"
                  :to="tab.NewPath"
                  dark
                  fab
                  x-small
                  absolute
                  top
                  right
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <component
                  v-if="tab.CanView"
                  :ArticlesUpdateExternal="ArticlesUpdateExternal"
                  :ArticlesExternalfirebaseConfig="ArticlesExternalfirebaseConfig"
                  :ClientAppURL="ClientAppURL"
                  :ClientFireStore="ClientFireStore"
                  :GroupCategory="GroupCategory"
                  :logo="logo"
                  :is="'Group' + tab.Name"
                  :CanCreate="tab.CanCreate"
                  :CanView="CanView"
                  :RelatedObj="RelatedObj"
                  :UserRecord="UserRecord"
                  :UsersArray="UsersArray"
                  :System="System"
                  :UserisAdministrator="UserisAdministrator"
                >
                </component>
                <v-card
                  height="250"
                  v-if="!tab.CanView"
                  flat
                  class="transparent"
                >
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    You do not have access to the {{ tab.Name }} on the
                    {{ name }} Group
                  </v-row>
                </v-card>
              </span>
              <span v-else-if="tab.TabType === 'Group Page'">
                <LandingPageSingle :DataSingleTabs="tab.PageTabs"
              :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
              :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing"
              @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
              @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
              :EntityDataRef="EntityDataRef" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
              :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
              :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="'UpdateAccount'"
                @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
                :AppisDarkMode="AppisDarkMode" :CanEdit="true" :tab="{}"
                :DynamicWikiData="$route.params.slug? CurrentEntity : DynamicRecordData" :Business_Unit="Business_Unit"
                :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
                :ActivitiesArray="ActivitiesArray" :RADB="RADB"
                :PrimaryFieldName="PrimaryFieldName"
              :ActiveEntityID="$route.meta.EntityID"/>
              </span>
            </v-tab-item>
          </v-tabs> -->
          <SecondaryNavigation v-if="SecondaryNavigationActive" :style="'position: fixed;bottom: 50px;z-index: 5;'" :BottomNavigationItems="SecondaryNavigationItems" :ThemeColor="'socialmenufont'" :ThemeBGColor="'socialappnavbar'"
    :UsersArray="UsersStore" :CurrentRecordName="GroupData.name" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :BottomMenu="false" :TopMenu="true"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
        <SocialSitePosts v-if="MenuItem === 'Wall' && userLoggedIn"
                :UsersArray="UsersArray" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :UserMemberObj="UserMemberObj" :PostsbyApproval="PostsbyApproval"
                :UserObj="RelatedGroupAdministrators[0]"
                :dark="AppisDarkMode"
                class="mx-3"
                :WallType="'Group'"
                :ShareName="GroupData.name"
                :UserRecord="UserRecord"
                :UserorGroupID="groupid"
                :UserorGroupView="true"
              />
              <GroupRatingComponent v-if="MenuItem === 'Ratings' && userLoggedIn" :CanViewReplies="CanViewReplies"
                :Ratings="GroupRatings" :ItemName="GroupData.name" :CanWriteReview="!UserisTeamMember" @EmitReviewMethod="EmitReviewMethod" :CanSendMessage="!UserMemberObj"
                 @EmitSendMessageMethod="EmitSendMessageMethod"
              />
              <GroupInbox v-if="MenuItem === 'Group Inbox' && userLoggedIn"
                :UsersArray="UsersArray" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :UserMemberObj="UserMemberObj" :PostsbyApproval="PostsbyApproval"
                :UserObj="RelatedGroupAdministrators[0]"
                :dark="AppisDarkMode"
                class="mx-3" :GroupData="GroupData"
                :WallType="'Group'"
                :ShareName="GroupData.name"
                :UserRecord="UserRecord"
                :UserorGroupID="groupid"
                :UserorGroupView="true"
              />
            <LandingPageSingle :DataSingleTabs="ActivePage.PageTabs" v-if="MenuItem === 'Group Page' && ActivePage"
              :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
              :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing"
              @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
              @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
              :EntityDataRef="EntityDataRef" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
              :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
              :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="'UpdateAccount'"
                @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
                :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
                :DynamicWikiData="$route.params.slug? CurrentEntity : DynamicRecordData" :Business_Unit="Business_Unit"
                :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
                :ActivitiesArray="ActivitiesArray" :RADB="RADB"
                :PrimaryFieldName="PrimaryFieldName"
              :ActiveEntityID="$route.meta.EntityID"/>
              <component
                v-if="ActiveSocialScope && MenuItem === 'Social Scope'"
                :ArticlesUpdateExternal="ArticlesUpdateExternal"
                :ArticlesExternalfirebaseConfig="ArticlesExternalfirebaseConfig"
                :ClientAppURL="ClientAppURL"  :pushedgroupmembers="computedgroupmembers"
                :ClientFireStore="ClientFireStore"
                :GroupCategory="GroupCategory" :GroupData="GroupData"
                :logo="logo"
                :is="'Group' + ActiveSocialScope.Name"
                :CanCreate="ActiveSocialScope.CanCreate"
                :CanView="CanView"
                :RelatedObj="RelatedObj"
                :UserRecord="UserRecord"
                :UsersArray="UsersArray"
                 :System="System"
                :UserisAdministrator="UserisAdministrator"
              >
              </component>
          <v-tabs v-model="GroupTab" v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && userLoggedIn && !MenuItem">
             
           
            <v-tab v-if="UserCanViewPosts" class="socialmenufont--text"> Wall </v-tab>
            <v-tab-item v-if="UserCanViewPosts">
              <SocialSitePosts @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
              :UserMemberObj="UserMemberObj" :PostsbyApproval="PostsbyApproval"
                :UsersArray="UsersArray"
                :UserObj="RelatedGroupAdministrators[0]"
                :dark="AppisDarkMode"
                class="mx-3"
                :WallType="'Group'"
                :CanPost="UserCanPost"
                :ShareName="GroupData.name"
                :UserRecord="UserRecord"
                :UserorGroupID="groupid"
                :UserorGroupView="true"
              />
            </v-tab-item>
           
            <v-tab
              class="socialmenufont--text"
              v-for="page in GroupMenuPages"
              :key="page.itemObjKey"
            >
              {{ page.Name }}
            </v-tab>

            <v-tab-item
              class="tabsinner"
              v-for="page in GroupMenuPages"
              :key="page.itemObjKey"
            >
            <LandingPageSingle :DataSingleTabs="page.PageTabs"
              :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
              :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing"
              @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
              @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
              :EntityDataRef="EntityDataRef" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
              :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
              :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="'UpdateAccount'"
                @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
                :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
                :DynamicWikiData="$route.params.slug? CurrentEntity : DynamicRecordData" :Business_Unit="Business_Unit"
                :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
                :ActivitiesArray="ActivitiesArray" :RADB="RADB"
                :PrimaryFieldName="PrimaryFieldName"
              :ActiveEntityID="$route.meta.EntityID"/>
              <!-- {{page.PageTabs}} -->
            </v-tab-item>
            <v-tab
              class="socialmenufont--text"
              v-for="scope in MutatedAssignedGroupScope"
              :key="scope.itemObjKey"
            >
              {{ scope }}
            </v-tab>

            <v-tab-item
              class="tabsinner"
              v-for="scope in GroupScopePermitted"
              :key="scope.itemObjKey"
            >
              <!-- <v-btn :to="'NewBlog/'+groupid" v-if="UserisEditor && scope === 'Blogs'" dark color="#e4003b" 
                fab                
                x-small
                absolute
                top
                right ><v-icon>mdi-plus</v-icon></v-btn>  -->
              <!-- <v-btn
                v-if="scope.NewPath && scope.CanCreate"
                :to="scope.NewPath"
                dark
                fab
                x-small
                absolute
                top
                right
                ><v-icon>mdi-plus</v-icon></v-btn
              > -->
              <component
                v-if="scope.CanView"
                :ArticlesUpdateExternal="ArticlesUpdateExternal"
                :ArticlesExternalfirebaseConfig="ArticlesExternalfirebaseConfig"
                :ClientAppURL="ClientAppURL"  :pushedgroupmembers="computedgroupmembers"
                :ClientFireStore="ClientFireStore"
                :GroupCategory="GroupCategory" :GroupData="GroupData"
                :logo="logo"
                :is="'Group' + scope.Name"
                :CanCreate="scope.CanCreate"
                :CanView="CanView"
                :RelatedObj="RelatedObj"
                :UserRecord="UserRecord"
                :UsersArray="UsersArray"
                 :System="System"
                :UserisAdministrator="UserisAdministrator"
              >
              </component>
              <v-card
                height="250"
                v-if="!scope.CanView"
                flat
                class="transparent"
              >
                <v-row class="fill-height ma-0" align="center" justify="center">
                  You do not have access to the {{ scope.Name }} on the
                  {{ name }} Group
                </v-row>
              </v-card>
            </v-tab-item>
            <!-- <v-tab class="socialmenufont--text"> Ratings </v-tab>
            <v-tab-item >
              <GroupRatingComponent 
                :Ratings="GroupRatings" :ItemName="GroupData.name" :CanWriteReview="!UserisTeamMember" @EmitReviewMethod="EmitReviewMethod" :CanSendMessage="!UserMemberObj"
                 @EmitSendMessageMethod="EmitSendMessageMethod"
              />
            </v-tab-item>
             <v-tab v-if="UserisTeamMember" class="socialmenufont--text"> Group Inbox </v-tab>
            <v-tab-item >
              <GroupInbox v-if="UserisTeamMember"
                :UsersArray="UsersArray" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :UserMemberObj="UserMemberObj" :PostsbyApproval="PostsbyApproval"
                :UserObj="RelatedGroupAdministrators[0]"
                :dark="AppisDarkMode"
                class="mx-3" :GroupData="GroupData"
                :WallType="'Group'"
                :ShareName="GroupData.name"
                :UserRecord="UserRecord"
                :UserorGroupID="groupid"
                :UserorGroupView="true"
              />
            </v-tab-item> -->
          </v-tabs>

          <!-- <v-expansion-panels
            background-color="#048abf"
            color="#ffffff"
            light
            v-if="UserhasAccess"
            class="hidden-md-and-up"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Blogs</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-dialog v-model="blogdialog">
                  <v-form ref="form" class="tabsinner">
                    <v-text-field
                      v-model="blogtitle"
                      label="Name"
                    ></v-text-field>
                    <v-textarea
                      v-model="blogcontent"
                      label="Content"
                    ></v-textarea>
                    <input
                      v-if="!image"
                      type="file"
                      @change="onFileselected($event)"
                      ref="fileInputer"
                    />
                    <v-btn color="#70cbf3" @click="Closedialog()">
                      Cancel
                    </v-btn>
                    <v-btn color="#e4003b" @click="saveBlog()"> Save </v-btn>
                  </v-form>
                </v-dialog>
                <v-btn
                  :to="'NewBlog/' + groupid"
                  v-if="UserisEditor"
                  dark
                  color="#e4003b"
                  fab
                  x-small
                  absolute
                  top
                  right
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <GroupBlogs />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Meetups</v-expansion-panel-header>

              <v-expansion-panel-content>
                <GroupBlogs :RelatedObj="RelatedObj" :UserRecord="UserRecord" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Events</v-expansion-panel-header>

              <v-expansion-panel-content>
                <GroupEvents
                  :RelatedObj="RelatedObj"
                  :UserRecord="UserRecord"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Forum</v-expansion-panel-header>

              <v-expansion-panel-content>
                <GroupBlogs :RelatedObj="RelatedObj" :UserRecord="UserRecord" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Members</v-expansion-panel-header>

              <v-expansion-panel-content>
                <GroupMembers :System="System"
                  :RelatedObj="RelatedObj"
                  :UserRecord="UserRecord" :pushedgroupmembers="computedgroupmembers"
                  :UserisAdministrator="UserisAdministrator"
                  :GroupCategory="GroupCategory"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>News</v-expansion-panel-header>
              <v-expansion-panel-content>
                <GroupBlogs :RelatedObj="RelatedObj" :UserRecord="UserRecord" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="Network">
              <v-expansion-panel-header
                >Network Groups</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <GroupBlogs :RelatedObj="RelatedObj" :UserRecord="UserRecord" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->
        </v-flex>
      </v-layout>
    </v-card>
    <v-row
      v-if="GroupForbidden"
      class="fill-height ma-0"
      align="center"
      justify="center"
    >
      <v-img alt="Access" src="@/assets/accessdeniedBG.png">
        <v-layout class="justify-center">
          <img alt="Access" src="@/assets/access_denied.png" />
        </v-layout>
        <v-layout class="justify-center">
          <v-card flat color="rgb(255, 255, 255, 0.05)">
            <h1>
              <v-icon x-large color="red">mdi-account-alert</v-icon>You do not
              have the permissions to view this page
            </h1>
            <v-layout class="justify-center">
              <h1>
                <span>{{System.Name.substr(0,System.Name.indexOf(' '))}}</span><span v-if="System.Name.substr(System.Name.indexOf(' ')+1)" class="font-weight-light"> {{System.Name.substr(System.Name.indexOf(' ')+1)}}</span>
              </h1>
            </v-layout>
            <v-layout class="justify-center">
              <p>
                To gain access, speak to your System Adminisrator
              </p> </v-layout
            ><br /><br />
            <v-layout class="justify-center">
              <p>Powered by</p>
              <h4>
                <span> Rapid</span><span class="font-weight-light">Apps©</span>
              </h4>
            </v-layout>
          </v-card>
        </v-layout>
      </v-img>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import format from "date-fns/format";
import axios from "axios";
import GroupBlogs from "@/components/SuitePlugins/Blogs/SiteBlogs";
import GroupMembers from "@/components/SuitePlugins/Groups/GroupMembers";
import GroupEvents from "@/components/SuitePlugins/Events/SiteEvents";
import GroupArticles from "@/components/SuitePlugins/Articles/SiteArticles";
import GroupForums from "@//components/SuitePlugins/Forums/SiteForums";
import GroupPolls from "@/components/SuitePlugins/Polls/SitePolls";
import GroupMeetups from "@/components/SuitePlugins/Meetups/SiteMeetups";
import SocialSharing from "@/components/SocialNetwork/SocialSharing";
import SocialSitePosts from "@/components/SocialNetwork/SocialSitePosts";
import GroupInbox from "@/components/SuitePlugins/Groups/GroupInbox";
import GroupRatingComponent from "@/components/WebPages/RenderComponents/RatingComponent";
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import SecondaryNavigation from '@/components/Navigation/BottomNavigation'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import UserLookup from '@/components/General/UserLookup'
export default {
  props: ['SiteModerators', 'FriendsList','System','Directories','SubscriptionPackages'],
  middleware: "auth",
  components: {
    SocialSharing,
    SocialSitePosts,
    GroupInbox,
    GroupRatingComponent,
    GroupBlogs,
    GroupEvents,
    GroupMembers,
    GroupForums,
    GroupArticles,
    GroupPolls,
    GroupMeetups,
    LandingPageSingle,
    SecondaryNavigation,
    ContentEditableField,
    UserLookup
  },
  data() {
    return {
      CalledMembers: false,
      GroupRatings: [],
      MessagefromUser: '',
      MessageGroupDialog: false,
      GroupRatingActive: false,
      GroupRating: 3,
      NewMessage: {
        recipientid: '',
        senderid: '',
        message: '',
        Created_On: '',
        Read: false
      },
      DefaultNewMessage: {
        recipientid: '',
        senderid: '',
        message: '',
        Created_On: '',
        Read: false
      },
      ActivePage: '',
      ActiveSocialScope: '',
      MenuItem: '',
      ActiveRoute: {Name: 'GroupSingle',Topic: 'Site Groups',Type: 'Single',Moderator: 'Groups',Single: 'Group',HasList: true, ListPath: '/Groups',HasEdit: true,EditMethod: 'EditGroup',HasShareList: true,ShareNotice: true,ShareMethod: 'ShareBlog',},
      GroupTab: 0,
      PaymentDoc: '',
      GotPages: false,
      aboutdialog: false,
      GroupData: "",
      GroupPages: [],
      ShareItemDialog: false,
      InternalLinkType: "Group",
      LinkComponent: "SiteGroupsShared",
      LinkID: this.$route.params.id,
      SharedCollection: "groups",
      NoticeCoverIMGProp: "logo",
      UserInvite: "",
      Invitees: [],
      ClientFireStore: "",
      ClientAppURL: "",
      ArticlesUpdateExternal: false,
      ArticlesExternalfirebaseConfig: "",
      BlogPrivacy: null,
      EventsPrivacy: null,
      ArticlesPrivacy: null,
      PollsPrivacy: null,
      MeetupsPrivacy: null,
      ForumPrivacy: null,
      RelatedObj: {},
      AssignedGroupScope: [],
      GroupScopeOptions: [
        {
          DisplayName: "Articles",
          Path: "/Articles",
          Boolean: true,
        },
        {
          DisplayName: "Blogs",
          Path: "/Blogs",
          Boolean: true,
        },
        {
          DisplayName: "Classifieds",
          Path: "/Classifieds",
          Boolean: true,
        },
        {
          DisplayName: "Events",
          Path: "/Events",
          Boolean: true,
        },
        {
          DisplayName: "Forums",
          Path: "/Forums",
          Boolean: true,
        },
        // {
        // DisplayName: 'Groups',
        // Path: '/Groups',
        // Boolean: true,
        // },
        {
          DisplayName: "Meetups",
          Path: "/Meetups",
          Boolean: true,
        },
        {
          DisplayName: "Polls",
          Path: "/Polls",
          Boolean: true,
        },
      ],
      PrivacyTypes: [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
        { index: 4, text: "All Site Members" },
        { index: 5, text: "Public" },
      ],
      snackbar: false,
      Network: false,
      GroupisPrivate: false,
      GroupisMembersOnly: false,
      GroupCategory: null,
      //userid: this.$store.state.user.id,
      UserisEditor: false,
      
      UserisMember: false,
      UserhasAccess: false,
      blogdialog: false,
      selectedfile: null,
      selectedGroupImage: null,
      GroupImagelocalURL: null,
      groupblogs: [],
      groups: [],
      content: null,
      logo: "",
      image: "",
      Description: null,
      GroupPrivacy: null,
      WhocanJoin: null,
      MembershipbyApproval: false,
      PostPrivacy: '',
      WhocanPost: '',
      PostsbyApproval: false,
      GroupisNetworkValidated: false,
      EndorsingNetwork: null,
      DefaultPage: {
          Name: 'Wall',
          Type: 'Social Page'
        },
      name: null,
      editedItem: {
        name: null,
        content: null,
        Description: null,
        image: null,
      },
      defaultItem: {
        name: null,
        content: null,
        Description: null,
        image: null,
      },
      blogtitle: null,
      blogcontent: null,
      image: null,
      UserRecord: {},
    };
  },
  watch: {
    ActiveGroup: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.getGroupInformation()
                }
            },
            deep: true
    },
    GroupTabs: {
            handler: function(newvalue, oldvalue) {      
              if(this.GroupTabs && this.GroupTabs.length > 0){
               this.$emit('UpdateGroupNavigationalItems',this.GroupTabs)             
              }
            },
            deep: true
        },
    UserisAdministrator(value) {
      if (value && value === true) {
        this.GetAdminBackend();
         this.$store.commit('SetCurrentItemModerator',value)  
      }
    },
    SocialItemInteractMethod(v){  
            if(v){
              if(this.SocialItemInteractMethodProp){
                //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
                this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
                this.$store.commit('SetSocialItemInteractMethodProp','') 
                this.$store.commit('SetSocialItemInteractMethod','')
              }
              else{ 
                this[this.SocialItemInteractMethod]()
                this.$store.commit('SetSocialItemInteractMethodProp','') 
                this.$store.commit('SetSocialItemInteractMethod','')
              }          
            }
          }
  },
  computed: {
    GroupRoot(){
      return this.ActiveTemplate ? '' :
      db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
    },
    GroupDB(){
      return this.ActiveTemplate ? '' :
      this.GroupRoot.collection('groups').doc(this.$route.params.id)
    },
    TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveMarketTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
    UserisTemplateOwner(){
        return this.ActiveMarketTemplate && this.userLoggedIn && this.ActiveMarketTemplate.Ownerid === this.userLoggedIn.id
      }, 
    ActiveMarketTemplate(){
      return this.$store.state.ActiveMarketTemplate
    },
    ActiveSuiteTemplate(){
        return this.$store.state.ActiveSuiteTemplate
    },
    ConfigPath(){
      return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
    },
    SelectedImage(){
      return this.$store.state.SelectedGalleryAsset
    },
    GroupMenuPages(){
      return this.GroupData.Pages ? this.GroupData.Pages.map(page => {
        let menuitems = []
        if(this.GroupData.GroupNavbarItems){
          menuitems = this.GroupData.GroupNavbarItems
        }
        let menuentry = menuitems.find(obj => obj.Path === page.PageRoute)
        if(menuentry){
          page.MenuIndex = menuentry.MenuIndex
        }
        return page
      }).filter(page => {
        return typeof page.MenuIndex !== 'undefined' && page.MenuIndex !== 100000
      }).sort((a, b) => {
                var key1 = a.MenuIndex;
                var key2 = b.MenuIndex;

                if (key1 < key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
        })
        : []
    },
    groupmembers(){
            return this.ActiveGroup && this.ActiveGroup.GroupMembers ? this.ActiveGroup.GroupMembers : []
        },
    CanViewReplies(){
      return true
    },
    ShowGroupsasPortal(){
      return !this.System.GroupSinglePortal
    },
    groupid(){
      return this.ActiveMarketTemplate ? this.$route.params.slug : this.$route.params.id
    },
    GroupCatNavlist(){
      return this.Directories.find(obj => obj.id === 'Group_Categories')
    },
    computedgroupmembers(){
      return this.groupmembers.filter(member => {
        //console.log('member',member)
        return this.UserisAdministrator ? member : member.Status === 'Active'
      })
    },
    UserCanViewPosts(){
      //console.log('this.PostPrivacy',this.PostPrivacy)
      if(this.PostPrivacy === 'Team Members Only'){
          return this.UserisTeamMember
        }
      else if(this.PostPrivacy === 'Members Only'){
        return this.UserisActiveMember
      }
      else if(this.PostPrivacy === 'Non Guest Site Members'){
        return this.userLoggedIn && !this.$store.state.UserisGuest
      }
      else if(this.PostPrivacy === 'All Site Members'){
        return this.userLoggedIn 
      }
       else if(this.PostPrivacy === 'Public'){
        return true
      }
    },
    UserCanPost(){
      if(this.UserCanViewPosts){
        if(this.WhocanPost === 'Team Members Only'){
          return this.UserisTeamMember
        }
        else if(this.WhocanPost === 'Members Only'){
          return this.UserisActiveMember
        }
        else if(this.WhocanPost === 'Non Guest Site Members'){
        return this.userLoggedIn && !this.$store.state.UserisGuest
        }
        else if(this.WhocanPost === 'All Site Members'){
          return this.userLoggedIn 
        }
      }
    },
    UserCanJoin(){
      if(this.WhocanJoin === 'Non Guest Site Members'){ 
        return this.userLoggedIn && !this.$store.state.UserisGuest && !this.UserisActiveMember && !this.UserisPendingMember
      }
      else if(this.WhocanJoin === 'All Site Members'){ 
        return this.userLoggedIn && !this.UserisActiveMember && !this.UserisPendingMember
      }
      //WhocanJoin can be Invite Only, Non Guest Site Members, All Site Members
      //return this.WhocanJoin && !this.UserisActiveMember && this.WhocanJoin !== 'Invite Only' && this.GroupData && this.GroupData.GroupPublishStatus === 'Published'
    },
    UserCanJoinviaInvite(){
      return this.UserInvite && this.UserInvite.Status && this.UserInvite.Status.ID === 1000001
      //return this.UserInvite && this.UserInvite.Status && this.UserInvite.Status.ID === 1000001
    },
    UserDBNavList(){
      if(this.userLoggedIn && this.userLoggedIn.DBNavList){
        //console.log('this.userLoggedIn.DBNavList',this.userLoggedIn.DBNavList)
        let list = this.DBNavLists.find(obj => obj.id === this.userLoggedIn.DBNavList.id)
        if(list){
          return list
        }
      }
    },
    GroupPagesBottomNav(){
      //console.log('this.GroupData pages',this.GroupData,this.GroupData.Pages)
      return this.GroupMenuPages
      .map(page => {
       // console.log('page',page)
        let pageobj =  {
                title: page.Name,
                icon: page.PageIcon,
                ItemPass: true,
                Method: 'ActivateMenuItem',
                Prop: {MenuItem: 'Group Page',ActivePage: page},
                }
          return pageobj
      }) 
    },
    GroupScope(){
      
      return this.GroupScopePermitted.map(scope => {
        //console.log('scope',scope)
        let scopeobj =  {
                title: scope.Name,
                ItemPass: scope.CanView,
                Method: 'ActivateMenuItem',
                Prop: {MenuItem: 'Social Scope',ActiveSocialScope: scope},
                }
                if (scope.Name === "Blogs") {
                  scopeobj.icon = "mdi-typewriter";
                }
                if (scope.Name === "Classifieds") {
                  scopeobj.icon = "mdi-newspaper";
                }
                if (scope.Name === "Articles") {
                  scopeobj.icon = "mdi-email-newsletter";
                }
                if (scope.Name === "Polls") {
                  scopeobj.icon = "mdi-vote";
                }
                if (scope.Name === "Forums") {
                  scopeobj.icon = "mdi-forum";
                }
                if (scope.Name === "Events") {
                  scopeobj.icon = "mdi-calendar-star";
                }
                if (scope.Name === "Meetups") {
                  scopeobj.icon = "mdi-account-supervisor-circle";
                }
                if (scope.Name === "Members") {
                  scopeobj.icon = "mdi-account-group";
                  //scopeobj.CanView = this.CalledMembers
                }
          return scopeobj
      })
    },
    GroupCenterItems(){
      //console.log('this.GroupPagesBottomNav',this.GroupPagesBottomNav)
      let items = []
      items.push({
      title: 'View Tabs',
      icon: 'mdi-cancel',
      ItemPass: this.MenuItem && !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm,
      Method: 'ActivateMenuItem',
      Prop: {MenuItem: ''},
      })
      items.push({
      title: 'View Ratings',
      icon: 'mdi-star-box',
      ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage',
      Method: 'ActivateMenuItem',
      Prop: {MenuItem: 'Ratings'},
      })
      
      if(this.UserCanViewPosts){
        items.push({
      title: 'Wall',
      icon: 'mdi-post',
      ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage',
      Method: 'ActivateMenuItem',
      Prop: {MenuItem: 'Wall'},
      })
      }
      if(this.UserisTeamMember){
        
        items.push({
        title: 'Group Inbox',
        icon: 'mdi-post',
        ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage',
        Method: 'ActivateMenuItem',
        Prop: {MenuItem: 'Group Inbox'},
        })
      }
      
      
      items = items.concat(this.GroupPagesBottomNav,this.GroupScope)
      return items
    },
    SecondaryNavigationItems(){
      //Wall
      //Pages
      //MutatedAssignedGroupScope
      //GroupScopePermitted
      let centeritem = {
              title: 'Group Menu',
              icon: 'mdi-menu',
              ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage',
              Children: this.GroupCenterItems
      }
      let items = [
          {
              title: 'Message',
              icon: 'mdi-message-reply-text-outline',
              ItemPass: !this.UserisTeamMember || this.ActiveMarketTemplate,
              Children: [
                {
                  title: 'Message',
                  icon: 'mdi-message-reply-text-outline',
                  ItemPass: !this.UserMemberObj || this.ActiveMarketTemplate,
                  Method: 'ActivateMessagetoGroup',
                  Prop: '',
                },
                {
                  title: 'Rate '+this.name,
                  icon: 'mdi-star-box',
                  ItemPass: !this.UserMemberObj || !this.UserisTeamMember || this.ActiveMarketTemplate,
                  Method: 'ActivateGroupRating',
                  Prop: '',
                },
              ]
            },
            
         {
              title: 'Edit',
              icon: 'mdi-pencil',
              ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage' && this.UserisAdministrator,
              Children: [
                {
                title: 'Edit '+this.ActiveRoute.Single,
                ItemPass: this.ActiveRoute.HasEdit && this.ActiveRoute.EditMethod || this.ActiveMarketTemplate,
                icon: 'mdi-pencil',  
                Method: 'EditGroup',
                Prop: '',            
                },
              ]
            },
            {
                title: 'All '+this.ActiveRoute.Topic,
                icon: 'mdi-view-list',
                ItemPass: this.ActiveRoute.HasList && !this.ActiveRoute.DropdownList && this.ActiveRoute.ListPath || this.ActiveMarketTemplate,
                IsRoute: true,                    
                Path: this.ActiveRoute.ListPath
              },
            centeritem,
            {
              title: 'Social',
              icon: 'mdi-home-group',
              ItemPass: true,
              Children: [
                {title: 'Glossary',IsRoute: true, Path: '/Glossary',icon: 'mdi-file-word',ItemPass: true},
                {title: 'Members',IsRoute: true,Path: '/SiteMembers',Boolean: false,icon: 'mdi-tag-faces',ItemPass: true},
                {title: 'Articles',IsRoute: true,Path: '/Articles',Boolean: true,icon: 'mdi-email-newsletter',ItemPass: this.PluginDataBase.Site_Articles || this.ActiveMarketTemplate},
                {title: 'Blogs',IsRoute: true,Path: '/Blogs',icon: 'mdi-typewriter',Boolean: true,ItemPass: this.PluginDataBase.Site_Blogs},
                {title: 'Classifieds',IsRoute: true,Path: '/Classifieds',icon: 'mdi-newspaper',Boolean: true,ItemPass: this.PluginDataBase.Classifieds || this.ActiveMarketTemplate},
                {title: 'Events',IsRoute: true,Path: '/Events',icon: 'mdi-calendar-star',Boolean: true,ItemPass: this.PluginDataBase.Site_Events || this.ActiveMarketTemplate},
                {title: 'Forums',IsRoute: true,Path: '/Forums',icon: 'mdi-forum',Boolean: true,ItemPass: this.PluginDataBase.Site_Forum || this.ActiveMarketTemplates},
                {title: 'Groups',IsRoute: true,Path: '/Groups',Boolean: true,icon: 'mdi-account-group',ItemPass: this.PluginDataBase.Site_Groups || this.ActiveMarketTemplate},
                {title: 'Meetups',IsRoute: true,Path: '/Meetups',Boolean: true,icon: 'mdi-human-greeting-proximity',ItemPass: this.PluginDataBase.Site_Meetup || this.ActiveMarketTemplates}, 
                {title: 'Polls',IsRoute: true,Path: '/Polls',Boolean: true,icon: 'mdi-vote',ItemPass: this.PluginDataBase.Site_Polls || this.ActiveMarketTemplate},
                
              ]
              },
              {
              title: 'Share',
              icon: 'mdi-share-circle',
              ItemPass: this.ActiveRoute.HasShareList && this.ActiveRoute.ShareMethod && !this.ActiveMarketTemplate || this.ActiveRoute.HasShareList && this.ActiveRoute.ShareNotice && !this.ActiveMarketTemplate,
              Children: [
                {
                  title: 'Walls',
                  icon: 'mdi-post-outline',
                  ItemPass: true,
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: this.ActiveRoute.ShareMethod
                },
                {
                  title: 'Create Notice',
                  icon: 'mdi-clipboard-alert',
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'AddSocialItemtoNotice',
                  ItemPass: this.ActiveRoute.ShareNotice && this.UserisItemModerator('Notices')
                }
              ]
            },
      ]
      return items.map(item => {
        if(item.Children){
          item.Children = item.Children.filter(child => {
            return child.ItemPass
        })
        }
        return item
      }).filter(item => {
        return item.ItemPass
      })
    },
    PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
    SecondaryNavigationActive(){
      return this.GroupData
    },
    SocialItemInteractMethod(){
    return this.$store.state.SocialItemInteractMethod
    },
    SocialItemInteractMethodProp(){
      return this.$store.state.SocialItemInteractMethodProp
    },
    UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
    GroupBillingAccount(){
      if(this.GroupData.BillingAccount){
        return this.UserBillingAccounts.find(obj => obj.id === this.GroupData.BillingAccount.id)
      }
    },
    GroupSubscriptionPackage(){
      if(this.GroupBillingAccount){
        return this.SubscriptionPackages.find(obj => obj.id === this.GroupBillingAccount.Subscription_Packageid)
      }
    },
    WebPageBuilderAvailable(){
      return this.System.Group_Web_Page_Builder_Paid && this.GroupData && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Web Page Builder') || !this.System.Group_Web_Page_Builder_Paid
    },
    GroupDashboardAvailable(){
      return this.UserisActiveMember && this.UserMemberObj && this.UserMemberObj.IsTeamMember && this.TeamDashboardAvailable || this.UserisAdministrator
    },
    WebFormsAvailable(){
      return this.System.Group_Web_Forms_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Web Forms') || !this.System.Group_Web_Forms_Paid
    },
    TeamsAvailable(){
      return this.System.Group_Teams_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Group Teams') || !this.System.Group_Teams_Paid
    },
    TeamDashboardAvailable(){
      return this.System.Group_Team_Dashboard_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Team Dashboard') || !this.System.Group_Team_Dashboard_Paid
    },
    CustomEntitiesAvailable(){
      return this.System.Group_Custom_Entities_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Custom Entities') || !this.System.Group_Custom_Entities_Paid
    },
    CustomDashboardsAvailable(){
      return this.System.Group_Custom_Dashboards_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Custom Dashboards') || !this.System.Group_Custom_Dashboards_Paid
    },
    userIsMonetizationOfficer(){
      return this.userIsAdmin || this.userLoggedIn && this.userLoggedIn.MonetizationOfficer
    },
    
    ComputedGroupCategory(){
      if(this.GroupData && this.GroupData.GroupCategory){
        console.log('this.GroupData.GroupCategory',this.GroupData.GroupCategory)
        return this.System && this.System.Group_Categories && this.System.Group_Categories.Options.find(opt => opt.ID === this.GroupData.GroupCategory.ID)
      }      
    },
    SlugTab(){
      if(this.$route.params.slug && !this.ActiveMarketTemplate){
        let tab = this.GroupTabs.find(obj => obj.TabName === this.$route.params.slug)
        if(tab){
          return tab
        }
        else{
          return this.GroupScopePermitted.find(obj => obj.TabName === this.$route.params.slug)
        }
      }
      else if(this.ActiveMarketTemplate){

      }
    },
    GroupTabs(){
      if(this.GroupData && this.GroupData.Pages && this.userLoggedIn){
        let arr1 = this.GroupData.Pages.map(page => {
          let obj = Object.assign({},page)
          obj.TabName = page.Name
          obj.TabType = 'Group Page'
          return obj
        })
        //console.log(arr1)
        let socialobj = {
            TabName: 'Social',
            TabType: 'Group Components',
            SubItems: this.GroupScopePermitted 
          }
        let arr2 = [socialobj]
        //console.log(arr2)
        let arr3 = [
          
        ]
        let wallobj = {
            TabName: 'Wall',
            TabType: 'Social Page'
          }
          if(this.UserCanViewPosts){
            arr3.push(wallobj)
          }
          if(this.UserisTeamMember){
            
            arr3.push({
            title: 'Group Inbox',
            icon: 'mdi-post',
            ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage',
            Method: 'ActivateMenuItem',
            Prop: {MenuItem: 'Group Inbox'},
            })
          }
          
        //console.log('arr3',arr3)
        let arr4 = arr1.concat(arr3,arr2)
        //console.log(arr4)
        let defpage = arr4.find(obj => obj.Name === this.DefaultPage.Name)
        let defindex = arr4.indexOf(defpage)
        arr4.splice(defindex,1)
        arr4.unshift(defpage)
        return arr4
      }
      else if(this.GroupData && this.GroupData.Pages && !this.userLoggedIn){
        let GroupNavbarItems = []
        if(this.GroupData.GroupNavbarItems){
          GroupNavbarItems = this.GroupData.GroupNavbarItems
        }
        return GroupNavbarItems.map(pagemenu => {          
          let page = this.GroupData.Pages.find(obj => obj.Name === pagemenu.title)          
          let obj = Object.assign({},page)
          obj.TabName = page.Name
          obj.TabType = 'Group Page'
          return obj
        })
      }
      else{
        return []
      }
      
    },
    UsersArray() {
      return this.$store.state.UsersArray;
    },
    userisContentAdmin() {
      return this.userIsAdmin;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    UserCanViewBlogs() {
      if (this.BlogPrivacy === "Members Only") {
        if (this.UserisNonMember === false) {
          return true;
        } else {
          return false;
        }
      }
    },

    UserisNonMember() {
      if (
        this.UserisInactiveMember === false &&
        this.UserisActiveMember === false
      ) {
        return true;
      } else {
        return false;
      }
    },
    UserisInactiveMember() {
      return this.UserMemberObj && this.UserMemberObj.Status !== 'Active'
    },
    UserMemberObj(){
      return this.$store.state.UserMemberObj
    },
    UserisTeamMember(){
      return this.UserMemberObj && this.UserMemberObj.IsTeamMember || this.UserisAdministrator
    },
    UserisActiveMember() {
      return this.UserMemberObj && this.UserMemberObj.Status === 'Active'
    },
    UserisPendingMember() {
      //console.log('UserMemberObj',this.UserMemberObj)
      return this.UserMemberObj && this.UserMemberObj.Status === 'Pending Approval'
    },
    UserisAdministrator() {
      //console.log('this.ActiveGroup',this.ActiveGroup)
      return this.userLoggedIn && this.GroupData && this.GroupData.Administrators.includes(this.userLoggedIn.id) || 
      this.userLoggedIn && this.ActiveGroup && this.ActiveGroup.Administrators.includes(this.userLoggedIn.id)
    },
    GroupForbidden(){
      return this.$store.state.GroupForbidden
    },
    ActiveGroup(){
            return this.$store.state.ActiveGroup
        },
    UserisBlogsModerator() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.BlogsModerator;
      } else {
        return false;
      }
    },
    UserisArticlessModerator() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.ArticlesModerator;
      } else {
        return false;
      }
    },
    UserisForumsModerator() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.ForumsModerator;
      } else {
        return false;
      }
    },
    UserisEventsModerator() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.EventsModerator;
      } else {
        return false;
      }
    },

    UserisMeetupsModerator() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.MeetupsModerator;
      } else {
        return false;
      }
    },
    UserisPollsModerator() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.PollsModerator;
      } else {
        return false;
      }
    },
    UserMembershipid() {
      let memberobj = this.groupmembers.find(
        (obj) => obj.Userid === this.UserRecord.id
      );
      if (memberobj) {
        return memberobj.id;
      }
    },
    DisplayGroupScopeOptions(){
      return this.GroupScopeOptions.map(item => {
        return item.DisplayName
      })
    },
    MutatedAssignedGroupScope() {
      let arr1 = this.ActiveMarketTemplate ? this.DisplayGroupScopeOptions : this.AssignedGroupScope;
      let arr2 = ["Members"];
      return arr1.concat(arr2);
    },
    GroupScopePermitted() {
      return this.MutatedAssignedGroupScope.map((scope) => {
        let obj = {
          Name: scope,
          CanView: false,
          CanCreate: false,
          TabName: scope,
          TabType: 'Group Component'
        };
        if(this.ActiveMarketTemplate){
          obj.CanView = true
          if(this.UserisTemplateOwner){
            //save ourselves the time this is enough!
            //obj.CanCreate = true
          }
        }
        if (scope === "Members") {
          obj.CanView = true;
        }
        if (this.UserisAdministrator) {
          obj.CanView = true;
          obj.CanCreate = true;
        }
        if (scope === "Blogs") {
          obj.icon = "mdi-newspaper";
        }
        if (scope === "Articles") {
          obj.icon = "mdi-newspaper";
        }
        if (scope === "Polls") {
          obj.icon = "mdi-vote";
        }
        if (scope === "Forums") {
          obj.icon = "mdi-forum";
        }
        if (scope === "Events") {
          obj.icon = "mdi-calendar";
        }
        if (scope === "Meetups") {
          obj.icon = "mdi-account-supervisor-circle";
        }
        if (scope === "Members") {
          obj.icon = "mdi-account-group";
        }
        if (scope === "Blogs") {
          obj.NewPath = "NewBlog/" + this.groupid;
          if (this.UserisBlogsModerator) {
            obj.CanView = true;
            obj.CanCreate = true;
          } else if (this.BlogPrivacy === "Team Members Only") {
            obj.CanView = this.UserisTeamMember;
          } else if (this.BlogPrivacy === "Members Only") {
            if (this.UserisActiveMember === true) {
              obj.CanView = true;
            } else {
              return obj;
            }
          } else if (this.BlogPrivacy === "All Site Members") {
            obj.CanView = true;
          } else if (this.BlogPrivacy === "Non Guest Site Members") {
            obj.CanView = this.userLoggedIn && !this.$store.state.UserisGuest;
          } else if (this.BlogPrivacy === "Public") {
            obj.CanView = true;
          }
        } else if (scope === "Meetups") {
          obj.NewPath = "Group-Meetup/" + this.groupid;
          if (this.UserisMeetupsModerator) {
            obj.CanView = true;
            obj.CanCreate = true;
          } else if (this.MeetupsPrivacy === "Team Members Only") {
            obj.CanView = this.UserisTeamMember;
          } else if (this.MeetupsPrivacy === "Members Only") {
            if (this.UserisActiveMember === true) {
              obj.CanView = true;
            } else {
              return obj;
            }
          } else if (this.ArticlesPrivacy === "All Site Members") {
            obj.CanView = true;
          } else if (this.BlogPrivacy === "Non Guest Site Members") {
            obj.CanView = this.userLoggedIn && !this.$store.state.UserisGuest;
          } else if (this.ArticlesPrivacy === "Public") {
            obj.CanView = true;
          }
        } else if (scope === "Articles") {
          obj.NewPath = "NewArticle/" + this.groupid;
          if (this.UserisArticlessModerator) {
            obj.CanView = true;
            obj.CanCreate = true;
          } else if (this.ArticlesPrivacy === "Team Members Only") {
            obj.CanView = this.UserisTeamMember;
          } else if (this.ArticlesPrivacy === "Members Only") {
            if (this.UserisActiveMember === true) {
              obj.CanView = true;
            } else {
              return obj;
            }
          } else if (this.ArticlesPrivacy === "Non Guest Site Members") {
            obj.CanView = this.userLoggedIn && !this.$store.state.UserisGuest;
          } else if (this.ArticlesPrivacy === "All Site Members") {
            obj.CanView = true;
          } else if (this.ArticlesPrivacy === "Public") {
            obj.CanView = true;
          }
        } else if (scope === "Forums") {
          if (this.UserisForumsModerator) {
            obj.CanView = true;
            obj.CanCreate = true;
          } else if (this.ForumPrivacy === "Team Members Only") {
            obj.CanView = this.UserisTeamMember;
          } else if (this.ForumPrivacy === "Members Only") {
            if (this.UserisActiveMember === true) {
              obj.CanView = true;
            } else {
              return obj;
            }
          } else if (this.ForumPrivacy === "Non Guest Site Members") {
            obj.CanView = this.userLoggedIn && !this.$store.state.UserisGuest;
          } else if (this.ForumPrivacy === "All Site Members") {
            obj.CanView = true;
          } else if (this.ForumPrivacy === "Public") {
            obj.CanView = true;
          }
        } else if (scope === "Polls") {
          obj.NewPath = "NewPoll/" + this.groupid;
          if (this.UserisPollsModerator) {
            obj.CanView = true;
            obj.CanCreate = true;
          } else if (this.PollsPrivacy === "Team Members Only") {
            obj.CanView = this.UserisTeamMember;
          } else if (this.PollsPrivacy === "Members Only") {
            if (this.UserisActiveMember === true) {
              obj.CanView = true;
            } else {
              return obj;
            }
          } else if (this.PollsPrivacy === "Non Guest Site Members") {
            obj.CanView = this.userLoggedIn && !this.$store.state.UserisGuest;
          } else if (this.PollsPrivacy === "All Site Members") {
            obj.CanView = true;
          } else if (this.PollsPrivacy === "Public") {
            obj.CanView = true;
          }
        } else if (scope === "Events") {
          if (this.UserisEventsModerator) {
            obj.CanView = true;
            obj.CanCreate = true;
          } else if (this.EventsPrivacy === "Team Members Only") {
            obj.CanView = this.UserisTeamMember;
          } else if (this.EventsPrivacy === "Members Only") {
            if (this.UserisActiveMember === true) {
              obj.CanView = true;
            } else {
              return obj;
            }
          } else if (this.EventsPrivacy === "Non Guest Site Members") {
            obj.CanView = !this.$store.state.UserisGuest;
          } else if (this.EventsPrivacy === "All Site Members") {
            obj.CanView = true;
          } else if (this.EventsPrivacy === "Public") {
            obj.CanView = true;
          }
        }

        return obj;
      });
    },
    RelatedBlogs() {
      return this.groupblogs.filter((blog) => {
        return blog.Groupid === this.groupid;
      });
    },
    RelatedGroupMembers() {
      return this.groupmembers.filter((groupmember) => {
        return (
          groupmember.Groupid === this.groupid &&
          groupmember.Userid === this.UserRecord.id
        );
      });
    },
    RelatedGroupMembersLength() {
      let relatedgroupmembercheck = this.groupmembers.filter((groupmember) => {
        return (
          groupmember.Groupid === this.groupid &&
          groupmember.Userid === this.UserRecord.id
        );
      });
      return relatedgroupmembercheck.length;
    },
    RelatedGroupAdministrators() {
      return this.groupmembers.filter((groupmember) => {
        return (
          groupmember.Groupid === this.groupid &&
          groupmember.Userid === this.UserRecord.id &&
          groupmember.UserRole === "Administrator"
        );
      });
    },
    RelatedGroupAdministratorsLength() {
      let relatedgroupadmincheck = this.groupmembers.filter((groupmember) => {
        return (
          groupmember.Groupid === this.groupid &&
          groupmember.Userid === this.UserRecord.id &&
          groupmember.UserRole === "Administrator"
        );
      });
      return relatedgroupadmincheck.length;
    },
    RelatedGroupEditorsLength() {
      let relatedgroupadmincheck = this.groupmembers.filter((groupmember) => {
        return (
          groupmember.Groupid === this.groupid &&
          groupmember.Userid === this.UserRecord.id &&
          groupmember.UserRole === "Editor"
        );
      });
      return relatedgroupadmincheck.length;
    },
    UserRelationshipTypeObject() {
      let usertype = this.RelatedGroupMembers.map((groupmember) => {
        return groupmember.UserRole;
      });
      return usertype;
    },
    StringUserType() {
      let stringarray = this.UserRelationshipTypeObject.join(",");
      let objectstring = "" + stringarray;
      return objectstring;
    },
    GroupMembersList() {
      return this.groupmembers.filter((groupmember) => {
        return groupmember.groupid === this.groupid;
      });
    },
    UserGrouprelationship() {
      return this.GroupMembersList.filter((groupmember) => {
        return groupmember.UserRecord.id === this.UserRecord.id;
      });
    },

    UserMemberCheck() {
      let filteredgroupmemberusers = this.UserGrouprelationship.map(
        (thisgroupmembers) => {
          return thisgroupmembers.UserRecord.id;
        }
      );
      return filteredgroupmemberusers;
    },
  },
  created() {
    if(this.userLoggedIn && this.UserCanViewPosts || this.ActiveMarketTemplate){
      this.MenuItem = 'Wall'
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    //console.log(this.groupid);
    //     this.FocusedViewToggle()
    if(!this.ActiveMarketTemplate){
      this.IntranetViewToggle();
      this.GetRequestingUser(); 
    }
    else{
      ///now what?
      this.ActiveRoute = {Name: 'MarketplaceTemplateGroupSingle',Topic: 'Site Groups',Type: 'Single',Moderator: 'Groups',Single: 'Group',HasList: true, ListPath: '/MarketplaceTemplate/'+this.$route.params.id+'/Groups',HasEdit: true,EditMethod: 'EditGroup',HasShareList: true,ShareNotice: true,ShareMethod: 'ShareBlog',}
      this.LinkID = this.groupid
      this.GetTemplateGroup(this.ConfigPath.collection('samplegroups').doc(this.$route.params.slug))
    }
    

  },
  methods: {
    UpdateUserLookupProp(Prop,Value,RecordObj){
        RecordObj[Prop] = Value
        if(this.ActiveMarketTemplate && Prop === 'Owner'){
          this.ConfigPath.collection('samplegroups').doc(this.$route.params.slug).update({
            Owner: Value,
            Ownerid: Value.id
          })
        }
    },
    OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
    GetTemplateGroup(dbref){
      dbref.onSnapshot(snapshot => {
        let groupdata = snapshot.data()
        this.UserRecord = this.userLoggedIn
        this.GroupData = groupdata
        this.GroupData.id = this.$route.params.slug
        this.AssignGroupProperties()
      })
    },
    AssignSocialItemInteractMethod(item,SecondProp){
      //console.log('AssignSocialItemInteractMethod',item,SecondProp)
      this.$store.commit('SetSocialItemInteractMethodProp','') 
      this.$store.commit('SetSocialItemInteractMethod','')
      if(SecondProp){
        this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
      else if(item){
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
    },
    EmitReviewMethod(){
      this.ActivateGroupRating()
    },
    EmitSendMessageMethod(){
      this.ActivateMessagetoGroup()
    },
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        //console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        //console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
    SendMessagetoGroup(){
      let NewMessage = Object.assign({},this.NewMessage)
      NewMessage.Created_On = new Date()
      if(this.GroupRatingActive){
        NewMessage.IsGroupRating = true
        NewMessage.Rating = this.GroupRating
      }
       db.collection('usermessages').add(NewMessage).then(doc => {
          this.DeactivateMessageGroupDialog()         
       })
    },
    ActivateGroupRating(){
      this.GroupRatingActive = true
      this.ActivateMessagetoGroup()
    },
    ActivateMessagetoGroup(){      
      this.DefaultNewMessage.recipientid = this.groupid,
      this.DefaultNewMessage.senderid = this.userLoggedIn.id,
      this.DefaultNewMessage.groupid = this.GroupData.id,
      this.DefaultNewMessage.groupname = this.name,
      this.DefaultNewMessage.grouplogo = this.GroupData.logo,
      this.NewMessage.recipientid = this.groupid,
      this.NewMessage.senderid = this.userLoggedIn.id,
      this.NewMessage.groupid = this.GroupData.id,
      this.NewMessage.groupname = this.name,
      this.NewMessage.grouplogo = this.GroupData.logo,
      this.NewMessage.GroupSender = false
      this.MessageGroupDialog = true
    },
    DeactivateMessageGroupDialog(){
      this.MessageGroupDialog = false
      this.GroupRatingActive = false
      this.GroupRating = 3
      this.NewMessage = Object.assign({},this.DefaultNewMessage)
    },
    ActivateMultipleFilesUploadNotification(boolean){
      this.$emit('ActivateMultipleFilesUploadNotification',boolean)
    },
    ActivateMenuItem(item){
      if(item.MenuItem === 'Group Page'){
        this.ActivePage = item.ActivePage
      }
      else if(item.ActiveSocialScope){
        this.ActivePage = ''
        this.ActiveSocialScope = item.ActiveSocialScope
      }
      else{
        this.ActivePage = ''
        this.ActiveSocialScope = ''
      }
      this.MenuItem = item.MenuItem
    },
    UserisItemModerator(item){
      return this.userLoggedIn && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.includes(item) || this.userIsAdmin
    },
    SelectBottomNavMenuItem(item){
      if(!item.Children && !item.Method && !item.RouteBlank){
        this.$router.push(item.Path)
      }
      else if(item.Method){
        if(item.Method === 'IntranetViewToggle' && item.Prop === true){
          this[item.Method](item.Prop,true)
        }
        else if(item.SecondProp){
          this[item.Method](item.Prop,item.SecondProp)
        }
        else{
          this[item.Method](item.Prop)
        }
      }
    },
    updateGroupPublishStatus(newstatus,previousstatus){
      this.GroupDB.update({
          GroupPublishStatus: newstatus,
          PreviousStatus: previousstatus
        })
     
    },
    AddSocialItemtoNotice() {
      let socialitem = {
        LinkID: this.LinkID,
        InternalLinkType: this.InternalLinkType,
        ComponentName: this.LinkComponent,
        collectionname: this.SharedCollection,
        IsLink: true,
        InternalLink: true,
      };
      if (this[this.NoticeCoverIMGProp]) {
        socialitem.coverimage = this[this.NoticeCoverIMGProp];
      }
      this.$router.push({
        name: "noticenew",
        params: { NoticeItem: socialitem },
      });
    },
    ShareBlog() {
      this.ShareItemDialog = true;
      //this.CreatePost()
    },
    CancelShareItemDialog() {
      this.ShareItemDialog = false;
    },
    IgnoreInvite() {
      confirm(
        "This will Cancel your invite and you will no longer have any access to the Invitation. Are you sure you want to continue?"
      ) && this.ProcessIgnoreInvite();
    },
    ProcessIgnoreInvite() {
      this.GroupDB
        .collection("invites")
        .doc(this.UserRecord.id)
        .update({
          Status: { Name: "Ignored", ID: 1000003 },
        });
        let invites = this.$store.state.InvitedGroups
        let match = invites.find(obj => obj.id === this.groupid)
        if(match){
          match.Responded = true
          this.$store.commit('SetInvitedGroups',invites)
          //console.log('this.$store.state.InvitedGroups',invites,this.$store.state.InvitedGroups)
        }
    },
    GetAdminBackend() {
     

     this.GroupDB
        .collection("adminonly")
        .doc("backendconfig")
        .onSnapshot((config) => {
          let configdata = config.data();
          //console.log(configdata);
          if (configdata) {
            if (configdata.ArticlesUpdateExternal) {
              this.ArticlesUpdateExternal = configdata.ArticlesUpdateExternal;
            }
            if (configdata.ArticlesExternalfirebaseConfig) {
              this.ArticlesExternalfirebaseConfig =
                configdata.ArticlesExternalfirebaseConfig;
            }
            if (configdata.ClientFireStore) {
              this.ClientFireStore = configdata.ClientFireStore;
            }
            if (configdata.ClientAppURL) {
              this.ClientAppURL = configdata.ClientAppURL;
            }
          }
        });
    },
    EditGroup() {
      //console.log(this.groupid,this.groupmembers,this.UserMembershipid)
      this.$router.push({
        name: "GroupEdit",
        params: {
          id: this.groupid,
          groupmembers: this.groupmembers,
          UserMembershipid: this.UserMembershipid,
        },
      });
    },
    async GetRequestingUser() {
      let vm = this;
      if (this.userLoggedIn) {
        this.UserRecord = this.userLoggedIn;
        if(this.ActiveGroup){
          vm.getGroupInformation();
        }
        //
      } else {
        if(this.ActiveGroup){
          vm.getGroupInformation();
        }
        //console.log("nope the user is apprantely NOT signed in");
      }
    },
    IntranetViewToggle() {
      this.$emit("IntranetViewToggle", true);
    },
    FocusedViewToggle() {
      this.$emit("FocusedViewToggle", false);
    },
    closeGroupImage() {
      (this.GroupImagelocalURL = null), (this.selectedGroupImage = null);
    },
    onUploadGroupImageFile() {
      var storageRef = firebase
        .storage()
        .ref("Group/"+this.groupid+"/Group_Logos/"+new Date()+this.selectedGroupImage.name);
      var uploadTask = storageRef.put(this.selectedGroupImage);
      uploadTask
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          this.FileURL = url;

          this.GroupDB.update({
            logo: this.FileURL,
          });
          this.snackbar = true;
          this.closeGroupImage();
        });
    },
    waitForMe() {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve("Resolved"), 1000);
      });
    },
    
    ProcessMembershipStatusNEW(status) {
      const GroupMember = {
        Userid: this.UserRecord.id,
        Groupid: this.groupid,
        Follower: status,
        Administrator: false,
        BlogsModerator: false,
        ArticlesModerator: false,
        EventsModerator: false,
        PollsModerator: false,
        MeetupsModerator: false,
        ForumsModerator: false,
        UserRole: "Follower",
        Status: "Active",
        GroupPrivacy: this.GroupPrivacy,
        UserName: this.UserRecord.Full_Name,
      };
      GroupMember.MembershipDate = new Date();
      this.GroupRoot.collection("groupmembers")
        .doc(this.UserRecord.id)
        .collection("groups")
        .doc(this.groupid)
        .set(GroupMember)
        .then((doc) => {
          //console.log("yippee we did it");
        });
    },
    ProcessMembershipStatus(status, byinvite) {
      //console.log(this.UserMembershipid);
      if (status === false) {
        //console.log(this.UserMembershipid);
        this.GroupRootdb.collection("groupmembers")
          .doc(this.UserRecord.id)
          .collection("groups")
          .doc(this.groupid)
          .update({
            Userid: this.UserRecord.id,
            Groupid: this.groupid,
            Follower: status,
            Administrator: false,
            BlogsModerator: false,
            ArticlesModerator: false,
            EventsModerator: false,
            PollsModerator: false,
            MeetupsModerator: false,
            ForumsModerator: false,
            Status: "InActive",
            LeaveDate: new Date(),
            GroupPrivacy: this.GroupPrivacy,
            UserName: this.UserRecord.Full_Name,
          });
      } else if (this.UserisInactiveMember) {
        this.GroupRoot.collection("groupmembers")
          .doc(this.UserRecord.id)
          .collection("groups")
          .doc(this.groupid)
          .update({
            Userid: this.UserRecord.id,
            Groupid: this.groupid,
            Follower: status,
            Administrator: false,
            BlogsModerator: false,
            ArticlesModerator: false,
            EventsModerator: false,
            PollsModerator: false,
            MeetupsModerator: false,
            ForumsModerator: false,
            Status: "Active",
            NewMembershipDate: new Date(),
            GroupPrivacy: this.GroupPrivacy,
            UserName: this.UserRecord.Full_Name,
          });
        // })
      } else {
        // let ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc()
        // let newdocid = ref.id

        //     if(!this.UserRecord.groups){
        //     this.UserRecord.groups = []
        //     }
        //     if(!this.UserRecord.groupspermissions){
        //         this.UserRecord.groupspermissions = []
        //     }
        //     let grouppermobj = {
        //         groupid: this.groupid,
        //         GroupPrivacy: this.GroupPrivacy,
        //         MembershipID: newdocid
        //     }
        //     this.UserRecord.groupspermissions.push(grouppermobj)
        //     this.UserRecord.groups.push(this.groupid)
        //     db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
        //         groups: this.UserRecord.groups,
        //         groupspermissions: this.UserRecord.groupspermissions
        //     }).then(userdoc => {
        const GroupMember = {
          Userid: this.UserRecord.id,
          Groupid: this.groupid,
          Follower: status,
          Administrator: false,
          BlogsModerator: false,
          ArticlesModerator: false,
          EventsModerator: false,
          PollsModerator: false,
          MeetupsModerator: false,
          ForumsModerator: false,
          UserRole: "Follower",
          Status: "Active",
          GroupPrivacy: this.GroupPrivacy,
          UserName: this.UserRecord.Full_Name,
        };
        if(this.MembershipbyApproval){
          GroupMember.Status = 'Pending Approval'
        }
        GroupMember.MembershipDate = new Date();
        this.GroupRoot.collection("groupmembers")
          .doc(this.UserRecord.id)
          .collection("groups")
          .doc(this.groupid)
          .set(GroupMember)
          .then((doc) => {
            //console.log("yippee we did it");
          });
        // })
      }
      if (byinvite) {
        db.collection("groups")
          .doc(this.groupid)
          .collection("invites")
          .doc(this.UserRecord.id)
          .update({
            Status: { Name: "Accepted", ID: 1000002 },
          });
          let invites = this.$store.state.InvitedGroups
        let match = invites.find(obj => obj.id === this.groupid)
        if(match){
          match.Responded = true
          this.$store.commit('SetInvitedGroups',invites)
          //console.log('this.$store.state.InvitedGroups',invites,this.$store.state.InvitedGroups)
        }
      }
      // db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.groupid).update({
      //     yep: true
      // })
      // db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).collection('Groups').add(GroupMember)
      // this.UserisMember = true
      // this.snackbar = true
    },
    
    
    GetGroupRatings(){
      db.collection('usermessages').where('GroupSender','==',false).where('groupid','==',this.GroupData.id).where('IsGroupRating','==',true).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let item = {
              ...change.doc.data(),
              id: change.doc.id
            }
            item.Replies = []
            this.GroupRatings.push(item)
            if(this.CanViewReplies){
                      db.collection('usermessages').where('GroupSender','==',true).where('Replytoid','==',item.id).onSnapshot(replyres => {
                        const rechanges = replyres.docChanges();
                        rechanges.forEach(rechange => {
                        if (rechange.type === 'added') {
                            let reply = {
                            ...rechange.doc.data(),
                            id: rechange.doc.id
                            }
                            item.Replies.push(reply)
                        }
                        })
                     })  
                    }
          }  
        })
        //console.log('this.SentMessages',this.SentMessages,this.AllUserConversations,this.AllUserConversationsAscending)
      })
    },
    GetGroupContentCategories(){
      let array = [
          'ArticlesCategories',
          'BlogsCategories',
          'MeetupsCategories',
          'PollsCategories'
          ]
           array.map(item => {
                  this.GroupData[item] = []
                    this.GroupDB.collection(item).onSnapshot(res => {
                    const changes = res.docChanges();
                      changes.forEach(change => {
                        if (change.type === 'added') {
                          this.GroupData[item].push({
                            ...change.doc.data(),
                            id: change.doc.id
                          })
                        }
                      })
                  })
                })
    },
    async getGroupInformation() {
      let groupcollection = db.collection("groups");
      let docref = groupcollection.doc(this.groupid);
      //console.log('activegroup',this.ActiveGroup,'store;state;',this.$store.state)
      // this.CalledMembers = true only needed if we wanted to prhibit view of groupmembers until called
      this.GroupData = this.ActiveGroup;
         this.AssignGroupProperties()
    },
    AssignGroupProperties(){
       if(this.GroupData.DefaultPage){
            this.DefaultPage = this.GroupData.DefaultPage
          }
          
          
          this.GroupData.id = this.groupid
          this.GetGroupRatings()
          if(this.GroupData.GroupPrivacy !== 'Invite Only'){
           //this.GetGroupContentCategories() 
          }          
          this.GroupData.id = this.groupid;
          if(this.GroupData.GroupPublishStatus !== 'Published'){
           if(!this.UserisAdministrator && !this.userIsMonetizationOfficer && !this.GroupData.Invitees.includes(this.userLoggedIn.id)){
             this.$router.replace('/404')
           }
          }
          //console.log(this.GroupData);

          //this.GroupData.Pages = []
          this.GroupPages = this.GroupData.Pages
          this.$emit("PushRecordName", this.GroupData.name);
          if (this.GroupData.Invitees) {
            this.Invitees = this.GroupData.Invitees;
            let invitecheck = this.Invitees.find(
              (obj) => obj === this.UserRecord.id
            );
            if (invitecheck) {
              db.collection("groups")
                .doc(this.groupid)
                .collection("invites")
                .doc(this.UserRecord.id)
                .onSnapshot((invitesnapshot) => {
                  let invitedata = invitesnapshot.data();
                  this.UserInvite = invitedata;
                });
            }
          }
          
          this.RelatedObj = {
            id: this.groupid,
            name: this.GroupData.name,
            identifier: "name",
            collection: "groups",
            Link: "Group",
          };
          if (this.GroupData.AssignedGroupScope) {
            this.AssignedGroupScope = this.GroupData.AssignedGroupScope;
          }
          this.BlogPrivacy = this.GroupData.BlogPrivacy;
          this.EventsPrivacy = this.GroupData.EventsPrivacy;
          this.ArticlesPrivacy = this.GroupData.ArticlesPrivacy;
          this.PollsPrivacy = this.GroupData.PollsPrivacy;
          this.MeetupsPrivacy = this.GroupData.MeetupsPrivacy;
          (this.ForumPrivacy = this.GroupData.ForumPrivacy),
            (this.GroupCategory = this.GroupData.GroupCategory);
          this.name = this.GroupData.name;
         
          this.Description = this.GroupData.Description;
          this.logo = this.GroupData.logo;
          this.GroupPrivacy = this.GroupData.GroupPrivacy;
          this.WhocanJoin = this.GroupData.WhocanJoin
          if(typeof this.GroupData.MembershipbyApproval !== 'undefined'){
          this.MembershipbyApproval = this.GroupData.MembershipbyApproval
        }
          this.PostPrivacy = this.GroupData.PostPrivacy
          if(this.GroupData.PostsbyApproval){
            this.PostsbyApproval = this.GroupData.PostsbyApproval
          }
          this.WhocanPost = this.GroupData.WhocanPost
          this.EndorsingNetwork = this.GroupData.EndorsingNetwork;
          if (this.GroupData.GroupPrivacy === "Public") {
            this.UserhasAccess = true;
          }
          // if (this.userid === this.GroupData.creator) {
          //     this.UserhasAccess = true
          //     this.UserisAdmin = true
          // }

          if (this.UserisMember === true) {
            this.UserhasAccess = true;
          }
          if (this.GroupData.GroupPrivacy === "Private") {
            this.GroupisPrivate = true;
          }

          if (this.GroupData.GroupPrivacy === "Members Only") {
            this.GroupisMembersOnly = true;
          }
          if (this.GroupData.EndorsingNetwork) {
            this.GroupisNetworkValidated = true;
          }
          if (
            this.GroupCategory === "Ministry Network" ||
            this.GroupCategory === "Family Network" ||
            this.GroupCategory === "Business Network"
          )
            this.Network = true;
    },
    OpenBlogdialog() {
      this.blogdialog = true;
    },
    Closedialog() {
      this.blogdialog = false;
      this.editedItem = this.defaultItem;
      this.selectedFile = null;
    },
    onGroupImageselected(event) {
      this.selectedGroupImage = event.target.files[0];
      this.GroupImagelocalURL = URL.createObjectURL(this.selectedGroupImage);
    },
    onFileselected(event) {
      this.selectedFile = event.target.files[0];
    },
    saveBlog() {
      if (this.$refs.form.validate()) {
        var storageRef = firebase
          .storage()
          .ref("Group_Blog_Photos/" + this.selectedFile.name);
        var uploadTask = storageRef.put(this.selectedFile);
        uploadTask
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.title = this.blogtitle;
            this.content = this.blogcontent;
            // this.author = this.$store.state.user.FullName
            this.authorid = this.$store.state.user.id;
            this.image = url;

            const blog = {
              title: this.title,
              content: this.content,
              // author: this.author,
              authorid: this.authorid,
              image: this.image,
            };
            db.collection("groupblogs")
              .add(blog)
              .then(() => {});
            this.Closedialog();
          }),
          (this.quotedialog = false);
        this.snackbar = true;
      }
    },
  },
};
</script>
<style>
.tabsinner {
  background-color: #f8f8f8;
  color: #575757;
}
.Tabs {
  background-color: #048abf;
  color: #e4003b;
}
.Grouptitle {
  color: #ffffff;
  margin-left: 0.5em;
}
.GroupDescription {
  color: #70cbf3;
}
.Avatar {
  border: 1px solid #f3f3f3;
}
</style>
