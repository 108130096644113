
<template>

    <v-card :class="tab.BoxTransparency+''" :dark="tab.DarkForm"
     height="100%"
     :color="tab.PopupStyle || IsSurveyForm? '' : tab.BGColor ? tab.BGColor.hexa : ''"  :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  
     :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'" >
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               />
                               </v-card-title>
                                    <v-card-title  :class="tab.Center ? 'justify-center' : tab.Justify ? tab.Justify : ''" v-if="!tab.HideFormName &&  $route.name !== 'SurveyBuilder'" :style="TitleStyle(tab)">
                                    <strong>{{tab.FormName}}</strong><v-btn small v-if="ViaDialog" @click="DeactivateWebDialog(DialogName)" color="red" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
                                    <v-card-text style="padding-bottom: 0;padding-left: 30px;padding-right: 30px;padding-top:30px;">
                                        <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <span  @dragover.prevent @drop.prevent="Fielddragfinish(fieldindex)" :draggable="true" @dragstart="Fielddragstart(field,fieldindex)" v-for="(field,fieldindex) in tab.FormFields" :key="field.itemObjKey">
                                            <span v-if="field.Type === 'Option Set'">
                                            <v-select dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" return-object :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response" :rules="[$store.state.formrules.required]" v-model="field.Response" :items="field.Options" :label="field.DisplayName" item-text="Name"></v-select></span><span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1]">
                                            <v-select dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" v-if="field.Response" return-object :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response" :rules="[$store.state.formrules.required]" v-model="field.Level1Response" :items="field.Response.Options" :label="field.Levels[1].name" item-text="Name"></v-select></span><span v-if="field.Type === 'Single Line Text'">
                                            <v-text-field dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert"  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.required]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Boolean'">
                                            <v-switch dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert"  :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.required]" v-model="field.Response" :label="field.DisplayName"></v-switch></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'">
                              <v-text-field dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.required,$store.state.formrules.email]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'URL Link'">
                              <v-text-field dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.required,$store.state.formrules.url]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Telephone Number'">
                              <v-text-field dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.required,$store.state.formrules.telnr]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                                                <v-list dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" class="outline">
                                                    <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title>
                                                    <v-list-item v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                                        <v-text-field dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response" v-model="field.Response[breakdownfield.Name]" :label="breakdownfield.DisplayName"/>
                                                    </v-list-item>
                                                </v-list>
                                                </span><span v-if="field.Type === 'Number Field'">
                                            <v-text-field dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.numberfield,$store.state.formrules.required]" v-model="field.Response" type="number" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text'">
                                            <v-textarea class="smalltextarea" dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  :rules="[$store.state.formrules.required]" v-model="field.Response"  :label="field.DisplayName"></v-textarea></span><span v-if="field.Type === 'Lookup'">
                                            <v-autocomplete dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response" :items="field.Array" :item-text="field.LookupFieldName" :rules="[$store.state.formrules.required]" v-model="field.Response" return-object :label="field.DisplayName"></v-autocomplete></span><span v-if="field.Type === 'Date'">
                                            <v-menu 
                                            v-model="field.DateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="200px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                :label="field.DisplayName"
                                                prepend-icon="mdi-calendar-month"
                                                readonly  :rules="[$store.state.formrules.required]"
                                                v-on="on"
                                                v-model="field.Response"
                                                ></v-text-field>
                                                </template>
                                            <v-date-picker :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response"  v-model="field.Response"
                                                    @input="field.DateMenu = false"></v-date-picker>
                                            </v-menu>
                                            <v-menu v-if="field.HasTimeInput"
                                                v-model="field.TimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px" 
                                                id="fieldTimeMenu"
                                                
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-model="field.TimeResponse"
                                                    :label="field.DisplayName+' Time'"
                                                    prepend-icon="mdi-calendar-month"
                                                    readonly
                                                    v-on="on"
                                                    :id="field.Name+'Time'"
                                                    clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-model="field.TimeResponse"
                                                    @input="field.TimeMenu = false"
                                                    type="month" :readonly="tab.FormProcessing || field.MappedField && userLoggedIn && field.Response || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue && field.Response" 
                                                    width="290"
                                                    class="ml-4"
                                                ></v-time-picker>
                                                </v-menu></span><span v-if="field.Type === 'Single File Upload'">
                                            <input
                                            @change="UploadFile($event,tab,field)"
                                        type="file">
                                    <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> --></span><span v-if="field.Type === 'Radio Group' && $route.name !== 'MyDashboard'">
                            {{field.DisplayName}}:
                      <v-radio-group dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" row :value="field.Response" multiple>
                        <!-- field.Type {{field.Type}} -->
                                        <v-radio dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert"
                                        @click="toggleMultipleRadio(field,option)"
                                          v-for="option in field.Options"
                                          :key="option.itemObjKey"
                                          :label="option.Name"
                                          :value="option"
                                        ></v-radio>
                                      </v-radio-group></span><span v-if="field.Type === 'Radio Group' && $route.name === 'MyDashboard'">
                                        <v-select item-text="Name" dense :solo="!tab.SoloInvert" :solo-inverted="tab.SoloInvert" filled multiple return-object :items="field.Options" v-model="field.Response" />
                                        </span>
                                      </span>
                                        
                                        <!-- <v-checkbox
                                        v-model="checkbox"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        label="Do you agree?"
                                        required
                                        ></v-checkbox> -->
                                        
                                    </v-form>
                                    </v-card-text>
                                <v-card-actions :class="tab.ButtonAlignment">
                                        <v-btn
                                       
                                        :color="tab.ButonColor ? tab.ButonColor.hexa : 'links'"
                                        class="mr-4"
                                        @click="SubmitForm(tab)"
                                        >
                                        <span
                                        :style="tab.ActionBTNTextColor ? 
                                        'color:'+tab.ActionBTNTextColor.hexa+';' : ''">Submit</span>
                                        </v-btn>
                                        </v-card-actions>
                                <!-- <v-card-text v-if="tab.HasImage"> -->
                                    <!-- <v-layout :class="tab.Justify" row> -->
                                     <v-card-text v-if="tab.IMG && !tab.HideFormIMG">
                                            <v-layout :class="tab.Justify" row>
                                        
                                    <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && tab.IMG"
                                   :height="150"
                                        :src="tab.IMG"
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="150"
                                        src="@/assets/ImageHolder.png"
                                        />
                                            </v-layout>
                                        </v-card-text>
                                   <!-- </v-layout> -->
                                 <!-- </v-card-text> -->
                            
                            <v-card-actions v-if="tab.HasActionButton && tab.Name === 'Web Form'" >
                                    <v-btn :dark="tab.ActionBtnDark" :style="'background-color:'+tab.ButonColor.hex">
                                        {{tab.ActionButtonName}}
                                    </v-btn>
                                </v-card-actions>
                                </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','PageName','rowindex','DynamicWikiData','ViaDialog','DialogName'],
    components: {

        },
    data() {
    return {    
        draggingrow: '',
        draggingrowindex: -1,   
        valid: '',
      UserRecord: '',
      rules: {
          numberfield: value => {
            const pattern = /^[0-9]+$/im;
            return pattern.test(value) || "Not a valid number.";
          },
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        TitleStyle(tab){
            // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
            if(tab.HeaderBGColor){
                return tab.Center? 'background-color: '+tab.HeaderBGColor.hexa+';padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
            else{
                return tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
        },
        DeactivateWebDialog(DialogName){
            this.$emit('DeactivateWebDialog',DialogName)
        },
        Fielddragstart(row,rowindex){
            this.draggingrow = row
            this.draggingrowindex = rowindex
        },
        Fielddragfinish(to){
            // let newobj = Object.assign({},this.dragging)
            console.log(this.draggingrow)
            // this.EditedTab.Rows.splice(to, 1,this.draggingrow,this.EditedTab.Rows.splice(to)[0])
            this.tab.FormFields.splice(to, 0, this.tab.FormFields.splice(this.draggingrowindex, 1)[0]);
            // console.log(this.PageTabs)
            //this.SiteTabs[totab].Sections[tosection].Content.splice(tocontent, 0, this.SiteTabs[fromtab].Sections[fromsection].Content.splice(fromcontent, 1)[0]);
            this.draggingrow = ''
            this.draggingrowindex = -1
        },
        SubmitForm(tab){
            console.log(tab)
            let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry for Landing Page - `+this.PageName+`</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`
                 } 
            })
            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'noreply@businessandpeople.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New - '+tab.FormName,
                EmailBody: EmailBody
            }
            //console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              //console.log(result)
              this.EmailBody = ''
            })
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>