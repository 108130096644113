<template>
  <span>
    <SingleButton v-if="!tab.MultipleButtons"
        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" :IsElement="IsElement" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
        />
    <div v-if="tab.MultipleButtons">
        <SingleButton v-for="btntab in ComputedTabButtonTabs" :key="btntab.itemObjKey"
         :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" :IsElement="IsElement" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="btntab" :i="i" :PageID="PageID" :PageName="PageName"								
        />
    </div>
  
  </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import SingleButton from '@/components/WebPages/RenderComponents/ActionButton/SingleButtonReadOnly';

export default {
    props: ['System','SystemEntities','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {WebFormTab,SingleButton},
    data() {
        return {
            
            WebFormDialogFancy: false, 
            PopupStyleDialog: false,
            WebFormDialogNonFancy: false,
            ButtonProps: ['ActionButtonName','ActionButtonIcon','ActionButtonIconOnly','ActionButtonText','ActionButtonFullWidth',
          'ActionButtonType','ContentDialogType','ContentDialogGalType','ContentDialogBox','ActionButtonElementIndex',
          'RelatedEntity','FormName','FormEmailRecipient','DarkForm','SoloInvert','HideFormIMG','PopupStyle',
          'ActionBTNTextColor','ActionButtonRoute','ButtonAlignment','ActionBtnDark','ButonColor','ActionBTNTextColor'],
        }
    },
    computed:{
        ComputedTabButtonTabs(){
            return this.tab.ActionButtons ? this.tab.ActionButtons.map(btn => {
                let tab = JSON.parse(JSON.stringify(this.tab))
                this.ButtonProps.map(prp => {
                    if(typeof btn[prp] !== 'undefined'){
                        tab[prp] = btn[prp]
                    }
                })
                return tab
            })
             : []
        },
        FullScreenGalleryContentBox(){
            let dialbox = this.tab.ContentDialogBox
            return this.tab.ContentDialogType && this.tab.ContentDialogType.Name === 'Gallery' && dialbox && dialbox.FullScreenDialog && dialbox.Assets[0]
        },
        ContentDialogBoxType() {
            if(this.tab.ContentDialogType){
                return () => import(`@/components/WebPages/RenderComponents/`+this.tab.ContentDialogType.ComponentName.split(' ').join('')+`ReadOnly`);
            }
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
    },
    created(){

    },
    methods:{
        DownloadFile(file){
            this.GetShortLivedURL(file)
        },
        GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            console.log(item)
            GetSignedInURL(item).then(result => {
            let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
            })
        },
         DeactivateWebDialog(){
            this.WebFormDialogFancy = false
            this.WebFormDialogNonFancy = false
            this.PopupStyleDialog = false
        },
    }
}
</script>

<style>

</style>
