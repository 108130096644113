<template>
  <v-overlay v-model="HelperActive" style="z-index:6;">
    <v-card v-if="!Toggling" width="250" light :style="BuilderHelperStyleandPosition">
        <div>
        <v-card-title class="LimeBuilderGradient white--text overline justify-space-between">
            <v-icon dark class="actionbounce" left x-large>mdi-arrow-left-bold-circle
            </v-icon>
            {{ActiveHelper.Title}}
            <v-icon dark v-if="ActiveHelper.Icon" class="actionbounce" right>{{ActiveHelper.Icon}}
            </v-icon>
           
        </v-card-title>
        <v-card-text>
            {{ActiveHelper.Text}}
        </v-card-text>
        <v-card-actions>
            <v-checkbox @click="StopHelper()" v-model="PleaseStopBugging" label="Stop Bugging Me"/>
        </v-card-actions>
        <v-card-actions>
            <v-btn @click="helperstep--" :disabled="!HelperElements[helperstep-1]" outlined>
                Back
            </v-btn>
            <v-spacer>              
            </v-spacer>
                <v-icon class="soloactionicon"  @click="DeactivateHelper()" color="red" dark>mdi-close</v-icon>
            <v-spacer>              
            </v-spacer>
            <v-btn @click="helperstep++" :disabled="!HelperElements[helperstep-1+2]" outlined>
                Next
            </v-btn>
        </v-card-actions>
        </div>
    </v-card>
    </v-overlay>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','HelperActive','HelperElements','OffsetX','OffsetXVal','OffsetY','OffsetYVal','KeepPulseIndexes','HelperID'],
    components: {},
    data() {
        return {
            Toggling: false,
             HelpElmntY: '',
            HelpElmntX: '',
            helperstep: 0,
        }
    },	
    computed:{
        ActiveHelper(){
            return this.HelperElements[this.helperstep]
        },
        BuilderHelperStyleandPosition(){
            let style = 'z-index:500;position: fixed;'
            let xpos = this.HelpElmntX+this.OffsetXVal
            let ypos = this.HelpElmntY+this.OffsetYVal
            let cardheightmax = 340
            // RULE OF THUMB elements around 320/340px.
            //Card goes offscreen sometimes, need to calculate
            //perhaps ypos + 320 +50  bttompad is higher than screen height?
            //console.log(this.WindowHeight)
            if(ypos+cardheightmax > this.WindowHeight && this.OffsetY === 'top'){
                ypos = this.WindowHeight-cardheightmax
            }
            else if(ypos < 0 && this.OffsetY === 'bottom'){
                ypos = 0
            }
            style = style+this.OffsetX+':'+xpos+'px;'+this.OffsetY+':'+ypos+'px;'
            //console.log('style',style,this.OffsetXVal,this.HelpElmntX,this.OffsetYVal,this.HelpElmntY)
            return style            
        },
        ComputedActiveMarketTemplate(){
            //console.log('this.ActiveMarketTemplate',this.ActiveMarketTemplate,this.ComputedTemplateChildren)
            let obj = Object.assign({},this.ActiveMarketTemplate)
            obj.Pages = this.ComputedTemplateChildren
            return obj
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {        
        HelperActive: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    if(this.HelperActive){
                        this.InitiateHelper()
                    }
                    
                }
        },
        helperstep: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    let elmnt = document.getElementById(this.ActiveHelper.elmntid)
                    //console.log(this.HelperParentElement)
                    if(newvalue<oldvalue){
                       this.getOffset(elmnt,true)  
                    }
                    else{
                    this.getOffset(elmnt)
                    }
                    
                }   
        },  
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        //console.log(this.HelperElements,this.helperstep,this.ActiveHelper)
    },
    methods:{
        StopHelper(){
            this.$emit('StopHelper',this.HelperID)
        },
        InitiateHelper(){
            this.$emit('ActivateHelper',this.HelperID)
            let elmnt = document.getElementById(this.ActiveHelper.elmntid)
                    //console.log(this.HelperParentElement)
           // elmnt.scrollIntoView()
           this.getOffset(elmnt) 
            
        },
        DeactivateHelper(){
            this.$emit('DeactivateHelper')
            this.HelperElements.map((item,index) => {
                if(!this.KeepPulseIndexes.includes(index)){
                    let elmnt = document.getElementById(item.elmntid)
                    if(elmnt){
                        elmnt.classList.remove('actionbounce')
                    }
                }
            })
            
        },
        getOffset(el,decrease) {
            this.Toggling = true
            let previndex = 0
            if(decrease){
                previndex = this.helperstep-1+2
            }
            else{
                previndex = this.helperstep-1
            }
            if(!this.KeepPulseIndexes.includes(previndex)){
                let prevelmnt = this.HelperElements[previndex] ? document.getElementById(this.HelperElements[previndex].elmntid) : ''
                //console.log(prevelmnt)
                    if(prevelmnt){
                        prevelmnt.classList.remove('actionbounce')
                    }                          
            }
            el.classList.add('actionbounce')
            const scrollrect = el.getBoundingClientRect();
            let scrolly = scrollrect.top
            window.scrollTo({top: scrolly, behavior: 'smooth'})
            setTimeout(() => {
            let rect = el.getBoundingClientRect();
            let positionobj = {
                right: rect.right,
                bottom: rect.bottom,
                left: rect.left,
                // + window.scrollX,
                top: rect.top
                // + window.scrollY
            }
            
                this.HelpElmntX = positionobj[this.OffsetX]
                this.HelpElmntY = positionobj[this.OffsetY]
                this.Toggling = false
            }, 500);


            //console.log(positionobj)
        },
    }
}
</script>

<style>

</style>



