<template>
  <div>
    <!-- <v-card-title class="largeoverline" style="margin-top: 35px;font-weight: 300;">
      <v-icon large>mdi-monitor-star</v-icon>  Adverts
      <v-btn @click="DeactivateActivesession()" right style="z-index: 2;" absolute outlined color="red">Exit</v-btn>
    </v-card-title> -->
      <SuiteAdvertsList v-if="!userIsAdmin" :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @DeactivateActivesession="DeactivateActivesession" :SubscriptionPackages="SubscriptionPackages"
        />
    <v-tabs v-if="userIsAdmin" centered>
        <v-tab>Adverts</v-tab>
        <v-tab-item>
            <SuiteAdvertsList :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @ActivateSnackbar="ActivateSnackbar"
        @DeactivateActivesession="DeactivateActivesession" :SubscriptionPackages="SubscriptionPackages"
        />
        </v-tab-item>
        <v-tab>Categories</v-tab>
        <v-tab-item>
        <SuiteAdvertCategories
           :System="System" :SystemEntities="SystemEntities"
           />
        </v-tab-item>
        <v-tab :disabled="false">Directory Settings</v-tab>
        <v-tab-item>
           <!-- 1. Packages 2. Who can Create -->
           <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
                <v-avatar size="80" class="mx-1">
                    <v-img contain src="@/assets/BnPFullLogo.png"/>
                </v-avatar>
              Advert Directories Config <v-spacer>
                  </v-spacer><v-btn @click="ViewMonetization()" dark>Manage Packages</v-btn>
              </v-card-title>
              <v-card elevation="0" class="siteconfigcard">
                <v-card-subtitle class="mediumoverline">
                  General
              </v-card-subtitle>
              <v-card-text >                 
                     <Monetization :System="System" :SystemEntities="SystemEntities" :Record="System"
                    @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
                    @AddGallerySubcolImages="AddGallerySubcolImages" :SubscriptionPackages="SubscriptionPackages"
                    />
              </v-card-text>
              <v-card-actions>
                  <v-spacer>
                  </v-spacer>
                  <v-btn @click="UpdateQuickEdits()" dark color="success">
                      Save
                  </v-btn>
              </v-card-actions>
              </v-card>
             
        </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SuiteAdvertsList from '@/components/SuitePlugins/AdvertDirectory/SuiteAdvertsList';
import SuiteAdvertCategories from '@/components/SuitePlugins/AdvertDirectory/SuiteAdvertCategories';
import Monetization from '@/components/SuitePlugins/Monetization/Monetization';
export default {
    props: ['System','SystemEntities','SubscriptionPackages','UnreadNotifications','Directories'],
    components: {SuiteAdvertsList,SuiteAdvertCategories,Monetization},
    data() {
        return {

        }
    },	
    computed:{
      ListPrimaryField(){
            return this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
            this.AdvertDirectory.CategoryField : ''
        },
        AdvertDirectory(){
            return this.$store.state.AdvertDirectory
        },
        CategoryField(){
          return this.ActiveStore && this.ActiveStore.CategoryField ? 
          this.ActiveStore.CategoryField : ''
        },
        ActiveStore(){
          return this.$store.state.ActiveStore
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      //setActiveDirectory setActiveStore
    },
    methods: {
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        if(AdditionalSaveMethod){
          this[AdditionalSaveMethod]()
        }
      },
        DeactivateActivesession(){
        this.$emit('DeactivateActivesession')
      },
    }
}
</script>

<style>

</style>



