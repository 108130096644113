<template>
  <div>
      
       <v-card>
        <v-card-title>
        <span class="headline">Wiki Source Dialog</span>
        </v-card-title>
        <v-card-text>
            <v-list  v-if="EditedTab.Name !== 'HTML Table'">
            <!-- CurrentEntity {{CurrentEntity}} -->
            <v-list-item v-for="prop in SourceProps" :key="prop.itemObjKey">
                <v-list-item-content>
                    <v-select item-text="Name" v-model="prop.SourceType" :items="ComputedSourceTypes" :label="prop.ElementProp+' Source Type'">
                        <template v-slot:item="{ item }">
                            <v-tooltip bottom >
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                    v-on="on">
                                    {{item.Name}}
                                    </span>
                                </template>
                                <v-card max-width="200" v-html="item.Description" tile class="transparent white--text">
                                </v-card>
                            </v-tooltip>
                                </template>
                    </v-select>
                </v-list-item-content>
                <v-list-item-content v-if="prop.SourceType === 'Data Field'">
                    <v-select v-if="EditedTab.Name !== 'Documents Table' && EditedTab.Name !== 'Gallery'" return-object v-model="prop.DataProp" :items="FilteredItems(prop,'AllFields')" :label="prop.ElementProp" item-text="Name"/>
                    <v-select v-if="EditedTab.Name === 'Documents Table'" v-model="prop.DataProp" :items="DocumentRegisters" :label="prop.ElementProp" item-text="id"/>
                    <v-select v-if="EditedTab.Name === 'Gallery'" v-model="prop.DataProp" :items="Galleries" :label="prop.ElementProp" item-text="id"/>                    
                </v-list-item-content>
                <!-- <v-list-item-content v-if="EditedTab.Name === 'HTML Table' && prop.SourceType">
                    <v-select v-if="EditedTab.Name === 'HTML Table'" @change="UpdateTabTable(prop.DataProp)" v-model="prop.DataProp" :items="SystemEntities" :label="prop.ElementProp" item-text="id"/>                    
                </v-list-item-content> -->
                <v-list-item-content v-if="prop.SourceType === 'Page Record Prop'">
                    <v-select return-object v-model="prop.DataProp" :items="FilteredItems(prop,'AllFields')" :label="prop.ElementProp" item-text="Name">
                       <template v-slot:prepend-item>
                           <v-btn v-if="userisAdmin" small @click="ActivateNewWikiFieldDialog(prop.DefaultType)" color="green" dark text>Add New</v-btn>
                          </template>
                    </v-select>                 
                </v-list-item-content>
                			
            </v-list-item>
            </v-list>
            <v-list  v-if="EditedTab.Name === 'HTML Table'">
            <v-list-item >
                 <v-list-item-content >                    
                   <v-select v-model="EditedTab.TableType" :items="TableTypes" label="Table Source">                     
                    </v-select>
                </v-list-item-content>
                <v-list-item-content >    
                    <v-select v-if="EditedTab.TableType !== 'Custom Table'" return-object v-model="EditedTab.DataProp" :items="FilteredItems({Allowed: ['Data Table']},'WikiFields')" label="Table Name" item-text="Name">
                       <template v-slot:prepend-item>
                           <v-btn small @click="ActivateNewWikiFieldDialog('Data Table')" color="green" dark text>Add New</v-btn>
                          </template>
                    </v-select>
                </v-list-item-content>
            </v-list-item>
            <v-list-item >
                <v-list-item-content >    
                    <v-switch v-if="EditedTab.TableType !== 'Custom Table'" label="Filter by Record"
                    v-model="EditedTab.FilterbyCurrent"/>
                </v-list-item-content>
                 <v-list-item-content >    
                    <v-select v-if="EditedTab.TableType !== 'Custom Table'" clearable @change="UpdateTabTable(EditedTab.TableEntity)"
                    v-model="EditedTab.TableEntity" :items="RelatedSystemEntities(EditedTab)" label="Entity" item-text="id">                     
                    </v-select>
                </v-list-item-content>                
            </v-list-item>
             <v-list-item >
                 <v-list-item-content >    
                    <v-select v-if="EditedTab.TableType !== 'Custom Table' && EditedTab.TableEntity" return-object
                    v-model="EditedTab.TableFilterField" :items="RelatedEntityFields(EditedTab.TableEntity)" label="Filter Field" item-text="Name">                     
                    </v-select>
                </v-list-item-content>                
            </v-list-item>
        </v-list>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="CancelWikiSourceDialog()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="UpdateWikiSource(EditedTab,SourceProps)">Save</v-btn>
        </v-card-actions>
    </v-card>
      
  </div>
</template>

<script>
export default {
    props: ['CurrentEntity','EditedTab','SystemEntities'],
    components: {

    },
    data(){
        return{
            TableTypes: ['Wiki Table','Data Table','Custom Table'],
            NewWikiFieldDialog: false,
            NewFieldName: '',
            SourceTypes: [
                {Name: 'Data Field',Description: `With this option you can choose which data value you want to syncronize with the element content. It will always determine the value from the record it is viewing.
                 <p>E.g. The Page is for a Table of people, the element is an HTML Box and you the Page to always display "Full Name" as the Box Title.</p>`},
                {Name: 'Page Record Prop',Description: `This option allows updating the element, and stores the input to the Record, allowing each Record within this Table to view a different result.
                 <p>E.g. The Page is for a Table of people, the element is an HTML Box and you the Page to always display a custom value per record, with "John Doe" having "Special Guy", and for Jane Doe, you want "Critical Thinker".</p>`},
                {Name: 'Template Prop',Description: `This option is used when you want the value to always be the same for every reocrd within this particular table.
                 <p>E.g. The Page is for a Table of people, the element is an HTML Box and you all Records viewed from the Page Template to display "About" as the Box Title.</p>`},
            ],
            DefaultSourceProps: [
                {Type: 'HTML Box',Props: [
                    {ElementProp:'Title',Allowed: ['Single Line Text','Option Set','Lookup','Number Field'],DefaultType: 'Single Line Text'},
                    {ElementProp:'Description',Allowed: ['Multiple Lines Text'],DefaultType: 'Multiple Lines Text'},
                    {ElementProp:'IMG',Allowed: ['Single File Upload'],DefaultType: 'Single File Upload',TabBool: 'HasImage'},
                ]},
                {Type: 'Title',Props: [
                    {ElementProp:'Title',Allowed: ['Single Line Text','Lookup'],DefaultType: 'Single Line Text'},
                ]},
                {Type: 'Info Sheet',Props: [
                    {ElementProp:'Description',Allowed: ['Multiple Lines Text'],DefaultType: 'Multiple Lines Text'}
                ]},
                 {Type: 'Documents Table',Props: [
                    {ElementProp:'Documents',IsArray: true,EntityProp: 'DocumentRegisters'},
                ]},
                {Type: 'Parallax',Props: [
                    {ElementProp:'IMG',Allowed: ['Single File Upload'],DefaultType: 'Single File Upload'},
                ]},
                {Type: 'Image',Props: [
                    {ElementProp:'IMG',Allowed: ['Single File Upload'],DefaultType: 'Single File Upload'},
                ]},
                {Type: 'Gallery',Props: [
                    {ElementProp:'Assets',IsArray: true,EntityProp: 'Galleries',DefaultType: 'Gallery'},
                ]},
                {Type: 'FAQ',Props: [
                    {ElementProp:'SelectedFAQ',IsArray: true,DefaultType: 'FAQ'},
                ]},
                {Type: 'HTML Table',Props: [
                    {ElementProp:'TableName',DefaultType: 'Data Table',IsArray: true},
                ]},
                {Type: 'Description List',Props: [
                    // okay this is "wrong" but yeah what we should do is - define per list item entry, which field
                    //for current tempalte though yes all is good, all working. Future ref though - much coming
                    {ElementProp:'ListItems',DefaultType: 'Data Table',IsArray: true},
                ]},
            ],
          
        }
    },
    computed:{
        ComputedSourceTypes(){
            return this.SourceTypes.filter(type => {
                if(this.EditedTab.Name === 'HTML Table'){
                    return type.Name !== 'Data Field'
                }
                else{
                    return type
                }
            })
        },
        WikiFields(){
            if(this.CurrentEntity.WikiFields){
                return this.CurrentEntity.WikiFields.filter(field => {
                    return field.Source === 'Page Record Prop'
                })
            }
            else{
                return []
            }
        },
        
        Galleries(){
            return this.CurrentEntity.Galleries
        },
        DocumentRegisters(){
            return this.CurrentEntity.DocumentRegisters
        },
        AllFields(){
            return this.CurrentEntity.AllFields
            .map(field => {
                let fieldobj = {
                    Name: field.Name,
                    Type: field.Type
                }
                if(field.Type === 'Lookup'){
                    fieldobj = Object.assign({},field)
                    delete fieldobj.Array
                }
                return fieldobj
            })
        },
        SourceProps(){
            // return this.EditedTab.SourceProps? this.EditedTab.SourceProps : this.DefaultSourceProps.find(obj => obj.Type === this.EditedTab.Name).Props 
            return this.SourceItem.Props
            .filter(prop => {
                return !prop.TabBool || prop.TabBool && this.EditedTab[prop.TabBool]
            })
            .map(prop => {
                if(this.EditedTab.SourceProps){
                    let editedprop = this.EditedTab.SourceProps.find(obj => obj.ElementProp === prop.ElementProp)
                    if(editedprop){
                        if(editedprop.SourceType){
                            prop.SourceType = editedprop.SourceType
                        }
                        if(editedprop.DataProp){
                            prop.DataProp = editedprop.DataProp
                        }
                    }
                }
                return prop
            })        
        },
        SourceItem(){
            return this.DefaultSourceProps.find(obj => obj.Type === this.EditedTab.Name)
        },
    },
    created(){

    },
    methods:{
        RelatedEntityFields(TableEntity){
            let entity = this.SystemEntities.find(obj => obj.id === TableEntity)
            return entity.AllFields.filter(field => {
                return field.RelatedBuildID === this.CurrentEntity.id
            })
        },
        RelatedSystemEntities(prop){
            if(prop.TableType === 'Data Table'){
                return this.SystemEntities
            }
            else if(prop.TableType === 'Wiki Table'){
                return this.SystemEntities.filter(entity => {
                    return entity.Has_Wiki
                }).filter(entity => {
                    if(prop.FilterbyCurrent){
                        let lookupmatch = entity.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
                        return lookupmatch
                    }   
                    else{
                        return entity
                    }
                })
            }
        },
        // ComputedSystemEntities(){
        //     if(this.EditedTab.Name === 'HTML Table' && this.EditedTab)
        // },
        UpdateTabTable(entityid){
            let entity = this.SystemEntities.find(obj => obj.id === entityid)
            console.log(entity)
            if(entity){
                this.EditedTab.Headers = entity.AllFields
                .filter(field => {
                    return !field.SystemRequired
                })
                .map(field =>{
                    if(!field.text || !field.value){
                        field.text = field.DisplayName
                        field.value = field.Name
                    }
                    return field
                })
                if(this.$store.state[entity.DisplayName.split(' ').join('')+'Array']){
                    let array = JSON.parse(JSON.stringify(this.$store.state[entity.DisplayName.split(' ').join('')+'Array']))
                    this.EditedTab.Data = array.map(record => {
                        let recordobj = {}
                        this.EditedTab.Headers.map(header => {
                            recordobj[header.value] = record[header.value]
                        })
                        return recordobj
                    })
                }
                else{
                    this.EditedTab.Data =  []
                }
                
            }
        },
        FilteredItems(prop,EntityProp){
            return this[EntityProp].filter(item => {
                if(typeof prop.Allowed !== 'undefined'){
                    return prop.Allowed.includes(item.Type)
                }
                else{
                    return item
                }
            })
        },
        ActivateNewWikiFieldDialog(DefaultType){
            this.$emit('ActivateNewWikiFieldDialog',DefaultType)
        },
        
        UpdateWikiSource(EditedTab,SourceProps){
            this.$emit('UpdateWikiSource',EditedTab,SourceProps)
        },
        CancelWikiSourceDialog(){
            this.$emit('CancelWikiSourceDialog')
        }
        
    }
}
</script>

<style>

</style>