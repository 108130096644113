<template>
<v-card tile  width="100%" flat  class="transparent CodeRenders" id="PageCode" :style="GetTabStyle(tab)">
  <v-layout row justify-center v-if="tab.ShowComponents" 
  :style="downsized ? 'align-items: start;' : ''">
  <!-- should be :class="downsized ? 'wasdownsized' : '' on parent but okay -->
                              
                          <div :class="'flex xl'+ComputedTab.FlexXLRG+' lg'+ComputedTab.FlexLarge+' md'+ComputedTab.FlexMedium+' sm'+ComputedTab.FlexSmall+' xs'+ComputedTab.FlexXSmall">  
                            <!-- style="width:200%;"
                             downsized-->
                              <!-- <v-flex :xl="tab.FlexXLRG" {{tab.FlexXLRG}} :lg="tab.FlexLarge" :md="tab.FlexMedium" :sm="tab.FlexSmall" :xs="tab.FlexXSmall"> -->
                     <div :class="downsized ? 'wasdownsized' : ''" v-if="tab.Name === 'Card Row'" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'">
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                <v-card-title :class="tab.Center" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                <strong>{{tab.Title}}</strong>
                                 <!-- <v-divider inset class="white" >
                                </v-divider> -->
                                <!-- <hr style="width:35%; color:blue;"> -->
                                </v-card-title>
                                <!-- <v-divider inset class="white" >
                                </v-divider> -->
                            <v-card-text v-html="tab.Description" :class="tab.FillHeight" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex">
                                </v-card-text>
                             
                             <v-card-text v-if="tab.HasImage">
                                 <v-layout :class="tab.Justify" row>
                                 <v-img  v-if="tab.HasImage && tab.IMG"
                                 :contain="!tab.Clipped"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        :style="CheckifClipped(tab)"
                                       
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                 </v-layout>
                             </v-card-text>
                        
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">
                            <v-btn :dark="tab.ActionBtnDark" :to="'/'+tab.ActionButtonRoute" :style="'background-color:'+tab.ButonColor.hex">
                                {{tab.ActionButtonName}}
                            </v-btn>
                        </v-card-actions>
                            </div>
                            
                    <v-row v-if="tab.DataViewName && !SingleRecordNavMenu || tab.DataViewName && SingleRecordNavMenu && SingleRecordNavMenuItem && SingleRecordNavMenuItem.Name === tab.Name || tab.DataViewName && SingleRecordNavMenuItem && SingleRecordNavMenuItem.SingleBuildTabs"
                    :class="downsized ? 'downsized mb-6 '+tab.Justify : 'mb-6 '+tab.Justify"
                        no-gutters
                        :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                       
                        > 
                        <v-flex :xl="ComputedTab.FlexXLRG" :lg="ComputedTab.FlexLarge" :md="ComputedTab.FlexMedium" :sm="ComputedTab.FlexSmall" :xs="ComputedTab.FlexSmall">
                           
                        <!-- Standard
                        'tab','AppisDarkMode','CanEdit','Record','Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','ActivitiesArray',
                        'SystemEntities','AdditionalSaveMethod','SingleFormEditing','tabindex','NewFieldDragging','NewFieldDraggingIndex','EntityDataRef'] -->
                        <DataSingleStandard v-if="tab.DataViewName === 'Data Single Standard' && !tab.TransposedTabs" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
                        :SystemEntities="ComputedSystemEntities" :EntityDataRef="EntityDataRef" :SingleFormEditing="SingleFormEditing" :FeatureNavList="FeatureNavList"
                        :NewFieldDragging="NewFieldDragging" :NewFieldDraggingIndex="NewFieldDraggingIndex" :CurrentEntity="CurrentEntity" :SingleElementView="SingleElementView"
                        :Record="DynamicWikiData" :CanEdit="CanEdit" :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                        :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                        :ActivitiesArray="ActivitiesArray" :AdditionalSaveMethod="AdditionalSaveMethod" @UpdateRecordCalculation="UpdateRecordCalculation"
                        @NewFieldDragfinish="NewFieldDragfinish" @UpdateEditableField="UpdateEditableField" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"              
                        :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
                        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" @ActivateProcessing="ActivateProcessing"
                        :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                        />
                        <!-- Cabinet
                        ['tab','AppisDarkMode','CanEdit','CanCreate','CanDelete','CanGet','Record','Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','ActivitiesArray',
                        'SystemEntities','AdditionalSaveMethod','SingleFormEditing','tabindex','NewTableDragging','NewTableDraggingIndex','ActiveEntityID','EntityCollectionRef','EntityDataRef' -->
                        <DataSingleCabinet v-if="tab.DataViewName === 'Data Single Cabinet' || tab.DataViewName === 'Data Single Related' || tab.DataViewName === 'Data Single Standard' && tab.TransposedTabs"
                        :SystemEntities="ComputedSystemEntities" :EntityDataRef="EntityDataRef" :EntityCollectionRef="EntityCollectionRef" :SingleFormEditing="SingleFormEditing"
                        :NewTableDragging="NewTableDragging" :NewTableDraggingIndex="NewTableDraggingIndex" :ActiveEntityID="CurrentEntity.id" :CurrentEntity="CurrentEntity"
                        :Record="DynamicWikiData" :CanEdit="CanEdit" :CanGet="CanGet" :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                        :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                        :AdditionalSaveMethod="AdditionalSaveMethod" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
                        @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :FeatureNavList="FeatureNavList"
                        @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"  :RADB="RADB" :SingleElementView="SingleElementView"
                        @NewTableDragfinish="NewTableDragfinish" @UpdateEditableField="UpdateEditableField" @UpdateRecordCalculation="UpdateRecordCalculation"      
                        :AppisDarkMode="AppisDarkMode" :SystemPhotoLibraries="SystemPhotoLibraries"
                        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                        :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                        />
                        <!-- Data Single Standard
                        Data Single Cabinet -->
                        </v-flex>
                    </v-row>
                     <v-row  v-if="tab.Name !== 'Parallax' && tab.Name !== 'Clipped Tab' && !tab.DataViewName"        
                       :class="downsized ? 'downsized mb-6 '+tab.Justify : 'mb-6 '+tab.Justify"
                        :no-gutters="!downsized"
                        :style="tab.Name === 'Card Row' ? '' : 'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                       
                        >        
                        
                         
                                    
                        <v-col
                            v-for="(elmnt,index) in PreparedTabElements(tab)"
                            :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                            cols="12"    
                            :class="elmnt.Justify+' '+ColMY(elmnt)"  
                            :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
                        >    
                        <!-- text,gradient,line -->
                        <v-card-title class="mediumoverline" v-if="IsDefaultSocialPage && elmnt.DisplayName" :style="SocialPageHeaderStyle">
                                {{elmnt.DisplayName}} 
                        </v-card-title>
                         <v-card-title class="mediumoverline" v-if="elmnt.Title && elmnt.SocialComponent && !SampleOnly || elmnt.Title && elmnt.Name === 'RSS Feed'" :style="HeaderFontFamily(elmnt,elmnt.HeaderFontFamily)">
                                {{elmnt.Title}} 
                        </v-card-title>		
                         <TimelineTab :SampleOnly="SampleOnly"
                            v-if="elmnt.Name === 'Timeline' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                            />
                            
                            <ImageTab v-if="elmnt.Name === 'Image' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                            />
                            <RSSFeedTab :SampleOnly="SampleOnly"
                            v-if="elmnt.Name === 'RSS Feed' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />

                            <SystemActivitiesFeed 
                            v-if="elmnt.Name === 'System Activities' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :System="System"
                             class="mx-3" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Filter="''"
                            />
                            <SocialSitePosts 
                            v-if="elmnt.Name === 'Social Site Posts' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :System="System"
                            :UserObj="userLoggedIn" :AppisDarkMode="AppisDarkMode" class="mx-3" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" 
                            :UserRecord="userLoggedIn" :UserID="userLoggedIn.id" :UserView="false" :Slice="10"
                            />                             
                             <SiteArticleFeed :style="SampleOnly ? 'margin-top: 40px;' : ''"
                            v-if="elmnt.Name === 'Site Articles' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                            :SliderView="elmnt.SliderView ? true : false" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Slice="elmnt.Slice ? elmnt.Slice : 6" :Filter="''"
                            />
                             <DirectoryViewer style="padding-left: 10px;padding-right:10px;" v-if="elmnt.Name === 'Site Groups' && elmnt.ShowComponents" :ProvidedAdditionalFilters="elmnt.CatNavList && elmnt.CatNavList.AdditionalFilters ? elmnt.CatNavList.AdditionalFilters: ''"
                            :ProvidedNavList="elmnt.CatNavList && elmnt.CatNavList.NavList ? elmnt.CatNavList.NavList : ''" :ProvidedCurrentEntity="elmnt.CatNavList"
                            @ActivateTreeNode="ActivateTreeNode" :ProvidedFeatureList="elmnt.CatNavList && elmnt.CatNavList.FeatureList ? elmnt.CatNavList.FeatureList : ''" :style="FinalPageStyling"
                            />
                            <SiteBlogsFeed :style="SampleOnly ? 'margin-top: 40px;' : ''"
                            v-if="elmnt.Name === 'Site Blogs' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                            :SliderView="elmnt.SliderView ? true : false" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Slice="elmnt.Slice ? elmnt.Slice : 6" :Filter="''"
                            />                            
                            <SiteEventsFeed
                            v-if="elmnt.Name === 'Site Events' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :FeedView="true" :AppisDarkMode="AppisDarkMode" :System="System"
                            />
                            <SiteMeetupsFeed :style="SampleOnly ? 'margin-top: 40px;' : ''"
                            v-if="elmnt.Name === 'Site Meetups' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                            :SliderView="elmnt.SliderView ? true : false" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Slice="elmnt.Slice ? elmnt.Slice : 6" :Filter="''"
                            />
                            <NoticeboardFeed 
                            v-if="elmnt.Name === 'Notice Board' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :SliderView="elmnt.SliderView ? true : false" 
                             style="padding:15px;" :FeedView="true" :AppisDarkMode="AppisDarkMode" :System="System" :SampleOnly="SampleOnly"
                            />
                            <DigitalBusinessCards 
                            v-if="elmnt.Name === 'Business Members' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :SliderView="elmnt.SliderView ? true : false"
                             :AppisDarkMode="AppisDarkMode" :System="System" :SampleOnly="SampleOnly"
                            />
                            
                            <InfoCardTab 
                            v-if="elmnt.Name === 'Info Card' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :DefaultPageFont="DefaultPageFont"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <CardLinkTab 
                            v-if="elmnt.Name === 'Card Link' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                        <TitleTab 
                            v-if="elmnt.Name === 'Title' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                           <CarouselTab 
                            v-if="elmnt.Name === 'Carousel' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :SampleOnly="SampleOnly"
                            /> 
                            <DescriptionList 
                            v-if="elmnt.Name === 'Description List' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            /> 
                            <FAQ 
                            v-if="elmnt.Name === 'FAQ' && elmnt.ShowComponents && elmnt.SelectedFAQ.length>0" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <HTMLTable
                            v-if="elmnt.Name === 'HTML Table' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <DocumentsTableTab 
                            v-if="elmnt.Name === 'Documents Table' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <IFRAMETab  v-if="elmnt.Name === 'IFRAME' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                                :PageHeadersFontSize="PageHeadersFontSize" :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                                    />
                        <TableEmbedTab							
                v-if="elmnt.Name === 'Table Embed' && elmnt.Entityid && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	
                @RefreshComponent="RefreshComponent"								
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity" :RADB="RADB" :System="System"		
                :SystemEntities="ComputedSystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"	@ActivateProcessing="ActivateProcessing"				
                :tab="elmnt" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="DynamicWikiData" :SampleRecords="SampleRecords" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"								
                />
                            <WikiCollection 									
                            v-if="elmnt.Name === 'Wiki Collection' && elmnt.ShowComponents && elmnt.Entityid" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :WikiEntities="WikiEntities"								
                            :tab="elmnt" @OpenDialog="OpenDialog" :Record="DynamicWikiData"								
                            />	
                            <GoogleMyMapsTab 
                            v-if="elmnt.Name === 'Google My Maps' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :SampleOnly="SampleOnly"
                            />
                            <HTMLBox 
                            v-if="elmnt.Name === 'HTML Box' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :PageID="ActivePageID" :PageName="PageName"
                            />
                            <InfoSheet :SampleOnly="SampleOnly"								
                            v-if="elmnt.Name === 'Info Sheet' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />	
                            <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" :PageID="ActivePageID" :PageName="PageName"
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                             :DynamicWikiData="DynamicWikiData"
                            :tab="elmnt" :i="i"
                            />
                            <SurveyForm v-if="elmnt.Name === 'Survey Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" :PageID="ActivePageID" :PageName="PageName"
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                             :DynamicWikiData="DynamicWikiData"
                            :tab="elmnt" :i="i"
                            />
                            <CardRowTab 
                            v-if="elmnt.Name === 'Card Row Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :PageID="ActivePageID" :PageName="PageName"
                            />
                           <CardBlocksTab 
                            v-if="elmnt.Name === 'Card Block Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <CounterTab :SampleOnly="SampleOnly"
                            v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <TestimonialTab 
                            v-if="elmnt.Name === 'Testimonials' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <StoreProductTab 
                            v-if="elmnt.Name === 'Store Product Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            
                        </v-col>
                        
                     </v-row>
                     <!-- <div v-if="tab.Name === 'Card Blocks'">

                                        <v-container class="pa-1">
                                            <v-row>
                                            <v-col
                                                v-for="(item, i) in tab.Elements"
                                                :key="i"
                                                cols="3"
                                                md="3"
                                            >
                                            </v-col>
                                            </v-row>
                                        </v-container>
                            </div> -->
                     
                    <GalleryTab  v-if="tab.Name === 'Gallery' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"
                                @DeleteTabAsset="DeleteTabAsset" :SampleOnly="SampleOnly" :class="downsized ? 'wasdownsized' : ''"
                                />
                        
                   <ParallaxTab v-if="tab.Name === 'Parallax' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"
                    :tab="tab" @OpenDialog="OpenDialog" :i="i" :class="downsized ? 'wasdownsized' : ''"
                    />
                 <HeroBanner  v-if="tab.Name === 'Hero Banner' && tab.ShowComponents" 
                    :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                     :System="System" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                        />
                  
                    <ClippedTab :class="downsized ? 'wasdownsized' : ''"
                    v-if="tab.Name === 'Clipped Tab' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize"
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"
                    />
                    <v-card flat width="100%" style="visibility:hidden;height:0px"  @dragleave="unallowDrop(event,i)" @dragover.prevent @drop.prevent="contentdropFinish(i, $event)" :id="i+'drop-area'">
                <v-layout class="justify-center">
                    <v-card-title class="grey--text">
                    <v-icon>mdi-plus-thick</v-icon> Add tab below
                    </v-card-title>
                </v-layout>
                </v-card>
                      </div>
                   
                      
                            </v-layout>  
</v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DataSingleCabinet from '@/components/Database/DataSingleCabinet.vue';
import DataSingleStandard from '@/components/Database/DataSingleStandard.vue';
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';	
import TimelineTab from '@/components/WebPages/RenderComponents/TimelineTabReadOnly';
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import SurveyForm from '@/components/WebPages/RenderComponents/SurveyForm';
import DirectoryViewer from '@/components/Directories/DirectoryViewer';
import InfoCardTab from '@/components/WebPages/RenderComponents/InfoCardTabReadOnly';
import CardLinkTab from '@/components/WebPages/RenderComponents/CardLinkTab';

import SystemActivitiesFeed from '@/components/SocialNetwork/SystemActivitiesFeed';
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';
import SiteArticleFeed from '@/components/SuitePlugins/Articles/SiteArticleFeed';
import SiteBlogsFeed from '@/components/SuitePlugins/Blogs/SiteBlogsFeed';
import SiteEventsFeed from '@/components/SuitePlugins/Events/SiteEvents';
import SiteMeetupsFeed from '@/components/SuitePlugins/Meetups/SiteMeetupsFeed';
import NoticeboardFeed from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeboardFeed';
import DigitalBusinessCards from '@/components/SuitePlugins/DigitalBusinessCards/DigitalBusinessCards';

import RSSFeedTab from '@/components/WebPages/RenderComponents/RSSFeedTab';

import CarouselTab from '@/components/WebPages/RenderComponents/CarouselTab';

import HTMLTable from '@/components/WebPages/RenderComponents/HTMLTable';
import DocumentsTableTab from '@/components/WebPages/RenderComponents/DocumentsTableTab';
import DescriptionList from '@/components/WebPages/RenderComponents/DescriptionList';
import FAQ from '@/components/WebPages/RenderComponents/FAQ';	
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
import StoreProductTab from '@/components/WebPages/RenderComponents/StoreProductTabReadOnlyOLD';
import TestimonialTab from '@/components/WebPages/RenderComponents/TestimonialTabReadOnly';
import CardBlocksTab from '@/components/WebPages/RenderComponents/CardBlocksTabReadOnly';
import CardRowTab from '@/components/WebPages/RenderComponents/CardRowTabReadOnly';
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';	
import HTMLBox from '@/components/WebPages/RenderComponents/HTMLBoxReadOnly';
import ImageTab from '@/components/WebPages/RenderComponents/ImageTabReadOnly';
import HeroBanner from '@/components/WebPages/RenderComponents/HeroBanner/HeroBannerReadOnly';
import ParallaxTab from '@/components/WebPages/RenderComponents/ParallaxTabReadOnly';
import ClippedTab from '@/components/WebPages/RenderComponents/ClippedTabReadOnly';
import IFRAMETab from '@/components/WebPages/RenderComponents/IFRAMETab';
import GalleryTab from '@/components/WebPages/RenderComponents/GalleryTabReadOnly';
import GoogleMyMapsTab from '@/components/WebPages/RenderComponents/GoogleMyMapsTab';
import WikiCollection from '@/components/WebPages/RenderComponents/WikiCollectionReadOnly';
export default {
    props: ['System','SystemEntities','tab','i','PageHeadersFontSize','PageHeadersFont','PageContentFont','wasdownsized','SampleOnly'],
    components: {TimelineTab,
        WebFormTab,
        DirectoryViewer,
        SurveyForm,
        CarouselTab,
        HTMLTable,
        DocumentsTableTab,
        DescriptionList,
        FAQ,
        TitleTab,
        InfoCardTab,
        CardLinkTab,
        SiteArticleFeed,
        SiteBlogsFeed,
        SystemActivitiesFeed,
        SocialSitePosts,
        SiteEventsFeed,
        SiteMeetupsFeed,
        NoticeboardFeed,
        DigitalBusinessCards,
        RSSFeedTab,
        InfoSheet,
        HTMLBox,
        CardBlocksTab,
        CardRowTab,
        CounterTab,
        StoreProductTab,
        TestimonialTab,
        ImageTab,
        HeroBanner,
        ParallaxTab,
        ClippedTab,        
        GalleryTab,
        IFRAMETab,
        GoogleMyMapsTab,
        WikiCollection,
        DataSingleStandard,
        DataSingleCabinet,
        TableEmbedTab},
    data() {
        return {
            CounterSingle: {
                    CounterInteger: 12,
                    BGColor: {hex: 'pink'},
                    CounterIcon: 'mdi-counter',
                    CenteredHeader: true,									
                    Name: 'Counter Single',									
                    HeaderFontFamily: 'Roboto',									
                    HeaderFontSize: 18,									
                    Tile: true,									
                    HeaderColor: { "alpha": 1, "hex": "#FFD700", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                    FontSize: 10,									
                    FontColor: { "alpha": 1, "hex": "#FFD700", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                                                        
                    Title: 'Clients',									
                    Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet.</div>`,									
                    FlexXLRG:2,									
                    FlexLarge: 3,									
                    FlexMedium: 3,									
                    FlexSmall: 6,									
                    FlexXSmall: 12,									
                    Cols: 3,									
                    Justify: 'justify-center',									
                    RowColor: '#00ffff',									
                    ShowComponents: true,									
                    Icon: 'mdi-cards',									
                    MarginTop: 0,									
                    MarginBottom: 0,									
                    MarginLeft: 0,									
                    MarginRight: 0,					
                    IsDark: true,
                },
            CardBlockSingle: {									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Card Block Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Here is a Headline',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 6,									
                FlexXSmall: 6,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,									
                									
                									
            },
        }
    },	
    computed:{
        ComputedTab(){
            
            if(this.SampleOnly){
                let newtab = JSON.parse(JSON.stringify(this.tab))
                newtab.FlexXLRG = 12
                newtab.FlexLarge = 12
                newtab.FlexMedium = 12
                return newtab
            }
            else{
                return this.tab
            }
        },
        ComputedSystemEntities(){
            return this.$route.name === 'GroupPageEditor' || this.$route.name === 'GroupPage' ? this.GroupEntities.filter(entity => entity.groupid === this.$route.params.id) : this.SystemEntities
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        GetTabStyle(tab){
            let style = {}
            if(tab.RowBlendMode){
                    style.mixBlendMode = tab.RowBlendMode
                }
            if(tab.Name !== 'Card Row'){
                style.marginTop = tab.MarginTop+'px'
                style.marginBottom = tab.MarginBottom+'px'
                style.marginLeft = tab.MarginLeft+'px'
                style.marginRight = tab.MarginRight+'px'
            }
            if(tab.Floating){
                style.marginRight = 'absolute'
            }
             if(tab.BGIMG && tab.HasBGIMG){
                
                if(tab.HasBGGradient && tab.BGGradient){
                    style.backgroundImage = tab.BGGradient.split('background: ')[1].split(';')[0]+`,url('`+tab.BGIMG+`')`
                    style.backgroundSize = 'cover'
                }
                else{
                    style.backgroundImage = `url('`+tab.BGIMG+`')`
                    style.backgroundSize = 'cover'
                }    
                 if(tab.BGIMGFixed){
                    style.backgroundAttachment = 'fixed'
                    if(this.$vuetify.breakpoint.xs){
                      style.backgroundSize = 'auto'  
                    }                    
                }   
                if(tab.BGIMGCentered){
                    style.backgroundPosition = 'center'
                }             
            }
                return style
        },
        PreparedTabElements(tabobj){
            let tab = JSON.parse(JSON.stringify(tabobj))
            if(this.SampleOnly){
                let newblock = JSON.parse(JSON.stringify(tab))
                
            // presettab.FlexXLRG =12
            // presettab.FlexLarge =12
            // presettab.FlexMedium =12
                if(newblock.Name === 'Counter' && newblock.Elements.length === 0){
                        let counterlmnt1 = JSON.parse(JSON.stringify(this.CounterSingle))
                        newblock.Elements.push(counterlmnt1)
                        let counterlmnt2 = JSON.parse(JSON.stringify(this.CounterSingle))
                        counterlmnt2.BGColor.hex = 'red'
                        counterlmnt2.CounterInteger = 5
                        counterlmnt2.Title = 'Years in Business'
                        newblock.Elements.push(counterlmnt2)
                        let sizeprops= [
                        'FlexXLRG',
                        'FlexLarge',
                        'FlexMedium',
                        'FlexSmall',
                        'FlexXSmall'
                    ]
                    return newblock.Elements.map(elmnt => {
                        sizeprops.map(val => {
                        if(elmnt[val]){
                            elmnt[val] = 6
                        }
                    })
                        return elmnt
                    })
                    
                    }
                    else if(newblock.Name === 'Card Blocks' && newblock.Elements.length === 0){
                       
                        for(var x=0;x < 4;x++){                            
                            let NewRow = JSON.parse(JSON.stringify(this.CardBlockSingle))
                            let colors = [									
                                '#F44336',									
                                '#E91E63',									
                                '#9C27B0',									
                                '#673AB7',									
                                '#3F51B5',									
                                '#2196F3',									
                                '#03A9F4',									
                                '#00BCD4',									
                                '#009688',									
                            ]									
                            let ranint = Math.floor(Math.random() * Math.floor(colors.length))									
                            let length = newblock.Elements.length									
                            let twoback = length-2									
                            if(length%2 != 0){									
                                if(newblock.Elements[twoback] && newblock.Elements[twoback].Type  && newblock.Elements[twoback].Type === 1){									
                                NewRow.Title = ''									
                                NewRow.Description = '' 									
                                NewRow.HasImage = true									
                                NewRow.IMG = 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert-BG?alt=media&token=ed09e64f-d4ee-4948-b481-809495b3e236'									
                                    NewRow.Type = 2									
                                }									
                                else{									
                                NewRow.Title = ''									
                                NewRow.Description = '' 									
                                NewRow.HasImage = true									
                                NewRow.IMG = 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert?alt=media&token=54d7ec67-2109-4eda-93c0-7bf98f55dbf8'									
                                    NewRow.Type = 1									
                                }									
                            }		                            
                            NewRow.BGColor.hex = colors[ranint]									
                            newblock.Elements = newblock.Elements.concat([NewRow])
                           
                        }
                         return newblock.Elements
                    }
                    else if(newblock.Name === 'Parallax' || newblock.Name === 'Clipped Tab' ){
                        newblock.Height = 150
                    }
                    else{
                        //console.log(tab,tab.Elements)
                        let elmntcount = tab.Elements.length
                        return tab.Elements.map(elmnt => {
                            if(elmnt.Name === 'IFRAME'){
                        elmnt.FrameURL = `<p style="font-size:48px">
    &#128512; &#128516; &#128525; &#128151;
    </p>`
                    }
                    if(elmnt.Name === 'Testimonials' && elmnt.Elements.length === 0){
                        elmnt.Elements.push({									
                Avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',									
                Name: 'John Leider',									
                Role: 'Creator - Vuetify',									
                Description: `<div style="text-align: center;"><i>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</i></div>`									
            })
                    }
                    if(elmnt.Name === 'HTML Table' && elmnt.Data.length === 0 && elmnt.Headers.length === 0){
                        let headernames = ['Company','Description','IMG']
                        let headertypes = ['Single Line Text','Multiple Lines Text','Single File Upload']
                        elmnt.Headers = headernames.map((head,headindex) => {
                            let headobj = {
                                Name: head.split(' ').join('_'),
                                DisplayName: head,
                                Type: headertypes[headindex]
                            }
                            if(headobj.Type === 'Date'){
                                headobj.GraphicDisplay = true
                                headobj.GRaphicColor = 'purple'
                            }
                            else if(headobj.Type === 'Multiple Lines Text'){
                                headobj.IsContentEditable = true
                            }
                            else if(headobj.Type === 'Single File Upload'){
                                headobj.FeaturedPhoto = true
                            }
                            else if(headobj.Type === 'Common Field'){
                                headobj.CommonFieldType = 'URL Link'
                            }
                            return headobj
                        })
                        elmnt.Data = [
                            //{Company: 'RapidApps',Description: `<p>RapidApps specialises in providing intergrated Business Suites for startup and established companies of any size</p>`,IMG: {ThumbURL:require('@/assets/BnPFullLogo.png'),Name: 'Logo'},Date: '2020-04-01',Website: 'https://www.rapidapps.co.za'},
                             {Company: this.System.Name,Description: `<p>`+this.System.Name+` is a Suite built using the RapidApps Power of One Business Suite</p>`,IMG: {ThumbURL: require('@/assets/logo.png'),Name: 'Logo'},Date: format(new Date,'yyyy-MM-dd'),Website: this.System.ClientAppURL}
                        ] 
                    }
                    else if(elmnt.Name === 'Web Form'){
                        let headernames = ['Company','Description','IMG']
                        let headertypes = ['Single Line Text','Multiple Lines Text','Single File Upload']
                        elmnt.FormFields = headernames.map((head,headindex) => {
                            let headobj = {
                                Name: head.split(' ').join('_'),
                                DisplayName: head,
                                Type: headertypes[headindex]
                            }
                            if(headobj.Type === 'Date'){
                                headobj.GraphicDisplay = true
                                headobj.GRaphicColor = 'purple'
                            }
                            else if(headobj.Type === 'Multiple Lines Text'){
                                headobj.IsContentEditable = true
                            }
                            else if(headobj.Type === 'Single File Upload'){
                                headobj.FeaturedPhoto = true
                            }
                            else if(headobj.Type === 'Common Field'){
                                headobj.CommonFieldType = 'URL Link'
                            }
                            return headobj
                        })
                    }
                    else if(elmnt.Name === 'FAQ'){
                        elmnt.SelectedFAQ = [
                            {Question: 'What is an FAQ Box',Answer: 'The FAQ box is a box that feed in FAQ'},
                            {Question: 'Why is my FAQ Box blank',Answer: 'The FAQ box feeds from FAQ registeed on your Suite, you have to register FAQ first.'}
                        ]
                    }
                            if(elmntcount === 1){                                
                            elmnt.FlexXLRG =12
                            elmnt.FlexLarge =12
                            elmnt.FlexMedium =12
                            }	
                            if(elmnt.Name === 'Image'){
                                elmnt.Height = 150
                            }
                            return elmnt
                        })      
                    }
            }
            else if (tab.Elements){
                return tab.Elements.map(elmnt => {
                    elmnt.FlexXLRG =12
                    elmnt.FlexLarge =12
                    elmnt.FlexMedium =12	
                    return elmnt
                })
            }
            else{
                tab.FlexXLRG =12
                tab.FlexLarge =12
                tab.FlexMedium =12	
                return [tab]
            }
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           //console.log(tab,tabHeaderFontFamily)
       },
    }
}
</script>

<style>

</style>



