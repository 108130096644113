<template>
  <div>
    <v-list dense v-if="ManualInteraction">
        
      <v-virtual-scroll
        :items="NavUserFavorites"
        :height="NavUserFavorites.length > 4 ? 200 : NavUserFavorites.length*45"
        item-height="40">
        <template v-slot:default="{ item }">
          <v-list-item class="settingsfont" :to="item.Path">
            <!-- <v-list-item-action>
                                <v-icon class="actionicon" color="accent">{{ item.icon }}</v-icon>
                                    
                                  </v-list-item-action>                           -->
            <v-list-item-title class="navoverline">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-list>
    <v-menu
      nudge-right="30"
      v-if="!ManualInteraction"
      :close-on-content-click="false"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">
          {{ CurrentisFavorite ? "mdi-star-off" : "mdi-star-plus-outline" }}
        </v-icon>
      </template>
      <v-card flat width="200">
        <v-list class="settingsfont" dense>
          <v-list-item dense>
            <v-list-item-title v-if="!CurrentisFavorite" class="navoverline">
              <v-icon style="padding-right: 5px" size="18"
                >mdi-star-outline </v-icon
              >Add as Favorite
            </v-list-item-title>
            <v-list-item-title v-if="CurrentisFavorite" class="navoverline">
              <v-icon style="padding-right: 5px" size="18">mdi-star-off </v-icon
              >Remove Favorite
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense v-if="!CurrentisFavorite">
            <v-text-field v-model="FavoriteIcon" label="Icon" />
          </v-list-item>
          <v-list-item dense v-if="!CurrentisFavorite">
            <v-icon>{{ FavoriteIcon }}</v-icon>
          </v-list-item>
          <v-list-item dense v-if="!CurrentisFavorite">
            <v-list-item-avatar :color="FavoriteColor"> </v-list-item-avatar>
            <v-list-item-content>
              <v-autocomplete
                :items="LibraryColors"
                v-model="FavoriteColor"
                label="Color"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-btn
              width="100%"
              outlined
              :color="CurrentisFavorite ? 'red' : 'success'"
              @click="AddPageAsFavorite()"
            >
              {{ CurrentisFavorite ? "Remove" : "Add" }}
            </v-btn>
          </v-list-item>
          <v-menu nudge-right="90" transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title class="navoverline"
                  ><v-icon style="padding-right: 5px" size="18">mdi-star</v-icon
                  >My Favorites</v-list-item-title
                >
              </v-list-item>
            </template>
            <v-list dense>
              <v-virtual-scroll
                :items="NavUserFavorites"
                :height="
                  NavUserFavorites.length > 4
                    ? 200
                    : NavUserFavorites.length * 45
                "
                item-height="40"
              >
                <template v-slot:default="{ item }">
                  <v-list-item class="settingsfont" :to="item.Path">
                    <!-- <v-list-item-action>
                                <v-icon class="actionicon" color="accent">{{ item.icon }}</v-icon>
                                    
                                  </v-list-item-action>                           -->
                    <v-list-item-title class="navoverline">{{
                      item.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list>
          </v-menu>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";

export default {
  props: ["System", "SystemEntities", "Userid", "ManualInteraction"],
  components: {},
  data() {
    return {
      RecordName: "",
      FavoriteIcon: "mdi-star",
      FavoriteColor: "red",
    };
  },
  computed: {
    LibraryColors() {
      let colors = [
        "red",
        "pink",
        "purple",
        "deep-puprle",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "brown",
        "blue-grey",
        "grey",
      ];
      let variations = [
        " lighten-5",
        " lighten-4",
        " lighten-3",
        " lighten-2",
        " lighten-1",
        "",
        " darken-1",
        " darken-2",
        " darken-3",
        " darken-4",
        " darken-5",
      ];
      let finalarray = [];
      variations.map((vary) => {
        colors.map((col) => {
          let obj = col + vary;
          finalarray.push(obj);
        });
      });
      return finalarray;
    },
    CurrentisFavorite() {
      let path = this.$route.path;
      if (this.RelevantUser && this.RelevantUser.FavoriteLinks) {
        let pathcheck = this.RelevantUser.FavoriteLinks.find(
          (obj) => obj.Path === path
        );
        if (pathcheck) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    UserFavorites() {
      return this.RelevantUser && this.RelevantUser.FavoriteLinks
        ? this.RelevantUser.FavoriteLinks
        : [];
    },
    NavUserFavorites() {
      return this.UserFavorites.map((fav) => {
        let favobj = {
          title: fav.DisplayName,
          icon: fav.Icon,
          Path: fav.Path,
          IsRoute: true,
          RouteBlank: false,
        };
        return favobj;
      });
    },
    RelevantUser() {
      return this.Userid === this.userLoggedIn.id
        ? this.userLoggedIn
        : this.UsersArray.find((obj) => obj.id === this.Userid);
    },
    CurrentisFavorite() {
      let path = this.$route.path;
      if (this.RelevantUser && this.RelevantUser.FavoriteLinks) {
        let pathcheck = this.RelevantUser.FavoriteLinks.find(
          (obj) => obj.Path === path
        );
        if (pathcheck) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    UsersArray() {
      return this.$store.state.Users;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    UserisGuest() {
      return this.userLoggedIn && this.userLoggedIn.IsGuestUser;
    },
    userIsEagleViewer() {
      return this.$store.state.IsEagleView;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    userBUID() {
      return this.$store.getters.userBUID;
    },
    userBUChildren() {
      return this.$store.getters.userBUChildren;
    },
    userBUParents() {
      return this.$store.getters.userBUParents;
    },
    WindowHeight() {
      return window.innerHeight;
    },
    SocialItemInteractMethod() {
      return this.$store.state.SocialItemInteractMethod;
    },
    SocialItemInteractMethodProp() {
      return this.$store.state.SocialItemInteractMethodProp;
    },
  },
  watch: {
    SocialItemInteractMethod(v) {
      let acceptedmethods = [];
      if (v && acceptedmethods.includes(v)) {
        if (this.SocialItemInteractMethodProp) {
          this[this.SocialItemInteractMethod](
            this.SocialItemInteractMethodProp
          );
          if (this.SocialItemInteractMethod === "ActivateSelectedElement") {
            this.SingleRecordNavMenuItem = "";
          }
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        } else {
          this[this.SocialItemInteractMethod]();
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        }
      }
    },
  },
  created() {},
  methods: {
    AddPageAsFavorite() {
      let routeitem = this.$router.options.routes.find(
        (obj) => obj.name === "myprofile"
      );
      //console.log("routeitem",routeitem)
      //console.log(this.$route.meta)
      let path = this.$route.path;
      let icon = this.FavoriteIcon;
      let col = this.FavoriteColor;
      let FavoriteLinks = [];
      if (this.RelevantUser.FavoriteLinks) {
        FavoriteLinks = JSON.parse(
          JSON.stringify(this.RelevantUser.FavoriteLinks)
        );
      }
      let pathcheck = FavoriteLinks.find((obj) => obj.Path === path);
      if (pathcheck) {
        confirm(
          "This item is already a favorite. Would you like to remove it from Favorites?"
        ) && this.RemoveFavorite(pathcheck);
      } else {
        let display = "";

        if (this.$route.params.id) {
          this.RecordName = prompt(
            "Please confirm a name for the favorite",
            this.CurrentRecordName
          );
          if (this.RecordName != "") {
            this.StoreFavorite({
              display: this.RecordName,
              color: col,
              icon: icon,
              path: path,
            });
          }
        } else if (this.$route.params.slug) {
          display = this.$route.params.slug + "(" + path.split("/")[1] + ")";
          this.RecordName = prompt(
            "Please confirm a name for the favorite",
            display
          );
          if (this.RecordName != "") {
            this.StoreFavorite({
              display: this.RecordName,
              color: col,
              icon: icon,
              path: path,
            });
          }
        } else {
          display = path.replace("/", "");
          this.RecordName = prompt(
            "Please confirm a name for the favorite",
            display
          );
          if (this.RecordName != "") {
            this.StoreFavorite({
              display: this.RecordName,
              color: col,
              icon: icon,
              path: path,
            });
          }
        }
      }

      // this.RelevantUser.FavoriteLinks.find
    },
    RemoveFavorite(payload) {
      this.StoreFavorite(payload, true);
    },
    StoreFavorite(payload, remove) {
      let FavoriteLinks = [];
      if (this.RelevantUser.FavoriteLinks) {
        FavoriteLinks = JSON.parse(
          JSON.stringify(this.RelevantUser.FavoriteLinks)
        );
      }
      if (!remove) {
        FavoriteLinks.push({
          DisplayName: payload.display,
          Icon: payload.icon,
          Path: payload.path,
          Color: payload.color,
        });
      } else {
        let match = FavoriteLinks.find((obj) => obj.Path === payload.Path);
        if (match) {
          let index = FavoriteLinks.indexOf(match);
          FavoriteLinks.splice(index, 1);
        }
      }
      db.collection("SystemConfig")
        .doc(this.$store.state.ActiveSuiteid)
        .collection("users")
        .doc(this.RelevantUser.id)
        .update({
          FavoriteLinks: FavoriteLinks,
        });
    },
  },
};
</script>

<style>
</style>



