
          <template>
<v-card
      flat
      class="transparent"
      
    >
 <v-layout column class="fab-container" id="myBtn">    
    <v-btn @click="NavigatetoWikiDataMode()" v-if="userIsAdmin && !$store.state.TemplateView" icon x-large><v-icon  color="blue" x-large>mdi-database</v-icon>
       </v-btn>
  </v-layout>
 <v-snackbar v-model="FriendRequestSnackbar" :timeout="4000" top color="success">
      <span>Your friendship request to {{UserData.Full_Name}} has been sent!...</span>
      <v-btn color="white" @click="FriendRequestSnackbar = false">Close</v-btn>
    </v-snackbar>
  <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          dark
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>This is the My Profile help sheet. What would you want to know?</div>
      </v-sheet>
    </v-bottom-sheet>

 <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
             <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{System.Name}} Member</h1>
                <h4 class="headline">{{UserData.Full_Name}}</h4>
              </v-col>
            </v-row>
             
            </v-parallax>   


  <v-banner two-line :sticky="true" class="topbasicoutlined" v-if="EditingUser" height="80px">
   <v-container fluid>
   <v-card width ="100%" flat>
   <v-layout row class="secondary white--text justify-center ">
      <h2 class="headline white--text font-weight-light">My Profile</h2>
    </v-layout>
     <v-btn @click="CancelUserEditing()" color="red" dark
                fab
                small
                top
                left><v-icon>mdi-cancel</v-icon></v-btn>
                <v-btn @click="UpdateUser()" color="#012840" dark
                fab
                small
                absolute
                bottom
                right><v-icon>mdi-content-save</v-icon></v-btn>
      </v-card>
    </v-container>
  </v-banner>
  <v-layout class="justify-center" style="margin-top:60px;">
     <v-flex xl8 lg10 md10 >
        <v-parallax v-if="!UserData.Parallax"
            height="150"
            src="@/assets/RASolidA-Light.jpeg"
            class="mx-3"
            >
             
            </v-parallax>   
            <v-parallax  v-if="UserData.Parallax"
            height="150"
            :src="UserData.Parallax"
            class="mx-3"
            >
            </v-parallax>
       <v-layout row v-if="!EditingUser">

         
          
      
        <v-col cols="12" md="12" class="justify-center">
           
          <v-layout class="justify-center"  style="margin-top:-115px;">
          
           <!-- <v-avatar
              color="grey"
              size="164" 
              class="elevatedsectionheader"                     
            >
            <v-img  :src="UserData.Profile_Photo ? UserData.Profile_Photo : require('@/assets/BlankProfilePic.png')">
                <svg viewBox="0 0 100 100"  xml:space="preserve" v-if="UserData.MemberAvatarRibbon">
                      <g>
                      <path opacity="0.9" :fill="UserData.MemberAvatarRibbon.Color.hexa" class="st1" d="M64.37,33.12c-3.7,0.99-7.15,2.43-10.32,4.25c24.69-0.41,47.43,15.92,54.11,40.86
		c2.21,8.23,2.42,16.53,0.95,24.38c8.58-10.45,12.18-24.74,8.41-38.8C111.32,40.66,87.52,26.92,64.37,33.12z"/>
                    </g>
                       <path  fill="transparent" id="curve" class="st4"  d="M65,36c0,0,22.87,3.33,35,20c16,22,13,36,13,36"/>
                        <text>	<textPath  xlink:href="#curve" startOffset="30%">
                      <tspan  class="st6 st7" style="fill:#FFFFFF;font-size:10px;">{{UserData.MemberAvatarRibbon.Name}}</tspan>	</textPath>
                      </text>
                    </svg>
            </v-img>
          </v-avatar> -->
          <SiteMemberAvatar class="goldgradbg elevatedsectionheader" :System="System" :SystemEntities="SystemEntities" :member="UserData"
          />
           
          </v-layout>
          
          <v-layout class="justify-center overlayed borderthicknobottom mx-3">
               <v-menu >
            <template v-slot:activator="{ on }">
          <v-card :dark="AppisDarkMode" tile flat height="60px" width="100%">
              <v-spacer>
              </v-spacer>
                  <v-icon  v-on="on" >mdi-dots-vertical</v-icon>
                  
              </v-card>
            </template>
            <v-list :dark="AppisDarkMode">
             
                <v-list-item v-if="userIsAdmin" @click="FeatureBusinessMember()">
                  <v-list-item-title>
                    {{BusinessMemberTitle}}
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="primary">mdi-account-star-outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="UserisFeaturedBusinessMember" @click="DownloadQR('https://ignite-youth.firebaseapp.com','/BusinessMember/',Memberid,UserData.Full_Name+' BusinessUser_QR')">
                  <v-list-item-title>
                    Download Business QR
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="primary">mdi-qrcode</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>

        <v-card :dark="AppisDarkMode" tile flat>
            <v-btn-toggle class="accent">
              <v-btn small @click="SendFriendrequest()"  v-if="!RequestStatus && !IsFriend && Memberid !== UserRecord.id"><span v-if="!$vuetify.breakpoint.xs"><span v-if="!$vuetify.breakpoint.sm">Add </span>Friend</span><v-icon color="success">mdi-account-multiple-plus</v-icon></v-btn>
              <v-btn small v-if="RequestStatus && !IsFriend" @click="ReleventRequestProcess()" class="success--text"><span v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" v-html="RequestStatusMessage"></span><v-icon color="success">{{RequestStatusIcon}}</v-icon></v-btn>
              <v-btn small v-if="IsFriend" @click="UnFriendUser()"><span v-if="!$vuetify.breakpoint.xs">UnFriend</span><v-icon color="red">mdi-account-multiple-minus</v-icon></v-btn>
              <!-- <v-btn small v-if="RequestStatus && RequestStatus === 'Sent' && RelationtoRequest === 'Recipient'" @click="IgnoreFriendRequest()"><span v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">Ignore Request</span><v-icon color="warning">mdi-timer-sand-empty</v-icon></v-btn> -->
              <v-btn small v-if="Memberid !== UserRecord.id"><span v-if="!$vuetify.breakpoint.xs">Block<span v-if="!$vuetify.breakpoint.sm"> User</span></span><v-icon color="red">mdi-cancel</v-icon></v-btn>
            </v-btn-toggle>
          </v-card>



          </v-layout>
          <v-layout class="justify-center borderthicknotop mx-3">
         
            <v-card tile flat :dark="AppisDarkMode" width="100%"  class="text-xs-center">
              <h6 class="subheader centertext">{{UserData.Position}}</h6>
              <h4 class="headline font-weight-thin centertext my-1">{{UserData.Full_Name}}</h4>
              <p class="centertext grey--text mx-2 my-2">
               {{UserData.FavoriteQuote}}
              </p>
         
              </v-card>
         
            
          
          </v-layout>
          
          </v-col>
       </v-layout>
       <v-layout class="justify-center borderthicknotop mx-3">
        <v-card  flat tile width="100%" style="padding-bottom:40px;">
        <v-list-item v-if="System.Member_Ribbon && UserCanEditMember">
             <v-select
          v-model="UserData.MemberAvatarRibbon" label="Avatar Status" item-text="Name"
           :items="System.Member_Ribbon.Options" return-object
           @change="UpdateMemberAvatar()"
          >
          <template v-slot:item="data">
           <v-chip :color="data.item.Color ? data.item.Color.hexa : 'black'" class="white--text"> {{data.item.Name}}</v-chip> - <span class="caption">{{data.item.Description}}</span>
          </template>
             </v-select>
          </v-list-item>
        <v-list-item class="background darken-1 justify-space-between">
          
            <v-list-item-content>
              <v-list-item-title>
                Social Links
              </v-list-item-title>
              <v-list-item-subtitle v-if="UserCanEditMember">
               <v-btn outlined @click="EditingSocialLinks = !EditingSocialLinks" small>{{!EditingSocialLinks ? 'Edit' : 'Cancel'}}</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
                  
                  <v-btn :to="UserData.FeaturedMemberSite" icon v-if="FeaturedMemberActive && UserData.FeaturedMemberSite" >
                    <v-icon color="pink" small>mdi-account-star-outline</v-icon>
                  </v-btn>
                  <v-btn :disabled="!UserData.GroupSite" icon >
                    <!-- UserData.GroupSite -->
                    <v-icon  color="orange" small>mdi-account-group</v-icon>
                  </v-btn>
                  <v-btn :href="UserData.FacebookLink" target="_new" :disabled="!UserData.FacebookLink" icon >
                    <!-- UserData.LinkedInLink -->
                    <v-icon color="blue darken-1" small>mdi-facebook</v-icon>
                  </v-btn>
                  <!-- <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn> -->
                  <v-btn :href="UserData.LinkedInLink" target="_new" :disabled="!UserData.LinkedInLink" icon >
                    <!-- UserData.LinkedInLink -->
                    <v-icon color="blue darken-4" small>mdi-linkedin</v-icon>
                  </v-btn>
          </v-list-item>
          <v-list-item v-if="EditingSocialLinks">
            <v-text-field v-model="UserData.FacebookLink" prepend-icon="mdi-facebook" label="Facebook"/>
            <v-text-field v-model="UserData.LinkedInLink" prepend-icon="mdi-linkedin" label="LinkedIn"/>
          </v-list-item>
          <v-list-item v-if="EditingSocialLinks">
            <!-- really only if you have Groups I guess it don't matter yes -->
            <v-select :items="UserGroups" return-object item-text="name" v-model="UserData.GroupSite" prepend-icon="mdi-account-group" label="Business Group"/>
            <!-- <v-select v-if="FeaturedMembersAllowed" v-model="UserData.FeaturedMemberSite" prepend-icon="mdi-account-star-outline" label="My Featured Page"/> -->
          </v-list-item>
            <v-card-actions v-if="EditingSocialLinks">
              <v-spacer>
              </v-spacer>
              <v-btn @click="UpdateSocialLinks()" outlined color="success">
                  Save
              </v-btn>
            </v-card-actions>
            <v-list-item>
              <v-spacer>
              </v-spacer>
                <v-btn @click="EditingAboutMe = !EditingAboutMe" v-if="UserCanEditMember" outlined>{{!EditingAboutMe ? 'Edit About Me' : 'Cancel'}}
              </v-btn>
              </v-list-item>
            <v-card-text>
            
              <div v-html="UserData.AboutMe" v-if="!EditingAboutMe">
              </div>
               <ContentEditableField style="padding:15px;" v-if="EditingAboutMe"
                    :FieldObject="UserData" :FieldName="'AboutMe'"
                    :FieldValue="UserData.AboutMe" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-card-text>
            <v-card-actions v-if="EditingAboutMe">
              <v-spacer>
              </v-spacer>
              <v-btn @click="UpdateAboutMe()" outlined color="success">
                  Save
              </v-btn>
            </v-card-actions>
        </v-card>
       </v-layout>
        <v-layout class="justify-center mx-3">
        <v-card :dark="AppisDarkMode" flat tile class="transparent" width="100%" style="padding-bottom:40px;"
          min-height="500">
         <v-tabs
            fixed-tabs
           
            :dark="AppisDarkMode"
        >
            <v-tab v-if="SocialPostsActive">
            Wall
            </v-tab>
            <v-tab-item v-if="SocialPostsActive">
              <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
               <SocialSitePosts v-if="UserData.id && UserData.Full_Name" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :UsersArray="UsersArray" :UserObj="UserData"  :AppisDarkMode="AppisDarkMode" class="mx-3" :UserRecord="UserRecord" :ShareName="UserData.Full_Name" :WallType="'SiteMember'" :UserorGroupID="Memberid" :UserorGroupView="true"  />
              </v-card>
               
           
            </v-tab-item>
            <v-tab v-if="SocialPostsActive">
            Photos
            </v-tab>
            <v-tab-item v-if="SocialPostsActive">
              <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
                <SocialSitePosts v-if="UserData" :dark="AppisDarkMode" class="mx-3" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" 
                  :UserRecord="UserRecord" :UserorGroupID="UserData.id" :UserView="false" :GalleryTimeline="true" :Slice="10" />
              </v-card>
               
           
            </v-tab-item>
            <v-tab>
            Activities
            </v-tab>
            <v-tab-item>
            <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
                <UserActivities  :UsersArray="UsersArray"  class="mx-3" :dark="AppisDarkModeCard" :AppisDarkMode="AppisDarkMode" :UsersRecord="UserRecord" :Origin="'Page'" :Excerpt="20" :HasFilter="false" :UserView="true" :UserID="Memberid" />  
              </v-card>
            </v-tab-item>
            <v-tab v-if="ReferencedPluginDataBase.Site_Groups && UserData">
            Groups
            </v-tab>
            <v-tab-item v-if="ReferencedPluginDataBase.Site_Groups && UserData">
              <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
                <UserGroups :UsersArray="UsersArray"  :View="'Option2'" :Filter="GroupCategoryTypes" :UserView="true" :UserID="Memberid" :AppisDarkMode="AppisDarkMode"/>  
              </v-card>
            </v-tab-item>
            
            <v-tab v-if="ReferencedPluginDataBase.Site_Blogs && UserData">
            Blogs
            </v-tab>
             <v-tab-item v-if="ReferencedPluginDataBase.Site_Blogs && UserData">
             <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
                <UserBlogs  :UsersArray="UsersArray"  :SliderView="false" :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="250" :Slice="4" :Filter="null" :UserView="true" :UserID="Memberid"/>  
              </v-card>
            </v-tab-item>
            <v-tab v-if="ReferencedPluginDataBase.Site_Articles">
            Articles
            </v-tab>
            <v-tab-item v-if="ReferencedPluginDataBase.Site_Articles && UserData">
               <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
                <SiteArticlesFeed  :UsersArray="UsersArray" :dark="AppisDarkMode" :UserRecord="UserRecord" :Origin="'Page'" :Excerpt="250" :Slice="20" :Filter="InthePublicFilter" :UserView="true" :UserID="Memberid" />  
               </v-card>
            </v-tab-item>
            <v-tab v-if="UserData">
            Friends
            </v-tab>
            <v-tab-item v-if="UserData">
              <v-card flat tile height="100%" width="100%" :dark="AppisDarkMode">
               <FriendsList  :UsersArray="UsersArray" :UserView="true" :UserID="Memberid" :AppisDarkMode="AppisDarkMode"/>
                </v-card>
            </v-tab-item>
        </v-tabs>
      </v-card>
        </v-layout>
     </v-flex>
  </v-layout>
  
    

  </v-card>
</template>




<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

import SiteMemberAvatar from '@/components/SocialNetwork/SiteMemberAvatar';
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';
import UserActivities from '@/components/SocialNetwork/SystemActivitiesFeed';
import UserGroups from '@/components/SuitePlugins/Groups/GroupsFeed';
import UserBlogs from '@/components/SuitePlugins/Blogs/SiteBlogsFeed';
import SiteArticlesFeed from '@/components/SuitePlugins/Articles/SiteArticleFeed';
import FriendsList from '@/components/SocialNetwork/FriendsList';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
  props: ['AppisDarkMode','AppisDarkModeCard','System'],
  middleware: 'auth',
components: {
  SiteMemberAvatar,
  SocialSitePosts,
    UserActivities,
    UserGroups,
    UserBlogs,
    SiteArticlesFeed,
    FriendsList,
    ContentEditableField
},
  data() {
    return {
      EditingAboutMe: false,
      EditingSocialLinks: false,
      UserisFeaturedBusinessMember: false,
      
        FriendRequestSnackbar: false,
        RequestStatus: '',
        RelationtoRequest: '',
        RequestStatusMessage: '',
        RequestStatusIcon: '',
        MySentFriendshipRequests: [],
        MyReceivedFriendshipRequests: [],
        MyFriendships: [],
        IsFriend: false,
        ExistingRelationship: '',
      rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
        UserInfoTabs: [
            'Activities',
            'Groups',
            'Blogs',
            'Articles',

        ],
      ActivitiesArray: [],
      NotesArray: [],
      TaskFilterDate: '',
      TaskDialog: false,
      NewTask: {
            Name: '',
            description: '',
            activitytype: 'Task',
            regardingtype: 'User',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
      DefaultNewTask: {
            Name: '',
            description: '',
            activitytype: 'Task',
            regardingtype: 'User',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
            creatorfullname: '',
            creatorid: ''
      },
      pickerDate: null,
      TaskStatusFilter: 'Open',
      CreatedOnmenu: false,
      DueDatemenu: false,
      FullName:  '',
      
      
      
      
      EditingUser: false,
      dialog: false,
      initialize: '',
      search: '',
      UserData: {},
      headers: [
      
        { text: 'Actions', value: 'action', sortable: false },
      ],
      FullName:  '',
      Name:  '',
      Surname:  '',
      BusinessUnit:  {id: '', Name: ''},
      Manager:  {id: '', Full_Name: ''},
      ProfilePhoto:  '',
      Managerfullname: null,
      profilephotofilesnackbar: false,
      selectedprofilephotoFile: '',
      profilephotolocalURL: '',
      BusinessUnitArray: [],
      BusinessUnitfilter: {Name: ''},ManagerArray: [],
      Managerfilter: {Full_Name: ''},
      EditingUser: false,
      dialog: false,
      initialize: '',
      search: '',
      helpsheet: false,
      headers: [
        
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      Buildsbysystem: [
        {
          id: "02y7N2yNELwRryTuv8p7",
          tempname: "User"
        },
        
        {
          id: "0zo5rPBdtqHTDgJagAsB",
          tempname: "Account"
        },
        
        {
          id: "V8X6lZHMZLxAlqUwXF6Y",
          tempname: "Business Unit"
        },
        ],
      AssignedRoles: [],
      SecurityRoles: [
      {
        Builds: [
          {
          createBoolean: true,
          createUnitConfig: "All",
          deleteBoolean: true,
          deleteUnitConfig: "All",
          getBoolean: true,
          getUnitConfig: "All",
          id: "02y7N2yNELwRryTuv8p7",
          listBoolean: true,
          listUnitConfig: "All",
          tempname: "User",
          updateBoolean: true,
          updateUnitConfig: "All"
          },
        
          {
          createBoolean: true,
          createUnitConfig: "All",
          deleteBoolean: true,
          deleteUnitConfig: "All",
          getBoolean: true,
          getUnitConfig: "All",
          id: "0zo5rPBdtqHTDgJagAsB",
          listBoolean: true,
          listUnitConfig: "All",
          tempname: "Account",
          updateBoolean: true,
          updateUnitConfig: "All"
          },
        
          {
          createBoolean: true,
          createUnitConfig: "All",
          deleteBoolean: true,
          deleteUnitConfig: "All",
          getBoolean: true,
          getUnitConfig: "All",
          id: "V8X6lZHMZLxAlqUwXF6Y",
          listBoolean: true,
          listUnitConfig: "All",
          tempname: "Business Unit",
          updateBoolean: true,
          updateUnitConfig: "All"
          },
        
        ],
        Description: "Add more details about this new security role here",
        DisplayName: "System Admin",
        Name: "Name 1"
          },
      {
        Builds: [
          {
          createBoolean: true,
          createUnitConfig: "All",
          deleteBoolean: false,
          deleteUnitConfig: "",
          getBoolean: true,
          getUnitConfig: "All",
          id: "02y7N2yNELwRryTuv8p7",
          listBoolean: true,
          listUnitConfig: "All",
          tempname: "User",
          updateBoolean: true,
          updateUnitConfig: "All"
          },
        
          {
          createBoolean: true,
          createUnitConfig: "UnitDown",
          deleteBoolean: false,
          deleteUnitConfig: "",
          getBoolean: true,
          getUnitConfig: "UnitDown",
          id: "0zo5rPBdtqHTDgJagAsB",
          listBoolean: true,
          listUnitConfig: "UnitDown",
          tempname: "Account",
          updateBoolean: true,
          updateUnitConfig: "UnitDown"
          },
        
          {
          createBoolean: true,
          createUnitConfig: "All",
          deleteBoolean: false,
          deleteUnitConfig: "",
          getBoolean: true,
          getUnitConfig: "All",
          id: "V8X6lZHMZLxAlqUwXF6Y",
          listBoolean: true,
          listUnitConfig: "All",
          tempname: "Business Unit",
          updateBoolean: true,
          updateUnitConfig: "All"
          },
        
        ],
        Description: "Add more details about this new security role here",
        DisplayName: "Sales Representative",
        Name: "Name 2"
          },],
      SecurityRoleIndex: -1,
      SecurityRoledialog: false,
      editedRole: {Name: '', DisplayName: '', Description: '', Builds: []},
      defaultRole: {Name: '', DisplayName: '',Description: '', Builds: []},
      RolesArray: [],
      snackbar: false,
      EditedUsersnackbar: false,
      UserEditdialog: false,
      newUserdialog: false,
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      IsTeamActivityManager: false,
      UsersWantsEmailsOnAllNotifications: true,
      BusinessUnitsArray: [],
      Owner: {Name: '', Surname: '', id: '', Full_Name: ''},
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      BusinessUnit: {},
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ManagersToUpdate: [],
      SubordindatesArray: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [
  "System Admin",
  "Sales Representative"
],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [
  "System Admin"
],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {}
    }
  },
  
  computed: {
    MemberColRef(){
      return this.ConfigPath ? this.ConfigPath.collection('sampleusers') : db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users')
    },
    UserCanEditMember(){
      return this.UserisTemplateOwner || this.UserisMember
    },
    UserisMember(){
      return this.UserData && this.userLoggedIn && this.UserData.id === this.userLoggedIn.id
    },
    UserGroups(){
      return this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.map(group => {
          let groupobj = {name: group.name,id: group.id}
          return groupobj
      }) : []
    },
    FeaturedMemberDefaultPage(){
      return this.$store.state.FeaturedMemberDefaultPage
    },
    FeaturedMemberActive(){
        return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
    },
    FeaturedMembersAllowed(){
        return this.FeaturedMemberActive && this.userLoggedIn.IsFeaturedMember || this.FeaturedMemberActive && this.userIsAdmin
        // && this.userIsAdmin || this.FeaturedMemberActive && this.System && this.System.Featured_Members_Self_Manage
    },
    SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/SiteMember/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/SiteMember/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/SiteMember/' : '/SiteMember/'
        }
      },
    Memberid(){
      return this.$store.state.TemplateView ? this.$route.params.slug : this.$route.params.id
    },
     UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
       SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SystemConfig').doc(this.ActiveSuiteTemplate.id) : 
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
    ReferencedPluginDataBase(){
        // console.log('this.ActiveMarketTemplate',this.ActiveMarketTemplate)
        return this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.PluginDataBase ? this.ActiveSuiteTemplate.PluginDataBase : 
        this.ActiveMarketTemplate && this.ActiveMarketTemplate.PluginDataBase ? {} : this.PluginDataBase
    },
    PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
    GroupCategoryTypes(){
      return this.System && this.System.Group_Categories ? this.System.Group_Categories.Options : []
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    SocialPostsActive(){
      return this.System.Disable_Guest_Posts && this.userLoggedIn && !this.$store.state.UserisGuest || !this.System.Disable_Guest_Posts && this.userLoggedIn
    } ,
    userIsAdmin () {
            return this.$store.state.IsAdmin
        },
     BusinessMemberTitle(){
      return this.UserisFeaturedBusinessMember? 'Update Business Member' : 'Feature Business Member'
    },
    ActivitiesSearched() {
      return this.ActivitiesArray.filter(Activity => {
        if(Activity.Name){return Activity.Name.toLowerCase().includes(this.search.toLowerCase())}}).filter(Activity => {if(Activity.description){return Activity.description.toLowerCase().includes(this.search.toLowerCase())}
      })
      },
      ActivitiesFilteredandSearched() {
      return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
      },
      TasksFilteredandSearched(){
          return this.ActivitiesFilteredandSearched.filter(activity => {
              return activity.activitytype === 'Task' && activity.duedate.includes(this.pickerDate) && activity.status.includes(this.TaskStatusFilter)
          })
      },
      TasksFilteredandSearchedTable(){
          return this.TasksFilteredandSearched.filter(activity => {
              return activity.duedate === this.TaskFilterDate
          })
      },
        PotentialManagers() {
      return this.UsersArray.filter(unit => {
        return !this.SubordindatesArray.includes(unit.id)
      })
    },
    ActiveVisitor(){
      return this.$store.state.ActiveVisitor
    },
   
    },
  watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleSiteArticleCoverIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      ActiveVisitor: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue && newvalue && newvalue.id){ 
                  this.UserRecord = newvalue 
                }
            },deep: true
      }
    },
  created(){
    if(this.$store.state.TemplateView){
      this.GetSampleUser()
      if(this.ActiveVisitor){
        this.UserRecord = this.ActiveVisitor
      }
    }
    else{
       this.IntranetViewToggle()

    this.GetRequestingUser()
    }
   
    
    // window.addEventListener('scroll', this.handleScroll)

  },
  methods: {
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        //console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        //console.log(this.field,FieldObject,this.Record)
        },
    UpdateAboutMe(){
      //console.log(this.UserData.MemberAvatarRibbon)
      this.MemberColRef.doc(this.UserData.id).update({
        AboutMe: this.UserData.AboutMe
      }).then(updated => {
        this.EditingAboutMe = false
      })
    },
    UpdateSocialLinks(){
      let updobj = {}
      let props = ['FacebookLink','LinkedInLink','GroupSite','FeaturedMemberSite']
      props.map(prp => {
        if(typeof this.UserData[prp] !== 'undefined'){
          updobj[prp] = this.UserData[prp]
        }
      })
      if(this.FeaturedMembersAllowed && this.FeaturedMemberDefaultPage){
        updobj.FeaturedMemberSite = '/FeaturedMember/'+this.userLoggedIn.id+'/'+this.FeaturedMemberDefaultPage
      }
      this.MemberColRef.doc(this.UserData.id).update(updobj).then(updoc => {
        this.EditingSocialLinks = false
      })
    },
    UpdateMemberAvatar(){
      //console.log(this.UserData.MemberAvatarRibbon)
      this.MemberColRef.doc(this.UserData.id).update({
        MemberAvatarRibbon: this.UserData.MemberAvatarRibbon
      })
    },
    NavigatetoWikiDataMode(){
      let newpath = '/User/'+this.Memberid
      //console.log(newpath)
      this.$emit('IntranetViewToggle',false)
      this.$router.push(newpath)
    },
    ActivateMultipleFilesUploadNotification(boolean){
      this.$emit('ActivateMultipleFilesUploadNotification',boolean)
    },
    DownloadQR(appurl,path,final,name){
      var QRAPI = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data="
      var options = {
                      method: 'GET',
                      mode: 'cors'
                    }
      var RoutingUrl = path+final
      var hostingURL = appurl  
      var QRUrl = hostingURL+RoutingUrl
      var FetchURL = QRAPI+QRUrl
      fetch(FetchURL, options).then((resp) => resp.blob()
        .then(QRimage => {
        var QRLocalURL = URL.createObjectURL(QRimage)
            var element = document.createElement('a')
            element.setAttribute('href',QRLocalURL)
            element.setAttribute('download', name+' QRCode')
            element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
      )
    },
    FeatureBusinessMember(){
      let userobj = this.UserData
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.Memberid).onSnapshot(roledata => {
        let userroledoc = roledata.data()
        let BUid = userroledoc.Business_Unitid
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(BUid).onSnapshot(budata => {
          let budoc = budata.data()
          if(budoc.Address_Street_Number && budoc.Address_Street_Name){
            userobj.AddressLine1 = budoc.Address_Street_Number+' '+budoc.Address_Street_Name
          }
          if(budoc.Address_Suburb){
            userobj.AddressLine2 = budoc.Address_Suburb
          }
          if(budoc.Address_City){
            userobj.AddressLineCity = budoc.Address_City
          }

          if(budoc.Address_Country_Region){
            userobj.AddressLineCountry = budoc.Address_Country_Region
          }
          if(budoc.Address_Postal_Code){
            userobj.AddressLinePostalCode = budoc.Address_Postal_Code
          }
          if(budoc.Address_State_Province){
            userobj.AddressLineProvince = budoc.Address_State_Province
          }    
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessmembers').doc(this.Memberid).set(userobj).then(doc => {
            console.log('set user')
          })      
        })
        
      })
      
    },
    IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      UnFriendUser(){
          console.log('no action yet')
      },
      ReleventRequestProcess(){
          console.log('yep we try do something')
          if(this.RequestStatus === 'Sent' && this.RelationtoRequest === 'Recipient'){
              console.log('it is a friend approval let us run method')
              this.ApproveFriendRequest()
          }
      },
      ApproveFriendRequest(){
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('friendrequests').doc(this.ExistingRelationship.id).update({
              RequestStatus: 'Approved',
              Modified_On: new Date(),
              Modified_By: {id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname , Full_Name:this.UserRecord.Full_Name},
          })
          let FriendIDStrings = []
          let FriendIDKeys = {}
            if(this.UserRecord.FriendIDStrings){
                FriendIDStrings = this.UserRecord.FriendIDStrings
            }
            if(this.UserRecord.FriendIDKeys){
                FriendIDKeys = this.UserRecord.FriendIDKeys
            }
            FriendIDStrings.push(this.Memberid)
            FriendIDKeys[this.Memberid] = true
             this.MemberColRef.doc(this.UserRecord.id).update({
                    FriendIDKeys: FriendIDKeys,
                    FriendIDStrings: FriendIDStrings
                })
                const functions = firebase.functions();
                const UpdateUserFriendList = functions.httpsCallable('UpdateUserFriendList');
                let data = {
                  Adding: true,
                  friendid: this.UserRecord.id,
                  uid: this.Memberid,
                  siteid: this.$store.state.ActiveSuiteid
                }
                UpdateUserFriendList(data).then(result => {
                  console.log(result)
                })

                let NewFriendship = {
                    RequestDocID: this.ExistingRelationship.id,
                    Requestorid: this.Memberid,
                    Recipientid: this.UserRecord.id,
                    Date: new Date()
                }
                NewFriendship[this.Memberid] = true
                NewFriendship[this.UserRecord.id] = true
                let vm = this
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('friendships').add(NewFriendship).then(doc => {
                   const NewNotification = {
                    freidnshipid: doc.id,
                    Type: 'Friendship Approved',
                    Header: this.UserRecord.Full_Name+' accepted your Friend Request.',
                    Content: 'Click to view profile',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: this.UserData.Full_Name,
                    Ownerid: this.Memberid,
                    Read: false,
                    Path: '/SiteMember/'+this.UserRecord.id,
                    Message: 'Click to view Profile',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
                    const systemactivity = {
                            user: vm.UserRecord,
                            contentvar: 'became friends with',
                            location: '/SiteMember/',
                            docname: this.UserData.Full_Name,
                            docid: this.Memberid,
                            type: 'New Friendship',
                          }                          
                          vm.$store.dispatch('createSystemActivity',systemactivity)
                })
                
          

            
      },
      IgnoreFriendRequest(){

      },
      SendFriendrequest(){
          let NewFriendRequest = {
              Requestor: {id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname , Full_Name:this.UserRecord.Full_Name},
              Requestorid: this.UserRecord.id,
              Created_On: new Date(),
              Recipient: {id: this.Memberid,Name: this.UserData.Name,Surname: this.UserData.Surname , Full_Name:this.UserData.Full_Name},
              Recipientid: this.Memberid,
              RequestStatus: 'Sent'
          }
          let vm = this
          if(!this.$store.state.TemplateView){
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('friendrequests').add(NewFriendRequest).then(doc => {
              vm.FriendRequestSnackbar = true
              let MyrequestObj = {
                  Recipientid: NewFriendRequest.Recipientid,
                  DocumentID: doc.id
              }
              vm.MySentFriendshipRequests.push(MyrequestObj)
              vm.MemberColRef.doc(vm.UserRecord.id).update({
                  MySentFriendshipRequests: vm.MySentFriendshipRequests
              })
              const NewNotification = {
              taskid: doc.id,
              Type: 'Friendship Requested',
              Header: 'New Friend Request - '+NewFriendRequest.Requestor.Full_Name,
              Content: 'Click to view profile',
              CreatorFullName: NewFriendRequest.Requestor.Full_Name,
              CreatorID: NewFriendRequest.Requestorid,
              Owner: NewFriendRequest.Recipient,
              Ownerid: NewFriendRequest.Recipientid,
              Read: false,
              Path: '/SiteMember/'+NewFriendRequest.Requestorid,
              Message: NewFriendRequest.Requestor.Full_Name+' sent you a Friend Request.',
                    Date: new Date()
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

            })
          })
          }
          else{
            let ref = db.collection('samplefriendrequests').doc()
            let newdocid = ref.id
            const NewNotification = {
              taskid: newdocid,
              Type: 'Friendship Requested',
              Header: 'New Friend Request - '+NewFriendRequest.Requestor.Full_Name,
              Content: 'Click to view profile',
              CreatorFullName: NewFriendRequest.Requestor.Full_Name,
              CreatorID: NewFriendRequest.Requestorid,
              Owner: NewFriendRequest.Recipient,
              Ownerid: NewFriendRequest.Recipientid,
              Read: false,
              Path: this.SingleRoutePrefix+NewFriendRequest.Requestorid,
              Message: NewFriendRequest.Requestor.Full_Name+' sent you a Friend Request.',
                    Date: new Date()
            }
            console.log(NewNotification)
            this.ConfigPath.collection('samplenotifications').add(NewNotification).then(notedoc => {

            })
          }
      },
    AddNote(){
      let NewNote = {
        Title: 'New Note',
        Owner: {Full_Name: this.UserRecord.Full_Name, id:this.UserRecord.id},
        Description: 'Add Description to the Note',
        Color: '#ECEC93FF',
        TitleColor: '#EBEB6C',
        Dark: false
      }
      this.NotesArray.push(NewNote)
      this.UpdateNotesArray()
    },
    LightenColor(note) {
    let color = note.Color
    let num = parseInt(color.replace("#",""), 16)
    let amt = Math.round(2.55 * 25)
    let R = (num >> 16) - amt
    let B = (num >> 8 & 0x00FF) - amt
    let G = (num & 0x0000FF) - amt;

    note.TitleColor = (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    },
    ToggleNoteDark(note){
      if(note.Dark === true){
        note.Dark = false
        this.UpdateNotesArray()
      }
      else{
        note.Dark = true
        this.UpdateNotesArray()
      }
    },
    UpdateNotesArray(){
      this.MemberColRef.doc(this.UserRecord.id).update({
        notesarray: this.NotesArray
      })
    },
    DateFormatter(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
    },
    SaveNewTask(){
      const TasktoAdd = {
            Name: this.NewTask.Name,
            description: this.NewTask.description,
            activitytype: 'Task',
            regardingtype: 'User',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: this.DateFormatter(this.NewTask.duedate),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            regardingrecord: {
              Link:'/Account/'+this.UserRecord.id, Name:this.CompanyName, companyname:this.CompanyName,id: this.UserRecord.id
            },

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      if(typeof this.BusinessUnit.id !== 'undefined'){
        TasktoAdd.Business_Unitid = this.BusinessUnit.id
      }
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.activitytype === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Name,
              Content: TasktoAdd.description,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.OwnerOwner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname,
                    Date: new Date()
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },

    GetActivities( regardingquery) {

      regardingquery.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },
    AssignUsersHierarchy(){
      console.log('the first call')
      console.log(this.Manager)
      let CurrentUnitObj = this.ManagerArray.find(obj => obj.id == this.UserRecord.id)
      let CurrentUnitIndex = this.ManagerArray.indexOf(CurrentUnitObj)
      this.ManagerArray[CurrentUnitIndex].Manager = {Name: this.Manager.Name, id: this.Manager.id}
      this.ManagerArray[CurrentUnitIndex].Managerid = this.Manager.id
      // this.UpdateBusinessUnit()
      this.fixunithierarchy()
    },

    async fixunithierarchy(){
      console.log('I await')

      await this.DestroyOldHierarchy()
      console.log('Done Waiting')

      this.getParentofParent(this.Manager)
    },


    async DestroyOldHierarchy(){

      console.log('watch this')
      console.log(this.PotentialManagers)
      let FilteredUsersArray = this.PotentialManagers.filter(unit => {
        return unit.id !== this.UserRecord.id
      })
      let CurrentUser = this.UsersArray.find(obj => obj.id === this.UserRecord.id)
          console.log('current bu')
          console.log(CurrentUser)

      Array.from(Array(CurrentUser.Subordinatearrayquery.length).keys()).map(x => {
        this.RemoveBUfromParentQueryandDBArray(CurrentUser.Subordinatearrayquery[x])
      })
    },

    RemoveBUfromParentQueryandDBArray(BUid){
      console.log("removing "+BUid)
      // let CurrentUser = this.UsersArray.find(obj => obj.id === this.UserRecord.id)
      // let BUidCheck = CurrentUser.Subordinatearrayquery.indexOf(BUid)
      // if(BUidCheck === -1){
      let BUtoRemove = this.UsersArray.find(obj => obj.id == BUid)
      let FilteredUsersArray = this.PotentialManagers.filter(unit => {return unit.id !== BUid && unit.id !== this.UserRecord.id && unit.id !== BUtoRemove.Managerid && unit.Managerid !== this.UserRecord.id})
      console.log('filterarray')
      console.log(FilteredUsersArray)
      FilteredUsersArray.map(unit => {
        if(BUtoRemove.Managerid !== unit.id){
          let dbchildindex = unit.Subordinatearrayquery.indexOf(BUid)
          let querychildobjectprop = unit.SubordinateArrayDBrules[BUid]

          if(dbchildindex !== -1){
            console.log('BUtoRemove '+BUtoRemove.id+' is index '+dbchildindex+' on '+unit.id)
            unit.Subordinatearrayquery.splice(dbchildindex,1)

            }
            console.log('BUtoRemove '+BUtoRemove.id+' has no index on '+unit.id)
            if(typeof querychildobjectprop !== 'undefined'){
              console.log('from '+unit.id)
            delete unit.SubordinateArrayDBrules[BUid]
            this.MemberColRef.doc(unit.id).update({
            SubordinateArrayDBrules: unit.SubordinateArrayDBrules,
            Subordinatearrayquery: unit.Subordinatearrayquery
              })
            }
        }
        else {
          console.log('BU '+BUid+' has '+ unit.id+' as a parent')
        }
        })


        if(this.Manager.Name !== ''){
            this.ManagersToUpdate = []
            console.log('initial call for')
            console.log(this.Manager)
            console.log('Finished')
            console.log('parentarrayvs filtered')
            console.log(this.UsersArray)
            console.log(FilteredUsersArray)
          }
          // }
    },

    UpdateParent(parent){
      console.log('now calling UpdateParent')
        let FilteredUsersArray = this.PotentialManagers.filter(unit => {return unit.id !== this.UserRecord.id})
        let unitdata = FilteredUsersArray.find(obj => obj.id == parent.id)

          if(typeof unitdata.Manager !== 'undefined'){
          console.log('this parent has a parent')
          console.log(unitdata)
          this.getParentofParent(unitdata.Manager)
          }
          else {
          console.log('second okay save it')
          console.log(this.ManagersToUpdate)
          this.ManagerArrayUpdate()
          }


    },

    ManagerArrayUpdate() {
      console.log('now calling ManagerArrayUpdate')
      Array.from(Array(this.ManagersToUpdate.length).keys()).map(x => {
        this.PrepareChildrenList(this.ManagersToUpdate[x])
      })
    },

    PrepareChildrenList(parent){
      console.log('now calling PrepareChildrenList')
      let EditedUnit = this.UsersArray.find(obj => obj.id == this.UserRecord.id)
      // this.AddQueryandDBChildtoParent(EditedUnit.id)
      console.log("parent")
      console.log(parent)
      let unitdata = this.UsersArray.find(obj => obj.id == parent)
      console.log("unitdata")
      console.log(unitdata)
        Array.from(Array(unitdata.Subordinatearrayquery.length).keys()).map(x => {
        this.AddQueryandDBChildtoParent(EditedUnit.Subordinatearrayquery[x],parent)
      })
    },

    AddQueryandDBChildtoParent(length, parent){
      console.log('now calling AddQueryandDBChildtoParent')
      console.log(length)
      let CurrentUser = this.UsersArray.find(obj => obj.id === this.UserRecord.id)
      console.log('each individually')
      console.log('parents first')
      console.log(parent)
        let FilteredUsersArray = this.PotentialManagers.filter(unit => {return unit.id !== this.UserRecord.id})
        let unitdata = this.UsersArray.find(obj => obj.id == parent)
      console.log(unitdata)
      Array.from(Array(CurrentUser.Subordinatearrayquery.length).keys()).map(x => {
        this.NarrowChildofChildQueryandDBtoParent(CurrentUser.Subordinatearrayquery[x], unitdata)
      })
      // this.NarrowChildofChildQueryandDBtoParent(unitdata, CurrentUser)
    },

    NarrowChildofChildQueryandDBtoParent(unitid,unitdata){
      console.log('now calling NarrowChildofChildQueryandDBtoParent')
      let checkon = unitdata.Subordinatearrayquery.indexOf(unitid)
      if(checkon === -1){
        console.log('not on array')
        console.log(unitid)
      unitdata.Subordinatearrayquery.push(unitid)
      console.log('new array')
      console.log(unitdata.Subordinatearrayquery)
      }
      else {
        console.log()
        console.log('is on array')
      }
      let tempchildarrayQuery = unitdata.Subordinatearrayquery
      console.log(unitdata)
      unitdata.SubordinateArrayDBrules[unitid] = true
      let tempchildarrayDBRules = unitdata.SubordinateArrayDBrules
      this.MemberColRef.doc(unitdata.id).update({
        Subordinatearrayquery: tempchildarrayQuery,
        SubordinateArrayDBrules: tempchildarrayDBRules,
      })
      // this.UpdateBusinessUnit()
    },


    getParentofParent(parent){
      console.log('now calling getParentofParent')
      console.log(parent)
      this.ManagersToUpdate.push(parent.id)
      let unitdata = this.UsersArray.find(obj => obj.id == parent.id)

        if(typeof unitdata.Manager === 'undefined'){
          console.log('has no parent')
        this.UpdateParent(unitdata)
        }
        else{
          console.log('yip has parent')
          this.ManagersToUpdate.push(unitdata.Manager.id)
          this.UpdateParent(unitdata.Manager)
        }

    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    
      GetUsers(){
      this.MemberColRef.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    ResetFilters() {},
    
    ResetSearch() {
      this.search = ''
    },
    CancelUserEditing(){
      this.EditingUser = false
    },
    EditUser() {
      this.EditingUser = true
    },

  
       onprofilephotoUpload() {
        var storageRef = firebase.storage().ref('User/' + 'profilephoto/'+this.selectedprofilephotoFile.Name);
        var uploadTask = storageRef.put(this.selectedprofilephotoFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.MemberColRef.doc(this.UserRecord.id).update({
                    Profile_Photo: url
            })
            this.selectedprofilephotoFile = ''
                }),
                  this.profilephotofilesnackbar = true



      },
      onprofilephotoFileselected(event) {
        this.selectedprofilephotoFile = event.target.files[0]
        this.profilephotolocalURL = URL.createObjectURL(this.selectedprofilephotoFile)
      },




      GetBusinessUnitsBusinessUnit() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
      },

      GetUsersManager() {
      this.MemberColRef.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ManagerArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
      },
            closeSecurityRoleDialog(){
              this.SecurityRoledialog = false
              setTimeout(() => {
              this.editedRole = Object.assign({}, this.defaultRole)
              this.SecurityRoleIndex = -1
              }, 300)

            },

          ActivateSecurityRoleEditdialog(role){
            this.SecurityRoleIndex = this.SecurityRoles.indexOf(role)
            this.editedRole = Object.assign({}, role)
            //this.BuildsforRoles()
            this.SecurityRoledialog = true
          },

          AssignRoles(){
            const EditedUser = {
              assignedroles: this.AssignedRoles
            }
            let RolesObject = {}
            this.RolesArray= []
            this.AssignedRoles.map(role => {
              RolesObject[role.DisplayName.split(' ').join('')] = true
              this.RolesArray.push(role.DisplayName)
            })
            this.MemberColRef.doc(this.UserRecord.id).update({
            rolesarrayDBRules: RolesObject,
            rolesarrayQuery: this.RolesArray,
            assignedroles: EditedUser.assignedroles,
            })
          },

          BuildsforRoles(){
         this.Buildsbysystem.map(build => {
            let rolebybuild = this.editedRole.Builds.find(obj => obj.id == build.id)
            if(typeof rolebybuild === 'undefined'){
              let rolebuild = {tempname: build.tempname, id: build.id, getBoolean: false, listBoolean: false, createBoolean: false, updateBoolean: false, deleteBoolean: false}
              this.editedRole.Builds.push(rolebuild)
            }
          })
      },
      GetMySentFriendrequestdoc(docid){
          console.log('getting requests')
          let vm = this
          return new Promise(function(resolve, reject) {
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('friendrequests').doc(docid).onSnapshot(snapshot => {
            if(typeof snapshot.data !== 'undefined'){
            let RequestData = snapshot.data()
             if(typeof RequestData !== 'undefined'){
              console.log(RequestData)
                  vm.RequestStatus = RequestData.RequestStatus
                  if(vm.RequestStatus === 'Sent'){
                      vm.RequestStatusMessage = `<i>Request Sent</i>`
                        vm.RequestStatusIcon = 'mdi-timer-sand'
                  }
             }
              }
              resolve('Get SentRequest Finished')
                })
          })
      },
      GetMyReceivedFriendrequests(){
          let vm = this
          return new Promise(function(resolve, reject) {
          db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('friendrequests').where('Recipientid','==',vm.UserRecord.id).onSnapshot(res => {
              const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    vm.MyReceivedFriendshipRequests.push({
                    ...change.doc.data(),
                    id: change.doc.id
                    })
                }
                })
                 resolve('Get Received FriendRequests Finished')
                })
          })
      },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            
          user.getIdTokenResult().then(IdTokenResult => {
              // console.log('UsersWantsEmailsOnAllNotifications')
              // console.log(IdTokenResult.claims.UsersWantsEmailsOnAllNotifications)
              vm.UsersWantsEmailsOnAllNotifications = IdTokenResult.claims.UsersWantsEmailsOnAllNotifications
            })
            vm.MemberColRef.doc(user.uid).onSnapshot(snapshot => {
                //console.log(typeof snapshot.data)
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
        //       MyFriendships: [],
        // IsFriend
        if(!vm.UserisMember){
                vm.DetermineFriendshipStatus()
        }
                if(vm.UserRecord !== 'undefined'){
                    vm.GetUser()
                }
            })
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          })
      },
      
      GetMyFriendList(vm){
         
          return new Promise(function(resolve, reject) {
            db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('friendships').where(vm.UserRecord.id,'==',true).onSnapshot(res => {
                        const changes = res.docChanges();
                            changes.forEach(change => {
                            if (change.type === 'added') {
                                vm.MyFriendships.push({
                                ...change.doc.data(),
                                id: change.doc.id
                                })
                            }
                            })
                resolve('Get FriendList Finished')
                })
            })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      
        GetSampleUser() {
          let vm = this
            this.ConfigPath.collection('sampleusers').doc(this.Memberid).onSnapshot(snapshot => {
            var user = snapshot.data()
            //console.log('user',user)
            this.UserData = user
            this.UserData.id = this.Memberid
            if(this.UserData.MemberAvatarRibbon && this.System.Member_Ribbon){
              let match = this.System.Member_Ribbon.Options.find(obj => obj.ID === this.UserData.MemberAvatarRibbon.ID)
              this.UserData.MemberAvatarRibbon = match
            }
            this.ConfigPath.collection('samplebusinessmembers').doc(this.Memberid).onSnapshot(busmemsnap => {
               let busmemdata = busmemsnap.data()
               if(busmemdata){
                 this.UserisFeaturedBusinessMember = true
               }                
              })
            })
          
        },
      
        GetUser() {
          let vm = this
            this.MemberColRef.doc(this.Memberid).onSnapshot(snapshot => {
            var user = snapshot.data()
            //console.log('user',user)
            this.UserData = user
            this.UserData.id = this.Memberid
             if(this.UserData.MemberAvatarRibbon && this.System.Member_Ribbon){
              let match = this.System.Member_Ribbon.Options.find(obj => obj.ID === this.UserData.MemberAvatarRibbon.ID)
              this.UserData.MemberAvatarRibbon = match
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessmembers').doc(this.Memberid).onSnapshot(busmemsnap => {
               let busmemdata = busmemsnap.data()
               if(busmemdata){
                 this.UserisFeaturedBusinessMember = true
               }                
              })
            })
          
        },
        
      
      async DetermineFriendshipStatus(){
          //console.log('trying to determine friendships')
          let vm = this
          vm.GetMyFriendList(vm).then(resolve => {
              if(resolve){
                  //console.log(resolve)
              let FriendListCheck = this.MyFriendships.find(obj => obj[this.Memberid] === true)
                  if(FriendListCheck){
                      console.log('is friend')
                      vm.IsFriend = true
                      vm.RequestStatus = 'Friends'
                  }
                  else{
                      //console.log('Is not friend yet, maybe requests?')
                            if(vm.UserRecord.MySentFriendshipRequests){
                                 vm.MySentFriendshipRequests = vm.UserRecord.MySentFriendshipRequests
                                let Hasrelationship = vm.MySentFriendshipRequests.find(obj => obj.Recipientid === vm.Memberid)
                            if(Hasrelationship){
                                    vm.ExistingRelationship = Hasrelationship
                                    vm.RelationtoRequest = 'Requestor'
                                    vm.GetMySentFriendrequestdoc(vm.ExistingRelationship.DocumentID)
                                } 
                                else{
                                    vm.GetMyReceivedFriendrequests().then(resolve => {
                                    console.log('here is received reqeust resolve - ',resolve)
                                    let ReceivedRequestCheck = this.MyReceivedFriendshipRequests.find(obj => obj.Requestorid == this.Memberid)
                                    if(ReceivedRequestCheck){
                                        console.log('is receipient of requests')
                                        vm.ExistingRelationship = ReceivedRequestCheck
                                        vm.RelationtoRequest = 'Recipient'
                                        vm.RequestStatus = ReceivedRequestCheck.RequestStatus
                                        if(this.RequestStatus === 'Sent'){
                                            this.RequestStatusMessage = `Accept Request`
                                                this.RequestStatusIcon = 'mdi-check'
                                        }
                                        
                                    }
                                })
                             } 
                             } 
                             else{
                                    vm.GetMyReceivedFriendrequests().then(resolve => {
                                    //console.log('here is received reqeust resolve - ',resolve)
                                    let ReceivedRequestCheck = this.MyReceivedFriendshipRequests.find(obj => obj.Requestorid == this.Memberid)
                                    if(ReceivedRequestCheck){
                                        console.log('is receipient of requests')
                                        vm.ExistingRelationship = ReceivedRequestCheck
                                        vm.RelationtoRequest = 'Recipient'
                                        vm.RequestStatus = ReceivedRequestCheck.RequestStatus
                                        if(this.RequestStatus === 'Sent'){
                                            this.RequestStatusMessage = `Accept Request`
                                                this.RequestStatusIcon = 'mdi-check'
                                        }
                                        
                                    }
                                })
                             }
                
                   
                    
                  }
              }
          })
         
      },
    
  

  
    

      ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.UsersArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && this.MemberColRef.doc(item.id).delete() && this.UsersArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.UsersArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
      },
        UpdateUserTaskManager(){
          if(typeof this.IsTeamActivityManager !== 'undefined'){
            this.MemberColRef.doc(this.UserRecord.id).update({
              IsTeamActivityManager: this.IsTeamActivityManager
            })
          }
        },
      UpdateNotifiationEmailer(){
        const UserDefinedMailedNotifications = firebase.functions().httpsCallable('UserDefinedMailedNotifications')
        UserDefinedMailedNotifications({id: this.UserRecord.id, boolean: this.UsersWantsEmailsOnAllNotifications}).then(result => {
          console.log(result);
        })
      },
        

      UpdateUser(){
        this.UndefinedMandatoryFields = []
      const EditedUser = {
    
        }
      for(var prop in EditedUser) {
          if (EditedUser.hasOwnProperty(prop)) {
            if(typeof EditedUser[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      this.MemberColRef.doc(this.UserRecord.id).update({
              });
              this.close()
              this.EditedUsersnackbar = true
              this.CancelUserEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              
                     if(typeof this.IsTeamActivityManager !== 'undefined'){
                       this.MemberColRef.doc(this.UserRecord.id).update({
                         IsTeamActivityManager: this.IsTeamActivityManager
                       })
                     }
                      if(typeof this.AssignedRoles !== 'undefined'){
                        this.MemberColRef.doc(this.UserRecord.id).update({
                         assignedroles: this.AssignedRoles
                       })
                      }
                      if(typeof this.FullName !== 'undefined'){
                        this.MemberColRef.doc(this.UserRecord.id).update({
                         Full_Name: this.FullName
                       })
                      }
                      if(typeof this.Name !== 'undefined'){
                        this.MemberColRef.doc(this.UserRecord.id).update({
                         Name: this.Name
                       })
                      }
                      if(typeof this.Surname !== 'undefined'){
                        this.MemberColRef.doc(this.UserRecord.id).update({
                         Surname: this.Surname
                       })
                      }
                      
                      if(typeof this.Manager !== 'undefined'){
                        this.MemberColRef.doc(this.UserRecord.id).update({
                         Manager: {Full_Name: this.Manager.Name+' '+this.Manager.Surname,Name: this.Manager.Name, Surname: this.Manager.Surname, id: this.Manager.id},
                         Managerid: this.Manager.id
                       })
                      }
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },

  }
}


</script>

<style>
/* .borderthicknobottom{
  border-top: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
}
.borderthicknotop{
  border-bottom: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
} */
.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}

.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.hasBG{
background-image: 'url(' + require('@/assets/logo.png') + ')';
 background-color: #cccccc;
}
</style>
    