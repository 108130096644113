<template>
  <span>
    <SingleButton v-if="!tab.MultipleButtons"
        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" :IsElement="IsElement" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	
        @OpenDialog="OpenDialog"						
        />
    <div v-if="tab.MultipleButtons">
        <SingleButton v-for="btntab in ComputedTabButtonTabs" :key="btntab.itemObjKey"
         :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" :IsElement="IsElement" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="btntab" :i="i" :PageID="PageID" :PageName="PageName"
        @OpenDialog="OpenDialog"								
        />
    </div>
  
  </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTab';
import SingleButton from '@/components/WebPages/RenderComponents/ActionButton/SingleButton';

export default {
    props: ['System','SystemEntities','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {WebFormTab,SingleButton},
    data() {
        return {
            WebFormDialogFancy: false, 
            PopupStyleDialog: false,
            WebFormDialogNonFancy: false,
            ButtonProps: ['ActionButtonName','ActionButtonIcon','ActionButtonIconOnly','ActionButtonText','ActionButtonFullWidth',
          'ActionButtonType','ContentDialogType','ContentDialogGalType','ContentDialogBox','ActionButtonElementIndex',
          'RelatedEntity','FormName','FormEmailRecipient','DarkForm','SoloInvert','HideFormIMG','PopupStyle',
          'ActionBTNTextColor','ActionButtonRoute','ButtonAlignment','ActionBtnDark','ButonColor','ActionBTNTextColor'],
        }
    },
    computed:{
        ComputedTabButtonTabs(){
            return this.tab.ActionButtons ? this.tab.ActionButtons.map(btn => {
                let tab = Object.assign({},this.tab)
                this.ButtonProps.map(prp => {
                    if(typeof btn[prp] !== 'undefined'){
                        tab[prp] = btn[prp]
                    }
                })
                return tab
            })
             : []
        },
        ContentDialogBoxType() {
            if(this.tab.ContentDialogType){
              return () => import(`@/components/WebPages/RenderComponents/`+this.tab.ContentDialogType.ComponentName.split(' ').join(''));  
            }
        
        
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
    },
    created(){
        
    },
    methods:{
        DownloadFile(file){
            this.GetShortLivedURL(file)
        },
        GetShortLivedURL(item){      
      let itemobj = Object.assign({},item)
      if(itemobj.StorageRef[0] === '/'){
        itemobj.StorageRef = itemobj.StorageRef.slice(1)
      }
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(itemobj).then(result => {
          let payload = {
            item: itemobj,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
         DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
         DeactivateWebDialog(DialogName){
            this[DialogName] = false
        },
        OpenDialog(tab,tabindex){
            // always only for content dialog so tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,
            this.$emit('OpenDialog',tab,tabindex,'','','','',true)
            this.WebFormDialogNonFancy = false
            this.WebFormDialogFancy = false
            this.PopupStyleDialog = false
        }
    }
}
</script>

<style>

</style>
