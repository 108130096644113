
<template>
<div  >
    
    <v-row class="justify-center">
        <v-btn-toggle>
          <!-- <v-btn small @click="UpdateContent()">
                <v-icon small>mdi-content-save</v-icon>
            </v-btn> -->
            <v-btn small onclick="document.execCommand('justifyLeft',false,'')">
                <v-icon small>mdi-format-align-left</v-icon>
            </v-btn>

            <v-btn small onclick="document.execCommand('justifyCenter',false,'')">
                <v-icon small>mdi-format-align-justify</v-icon>
            </v-btn>

            <v-btn small onclick="document.execCommand('justifyRight',false,'')">
                <v-icon small>mdi-format-align-right</v-icon>
            </v-btn>

            <v-btn small onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon small>mdi-format-list-bulleted</v-icon>
            </v-btn>
            </v-btn-toggle>
    </v-row>
    <v-row class="justify-center" v-if="FullScope">        
            <v-btn-toggle>
                 <v-btn small onclick="document.execCommand('formatBlock', false, '<h1>')">
                H1
                </v-btn>
                <v-btn small onclick="document.execCommand('formatBlock', false, '<h2>')">
                H2
                </v-btn>
                <v-btn small onclick="document.execCommand('formatBlock', false, '<h3>')">
                H3
                </v-btn>
                <v-btn small>
                <v-icon small @click="link()">mdi-link</v-icon>
                </v-btn>
                <v-btn small onclick="document.execCommand('strikeThrough',false,'')">
                        <v-icon>mdi-format-strikethrough</v-icon>
                        </v-btn>
            </v-btn-toggle>
    </v-row>
  <v-row class="justify-center">      
    <div style="border: 1px solid #d3d3d3;width:100%;min-height: 50px;" :id="FieldID ? FieldID : FieldName" @blur="UpdateContent()" @input="removePipeCharacter()"
    v-html="FieldValue" contenteditable>
    </div>
  </v-row>
</div>
</template>

<script>
export default {
    props: ['FieldID','FieldName','FieldValue','AdditionalSaveMethod','FieldObject','FullScope'],
    data(){
        return{
            
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        removePipeCharacter() {
            let elmnt = document.getElementById(this.FieldName)
            let newvalue = elmnt.innerHTML
            if(this.$route.meta && this.$route.meta.DataSingleBuilder && newvalue.includes('|') || this.$route.meta && this.$route.meta.TableBuilder && newvalue.includes('|')){
               elmnt.innerHTML = newvalue.replace(/[|]/g, '');
               this.$store.commit('SetAlertSound','error_sound')
               this.$store.commit('setConfirmMSGTitle',`Not Allowed!`)
               this.$store.commit('setConfirmMSGIcon',`mdi-alert`)
               this.$store.commit('setConfirmMSGIconColor',`red`)
               this.$store.commit('setConfirmMSGText',`<p style="text-align: center;">The "|" (pipe symbol) is not allowed in Data Record fields, as the "|" symbol represents a columns separator for data imports and exports from .csv.</p>`)
               this.$store.commit('setConfirmMSGDialog',true)               
            }
            
      },
        UpdateContent(){
            setTimeout(() => {
             let elmnt = document.getElementById(this.FieldID ? this.FieldID : this.FieldName)
            let newvalue = elmnt.innerHTML
            this.$emit('UpdateContentEditableField',this.FieldName,newvalue,this.AdditionalSaveMethod,this.FieldObject)   
            }, 200);
            
        }
    }
}
</script>

<style>

</style>


    