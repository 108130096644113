<template>
   <v-card flat tile class="transparent">
     <v-dialog v-model="NewDocumentDialog" max-width="500px">
               <v-card>
                  <v-img
                  width="500"
                  src="@/assets/RABaseBG-Soft.png"
                  ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                    <v-card-title class="mediumoverline white--text">
                      Register Documents
                    </v-card-title></div>
                    <v-form ref="Enquiriesform" id="Enquiriesform">
                      <v-card-text>
                            <!-- <v-select  :rules="[$store.state.formrules.required]" :items="section.PrimaryOptions"
                            label="Select Primary Type" v-model="editedItem.PrimarySelectedOption" item-text="Name" return-object>
                            </v-select> -->
                             <!-- //.DocRegAdditionalFields.map? -->
                            <input  v-if="!editedItem.fileurl"
                            type="file"
                            @change="onFileselected($event)"
                            ref="fileInputer"
                            id="InputElement"
                            >
                            <v-btn icon @click="GetShortLivedURL(editedItem)" v-if="editedItem.fileurl">View
                                </v-btn>
                                <v-text-field v-model="NewDocName" label="Display Name"
                                />
                        </v-card-text>
                      <v-card-actions class="white documenttypeshadowinvert">                  
                      <v-btn color="orange" dark class="overline" @click="closedialog()">Cancel</v-btn>
                        <v-spacer></v-spacer>
                      <v-btn color="green" dark  class="overline" @click="ProcessSelectedDocument()" v-if="selectedFile || editedItem.fileurl">Save</v-btn>
                    </v-card-actions>
                    </v-form>
                    </v-img>
                </v-card>
            </v-dialog>
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
          <v-card-text v-if="EditedTab.Name === 'Documents Table'">
           <v-list>
            <v-switch v-model="EditedTab.BasicDocumentsList" label="Basic List"/>
            <!-- Need a "Select" I mean where is the select option? Where is that which we had Bob? -->
            <!-- could also say if no Entityid I believe... -->
            <v-btn @click="AddDocument(EditedTab.Documents)"
            v-if="EditedTab.BasicDocumentsList">Add Doc</v-btn>
            <v-menu
               :nudge-top="40"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px" 
                >
                    <template v-slot:activator="{ on }">
             <v-btn v-on="on"
            v-if="EditedTab.BasicDocumentsList && CanSelectDoc">Select Doc</v-btn>
                    </template>
            <v-list>
                <v-list-item v-for="doc in SiteDocuments" :key="doc.itemObjKey">
                    <v-list-item-avatar>
                    <v-img v-if="doc.type === 'application/pdf'" :src="require('@/assets/PDF_DOC.png')"/>
                    <v-img v-else :src="require('@/assets/access_denied.png')"/>                
                    </v-list-item-avatar>
                    <v-list-item-content>
                    <v-list-item-title>
                        ...{{doc.Name}}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="grey--text">
                    <v-btn small class="blue" @click="GetShortLivedURL(doc)"
                        fab dark >
                            <v-icon>mdi-eye
                            </v-icon>
                        </v-btn>
                </v-list-item-action>
                <v-list-item-action class="grey--text">
                    <v-btn small class="success" @click="Selectdoc(doc)"
                        fab dark >
                            <v-icon>mdi-check
                            </v-icon>
                        </v-btn>
                </v-list-item-action>
                </v-list-item>
            </v-list>
            </v-menu>
            <v-list-item v-for="item in EditedTab.Documents" :key="item.itemObjKey" class="basicoutlined">
            <v-layout row class="justify-start">
                <v-list-item-avatar size="50">
                    <v-img
                        aspect-ratio="1"
                        :src="getImgUrl(item)"/>                          
                </v-list-item-avatar>
                <v-list-item-content>
                        <v-list-item-title>{{item.Name}}</v-list-item-title>
                </v-list-item-content>                             
                    <v-list-item-action>                            
                        <v-btn icon @click="ConfirmDeleteDocument(item)">
                        <v-icon color="grey lighten-1">mdi-delete-forever</v-icon>
                        </v-btn>                              
                    </v-list-item-action>                           
                </v-layout>
            </v-list-item>
           </v-list>
          </v-card-text>
          <v-card-text v-if="EditedTab.Name === 'Skills List'">
           <v-list>
            <v-btn @click="AddItem(EditedTab.ListItems)">Add Item</v-btn>
            <!-- <v-list-item>
                Color 1
            </v-list-item>
            <v-list-item>                
                Color 2
            </v-list-item> -->
            <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            v-if="EditedTab.Name === 'Skills List'"
            >
            <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
                <v-icon left>mdi-palette-advanced</v-icon>
                Font Color
                </v-list-item>
                </template>
            <v-color-picker v-model="EditedTab.FontColor"></v-color-picker>
            </v-menu>
            <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            v-if="EditedTab.Name === 'Skills List'"
            >
            <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
                <v-icon left>mdi-palette-advanced</v-icon>
                Bar BG
                </v-list-item>
                </template>
            <v-color-picker v-model="EditedTab.BarBG"></v-color-picker>
            </v-menu>
            <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            v-if="EditedTab.Name === 'Skills List'"
            >
            <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
                <v-icon left>mdi-palette-advanced</v-icon>
                From Color
                </v-list-item>
                </template>
            <v-color-picker v-model="EditedTab.FromColor"></v-color-picker>
            </v-menu>
            <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            v-if="EditedTab.Name === 'Skills List'"
            >
            <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
                <v-icon left>mdi-palette-advanced</v-icon>
                To Color
                </v-list-item>
                </template>
            <v-color-picker v-model="EditedTab.ToColor"></v-color-picker>
            </v-menu>
            <v-switch v-if="EditedTab.Name === 'Skills List'" label="Inline" v-model="EditedTab.InlineItems"/>
            <v-switch v-if="EditedTab.Name === 'Skills List'" label="Hide Values" v-model="EditedTab.HideValues"/>
            <v-list v-for="item in EditedTab.ListItems" :key="item.itemObjKey">
                <!-- <v-btn @click="AddItem(EditedTab.ListItems)">Add Item</v-btn> -->
                <v-list-item>
                    <v-text-field v-model="item.Title" label="Title"/>
                </v-list-item>
                <v-list-item>
                        <v-slider min="0" max="100" v-model="item.Value" label="Value"
                        />
                                  <v-list-item-action>                            
                                <v-btn icon @click="ConfirmDeleteTiledTitle(item)">
                                <v-icon color="red">mdi-delete-forever</v-icon>
                                </v-btn>                              
                            </v-list-item-action> 
                </v-list-item>
                
            </v-list>
           
           </v-list>

          </v-card-text>
          <v-card-text v-if="EditedTab.Name === 'Tiled Titles'">
            <v-slider thumb-label="always" v-model="EditedTab.LineHeight" max="300" min="50" label="Line Height"
            />
             <v-select v-model="EditedTab.FontWeight" :items="[100,200,300,400,500,600,700,800,900,1000]" label="Font Weight"
            />
            <v-slider thumb-label="always" v-model="EditedTab.FontEM" max="12" min="0" label="Font"
            />
            <v-btn @click="AddTiledTitle(EditedTab.ListItems)">Add Item</v-btn>
            <v-list v-for="item in EditedTab.ListItems" :key="item.itemObjKey">
                <v-list-item>
                    <v-list-item-title>
                    <v-text-field v-model="item.Title" label="Title"/>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-text-field v-model="item.RevealTitle" label="Reveal Title"/>
                        
                    </v-list-item-subtitle>
                 <v-list-item-action>                            
                        <v-btn icon @click="ConfirmDeleteTiledTitle(item)">
                        <v-icon color="grey lighten-1">mdi-delete-forever</v-icon>
                        </v-btn>                              
                    </v-list-item-action> 
                </v-list-item>
                <v-list-item>
                    <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-icon left>mdi-palette-advanced</v-icon>
                        Color
                        </v-list-item>
                        </template>
                    <v-color-picker v-model="item.Color"></v-color-picker>
                    </v-menu>
                </v-list-item> 
                <v-list-item>
                    <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-icon left>mdi-palette-advanced</v-icon>
                        BG Color
                        </v-list-item>
                        </template>
                    <v-color-picker v-model="item.BGColor"></v-color-picker>
                    </v-menu>
                </v-list-item> 
            </v-list>
          </v-card-text>
        <v-card-text v-if="EditedTab.Name === 'Description List'">
             <v-list-item>
                                 <v-switch @change="PrepareForPanels(EditedTab)" v-model="EditedTab.WrapInExpansionPanel" label="Wrap In Expansion Panel"/>     
                                 
             <v-switch v-if="EditedTab.Name === 'Description List'" label="Dark" v-model="EditedTab.Dark"/>                           
                            </v-list-item> 
                            <v-list  v-if="!EditedTab.WrapInExpansionPanel">
                          <v-list-item>
                              <v-chip-group v-if="!DynamicWikiData"
                                    active-class="primary--text"
                                    column
                                >
                                <v-chip
                                    @click="ActivateNewFieldDialog(field,true,EditedTab)"
                                    small  v-for="field in BasicFieldTypes"
                                    :key="field.itemObjKey"
                                    >
                                    {{field.Type}}
                                    </v-chip>                           
                                </v-chip-group>
                                <v-select v-if="DynamicWikiData" multiple @change="AssignDescriptionList(EditedTab)" v-model="EditedTab.ListItems" :items="EntityFieldsandFeatures" label="List Items" return-object item-text="DisplayName"/>                       
                            </v-list-item>  
                            <v-list-item v-for="listitem in EditedTab.ListItems" :key="listitem.itemObjKey">
                                <v-list-item-content>
                                    {{listitem.DisplayName}}
                                </v-list-item-content>
                                <!-- <v-list-item-action v-if="!DynamicWikiData">
                                   <v-menu 									
                                    :close-on-content-click="false"                            									
                                    transition="scale-transition"                            									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                        <v-btn fab icon v-on="on"><v-icon>mdi-cogs</v-icon></v-btn>									
                                        </template>	
                                        <v-card width="400">
                                         
                                            <v-card-text>
                                              <SingleTabFieldsSection
                                                    @UpdateEditableField="UpdateEditableField"
                                                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                                                :sectionindex="''" :tabindex="''" :SampleOnly="true"
                                                :PrimaryFieldName="''" :SingleFormEditing="false"
                                                :Record="listitem.Record" :AdditionalSaveMethod="AdditionalSaveMethod"
                                                :section="''" :tab="''" :Fields="[listitem]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                                                />
                                            </v-card-text>
                                        </v-card>
                                   </v-menu>
                                </v-list-item-action> -->
                            </v-list-item>
                            </v-list>
                            <v-list v-if="EditedTab.WrapInExpansionPanel">
                                <v-btn @click="AddListPanels(EditedTab)">Add Panel</v-btn>
                              
                             <v-list-item  v-for="(panel,panelindex) in EditedTab.Panels"
                                :key="panel.itemObjKey">
                                  <v-text-field :rules="[$store.state.formrules.required]" v-model="panel.Title" label="Title"></v-text-field> 
                                  <v-menu 									
                                    :close-on-content-click="false"                            									
                                    transition="scale-transition"                            									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                        <v-btn fab icon v-on="on"><v-icon>mdi-cogs</v-icon></v-btn>									
                                        </template>	
                                        <v-card>
                                            <v-card-title>List Items</v-card-title>
                                            <v-card-text>
                                                <v-chip-group v-if="!DynamicWikiData"
                                                        active-class="primary--text"
                                                        column
                                                    >
                                                    <v-chip
                                                        @click="ActivateNewFieldDialog(field,true,panel)"
                                                        small  v-for="field in BasicFieldTypes"
                                                        :key="field.itemObjKey"
                                                        >
                                                        {{field.Type}}
                                                        </v-chip>                           
                                                    </v-chip-group>
                                                        <v-list-item v-for="listitem in panel.ListItems" :key="listitem.itemObjKey">
                                                            <v-list-item-content>
                                                                {{listitem.DisplayName}}
                                                            </v-list-item-content>
                                                            <!-- <v-list-item-action v-if="!DynamicWikiData">
                                                            <v-menu 									
                                                                :close-on-content-click="false"                            									
                                                                transition="scale-transition"                            									
                                                                >									
                                                                <template v-slot:activator="{ on }">									
                                                                    <v-btn fab icon v-on="on"><v-icon>mdi-cogs</v-icon></v-btn>									
                                                                    </template>	
                                                                    <v-card width="400">
                                                                    
                                                                        <v-card-text>
                                                                        <SingleTabFieldsSection
                                                                                @UpdateEditableField="UpdateEditableField"
                                                                            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                                                                            :sectionindex="''" :tabindex="''" :SampleOnly="true"
                                                                            :PrimaryFieldName="''" :SingleFormEditing="false"
                                                                            :Record="listitem.Record" :AdditionalSaveMethod="AdditionalSaveMethod"
                                                                            :section="''" :tab="''" :Fields="[listitem]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                                                                            />
                                                                        </v-card-text>
                                                                    </v-card>
                                                            </v-menu>
                                                            </v-list-item-action> -->
                                                        </v-list-item>
                                                <v-select v-if="DynamicWikiData" multiple @change="AssignDescriptionList(EditedTab)" v-model="panel.ListItems" :items="EntityFieldsandFeatures" label="List Items" return-object item-text="DisplayName"/> 
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn @click="RemovePanel(EditedTab,panelindex)">Remove</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                  </v-menu>	
                            </v-list-item>  
                            </v-list> 
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';

export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,SingleTabFieldsSection},
    data() {
        return {
            PrimarySelectedOption: '',
            NewDocumentDialog: false,
            NewDocName: '',
            selectedFile: '',
            editedItem: {
            PrimarySelectedOption: '',
            },
            DefaultItem: {
            PrimarySelectedOption: '',
            },
            editedIndex: -1,
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    watch: {
         SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['ReceivePageDocumentTableDoc']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    computed:{
        UseFeaturedMemberAssets(){
            return this.$route.name === 'FeaturedMemberEditor' && this.ActiveFeaturedMember || 
            this.FeaturedMemberTemplate
            //or you need 
        },
        ActiveFeaturedMemberDocuments(){
            return this.UseFeaturedMemberAssets && this.ActiveFeaturedMember ? this.ActiveFeaturedMember.Assets.filter(asset => {
                return asset.FileType && !asset.FileType.includes('image/')
            }) : []
        },
        SiteDocuments(){
            let array = []
            if(this.IsFeaturedMember){
                array = this.ActiveFeaturedMemberDocuments
            }
            return array
        },
        ActiveFeaturedMemberDocuments(){
            return this.UseFeaturedMemberAssets && this.ActiveFeaturedMember ? this.ActiveFeaturedMember.Assets.filter(asset => {
                return asset.FileType && !asset.FileType.includes('image/')
            }) : []
        },
        CanSelectDoc(){
            return this.IsFeaturedMember
        },
        IsFeaturedMember(){
            return this.$route.name === 'FeaturedMemberEditor' || this.ActiveMarketTemplate && this.FeaturedMemberTemplate
        },
        ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        FeaturedMemberTemplate(){
            return  this.$route.name === 'MyMarketplaceTemplates' && this.ActiveFeaturedMember 
            && this.ActiveMarketTemplate && this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name/includes('Featured Member')
            },
        SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
        BasicFieldTypes(){
            return this.FieldTypes.filter(field => {
                return field.Type === 'Single Line Text' || field.Type === 'Multiple Lines Text' || field.Type === 'Boolean'
            })
        },
    },
    created(){

    },
    methods:{
        ConfirmDeleteTiledTitle(item){
            confirm('This will delete the document forever, there is no undo. Are you absolutely sure you want to do this?') && this.ProcessDeleteTiledTitle(item)
        },
        ProcessDeleteTiledTitle(item){
           let index = this.EditedTab.ListItems.indexOf(item)
                this.EditedTab.ListItems.splice(index,1)
        },
        ConfirmDeleteDocument(item){
            confirm('This will delete the document forever, there is no undo. Are you absolutely sure you want to do this?') && this.ProcessDeleteDocument(item)
        },
        ProcessDeleteDocument(item){
            let doc = this.EditedTab.Documents.find(obj => obj.StorageRef === item.StorageRef)
            if(doc){
                let index = this.EditedTab.Documents.indexOf(doc)
                this.EditedTab.Documents.splice(index,1)
            }
        },
        closedialog (){
        this.NewDocumentDialog = false
        this.selectedFile = ''
        this.NewDocName = ''
        this.PrimarySelectedOption = ''
        this.editedItem = this.DefaultItem
        this.editedIndex = -1
        let inputelement = document.getElementById('InputElement')
        if(inputelement){
            inputelement.value = ''
        }
        },
        onFileselected(event){
        this.selectedFile = event.target.files[0]
        this.selectedFile.FileModifiedDate = new Date(this.selectedFile.lastModified)
        },
        getImgUrl(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
        AddDocument(Documents){
            //so here we gotta actually select multiple documents, name them etc etc so like, what do we do is the std file acceptabled?
            //First let's get a dialog going appNotifications relevant
        this.NewDocumentDialog = true
            
        },
        saveDoc(){
      
            if (this.editedIndex > -1) {

            }
            else{
                
            }
        },
        Selectdoc(doc){
            this.EditedTab.Documents.push(doc)
        },
        ReceivePageDocumentTableDoc(payload){
            this.EditedTab.Documents.push(payload.UploadObject)
            if(this.IsFeaturedMember){
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.ActiveFeaturedMember.id).collection('Assets').add(payload.UploadObject).then(addeddoc => {
                    this.closedialog()
                })
            }
            else{
                this.closedialog()
            }
        },
        ProcessSelectedDocument(){
            //see saveDoc from DataSingleDocumentRegister for more information
            //also currently we work on featuredmembers but on pages this would be under pageid. so
            //let storepath = ''
            let routestoragepaths = [
                {name: 'FeaturedMemberEditor',path: 'UserAssets/'+this.$route.params.id+'/Documents/'+new Date()+'/'},
                {name: 'PageEditor',path: 'Pages/'+this.$route.params.id+'/Documents/'+new Date()+'/'}
            ]
            let storeroute = routestoragepaths.find(obj => obj.name === this.$route.name)
            if(this.IsFeaturedMember){
                storeroute.path = 'UserAssets/'+this.ActiveFeaturedMember.id+'/'+new Date()+'/'
            }
            let storepath = storeroute.path+this.NewDocName
            if(this.EditedTab.BasicDocumentsList){
             let payload = {
                    file: this.selectedFile,
                    filename: this.NewDocName,
                    storepath: storepath,
                    returnmethod: 'ReceivePageDocumentTableDoc',
                    PassedObj: {}
                }
                if(this.NewDocName){
                    payload.PassedObj.Name = this.NewDocName
                }
                //catch tips here for DataSingleDocumentRegister
                if(this.PrimarySelectedOption){
                    payload.PassedObj.Name = this.PrimarySelectedOption
                }
                this.$store.commit('SetSocialItemInteractMethodProp',payload) 
                this.$store.commit('SetSocialItemInteractMethod','ProcessSingleFileUpload')
            }
            else{

            }
        },
        AddTiledTitle(ListItems){
            let newitem = {BGColor: {
    "alpha": 0,
    "hex": "#888888",
    "hexa": "#88888800",
    "hsla": {
        "h": 202.8169014084507,
        "s": 0,
        "l": 0.5333333333333333,
        "a": 0
    },
    "hsva": {
        "h": 202.8169014084507,
        "s": 0,
        "v": 0.5333333333333333,
        "a": 0
    },
    "hue": 202.8169014084507,
    "rgba": {
        "r": 136,
        "g": 136,
        "b": 136,
        "a": 0
    }
},Color: {
    "alpha": 1,
    "hex": "#1DA1F2",
    "hexa": "#1DA1F2FF",
    "hsla": {
        "h": 202.8169014084507,
        "s": 0.8912133891213389,
        "l": 0.5313725490196078,
        "a": 1
    },
    "hsva": {
        "h": 202.8169014084507,
        "s": 0.8801652892561983,
        "v": 0.9490196078431372,
        "a": 1
    },
    "hue": 202.8169014084507,
    "rgba": {
        "r": 29,
        "g": 161,
        "b": 242,
        "a": 1
    }
},Title: 'Be',RevealTitle: 'Be'}
            ListItems.push(newitem)
        },
        AddItem(ListItems){
            let newitem = {Title: 'Skill',Value: 80}
            ListItems.push(newitem)
        },
        AssignDescriptionList(EditedTab){
            this.$emit('AssignDescriptionList',EditedTab)
        },
        RefreshComponent(EditedTab){
            this.$emit('RefreshComponent',EditedTab)
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            this.$emit('ActivateNewFieldDialog',field,AddingNewField,ListPanel)
        },
        RefreshPanels(EditedTab){
            EditedTab.WrapInExpansionPanel = false
            EditedTab.WrapInExpansionPanel = true
            this.RefreshComponent(EditedTab)
        },
        RemovePanel(EditedTab,panelindex){
            EditedTab.Panels.splice(panelindex,1)
            this.RefreshPanels(EditedTab)
        },
        PrepareForPanels(EditedTab){
            if(!EditedTab.Panels){
                EditedTab.Panels = []
            }            
        },
        AddListPanels(EditedTab){
            let newpanel = {
                Title: 'Some Title',
                ListItems: [],
            }
            EditedTab.Panels.push(newpanel)
            this.RefreshPanels(EditedTab)
            console.log(EditedTab)
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
