<template>
  <v-card :dark="AppisDarkMode" :flat="Flat" >
      <SocialSharing :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
    :SharingPost="SharingPost"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="userLoggedIn" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>
       <v-divider></v-divider>
              <v-card tile flat
                v-if="!HideSummary"
                height="100%" width="100%"
                >  
              
                <v-card-actions class="mx-10">
                <v-layout row class="justify-space-between">  
                  <v-btn style="text-transform: none;" icon v-if="CanSocial">
                    <v-tooltip bottom color="links">
                        <template v-slot:activator="{ on, attrs }">
                          <v-badge right color="links" dark overlap>
                                <v-icon left v-bind="attrs"
                                  v-on="on" color="blue lighten-2" @click="LikePost(SocialItem)">mdi-thumb-up-outline</v-icon>
                            <span slot="badge"> {{ComputedSocialItemLikes.length}} </span>
                          </v-badge>
                      </template>
                      <span >
                        {{ComputedLikesTooltip}}
                      </span>
                        </v-tooltip>
                       Like
                    </v-btn>
                    <v-btn disabled icon v-if="!CanSocial">
                      <v-badge color="links" dark overlap>
                        <v-icon  color="blue lighten-2">mdi-thumb-up-outline</v-icon>
                        <span slot="badge"> {{ComputedSocialItemLikes.length}} </span>
                      </v-badge>
                      
                    </v-btn>

                    <v-btn icon >
                    <v-icon  >mdi-tag-outline</v-icon>
                  </v-btn>
                  <!--  @click="ShareBlog(SocialItem)" -->
                  <v-btn icon @click="ShareBlog(SocialItem)">
                    <v-icon  color="red lighten-2">mdi-share-circle</v-icon>
                  </v-btn>
                  <v-btn icon >
                      <v-badge color="links" dark overlap>
                        <v-icon  color="orange lighten-2">mdi-comment-outline</v-icon>
                        <span slot="badge"> {{ComputedSocialItemComments.length}} </span>
                      </v-badge>
                      
                    </v-btn>
                </v-layout> 
                </v-card-actions>
              </v-card>

              <v-divider></v-divider>
        <!-- <v-card-actions class="commentpost"> -->
                 <v-row v-if="CanSocial"
                class="fill-height justify-space-between mx-4 my-1"
    
                >
                
          <v-avatar size="40">
            <img :src="userLoggedIn.Profile_Photo"/>
            
          </v-avatar>
          <input style="display: none" ref="commentfileattach" @change="UploadIMG($event,'CommenttoPost',SocialItem)" type="file" accept="image/*">
          <v-btn @click="$refs.commentfileattach.click()" icon><v-icon>mdi-paperclip</v-icon></v-btn>
          <span  class="NEWChipinput mx-1" role="textbox" contenteditable :id="computedcommentinputchip"  @keydown.enter="CommenttoPost(SocialItem)"  placeholder="Type Message"/>
          <!-- <input class="Chipinput mx-3" style="font-size:12px" type="text" @keydown.enter="CommenttoPost(SocialItem)" placeholder="Insert Comment" :id="computedcommentinputchip"/> -->
                 </v-row>
              <v-divider></v-divider>
            
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel >
                  <v-expansion-panel-header :id="SocialItem.id+'commentpanel'">
                    <v-layout row wrap class="justify-center mx-3">
                       
                      
                        <v-btn icon small>
                            <v-icon small color="orange">mdi-comment-outline</v-icon>
                        </v-btn>
                      <span class="caption"> {{ComputedSocialItemComments.length}} Comments</span> 
                      </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                     
                      <v-list dense  width="100%" v-for="comment in ComputedSocialItemComments" :key="comment.itemObjkey">
                      <!-- <v-list dense> -->
                        
                      <v-list-item :id="comment.id">
                      <v-list-item-avatar>
                          <img v-if="comment.creatorimg"
                            :src="comment.creatorimg"
                            :alt="comment.creatorname"
                        >
                        <img v-if="!comment.creatorimg"
                            src='@/assets/BlankProfilePic.png'
                            :alt="comment.creatorname"
                        >
                        
                      </v-list-item-avatar>
                     
                        <v-list-item-content v-if="!comment.ImageObject">
                          <!-- <v-chip
                            large
                          > -->
                          <div :class="AppisDarkMode ? 'commentblockdark' : 'commentblock'" >
                          <v-list class="transparent" dense width="90%">
                            <v-list-item style="font-size:10px">
                            <b><a :href="'/SiteMember/'+comment.Creatorid">{{comment.creatorname}}</a></b>
                            </v-list-item>
                            <v-list-item style="font-size:12px">
                            {{comment.content}}
                            </v-list-item>
                          
                          </v-list>
                          </div>
                          <!-- </v-chip> -->
                        </v-list-item-content>
                        <v-img max-width="70%" max-height="300"  v-if="comment.ImageObject" contain :src="comment.ImageObject.ThumbURL"/>
                           
                      </v-list-item>
                      <v-list-item>
                        <v-spacer></v-spacer>
                        <v-btn icon small v-if="CanSocial">
                           <v-tooltip bottom color="links" :disabled="!ComputedLikesCommentsTooltip(comment.Likes)">
                            <template v-slot:activator="{ on, attrs }">
                              <v-badge dark overlap>
                              <v-icon v-bind="attrs"
                                v-on="on" small color="blue" @click="LikeComment(comment)">mdi-thumb-up-outline</v-icon>
                              <span slot="badge"> {{comment.Likes.length}} </span>
                            </v-badge>
                          </template>
                          <span>
                            {{ComputedLikesCommentsTooltip(comment.Likes)}}
                          </span>
                        </v-tooltip>
                      </v-btn>
                      <v-btn icon small v-if="CanSocial">
                        <v-badge dark overlap>
                          <v-icon small color="blue" @click="ReplyBoxActivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')">mdi-reply</v-icon>
                          <span slot="badge"> {{comment.Replies.length}} </span>
                        </v-badge>
                        
                      </v-btn>
                      </v-list-item>
                      <v-list-item :id="comment.id+'replyboxbreak'"  style="display:none">
                      <!-- <v-list> -->
                        <!-- <v-list-item :id="i+'commentreply'" style="display:none"> -->
                          <v-avatar size="35" :id="comment.id+'replyinputavatar'">
                            <img :src="UserRecord.Profile_Photo"/>
                          </v-avatar>
                          <span @blur="ReplyBoxDeactivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')" class="NEWChipinput mx-1" role="textbox" contenteditable :id="comment.id+'replyinputchip'"  @keydown.enter="ReplytoComment(comment)"  placeholder="Reply Here"/>
                          <!-- <input @blur="ReplyBoxDeactivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')" class="Chipinput mx-3" type="text" @keydown.enter="ReplytoComment(comment)" placeholder="Reply Here" :id="comment.id+'replyinputchip'"> -->
                          
                        <!-- </v-list-item>
                      </v-list>                       -->
                      </v-list-item>
                       <!-- </v-list> 
                       <v-list dense> -->
                         <v-list-item  v-for="reply in comment.Replies" :key="reply.itemObjKey">
                            <v-list class="transparent" dense width="90%">
                                  <v-list-item>
                                    
                            <v-list-item-avatar>
                                <img v-if="reply.creatorimg"
                                  :src="reply.creatorimg"
                                  :alt="reply.creatorname"
                              >
                              <img v-if="!reply.creatorimg"
                                  src='@/assets/BlankProfilePic.png'
                                  :alt="reply.creatorname"
                              >
                              
                            </v-list-item-avatar>
                              <v-list-item-content>
                                <!-- <v-chip
                                  large
                                > -->
                                <div :class="AppisDarkMode ? 'commentblockdark' : 'commentblock'">
                                <v-list class="transparent" dense width="90%">
                                  <v-list-item style="font-size:10px">
                                  <b><a :href="'/SiteMember/'+reply.Creatorid">{{reply.creatorname}}</a></b>
                                  </v-list-item>
                                  <v-list-item style="font-size:12px">
                                  {{reply.content}}
                                  </v-list-item>
                                </v-list>
                                </div>
                                <!-- </v-chip> -->
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-spacer></v-spacer>
                                <v-btn icon small v-if="CanSocial">
                                <v-badge dark overlap>
                                  <v-icon small color="blue" @click="LikeReply(reply)">mdi-thumb-up-outline</v-icon>
                                  <span slot="badge"> {{reply.Likes.length}} </span>
                                </v-badge>
                                
                              </v-btn>
                              <v-btn icon small v-if="CanSocial">
                                <v-badge dark overlap>
                                  <v-icon small color="blue" @click="ReplyBoxActivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')">mdi-reply</v-icon>
                                  <span slot="badge"> {{reply.Replies.length}} </span>
                                </v-badge>
                                
                              </v-btn>
                              </v-list-item>
                              <!-- <v-list>
                                <v-list-item> -->
                                  <v-list-item :id="reply.id+'replyboxbreak'" style="display:none">
                                  <v-avatar size="35">
                                    <img :src="UserRecord.Profile_Photo"/>
                                  </v-avatar>
                                  <span @blur="ReplyBoxDeactivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')" class="NEWChipinput mx-1" role="textbox" contenteditable  :id="reply.id+'replytoreplyinputchip'"  @keydown.enter="ReplytoReply(reply,comment)" placeholder="Reply to reply"/>
                                  <!-- <input @blur="ReplyBoxDeactivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')" class="Chipinput mx-3" type="text"  @keydown.enter="ReplytoReply(reply,comment)"  placeholder="Reply to reply" :id="reply.id+'replytoreplyinputchip'"> -->
                                  
                               <!-- </v-list-item>
                              </v-list>                      -->
                              </v-list-item>
                                </v-list>
                            </v-list-item>
                      
                      
                  </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SocialSharing from "@/components/SocialNetwork/SocialSharing";
export default {
    props: ['FromSocialPosts','ItemType','SocialItem','UserRecord','AppisDarkMode','PathName','RelatedType','notificationitem','panel','Flat','HideSummary','IsChild','Child','ChildProp','ChildType','ChildArrayProp','CanSocial','GroupID'],
    components: {
      SocialSharing
    },
    data(){
        return{
          
            // panel: [0]
            ShareItemDialog: false,
            InternalLinkType: '',
            SharedCollection: '',
            LinkComponent: '',
            LinkID: '',
            SharingPost: '',
        }
    },
    computed:{
      ComputedLikesTooltip(){
        if(this.ComputedSocialItemLikes.length > 0){
        let display = this.ComputedSocialItemLikes[0].Likedby.Full_Name
        if(this.ComputedSocialItemLikes.length === 1){
          display +=' likes this'
        }
        else if(this.ComputedSocialItemLikes.length === 2){
          display +=' & '+this.ComputedSocialItemLikes[1].Likedby.Full_Name+' like this'
        }
        else{
          display +=' & '+(this.ComputedSocialItemLikes.length-1)+' others likes this'
        }
          return display
        }
        else{
          return 'No likes as yet'
        }
        
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
        ComputedSocialItemComments(){
            if(!this.IsChild){
                return this.SocialItem.Comments
            }
            else if(this.Child && this.Child.Comments){
                return this.Child.Comments
            }
            else{
                return []
            }
        },
        ComputedSocialItemLikes(){
            if(!this.IsChild){
                return this.SocialItem.Likes
            }
            else if(this.Child && this.Child.Likes){
                return this.Child.Likes
            }
            else{
                return []
            }
        },
        computedcommentinputchip(){
            if(this.IsChild){
                return this.Child ? this.Child[this.ChildProp]+'commentinputchip' : ''
            }
            else{
                return this.SocialItem.id+'commentinputchip'
            }
        },
    },
    created(){
      //console.log(this.Child)
      setTimeout(() => {
        document.getElementById(this.computedcommentinputchip).addEventListener("paste", function(e) {
              e.preventDefault();
              var text = "";
              if (e.clipboardData && e.clipboardData.getData) {
                text = e.clipboardData.getData("text/plain");
              } else if (window.clipboardData && window.clipboardData.getData) {
                text = window.clipboardData.getData("Text");
              }
              document.execCommand("insertHTML", false, text);
            });
      }, 2000);
      
    },
    methods:{
      UploadIMG(event,Method,FirstProp,SecondProp){
        let vm = this
        let imagefile = event.target.files[0]
        imagefile.FileModifiedDate = new Date(imagefile.lastModified)
        let filename = imagefile.name+imagefile.size
        let storepath = 'SocialSitePosts/'+FirstProp.id+'/'+Method
        if(SecondProp){
          storepath = storepath+'/'+SecondProp.id+'/'+filename
        }
        else{
          storepath = storepath+'/'+filename
        }
        
        var storageRef = firebase.storage().ref(storepath);
        var uploadTask = storageRef.put(imagefile);
        //vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,imagefile,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let ImageObject = {
              fileurl: url,
              url: url,
              ThumbURL: thumbresult,
              FileType: imagefile.type,
              Modified_By: {Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
              Modified_Byid: vm.UserRecord.id,
              Modified_On: new Date(),
              ModifiedDate: imagefile.FileModifiedDate,
              Size: imagefile.size,
              StorageRef: storepath,
              filename: filename
            }
            if(!SecondProp){
              this[Method](FirstProp,true,ImageObject)
            }
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added Image to - '+vm.Item_Name,
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            imagefile = ''
                })
                  vm.Imagefilesnackbar = true
         })
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },

        ComputedLikesCommentsTooltip(CommentLikes){
          if(CommentLikes.length > 0){
          let display = CommentLikes[0].Likedby.Full_Name
          if(CommentLikes.length === 1){
            display +=' likes this'
          }
          else if(CommentLikes.length === 2){
            display +=' & '+CommentLikes[1].Likedby.Full_Name+' like this'
          }
          else{
            display +=' & '+(CommentLikes.length-1)+' others likes this'
          }
            return display
          }
          else{
            return ''
          }
        },
        ShareBlog(item){
          console.log('item',item,this.FromSocialPosts)
        
        if(this.FromSocialPosts){
          this.SharingPost = Object.assign({},item)
          this.InternalLinkType = 'SitePosts'
          this.SharedCollection = 'socialsiteposts'
          this.LinkComponent = 'SocialPostShared'
          this.SharingPost.LinkID = item.id
          this.SharingPost.InternalLinkType = 'SitePosts'
          this.SharingPost.SharedCollection = 'socialsiteposts'
          this.SharingPost.LinkComponent = 'SocialPostShared'
          this.SharingPost.PostID = item.id
          this.LinkID = item.id
        }
        else if(this.$route.name === 'GroupBlog'){
          this.InternalLinkType = 'Group-Blog/'+this.$route.params.id+'/Blog'
          this.SharedCollection = 'groupblogs'
          this.LinkComponent = 'GroupBlogShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else if(this.$route.name === 'SiteBlogSingle'){
          this.InternalLinkType = 'Blog'
          this.SharedCollection = 'siteblogs'
          this.LinkComponent = 'SiteBlogShared'
          this.LinkID = this.$route.params.id
        } 
        else if(this.$route.name === 'GroupArticle'){
          this.InternalLinkType = 'Group-Article/'+this.$route.params.id+'/Article'
          this.SharedCollection = 'grouparticles'
          this.LinkComponent = 'GroupArticleShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else if(this.$route.name === 'SiteArticleSingle'){
          this.InternalLinkType = 'Article'
          this.SharedCollection = 'sitearticles'
          this.LinkComponent = 'SiteArticleShared'
          this.LinkID = this.$route.params.id
        }
        
        this.LinkID = item.id
        this.ShareItemDialog = true
      },
       
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
        LikePost(post){
          if(!this.CanSocial){
              this.NoAccess()
          }
          else{
            let UserhasLiked = ''
            //console.log(this.Child)
            if(this.IsChild){
              UserhasLiked = this.Child.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
            }
            else{
              UserhasLiked = post.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
            }
            //post.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
            if(!UserhasLiked){
            let newlike = {
              Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
              Likedbyid: this.UserRecord.id,
              createdon: new Date(),
                relatedtype: this.RelatedType,
                relatedid: post.id,
                relatedowner: post.Creatorid
            }
            if(this.GroupID){
              newlike.GroupID = this.GroupID
            }
            if(this.IsChild){
                newlike.IsChild = true
                newlike.Child = this.Child[this.ChildProp]
                newlike.ChildType = this.ChildType
                newlike.ChildArrayProp = this.ChildArrayProp
            }
            // post.Likes.push(newlike)
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitelikes').add(newlike).then(doc => {
              if(newlike.Likedbyid !== post.Creatorid){
                let path = this.GetPath(doc)         
                this.PrepareNotification('Likes','likes your '+this.PathName,path)
              }
            })
            }
            else{
              let newlike = {}
              if(this.IsChild){
                newlike.IsChild = true
                newlike.Child = this.Child[this.ChildProp]
                newlike.ChildType = this.ChildType
                newlike.ChildArrayProp = this.ChildArrayProp
            }
              console.log(post,newlike)
              alert('you already liked this item')
            }
          }
        
      },
      CommentReplyActivate(i,comment){
        if(!this.CanSocial){
          this.NoAccess()
        }
        else{
          // comment.Replying = true
          setTimeout(() => {
            let elmnt = document.getElementById(i+'commentreply')
          elmnt.style.display = 'block'
          console.log(comment)
          console.log(elmnt)
          }, 300);
        }
      },
      
      ReplytoComment(comment,i){
          if(!this.CanSocial){
              this.NoAccess()
          }
          else{
            if(!comment.Replies){
                comment.Replies = []
              }
              let inputelmnt = document.getElementById(comment.id+'replyinputchip')
              let NewReply = 
              {
                content: inputelmnt.innerHTML,
                Likes: [],
                Shares: [],
                tags: [],
                Replies: [],
                Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
                Creatorid: this.UserRecord.id,
                creatorname: this.UserRecord.Full_Name,
                createdon: new Date(),
                Comments: [],
                relatedtype: 'Social Comment',
                relatedid: comment.id,
                relatedowner: comment.Creatorid,
                commentrelatedid: comment.relatedid
                
              }
              if(this.GroupID){
                NewReply.GroupID = this.GroupID
              }
              if(this.UserRecord.Profile_Photo){
                NewReply.creatorimg = this.UserRecord.Profile_Photo
                }
              db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitereplies').add(NewReply).then(doc => {
              if(NewReply.Creatorid !== comment.Creatorid){
                let path = this.GetPath(doc)     
                this.PrepareNotification('Reply','replied to your comment',path,'commentid',comment)
              }
            })
              comment.Replying = false
              this.NewReplyInput = ''
              inputelmnt.innerHTML = '' 
          }          
        },

        CommenttoPost(post,ImageUpload,ImageObject){
            if(!this.CanSocial){
              this.NoAccess()
            }
            // else if(ImageUpload){

            // }
            else{
            //         if(!post.Comments){
            //     post.Comments = []
            //   }
            //   setTimeout(() => {
                let elmntid = ''
                if(this.IsChild){
                    elmntid = this.Child[this.ChildProp]+'commentinputchip'
                }
                else{
                    elmntid = this.SocialItem.id+'commentinputchip'
                }
              let inputelmnt = document.getElementById(elmntid)
              //console.log(inputelmnt)
              
              let expandelmnt = ''
              
              if(!this.IsChild){
              expandelmnt = document.getElementById(post.id+'commentpanel')
              }
              let NewComment = 
              {
                content: inputelmnt.innerHTML,
                Likes: [],
                Shares: [],
                tags: [],
                Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
                Creatorid: this.UserRecord.id,
                creatorname: this.UserRecord.Full_Name,
                createdon: new Date(),
                Replies: [],
                relatedtype: this.RelatedType,
                relatedid: post.id,
                relatedowner: post.Creatorid
                
              }
              if(ImageUpload){
                NewComment.ImageObject = ImageObject
              }
              if(this.GroupID){
                NewComment.GroupID = this.GroupID
              }
              if(this.IsChild){
                NewComment.IsChild = true
                NewComment.Child = this.Child[this.ChildProp]
                NewComment.ChildType = this.ChildType
                NewComment.ChildArrayProp = this.ChildArrayProp
            }
              //post.Comments.push(NewComment)
              if(this.UserRecord.Profile_Photo){
              NewComment.creatorimg = this.UserRecord.Profile_Photo
            }
              db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitecomments').add(NewComment).then(doc => {
                //console.log('created under '+doc.id)
              if(NewComment.Creatorid !== post.Creatorid){
                let path = this.GetPath(doc)           
                this.PrepareNotification('Comment','commented on your '+this.PathName,path)
              }
            })
              post.NewCommentInput = ''
              inputelmnt.innerHTML = ''
              if(!this.IsChild){
              expandelmnt.click()
              }
                // }, 500);
            }
       
          
        },
      GetPath(doc){
        console.log('this.SocialItem',this.SocialItem)
        if(this.PathName === 'SitePosts'){
          return '/'+this.PathName+'/'+this.SocialItem.id+'/'+doc.id
        }
        else if(this.GroupID){
          return '/Group-'+this.PathName+'/'+this.$route.params.id+'/'+this.PathName+'/'+this.$route.params.slug
        }
        else{
          return '/'+this.PathName+'/'+this.$route.params.id
        }   
      },
      PrepareNotification(type,HeaderSuffix,path,objidprop,object){        
        let NewNotification = {
      
        Type: 'New '+type,
        Header: this.UserRecord.Full_Name+' '+HeaderSuffix,
        Content: 'Click to view '+type,
        CreatorFullName: this.UserRecord.Full_Name,
        CreatorID: this.UserRecord.id,
        Read: false,
        Path: path,
        Message: 'Click to view '+type,
        Date: new Date(),
        postid: this.SocialItem.id,
        }
        if(objidprop && object && object.id){
          NewNotification[objidprop] = object.id
          NewNotification.Owner = object.Creator.Full_Name
          NewNotification.Ownerid = object.Creatorid
        }
        else{
          NewNotification.Owner = this.SocialItem.Creator.Full_Name
          NewNotification.Ownerid = this.SocialItem.Creatorid
        }
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

        })
      },
      NoAccess(){
        alert('You do not have access to perform this action')
      },
      LikeReply(reply){
        if(this.CanSocial){
        let UserhasLiked = reply.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
          relatedtype: 'Social Reply',
            relatedid: reply.id,
            relatedowner: reply.Creatorid
        }
        if(this.GroupID){
            newlike.GroupID = this.GroupID
          }
        // post.Likes.push(newlike)
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitelikes').add(newlike).then(doc => {
          if(newlike.Likedbyid !== reply.Creatorid){
            let path = this.GetPath(doc)      
            this.PrepareNotification('Likes','likes your comment',path,'replyid',reply)
          }
                
        })
        }
        else{
          alert('you already liked this item')
        }
        }
        else{
          this.NoAccess()
        }
      },
      ReplytoReply(reply,comment){
        if(!this.CanSocial){
          this.NoAccess()
        }
        else{
          if(!reply.Replies){
            reply.Replies = []
          }
          console.log(reply)
          let inputelmnt = document.getElementById(reply.id+'replytoreplyinputchip')
          let NewReply = 
          {
            content: inputelmnt.innerHTML,
            Replies: [],
            Likes: [],
            Shares: [],
            tags: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Comments: [],
            relatedtype: 'Social Reply',
            relatedid: comment.id,
            relatedowner: reply.Creatorid
            
          }
          if(this.GroupID){
            NewReply.GroupID = this.GroupID
          }
          if(this.UserRecord.Profile_Photo){
            NewReply.creatorimg = this.UserRecord.Profile_Photo
            }
           db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitereplies').add(NewReply).then(doc => {
          if(NewReply.Creatorid !== reply.Creatorid){
            let path = this.GetPath(doc)      
            this.PrepareNotification('Reply','replied to your comment',path,'replyid',reply)
          }
        })
          reply.Replying = false
          this.NewReplyInput = ''
          inputelmnt.innerHTML = ''
        }        
      },
      LikeComment(comment){
        if(!this.CanSocial){
          this.NoAccess()
        }
        else{
          let UserhasLiked = comment.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
          if(!UserhasLiked){
          let newlike = {
            Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            Likedbyid: this.UserRecord.id,
            createdon: new Date(),
            relatedtype: 'Social Comment',
              relatedid: comment.id,
              relatedowner: comment.Creatorid
          }
           if(this.GroupID){
            newlike.GroupID = this.GroupID
          }
          // post.Likes.push(newlike)
          db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitelikes').add(newlike).then(doc => {
            if(newlike.Likedbyid !== comment.Creatorid){
            let path = this.GetPath(doc)     
            this.PrepareNotification('Likes','likes your comment',path,'commentid',comment)
            }
          })
          }
          else{
            alert('you already liked this item')
          }
        }
        
      },
        ReplyBoxDeactivate(replyboxbreak,input){
        let replyboxbreakelmnt = document.getElementById(replyboxbreak)
        let inputelmnt = document.getElementById(input)
        inputelmnt.innerHTML = ''
        replyboxbreakelmnt.style.display = 'none'
        },
        ReplyBoxActivate(replyboxbreak,input){
            let replyboxbreakelmnt = document.getElementById(replyboxbreak)
            let inputelmnt = document.getElementById(input)
            replyboxbreakelmnt.style.display = ''
            inputelmnt.addEventListener("paste", function(e) {
              e.preventDefault();
              var text = "";
              if (e.clipboardData && e.clipboardData.getData) {
                text = e.clipboardData.getData("text/plain");
              } else if (window.clipboardData && window.clipboardData.getData) {
                text = window.clipboardData.getData("Text");
              }
              document.execCommand("insertHTML", false, text);
            }); 
            inputelmnt.focus()
        },
    }
}
</script>

<style>

</style>


