<template>
  <v-main style="">
    <!-- why did we have style="margin-top: 65px;"? -->
      <v-dialog max-width="600" v-model="NewFieldDialog">                               
        <v-card>
            <v-card-title class="overline">
                {{NewField.Type}}
            </v-card-title>
            <v-card-text v-html="NewField.Description">
            </v-card-text>
            <!-- <v-card-text> -->
                <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="CurrentEntity" @RefreshField="RefreshField" v-if="!RefreshingNewField"
                :SystemEntities="SystemEntities" :field="NewField" :NewField="AddingNewField" :System="System"
                />
            <v-card-text>
                <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true" :System="System"
                :PrimaryFieldName="''" :SingleFormEditing="false" :CurrentEntity="CurrentEntity"
                :Record="SampleRecord" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="[NewField]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelNewFieldDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddField(NewField)">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
      <v-dialog width="400" v-model="AddfieldDialog">
          <v-card>
                  <v-card-title>Record Title</v-card-title>
                  <v-card-text>
              <v-chip-group
                                active-class="primary--text"
                                column
                            >
                            <v-chip
                                @click="ActivateNewFieldDialog(field,true)"
                                small  v-for="field in FieldTypes"
                                :key="field.itemObjKey"
                                >
                                {{field.Type}}
                                </v-chip>                           
                            </v-chip-group>
                  </v-card-text>
          </v-card>
      </v-dialog>
      <v-dialog width="400" v-model="CombinedTitleDialog">
              <v-card>
                  <v-card-title>Record Title</v-card-title>
                  <v-card-text>
                      You need a Title for the records, in order for the records to work like a bomb 
                      <!-- <FieldValueEditerComponent :field="" :Record="PageObj" /> -->
                   							
                    <v-select :readonly="CurrentEntity.CombinedTitleMap" v-model="CombinedTitleMap" label="Combined Title Map"                                  
                    :items="CurrentEntity.AllFields" multiple return-object item-text="DisplayName"/>
                    <v-select :items="CurrentEntityStatusReasons" multiple item-text="ID" v-model="QualifiedListings" label="Option Matches">
                        <template v-slot:item="{ item }">
                        {{item.Name}}
                        </template>
                    </v-select>
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CombinedTitleDialog = !CombinedTitleDialog">Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="SaveNavList()">Save</v-btn>
                  </v-card-actions>
              </v-card>
         </v-dialog>
       <v-dialog  max-width="500" v-model="AddAppendedFiltersDialog" persistent>                               
        <v-card
                class="mx-auto"
                max-width="500"
            >
                <v-card-title class="text-h6 font-weight-regular justify-space-between">
                <span>{{ currentAppendedFiltersTitle }}</span>
                
                <v-avatar
                    color="primary lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="appendedfiltersstep"
                ></v-avatar>
                <v-btn @click="CancelAddAppendFiltersDialog()" class="red white--text" icon><v-icon>mdi-close</v-icon>
            </v-btn>
                
                </v-card-title>

                <v-window v-model="appendedfiltersstep">
                <v-window-item :value="1">
                    <v-card-text>
                   
                    <span class="text-caption grey--text text--darken-1">
                        <v-select v-if="!editedappendedFilter.CreateNewField" label="Field" v-model="editedappendedFilter.Field" return-object :items="ComputedFields" item-text="id"/>
                        <v-checkbox v-model="editedappendedFilter.CreateNewField" label="Create New Field"/>
                    </span>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-text>
                   
                    <span class="text-caption grey--text text--darken-1">
                        <v-text-field label="Name" v-model="editedappendedFilter.Field.Name"/>
                        <v-select label="Field Type" v-model="editedappendedFilter.Field.Type" :items="['Number Field','Date','Option Set']"/>
                    </span>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                    <v-card-text>
                        
                   <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                        :CurrentEntity="CurrentEntity" 
                        :SystemEntities="SystemEntities" :field="editedappendedFilter.Field" :NewField="false" :System="System"
                        />
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                    <v-card-text>
                   
                    <span class="text-caption grey--text text--darken-1">
                        Awesome, we are ready to create the filter
                    </span>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                    <v-card-text>
                   
                    <span class="text-caption grey--text text--darken-1">
                        <v-select v-if="!editedappendedFilter.CreateNewField" label="Field" v-model="editedappendedFilter.Field" return-object :items="ComputedFields" item-text="id"/>
                        <v-checkbox v-model="editedappendedFilter.CreateNewField" label="Create New Field"/>
                    </span>
                    </v-card-text>
                </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn
                    :disabled="appendedfiltersstep === 1"
                    text
                    @click="appendedfiltersstep--"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="appendedfiltersstep === 5"
                    color="primary"
                    depressed
                    @click="AdvanceAppendedFilterDialog()"
                >
                    {{AdvanceAppendedFilterDialogNextText}}
                </v-btn>
                <v-btn
                    v-if="appendedfiltersstep === 4"
                    color="primary"
                    depressed
                    @click="RestartAppendedFilterFieldDialog()"
                >
                    Add Another Field
                </v-btn>
                </v-card-actions>
            </v-card>
      </v-dialog>
            
            <v-dialog  max-width="800" v-model="FeaturesListConfigDialog" persistent>                               
                 <v-card >
            <v-card-title class="overline">
                Features Builder
            </v-card-title>
            <!-- <v-card-subtitle>
                {{ViewPortItemType}}
            </v-card-subtitle> -->
            <v-card-text>
                <!-- <div>{{ViewPortItemName}}</div> -->
                <div>{{ViewPortItemType}}</div>
                <p class="text-h4 text--primary">
                    {{CurrentEntity.DisplayName}}
                </p>
            </v-card-text>
            <v-card-text class="background">
                <v-row>
                    <v-col cols="5">
                        <v-list class="mx-auto" >
                            <v-list-item-title class="mx-3">
                                Settings
                            </v-list-item-title>
                            <!-- FeaturesListRelatedBuildID: '',
            FeatureList: [],
            FeatureListType: '', -->
                            <v-list-item >
                                <v-select :items="['Standard','Lookup']" v-model="FeatureListType" label="FeatureList Type" />                              
                            </v-list-item>
                            <v-list-item v-if="FeatureListType === 'Lookup'">
                                <v-select @change="UpdateFeautreListBuildID(FeatureListLookupField)" return-object :items="LookupFields" v-model="FeatureListLookupField" item-text="DisplayName" label="Field" />                              
                            </v-list-item>
                            <v-list-item  v-if="FeatureListType === 'Lookup' && FeatureListLookupField">
                                <v-select :items="SystemEntities" label="Where Are they Stored?" v-model="FeaturesListRelatedBuildID" item-text="id"/>
                            </v-list-item>
                             <v-btn @click="AddFeature()">Add Feature</v-btn>
                        </v-list>
                    </v-col>
                    <v-col cols="7">
                        <v-card
                        flat
                        tile
                    >
                        <v-window
                        v-model="FeatureWindow"
                        reverse
                        >
                        <v-window-item
                            v-for="featureitem in FeatureList"
                            :key="featureitem.itemObjKey" style="padding:20px;"
                        >
                            <v-card
                            
                            shaped elevation="6"
                            
                            >
                            <v-card-title>
                            {{featureitem.DisplayName}}
                            <v-menu offset-y :close-on-content-click="false" bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-icon small v-on="on">mdi-pencil</v-icon>
                                </template>
                                <v-card max-width="200">
                                    <v-card-title>Styling<SystemAssetsSelector :item="featureitem" :Prop="'DescriptionIMG'" :URLOnly="true"
                                   
                                    /></v-card-title>
                                    <v-card-text>
                                      <v-select :items="['Boolean','Number Field']" label="Type" v-model="featureitem.Type"/>
                                    <FieldValueEditerComponent :field="{Type: 'Single Line Text',Name: 'DisplayName',DisplayName: 'Display Name'}" :Record="featureitem"
                                    />
                                    
                                    <FieldValueEditerComponent :field="{Type: 'Multiple Lines Text',IsContentEditable: true,Name: 'HoverDescription',DisplayName: 'Hover Description'}" :Record="featureitem"
                                     style="padding-top:50px;"
                                    />
                                </v-card-text>
                                </v-card>
                            </v-menu>
                            
                            </v-card-title>
                            
                             <v-img v-if="!featureitem.DescriptionIMG"
                                src="@/assets/ImageHolder.png"
                                height="100px"
                                ></v-img>
                                <v-img v-if="featureitem.DescriptionIMG"
                                :src="featureitem.DescriptionIMG"
                                height="100px"
                                ></v-img>                                    
                                <v-card-text  v-html="featureitem.HoverDescription"/>
                            </v-card>
                            
                        </v-window-item>
                        </v-window>

                        <v-card-actions class="justify-space-between">
                        <v-btn
                            text
                            @click="FeatureWindow--"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-item-group
                            v-model="FeatureWindow"
                            class="text-center"
                            mandatory
                        >
                            <v-item
                            v-for="featureitem in FeatureList"
                            :key="featureitem.itemObjKey"
                            >
                            <v-btn
                                :input-value="active"
                                icon
                                @click="toggle"
                            >
                                <v-icon>mdi-record</v-icon>
                            </v-btn>
                            </v-item>
                        </v-item-group>
                        <v-btn
                            text
                            @click="FeatureWindow++"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-col>
                </v-row>
              
               
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelFeatureBuilderDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="UpdateFeatureList()">
                   Update Features
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
        <v-dialog  max-width="800" v-model="ViewPortBuilderDialog" persistent>                               
        <v-card >
            <v-card-title class="overline">
                ViewPort Builder
            </v-card-title>
            <!-- <v-card-subtitle>
                {{ViewPortItemType}}
            </v-card-subtitle> -->
            <v-card-text>
                <!-- <div>{{ViewPortItemName}}</div> -->
                <div>{{ViewPortItemType}}</div>
                <p class="text-h4 text--primary">
                    {{CurrentEntity.DisplayName}}
                </p>
            </v-card-text>
            <v-card-text class="background">
                <v-row>
                    <v-col cols="5">
                        <v-list class="mx-auto" v-if="ViewPortItemType === 'Classified Listing' || ViewPortItemType === 'Wiki Listing'">
                            <v-list-item-title class="mx-3">
                                Settings
                            </v-list-item-title>
                            <v-list-item  v-if="$route.name !== 'WikiCollectionBuilder'">
                                <v-switch v-model="CurrentEntity.HasLocation" label="HasLocation" />                              
                            </v-list-item>
                            <v-list-item >
                                <v-switch v-model="CurrentEntity.HideTitle" label="Hide Title" />                              
                            </v-list-item>
                            <v-list-item  v-if="$route.name === 'WikiCollectionBuilder'">
                                <v-select :items="CurrentEntity.WikiFields" item-text="Name" label="Title Field"
                                v-model="CurrentEntity.TitleField" clearable
                                />                              
                            </v-list-item>
                             <v-list-item v-if="$route.name !== 'WikiCollectionBuilder'">
                                <v-switch v-model="CurrentEntity.IMGIsCarousel" label="IMG Is Carousel" />                              
                            </v-list-item>
                            <v-list-item v-if="$route.name === 'WikiCollectionBuilder'">
                                <v-switch v-model="CurrentEntity.HideIMG" label="Hide IMG" />                              
                            </v-list-item>
                            <v-list-item  v-if="$route.name === 'WikiCollectionBuilder' && !CurrentEntity.HideIMG">
                                <v-select :items="CurrentEntity.WikiFields" item-text="Name" label="IMG Field"
                                v-model="CurrentEntity.IMGField" clearable
                                />                              
                            </v-list-item>
                            <v-list-item v-if="$route.name !== 'WikiCollectionBuilder'">
                                  <v-select :items="TaggableFields" item-text="Name" label="Tag Field"
                                v-model="CurrentEntity.TagField" return-object clearable
                                />
                            </v-list-item>
                            <v-list-item v-if="$route.name === 'WikiCollectionBuilder' & !CurrentEntity.HideIMG">
                                  <v-select :items="CurrentEntity.WikiFields" item-text="Name" label="Tag Field"
                                v-model="CurrentEntity.TagField" return-object clearable
                                />
                            </v-list-item>
                            <v-list-item v-if="$route.name !== 'WikiCollectionBuilder'">
                                <v-switch v-model="CurrentEntity.HidePrice" label="Hide Price" />                              
                            </v-list-item>
                            <v-list-item v-if="$route.name === 'WikiCollectionBuilder'">
                                <v-switch v-model="CurrentEntity.ShowPrice" label="Show Price" />                              
                            </v-list-item>
                           <v-list-item  v-if="$route.name === 'WikiCollectionBuilder' && CurrentEntity.ShowPrice">
                                <v-select :items="CurrentEntity.WikiFields" item-text="Name" label="Price Field"
                                v-model="CurrentEntity.PriceField" clearable
                                />                              
                            </v-list-item>
                             <v-list-item >
                                <v-switch v-model="CurrentEntity.HideDescription" label="Hide Description" />                              
                            </v-list-item>
                            <v-list-item  v-if="$route.name === 'WikiCollectionBuilder' && !CurrentEntity.HideDescription">
                                <v-select :items="CurrentEntity.WikiFields" item-text="Name" label="Description Field"
                                v-model="CurrentEntity.DescriptionField" clearable
                                />                              
                            </v-list-item>
                            <v-list-item  v-if="$route.name !== 'WikiCollectionBuilder'">                                
                                  <v-select @change="RefreshViewPortBuilderDialog()" :items="CurrentEntity.AllFields" item-text="Name" label="Tagged Fields"
                                v-model="CurrentEntity.TaggedFields" multiple return-object
                                />
                            </v-list-item>
                            <v-list-item  v-if="$route.name === 'WikiCollectionBuilder'">                                
                                  <v-select @change="RefreshViewPortBuilderDialog()" :items="CurrentEntity.WikiFields" item-text="Name" label="Tagged Fields"
                                v-model="CurrentEntity.TaggedFields" multiple return-object
                                />
                            </v-list-item>
                            
                            <v-list-item>
                                <v-btn>Add Features</v-btn>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="7">
                          <ClassifiedListingListItem v-if="ViewPortItemType === 'Classified Listing'" :IMGIsCarousel="CurrentEntity.IMGIsCarousel"
                          :TaggedFields="CurrentEntity.TaggedFields" :TagField="CurrentEntity.TagField" :CurrentEntity="CurrentEntity"
                          :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription"
                          :item="ViewPortItem" :AppisDarkMode="AppisDarkMode" :HasLocation="HasLocation" :ViewPortBuilder="true"
                       />
                       <WikiListingItem v-if="ViewPortItemType === 'Wiki Listing'"
                          :TaggedFields="CurrentEntity.TaggedFields" :TagField="CurrentEntity.TagField" :CurrentEntity="CurrentEntity"
                          :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription"
                          :HideIMG="CurrentEntity.HideIMG"
                          :item="ViewPortItem" :AppisDarkMode="AppisDarkMode" :HasLocation="HasLocation" :ViewPortBuilder="true"
                          :IMGField="CurrentEntity.IMGField" :DescriptionField="CurrentEntity.DescriptionField" :TitleField="CurrentEntity.TitleField"
                       />
                    </v-col>
                </v-row>
              
               
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelViewPortBuilderDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="UpdateViewPort()">
                   Update ViewPort
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog  max-width="600" v-model="ViewAppendedFiltersDialog" persistent>                               
        <v-card>
            
            <v-card-title v-if="editedFilterParent" class="overline">
                {{editedFilterParent.Name}} - Appended Filters <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-btn x-small @click="ActivateAddAppendedFiltersDialog()">Add Filter</v-btn>
                    <v-list-item v-for="filter in editedFilterParent.AppendedFilters" :key="filter.itemObjKey">
                        <v-list-item-content>
                        <v-list-item-title v-text="filter.Title"></v-list-item-title>

                        <v-list-item-subtitle v-text="filter.Type"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon @click="AppendedfilterActivate(filter)">
                            <v-icon color="grey lighten-1">mdi-cogs</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelAppendFiltersDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AppendFilter()">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog  max-width="600" v-model="EditFieldDialog" persistent>                               
        <v-card>
            
            <v-card-title v-if="editedNavItem.NavTreeField" class="overline">
                {{editedNavItem.NavTreeField.Type}}
            </v-card-title>
          <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="CurrentEntity" v-if="EditFieldDialog"
                :SystemEntities="SystemEntities" :field="editedNavItem.NavTreeField" :NewField="false" :System="System"
                />
            <v-card-actions>
                <v-btn @click="CancelEditField()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddFieldOdd(NewField)">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="400" v-model="NavlistDialog" persistent>
      
        <v-card class="white">
        <v-card-title class="primary white--text overline">New Nav Item
            <v-spacer></v-spacer>
            <v-btn @click="CancelAddNavItem()" dark icon><v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-text-field label="Title" v-model="editedNavItem.Title"/>
         
            <v-select @change="CheckNavTreeField()" label="Type" v-model="editedNavItem.Type" :items="ComputedNavItemTypes"/>
            <v-select v-if="editedNavItem.Type === 'TreeView'" :readonly="editedNavItem.Type === 'TreeView'" label="Tree Field" v-model="editedNavItem.NavTreeField" return-object :items="ComputedFields" item-text="id"/>
            <v-select @change="CheckforLookup(editedNavItem.Field)" v-if="editedNavItem.Type === 'Single Number Filter' || editedNavItem.Type === 'Single Option Filter' || editedNavItem.Type === 'Text Search'" label="Field" v-model="editedNavItem.Field" return-object :items="ComputedFields" item-text="Name"/>
            <!-- <v-btn @click="EditField('NavTreeField')" v-if="editedNavItem.NavTreeField">Edit</v-btn> -->
            <v-text-field v-if="editedNavItem.Type === 'Single Number Filter'" label="Min" v-model.number="editedNavItem.Min"/>
            <v-text-field v-if="editedNavItem.Type === 'Single Number Filter'" label="Max" v-model.number="editedNavItem.Max"/>
            <v-select @change="CheckArrayforLookup(editedNavItem.Filters)" v-if="editedNavItem.Type === 'Filter List'" label="Field" v-model="editedNavItem.Filters" multiple return-object :items="ComputedFields" item-text="DisplayName"/>
        </v-card-text>
        <v-card-text>
            <!-- {{ListPrimaryField}} -->
             <TreeViewComp v-if="editedNavItem.Type === 'TreeView'" :navitem="editedNavItem" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
            @ActivateTreeNode="ActivateTreeNode" :ListPrimaryField="ListPrimaryField" @AppendFilters="AppendFilters"
            />
            <SingleOptionFilterComp v-if="editedNavItem.Type === 'Single Option Filter'" :navitem="editedNavItem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
            />
            <SingleNumberFilterComp v-if="editedNavItem.Type === 'Single Number Filter'" :navitem="editedNavItem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
            />
            <FilterListComp v-if="editedNavItem.Type === 'Filter List' && editedNavItem.Filters" :navitem="editedNavItem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
            @EditNavItemSubItem="EditNavItemSubItem"
            />
            <RatingsComp v-if="editedNavItem.Type === 'Rating' && editedNavItem.Filters" :navitem="editedNavItem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
            />
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="editedNavItemIndex > -1" @click="ConfirmDeleteNavItem(editedNavItem)">Delete Item</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="AddNewNavItem()">Add Item</v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="400" v-model="NavlistSubItemDialog">
      
        <v-card class="white">
        <v-card-title class="primary white--text overline">{{editedNavItem.Title}} Sub Item
        </v-card-title>
        <v-card-subtitle class="primary white--text overline">{{editedSubItem.Name}}
            <v-spacer></v-spacer>
            <v-btn @click="DeactivateNavSubItemDialog()" dark icon><v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-subtitle>
        <v-card-text>
            <v-text-field v-if="editedNavItem.Type === 'Filter List' && editedSubItem.Type === 'Number Field'" label="Min" v-model.number="editedSubItem.Min"/>
            <v-text-field v-if="editedNavItem.Type === 'Filter List' && editedSubItem.Type === 'Number Field'" label="Max" v-model.number="editedSubItem.Max"/>
            <v-text-field v-if="editedNavItem.Type === 'Filter List' && editedSubItem.Type === 'Number Field'" label="Intervals" v-model.number="editedSubItem.Intervals"/>
            <v-text-field v-if="editedNavItem.Type === 'Filter List' && editedSubItem.Type === 'Number Field'" label="Starting Integer" v-model.number="editedSubItem.StartingInteger"/>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="UpdateNavSubItem()">Update {{editedSubItem.Name}}</v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- {{NavList[0].TreeList[1].children.length}}
      {{ComputedNavList[0].TreeList[1].children.length}} -->
       <DirectoryViewer v-if="CurrentEntity && CurrentEntity.CategoricalListType && !Refreshing" :ProvidedAdditionalFilters="AdditionalFilters" @ActivateFeaturesListConfigDialog="ActivateFeaturesListConfigDialog"
      :ProvidedNavList="ComputedNavList" :ProvidedCurrentEntity="CurrentEntity" @ActivateNewNavItemDialog="ActivateNewNavItemDialog" @SaveNavList="SaveNavList" @EditNavItem="EditNavItem" :CartItems="CartItems"
      @EditNavItemSubItem="EditNavItemSubItem" @ActivateTreeNode="ActivateTreeNode" @ActivateViewportBuilder="ActivateViewportBuilder" :ProvidedFeatureList="CurrentEntity.FeatureList" @ActivateNewFieldDialog="ToggleNewFieldDialog"
      />
     <v-layout column class="fab-container">
        <v-btn icon x-large @click="CombinedTitleDialog = !CombinedTitleDialog" v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Classified Listing'"><v-icon  color="warning" x-large>mdi-format-title</v-icon></v-btn>
         
        
         <v-btn v-if="CurrentEntity.ActivePageid" icon x-large :to="'/'+CurrentEntity.ActivePageid.split('_').join('-')"><v-icon  color="blue" x-large>mdi-eye</v-icon></v-btn>
         <v-dialog width="400" v-model="PublishPageDialog">
              <template v-slot:activator="{ on }">
                 <v-btn v-if="!CurrentEntity.ActivePageid" icon x-large v-on="on"><v-icon  color="blue" x-large>mdi-publish</v-icon></v-btn>
              </template>
              <v-card>
                  <v-card-title>Publish Page</v-card-title>
                  <v-card-text>
                      <!-- <FieldValueEditerComponent :field="" :Record="PageObj" /> -->
                    <v-text-field :readonly="$route.meta && $route.meta.WikiCollectionBuilder" v-model="PageName" label="Name" />	
                    <v-text-field v-if="$route.meta && $route.meta.WikiCollectionBuilder" :readonly="$route.meta && $route.meta.WikiCollectionBuilder" v-model="SingleName" label="Single Name" />	
                    								
                    <v-textarea v-model="PageOverview" label="verview" />									
                    <v-select v-model="PageHeadersFont" label="Headers Font"                                  
                                        :items="FontOptions">
                                        <template v-slot:item="{ item }">
                                            <span :style="'font-family:'+item">{{item}}
                                                <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                                </p>
                                                </span>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <span :style="'font-family:'+item">{{item}}</span>
                                        </template>
                                        </v-select>                                 
                                        <v-select v-model="PublishType" label="Visbility"                                   
                                        :items="PublishTypes"/>                                 
                        <v-select v-model="PageContentFont" label="Body Font"                                   
                        :items="FontOptions">
                                        <template v-slot:item="{ item }">
                                            <span :style="'font-family:'+item">{{item}}
                                                <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                                </p>
                                                </span>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <span :style="'font-family:'+item">{{item}}</span>
                                        </template>
                                        </v-select>									
                        <v-select v-model="PageHeadersFontSize" :items="[24,36,42,50]"									
                        />	
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CancelPublishPageDialog()">Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="SaveNewPage()">Save</v-btn>
                  </v-card-actions>
              </v-card>
         </v-dialog>
        </v-layout>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import DirectoryViewer from '@/components/Directories/DirectoryViewer';
import FieldConfig from '@/components/Database/Fields/FieldConfig';	
import TreeViewComp from '@/components/Directories/ListComponents/TreeViewComp';
import SingleOptionFilterComp from '@/components/Directories/ListComponents/SingleOptionFilterComp';
import SingleNumberFilterComp from '@/components/Directories/ListComponents/SingleNumberFilterComp';
import FilterListComp from '@/components/Directories/ListComponents/FilterListComp';
import RatingsComp from '@/components/Directories/ListComponents/RatingsComp';
import ClassifiedListingListItem from '@/components/Directories/ClassifiedListingListItem';
import WikiListingItem from '@/components/Directories/WikiListingItem';
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import SystemAssetsSelector from '@/components/General/SystemAssetsSelector';

export default {
    props: ['ActiveNavbar','System','PublicPreview','GraphicalElements','SystemPhotoLibraries','Directories','AppisDarkMode','SystemEntities','WikiEntities','CartItems'],
    components: {
        DirectoryViewer,
        FieldConfig,
        TreeViewComp,
        SingleOptionFilterComp,
        SingleNumberFilterComp,
        FilterListComp,
        RatingsComp,
        ClassifiedListingListItem,
        WikiListingItem,
        SingleTabFieldsSection,
        FieldViewerComponent,
        FieldValueEditerComponent,
        SystemAssetsSelector
    },
    data(){
        return {
            AddfieldDialog: false,
            NewFieldDialog: false,
            AddingNewField: false,
            NewField: '',
            QualifiedListings: [],
            WikiProperties: ['WikiFields','HideIMG','IMGField','HideDescription','DescriptionField','TaggedFields','ShowPrice','PriceField','TitleField','HideTitle','TagField'],
            CombinedTitleDialog: false,
            CombinedTitleMap: '',
            // PageObj: {},
            // PageFields: [
            //     {Name: 'PageName',DisplayName: 'Page Name',Type: 'Single Line Text'},
            //     {Name: 'PageName',DisplayName: 'Page Name',Type: 'Single Line Text'}
            // ]
            PublishPageDialog: false,
            PublishType: '',									
            PublishTypes: [									
                'Public',									
                'Members Only'									
            ],
            PageOverview: '',	
            SingleName: '',								
            PageName: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            
            FeatureWindow: 1,
            FeaturesListConfigDialog: false,
            FeaturesListRelatedBuildID: '',
            SystemAssetsPage: 1,
            FeatureList: [],
            FeatureListType: '',
            FeatureListLookupField: '',
            SavingNavList: false,
            appendedfiltersstep: 1,
            editedListItem: '',
            editedFilterParent: {},
            ViewAppendedFiltersDialog: false,
            AddAppendedFiltersDialog: false,
            editedappendedFilter: { Field: {}},
            AdditionalFilters: [],
            Refreshing: false,
            ListPrimaryField: '',
            CurrentEntity: '',
            EditedField: '',
            EditFieldDialog: false,
            
            editedNavItem: {

            },
            editedNavItemIndex: -1,
            editedSubItem: {

            },
            editedSubItemIndex: -1,
            editedSubItemProp: '',
            NavlistDialog: false,
            NavlistSubItemDialog: false,
            NavListFields: [],
            NavList: [],
            ClassifiedClasses: [
                {
                    id: 'BglxN25PzOc1zLp1n8pD',
                    Name: 'Toyota'
                }
            ],
            
            VehiclesLinksGroupsearch: '',
            VehiclesWikiViewType: '',
            VehiclesWikiViewbyOptions: [],
            ViewPortItemType: '',
            ViewPortItem: '',
            ViewPortBuilderDialog: false,
            
            HasLocation: false,
        }
    },
    computed:{
        SavingNavListArray(){
            return this.ComputedNavList.filter(item => {
                    if(item.Type === 'Filter List'){
                        item.Filters = item.Filters.map(filter => {
                            if(filter.Type === 'Keyword'){
                                delete filter.Options
                            }
                            return filter
                        })
                    }
                    else{
                        if(item.Field && item.Field.Type === 'Keyword'){
                            delete item.Field.Options
                        }
                    }
                    return item
                })
        },
        FontOptions(){
            return this.$store.state.FontOptions
        },
        DefaultDirectories(){
            return this.$store.state.DefaultDirectories
        },
        Dirid(){
            //console.log('this.CurrentEntity',this.CurrentEntity)
            return this.ActiveMarketTemplate || this.ActiveSuiteTemplate ? this.$route.params.slug : this.$route.params.id
        },
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
        },
        ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
        ActiveSuiteid(){
            return this.$store.state.ActiveSuiteid
        },
        ConfigPath(){
            return this.ActiveSuiteTemplate ? 
            db.collection('Libraries').doc(this.ActiveSuiteTemplate.id) : 
            this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : 
            db.collection('Libraries').doc(this.ActiveSuiteid)
        },
        TaggableFields(){
            return this.CurrentEntity && this.CurrentEntity.id === 'Group_Categories' ? this.CurrentEntity.AllFields.concat(this.CurrentEntity.AdditionalFields) : this.CurrentEntity.AllFields
        },
        EntityCollectionRef(){
             if(this.$route.meta && this.$route.meta.WikiCollectionBuilder){
                return db.collection('Libraries').doc(this.ActiveSuiteid).collection('wikientities').doc(this.Dirid)
            }
            else{
            return this.ConfigPath.collection('Directories').doc(this.Dirid)
            }
        },
        FieldTypes(){
            let fieldtypes = ['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
      'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
      'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Keyword']
      return fieldtypes.map(type => {
          let typeobj = {
              Type: type,
              Name: '',
              DisplayName: '',              
          }
          if(type === 'Lookup'){
               //typeobj.Description = 'Here is a Description about Lookup Fields'
          }
          else if(type === 'Parent Field'){
              typeobj.ReadOnly = true
          }
          else if(type === 'Option Set'){
              typeobj.Options = []
              typeobj.lastassignedinteger = 1000000
          }
          return typeobj
      })
        },
        CurrentEntityStatusReasons(){
            if(this.CurrentEntity && this.CurrentEntity.id){
              let entity = this.SystemEntities.find(obj => obj.id === this.CurrentEntity.id)
                if(entity){
                let statusfield = entity.AllFields.find(obj => obj.id === 'Status')
                    return statusfield.Options.map(opt => {
                        return opt.Options
                    }).flat()  
                }
                else{ return []}
            }
            else{ return []}
            
            
        },
        LookupFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Lookup'
            })
        },
        ViewPortItemName(){
            if(this.ViewPortItem){
              switch (this.ViewPortItemType) {
                case 'Classified Listing': return this.ViewPortItem.Title
                case 2: return this.editedFilterParent.Name + ' - Choose Field Type'
                case 3: return this.editedFilterParent.Name + ' - Configure field'
                default: return this.editedFilterParent.Name + ' - Field Created'
                }  
            }
            
        },
        AdvanceAppendedFilterDialogNextText(){
            switch (this.appendedfiltersstep) {
                case 4: return 'Create Filter'
                default: return 'Next'
                }

        },
        currentAppendedFiltersTitle(){
            switch (this.appendedfiltersstep) {
                case 1: return this.editedFilterParent.Name + ' - Select Field'
                case 2: return this.editedFilterParent.Name + ' - Choose Field Type'
                case 3: return this.editedFilterParent.Name + ' - Configure field'
                default: return this.editedFilterParent.Name + ' - Field Created'
                }
        },
        ComputedDefaultDirectories(){
            //bear in mind now if "Store has price" then Store Price Field relevant...
            return this.DefaultDirectories
        },
        ComputedNavItemTypes(){          
            if(this.ListPrimaryField && this.ListPrimaryField.HasDeepHierarchy){                
                return ['TreeView','Single Option Filter','Single Number Filter','Filter List','Rating','Text Search']
            }
            else{
                return ['Single Option Filter','Single Number Filter','Filter List','Rating','Text Search']
            }            
        },
        ComputedNavTreeField(){
            if(this.editedNavItem.NavTreeField && !this.editedNavItem.NavTreeField.id){
                return this.CurrentEntity.AllFields.find(obj => obj.id === this.editedNavItem.NavTreeField)
            }
        },
        ComputedNavList(){
            // let arr1 = this.NavList
            // let arr2 = this.AdditionalFilters
            // return this.SavingNavList ? arr1 : arr1.concat(arr2)
            return this.NavList
        },
        ComputedFields(){
            let arr1 = []
            if(this.CurrentEntity.AllFields && !this.editedListItem){
                arr1 = this.CurrentEntity.AllFields 
            }
            let arr2 = []
            if(this.CurrentEntity.AdditionalFields){
                arr2 = this.CurrentEntity.AdditionalFields
            }
            let arr3 = arr1.concat(arr2)
            switch (this.editedNavItem.Type) {
                case 'TreeView': return arr3
                .filter(field => {
                    return field.HasDeepHierarchy
                })
                case 'Single Option Filter': return arr3
              .filter(field => {
                  return field.Type === 'Option Set' && !field.HasDeepHierarchy || field.Type === 'Lookup' || field.Type === 'Radio Group' || field.Type === 'Keyword'
              })
              case 'Text Search': return arr3
              .filter(field => {
                  return field.Type === 'Single Line Text'
              })
                case 'Single Number Filter': return arr3
              .filter(field => {
                  return field.Type === 'Number Field'
              })
                case 'Filter List': return arr3
              .filter(field => {
                  return field.Type === 'Number Field' || field.Type === 'Option Set' && !field.HasDeepHierarchy || field.Type === 'Lookup' || field.Type === 'Radio Group' || field.Type === 'Keyword'
              })
                default: return arr3
                }
            
        },
        userLoggedIn () {
      return this.$store.getters.user
    },
    
    },
    created(){
        this.$store.commit('setBuilderView','')
        this.$emit('ToggleCatNavlistView',true)
        //alert('yep')
        this.GetCategoricalList()
    },
    methods:{
        ConfirmDeleteNavItem(editedNavItem){
            confirm('Are you sure you want to delete this item off the navigational filter list?') && this.ProcessDeleteNavItem(editedNavItem)
        },
        ProcessDeleteNavItem(editedNavItem){
           this.NavList.splice(this.editedNavItemIndex,1)
        },
        ToggleNewFieldDialog(){
            //FieldConfig
            this.AddfieldDialog = !this.AddfieldDialog
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            this.ToggleNewFieldDialog()
            this.NewField = Object.assign({},field)
            this.NewFieldDialog = true
            this.AddingNewField = AddingNewField
            // if(tableoption){
            //     this.NewTableOption = tableoption 
            // }
        },
        CancelNewFieldDialog(){
            this.NewFieldDialog = false,
            this.NewField = ''
            //this.NewTableOption = ''
        },
        AddField(field){
            let fieldobj = Object.assign({},field)
            delete fieldobj.Array
            delete fieldobj.RecordData
            if(!fieldobj.Name){
                fieldobj.Name = fieldobj.DisplayName.split(' ').join('_')
            }
            //console.log(this.EntityCollectionRef,field)
            this.CurrentEntity.AdditionalFields.push(fieldobj)
            this.CancelNewFieldDialog()
            // if(this.NeedsRASync){
            //  this.RAConfigDB.collection('AllFields').doc(field.Name).set(fieldobj).then(radoc => {
            //     this.EntityCollectionRef.collection('AllFields').doc(field.Name).set(fieldobj)
            // })   
            // }
            // else{
            //     this.EntityCollectionRef.collection('AllFields').doc(field.Name).set(fieldobj)
            // }
        },
        RefreshViewPortBuilderDialog(){
            this.ViewPortBuilderDialog = false
            setTimeout(() => {
                this.ViewPortBuilderDialog = true
            }, 100);
        },
        UpdateFeautreListBuildID(FeatureListLookupField){
            if(FeatureListLookupField){
                this.FeaturesListRelatedBuildID = FeatureListLookupField.RelatedBuildID
            }
        },
        CheckArrayforLookup(Filters){
            Filters.map(field => {
                this.CheckforLookup(field)
            })
        },
        CheckforLookup(field){
           
            if(field && field.Type === 'Lookup'){
                let options = []
                let arrayname = ''
                let entity = this.SystemEntities.find(obj => obj.id === field.RelatedBuildID)
                if(entity.id === 'Users'){
                    arrayname = 'Users'                    
                }
                else{
                    arrayname = entity.DisplayName.split(' ').join('')+'Array'
                }
                options = JSON.parse(JSON.stringify(this.$store.state[arrayname]))
                    
                field.Options = options.map(opt => {
                        let lookupopt = {
                            Name: opt[field.LookupFieldName],
                            ID: opt.id
                        }
                        return lookupopt
                    })
            }
            console.log(field)
        },
        CancelPublishPageDialog(){
            this.PublishPageDialog = false
        },
        SaveNewPage(){
            if(this.$route.meta && this.$route.meta.WikiCollectionBuilder){
                
                this.WikiProperties.map(item => {
                    if(typeof this.CurrentEntity[item] !== 'undefined'){
                        this.EntityCollectionRef.update({
                            [item]: this.CurrentEntity[item]
                        })
                    }
                }) 
                 this.EntityCollectionRef.update({
                    SingleName: this.SingleName
                })
                this.EntityCollectionRef.update({
                    PublishType: this.PublishType
                })
                this.EntityCollectionRef.update({
                    PageOverview: this.PageOverview
                })
                this.EntityCollectionRef.update({
                    PageName: this.PageName
                })
                         
                this.SavingNavList = false
                this.CancelPublishPageDialog()
            }
            else{
                let pageid = this.PageName.split(' ').join('_')
                let pageobj ={
                    Name: this.PageName,
                    OverView: this.PageOverview,
                    PageContentFont: this.PageContentFont,
                    PageHeadersFont: this.PageHeadersFont,
                    PageHeadersFontSize: this.PageHeadersFontSize,
                    PageTabs: [],
                    PublishType: this.PublishType,
                    CatNavList: this.Dirid
                }
                let pageref = this.PublishType === 'Public' ? db.collection('Websites').doc(this.ActiveSuiteid).collection('Pages').doc(pageid) : 
                db.collection('SocialNetworks').doc(this.ActiveSuiteid).collection('Pages').doc(pageid)
                pageref.set(pageobj).then(savedoc => {
                    this.EntityCollectionRef.update({
                        ActivePageid: pageid
                    })
                    this.CancelPublishPageDialog()
                })
            }
            
        },
        CancelFeatureBuilderDialog(){
            this.FeaturesListConfigDialog = false
        },
        UpdateFeatureList(){
            this.SaveNavList()
            this.CancelFeatureBuilderDialog()
        },
        AddFeature(){
            let newfeature = {
                
                Type: 'Boolean'
            }
            this.FeatureList.push(newfeature)
        },
        ActivateFeaturesListConfigDialog(){
            this.FeaturesListConfigDialog = true
        },
        UpdateViewPort(){
            this.CancelViewPortBuilderDialog()
            this.SaveNavList()
        },
        CancelViewPortBuilderDialog(){
            this.ViewPortBuilderDialog = false
            this.ViewPortItem = ''
        },
        ActivateViewportBuilder(item,itemtype){
            console.log(item)
            this.ViewPortItemType = itemtype
            this.ViewPortItem = item
            this.ViewPortBuilderDialog = true
        },
        RestartAppendedFilterFieldDialog(){
            this.editedappendedFilter.Field = {}
            this.appendedfiltersstep = 1
        },
        AdvanceAppendedFilterDialog(){
            if(this.appendedfiltersstep === 1){
                if(this.editedappendedFilter.CreateNewField){
                    this.appendedfiltersstep++
                }
                else{
                    this.appendedfiltersstep = 4
                }
            }
            else if(this.appendedfiltersstep === 2){
                this.editedappendedFilter.Field.DisplayName = this.editedappendedFilter.Field.Name
                this.editedappendedFilter.Field.Name = this.editedappendedFilter.Field.Name.split(' ').join('_')
                this.editedappendedFilter.Field.id = this.editedappendedFilter.Field.Name
                //actually also conditional render. classifieds on level 3 example goes
                //mainclass = mainparent ID
                //subclass = whatever parent e.g. Mobile Devices
                //Category = whatever direct parent e.g. Huwawei, return "models"
                this.appendedfiltersstep++
            }
            else if(this.appendedfiltersstep === 3){
                this.CurrentEntity.AdditionalFields.push(this.editedappendedFilter.Field)
                
                this.appendedfiltersstep++
            }
            else if(this.appendedfiltersstep === 4){
                this.editedNavItem = this.editedappendedFilter
                this.AddAppendedFiltersDialog = false
                this.NavlistDialog = true
                //okay here we must open NavListDialog but
                //editedappendedFilter dialog closes, editedNavItem becomes editedappendedFilter, NavListDialog opens
                //"Field" should now be "readonly" ? And "Types" are "limited"
                //On this, when we "Add Item" AFTEr THIS it should add to editedFilterParent.AppendedFilters
            }
        },
        ActivateAddAppendedFiltersDialog(){
            this.AddAppendedFiltersDialog = true
        },
        
        AppendedfilterActivate(filter){
            this.editedNavItem = filter
            this.NavlistDialog = true
        },
        AppendFilters(lvl,item,listitem,parent){
            
            //AddAppendedFiltersDialog
            //editedFilterParent
            this.editedFilterParent = item
            this.editedListItem = listitem
            if(!this.editedFilterParent.AppendedFilters){
                this.editedFilterParent.AppendedFilters = []
                this.editedFilterParent.LastAppendedFilter = 1000000
            }
            if(!this.editedListItem.AppendedFilters){
                this.editedListItem.AppendedFilters = []
            }
            this.ViewAppendedFiltersDialog = true
            // this.AddAppendedFiltersDialog = true
            console.log(this.editedNavItem,lvl,item,this.editedListItem,parent)
            this.CancelAddNavItem()
        },
        AppendFilter(){
            //huh?
        },
        CancelAppendFiltersDialog(){
            this.ViewAppendedFiltersDialog = false
            this.editedFilterParent = {}
            this.editedappendedFilter = {Field: {}}            
            this.editedListItem = ''
        },
        CancelAddAppendFiltersDialog(){
            this.editedappendedFilter.Field = {}
            this.AddAppendedFiltersDialog = false
        },
        ActivateTreeNode(item){
            this.AdditionalFilters = []
            //console.log('item for fielddialg',item)
            if(item.AppendedFilters){
                this.AdditionalFilters = item.AppendedFilters
                
            }
            //console.log(this.AdditionalFilters)
            //this.Refresh()
            
        },
        UpdateNavSubItem(){
            console.log(this.NavList,this.editedSubItem)
            if(this.editedNavItem.Type === 'Filter List' && this.editedNavItem.Filters){
                    //alert('here one')
                    console.log(this.editedNavItem.Filters)
                    this.editedNavItem.Filters[this.editedSubItemIndex] = this.editedSubItem
                    this.editedNavItem.Filters = this.editedNavItem.Filters.map(filter => {
                        if(filter.Type === 'Number Field'){
                            filter.CanEdit = true
                        }
                        if(filter.Type === 'Number Field' && typeof filter.Min !== 'undefined' && typeof filter.Max !== 'undefined' && typeof filter.StartingInteger !== 'undefined' && typeof filter.Intervals !== 'undefined'){
                            filter.Options = []
                            let OptionsCount = Number((filter.Max-filter.Min)/filter.Intervals)
                            for (let i = 0; i < OptionsCount; i++){   
                                let startint =   filter.Min+(i*filter.Intervals)  
                                let endint =   filter.Min+((i-1+2)*filter.Intervals)                     
                                let newopt = {
                                    ID: 1000000+i-1+2,
                                    Name: (startint)+' - '+(endint),
                                    Range: [startint,endint]
                                }
                                filter.Options.push(newopt)
                            }
                        }
                        //had this before, unsure how or why what the deal was. either way, continue
                        //just stating wha tI don't get about the below - why woul I ever have a Min and a Max, WIUTH interval...but then a starting int AS WELL???
                        // if(filter.Type === 'Number Field' && typeof filter.Min !== 'undefined' && typeof filter.Max !== 'undefined' && typeof filter.StartingInteger !== 'undefined' && typeof filter.Intervals !== 'undefined'){
                        //     filter.Options = []
                        //     let OptionsCount = Number((filter.Max-filter.Min)/filter.Intervals)
                        //     for (let i = 0; i < OptionsCount; i++){                                
                        //         let newopt = {
                        //             ID: 1000000+i-1+2,
                        //             Name: (i*filter.Intervals)+' - '+((i-1+2)*filter.Intervals),
                        //             Range: [i*filter.Intervals,(i-1+2)*filter.Intervals]
                        //         }
                        //         filter.Options.push(newopt)
                        //     }
                        // }
                        return filter
                    })
                }
                this.NavList[this.editedNavItemIndex] = this.editedNavItem
            // this.SaveNavList()
            this.Refresh()
            this.DeactivateNavSubItemDialog()
            
        },
        EditNavItem(navitem,navitemindex){
            this.editedNavItemIndex = navitemindex
            this.editedNavItem = navitem
            this.ActivateNewNavItemDialog()
        },
        EditNavItemSubItem(navitem,navitemindex,child,childindex,prop){
            this.editedNavItem = navitem
            this.editedNavItemIndex = navitemindex            
            this.ActivateNavSubItemDialog(child,childindex,prop)
        },
        ActivateNavSubItemDialog(child,childindex,prop){
            this.editedSubItemIndex = childindex
            this.editedSubItem = child
            this.editedSubItemProp = prop
            this.NavlistSubItemDialog = true
        },
        DeactivateNavSubItemDialog(){
            this.NavlistSubItemDialog = false
            this.editedSubItemIndex = -1
            this.editedSubItem = {}
            this.editedSubItemProp = ''
            
        },
        ActivateNewNavItemDialog(){
            this.NavlistDialog = true
        },
        CheckNavTreeField(){
            if(this.editedNavItem.Type === 'TreeView'){
                this.editedNavItem.NavTreeField = this.ListPrimaryField
            }
        },
        SaveNavList(){
            this.SavingNavList = true
            console.log(this.CurrentEntity)
            let saveobj = {
                DisplayName: this.CurrentEntity.DisplayName,
                SingleName: this.CurrentEntity.SingleName,
                NavList: this.SavingNavListArray,
                CategoricalListType: this.CurrentEntity.CategoricalListType,
                AdditionalFields: this.CurrentEntity.AdditionalFields,
                FeatureList: this.CurrentEntity.FeatureList
            }
            if(this.CurrentEntity.Cart_Entity){
                saveobj.Cart_Entity = this.CurrentEntity.Cart_Entity
            }
            if(this.CurrentEntity.Has_Ratings){
                saveobj.Has_Ratings = this.CurrentEntity.Has_Ratings
            }
            if(this.QualifiedListings){
                saveobj.QualifiedListings = this.QualifiedListings
            }
            if(this.CurrentEntity.FeatureList && this.Dirid !== 'Group_Categories'){
                saveobj.FeatureList = this.CurrentEntity.FeatureList
            }
            else{
                saveobj.FeatureList = this.FeatureList.map(feature => {
                    console.log(feature)
                    if(typeof feature.Name === 'undefined'){
                        feature.Name = feature.DisplayName.split(' ').join('_')
                    }
                    return feature
                })
            }
            if(this.CurrentEntity.RatingModerators){
                let ratingmoderatorids = this.CurrentEntity.RatingModerators.map(mod => {return mod.id})
                saveobj.RatingModerators = this.CurrentEntity.RatingModerators
                saveobj.RatingModeratorsIDs = ratingmoderatorids
            }
            if(this.CurrentEntity.ActivePageid){
                saveobj.ActivePageid = this.CurrentEntity.ActivePageid
            }
            if(this.CombinedTitleMap){
                saveobj.CombinedTitleMap = this.CombinedTitleMap
            }
            if(this.FeatureListType && this.FeaturesListRelatedBuildID){
                saveobj.FeatureListType = this.FeatureListType
                saveobj.FeaturesListRelatedBuildID = this.FeaturesListRelatedBuildID
                saveobj.FeatureList = this.FeatureList.map(featureitem => {
                    if(!featureitem.Name){
                        featureitem.Name = featureitem.DisplayName.split(' ').join('_')
                        featureitem.id = featureitem.Name
                    }
                    return featureitem
                })
            }
            if(this.FeatureListLookupField){
                saveobj.FeatureListLookupField = this.FeatureListLookupField
            }
            if(this.ListPrimaryField){
                saveobj.ListPrimaryField = this.ListPrimaryField
            }
            if(this.CurrentEntity.TaggedFields){
                saveobj.TaggedFields = this.CurrentEntity.TaggedFields
            }
            if(this.CurrentEntity.TagField){
                saveobj.TagField = this.CurrentEntity.TagField
            }
            
            if(this.CurrentEntity.HasLocation){
                saveobj.HasLocation = this.CurrentEntity.HasLocation
            }
            if(this.CurrentEntity.HidePrice){
                saveobj.HidePrice = this.CurrentEntity.HidePrice
            }
            if(this.CurrentEntity.HideDescription){
                saveobj.HideDescription = this.CurrentEntity.HideDescription
            }
            if(this.CurrentEntity.IMGIsCarousel){
                saveobj.IMGIsCarousel = this.CurrentEntity.IMGIsCarousel
            }
            
            if(this.CurrentEntity.HideTitle){
                saveobj.HasLocation = this.CurrentEntity.HideTitle
            }
            console.log(saveobj)
            if(this.$route.meta && this.$route.meta.WikiCollectionBuilder){
                // let properties = ['WikiFields','HideIMG','IMGField','HideDescription','DescriptionField','TaggedFields','ShowPrice','PriceField','TitleField','HideTitle','TagField']
                // properties.map(item => {
                //     if(typeof this.CurrentEntity[item] !== 'undefined'){
                //         this.EntityCollectionRef.update({
                //             WikiFields: this.CurrentEntity.WikiFields
                //         })
                //     }
                // }) 
                // this.EntityCollectionRef.update({
                //     PublishType: this.PublishType
                // })
                // this.EntityCollectionRef.update({
                //     PageOverview: this.PageOverview
                // })
                // this.EntityCollectionRef.update({
                //     PageName: this.PageName
                // })
                         
                // this.SavingNavList = false
            }
            else{
              // db.collection('SystemConfig').doc(this.ActiveSuiteid).collection('Directories')
                let dbref = this.EntityCollectionRef
                
                dbref.set(saveobj).then(savedoc => {
                    this.SavingNavList = false
                })  
            }
              
            
        },
        EditField(fieldprop){
            if(this.editedNavItem[fieldprop] && !this.editedNavItem[fieldprop].id){
             this.editedNavItem[fieldprop] = this.CurrentEntity.AllFields.find(obj => obj.id === this.editedNavItem[fieldprop])
            // console.log(this.ListPrimaryField)
            // this.EditedField = this.ListPrimaryField
            //this.EditedField = Object.assign({},this.ComputedNavTreeField)
            this.EditFieldDialog = true   
            }
            else if(this.editedNavItem[fieldprop] && this.editedNavItem[fieldprop].id){
          
            this.EditFieldDialog = true   
            }
            
        },
        CancelEditField(){
            this.EditFieldDialog = false
        },
        AddFieldOdd(){
            //console.log(this.NavTreeField,this.CurrentEntity.AllFields)
            this.CancelEditField()
        },
        UpdateField(field,prop,value){
            field[prop] = value
            console.log(this.EditedField)
            this.CancelEditField()
        },
        AddNewNavItem(){
            
            let navitem = Object.assign({},this.editedNavItem) 
               
            if(typeof navitem.Min !== 'undefined' && typeof navitem.Max !== 'undefined'){
                navitem.Range = [navitem.Min,navitem.Max]
            }
            if(navitem.Type === 'TreeView'){
              let HierarchyField = ''
            if(navitem.NavTreeField && navitem.NavTreeField.id){
              
                HierarchyField = navitem.NavTreeField
                navitem.NavTreeField = navitem.NavTreeField.id
            }
            else{
                
                HierarchyField = this.ListPrimaryField
            }
                console.log(HierarchyField)
                let array = JSON.parse(JSON.stringify(HierarchyField.Options))      
                navitem.TreeList = array
                .map(option => {
                    console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    // id: option.ID+'Record:'+option.LevelFieldName,
                    id: option.ID,
                    //EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                if(option.Icon){
                    treeobj.Icon = option.Icon
                }
                if(option.AppendedFilters){
                    navitem.AppendedFilters = option.AppendedFilters
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        //console.log('secondoption',secondoption)
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        //EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Icon){
                            secondtreeobj.Icon = secondoption.Icon
                        }
                        if(secondoption.AppendedFilters){
                            secondtreeobj.AppendedFilters = secondoption.AppendedFilters
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                           // console.log('thirdoption',thirdoption)
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            //EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Icon){
                                thirdtreeobj.Icon = thirdoption.Icon
                            }
                            if(thirdoption.AppendedFilters){
                                thirdtreeobj.AppendedFilters = thirdoption.AppendedFilters
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                //EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                })  
            }
            if(navitem.Type === 'Filter List' && navitem.Filters){
                    //alert('here one')
                    console.log(navitem.Filters)
                    navitem.Filters = navitem.Filters.map(filter => {
                        if(filter.Type === 'Number Field'){
                            filter.CanEdit = true
                        }
                        if(filter.Type === 'Number Field' && typeof filter.Min !== 'undefined' && typeof filter.Max !== 'undefined' && typeof filter.StartingInteger !== 'undefined' && typeof filter.Intervals !== 'undefined'){
                            filter.Options = []
                            let OptionsCount = Number((filter.Max-filter.Min)/filter.Intervals)
                            for (let i = 0; i < OptionsCount; i++){                                
                                let newopt = {
                                    ID: 1000000+i-1+2,
                                    Name: (i*filter.Intervals)+' - '+((i-1+2)*filter.Intervals),
                                    Range: [i*filter.Intervals,(i-1+2)*filter.Intervals]
                                }
                                filter.Options.push(newopt)
                            }
                        }
                        return filter
                    })
                }
            
                //shuold actuall y be "id"
            
            console.log(navitem)
            if(this.editedNavItemIndex === -1){
                if(this.editedListItem){
                    this.editedFilterParent.AppendedFilters.push(navitem)
                    this.editedListItem.AppendedFilters.push(navitem)
                }
                else{
                    this.NavList.push(navitem)
                }              
                
            }
            else{
                 //if this.editedappendedFilter add to editedFilterParent.AppendedFilters???
                 //what do we do if it already exists, right?
                this.NavList[this.editedNavItemIndex] = Object.assign({},navitem)
            }
            console.log(this.NavList,this.ComputedNavList,this.editedappendedFilter,this.editedFilterParent,this.editedNavItemIndex,this.ListPrimaryField)
            this.Refresh()
            
            this.CancelAddNavItem()
            
            
        },
        Refresh(){
            this.Refreshing = true
            setTimeout(() => {
                console.log(this.NavList,this.ComputedNavList)
                this.Refreshing = false
            }, 10);
        },
        CancelAddNavItem(){
            this.NavlistDialog = false
            this.editedNavItemIndex = -1
            this.editedNavItem = {}
        },
        AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
        GetCategoricalList(){
            let defaultentity =  Object.assign({},this.DefaultDirectories.find(obj => obj.id === this.Dirid))
            let customentity = this.SystemEntities.find(obj => obj.id === this.Dirid)
            let query = this.EntityCollectionRef
            query.onSnapshot(snapshot => {
                let data = snapshot.data()
                if(data){
                    if(data.QualifiedListings){
                        this.QualifiedListings = data.QualifiedListings
                    }
                     if(this.$route.meta && this.$route.meta.WikiType){
                         let entity = this.SystemEntities.find(obj => obj.id === this.Dirid)
                         this.CurrentEntity = entity
                         
                         let wikientity = this.WikiEntities.find(obj => obj.id === this.Dirid)
                         if(wikientity){
                             console.log('wikientity',wikientity)
                            this.WikiProperties.map(item => {
                             if(typeof wikientity[item] !== 'undefined'){
                                 this.CurrentEntity[item] = wikientity[item]
                             }
                            })
                         }
                         if(data.WikiFields){
                         this.CurrentEntity.WikiFields = data.WikiFields
                         }
                         if(data.TaggedFields){
                             this.CurrentEntity.TaggedFields = data.TaggedFields
                         }
                         else{
                             this.CurrentEntity.TaggedFields = []
                         }
                         if(!data.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = []
                        }
                            //this.CurrentEntity.CategoricalListType = 'Wiki Listing'
                            this.CurrentEntity.CategoricalListType = 'Wiki Listing'
                            if(data.PublishType){
                                this.PublishType = data.PublishType
                            }
                            if(data.PageOverview){
                                this.PageOverview = data.PageOverview
                            }
                            if(data.PageName){
                                this.PageName = data.PageName
                            }
                            else if(this.CurrentEntity.DisplayName){
                                this.PageName = this.CurrentEntity.DisplayName
                            }
                            if(data.SingleName){
                                this.SingleName = data.SingleName
                            }
                            else{
                                this.SingleName = this.CurrentEntity.SingleName
                            }
                            
                    }
                    else if(this.Dirid === 'Group_Categories'){
                        this.CurrentEntity = {id: 'Group_Categories',HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Social Groups',DisplayName: 'Social Groups',SingleName: 'Social Group',AllFields: [
                            {Name: 'name',DisplayName: 'Name',Type: 'Single Line Text',Primary: true},
                            {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text'},
                            {Name: 'Logo',DisplayName: 'Logo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                            {Name: 'Telephone',DisplayName: 'Telephone',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                        ],FeatureList: []}
                        this.NavList = data.NavList
                        this.ListPrimaryField = this.System.Group_Categories
                        this.CurrentEntity.ListPrimaryField = this.ListPrimaryField
                        this.CurrentEntity.AllFields.push(this.ListPrimaryField)
                        if(data.FeatureList){
                            this.FeatureList = data.FeatureList
                        }
                        if(data.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = data.AdditionalFields
                        }
                        if(data.TagField){
                            this.CurrentEntity.TagField = data.TagField
                        }
                    }
                    else if(this.Dirid === 'Business_Members'){
                         this.CurrentEntity = {id: 'Business_Members',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Business Members',DisplayName: 'Business Members',SingleName: 'Business Member',AllFields: [
                            {Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true},
                            {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Profile_Photo',DisplayName: 'Profile Photo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                            {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                            {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                        ],FeatureList: [],TagField:{Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true}}
                        this.NavList = data.NavList
                        this.CurrentEntity.AllFields.push(this.ListPrimaryField)
                        if(data.FeatureList){
                            this.FeatureList = data.FeatureList
                        }
                        if(data.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = data.AdditionalFields
                        }
                        if(data.TagField){
                            this.CurrentEntity.TagField = data.TagField
                        }
                    }
                    else if(this.Dirid === 'Featured_Members'){ 
                        //what now?
                        this.CurrentEntity = {id: 'Featured_Members',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Featured Members'
                        ,DisplayName: 'Featured Members',SingleName: 'Featured Member',AllFields: [
                            {Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true},
                            //{Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Profile_Photo',DisplayName: 'Profile Photo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                            {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                            {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                        ],FeatureList: [],TagField:{Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true}}
                        this.NavList = data.NavList
                        this.CurrentEntity.AllFields.push(this.ListPrimaryField)
                        if(data.FeatureList){
                            this.FeatureList = data.FeatureList
                        }
                        if(data.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = data.AdditionalFields
                        }
                        if(data.TagField){
                            this.CurrentEntity.TagField = data.TagField
                        }
                        }
                        else if(this.Dirid === 'Vacancies'){ 
                        //what now?
                        this.CurrentEntity = {id: 'Vacancies',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Vacancies'
                        ,DisplayName: 'Vacancies',SingleName: 'Vacancy',AllFields: [
                            {Name: 'Title',DisplayName: 'Title',Type: 'Single Line Text',Primary: true},
                            {Name: 'Urgent',DisplayName: 'Urgent',Type: 'Boolean'},
                            {Name: 'Remote',DisplayName: 'Remote',Type: 'Boolean'},
                            //{Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Company',DisplayName: 'Company',Type: 'Lookup',LookupFieldName: 'Company_Name',RelatedBuildID: 'Member_Companies',RelatedLocation: 'Member Company', RelatedRecord: 'Member Companies',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            // {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                            // {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                        ],FeatureList: [],TagField:{Name: 'Title',DisplayName: 'Title',Type: 'Single Line Text',Primary: true}}
                        this.NavList = data.NavList
                        this.CurrentEntity.AllFields.push(this.ListPrimaryField)
                        if(data.FeatureList){
                            this.FeatureList = data.FeatureList
                        }
                        if(data.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = data.AdditionalFields
                        }
                        if(data.TagField){
                            this.CurrentEntity.TagField = data.TagField
                        }
                        }
                    
                    
                        console.log(data)
                        this.CurrentEntity = data
                        if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                            let entity = this.SystemEntities.find(obj => obj.id === this.Dirid)
                            console.log('store entity',entity)
                            this.CurrentEntity.ListPrimaryField = entity.AllFields.find(obj => obj.id === 'Product_Group')
                            if(entity.Cart_Entity){
                                this.CurrentEntity.Cart_Entity = entity.Cart_Entity
                            }
                        }
                        this.CurrentEntity.id = this.Dirid
                        if(this.CurrentEntity.ListPrimaryField){
                        this.ListPrimaryField = this.CurrentEntity.ListPrimaryField
                        }
                        else if(this.CurrentEntity.id === 'Systems'){
                            this.ListPrimaryField = JSON.parse(JSON.stringify(this.$store.state.DefaultSiteCategoryField))
                            this.CurrentEntity.ListPrimaryField = JSON.parse(JSON.stringify(this.$store.state.DefaultSiteCategoryField))
                        
                        }
                        if(this.CurrentEntity.NavList){
                            this.NavList = this.CurrentEntity.NavList
                        }
                        
                        if(defaultentity){
                            this.CurrentEntity.AllFields = defaultentity.AllFields
                            // if(!this.CurrentEntity.AllFields){
                            //     this.CurrentEntity.AllFields = this.CurrentEntity.AdditionalFields
                            // }
                            if(defaultentity.ListPrimaryField){
                                this.CurrentEntity.ListPrimaryField = defaultentity.ListPrimaryField
                            }
                            if(this.CurrentEntity.ListPrimaryField){
                            let oncheck = this.CurrentEntity.AllFields.find(obj => obj.id === this.CurrentEntity.ListPrimaryField.id)
                            if(!oncheck && this.CurrentEntity.id === 'Systems'){                                
                                this.CurrentEntity.AllFields.push(JSON.parse(JSON.stringify(this.CurrentEntity.ListPrimaryField)))
                            }
                            }
                        }
                        else{
                            if(customentity && customentity.AllFields){
                            this.CurrentEntity.AllFields = customentity.AllFields
                            }
                            if(this.CurrentEntity.CombinedTitleMap){
                                this.CombinedTitleMap = this.CurrentEntity.CombinedTitleMap
                            }
                        }
                        if(!this.CurrentEntity.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = []
                        }
                        if(data.FeatureList){
                            this.FeatureList = data.FeatureList
                        }
                        else{
                            this.CurrentEntity.FeatureList = []
                        }
                        if(data.FeatureListType){
                            this.FeatureListType = data.FeatureListType
                        }
                        if(data.FeaturesListRelatedBuildID){
                            this.FeaturesListRelatedBuildID = data.FeaturesListRelatedBuildID
                        }
                        if(data.FeatureListLookupField){
                            this.FeatureListLookupField = data.FeatureListLookupField
                        }
                }
                else{
                    if(this.Dirid === 'Group_Categories'){
                        this.CurrentEntity = {id: 'Group_Categories',HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Social Groups',DisplayName: 'Social Groups',SingleName: 'Social Group',AllFields: [
                            {Name: 'name',DisplayName: 'Name',Type: 'Single Line Text',Primary: true},
                            {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text'},
                            {Name: 'Logo',DisplayName: 'Logo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                        ],FeatureList: []}
                        this.NavList = []
                        this.ListPrimaryField = this.System.Group_Categories
                        this.CurrentEntity.ListPrimaryField = this.ListPrimaryField
                        this.CurrentEntity.AllFields.push(this.ListPrimaryField)
                        this.FeatureList = this.CurrentEntity.FeatureList
                    }
                    else if(this.Dirid === 'Group_Featured_Members'){
                      this.CurrentEntity = {id: 'Group_Featured_Members',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Group Featured Members',DisplayName: 'Group Featured Members',SingleName: 'Group Featured Member',AllFields: [
                            {Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true},
                            {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Profile_Photo',DisplayName: 'Profile Photo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                        ],FeatureList: [],TagField:{Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true} }  
                    }
                    else if(this.Dirid === 'Business_Members'){
                        this.CurrentEntity = {id: 'Business_Members',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Business Members',DisplayName: 'Business Members',SingleName: 'Business Member',AllFields: [
                            {Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true},
                            {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Profile_Photo',DisplayName: 'Profile Photo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                            {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                            {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                        ],FeatureList: [],TagField:{Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true} }
                    }
                    else if(this.Dirid === 'Featured_Members'){ 
                        //what now?
                        this.CurrentEntity = {id: 'Featured_Members',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Featured Members'
                        ,DisplayName: 'Featured Members',SingleName: 'Featured Member',AllFields: [
                            {Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true},
                            //{Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Profile_Photo',DisplayName: 'Profile Photo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                            {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                            {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                        ],FeatureList: [],TagField:{Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true}}
                        }
                    
                        else if(this.Dirid === 'Vacancies'){ 
                        //what now?
                        this.CurrentEntity = {id: 'Vacancies',HideTitle: true,HideDescription:true,HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Vacancies'
                        ,DisplayName: 'Vacancies',SingleName: 'Vacancy',AllFields: [
                            {Name: 'Title',DisplayName: 'Title',Type: 'Single Line Text',Primary: true},
                            {Name: 'Urgent',DisplayName: 'Urgent',Type: 'Boolean'},
                            {Name: 'Remote',DisplayName: 'Remote',Type: 'Boolean'},
                            //{Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            {Name: 'Company',DisplayName: 'Company',Type: 'Lookup',LookupFieldName: 'Company_Name',RelatedBuildID: 'Member_Companies',RelatedLocation: 'Member Company', RelatedRecord: 'Member Companies',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                            // {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                            // {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                        ],FeatureList: [],TagField:{Name: 'Title',DisplayName: 'Title',Type: 'Single Line Text',Primary: true}}
                        this.NavList = data.NavList
                        this.CurrentEntity.AllFields.push(this.ListPrimaryField)
                        if(data.FeatureList){
                            this.FeatureList = data.FeatureList
                        }
                        if(data.AdditionalFields){
                            this.CurrentEntity.AdditionalFields = data.AdditionalFields
                        }
                        if(data.TagField){
                            this.CurrentEntity.TagField = data.TagField
                        }
                        }
                    else if(defaultentity){
                        this.CurrentEntity = Object.assign({},defaultentity)
                        this.CurrentEntity.AdditionalFields = []
                        //console.log('defaultentity',defaultentity,this.DefaultDirectories)
                         if(defaultentity.ListPrimaryField){
                                this.CurrentEntity.ListPrimaryField = defaultentity.ListPrimaryField
                            }
                        this.ListPrimaryField = this.CurrentEntity.AllFields.find(obj => obj.ListPrimary)
                        this.CurrentEntity.ListPrimaryField = this.ListPrimaryField
                    }
                    else{
                        this.CurrentEntity = customentity
                        this.CurrentEntity.AdditionalFields = []
                        this.ListPrimaryField = this.CurrentEntity.AllFields.find(obj => obj.ListPrimary)
                        if(this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Inventory Article'){
                            this.CurrentEntity.CategoricalListType = 'Store Products'
                            this.CurrentEntity.FeatureList = []
                            this.CurrentEntity.ListPrimaryField = this.CurrentEntity.AllFields.find(obj => obj.id === 'Product_Group')
                        }
                        else{
                           this.CurrentEntity.CategoricalListType = 'Classified Listing' 
                           //console.log('yep this type')
                        }
                        
                    }   
                }
                
                if(!this.CurrentEntity.TaggedFields){
                    this.CurrentEntity.TaggedFields = []
                }
                
                console.log(this.CurrentEntity,this.ListPrimaryField)
            })
        }
    }
}
</script>

<style>

</style>