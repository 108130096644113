var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',{class:_vm.tab.Justify || _vm.tab.Center,style:(_vm.tab.TitleTextShadow? _vm.TitleStyle(_vm.tab)+'position: relative;text-shadow: 1px 2px 3px #373737aa;' : _vm.TitleStyle(_vm.tab)+'position: relative;'),attrs:{"id":_vm.tab.ElementID+'_Title'}},[_c('div',{class:_vm.tab.Justify,staticStyle:{"display":"flex","z-index":"2"},attrs:{"dense":""}},[(_vm.tab.HasHeaderImage)?_c('v-avatar',{style:(_vm.tab.ContainIMG ? 'object-fit: contain;' : 'object-fit:cover;'),attrs:{"tile":_vm.tab.Tile,"size":_vm.tab.HeaderFontSize ? _vm.tab.HeaderFontSize : 50}},[(_vm.tab.HasHeaderImage && _vm.tab.HeaderImage)?_c('img',{style:(_vm.tab.IMGBlendMode ? 'mix-blend-mode: '+_vm.tab.IMGBlendMode+_vm.tab.ContainIMG ? 'object-fit: contain;'+_vm.CheckifClipped(_vm.tab) : 'object-fit: cover;'+_vm.CheckifClipped(_vm.tab) : 
                    _vm.tab.ContainIMG ? 'object-fit: contain;'+_vm.CheckifClipped(_vm.tab) : 'object-fit: cover;'+_vm.CheckifClipped(_vm.tab)),attrs:{"height":_vm.tab.HeaderFontSize ? _vm.tab.HeaderFontSize : 50,"src":_vm.tab.HeaderImage}}):_vm._e(),(_vm.tab.HasHeaderImage && !_vm.tab.HeaderImage)?_c('img',{style:(_vm.tab.IMGBlendMode ? 'mix-blend-mode: '+_vm.tab.IMGBlendMode+_vm.tab.ContainIMG ? 'object-fit: contain;'+_vm.CheckifClipped(_vm.tab) : 'object-fit: cover;'+_vm.CheckifClipped(_vm.tab) : 
                    _vm.tab.ContainIMG ? 'object-fit: contain;'+_vm.CheckifClipped(_vm.tab) : 'object-fit: cover;'+_vm.CheckifClipped(_vm.tab)),attrs:{"height":_vm.tab.HeaderFontSize ? _vm.tab.HeaderFontSize : 50,"src":require("@/assets/ImageHolder.png")}}):_vm._e()]):_vm._e(),_c('div',{style:('padding-right: 10px;display: inline-flex;height: 100%;')},[_vm._v(" "+_vm._s(_vm.tab.Title)+" ")]),(!_vm.$vuetify.breakpoint.xs && _vm.tab.FancyTitle !== false)?_c('div',{style:('display: flex; flex-wrap: wrap;flex: 1 1 0%;align-items: center;height: 100%;')},[(_vm.tab.SubTitleTop)?_c('div',{style:(_vm.SubtitleTopStyle(_vm.tab))},[_vm._v(_vm._s(_vm.tab.SubTitleTop))]):_vm._e(),(_vm.tab.SubTitleBottom)?_c('div',{style:(_vm.SubtitleBTMStyle(_vm.tab))},[_vm._v(_vm._s(_vm.tab.SubTitleBottom))]):_vm._e()]):_vm._e()],1),(_vm.$vuetify.breakpoint.xs && _vm.tab.FancyTitle !== false)?_c('v-card-title',{style:(_vm.tab.HeaderFontSize ? 
                'align-items: center;height: 100%;z-index: 2;padding: 0px;' :
                'align-items: center;height: 100%;z-index: 2;padding: 0px;')},[(_vm.tab.SubTitleTop)?_c('div',{style:(_vm.SubtitleTopStyle(_vm.tab))},[_vm._v(_vm._s(_vm.tab.SubTitleTop))]):_vm._e(),(_vm.tab.SubTitleBottom)?_c('div',{style:(_vm.SubtitleBTMStyle(_vm.tab))},[_vm._v(_vm._s(_vm.tab.SubTitleBottom))]):_vm._e()]):_vm._e(),(_vm.tab.Ribbon)?_c('i',{style:(_vm.tab.RibbonInnerColor ? 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-right-color: '+_vm.tab.RibbonInnerColor.hex+';top: 54px;left: -30px;animation: edge forwards 850ms;animation-delay: 900ms;' : 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-right-color: '+_vm.tab.HeaderBGColor+';top: 54px;left: -30px;animation: edge forwards 850ms;animation-delay: 900ms;')}):_vm._e(),(_vm.tab.Ribbon)?_c('i',{style:(_vm.tab.RibbonInnerColor ? 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-left-color: '+_vm.tab.RibbonInnerColor.hex+';top: 54px;right: -30px;animation: edge forwards 850ms;animation-delay: 900ms;' : 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-left-color: '+_vm.tab.HeaderBGColor+';top: 54px;right: -30px;animation: edge forwards 850ms;animation-delay: 900ms;')}):_vm._e(),(_vm.tab.Ribbon)?_c('i',{style:(_vm.tab.RibbonOuterColor ? 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+_vm.tab.RibbonOuterColor.hex+';border-left-color: transparent;left: -15px;animation: edgetiplft forwards 850ms;animation-delay: 1300ms;' : 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+_vm.tab.HeaderBGColor+';border-left-color: transparent;left: -15px;animation: edgetiplft forwards 850ms;animation-delay: 1300ms;')}):_vm._e(),(_vm.tab.Ribbon)?_c('i',{style:(_vm.tab.RibbonOuterColor ? 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+_vm.tab.RibbonOuterColor.hex+';border-right-color: transparent;right: -15px;animation: edgetirght forwards 850ms;animation-delay: 1300ms;' : 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+_vm.tab.HeaderBGColor+';border-right-color: transparent;right: -15px;animation: edgetirght forwards 850ms;animation-delay: 1300ms;')}):_vm._e(),(_vm.tab.Ribbon)?_c('div',{style:('border-top-left-radius: 3px;border-top-right-radius: 3px;z-index:1;position:absolute; width:100%;height:105%;background-color: '+_vm.tab.HeaderBGColor+';animation: edge 950ms;box-shadow: 0px 3px 3px #373737aa;')}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }