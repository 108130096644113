<template>
  <div :class="ProvidedEntityid ? '' : 'v-main__wrap'" :style="MobileView ? '' : TableOnly ? '' : 'margin-top: 120px;'">
   <v-parallax v-if="CurrentEntity && CurrentEntity.TableParallax && !QuickDashView && quickeditstep === 1" 
   :style="MobileView ? 'position: absolute;top: 0px;width: 100%;clip-path: ellipse(100% 56% at 40% 17%);' : 
   'position: absolute;top: -20px;width: 100%;clip-path: ellipse(100% 56% at 40% 17%);'"
    :height="250" 
        :src="CurrentEntity.TableParallax ? CurrentEntity.TableParallax : require('@/assets/RASolidA.jpeg')"
    >
   </v-parallax>  
    <!-- <v-toolbar :class="$vuetify.theme.dark? 'my-6 elevation-1 dbcard dbtable darken-2' : 'my-6 elevation-1 dbtable dbcard'" flat v-if=" $route.name !== 'MyAccount' && StatusField && !QuickDashView && quickeditstep === 1 && !RecordChatView">
          <v-layout col class="justify-space-between"  v-for="item in StatusField.Options" :key="item.itemObjKey">
            {{item.Name}}
            <v-divider s vertical></v-divider>
            {{GetDataCountbyStatus(item)}}           
          </v-layout>
        </v-toolbar> -->
       <div :style="MobileView ? 'margin-top: 100px;' : ''">
        <v-btn v-if="!QuickDashView && ActiveQuickDashElements && quickeditstep === 1 && !RecordChatView" @click="ViewQuickDash()" color="blue" dark>View QuickDash</v-btn>
        <v-btn v-if="!QuickDashView && QuickDashFilterItem && quickeditstep === 1 && !RecordChatView" @click="QuickDashFilterItem = ''" color="warning" dark>Clear Dash Filter</v-btn>
        <v-btn v-if="!QuickDashView && AdvancedFind && quickeditstep === 1 && !RecordChatView" @click="ClearAdvancedFilter()" color="blue" dark>Clear Advanced Filter</v-btn>
        <v-btn width="100%" outlined small v-if="userIsAdmin && !ActiveQuickDashElements && quickeditstep === 1 && !RecordChatView && !TableOnly" @click="CheckforQuickDashPlugin()">
            <v-icon small>mdi-plus</v-icon> Q-Dash
          </v-btn>
          <v-btn @click="QuickDashView = !QuickDashView" v-if="QuickDashView">Hide Quickdash 
          </v-btn><v-btn @click="QuickDashPreview = !QuickDashPreview" v-if="QuickDashView && userIsAdmin">{{QuickDashPreview ? 'Admin View' : 'User View'}} 
          </v-btn>
         <QuickDashboard :System="System" :SystemEntities="SystemEntities" :DashboardEntity="CurrentEntity" :MasterData="DataFiltered" @SetQuickDashbtoTemplate="SetQuickDashbtoTemplate"
          @QuickDashboardInteract="QuickDashboardInteract" v-if="QuickDashView && TableReady" @ToggleQuickDashView="ToggleQuickDashView" @SaveQuickDashboard="SaveQuickDashboard"
          :OptionSetsFilterFields="OptionSetsFilterFields" :QuickDashPreview="QuickDashPreview"
          />
       </div>
     
    <v-row class="justify-center" style="margin-top: 20px;padding:20px;"  
    v-if=" $route.name !== 'MyAccount' && StatusField && !QuickDashView && quickeditstep === 1 && !RecordChatView && !TableOnly && TableToolbar !== 'Compact'">
   
       <v-col :cols="DefaultServiceCols">
        <v-card height="150" style="border-bottom: 8px solid purple;">
        <v-list height="100%">
          <v-list-item @click="ActivateSelectedMain(menuitem)" dense v-for="menuitem in ActiveSuiteMenuItems" :key="menuitem.itemObjKey">
            <v-list-item-avatar size="25">
              <v-icon size="20">{{menuitem.icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content style="font-size: 0.7em;">
            {{menuitem.title}}
            </v-list-item-content>
            <v-list-item-actions>
              <v-icon small>mdi-chevron-right</v-icon>
            </v-list-item-actions>
          </v-list-item>
        </v-list>
        </v-card>
       </v-col>
       <v-col v-if="StatusField" :cols="DefaultServiceCols"  v-for="item in StatusField.Options" :key="item.itemObjKey">
         <v-card height="150" :style="item.Color ? 'border-bottom: 8px solid #'+item.Color.hexa+';' : 'border-bottom: 8px solid lime;'">
                   <v-card-title class="largeoverline justify-start font-weight-light">
                     {{GetDataCountbyStatus(item)}}
                   </v-card-title>
                  <v-card-title class="overline justify-start">
                     {{item.Name}}
                   </v-card-title>
                </v-card>
       </v-col>
       <v-col :cols="DefaultServiceCols">
        <v-card height="150" style="border-bottom: 8px solid gold;">
              <v-card-title class="largeoverline justify-start font-weight-light">
                {{TableData.length}}
              </v-card-title>
            <v-card-title class="overline justify-start">
               Total {{CurrentEntity.DisplayName}}
              </v-card-title>
          </v-card>
       </v-col>
    </v-row>
    
   
    <v-card-title :class="MobileView ? 'mediumoverline font-weight-thin' : 'largeoverline font-weight-thin'" 
    v-if="TableToolbar === 'Compact' && quickeditstep === 1" 
    :style="MobileView ? 'margin-top: 20px;z-index:2;position: relative;' : 
    'margin-top: 20px;z-index:2;position: relative;'">
    <v-icon x-large>{{TableIcon}}</v-icon>
     {{TableName}}
     <v-spacer>
     </v-spacer>
    <v-progress-circular
      :rotate="360"
      :size="120"
      :width="5"
      :value="ViewingValue"
    >
   <span :class="MobileView ? 'mediumoverline' : ''" 
   :style="MobileView ? 'position: absolute; top: 40%;' : 'position: absolute; top: 25%;'">{{ViewingText}}</span><br>
     <span :style="MobileView ? 'position: relative;top: 25px;': 'position: relative; top: 20px;'" class="caption">In View</span>
    </v-progress-circular>
    </v-card-title>
    <v-card-subtitle v-if="TableToolbar === 'Compact' && quickeditstep === 1">
     
           <v-select v-for="textfield in OptionSetsFilterFields" :key="textfield.itemObjKey"
           :style="MobileView ? 'border-radius: 50px;width: 50%;' : 'border-radius: 50px;width: 30%;'"
            :items="textfield.Options" 
            v-model="textfield.ActiveFilter"          
            item-text="Name"
            clearable
            :label="textfield.DisplayName"
            return-object
            chips
            flat dense solo
            />
    </v-card-subtitle>
    <v-card-subtitle v-if="TableToolbar === 'Compact' && quickeditstep === 1">
       
           <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn absolute outlined fab small class="elevation-6" >
              <v-icon  v-on="on" v-if="CanCreate || userIsAdmin || HasCharts || HasProcesses" >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list >
              <v-list-item :disabled="PreventAdd" class="listoutline"  @click="ActivateRelevantdialog()"  v-if="ComputedCanCreate || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    New
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  :disabled="PreventAdd" class="listoutline" @click="importdialog = !importdialog" v-if="ImportActive">
                  <v-list-item-title class="subtleoverline">
                    Import
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="links">mdi-database-import</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="ActivateExportDialog(TableData,Headers)"  v-if="ExportActive">
                  <v-list-item-title class="subtleoverline">
                    Export
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="blue leightn-2">mdi-database-export</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="ChartsActive" class="listoutline" @click="ActivateChartView()">
                  <v-list-item-title class="subtleoverline">
                    Charts
                  </v-list-item-title>
                <v-list-item-action>                  
                    <v-icon class="actionicon" color="success">mdi-chart-bar</v-icon>
                  </v-list-item-action>
                </v-list-item>
                 <v-list-item class="listoutline" v-if="TableEditActive" @click="EditingTable = !EditingTable">
                  <v-list-item-title class="subtleoverline">
                    {{EditingTable? 'Cancel Table Edit' : 'Edit Table'}}
                  </v-list-item-title>
                <v-list-item-action>                  
                    <v-icon class="actionicon">mdi-cogs</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
          <v-text-field 
       clearable 
            v-model="search"
            solo style="margin-left: 50px;border-radius: 50px;"
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field>
          <!-- <v-icon @click="ResetSearch()" v-if="search === ''" >mdi-file-search</v-icon>
          <v-icon @click="ResetSearch()" v-if="search !== ''" >mdi-close</v-icon> -->
    </v-card-subtitle>
    <v-toolbar dark  style="border-radius: 30px 15px 0px 0px;" elevation="0" class="mx-3"
        :style="{ zIndex:2 }" v-if="quickeditstep === 1 && !RecordChatView && TableToolbar === 'Compact'"
         
        >
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large>{{TableIcon}}</v-icon>{{TableName}}</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon @click="ToggleIsCalendarView()" v-if="HasCalendarView && IsCalendarView" class="soloactionicon mx-1">mdi-view-sequential</v-icon>
          <v-icon @click="ToggleIsCalendarView()" v-if="HasCalendarView && !IsCalendarView" class="soloactionicon mx-1">mdi-calendar</v-icon>
          <v-icon @click="ToggleIsLiaisonView()" class="soloactionicon mx-1">mdi-chat-processing</v-icon>
          <v-icon @click="ToggleBoardView()" v-if="BoardOptions && BoardOptions.length > 0" class="soloactionicon mx-1">mdi-view-list</v-icon>
    </v-toolbar>
    <div :style="MobileView && quickeditstep === 2 ? '' : 'padding: 20px;'" v-if="!QuickDashView && !RecordChatView || ActiveWarehouse && !QuickDashView && !RecordChatView">
    <v-dialog v-model="StateDialog" max-width="400">
      <v-card v-if="editedItem && editedItem.id && StatusField">
        <v-card-title>
          {{editedItem[CurrentEntity.Primary_Field_Name]}} Status
        </v-card-title>
        <v-card-text>
          <v-select :items="StatusField.Options" return-object item-text="Name" v-model="editedItem.Status" label="Status"/>
          <v-select v-if="editedItem.Status" :items="editedItem.Status.Options" return-object item-text="Name" v-model="editedItem.Status_Reason" label="Status"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="DeactivateStateDialog()" dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateItemStatus()" dark color="success">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ImportingProgressDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Importing Records</h2><br>
                Now Importing - 
                          <div class="red--text">
                          <p>{{Importcount}}</p>
                          </div>
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
                  <v-layout row class="justify-center mx-3 my-3">
                      <progress id="importprogressbar">
                      </progress>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
    <!-- style="max-height:350px;" -->
    <v-navigation-drawer  v-if="TableEditActive && EditingTable" src="@/assets/QuoteBG2.jpeg" v-model="EditingTable" app right width="400">
    <v-card>
                  <v-card-title>
                      Manage Headers<v-spacer></v-spacer><v-btn @click="EditingTable = !EditingTable" icon><v-icon>mdi-close</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs v-model="HeadersTab">
                      <v-tab>Existing Fields</v-tab>
                      <v-tab-item >
                        <v-list dense>
                          <v-list-item>
                            <v-text-field v-model="FieldSearch" label="Search"/>
                          </v-list-item>
                          <v-list-item class="background">
                          <v-virtual-scroll 
                            style="overflow-x: hidden;padding:4px;"                                  
                            :items="PotentialHeaderFields"
                            :item-height="35"
                            :height="140"
                                >
                                <template v-slot:default="{ item }">
                                  <v-list-item >
                                    <v-list-item-action>
                                      <v-checkbox class="tinylabel" @change="UpdateHeaders(item)" dense :disabled="item.Primary" v-model="item.IsHeader"/>
                                    </v-list-item-action>
                                    <v-list-item-content style="font-size:14px;">
                                      {{item.DisplayName}}
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                          </v-virtual-scroll>
                          </v-list-item>
                        </v-list>
                      </v-tab-item>
                      <v-tab>New Field</v-tab>
                      <v-tab-item >
                        <v-card>
                        <v-card-title>
                            Add Field
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item>
                                    <v-text-field v-model="NewField.Name" label="Name"/>
                                </v-list-item>
                                <v-list-item>
                                    <v-select :items="HeaderTypes" label="Type" v-model="NewField.Type" />
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-card-text v-if="NewField.Type">
                          <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                            :CurrentEntity="CurrentEntity"
                            :SystemEntities="SystemEntities" :field="NewField" :NewField="true" :System="System"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn>Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="AddNewField()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions v-if="HeadersTab === 0">
                    <v-btn @click="RefreshComponent()" dark color="warning">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="success" @click="SaveHeaderUpdates()">Save</v-btn>
                  </v-card-actions>
                </v-card>
    </v-navigation-drawer>
    <v-dialog v-model="importresultsdialog" max-width="800px">
        <v-card dark height="100%" width ="100%" class="center secondary">
          <v-img src="@/assets/RABaseBG.jpeg">
          <v-card-title>
            Import Result<v-spacer></v-spacer><v-icon @click="CloseImportresultsdialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Successful Imports ({{SuccessfulImportsArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in SuccessfulImportsArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Partial Failures ({{PartialFailuresArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in PartialFailuresArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer><v-btn href="/DataImports" target="_blank" color="green" dark>View Import Record</v-btn>
          
          </v-card-actions>
          </v-img>
        </v-card>
      </v-dialog>
    <v-dialog v-model="importdialog" max-width="500px">
               <v-card>
                 <v-img
                    width="500"
                    src="@/assets/RABaseBG-Soft.png"
                    ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                      <v-card-title class="mediumoverline white--text">
                        Import {{TableName}}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">{{TableIcon}}</v-icon>
                      </v-card-title>
                      <v-card-subtitle class="overline white--text">
                      <v-icon  class="actionicon" small color="green">mdi-database-import</v-icon> Import {{TableName}} here
                    </v-card-subtitle>
                    <v-card-subtitle class="overline white--text">
                     <v-icon  class="actionicon" @click="DownloadImportTemplate()" small color="white">mdi-download</v-icon> Download {{TableName}} Template
                    </v-card-subtitle></div>
                 <v-card-text>
                     <v-row>
                       <v-col cols="12" sm="12" md="12" lg="12">
                         <input v-if="!ImportFile"
                                   type="file"
                                   @change="onImportfileSelected($event)"
                                   ref="ImportFileinputter"
                                   id="fileUpload"
                                   >
                         <v-text-field class="FormFields" v-model="Import.Name" label="Import File Name"></v-text-field>
                         
                         
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions class="white documenttypeshadowinvert">                  
                  <v-btn color="orange" dark class="overline" @click="closeImport()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn color="green" dark  class="overline" @click="saveImport()">Save</v-btn>
                </v-card-actions>
                 </v-img>
               </v-card>
             </v-dialog>
              <LookupSubColDialogComponent v-if="SubColLookupDialogActive" @DeactivateSubColLookupDialog="DeactivateSubColLookupDialog" :MasterTableObj="MasterTableObj" :tab="tab"
      :CurrentEntity="CurrentEntity" :TableCollectionRef="TableCollectionRef" :RelatedObj="DynamicWikiData" :RelatedObjidentifier="CurrentEntity.Primary_Field_Name" :System="System" :SystemEntities="SystemEntities" :RADB="RADB"
      />
      
        <v-dialog v-model="recordformdialog" max-width="500px" persistent>
         
          <v-card >
            <!-- tab.EntitySource === '{{tab.EntitySource}}'  -->
            <v-card dark tile>
            <v-card-title class="mediumoverline white--text">
                {{ formTitle }}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">{{TableIcon}}</v-icon>
              </v-card-title>
              <v-card-subtitle class="overline white--text">
              <v-icon  class="actionicon" small :color="formSubTitleIconColor">{{formSubTitleIcon}}</v-icon> {{formSubTitle}}
            </v-card-subtitle>
            
            </v-card>
                <v-card-text>
                  
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                       <FieldsForm :FormFields="FilteredHeaders" :Validating="Validating" @ActivateAddNewLookupDialog="ActivateAddNewLookupDialog"
                       :DataTableView="true" @save="save" :DataObject="editedItem" :tab="{}" />
                      </v-col>
                    </v-row>       
                </v-card-text>
                <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="close()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
              </v-card>
            </v-dialog>
        <v-dialog v-model="exportdialog" max-width="500">
      <v-card>
        <v-img
          width="500"
          src="@/assets/RABaseBG-Soft.png"
          ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
            <v-card-title class="mediumoverline white--text">
              Export {{TableName}}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">{{TableIcon}}</v-icon>
            </v-card-title>
            <v-card-subtitle class="overline white--text">
            <v-icon  class="actionicon" small color="green">mdi-database-export</v-icon> Export {{TableName}} here
          </v-card-subtitle>
          </div>
        <v-card-text class="FormFields">
          This will export all data in your curent view, in .CSV Format. Choose below whether to Export just for viewing, or to Export for importing of Re-Importing the sheet
          to perform Bulk updates to the {{TableName}} Records:
          <v-list class="transparent">
            <v-list-item>
              <v-btn @click="ExportData()" class="overline blue--text" text>Export for View</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="ExportDataforReimport()" class="overline blue--text" text>Export for Reimport</v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions  class="white documenttypeshadowinvert">
          <v-btn color="orange" dark class="overline" @click="closeExport()">Cancel</v-btn>
          </v-card-actions>           
        </v-img>
      </v-card>
    </v-dialog>
    
      <v-layout row class="justify-center" v-if="$route.name !== 'MyAccount' && !RecordChatView && TableToolbar !== 'Compact' ||
      $route.name === 'MyAccount' && $route.query && $route.query.TableType && TableToolbar !== 'Compact'" :style="tab.EntitySource === 'SubCollection' ? '' : 'margin-bottom: -40px;'">
          <LookupComponentSubCollections @LinkSubCollectionArray="LinkSubCollectionArray"
         :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" v-if="SCViewExistingLookupDialog"
         @CancelSelection="CancelSCAddParentDialog" @CancelAddParentDialog="CancelSCAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders"
         :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional"
         :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="SCViewExistingLookupDialog"
         :SubCollectionLookupArrays="SubCollectionLookupArrays" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
         
      <v-card class="transparent" flat width="100%">
      <!-- <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12"style="margin-bottom:-40px;" color="primary white--text"> -->
      
        
     <!-- :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')',backgroundSize: 'cover',zIndex:2 }" to buildergrad -->
         
        <v-toolbar dark  style="border-radius: 30px 15px 0px 0px;" elevation="0" class="mx-3"
        :style="{ zIndex:2 }" v-if="quickeditstep === 1 && !RecordChatView"
         
        >
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large>{{TableIcon}}</v-icon>{{TableName}}</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon @click="ToggleIsCalendarView()" v-if="HasCalendarView && IsCalendarView" class="soloactionicon mx-1">mdi-view-sequential</v-icon>
          <v-icon @click="ToggleIsCalendarView()" v-if="HasCalendarView && !IsCalendarView" class="soloactionicon mx-1">mdi-calendar</v-icon>
          <v-icon @click="ToggleIsLiaisonView()" class="soloactionicon mx-1">mdi-chat-processing</v-icon>
          <v-icon @click="ToggleBoardView()" v-if="BoardOptions && BoardOptions.length > 0" class="soloactionicon mx-1">mdi-view-list</v-icon>
          <v-spacer></v-spacer>
          <v-text-field 
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" >mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" >mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" >
              <v-icon  v-on="on" v-if="CanCreate || userIsAdmin || HasCharts || HasProcesses" >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list >
              <v-list-item :disabled="PreventAdd" class="listoutline"  @click="ActivateRelevantdialog()"  v-if="ComputedCanCreate || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    New
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  :disabled="PreventAdd" class="listoutline" @click="importdialog = !importdialog" v-if="ImportActive">
                  <v-list-item-title class="subtleoverline">
                    Import
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="links">mdi-database-import</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="ActivateExportDialog(TableData,Headers)"  v-if="ExportActive">
                  <v-list-item-title class="subtleoverline">
                    Export
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="blue leightn-2">mdi-database-export</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="ChartsActive" class="listoutline" @click="ActivateChartView()">
                  <v-list-item-title class="subtleoverline">
                    Charts
                  </v-list-item-title>
                <v-list-item-action>                  
                    <v-icon class="actionicon" color="success">mdi-chart-bar</v-icon>
                  </v-list-item-action>
                </v-list-item>
                 <v-list-item class="listoutline" v-if="TableEditActive" @click="EditingTable = !EditingTable">
                  <v-list-item-title class="subtleoverline">
                    {{EditingTable? 'Cancel Table Edit' : 'Edit Table'}}
                  </v-list-item-title>
                <v-list-item-action>                  
                    <v-icon class="actionicon">mdi-cogs</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-toolbar dark style="z-index:1;border-radius: 0px 0px 30px 0px;" v-if=" $route.name !== 'MyAccount' && quickeditstep === 1 && !IsBoardView" elevation="0" dense class="mx-3"
          >
         <v-list-item dense style="padding: 0px !important; align-items: normal !important;" class="justify-start">
           <h3 class="my-8 overline">Data Filters</h3><v-spacer></v-spacer><span v-for="textfield in OptionSetsFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
         <!--  v-model="{{textfield.Name.split(' ').join('')}}filter"<span v-if="textfield.HasDeepHierarchy && textfield.Levels && textfield.Levels[1]">
            @change="{{textfield.Levels[1].FieldName}}filter = ''"</span> -->
           <v-autocomplete class="mx-5"
            :items="textfield.Options" 
            v-model="textfield.ActiveFilter"          
            item-text="Name"
            clearable
            :label="textfield.DisplayName"
            autowidth
            return-object
            chips
            flat dense
            />
           </div>
           <!-- <span v-if="textfield.HasDeepHierarchy && textfield.Levels && textfield.Levels[1]">
         <div width="100px" v-if="textfield.ActiveFilter">
           <v-autocomplete class="mx-5"
            :items="textfield.ActiveFilter.Options"
            v-model="textfield.ActiveFilterChild"
            item-text="Name"
            :label="textfield.Levels[1].FieldName"
            autowidth
            return-object
            chips
            flat
            />
           </div></span> -->
           </span><span v-for="textfield in ParentOptionSetsFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
         <!--  v-model="{{textfield.Name.split(' ').join('')}}filter"<span v-if="textfield.HasDeepHierarchy && textfield.Levels && textfield.Levels[1]">
            @change="{{textfield.Levels[1].FieldName}}filter = ''"</span> -->
           <v-autocomplete class="mx-5"
            :items="textfield.Options" 
            v-model="textfield.ActiveFilter"          
            item-text="Name"
            clearable
            :label="textfield.DisplayName"
            autowidth
            return-object
            chips
            flat dense
            />
           </div>
           <!-- <span v-if="textfield.HasDeepHierarchy && textfield.Levels && textfield.Levels[1]">
         <div width="100px" v-if="textfield.ActiveFilter">
           <v-autocomplete class="mx-5"
            :items="textfield.ActiveFilter.Options"
            v-model="textfield.ActiveFilterChild"
            item-text="Name"
            :label="textfield.Levels[1].FieldName"
            autowidth
            return-object
            chips
            flat
            />
           </div></span> -->
           </span>
           <span v-for="textfield in LookupFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
           <v-autocomplete class="mx-5"
            :items="textfield.Array"
            v-model="textfield.ActiveFilter" 
            :item-text="textfield.LookupFieldName"
            clearable
            :label="'by '+textfield.RelatedRecord"
            autowidth
            return-object
            chips
            flat
            />
           </div></span>
           <v-btn outlined fab small class="elevation-6"  style="margin-top:25px;" >
             <v-icon @click="ResetFilters()">mdi-close</v-icon>
           </v-btn>
         </v-list-item>
         </v-toolbar>
      </v-card>
    </v-layout>
    <div v-if="QuickEditEntity || quickeditstep === 2">
       <!-- <v-list dark :class="MiniView || !ActiveSuiteApp ? 'recordcabinetmini transparent' : 'recordcabinet transparent'" :style="MiniView ? 'top: 20vh;' : 'top: 30vh;'" v-if="quickeditstep === 2">
                      <v-list-item @click="HideRecordQuickNav = !HideRecordQuickNav"
                        :class="MiniView? 'recordcabinetdrawermini pink' : 'recordcabinetdrawer pink'">
                        <v-list-item-content>
                        {{HideRecordQuickNav ? 'Show' : 'Hide'}}
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon>{{HideRecordQuickNav ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list style="padding: 0px;" class="transparent" v-if="!HideRecordQuickNav">
                       <v-list-item v-for="item in ComputedRecordQuickNavItems" :key="item.itemObjKey" @click="ToggleQuickMode(item.Prop,item)"
                        :class="item.Class">
                        <v-list-item-content>
                        {{item.Name}}
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon>{{item.Icon}}</v-icon> <v-badge color="error" v-if="item.notifications" >
                        <span slot="badge"> {{item.notifications}}</span>
                        </v-badge>
                        </v-list-item-action>
                      </v-list-item>
                      </v-list>
                    </v-list>   
                    <v-list :class="MiniView || !ActiveSuiteApp? 'recordviewtabsmini transparent' : 'recordviewtabs transparent'"
                    dark :style="MobileView ? 'top: 20vh;' : 'top: 25vh;'" v-if="QuickMode === 'RecordView' && quickeditstep === 2">
           <v-list-item :to="'/StockMovement/'+this.CurrentEntity.id.split('_').join('')+'/'+editedItem.Warehouseid+'/'+editedItem.id" v-if="StoreProductEntity" :class="MiniView ? 'pink recordviewtabssectionmini my-1' : 'pink recordviewtabssection my-1'">          
            <v-list-item-action>
              <v-icon>mdi-timeline-clock-outline</v-icon>
            </v-list-item-action>          
            <v-list-item-content>
           Movement
            </v-list-item-content>
        </v-list-item>   
        <v-list-item v-for="secttype in ActiveSectionTypes" :key="secttype.itemObjKey" @click="ActivateActiveSectionType(secttype)" :class="secttype.Class">          
            <v-list-item-action>
              <v-icon>{{secttype.Icon}}</v-icon>
            </v-list-item-action>          
            <v-list-item-content>
            {{secttype.Name}}
            </v-list-item-content>
        </v-list-item>
        <!-- Here we precompute all sections that are subcol, each individually -->
         <v-list-item v-for="item in ComputedSubCollectionSections" :key="item.itemObjKey" @click="ActivateActiveTab(item)"
            :class="item.Class">
            <v-list-item-action>
              <v-icon>{{item.Icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
            {{item.Name}}
            </v-list-item-content>
          </v-list-item>
    <!-- </v-list> -->
    
            <v-window v-model="quickeditstep">
            <v-window-item :value="1">
             
              <StoreArticlesManager style="margin-top:40px;" :System="System" :SystemEntities="SystemEntities" :CurrentEntity="CurrentEntity"
              :Articles="TableData" v-if="StoreArticleEntity" :Directories="Directories"
              />
              <v-row style="height: 100%;" class="mx-0" v-if="HasNavlist">
                <div :class="FlexClasses" v-for="item in ComputedDataTableListingsPaginated.slice(0, 9)"
                  :key="item.itemObjKey" :style="MiniView ? 'padding-top:30px;' : 'padding:10px;'">
                      <ClassifiedListingListItem v-if="HasNavlist.CategoricalListType !== 'Wiki Listing'" :TaggedFields="HasNavlist.TaggedFields" :TagField="HasNavlist.TagField" :CurrentEntity="HasNavlist"
                      :HidePrice="HasNavlist.HidePrice" :HideTitle="HasNavlist.HideTitle" :HideDescription="HasNavlist.HideDescription" @PushActiveProduct="PushActiveProduct"
                      :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare" @editRecomputedItem="editRecomputedItem"
                      :FeatureList="FeatureList" :CompareItems="CompareItems" :IMGIsCarousel="HasNavlist.IMGIsCarousel" :CartItems="[]"
                      :FlexXLRG="FlexXLRG" :FlexLarge="FlexLarge" :FlexMedium="FlexMedium" :FlexSmall="FlexSmall" :FlexXSmall="FlexXSmall"	
                      />
                      <WikiListingItem v-if="HasNavlist.CategoricalListType === 'Wiki Listing'" :TaggedFields="HasNavlist.TaggedFields" :TagField="HasNavlist.TagField" :CurrentEntity="HasNavlist"
                      :HidePrice="HasNavlist.HidePrice" :HideTitle="HasNavlist.HideTitle" :HideDescription="HasNavlist.HideDescription"
                      :HideIMG="HasNavlist.HideIMG"
                      :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                      :FeatureList="FeatureList" :CompareItems="CompareItems"
                      :IMGField="HasNavlist.IMGField" :DescriptionField="HasNavlist.DescriptionField" :TitleField="HasNavlist.TitleField"
                      />
                  </div>
                </v-row>              
            </v-window-item>
          <v-window-item :value="2">
            <div  v-if="StrictlyQuickEdit" style="margin-top:50px;">
            <v-btn @click="save(true)" width="100%" outlined color="success">Save
              </v-btn>
              <v-btn @click="close(true)" width="100%" outlined color="warning">Cancel
              </v-btn>
             <v-card class="siteconfigcard">
              
            <v-layout row class="justify-center">
              <div class="flex xl11 lg11 md11 sm11 xs12">
              <QuickEditComponent :TableQuick="true" @UpdateNewRecordQuickEdit="UpdateNewRecordQuickEdit"
              :System="System" :SystemEntities="SystemEntities" :Record="editedItem" :QuickEditSections="QuickEditSections"
                    @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage" :SystemPhotoLibraries="SystemPhotoLibraries"
                    @AddGallerySubcolImages="AddGallerySubcolImages" :SystemBuilderTable="SystemBuilderTable" :CurrentEntity="CurrentEntity"
              />
              </div>
            </v-layout>
             </v-card>
            </div>
            <v-card class="transparent" v-if="editedItem && quickeditstep === 2 && !StrictlyQuickEdit"
            :style="MobileView ? 'margin-top:40px;padding-top: 20px;padding-bottom:10px;' : 'padding-top: 0px;padding-bottom:10px;'"> 
              <!-- The trick is to reinstate the old QuickEditSections
              I guess 1)How do we know 2) how does it work again?
              <QuickEditSections
              /> -->
             
              <TableRecordComponent
              :Record="editedItem" @ReduceQuickStep="ReduceQuickStep" @ViewItem="ViewItem" @save="save"
                :System="System" :SystemEntities="SystemEntities" :CurrentEntity="CurrentEntity" :RADB="RADB" :SystemPhotoLibraries="SystemPhotoLibraries"
                @ToggleQuickMode="ToggleQuickMode" :QuickMode="QuickMode" :SystemGuides="SystemGuides" :RAApp="RAApp"
                @ActivateProcessing="ActivateProcessing" @ActivateSnackbar="ActivateSnackbar" :UnreadNotifications="UnreadNotifications"
                :ActiveSectionType="ActiveSectionType" :ActiveTab="ActiveTab" :ActiveTabIndex="ActiveTabIndex"
                :CanEdit="editedItem.CanEdit" :CanDelete="editedItem.CanDelete" :MyActivitiesArray="MyActivitiesArray"
                :EntityCollectionRef="EntityCollectionRef" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                :ActiveSectionIndex="ActiveSectionIndex" :ActiveSection="ActiveSection" @ActivateActiveTab="ActivateActiveTab"
                />             
            
            </v-card>
          </v-window-item>
           <v-window-item :value="3">
             <v-card v-if="editedItem" style="margin-top:40px;padding-top: 20px;">  
             <v-card-title class="text-h6 font-weight-regular justify-space-between">
               <v-btn dark class="links" @click="ReduceQuickStep()">Back</v-btn>
                {{SubColRecord[SubColPrimaryField.Name]}}
               <v-btn dark class="success" @click="save(true)">Save</v-btn>
               </v-card-title>
               <v-card-text v-if="ActiveSubCollection.id  === 'BookedUsers'">
                <BookedUser :System="System" :SystemEntities="SystemEntities" :BookedUser="SubColRecord" :BookingHeaders="ActiveSubcolHeaders"
                @UpdateBookedUser="UpdateBookedUser"
                />
               </v-card-text>
             </v-card>
           </v-window-item>
            </v-window>
    </div>
      <CalendarViewComp style="margin-top:0px;" v-if="IsCalendarView && !ScheduleBookingEntity && quickeditstep === 1 && !IsLiaisonView"
      :CurrentEntity="CurrentEntity" :Data="TableData" :CanCreate="CanCreate" :Editing="EditingCalendar" @OpenEventEditor="OpenEventEditor"
      :CalendarEntryCompleted="CalendarEntryCompleted"
      />
      <ScheduledBookingViewComp style="margin-top:30px;" v-if="IsCalendarView && ScheduleBookingEntity && quickeditstep === 1" :CanCreate="CanCreate"
      :CurrentEntity="CurrentEntity" :Data="TableData" @ActivateProcessing="ActivateProcessing" :Editing="EditingCalendar" @OpenEventEditor="OpenEventEditor"
      :CalendarEntryCompleted="CalendarEntryCompleted"
      />
      <v-card flat style="padding-top:40px;" v-if="IsBoardView && quickeditstep === 1 && !IsLiaisonView">
         <v-card-title class="background mediumoverline justify-center">
        Active Board
      </v-card-title>
      <v-card-title class="background mediumoverline justify-center">
        <v-select v-model="ActiveBoard" label="Board" :items="BoardOptions" return-object
        />
      </v-card-title>
      <v-card-text>
      <BoardComponent style="margin-top:0px;padding: 20px;" :Label="ActiveBoard" :HasOwnerField="HeadershaveOwner" @CreateLiaisonNotificationtoOwner="CreateLiaisonNotificationtoOwner"
      :CurrentEntity="CurrentEntity" :Data="DataFiltered" :CanCreate="CanCreate" :System="System" :SystemEntities="SystemEntities"
      :OptionSetsFilterFields="OptionSetsFilterFields" :search="search" @ResetSearch="ResetSearch" @UpdateSearch="UpdateSearch"
      />
      </v-card-text>
      </v-card>
      <!--   hide-default-footer -->
       <!-- <v-data-table
      v-if="!Refreshing && STDTableView"
      :dark="AppisDarkMode"
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="Headers"
      :items="Data"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
  > -->
  <div v-if="IsLiaisonView" style="margin-top:40px;">
   
    <v-list v-if="!RecordChatView">
      <v-list :class="ConversationClass(record)"
      dense v-for="record in CommsDataFiltered" :key="record.itemObjKey">
        <v-list-item dense @click="ActivateRecordLiaisonmode(record)">
      
            <v-list-item-avatar color="blue" size="50">
              <v-icon dark>{{CurrentEntity.Table_Icon}}</v-icon>
          <!-- <v-avatar size="35"> -->
        <!-- <v-img :src="userLoggedIn.Profile_Photo" v-if="userLoggedIn.Profile_Photo"/>
            <v-img src="@/assets/BlankProfilePic.png" v-if="!userLoggedIn.Profile_Photo"/> -->
            </v-list-item-avatar>
            <v-list-item-content>
             
              <v-list-item-title v-text="record[CurrentEntity.Primary_Field_Name]"></v-list-item-title>
              <v-list-item-subtitle v-if="record.lastMessage" v-text="msg.lastMessage.message"></v-list-item-subtitle>
              <v-chip-group column>
                  <v-chip x-small v-for="textfield in ComputedOptionSetsFilterFields" :key="textfield.itemObjKey">
                    <span v-if="record[textfield.id]">
                    {{record[textfield.id].Name}}
                    </span>
                    <!--   @click="CheckforFilter(item,textfield)" -->
                  </v-chip> 
                </v-chip-group>
              <!-- <v-list-item-subtitle v-if="!record.lastMessage" v-text="`asdasd`"></v-list-item-subtitle> -->
             
            </v-list-item-content>
             <!--   <v-spacer>
                </v-spacer>
               <span style="font-size:8px">{{record.lastMessage.SendTime}}</span>
                 <v-list-item-action>
                <v-badge color="error" v-if="record.notifications" >
            <span slot="badge"> {{record.notifications}}</span>
            </v-badge>
                 </v-list-item-action> -->
                 <v-badge overlap color="error" v-if="record.notifications" >
                <span slot="badge"> {{record.notifications}}</span>
            </v-badge>
        </v-list-item>
        <v-list-item style="height: 2px;" dense>
          <v-divider>
          </v-divider>
        </v-list-item>
      </v-list>
      </v-list>
  </div>
  <!--  -->
  
  <div v-if="ActiveTableView === 'Cards' && quickeditstep === 1" style="margin-top: 40px;">
    <v-row :class="$vuetify.breakpoint.xs ? 'justify-center ' : 'justify-start'" style="padding:20px;" >
          
            <v-col style="flex-grow: 0!important;" v-for="record in CommsDataFiltered" :key="record.itemObjKey">
                <v-card elevation="4" 
                :height="ServiceBlockSize" :width="ServiceBlockSize" rounded style="border-radius: 10px!important;"                  
                  >
                  <v-card-title class="justify-center" style="padding-bottom: 0px;">
                   <DynamicItemBubble :Icon="TableIcon" :btnid="record.id+'bubbletoggle'"
                   :Items="ActionItems" :Record="record" @EmitMethod="RecordBubbleMethod"
                   />
                      
                  </v-card-title>
                  <v-card-title class="justify-center" style="font-family: Montserrat;padding: 10px;text-align: center;font-size: 0.75em;">
                      {{record[CurrentEntity.Primary_Field_Name]}}
                  </v-card-title>
                  </v-card>
              </v-col>
    </v-row>
  </div>
  <div v-if="ActiveTableView === 'List'" style="margin-top: 40px;">
    <v-expansion-panels v-if="MiniView">
    <v-expansion-panel
      v-for="record in CommsDataFiltered" :key="record.itemObjKey"
    >
      <v-expansion-panel-header>
        <v-list-item dense>
        <v-list-item-avatar :tile="!MiniView" color="blue" :size="MiniView ? 50 : 120">
              <v-icon  :size="MiniView ? 35 : 80" dark>{{CurrentEntity.Table_Icon}}</v-icon>
        </v-list-item-avatar>
            <v-list-item-content>
             
              <v-list-item-title v-text="record[CurrentEntity.Primary_Field_Name]"></v-list-item-title>
              <v-list-item-subtitle v-if="record.Business_Unit">
                <v-avatar :size="MiniView ? 20 : 35">
                 <v-icon>mdi-office-building</v-icon>
                  </v-avatar>
                  <span v-if="record.Business_Unit">{{' '+record.Business_Unit.Name}}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="record.Owner">
                <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Owner.Profile_Photo" v-if="record.Owner && record.Owner.Profile_Photo"/>
                  <v-img src="@/assets/BlankProfilePic.png" v-if="!record.Owner || !record.Owner.Profile_Photo"/>
                  </v-avatar>
                  <span v-if="record.Owner">{{' '+record.Owner.Full_Name}}</span>
              </v-list-item-subtitle>
              <v-chip-group column>
                  <v-chip x-small v-for="textfield in OptionSetsFilterFields.concat(OptionSetsFilterFields,OptionSetsFilterFields)" :key="textfield.itemObjKey">
                    <span v-if="record[textfield.id]">
                    {{record[textfield.id].Name}}
                    </span>
                    <!--   @click="CheckforFilter(item,textfield)" -->
                  </v-chip> 
                </v-chip-group>
              <!-- <v-list-item-subtitle v-if="!record.lastMessage" v-text="`asdasd`"></v-list-item-subtitle> -->
             
            </v-list-item-content>
             <!--   <v-spacer>
                </v-spacer>
               <span style="font-size:8px">{{record.lastMessage.SendTime}}</span>
                 <v-list-item-action>
                <v-badge color="error" v-if="record.notifications" >
            <span slot="badge"> {{record.notifications}}</span>
            </v-badge>
                 </v-list-item-action> -->
                 <!-- <v-badge overlap color="error" v-if="record.notifications" >
                <span slot="badge"> {{record.notifications}}</span>
            </v-badge>
        <v-list-item style="height: 2px;" dense>
          <v-divider>
          </v-divider>
        </v-list-item> -->
        <!-- <v-row no-gutters>
          <v-col cols="2">
             <v-avatar :style="MiniView ? '' : 'width: 0px!important;'" :tile="!MiniView" color="blue" :size="MiniView ? 50 : 120">
              <v-icon  :size="MiniView ? 35 : 80" dark>{{CurrentEntity.Table_Icon}}</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="4">
            <v-list-item-content>
             
              <v-list-item-title v-text="record[CurrentEntity.Primary_Field_Name]"></v-list-item-title>
              <v-list-item-subtitle v-if="record.Business_Unit">
                <v-avatar :size="MiniView ? 20 : 35">
                 <v-icon>mdi-office-building</v-icon>
                  </v-avatar>
                  <span v-if="record.Business_Unit">{{' '+record.Business_Unit.Name}}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="record.Owner">
                <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Owner.Profile_Photo" v-if="record.Owner && record.Owner.Profile_Photo"/>
                  <v-img src="@/assets/BlankProfilePic.png" v-if="!record.Owner || !record.Owner.Profile_Photo"/>
                  </v-avatar>
                  <span v-if="record.Owner">{{' '+record.Owner.Full_Name}}</span>
              </v-list-item-subtitle>
              <v-chip-group column>
                  <v-chip x-small v-for="textfield in OptionSetsFilterFields.concat(OptionSetsFilterFields,OptionSetsFilterFields)" :key="textfield.itemObjKey">
                    <span v-if="record[textfield.id]">
                    {{record[textfield.id].Name}}
                    </span>
                  </v-chip> 
                </v-chip-group>
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
          </v-col>
         </v-row> -->
        </v-list-item>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list-item dense class="caption">
          <v-list-item-avatar>
           <DateRender :Value="record.Created_On"
              :Display="'Card'" :Color="'grey'"
              /> 
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Created By:  
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Created_By && record.Created_By.Profile_Photo ? record.Created_By.Profile_Photo :
                  require('@/assets/BlankProfilePic.png')"/>
                  </v-avatar><span v-if="record.Created_By">{{' '+record.Created_By.Full_Name}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
           <v-list-item-avatar>
           <DateRender :Value="record.Modified_On"
              :Display="'Card'" :Color="'grey'"
              /> 
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Modified By:  
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Modified_By && record.Modified_By.Profile_Photo ? record.Modified_By.Profile_Photo :
                  require('@/assets/BlankProfilePic.png')"/>
                  </v-avatar><span v-if="record.Modified_By">{{' '+record.Modified_By.Full_Name}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- until we figure out "AppListFields" comp, don't do this. OR we could at most render then, 
        the other headers otherwise on table view
          <v-list v-for="field in MoreInfoFields" :key="field.itemObjkey">
        <v-list-item>
          {{field.DisplayName}}:
        </v-list-item>
        <v-list-item>
          {{record[field.id]}}
        </v-list-item>
        </v-list> -->
        <v-list-item class="justify-end">
          <v-btn outlined small @click="ViewItem(record)" class="elevation-6 accent--text"><v-icon small v-if="record.CanView">{{TableIcon}}</v-icon>
          View
            </v-btn>
          <v-btn outlined small @click="editItem(record)" class="elevation-6 orange--text"
          v-if="record && record.CanEdit">       
              <v-icon small>
                mdi-pencil
                </v-icon>Edit
            </v-btn>
            <v-btn outlined small @click="deleteItem(record)" class="elevation-6 red--text"
            v-if="record && record.CanDelete">
              <v-icon small>
                mdi-trash-can-outline
              </v-icon>Delete
            </v-btn>
        </v-list-item>
        </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <div v-if="!MiniView">
      <v-list
       v-for="record in CommsDataFiltered" :key="record.itemObjKey">
        <v-list-item  >
      
            <v-list-item-avatar :tile="!MiniView" color="blue" :size="MiniView ? 50 : 80">
              <v-icon  :size="MiniView ? 35 : 60" dark>{{CurrentEntity.Table_Icon}}</v-icon>
          <!-- <v-avatar size="35"> -->
        <!-- <v-img :src="userLoggedIn.Profile_Photo" v-if="userLoggedIn.Profile_Photo"/>
            <v-img src="@/assets/BlankProfilePic.png" v-if="!userLoggedIn.Profile_Photo"/> -->
            </v-list-item-avatar>
            <v-list-item-content>
             
              <v-list-item-title v-text="record[CurrentEntity.Primary_Field_Name]"></v-list-item-title>
              <v-list-item-subtitle v-if="record.Business_Unit">
                <v-avatar :size="MiniView ? 20 : 35">
                 <v-icon>mdi-office-building</v-icon>
                  </v-avatar>
                  <span v-if="record.Business_Unit">{{' '+record.Business_Unit.Name}}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="record.Owner">
                <v-avatar :size="MiniView ? 20 : 35">
                  <v-img :src="record.Owner.Profile_Photo" v-if="record.Owner && record.Owner.Profile_Photo"/>
                  <v-img src="@/assets/BlankProfilePic.png" v-if="!record.Owner || !record.Owner.Profile_Photo"/>
                  </v-avatar>
                  <span v-if="record.Owner">{{' '+record.Owner.Full_Name}}</span>
              </v-list-item-subtitle>
              <v-chip-group column>
                  <v-chip x-small v-for="textfield in OptionSetsFilterFields.concat(OptionSetsFilterFields,OptionSetsFilterFields)" :key="textfield.itemObjKey">
                    <span v-if="record[textfield.id]">
                    {{record[textfield.id].Name}}
                    </span>
                    <!--   @click="CheckforFilter(item,textfield)" -->
                  </v-chip> 
                </v-chip-group>
              <!-- <v-list-item-subtitle v-if="!record.lastMessage" v-text="`asdasd`"></v-list-item-subtitle> -->
             
            </v-list-item-content>
            <v-list-item-avatar tile rounded>
            <v-img :src="record.Created_By && record.Created_By.Profile_Photo ? record.Created_By.Profile_Photo :
                  require('@/assets/BlankProfilePic.png')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Created:  
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="record.Created_By">{{' '+record.Created_By.Full_Name}}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
               <DateRender :Value="record.Modified_On"
              :Display="'Read Date'" :Color="'grey'"
              /> 
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar tile rounded>
            <v-img :src="record.Modified_By && record.Modified_By.Profile_Photo ? record.Modified_By.Profile_Photo :
                  require('@/assets/BlankProfilePic.png')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Modified:  
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="record.Modified_By">{{' '+record.Modified_By.Full_Name}}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
               <DateRender :Value="record.Modified_On"
              :Display="'Read Date'" :Color="'grey'"
              /> 
            </v-list-item-subtitle>
          </v-list-item-content>
             <!--   <v-spacer>
                </v-spacer>
               <span style="font-size:8px">{{record.lastMessage.SendTime}}</span>
                 <v-list-item-action>
                <v-badge color="error" v-if="record.notifications" >
            <span slot="badge"> {{record.notifications}}</span>
            </v-badge>
                 </v-list-item-action> -->
                 <v-badge overlap color="error" v-if="record.notifications" >
                <span slot="badge"> {{record.notifications}}</span>
            </v-badge>
        </v-list-item>
        <v-list-item class="justify-end" dense>
          <v-btn outlined small @click="ViewItem(record)" class="elevation-6 accent--text"><v-icon small v-if="record.CanView">{{TableIcon}}</v-icon>
          View
            </v-btn>
          <v-btn outlined small @click="editItem(record)" class="elevation-6 orange--text"
          v-if="record && record.CanEdit">       
              <v-icon small>
                mdi-pencil
                </v-icon>Edit
            </v-btn>
            <v-btn outlined small @click="deleteItem(record)" class="elevation-6 red--text"
            v-if="record && record.CanDelete">
              <v-icon small>
                mdi-trash-can-outline
              </v-icon>Delete
            </v-btn>
        </v-list-item>
        <!-- <v-list-item style="height: 2px;" dense>
          <v-divider>
          </v-divider>
        </v-list-item> -->
      </v-list>
  </div>
  </div>
      <v-data-table
      v-if="ActiveTableView === 'Standard' && !Refreshing && STDTableView && !StoreArticleEntity && !IsCalendarView && quickeditstep === 1 && !SystemBuilderEntity && !IsBoardView && !IsLiaisonView"
      :dark="AppisDarkMode"
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="ComputedHeaders"
      :items="DataFiltered"      
      :items-per-page="itemsPerPage"
      :hide-default-header="EditingTable"
      :multi-sort="MultiSort"
      :sort-by="MultiSortHeaders"
      :sort-desc="MultiSortOrderDescending"
     :class="$vuetify.theme.dark? 'elevation-1 dbtable darken-2' : 'elevation-1 dbtable'"
      >
       <template v-slot:top>
        <div style="padding-top:40px;"/>        
             
      </template>
      <template v-slot:no-data>
        <v-list class="transparent" v-if="CurrentEntity.Entity_Type.Name !== 'Store Product'">
          No data available
        </v-list>
        <v-list class="transparent" v-if="CurrentEntity.Entity_Type.Name === 'Store Product'">
          <v-list-item class="justify-center">
          You have no {{CurrentEntity.DisplayName}} at the moment.<span  v-if="PreventAdd">But first you need to add {{StoreProductEntityArticleEntity.DisplayName}}</span>
          </v-list-item>
          <v-list-item dense class="justify-center" v-if="PreventAdd">
          {{CurrentEntity.DisplayName}} are Warehouse records, meaning you have "X" amount of {{CurrentEntity.SingleName}} "ABC" in Warehouse "A".<br>
          {{StoreProductEntityArticleEntity.DisplayName}} are in fact the key {{CurrentEntity.DisplayName}} list 
          </v-list-item>
          <v-list-item dense class="justify-center" v-if="StoreProductArticleField.Array.length > 0">
          <v-btn outlined :to="'/'+StoreProductEntityArticleEntity.DisplayName.split(' ').join('')">
          Add {{StoreProductEntityArticleEntity.DisplayName}}</v-btn>
          </v-list-item>
          <v-list-item dense class="justify-center" v-if="userIsAdmin">
            <v-btn class="purple" dark :to="'/Documentation/Suite_Builder/Accounting-Ledger'">
          Read More</v-btn>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:header="{ props }">
            <!-- border-right: 2px solid green; -->
            
            <thead class="v-data-table-header" v-if="EditingTable">
                <tr>
                 
                <th :style="EditingTable? 'text-align:left;padding:10px;border-right: 2px solid red;' : 'text-align:left;padding:10px;'"
                :draggable="EditingTable"
                @dragstart="Headerdragstart(head,headerindex, $event)"  @dragover.prevent @drop.prevent="Headerdragfinish(head,headerindex, $event)"
                class="overline" v-for="(head,headerindex) in props.headers" :key="head.itemObjKey">{{ head.text.toUpperCase() }}
                </th>
                </tr>
            </thead>
      </template>
     <!-- <template v-slot:item="{ item }">
            <tr class="justify-start">
                <td v-for="header in ComputedHeaders" :key="header.itemObjKey" class="text-start">
                  
                  <span v-if="header.Primary">
                       <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text"><v-icon small v-if="item.CanView">{{TableIcon}}</v-icon>
                       <v-badge color="error" v-if="item.notifications" >
                        <span slot="badge"> {{item.notifications}}</span>
                        </v-badge>
                       </v-btn>
                    </span>
                    <span v-if="header.IsContentEditable && item[header.value]" v-html="item[header.value].substr(0,50)">
                    </span>
                    <span v-if="header.Type !== 'Single File Upload' && header.Type !== 'Radio Group'
                    && header.Type !== 'Option Set' && header.Type !== 'Date' && header.Type !== 'Lookup'
                    && header.Type !== 'Social Lookup' && header.value !== 'actions' && !header.IsContentEditable && header.value !== 'LookupObj'">
                     {{ item[header.value] }}
                    </span>
                    <span v-if="header.Type === 'Single File Upload'"><span v-if="!header.FeaturedPhoto && item[header.Name]">
                        {{item[header.Name].filename}}
                          </span><span v-if="header.FeaturedPhoto">
                      <v-avatar
                      color="grey"
                      size="40" 
                      tile>
                      <img style="object-fit:cover;" v-if="item[header.Name]" :src="item[header.Name+'ThumbURL']" height="150px">
                      <img v-if="!item[header.Name] && !header.localURL && $vuetify.theme.dark" src="@/assets/ImageHolder_dark.png" height="150px">
                      <img v-if="!item[header.Name] && !header.localURL && !$vuetify.theme.dark" src="@/assets/ImageHolder.png" height="150px">
                  </v-avatar>
                      </span>
                      </span>
                    <span v-if="header.value == 'LookupObj' && item[header.value]">
                
                     <v-btn v-if="!item.LookupObj.RouteQuery" outlined :to="'/'+item.LookupObj.single+'/'+item.LookupObj.id">View</v-btn>
                     <v-btn v-if="item.LookupObj.RouteQuery" outlined @click="LookupObjRoutetoQuery(item.LookupObj)">View</v-btn>
                    </span>
                    <span v-if="header.Type === 'Option Set' && item[header.propvalue]">
                        {{ item[header.propvalue].Name }}
                    </span>
                    <span v-if="header.Type === 'Radio Group' && item[header.propvalue]">
                      <v-chip-group>
                        <v-chip v-for="entry in item[header.propvalue]" :key="entry.itemObjKey">
                          {{entry.Name}}
                        </v-chip>
                      </v-chip-group>
                    </span>
                    <span v-if="header.Type === 'Date' && item[header.propvalue] && item[header.propvalue].toDate">
                        {{ item[header.propvalue].toDate().toISOString().substring(0, 10) }}
                    </span>
                    <span v-if="header.Type === 'Lookup' && item[header.propvalue] && !header.Name === 'Owner'">
                        {{ item[header.propvalue][header.LookupFieldName] }}
                    </span>
                    <span v-if="header.Type === 'Social Lookup' && item[header.propvalue]">
                        {{ item[header.propvalue][header.LookupFieldName] }}
                    </span>
                    <span v-if="header.Type === 'Common Field' && header.CommonFieldType === 'Physical Address' & HeaderPhysicalAddress(item,header)">
                        <v-btn icon @click="ViewMapItem(header.FieldBreakdown,item)"><v-icon>mdi-google-maps</v-icon></v-btn>
                    </span>
                    <span v-if="header.value === 'Owner' && item.Owner">
                      <v-avatar size="35">
                      <v-img :src="item.Owner.Profile_Photo" v-if="item.Owner && item.Owner.Profile_Photo"/>
                      <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Owner || !item.Owner.Profile_Photo"/>
                      </v-avatar>
                      <span v-if="item.Owner">{{' '+item.Owner.Full_Name}}</span>
                    </span>
                    <span v-if="header.value === 'actions'">
                        <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
                        v-if="item && item.CanEdit">
                            <v-icon small>
                                mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
                            v-if="item && item.CanDelete">
                            <v-icon small>
                                mdi-trash-can-outline
                            </v-icon>
                            </v-btn>
                    </span>
                </td>
            </tr>
        </template> -->
        
      <!-- <template v-if="HeadershaveOwner" v-slot:[`item.Owner`]="{ item }">
      <v-avatar size="35">
        <v-img :src="item.Owner.Profile_Photo" v-if="item.Owner && item.Owner.Profile_Photo"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Owner || !item.Owner.Profile_Photo"/>
        </v-avatar>
        <span v-if="item.Owner">{{' '+item.Owner.Full_Name}}</span>
       
    </template> -->
    <!-- <template v-slot:[`item.Name`]="{ item }">        
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text">        
          <v-icon small v-if="item.CanView">{{TableIcon}}</v-icon></v-btn>
          {{item[PrimaryField]}}
      </template> -->
       <!-- <template v-slot:header="{ props: { headers } }">
      </template> -->
      <template v-slot:[`item.Primary_Field_Value`]="{ item }">
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text"><v-icon small v-if="item.CanView">{{TableIcon}}</v-icon>
            <v-badge color="error" v-if="item.notifications" >
            <span slot="badge"> {{item.notifications}}</span>
            </v-badge>
            </v-btn>
            {{item.Primary_Field_Value}}
      </template>
      <template v-for="opt in ColoredOptions" v-slot:[`item.${opt.value}`]="{ item }">
         <v-badge :key="opt.itemObjKey"  v-if="item[opt.id]"        
              :color="item[opt.id].Color"
              :icon="item[opt.id].Icon"
            >
            {{item[opt.id].Name}}
         </v-badge>
      </template>
      <template  v-slot:[`item.Icon`]="{ item }">
       
        <v-icon color="purple" v-if="item.Icon">
          {{item.Icon}} 
        </v-icon>
      </template>
      <template v-for="field in SpecialTableFields" v-slot:[`item.${field.propvalue}`]="{ item }">
          <v-switch :key="field.itemObjKey" v-if="field.Type === 'Boolean'"
       disabled dense v-model="item[field.propvalue]">
        </v-switch>
        <span :key="field.itemObjKey"
        v-if="item[field.id] && field.Type === 'Multiple Lines Text'" v-html="smart_substr(item[field.id],70)">
        </span>
         <span :key="field.itemObjKey" v-if="item[field.Name] && field.Type === 'Single Line Text'"
       >
        {{smart_substr(item[field.Name],20)}}
        </span>
        <v-avatar size="35" :key="field.itemObjKey" v-if="item[field.Name] && field.Type === 'Lookup' && field.RelatedBuildID === 'Users'">
        <v-img :src="item[field.Name].Profile_Photo" v-if="item[field.Name] && item[field.Name].Profile_Photo"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item[field.Name] || !item[field.Name].Profile_Photo"/>
        </v-avatar>
        <span  :key="field.itemObjKey" v-if="item[field.Name] && field.Type === 'Lookup' && field.RelatedBuildID === 'Users' && !MobileView">{{' '+item[field.Name].Full_Name}}</span>
        <v-btn small :key="field.itemObjKey" v-if="item[field.id] && field.CommonFieldType === 'URL Link'" target="_blank"
        :href="item[field.id]">{{field.DisplayName}}</v-btn>
        <v-btn icon :key="field.itemObjKey"  v-if="item[field.id] && field.CommonFieldType === 'Physical Address'"
        @click="ViewMapItem(header.FieldBreakdown,item)"><v-icon>mdi-google-maps</v-icon></v-btn>
      </template>
      <template v-slot:[`item.Status.Name`]="{ item }">
        <v-btn @click="ActivateStateDialog(item)" :disabled="!item.CanEdit || CashLoanEntity" class="caption" style="text-transform: none !important;" depressed text plain>
          <v-icon left small>
            mdi-power
            </v-icon>
       {{item.Status_Reason ? item.Status_Reason.Name : 'No Status Reason'}} ({{item.Status? item.Status.Name : 'No Status'}})
        </v-btn>
        <!-- <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item.CanEdit">       
          <v-icon small>
            mdi-power
            </v-icon>
        </v-btn> -->
      </template>
      <template v-slot:[`item.actions`]="{ item }">
      <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item.CanEdit">       
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item.CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
        
      </template>
      </v-data-table>
    </div>
    
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/Fields/FieldsForm';	
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import LookupComponentSubCollections from '@/components/Database/LookupComponentSubCollection'
import CalendarViewComp from '@/components/SuitePlugins/CalendarTables/CalendarViewComp'
import BoardComponent from '@/components/General/BoardComponent'
import ScheduledBookingViewComp from '@/components/SuitePlugins/CalendarTables/ScheduledBookingViewComp'
import FieldConfig from '@/components/Database/Fields/FieldConfig';	
import DateRender from '@/components/Database/Fields/ReadOnly/DateRender';	
import DynamicItemBubble from '@/components/Database/DynamicItemBubble';	

import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import LookupSubColDialogComponent from '@/components/Database/LookupComponentSubCollection'
import ClassifiedListingListItem from '@/components/Directories/ClassifiedListingListItem';
import WikiListingItem from '@/components/Directories/WikiListingItem';
import QuickAlerts from '@/components/Dashboards/QuickAlerts';
import QuickCounters from '@/components/Dashboards/QuickCounters';
import QuickCharts from '@/components/Dashboards/QuickCharts';
import QuickDashboard from '@/components/Dashboards/QuickDashboard';
import RSVPTableItem from '@/components/SuitePlugins/CalendarTables/RSVPTableItem';	
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	
import UserLookup from '@/components/General/UserLookup'
import QuickEditComponent from '@/components/Database/Fields/Input/QuickEditComponent'
import AllMyTasksandAppointments from '@/views/Activities/MyProfile/AllMyTasksandAppointments';

import Timesheets from '@/components/SuitePlugins/Timesheets/Timesheets';
import BookedUser from '@/components/SuitePlugins/CalendarTables/BookedUser';	
import TableRecordComponent from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/DataSingleRender';	
import StoreArticlesManager from '@/components/SuitePlugins//OnlineStore/StoreArticlesManager';
export default {
    props: ['System','Directories','SystemEntities','RADB','SystemPhotoLibraries','SystemGuides','RAApp','UnreadNotifications','ProvidedEntityid','TableOnly','MyActivitiesArray'],
    components: {
      BoardComponent,
      TableRecordComponent,
        FieldsForm,
        ContentEditableField,
        LookupComponentSubCollections,
        CalendarViewComp,
        ScheduledBookingViewComp,
        FieldConfig,
        DateRender,
        DynamicItemBubble,
        FieldViewerComponent,
        LookupSubColDialogComponent,
        ClassifiedListingListItem,
        WikiListingItem,
        QuickAlerts,
        QuickCounters,
        QuickCharts,
        QuickDashboard,
        RSVPTableItem,
        BookedUser,
        AssetsDirectory,
        UserLookup,
        QuickEditComponent,
        AllMyTasksandAppointments,
        Timesheets,
        StoreArticlesManager
    },
    data() {
        return {
          StateDialog: false,
          ActiveSectionType: '',
          SectionTypes: [
            {Name: 'Fields',TypeProps: ['Data Single Fields'],Icon: 'mdi-clipboard-list',Color: 'blue'},
            {Name: 'Galleries',TypeProps: ['Data Single Gallery'],Icon: 'mdi-folder-image',Color: 'pink'},
            {Name: 'Documents',TypeProps: ['Data Single Document Register'],Icon: 'mdi-database',Color: 'brown'},
            {Name: 'Related',TypeProps: ['Data Single Related Data'],Icon: 'mdi-database',Color: 'brown'}
          ],
          ActiveTab: '',
          ActiveTabIndex: -1,
          ActiveSectionIndex: -1,
          ActiveSection: '',
          HideRecordQuickNav: false,
          ActiveChatRecord: '',
          QuickMode: '',
          AdvancedFind: false,
          AdvancedFindFilters: [],
          AdvancedFindFilterItems: [],
          QuickDashFilterItem: '',
          QuickDashView: false,
          QuickDashPreview: true,
          ActiveSubCollection: '',
          ActiveSubcolHeaders: [],
          SubColRecord: '',
          SubColPrimaryField: '',
          PrimaryCategoryField: '',
          PrimaryDateMenu: false,
          CalendarEntryCompleted: false,
          schedstep: 1,
          quickeditstep: 1,
          TableReady: [],
          SubColLookupDialogActive: false,
          InboundStockRecords: [],
          OutboundStockRecords: [],
          WarehouseEntries: [],
          ConsumeBulkQuantityArrayCounter: 0,
          FilterHeaders: [],
          OptionSetsFilterFields: [],
          ParentOptionSetsFilterFields: [],
          LookupFilterFields: [],
          Validating: false,
          search: '',
          HeadersTab: 0,
          page: 1,
          pageCount: 0,
          itemsPerPage: 10,
          IsVCardView: false,
          IsCalendarView: false,
          IsBoardView: false,
          IsLiaisonView: false,
          ActiveBoard: '',
          STDTableView: false,
          subcollectstep: 1,
          SelectedSubcollectionArray: [],
          SubColLookupDialog: false,
          SubCollectionItemDialog: false, 
          SCViewExistingLookupDialog: false,
          SCAddNewParentLookupDialog: false,
          AddNewParentLookupDialog: false,
          ViewExistingLookupDialog: false,
          FieldFilter: '',
          ParentTitle: '',
          ParentCollection:  '',
          NewParentPrimary: '',
          ParentLookup: '',
          NewParentAdditional: [],
          SubCollectionLookupArrays: [],
          LookupArray: [],
          LookupArrayHeaders: [],
            DraggingHeaderIndex: -1,

            EditingTable: false,
            HeaderTypes: [
            'Single Line Text',
            'Multiple Lines Text',
            'Option Set',
            'Lookup',
            'Number Field',
            'Date'  
            ],
            DefaultNewField: {Name: '',Type: '',DisplayName: ''},
            NewField: {Name: '',Type: '',DisplayName: ''},
            Refreshing: false,
          editedIndex: -1,
          defaultItem: {
            id: '',
          },
          editedItem: {
          id: '',
          },
          rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
          RecordQuickNavItems: [
            {Name: 'Record',Icon: 'mdi-database',Color: 'blue-grey',Prop: 'RecordView'},
            {Name: 'Q-Edit',Icon: 'mdi-pencil',Color: 'warning',Prop: 'QuickEdit',ComputedProp: 'QuickEditEntity'},
            {Name: 'Activities',Icon: 'mdi-clipboard-list',Color: 'purple',Prop: 'QuickActivities',Pluginid: 'Activities_and_Team'},
            {Name: 'Timesheets',Icon: 'mdi-account-clock',Color: 'success',Prop: 'QuickTimesheets',Pluginid: 'Timesheets'},
            {Name: 'Liaison',Icon: 'mdi-comment-outline',Color: 'indigo',Prop: 'QuickLiaison'},
            {Name: 'PDF',Icon: 'mdi-file-pdf-box',Color: 'error',Prop: 'QuickPDF',Pluginid: 'PDF_Builder'}
          ] ,
          ActivityType: '',
          EditingCalendar: false,
          recordformdialog: false,
          importdialog: false,
          importsnackbar: false,
          ImportHeaders: [],
          ImportingProgressDialog: false,
          Importcount: '',
          importresultsdialog: false,
          NewImportsArray: [],
          PartialFailuresArray: [],
          SuccessfulImportsArray: [],
          CSVResult: [],
          Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: '', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
          ImportFile: '',
          exportdialog: false,
          ExportDataArray: [],
          ExportHeaders: [],
          FieldSearch: '',
          MasterHeaders: [],
          Data: [],
          FlexXLRG:3,									
        FlexLarge: 4,									
        FlexMedium: 4,									
        FlexSmall: 6,									
        FlexXSmall: 6,
        }
    },
    computed:{
      ActionItems(){
        return [
          {title: 'View',icon: 'mdi-eye',Color: 'blue',Method: 'ViewItem'},
          {title: 'Edit',icon: 'mdi-pencil',Color: 'orange',Method: 'editItem'},
          {title: 'Delete',icon: 'mdi-delete-forever',Color: 'red',Method: 'deleteItem'}
        ]
      },
      TableToolbar(){
        return this.MobileView ? this.TableToolbarMobile : 
        this.MiniView ?  this.TableToolbarSmall : this.TableToolbarSTD
      },
      TableToolbarSTD(){
        return this.CurrentEntity && this.CurrentEntity.TableToolbarSTD ?
        this.CurrentEntity.TableToolbarSTD : 'Standard' 
      },
      TableToolbarSmall(){
        return this.CurrentEntity && this.CurrentEntity.TableToolbarSmall ?
        this.CurrentEntity.TableToolbarSmall : 'Standard' 
      },
      TableToolbarMobile(){
        return this.CurrentEntity && this.CurrentEntity.TableToolbarMobile ?
        this.CurrentEntity.TableToolbarMobile : 'Standard' 
      },
      ViewingValue(){
        return (this.DataFiltered.length/this.TableData.length)*100
      },
      ViewingText(){
        return this.DataFiltered.length+'/'+this.TableData.length
      },
      ActiveTableView(){
        return this.MobileView ? this.TableViewMobile : 
        this.MiniView ?  this.TableViewSmall : this.TableViewSTD
      },
      TableViewSTD(){
        return this.CurrentEntity && this.CurrentEntity.TableViewSTD ?
        this.CurrentEntity.TableViewSTD : 'Standard' 
      },
      TableViewSmall(){
        return this.CurrentEntity && this.CurrentEntity.TableViewSmall ?
        this.CurrentEntity.TableViewSmall : 'Standard' 
      },
      TableViewMobile(){
        return this.CurrentEntity && this.CurrentEntity.TableViewMobile ?
        this.CurrentEntity.TableViewMobile : 'Standard' 
      },
      ServiceBlockSize(){
            return this.MobileView? 150 : this.MiniView ? 150 : this.$vuetify.breakpoint.md ? 150 : this.$vuetify.breakpoint.lg ? 150 : 150
        },
      MoreInfoFields(){
        let autoincluded = ['Owner','Business_Unit','Created_By','Created_On','Modified_By','Modified_On']
        return this.CurrentEntity && this.CurrentEntity.AllFields ? 
        this.CurrentEntity.AllFields.filter(field => {
          return !autoincluded.includes(field.id) && field.id !== this.CurrentEntity.Primary_Field_Name
          && !this.OptionSetsFilterFields.find(obj => obj.id === field.id)
        }) : []
      },
      StrictlyQuickEdit(){
        return this.CalendarTable
      },
      ActiveSuiteMenuItems(){
        return this.$store.state.ActiveSuiteMenuItems.filter(item => {
          return item.TableFilter
        })
      },
      DefaultServiceCols(){
            return this.MobileView? 12 : this.MiniView ? 6 : 3
        },
      CashLoanEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Cash Loan'
        },
      DynamicEditItem(){
        return this.editedItem && this.editedIndex !== -1 && this.Data[this.editedIndex] ? this.Data[this.editedIndex] : ''
      },
      ComputedCanCreate(){
        return this.CanCreate && this.SystemBuilderTable && this.DefaultSystemEntities && this.$store.state.DefaultEntities.length === this.DefaultSystemEntities.length ? this.CanCreate : this.CanCreate
      },
      SystemBuilderTable(){
        return this.SystemisRA && this.CurrentEntity && this.CurrentEntity.id === 'Systems'
      },
      EntitiesArray(){
        return this.SystemBuilderTable && this.$store.state.EntitiesArray ? this.$store.state.EntitiesArray.concat([this.SystemEntities.find(obj => obj.id === 'Business_Units')]) : []
      },
      DefaultSystemEntities(){
        return this.SystemBuilderTable ? this.EntitiesArray
        .filter(ent => {
          //Entities on RA db has boolean "Default_System_Entity" and this maarks the RA store Template as a default install entity 
          return ent.Default_System_Entity
        })
        .map(ent => {
          let stateent = this.$store.state.DefaultEntities.find(obj => obj.DisplayName === ent.DisplayName)
          let entobj = JSON.parse(JSON.stringify(ent))
          if(stateent && stateent.Table_Icon){
            entobj.Table_Icon = stateent.Table_Icon
          }
          if(stateent && stateent.Security === 'Custom Roles'){
            entobj.CustomCreateRoles = []
            entobj.CustomDeleteRoles = []
            entobj.CustomListRoles = []
            entobj.CustomGetRoles = []
            entobj.CustomUpdateRoles = []
          }
          else {
            this.$store.state.BURoles.map(role => {
              entobj[role.Name] = []
            })
          }
          return entobj
        }) : []
      },
       DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      ComputedData(){
        return this.Data
      },
      RecordChatView(){
        return this.IsLiaisonView && this.ActiveChatRecord
      },
      ComputedOptionSetsFilterFields(){
        return this.OptionSetsFilterFields.filter(field => {
          return field.id !== 'Status'
        })
      },
      ItemOperationalDB(){
        return this.ActiveChatRecord && this.ActiveChatRecord.id ? 
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.ActiveChatRecord.id)
        : ''
      },
      BoardOptions(){
        return this.CurrentEntity.AllFields.filter(field => {
          return field.Type === 'Option Set' && field.IsBoard
        })
      },
      ColoredOptions(){
        return this.ComputedHeaders.filter(field => {
          return field.Type === 'Option Set' && field.HasColors
        })
      },
      UserFields(){
        return this.ComputedHeaders.filter(field => {
          return field.Type === 'Lookup' && field.RelatedBuildID === 'Users'
        })
      },
      BooleanFields(){
        return this.ComputedHeaders.filter(field => {
          return field.Type === 'Boolean'
        })
      },
      SingleLineTextFields(){
        return this.ComputedHeaders.filter(field => {
          return field.Type === 'Single Line Text' && !field.Primary && field.id !== 'Icon'
        })
      },
      MultipleTextFields(){
        return this.ComputedHeaders.filter(field => {
          return field.Type === 'Multiple Lines Text'
        })
      },
      URLLinkFields(){
        return this.ComputedHeaders.filter(field => {
          return field.CommonFieldType === 'URL Link'
        })
      },
      AddressFields(){
        return this.ComputedHeaders.filter(field => {
          return field.CommonFieldType === 'Physical Address'
        })
      },
      SpecialTableFields(){
        return this.UserFields.concat(this.BooleanFields,this.SingleLineTextFields,this.MultipleTextFields,this.AddressFields,this.URLLinkFields)
      },
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
      ComputedSingleBuildTabs(){
        // console.log(this.$store.state)
          return this.$store.state.CurrentDataTabs
        },
        ComputedSingleBuildSections(){
        return this.ComputedSingleBuildTabs.map((tab,tabindex) => {
          return tab.Elements.map((elmnt,elmntindex) => {
            let elmntobj = Object.assign({},elmnt)
            elmntobj.TabObj = tab
            elmntobj.TabIndex = tabindex
            elmntobj.ElmntIndex = elmntindex
            return elmntobj
          })
        }).flat()
      },
      ComputedSubCollectionSections(){
        return this.ComputedSingleBuildSections.filter(sect => {
          return sect.DataViewName === 'Data Single SubCollection'
        }).map(item => {
          if(!item.Color){
            item.Color = 'warning'
          }
          if(this.MiniView){
            item.Class = item.Color+' recordviewtabssectionmini my-1'
          }
          else{
            item.Class = item.Color+' recordviewtabssection my-1'
          }
          return item
        })
      },
      SectionSelection(){
        return this.PaginationRequired ? this.PaginatedSectionsbyType : this.ComputedSingleBuildSectionsbyType
      },
      PaginatedSectionsbyType(){
        let start = (this.page-1)*this.PaginationSize
        let end = start+this.PaginationSize
        //console.log(start,end)
        return this.ComputedSingleBuildSectionsbyType.slice(start,end)
      },
      PaginationLength(){
        return Math.ceil(this.ComputedSingleBuildSectionsbyType.length/this.PaginationSize)
      },
      PaginationRequired(){
        return this.ComputedSingleBuildSectionsbyType.length > this.PaginationSize
      },
      PaginationSize(){
        if(this.$vuetify.breakpoint.xs){
          return 3
        }
        else if(this.$vuetify.breakpoint.sm){
          return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return 5
        }
        else if(this.$vuetify.breakpoint.lg){
          return 6
        }
        else if(this.$vuetify.breakpoint.xl){
          return 8
        }
      },
      ComputedSingleBuildSectionsbyType(){
        return this.ComputedSingleBuildSections.filter(sect => {
          return this.ActiveSectionType ? this.ActiveSectionType.TypeProps.includes(sect.DataViewName) : sect
        })
      },
      ActiveSectionTypes(){
        return this.SectionTypes.filter(secttype => {
          let sectmatch = this.ComputedSingleBuildSections.find(obj => secttype.TypeProps.includes(obj.DataViewName))
          return sectmatch
        }).map(item => {
          if(this.MiniView){
            item.Class = item.Color+' recordviewtabssectionmini my-1'
          }
          else{
            item.Class = item.Color+' recordviewtabssection my-1'
          }
          return item
        })
      },
      RecordNotifications(){
        return this.UnreadNotifications.filter(not => {
                    return this.editedItem && not.clientrecords === this.editedItem.id
                })
      },
      ComputedRecordQuickNavItems(){
        return this.RecordQuickNavItems.map(item => {
          if(this.MiniView){
            item.Class = item.Color+' recordcabinetdrawermini  my-1' 
          }
          else{
            item.Class = item.Color+' recordcabinetdrawer my-1'
          }
          if(item.Name === 'Liaison'){
            item.Notifications = this.RecordNotifications.filter(not => {
                    return not.IsLiaison
                })
                if(item.Notifications.length > 0){
                    item.notifications = item.Notifications.length
                }
                else{
                  item.notifications = ''
                }
          }
          else if(item.Name === 'Activities'){
            item.Notifications = this.RecordNotifications.filter(not => {
                    return not.Type.includes('Task')
                })
                if(item.Notifications.length > 0){
                    item.notifications = item.Notifications.length
                }
                else{
                  item.notifications = ''
                }
          }
          //console.log(item.Name)
          return item
        }).filter(item => {
          if(item.Pluginid && this.ActiveSuiteApp){
            return this.ActiveSuiteApp.Plugins.find(obj => obj.id === item.Pluginid)
            || this.PluginDataBase[item.Pluginid] && this.PluginDataBase[item.Pluginid].Active
          }
          else if(item.ComputedProp){
            return this[item.ComputedProp]
          }
          else{
            return item
          }
        })
        //should check plugins
      },
      PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
      CurrentEntityFields(){
        return this.CurrentEntity.AllFields.map(field => {
          let fieldobj = Object.assign({},field)
          return fieldobj
        })
      },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
      AppQuickDashPlugin(){
        return this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Quick_Dashboards')
      },
      ActiveQuickDashElements(){
        return this.EntityQuickDashElements && this.EntityQuickDashElements.length > 0
      },
      EntityQuickDashElements(){
        return this.CurrentEntity.QuickDashElements
      },
      ComputedQuickDashFilterField(){
        let fieldobj = Object.assign({},this.CurrentEntityFields.find(obj => obj.id === this.QuickDashFilterItem.DataField))
        fieldobj.FilterID = this.QuickDashFilterItem.FilterID
        if(this.QuickDashFilterItem.ExtendedFilterID && this.QuickDashFilterItem.ExtendedFilterField){
          fieldobj.ExtendedFilterID = this.QuickDashFilterItem.ExtendedFilterID
          fieldobj.ExtendedFilterField = this.QuickDashFilterItem.ExtendedFilterField
        }
        //console.log(fieldobj)
        return fieldobj
      },
      CalendarTable(){
        return this.CurrentEntity.Entity_Type.Name === 'Calendar'
      },
      CalendarTypeEntity(){
        return this.ScheduleBookingEntity || this.CalendarTable
      },
      ScheduleBookingEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Booking Schedule'
      },
      QuickEditEntity(){
        return this.StoreArticleEntity || this.StoreProductEntity || this.CalendarTypeEntity || this.SystemBuilderEntity
      },
      SystemBuilderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'System Builder'
      },
      HasNavlist(){
        if(this.StoreArticleEntity){
         let obj = {CategoricalListType: 'Store Products',HidePrice: true,HideTitle: true,
        HideCart: true,HideWishlist: true,DataRoute: true,
        TagField: this.CurrentEntityFields.find(obj =>obj.id === 'Product_Code'),
        IMGField: 'IMG',
        DescriptionField: 'Descriptive_Text',
        SingleName: this.CurrentEntity.SingleName}
        //console.log(obj)
        return obj 
        }
        else if(this.SystemBuilderEntity){
         let obj = {CategoricalListType: 'Store Products',HidePrice: true,HideTitle: true,
        HideCart: true,HideWishlist: true,DataRoute: true,
        TagField: this.CurrentEntityFields.find(obj =>obj.id === 'Name'),
        IMGField: 'Suite_Logo',
        DescriptionField: 'Description',
        SingleName: this.CurrentEntity.SingleName}
        //console.log(obj)
        return obj 
        }
        
      },
      QuickEditSections(){
        if(this.StoreArticleEntity){
          let sect1fields = ['Product_Code','Product_Group','Item_Type','UOM','PrimaryCategoryField','Descriptive_Text']
          let sect2fields = ['Image','Purchase_Leadtime','Supply_Leadtime','Last_Quoted_Price','Last_Quoted_Price_Date','Store_Price']
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Type: 'Gallery',GalleryName: 'Gallery',Class: 'flex xl12 lg12 md12 sm12 xs12',SelectAction: 'Set Primary',CanCreate: this.editedItem && this.editedItem.CanEdit},
          ]
        }
        else if(this.SystemBuilderEntity){
          let sect1fields = ['Name','BillingAccount','Business_Unit']
          let sect2fields = [
          {id: 'Suite_Logo',Name: 'Suite_Logo',DisplayName: 'Suite Logo',text: 'Suite Logo',value: 'Suite_Logo',
          Type: 'Single File Upload',FeaturedPhoto: true},
          {id: 'App_Icon',Name: 'App_Icon',DisplayName: 'App Icon',text: 'App Icon',value: 'App_Icon',
          Type: 'Single File Upload',FeaturedPhoto: true}]
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields).concat([{Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description'}])
            ,Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Details',Type: 'Fields',Fields: sect2fields,Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Plugins',Type: 'SubCollection',GalleryName: 'BookedUsers',Class: 'flex xl12 lg12 md12 sm12 xs12',SubCollection: {}
            ,SelectAction: 'Set Primary',
            SubCollectionComponent: this.ActiveComponent('components/SuiteBuilder/Main/RASuiteSubscriptions'),}
          ]
        }
        else if(this.StoreProductEntity){
          let sect1fields = ['Item_Name','Inventory_Article','Cost_Price','Markup','Discount','Store_Price']
          let sect2fields = ['On_Order','Backorder','Inbound','Received','On_Hand']
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Movement',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Gallery',GalleryName: 'Gallery',Class: 'flex xl12 lg12 md12 sm12 xs12',SelectAction: 'Set Primary'},
          ]
        }
        else if(this.ScheduleBookingEntity){
          let sect1fields = ['Title','Type','Owner','Presenter','Description']
          let sect2fields = ['Image']
          let sect3fields = ['User_Limit','RSVP_Amount','RSVP_Type','RSVP_Advance_Amount','RSVP_Advance_Type']
          let bookingsubcol = this.CurrentEntity.SubCollections[0]
          let bookedusers = this.editedItem && this.editedItem.BookedUsers ? this.editedItem.BookedUsers.map(booking => {
            let userobj = this.UsersArray.find(obj => obj.id === booking.id)
            userobj.BookingObj = booking
            return userobj
          }) : []
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Header',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Graphics',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Booking Settings',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect3fields),Class: 'flex xl12 lg12 md6 sm12 xs12',
             Tooltip: this.RSVPUserLimit+`<br>`+this.RSVPSoonestTooltip+`<br>`+this.RSVPLatestTooltip
            },
            {Header: 'Bookings',Type: 'SubCollection',GalleryName: 'BookedUsers',Class: 'flex xl12 lg12 md12 sm12 xs12',SubCollection: bookingsubcol,SelectAction: 'Set Primary',
            SubCollectionComponent: this.ActiveComponent('components/General/UserLookupTable'),
            UsersLookupArray: bookedusers,UserHeaders: bookingsubcol.Headers,AdditionalHeaders: []}
          ]
        }
        else if(this.CalendarTable){
          //tricky but essentially 'Owner' applies if bu structure entity
          let sect1fields = ['Title','Description']
          let sect2fields = ['Start','End']
          //no image it would seem?
          //let sect2fields = ['Image']
          //let sect3fields = ['User_Limit','RSVP_Amount','RSVP_Type','RSVP_Advance_Amount','RSVP_Advance_Type']
          //HAPPPY THAT NOBODY CAN BOOK ON CALENDAR RIGHT? ALSO WHAT ABOUT CUSTOM SUBCOLS? QUICKEDIT?
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Header',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Time',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
          ]
        }
        
        else{
          return []
        }
      },
      RSVPUserLimit(){
        return `No more than <span class="red--text">`+this.editedItem.User_Limit+`</span> Users allowed`
      },
      RSVPSoonestTooltip(){
        let amount = this.editedItem.RSVP_Amount+' '
        let type = this.editedItem.RSVP_Type ? this.editedItem.RSVP_Type.Name : ' '
        return `Users would be allowed to RSVP up until <span class="red--text">`+amount+type+`</span> prior to `+this.CurrentEntity.SingleName+` start`
      },
      RSVPLatestTooltip(){
        let amount = this.editedItem.RSVP_Advance_Amount+' '
        let type = this.editedItem.RSVP_Advance_Type ? this.editedItem.RSVP_Advance_Type.Name : ' '
        return `Users would be allowed to RSVP NO SOONER THAN <span class="red--text">`+amount+type+`</span> prior to `+this.CurrentEntity.SingleName+` start`
      },
      PrimaryImageFieldName(){
        if(this.StoreArticleEntity){
          return 'Image'
        }
      },
      PrimaryImageField(){
        return this.PrimaryImageFieldName ? this.CurrentEntityFields.find(obj => obj.id === this.PrimaryImageFieldName) : ''
      },
      ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 12
        }
        else if(this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.xl){
          return 2
        }
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ScheduledCalendarStepTitle(){
        switch (this.schedstep) {
                case 1: return 'Edit Title Info'
                case 2: return 'Details'
                case 3: return 'Define RSVP Rules'
                case 4: return 'Review and Save'
        }
      },
      ProductsModifiedThisWeekChartObj(){
      let chartobj = {
        //Preset: 'Activities',
        Title: 'Modified This Week',
        Type: 'Line',
        TimeFilterOption: 'This Month',
        PrimaryLabelType: 'From Timeline',
        PrimaryEntity: this.CurrentEntity.id,
        Data: [],
        ChartLabelsfromTimeline: true,
        ChartLabels: [],
        ChartBG: 'purple',
        PrimaryDateFilter: 
            {
                Name: 'Modified_On',
                Type: 'Date'
            },
        ChartType: 'line',	
        ColumnWidth: 'Full Column',	
        ChartPosition: 'Left',
        HasData: true,
        HasChart: true,
        HasDataContext: false,
        DataContextStyle: 'List',
        Data: this.MasterData.map(record => {
          let recordobj = Object.assign({},record)
          return recordobj
        }),
        ValueType: 'Count'
      }
      chartobj.DashboardEntity = this.CurrentEntity
      return chartobj
    },
    ProductsonHand(){
      return this.MasterData.filter(data => {
        return data.On_Hand && data.On_Hand > 0
      })
    },
    StoreProductSummaries(){
      return this.WarehouseCardsView ? 
      this.$store.state.WarehousesArray.map(wh => {
        let counterobj = {QuickDashType: 'QuickCounters', Counters: this.WarehouseProductCounters(wh), CanInteract: true}
        return counterobj
      }) : [
        {QuickDashType: 'QuickCharts', ChartData: [], ChartObj: this.ProductsModifiedThisWeekChartObj},
        {QuickDashType: 'QuickCounters', Counters: this.ProductCounters, CanInteract: true},
      ]
    },
      ProductCounters(){
            return [
              // #ff980012
                {CounterIcon: 'mdi-barcode',Data: this.StoreProductArticles,CounterInteger: this.StoreProductArticles.length, Title: 'Products',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#DD2C00'},FontColor: {hex: '#DD2C00'}},
                {CounterIcon: 'mdi-gift',Data: this.MasterData,CounterInteger: this.MasterData.length, Title: 'Warehouse Items',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#DD2C00'},FontColor: {hex: '#DD2C00'}},
                {CounterIcon: 'mdi-warehouse',Data: this.$store.state.WarehousesArray,CounterInteger: this.$store.state.WarehousesArray.length, Title: 'Warehouses',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#E65100'},FontColor: {hex: '#E65100'}},
                {CounterIcon: 'mdi-hand-okay',Data: this.ProductsonHand,CounterInteger: this.ProductsonHand.length, Title: 'On Hand',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#795548'},FontColor: {hex: '#795548'}},
                // {CounterIcon: 'mdi-clipboard-list',Data: this.MyActivitiesArray,CounterInteger: this.MyActivitiesArray.length, Title: 'Open',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                // BGColor: {hex: ''},HeaderColor: {hex: '#607D8B'},FontColor: {hex: '#607D8B'}},
            ]
        },
      PrimaryTaskAlerts(){
          return [
              {Title: 'Incomplete Activities',Array: this.OverdueActivities, TableName: 'Activities',IncompleteName: 'Overdue'},
          ]
      },
      ActiveWarehouse(){
            return this.$store.state.ActiveWarehouse
        },
      WarehouseswithProducts(){
        return this.$store.state.WarehousesArray.map(wh => {
          let whobj = Object.assign({},wh)
          whobj.Products = this.MasterData
          return whobj
        })
      },
      CommsDataFiltered(){
        return this.DataFiltered.sort(function(a, b){return b.notifications - a.notifications})
      },
      ComputedDataTableListingsPaginated(){
        return this.DataFiltered.map(item => {
          let itemobj = Object.assign({},item)
          //console.log(itemobj)
          if(this.StoreArticleEntity){
           itemobj.Title = item.Product_Code
          if(item.Image){
            item.IMG = item.Image.fileurl
            itemobj.Photo = item.Image
          }
          if(item.Descriptive_Text){
            itemobj.Description = item.Descriptive_Text
          }
          if(item.Store_Price){
            itemobj.Price = item.Store_Price
          }
          }
          else if(this.SystemBuilderEntity){
            itemobj.Title = item.Name
            if(item.Suite_Logo){
              item.IMG = item.Suite_Logo.fileurl
              itemobj.Photo = item.Suite_Logo
            }
            if(item.Description){
              itemobj.Description = item.Description
            }
            //if(item.Store_Price){
              itemobj.Price = 0
            //}
          }
          
          itemobj.Path = '/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+itemobj.id 
          return itemobj
        })
      },
      FlexClasses(){
      return 'flex xl'+this.FlexXLRG+' lg'+this.FlexLarge+' md'+this.FlexMedium+' sm'+this.FlexSmall+' xs'+this.FlexXSmall+''
    },
      PreventAdd(){
        return this.StoreProductEntity && this.StoreProductArticleField && this.StoreProductArticleField.Array.length === 0
      },
      StoreArticleEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
      },
      StoreProductEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Product'
      },
      UserCompaniesEntity(){
        return this.CurrentEntity && this.CurrentEntity.id === 'User_Companies'
      },
      StoreOrderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Order'
      },
      AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
      StoreProductArticleField(){
        return this.StoreProductEntity ? this.CurrentEntityFields.find(obj => obj.id === 'Inventory_Article') : ''
      },
      StoreProductArticles(){
        return this.StoreProductArticleField.Array
        //pending of course must be header, then field called form headers
      },
      StoreProductEntityArticleEntity(){
        return this.StoreProductArticleField ? this.AppEntities.find(obj => obj.id === this.StoreProductArticleField.RelatedBuildID) : ''
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
        },
      TemplateView(){
          return this.ActiveMarketTemplate
      },
      TemplateEntities(){
          return this.$store.state.TemplateEntities
      },
      ComputedEntities(){
          return this.TemplateView ? this.TemplateEntities : this.SystemEntities
      },
      CurrentEntity(){
        return this.ComputedEntities.find(obj =>obj.id === this.id)
      },
      id(){
        return this.$route.meta && this.$route.meta.EntityID ? this.$route.meta.EntityID : this.ProvidedEntityid
      },
      TableName(){
        return this.CurrentEntity.DisplayName
      },
      TableNameSplit(){
            return this.TableName.split(' ').join('').split('_').join('')
        },
      TableIcon(){
        return this.CurrentEntity.Table_Icon
      },
      PrimaryField(){
        return this.CurrentEntityFields.find(obj => obj.Primary)
      },
      tab(){
        return {
          Entityid: this.id,
          datasetsecuritystructure: this.CurrentEntity.Security_Structure.Name
        }
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      TemplateView(){
          return this.ActiveMarketTemplate
      },
      MasterData(){
        return this.TemplateView ? [] : 
        this.$store.state[this.TableNameSplit+'Array']
      },
      TableData(){
            return this.Data.map(item => {
              item.Notifications = this.UnreadNotifications.filter(not => {
                    return not.clientrecords === item.id
                })
                if(item.Notifications.length > 0){
                    item.notifications = item.Notifications.length
                }
                else{
                  item.notifications = ''
                }
                if(item.Owner){
                  let ownerobj = this.UsersArray.find(obj => obj.id === item.Ownerid)
                  //console.log('ownerobj',ownerobj,this.UsersArray)
                  if(ownerobj && ownerobj.Profile_Photo){
                    item.Owner.Profile_Photo = ownerobj.Profile_Photo
                  }
                }
                if(item[this.CurrentEntity.Primary_Field_Name]){
                  item.Primary_Field_Value = item[this.CurrentEntity.Primary_Field_Name]
                }
                // this.ColoredOptions.map(field => {
                  //this does NOT work it ahngs the system gotta figure a way to keep colors up to date with latest option colorrs though
                //   field.Options.map(opt => {
                //    if(item[field.Name] && opt.Color){
                //     item[field.Name].Color = opt.Color
                //   } 
                //   })
                  
                // })
                return item
            }).map(item => {
              this.MasterHeaders.map(header => {
                if(item[header.id] && item[header.id].toDate && header.Type === 'Date'){
                  let dateobj = item[header.id].toDate()
                  item[header.id] = format(dateobj,'yyyy-MM-dd')
                }
              })
              return item
            })
        },
      FilteredHeaders(){
        return this.Headers.filter(head => {
          return !head.ReadOnly
        })
      },
      Headers(){
            let headers = this.MasterHeaders.map(header => {
                // if(header.Type === 'Lookup' && header.Name === 'Owner'){
                //     let entityobj = this.CurrentEntity
                //     let tablesecuritystructure = entityobj.Security_Structure.Name
                //     let namecheck = entityobj.id.split('_').join('')
                //     //console.log(header,header.id,tablesecuritystructure,header.Array,namecheck,this.$store.state,this.$store.state[namecheck+'createAll'],this.UsersArray)
                //     if(header.RelatedBuildID === 'Users' && header.Array.length === 0){
                //       header.Array = this.UsersArray
                //     }
                //     if(tablesecuritystructure === 'Business Unit'){
                //         header.Array = header.Array
                //         .filter(item => {
                //           console.log(item,this.$store.state[namecheck+'createAll'])
                //             if(this.$store.state[namecheck+'createAll']){
                //                 return item
                //             }
                //             else if(this.$store.state[namecheck+'createUnitDown']){
                //                 return this.userBUChildren.includes(item.Business_Unitid)
                //             }
                //             else if(this.$store.state[namecheck+'createUserUnit']){
                //                 return item.Business_Unitid === this.userBUID
                //             }
                //             else if(this.$store.state[namecheck+'createOwner']){
                //                 return item.id === this.userLoggedIn.id
                //             }
                //         })                        
                //     }
                // }
                return header
            })
            if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'){
                let warehousefield = {
                    Name: 'Warehouse',DisplayName: 'Warehouse',id: 'Warehouse',Type: 'Lookup',text: 'Warehouse',value: 'Warehouse',propvalue: 'Warehouse',HeaderIndex: 2,RelatedBuildID: 'Warehouses',LookupFieldName: 'Name',class: 'overline',Array: this.$store.state.WarehousesArray.filter(item => {
                        return this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[item.id] && this.userLoggedIn.Warehouses[item.id].Create || this.userIsAdmin
                    })
                }
                let whfoncheck = headers.find(obj => obj.id === 'Warehouse')
                if(!whfoncheck){
                  headers.push(warehousefield)
                }                               
            }
            return headers.filter(head => {
              //console.log(head)
               return head
               //return !head.ReadOnly || head.Primary
                })
        },
      ActiveSuiteid(){
        return this.$store.state.ActiveSuiteid
      },
      roottableentities(){
        return this.$store.state.roottableentities
        },
      TableCollectionRef(){
        //this one is going to hurt we don't use TableMain exclusively for table collection view but okay
        return this.CurrentEntity ?  this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records') : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()) : ''
        //return db.collection(this.TableName.split(' ').join('').toLowerCase())
      },
      TableEntryPath(){
        return this.CurrentEntity.SingleName.split(' ').join('')
      },
      EntityCollectionRef(){
        return db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.id)
      },
      MasterTableObj(){

      },
      tab(){
        return {}
      },
      // 'tab',
      // 'RelatedObj',
       SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
      StatusField(){
            if(this.FinalTableEntity){
                return this.FinalTableEntity.AllFields.find(obj => obj.id === 'Status')
            }
        },
      LookupHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Lookup'
        })
      },
      OptionSetHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Option Set' && header.id !== 'Status'
        })
      },
      NumberFieldHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Number Field'
        })
      },
      DateHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Date'
        })
      },
      
      SelectedFilterHeaders(){
        return this.FilterHeaders.filter(field => {
          return field.ActiveFilter
        })
      },
      System(){
        return this.$store.state.SystemObj
      },
      SystemConfigorRA(){
        return this.$route.meta && this.$route.meta.SystemBuilder || this.$route.meta && this.$route.meta.RecordisSystem
      },
      FinalTableEntity(){
        return this.IsRelatedTable ? this.SystemEntities.find(obj => obj.id === this.tab.Entityid) : this.CurrentEntity
      },
       IsRelatedTable(){
            return this.tab.Entityid !== this.CurrentEntity.id && this.$route.params.id && this.tab.RelationshipField
        },
      
      DataFiltered(){
        return this.DataSearched
        .map(item => {
          if(this.CashLoanEntity){
            let today = new Date()
            let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
            weekfromnow.setHours(0,0,0,0)
            let uninvoicedpackagelines = item.Package.Line_Items.filter(lineitem => {
              return !lineitem.Invoice_Number
            })
            if(uninvoicedpackagelines[0]){
              let duedate = uninvoicedpackagelines[0].Due_Date
              if(duedate.toDate){
                duedate = duedate.toDate()
              }
              if(duedate <= weekfromnow){
                item.Next_Invoice_Due = true
                item.notifications++
              }
              else{
                item.Next_Invoice_Due = false
              }
            }
          }
          return item
        })
        .map(item => {
          item.FilterMatch = true
          //console.log('this.OptionSetsFilterFields',this.OptionSetsFilterFields)
          if(this.OptionSetsFilterFields && this.OptionSetsFilterFields.length > 0){
            this.OptionSetsFilterFields.map(header => {
              //console.log('header',header)
              if(header.ActiveFilter && header.ActiveFilter.ID){
                if(!item[header.id] || item[header.id] && item[header.id].ID !== header.ActiveFilter.ID){
                  item.FilterMatch = false
                }
              }
              else if(header.Type === 'Lookup' && header.ActiveFilter && header.ActiveFilter.id){
                if(!item[header.id] || item[header.id] && item[header.id].id !== header.ActiveFilter.id){
                  item.FilterMatch = false
                }
              }
            })
          }
          if(this.ParentOptionSetsFilterFields && this.ParentOptionSetsFilterFields.length > 0){
            this.ParentOptionSetsFilterFields.map(header => {
              let parentfield = this.CurrentEntityFields.find(obj => obj.id === header.RelatedRecord.RelationshipField.id)
              let parentrecord = ''
              //console.log('parentfield',parentfield)
              if(parentfield && item[parentfield.id]){
                parentrecord = parentfield.Array.find(obj => obj.id === item[parentfield.id].id)
              }
              if(header.ActiveFilter && header.ActiveFilter.ID){
                if(!parentrecord || parentrecord && !parentrecord[header.id]
                || parentrecord && parentrecord[header.id] && parentrecord[header.id].ID !== header.ActiveFilter.ID){
                  item.FilterMatch = false
                }
              }
              else if(header.Type === 'Lookup' && header.ActiveFilter && header.ActiveFilter.id){
                if(!item[header.id] || item[header.id] && item[header.id].id !== header.ActiveFilter.id){
                  item.FilterMatch = false
                }
              }
            })
          }
          if(this.LookupFilterFields && this.LookupFilterFields.length > 0){
            this.LookupFilterFields.map(header => {
              if(header.ActiveFilter && header.ActiveFilter.id){
                if(!item[header.id] || item[header.id] && item[header.id].id !== header.ActiveFilter.id){
                  item.FilterMatch = false
                }
              }
            })
          }
         return item
        })
        .filter(item => {
          return item.FilterMatch
        }).filter(item => {
          if(this.StoreProductEntity && this.ActiveWarehouse){
            return item.Warehouseid === this.ActiveWarehouse.id
          }
          else{
            return item
          }
        }).filter(item => {
          if(this.ComputedQuickDashFilterField && this.ComputedQuickDashFilterField.Type === 'Lookup' && this.ComputedQuickDashFilterField.FilterID){
            return item[this.ComputedQuickDashFilterField.Name] && item[this.ComputedQuickDashFilterField.Name+'id'] === this.ComputedQuickDashFilterField.FilterID
          }
          if(this.ComputedQuickDashFilterField && this.ComputedQuickDashFilterField.Type === 'Option Set' && this.ComputedQuickDashFilterField.FilterID){
            return item[this.ComputedQuickDashFilterField.Name] && item[this.ComputedQuickDashFilterField.Name].ID  === this.ComputedQuickDashFilterField.FilterID
          }
          else if(this.ComputedQuickDashFilterField && this.ComputedQuickDashFilterField.Type === 'Checklist' && this.ComputedQuickDashFilterField.FilterID){
            return !item[this.ComputedQuickDashFilterField.Name] || 
            item[this.ComputedQuickDashFilterField.Name] && !item[this.ComputedQuickDashFilterField.Name][this.ComputedQuickDashFilterField.FilterID]
          }
          else{
            return item
          }
        })
        .filter(item => {
          if(this.QuickDashFilterItem && this.QuickDashFilterItem.ExtendedFilterField && this.QuickDashFilterItem.ExtendedFilterID && this.QuickDashFilterItem.ExtendedFilterField){
            if(this.QuickDashFilterItem.RelatedTableFilter){
              let lumatch = this.QuickDashFilterItem.RollupItems.find(obj =>obj.id === item[this.QuickDashFilterItem.DataField+'id'])
              return lumatch && lumatch[this.QuickDashFilterItem.ExtendedFilterField.Name]
            && lumatch[this.QuickDashFilterItem.ExtendedFilterField.Name].ID === this.QuickDashFilterItem.ExtendedFilterID 
            }
            else{
              return item[this.QuickDashFilterItem.ExtendedFilterField.Name]
            && item[this.QuickDashFilterItem.ExtendedFilterField.Name].ID === this.QuickDashFilterItem.ExtendedFilterID 
            }
          }
          else{
            return item
          }
        })
        .filter(item => {
          if(this.AdvancedFind){
            return this.AdvancedFindFilterItems.find(obj => obj.id === item.id)
          }
          else{
            return item
          }
        })
      },
      MultiSort(){
        return this.CurrentEntity.Default_Sort_Fields && this.CurrentEntity.Default_Sort_Fields.length > 0
      },
      MultiSortHeaders(){
        if(this.MultiSort){
          return this.CurrentEntity.Default_Sort_Fields.map(field => {
            let fieldobj = Object.assign({},this.MasterHeaders.find(obj => obj.id === field))
            console.log(fieldobj)
            return fieldobj.value
          })
        }
        else{
          return []
        }
      },
      MultiSortOrderDescending(){
        return this.MultiSortHeaders.map(header => {
          return false
        })
      },
      ComputedDataFiltered(){
        this.VerifyAdvancedFilters(elmnt.Checks).PassedRecords
      },
      DataSearched(){
        return this.TableData.filter(item => {
          if(this.search && this.CurrentEntity && this.CurrentEntity.Primary_Field_Name){
            return this.ItemPassSearch(item)
          }
          else{
            return item
          }
        })
        
      },
      
      ComputedHeaders(){
        return this.Headers.map(head => {
          let headobj = Object.assign({},head)
          return headobj
        }).concat([
          {
            text: 'Actions',
            value: 'actions',
            class: 'overline'
        }
        ])
      },
      PotentialHeaderFields(){
        return this.CurrentEntityFields.map(field => {
          return field
        })
        .filter(field => {
          if(this.FieldSearch){
            return field.DisplayName.toLowerCase().includes(this.FieldSearch.toLowerCase())
          }
          else{
            return field
          }          
        })
      },
      RASystemOpsDB(){
      if(this.NeedsRASync){
       return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities')
      }
    },
      RAOperationalDB(){
      if(this.NeedsRASync){
       return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
      }
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
        return this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.TableBuilder || 
              this.$store.state.NeedsRASync && this.SystemConfigorRA && this.tab.Entityid === 'entities'     
        },
      ImportActive(){
       return  this.userLoggedIn && this.userLoggedIn.CanImport || this.userIsAdmin
      },
      ExportActive(){
        return  this.userLoggedIn && this.userLoggedIn.CanImport || this.userIsAdmin        
      },
      ChartsActive(){

      },
      TableEditActive(){
       return this.userIsAdmin && this.ProvidedEntityid
      },
      HasCalendarView(){
        if(this.CurrentEntity){
          return this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Calendar' || this.ScheduleBookingEntity
        }
      },
      HasVCardView(){
        return this.CurrentEntity && this.CurrentEntity.HasVCardView
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New '+this.TableName : 'Edit '+this.TableName
      },
      formSubTitle(){
        return this.editedIndex === -1 ? 'Fill the form below and add a new '+this.CurrentEntity.SingleName : 'Edit the '+this.CurrentEntity.SingleName+' '+this.editedItem[this.CurrentEntity.Primary_Field_Name]
      },
      formSubTitleIcon(){
        return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'
      },
      formSubTitleIconColor(){
        return this.editedIndex === -1 ? 'green' : 'orange'
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userBUID () {
        return this.$store.getters.userBUID
      },
      userBUChildren () {
        return this.$store.getters.userBUChildren
      },
      userBUParents () {
        return this.$store.getters.userBUParents
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
      BusinessUnitsStore(){
          return this.$store.state.BusinessUnitsArray
        },
      HeadershaveOwner(){
          let ownerfield = this.Headers.find(obj => obj.id === 'Owner')
          return ownerfield
      },

      userLoggedIn () {
        return this.$store.getters.user
      },
      HeaderNames() {
      let headers = JSON.parse(JSON.stringify(this.Headers))      
      headers = headers.map(header => {
        return header.propvalue
      }).filter(header => {
        return header !== 'id'
      })
      this.Headers.map((header,i) => {
        if(header.HasTimeInput){
          let newindex = i-1+2
          headers.splice(newindex, 0, header.value+'_Time')
        }
      })
      return headers
    },
      HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
        let correctheader = header
          return correctheader
        })
      },
      ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        RouteObj(){
    return this.$route
  },
  LookupEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
        }).map(ent => {
          let entobj = Object.assign({},ent)
          return entobj
        })
      },
    },
    mounted(){
        this.$store.watch(
        state => state.TableData,
            (val)=>{
                    val.map(table => {
                       if(this.CurrentEntity && this.CurrentEntity.id.split('_').join('')+'Array' === table.Name){
                            //this.MasterData = table.Data
                            this.DataInteractionPrep()
                        }
                    })
            },
            {
                deep:true
            }
      );
    },
    watch: {
      DynamicEditItem:{
         handler: function(newvalue, oldvalue) {
          //console.log('this.DynamicEditItem',this.DynamicEditItem)
            if(newvalue !== oldvalue){
              this.editedItem = Object.assign({},this.DynamicEditItem)
            }
         },deep: true
      },
      EntitiesArray:{
         handler: function(newvalue, oldvalue) {
            if(newvalue !== oldvalue){
              //console.log(newvalue, oldvalue)
            }
            },deep: true
          },
      DefaultSystemEntities:{
         handler: function(newvalue, oldvalue) {
            if(newvalue !== oldvalue){
              //console.log(newvalue, oldvalue)
            }
            },deep: true
          },
      CurrentEntity:{
         handler: function(oldvalue, newvalue) {
            if(newvalue !== oldvalue){
              this.SetAppCurrentEntity()
            }
            },deep: true
          },
      ActiveChatRecord:{
         handler: function(oldvalue, newvalue) {
          if(newvalue !== oldvalue){
            this.$store.commit('SetActiveChatRecord',this.ActiveChatRecord)
          }          
         },deep: true
      },
      ComputedSingleBuildTabs:{
         handler: function(oldvalue, newvalue) {
          if(this.ComputedSingleBuildTabs){
            if(this.ComputedSingleBuildSections[0]){        
              let secttype = this.SectionTypes.find(obj => obj.TypeProps.includes(this.ComputedSingleBuildSections[0].DataViewName))
              //console.log(secttype)
              this.ActivateActiveSectionType(secttype)
              this.ActivateActiveTab(this.ComputedSingleBuildSections[0])
            }
          }
          },
          deep: true
      },
      StoreProductEntity(v){
        if(v){
          this.SetActiveWarehouse('')
        }
        else{
          this.SummaryView = false
          this.WarehouseCardsView = false
        }
      },
      HasCalendarView(v){
        if(v){
          this.IsCalendarView = true
        }
      },
      RouteObj: {
            handler: function(newvalue, oldvalue) {
              //console.log('RouteObj')
                if(oldvalue !== newvalue){
                  this.TableStart()
                  this.TableRestart()
                }
            },deep: true
      },
      MasterData: {
        handler: function(newvalue, oldvalue) {   
          //console.log(newvalue, oldvalue)
            if(newvalue !== oldvalue){
              this.RenderTable()           
            }    
            },
            deep: true
      },
      Headers: {
        handler: function(newvalue, oldvalue) {   
            if(newvalue !== oldvalue){
              this.SetFilterHeaders()              
            }    
            },
            deep: true
      },
      FilterHeaders: {
        handler: function(newvalue, oldvalue) { 
            if(newvalue !== oldvalue){
              this.OptionSetsFilterFields = this.FilterHeaders.filter(field => {
                  return field.Type === 'Option Set'})
                this.ParentOptionSetsFilterFields = this.FilterHeaders.filter(field => {
                  return field.Type === 'Parent Field' && field.ParentFieldObj.Type === 'Option Set'
                }).map(field => {
                  if(field.Type === 'Parent Field' && !field.Options){
                    field.Options = field.ParentFieldObj.Options
                  }
                  return field
                })
                
                //console.log('s.OptionSetsFilterFields',this.OptionSetsFilterFields)
              this.LookupFilterFields = this.FilterHeaders.filter(field => {
                  return field.Type === 'Lookup'
                }) 
            }    
            },
            deep: true
      },
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateRelevantdialog','DeactivateActiveChatRecord']   
        //console.log(this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v) && this.$route.meta && this.$route.meta.TableBuilder || 
        v && acceptedmethods.includes(v) && this.tab.Entityid === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      // alert('ooh yay!')
      this.TableStart()
      
    },
    methods:{
      RecordBubbleMethod(payload){
        this[payload.Method](payload.Record)
      },
      UpdateNewRecordQuickEdit(payload,field){
        //console.log('UpdateNewRecordQuickEdit payload,field',payload,field)
        if(field.Type === 'Date'){
          this.editedItem[field.id] = payload.DateString
          this.editedItem[field.id+'Object'] = payload.DateObject
          if(field.HasTimeInput){
            this.editedItem[field.id+'Time'] = payload.TimeResponse
          } 
          this.editedItem[field.id.split('_').join('').toLowerCase()] = payload.DateObject.getTime()
          //console.log(this.editedItem)
        }            
      },
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean) 
        },
      TableRestart(){        
          //console.log('picked change')
        //   let ent = this.CurrentEntity
        // let storequery = ent.id.split('_').join('')+'Query'
        // console.log(storequery,this.$store.state,this.$store.state[storequery])
        //this.FixEntityRules()
          this.IsBoardView = false
          this.ActiveBoard = ''
          this.quickeditstep = 1
          this.editedItem = Object.assign(this.defaultItem)
          this.editedIndex = -1
          this.QuickMode = 'RecordView'
          this.QuickDashView = false
          this.CheckLookupEntities()
          this.TableReady = false
          this.MasterHeaders = []
          this.Data = []
          if(!this.HasCalendarView){
            this.IsCalendarView = false 
          }
          if(this.ActiveQuickDashElements){
            setTimeout(() => {
              this.QuickDashView = true
                //console.log(this.QuickDashView)
                //QuickDashFilterItem = ''
            }, 20);
            
          }
          this.PrepareSuiteApp()
      },
      TableStart(){
        // let ent = this.CurrentEntity
        // let storequery = ent.id.split('_').join('')+'Query'
        // console.log(storequery,this.$store.state,this.$store.state[storequery])
        //this.FixEntityRules()
        this.$store.commit('setBuilderView','')
        if(this.HasCalendarView){
          this.IsCalendarView = true
        }
        this.SetAppCurrentEntity()//on restart, this is set by currentyentity watcher    
        this.CheckLookupEntities()//active on restart
        this.DefaultBoardCheck()//also triggered from prepareheaders and toggleboardview - restart?
        this.TableReady = false
        this.PrepareSuiteApp()
        if(this.ActiveQuickDashElements){
          this.QuickDashView = true
        }
        
      //console.log('this.$store.state',this.$store.state,this.$route.meta)
        //console.log('this.tab',this.tab)
        //console.log('this.Headers',this.Headers)
        this.SetFilterHeaders()
        let queryobj = this.$route.query
        if(this.$route.name === 'MyAccount' && !queryobj || this.$route.name === 'MyAccount' && queryobj && Object.keys(queryobj).length === 0 && queryobj.constructor === Object){      
          this.ToggleIsCalendarView()
        }
        else{
          this.STDTableView = true
        }
      },
      FixEntityRules(){
        // console.log(this.CurrentEntity)
       let CustomRolesDefault =
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ]
      let BURolesDefault = [
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
      ]
      
      if(this.CurrentEntity.datasetsecuritystructure === 'Business Unit'){
       BURolesDefault.map(role => {
        if(!this.CurrentEntity[role.Name]){
          this.EntityCollectionRef.update({
            [role.Name]: []
          })
        }
                
              })
      }
      else if(this.CurrentEntity.datasetsecuritystructure === 'Business Unit'){
       CustomRolesDefault.map(role => {
                if(!this.CurrentEntity[role.Name]){
          this.EntityCollectionRef.update({
            [role.Name]: []
          })
          
        }
              })
      }
      },
      ActivateSelectedMain(menuitem){
        this.$store.commit('SetSocialItemInteractMethodProp',menuitem) 
        this.$store.commit('SetSocialItemInteractMethod','ActivateSelectedMain')
      },
      UpdateItemStatus(){
        let NewDataObj = {
          Status: this.editedItem.Status,
          Status_Reason: this.editedItem.Status_Reason
        }
        this.TableCollectionRef.doc(this.editedItem.id).update(NewDataObj).then(updatedobj => {
          this.DeactivateStateDialog()
        })
      },
      DeactivateStateDialog(){   
        this.StateDialog = false     
        this.editedItem = Object.assign(this.defaultItem)
        this.editedIndex = -1
      },
      ActivateStateDialog(item){
        let itemobj = this.MasterData.find(obj => obj.id === item.id)
        this.editedIndex = this.MasterData.indexOf(itemobj)
        this.editedItem = Object.assign({}, itemobj)
        this.StateDialog = true
      },
      ActivateAddNewLookupDialog(field){
        let RelatedObj = {
          id: this.editedItem.id,
          [this.CurrentEntity.Primary_Field_Name]: this.editedItem[this.CurrentEntity.Primary_Field_Name],
          identifier: this.CurrentEntity.Primary_Field_Name,
          collection: this.$route.meta.Collection,
          Link: this.CurrentEntity.SingleName.split(' ').join('')
        }
        //console.log(this.$store.state.CurrentEntity)
        this.$store.commit('setRelatedRecordObj',RelatedObj) 
        this.$store.commit('SetSocialItemInteractMethodProp',field) 
        this.$store.commit('SetSocialItemInteractMethod','ActivateAddNewLookupDialog')
      },
      fieldSorter(fields) {
        return function (a, b) {
            return fields.map(field => {
              //console.log(field)
              let o = field.propvalue
            let dir = 1;
            return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
        }).reduce((p, n) => p ? p : n, 0);
        }
      },

      sortByThenBy(array, keys) {
        console.log(keys)
    return array.sort(function (a, b) {
        var r = 0;
        keys.some(function (k) {
            return r = a[k] - b[k];
        });
        return r;
    });
    },
      SetAppCurrentEntity(){
        //console.log('this.CurrentEntity',this.CurrentEntity,this.$store.state)
        this.$store.commit('SetCurrentEntity',this.CurrentEntity)
      },
      DeactivateActiveChatRecord(){
        this.ActiveChatRecord = ''
      },
      ConversationClass(record){
        if(record.notifications > 0 && this.$vuetify.theme.dark){
          return 'grey darken-2'
        }
        else if(record.notifications > 0){
          return 'grey lighten-2'
        }
      },
      ActivateRecordLiaisonmode(record){
        this.ActiveChatRecord = record
        this.QuickMode = 'QuickLiaison'
      },
      GetTemplateEntities(){
        let ent = this.SystemEntities.find(obj => obj.id === 'Entities')
        let storequery = ent.id.split('_').join('')+'Query'
        let storearrayname = ent.id.split('_').join('')+'Array'
        if(this.$store.state[storequery]){
          if(this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
              let payload = {                
              query: this.$store.state[storequery],
              arraymutation: 'set'+storearrayname,
              getter: 'get'+storearrayname,
              arrayname: storearrayname
              }
              payload.SubCollections = [
              {
                Subcolpath: 'AllFields',
                SubcolName: 'AllFields'
              },
            ]
              this.$store.dispatch('GetArrayandMultipleSubcols',payload)
          }
        }
      },
      CheckLookupEntities(){
        if(this.SystemBuilderTable){
          this.GetTemplateEntities()
          // console.log(this.DefaultSystemEntities)
        }
        this.LookupEntities.map(ent => {
                    let storequery = ent.id.split('_').join('')+'Query'
                    let storearrayname = ent.id.split('_').join('')+'Array'
                    //console.log(storequery,this.$store.state[storequery],storearrayname,this.$store.state[storearrayname])
                    if(this.$store.state[storequery]){
                      if(this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
                         let payload = {                
                          query: this.$store.state[storequery],
                          arraymutation: 'set'+storearrayname,
                          getter: 'get'+storearrayname,
                          arrayname: storearrayname
                          }
                          this.$store.dispatch('GetCollectionArray',payload)
                      }
                    }
                  })
      },
      DefaultBoardCheck(fromtoggle){
        //console.log('board check')
        if(this.BoardOptions && this.BoardOptions.length > 0){          
          if(this.CurrentEntity.Default_Board){            
            let board = this.BoardOptions.find(obj => obj.id === this.CurrentEntity.Default_Board)
            if(board){
            //console.log('has options',this.ActiveBoard)
                this.ActiveBoard = board
                if(!fromtoggle){
                this.IsBoardView = true
                }
            }
            else{
              this.ActiveBoard = this.BoardOptions[0]
            }
          }
          else{
            this.ActiveBoard = this.BoardOptions[0]
          }
        }
        else{
          this.ActiveBoard = ''
        }
        
      },
      ToggleBoardView(){
        this.IsBoardView = !this.IsBoardView
        this.IsLiaisonView = false
        this.DefaultBoardCheck(true)
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
       ActivateActiveSectionType(secttype){
        this.ActiveSectionType = secttype
        //console.log(this.ComputedSingleBuildSections)
        let sect = this.ComputedSingleBuildSections.find(obj => secttype.TypeProps.includes(obj.DataViewName))
        if(sect){
          this.ActivateActiveTab(sect)
        }        
      },
      ActivateActiveTab(section){
        if(section.DataViewName === 'Data Single SubCollection'){
          this.ActiveSectionType = {Name: 'SubCollections',TypeProps: ['Data Single SubCollection'],Icon: 'mdi-database-arrow-down-outline',Color: 'blue'}
        }
        this.ActiveTab = section.TabObj
        this.ActiveTabIndex = section.TabIndex
        this.ActiveSection = section
        this.ActiveSectionIndex = section.ElmntIndex
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      ActivateProcessing(bool){
        this.$emit('ActivateProcessing',bool)
      },
      ToggleQuickMode(Name){
        this.QuickMode = Name
        this.ScrolltoTop()
      },
      UpdateSearch(search){
        this.search = search
      },
      ResetSearch(){
            this.search = ''
        },
      ViewQuickDash(){
        this.AdvancedFind = false
        this.AdvancedFindFilters = []
        this.AdvancedFindFilterItems = []
        this.QuickDashView = !this.QuickDashView
        this.QuickDashFilterItem = ''
      },
      ClearAdvancedFilter(){
        this.AdvancedFind = false
        this.AdvancedFindFilters = []
        this.AdvancedFindFilterItems = []
      },
      CheckforQuickDashPlugin(){
        if(this.AppQuickDashPlugin){
          this.ToggleQuickDashView()
        }
        else{
          let plug = this.RAPlugins.find(obj => obj.id === 'Quick_Dashboards')
          this.$store.commit('SetAlertSound','error_sound')
          this.$store.commit('setConfirmMSGTitle',`Not Installed!`)
          this.$store.commit('setConfirmMSGIcon',`mdi-alert`)
          this.$store.commit('setConfirmMSGIconColor',`red`)
          this.$store.commit('setConfirmMSGText',`<p style="text-align: center;">You would need to install the Quick Dashboard plugin in order to use this feature.</p>`)
          this.$store.commit('setConfirmMSGDialog',true)
          //first purchase plugin
        }
      },
      SetQuickDashbtoTemplate(templateid,array){
        let path = ''
        if(!this.SystemisRA){
          path = this.RADB.collection('entities').doc(templateid)
        }
        else{
          path = db.collection('entities').doc(templateid)
        }
        //console.log(path)
          path.update({
            QuickDashElements: array
          }).then(updatedradoc => {
          })
      },
      SaveQuickDashboard(array){
        if(!this.SystemisRA){
          this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id).update({
            QuickDashElements: array
          }).then(updatedradoc => {
            db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).update({
              QuickDashElements: array
            })
          })
        }
        else{
           db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).update({
              QuickDashElements: array
            })
        }
        
      },
      ToggleQuickDashView(){
        this.QuickDashView = !this.QuickDashView
      },
      PerformedCalculation(Record,field){
            //console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map((formula,i) => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            console.log(formula.integer)
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = Record[formulas[i-1].fieldName.Name]*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                            console.log(formula.integer)
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
      ReduceQuickStep(){
        let step = this.quickeditstep
        this.quickeditstep--
        if(step === 3){
          console.log('was 3')
          this.ActiveSubCollection = ''
          this.ActiveSubcolHeaders = []
          this.SubColPrimaryField = ''
          this.SubColRecord = ''
        }        
      },
      UpdateBookedUser(user){
        let bookingobj = user.BookingObj
        //actually verify if changes otherwise you update nothing but modified by for now reason as it was the only midificaiton - the data modified lol
        let updateobj = {
          Modified_On: new Date(),
          Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Modified_Byid:  this.userLoggedIn.id, 
        }
        this.ActiveSubcolHeaders.map(header => {
          if(typeof header.Response !== 'undefined'){
            updateobj[header.Name] = header.Response
          }
        })
        this.TableCollectionRef.doc(this.editedItem.id).collection('BookedUsers').doc(user.id).update(updateobj).then(updatedbooking => {
          this.ReduceQuickStep()
        })
      },
      OpenUserSubcolDialog(user){
        //console.log(user)
        if(this.ScheduleBookingEntity){
          this.SubColRecord = Object.assign({},user)
          this.ActiveSubCollection = this.CurrentEntity.SubCollections[0]
          let LinkedFields = this.ActiveSubCollection.LookupBuilds[0].LinkedFields
          this.ActiveSubcolHeaders = this.ActiveSubCollection.Headers.map(head => {
            let headobj = Object.assign({},head)
            if(typeof this.SubColRecord[head.Name] !== 'undefined'){
              headobj.Response = this.SubColRecord[head.Name]
            }
            if(LinkedFields[head.Name]){
              headobj.ReadOnly = true
              }
            //and in fact if field is in  "LinkedFields" it must become readonly
            return headobj
          })
          this.SubColPrimaryField = this.ActiveSubCollection.Headers[0]
          this.quickeditstep = 3
        }
      },
      ActiveComponent(ComponentPath){
            return () => import(`@/`+ComponentPath+`.vue`);	 
        },
      PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = Object.assign({},this.CurrentEntityFields.find(obj => obj.id === field))
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.editedItem[fieldobj.Name] !== 'undefined' && typeof this.editedItem[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.editedItem[fieldobj.Name].toDate()
                this.editedItem[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            if(fieldobj.Type === 'Calculated Field'){
              this.editedItem[fieldobj.Name] = this.PerformedCalculation(this.editedItem,fieldobj)
            }
            return fieldobj
          })
      },
      SelectImage(tab, tapprop, asset, IsThis,SelectName){
        //console.log(tab, tapprop, asset, IsThis,SelectName)
        if(SelectName === 'Set Primary'){
          this.SelectPrimaryImage(asset)
        }
      },
      SelectPrimaryImage(asset){
        if(this.PrimaryImageFieldName){
          this.editedItem[this.PrimaryImageFieldName] = Object.assign({},asset)
          this.editedItem[this.PrimaryImageFieldName+'ThumbURL'] = asset.ThumbURL
        }
        this.quickeditstep = 1
        setTimeout(() => {
          this.quickeditstep = 2  
        }, 1);
              
        //console.log(this.editedItem)
      },
      AddGallerySubcolImages(event,item,subcolid){ 
      let fileList = event.target.files || e.dataTransfer.files
      this.$store.commit('setUploadFilesList',fileList)
      let sectionstorageref = subcolid.split(' ').join('')+'/'+item.id+'/'
      let basestorageref = this.TableNameSplit+'/'+item.id+'/'
      let filestorageref = basestorageref+sectionstorageref
      let dbrecordref = this.TableCollectionRef.doc(item.id).collection(subcolid.split(' ').join('').split('_').join('').toLowerCase())
      this.$store.commit('setUploadFileStorageRef',filestorageref)
      this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
      },
      UploadFileSelect(event,field,Record){
        let file = event.target.files[0]
        Record[field.Name] = {UploadFile: file, FileName: file.name,localURL: URL.createObjectURL(file)}
        this.quickeditstep = 1
        setTimeout(() => {
          this.quickeditstep = 2  
        }, 10);
        },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      OpenEventEditor(event){
        this.editedItem = Object.assign({},event)
        if(event.id){
          this.editedIndex = 10000
          this.QuickMode = 'QuickEdit'
          this.quickeditstep = 2
        }
        else{
          //console.log('this.editedItem',this.editedItem,event)
          this.editedIndex = -1
          this.QuickMode = 'QuickEdit'
          this.quickeditstep = 2
          this.ScrolltoTop()
          //this.ActivateRelevantdialog(true)
          //really the trick is QuickEditSections reinstate in this case
        }
        
        //this.editItem(this.editedItem)
        // console.log(this.editedItem)
        // this.scheddialog = true
      },
      WarehouseProductCounters(wh){
        let data = this.MasterData.filter(item => {
          return item.Warehouseid === wh.id
        })
        return [{CounterIcon: 'mdi-warehouse',Data: data,CounterInteger: data.length, Title: wh.Name,Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#DD2C00'},FontColor: {hex: '#DD2C00'}}]
      },
      QuickDashboardInteract(elmnt,closedashonly){
      //console.log(elmnt,closedashonly)
        if(closedashonly){
          this.QuickDashView = false
        }
        else{
          if(elmnt.QuickDashType === 'QuickAlerts'){
            if(elmnt.Results){
            this.AdvancedFindFilters = elmnt.Checks
            this.AdvancedFindFilterItems = elmnt.Results.PassedRecords
            this.AdvancedFind = true
            }
            else{
              this.QuickDashView = false
            }
          }
          else{            
          this.QuickDashFilterItem = elmnt
          }
          //first assign filter obj, then close dashb view
          this.QuickDashView = false
          if(this.QuickDashFilterItem.FilterbyRelatedChildTable && this.QuickDashFilterItem.RelatedChildTableData){
            this.AdvancedFind = true
            this.AdvancedFindFilterItems = this.QuickDashFilterItem.RelatedChildTableData
          }
          if(this.QuickDashFilterItem && this.QuickDashFilterItem.ExtendedFilterField){
           let board = this.BoardOptions.find(obj => obj.id === this.QuickDashFilterItem.ExtendedFilterField.id)
          if(board){
            this.ActiveBoard = board
            this.IsBoardView = true
          } 
          }
        }
      },
      SetActiveWarehouse(wh){
            this.$store.commit('setActiveWarehouse',wh)
        },
      PrepareSuiteApp(){
        if(!this.ActiveSuiteApp && this.CurrentEntity.Primary_Appid){
          let app = this.$store.state.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid)
          if(app){
            this.$store.commit('setActiveSuiteApp',app)
            this.PrepareHeaders()
          }
        }
        else if(!this.ActiveSuiteApp && this.CurrentEntity.AssignedApps){
          let app = this.$store.state.AppsDataBase.find(obj => this.CurrentEntity.AssignedApps.includes(obj.id) && this.userLoggedIn.Apps.includes(obj.id))
          if(app){
            this.$store.commit('setActiveSuiteApp',app)
            this.PrepareHeaders()
          }
        }
        else{
          this.PrepareHeaders()
        }
      },
      PrepareHeaders(){
           let entity = this.CurrentEntity
                //console.log(this.TableEntity)
            this.MasterHeaders = entity.AllFields.filter(field => {
                return field.IsHeader
            }).map((header,headerindex) => {
                header.RelatedReadOnly = false
                header.text = header.DisplayName,
                header.propvalue = header.Name
                header.value = header.Name,
                header.class = 'overline'
                if(header.Primary){
                  header.value = 'Primary_Field_Value'
                }
                else if(header.Type === 'Parent Field' && header.ParentFieldObj.Type === 'Option Set'){
                  header.value = header.Name+'.Name'
                }
                else if(header.Type === 'Option Set'){
                    
                    header.value = header.Name+'.Name'
                    if(header.DefaultOption){
                        header.Response = header.DefaultOption
                    }
                    //console.log('header Option Set',header)
                    if(header.HasDeepHierarchy && header.Levels && header.Levels[1] && header.DefaultLevel2Option){
                        header.Level1Response = header.DefaultLevel2Option
                    }
                }
                else if(header.Type === 'Lookup' || header.Type === 'Social Lookup'){
                  header.value = header.Name+'.'+header.LookupFieldName
                    header.Array = []
                    if(header.RelatedBuildID === 'Users'){
                        header.Array = this.UsersArray
                        //console.log(header.id,header.Array)
                    }
                    else{
                        let storearrayname = header.RelatedBuildID.split('_').join('')+'Array'
                        if(!this.$store.state[storearrayname] || this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
                            let vm = this
                            
                            let storequery = header.RelatedBuildID.split('_').join('')+'Query'
                            if(vm.$store.state[storequery]){
                                if(vm.IsRelatedTable && vm.tab.RelationshipField.Name === header.Name){
                                    header.Array.push(vm.tab.RelationshipField.RecordData)
                                    header.Response = vm.tab.RelationshipField.RecordData
                                    header.RelatedReadOnly = true
                                    
                                }                                
                                else{
                                  let ent = this.SystemEntities.find(obj => obj.id === header.RelatedBuildID)
                                    let payload = {                
                                    query: this.$store.state[storequery],
                                    arraymutation: 'set'+storearrayname,
                                    getter: 'get'+storearrayname,
                                    arrayname: storearrayname
                                    }
                                   if(ent.Entity_Type.Name === 'Inventory Article'){
                                    payload.SubCollections = [
                                      {
                                        Subcolpath: 'gallery',
                                        SubcolName: 'Gallery'
                                      },
                                    ]
                                    console.log('GetArrayandMultipleSubcols')
                                    vm.$store.dispatch('GetArrayandMultipleSubcols',payload).then(function(result) {
                                      header.Array = vm.$store.state[storearrayname]
                                    })
                                  }
                                  else if(ent.Entity_Type.Name === 'System Builder'){
                                    payload.SubCollections = [
                                      {
                                        Subcolpath: 'plugins',
                                        SubcolName: 'Plugins'
                                      },
                                      {
                                        Subcolpath: 'apps',
                                        SubcolName: 'Apps'
                                      },
                                    ]
                                     vm.$store.dispatch('GetArrayandMultipleSubcols',payload).then(function(result) {
                                      header.Array = vm.$store.state[storearrayname]
                                    })
                                  }
                                  else{
                                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
                                        header.Array = vm.$store.state[storearrayname]
                                    })
                                  }
                                }                                
                            }
                        }
                        else if(this.$store.state[storearrayname]){
                            header.Array = this.$store.state[storearrayname]
                            .filter(item => {
                                if(this.IsRelatedTable && this.tab.RelationshipField.Name === header.Name){
                                    return item.id === this.$route.params.id
                                }
                                else{
                                return item  
                                }                                
                            })
                            if(this.IsRelatedTable && this.tab.RelationshipField.Name === header.Name){
                                header.ReadOnly = true
                                header.Response = header.Array[0]
                            }
                        }
                    }
                    if(header.IsMultiple){
                        header.Array = header.Array.map(item => {                      
                                let itemobj = {
                                [header.LookupFieldName]: item[header.LookupFieldName],
                                id: item.id
                                }
                                return itemobj
                            })
                        }
                }
                return header
            })
            .map((header,headerindex) => {
              
              if(typeof header.HeaderIndex !== 'number'){
                header.HeaderIndex = 1000+headerindex
              }
              //console.log(header.id,header.HeaderIndex,typeof header.HeaderIndex)
              return header
            }).sort(function(a, b){return a.HeaderIndex - b.HeaderIndex})
            // let primaryheader = this.MasterHeaders.find(obj => obj.value === this.PrimaryField) 
            // let currentprimaryindex = this.MasterHeaders.indexOf(primaryheader)
            // this.MasterHeaders.splice(0,0,this.MasterHeaders.splice(currentprimaryindex,1)[0])
            this.InitiateTable()
            this.DefaultBoardCheck()
        },
      RenderTable(){
        //console.log('rendering table update',this.MasterData)
        let lookupfields = this.MasterHeaders.filter(field => {
          return field.Type === 'Lookup' && field.IsHeader
        })
        let parentfields = this.MasterHeaders.filter(field => {
          return field.Type === 'Parent Field' && field.IsHeader
        })
        this.Data = this.MasterData
        this.Data = this.Data.map(item => {
          lookupfields.map(field => {
            if(item[field.id] && field.Array){
              let matchobj = field.Array.find(obj => obj.id === item[field.id].id)
              if(matchobj){
                item[field.id] = matchobj
              }
            }
          })
          return item
        }).map(item => {
          parentfields.map(field => {
            let parentfield = this.CurrentEntityFields.find(obj => obj.id === field.RelatedRecord.RelationshipField.id)
            let parentrecord = ''
            if(parentfield && item[parentfield.id]){
              parentrecord = item[parentfield.id]
              if(parentrecord && parentrecord[field.id]){
                item[field.id] = parentrecord[field.id]
              }
            }
          })
          return item
        })
        //console.log(this.Data,this.MasterData)
        this.LookupHeadersPrep()
        this.DataInteractionPrep()
        this.TableReady = true
      },
      InitiateTable(){
        let vm = this
        let storearrayname = this.TableNameSplit+'Array'
        let storequery = this.TableNameSplit+'Query'
        if(this.MasterData && this.MasterData.length === 0){
          //console.log('no store')
          if(this.$store.state[storequery]){
            let payload = {                
            query: vm.$store.state[storequery],
            arraymutation: 'set'+storearrayname,
            getter: 'get'+storearrayname,
            arrayname: storearrayname
            }
            if(vm.CurrentEntity.Entity_Type.Name === 'Inventory Article'){
              payload.SubCollections = [
                {
                  Subcolpath: 'gallery',
                  SubcolName: 'Gallery'
                },
              ]
              //console.log('GetArrayandMultipleSubcols')
              vm.$store.dispatch('GetArrayandMultipleSubcols',payload).then(function(result) {
                //vm.RenderTable()
              })
               vm.RenderTable()
            }
            else if(vm.CurrentEntity.Entity_Type.Name === 'System Builder'){
              payload.SubCollections = [
                {
                  Subcolpath: 'plugins',
                  SubcolName: 'Plugins'
                },
                {
                  Subcolpath: 'apps',
                  SubcolName: 'Apps'
                },
              ]
              //console.log('GetArrayandMultipleSubcols')
              vm.$store.dispatch('GetArrayandMultipleSubcols',payload).then(function(result) {
                //vm.RenderTable()
              })
               vm.RenderTable()
            }
            else{
              vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                vm.RenderTable()
              })
            }
            
          }
          else{
            vm.RenderTable()
            //console.log("really yo don't have access")
          }
       }
        else{
          vm.RenderTable()
          //done 
        }
      },
      LookupHeadersPrep(){
        let vm = this
        vm.MasterHeaders = vm.MasterHeaders.map(header => {
                        if(header.Type === 'Lookup' || header.Type === 'Social Lookup'){
                          header.Array = []
                            //console.log('header is lookup',header,vm.CurrentEntity,vm.$store.state)
                            let headerarray = vm.$store.state[header.RelatedRecord.split(' ').join('')+'Array']
                            //console.log(header.RelatedRecord.split(' ').join('')+'Array',headerarray)
                            //console.log(headerarray,header.RelatedRecord+'Query',vm.$store.state[header.RelatedRecord+'Query'])
                            if(!headerarray || headerarray && headerarray.length === 0){
                                if(vm.$store.state[header.RelatedRecord+'Query']){
                                    //console.log('here is lookup query for empty array ',vm.$store.state[header.RelatedRecord+'Query'])
                                    let lookuppayload = {                
                                    query: vm.$store.state[header.RelatedRecord+'Query'],
                                    arraymutation: 'set'+header.RelatedRecord+'Array',
                                    getter: 'get'+header.RelatedRecord+'Array',
                                    arrayname: header.RelatedRecord+'Array'
                                    }
                                    let luentity = vm.SystemEntities.find(obj => obj.id === header.RelatedBuildID)
                                    if(luentity && luentity.Entity_Type.Name === 'Inventory Article'){
                                      lookuppayload.Subcolpath = 'gallery',
                                      lookuppayload.SubcolName = 'Gallery'
                                      vm.$store.dispatch('GetArrayandSubcols',lookuppayload).then(function(result) {
                                        header.Array = headerarray
                                      })
                                    }
                                    else{
                                    vm.$store.dispatch('GetCollectionArray',lookuppayload).then(function(result) {
                                      header.Array = headerarray
                                    })

                                    }
                                }
                                
                            }
                            else{
                                header.Array = headerarray
                            }
                            if(vm.CurrentEntity.Security_Structure.Name === 'Business Unit'){
                                if(header.RelatedBuildID === 'Business_Units'){
                                    let entityprop = vm.CurrentEntity.DisplayName.split(' ').join('')
                                    header.Array = header.Array.filter(item => {
                                        //console.log('vm.$store.state BU field',vm.$store.state)
                                        return vm.$store.state[entityprop+'createAll'] || 
                                        vm.$store.state[entityprop+'createUnitDown'] && vm.userBUChildren.includes(item.id) || 
                                        vm.$store.state[entityprop+'createUserUnit'] && vm.userBUID === item.id || 
                                        vm.$store.state[entityprop+'createOwner'] && vm.userBUID === item.id || vm.userIsAdmin
                                    })
                                }
                                else if(header.RelatedBuildID === 'Users'){
                                    let entityprop = vm.CurrentEntity.DisplayName.split(' ').join('')
                                    header.Array = this.UsersArray.filter(item => {
                                        return vm.$store.state[entityprop+'createAll'] || 
                                        vm.$store.state[entityprop+'createUnitDown'] && vm.userBUChildren.includes(item.Business_Unitid) || 
                                        vm.$store.state[entityprop+'createUserUnit'] && vm.userBUID === item.Business_Unitid || 
                                        vm.$store.state[entityprop+'createOwner'] && vm.userLoggedIn.id === item.id || vm.userLoggedIn && item.id === vm.userLoggedIn.id && vm.userIsAdmin
                                    })
                                }
                            }                           
                            
                        }
                        return header
                    })
    },
    DataInteractionPrep(){
      //console.log('DataInteractionPrep',this.TableNameSplit,this.$store.state)
            //So below I have the structure I must interrogate. 
            //I get the security structure from router. But perhaps t's even eaier to get from the storeconfig docs, for future modularity on router
            //go it, must call tablesecuritystructure
            let tablesecuritystructure = this.CurrentEntity.Security_Structure.Name
                if(tablesecuritystructure === 'Warehouse'){
                    this.Data = this.GetWarehouseTableInteraction(this.MasterData)
                    if(this.userLoggedIn.Warehouses){
                        for(var prop in this.userLoggedIn.Warehouses) {
                            if (this.userLoggedIn.Warehouses.hasOwnProperty(prop)) {
                                if(this.userLoggedIn.Warehouses[prop].Create || this.userIsAdmin){
                                 this.CanCreate = true
                                }
                            }
                        }
                        
                    }
                }
                else if(tablesecuritystructure === 'Custom Roles'){
                    this.Data = this.GetCustomRolesTableInteraction(this.MasterData)
                     this.CanCreate = this.$store.state[this.TableNameSplit+'CanCreate']                   
                }
                else if(tablesecuritystructure === 'Business Unit'){
                    this.Data = this.GetBUTableInteraction(this.MasterData)
                    let CreateRules = [{Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
                    {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
                    {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
                    {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},]
                    CreateRules.map(rule => {
                        if(this.$store.state[this.TableNameSplit+rule.Boolean]){
                           this.CanCreate = true
                        }
                    })
                }
        },
        GetWarehouseTableInteraction(Data){
            let rules = [
                {rule: 'CanCreate',prop: 'Create'},
                {rule: 'CanView',prop: 'Get'},
                {rule: 'CanEdit',prop: 'Update'},
                {rule: 'CanDelete',prop: 'Delete'}
            ]
            return Data.map(data => {
                rules.map(rule => {
                    if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[data.Warehouseid] || this.userIsAdmin){
                        data[rule.rule] = this.userLoggedIn.Warehouses[data.Warehouseid][rule.prop]
                    }
                    else{
                        data[rule] = this.userIsAdmin    
                    }                    
                })
                return data                        
            })
        },
        GetCustomRolesTableInteraction(Data){
            let rules = [
                'CanCreate',
                'CanView',
                'CanEdit',
                'CanDelete'
            ]
            return Data.map(data => {
                rules.map(rule => {
                    data[rule] = this.$store.state[this.TableNameSplit+rule]
                    
                })
                return data                        
            })
        },
        GetBUTableInteraction(Data){
            
            let rules = this.$store.state.BURoles
            return Data.map(data => {
                rules.map(rule => {
                    //console.log(rule.PermissionLevel,rule.Action,this.TableNameSplit+rule.Boolean,this.$store.state[this.TableNameSplit+rule.Boolean],data)
                    if(rule.PermissionLevel === 4){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean]){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 3){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean] && this.userBUChildren.includes(data.Business_Unitid)){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 2){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean] && data.Business_Unitid === this.userBUID){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 1){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean] && data.Ownerid === this.userLoggedIn.id){
                            data[rule.Action] = true          
                        } 
                    }
                    
                })
                return data                        
            })
        },
      LookupObjRoutetoQuery(LookupObj){
        let newroute = { path: LookupObj.RoutePath, query: LookupObj.RouteQuery}
        this.$router.push(newroute)
      },
      DeactivateSubColLookupDialog(){
        this.SubColLookupDialog = false
      },
      PerformCalulation(Record,field,IsRollup){
            //console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map(formula => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = formula.integer*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
      ItemPassSearch(item){
        let pass = false
        let searchfields = this.CurrentEntityFields.filter(field => {
          return field.IsSearch
        })
        //console.log('searchfields',searchfields)
        if(item[this.CurrentEntity.Primary_Field_Name] && item[this.CurrentEntity.Primary_Field_Name].toLowerCase().includes(this.search.toLowerCase())){
          pass = true
        }
        else{
          searchfields.map(field => {
              if(field.Type === 'Single Line Text' || field.Type === 'Multiple Lines Text'){
                if(item[field.Name] && item[field.Name].toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
              else if(field.Type === 'Option Set'){
                if(item[field.Name] && item[field.Name].Name.toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
              else if(field.Type === 'Lookup'){
                if(item[field.Name] && item[field.Name][field.LookupFieldName].toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
          })
        }
        return pass
        
      },
      SetFilterHeaders(){
        //console.log('Headers for FilterHeaders',this.Headers)
              this.FilterHeaders = this.Headers.filter(field => {
                return field.IsFilter
              }).map(field => {
                let fieldobj = Object.assign({},field)
                return fieldobj
              })
      },
      GetDataCountbyStatus(item){
        return this.DataFiltered.filter(record => {
          return record.Status && record.Status.ID === item.ID
        }).length
      },
      ValidateForm(){
        this.Validating = false
        setTimeout(() => {
          this.Validating = true
        }, 50);
      },
      ResetFilters() {
        this.FilterHeaders.map(field => {
          field.ActiveFilter = ''
        })
       
      },
      ViewMapItem(FieldBreakdown,item) {
        let combinedlink = ''
        FieldBreakdown.map((breakdown,i) => {
            combinedlink = combinedlink+item[breakdown.Name]
            if(i-1+2 < FieldBreakdown.length){
                combinedlink = combinedlink+'+'
            }
        })
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        
        window.open(FinalLink, '_blank')
      },
      HeaderPhysicalAddress(item,header){
        //console.log('HeaderPhysicalAddress(item,header)',item,header)
        let boolean = true
        if(header.Type === 'Common Field' && header.CommonFieldType === 'Physical Address'){
          header.FieldBreakdown.map(breakdownfield => {
          if(!item[breakdownfield.Name]){
            boolean = false
          }
        })
        }
        else{
          boolean = false
        }
        return boolean
      },
      UpdateRelated(field,RelatedBuildID){
            let entity = this.SystemEntities.find(obj => obj.id === RelatedBuildID)
            if(entity){
                field.RelatedLocation = entity.SingleName
                field.RelatedRecord = entity.DisplayName
                field.LookupFieldName = entity.RecordPrimaryField.Name
            }
        },
      UpdateField(field,prop,value){
            field[prop] = value
            if(!this.AddingNewField){
                
            }
            //console.log(field)
        },
      RefreshComponent(){
        this.EditingTable = false
        this.$emit('RefreshComponent',true)
      },      
      SaveHeaderUpdates(){ 
        let length = this.CurrentEntityFields.length
        this.CurrentEntityFields.map((field,index) => {
          let fieldobj = Object.assign({},field)
          delete fieldobj.CanEdit
          delete fieldobj.CanDelete
          delete fieldobj.Response
          delete fieldobj.Array
          delete fieldobj.MappedValues
          let onheaderlist = this.Headers.find(obj => obj.Name === fieldobj.Name)
          //console.log(onheaderlist,this.PotentialHeaderFields)
          if(onheaderlist){            
            fieldobj.text = onheaderlist.text
            fieldobj.value = onheaderlist.value
            fieldobj.IsHeader = onheaderlist.IsHeader
            fieldobj.HeaderIndex = onheaderlist.HeaderIndex
          }
          if(fieldobj.value !== 'action' && fieldobj.value !== 'actions' && fieldobj.value !== 'LookupObj'){
            if(typeof fieldobj.IsHeader === 'undefined'){
              fieldobj.IsHeader = false
            }
            if(fieldobj.IsHeader === false){
              fieldobj.HeaderIndex = ''
            }
            if(typeof fieldobj.HeaderIndex === 'undefined'){
              fieldobj.HeaderIndex = ''
            }
            if(typeof fieldobj.value === 'undefined'){
              fieldobj.value = ''
            }
            if(typeof fieldobj.text === 'undefined'){
              fieldobj.text = ''
            }
            if(this.NeedsRASync){                    
              this.RAOperationalDB.collection('AllFields').doc(fieldobj.Name).update({
                IsHeader: fieldobj.IsHeader,
                value: fieldobj.value,
                text: fieldobj.text,
                HeaderIndex: fieldobj.HeaderIndex,
                }).then(radoc => {
                this.EntityCollectionRef.collection('AllFields').doc(fieldobj.Name).update({
                IsHeader: fieldobj.IsHeader,
                value: fieldobj.value,
                text: fieldobj.text,
                HeaderIndex: fieldobj.HeaderIndex,
                }).then(doc => {
                  if(index-1 === length){
                    this.RefreshComponent()
                  }                    
                }) 
              })
            }
            else{
              this.EntityCollectionRef.collection('AllFields').doc(fieldobj.Name).update({
                IsHeader: fieldobj.IsHeader,
                value: fieldobj.value,
                text: fieldobj.text,
                HeaderIndex: fieldobj.HeaderIndex,
                }).then(doc => {
                  if(index-1 === length){
                    this.RefreshComponent()
                  }                    
                }) 
            }            
          }
        })
      },
      UpdateHeaders(item){
        //console.log(item,this.MasterHeaders)
        if(item.IsHeader){
          let index = this.MasterHeaders.length
          let newfield = Object.assign({},item)
          newfield.text = newfield.DisplayName
          newfield.class = ' overline'
          newfield.HeaderIndex = index
          newfield.value = newfield.Name
          newfield.propvalue = newfield.value
          if(newfield.Type === 'Option Set'){
            newfield.value = newfield.value+'.Name'
          }
            newfield.propvalue = newfield.value
            this.MasterHeaders.push(newfield)
        }
        else{
          if(!item.Primary){
           let indexobj = this.MasterHeaders.find(obj => obj.Name === item.Name)
           let index = this.MasterHeaders.indexOf(indexobj)
            this.MasterHeaders.splice(index,1)
            this.MasterHeaders.map((header,headerindex) => {
              header.HeaderIndex = headerindex
            }) 
          }
          
        }        
        //this.PrepareHeaders()
      },
      
      DownloadImportTemplate(){
      let copyText = this.HeaderNamesSplit

      var csvfilename = this.CurrentEntity.DisplayName+' Import Template.csv'
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
      element.setAttribute('download', csvfilename)
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      // }
    },
    CloseImportresultsdialog(){
      this.importresultsdialog = false
    },
    onImportfileSelected(event){
      this.ImportFile = event.target.files[0]
    },
      closeImport(){
      this.importdialog = false
      this.ImportFile = null
      this.Import.Name = ''
    },

    saveImport() {
      this.ImportingProgressDialog = true
      setTimeout(() => {
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
    }, 200);
      this.Importcount = 'Preparing Import'
      let importLocalURL = URL.createObjectURL(this.ImportFile)
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this
      reader.addEventListener('load', function(e) {
            var text = e.target.result;
            vm.CSVConvertToJSON(text)
      });
    },


    CheckDelimiter(lines){
      console.log(lines)
      let headertest = lines[0].split("|");
      if(headertest.length ===1){
        this.ConversionCSVtoJJSON(lines,'|')
      }
      else{
        this.ConversionCSVtoJJSON(lines, '|')
      }
      console.log(lines,headertest)
      //console.log('headertest')
      //console.log(headertest)
    },
    ConversionCSVtoJJSON(lines,delimiter){
      let dataonly = Object.assign([],lines)
      dataonly.splice(0,1)
      //console.log(dataonly)
      this.CSVResult = []
      this.ImportHeaders=lines[0].split(delimiter);
      //console.log('this.ImportHeaders')
      //console.log(this.ImportHeaders)
      let finalindex = this.ImportHeaders.length-1
    let finalheader = this.ImportHeaders[finalindex]
    console.log(finalheader)
    if(finalheader.includes('id')){
      // if(finalheader === 'id'){
      this.Reimport = true
      confirm('We picked up this is a Reimport. Shall you continue?') && this.ProceedReimport(lines,delimiter)
    }
    else{
      for(var i=1;i-1+2<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
      this.CSVResult.push(obj);
      //console.log("this.CSVResult length is "+this.CSVResult.length)


    }
    this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
      this.CSVResult.map((res,i) => {
        
        this.UpdateFiresStore(res,i)
        
      })
    }
    },
    ProceedReimport(lines,delimiter){
    for(var i=1;i-1+2<lines.length;i++){
        var obj = {};
        var currentline=lines[i].split(delimiter);
        
        for(var j=0;j<this.ImportHeaders.length;j++){
          obj[this.ImportHeaders[j]] = currentline[j];
        }
        let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
        this.CSVResult.push(obj);
        //console.log("this.CSVResult length is "+this.CSVResult.length)
      }
      this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
        this.CSVResult.map((res,i) => {
            this.UpdateFiresStoreReimport(res,i)
        })
  },
  // Headers
  RoundUpReImport(result,ResultLength,Index){
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log(ImportSession,SingleResult,OriginalImportObject,FailureObject)
        if(ImportSession.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }
        this.NewImportsArray.push(OriginalImportObject)
        //console.log('ImportSession',ImportSession)
        //still t check mandataory fields, do not add if not present
        this.TableCollectionRef.doc(OriginalImportObject.id).set(ImportSession).then(doc => {
          let AllImportObj = this.NewImportsArray.find(imp => imp.id == OriginalImportObject.id)
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = OriginalImportObject.id
          //console.log(this.NewImportsArray[AllImportObjIndex])
          //console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp.id == OriginalImportObject.id)

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = OriginalImportObject.id
          //console.log(this.PartialFailuresArray[PartialImportObjIndex])
          //console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.id == OriginalImportObject.id)
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = OriginalImportObject.id
          //console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          //console.log(this.SuccessfulImportsArray)
          }
          let indexstring = Index.toString()
          let totallength = ResultLength.toString()
          let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
          this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
          const totalprogresselement = document.getElementById('importprogressbar')
          totalprogresselement.setAttribute('value',runningPercentage)
          if(Index-1+2 === ResultLength){
          //console.log(this.NewImportsArray,this.PartialFailuresArray,this.SuccessfulImportsArray) 
          let Created_On = new Date()
          let User = this.userLoggedIn
          let RefinedHeaders = this.ImportHeaders.map(header => {
            let obj = {text: header, value: header}
            return obj
          })
          //console.log("this.NewImportsArray at final length")
          //console.log(this.NewImportsArray)
          let DataImport = {name: this.Import.Name, dataset: this.CurrentEntity.DisplayName, datasetrecordpath: this.CurrentEntity.SingleName.split(' ').join(''), createdon: Created_On, createdby: {id: User.id, Full_Name: User.Full_Name}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
          db.collection('dataimports').add(DataImport).then(doc => {
          var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
          var uploadTask = storageRef.put(this.ImportFile);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              db.collection('dataimports').doc(doc.id).update({
                      csvfile: url
              })
              this.ImportFile = ''
                  }),
                  this.importresultsdialog = true
                  this.importdialog = false
                  this.Import.Name = ''
                  this.ImportingProgressDialog = false
          })
          }
        })
        
        
       
    },
    RoundUpImport(result,ResultLength,Index){
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log(ImportSession,SingleResult,OriginalImportObject,FailureObject)
        if(ImportSession.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }
        this.NewImportsArray.push(OriginalImportObject)
        //console.log('ImportSession',ImportSession)
        //still t check mandataory fields, do not add if not present
        this.TableCollectionRef.add(ImportSession).then(doc => {
          let AllImportObj = this.NewImportsArray.find(imp => imp[this.CurrentEntity.Primary_Field_Name] == ImportSession[this.CurrentEntity.Primary_Field_Name])
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = doc.id
          //console.log(this.NewImportsArray[AllImportObjIndex])
          //console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp[this.CurrentEntity.Primary_Field_Name] == ImportSession[this.CurrentEntity.Primary_Field_Name])

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
          //console.log(this.PartialFailuresArray[PartialImportObjIndex])
          //console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp[this.CurrentEntity.Primary_Field_Name] == ImportSession[this.CurrentEntity.Primary_Field_Name])
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
          //console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          //console.log(this.SuccessfulImportsArray)
          }
          let indexstring = Index.toString()
          let totallength = ResultLength.toString()
          let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
          this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
          const totalprogresselement = document.getElementById('importprogressbar')
          totalprogresselement.setAttribute('value',runningPercentage)
          if(Index-1+2 === ResultLength){
          //console.log(this.NewImportsArray,this.PartialFailuresArray,this.SuccessfulImportsArray) 
          let Created_On = new Date()
          let User = this.userLoggedIn
          let RefinedHeaders = this.ImportHeaders.map(header => {
            let obj = {text: header, value: header}
            return obj
          })
          //console.log("this.NewImportsArray at final length")
          //console.log(this.NewImportsArray)
          let DataImport = {name: this.Import.Name, dataset: this.CurrentEntity.DisplayName, datasetrecordpath: this.CurrentEntity.SingleName.split(' ').join(''), createdon: Created_On, createdby: {id: User.id, Full_Name: User.Full_Name}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
          db.collection('dataimports').add(DataImport).then(doc => {
          var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
          var uploadTask = storageRef.put(this.ImportFile);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              db.collection('dataimports').doc(doc.id).update({
                      csvfile: url
              })
              this.ImportFile = ''
                  }),
                  this.importresultsdialog = true
                  this.importdialog = false
                  this.Import.Name = ''
                  this.ImportingProgressDialog = false
          })
          }
        })
        
        
       
    },
    PrepImportHeadersDefault(ImportSession,SingleResult,OriginalImportObject,FailureObject,ReImport){
      //console.log('PrepImportHeadersDefault',ImportSession,SingleResult,OriginalImportObject,FailureObject)
      if(ReImport){
        ImportSession.id = SingleResult.id
        FailureObject.id = SingleResult.id
      }
      let vm = this
      return new Promise(function(resolve, reject) {
      let length = vm.Headers.length
       if(length === 0){
          resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
        }
        else{
          vm.Headers.map((header,index) => {
            if(typeof SingleResult[header.id] !== 'undefined'){
              let value = SingleResult[header.id].trim()
              //console.log('SingleResult',header,value,vm.CurrentEntity)
              if(header.Type === 'Date' && !header.HasTimeInput && SingleResult[header.id].replace(/\s/g, '').length){
                ImportSession[header.id] = vm.DateFormatter(value)
              }
              else if(header.Type === 'Date' && header.HasTimeInput && SingleResult[header.id].replace(/\s/g, '').length){
                ImportSession[header.id] = vm.DateFormatterwithTimeImport(value+'T'+SingleResult[prop+'_Time'])
              }
              else if(header.Type === 'Number Field'){
                let CommaTODot = value.split(',').join('.')
                ImportSession[header.id] = Number(CommaTODot)
              }
              else{
                ImportSession[header.id] = value
              } 
            }
            if(index-1+2 === length){
              resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
            }
          })
        }      
      })
    }, 
    PrepImportHeadersOptionSets(result){
      //console.log('PrepImportHeadersOptionSets',result)
      let vm = this
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log('PrepImportHeadersOptionSets',ImportSession,SingleResult,OriginalImportObject,FailureObject,vm.OptionSetHeaders)
      let length = vm.OptionSetHeaders.length
      if(ImportSession.Status && !ImportSession.Status.ID){
         let status = this.CurrentEntityFields.find(obj => obj.id === 'Status')
         let option = status.Options.find(obj => obj.Name === ImportSession.Status)
         if(option){
           ImportSession.Status = option
           SingleResult.Status = option
           OriginalImportObject.Status = option
           FailureObject.Status = option
         }
      }
      return new Promise(function(resolve, reject) {      
        if(length === 0){
          resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
        }
        else{
          vm.OptionSetHeaders.map((header,index) => {          
            let optionfield = vm.CurrentEntityFields.find(obj => obj.id === header.id)
            if(!optionfield){                
              FailureObject[header.Name] = 'Failed - No Matching Field Found'
              ImportSession.hasFailures = true
              let FailureInstance = {Type: 'No Matching Option', Name: header.Name}
              FailureObject.Failures.push(FailureInstance)
            } 
            else{
              if(ImportSession[header.id] && header.IsNumeric){
                ImportSession[header.id] = Number(ImportSession[header.id])
              }
              let match = optionfield.Options.find(obj => obj.Name === ImportSession[header.id])
              if(match){
                ImportSession[header.id] = match
              }
              else{
                FailureObject[header.Name] = 'Failed - No Matching Option Found'
                ImportSession.hasFailures = true
                let FailureInstance = {Type: 'No Matching Field', Name: header.Name}
                FailureObject.Failures.push(FailureInstance)
              }
            }
            //console.log(ImportSession)
            if(index-1+2 === length){
              resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
            }
          })
        }      
      })
    }, 
    PrepImportHeadersLookups(result){
      //console.log('PrepImportHeadersLookups',result)
      let vm = this
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log('PrepImportHeadersLookups',ImportSession,SingleResult,OriginalImportObject)
      let length = vm.LookupHeaders.length
      return new Promise(function(resolve, reject) {
        if(length === 0){
          resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
        }
        else{
          vm.LookupHeaders.map((header,index) => {    
              //console.log(header,ImportSession[header.id],ImportSession)   
            let GetObj = ''
            if(ImportSession[header.id]){
              //console.log(header.Array.find(obj => obj[header.LookupFieldName].trim() == ImportSession[header.id].trim()))
              GetObj = header.Array.find(obj => obj[header.LookupFieldName].trim() == ImportSession[header.id].trim())
            }
            //console.log(ImportSession[header.id].trim(),GetObj)
            if(!GetObj){
              FailureObject[header.Name] = 'Failed - No Matching Record Found'
              ImportSession.hasFailures = true
              let FailureInstance = {Type: 'No Matching Lookup Record', Name: header.Name}
              FailureObject.Failures.push(FailureInstance)
            }
            else{
              let matchobj = {id: GetObj.id,[header.LookupFieldName]: GetObj[header.LookupFieldName]}
              ImportSession[header.id] = matchobj
              ImportSession[header.id+'id'] = matchobj.id
              if(header.id === 'Owner' && header.RelatedBuildID === 'Users'){
              let user = vm.$store.state.UsersArray.find(obj => obj.id === matchobj.id) 
              if(user){
                ImportSession.Business_Unit = {Name: user.Business_Unit.Name,id: user.Business_Unit.id}
                ImportSession.Business_Unitid = user.Business_Unit.id
              }
              }
              else if(header.id === 'Warehouse' && header.RelatedBuildID === 'Warehouses'){
              let user = vm.$store.state.WarehousesArray.find(obj => obj.id === matchobj.id) 
              if(user){
                ImportSession.Business_Unit = {Name: user.Business_Unit.Name,id: user.Business_Unit.id}
                ImportSession.Business_Unitid = user.Business_Unit.id
                ImportSession.Owner = {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name}
                ImportSession.Ownerid = vm.userLoggedIn.id
                
              }
              }            
            }
            //console.log(OriginalImportObject,ImportSession)
            //console.log(header,ImportSession[header.id],ImportSession,vm.$store.state.UsersArray)
            if(index-1+2 === length){
              resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
            }
          }) 
        }      
      })
    }, 
    UpdateFiresStore(SingleResult, Index){
      let vm = this
      let ResultLength = this.CSVResult.length
      let OriginalImportObject = Object.assign({},SingleResult)                
                    
        let NameQuery = []
        let NameStringArray = SingleResult[this.CurrentEntity.Primary_Field_Name].trim()
        var lowertext = "";                
        var p;
          let buildup = ''
        //console.log(NameStringArray.length,NameStringArray)
          for (p = 0; p < NameStringArray.length; p++) {
              buildup = buildup+ NameStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          NameQuery = lowertext.split(',')  
          NameQuery.length = NameQuery.length-1
                 
          NameQuery = NameQuery.concat(NameStringArray.split(' '))    
          NameQuery = NameQuery.map(entry => {
            return entry.toLowerCase()
          })

      let status = this.CurrentEntityFields.find(obj => obj.id === 'Status')
      let Status = status.DefaultOption
      let Status_Reason = status.DefaultLevel2Option              
      let ImportSession = {
        [this.CurrentEntity.Primary_Field_Name]: SingleResult[this.CurrentEntity.Primary_Field_Name].trim(),
        [this.CurrentEntity.Primary_Field_Name+'Query']: NameQuery,
        SearchQuery: NameQuery,
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid: this.userLoggedIn.id,
        Modified_On: new Date(), 
        ImportedRecord: true,
        Status: Status,
        Status_Reason: Status_Reason
        }
        let FailureObject = Object.assign({},ImportSession)
        FailureObject.Failures = []
        this.PrepImportHeadersDefault(ImportSession,SingleResult,OriginalImportObject,FailureObject).then(defheadres => {
          if(defheadres){
           vm.PrepImportHeadersOptionSets(defheadres).then(OSheadres => {
              if(OSheadres){
                vm.PrepImportHeadersLookups(OSheadres).then(LUheadres => {
                  if(LUheadres){
                    vm.RoundUpImport(LUheadres,ResultLength,Index)
                  }
                })
              }
            }) 
          }
          
        })        
    },
    UpdateFiresStoreReimport(SingleResult, Index){
      let vm = this
      let ResultLength = this.CSVResult.length
      let OriginalImportObject = Object.assign({},SingleResult)                
                    
        let NameQuery = []
        let NameStringArray = SingleResult[this.CurrentEntity.Primary_Field_Name].trim()
        var lowertext = "";                
        var p;
          let buildup = ''
        //console.log(NameStringArray.length,NameStringArray)
          for (p = 0; p < NameStringArray.length; p++) {
              buildup = buildup+ NameStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          NameQuery = lowertext.split(',')  
          NameQuery.length = NameQuery.length-1
                 
          NameQuery = NameQuery.concat(NameStringArray.split(' '))    
          NameQuery = NameQuery.map(entry => {
            return entry.toLowerCase()
          })
      let status = this.CurrentEntityFields.find(obj => obj.id === 'Status')
      let Status = status.DefaultOption
      let Status_Reason = status.DefaultLevel2Option              
      let ImportSession = {
        [this.CurrentEntity.Primary_Field_Name]: SingleResult[this.CurrentEntity.Primary_Field_Name].trim(),
        [this.CurrentEntity.Primary_Field_Name+'Query']: NameQuery,
        SearchQuery: NameQuery,
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid: this.userLoggedIn.id,
        Modified_On: new Date(), 
        ImportedRecord: true,
        Status: Status,
        Status_Reason: Status_Reason
        }
        
        // this.Headers.map (header => {
        //   let prop = header.propvalue
        //   if(typeof SingleResult[prop] !== 'undefined'){
        //     let value = SingleResult[prop]
        //     if(header.Type === 'Date' && !header.HasTimeInput){
        //       ImportSession[prop] = this.DateFormatter(SingleResult[value])
        //     }
        //     else if(header.Type === 'Date' && header.HasTimeInput){
        //       ImportSession[prop] = this.DateFormatterwithTimeImport(value+'T'+SingleResult[prop+'_Time'])
        //     }
        //     else if(header.Type === 'Number Field'){
        //       let CommaTODot = SingleResult[value].split(',').join('.')
        //       ImportSession[prop] = Number(CommaTODot)
        //     }
        //     else{
        //       ImportSession[prop] = value
        //     }
            
        //   }
        // })
        let FailureObject = Object.assign({},ImportSession)
        FailureObject.Failures = []
        this.PrepImportHeadersDefault(ImportSession,SingleResult,OriginalImportObject,FailureObject,true).then(defheadres => {
          if(defheadres){
           vm.PrepImportHeadersOptionSets(defheadres).then(OSheadres => {
              if(OSheadres){
                vm.PrepImportHeadersLookups(OSheadres).then(LUheadres => {
                  if(LUheadres){
                    //console.log('LUheadres,ResultLength,Index',LUheadres,ResultLength,Index)
                    vm.RoundUpReImport(LUheadres,ResultLength,Index)
                  }
                })
              }
            }) 
          }
          
        }) 
        // let FailureObject = Object.assign({},ImportSession)
        // FailureObject.Failures = []
        // console.log(ImportSession)
        // if(ImportSession.hasFailures){
        // this.PartialFailuresArray.push(FailureObject)

        // }
        // else{
        //   this.SuccessfulImportsArray.push(OriginalImportObject)
        // }

        // this.NewImportsArray.push(OriginalImportObject)
        // console.log(this.NewImportsArray,this.TableCollectionRef)
    },
    DateFormatterwithTimeImport(date){
        if(date){
              let removeT = date.split('T').join(' ')
              let removeFWDslash = date.split('/').join('-')
              let yearstring = removeFWDslash.split('-')[0]
              let monthstring = removeFWDslash.split('-')[1]
              let daystring = removeFWDslash.split('-')[2].split('T')[0]
              let timestring = removeFWDslash.split('-')[2].split('T')[1]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = timestring.split(':')[0]
              var min = timestring.split(':')[1]
              var sec = timestring.split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
              //console.log(yearnumber, monthnumber-1, daynumber,removeFWDslash,timestring,hr,min,sec)
              if(sec){
                //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
                return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec) 
              }
              else{
                //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min))
                return new Date(yearnumber, monthnumber-1, daynumber,hr,min)
              }
              
              }
            else{
              return null
            }
      },
    DateFormatter(date){
      if(date){
        let removeT = date.split('T').join(' ')
        let removeFWDslash = date.split('/').join('-')
      let yearstring = removeFWDslash.split('-')[0]
        let monthstring = removeFWDslash.split('-')[1]
        let daystring = removeFWDslash.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
    },
    CSVConvertToJSON(text){
     
      this.NewImportsArray = []
      this.PartialFailuresArray = []
      //console.log(text)
      let lines = text.split("\n")
      this.CheckDelimiter(lines)
      
    },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },      
      ToggleIsLiaisonView(){
        this.IsLiaisonView = !this.IsLiaisonView
      },
      ToggleIsCalendarView(){
        if(this.IsVCardView){
          this.IsVCardView = false
        }
        this.IsCalendarView = !this.IsCalendarView
        this.STDTableView = !this.IsCalendarView
        
      },
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      ViewItem (item,fromquickview) {
        this.ScrolltoTop()
         if(this.StoreOrderEntity && item.Business_Unitid){
            this.$router.push('/SalesOrder/'+this.CurrentEntity.id+'/'+item.id)
          }
          else if(fromquickview){
            this.$router.push('/'+this.TableEntryPath+'/'+item.id)
          }
          else{
          this.QuickMode = 'RecordView' 
          //console.log(this.QuickMode)
          let itemobj = this.MasterData.find(obj => obj.id === item.id)
          this.editedIndex = this.MasterData.indexOf(itemobj)
          this.editedItem = Object.assign({}, itemobj)
          this.quickeditstep = 2
          
          }
          
        
      },
      RoutetoEntitySingleBuilder(item){
        if(this.$route.name === 'TableViewer'){
        let routes = this.$router.getRoutes()
        //console.log(routes)
        if(this.$route.params.slug === 'Systems'){
          let newroute = routes.find(obj => obj.name === 'system')
          newroute.params = {id: this.$route.query.Record}
          newroute.query = { TableType: 'Operational_Entity',TableEntity: this.$route.query.TableEntity,RecordCollection: 'Sample_Records',Record: item.id}
          this.$router.push(newroute)
        }
        else{
          let newroute = routes.find(obj => obj.name === 'entity')
          newroute.params = {id: this.$route.query.Record}
          newroute.query = { TableType: 'Operational_Entity',RecordCollection: 'Sample_Records',Record: item.id}          
          this.$router.push(newroute)
        }
      }
      else if(this.$route.meta && this.$route.meta.RecordisSystem && this.MasterTableObj.EntityBuilder){
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','ActivateSelectedElement')
        this.$router.replace({ name: "SystemConfig", query: { TableType: 'System_Entity',TableEntity: 'Entities',RecordCollection: 'entities',Record: item.id}})
        
      }
      else if(item.RouteQuery){
        let newroute = { path: this.$route.path, query: item.RouteQuery}
        //console.log(newroute)
        this.$router.push(newroute)
      }
      else if(this.$route.query && this.$route.query.Record){
        this.$router.push({ name: 'EntryViewer',query: {Parent: this.$route.query.Record,Record: item.id}, params: {slug: this.$route.params.slug,id: this.$route.params.id, EntityRecord: item,EntityConfigDB: '',EntityConfigSystemEntities: [] }})
      }
      // else if(!this.MasterTableObj.EntityBuilder){

      // }
      else{
        this.$router.replace({ name: 'EntryViewer',query: {Parent: this.$route.params.id,Record: item.id}, params: {slug: this.CurrentEntity.id,id: this.MasterTableObj.id, EntityRecord: item,EntityConfigDB: '',EntityConfigSystemEntities: [] }})
        // console.log(this.MasterTableObj,this.CurrentEntity)
      }
        
    },
      
      ActivateRelevantdialog(keepitem){
        this.close(true)        
        if(this.ScheduleBookingEntity){
          // this.scheddialog = !this.scheddialog
          this.EditingCalendar = !this.EditingCalendar
          //console.log(this.EditingCalendar)
        }
        else{
         this.recordformdialog = !this.recordformdialog 
        }        
        
      },
      
    
        Headerdragstart(head,headerindex,event){
            this.DraggingHeaderIndex = headerindex            
        },
        Headerdragfinish(head,headerindex,event){
            //this.Headers.splice(0,0,this.Headers.splice(currentprimaryindex,1)[0])
            this.Headers.splice(headerindex,0,this.Headers.splice(this.DraggingHeaderIndex,1)[0])  
            this.Headers.map((header,newindex) => {
                header.HeaderIndex = newindex
                //moved updates to SaveHeaderUpdates, so changes only occur either adding field, or bulk update
                // db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.id).collection('AllFields').doc(header.id).set(cleanheader).then(doc => {
                
                return header
            })          
            //console.log(this.Headers,this.id)
        },
        AddNewField(){
            let newfield = Object.assign({},this.NewField)
            newfield.Name = newfield.Name.split(' ').join('_')
            newfield.IsHeader = true
            newfield.HeaderIndex = this.Headers.length-1
            //console.log(newfield)  
            if(this.$route.meta && this.$route.meta.SystemConfigBuilder){
              //console.log(this.EntityCollectionRef)
              db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.id).collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                //this.EntityCollectionRef.collection('entities').doc(this.id).collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                  this.NewField = Object.assign({},this.DefaultNewField)
                this.RefreshComponent()
            })
            }
            else{
              if(this.NeedsRASync){        
                          
                    this.RAOperationalDB.collection('AllFields').doc(newfield.Name).set(newfield).then(radoc => {
                      this.EntityCollectionRef.collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                        this.NewField = Object.assign({},this.DefaultNewField)
                        this.RefreshComponent()
                      })   
                    })
                  }
                  else{
                    this.EntityCollectionRef.collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                      this.NewField = Object.assign({},this.DefaultNewField)
                        this.RefreshComponent()
                    })   
                  }
            }
            
        },
        SaveIconList(array){

        },
        RemovefromIconList(icon,index,array){
            array.splice(index,1)
        },
        deleteItem (item) {
        const index = this.MasterData.indexOf(item)
        confirm('Are you sure you want to delete this item?') && this.ProcessItemDelete(item)        
        // && this.MasterData.splice(index, 1)
      },
        ProcessItemDelete(item){
          this.TableCollectionRef.doc(item.id).update({
            Deleted_By: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
            Deleted_Byid: this.userLoggedIn.id,
            Deleted_On: new Date(),
          }).then(updateddoc => {
            this.TableCollectionRef.doc(item.id).delete()
          })
        },
        editRecomputedItem(item){
          let itemobj = this.MasterData.find(obj => obj.id === item.id)
          this.editedIndex = this.MasterData.indexOf(itemobj)
          this.editedItem = Object.assign({}, itemobj)
          this.PrimaryCategoryField = this.CurrentEntityFields.find(obj => obj.id === 'Product_Group')
          this.quickeditstep = 2
          this.QuickMode = 'QuickEdit'
        },
        editItem (item) {
          this.ScrolltoTop()
        this.editedIndex = this.MasterData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if(!this.QuickEditEntity){
         this.MasterHeaders = this.MasterHeaders.map(headobj => {
           let header = Object.assign({},headobj)
            //console.log(header,item)
            header.Response = this.editedItem[header.propvalue]
            if(header.HasDeepHierarchy && header.Levels && header.Levels[1]){
                header.Level1Response = this.editedItem[header.Levels[1].FieldName]
            }
            if(header.Type === 'Date'){
                if(this.editedItem[header.propvalue] && this.editedItem[header.propvalue].toDate){
                    let dateobject = this.editedItem[header.propvalue].toDate()
                    header.Response = format(dateobject,'yyyy-MM-dd')  
                    if(header.HasTimeInput){
                      let hours = dateobject.getHours()
                      let mins = dateobject.getMinutes()
                      if(hours === 0){
                        hours = '00'
                      }
                      if(mins === 0){
                        mins = '00'
                      }
                      header.TimeResponse = hours+':'+mins
                    }
                }
                
            }
            return header
        })
        this.QuickMode = 'RecordView'
        this.quickeditstep = 2
        //this.recordformdialog = true 
        }
        else{
          this.QuickMode = 'QuickEdit'
          this.quickeditstep = 2
        }
        
      },
        StoreCalendarEntry(){
          alert('yep StoreCalendarEntry')
          let newdocid = ''
          let ref = this.TableCollectionRef.doc()
          let vm = this
         if(!this.editedItem.id){
           newdocid = ref.id
           //this.editedItem.id = newdocid
         }
         else{
           newdocid = this.editedItem.id
         }
         if(this.editedItem.Image && this.editedItem.Image.UploadFile){
            let file = this.editedItem.Image.UploadFile
            let filename = this.editedItem.Image.FileName
            let filestorageref = this.TableNameSplit+'/'+newdocid+'/Image/'+filename
            this.onPhotoUpload(filename,file,filestorageref).then(fileobj => {
              vm.editedItem.Image = fileobj
              vm.$emit('ActivateProcessing',false)
              vm.FinalizeAddingCalendarItem(newdocid,vm.editedItem)
            })
         }
         else{
           this.FinalizeAddingCalendarItem(newdocid,this.editedItem)
         }
          
        },
        onPhotoUpload(filename,file,storepath) {
          let vm = this
          return new Promise(function(resolve, reject) {	
              var storageRef = firebase.storage().ref(storepath)
              var uploadTask = storageRef.put(file)
              vm.$emit('ActivateProcessing',true)
              vm.PrepareThumbnail(filename,file,storepath).then(thumbresult => {
              uploadTask
              .then(snapshot => snapshot.ref.getDownloadURL())
                .then((url) => {
                  let UploadObject = {
                    fileurl: url,
                    url: url,
                    ThumbURL: thumbresult,
                    FileType: file.type,
                    Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                    Modified_Byid: vm.userLoggedIn.id,
                    Modified_On: new Date(),
                    ModifiedDate: new Date(file.lastModified),
                    Size: file.size,
                    StorageRef: storepath,
                    filename: filename
                  }
                  resolve(UploadObject)
                      })
              })
          })
            },
          PrepareThumbnail(filename,file,storageref){
              let vm = this
              return new Promise(function(resolve, reject) {	
              file.tmpsrc = URL.createObjectURL(file)
                    //this.OutboundPhotosforUpload.push(file)
                    var img = document.createElement("IMG");
                      img.setAttribute('width',150)
                      img.setAttribute('src',file.tmpsrc)
                      document.body.appendChild(img)
                      let vm = this
                      img.onload = function() {
                      var c = document.createElement("canvas");
                      var ctx = c.getContext("2d");
                      var canvasRatio = img.naturalHeight / img.naturalWidth
                      c.width = 400
                      c.height = c.width*canvasRatio
                      ctx.drawImage(img, 0, 0, c.width, c.height);
                      c.toBlob(blob => {
                        let blobfile = new File([blob], filename, {
                        type: file.type,
                  });
                  //console.log(blobfile)
                  var storageRef = firebase.storage().ref(storageref+'_thumb')
                      document.body.removeChild(img)
                      var uploadTask = storageRef.put(blobfile)
                      uploadTask
                        .then(snapshot => snapshot.ref.getDownloadURL())
                          .then((thumburl) => {
                            //console.log(thumburl)
                            resolve(thumburl)
                          })
                    })
                      }
              })
            },
        FinalizeAddingCalendarItem(newdocid,item){
          let itemobj = {
            Start: item.StartObject,
            End: item.EndObject,
            Title: item.Title,
            Description: item.Description,
            User_Limit: item.User_Limit,
            RSVP_Amount: item.RSVP_Amount,
            RSVP_Type: item.RSVP_Type,
            RSVP_Advance_Amount: item.RSVP_Advance_Amount,
            RSVP_Advance_Type: item.RSVP_Advance_Type,
            Presenter: item.Presenter,
            start: item.StartObject.getTime(),
            end: item.EndObject.getTime(),
            timed: true
          }
          
          if(item.Image){
            itemobj.Image = item.Image
          }
          if(item.Type){
            itemobj.Type = item.Type
          }
          if(!item.id){
                let status = this.CurrentEntityFields.find(obj => obj.id === 'Status')
                itemobj.Status = status.DefaultOption
                itemobj.Status_Reason = status.DefaultLevel2Option
                itemobj.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                itemobj.Created_Byid = this.userLoggedIn.id,
                itemobj.Created_On = new Date()            
            }
            
            let owner = Object.assign({},item.Owner)
            let presenter = Object.assign({},item.Owner)
            itemobj.Presenter = {id: presenter.id, Name: presenter.Name, Surname: presenter.Surname, Full_Name: presenter.Full_Name}
            itemobj.Presenterid = presenter.id
            itemobj.Owner = {id: owner.id, Name: owner.Name, Surname: owner.Surname, Full_Name: owner.Full_Name}
            itemobj.Ownerid = owner.id
            if(owner.Business_Unit){
            itemobj.Business_Unit = owner.Business_Unit
            itemobj.Business_Unitid = owner.Business_Unitid  
            }
            
            itemobj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
            itemobj.Modified_Byid = this.userLoggedIn.id,
            itemobj.Modified_On = new Date()     
            this.TableCollectionRef.doc(newdocid).set(itemobj).then(addeddoc => {
              this.CalendarEntryCompleted = true
              this.close()
              setTimeout(() => {
                this.CalendarEntryCompleted = false
              }, 500);
            })
        },
        // ReceiveAddedAssets(assets){
        //   if(this.scheddialog){
        //     this.editedItem.Image = assets[0]
        //       if(!this.editedItem.Created_On){
        //         let owner = this.editedItem.Owner
        //         this.editedItem.Owner = {id: this.editedItem.Owner.id, Name: this.editedItem.Owner.Name, Surname: this.editedItem.Owner.Surname, Full_Name: this.editedItem.Owner.Full_Name}
        //         this.editedItem.Ownerid = this.editedItem.Owner.id
        //         this.editedItem.Business_Unit = owner.Business_Unit
        //         this.editedItem.Business_Unitid = owner.Business_Unitid
        //         this.editedItem.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        //         this.editedItem.Created_Byid = this.userLoggedIn.id,
        //         this.editedItem.Created_On = new Date()
        //         this.editedItem.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        //         this.editedItem.Modified_Byid = this.userLoggedIn.id,
        //         this.editedItem.Modified_On = new Date()                 
        //     }
        //     this.TableCollectionRef.doc(this.editedItem).set(this.editedItem).then(addeddoc => {
        //       this.close()
        //     })
        //   }          
        // },
        async save(byQuickStep){
          let Headers = []
          if(byQuickStep){
            Headers = this.QuickEditSections.filter(sect => {
              return sect.Type === 'Fields'
            }).map(sect => {
              return sect.Fields
            }).flat()
            Headers = Headers.map(head => {
              let headobj = Object.assign({},head)
              if(typeof this.editedItem[head.Name] !== 'undefined'){
                headobj.Response = this.editedItem[head.Name]
              }
              //console.log(this.editedItem[head.Name],head.Name)
              headobj.text = head.DisplayName
              headobj.propvalue = head.Name
              headobj.value = head.Name
              return headobj
            })            
        // /StoreCalendarEntry()
        // <RSVPTableItem :item="editedItem" :CurrentEntity="CurrentEntity"
        //             />
          }
          else{
            Headers = this.Headers
          }
            let NewDataObj = {
              }
              
          if(this.editedIndex === -1){
            console.log('not by quikstep and mind you - brand new')
              
              if(this.UserCompaniesEntity){
                if(this.System.Companies_Account_Limits_by_Admin){
                  NewDataObj.Account_Limits_by_Admin = true
                }
                if(this.System.CompaniesDefaultLoanEntities){
                  NewDataObj.LoanEntities = this.System.CompaniesDefaultLoanEntities
                }
                if(this.System.CompaniesDefaultStoreEntities){
                  NewDataObj.StoreEntities = this.System.CompaniesDefaultStoreEntities
                }
              }
              if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'){
                NewDataObj.Available = 0
                NewDataObj.Backorder = 0
                 NewDataObj.Inbound = 0
                NewDataObj.On_Hand = 0
                NewDataObj.On_Order = 0
                NewDataObj.Received = 0
                NewDataObj.Picking = 0
                NewDataObj.Packing = 0
                NewDataObj.Dispatching = 0
                NewDataObj.Average_Price = 0
                NewDataObj.Total_Received = 0
                NewDataObj.Latest_Price = 0
                NewDataObj.Total_Cost = 0
                NewDataObj.Purchased_Counter = 0
                NewDataObj.Purchased_With = {}
              }
              if(this.StoreProductEntity){
                if(this.CurrentEntity.Precede_with_Quote){
                  //!E-COMMERCE PLUGIN
                  NewDataObj.Order_Progress = {ID: 1000001,Name: 'Preparing'}
                }
                else{
                  NewDataObj.Order_Progress = {ID: 1000001,Name: 'Preparing'}
                  //NewDataObj.Order_Progress = {ID: 1000003,Name: 'Approved'}
                }
                NewDataObj.Amount_Invoiced = 0
                NewDataObj.Amount_Paid = 0
                NewDataObj.Order_Total = 1
              }
              if(this.userLoggedIn){
                  NewDataObj.Business_Unit = {Name: this.userLoggedIn.Business_Unit.Name,id: this.userBUID},
                  NewDataObj.Business_Unitid = this.userLoggedIn.Business_Unitid
                  NewDataObj.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                  NewDataObj.Created_Byid = this.userLoggedIn.id,
                  NewDataObj.Created_On = new Date()
                  NewDataObj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                  NewDataObj.Modified_Byid = this.userLoggedIn.id,
                  NewDataObj.Modified_On = new Date()
              }
              else{
                  //do we assign "system" as createdby?
                  NewDataObj.Created_On = new Date()
                  NewDataObj.Modified_On = new Date()
              }
          }
              let SearchQuery = []
              NewDataObj.SearchQuery = []
              let length = Headers.length
              //console.log('Headers',length,Headers)
              Headers.map((header,headerindex) => {
                //NEED TO VALIDATEFORM PRIOR TO THIS STEP MIND YOU!!! 
                  if(header.Type === 'Boolean' && typeof header.Response !== 'undefined' || header.Response){
                      NewDataObj[header.propvalue] = header.Response
                      if(header.Type === 'Lookup' && !header.IsMultiple){
                          let baseobj = {
                             [header.LookupFieldName]: NewDataObj[header.propvalue][header.LookupFieldName],
                             id: NewDataObj[header.propvalue].id
                          }
                          if(header.RelatedRecord !== 'Users' && header.RelatedBuildID !== 'Warehouses'){
                              NewDataObj[header.propvalue] = baseobj
                              NewDataObj[header.propvalue+'id'] = baseobj.id
                          }
                          else if(header.RelatedRecord === 'Users' && header.propvalue === 'Owner'){
                              NewDataObj[header.propvalue] = baseobj
                              NewDataObj[header.propvalue+'id'] = baseobj.id
                              //and here we need to add the BU of the owner, but how?
                              let ownerobj = this.$store.state.UsersArray.find(obj => obj.id === baseobj.id)
                              if(ownerobj){
                                NewDataObj.Business_Unit = ownerobj.Business_Unit
                                NewDataObj.Business_Unitid = ownerobj.Business_Unitid
                              }
                          }
                          else if(header.RelatedBuildID === 'Warehouses' && header.propvalue === 'Warehouse'){
                              NewDataObj[header.propvalue] = baseobj
                              NewDataObj[header.propvalue+'id'] = baseobj.id
                              //and here we need to add the BU of the owner, but how?
                              let whobj = this.$store.state.WarehousesArray.find(obj => obj.id === baseobj.id)
                              //console.log('whobj',whobj,this.$store.state.WarehousesArray)
                              if(whobj){
                                NewDataObj.Business_Unit = whobj.Business_Unit
                                NewDataObj.Business_Unitid = whobj.Business_Unitid
                                NewDataObj.Owner = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                                NewDataObj.Ownerid = this.userLoggedIn.id
                                
                              }
                          }
                          else{
                            NewDataObj[header.propvalue] = {
                                [header.LookupFieldName]: NewDataObj[header.propvalue][header.LookupFieldName],
                                id: NewDataObj[header.propvalue].id,                                
                            }
                            if(NewDataObj[header.propvalue] && NewDataObj[header.propvalue].Name && NewDataObj[header.propvalue].Surname){
                                  NewDataObj.Name = NewDataObj[header.propvalue].Name
                                  NewDataObj.Surname = NewDataObj[header.propvalue].Surname
                            }
                            NewDataObj[header.propvalue+'id'] = baseobj.id
                          }
                          if(header.MappedValues){
                              header.MappedValues.map(mapvalue => {
                                  if(mapvalue.InputField.Type !== 'Common Field' || mapvalue.InputField.Type === 'Common Field' && mapvalue.InputField.CommonFieldType !== 'Physical Address' && mapvalue.InputField.CommonFieldType !== 'Postal Address'){
                                      if(header.Response[mapvalue.InputField.Name]){
                                        NewDataObj[mapvalue.OutputField.Name] = header.Response[mapvalue.InputField.Name] 
                                        if(mapvalue.OutputField.Type === 'Lookup'){
                                              NewDataObj[mapvalue.OutputField.Name+'id'] = header.Response[mapvalue.InputField.Name+'id']
                                          }
                                      }                            }
                                  else if(mapvalue.InputField.Type === 'Common Field'){
                                      mapvalue.OutputField.FieldBreakdown.map((breakdown,breakdownindex) => {                                        
                                        let mapname = mapvalue.InputField.FieldBreakdown[breakdownindex].Name
                                          if(header.Response[mapname]){
                                          NewDataObj[breakdown.Name] = header.Response[mapname]   
                                          }                                    
                                      })
                                  }                                                        
                              })           
                          }
                          
                      }
                      else if(header.Type === 'Lookup' && header.IsMultiple){
                        //then nothing, already done
                      }
                      else if(header.Type === 'Date' && !header.HasTimeInput){
                          NewDataObj[header.propvalue] = new Date(header.Response)
                      }
                      else if(header.Type === 'Date' && header.HasTimeInput){                       
                          NewDataObj[header.propvalue] = new Date(header.Response+' '+header.TimeResponse)
                          NewDataObj[header.propvalue+'TimeResponse'] = header.TimeResponse
                      }
                      else if(header.HasDeepHierarchy && header.Levels && header.Levels[1] && !byQuickStep){
                          NewDataObj[header.Levels[1].FieldName] = header.Level1Response
                      }
                      else if(header.HasDeepHierarchy && header.Levels && header.Levels[1] && byQuickStep && this.editedItem[header.Levels[1].FieldName]){
                          NewDataObj[header.Levels[1].FieldName] = this.editedItem[header.Levels[1].FieldName]
                      }
                      if(header.IsSearch || header.Primary){
                        //NewDataObj.SearchQuery = []
                        let PrimaryString = ''
                        if(header.Type === 'Single Line Text'){
                          PrimaryString = NewDataObj[header.propvalue].trim()
                        }
                        else if(header.Type === 'Option Set'){
                          PrimaryString = NewDataObj[header.propvalue].trim()
                        }
                        //console.log('PrimaryString','"PrimaryString"')
                        var lowertext = "";                
                        var p;
                        let buildup = ''
                        //console.log(PrimaryString.length,PrimaryString)
                        for (p = 0; p < PrimaryString.length; p++) {
                            buildup = buildup+ PrimaryString[p]
                            if(p === PrimaryString.length-1){
                              lowertext += buildup.toLowerCase();
                            }
                            else{
                              lowertext += buildup.toLowerCase() + ",";
                            }
                        }          
                        NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(NewDataObj[header.id].trim().split(' '))         
                        NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(lowertext.split(','))  
                        NewDataObj.SearchQuery = NewDataObj.SearchQuery.map(entry => {
                          return entry.toLowerCase()
                        })                      
                        //NewDataObj[header.id+'Query'].length = NewDataObj[header.id+'Query'].length-1
                      }
                      if(header.Primary){
                        NewDataObj[header.id+'Query'] = []
                        let PrimaryString = NewDataObj[header.propvalue].trim()
                        var lowertext = "";                
                        var p;
                        let buildup = ''
                        //console.log(PrimaryString.length,PrimaryString)
                        for (p = 0; p < PrimaryString.length; p++) {
                            buildup = buildup+ PrimaryString[p]
                            if(p === PrimaryString.length-1){
                              lowertext += buildup.toLowerCase();
                            }
                            else{
                              lowertext += buildup.toLowerCase() + ",";
                            }
                        }                   
                        NewDataObj[header.id+'Query'] = lowertext.split(',') 
                      }
                      
                      if(this.editedIndex > -1){
                        
                        //console.log(header.propvalue,NewDataObj[header.propvalue],NewDataObj)
                        if(typeof NewDataObj[header.propvalue] !== 'undefined'){  
                          if(header.id === 'Status'){
                            NewDataObj.State_Modified_Date = new Date()
                             if(this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.editedItem.Combined_Title){
                                const functions = firebase.functions();
                                const UpdateRecordData = functions.httpsCallable('UpdateRecordData'); 
                                let payload = {
                                  collection: this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings',
                                  RecordID: this.editedItem.id,
                                  UpdatedValues: [
                                    {Prop: 'Status', Value: NewDataObj.Status,Prop: 'Status_Reason', Value: NewDataObj.Status_Reason}
                                  ],
                                  siteid: this.$store.state.ActiveSuiteid
                                }
                                UpdateRecordData(payload).then(result => {
                                   //console.log(result)
                                })
                             }
                          }
                           }
                      }
                  }
                  let vm = this//console.log(headerindex)
                  if(headerindex-1+2 === length){
                    let status = this.CurrentEntityFields.find(obj => obj.id === 'Status')
                    let Status = status.DefaultOption
                    let Status_Reason = status.DefaultLevel2Option
                    if(!NewDataObj.Status){
                      NewDataObj.Status = Status
                    }
                    if(!NewDataObj.Status_Reason){
                      NewDataObj.Status_Reason = Status_Reason
                    }
                    if(this.CalendarTypeEntity){
                      console.log('this.editedItem',this.editedItem)
                          NewDataObj.start = this.editedItem.start
                          NewDataObj.end = this.editedItem.end
                          NewDataObj.timed = true
                          NewDataObj.StartObject = this.editedItem.StartObject
                          NewDataObj.Start = format(this.editedItem.StartObject,'yyyy-MM-dd')
                          let starthours = NewDataObj.StartObject.getHours()
                          let startmins = NewDataObj.StartObject.getMinutes()
                          if(starthours === 0){
                            starthours = '00'
                          }
                          if(startmins === 0){
                            startmins = '00'
                          }
                          NewDataObj.StartTimeResponse = starthours+':'+startmins
                          NewDataObj.EndObject = this.editedItem.EndObject
                          NewDataObj.End = format(this.editedItem.EndObject,'yyyy-MM-dd')
                          let endhours = NewDataObj.EndObject.getHours()
                          let endmins = NewDataObj.EndObject.getMinutes()
                          if(endhours === 0){
                            endhours = '00'
                          }
                          if(endmins === 0){
                            endmins = '00'
                          }
                          NewDataObj.EndTimeResponse = endhours+':'+endmins
                        }
                      if(this.editedIndex === -1){
                        let newdocid = ''
                        let ref = this.TableCollectionRef.doc()
                        if(this.MasterTableObj && this.MasterTableObj.ConfigValueasDocID && this.MasterTableObj.DocIDProp){
                          // encodeURIComponent(NewDataObj[this.MasterTableObj.DocIDProp])
                          //no, soon as you replace wit URI the route gets confused, we tried simply replacing "/" with %2F and then record calling goes to 404
                          newdocid = NewDataObj[this.MasterTableObj.DocIDProp].split(' ').join('_').split("'").join('').split('"').join('').split('/').join('')
                        }
                        if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Plugins'){
                          newdocid = NewDataObj.Name.split(' ').join('_').split('/').join('')
                        }
                        else if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
                        ){
                          newdocid = NewDataObj.Product_Code.split(' ').join('_').split('/').join('')
                        }
                        else if(this.UserCompaniesEntity){
                          // db.collection('Databases').doc(this.ActiveSuiteid).collection('AutoNumbers').doc('BillingAccounts_Customer_ID').get().then(snapshot => {
                          //   let data = snapshot.data()
                          //   let codedprefix = data.Prefixes[0]
                          //   codedprefix.Prefix_Value.Prop = "Company_Name"
                          //   let stdprefix = {Prefix_Value: 'CO-',Type: 'Standard'}
                          //   data.Prefixes = [stdprefix,codedprefix]
                          //   data.Last_Numbers = []
                          //   data.Last_Value = ''
                          //   this.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('AutoNumbers').doc('User_Companies_Company_ID').set(data)
                          //   db.collection('Databases').doc(this.ActiveSuiteid).collection('AutoNumbers').doc('User_Companies_Company_ID').set(data)
                          // })
                          const functions = firebase.functions();
                          
                          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
                          let payload = {
                            Docid: this.CurrentEntity.id+'_Company_ID',
                            Data: NewDataObj,
                            siteid: this.$store.state.ActiveSuiteid
                          }
                          AssignAutoNumber(payload).then(result => {
                            newdocid = result.data
                            NewDataObj.Company_ID = newdocid
                            vm.FinalizeRecordLoading(newdocid,NewDataObj)
                          })
                        }
                        else if(this.StoreOrderEntity){
                          const functions = firebase.functions();
                          
                          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
                          let payload = {
                            Docid: this.CurrentEntity.id+'_SO_Number',
                            Data: {},
                            siteid: this.$store.state.ActiveSuiteid
                          }
                          AssignAutoNumber(payload).then(result => {
                            newdocid = result.data
                            NewDataObj.SO_Number = newdocid
                            vm.FinalizeRecordLoading(newdocid,NewDataObj)
                          })
                        }
                        else if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Supplier'){
                          const functions = firebase.functions();
                          
                          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
                          let payload = {
                            Docid: 'Store_Suppliers_Supplier_Code',
                            Data: NewDataObj,
                            siteid: this.$store.state.ActiveSuiteid
                          }
                          AssignAutoNumber(payload).then(result => {
                            newdocid = result.data
                            NewDataObj.Supplier_Code = newdocid
                            vm.FinalizeRecordLoading(newdocid,NewDataObj)
                          })
                        }
                        else if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Billing Account'){
                          const functions = firebase.functions();
                          
                          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
                          let payload = {
                            Docid: 'BillingAccounts_Customer_ID',
                            Data: NewDataObj,
                            siteid: this.$store.state.ActiveSuiteid
                          }
                          AssignAutoNumber(payload).then(result => {
                            newdocid = result.data
                            NewDataObj.Customer_ID = newdocid
                            vm.FinalizeRecordLoading(newdocid,NewDataObj)
                          })
                        }
                        else{
                          newdocid = ref.id
                        }
                        //console.log(NewDataObj)

                        //IN HERE WE SMACK THIS
                        
                      if(newdocid){
                        vm.FinalizeRecordLoading(newdocid,NewDataObj)
                      }
                      
                      }
                      else{                        
                        let uploadfiles = Headers.filter(fileheader => {
                          return fileheader.Type === 'Single File Upload' && NewDataObj[fileheader.propvalue].UploadFile
                        })
                        console.log(NewDataObj,uploadfiles)
                        if(uploadfiles.length > 0){
                          uploadfiles.map((fileheader,fileheaderindex) => {
                            let file = NewDataObj[fileheader.propvalue].UploadFile
                              let filename = NewDataObj[fileheader.propvalue].FileName
                              let filestorageref = this.TableNameSplit+'/'+this.editedItem.id+'/'+fileheader.propvalue+'/'+filename
                              this.onPhotoUpload(filename,file,filestorageref).then(fileobj => {
                                NewDataObj[fileheader.propvalue] = fileobj
                                if(fileheaderindex-1+2 === uploadfiles.length){
                                  vm.$emit('ActivateProcessing',false)
                                  this.TableCollectionRef.doc(this.editedItem.id).update(NewDataObj).then(updateddoc => {
                                  this.close()
                                  this.RefreshComponent() 
                                  })
                                }
                              })
                          })
                        }
                        else{
                          this.TableCollectionRef.doc(this.editedItem.id).update(NewDataObj).then(updateddoc => {
                           this.close()
                          this.RefreshComponent() 
                          })
                        }
                          
                          
                      // })
                      }
                  }
              })
              
        },
        FinalizeRecordLoading(newdocid,NewDataObj){
          //console.log('this.TableCollectionRef',this.TableCollectionRef,newdocid,NewDataObj)
          this.TableCollectionRef.doc(newdocid).set(NewDataObj).then(doc => {
                        //console.log('ph setting')
                        if(this.FinalTableEntity.DefaultEntityProcess && this.editedIndex === -1){
                          let defaultprocess = this.FinalTableEntity.EntityProcesses.find(obj => obj.Number === this.FinalTableEntity.DefaultEntityProcess)
                          if(defaultprocess){
                            let processprop = 'RecordProcess'+defaultprocess.Number
                            this.TableCollectionRef.doc(newdocid).collection('processes').add(defaultprocess).then(processdoc => {
                              this.TableCollectionRef.doc(newdocid).update({
                                ActiveProcess: defaultprocess,
                                ActiveProcessDocid: processdoc.id,
                                [processprop]: processdoc.id,
                                stagename: defaultprocess.ProcessStages[0],
                                stagenameid: defaultprocess.ProcessStages[0].Number
                              })
                            })
                            if(NewDataObj.Owner){
                              NewDataObj.id = newdocid 
                              NewDataObj.ActiveProcess = defaultprocess
                              this.CreateLiaisonNotificationtoOwner(NewDataObj)
                            }
                            
                          }
                          else if(NewDataObj.Owner){
                              NewDataObj.id = newdocid 
                              this.CreateLiaisonNotificationtoOwner(NewDataObj)
                          }
                        }
                        else if(NewDataObj.Owner){
                              NewDataObj.id = newdocid 
                              this.CreateLiaisonNotificationtoOwner(NewDataObj)
                          }
                        if(this.$route.meta.EntityBuilder){
                          let configref = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.id)
                          this.AnalyzeandUpdateEntity(NewDataObj.Entity_Type.Name,this.TableCollectionRef.doc(newdocid),NewDataObj)
                        }
                        if(this.SystemBuilderTable && this.editedIndex === -1){
                          console.log('it is systembuilder yes')
                          let fieldscount = this.DefaultSystemEntities.map(ent => {
                            return ent.AllFields.length
                          }).reduce((a, b) => a + b, 0)
                          let fieldcounter = 0
                          this.DefaultSystemEntities.map(ent => {
                            let entobj = Object.assign({},ent)
                            entobj.id = entobj.DisplayName.split(' ').join('_')
                            delete entobj.AllFields
                            this.TableCollectionRef.doc(newdocid).collection('entities').doc(entobj.id).set(entobj).then(newnetobj => {
                              ent.AllFields.map(field => {
                                this.TableCollectionRef.doc(newdocid).collection('entities').doc(entobj.id).collection('AllFields').doc(field.id).set(field).then(newfieldoc => {
                                  fieldcounter++
                                  if(fieldcounter === fieldscount){
                                     this.close()
                                      this.RefreshComponent()
                                  }
                                })
                              })
                            })

                          })
                        }
                        else{
                          //console.log('added new entry under '+newdocid)
                          this.close()
                          this.RefreshComponent()
                        }
                        
                          
                      })  
        },
        close(preponly){
          this.quickeditstep = 1
          this.schedstep = 1
            this.editedIndex = -1
            this.MasterHeaders = this.MasterHeaders.map(headobj => {
           let header = Object.assign({},headobj)
              if(this.IsRelatedTable && this.tab.RelationshipField.Name === header.Name || this.IsRelatedTable && this.tab.RelationshipField && header.MappedRelationshipField){

              } 
              else{
                header.Response = ''
              }
              if(header.DefaultOption){
                      header.Response = header.DefaultOption
                  }
                  //console.log(header.id,header.Response)
                  return header
            })
            this.editedItem = Object.assign(this.defaultItem)
            if(!preponly){
              this.recordformdialog = false
              this.scheddialog = false
            }
            this.Validating = false
        },
        CreateLiaisonNotificationtoOwner(record){
        let owneruser = record.Owner
        let NewComms = {
          description: this.userLoggedIn.Full_Name+' assigned the record to '+owneruser.Full_Name,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.FinalTableEntity.DisplayName,
          recordlocation: '/'+this.FinalTableEntity.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.FinalTableEntity.id,
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        //console.log(NewComms)
        this.SaveNewcomms(NewComms,record)  
    },
    SaveNewcomms(NewComms,record){
        let commscol = db.collection(this.FinalTableEntity.id.split('_').join('').toLowerCase()).doc(record.id).collection('InternalLiaison')
        //console.log(commscol)
        let owneruser = record.Owner
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.FinalTableEntity.Primary_Field_Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.FinalTableEntity.Primary_Field_Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
        AnalyzeandUpdateEntity(type,configref,newobject){
          let fieldstoadd = []
          //we should be very careful on "fieldstoadd", considering all fields should strictly speaking be on template
          //on plenty of these we only missing "EntityRequired" when we add them on Ui but other than that should eb able to add them
          //that being said I believe almost true that every field on a template entity should be assumed EntityRequired...
          let defaultsubcols = []
          let recordprimaryfield = {Name: newobject.Primary_Field_Name.split(' ').join('_').split('(').join('').split(')').join('').split('/').join(''), DisplayName: newobject.Primary_Field_Name, Type: 'Single Line Text',Primary: true, Created: true,IsSearch: true,IsMandatory: true,IsHeader: true,HeaderIndex: 0}
          let CreatedBy = {Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let CreatedOn = {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let ModifiedBy = {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let ModifiedOn = {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let State_Modified_Date = {Name: 'State_Modified_Date', DisplayName: 'State Modified Date', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let Status = this.DefaultStatusField
          let UserUnit = {Name: 'Business_Unit', DisplayName: 'Business Unit',Type: 'Lookup', Created: true, SystemRequired: true,LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'BusinessUnit', RelatedRecord: 'BusinessUnits',UsedonForm: true,UsedonCompForm: true, UsedonForm: true}
          fieldstoadd.push(recordprimaryfield)
          fieldstoadd.push(CreatedBy)
          fieldstoadd.push(CreatedOn)
          fieldstoadd.push(ModifiedBy)
          fieldstoadd.push(ModifiedOn)
          fieldstoadd.push(State_Modified_Date)
          //if(type !== 'Ticketing Table'){
          fieldstoadd.push(Status)
          //}
          fieldstoadd.push(UserUnit)
          //console.log(type,fieldstoadd,configref)
          // if(type === 'Product Order'){
          //   fieldstoadd.push({Name: 'User', DisplayName: 'User', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'User',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Name', DisplayName: 'Name', Type: 'Single Line Text',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Name',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Surname', DisplayName: 'Surname', Type: 'Single Line Text',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Name',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Email', DisplayName: 'Email', Type: 'Common Field',CommonFieldType: 'Email',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Email',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Phone', DisplayName: 'Phone', Type: 'Common Field',CommonFieldType: 'Telephone Number',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Mobile_Number',LoggedInUser: true,}})
          // }
          if(type === 'Classified Listing'){
              // Rememebr now, recordprimary HAS to be "title", don't need to "add" title as a field
              //you are WRONG. Title gets added, becuase the entit itself still has a primary field. 
              fieldstoadd.push({Type: 'Single Line Text', Name: 'Title', DisplayName: 'Title',text: 'Title',value: 'Title',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 1,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 2,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'Price', DisplayName: 'Price',text: 'Price',value: 'Price',IsHeader: true,IsMandatory: true,HeaderIndex: 3,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Single File Upload', Name: 'Photo', DisplayName: 'Photo',text: 'Photo',value: 'Photo',FeaturedPhoto: true,EntityTypeRequired: true})
              //YET TO ADD GALLERY
          }
          else if(type === 'Ticketing Table'){
              //fieldstoadd.push(this.DefaultTicketingStatusField)
              fieldstoadd.push({Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: true,  EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 2,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'Priority', DisplayName: 'Priority',text: 'Priority',value: 'Priority',IsHeader: true,IsMandatory: true,HeaderIndex: 3,Options: [{ID: 1000001, Name: 'Low'},{ID: 1000002, Name: 'Medium'},{ID: 1000003, Name: 'High'}],lastassignedinteger: 1000003,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Due_Date', DisplayName: 'Due Date',text: 'Due Date',value: 'Due_Date',IsHeader: true,IsMandatory: true,HeaderIndex: 4,EntityTypeRequired: true})
              //fieldstoadd.push({Type: 'Common Field',CommonFieldType: 'URL Link', Name: 'ViewURL', DisplayName: 'ViewURL',text: 'ViewURL',value: 'ViewURL',IsHeader: true,IsMandatory: true,HeaderIndex: 5,EntityTypeRequired: true})
              
          }
          else if(type === 'Activities'){
              //fieldstoadd.push(this.DefaultTicketingStatusField)
              fieldstoadd.push({Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: true,  EntityTypeRequired: true})
              fieldstoadd.push({Name: 'Business_Unit', DisplayName: 'Business Unit',text: 'Business Unit',value: 'Business_Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'Type', DisplayName: 'Type',text: 'Type',value: 'Type',IsHeader: true,IsMandatory: true,HeaderIndex: 3,Options: [{
                ID: 1000001,
                Name: 'Task'
              },
              {
                ID: 1000002,
                Name: 'Appointment'
              },
              {
                ID: 1000003,
                Name: 'Email'
              },
              {
                ID: 1000004,
                Name: 'Phone Call'
              }],lastassignedinteger: 1000003,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 4,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'Priority', DisplayName: 'Priority',text: 'Priority',value: 'Priority',IsHeader: true,IsMandatory: true,HeaderIndex: 5,Options: [{ID: 1000001, Name: 'Low'},{ID: 1000002, Name: 'Medium'},{ID: 1000003, Name: 'High'}],lastassignedinteger: 1000003,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Due_Date', DisplayName: 'Due Date',text: 'Due Date',value: 'Due_Date',IsHeader: true,IsMandatory: true,HeaderIndex: 6,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Boolean', Name: 'Has_Reminder', DisplayName: 'Has Reminder',text: 'Has Reminder',value: 'Has_Reminder',IsHeader: true,IsMandatory: true,HeaderIndex: 7,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Completed_On', DisplayName: 'Completed On',text: 'Completed On',value: 'Completed_On',IsHeader: false,IsMandatory: false,EntityTypeRequired: true,UsedonForm: true})
              
              //fieldstoadd.push({Type: 'Common Field',CommonFieldType: 'URL Link', Name: 'ViewURL', DisplayName: 'ViewURL',text: 'ViewURL',value: 'ViewURL',IsHeader: true,IsMandatory: true,HeaderIndex: 5,EntityTypeRequired: true})
              
          }
          else if(type === 'Calendar'){
            //same here, primary field MUST be "title"
            //again is this not WRONG?. Ref Classified Listing
              fieldstoadd.push({Type: 'Date', Name: 'Start', DisplayName: 'Start',text: 'Start',value: 'Start',IsHeader: true,IsMandatory: true,HeaderIndex: 1,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'End', DisplayName: 'End',text: 'End',value: 'End',IsHeader: true,IsMandatory: true,HeaderIndex: 2,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 3,EntityTypeRequired: true})
          }
          else if(type === 'Booking Schedule'){
            
            //same here, primary field MUST be "title"
            //again is this not WRONG?. Ref Classified Listing
              fieldstoadd.push({Type: 'Option Set', Name: 'Type', DisplayName: 'Type',text: 'Type',value: 'Type',IsHeader: true,IsMandatory: true,HeaderIndex: 1,Options: [],lastassignedinteger: 1000000,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Start', DisplayName: 'Start',text: 'Start',value: 'Start',IsHeader: true,IsMandatory: true,HeaderIndex: 2,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'End', DisplayName: 'End',text: 'End',value: 'End',IsHeader: true,IsMandatory: true,HeaderIndex: 3,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 4,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'RSVP_Advance_Type', DisplayName: 'RSVP Advance Type',text: 'RSVP Advance Type',value: 'RSVP_Advance_Type',IsHeader: true,IsMandatory: true,HeaderIndex: 5,Options: [{ID: 1000001,Name: 'Minutes'},{ID: 1000002,Name: 'Hours'},{ID: 1000003,Name: 'Days'}],lastassignedinteger: 1000003,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'RSVP_Type', DisplayName: 'RSVP Type',text: 'RSVP Type',value: 'RSVP_Type',IsHeader: true,IsMandatory: true,HeaderIndex: 6,Options: [{ID: 1000001,Name: 'Minutes'},{ID: 1000002,Name: 'Hours'},{ID: 1000003,Name: 'Days'}],lastassignedinteger: 1000003,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'RSVP_Advance_Amount', DisplayName: 'RSVP Advance Amount',text: 'RSVP Advance Amount',value: 'RSVP_Advance_Amount',IsHeader: true,IsMandatory: true,HeaderIndex: 7,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'RSVP_Amount', DisplayName: 'RSVP Amount',text: 'RSVP Amount',value: 'RSVP_Amount',IsHeader: true,IsMandatory: true,HeaderIndex: 8,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'User_Limit', DisplayName: 'User Limit',text: 'User Limit',value: 'User_Limit',IsHeader: true,IsMandatory: true,HeaderIndex: 9,EntityTypeRequired: true})
              fieldstoadd.push({Name: 'Presenter', DisplayName: 'Presenter',text: 'Presenter',value: 'Presenter', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: true,HeaderIndex: 10, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Single File Upload', Name: 'Image', DisplayName: 'Image',text: 'Image',value: 'Image',FeaturedPhoto: true,EntityTypeRequired: true})
              let userprimfield = {id: 'Full_Name',DisplayName: 'Full Name',Name: 'Full_Name',text: 'Full Name',value: 'Full_Name',propvalue: 'Full_Name',class: 'overline',Type: 'Single Line Text',Primary: true,EntityTypeRequired: true}
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Session_Outcome', DisplayName: 'Session Outcome',text: 'Session Outcome',value: 'Session_Outcome',IsContentEditable: true,EntityTypeRequired: true})
              defaultsubcols.push({
                id: 'BookedUsers',ConfigCollection: 'SubCollections',DisplayName: 'Booked Users',Name: 'BookedUsers',OptionType: 'SubCollection',SectionType: 'SubCollection',MultipleFileUploadType: 'SubCollection',
                HeadersInteger: 1000001,
                Headers:[userprimfield],
                Lookup_Once_Only: true,
                LookupBuilds: [
                  {
                    AllFields: [userprimfield],
                    Entity_Type: {ID: 1000004,Name: 'User Entity'},
                    LinkedFields: {Full_Name: userprimfield},
                    dataseticon: 'mdi-face',
                    identifier: 'Full_Name',
                    id: 'Users',
                    SubCollections: [],
                    recordprimaryfield: userprimfield,
                    single: 'User',
                    tempname: 'User',
                    temppluralname: 'Users'
                  }
                ],
                Type: 'Lookup'
              })
          }

          else if(type === 'Inventory Article') {
            fieldstoadd.push({
                            id: 'Product_Group',
                            Name: 'Product_Group',
                            DisplayName: 'Product Group',
                            ListPrimary: true,
                            LastLevel: 3,
                            Levels: [{id: 1,FieldName: 'Product_Group',name: 'Product Group',children: [{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]}]},{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]},{id: 3,FieldName: 'Product_Type',name: 'Product Type'}],
                            LevelTree: [{id: 1,FieldName: 'Product_Group',name: 'Product Group',children: [{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]}]}],
                            Type: 'Option Set',
                            Options: [],
                            lastassignedinteger: 1000000,
                            HasDeepHierarchy: true,
                            EntityTypeRequired: true
                        })
                      fieldstoadd.push({Type: 'Single File Upload', Name: 'Image', DisplayName: 'Image',text: 'Image',value: 'Image',FeaturedPhoto: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Descriptive_Text', DisplayName: 'Descriptive Text',text: 'Descriptive Text',value: 'Descriptive_Text',IsContentEditable: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Average_Price', DisplayName: 'Average Price',text: 'Average Price',value: 'Average_Price',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Supply_Leadtime', DisplayName: 'Supply Leadtime',text: 'Supply Leadtime',value: 'Supply_Leadtime',EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Purchase_Leadtime', DisplayName: 'Purchase Leadtime',text: 'Purchase Leadtime',value: 'Purchase_Leadtime',EntityTypeRequired: true})
              
          }
          else if(type === 'Store Product') {
                      let costprice = {Type: 'Number Field', Name: 'Cost_Price', DisplayName: 'Cost Price',text: 'Cost Price',value: 'Cost_Price',EntityTypeRequired: true}
                      let markup = {Type: 'Number Field', Name: 'Markup', DisplayName: 'Markup',text: 'Markup',value: 'Markup',IsPercentage: true,EntityTypeRequired: true}
                      let storeprice = {Type: 'Calculated Field', Name: 'Store_Price', DisplayName: 'Store Price',text: 'Store Price',value: 'Store_Price',EntityTypeRequired: true,
                      FieldFormulas: [
                        {fieldName: costprice,hasoperation: true,input: 'field',integer: 0, operation: '+'},
                        {fieldName: markup,hasoperation: true,input: 'percentage field',integer: 0, operation: '='}
                      ]}
                      let discount = {Type: 'Number Field', Name: 'Discount', DisplayName: 'Discount',text: 'Discount',value: 'Discount',IsPercentage: true,EntityTypeRequired: true}
                      fieldstoadd.push(costprice)
                      fieldstoadd.push(discount)
                      fieldstoadd.push(markup)
                      fieldstoadd.push(storeprice)
                      fieldstoadd.push({Type: 'Calculated Field', Name: 'Store_Price', DisplayName: 'Store Price',text: 'Store Price',value: 'Store_Price',EntityTypeRequired: true,
                      FieldFormulas: [
                        {fieldName: storeprice,hasoperation: true,input: 'field',integer: 0, operation: '-'},
                        {fieldName: discount,hasoperation: true,input: 'percentage field',integer: 0, operation: '='}
                      ]})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Available', DisplayName: 'Available',text: 'Available',value: 'Available',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'On_Hand', DisplayName: 'On Hand',text: 'On Hand',value: 'On_Hand',ReadOnly: true,EntityTypeRequired: true})                      
                      fieldstoadd.push({Type: 'Number Field', Name: 'On_Order', DisplayName: 'On Order',text: 'On Order',value: 'On_Order',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Received', DisplayName: 'Received',text: 'Received',value: 'Received',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Picking', DisplayName: 'Picking',text: 'Picking',value: 'Picking',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Packing', DisplayName: 'Packing',text: 'Packing',value: 'Packing',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Dispatching', DisplayName: 'Dispatching',text: 'Dispatching',value: 'Dispatching',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Total_Received', DisplayName: 'Total Received',text: 'Total Received',value: 'Total_Received',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Average_Price', DisplayName: 'Average Price',text: 'Average Price',value: 'Average_Price',ReadOnly: true,Iscurrency: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Latest_Price', DisplayName: 'Latest Price',text: 'Latest Price',value: 'Latest_Price',ReadOnly: true,Iscurrency: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Total_Cost', DisplayName: 'Total Cost',text: 'Total Cost',value: 'Total_Cost',ReadOnly: true,Iscurrency: true,EntityTypeRequired: true})              
          }
          //Store Products - Brand only?
          //Scheduled Bookings - ??? Type, Calendar Std What else?
          //User Entity - ???
          //Business Unit Entity - ???


                fieldstoadd.map(field => {
                  configref.collection('AllFields').doc(field.Name).set(field)
                })
                defaultsubcols.map(subcol => {
                  configref.collection('SubCollections').doc(subcol.id).set(subcol)
                })
                configref.update({
                  RecordPrimaryField: recordprimaryfield,
                  SingleBuildTabs: [],
                })
        },
        ActivateExportDialog(array,headers){
          this.ExportDataArray = array.map(item => {
            delete item.Children
            delete item.headers
            return item
          })
          this.ExportHeaders = Array.from(headers)
          this.exportdialog = true
        },
        closeExport(){
          this.exportdialog = false
          this.ExportData = []
          this.ExportHeaders = []
        },
        ExportData(){
        this.exportToCsv('download.txt',this.ExportDataArray)
        },
        ExportDataforReimport(){
      this.exportToCsv('download.txt',this.ExportDataArray,true)
    },
      exportToCsv(filename, exportdata,forreimport) {
        const headers = this.ExportHeaders.map(header => {
          return header.value.split('.')[0]
        }).map(header => {
          if(header === 'Primary_Field_Value'){
            header = this.CurrentEntity.Primary_Field_Name
          }
          return header
        })
        const headerrows = [headers]
        if(forreimport === true){
          headers.push('id')
          let idobj = { text: 'id', value: 'id'}
          this.ExportHeaders.push(idobj)
          headers.push('id')
          this.ExportHeaders.push(idobj)
        }
        //console.log(this.ExportDataArray)
        //console.log(this.ExportHeaders)
        const datarows = exportdata.map(row => {
          row.csvdata =  this.ExportHeaders.map(header => {
            
            let headervalue = ''
            let firstprop = header.value.split('.')[0]
            let secondprop = header.value.split('.')[1]
            
            if(secondprop){
              
              if(row[firstprop]){
                if(row[firstprop][secondprop]){   
                  let thirdprop = row[firstprop][secondprop]
                              
                headervalue = row[firstprop][secondprop]
                }
              }
            }
            else{
              if(row[firstprop]){
                
              headervalue = row[firstprop]
              }
            }   
            if(header.text === 'Owner'){
              if(row.Owner){
                headervalue = row.Owner.Full_Name
              }
              
            }         
            return headervalue
          })
          return row
          }).map(row => {
            return row.csvdata
          })
        const rows = headerrows.concat(datarows)
        

        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join("|")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", this.TableName+".csv");
            document.body.appendChild(link); // Required for FF

            link.click();
        },
    }
}
</script>

<style>
.dbtable .v-data-footer{
  font-family: "Roboto", Tahoma , sans-serif;
  font-weight: 300;
  font-size:  1em;
  letter-spacing: 0.1666666667;


}
/* .dbtable .v-data-footer .v-data-footer__pagination{ 
  position: relative;
  left:20%;
} */
/* .dbtable .v-data-footer .v-data-footer__icons-after {
  padding-left: 170px;

} */
.dbtable .v-data-footer .v-data-footer__icons-after .v-btn .v-btn__content{
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
}
/* .dbtable .v-data-footer .v-data-footer__icons-before {
 
  position: relative;
} */
.dbtable .v-data-footer .v-data-footer__icons-before .v-btn .v-btn__content{
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  

}


</style>