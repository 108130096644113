<template>
  <div :style="MobileView? '' : 'margin-top: 50px;'">
    <v-btn small dark color="warning" v-if="AdminView && AppQuickDashPlugin && !EditingDashboard"
    @click="CheckforQuickDashPlugin()">Edit</v-btn>
    <v-list style="padding: 30px;" v-if="AdminView && AppQuickDashPlugin && EditingDashboard">    
      <v-list-item dense class="justify-space-between">
      <v-btn small dark color="warning" @click="CancelDashEditing()">Cancel Edit</v-btn>
      <v-btn small dark color="success" @click="SaveQuickDashboard()">Save QuickDash</v-btn>
      <v-spacer>
      </v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="RAAdmin && DashboardEntity.PluginEntityid" v-bind="attrs" v-on="on"
                small dark color="success" @click="SetQuickDashbtoTemplate(DashboardEntity.PluginEntityid)">Set to RA Template</v-btn>                   
              </template>
                {{DashboardEntity.PluginEntityid}}<br>
                <span v-if="DashboardEntity.Pluginin">
                  {{DashboardEntity.Pluginin}}
                </span>
            </v-tooltip>
        
      </v-list-item>
      
      <v-select label="Type" @change="CheckElementItems()" :items="ElementTypeOptions" :readonly="EditedElementIndex > -1" v-model="EditedElement.QuickDashType"
      />
      <v-switch v-if="EditedElementIndex > -1" v-model="EditedElement.ShowTableFilters"
        label="Show Table Filters"
      />
      <!-- <v-text-field label="Title" v-model="EditedElement.Title"/> -->
      <v-card-text v-if="AdvancedFindView">
        <v-btn @click="AdvancedFindView = !AdvancedFindView" v-if="EditedElement.QuickDashType === 'QuickCounters'">Cancel Advanced Filter</v-btn>
            <AdvancedFindComponent :System="System" :SystemEntities="SystemEntities" :CurrentEntity="ComputedDashboardEntity"
              :Data="QuickData" @SaveAdvancedFind="SaveAdvancedFind" :Checks="ElementChecks"
              :AdvancedFindSaveName="'Update'"
              />
        </v-card-text>
      <v-card v-if="EditedElement.QuickDashType === 'QuickAlerts'">
        <v-btn @click="ActivateAdvancedFindView()" dark color="purple">
                  <v-icon>mdi-filter-menu-outline</v-icon>
                  Advanced Filter
                </v-btn>
        <v-card-text>           
           <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="EditedElement" :QuickEditSections="QuickAlertEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
            @AddGallerySubcolImages="AddGallerySubcolImages"
            />
            <v-select v-model="EditedElement.Color" label="Color" :items="LibraryColors"
            />
           
        </v-card-text>
        
        <v-card-text>
             <QuickAlerts :Alerts="ComputedEditedAlert.Alerts" v-if="ComputedEditedAlert.QuickDashType === 'QuickAlerts' && !EditedElement.Refreshing && ComputedEditedAlert.Alerts"
            />
        </v-card-text>
        <v-card-actions>
          <v-btn dark color="success" @click="SaveAlert()">Save</v-btn>
        </v-card-actions>
      </v-card>
       <v-card flat tile v-if="EditedElement.QuickDashType === 'QuickCharts'">         
          <v-card-text >
            <v-select item-text="DisplayName" return-object v-model="EditedElement.PrimaryDateFilter" label="Date Field" :items="ComputedDateFields"
            />
            <v-select v-model="EditedElement.TimeFilterOption" label="Timeline Filter" :items="['This Month','This Year']"
            />
            <v-select v-model="EditedElement.ChartBG" label="BG Color" :items="LibraryColors"
            />
            <v-btn dark color="blue" @click="RefreshChart()">Refresh</v-btn>
            <v-btn dark color="success" @click="SaveChart()">Save</v-btn>
          </v-card-text>
          <v-card-text >
             <QuickCharts :System="System" :SystemEntities="SystemEntities"
              :ChartObj="EditedChart.ChartObj" :i="0" v-if="EditedChart.QuickDashType === 'QuickCharts' && EditedChart.ChartObj && !RefreshingChartElement"
              />
          </v-card-text>
       </v-card>
      <v-card flat tile v-if="EditedElement.QuickDashType === 'QuickCounters'">         
          <v-card-text v-if="AddingCounters">
            <v-btn @click="AddingCounters = !AddingCounters" dark color="success">
              Cancel Adding
            </v-btn>
            {{EditedElement.RollupType}}
             <v-row class="justify-center my-1">
                <v-chip-group column>
                  <v-chip @click="ActivateAvailableFilter(EditedCounterElement,'RollupType',type)" v-for="type in RollupTypes" :key="type.itemObjKey">
                    {{type}}
                  </v-chip>
                </v-chip-group>
              </v-row>
               <v-row class="justify-center my-1" v-if="EditedCounterElement.RollupType === 'Amount'">
                <v-chip-group column>
                  <v-chip @click="ActivateAvailableFilter(EditedCounterElement,'NumberField',field.id)" v-for="field in NumberTypeFields" :key="field.itemObjKey">
                    {{field.id}}
                  </v-chip>
                </v-chip-group>
              </v-row>
              <v-row class="justify-center my-1">
                <v-chip-group column>
                  <v-chip @click="ActivateAvailableFilter(EditedCounterElement,'CounterType',type)" v-for="type in CounterTypes" :key="type.itemObjKey">
                    {{type}}
                  </v-chip>
                </v-chip-group>
              </v-row>
              <v-row class="justify-center my-1" v-if="EditedCounterElement.CounterType === 'Grouped' || EditedCounterElement.DataField">
                <v-chip-group column>
                  <v-chip @click="ActivateAvailableFilter(EditedCounterElement,'DataField',field.id)" v-for="field in GroupingTypeFields" :key="field.itemObjKey">
                    {{field.id}}
                  </v-chip>
                </v-chip-group>
              </v-row>
            <v-carousel class="background darken-4" v-model="counteritem" :height="300" >
                <v-carousel-item
                v-for="elmnt in AvailableCounterElements" :key="elmnt.itemObjKey"
                ><v-chip-group class="justify-center">
                  <v-chip class="purple" dark><v-icon>
                    {{elmnt.CounterType === 'Grouped' ? 'mdi-set-split' : 'mdi-set-merge'}}</v-icon>
                    {{elmnt.CounterType}}
                  </v-chip>
                  <v-chip class="purple" dark><v-icon>
                    {{elmnt.RollupType === 'Amount' ? 'mdi-calculator-variant' : 'mdi-counter'}}</v-icon>
                    {{elmnt.RollupType}}
                  </v-chip>
                  <v-chip v-if="elmnt.DataField" class="blue" dark><v-icon>
                    mdi-sort-ascending</v-icon>
                    {{elmnt.DataField}}
                  </v-chip>
                </v-chip-group>
                <v-btn @click="SelectCounterElement(elmnt)">Add</v-btn>
                 <QuickCounters style="padding:10px;" :Counters="ComputedEditedCounter.Counters" :CanInteract="false" :SystemEntities="SystemEntities"/>
                </v-carousel-item>
            </v-carousel>
          </v-card-text>  
          <v-card-text v-if="!AddingCounters">
            <v-card-title class="justify-space-between">
              
              <v-btn @click="ActivateAdvancedFindView()" dark color="purple">
                  <v-icon>mdi-filter-menu-outline</v-icon>
                  Advanced Filter
                </v-btn>
            <v-btn @click="StoreCounterItem()" dark color="success">
                  Save
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="RemoveCounterItem()" dark color="error">
                  Remove
                </v-btn>
                <v-btn @click="AddingCounters = !AddingCounters" dark color="blue">
                  More Counters
                </v-btn>
            </v-card-title>
            <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="EditedCounterElement" :QuickEditSections="QuickCounterEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
            @AddGallerySubcolImages="AddGallerySubcolImages"
            />
            <!-- {{RelatedDashboardEntity.id}} -->
             <v-select item-text="DisplayName" :items="OptionSetFields" return-object clearable label="Extended Filter Field" v-model="EditedCounterElement.ExtendedFilterField"
            />
            <v-select item-text="DisplayName" multiple :items="OptionSetFilterFields" return-object clearable label="Option set Filters" v-model="EditedCounterElement.OptionSetFilters"
            />
              <!-- <v-select item-text="DisplayName" :items="LookupChildFields" multiple return-object clearable label="Extended Child Filter Fields" v-model="EditedCounterElement.ExtendedChildFilterFields"
            /> -->
             
              <v-list>
                <v-list-item v-for="item in LookupChildFields" :key="item.itemObjKey">
                  <v-list-item-content>
                  {{item.DisplayName}}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn v-if="NotIncludedChildfilter(item)" @click="AddtoChildFilters(item)">Add</v-btn>
                    <v-btn v-if="!NotIncludedChildfilter(item)" @click="RemoveChildFilter(item)">Remove</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            <v-list v-if="EditedCounterElement.ExtendedChildFilterFields && EditedCounterElement.ExtendedChildFilterFields.length > 0">
              <v-list-item v-for="child in EditedCounterElement.ExtendedChildFilterFields" :key="child.itemObjKey">
                <v-list-item-content>
                  <v-list-item-title>{{child.DisplayName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{child.ChildEntityid}}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-select return-object v-model="child.PrefilterField" label="Child Filter" :items="ChildOptionFields(child)" item-text="Name"
                  />
                </v-list-item-content>
                <v-list-item-content v-if="child.PrefilterField">
                  <v-select v-model="child.Prefilter" return-object :items="child.PrefilterField.Options" item-text="Name" label="Child Filter"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-carousel class="background darken-4" v-model="counteritem" :height="300" >
                <v-carousel-item
                v-for="elmnt in EditedElement.Elements" :key="elmnt.itemObjKey"
                >
                <v-chip-group class="justify-center">
                  <v-chip class="purple" dark><v-icon>
                    {{elmnt.CounterType === 'Grouped' ? 'mdi-set-split' : 'mdi-check'}}</v-icon>
                    {{elmnt.CounterType}}
                  </v-chip>
                  <v-chip class="purple" dark><v-icon>
                    {{elmnt.RollupType === 'Amount' ? 'mdi-calculator-variant' : 'mdi-counter'}}</v-icon>
                    {{elmnt.RollupType}}
                  </v-chip>
                  <v-chip v-if="elmnt.DataField" class="blue" dark><v-icon>
                    mdi-sort-ascending</v-icon>
                    {{elmnt.DataField}}
                  </v-chip>
                </v-chip-group>
                 <QuickCounters style="padding:10px;" :Counters="ComputedEditedCounter.Counters" :CanInteract="false" :SystemEntities="SystemEntities"/>
                </v-carousel-item>
            </v-carousel>
          </v-card-text>     
      </v-card>
    </v-list>
    <v-btn v-if="ActiveDrillDownItem" @click="ActiveDrillDownItem = ''">Back</v-btn>
    
    <v-flex xs12 class="justify-center" v-for="(sum,sumindex) in ComputedTableSummaries" :key="sum.itemObjKey">
      <v-card-actions class="my-4" v-if="AdminView && !ActiveDrillDownItem && EditingDashboard && AppQuickDashPlugin">
      <v-btn dark color="warning"
        @click="ActivateEditedElement(sum,sumindex)">
        Edit
      </v-btn><v-btn dark color="error"
        @click="CheckDeleteElement(sum,sumindex)">
        Delete
      </v-btn>
      <v-btn :disabled="sumindex === 0" dark color="purple"
        @click="MoveElement('Up',sumindex)">
        <v-icon>mdi-chevron-up</v-icon> Move Up
      </v-btn>
      <v-btn :disabled="sumindex-1+2 === ComputedTableSummaries.length" dark color="purple"
        @click="MoveElement('Up',sumindex)">
        <v-icon>mdi-chevron-down</v-icon> Move Down
      </v-btn>
      </v-card-actions>
      <v-card-title style="padding: 0px;" class="background justify-center mediumoverline">
        <span v-if="!EditSummaryTitles">
          {{sum.Title}}
        </span>   
        <span v-if="EditSummaryTitles">
          <v-text-field label="Title" v-model="sum.Title"/>
        </span>      
          <v-btn v-if="AdminView && !EditSummaryTitles" @click="ToggleEditSummary()" dark color="warning">Edit</v-btn>
          <v-btn v-if="AdminView && EditSummaryTitles" @click="ToggleEditSummary()" dark color="warning">Cancel</v-btn>
          <v-btn v-if="AdminView && EditSummaryTitles" @click="SaveSummaryEdit(sum,sumindex)" dark color="green">Save</v-btn>
      </v-card-title>
      <v-card-title style="padding: 0px;" class="background justify-center mediumoverline" v-if="OptionSetsFilterFields && OptionSetsFilterFields.length > 0 && sum.ShowTableFilters">
        <span v-for="textfield in OptionSetsFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
           <v-autocomplete class="mx-5"
            :items="textfield.Options" 
            v-model="textfield.ActiveFilter"          
            item-text="Name"
            clearable
            :label="textfield.DisplayName"
            autowidth
            return-object
            chips
            flat dense
            />
           </div>
           </span>  
      </v-card-title>
      <v-card-title style="padding: 0px;" class="background justify-center"
      v-if="sum.QuickDashType === 'QuickCounters' && sum.Elements && sum.Elements[0] && sum.Elements[0].ExtendedFilterField
       && sum.Elements[0].ExtendedFilterField.Type === 'Option Set'">
       <v-chip-group col>
        <v-chip @click="ActivateDashFilterOption(sum,opt,sum.Elements[0].ExtendedFilterField)" v-for="opt in sum.Elements[0].ExtendedFilterField.Options" :key="opt.itemObjKey">
          {{opt.Name}}
        </v-chip>
       </v-chip-group>
      </v-card-title>
          <QuickCharts :System="System" :SystemEntities="SystemEntities"
          :ChartObj="sum.ChartObj" :i="0" v-if="sum.QuickDashType === 'QuickCharts'"
          />
         <!-- <QuickCounters style="padding:10px;"  -->
          <QuickCounters :Counters="sum.Counters" :CanInteract="sum.CanInteract" @InteractMethod="QuickDashboardInteract" :SystemEntities="SystemEntities"
           v-if="sum.QuickDashType === 'QuickCounters'" :DashFilterField="DashFilterField" :DashFilterOption="DashFilterOption"
                />
            <QuickAlerts :Alerts="sum.Alerts"  v-if="sum.QuickDashType === 'QuickAlerts'" @InteractMethod="QuickDashboardInteract" :CanInteract="true" :SystemEntities="SystemEntities"
            />
    </v-flex>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import QuickAlerts from '@/components/Dashboards/QuickAlerts';
import QuickCounters from '@/components/Dashboards/QuickCounters';
import QuickCharts from '@/components/Dashboards/QuickCharts';
import QuickEditComponent from '@/components/Database/Fields/Input/QuickEditComponent'
import AdvancedFindComponent from '@/components/Database/AdvancedFindComponent'
export default {
    props: ['System','SystemEntities','Alerts','MasterData','DashboardEntity','OptionSetsFilterFields','QuickDashPreview'],
    components: {QuickAlerts,QuickCounters,QuickCharts,QuickEditComponent,AdvancedFindComponent},
    data() {
        return {
          DashFilterOption: false,
          DashFilterSummary: '',
          DashFilterField: '',
          EditSummaryTitles: false,
          AdvancedFindView: false,
          EditingDashboard: false,
          Today: '',
          RefreshingChartElement: false,
          LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
          ActiveDrillDownItem: '',
          AddingCounters: false,
          ElementTypeOptions: [
              'QuickCounters','QuickCharts','QuickAlerts'
          ],
          counteritem: 0,
          EditedCounterElement: {
            BaseColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },
            Title: '',
            RollupType: '',
            DataField: null,
            CounterType: '',
            NumberField: '',
             Number: 0,
          },
          DefaultCounterElement: {
            BaseColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },
            Title: '',
            RollupType: '',
            DataField: null,
            CounterType: '',
            NumberField: '',
            Number: 0,
          },
          EditedElement: {QuickDashType: ''},
          EditedElementIndex: -1,
          CounterTypes: ['Rollup','Grouped'],
          RollupTypes: ['Count','Amount'],        
          TableSummaries: [],
            basicinfosheet:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,								
            },
            basecounter: {CounterIcon: '',Data: [],CounterInteger: 0, Title: 'Products',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
            BGColor: {hex: ''},HeaderColor: {hex: '#DD2C00'},FontColor: {hex: '#DD2C00'},FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,}
        }
    },
    computed:{
      QuickData(){
        return this.MasterData.filter(record => {
          if(this.DashFilterField && this.DashFilterOption){
            return record[this.DashFilterField.id] && record[this.DashFilterField.id].ID === this.DashFilterOption.ID
          }
          else{
            return record
          }
        })
      },
      QuickCounters(){
        return this.EntityQuickDashElements.filter(elmnt => {
          return elmnt.Type === 'QuickCounter'
        })
      },
      ChildEntityFilters(){
        return this.QuickCounters.filter(elmnt => {
          return elmnt.ExtendedChildFilterFields && elmnt.ExtendedChildFilterFields.length > 0
        })
      },
      RelatedDashboardEntity(){
        if(this.EditedCounterElement && this.EditedCounterElement.DataField){
          let datafield = this.DashboardEntity.AllFields.find(obj => obj.id === this.EditedCounterElement.DataField)
          if(datafield && datafield.Type === 'Lookup' && this.EditedCounterElement.RelatedTableFilter){            
            return this.SystemEntities.find(obj => obj.id === datafield.RelatedBuildID)
          }
          else{
          return this.DashboardEntity
        }   
        }
        else{
          return this.DashboardEntity
        }        
      },
      ExtendedfilterfieldOptions(){
        return this.LookupChildFields.concat(this.OptionSetFields)
      },
      LookupEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.AllFields.find(obj => obj.RelatedBuildID === this.RelatedDashboardEntity.id)
        }).map(ent => {
          let entobj = Object.assign({},ent)
          return entobj
        })
      },
      LookupChildFields(){
        let fields = this.LookupEntities.map(ent => {
          return ent.AllFields.filter(obj => { return obj.RelatedBuildID === this.RelatedDashboardEntity.id}).map(field => {
          let fieldobj = Object.assign({},field)
            fieldobj.DisplayName = field.DisplayName+' ('+ent.DisplayName+')'
            fieldobj.Type = 'Child Lookup'
            fieldobj.ChildEntityid = ent.id
            delete fieldobj.Array
            return fieldobj
          })          
        }).flat()
        console.log('fields',fields)
        return fields
      },
      OptionSetFields(){
        return this.RelatedDashboardEntity.AllFields.filter(field => {
          return field.Type === 'Option Set'
          && field.HasColors
        })
      },
      OptionSetFilterFields(){
        return this.RelatedDashboardEntity.AllFields.filter(field => {
          return field.Type === 'Option Set'
          && field.IsFilter
        })
      },
      MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ElementChecks(){
        if(this.EditedElement.QuickDashType === 'QuickAlerts'){
          return this.EditedElement.Checks
        }
        else if(this.EditedElement.QuickDashType === 'QuickCounters'){
          return this.EditedCounterElement.Checks
        }
      },
      LastYear(){
            return {Start: this.StartofLastYear,End: this.EndofLastYear}
        },
        StartofLastYear(){
            var year = this.EndofLastYear.getFullYear();			
            var month = 0;				
            var d = new Date(year, month, 1)	
            d.setHours(0,0,0,0)	
            return d
        },
        EndofLastYear(){
          let d = this.ThisJanuaryStart
          d.setDate(d.getDate() - 1)
            return this.EndofDay(d) 
        },
        NextYear(){
            return {Start: this.StartofNextYear,End: this.EndofNextYear}
        },
        StartofNextYear(){
            let d = this.ThisDecemberEnd
            d.setDate(d.getDate() + 1)
            d.setHours(0,0,0,0)
            return d
        },
        EndofNextYear(){
            var year = this.StartofNextYear.getFullYear();			
            var month = 11;				
            var d = new Date(year, month, 31)		
            return this.EndofDay(d)
        },
        NextMonth(){
            return {Start: this.StartofNextMonth,End: this.EndofNextMonth}
        },
        StartofNextMonth(){
            let d = this.EndofMonth(this.Today)
            d.setDate(d.getDate() + 1)
            return d
        },
        EndofNextMonth(){
            let d = this.EndofMonth(this.StartofNextMonth)
            return d
        },
        LastMonth(){
            return {Start: this.StartofLastMonth,End: this.EndofLastMonth}
        },
        StartofLastMonth(){		
            let d = this.EndofLastMonth	
            d.setDate(1)		
            d.setHours(0,0,0,0)			
        return d			
        },
        EndofLastMonth(){			
            let d = this.Today; // current date
            d.setDate(1); // going to 1st of the month
            d.setHours(-1); // going to last hour before this date even started.
            d.setHours(23,59,59,999)
            return d
        },
        LastWeek(){
            return {Start: this.LastweekFirstDate,End: this.LastweekLastDate}
        },
        LastweekFirstDate(){			
            let newtoday = new Date(this.Today)			
            var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
            let date = new Date(newtoday.setDate(lastdaylastweek - 7))			
            date.setHours(0,0,0,0)			
            return date			
        },
        LastweekLastDate(){			
            let newtoday = new Date(this.Today)			
            var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
            let date = new Date(newtoday.setDate(lastdaylastweek))			
            date.setHours(23,59,59,999)			
            return date			
        },	
        NextWeek(){
            return {Start: this.NextweekFirstDate,End: this.NextweekLastDate}
        },
        NextweekFirstDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek + 7))			
          date.setHours(0,0,0,0)			
          return date			
      },	
        NextweekLastDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek))			
          date.setHours(23,59,59,999)			
          return date			
      },
        DayofView(){
            return {Start: this.Today,End: this.EndofDay(this.Today)}
        },
        ThisWeek(){
            return {Start: this.ThisMonday,End: this.ThisSunday}
        },
        ThisMonth(){
            return {Start: this.CurrentMonthStart,End: this.CurrentMonthEnd}
        },
        ThisYear(){
            return {Start: this.ThisJanuaryStart,End: this.ThisDecemberEnd}
        },
        CurrentMonthStart(){
            let d = this.Today
            d.setDate(1);	
            d.setHours(0,0,0,0);
            return d
        }, 
        CurrentMonthEnd(){
            let d = this.Today
            return this.EndofMonth(d)
        }, 
        ThisMonday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+1))			
            date.setHours(0,0,0,0)			
            return date			
      },	
      ThisSunday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+7))			
          date.setHours(0,0,0,0)			
          return date			
      },
    
    ComputedToday(){			
          let date = new Date(this.Today)			
          date.setHours(0,0,0,0)			
          return date			
      },	
    ThisJanuaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 0;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },	
    ThisDecemberEnd(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 11;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return this.EndofDay(d)
    },
       RAAdmin(){
            return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
        },
        RAUserObj(){
            return this.$store.state.RAUserObj
        },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
      AppQuickDashPlugin(){
        return this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Quick_Dashboards')
      },
      ActiveSuiteApp(){
          return this.$store.state.ActiveSuiteApp
      },
      EditedChart(){
        let elmnt = Object.assign({},this.EditedElement)
        if(this.EditedElement && this.EditedElement.QuickDashType === 'QuickCharts' && this.EditedElement.PrimaryDateFilter && this.EditedElement.TimeFilterOption && !this.RefreshingChartElement){
           elmnt.ChartObj = this.PrepareChartObj(elmnt)
        }  
        //console.log(elmnt)     
        return elmnt
      },
      EntityQuickDashElements(){
        return this.DashboardEntity.QuickDashElements
      },
      QuickAlertEditSections(){
        let sect1fields = [
          {id: 'IncompleteName', Name: 'IncompleteName',DisplayName: 'Title',Type: 'Single Line Text'},
          {id: 'AlertDescription', Name: 'AlertDescription',DisplayName: 'Description',Type: 'Multiple Lines Text'},
           {id: 'AlertIcon', Name: 'AlertIcon',DisplayName: 'Icon',Type: 'Single Line Text'},
          ]        
         return [
            {Type: 'Fields',Fields: sect1fields,Class: 'flex xl12 lg12 md12 sm12 xs12'},
            //{Type: 'Fields',Fields: sect2fields,Class: 'flex xl6 lg6 md6 sm12 xs12'},
         ]
      },
      QuickCounterEditSections(){
        let sect1fields = [
          {id: 'Title', Name: 'Title',DisplayName: 'Title',Type: 'Single Line Text'},
           {id: 'CounterIcon', Name: 'CounterIcon',DisplayName: 'Icon',Type: 'Single Line Text'},
          {id: 'BaseColor', Name: 'BaseColor',DisplayName: 'Base Color',Type: 'Color Picker'},
          {id: 'IsTableRoute', Name: 'IsTableRoute',DisplayName: 'Route to Table',Type: 'Boolean'},
          {id: 'RelatedTableFilter', Name: 'RelatedTableFilter',DisplayName: 'Related Table Filter',Type: 'Boolean'}
          ].filter(field => {
            if(this.EditedCounterElement && this.EditedCounterElement.DataField && this.EditedCounterElement.CounterType === 'Rollup'){              
              let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === this.EditedCounterElement.DataField)
              if(datafield && datafield.Type === 'Lookup'){
                return field
              }
              else{
              return field.id !== 'IsTableRoute'
            }
            }
            else{
              return field.id !== 'IsTableRoute'
            }            
          })
        
         return [
            {Type: 'Fields',Fields: sect1fields,Class: 'flex xl12 lg12 md12 sm12 xs12'},
            //{Type: 'Fields',Fields: sect2fields,Class: 'flex xl6 lg6 md6 sm12 xs12'},
         ]
      },
      AdminView(){
        return this.userIsAdmin && !this.QuickDashPreview
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      ComputedDashboardEntity(){
        let ent = Object.assign({},this.DashboardEntity)
        ent.AllFields = ent.AllFields.map(field => {
          let fieldobj = Object.assign({},field)
          return fieldobj
        })
        .filter(field => {
          return field.IsHeader || field.Type !== 'Lookup'
        })
        if(ent.Security_Structure.Name === 'Warehouse'){
          ent.AllFields = ent.AllFields.concat([this.WarehouseField])
        }
        return ent
      },
      WarehouseField(){
        return {
                    Name: 'Warehouse',DisplayName: 'Warehouse',id: 'Warehouse',Type: 'Lookup',text: 'Warehouse',value: 'Warehouse',propvalue: 'Warehouse',HeaderIndex: 2,RelatedBuildID: 'Warehouses',LookupFieldName: 'Name',class: 'overline',Array: this.WarehousesArray
                    }
      },
      WarehousesArray(){
        return this.$store.state.WarehousesArray
      },
      FilteredWarehousesCreateArray(){
        return this.WarehousesArray.filter(item => {
            return this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[item.id] && this.userLoggedIn.Warehouses[item.id].Create || this.userIsAdmin
            })
      },
        AvailableCharts(){
          return []
        },
        ActiveGroupingTypeFields(){
          return this.GroupingTypeFields.filter(field => {
            return field.id === this.EditedCounterElement.DataField
          })
        },
        ActiveNumberFieldTypes(){
          return this.NumberTypeFields.filter(field => {
            return this.EditedCounterElement.NumberField && field.id === this.EditedCounterElement.NumberField && this.EditedCounterElement.RollupType === 'Amount'
          })
        },
        ComputedDateFields(){
          return this.DateFields.map(field => {
            return {id: field.id,Name: field.Name,DisplayName: field.DisplayName,Type: field.Type}
          })
        },
        DateFields(){
          return this.ComputedDashboardEntity.AllFields.filter(field => {
            return field.Type === 'Date'
          })
        },
        NumberTypeFields(){
          return this.ComputedDashboardEntity.AllFields.filter(field => {
            return field.Type === 'Number Field' || field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation'
          })
        },
        GroupingTypeFields(){
          return this.ComputedDashboardEntity.AllFields.filter(field => {
            return field.Type === 'Lookup' || field.Type === 'Option Set' || field.Type === 'Checklist'
          })
        },
        ComputedEditedAlert(){
          let elmntobj = Object.assign({},this.EditedElement)
          elmntobj = this.PrepAlertElmnt(elmntobj)         
          //console.log(elmntobj)
          return elmntobj
        },
        ComputedEditedCounter(){
          // let counterelmnt = {Type: 'QuickCounters',Title: 'Products',Elements:[]}
          let elmntobj = Object.assign({},this.EditedElement)
          if(!this.AddingCounters){
           elmntobj.Elements = elmntobj.Elements.filter((elmnt,elmntindex) => {
            return elmntindex === this.counteritem
          }) 
          }
          else{
            elmntobj.Elements = this.AvailableCounterElements.filter((elmnt,elmntindex) => {
              return elmntindex === this.counteritem
            }) 
          }
          
          if(elmntobj.QuickDashType === 'QuickCounters'){
            elmntobj = this.PrepCounterElemnt(elmntobj)
          }   
          //actually we gotta be "counteritem" conscious, or else all counters for teh entire quickcounter elmnt reurns. 
          //console.log(elmntobj)       
          return elmntobj
        },
        AvailableCounterElements(){
          let baserollup = [{Title: this.ComputedDashboardEntity.DisplayName,CounterType: 'Rollup',RollupType: 'Count',DataField: null,NumberField: null}]
          let basenumberfieldtypes = this.NumberTypeFields.map(numberfield => {
            return {Title: numberfield.DisplayName,CounterType: 'Rollup',RollupType: 'Amount',NumberField: numberfield.Name,DataField: null}
          })
          let basegroupingtypesrollup = this.GroupingTypeFields.map(datafield => {
            return {Title: datafield.DisplayName,DataField: datafield.Name,CounterType: 'Grouped',RollupType: 'Count',NumberField: null}
          })
          let basegroupingtypesgrouped = this.GroupingTypeFields.map(datafield => {
            return {Title: datafield.DisplayName,DataField: datafield.Name,CounterType: 'Rollup',RollupType: 'Count',NumberField: null}
          })
          
          let rollupgroupingsnumbered = this.NumberTypeFields.map(numberfield => {
           return this.GroupingTypeFields.map(datafield => {
              return {Title: datafield.DisplayName,DataField: datafield.Name,CounterType: 'Rollup',RollupType: 'Amount',NumberField: numberfield.Name}
            })
          }).flat()
          let groupedgroupingsnumbered = this.NumberTypeFields.map(numberfield => {
           return this.GroupingTypeFields.map(datafield => {
              return {Title: datafield.DisplayName,DataField: datafield.Name,CounterType: 'Grouped',RollupType: 'Amount',NumberField: numberfield.Name}
            })
          }).flat()   
          let elementlist = baserollup.concat(basenumberfieldtypes,basegroupingtypesrollup,basegroupingtypesgrouped,rollupgroupingsnumbered,groupedgroupingsnumbered) 
          //console.log(elementlist)
          elementlist = elementlist.map(elmnt => {
            if(!elmnt.BaseColor){
              elmnt.BaseColor = { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } }
            }            
            return elmnt
          }).filter(elmnt => {
            let props = ['RollupType','NumberField','CounterType','DataField']
            let pass = true
            props.map(prop => {
              //console.log(this.EditedCounterElement[prop],elmnt[prop])
              if(this.EditedCounterElement[prop] && !elmnt[prop] 
              || this.EditedCounterElement[prop] && elmnt[prop] !== this.EditedCounterElement[prop]){
                pass = false
              }
            })
            return pass
          })
          //console.log(elementlist)
          return elementlist
        },
        
        AvailableAlerts(){
          return []
        },
        ComputedActiveDrillDownItem(){
          if(this.ActiveDrillDownItem){
            if(this.ActiveDrillDownItem.QuickDashType === 'QuickCounters'){
              let filterobj = this.ActiveDrillDownItem
              filterobj = this.PrepCounterElemnt(filterobj)
              //console.log(filterobj)
              return filterobj
            }
          }
        },
        ComputedTableSummaries(){
          if(this.ActiveDrillDownItem){
            //console.log(this.ComputedActiveDrillDownItem)
           return [this.ComputedActiveDrillDownItem]
          }
          else{
            return this.TableSummaries
          .map((sum,sumindex) => {
            let sumobj = JSON.parse(JSON.stringify(sum))
            sumobj.SummaryID = sumindex
            if(sum.QuickDashType === 'QuickCounters'){
              sumobj.Elements = sumobj.Elements.map((elmnt,elmntindex) => {
                let elmntobj = Object.assign(elmnt)
                elmntobj.ElementID = elmntindex
                elmntobj.SummaryID = sumindex
                return elmntobj
              })
                sumobj = this.PrepCounterElemnt(sumobj)
              }
              else if(sumobj.QuickDashType === 'QuickCharts' && sumobj.PrimaryDateFilter){
                sumobj.ChartObj = this.PrepareChartObj(sumobj)
              }
              else if(sumobj.QuickDashType === 'QuickAlerts'){
                sumobj = this.PrepAlertElmnt(sumobj)
              }
              //console.log(sumobj)
              if(sum.Title){
                sumobj.Title = sum.Title
              }
              return sumobj
            })
          }
          //so it's here we return summaries, which are nothing but "rows", and in them elements, which are either quicksharts,quickcounters or quickalerts
          //the key? it computes...
        },
        AvailableSummaries(){
            return this.AvailableCharts.concat(this.AvailableCounters,this.AvailableAlerts)
        },
        RootElementOptions(){			
      let array = []			
      let activityobj = {			
        ConfigName: 'Activities in Timeline',			
        ConfigID: 1000001,			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.ActivitiesinTimeline			
        }),			
        HasChart: true,			
        HasDataContext: true,			
        ShowTitle: false,			
        ChartPosition: 'Top',			
        DataContextStyle: 'List',			
        ChartType: 'bar',			
        ChartLabelsfromTimeline: true			
        			
      }			
      array.push(activityobj)			
      let datatimelineobj = {			
        ConfigID: 1000002,			
        ConfigName: 'Data in Timeline',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.DatainTimeline			
        }),			
        HasChart: true,			
        HasDataContext: true,			
        ChartPosition: 'Top',			
        ChartLabelsfromTimeline: true,			
        DataContextStyle: 'List',			
        ChartType: 'line'			
      }			
      array.push(datatimelineobj)			
      let datachartscontextobj = {			
        ConfigID: 1000003,			
        ConfigName: 'Charts and Context',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.DatainTimeline			
        }),			
        ChartType: 'pie',			
        ShowTitle: true,			
        HasChart: true,			
        HasDataContext: true,			
        ChartPosition: 'Right',			
        DataContextStyle: 'List'			
      }			
      array.push(datachartscontextobj)			
      let dataprocessobj = {			
        ConfigID: 1000004,			
        ConfigName: 'Data in Process',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.DatainTimeline			
        }),        			
        ShowTitle: true,			
        HasChart: true,			
        HasDataContext: false,			
        ChartPosition: '',			
        ChartLabelsfromProcess: true,			
        DataContextStyle: ''			
      }			
      if(this.ComputedDashboardEntity.EntityProcesses && this.ComputedDashboardEntity.EntityProcesses.length>0){			
        array.push(dataprocessobj)			
      }			
      let goalmeasurementobj = {			
        ConfigID: 1000005,			
        ConfigName: 'Goal Measurements',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.GoalMeasurements			
        }),   			
        ChartType: 'line',     			
        ShowTitle: false,			
        ShowSummary: true,			
        ShowSummaryOnly: true,			
        HasChart: true,			
        HasDataContext: true,			
        ChartPosition: 'Top',			
        ChartLabelsfromTimeline: true,			
        DataContextStyle: 'List',			
        AccumulativeGraph: true,			
      }			
      if(this.ComputedDashboardEntity.GoalMeasurements && this.ComputedDashboardEntity.GoalMeasurements.length>0){			
        array.push(goalmeasurementobj)			
      }			
      			
      return array			
    },	
    
    ElementOptionsbyRoot(){			
      let array = []			
      //console.log('now')			
      if(this.ComputedDashboardEntity && this.ComputedDashboardEntity.id){			
        let RelatedChild = this.SystemEntities.find(obj => obj.id === 'Activities')			
        let PrimaryDateFilter = RelatedChild.AllFields.find(obj => obj.id === 'Due_Date')			
        let PreFilterField = RelatedChild.AllFields.find(obj => obj.id === 'Type')			
        let ChildRelationshipsField = {Name: 'Regarding_Record', DisplayName: 'Regarding Record',id: 'Regarding_Record',Type: 'WildCard'}		
        let taskprefilter = PreFilterField.Options.find(obj => obj.Name === 'Task')			
        let appointmentprefilter = PreFilterField.Options.find(obj => obj.Name === 'Task')			
        let ActivitiesOptions = [			
          {			
            Name: 'Tasks for '+this.ComputedDashboardEntity.DisplayName,			
            ActivitiesinTimeline: true,			
            PrimaryLabelType: 'Name of Child',			
            RelatedChild: RelatedChild,			
            PrimaryDateFilter: PrimaryDateFilter,			
            ValueType: 'Count',			
            ChildRelationshipsField: ChildRelationshipsField,			
            PreFilterField: PreFilterField,			
            PreFilter: taskprefilter			
          },			
          			
          {			
            Name: 'Appointments for '+this.ComputedDashboardEntity.DisplayName,			
            ActivitiesinTimeline: true,			
            PrimaryLabelType: 'Name of Child',			
            RelatedChild: RelatedChild,			
            PrimaryDateFilter: PrimaryDateFilter,			
            ValueType: 'Count',			
            ChildRelationshipsField: ChildRelationshipsField,			
            PreFilterField: PreFilterField,			
            PreFilter: appointmentprefilter 			
          }			
        ]			
        array = array.concat(ActivitiesOptions)			
        let fromtimelineobj = {			
            Name: this.ComputedDashboardEntity.DisplayName+' by Timeline',			
            PrimaryLabelType: 'From Timeline',			
            PrimaryLabelField: 'thisTimeline',			
            DatainTimeline: true			
        }			
        array.push(fromtimelineobj)			
        this.ComputedDashboardEntity.GoalMeasurements.map(goal => {			
          let goalobj = {			
            Name: goal.Name,			
            PrimaryLabelType: goal.PrimaryFilterType,			
            PrimaryLabelField: goal.PrimaryFilterField,			
            PrimaryFilterType: goal.PrimaryFilterType,			
            PrimaryFilterField: goal.PrimaryFilterField,			
            PrimaryDateFilter: goal.PrimaryDateFilter, 			
            ValueType: goal.ValueType,                       			
            GoalMeasurements: true,			
            GoalID: goal.id,			
        }			
        if(goal.ValueField){			
          goalobj.ValueField = goal.ValueField			
        }			
        if(goal.ValueCurrency){			
          goalobj.ValueCurrency = goal.ValueCurrency			
        }			
        if(goal.PreFilterField){			
          goalobj.PreFilterField = goal.PreFilterField			
        }			
        if(goal.PreFilter){			
          goalobj.PreFilter = goal.PreFilter			
        }			
        array.push(goalobj)			
        })			
        let groupings = this.ComputedDashboardEntity.AllFields.filter(field => {			
          return field.Type === 'Option Set'			
        })			
        let lookups = this.ComputedDashboardEntity.AllFields.filter(field => {			
          return field.Type === 'Lookup'			
        })			
        let concatarray = groupings.concat(lookups)			
        			
        let types = concatarray			
        .map(field => {			
          if(field.Type === 'Lookup'){			
            		
            let parentobj = this.SystemEntities.find(obj => obj.id === field.RelatedBuildID)			
            let primaryfield = parentobj.AllFields.find(obj => obj.Primary === true)			
            let lutabledata = this.$store.state[parentobj.id.split('_').join('')+'Array']
            //console.log(field.id,field.RelatedBuildID,parentobj.id,primaryfield)	
            return {			
              Name: this.ComputedDashboardEntity.DisplayName+' by '+field.DisplayName+' ('+primaryfield.DisplayName+')',			
              DatainTimeline: true,			
              PrimaryLabelType: 'Lookup',			
              PrimaryLabelField: field,
              Data: lutabledata,
              Icon: parentobj.Table_Icon
            }			
          }			
          else{			
            return {			
            Name: this.ComputedDashboardEntity.DisplayName+' by '+field.DisplayName,			
            PrimaryLabelType: 'Option Set',			
             PrimaryLabelField: field,			
             DatainTimeline: true,             
              Data: this.QuickData,
              Icon: this.ComputedDashboardEntity.Table_Icon 			
          }			
          }         			
        })			
        // console.log(types)			
        array = array.concat(types)			
        let parenttypes = []			
        let parents = lookups.map(field => {			
        let parentobj = this.SystemEntities.find(obj => obj.id === field.RelatedBuildID)			
          parentobj.OptionSets = parentobj.AllFields.filter(field => {			
            return field.Type === 'Option Set'			
          })			
          parentobj.ParentField = field			
          return parentobj			
        })			
			
        parents.map (parent => {			
          parent.OptionSets.map(field => {			
            let obj = {			
                Name: this.ComputedDashboardEntity.DisplayName+' by '+parent.DisplayName+' and '+field.DisplayName,			
                PrimaryLabelType: 'Option Set from Lookup',			
                PrimaryLabelLookup: parent.ParentField, 			
                PrimaryLabelField: field,			
                DatainTimeline: true			
              }			
              parenttypes.push(obj)			
          })			
        })			
        array = array.concat(parenttypes)			
      }			
      return array			
    },		
        HighlightedAlertElements(){
            return this.Alerts.map(item => {
                let passcol = item.Color ? item.Color : 'red'
                let failcol = item.Color === 'green' ? 'red' : 'green'
                let passicon = item.AlertIcon ? item.AlertIcon : 'mdi-alert'
                let failicon = item.AlertIcon === 'mdi-check' ? 'mdi-check' : 'mdi-check'
               if(item.Array && item.Array.length > 0){
                        item.Description = `You have a total of <span style="font-size: 1.5rem;color: `+passcol+`;">`+item.Array.length+` `+item.IncompleteName+` `+item.TableName+`</span>.`
                        item.Color = passcol
                        item.AlertIcon = passicon
                        if(item.TablePath){
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath  
                        }
                        if(!item.AlertDescription){
                            item.Description = item.Description+` View them now?`
                        }
                        else{
                            item.Description = item.Description+`<br> `+item.AlertDescription
                        }
                        
                    }
                    else if(!item.Array || item.Array && item.Array.length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = failcol
                        item.AlertIcon = failicon
                    }
                return item
            }).map(item => {
                let elmnt = Object.assign({},this.basicinfosheet)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                if(item.QuickDashType){
                    elmnt.QuickDashType = item.QuickDashType
                    elmnt.Results = item.Results
                }
                return elmnt
            })
        },
    },
    watch: {
      counteritem: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  if(this.EditedElement.QuickDashType === 'QuickCounters'){
                    //console.log(this.EditedElement.Elements,newvalue)
                   if(!this.AddingCounters){
                    this.EditedCounterElement = Object.assign({},this.EditedElement.Elements[newvalue])
                  }
                  else{
                   this.EditedCounterElement = Object.assign({},this.AvailableCounterElements[newvalue]) 
                  } 
                  }
                  
                }
            },deep: true
      }
    },
    created(){
      if(this.EntityQuickDashElements){
        this.TableSummaries = JSON.parse(JSON.stringify(this.EntityQuickDashElements))
        if(this.ChildEntityFilters && this.ChildEntityFilters.length > 0){
          this.ChildEntityFilters.map(filter => {
          let array = this.$store.state[filter.ChildEntityid.split('_').join('')+'Array']
          if(array && array.length === 0){
            console.log('yea would need to call array for '+filter.ChildEntityid)
          }
          })
        }
        //console.log('this.this.TableSummaries',this.TableSummaries)
      }
      else{
        this.EditingDashboard = true
      }
      this.Today = new Date()
        this.Today.setHours(0)
        this.Today.setMinutes(0)
        this.Today.setSeconds(0)
    },
    methods:{
      ActivateDashFilterOption(sum,opt,fieldid){
        //console.log(this.DashFilterSummary,sum.Title)
         let reactivate = false
        if(this.DashFilterOption && this.DashFilterSummary){         
          if(this.DashFilterSummary && this.DashFilterSummary.title !== sum.Title){
            reactivate = true
          }
          this.CancelDashFilterOption(sum,opt,fieldid,reactivate)
        }
        else{          
        this.DashFilterOption = opt
        this.DashFilterSummary = sum
        this.DashFilterField = fieldid
        }
        //console.log(this.DashFilterSummary,this.DashFilterOption)
      },
      CancelDashFilterOption(sum,opt,fieldid,reactivate){
        this.DashFilterOption = ''
        this.DashFilterSummary = ''
        this.DashFilterField = ''
        if(reactivate){
          this.ActivateDashFilterOption(sum,opt,fieldid)
        }
      },
      ToggleEditSummary(){
        this.EditSummaryTitles = !this.EditSummaryTitles
      },
      SaveSummaryEdit(sum,sumindex){
        this.TableSummaries[sumindex].Title = sum.Title
        this.ToggleEditSummary()
      },
      NotIncludedChildfilter(item){
        return this.EditedCounterElement && this.EditedCounterElement.ExtendedChildFilterFields && !this.EditedCounterElement.ExtendedChildFilterFields.find(obj => obj.DisplayName === item.DisplayName)
      },
      AddtoChildFilters(item){
        this.EditedCounterElement.ExtendedChildFilterFields.push(item)
      },
      RemoveChildFilter(item){
        let itemobj = this.EditedCounterElement.ExtendedChildFilterFields.find(obj => obj.DisplayName === item.DisplayName)
        let itemindex = this.EditedCounterElement.ExtendedChildFilterFields.indexOf(itemobj)
        this.EditedCounterElement.ExtendedChildFilterFields.splice(itemindex,1)
      },
      ChildOptionFields(child){
        let ent = this.SystemEntities.find(obj => obj.id === child.ChildEntityid)
        return ent.AllFields.filter(field => {
          return field.Type === 'Option Set'
        })
      },
      CheckDeleteElement(sum,sumindex){
        confirm('this will delete the leement, are you sure?') && this.ProceedDeleteElement(sum,sumindex)
      },
      ProceedDeleteElement(sum,sumindex){
        this.TableSummaries.splice(sumindex,1)
      },
      ActivateAdvancedFindView(){
        if(this.EditedElement.QuickDashType === 'Quick Counters'){
          if(!this.EditedCounterElement.Checks){
            this.EditedCounterElement.Checks = []
          }
          this.AdvancedFindView = true
        }
        else{
          this.AdvancedFindView = true
        }
      },
      CancelDashEditing(){
        this.EditedElement = {QuickDashType: ''}
        this.AdvancedFindView = false
        this.EditingDashboard = !this.EditingDashboard
        this.AddingCounters =! this.AddingCounters
        if(!this.EntityQuickDashElements){
        this.ToggleQuickDashView()
        }
      },
      MoveElement(direction,index){
        let to = ''
        if(direction === 'Up'){
          to = index-1
        }
        else{
          to = index-1+2
        }
        
        this.TableSummaries.splice(to, 0, this.TableSummaries.splice(index, 1)[0]);
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      LookupPass(condstep,fieldcheck,record){
            let checkrecord = ''
            let value = record[fieldcheck.Name]
            let valuecheck = ''
            if(value){
                valuecheck = value[fieldcheck.LookupFieldName]
            }            
            if(condstep.LookupParameter === 'Preset Options'){
                if(fieldcheck.Type === 'Lookup' && record[fieldcheck.Name]){
                    valuecheck = record[fieldcheck.Name][fieldcheck.LookupFieldName]
                }
                //I DO NOT THINK THE LITTLE MESS BELOW WILL EVER BE TURNED ON AGAIN
                else if(fieldcheck.Type === 'Option Set' && record[fieldcheck.Name]){
                    valuecheck = record[fieldcheck.Name].Name
                }
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[fieldcheck.Name]){
                        valuecheck = 'No Value in '+fieldcheck.Name
                    }
                
                else{
                    valuecheck = record[fieldcheck.Name]
                    
                }
                if(typeof record[condstep.FieldtoCheck.Name] !== 'undefined' && condstep.Parameter === 'Contains Data'){
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                    condstep.CheckValue = valuecheck
                }
                else if(typeof record[condstep.FieldtoCheck.Name] === 'undefined' && condstep.Parameter === 'Does Not Contain Data'){
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                    condstep.CheckValue = valuecheck
                }
                else if(typeof record[condstep.FieldtoCheck.Name] !== 'undefined' && condstep.Parameter === '==='){
                    if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                        condstep.CheckParams = record[condstep.FieldtoCheck.Name].Name+' '+condstep.Value.Name
                        condstep.Pass = true
                        condstep.icon = 'mdi-check'
                        condstep.Color = 'green'
                        condstep.CheckValue = valuecheck
                    }                                
                }
                else {
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
                }
            }
            //I DO HOWEVER THINK THAT WE KNOW WE CHECKING THE LOOKUP RECORD RIGHT NOW. AND PERHAPS THE QUESTION IS SHOULD WE NOT MAKE REFERENCE TO THE CORRECT PASS FORMULA?
             else if(fieldcheck.Type === 'Option Set' && record[fieldcheck.Name]){
                    valuecheck = record[fieldcheck.Name].Name
                }
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[fieldcheck.Name]){
                        valuecheck = 'No Value in '+fieldcheck.Name
                    }
                
                else{
                    valuecheck = record[fieldcheck.Name]
                    
                }
                if(fieldcheck.Type === 'Option Set'){
                  condstep = this.OptionSetPass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Number Field' || fieldcheck.Type === 'Calulated Field' || fieldcheck.Type === 'Rollup Calculation'){
                  condstep = this.NumberPass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Date'){
                  condstep = this.DatePass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Checklist'){
                    condstep = this.ChecklistPass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else if(fieldcheck.Type === 'Boolean'){
                  condstep = this.BooleanPass(condstep,fieldcheck,record)
                }
                else {
                  condstep = this.ParamsPass(condstep,fieldcheck,record,record[fieldcheck.Name])
                }
            // else{
            //   //actually only lookup checks that checks for lookup of lookup is of this type...how do we want this to react?
            // }
            //console.log(condstep,record,condstep.FieldtoCheck.Name,record[condstep.FieldtoCheck.Name])
            return condstep
        },
        OptionSetPass(condstep,fieldcheck,record){
            let checkrecord = ''
            let value = checkrecord[fieldcheck.Name]
            let valuecheck = ''
            if(!value){
                valuecheck = 'No Value in '+fieldcheck.Name
            }
            else{
                valuecheck = record[fieldcheck.Name].Name
            }
            if(record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Contains Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(!record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Does Not Contain Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(condstep.Parameter === 'in'){
              let values = condstep.Value.map(val => {
                return val.ID
              })
                if(record[condstep.FieldtoCheck.Name] && values.includes(record[condstep.FieldtoCheck.Name].ID)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === 'not in'){
              let values = condstep.Value.map(val => {
                return val.ID
              })
                if(record[condstep.FieldtoCheck.Name] && !values.includes(record[condstep.FieldtoCheck.Name].ID)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === '==='){
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === '!=='){
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].ID !== condstep.Value.ID){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            return condstep
        },
        BooleanPass(condstep,fieldcheck,record){
            let checkrecord = ''
            let value = checkrecord[fieldcheck.Name]
            let valuecheck = value
            if(typeof value === 'undefined' && condstep.Parameter !== 'false or undefined'){
                valuecheck = 'No Value in '+fieldcheck.Name 
            }
            else if(typeof value === 'undefined' && condstep.Parameter === 'false or undefined'){
                valuecheck = 'No Value in '+fieldcheck.Name
                condstep.Pass = true
            }
            else if(!value && condstep.Parameter === 'false or undefined'){
                valuecheck = value
                condstep.Pass = true
            }
            else if(!value && condstep.Parameter === 'false'){
                condstep.Pass = true
                valuecheck = value
            }
            else if(value && condstep.Parameter === 'true'){
                condstep.Pass = true
                valuecheck = value
            }
            //DO WE RATHER PUT PARAMSPASS IN HERE? CAN BOOLEAN BE DOE NOT CONTAIN OR WHATEVER?                                
            if(condstep.Pass){
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter
                condstep.CheckValue = valuecheck
            }
            else{
                condstep.Pass = false
                condstep.icon = 'mdi-cancel'
                condstep.Color = 'red'                                    
                condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter                                
                condstep.CheckValue = valuecheck
            }
            return condstep
        },
        NumberPass(condstep,fieldcheck,record){
            let value = record[fieldcheck.Name]
            let valuecheck = value
            if(typeof valuecheck !== 'undefined'){
                //console.log(record[this.CurrentEntity.Primary_Field_Name],condstep.Parameter,condstep.Value,valuecheck)
                if(condstep.Parameter === '===' && valuecheck === condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '!==' && valuecheck !== condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '<' && valuecheck < condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '>' && valuecheck > condstep.Value){                                        
                    condstep.Pass = true
                    //console.log("condstep.Parameter === '>' && valuecheck > condstep.Value",condstep.Pass)
                }
                else if(condstep.Parameter === '>=' && valuecheck >= condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '<=' && valuecheck <= condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === 'Contains Data'){
                    condstep.Pass = true
                }
            }
            else{
                if(condstep.Parameter !== 'Does Not Contain Data'){
                valuecheck = 'No Value in '+fieldcheck.Name 
                }
                else{
                    valuecheck = 'No Value in '+fieldcheck.Name 
                    condstep.Pass = true
                }
            }
            //console.log(condstep)
            return condstep
        },
        ParamsPass(condstep,fieldcheck,record,valuecheck){
            //was checkrecord
            if(record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Contains Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(!record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Does Not Contain Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(condstep.Parameter === '==='){
                if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }  
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === 'does not include'){
                let check = condstep.Value.toLowerCase()
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].toLowerCase() && !record[condstep.FieldtoCheck.Name].toLowerCase().includes(check)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                }
                else{
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
                }
            }
            else if(condstep.Parameter === 'includes'){
                let check = condstep.Value.toLowerCase()
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].toLowerCase() && record[condstep.FieldtoCheck.Name].toLowerCase().includes(check)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                }
                else{
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
                }
            }
            else if(condstep.FieldtoCheck.Type !== 'Boolean'){
                condstep.Pass = false
                condstep.icon = 'mdi-cancel'
                condstep.Color = 'red'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value                                
                condstep.CheckValue = valuecheck 
            }
                return condstep
        },
        ChecklistPass(condstep,fieldcheck,record){
          let value = record[fieldcheck.Name]
          let valuecheck = value
          let options = fieldcheck.Options
          let pass = false
          if(value){
           if(condstep.ChecklistParameter === 'Preset Options'){
            if(condstep.Parameter === 'All Checked True'){
              pass = true
              options.map(opt => {
                  if(!value[opt.ID] || value[opt.ID] === 'undefined'){
                    pass = false
                  }
              })
            }
            else if(condstep.Parameter === 'All Checked False'){
              pass = true
              options.map(opt => {
                  if(value[opt.ID] || value[opt.ID] === 'undefined'){
                    pass = false
                  }
              })
            }
            else if(condstep.Parameter === 'Any Checked True'){
              pass = false
              options.map(opt => {
                  if(value[opt.ID]){
                    pass = true
                  }
              })
            }
            else if(condstep.Parameter === 'Any Checked False'){
              pass = false
              options.map(opt => {
                  if(!value[opt.ID]){
                    pass = true
                  }
              })
            } 
          }          
          }
          if(pass){
                //now do what you do when passed
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckValue = valuecheck
            }
            else{
                //did not pass now get going
                  condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
            }
            return condstep
        },
        DatePass(condstep,fieldcheck,record){
            let value = record[fieldcheck.Name]
            let valuecheck = value
            if(valuecheck && valuecheck.toDate){
                //console.log('has to date function',record[fieldcheck.Name])
                valuecheck = valuecheck.toDate()
            }
            else{
                //console.log('does not have to date function',record[fieldcheck.Name])
                valuecheck = new Date(valuecheck)
            }
            if(valuecheck){
                if(condstep.DateParameter === 'Contains Data'){

                }
                else if(condstep.DateParameter === 'Does Not Contain Data'){
                    
                }
                else if(condstep.DateParameter === 'Other Field'){
                    //TBD but DateComparisonParameters would apply
                }
                else if(condstep.DateParameter === 'Preset Options'){
                    let startdate = ''
                    let enddate = ''
                    let pass = false
                        if(condstep.Parameter === '===' || condstep.Parameter === '!=='){//BOTH START AND END APPLIES
                            startdate = this[condstep.Value.split(' ').join('')].Start
                            enddate = this[condstep.Value.split(' ').join('')].End
                        }
                        else if(condstep.Parameter === 'Before' ){//NO END DATE -  ONLY SOONER THAN START  
                            startdate = this[condstep.Value.split(' ').join('')].Start                   
                        }
                        else if(condstep.Parameter === 'On or Before'){//NO START DATE - ONLY SOONER THAN END
                            enddate = this[condstep.Value.split(' ').join('')].End
                        }
                        else if(condstep.Parameter === 'After'){//NO START DATE - ONLY LATER THAN END
                            enddate = this[condstep.Value.split(' ').join('')].End                   
                        }
                        else if(condstep.Parameter === 'On or After' ){//NO END DATE - ONLY LATER THAN START
                            startdate = this[condstep.Value.split(' ').join('')].Start
                        }
                    
                    //console.log('startdate',startdate,'enddate',enddate,'valuecheck',valuecheck)
                    if(startdate){
                        startdate = startdate.getTime()
                    }
                    if(enddate){
                        enddate = enddate.getTime()
                    }     
                    
                        
                        if(startdate && enddate && condstep.Parameter === '!=='){//it must be between teh dates
                            pass = valuecheck.getTime() > enddate || valuecheck.getTime() < startdate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime() || typeof record[fieldcheck.Name] === 'undefined' || pass
                            }
                        }
                        else if(startdate && enddate){//it must be between teh dates
                            pass = valuecheck.getTime() >= startdate && valuecheck.getTime() <= enddate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(startdate && condstep.Parameter === 'Before'){//it must be before
                            pass = valuecheck.getTime() < startdate
                            if(condstep.IncludeUndefined){                              
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(enddate && condstep.Parameter === 'On or Before'){//it must be before
                            pass = valuecheck.getTime() <= enddate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(startdate && condstep.Parameter === 'On or After'){//it must be after
                            pass = valuecheck.getTime() >= startdate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(enddate && condstep.Parameter === 'After'){//it must be after
                            pass = valuecheck.getTime() > enddate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }    
                        //console.log(condstep.IncludeUndefined,valuecheck.getTime() !== valuecheck.getTime(),pass,record.id)                     
                        if(pass){
                            //now do what you do when passed
                            condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value
                            condstep.Pass = true
                            condstep.icon = 'mdi-check'
                            condstep.Color = 'green'
                            condstep.CheckValue = valuecheck
                        }
                        else{
                            //did not pass now get going
                             condstep.Pass = false
                                condstep.icon = 'mdi-cancel'
                                condstep.Color = 'red'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                                condstep.CheckValue = valuecheck
                        }
                        //console.log(record.id,condstep.Pass)
               
                }
            }
            else{
                //undefined date, definitely do not pass
                 condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
            }
            return condstep
        },
        PassCheck(Checks,record){
            let FinalChecks = JSON.parse(JSON.stringify(Checks))
            return FinalChecks.map(step => {
                let condstep = Object.assign({},step)
                //console.log(condstep)
                let fieldcheck = condstep.FieldtoCheck
                
                let checkrecord = ''
                let valuecheck = ''                
                if(condstep.Record && condstep.Record.Relationship === 'Lookup Field Build'){
                    checkrecord = record[condstep.Record.RelationshipField]
                }
                else{
                    checkrecord = record
                }
                let value = checkrecord[fieldcheck.Name]
                //console.log(fieldcheck,checkrecord,value)
                if(fieldcheck.Type === 'Lookup' && value){
                    valuecheck = value[fieldcheck.LookupFieldName]
                }
                else if(fieldcheck.Type === 'Option Set' && value){
                    valuecheck = value.Name
                }
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !value){
                    valuecheck = 'No Value in '+fieldcheck.Name
                }
                if(fieldcheck.Type === 'Lookup'){
                    //weird we seem to need reocrd not checkrecord so yeahh
                    condstep = this.LookupPass(condstep,fieldcheck,checkrecord)
                    if(condstep.LookupParameter === 'Preset Options'){
                        //I think this means, all is done, preset options where done in lookuppass
                    }
                    else{
                        //WOULD THIS BE checkrecord OR record?
                      //condstep = this.ParamsPass(condstep,fieldcheck,checkrecord,valuecheck)
                    }
                   
                }
                else if(fieldcheck.Type === 'Option Set'){
                    condstep = this.OptionSetPass(condstep,fieldcheck,checkrecord)
                    //NOW OPTION SET ALSO NO NEED PARAMSPASScondstep = this.ParamsPass(condstep,fieldcheck,checkrecord,valuecheck)
                }
                else if(fieldcheck.Type === 'Boolean'){
                    condstep = this.BooleanPass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else if(fieldcheck.Type === 'Number Field' || fieldcheck.Type === 'Calculated Field' || fieldcheck.Type === 'Rollup Calculation'){
                    condstep = this.NumberPass(condstep,fieldcheck,checkrecord)
                    //NOT NEEDED, NR PASS DOES ALL condstep = this.ParamsPass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Date'){
                    condstep = this.DatePass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else if(fieldcheck.Type === 'Checklist'){
                    condstep = this.ChecklistPass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else{
                    condstep = this.ParamsPass(condstep,fieldcheck,checkrecord,valuecheck)
                }
                //console.log(condstep)
                return condstep
            })
        },
    VerifyAdvancedFilters(AlertChecks){        
        let data = this.QuickData.map(record => {
            let recordobj = Object.assign(record)
            return recordobj
        })
        data = data.map(recordobj => {
            let record = Object.assign({},recordobj)      
            let Checks = JSON.parse(JSON.stringify(AlertChecks)) 
            record.Passed = this.PassCheck(Checks,record).filter(check => { 
                //console.log(record[this.DashboardEntity.Primary_Field_Name],'check.Pass',check.Pass)
                return check.Pass
            })
            record.Unpassed = this.PassCheck(Checks,record).filter(check => { 
                //console.log(record[this.DashboardEntity.Primary_Field_Name],'check.Pass',check.Pass)
                return !check.Pass
            })
            // console.log(record[this.DashboardEntity.Primary_Field_Name],'Passed',record.Passed,'Failed',record.Unpassed,Checks)
            return record
        })
        let passedrecords = data.filter(item => {
            return item.Unpassed.length === 0
        })
        let failedrecords = data.filter(item => {
            return item.Unpassed.length > 0
        })
        return {FailedRecords: failedrecords, PassedRecords: passedrecords}
    },
      EndofMonth(date){			
        var month = date.getMonth()			
        var year = date.getFullYear()			
        var d = new Date(year, month + 1, 0)			
        d.setHours(23,59,59,999)			
        return d			
        },
        EndofDay(date){			
        var day = date.getDate()			
        var month = date.getMonth()			
        var year = date.getFullYear()			
        var d = new Date(year, month, day)			
        d.setHours(23,59,59,999)			
        return d			
        },	
        EndofWeek(date){			
     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();			
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },	
      PrepAlertElmnt(elmntobj){
        let elmnt = Object.assign({},elmntobj)
        let alertobj = {
            AlertIcon: elmnt.AlertIcon,
            Color: elmnt.Color,
            IncompleteName: elmnt.IncompleteName,
            TableName: this.DashboardEntity.DisplayName,
            QuickDashType: 'QuickAlerts'
          }
          if(elmnt.AlertDescription){
            alertobj.AlertDescription = elmnt.AlertDescription
          }
          if(elmnt.SummaryID){
            alertobj.SummaryID = elmnt.SummaryID
          }
          alertobj.Results = this.VerifyAdvancedFilters(elmnt.Checks)
        alertobj.Array = alertobj.Results.PassedRecords
        elmnt.Alerts = [
          alertobj
        ]        
        //console.log(elmnt)
        //okay hold up how the hell?
        return elmnt
      },
      SaveAdvancedFind(Checks){
        console.log(Checks)
        if(this.EditedElement.QuickDashType === 'QuickAlerts'){          
        let title = this.EditedElement.IncompleteName
        this.EditedElement.Checks = Checks
        console.log(this.EditedElement.Checks)
        this.EditedElement.IncompleteName = ''
        this.EditedElement.Refreshing = true
        setTimeout(() => {
            this.EditedElement.Refreshing = false
            this.EditedElement.IncompleteName = title
        }, 100);
        }
        else if(this.EditedElement.QuickDashType === 'QuickCounters'){
          let step = this.counteritem
          //console.log('reset')
          this.EditedElement.Elements[this.counteritem].Checks = Checks
          this.EditedCounterElement.Checks = Checks          
          this.counteritem = -1
          setTimeout(() => {
            this.counteritem = step
        }, 100);
        }
        console.log(this.EditedElement,this.EditedCounterElement)
      },
      SetQuickDashbtoTemplate(templateid){
        this.$emit('SetQuickDashbtoTemplate',templateid,this.TableSummaries)
      },
      SaveAlert(){
        let elmnt = Object.assign({},this.EditedElement)
        delete elmnt.Alerts
        elmnt.Checks = this.FixChecksforSaving(elmnt.Checks)
        if(this.EditedElementIndex === -1){
          this.TableSummaries.push(elmnt)
        }
        else{
          this.TableSummaries.splice(this.EditedElementIndex,1,elmnt)
        }
        console.log(this.TableSummaries,this.EditedElementIndex)
      },
      SaveChart(){
        let chartelmnt = Object.assign({},this.EditedElement)
        delete chartelmnt.ChartObj
        if(this.EditedElementIndex === -1){
          this.TableSummaries.push(chartelmnt)
        }
        else{
          this.TableSummaries.splice(this.EditedElementIndex,1,chartelmnt)
        }
        //console.log(this.TableSummaries)
      },
      RefreshChart(){
        this.RefreshingChartElement = true
        setTimeout(() => {
            this.RefreshingChartElement = false
        }, 300);
      },
      PrepareChartObj(elmnt){
        let chartobj = {
        Preset: 'Activities',
        Type: 'Line',
        TimeFilterOption: elmnt.TimeFilterOption,
        PrimaryLabelType: 'From Timeline',
        PrimaryEntity: this.DashboardEntity.id,
        Data: [],
        ChartLabelsfromTimeline: true,
        ChartLabels: [],
        ChartBG: elmnt.ChartBG,
        PrimaryDateFilter: elmnt.PrimaryDateFilter,
        ChartType: 'line',	
        ColumnWidth: 'Full Column',	
        ChartPosition: 'Left',
        HasData: true,
        HasChart: true,
        HasDataContext: false,
        DataContextStyle: 'List',
        Data: this.QuickData,
        ValueType: 'Count'
      }
      chartobj.DashboardEntity = this.DashboardEntity
      return chartobj
      },
      SaveQuickDashboard(){
        console.log(this.TableSummaries)
        let summaries = JSON.parse(JSON.stringify(this.TableSummaries))
        summaries.map(sum => {
          if(sum.Checks){
            sum.Checks = this.FixChecksforSaving(sum.Checks)
          }
          if(sum.Elements){
            sum.Elements.map(elmnt => {
              if(elmnt.Checks){
                elmnt.Checks = this.FixChecksforSaving(elmnt.Checks)
              } 
            })
          }
        })
        this.$emit('SaveQuickDashboard',summaries)
      },
      ActivateAvailableFilter(item,prop,value){
        item[prop] = value
        this.counteritem = 0
        //console.log(item)
      },
      ToggleQuickDashView(){
        this.$emit('ToggleQuickDashView')
      },
      CheckElementItems(){
         if(this.EditedElement.QuickDashType === 'QuickCounters'){
            if(!this.EditedElement.Elements){
              this.EditedElement.Elements = []
              this.counteritem = 0
              this.EditedCounterElement = Object.assign({},this.AvailableCounterElements[this.counteritem])
              //this.EditedCounterElement = Object.assign({},this.DefaultCounterElement)
              //this.EditedCounterElement.Number = 1
              //this.EditedElement.Elements.push(this.EditedCounterElement)
             // console.log(this.EditedCounterElement,this.EditedElement)
             this.AddingCounters = true
            }
            delete this.EditedElement.ChartBG
            delete this.EditedElement.TimeFilterOption
         }
         else{
           delete this.EditedElement.Elements
           delete this.EditedElement.Checks
           if(this.EditedElement.QuickDashType === 'QuickCharts'){
             this.EditedElement.TimeFilterOption = 'This Month'
             this.EditedElement.ChartBG = 'purple'
           }
           if(this.EditedElement.QuickDashType === 'QuickAlerts'){
             this.AdvancedFindView = true
             if(!this.EditedElement.Checks){
               this.EditedElement.Checks = []
             }
             if(!this.EditedElement.AlertIcon){
               this.EditedElement.AlertIcon = 'mdi-alert'
             }
             if(!this.EditedElement.Color){
               this.EditedElement.Color = 'red'
             }
           }
         }
         //console.log(this.EditedElement)
      },
      CheckforQuickDashPlugin(elmnt,elmntindex){
        if(this.AppQuickDashPlugin){
          this.EditingDashboard = !this.EditingDashboard
        }
        else{
          let plug = this.RAPlugins.find(obj => obj.id === 'Quick_Dashboards')
          this.$store.commit('SetAlertSound','error_sound')
          this.$store.commit('setConfirmMSGTitle',`Not Installed!`)
          this.$store.commit('setConfirmMSGIcon',`mdi-alert`)
          this.$store.commit('setConfirmMSGIconColor',`red`)
          this.$store.commit('setConfirmMSGText',`<p style="text-align: center;">You would need to install the Quick Dashboard plugin in order to use this feature.</p>`)
          this.$store.commit('setConfirmMSGDialog',true)
          //first purchase plugin
        }
      },
      ActivateEditedElement(elmnt,elmntindex){
        this.counteritem = 0
        this.AddingCounters = false
        //console.log(elmnt,elmntindex,sum,sumindex)
        this.EditedElementIndex = elmntindex
        this.EditedElement = Object.assign({},elmnt)
        delete this.EditedElement.Counters
        if(elmnt.QuickDashType === 'QuickCounters'){
          this.EditedElement.Elements.map((elmnt,itemindex) => {
            elmnt.ElementID = itemindex
            elmnt.SummaryID = this.EditedElementIndex
          })
          this.EditedCounterElement = Object.assign({},this.EditedElement.Elements[0])
        }
        else if(elmnt.QuickDashType === 'QuickAlerts'){
          this.AdvancedFindView = true
        }
        //console.log(this.EditedElement)
      },
      SelectCounterElement(elmnt){
        this.EditedCounterElement = Object.assign({},elmnt)
        
        this.StoreCounterItem()
      },
      RemoveCounterItem(){
        this.EditedElement.Elements.splice(this.counteritem,1)
        if(this.EditedElement.Elements.length === 0){
          this.AddingCounters = true
        }
        else{
          //this.counteritem--
        }
      },
      FixChecksforSaving(Checks){
        return Checks.map(chck => {
            let chckobj = Object.assign({},chck)
            delete chck.RecordFields
            return chckobj
          })
      },
      StoreCounterItem(){
        this.AdvancedFindView = false
        if(this.EditedElementIndex === -1){
          let newobj = Object.assign({},this.EditedElement)
          delete newobj.Counters
          let newcounteritem = Object.assign({},this.EditedCounterElement)
          if(newcounteritem.Checks){
            newcounteritem.Checks = this.FixChecksforSaving(newcounteritem.Checks)
          }
          newobj.Elements.push(newcounteritem)
          newobj.Elements.map((elmnt,elmntindex) => {
            elmnt.ElementID = elmntindex
            elmnt.SummaryID = this.EditedElementIndex
          })
          //console.log(newobj)
           this.TableSummaries.push(newobj)
           this.CancelElmntEdit()
        }
        else{
          if(this.EditedElement.QuickDashType === 'QuickCounters'){     
            let newobj = Object.assign({},this.EditedCounterElement)    
            let itemobj = this.TableSummaries[this.EditedElementIndex].Elements[newobj.ElementID]   
            //console.log(newobj,itemobj)
            if(itemobj){
              let index = this.TableSummaries[this.EditedElementIndex].Elements.indexOf(itemobj)
              this.TableSummaries[this.EditedElementIndex].Elements.splice(index,1,newobj)
            }
            else{
               this.TableSummaries[this.EditedElementIndex].Elements.push(newobj)
            }
          
           this.CancelElmntEdit()
          }
        }     
        //console.log(this.EditedElement)
      },
      CancelElmntEdit(){
        this.EditedCounterElement = Object.assign({},this.DefaultCounterElement)
        this.EditedElement.QuickDashType = ''
        this.EditedElementIndex = -1
      },
        PrepCounterElemnt(elmnt){
          let rollupcounters = elmnt.Elements.filter(item => {
            return item.CounterType === 'Rollup' 
          })
          let groupedcounters = elmnt.Elements.filter(item => {
            return item.CounterType === 'Grouped' && item.DataField
          })
          let finalgroupcounters = groupedcounters.map(counter => {
            let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === counter.DataField)
            let Checks = []
            if(counter.Checks){
              Checks = counter.Checks.filter(check => {
                return datafield.Type === 'Lookup' && check.Record && check.Record.RelationshipField === datafield.id
                || datafield.Type === 'Option Set' && check.FieldtoCheck.id === datafield.id
              })
            }
            //console.log(Checks,counter.Checks,datafield)
              if(datafield.Type === 'Lookup' && datafield.RelatedBuildID !== 'Warehouses'){
                let dataentity = this.SystemEntities.find(obj => obj.id === datafield.RelatedBuildID)
                return datafield.Array.filter(record => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let FinalChecksPassed = Checks.map(check => {
                      let condstep = Object.assign({},check)
                      condstep = this.LookupPass(condstep,condstep.FieldtoCheck,record)
                      return condstep
                    }).filter(condstep => {
                      return condstep.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                }).map(record => {                  
                    let counterobj = Object.assign({},counter)
                    counterobj.Title = record[dataentity.Primary_Field_Name]
                    counterobj.FilterID = record.id
                    counterobj.ElementID = counter.ElementID
                    counterobj.SummaryID = counter.SummaryID
                    counterobj.CounterIcon = dataentity.Table_Icon
                    
                    return counterobj
                })
              }
              else if(datafield.Type === 'Lookup' && datafield.RelatedBuildID === 'Warehouses'){
                return this.WarehousesArray.filter(record => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let FinalChecksPassed = Checks.map(check => {
                      let condstep = Object.assign({},check)
                      condstep = this.LookupPass(condstep,condstep.FieldtoCheck,record)
                      return condstep
                    }).filter(condstep => {
                      return condstep.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                }).map(record => {                  
                    let counterobj = Object.assign({},counter)
                    counterobj.Title = record.Name
                    counterobj.FilterID = record.id
                    counterobj.ElementID = counter.ElementID
                    counterobj.SummaryID = counter.SummaryID
                    counterobj.CounterIcon = 'mdi-warehouse'
                    return counterobj
                })
              }
              else{
                let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === counter.DataField)
                return datafield.Options.filter(value => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let fakerecord = {
                      [datafield.Name]: value
                    }
                    let FinalChecksPassed = Checks.map(check => {
                      let condstep = Object.assign({},check)
                      condstep = this.OptionSetPass(condstep,condstep.FieldtoCheck,fakerecord)
                      return condstep
                    }).filter(condstep => {
                      return condstep.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                }).map(opt => {                  
                    let counterobj = Object.assign({},counter)
                    counterobj.Title = opt.Name
                    counterobj.FilterID = opt.ID
                    if(opt.Icon){
                      counterobj.CounterIcon = opt.Icon
                    }
                    counterobj.ElementID = counter.ElementID
                    counterobj.SummaryID = counter.SummaryID
                    
                    //console.log(counterobj)
                    return counterobj
                })
              }
          }).flat()
          let counters = rollupcounters.concat(finalgroupcounters).map(item => {
            let optobj = Object.assign({},this.basecounter)
            if(item.CounterIcon){
              optobj.CounterIcon = item.CounterIcon
            }
            if(item.FilterID){
              optobj.FilterID = item.FilterID
            }
            optobj.Title = item.Title
            optobj.Data = []
            if(!item.DataField){
              optobj.Data = this.QuickData
              let Checks = []
              //console.log(item,item.Checks)
              if(item.Checks){
                Checks = item.Checks
              }
              optobj.Data = optobj.Data.filter(record => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let FinalChecksPassed = this.PassCheck(Checks,record)
                    FinalChecksPassed = FinalChecksPassed.filter(check => {
                      //console.log(check,record)
                      return check.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                })
            }
            else{
              let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === item.DataField)
              //console.log(datafield.id,item)
              if(datafield.Type === 'Lookup'){
                let Checks = []
                if(item.Checks){
                Checks = item.Checks
              }
                optobj.Data = this.QuickData.filter(record => {
                  return record[datafield.Name] && record[datafield.Name+'id'] === item.FilterID || !item.FilterID
                }).filter(record => {
                  let checkpass = true
                  //console.log(Checks)
                  if(Checks.length > 0){
                    checkpass = false
                    let FinalChecksPassed = this.PassCheck(Checks,record)
                    FinalChecksPassed = FinalChecksPassed.filter(check => {
                      //console.log(check,record)
                      return check.Pass
                    })
                    //console.log(FinalChecksPassed.length,Checks.length,FinalChecksPassed)
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                })
              }
              else{
                let Checks = []
                if(item.Checks){
                  Checks = item.Checks
                }
                optobj.Data = this.QuickData.filter(record => {
                  return datafield.Type === 'Checklist' && !record[datafield.Name] || 
                  datafield.Type === 'Checklist' && record[datafield.Name] && !record[datafield.Name][item.FilterID] || 
                  record[datafield.Name] && record[datafield.Name].ID === item.FilterID || !item.FilterID
                }).filter(record => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let FinalChecksPassed = this.PassCheck(Checks,record)
                    FinalChecksPassed = FinalChecksPassed.filter(check => {
                      //console.log(check,record)
                      return check.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                })
              }
              //console.log(optobj.Data)
            }
            if(item.RollupType === 'Amount'){
              let numberfield = this.NumberTypeFields.find(obj =>obj.id === item.NumberField)
              optobj.Data = optobj.Data.filter(record => {
                  return typeof record[numberfield.Name] !== 'undefined'
                })
              if(typeof numberfield.IsCurrency !== 'undefined'){
                optobj.IsCurrency = numberfield.IsCurrency
              }
              optobj.CounterInteger = optobj.Data.map(item => {
              return item[numberfield.Name]
              })
              .reduce((a, b) => a + b, 0)
              if(item.DataField){
               optobj.SubTitle = '('+optobj.Title+')' 
               optobj.Title = numberfield.DisplayName
               
              }              
            }
            else if(item.DataField && item.CounterType === 'Rollup'){
              let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === item.DataField)
              let Checks = []
              if(item.Checks){
                Checks = item.Checks.filter(check => {
                  return datafield.Type === 'Lookup' && check.Record && check.Record.RelationshipField === datafield.id
                  || datafield.Type === 'Option Set' && check.FieldtoCheck.id === datafield.id
                })
              }
              if(datafield.Type === 'Option Set'){
                let rollupitems = datafield.Options.filter(value => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let fakerecord = {
                      [datafield.Name]: value
                    }
                    let FinalChecksPassed = Checks.map(check => {
                      let condstep = Object.assign({},check)
                      condstep = this.OptionSetPass(condstep,condstep.FieldtoCheck,fakerecord)
                      return condstep
                    }).filter(condstep => {
                      return condstep.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                })               
                optobj.CounterInteger = rollupitems.length
              }
              else{            
                let rollupitems = this.$store.state[datafield.RelatedBuildID.split('_').join('')+'Array'].filter(record => {
                  let checkpass = true
                  if(Checks.length > 0){
                    checkpass = false
                    let FinalChecksPassed = Checks.map(check => {
                      let condstep = Object.assign({},check)
                      condstep = this.LookupPass(condstep,condstep.FieldtoCheck,record)
                      return condstep
                    }).filter(condstep => {
                      return condstep.Pass
                    })
                    if(FinalChecksPassed.length === Checks.length){
                      checkpass = true
                    }
                  }
                  return checkpass
                })
                if(item.IsTableRoute){
                  //optobj.Data = rollupitems
                }
                if(item.RelatedTableFilter){
                  optobj.RelatedTableFilter = item.RelatedTableFilter
                  optobj.DataField = item.DataField
                }
                optobj.RollupItems = rollupitems
                
                //IsTableRoute
              optobj.CounterInteger = rollupitems.length
              }
            }
            else{
              optobj.CounterInteger = optobj.Data.length
            }
            if(!optobj.CounterIcon){
              optobj.CounterIcon = this.DashboardEntity.Table_Icon
            }            
            optobj.ElementID = item.ElementID
            optobj.SummaryID = item.SummaryID
            if(item.ExtendedFilterField){
              let entity = ''
              if(item.DataField){
              let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === item.DataField)
              //console.log(datafield)
              if(datafield.Type === 'Lookup' && item.RelatedTableFilter){
                entity = this.SystemEntities.find(obj => obj.id === datafield.RelatedBuildID)
              }
              else{
                entity = this.DashboardEntity
              }
              }
              else{
                entity = this.DashboardEntity
              }
                optobj.ExtendedFilterField = entity.AllFields.find(obj => obj.id === item.ExtendedFilterField.id)
              }
              if(item.ExtendedChildFilterFields){
              let entity = ''
              if(item.DataField){
              let datafield = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === item.DataField)
              //console.log(datafield)
              if(datafield.Type === 'Lookup' && item.RelatedTableFilter){
                entity = this.SystemEntities.find(obj => obj.id === datafield.RelatedBuildID)
              }
              else{
                entity = this.DashboardEntity
              }
              }
              else{
                entity = this.DashboardEntity
              }
                optobj.ExtendedChildFilterFields = item.ExtendedChildFilterFields
                //this.LookupChildFields.find(obj => obj.id === item.ExtendedChildFilterFields.id)
              }
              
            let colorprops = ['HeaderColor','FontColor']
            if(item.BaseColor){
              colorprops.map(clrprop => {
                optobj[clrprop] = item.BaseColor
              })
            }
            else if(this.EditedCounterElement.BaseColor){
              colorprops.map(clrprop => {
                optobj[clrprop] = this.EditedCounterElement.BaseColor
              })
            }
            return optobj
          })
           //console.log(counters)
          return {QuickDashType: 'QuickCounters', Counters: counters, CanInteract: true,Elements: elmnt.Elements}
        },
        
        QuickDashboardInteract(elmnt,ExtendedFilterField,filter){
          //console.log('QuickDashboardInteract(elmnt,ExtendedFilterField,filter)',elmnt,ExtendedFilterField,filter)
          let item = Object.assign({},this.ComputedTableSummaries[elmnt.SummaryID])
          //console.log(item)
          let finalobj = ''
          if(item.QuickDashType === 'QuickCounters' && this.EditedElementIndex === -1){
            finalobj = item.Elements[elmnt.ElementID]
            finalobj.Data = elmnt.Data
            if(elmnt.FilterID){
              finalobj.FilterID = elmnt.FilterID
            }
            if(finalobj.DataField && finalobj.CounterType === 'Rollup'){
              let field = this.ComputedDashboardEntity.AllFields.find(obj => obj.id === finalobj.DataField)
              //if it has route it is rable route, if not prefilter
              if(finalobj.IsTableRoute && field.RelatedBuildID !== 'Warehouses'){          
                let entity = this.SystemEntities.find(obj => obj.id === field.RelatedBuildID)
                this.$router.push('/'+entity.DisplayName.split(' ').join(''))
              }
              else{
                let elmntoptions = this.AvailableCounterElements.filter(obj => {
                  return obj.CounterType === 'Grouped' && obj.DataField === finalobj.DataField && obj.RollupType === finalobj.RollupType
                })
                let newelmnt = ''
                if(finalobj.NumberField){
                  newelmnt = Object.assign({},elmntoptions.find(obj => obj.NumberField === finalobj.NumberField))
                }
                else{
                  newelmnt = Object.assign({},elmntoptions.find(obj => !obj.NumberField))
                }
                if(finalobj.BaseColor){
                  newelmnt.BaseColor = finalobj.BaseColor
                }
                newelmnt.SummaryID = 0
                newelmnt.ElementID = 0
                if(ExtendedFilterField && filter){
                  finalobj.ExtendedFilterField = ExtendedFilterField
                  finalobj.ExtendedFilterID = filter.ID
                  finalobj.RollupItems = elmnt.RollupItems
                  this.$emit('QuickDashboardInteract',finalobj) 
                } 
                else if(!ExtendedFilterField && filter && filter.ChildEntityid && elmnt.ExtendedChildFilterFields){
                  //possibly this means it's a drilldown on a child entity "progress field" but how to apply filter remains a mystery
                }
                else{
                  this.ActiveDrillDownItem = {QuickDashType: 'QuickCounters',Elements: [newelmnt],Title: finalobj.Title+' - Drilldown',SummaryID: 0}
                }                
                //console.log(elmntoptions,newelmnt,this.ActiveDrillDownItem)
              }
            }
            else if(finalobj.DataField){
              //RelatedTableFilter
              //this is "grouped", but not base table..
              //definitely has a filter on the table now
              if(ExtendedFilterField && filter){
                finalobj.ExtendedFilterField = ExtendedFilterField
                finalobj.ExtendedFilterID = filter.ID
              }
              else if(filter && filter.Data && filter.FilterbyRelatedChildTable){
                //possibly this means it's a drilldown on a child entity "progress field" but how to apply filter remains a mystery
                //pop AdvancedFind as true and use "Data" as AdvancedFindFilterItems
                finalobj.FilterbyRelatedChildTable = true
                finalobj.RelatedChildTableData = filter.Data
              }
              this.$emit('QuickDashboardInteract',finalobj)              
            }
            else if(ExtendedFilterField && filter){
                finalobj.ExtendedFilterField = ExtendedFilterField
                finalobj.ExtendedFilterID = filter.ID
                this.$emit('QuickDashboardInteract',finalobj)    
              }
            else{
              //has not datafield, must be base table, unless we wanted something fancy on numbers etc simply close quickdashview.
              //and I mean it, I mean, as opposed to the previous one where "grouped" AND datafield, there really is nothign to filter by either
              this.$emit('QuickDashboardInteract',finalobj,true)
            }
          }
          else if(elmnt.QuickDashType === 'QuickAlerts'){
            
            let advancedfilterobj = {
              // Checks: item.Checks,
              Results: elmnt.Results,
              QuickDashType: 'QuickAlerts'
            }
            //console.log(advancedfilterobj)
            this.$emit('QuickDashboardInteract',advancedfilterobj)
          }
          //console.log(finalobj,elmnt,ExtendedFilterField,filter)
            
        }
    }
}
</script>

<style>

</style>
