<template>
    <v-row class="justify-center my-0">
      <v-flex lg12 md12 sm12 xs12>
        <v-card color="" class="transparent" tile width="100%" flat>
          <v-layout row justify-center>
            <div class="flex xl12 lg12 md12 sm12 xs12">
              <v-card
                color="#ffffff"
                class="transparent mx-3"
                tile
                width="100%"
                flat
                :style="IntranetView ? '' : 'margin-top:-80px;'"
              >
                <v-parallax
                  v-if="$route.name !== 'MyAccount'"
                  height="300"
                  src="@/assets/Classifieds Banner.jpg"
                  style="margin-top: 0px;margin-bottom: 0px;margin-left: 0px;margin-right: 0px;"
                >
                  <v-card
                    class="transparent"
                    flat
                    tile
                    style="background: linear-gradient(180deg,rgba(1, 0, 15, 0.835171568627451) 0%,rgba(9, 46, 54, 0.47942927170868344) 100%);"
                    height="100%"
                    width="100%"
                  >
                    <v-container class="fill-height">
                      <v-row>
                        <v-col
                          :xl="12"
                          :lg="12"
                          :md="12"
                          :sm="12"
                          :xs="12"
                          cols="12"
                        >
                          <v-card
                            class="transparent"
                            color="#00ffff"
                            flat
                            height="100%"
                            style="margin-top: 0px;margin-bottom: 0px;margin-left: 0px;margin-right: 0px;"
                          >
                            <v-card-title
                              class="justify-center"
                              style="padding-bottom: 20px;color: #fcf5f5;align-self: center;font-family: Montserrat, sans-serif;font-size: 60px;"
                            >
                              Classifieds
                            </v-card-title>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-parallax>
              </v-card>
            </div>
          </v-layout>
        </v-card>
        <v-card color="" class="transparent" tile width="100%" flat>
          <v-card-title row class="justify-end">
            <v-btn
              :style="$route.name !== 'MyAccount' ? '' : 'margin-top:50px;'"
              fab
              absolute
              outlined
              @click="AddClassified()"
              >Add</v-btn
            >
            <v-btn
              dark
              class="accent"
              style="margin-right: 70px"
              fab
              absolute
              outlined
              @click="OpenAboutDialog('AboutSiteClassifieds')"
              v-if="$store.state.TemplateView"
              ><v-icon>mdi-help</v-icon></v-btn
            >
          </v-card-title>
        </v-card>
        <v-card color="" class="transparent" tile width="100%" flat>
          <v-card-text>
            <v-row>
              <v-col
                class="px-4"
                v-for="slider in Sliders"
                :key="slider.itemObjKey"
              >
                <v-range-slider
                  :label="slider.Name"
                  v-model="slider.range"
                  :max="slider.max"
                  :min="slider.min"
                  hide-details
                  class="align-center"
                >
                  <template v-slot:prepend>
                    <v-text-field
                      :value="slider.range[0]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      @change="$set(slider.range, 0, $event)"
                    ></v-text-field>
                  </template>
                  <template v-slot:append>
                    <v-text-field
                      :value="slider.range[1]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      @change="$set(slider.range, 1, $event)"
                    ></v-text-field>
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-layout row class="justify-center"> </v-layout>
        </v-card>
        <v-card
          class="mx-3 background lighten-1"
          style="padding: 20px"
          height="100%"
        >
          <v-pagination
            :total-visible="9"
            v-model="Classifiedspage"
            circle
            :length="Math.ceil(FinalClassifiedsRender.length / 9)"
          ></v-pagination>
          <v-layout row justify-center class="mx-1">
            <div
              class="flex xl4 lg4 md6 sm12 xs12"
              v-for="item in FinalClassifiedsRender.slice(0, 9)"
              :key="item.itemObjKey"
              style="padding: 10px"
            >
              <v-card
                elevation="4"
                class="mx-auto"
                :dark="AppisDarkMode"
                max-width="600"
                height="450"
                style="padding: 5px"
              >
                <v-btn
                  dark
                  style="z-index: 1"
                  class="goldgradbg white--text my-2"
                  fab
                  absolute
                  right
                  :to="SingleRoutePrefix + item.id"
                  >View</v-btn
                >
                <v-img
                  v-if="item.AttachmentsArray && item.AttachmentsArray.length > 0 && item.AttachmentsArray[0].ThumbURL"
                  :src="item.AttachmentsArray[0].ThumbURL"
                  contain
                  class="black"
                  height="200px"
                >
                  <v-btn
                    v-if="item.Vehicle_Brands"
                    depressed
                    outlined
                    @click="
                      ActivateViewPortFilter(
                        'Vehicle_Brands',
                        item.Vehicle_Brands
                      )
                    "
                    style="position: absolute;top: 20px;min-width: 30% !important;min-height: 30% !important;"
                    tile
                    class="links"
                    dark
                    >{{ item.Vehicle_Brands.Name }}</v-btn
                  >
                </v-img>
                <v-img
                  v-if="(item.AttachmentsArray && item.AttachmentsArray.length > 0 && !item.AttachmentsArray[0].ThumbURL) ||
                    (item.AttachmentsArray && item.AttachmentsArray.length == 0)"
                  src="@/assets/ImageHolder.png"
                  contain
                  height="200px"
                >
                  <v-btn
                    v-if="item.Vehicle_Brands"
                    depressed
                    outlined
                    @click="ActivateViewPortFilter('Vehicle_Brands',item.Vehicle_Brands)"
                    style="position: absolute;top: 20px;min-width: 30% !important;min-height: 30% !important;"
                    tile
                    class="links"
                    dark
                    >{{ item.Vehicle_Brands.Name }}</v-btn
                  >
                </v-img>
                <v-list :dark="AppisDarkMode" dense class="transparent">
                  <v-list-item class="overline accent--text">
                    {{ item.Title }}
                  </v-list-item>
                  <v-list-item class="mediumoverline links--text">
                    {{ CurrencyFormatter(item.Price, "ZAR", "R") }}
                  </v-list-item>
                </v-list>

                <v-card
                  v-if="!TaggedFields"
                  flat
                  class="transparent background mx-3 my-2"
                  height="100"
                >
                  <v-card-subtitle
                    v-html="item.Description.substr(0, 250)"
                    class="caption mx-1 my-1"
                  >
                  </v-card-subtitle>
                </v-card>
              </v-card>
            </div>
          </v-layout>
        </v-card>
      </v-flex>
    </v-row>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";

export default {
  props: ["System","IntranetView","ClassifiedsbyClasses","Sliders","ActiveFilters","AdditionalFilters","NavList"],
  components: {},
  data() {
    return {        
      Classifiedspage: 1,
    };
  },
  computed: {
    FinalClassifiedsRender(){
          return this.ClassifiedsbyClasses.map(clsfd => {
            
            clsfd.Render = true
            this.ActiveFilters.map(filter => {
              if(!clsfd[filter.PropName]){
                clsfd.Render = false
              }
              else{
                if(filter.Type === 'Option Set'){
                  if(clsfd[filter.PropName].ID !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Number Field' && filter.IsOptionList){
                  if(clsfd[filter.PropName] < filter.Range[0] || clsfd[filter.PropName] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }})
            this.AdditionalFilters.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(typeof clsfd[filter.Field.Name] === 'undefined'){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            this.NavList.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(typeof !clsfd[filter.Field.Name] === 'undefined'){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            return clsfd       
          })
          .filter(clsfd => {
            return clsfd.Render
          })
          //ActiveFilters
          //return arr1.concat(arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1)
        },
    UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Classified/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Classified/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Classified/' : '/Group-Classified/'
        }
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
    UsersArray() {
      return this.$store.state.UsersArray;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
    userIsEagleViewer() {
      return this.$store.state.IsEagleView;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    userBUID() {
      return this.$store.getters.userBUID;
    },
    userBUChildren() {
      return this.$store.getters.userBUChildren;
    },
    userBUParents() {
      return this.$store.getters.userBUParents;
    },
    WindowHeight() {
      return window.innerHeight;
    },
    SocialItemInteractMethod() {
      return this.$store.state.SocialItemInteractMethod;
    },
    SocialItemInteractMethodProp() {
      return this.$store.state.SocialItemInteractMethodProp;
    },
  },
  watch: {
    SocialItemInteractMethod(v) {
      let acceptedmethods = [];
      if (v && acceptedmethods.includes(v)) {
        if (this.SocialItemInteractMethodProp) {
          this[this.SocialItemInteractMethod](
            this.SocialItemInteractMethodProp
          );
          if (this.SocialItemInteractMethod === "ActivateSelectedElement") {
            this.SingleRecordNavMenuItem = "";
          }
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        } else {
          this[this.SocialItemInteractMethod]();
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        }
      }
    },
  },
  created() {

  },
  methods: {
    CurrencyFormatter(value,currency,shortcurrency){
      const currencyformatter = this.PrepareCurrency(currency)
      let formalformat = currencyformatter.format(value)
      return formalformat.split(currency).join(shortcurrency)
    },
    PrepareCurrency(currency){			
      currency
      return new Intl.NumberFormat('en-US', {			
            style: 'currency',			
            currency: currency,			
            minimumFractionDigits: 2			
        })
       
    },	
    OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
    AddClassified(){
        let ref = this.$store.state.TemplateView ? this.ConfigPath.collection('sampleclassifieds').doc() : db.collection('classifieds').doc()
        let newdocid = ref.id
        let newrouteobj = {}
        if(this.$store.state.TemplateView){
          if(this.ActiveSuiteTemplate){
            newrouteobj = { name: 'SuiteTemplateClassifiedSingle', params: {id: this.$route.params.id, slug: newdocid, UserRecord: this.userLoggedIn}}
          }
          else{
            newrouteobj = { name: 'MarketplaceTemplateClassifiedSingle', params: {id: this.$route.params.id, slug: newdocid, UserRecord: this.userLoggedIn}}
          }
        }
        else{
          newrouteobj = { name: 'ClassifiedSingle', params: {id: newdocid, UserRecord: this.userLoggedIn}}
        }
          this.$router.push(newrouteobj)
        },
    ActivateViewPortFilter(PropName,Value){
        this.$emit('ActivateViewPortFilter',PropName,Value)
      },
  },
};
</script>

<style>
</style>



