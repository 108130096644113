<template>
  <v-layout row class="justify-center">
    <v-flex :class="ViewOnly || FullWidth? '' : 'xl2 lg3 md4 sm6 xs12'" v-for="pack in SubscriptionPackages" :key="pack.itemObjKey" :style="FullWidth ? '' : 'padding:20px;'">
        <v-card tile :style="Shorter ? 'height: 40%;' : 'height: 100%;'">
            <!--  :style="pack.FeaturedColor ? 'border: 7px solid '+pack.FeaturedColor.hexa+';' : 'border: 7px solid #FF0000;'" -->
          <div v-if="pack.Ribbon" class="subscriptioncardribbon">
            <span style="background: #ba24f0;">{{pack.RibbonText}}</span></div>
          <v-card-title :class="'white--text mediumoverline justify-center'" 
          :style="pack.Color? 'background-color:'+pack.Color.hexa+';' : 'background-color: #FF0000;height: 100%;'">
            <span style="max-width: 50%;font-weight: 500!important;font-size: 1em;text-align:center;">
            {{pack.Name}}
            </span>
          </v-card-title>
           <!-- ra ribbon sbtitle <v-card-title class="title-ribbon justify-center white--text" style="margin-top:10px;font-weight: 300!important;font-size: 0.9em;">
            Ideal for beginners
          </v-card-title>
          <v-card-text v-html="pack.Description" style="margin-top:70px;">
          </v-card-text> -->
          <v-card-title v-if="pack.SubText" class="justify-center" style="padding-top: 10px;font-weight: 400!important;font-size: 1em;">
            {{pack.SubText}}
          </v-card-title>
           <v-card-title class="justify-center" style="padding-top: 10px;font-weight: 300!important;font-size: 3em;">
            <span v-html="CurrencyFormatter(pack.Price,$store.state.DefaultCurrency.Currency,true)"/>
          </v-card-title>
          <v-card-title class="mediumoverline justify-center" :style="pack.Color? 'padding-bottom: 0px;padding-top: 0px;color: '+pack.Color.hexa+';font-weight: 500!important;' : 
          'padding-bottom: 0px;padding-top: 0px;color: red;font-weight: 500!important;'">
            <v-chip @click="!ViewOnly ? SignupwithPackage(pack) : ''" dark :style="pack.Color? 'width:150px;place-content: center;background-color:'+pack.Color.hexa+';' : 'width:150px;place-content: center;background-color: #FF0000;'" large >{{SignUpLabel}}</v-chip>
            
          </v-card-title>
          <p v-if="pack.PriceFootnote" style="text-align: center;" class="aj_p">{{pack.PriceFootnote}}</p>
          <v-card-text :style="ViewOnly ? 'max-width: 350px;height:260px;' : pack.KeyPoints ? 'height:'+(pack.KeyPoints.length*40)+'px;' : ''" >
            <div v-html="pack.Description">
            </div>
          </v-card-text>
          <v-card-text style="padding-top:80px;" v-if="pack.KeyPoints" >
            <v-list style="position: absolute;bottom: 0px; width: 90%;">
                <v-list-item dense class="caption detailslistoutline" v-for="adv in pack.KeyPoints" :key="adv.itemObjKey">
                    <v-list-item-title style="font-weight: 300;">
                         <v-icon left small>
                            {{adv.icon}}
                        </v-icon> {{adv.title}}
                    </v-list-item-title>
                    <!-- <v-list-item-action>
                        <v-icon left small>
                            {{adv.icon}}
                        </v-icon>                        
                    </v-list-item-action> -->
                    <v-list-item-action>
                      <v-switch v-if="CanToggleKeyPoints"
                      @click="UpdateKeyPointSelection(pack,adv)"
                      v-model="adv.Active"/>
                        <v-icon v-if="!CanToggleKeyPoints" left :color="adv.Active? 'green' : 'red'" small>
                            {{adv.Active? 'mdi-check' : 'mdi-close'}}
                        </v-icon>                        
                    </v-list-item-action>
                </v-list-item>
            </v-list>
         <!--     <ul v-if="pack.KeyPoints">
            <li v-for="adv in pack.KeyPoints" :key="adv.itemObjKey">
          <span class="list-name">{{adv.title}}</span>
          <v-icon>
            {{adv.icon}}
          </v-icon>
          <!-- <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">~10000 Visits Monthly</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">30 GB SSD Storage</span>
          <span class="icon cross"><i class="fas fa-times"></i></span>
        </li>
        <li>
          <span class="list-name">100 GB Bandwidth</span>
          <span class="icon cross"><i class="fas fa-times"></i></span>
        </li>
            </ul> -->
          </v-card-text>
        </v-card>
      </v-flex>
    <!-- </v-flex> -->
    <!-- <div class="wrapper">
    <div class="subscriptiontable basic">
      <div class="head_tab"><h2>Single<br> Web Hosting</h2></div>
      <div class="aj_p"><p>Ideal solution for beginners</p></div>
      <div class="price-section">
        <div class="price-area">
          <div class="inner-area">
            <span class="text">₹</span>
            <span class="price">29<span style="font-size:12px">/mo</span></span>

          </div>
        </div>
      </div>
      <div class="package-name"></div>
      <ul class="features">
        <div class="btn"><button>Add to Cart</button></div>
        <p class="aj_des">₹159/mo when you renew</p>
        <li>
          <span class="list-name">1 Website</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">~10000 Visits Monthly</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">30 GB SSD Storage</span>
          <span class="icon cross"><i class="fas fa-times"></i></span>
        </li>
        <li>
          <span class="list-name">100 GB Bandwidth</span>
          <span class="icon cross"><i class="fas fa-times"></i></span>
        </li>
      </ul>
      <div><h2>See all features <span class="icon cross"><i class="fa fa-angle-down"></i></span></h2></div>
    </div>
    <div class="subscriptiontable premium">
      <div class="head_tab"><h2>Premium <br>Web Hosting</h2></div>
      <div class="aj_p"><p>Perfect package for personal</p></div>
      <div class="subscriptioncardribbon"><span>BEST VALUE</span></div>
      <div class="price-section">
        <div class="price-area">
          <div class="inner-area">
            <span class="text">₹</span>
            <span class="price">59<span style="font-size:12px">/mo</span></span>
          </div>
        </div>
      </div>
      <div class="package-name"></div>
      <ul class="features">
        <div class="btn"><button>Add to Cart</button></div>
        <p class="aj_des">₹159/mo when you renew</p>
        <li>
          <span class="list-name">50 Website</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">~25000 Visits Monthly </span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">60 GB SSD Storage</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">200 GB Bandwidth</span>
          <span class="icon cross"><i class="fas fa-times"></i></span>
        </li>
      </ul>
      <div class="premium_all"><h2>See all features <span class="icon cross"><i class="fa fa-angle-down"></i></span></h2></div>
    </div>
    <div class="subscriptiontable ultimate">
      <div class="head_tab"><h2>Ultimate <br>Web Hosting</h2></div>
      <div class="aj_p"><p>Perfect package for businesses</p></div>
      <div class="price-section">
        <div class="price-area">
          <div class="inner-area">
            <span class="text">₹</span>
            <span class="price">99<span style="font-size:12px">/mo</span></span>
          </div>
        </div>
      </div>
      <div class="package-name"></div>
      <ul class="features">
        <div class="btn"><button>Add to Cart</button></div>
        <p class="aj_des">₹159/mo when you renew</p>
        <li>
          <span class="list-name">100 Website</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">~75000 Visits Monthly </span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">200 GB SSD Storage</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
        <li>
          <span class="list-name">500 GB Bandwidth</span>
          <span class="icon check"><i class="fas fa-check"></i></span>
        </li>
      </ul>
      <div><h2>See all features <span class="icon cross"><i class="fa fa-angle-down"></i></span></h2></div>
    </div>
  </div> -->
  </v-layout>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','SubscriptionPackages','ViewOnly','Shorter','FullWidth','SignUpLabel','CanToggleKeyPoints'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      UpdateKeyPointSelection(pack,adv){
        this.$emit('UpdateKeyPointSelection',pack,adv)
      },
        SignupwithPackage(pack){
            this.$emit('SignupwithPackage',pack)
        },
        CurrencyFormatter(value,currency,smalldec){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return smalldec ? this.SmallDecimals(formalformat) : formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        SmallDecimals(value){
          let price = value;
          let split = price.split(',')
          let final  = ''
          split.map((spl, i) => {
            if(i-1+2 < split.length){
              final = final+spl
            }
            else{
              final = final+`<span style="font-size:0.5em;">.`+spl+`</span>`
            }
          })
         return final
         //replace(/(\D*)(\d*\.)(\d*)/,'<span style="font-size:16px;">$1</span><span style="font-size:22px;">$2</span><span style="font-size:14px;">$3</span>')
        },
    }
}
</script>
 <style scoped>
 .wrapper{
  max-width: 1090px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapper .subscriptiontable{
  background: #fff;
  width: calc(33% - 20px);
  padding: 30px 30px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  text-align: center;
}
.subscriptiontable .price-section{
  display: flex;
  justify-content: center;
}
.subscriptiontable .price-area{
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 2px;
}

.aj_p{
  text-align: center;
}

p.aj_des {
  color: grey;
  padding-bottom: 30px;
  font-size: 14px;
}

.subscriptiontable.premium {
  margin: -20px;
}

.premium_all {
  padding-top: 22px;
}

.price-area .inner-area{
  height: 100%;
  width: 100%;
  line-height: 117px;
  text-align: center;
  color: #fff;
  position: relative;
}

.price-area .inner-area .text{
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  top: -10px;
  left: 5px;
}

.price-area .inner-area .price{
  font-size: 45px;
  font-weight: 500;
  margin-left: 16px;
}

.subscriptiontable .package-name{
  width: 100%;
  height: 2px;
  margin :35px 0;
  position: relative;
}

.subscriptiontable .features li{
  margin-bottom: 15px;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.features li .list-name{
  font-size: 17px;
  font-weight: 400;
}

.features li .icon{
  font-size: 15px;
}

.features li .icon.check{
  color: #2db94d;
}

.features li .icon.cross{
  color: #cd3241;
}

.subscriptiontable .btn{
  width: 100%;
  display: flex;
  justify-content: center;
}

.subscriptiontable .btn button{
  width: 80%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subscriptiontable .btn button:hover{
  border-radius: 5px;
}

.basic ::selection,
.basic .price-area,
.basic .inner-area,
.basic .head_tab h2{
  color:red;
}

.basic .btn button{
  background: red;
  color: #fff;
  margin-top: -75px;
}

.basic .btn button:hover{
  background: rgba(240, 101,36);
  color: #fff;
}

.premium ::selection,
.premium .price-area,
.premium .inner-area,
.premium .head_tab h2{
  color:#ba24f0;
}

.premium .btn button{
  background: #ba24f0;
  color: #fff;
  margin-top: -75px;
}

.premium .btn button:hover{
  background: rgba(240, 101,36);
  color: #fff;
}

.ultimate ::selection,
.ultimate .price-area,
.ultimate .inner-area,
.ultimate .head_tab h2{
  color:#f5b55a;
}

.ultimate .btn button{
  background: #f5b55a;
  color: #fff;
  margin-top: -75px;
}

.ultimate .btn button:hover{
  background: rgba(240, 101,36);
  color: #fff;
}

@media (max-width:756px){
  .wrapper .subscriptiontable{
    width: 100%;
  }

  .subscriptiontable.premium{
    margin: 40px 0px;
  }
  .subscriptiontable.ultimate{
    margin: 0px;
  }
}

::selection{
  color:#fff;
}

.subscriptioncardribbon{
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.subscriptioncardribbon::before,
.subscriptioncardribbon::after{
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #ba24f0;
  border-top-color: transparent;
  border-left-color: transparent;
}

.subscriptioncardribbon::before{
  top: 0px;
  right: 15px;
}

.subscriptioncardribbon::after{
  bottom: 15px;
  left: 0px;
}

.subscriptioncardribbon span{
  position: absolute;
  top:30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #ba24f0;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
}
</style>



