<template>
  <v-card flat tile class="transparent">
    <!-- :style="MobileView ? 'top:50px;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'" -->
       <v-list :style="MobileView ? 'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'"
              dark>               
              <v-list-item v-for="sect in ComputedActiveButtonMenuItems" :key="sect.itemObjKey" @click="ActivateButtonMenuSection(sect)" :class="sect.Class">          
                  <v-list-item-action>
                  <v-icon>{{sect.Icon}}</v-icon>
                  </v-list-item-action>          
                  <v-list-item-content>
                  {{sect.Name}}
                  </v-list-item-content>
              </v-list-item>
          </v-list>	
    <v-card-subtitle class="background darken-1 mediumoverline">
              Header
          </v-card-subtitle>
     <v-list v-if="ActiveButtonMenuSection.Name === 'Apply'">
            <v-btn @click="ConfirmApplytoPeers('Title',EditedTab)">Apply to All</v-btn>
          </v-list>
       <v-list class="transparent" v-if="ActiveButtonMenuSection.Name === 'Standard'">
          <v-card-subtitle class="background darken-1 overline" style="padding: 0px;margin-bottom:20px;text-align: center;">
              <v-icon>{{ActiveButtonMenuSection.Icon}}</v-icon> {{ActiveButtonMenuSection.Name}}
              <!-- (Fancy related) -->
          </v-card-subtitle>
          <v-expansion-panels v-if="EditedTab.Name === 'Hero Banner'">
            <v-expansion-panel>
              <v-expansion-panel-header>Generator
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  <v-list-item dense v-if="!EditedTab.TitleasCurrency">
              <!-- the Title test always remains -->
              <!--  v-if="EditedTab.Name !== 'Parallax'" -->
                <v-text-field dense v-model="EditedTab.Title" label="Title" />
                <!-- <ContentEditable style="padding:15px;" v-if="EditedTab.Name === 'Parallax'"
                        :PushedElementid="EditedTab.ElementID+'_writeup'"
                          @BlurWriteup="BlurWriteup" /> -->
              </v-list-item>
              <v-list-item dense v-if="EditedTab.TitleasCurrency">
              <!-- the Title test always remains -->
              <!--  v-if="EditedTab.Name !== 'Parallax'" -->
                <v-text-field dense type="number" v-model.number="EditedTab.Title" label="Title" />
                <!-- <ContentEditable style="padding:15px;" v-if="EditedTab.Name === 'Parallax'"
                        :PushedElementid="EditedTab.ElementID+'_writeup'"
                          @BlurWriteup="BlurWriteup" /> -->
              </v-list-item>
              <v-list-item>
                <v-btn width="100%" @click="CreateTitleLines(EditedTab)" outlined>Break</v-btn>
              </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-list-item v-if="EditedTab.Name === 'Hero Banner'">
              <v-btn width="100%" @click="CreateTitleLine(EditedTab)" outlined>Add Line</v-btn>
            </v-list-item>
      <v-expansion-panels v-if="EditedTab.TitleLines">
        <v-expansion-panel v-for="(titlecon,coni) in EditedTab.TitleLines" :key="titlecon.itemObjKey">
          <v-expansion-panel-header>
            {{coni}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
             <v-btn outlined color="red" width="100%" @click="ConfirmDeleteTitleBrk(EditedTab,coni)">
                  Delete
                </v-btn>
            <v-text-field type="number" v-model.number="titlecon.Margin" label="Margin"/>
            <v-switch v-model="titlecon.Shadow" label="Shadow"/>
            <v-list-item>
            <v-text-field v-model="titlecon.ShadowStyle" label="Shadow Style"/>
            <a href="https://www.w3schools.com/cssref/css3_pr_text-shadow.php" target="_blank">Get Code</a>
            </v-list-item>
            <v-expansion-panels>
              Breaks
              <v-btn outlined @click="AddBreak(titlecon)">Add Break
              </v-btn>
            <v-expansion-panel v-for="(brk,brki) in titlecon.Breaks" :key="brk.itemObjKey">
              <v-expansion-panel-header>
                {{brk.Value}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-btn outlined color="red" width="100%" @click="ConfirmDeleteBrk(titlecon,brki)">
                  Delete
                </v-btn>
                <v-text-field v-model="brk.Value" />
                
               <v-color-picker style="z-index:2;" v-model="brk.Color"></v-color-picker>
                 <v-text-field type="number" v-model.number="brk.PaddingLeft" label="PaddingLeft"/>
                 <v-text-field type="number" v-model.number="brk.FontSize" label="FontSize"/>
                 <v-text-field type="number" v-model.number="brk.FontWeight" label="FontWeight"/>
                  <v-select clearable style="z-index:100!important;"
                      v-model="brk.FontFamily"
                      label="Font"
                      :items="ComputedFontOptions"
                    >
                      <template v-slot:item="{ item }">
                        <span>
                          <p :style="'font-family:' + item">
                            {{ brk.Value }}
                          </p>
                          <p class="caption" v-if="brk.Value">{{ item }}</p>
                        </span>
                      </template>
                    </v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
            <!-- Padding: 0,
            Place: 'baseline',
            Margin: 5,
            Shadow: true,
            Breaks: [
              {
              Value: line,
              PaddingLeft: 0,
              FontSize: 2,
              FontWeight: 300,
              Color: 'white'
              }             
            ] -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        <v-list-item dense>
       <!-- the Title test always remains -->
       <!--  v-if="EditedTab.Name !== 'Parallax'" -->
        <v-text-field dense v-model="EditedTab.Title" label="Title" />
        <!-- <ContentEditable style="padding:15px;" v-if="EditedTab.Name === 'Parallax'"
                 :PushedElementid="EditedTab.ElementID+'_writeup'"
                   @BlurWriteup="BlurWriteup" /> -->
      </v-list-item>
          <v-list-item dense v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">        
        <v-text-field dense v-model="EditedTab.SubTitleTop" label="Subtitle Top" />
      </v-list-item>
        <v-list-item dense v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">        
        <v-text-field dense v-model="EditedTab.SubTitleBottom" label="Subtitle Btm" />
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.SubTitleTop"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Subtitle Top Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.SubTitleTopColor"></v-color-picker>
          </v-menu>
          <v-list-item v-if="EditedTab.SubTitleTop">        
        <v-switch v-model="EditedTab.SubTitleTopInline" label="Subtitle Top Inline" />
      </v-list-item>
      <v-list-item v-if="EditedTab.SubTitleTop">        
        <v-select  :items="['initial','center','end']" v-model="EditedTab.SubTitleTopTextAlign" label="Subtitle Top Align" />
      </v-list-item>
    
      <v-list-item v-if="EditedTab.SubTitleBottom">        
        <v-select :items="['initial','center','end']" v-model="EditedTab.SubTitleBottomTextAlign" label="Subtitle Btm Align" />
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.SubTitleTop"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Subtitle Bottom Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.SubTitleBottomColor"></v-color-picker>
          </v-menu>
        </v-list> 
         <v-list class="transparent" v-if="ActiveButtonMenuSection.Name === 'Font Styling'">
          <v-card-subtitle class="background darken-1 overline" style="padding: 0px;margin-bottom:20px;text-align: center;">
              <v-icon>{{ActiveButtonMenuSection.Icon}}</v-icon> {{ActiveButtonMenuSection.Name}}
              <!-- (Fancy, Chip,Bg Color,HeaderIMG,Ribbon,Title Shadow,BlendMode) -->
          </v-card-subtitle>
          <v-list-item v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">
            <v-switch v-model="EditedTab.DisplayasChip" label="Display as Chip"/>
             <v-menu      									
                :close-on-content-click="false"									
                :nudge-right="40"									
                transition="scale-transition"									
                offset-y									
                min-width="200px"									
                >									
                <template v-slot:activator="{ on }">									
            <v-icon v-if="EditedTab.DisplayasChip" v-on="on">mdi-palette-advanced</v-icon>									
                </template>									
            <v-color-picker v-model="EditedTab.ChipColor"></v-color-picker>									
            </v-menu>	
          </v-list-item>
           <v-list-item v-if="EditedTab.DisplayasChip">
              <v-text-field v-model="EditedTab.ChipRoute" label="Route"/>	
          </v-list-item>
           <v-list-item>
          <v-slider v-model="EditedTab.HeaderFontSize" label="Font Size" min="8"
                    max="120" thumb-label="always"									
                    />
           </v-list-item>
            <v-list-item>
            <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-palette-advanced</v-icon>
            </template>
            <v-color-picker v-model="EditedTab.HeaderColor"></v-color-picker>
          </v-menu>
          <v-select clearable
            v-model="EditedTab.HeaderFontFamily"
            label="Font"
            :items="ComputedFontOptions"
          >
            <template v-slot:item="{ item }">
              <span>
                <p :style="'font-family:' + item" v-if="EditedTab.Title">
                  {{ EditedTab.Title }}
                </p>
                <p :style="'font-family:' + item" v-if="!EditedTab.Title">
                  {{ item }}
                </p>
                <p class="caption" v-if="EditedTab.Title">{{ item }}</p>
              </span>
            </template>
          </v-select>
        </v-list-item>   
        
         </v-list>
       <v-list class="transparent" v-if="ActiveButtonMenuSection.Name === 'Header Styling'">
        <v-card-subtitle class="background darken-1 overline" style="padding: 0px;margin-bottom:20px;text-align: center;">
              <v-icon>{{ActiveButtonMenuSection.Icon}}</v-icon> {{ActiveButtonMenuSection.Name}}
              <!-- (Fancy, Chip,Bg Color,HeaderIMG,Ribbon,Title Shadow,BlendMode) -->
          </v-card-subtitle>
          <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.FlipCard || 
          EditedTab.Name === 'Card Row Single' && EditedTab.FlipCard">
                  <v-text-field
          label="Label Gradient"
          v-model="EditedTab.LabelGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                <v-switch
              label="Title as Tag" v-if="EditedTab.Name === 'Card Row Single'"
              v-model="EditedTab.TitleTag"
            />
             <v-switch
              label="Title Tag Dark" v-if="EditedTab.Name === 'Card Row Single'"
              v-model="EditedTab.TitleTagDark"
            />            
            <v-switch
              label="Title as Currency" v-if="EditedTab.Name === 'Card Row Single'"
              v-model="EditedTab.TitleasCurrency"
            />
            
        <v-list-item v-if="EditedTab.Name === 'Testimonials'">
           <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
           
          </template>
          <v-color-picker v-model="EditedTab.SubHeaderColor"></v-color-picker>
        </v-menu> <v-select clearable
          v-model="EditedTab.SubHeaderFontFamily"
          label="Font"
          :items="ComputedFontOptions"
        >
          <template v-slot:item="{ item }">
            <span>
              <p :style="'font-family:' + item" v-if="EditedTab.Title">
                {{ EditedTab.Title }}
              </p>
              <p :style="'font-family:' + item" v-if="!EditedTab.Title">
                {{ item }}
              </p>
              <p class="caption" v-if="EditedTab.Title">{{ item }}</p>
            </span>
          </template>
        </v-select>
        <v-select
          v-model="EditedTab.SubHeaderFontSize"
          :items="[12, 14, 18, 24, 36, 42, 50, 60, 75, 100]"
        />
      </v-list-item>     
      <v-list-item v-if="EditedTab.Name !== 'Image' && EditedTab.Name !== 'Card Row Single'">
        <v-switch
          label="Has Header Image"
          v-model="EditedTab.HasHeaderImage"
          v-if="
            EditedTab.Name !== 'Card Row' &&
            EditedTab.Name !== 'Counter Single' &&
            EditedTab.Name !== 'Store Product Single' &&
            EditedTab.Name !== 'Info Card'
          "
        />
        <v-dialog max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="
                EditedTab.Name !== 'Card Row' &&
                EditedTab.Name !== 'Counter Single' &&
                EditedTab.Name !== 'Store Product Single' &&
                EditedTab.Name !== 'Info Card' &&
                !EditedTab.HasTextBox
              "
              v-on="on"
              >mdi-image-edit-outline</v-icon
            >
          </template>
          <v-tabs v-if="!UseGroupAssets">
            <v-tab v-for="lib in GalleryLibraries" :key="lib.itemObjKey">
              {{ lib.name }}
            </v-tab>
            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">
              <v-card width="800">
                <PhotoLibraryViewer
                  :FullGallery="true"
                  :TableCollectionRef="lib.TableCollectionRef"
                  :TableName="lib.name"
                  @AssignImage="AssignImage"
                  :IMGProp="'HeaderImage'"
                  :CurrentEntity="lib"
                  :AppisDarkMode="true"
                  :tab="EditedTab"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-card-text v-if="UseGroupAssets">
            <v-row v-if="SystemAssets.length > 0">
              <v-col
                v-for="n in SystemAssets"
                :key="n.itemOvjKey"
                class="d-flex child-flex"
                cols="3"
              >
                <v-img
                  :src="n.ThumbURL"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="AssignImage(EditedTab, 'HeaderImage', n)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-dialog>
        <!-- <v-switch label="Center Header" v-model="EditedTab.CenteredHeader" @change="HeaderCenterClass(EditedTab)" /> -->
        
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.Name !== 'Card Row Single' || EditedTab.Name === 'Card Row Single' && EditedTab.InfographicBox ||
        EditedTab.Name === 'Card Row Single' && EditedTab.Type === 'Bootstrap Boxes'"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Header BG Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.HeaderBGColor"></v-color-picker>
          </v-menu>
          <v-icon right v-if="EditedTab.HeaderBGColor" @click="EditedTab.HeaderBGColor = ''">mdi-close</v-icon>
          <v-list-item v-if="EditedTab.Name === 'Title' || 
          EditedTab.FancyTitle && EditedTab.Name !== 'Card Row Single' || 
          EditedTab.FancyTitle && EditedTab.Name === 'Card Row Single' && !EditedTab.InfographicBox">
        <v-switch
          label="Ribbon"
          v-model="EditedTab.Ribbon"
        />
         <v-switch
          label="Title Shadow"
          v-model="EditedTab.TitleTextShadow"
        />
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.Ribbon"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Ribbon Inner Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.RibbonInnerColor"></v-color-picker>
          </v-menu>
          <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.Ribbon"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Ribbon Outer Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.RibbonOuterColor"></v-color-picker>
          </v-menu>
      <v-list-item v-if="EditedTab.Name !== 'Card Row Single'">
         <v-select clearable v-model="EditedTab.HeaderBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
      </v-list-item>
       </v-list>   
       <v-list class="transparent" v-if="ActiveButtonMenuSection.Name === 'Positioning'">
          <v-card-subtitle class="background darken-1 overline" style="padding: 0px;margin-bottom:20px;text-align: center;">
              <v-icon>{{ActiveButtonMenuSection.Icon}}</v-icon> {{ActiveButtonMenuSection.Name}}
              <!-- (justify, Center) -->
          </v-card-subtitle>
          <v-list-item>
            <v-select :items="['justify-start','justify-center','justify-end']" v-model="EditedTab.Justify" label="Justify" />
          </v-list-item>
          <v-list-item v-if="!EditedTab.HasTextBox || EditedTab.Name === 'Parallax'">
            <v-switch disabled
              label="Center Header"
              v-model="EditedTab.CenteredHeader"
              @change="Onepropemit('HeaderCenterClass', EditedTab)"
            />
            <v-switch
              label="Fancy Title" v-if="EditedTab.Name !== 'Title' && EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'Card Row Single' || EditedTab.Name === 'Card Row Single' && !EditedTab.InfographicBox"
              v-model="EditedTab.FancyTitle"
            />
            
          </v-list-item>
       </v-list>  
        
      
      
       
      
      
      <!-- <v-list-item>
        <v-autocomplete clearable v-model="EditedTab.HeaderBGColor" label="BG Color" :items="$store.state.CSSColors"
        >
       <template v-slot:item="data">
        <v-list-item-avatar>
          <v-avatar :style="'background-color: '+data.item+';'">
          </v-avatar>
        </v-list-item-avatar>
              <v-list-item-content>
                  <v-list-item-subtitle v-html="data.item"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
        </v-autocomplete>
      </v-list-item> -->
      
      
  </v-card>
</template>






<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,ContentEditable},
    data() {
        return {
          ActiveButtonMenuSection: {Name: 'Standard'},
            
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      ComputedActiveButtonMenuItems(){
          return this.ActiveButtonMenuItems.filter(item => {
              let peerfixtypes = ['Card Row Single']
              return peerfixtypes.includes(this.EditedTab.Name) || item.Name !== 'Apply'
          })
      },
      ActiveButtonMenuItems(){
            return [
                {Icon: 'mdi-format-title',Name: 'Standard',Class: 'grey'},
                {Icon: 'mdi-text-shadow',Name: 'Font Styling',Class: 'grey'},
                {Icon: 'mdi-format-line-style', Name: 'Header Styling',Class: 'grey'},
                {Icon: 'mdi-page-layout-sidebar-left',Name: 'Positioning',Class: 'grey'},
                {Icon: 'mdi-auto-fix',Name: 'Apply',Class: 'red'},
            ]
        },
      ComputedFontOptions(){
            return this.FontOptions.concat(this.System.CustomFonts.map(font => {return font.Name}))
        },
    },
    watch: {
      ActiveButtonMenuSection: {        
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                  this.CheckTitleValue()
                }
            },
            deep: true
      }
    },
    created(){
      this.CheckTitleValue()
    },
    methods:{
        ConfirmApplytoPeers(type,EditedTab){
            confirm('This will apply these settings to all items in the Parent Tab. Are you sure?') && this.ApplytoPeers(type,EditedTab)
        },
        ApplytoPeers(type,EditedTab){
            this.$emit('ApplytoPeers',type,EditedTab)
        },
      ConfirmDeleteTitleBrk(EditedTab,coni){
       confirm('This will delete this word break and there is no undo. Are you sure?') && this.DeleteTitleBrk(EditedTab,coni) 
      },
      DeleteTitleBrk(EditedTab,coni){
        EditedTab.TitleLines.splice(coni,1)
      },
      ConfirmDeleteBrk(titlecon,brki){
        confirm('This will delete this word break and there is no undo. Are you sure?') && this.DeleteBreak(titlecon,brki)
      },
      DeleteBreak(titlecon,brki){
        titlecon.Breaks.splice(brki,1)
      },
      CreateTitleLine(EditedTab){
        if(!EditedTab.TitleLines){
          EditedTab.TitleLines = []
        }
        let newline = {
            Padding: 0,
            Place: 'baseline',
            Margin: 5,
            Shadow: true,
            ShadowStyle: 'text-shadow: 4px 4px 4px #00000088;',
            Breaks: [
              {
              Value: 'Text',
              PaddingLeft: 0,
              FontSize: 2,
              FontWeight: 300,
              Color: 'white'
              }             
            ]
            //actually padding-left: 0em;line-height: 1em;font-size: 2em;font-weight: 200;color: white;
          }
          EditedTab.TitleLines.push(newline)
      },
      AddBreak(titlecon){
        let newbreak = {
          Value: '{break}',
              PaddingLeft: 0,
              FontSize: 2,
              FontWeight: 300,
              Color: 'white'
        }
        titlecon.Breaks.push(newbreak)
      },
      CreateTitleLines(EditedTab){
        //if(!EditedTab.TitleLines){
          EditedTab.TitleLines = []
        //}
        let lines = EditedTab.Title.split(' ')
        lines.map(line => {
          let newline = {
            Padding: 0,
            Place: 'baseline',
            Margin: 5,
            Shadow: true,
            ShadowStyle: 'text-shadow: 4px 4px 4px #00000088;',
            Breaks: [
              {
              Value: line,
              PaddingLeft: 0,
              FontSize: 2,
              FontWeight: 300,
              Color: 'white'
              }             
            ]
            //actually padding-left: 0em;line-height: 1em;font-size: 2em;font-weight: 200;color: white;
          }
          let props = ['HeaderBGColor','HeaderFontFamily']
          props.map(prp => {
            if(typeof EditedTab[prp] !== 'undefined'){
              newline.Breaks[0][prp] = EditedTab[prp]
            }
          })
          //'padding: 0px;place-items: baseline;margin-left: 5%;text-shadow: 4px 4px 4px #00000088;'
          EditedTab.TitleLines.push(newline)
        })
      },
      CheckTitleValue(){
       let e = document.getElementById(this.EditedTab.ElementID+'_writeup')
        if(e){
        e.innerHTML = this.EditedTab.Title
        }
      },
      BlurWriteup(){
        let e = document.getElementById(this.EditedTab.ElementID+'_writeup')
        this.EditedTab.Title = e.innerHTML
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
      ActivateButtonMenuSection(sect){
            this.ActiveButtonMenuSection = sect
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
