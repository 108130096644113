<template>
  <div>
    <v-dialog fullscreen v-model="FeaturedMembersOverlay">
      <v-card tile flat height="100%" width="100%">
    <DirectoryViewer v-if="CatNavList && CatNavList.CategoricalListType && !Refreshing" :ProvidedAdditionalFilters="CatNavList.AdditionalFilters"
      :ProvidedNavList="CatNavList.NavList" :ProvidedCurrentEntity="CatNavList"  @ActivateStoreView="ActivateStoreView"
      :FeaturedMemberFilters="FeaturedMemberFilters"
      @ActivateTreeNode="ActivateTreeNode" :ProvidedFeatureList="CatNavList.FeatureList" @PushActiveProduct="PushActiveProduct" :CartItems="CartItems"
      />
      </v-card>
    </v-dialog>
     <v-dialog v-model="SuiteInstallerDialog"  :max-width="400" >
       <v-card
            class="mx-auto"
            max-width="400"
          >
            <v-card-title class="text-h6 font-weight-regular">
              <span>{{ currentNewSiteTitle }}</span>
              <v-spacer>
              </v-spacer>
              <v-avatar
                color="primary lighten-2"
                class="subheading white--text"
                size="24"
                v-text="SuiteInstallerStep"
              ></v-avatar>
              <v-btn fab small icon @click="CancelSuiteInstallerDialog()" dark outlined color="red">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                 <v-window v-model="SuiteInstallerStep">
                  <v-window-item :value="1">
                    <v-select @change="CheckNewSiteOwnership()" v-model="NewSite.SiteHosting" label="Hosting" :items="HostingOptions" item-text="Name"/>
                    <v-switch v-if="NewSite.SiteHosting === 'On Site'" v-model="NewSite.SkipListing" label="Do NOT List in Directory"/>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-text-field v-model="NewSite.CompanyName" label="Company Name"/>
                    <v-text-field v-model="NewSite.Name" label="Site Name"/>
                   
                    <UserLookup :UsersLookupArray="UsersArray"  :ModelProp="'Owner'" :RecordObj="NewSite" @UpdateUserLookupProp="UpdateUserLookupProp"
                    :FieldLabel="'Site Owner'" :Rules="[$store.state.formrules.required]"
                    />
                    <UserLookup :UsersLookupArray="UsersArray"  :ModelProp="'Admin'" :RecordObj="NewSite" @UpdateUserLookupProp="UpdateUserLookupProp"
                    :FieldLabel="'Site Admin'" :Rules="[$store.state.formrules.required]"
                    />
                    
                  </v-window-item>
                  <v-window-item :value="3">  
                    <!-- <SystemBuilderQuickstart :ClientDBBusinessUnits="ClientDBBusinessUnits" :ClientDBAdministrators="ClientDBAdministrators"
                    v-if="NewSite && NewSite.Owner && SuiteInstallerStep === 3" :QuickstartStep="QuickstartStep" :clientDB="clientDB" :clientApp="clientApp"
                    :QuickStartDialog="QuickStartDialog" :ClientFireStore="ClientFireStore" :SystemObj="NewSite"
                    :ClientAppAuthDialog="ClientAppAuthDialog" :ViewingSystemID="NewSite.id" @ToggleQuickStartDialog="ToggleQuickStartDialog"
                    />              
                  
                    step 3 - firebaseconfig will autocreate bu in client app  -->
                     <v-textarea  v-model="firebaseConfig"></v-textarea>
                  </v-window-item>
                  <v-window-item :value="4">
                    <v-text-field v-if="NewSite.Admin" @keydown.space.prevent @input="removeWhiteSpace(email)" v-model.trim="NewSite.Admin.Email" :rules="[$store.state.formrules.email]" placeholder="Admin Email"></v-text-field>
                    <!-- choose client admin password (will autocreate Ra user if needed) -->
                    <v-text-field
                        placeholder="Admin Password"
                        type="password"
                        :rules="[$store.state.formrules.required, $store.state.formrules.min8Chars]"
                        v-model.trim="NewSiteAdminPW"
                    ></v-text-field>
                    <v-text-field
                        placeholder="Confirm Password"
                        type="password"
                        v-model.trim="reNewSiteAdminPW"
                        :rules="[$store.state.formrules.required, $store.state.formrules.min8Chars, passwordConfirmationRule]"
                    ></v-text-field>
                  </v-window-item>
                  <v-window-item :value="5">
                    <!-- like dude - you need an RA account now first 
                    OR do you have RA account? we should really popup ToggleRAAppAdminDialog-->
                  </v-window-item>
                   <v-window-item :value="6">
                    <v-list class="transparent">                      
                        <v-list-item>                     
                        <v-text-field v-model="SystemFromEmailHost" label="Email smtp host"></v-text-field>
                        </v-list-item>
                        <v-list-item>                         
                        <v-text-field v-model="SystemFromEmailAddress" label="Email Address"></v-text-field>
                        </v-list-item>
                        <v-list-item>
                          <v-text-field v-model="SystemFromEmailPassword" type="password" label="Password"></v-text-field>
                          </v-list-item>
                        <v-list-item>
                          <v-text-field v-model="SystemFromEmailPort" label="Port" type="number"></v-text-field>
                        </v-list-item>
                        <v-list-item>
                          <v-switch 
                            v-model="SystemFromEmailSecure"
                            class="mx-2"
                            label="Secure Mail"
                          ></v-switch>
                        </v-list-item>    
                    </v-list>
                  </v-window-item>
                   <v-window-item :value="7">
                    The site should be live <a target="_new" :href="ClientAppURL">here</a> soon
                   </v-window-item>
                 </v-window>
            </v-card-text>
                  <v-card-actions>
                <v-btn
                  :disabled="SuiteInstallerStep === 1"
                  text
                  @click="RegressNewSiteStepper()"
                >
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="SuiteInstallerStepDiabled"
                  color="primary"
                  depressed
                  @click="ProgressNewSiteStepper()"
                >
                  Next
                </v-btn>
                 <v-btn v-if="NewSuiteStep === 3" dark outlined color="green" @click="SaveSuiteTemplate()">
                    Save
                </v-btn>
              </v-card-actions>
       </v-card>       
    </v-dialog>
    <v-dialog v-model="SystemDialogActive"  :max-width="800" >
      
        <SystemAboutDialog v-if="SystemDialogActive" :SystemDialogid="SystemDialogid"
        :System="System" :SystemEntities="SystemEntities" @CloseSystemDialog="CloseSystemDialog"
        />
    </v-dialog>
    <v-dialog v-model="SuiteTemplatePreDialog" persistent max-width="500">
          <v-card tile v-if="SuiteTemplatePreDialog"
              class="mx-auto"
              max-width="500"
            >
           <v-img height="180" contain class="black" :src="require('@/assets/logo.png')">
           <v-card style="background-color: rgba(17, 0, 34, 0.85);" tile height="100%" width="100%">
              <v-card-title style=""
                class="mediumoverline white--text">
               Site Builder
                  <v-spacer>
                  </v-spacer>
                  
                  <v-btn fab small icon @click="CancelSaveSuiteTemplate()" dark outlined color="red">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title> 
                <v-card-text >
                  <div class="white--text" style="font-weight: 300;">
                  Whether you are simply making a new listing in the Directory, with or without a website, OR building something more complex like a Company App, 
                  you are in the right place. Let's first create your site.
                  </div>
                </v-card-text>
           </v-card>
           </v-img>
           <v-card-text>
            <!-- In a sense we could really do the PromoServices here, relating to the Web App Builder plugin.
            The alternative option being - we can just show a window of options. a carousel of stuff that can be built. -->
           </v-card-text>
                <v-card-text>
                  <v-btn style="width: 100%;" outlined
                  @click="ProcessSuiteTemplateDialog()">Proceed</v-btn>
                </v-card-text>
          </v-card>
    </v-dialog>
      <!-- <NewSiteBuilderDialog :System="System" :SystemEntities="SystemEntities" v-if="SuiteTemplateDialog"
      :InitialSuiteTemplate="NewSuiteTemplate" @CancelSaveSuiteTemplate="CancelSaveSuiteTemplate"
      :NewSuiteStep="NewSuiteStep" :Directories="Directories" :SuiteTemplateDialog="SuiteTemplateDialog"
      /> -->
        <v-dialog v-model="SuiteTemplateDialog" persistent max-width="500">
          <v-card tile v-if="SuiteTemplateDialog"
              class="mx-auto"
              max-width="500"
            >
              <v-img height="180" contain class="black" :src="require('@/assets/logo.png')">
              <v-card style="background-color: rgba(17, 0, 34, 0.85);" tile height="100%" width="100%">
                    <v-card-title style=""
                class="mediumoverline white--text">
                <v-avatar 
                    color="purple"
                    class="subheading white--text"
                    size="40"
                    v-text="NewSuiteStep"
                  ></v-avatar><span style="padding-left:10px;">{{ currentNSTitle }}</span>
                  <v-spacer>
                  </v-spacer>
                  
                  <v-btn fab small icon @click="CancelSaveSuiteTemplate()" dark outlined color="red">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title> 
                <v-card-text class="white--text">
                  {{currentNSDescription}}
                </v-card-text>
                </v-card>
              </v-img>
                  <v-window v-model="NewSuiteStep">
                    <v-window-item :value="1">
                    <v-card-text> 
                      <v-list-item>
                      <v-list-item-content v-if="!UpdatingPluginList">
                          <v-list-item-title>                                            
                                  Total Plugins
                          </v-list-item-title>
                          <v-list-item-subtitle>
                              {{CurrencyFormatter(NewSuiteTotal,$store.state.DefaultCurrency.Currency)}}
                          </v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                      <v-expansion-panels v-if="SuiteTemplateDialog">
                        <v-expansion-panel v-for="mod in ComputedModules" :key="mod.itemObjKey">
                          <v-expansion-panel-header>                          
                          
                            <v-list-item dense>
                              <v-list-item-action>
                                <v-icon left>{{mod.Icon}}</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                {{mod.Name}}
                              </v-list-item-content>
                            </v-list-item>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list-item dense>
                                <v-autocomplete  :filter="tempFilter"
                                :label="mod.Name+' Template'" dense item-text="id" @change="AssignTemplatePluginDataBase(mod.id)" v-if="!UpdatingPluginList" v-model="SelectedTemplates[mod.id]"
                                :items="MyMarketplaceTemplatesFiltered(mod.id)"
                                >
                                  <template v-slot:item="data">
                                    <template v-if="!data">
                                      You do not have any related templates to choose from
                                    </template>
                                    <template v-else>
                                  <v-chip small>
                                    {{ data.item.Name }}
                                  </v-chip>
                                </template>
                                  </template>
                                </v-autocomplete>
                            </v-list-item>
                            <div v-html="mod.Description">
                            </div>
                            <v-switch v-if="!UpdatingPluginList" @change="ToggleUpdatingPluginList()" v-model="NewSuiteTemplate.PluginDataBase[mod.id].Active" label="Active"/>
                            <v-list>
                              <v-virtual-scroll
                              :items="PluginList(mod)"
                              height="200"
                              item-height="60"
                            >
                            <template v-slot:default="{ item }">
                              <v-tooltip nudge-left="140" right max-width="200"
                              
                            >
                              <template v-slot:activator="{ on, attrs }">
                            <v-list-item dense v-on="on" v-bind="attrs">
                                      <v-list-item-avatar>
                                        <v-icon>{{item.Icon}}</v-icon>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                          <v-list-item-title>                                            
                                            {{item.Name}}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                              {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        <v-switch :disabled="!NewSuiteTemplate.PluginDataBase[mod.id].Active" v-if="!UpdatingPluginList" @change="ToggleUpdatingPluginList()" v-model="NewSuiteTemplate.PluginDataBase[item.id].Active" label="Active"/>
                                      </v-list-item-content>
                                  </v-list-item>
                              </template>
                              <span class="caption" v-html="item.Description"></span>
                              </v-tooltip>
                            </template>
                            </v-virtual-scroll>                          
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-card-text>   
                  <FieldValueEditerComponent style="padding: 0px;" v-for="field in AccountFields" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField"
                  :Record="NewAccount" :field="field" @onPhotoFileselected="onPhotoFileselected"
                      :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                      @UploadFileSelect="UploadFileSelect"
                          />
                  <v-select outlined item-text="Name" v-model="NewSuiteTemplate.OrganisationType"
                      :items="SiteOrgTypeField.Options" label="Organisation Type"
                    />
                    <v-select outlined v-model="NewSuiteTemplate.OrgProfileType" label="Profile"
                      :items="['Products','Services','Products & Services']"
                    />
                    <v-select outlined v-model="NewSuiteTemplate.ClientsType" label="Clients"
                      :items="['B2B','B2C','B2X']"
                    />
                    <v-select outlined v-model="NewSuiteTemplate.SiteApplication"
                  @change="ConfigureApplicationOption(NewSuiteTemplate.SiteApplication)"
                    label="Application" :items="['Site/Listing Only','Site/Listing PLUS','Skip Listing']"
                  />
                         
                    </v-card-text>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-card-text>          
                    <v-text-field outlined label="Name" v-model="NewSuiteTemplate.Name"/>Description
                      <ContentEditableField style="padding:15px;"
                  :FieldObject="NewSuiteTemplate" :FieldName="'Description'"
                  :FieldValue="NewSuiteTemplate.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                   <v-card>
                    <PhysicalAddressFieldCard
                    :col="{FieldObj: Invoicing_Address}"  
                    :Record="NewAccount" :CanEdit="true" @SaveRecord="SaveRecord" 
                    :System="System" :SystemEntities="SystemEntities" 
                    />
                          </v-card>
                    </v-card-text>
                  </v-window-item>
                
                  <v-window-item :value="4">
                    <v-card-text>    
                    <v-switch @change="CheckInviteData()" v-model="NewSuiteTemplate.SkipClientInfo" label="Skip Client for Now"/>
                  
                    <UserLookup :UsersLookupArray="OwnershipUsersArray"  :ModelProp="'Client'" :RecordObj="NewSuiteTemplate" @UpdateUserLookupProp="UpdateUserLookupProp"
                    :FieldLabel="'Client'" :Rules="[$store.state.formrules.required]" v-if="!NewSuiteTemplate.ClientisNonMember && !NewSuiteTemplate.SkipClientInfo"
                    />
                    </v-card-text>
                  </v-window-item>
                  </v-window>               
                
              </v-card-text>
              <v-card-actions>
                  <v-btn
                    :disabled="NewSuiteStep === 1"
                    text
                    @click="RegressNSWindow()"
                  >
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="NSStepNextDisabled"
                    color="primary"
                    depressed
                    @click="ProgressNSWindow()"
                  >
                    Next
                  </v-btn>
                  <v-btn v-if="NSSaveEnabled" dark outlined color="green" @click="SaveSuiteTemplate()">
                      Save
                  </v-btn>
                </v-card-actions>
          </v-card>
      </v-dialog>
    <v-dialog fullscreen v-model="ViewMarketplaceTemplates" persistent>
      <v-card v-if="ViewMarketplaceTemplates" height="100%" width="100%" >
        <v-card-title class="white--text recordtoolbar smalloverline">
          View Templates<v-spacer>
            </v-spacer><v-btn v-if="ActiveTemplate && ViewingTemplate" @click="DeactivateActiveTemplateComponent()">Close</v-btn>
          <v-btn v-if="ActiveTemplate && ViewingTemplate" @click="DeactivateActiveTemplateComponent(true)">Install</v-btn><v-spacer>
            </v-spacer>
            <v-btn @click="DeactivateViewMarketplaceTemplates()" icon dark><v-icon color="error">mdi-close
              </v-icon>
              </v-btn>
        </v-card-title>
        <v-card-text v-if="!ActiveTemplate && !ViewingTemplate && RAUserObj && !SystemisRA">
          <!-- perhaps only if ComputedMarketplaceTemplates length is 0? -->
          <v-btn  v-if="!RATemplateView" @click="ActivateRATemplates()" dark outlined class="RAWebBtnGraddark">View RA Templates
          </v-btn>  <v-btn @click="RATemplateView = false" v-if="RATemplateView" dark outlined color="success">View This Suite</v-btn>
        </v-card-text>
        <v-card-text v-if="!ActiveTemplate && !ViewingTemplate">
          <div v-if="ActiveFeaturedMember.Templateid">
            You already have a tempalte installed. {{InstalledTemplate ? '('+InstalledTemplate.Name+')' : ''}}
            Below you would only find tempaltes that are pages you could add to your site. 
            Would you prefer to view all tempaltes and replace your current site? There is no going back and there aren't refunds on forfeiting any previously purchased templates.
            <v-btn outlined @click="OverrideActiveTemplate = !OverrideActiveTemplate">
             {{OverrideActiveTemplate ? 'Pages Only' : 'View Sites'}}
            </v-btn>
          </div>
              <!-- Choose from the template library below -->
            <MarketplaceTemplateCarousel :System="System" :SystemEntities="SystemEntities"
              :temp="temp" :Templates="ComputedMarketplaceTemplates" @ActivateActiveTemplateComponent="ActivateActiveTemplateComponent"
              />
        </v-card-text>
        <v-card-text v-if="TemplateisPage(ActiveTemplate) && ViewingTemplate">
          <!-- <v-btn @click="DeactivateActiveTemplateComponent()">Close</v-btn>
          <v-btn @click="DeactivateActiveTemplateComponent(true)">Install</v-btn> -->
          <LandingPageSingle :ActiveTemplate="ActiveTemplate" :System="System" :SystemEntities="SystemEntities"
      />
        </v-card-text>
        <v-card-text v-if="TemplateisSite(ActiveTemplate) && ViewingTemplate">
          
          <v-card
              flat
              tile
            >
            <v-card-title v-if="ComputedTemplateChildren[SiteTemplateChildIndex]">
              <h1
                  style="font-size: 2rem;"
                  class="red--text"
                >
                  Page {{SiteTemplateChildIndex-1+2}} of {{ComputedTemplateChildren.length}} - {{ ComputedTemplateChildren[SiteTemplateChildIndex].Name }}
                </h1>
            </v-card-title>
              <v-window
                v-model="SiteTemplateChildIndex"
                
              >
                <v-window-item
                  v-for="card in ComputedTemplateChildren"
                  :key="card.itemObjKey"
                >
                  <v-card
                    color="grey"
                    height="600"

                  >
                   
                      
                       <LandingPageSingle class="background" :ActiveTemplate="card" :System="System" :SystemEntities="SystemEntities" style="z-index:1;overflow-y: scroll;height: 100%;"
                        />
                         <!-- <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                      style="position: absolute;background-color: rgba(38,38,38,0.3);width: 100%;top: 0;z-index:2;"
                    >
                   
                    </v-row> -->
                    <!-- <v-row
                      align="center"
                      justify="center"
                      style="position: absolute;background-color: rgba(38,38,38,0.3);width: 100%;top: 190px;z-index:2;height: 600px;"
                    >
                   
                    </v-row> -->
                    
                  </v-card>
                </v-window-item>
              </v-window>

              <v-card-actions class="justify-space-between">
                <v-btn
                  text
                  @click="prevSiteTemplateChildIndex"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-item-group
                  v-model="SiteTemplateChildIndex"
                  class="text-center"
                  mandatory
                >
                  <v-item
                   v-for="(card,cardindex) in ComputedTemplateChildren"
                  :key="card.itemObjKey"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      :input-value="active"
                      icon
                      @click="toggle"
                    >
                      <v-icon>mdi-record</v-icon>
                    </v-btn>
                    {{cardindex}}
                  </v-item>
                </v-item-group>
                <v-btn
                  text
                  @click="nextSiteTemplateChildIndex"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-card-text>
      </v-card>
      </v-dialog>
      
      <v-dialog v-model="MarketplaceTemplateDialog" max-width="500">
        <v-card v-if="MarketplaceTemplate">
        <v-card-title class="recordtoolbar white--text">
          Create Marketplace Template
          <v-spacer>
          </v-spacer>
          <v-btn :to="'/MyMarketplaceTemplates'">View My Templates
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- MarketplaceTemplate {{MarketplaceTemplate.Category}} -->
        <v-text-field v-model="MarketplaceTemplate.Name" label="Name"/>
        <ContentEditableField style="padding:15px;"
                :FieldObject="MarketplaceTemplate" :FieldName="'Description'"
                :FieldValue="MarketplaceTemplate.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
          <v-select v-if="MarketplaceTemplate.Components" v-model="SelectedMarketplaceTemplateComponents" :items="MarketplaceTemplate.Components" label="Components" item-text="Name"
          multiple
          return-object
          />
           <v-switch v-if="userIsAdmin" :label="'As '+System.Name" v-model="MarketplaceTemplate.As_Platform" />
            <v-switch v-if="userIsAdmin" :label="'QuickBuild Template'" v-model="MarketplaceTemplate.QuickBuildTemplate" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="DeactivateMarketplaceTemplateDialog()" dark color="warning">
            Cancel</v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="StoreandRoutetoMarketplaceTemplate()" dark color="success">
              Save
            </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog max-width="400" v-model="NewDocLibDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewDocLib.PublishType}} Documentation Library
              </v-card-title>
              <v-card-text>
                 <v-text-field v-model="NewDocLib.Name" label="Name" />
                <!-- <v-text-field v-model="NewDocLib.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field @input="removeWhiteSpace(NewDocLib.PageIcon)" v-model.trim="NewDocLib.PageIcon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
                    <v-text-field @input="removeWhiteSpace(NewDocLib.FirstPageName)" v-model.trim="NewDocLib.FirstPageName" label="First Page Name" />
                    
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewDocLibDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewDocLib()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
    <v-dialog max-width="400" persistent v-model="NewPageDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewPage.PublishType}} Page
              </v-card-title>
              <v-card-text>
                 <v-text-field @input="removeWhiteSpace(NewPage.Name)" v-model.trim="NewPage.Name" label="Name" />
                <!-- <v-text-field v-model="NewPage.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field @input="removeWhiteSpace(NewPage.PageIcon)" v-model.trim="NewPage.PageIcon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
                    <v-icon>{{NewPage.PageIcon}}</v-icon>
              </v-card-text>
              <v-list-item @click="ActivateViewMarketplaceTemplates('Add Page')" class="background text-caption grey--text text--darken-1">
                View Templates
              </v-list-item>
              <!-- this.UserMarketAccount {{UserMarketAccount}}  -->
              <v-list-item v-if="Templateselected">
                You Selected Template {{ActiveMarketTemplateChild.Name}}
              </v-list-item>
              <v-card-actions>
                <v-btn @click="DeactivateNewPageDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewPage()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
    
     <!-- I truly think plenty will start going here but don't overkill  -->
        <SiteAssetsComponent :System="System" :SystemEntities="SystemEntities"
     @ToggleNewPhotoLibraryDialog="ToggleNewPhotoLibraryDialog" :SystemPhotoLibraries="SystemPhotoLibraries"
     />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'	
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';	
import SiteAssetsComponent from '@/components/General/SiteAssetsComponent';
import SystemAboutDialog from '@/components/General/SystemAboutDialog';
import UserLookup from '@/components/General/UserLookup'
import UserInviteForm from '@/components/General/UserInviteForm';	
import NoticesCycleComp from '@/components/SuitePlugins/DigitalNoticeBoard/NoticesCycleComp'
import MarketplaceTemplateCarousel from '@/components/SuitePlugins/MarketplaceTemplates/MarketplaceTemplateCarousel';	
import DirectoryViewer from '@/components/Directories/DirectoryViewer'
// import SystemBuilderQuickstart from '@/components/RAExclusive/SystemBuilderQuickstart';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import PhysicalAddressFieldCard from '@/components/Database/Fields/Hybrid/FieldCards/PhysicalAddressFieldCard'
export default {
    props: ['System','SystemEntities','SubscriptionPackages','SystemPhotoLibraries','Directories'],
    components: {ContentEditableField,MarketplaceTemplateCarousel,LandingPageSingle,SiteAssetsComponent,SystemAboutDialog,UserLookup,UserInviteForm,NoticesCycleComp,
    DirectoryViewer,FieldValueEditerComponent,PhysicalAddressFieldCard
    //,SystemBuilderQuickstart
    },
    data() {
        return {
          SiteOrgTypeField: {
              id: 'Site_Owner_Type',Name: 'Site_Owner_Type', DisplayName: 'Site Owner Type',
              Type: 'Radio Group',SingleSelect: true, Options: [{ID: 1000001,Name: 'Content Creator'}
              ,{ID: 1000002,Name: 'Sideline Business'},{ID: 1000003,Name: 'Sole Proprietorship'},
              {ID: 1000004,Name: 'Startup'},{ID: 1000005,Name: 'Small Business'},
              {ID: 1000006,Name: 'SME'},{ID: 1000007,Name: 'Corporate Enterprise'},{ID: 1000008,Name: 'Professional Association'},
              {ID: 1000009,Name: 'NPO'}]
            },
          Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          NewSuiteTemplateModule: '',
          FeaturedMemberFilters: [],
          DefaultNewSite: {
            Owner: '',
            Admin: '',
            SiteHosting: '',
            SkipListing: false,
          },
          NewSite: {
            Owner: '',
            Admin: '',
            SiteHosting: '',
            SkipListing: false,
          },
          firebaseConfig: '',
          clientApp: {},
          clientDB: '',
          ClientFireStore: {},
          ClientAppURL: '',
          NewSiteAdminPW: '',
          reNewSiteAdminPW: '',
         SystemFromEmailHost: '',
         SystemFromEmailAddress: '',
         SystemFromEmailPassword: '',
         SystemFromEmailPort: '',
         SystemFromEmailSecure: '',
          
          SuiteInstallerStep: 1,
          SuiteInstallerDialog: false,
          OverrideActiveTemplate: false,
            Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Billing_Address: {Name: 'Billing_Address',FieldBreakdown: [],DisplayName: 'Billing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          SystemDialogActive: false,
          SystemDialogid: '',
          AboutClassifiedsDialog: false,
          AboutGlossaryDialog: false,
          AboutChatbotDialog: false,
          AboutSiteForumsDialog: false,
          // AboutSiteMeetupsDialog: false,
          AboutSitePollsDialog: false,
          // AboutSiteBlogs: false,
          // AboutDigitalNoticeboard: false,
          // AboutSiteArticles: false,
          // AboutSiteEvents: false,
          // AboutGroupsDialog: false,
           ModulesCheck: [
                {id: 'Website_Builder',Name: 'Website',Icon: 'mdi-search-web',Active: false},
                {id: 'Social_Network_Builder',Name: 'Social Network',Icon: 'mdi-home-group',Active: false},
                {id: 'Database_Builder',Name: 'Database Apps',Icon: 'mdi-database',Active: false},
                {id: 'Documentation_Builder',Name: 'Documentation Builder',Icon: 'mdi-book-education',Active: false}
            ],
            DefaultSelectedTemplates: {
              Website_Builder: '',
              Social_Network_Builder: '',
              Database_Builder: '',
              Documentation_Builder: '',
            },
            SelectedTemplates: {
              Website_Builder: '',
              Social_Network_Builder: '',
              Database_Builder: '',
              Documentation_Builder: '',
            },
            NewSuiteStep: 1,
            NewSuiteTemplateid: '',
            NewSuiteTemplateCreateDoc: '',
            NewSuiteTemplates: [],
            NewAccount: {},
            NewSuiteTemplate: {
              PluginDataBase: {}
            },
            DefaultNewSuiteTemplate: {
              PluginDataBase: {}
            },
            SuiteTemplatePreDialog: false,
            SuiteTemplateDialog: false,
            ViewingTemplate: false,
            NewTempPrimCat: '',
            NewTempSecCat: '',
            PrimaryCategories: [
                {ID:1000001,Name: 'Site'},
                {ID:1000002,Name: 'Page'}
            ],
            SecondaryCategories: [
                {ID: 1000001, Name: 'Website'},
                {ID: 1000002, Name: 'Social Network'},
                {ID: 1000003, Name: 'Group Site'},
                {ID: 1000004, Name: 'Featured Member'}
            ],
          RATemplateView: false,
          SiteTemplateChildIndex: 0,
          MarketTemplateUse: '',
          ViewMarketplaceTemplates: false,
          ViewingTemplate: false,
          ActiveTemplate: '',
          Templateselected: false,
          DefaultMarketplaceTemplate: {},
          MarketplaceTemplate: {},
          SelectedMarketplaceTemplateComponents: [],
            WindowWidth: 0,
            WindowHeight: 0,
            NewLibraryDialog: false,
            DefaultNewLibrary: {
                Collection: '',
                CustomTagsInteger: 1000000,
                CustomTags: [],
                DisplayName: '',
                Icon: '',
                PublishType: 'Public',
                Security_Structure: 'Custom Roles',
                SingleName: '',
                SinglePath: '',
                Type: '',
            },
            NewLibrary: {
                Collection: '',
                CustomTagsInteger: 1000000,
                CustomTags: [],
                DisplayName: '',
                Icon: '',
                PublishType: 'Public',
                Security_Structure: 'Custom Roles',
                SingleName: '',
                SinglePath: '',
                Type: '',
            },
          DefaultNewPage: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: []
          },
          NewPage: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: []
          },
          NewPageDialog: false,
          NewDocLibDialog: false,
          DefaultNewDocLib: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: [],
            LibraryNavbarItems: []
          },
          NewDocLib: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: [],
            LibraryNavbarItems: []
          },
          UpdatingPluginList: false,
        }
    },	
    computed:{     
      SitesDirectory(){
          return this.Directories.find(obj => obj.id === 'Systems')
      },
      SitesCategoryField(){
          return this.SitesDirectory && this.SitesDirectory.ListPrimaryField ? 
          this.SitesDirectory.ListPrimaryField : ''
      },
      AccountFields(){
         return [{Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},this.SitesCategoryField]
        //return [{Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},{...this.Invoicing_Address}]
      },
      ActiveBillingSystem(){
        return this.IsPrimarySite ? this.$store.state.PrimarySystem : this.$store.state.PrimarySystem
        //until mini sites can monetize keep as is, but at that point becomes SiteSystem for non primary
      },
      ActiveSystemWebsite(){
        return this.$store.state.ActiveSystemWebsite
      },
      ActiveSystemSocialNetwork(){
        return this.$store.state.ActiveSystemSocialNetwork
      },
      ActiveSystemLibrary(){
        return this.$store.state.ActiveSystemLibrary
      },
      ActiveSystemDatabase(){
        return this.$store.state.ActiveSystemDatabase
      },
      FeaturedMembersOverlay(){
        return this.$store.state.FeaturedMembersOverlay
      },
      CatNavList(){
        return this.FeaturedMembersOverlay ? this.Directories.find(obj => obj.id === 'Featured_Members') : ''
      },
      EntitiesArray(){
        return this.SystemEntities
      },
      DefaultSystemEntities(){
        return this.EntitiesArray
        .filter(ent => {
          //Entities on RA db has boolean "Default_System_Entity" and this maarks the RA store Template as a default install entity 
          return this.$store.state.DefaultEntities.find(obj => obj.DisplayName.split(' ').join('_') == ent.id)
        })
        .map(ent => {
          let stateent = this.$store.state.DefaultEntities.find(obj => obj.DisplayName === ent.DisplayName)
          let entobj = JSON.parse(JSON.stringify(ent))
          if(stateent && stateent.Table_Icon){
            entobj.Table_Icon = stateent.Table_Icon
          }
          if(stateent && stateent.Security === 'Custom Roles'){
            entobj.CustomCreateRoles = []
            entobj.CustomDeleteRoles = []
            entobj.CustomListRoles = []
            entobj.CustomGetRoles = []
            entobj.CustomUpdateRoles = []
          }
          else {
            this.$store.state.BURoles.map(role => {
              entobj[role.Name] = []
            })
          }
          return entobj
        })
      },
      passwordConfirmationRule() {
            return () => (this.NewSiteAdminPW === this.reNewSiteAdminPW) || 'Password must match'
            },
      TemplateRelatedUsersArray(){
        return this.OwnershipUsersArray.filter(user => {
          return this.ActiveSuiteTemplate.Ownerid === user.id || this.ActiveSuiteTemplate.Clientid === user.id
        })
      },
      OwnershipUsersArray(){
        return this.$store.state.UsersArray.map(user => {
          let userobj = {}
          let props = ['id','Name','Surname','Full_Name','Gender','Business_Unit','Business_Unitid','Title','Email','Profile_Photo']
          props.map(prp => {
            if(typeof user[prp] !== 'undefined'){
              userobj[prp] = user[prp]
            }
          })
          return userobj
        })
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
      RAAdmin(){
          return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
      },
      RAUserObj(){
          return this.$store.state.RAUserObj
      },
      HostingOptions(){
        return [
            {Name: 'On Site',Description: `<p>This option would host your site on `+this.System.Name+` and make it available immediately, on this domain. It will automaticaly list the site unless opted out below.<p>`},
            {Name: 'Offsite',Description: `<p>When hosting offsite you will receive your own domain. There are additional costs to this option.<p>`}]
      },
      DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      PluginDataBase(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.PluginDataBase : ''
        },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
        PurchasedPlugins(){
          return this.ActiveSuiteTemplate ? this.SuitePluginList.filter(plug => {
            return this.ActiveSuiteTemplate.PluginDataBase[plug.id] && this.ActiveSuiteTemplate.PluginDataBase[plug.id].Active
          }) : []
        },
        SuitePluginList(){
              return this.ActiveSuiteTemplate ? this.DataSingleBuilder ? this.AppPluginsMatched(['Database Builder','DBB']) : this.RAPluginsMatched(['Website Builder','WB','Module'],this.DataSingleBuilder) : []
          },
        SuiteTotal(){
              return this.ActiveSuiteTemplate ? this.PurchasedPlugins.map(plug => {
                  return plug.Price
              }).reduce((a, b) => a + b, 0) : 0
        },
        DataSingleBuilder(){
            return this.$route.meta && this.$route.meta.DataSingleBuilder
        },
      PDFDataRecord(){
        return this.$store.state.PDFDataRecord
        },
      IsPrimarySite(){
      return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
    },
      UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
    UserSiteAccount(){
      return this.IsPrimarySite ? '' : 
      this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.id === this.$store.state.ActiveSuiteid) : ''
    },
        AppMonetizeBU(){
            return this.$store.state.PrimaryMonetizeBU 
            //System.Monetization_BU ? this.System.Monetization_BU : ''
            //we now also have SiteMonetizationBU which we can work in later should user have monetizaiton occuring on their site
        },
      TemplateInstallationMethod(){
        return this.$store.state.TemplateInstallationMethod
      },
      TemplateSecondaryCatFilter(){
        return this.$store.state.TemplateSecondaryCatFilter
      },
      GroupSiteTemplate(){
            return this.ActiveTemplate && this.ActiveTemplate.Category.Name === 'Group Site'
        },
      ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
       UserBillingAccounts(){
      return this.$store.state.PrimaryUserObj && this.$store.state.PrimaryUserObj.BillingAccounts ? this.$store.state.PrimaryUserObj.BillingAccounts : []
    },
      GroupBillingAccount(){
        if(this.ActiveGroup.BillingAccount){
          return this.UserBillingAccounts.find(obj => obj.id === this.ActiveGroup.BillingAccount.id)
        }
      },
      GroupSubscriptionPackage(){
        //console.log(this.GroupBillingAccount,this.SubscriptionPackages)
        if(this.GroupBillingAccount){
          return this.SubscriptionPackages.find(obj => obj.id === this.GroupBillingAccount.Subscription_Packageid)
        }
      },
      ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
      NewPageSite(){
        return this.$store.state.NewPageSite
      },
      SuiteInstallerStepDiabled(){
        //really should bew vlaidation of "this.NewSite" in correspondence with this.SuiteInstallerStep value
        return false
      },
      NSSaveEnabled(){
        return this.NewSuiteStep === 3 && !this.userLoggedIn.IsWebDeveloper || this.NewSuiteStep === 4 && this.userLoggedIn.IsWebDeveloper
      },
      NSStepNextDisabled(){
        return this.NSSaveEnabled || this.NewSuiteStep === 3 && !this.NewSuiteTemplate.Description && !this.NewSuiteTemplate.Name
        //|| this.NewSuiteStep === 1 && !this.ModulesCheck.find(obj => this.SelectedTemplates[obj.id])
        //we now allow without templates, purchase templates afterwards|| this.NewSuiteStep === 2 && !this.ModulesCheck.find(obj => this.SelectedTemplates[obj.id])
      },
      NewSuiteTotal(){
         return this.UpdatingPluginList || !this.SuiteTemplateDialog ? 0 : this.RAPlugins.filter(plug => {
                return this.NewSuiteTemplate.PluginDataBase[plug.id].Active
            }).map(plug => {
                return plug.Price
            }).reduce((a, b) => a + b, 0)
      },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
      Modules(){
          return this.RAPlugins.filter(plug => {
              return plug.Module_Category.Name === 'Module'
          })
      },
      ComputedModules(){
            return this.Modules.map(mod => {
                let modobj = Object.assign({},mod)
                let match = ''
                if(this.PluginDataBase){
                    match = this.PluginDataBase[mod.id]
                    if(match && match.Active){
                        modobj.Active = true
                    }
                }
                return modobj
            }).sort(function (a,b){
            return (b.Name > a.Name) ? 1 : -1;
            })
        },
      currentNewSiteTitle(){
        switch (this.SuiteInstallerStep) {
          case 1: return 'Hosting'
          case 2: return 'Name and Ownership'
          case 3: return 'Backend Config'
          case 4: return 'Administrator Password'
          case 5: return 'RA User Account'
          case 6: return 'Email Config'
          default: return 'New Site'
        }
      },
      currentNSDescription(){
        switch (this.NewSuiteStep) {
          case 1: return `Let us configure the Scope for your Site. This is OPTIONAL, and can all be done AFTER creating your Site.`
          case 2: return `We need a new Billing Account. Let's capture some basic info for the Entity (Organisation or Individual) responsible for the Account.`
          case 3: return `Let's capture the Site information. This does NOT need to be the same as the Account.`
          case 4: return `Building for a Client? Select your Client from the existing Members, or invite them!`
          default: return 'New Site'
        }
      },
      currentNSTitle(){
        switch (this.NewSuiteStep) {
          case 1: return 'Scope'
          case 2: return 'Billing Info'
          case 3: return 'Site Info'
          case 4: return 'Client Info'
          default: return 'New Site'
          // case 1: return 'Basic'
          // case 2: return 'Modules and Plugins'
          // case 3: return 'Client Info'
          // default: return 'New Suite'
        }
      },
      samplenotice(){
        let dateobj = new Date()
        let not = {}
        not = {
          coverimageThumbURL: require('@/assets/GallerySample2.jpg'),
          Category: {Name: 'General',Icon: 'mdi-eye'},
          Icon: 'mdi-eye',
          caption: 'Tea break has been cancelled until futher notice',
          monthcreated: this.TimestampFormatterNoticeBoard(dateobj,'month'),
          daycreated: this.TimestampFormatterNoticeBoard(dateobj,'date'),
          createddate: this.TimestampFormatterNoticeBoard(dateobj,'full'),
          createdon: this.TimestampFormatterNoticeBoard(dateobj,'full'), 
        }
        return not
      },
      //When viewing a "site" template, viewing for instnace a "page", then the Primary Parent is ActiveMarketTemplate, while the page I am viewing is ActiveMarketTemplateChild
      ActiveMarketTemplate(){
          return this.$store.state.ActiveMarketTemplate
      },      
      ActiveMarketTemplateChild(){
          return this.$store.state.ActiveMarketTemplateChild
      },
      ComputedMarketplaceTemplates(){
        //console.log('this.MarketplaceTemplates',this.MarketplaceTemplates)
        let routes = [
          //btw this would allow two secondarycat IF ever needed...
           {name: 'MyAccount',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Featured Member','Group Site','Website'],Categories: ['Website','Group Site','Featured Member']},
           //For My Account we will push a seconcat filter to store
          {name: 'FeaturedMemberEditor',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Featured Member']},
          {name: 'FeaturedMemberCreator',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Featured Member'],Categories: ['Featured Member']},
          {name: 'Suite Template Website',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Website']},
          {name: 'Suite Template Social Network',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Social Network']},
          {name: 'MarketplaceTemplate',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Website','Social Network']},
          {name: 'PageEditor',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Website','Social Network'],Categories: ['Web Page','Social Page']},
           {name: 'login',PrimaryCategories: ['Page','Site'],SecondaryCategories: ['Website','Social Network']},          
        ]
        //additional problem here bud...if websitebuilderview and no public pag4es the route is "login"...
        routes = routes.map(route => {
          if(this.$route.name === 'PageEditor' && this.EditedPage && this.EditedPage.PublishType === 'Members Only'){
            //not sure anything needed? But this means we social network builder view now
          }
          return route
        })
        let route = routes.find(obj => obj.name === this.$route.name)
        return this.RATemplateView ? this.RAMarketplaceTemplates.filter(temp => {
          return !temp.Parentid
          //   && temp.PublishStatus === 'Published'
        })
        .filter(temp => {
          return route.Categories.includes(temp.Category.Name)
        }) : 
        this.MarketplaceTemplates.filter(temp => {
          return !temp.Parentid && temp.PublishStatus === 'Published'
        })
        .filter(temp => {
          //console.log('temp',temp)
          return route.Categories.includes(temp.Category.Name)
        }).filter(temp => {
          //console.log('temp',temp.id,temp,this.TemplateSecondaryCatFilter)
            return this.TemplateSecondaryCatFilter ? temp.Category.Name === this.TemplateSecondaryCatFilter : temp
        })
        .map(temp => {
          let tempobj = Object.assign({},temp)
          let owner = this.UsersStore.find(obj => obj.id === temp.Ownerid)
          if(owner && owner.Profile_Photo){
            tempobj.Profile_Photo = owner.Profile_Photo
          }
          if(typeof tempobj.Price === 'undefined' || tempobj.Price === 0){
                    tempobj.Price = 'Free'
                }
          return tempobj
        }).filter(temp => {
          if(this.$route.name === 'FeaturedMemberEditor'){
            return !this.ActiveFeaturedMember.Templateid || this.TemplateisPage(temp) || this.OverrideActiveTemplate
          }
          else{
            return temp
          }
        })
        //final thing would be to filter based on whatever you call from right? so
      },
      InstalledTemplate(){
        return this.$route.name === 'FeaturedMemberEditor' && this.ActiveFeaturedMember && this.ActiveFeaturedMember.Templateid ? this.MarketplaceTemplates.find(obj => obj.id === this.ActiveFeaturedMember.Templateid) : ''
      },
      ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
      ComputedTemplateChildren(){
        if(this.RATemplateView){
          return this.ActiveMarketTemplateChild && this.ActiveMarketTemplateChild.Children ? 
            this.RAMarketplaceTemplates.filter(temp => {
                return this.ActiveMarketTemplateChild.Children.includes(temp.id)
            }) :
            this.ActiveTemplate && this.ActiveTemplate.Children ? 
            this.RAMarketplaceTemplates.filter(temp => {
                return this.ActiveTemplate.Children.includes(temp.id)
            }) : []
        }
        else{
          return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Children ? 
            this.MarketplaceTemplates.filter(temp => {
                return this.ActiveMarketTemplate.Children.includes(temp.id)
            }) :
            this.ActiveTemplate && this.ActiveTemplate.Children ? 
            this.MarketplaceTemplates.filter(temp => {
                return this.ActiveTemplate.Children.includes(temp.id)
            }) : []
        }
            
        },
        SuiteTemplateMain(){
        return this.$route.name === 'Suite Template Main'
        },
        SuiteTemplateWebsite(){
        return this.$route.name === 'Suite Template Website'
        },
        SuiteTemplateSocialNetwork(){
        return this.$route.name === 'Suite Template Social Network'
        },
        SuiteTemplateDatabase(){
        return this.$route.name === 'Suite Template Database App'
        },
        SuiteTemplateView(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
      MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.ActiveSuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        RADB(){
            return this.$store.state.RADB
        },
        ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
        DocLibisRADB(){
            return this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.Database && this.ActiveDocumentationLibrary.Database === this.RADB
        },  
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
      FeaturedMembersOverlay: {
            handler: function(newvalue, oldvalue) {
              if(!newvalue){
                this.FeaturedMemberFilters = []
              }
            }
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ToggleNewPageDialog','DeactivateNewPageDialog','ToggleNewDocLibDialog','ToggleNewPhotoLibraryDialog','ToggleNewDocumentLibraryDialog',
        'ReplicatetoClientSuite',
        'PrepMarketplaceTemplateInvoice','PrepareNewSiteHosting','ProcessMarketTemplateInvoice','ProcessNewSiteHostingInvoice','ActivateSuiteTemplateInstallerDialog','ActivateFeaturedMembersOverlay',
        'ActivateMarketplaceTemplateDialog','InitiateFeaturedMember','ActivateViewMarketplaceTemplates','ActivateSuiteTemplateDialog','ToggleSystemAboutDialog']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){  
      //this.TestSiteInstall()
      //NEED TO CONSIDER THE SUBCOLS THAT MAKE A SOCIAL NETWORK WORK. THESE ITEMS MAKE IT WORK. SEPARATED AS
      //"samples" IS PLAIN SAMPLES USER CAN ADD TO THE TEMPLATE
      //FACOTRYSAMPLES - WE GOTTA THINK LOGICALLY HERE BUT LIST OF USERS AND CRAP AUTO CREATE OR KEEP OR WHATEVER
      //FACTORYSUBSAMPLES, ONCE AGAIN IT'S ACTUAL USER INTERACTION SO YES
      //FINALLY, I WOULD PREFER THINKING VERY CLEARLY HERE. IF WE PRESET USERS LET'S GET MEMBER GROUPS ASSIGN THEM TO USERS, TEST OUT MY PREVIEW MODE CHANGE TO PARTICULAR USER. YOU GET?
      let samples = ['samplesitearticles','samplesiteblogs','sampleclassifieds','samplesiteevents','samplesiteforumdiscussions','sampleglossary','samplegroups','samplesitemeetups','samplenotices']
      let factorysamples = ['sampleusers','samplesystemactivities','samplesocialposts','samplesitemoderators']
      let factorysubsamples = ['samplesocialsitecomments','samplesocialsiteimages','samplesocialsitelikes','samplesocialsitereplies','samplesitepollvotes']
      this.PrepareAddressField('Company_Address')
      this.PrepareAddressField('Billing_Address')
      this.PrepareAddressField('Invoicing_Address')
      console.log(this.Invoicing_Address)
    },
    methods:{
      TemplateisPage(temp){
        let pagecats = this.$store.state.TemplateCategories.filter(obj => obj.IsPage).map(obj => {
          return obj.ID
        })
        return temp && temp.Category && pagecats.includes(temp.Category.ID)
      },
      TemplateisSite(temp){
        let sitecats = this.$store.state.TemplateCategories.filter(obj => obj.IsSite).map(obj => {
          return obj.ID
        })
        return temp && temp.Category && sitecats.includes(temp.Category.ID)
      },
      ConfigureApplicationOption(option){
        if(option === 'Skip Listing'){
          this.NewSuiteTemplate.PrimaryScope.Listing = false
          this.NewSuiteTemplate.PrimaryScope.Website = false
        }
        else{
           this.NewSuiteTemplate.PrimaryScope.Listing = true
           this.NewSuiteTemplate.PrimaryScope.Website = true
        }
      },
      SaveRecord(payload){
        //console.log(payload,this.NewAccount)
      },
      ActivateModuleTemplateDialog(mod){
        if(mod.id === 'Website_Builder'){
          //this.ActivateTemplateDialog('Site')
          this.$store.commit('SetSocialItemInteractMethodProp','Site') 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewMarketplaceTemplates')
          this.$store.commit('setTemplateSecondaryCatFilter','Website')
          //this is RIGHT, problem is we have no website templates will need to make work of that!
          this.NewSuiteTemplateModule = mod
          console.log(this.userLoggedIn)
        }
      },
      ActivateFeaturedMembersOverlay(payload){
        this.FeaturedMemberFilters = payload        
        this.$store.commit('setFeaturedMembersOverlay',true)
      },
//       async TestSiteInstall(){
//         console.log('TestSiteInstall')
//         let vm = this            
//         vm.NewSite.id = 'm9BLksnQHmuY3idNxs8a'
//         const clientfbconfig = {
//   apiKey: "AIzaSyCoROee3e-H8Seh_1Dro0DJMizu8KO7KcU",
//   authDomain: "kudu-sigma-engineering.firebaseapp.com",
//   projectId: "kudu-sigma-engineering",
//   storageBucket: "kudu-sigma-engineering.appspot.com",
//   messagingSenderId: "598549777576",
//   appId: "1:598549777576:web:7829411ebb0ea3f6e2869d",
//   measurementId: "G-XCFLLT6R59"
// };
//   let appname = 'Kudu Sigma Engineering'
//         vm.clientApp = firebase.apps.find(obj => obj.name === appname)
//         if(!vm.clientApp){
//           vm.clientApp = firebase.initializeApp(clientfbconfig, appname);
//         }
//         vm.clientDB = vm.clientApp.firestore()
//         firebase.auth().signInWithEmailAndPassword(vm.userLoggedIn.Email,'MAS13ter!').then((response) => {
//           console.log('response',response)  
//                 vm.RADB.collection('systems').doc(vm.NewSite.id).get().then(snapshot => {
//                   let systemobj = snapshot.data()
//                   vm.WrapupAppsandPlugins().then(plugdone => {
//                      vm.InstallSuitetoClientDB(systemobj)
//                   })
                 
//                 })
//         })
//       },
      CheckNewSiteOwnership(){
        if(this.NewSite.SiteHosting === 'Offsite'){
          this.NewSite.Owner = this.NewSite.Admin
          this.NewSite.Ownerid = this.NewSite.Adminid
          //this is just for "safety" but user can change back to tempalte client
        }
        else{
          this.NewSite.Owner = this.OwnershipUsersArray.find(obj => obj.id === this.ActiveSuiteTemplate.Clientid)
          if(this.NewSite.Owner){
            this.NewSite.Ownerid = this.ActiveSuiteTemplate.Clientid
          }
        }
      },
      removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
      ToggleQuickStartDialog(bool){
            this.QuickStartDialog = bool
        },      
      PrepareAddressField(fieldname){
        let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
      },
      CloseSystemDialog(){
        this.SystemDialogActive = false
        this.SystemDialogid = ''
        //console.log(this.SystemDialogid,this.SystemDialogActive)
      },
      ToggleSystemAboutDialog(dial){
        //this[dial] = true
        this.SystemDialogid = dial
        this.SystemDialogActive = true
        //console.log(this.SystemDialogid,this.SystemDialogActive)
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      tempFilter (item, queryText, itemText) {
        //see userFilter from UserLookup comp
          const Name = item.Name.toLowerCase()
          const searchText = queryText.toLowerCase()

          return Name.indexOf(searchText) > -1 
      },
      AssignTemplatePluginDataBase(modid){
        //console.log(this.SelectedTemplates,this.SelectedTemplates[modid])
        if(this.SelectedTemplates[modid]){
          let temp = this.MyMarketplaceTemplates.find(obj => obj.id === this.SelectedTemplates[modid])
        this.RAPlugins.map(plug => {
          if(temp.PluginDataBase[plug.id] && temp.PluginDataBase[plug.id].Active){
            this.NewSuiteTemplate.PluginDataBase[plug.id].Active = true
          } 
        })
        //need to create templates correclty but anyway
        if(temp.Category.Name === 'Website'){
          this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active = true 
        }
        else if(temp.Category.Name === 'Social Network'){
          this.NewSuiteTemplate.PluginDataBase.Social_Network_Builder.Active = true 
        }
        this.ToggleUpdatingPluginList()
        }
        else{
          //great but what if I claered it? oops...
        }
        
      },
      MyMarketplaceTemplatesFiltered(modid){
        let secondcat = modid === 'Website_Builder' ? 'Website' : modid === 'Social_Network_Builder' ? 'Social Network' : 'TBD'
        // let allowedtemplates = this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates.filter(item => {
        //   return this.userLoggedIn.ContentPurchases.find(obj => obj.Contentid === item.id)
        // }))
        let allowedtemplates = this.MyMarketplaceTemplates
        return allowedtemplates.filter(item => {
          return item.Category.Name === secondcat && !item.Parentid
        })
      },
      ToggleUpdatingPluginList(){
            this.UpdatingPluginList = true
            
            setTimeout(() => {
                this.UpdatingPluginList = false
            }, 20);
            //OKay this we could extend and should extend. Run through entire selected list. Essentially, 
            //1. If the parent module not activated then activate it but also
            //2. If a "shared" thing exist, like SNB and WB...then activate BOTH shared modules. you get?
            //3. finally also if I had a templte value in SelectedTemplates for mod.id I probably need to remove it if "deactivated"
        },
      PluginList(item){
        let DataSingleBuilder = item.id === 'Database_Builder'
        let categorymatches = item.id === 'Database_Builder' ? ['Database Builder','DBB'] : item.id === 'Database_Builder' ? ['Social Network Builder','SNB'] : 
        item.id === 'Documentation_Builder' ? ['Documentation Library'] : ['Website Builder','WB']
        //sconsole.log('item',item,'categorymatches',categorymatches)
        //I decided to drop the ,'WB' to prevent Social Mod plugins from being activated through website mod...right?
        return this.RAPluginsMatched(categorymatches,DataSingleBuilder)
        //this.DataSingleBuilder ? this.AppPluginsMatched(['Database Builder','DBB']) : this.RAPluginsMatched(['Website Builder','WB'])

      },
      AppPluginsMatched(categorymatches){
            let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
      RAPluginsMatched(categorymatches,DataSingleBuilder){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
            if(DataSingleBuilder){
                return item.Exclusively_as_Extension
            }
            else{
                return item
            }
        })
      },
      ProgressNSWindow(){
        //console.log(this.NewSuiteTemplate,this.NewAccount)
        if(this.NewSuiteStep === 2){
          this.NewSuiteTemplate.Name = this.NewAccount.Company_Name
        }
        this.NewSuiteStep++
      },
      RegressNSWindow(){
        this.NewSuiteStep--
      },
      ProcessSuiteTemplateDialog(){
        this.SuiteTemplatePreDialog = false
        this.SuiteTemplateDialog = true
      },
      ActivateSuiteTemplateDialog(){
            //TEchnically we also need a "push to Suite"            
            if(this.ActiveMarketTemplate){
              if(this.ActiveMarketTemplate.Category.Name === 'Website'){
                if(!this.NewSuiteTemplate.PluginDataBase.Website_Builder){
                  this.NewSuiteTemplate.PluginDataBase.Website_Builder = this.ModulesCheck.find(obj => obj.id === 'Website_Builder')
                }
               this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active = true 
              }
              if(this.ActiveMarketTemplate){
                //WRONG...because you may have add after the fact, this must be done before dial opens
                this.NewSuiteTemplate.PluginDataBase = this.ActiveMarketTemplate.PluginDataBase
              }
            }
            this.RAPlugins.map(mod => {
              if(typeof this.NewSuiteTemplate.PluginDataBase[mod.id] === 'undefined'){
               this.NewSuiteTemplate.PluginDataBase[mod.id] = {
                id: mod.id,
                Name: mod.Name,
                Active: false,
                Module_Category: mod.Module_Category
              } 
              }              
              //ALSO CHECK IF FROM TEMPLATE, IF SO, ASSIGN IT'S VALUE
              
            })
            this.NewSuiteTemplate.Business_Unit = {id: 'Head_Office',Name: 'Head Office'}
            this.NewSuiteTemplate.Business_Unitid = 'Head_Office'
            //this.SuiteTemplateDialog = true
            this.NewSuiteTemplate.PrimaryScope = {
              Website: true,
              Listing: true
            }
            this.SuiteTemplatePreDialog = true
            

        },
        CheckInviteData(){
            if(this.NewSuiteTemplate.ClientisNonMember && !this.NewSuiteTemplate.InviteData){
                this.NewSuiteTemplate.InviteData = {}
            }
            if(this.NewSuiteTemplate.SkipClientInfo || !this.NewSuiteTemplate.ClientisNonMember){
                delete this.NewSuiteTemplate.InviteData
            }
        },
        UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
            RecordObj[Prop+'id'] = Value.id
        },
        CancelSaveSuiteTemplate(){
            this.SuiteTemplatePreDialog = false
            this.SuiteTemplateDialog = false
            this.NewSuiteTemplate = Object.assign({},this.DefaultNewSuiteTemplate)
            this.NewAccount = {}
            this.SelectedTemplates = Object.assign({},this.DefaultSelectedTemplates)
            this.NewSuiteStep = 1
            this.NewSuiteTemplateCreateDoc = ''
            this.NewSuiteTemplateid = ''
            this.NewSuiteTemplates = []
        },
        SaveSuiteTemplate(){
          //we need to check this.SelectedTemplates
          //get the website template, get the scoial network template etc...make this shit work bob!
          let templates = this.MyMarketplaceTemplates.filter(temp => {
            return this.SelectedTemplates.Website_Builder === temp.id || this.SelectedTemplates.Social_Network_Builder === temp.id
             || this.SelectedTemplates.Database_Builder === temp.id || this.SelectedTemplates.Documentation_Builder === temp.id
          })
          //we got templates and each has a parent and each parent get added then children....
           	
            let itemdb = this.clientDB ? this.clientDB : db
            let path = itemdb.collection('SystemConfig')
            let ref = path.doc()
            this.NewSuiteTemplateid = ref.id
            let newdocid = this.NewSuiteTemplateid
            let user = {}
            let props = ['id','Name','Surname','Full_Name','Gender','Business_Unit','Business_Unitid','Title','Email','Profile_Photo']
            props.map(prp => {
              if(typeof this.userLoggedIn[prp] !== 'undefined'){
                user[prp] = this.userLoggedIn[prp]
              }
            })
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            //let systemurl = this.System.ClientAppURL
            let defobj = {
                ...this.NewSuiteTemplate,
                SelectedTemplates: this.SelectedTemplates,
                Templateid: '',
                Systemid: systemid,
                //ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                HasClientRecord: false,
            } 
            if(this.SuiteInstallerDialog){
              //the logic is, if SuiteInstallerDialog we are transfering to client db, thus ClientappURL matters
              let systemurl = this.System.ClientAppURL
              defobj.ClientAppURL = systemurl
            }
             //defobj.Templateid = this.ActiveMarketTemplate.id MISSING but I don't thinkn it's useful
            if(!defobj.InviteData){ 
            this.$store.commit('setCustomProcessingDialog',true)
            this.CreateSiteAccount(user,defobj,itemdb,newdocid,templates)
            
            }
            else{
                defobj.InviteData.RelatedObj = {
                  id: newdocid,
                  Name: this.NewSuiteTemplate.Name,
                  identifier: 'Name',
                  collection: 'suitetemplates',
                  Link: '/Your-Domain/'+newdocid+'/Main'
                }
                defobj.InviteData.RelatedField = 'Client'
                //only as a note this makes no sense coming from tablemain right? but we must assignrelatedField how do we do that?
                // this.$store.commit('setRelatedRecordObj',RelatedObj)
                // this.$store.commit('SetSocialItemInteractMethodProp',field) 
                // this.$store.commit('SetSocialItemInteractMethod','ActivateAddNewLookupDialog')

                this.NewSuiteTemplateCreateDoc = defobj
                this.NewSuiteTemplates = templates
                this.$emit('ActivateProcessing',true)
                this.SendInvitetoUser(defobj.InviteData)
            }

        },
        
        
          //TOO FREAKING COMPLICATED, NEW SUITE IS NOT PUBLISHED YET DUDE WON'T SEE SO WE CANCELLED THIS ON CREATE LOGIC
        // PrepareInvitetoUser(editedInvite){
        //     this.NewSuiteTemplate.InviteData = editedInvite
        //     console.log('this.NewSuiteTemplate',this.NewSuiteTemplate)
        // },
        // SendInvitetoUser(editedInvite){
        //     //lol, technicall first complete the frigging data
        //     this.$store.commit('SetSocialItemInteractMethodProp',editedInvite) 
        //     this.$store.commit('SetSocialItemInteractMethod','SendInvitetoUser')
        // },
        // CancelInvitingUserEmail(){
        //     //gonna leave it here but blank. nothing went to appnot, why cancell right?
        //     //this.$store.commit('SetSocialItemInteractMethod','CancelInvitingUserEmail') 
        // },
        // AssignInviteid(result){
        //   //console.log('AssignInviteid',this.ItemOperationalDB)
        //   this.NewSuiteTemplateCreateDoc.ClientInviteID = result.InviteID
        //   //OKAY WOAH THIS WON'T WORK WE NEED THE BELOW PASSED PROPS SO
        //   //1. assign these props as globalvariable in this compo then
        //   //2 userinv ,ust be a comp recycle here...
        //   let path = db.collection('SystemConfig')
        //   let newdocpath = path.doc(this.NewSuiteTemplateid)
        //   this.ProcessNewSuiteTemplate(this.NewSuiteTemplateCreateDoc,newdocpath,this.NewSuiteTemplateid,this.NewSuiteTemplates)
        // },
        CreateSiteAccount(user,defobj,itemdb,newdocid,templates){
          //BELOW CREATES A SITE ACCOUNT FOR ON SITE HOSTING,
          //UNSURE IF ActivateSuiteTemplateInstallerDialog CHAIN CALLS THIS METHOD BUT IF SO, GOTTA SHARPEN UP AS THAT WOULD REQUIRE SUITE OWNERSHIP BA ON RA
          let vm = this
          vm.$store.commit('setCustomProcessingDialogText','Creating Account')
          let payinguser = ''
          if(this.NewSuiteTemplate.SkipClientInfo){
            payinguser = this.OwnershipUsersArray.find(obj => obj.id === this.userLoggedIn.id)
          }
          else{
            payinguser = this.NewSuiteTemplate.Client
          }
          if(!payinguser){
            payinguser = user
          }
          let rauser = this.OwnershipUsersArray.find(obj => obj.id === this.userLoggedIn.id)
          if(!rauser){
            rauser = user
          }
          let status = vm.$store.state.DefaultStatusField
          let buobj =  {id: 'Head_Office',Name: 'Head Office'}
          let baseobj = {          
            Business_Unit: buobj,
            Business_Unitid: buobj.id,
            Created_On: rauser.Created_On,
            Owner: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Ownerid: rauser.id,
            Created_By: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Created_Byid: rauser.id,
            Created_On: new Date(),
            Modified_By: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Modified_Byid: rauser.id,
            Modified_On: new Date(),
            Status: status.DefaultOption,
            Status_Reason: status.DefaultLevel2Option,
            Billing_Type: {ID: 1000001, Name: 'COD'}
          }
          let newbaobj = {
            ...baseobj,
            Client_Reference: this.NewAccount.Company_Name,
            Account_Name: this.NewAccount.Company_Name,
            Account_Type: {ID: 1000007, Name: 'Site Account'},
            Type_Default: false,
            PaymentSchedule: 'Monthly',
            IsFinal: false,
            User: payinguser,
            Userid: payinguser.id,
            Total_Invoiced: 0,
            Total_Paid: 0,
          }
          this.Invoicing_Address.FieldBreakdown.map(brd => {
            if(this.NewAccount[brd.Name]){
                newbaobj[brd.Name] = this.NewAccount[brd.Name]
            }
          })
          //IF WE DO LOOKUPOBJ TO BA FOR NEW SITE IT IS LOOKUP TO "SYSTEMS" ENTRY, WHERE APPS AND PLUGINS RESIDE YES!
          vm.$store.dispatch('CreateSearchTitle',newbaobj.Client_Reference).then(basearchtitle => {
            const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            console.log('billingaccount',newbaobj)
            let payload = {
              Docid: 'BillingAccounts_Customer_ID',
              Data: newbaobj,
              siteid: process.env.VUE_APP_RA_PROVIDER_ID
            }            
            vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')            
              AssignAutoNumber(payload).then(result => {
                newbaobj.Customer_ID = result.data
                newbaobj.id = newbaobj.Customer_ID
                newbaobj.SearchQuery = basearchtitle    
                defobj.id = newbaobj.Customer_ID        
                //ADDITIONALLY - SHOULD WE ALLOW TEMPALTES FROM MARKET HERE, THE BILLING WOULD APPLY TO THE BA HERE, OR THE MARKET BA DEPENDING - ALTHOUGH REMAINS A ONCE OFF PURCHASE YES
                //REFERRING TO PURCHASING A MARKETPLACE TEMPLATE IN ORDER TO CREATE A SITE, THAT IS
                //would also impact newdocid
                newdocid = newbaobj.id
                
                let masterref = itemdb.collection('SystemConfig').doc(newdocid)
                db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('billingaccounts').doc(newbaobj.id).set(newbaobj).then(newba => {
                  defobj.BillingAccount = {Client_Reference: newbaobj.Client_Reference,Customer_ID: newbaobj.Customer_ID,id: newbaobj.id},
                  defobj.BillingAccountid = newbaobj.id,      
                  //actually the answer lies here
                  //first of all we need to create templates where there aren;t default templates being default modules.
                  vm.ProcessNewSuiteTemplate(baseobj,defobj,itemdb,masterref,newdocid,templates)
                  // let defaulttemplates = [
                  //   {
                  //     Primary_Category: {ID: 1000002,Name: 'Site'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Website'}
                  //   },
                  //   {
                  //     Primary_Category: {ID: 1000002,Name: 'Site'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Social Network'}
                  //   },
                  //   {
                  //     Primary_Category: {ID: 1000003,Name: 'Library'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Photo Library'}
                  //   },
                  //   {
                  //     Primary_Category: {ID: 1000004,Name: 'App'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Website'}
                  //   }
                  // ]
                })    
                
              })
          })
          //Customer_ID id
          
        },
        ProcessNewSuiteTemplate(baseobj,defobj,itemdb,masterref,newdocid,templates) {
          //right now THINK
          //1. New structure, we do NOt add to "suitetemplates" and not subcol "websites", it's main col 'Websites' etc
          masterref.set(defobj).then(newdoc => {
            let webdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active : false
            }
            let webprops = ['Company_Name','Site_Category','Site_Class','Site_Type']
            webprops.map(prp => {
              if(typeof this.NewAccount[prp] !== 'undefined'){
                webdoc[prp] = this.NewAccount[prp]
              }
            })
            let socialdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Social_Network_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Social_Network_Builder.Active : false
            }
            let dbdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Documentation_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Documentation_Builder.Active : false
            }
            let libdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Database_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Database_Builder.Active : false
            }
            itemdb.collection('Websites').doc(newdocid).set(webdoc)
            itemdb.collection('SocialNetworks').doc(newdocid).set(socialdoc)
            itemdb.collection('Libraries').doc(newdocid).set(dbdoc)
            itemdb.collection('Databases').doc(newdocid).set(libdoc)
            
          let photolib = Object.assign({},this.SystemPhotoLibraries.find(obj => obj.id === 'System_Assets'))
              photolib.CustomTags = []
              photolib.CustomTagsInteger = 1000000
              let defastlibref = itemdb.collection('Libraries').doc(newdocid).collection('PhotoLibraries').doc(photolib.id)
              defastlibref.set(photolib).then(addedsysassetdoc => {
              if(templates.length === 0){
                    //Actually if the person logged in does NOt have any market tempaltes...They can install the new item WHEREAFTER they
                    //install the relevant tempaltes, for that Newsite.
                    //remember if we don't allow this it means, we let you purchase a tempalte ONCe and use FOREVER which is kind of stinky.
                    this.NewSiteTools(defobj,itemdb)
                }
                else{
                  this.$store.commit('setCustomProcessingDialogText','Checking Assets')
                  let assets = templates.filter(temp => {
                    return temp.Assets
                  }).map(temp => {
                    return temp.Assets
                  }).flat()
                    let totaltemplates = templates.length
                    let templatecounter = 0
                    let totalassets = assets.length
                    let assetcounter = 0
                    if(assetcounter === totalassets){
                    this.ProcessNewSuiteMarketTemplate(defobj,itemdb,masterref,newdocid,templates,totaltemplates,templatecounter)
                    }
                    else{
                      this.$store.commit('setCustomProcessingDialogText','Adding Assets')
                      assets.map(ast => {
                        defastlibref.collection('Assets').doc(ast.id).set(ast).then(newastdoc => {
                            assetcounter++
                            if(assetcounter === totalassets){
                              this.ProcessNewSuiteMarketTemplate(defobj,itemdb,masterref,newdocid,templates,totaltemplates,templatecounter)
                            }
                        })
                      })
                    }
                }
              })
          })
            
        },
        ProcessNewSuiteMarketTemplate(defobj,itemdb,masterref,newdocid,templates,totaltemplates,templatecounter){
          //SO WHEN WE ADD DBB AND LIBRARY MODULES, THIS IS WHERE WE CONFIGURE THEM, TAKE NOTES!
          //ALSO, WE ARE NOT TOUCHING CREATING USERROLES AND STUFF AS YET. SO USERROLES AND STUFF AS WELL AS DEFAULT ENTITIES
          this.$store.commit('setCustomProcessingDialogText','Adding Templates')
          //THE BELOW STRUCTURE STILL DOES NOT WORK FOR ADIDNG TO RA, SO FIGURE IT OUT, ALTHOUGH I DOUBT THE FUNCITON GETS CALLED FOR IT EITHER
          templates.map(modtemp => {
                let newtempref = ''
                let newstoreobj = {}
                if(modtemp.Category.Name === 'Site'){
                  if(modtemp.Category.Name === 'Social Network'){
                    newtempref = itemdb.collection('SocialNetworks').doc(newdocid)
                    newstoreobj = {
                      PluginDataBase: modtemp.PluginDataBase,
                      Templateid: modtemp.id,
                      SocialNavbarItems: modtemp.TemplateProps.Navlist.map(item => {
                        item.id = item.title.split(' ').join('_').split('-').join('_')
                        return item
                      }),
                    }
                  }
                  else if(modtemp.Category.Name === 'Website'){
                    newtempref = itemdb.collection('Websites').doc(newdocid)
                    newstoreobj = {
                      PluginDataBase: modtemp.PluginDataBase,
                      Templateid: modtemp.id,
                      PublicNavbarItems: modtemp.TemplateProps.Navlist.map(item => {
                        item.id = item.title.split(' ').join('_').split('-').join('_')
                        return item
                      }),
                    }
                  }
                  for(var prop in modtemp.TemplateProps) {
                    if (modtemp.TemplateProps.hasOwnProperty(prop)) {
                        if(typeof modtemp.TemplateProps[prop] !== 'undefined'){
                        newstoreobj[prop] = modtemp.TemplateProps[prop]
                        if(prop === 'Navlist'){
                         newstoreobj[prop] = newstoreobj[prop].map(item => {
                            item.id = item.title.split(' ').join('_').split('-').join('_')
                            return item
                          })
                        }
                        }
                    }
                  }
                  let childcounter = 0
                  let children = this.MyMarketplaceTemplates.filter(item => {
                    return item.Parentid === modtemp.id
                  })
                  let totalchildren = children.length
                  newtempref.set(newstoreobj).then(newtempdoc => {
                     if(modtemp.Category.Name === 'Social Network'){
                    //need to check CategoryFields and respective Categories
                    //also "samples" ?? also "Directories" which is gonna be added to "Libraries"
                    //however...possibly all of this relaly only "Directories" that matter, like if you don't transfer samples then there is not need to transfer categories right?
                    //but okay, for testing purposes is there ANY point to NOT add records?
                    //Maybe yes, honesltybecause I need to test the loading of records don't I?
                     }
                    
                    if(totalchildren === childcounter){
                      //LOOK IF WE ONLY INSTALL WEBSITES OR SOCIAL NETWORK TEMPALTES THIS WOULD NEVER PASS, THERE WOULD ALWAYS BE PAGES, ERGO ALWAYS CHILDREN
                      templatecounter++
                      if(templatecounter === totaltemplates){
                       this.NewSiteTools(defobj,itemdb)
                      }
                    }
                    else{
                      children.map(childitem => {
                        //site template children are always pages only
                        let childtempref = newtempref.collection('Pages').doc(childitem.TemplateObj.Name.split(' ').join('_').split('-').join('_'))
                        let childstoreobj = {...childitem.TemplateObj,Templateid: childitem.id}
                        childtempref.set(childstoreobj).then(newchild => {
                          childcounter++
                          if(totalchildren === childcounter){
                            templatecounter++
                            if(templatecounter === totaltemplates){
                              this.NewSiteTools(defobj,itemdb)
                            }
                          }
                        })
                      })
                    }
                    
                  })
                }
                else{
                  //I guess this is Libraries and Databases right? Not happening here correct?
                }
              })
        },
        NewSiteTools(defobj,itemdb){
          let vm = this
          vm.NewSiteAutoNumbers(defobj,itemdb).then(autores => {
          vm.$store.commit('setCustomProcessingDialogText','Adding Default Tables')
           vm.InstallDefaultEntities(itemdb.collection('Databases').doc(defobj.id)).then(entres => {
              if(entres){
                vm.$store.commit('setCustomProcessingDialogText','Installing Template Plugins')
                let plugins = this.RAPlugins.filter(plug => {
                  return defobj.PluginDataBase && defobj.PluginDataBase[plug.id] && defobj.PluginDataBase[plug.id].Active && plug.Module_Category.Name !== 'Module'
                }).map(plug => {
                  let plugobj = defobj.PluginDataBase[plug.id]
                  return plugobj
                }).concat(this.RAPlugins.filter(plug => {
                  return plug.Module_Category.Name === 'Module'
                }).map(plug => {
                  let plugobj = {
                    id: plug.id,
                    Active: true,
                    Module_Category: plug.Module_Category,
                    Name: plug.Name
                  }
                  return plugobj
                }))
                let totalplugins = plugins.length
                let plugcounter = 0
                plugins.map(plug => {
                  itemdb.collection('systems').doc(defobj.id).collection('plugins').doc(plug.id).set(plug).then(newplug => {
                    plugcounter++
                    if(plugcounter === totalplugins){
                      vm.$store.commit('setCustomProcessingDialogText','Structuring Security')
                      vm.NewSiteUserTools(defobj)
                    }
                  })
                })
                
              }
           })
          })
          
        },
        NewSiteAutoNumbers(defobj,itemdb){
            let vm = this
            return new Promise(function(resolve, reject) {
              let autonumbers = ['StoreOrderEntityDefault','StoreSupplierEntityDefault','User_Companies_Company_ID',
              'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number',
              'GoodsReceivingVouchers_GRV_Number','PackingLists_Number',
              'Payment_Invoices_Invoice_Number','PickingSlips_Number','PurchaseRequisitions_Number',
              'Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number',
              'Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
              let total = autonumbers.length
              let counter = 0
              autonumbers.map(auto => {
                let path = db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('AutoNumbers').doc(auto)
                let clientdb = itemdb.collection('SystemConfig').doc(defobj.id).collection('AutoNumbers').doc(auto)
                path.get().then(snapshot => {
                  let docdata = snapshot.data()
                  if(docdata){
                    docdata.LastNumbers = []
                    docdata.Last_Number = 0
                    docdata.Last_Value = 0
                   clientdb.set(docdata).then(newdoc => {
                    counter++
                    if(counter === total){
                      resolve('completed autonumbers')
                    }
                  }) 
                  }
                  else{
                    counter++
                    if(counter === total){
                      resolve('completed autonumbers')
                    }                    
                  }
                  
                })
              })
            })
        },
        NewSiteUserTools(temp){
          let configdb = db.collection('SystemConfig').doc(temp.id)
          let socialdb = db.collection('SocialNetworks').doc(temp.id)
          let buref = configdb.collection('businessunits')
          let HeadofficeObj = {
            Name: 'Head Office',
            Description: 'The default, main Business Unit',
            NameQuery: [
              'h',
              'he',
              'hea',
              'head',
              'heado',
              'headof',
              'headoff',
              'headoffi',
              'headoffic',
              'headoffice'
            ],
            childarrayDBRules: {
              Head_Office: true
            },
            childarrayQuery: ['Head_Office'],
            Top: true
          }
          let GuestObj = {
            Name: 'Guest',
            Description: 'A Business Unit completely unrelated to others meant to give basic Signed In Permissions to any Guest Users',
            NameQuery: [
              'g',
              'gu',
              'gue',
              'gues',
              'guest',
            ],
            childarrayDBRules: {
              Guest: true
            },
            childarrayQuery: ['Guest'],
            IsGuestBU: true,
            FinalStructure: true
          }
          buref.doc('Guest').set(GuestObj)
          buref.doc('Head_Office').set(HeadofficeObj)
        let userref = configdb.collection('users')  
        let userroleref = configdb.collection('userroles')
        let userprops = ['id','Name','Surname','Full_Name','Email','Mobile_Number','Profile_Photo',
        'Gender','Title','AboutMe']
        let defuser = {
          Business_Unit: {Name: HeadofficeObj.Name,id: 'Head_Office'},
          Business_Unitid: 'Head_Office',         
          Created_On: new Date(),
          Subordinatearrayquery: [temp.Ownerid],
          SubordinateArrayDBrules: {[temp.Owner.id]: true},
        }
        userprops.map(prp => {
          if(typeof this.userLoggedIn[prp] !== 'undefined'){
            defuser[prp] = this.userLoggedIn[prp]
          }
        })
        let userroleobj = {
          ...defuser,
          IsFeaturedMember: false,
          IsSiteCreator: false,
          IsWebDeveloper: false,
          MonetizationOfficer: false,
          IsEagleViewer: false,
          BusinessProfileAdmin: false,
          SiteContentAdmin: false,
          IsGuestUser: false,
          IsSystemAdmin: false,
          IsTeamActivityManager: false,
          CanInviteUsers: false,
          InviteGroups: [],
          CanExport: false,
          CanImport: false,
        }
        
        let user = {}
        userprops.map(prp => {
          if(typeof this.userLoggedIn[prp] !== 'undefined'){
            user[prp] = this.userLoggedIn[prp]
          }
        })
        let ownermatch = this.UsersStore.find(obj => obj.id === temp.Ownerid)
          if(!ownermatch){
            ownermatch = user
          }
        let owneruser = {
          id: temp.Ownerid,
          ...temp.Owner,
          ...userroleobj,
          rolesarrayDBRules: {SystemAdmin: true},
          rolesarrayQuery: ['System Admin'],
          IsSystemAdmin: true,        
          Name: ownermatch.Name,
          Surname: ownermatch.Surname,
          Full_Name: ownermatch.Full_Name,
          Email: ownermatch.Email,
          Title: ownermatch.Title,
          Apps: []
          }
          if(ownermatch.Profile_Photo){
                owneruser.Profile_Photo = ownermatch.Profile_Photo
              }
          
          userref.doc(defuser.id).set(defuser).then(ownerusedoc => {
            configdb.collection('featuredmembers').doc(defuser.id).set({
                    Disabled: false,
                  }).then(clientfeatmemdoc => {
                    socialdb.collection('groupmembers').doc(defuser.id).set({
                      Disabled: false,
                    }).then(clientgroupmemdoc => {
                  userroleref.doc(owneruser.id).set(owneruser).then(ownerusedoc => {
                    if(temp.Client && temp.Clientid !== temp.Ownerid){            
                  let clientmatch = this.UsersStore.find(obj => obj.id === temp.Clientid)
                  let clientdefuser = {              
                        Business_Unit: {Name: HeadofficeObj.Name,id: 'Head_Office'},
                        Business_Unitid: 'Head_Office',
                        Subordinatearrayquery: [temp.Clientid],
                        SubordinateArrayDBrules: {[temp.Clientid]: true},
                    }
                    userprops.map(prp => {
                        if(typeof clientmatch[prp] !== 'undefined'){
                          clientdefuser[prp] = clientmatch[prp]
                        }
                      })
                    let clientuser = {
                      ...clientdefuser,
                      ...temp.Client,
                      ...userroleobj,
                      rolesarrayDBRules: {},
                      rolesarrayQuery: [],
                      IsEagleViewer: true,                 
                      Name: clientmatch.Name,
                      Surname: clientmatch.Surname,
                      Full_Name: clientmatch.Full_Name,
                      Email: clientmatch.Email,
                      Title: clientmatch.Title,
                      Apps: []
                      }
                      if(clientmatch.Profile_Photo){
                        clientuser.Profile_Photo = clientmatch.Profile_Photo
                      }
                      userref.doc(clientdefuser.id).set(clientdefuser).then(ownerusedoc => {
                        configdb.collection('featuredmembers').doc(clientdefuser.id).set({
                          Disabled: false,
                        }).then(clientfeatmemdoc => {
                          socialdb.collection('groupmembers').doc(clientdefuser.id).set({
                            Disabled: false,
                          }).then(clientgroupmemdoc => {
                            userroleref.doc(clientuser.id).set(clientuser).then(clientusdoc => {
                              this.$store.commit('setCustomProcessingDialogText','')
                              this.$store.commit('setCustomProcessingDialog',false)
                              this.$store.commit('setActiveSuiteid',temp.id)
                              this.CancelSaveSuiteTemplate()
                            })
                          })
                        })                  
                      })
                    }
                    else{
                        this.$store.commit('setCustomProcessingDialogText','')
                        this.$store.commit('setCustomProcessingDialog',false)
                        this.$store.commit('setActiveSuiteid',temp.id)
                        this.CancelSaveSuiteTemplate()
                    }
                  })
                })
              })
          })
        },
        SaveNewLibrary(){
        this.NewLibrary.Collection = this.NewLibrary.DisplayName.split(' ').join('').toLowerCase()
        this.NewLibrary.SinglePath = '/'+this.NewLibrary.SingleName.split(' ').join('').toLowerCase()
        let col = ''
        if(this.NewLibrary.Type === 'Photo Library'){
          col = 'PhotoLibraries'
        }
        else{
          col = 'DocumentLibraries'
        }
          db.collection('Libraries').doc(this.System.id).collection(col).doc(this.NewLibrary.DisplayName.split(' ').join('_')).set(this.NewLibrary).then(doc => {
            this.DeactivateNewLibraryDialog()
          })
      },
      DeactivateNewLibraryDialog(){
        this.NewLibraryDialog = false
        this.NewLibrary = Object.assign({},this.DefaultNewLibrary)
      },
      ToggleNewDocumentLibraryDialog(type){
        this.NewLibrary.Type = type
        this.NewLibraryDialog = !this.NewLibraryDialog
      },
      ToggleNewPhotoLibraryDialog(type){
        this.NewLibrary.Type = type
        this.NewLibraryDialog = !this.NewLibraryDialog
      },
        ToggleNewPhotoLibraryDialog(type){
          //I dont THINK we need it?
          this.$emit('ToggleNewPhotoLibraryDialog',type)
        },
        ResizeWindow(){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
        
      },
      
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    ConfirmDownloadtemplatefromRA(temp){
        confirm('This will add the RA template to your Suite, do you want to continue?') && this.DownloadtemplatefromRA(temp)
      },
      DownloadtemplatefromRA(temp){
        let MasterTemplate = Object.assign({},temp)
        let path = db.collection('marketplacetemplates')
        let parentref = path.doc()
        let parentid = parentref.id
        let ClientAppURL = this.System.ClientAppURL
        let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
        let date = new Date()
        let deleteprops = ['id','ClientSuiteTemplateid','ClientOwner','ClientOwnerid','Original_Children','Original_Parentid','RATemplateid']
        deleteprops.map(prp => {
          delete MasterTemplate[prp]
        })
        MasterTemplate.ClientAppURL = ClientAppURL
        MasterTemplate.Systemid = process.env.VUE_APP_RA_SYSTEM_ID
        MasterTemplate.Owner = user          
        MasterTemplate.Ownerid = user.id
        MasterTemplate.Created_By = user     
        MasterTemplate.Created_Byid = user.id
        MasterTemplate.Modified_By = user         
        MasterTemplate.Modified_Byid = user.id
        MasterTemplate.Created_On = date
        MasterTemplate.Modified_On = date
        //could we not just do this.MarketplaceTemplate = temp and then StoreandRoutetoMarketplaceTemplate?
        //sure only need to assign SelectedMarketplaceTemplateComponents
        //but that's tricky...
        //need conversions of Ownerid etc...
        //look man, just need formatting for SelectedMarketplaceTemplateComponents, which comes from "Components" so refer FeaturedMemberBuilder
        //remember this is a once off...don't stuff it up! future code will look different because then we wil actually keep RaTemplateid and such things...
            
            if(this.TemplateisSite(MasterTemplate)){
              let children = this.RAMarketplaceTemplates.filter(temp => {
                    return MasterTemplate.Children.includes(temp.id)
                }).map(child => {
                    let childref = path.doc()
                    let childobj = Object.assign({},child)
                      deleteprops.map(prp => {
                      delete childobj[prp]
                    })
                    childobj.Owner = user          
                    childobj.Ownerid = user.id
                    childobj.Created_By = user     
                    childobj.Created_Byid = user.id
                    childobj.Modified_By = user         
                    childobj.Modified_Byid = user.id
                    childobj.Created_On = date
                    childobj.Modified_On = date
                    childobj.id = childref.id
                    childobj.Parentid = parentid
                   
                    return childobj
                })
                MasterTemplate.Children = children.map(child => {
                  return child.id
                })
                let total = children.length
                let running = 0
                children.map(child => {
                path.doc(child.id).set(child).then(childnew => {
                        running++
                        if(running === total){
                            path.doc(parentid).set(MasterTemplate).then(parentnew => {
                                  alert('sure done for now')
                                })               
                        }
                    })
                })
            }
            else if(this.TemplateisPage(MasterTemplate)){
              path.doc(parentid).set(MasterTemplate).then(parentnew => {
                        alert('sure done for now')
                    })
                
            }
      },
      GetMarketplaceTemplates(db,user,mytemparrayname,othertemparrayname){
      let vm = this
      //console.log('this.GetMarketplaceTemplates')
      let mytemppayload = {                
            query: db.collection('marketplacetemplates').where('Ownerid','==',user.id),
            subcolquery: db.collection('marketplacetemplates'),
            arrayname: mytemparrayname,
            SubCollections: [
              // {
              //   Subcolpath: 'Components',
              //   SubcolName: 'Components'
              // },
              {
                Subcolpath: 'Assets',
                SubcolName: 'Assets'
              }
            ]              
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',mytemppayload).then(function(result) {    
             let othertemplpayload = {                
            query: db.collection('marketplacetemplates').where('PublishStatus','==','Published').where('Ownerid','!=',user.id),
            subcolquery: db.collection('marketplacetemplates'),
            arrayname: othertemparrayname,
            SubCollections: [
              // {
              //   Subcolpath: 'Components',
              //   SubcolName: 'Components'
              // },
              {
                Subcolpath: 'Assets',
                SubcolName: 'Assets'
              }
            ]              
            }
            vm.$store.dispatch('GetArrayandMultipleSubcols',othertemplpayload).then(function(result) {    
            })  
            })
    },
      ActivateRATemplates(){
        this.RATemplateView = true
        if(this.RAMarketplaceTemplates.length === 0){
         this.GetMarketplaceTemplates(this.RADB,this.RAUserObj,'RAMyMarketplaceTemplates','RAOtherMarketplaceTemplates') 
        }        
      },
      InitiateFeaturedMember(){
        
        //this.ToggleNewPageDialog('Public')
        this.ActivateViewMarketplaceTemplates('Site')
        //console.log('this.OtherMarketplaceTemplates',this.OtherMarketplaceTemplates)
        //technically though could also be this.ActivateViewMarketplaceTemplates('Add Page') Ffffff
      },
      nextSiteTemplateChildIndex () {
        this.SiteTemplateChildIndex = this.SiteTemplateChildIndex + 1 === this.ComputedTemplateChildren.length
          ? 0
          : this.SiteTemplateChildIndex + 1
      },
      prevSiteTemplateChildIndex () {
        this.SiteTemplateChildIndex = this.SiteTemplateChildIndex - 1 < 0
          ? this.ComputedTemplateChildren.length - 1
          : this.SiteTemplateChildIndex - 1
      },

      ActivateViewMarketplaceTemplates(use){
        //console.log(this.AppMonetizeBU,this.$store.state)
        //console.log('this.ActiveGroup',this.ActiveGroup)
        console.log(this.ActiveBillingSystem,this.$store.state)
        if(this.userLoggedIn && this.MarketplaceTemplates.length === 0 && !this.userLoggedIn.IsWebDeveloper && !this.userIsAdmin && !this.userLoggedIn.IsFeaturedMember){
          //we assuming the client does not have tempaltes, is not tempalte creator or feat member etc but okay
           let othertemplpayload = {                
            query: db.collection('marketplacetemplates').where('PublishStatus','==','Published').where('Ownerid','!=',this.userLoggedIn.id),
            subcolquery: db.collection('marketplacetemplates'),
            arrayname: 'OtherMarketplaceTemplates',
            SubCollections: [
              {
                Subcolpath: 'Directories',
                SubcolName: 'Directories'
              },
               {
                Subcolpath: 'sampleusers',
                SubcolName: 'Users'
              },
              {
                Subcolpath: 'CategoryFields',
                SubcolName: 'CategoryFields'
              },
              {
                Subcolpath: 'Assets',
                SubcolName: 'Assets'
              }
            ]              
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',othertemplpayload).then(function(result) {  
              console.log('this.MarketplaceTemplates',this.MarketplaceTemplates)  
            })
        }
        this.ViewMarketplaceTemplates = true
        this.MarketTemplateUse = use
      },
      DeactivateViewMarketplaceTemplates(){
        this.ViewMarketplaceTemplates = false
        //console.log('this.Templateselected',this.Templateselected,this.ActiveMarketTemplateChild,this.ActiveTemplate)
        //this.$store.commit('setActiveMarketTemplateChild',this.ActiveTemplate)
        //look we have it in store, but like may wanna keep it until someone "purchase" you know?
        //so if Templateselected then well, you know?
        // yes!!!
        
        if(this.Templateselected){
          console.log(this.UserMarketAccount,this.UserSiteAccount,this.UserBillingAccounts)
          let vm = this
          //welll we could stop Right here, generate invoice and shit
          //for now create as paid but yeah that's it.
          // if(this.ActiveTemplate.Price === 'Free'){
          // //this.PrepareMarketplaceTemplateInstallation()
          // //console.log('this.UserMarketAccount',this.UserMarketAccount,'this.UserBillingAccounts',this.UserBillingAccounts)
          // this.PrepareTemplatePurchaseInvoice()
          // }
          // else{
          //   this.PrepareTemplatePurchaseInvoice()
          // }
          //okay so we install the template, but if this is for purposes of new Suite, we should simply 
          //1. if FREE, "install" as "my templates" so array returns, and return as mathcing.
          //2. if "Paid", prepare invoice, when done see step 1.
          //but this required dissecting requirements and potentially updates to MyMarketplaceTemplatesFiltered(mod.id)
          //so theoretically if we create a sales invoice, even "FREE" or "0 value", so long invoice type is "Market Content" all good. 
          //that should be fine. it automatically creates 'contentpurchases' entry
          //that being said I think it definitely then means, we should ALWAYS create invoice, even if FREE
          //the only exception then being now, when we process this for a new suite...
          //okay yeah that's the issue. What?
          //no tempalte purchases to MA. not Site Account. thus all safe all good all well. 
          //we just need to "close dial" and "select" as opposed to try "install"
          //NOTE - ALL OF THIS //Just assuming online payment gonna be running
          if(!this.UserMarketAccount && this.AppMonetizeBU){
           vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
           vm.$store.commit('setCustomProcessingDialog',true)
           const functions = firebase.functions();
           const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
           let payload = {
             Data: vm.userLoggedIn,
             AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
           }
           CreateMarketAccount(payload).then(result => {
             console.log(result)
             vm.$store.commit('setCustomProcessingDialogText','Done Creating')
             vm.PrepareTemplatePurchaseInvoice()
           })
         }
         else{
          this.PrepareTemplatePurchaseInvoice()
         }
        }
        else{
          this.NewSuiteTemplateModule = ''
        }
        this.$store.commit('setTemplateSecondaryCatFilter','')
        this.$store.commit('setTemplateInstallationMethod','')
      },
      PrepareTemplatePurchaseInvoice(){
        let vm = this
        let invtype = this.$store.state.InvoiceTypes.find(obj => obj.Name === 'Market Content')
           vm.$store.commit('setCustomProcessingDialog',true)
            let invpayload = {
            Docid: 'DispatchInstructions_Number',
            Data: {Invoice_Type: invtype},
            siteid: process.env.VUE_APP_RA_PROVIDER_ID
            }
            vm.$store.commit('setCustomProcessingDialogText','Creating Invoice Number')
            const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            AssignAutoNumber(invpayload).then(result => {
            
            vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
            //console.log(subpack,vm.AppMonetizeBU)
            //okay simply put, if this.MarketDialogType, then address details somehwat change
            let billingaccount = this.IsPrimarySite ? this.UserMarketAccount : this.UserSiteAccount
            console.log(this.IsPrimarySite,billingaccount)
            let contact = ''
            if(this.ActiveTemplate.As_Platform){
              contact = ''
            }
            else{
              contact = `(On behalf of `+this.ActiveTemplate.Owner.Full_Name+` to:)`
            }
            let PDFHeaderObj = {
                Company_Name: vm.ActiveBillingSystem.Company_Name,
                Company_Address_Prop: 'PO_Bill_To_Address_',
                Billing_Address_Prop: 'SO_Delivery_Address_',
                Primary_Field_Name: 'Invoice_Number',
                Date: new Date(),        
                Customer_ID: billingaccount.Customer_ID,
                Business_Unit: vm.AppMonetizeBU,
                Business_Unitid: vm.AppMonetizeBU.id,
                Reference_Number: billingaccount.id,
                BillingAccount: {Client_Reference: billingaccount.Client_Reference,Customer_ID: billingaccount.Customer_ID,id: billingaccount.id},
                BillingAccountid: billingaccount.id,
                Client: {
                    id: vm.userLoggedIn.id,
                    Full_Name: vm.userLoggedIn.Full_Name,
                    Name: vm.userLoggedIn.Name,
                    Surname: vm.userLoggedIn.Surname
                },
                Customer_Company: billingaccount.Account_Name,
                Customer_Contact: contact,
                Clientid: vm.userLoggedIn.id,
                Recordid: result.data,
                Delivery: false,
                Shipping_Method: {ID: 1000001,Name: 'FOB'},
                Due_Date: new Date(),
                Royalty_Percentage: this.ActiveBillingSystem.Base_App_Royalties
            }
            PDFHeaderObj.LookupObj = {
              id: this.ActiveTemplate.id,
              identifier: 'Name',
              BillingAccount: this.ActiveTemplate.BillingAccount,
              BillingAccountid: this.ActiveTemplate.BillingAccountid,
              Name: this.ActiveTemplate.Name,
              Owner: this.ActiveTemplate.Owner,
              Ownerid: this.ActiveTemplate.Ownerid ,
              Type: 'Marketplace Template',
              //NOT suite right?
              Collection: 'marketplacetempaltes'

            }
            PDFHeaderObj.Customer_Company = this.userLoggedIn.Full_Name
            vm.Company_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name.split('Company_Address_').join(PDFHeaderObj.Company_Address_Prop)
                let brdname = brd.Name
                if(vm.ActiveBillingSystem[brdname]){
                    PDFHeaderObj[brdname] = vm.ActiveBillingSystem[brdname]
                }
                })
                vm.Billing_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name
                let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
                if(billingaccount[brdname]){
                    PDFHeaderObj[newname] = billingaccount[brdname]
                }
                })
            
            let PDFDocLineItems = []
            let descr = `<p>Purchase of the Marketplace Template "`+this.ActiveTemplate.Name+`" from `+this.ActiveTemplate.BillingAccountid+` </p>`
            descr = descr+`<br>`
            let price = this.ActiveTemplate.Price === 'Free' ? 0 : this.ActiveTemplate.Price
            let item = {
                Nr: 1,
                Name: 'Marketplace Template',
                Description: descr,
                Price: price,
                Qty: 1,
                LookupObj: {
                  id: this.ActiveTemplate.id,
                  identifier: 'Name',
                  BillingAccount: this.ActiveTemplate.BillingAccount,
                  BillingAccountid: this.ActiveTemplate.BillingAccountid,
                  Name: this.ActiveTemplate.Name,                  
                  Owner: this.ActiveGroup.Owner,
                  Ownerid: this.ActiveGroup.Ownerid 
                }
            }
            
            PDFDocLineItems.push(item)
            let taxtotal = 0
            let taxperc = 0
            let total = 0
            //no tax on tempalte,s royalties and stuff
            // if(vm.AppMonetizeBU && typeof vm.AppMonetizeBU.Tax_Percentage !== 'undefined'){
            //     taxperc = vm.AppMonetizeBU.Tax_Percentage
            // }
            // if(taxperc > 0){
            //     total = subpack.Price*(taxperc/100)
            // }
            taxtotal = Number(total.toFixed(2))
            let PDFFooterObj = {
                Footer_Note: '',
                Sub_Total: price,
                Discount_Total: 0,
                Freight_Total: 0,
                Tax_Total: taxtotal,
                Tax_Percentage: taxperc,
                Grand_Total: Number(taxtotal)+Number(price),
                TotalFieldProp: 'Invoice_Total',
                TotalFieldValue: price
            }
            let note = `<p>This purchase does not transfer rights to ownership of the purchased content.</p>`
            if(!this.ActiveTemplate.As_Platform){
              note = note+`<p>`+this.ActiveBillingSystem.Company_Name+` merely faciitates the transaction, as this is a third party purchase.</p>`
            }
            PDFFooterObj.Footer_Note = note
            PDFHeaderObj.Payment_Terms = ''
            if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'COD'){
                PDFHeaderObj.Payment_Terms = 'COD'
                PDFHeaderObj.Payment_Period = 0
            }
            else if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'Credit'){          
                PDFHeaderObj.Payment_Period = billingaccount.Days_for_Payment
                PDFHeaderObj.Payment_Terms = 'Net '+billingaccount.Days_for_Payment
            }
            //console.log(PDFHeaderObj,PDFFooterObj,PDFDocLineItems)
            vm.$store.commit('setPDFFunctionName','Sales_Invoices')
            vm.$store.commit('setPDFHeaderObj',PDFHeaderObj)
            vm.$store.commit('setPDFFooterObj',PDFFooterObj)
            vm.$store.commit('setPDFDocLineItems',PDFDocLineItems)
            vm.$store.commit('EmitMarketDialogInteractMethod','PrepMarketplaceTemplateInvoice')
            vm.$store.commit('EmitMarketDialogType','Market Content')
            vm.$store.commit('setPDFDownloadOnly',false)
            //  DO NOT do this, appnot picks up value cahnge in function, otherwise funciton may be uncalled this.$store.commit('setExportingtoPDFDialog',true)
            })
      },
      ProgressNewSiteStepper(){   
        //console.log('firebase.apps',firebase.apps)
        if(this.SuiteInstallerStep === 1){
          this.SuiteInstallerStep++
        }
        else if(this.SuiteInstallerStep === 2){
          
          
          if(this.NewSite.SiteHosting === 'Offsite'){
            this.SuiteInstallerStep++
          }
          else{
          this.$store.commit('setCustomProcessingDialog',true)
          this.$store.commit('setCustomProcessingDialogText','Preparing Invoice')
            this.PrepareNewSiteInvoice()
            //after which activate the new site
            //2.2 if {{System.Name}} hosted, Owner is Owner prop on Libraries, Systemconfig, Databses, SocialNetworks, Websites ("throughout"). Admin is in fact Admin prop "throughout"       
          }
        }
        else if(this.SuiteInstallerStep === 3 && this.NewSite.SiteHosting === 'Offsite'){
          //take firebaseConfig and autocreate HQ BU the progress
           this.PrepNewSiteBackend()
        }
         else if(this.SuiteInstallerStep === 4 && this.NewSite.SiteHosting === 'Offsite'){
          //take NewSiteAdminPW and create new admin AFTER creating HQ and Guest BU
          this.ProcessClientBUandAdmin()
          // console.log(this.NewSite,this.OwnershipUsersArray)
        }
        else if(this.SuiteInstallerStep === 5 && this.NewSite.SiteHosting === 'Offsite'){
          //so here we have no active RA user, and because of that, we need to first create the user.
          //BTW we should perhaps at least check RA users, and check if a mathcing email...no fuck it. 
          //dialog work here
          //BUT - we definite need to rework this stuff to RA users, like admin here is fine using current db users id, but owner for ra "systems" entry must be RA user
        }
        else if(this.SuiteInstallerStep === 6 && this.NewSite.SiteHosting === 'Offsite'){
          //take email config and prep also yes          
            let SiteEmailConfigObj = {}
              SiteEmailConfigObj.SystemFromEmailAddress = this.SystemFromEmailAddress
              SiteEmailConfigObj.SystemFromEmailPassword = this.SystemFromEmailPassword
              SiteEmailConfigObj.SystemFromEmailPort = this.SystemFromEmailPort
              SiteEmailConfigObj.SystemFromEmailHost = this.SystemFromEmailHost
              SiteEmailConfigObj.SystemFromEmailSecure = this.SystemFromEmailSecure
              SiteEmailConfigObj.SystemNotificationsHasEmails = true
              SiteEmailConfigObj.SystemSendsEmails = true
              let vm = this
              this.$store.commit('setCustomProcessingDialog',true)
              this.$store.commit('setCustomProcessingDialogText','Initiating')
              this.InstallSuitetoRADB(SiteEmailConfigObj).then(systemobj=> {
                if(systemobj){
                  vm.$store.commit('setCustomProcessingDialogText','Installing to Client DB')
                  vm.InstallSuitetoClientDB(systemobj).then(clientdbres => {
                    if(clientdbres){
                      vm.SuiteInstallerStep++
                      vm.$store.commit('setCustomProcessingDialog',false)
                      vm.$store.commit('setCustomProcessingDialogText','')
                    }
                  })
                }
              })
              
          }
      },
      
      InstallSuitetoRADB(SiteEmailConfigObj){
        //Ownership - Admin - Account Owner, systemo bj owner
        //Ownership - Owner - Ba Owner, custodian, responsible for account
        //if new Ra USER - "Admin" is App Engineer. if "Owner" not matching "Amin" then "Client" memgorup aplies (not yet created mind you)
        //FINALLY ON USERS - IF "admin !== owner" - owner MUST be added as eagleviewer
        let vm = this
        return new Promise(function(resolve, reject) {
        
          let buobj = vm.RAUserObj.Business_Unit
          let rauser = vm.RAUserObj
          let status = vm.$store.state.DefaultStatusField
          let baseobj = {          
            Business_Unit: buobj,
            Business_Unitid: buobj.id,
            Created_On: rauser.Created_On,
            Owner: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Ownerid: rauser.id,
            Created_By: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Created_Byid: rauser.id,
            Created_On: new Date(),
            Modified_By: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Modified_Byid: rauser.id,
            Modified_On: new Date(),
            Status: status.DefaultOption,
            Status_Reason: status.DefaultLevel2Option,
          }
          let newcompany = {
            ...baseobj,
            Company_Name: vm.NewSite.CompanyName,
          }
          let newbillingaccount = {
            ...baseobj,
            Account_Type: {ID: 1000003, Name: 'Suite Ownership'},
            Account_Name: vm.NewSite.CompanyName,
            Client_Reference: vm.NewSite.CompanyName, 
            Billing_Type: {ID: 1000002, Name: 'COD'},
            "Invoicing_Address" : 'Invoicing_Address_City',

          }
          vm.$store.dispatch('CreateSearchTitle',newcompany.Company_Name).then(compsearchtitle => {
            newcompany.Company_NameQuery = compsearchtitle
            newcompany.SearchQuery = compsearchtitle
            let newcompref = vm.RADB.collection('accounts').doc()
            newcompany.id = newcompref.id
            vm.$store.dispatch('CreateSearchTitle',newbillingaccount.Client_Reference).then(basearchtitle => {
              const functions = firebase.functions(vm.$store.state.RAApp);
              const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
              console.log('billingaccount',newbillingaccount)
              let payload = {
                Docid: 'BillingAccounts_Customer_ID',
                Data: newbillingaccount,
                siteid: process.env.VUE_APP_RA_PROVIDER_ID
              }
              vm.$store.commit('setCustomProcessingDialogText','Creating Account')
              vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')            
                AssignAutoNumber(payload).then(result => {
                  newbillingaccount.Customer_ID = result.data
                newcompany.SearchQuery = basearchtitle        
                newbillingaccount.id = newbillingaccount.Customer_ID        
                let systemobj = {
                  ...baseobj,
                  Company: {Company_Name: newcompany.Company_Name,id: newcompany.id},
                  Companyid: {Company_Name: newcompany.Company_Name,id: newcompany.id},
                  BillingAccount: {Client_Reference: newbillingaccount.Client_Reference,id: newbillingaccount.id},
                  BillingAccountid: newbillingaccount.id,
                  ClientAppURL: vm.ClientAppURL,
                  ClientFireStore: vm.ClientFireStore,
                  firebaseConfig: vm.firebaseConfig,
                  QuickstartStep: 6,
                  id: vm.NewSite.id,
                  Name: vm.NewSite.Name
                }
                vm.$store.dispatch('CreateSearchTitle',systemobj.Name).then(syssearchtitle => {
                  systemobj.NameQuery = syssearchtitle  
                  systemobj.SearchQuery = syssearchtitle    
                  let sysref = vm.RADB.collection('systems').doc(systemobj.id)
                  vm.$store.commit('setCustomProcessingDialogText','Creating System')
                  vm.RADB.collection('accounts').doc(newcompany.id).set(newcompany).then(newcompdoc => {
                  vm.$store.commit('setCustomProcessingDialogText','Register Related')
                    vm.RADB.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('billingaccounts').doc(newbillingaccount.id).set(newbillingaccount).then(newbadoc => {
                      sysref.set(systemobj).then(newsysdoc => {
                        vm.InstallDefaultEntities(vm.RADB.collection('systems').doc(systemobj.id)).then(entres => {
                          if(entres){
                            vm.InstallDefaultEntities(vm.clientDB.collection('Databases').doc(systemobj.id)).then(cliententres => {
                              if(cliententres){
                                sysref.collection('Options').doc('EmailSettings').set(SiteEmailConfigObj).then(emaildoc => {
                                  //okay left with "apps" and "plugins"   
                                  vm.$store.commit('setCustomProcessingDialogText','Registering Plugins')                              
                                  vm.WrapupAppsandPlugins().then(appsandpluginsres => {
                                    if(appsandpluginsres){
                                       resolve(systemobj)
                                    }
                                  })
                                })
                              }
                            })
                          }
                        })
                      })
                    })
                  })
                  
                    //okay we could "add accounts", we could "add ba" we could even add systems entry right? but
                    //3.4 SubCollections (entities, apps plugins)
                  //3.5 Options (email config)
                  //The only "issue" here is ///
                  //A) AUTONUMBER FOR BA, NON RA CALL
                  //B) DEFAULT ENTITIES JUST NEED TO REDEFINE
                // BTW WHEN DONE CAPTURE "OSSITEID" WHICH IS SYSTEM ID BECAUSE WE MAY STILL WANT AN ONSITE IF DIRECTORY LISTING APPLICABLE
                
                })
                //1. create "accounts" entry, also becomes "Company" Lookup on "systems" obj using "NewSite.CompanyName"
                //2. Create BA, also BA lookup to systems obj, also NEwSite.CompanyName
                //3. Create RA system obj
                //3.1. Deault (createdby blablabla Ra User)
                //3.2 Basic (Name, NameQuery, SEarchQuery, QuickstartStep 6, Status)
                //3.3 Lookkups (Owner, BA, Company, BU)
                //3.4 SubCollections (entities, apps plugins)
                //3.5 Options (email config)
              })
            })
          })
        })

      },
      WrapupAppsandPlugins(){
        let vm = this
        return new Promise(function(resolve, reject) {
          let plugins = vm.RAPlugins.filter(plug => {
            return vm.$store.state.PluginDataBase && vm.$store.state.PluginDataBase[plug.id] && vm.$store.state.PluginDataBase[plug.id].Active
            //return vm.ActiveSuiteTemplate.PluginDataBase && vm.ActiveSuiteTemplate.PluginDataBase[plug.id] && vm.ActiveSuiteTemplate.PluginDataBase[plug.id].Active
          }).map(plug => {
            //let plugobj = Object.assign({},vm.ActiveSuiteTemplate.PluginDataBase[plug.id])
            let plugobj = Object.assign({},vm.$store.state.PluginDataBase[plug.id])
            return plugobj
          })
          let apps = []
          let totalplugins = plugins.length
          let totalapps = apps.length
          console.log(plugins,apps,totalplugins,totalapps)
          if(totalapps === 0){
            plugins.map((plug,plugind) => {
              vm.InstallPlugin(plug).then(plugres => {
                if(plugres && plugind-1+2 === totalplugins){
                  resolve('done plugins and apps')
                }
              })
            })
          }
          else {
            apps.map((app,appind) => {
              vm.InstallApp(app).then(appres => {
                if(appres && appind-1+2 === totalapps){
                  plugins.map((plug,plugind) => {
                    vm.InstallPlugin(plug).then(plugres => {
                      if(plugres && plugind-1+2 === totalplugins){
                        resolve('done plugins and apps')
                      }
                    })
                  })
                }
              })
            })
          }
          
        })
      },
      InstallApp(app){
        let vm = this
        return new Promise(function(resolve, reject) {
          let appobj = Object.assign({},app)
          vm.RADB.collection('systems').doc(vm.NewSite.id).collection('apps').doc(app.id).set(appobj).then(newappdoc => {            
            //Technically you need to realize, this could actually include an entity or even a couple
            //in this instance it owuld not be templates, it would be actual configured entities. but okay leave for now
            resolve('completed InstallApp')
          })
        })
      },
      InstallPlugin(plug){
        let vm = this
        return new Promise(function(resolve, reject) {
          let plugobj = Object.assign({},plug)
          vm.RADB.collection('systems').doc(vm.NewSite.id).collection('plugins').doc(plug.id).set(plugobj).then(newplugdoc => {
            resolve('completed InstallPlugin')
          })
        })
      },
      InstallDefaultEntities(TableCollectionRef){
        let vm = this
        return new Promise(function(resolve, reject) {
          //THE BIG ISSUE HERE IS WE DO NOT HAVE THESE ENTITIES. BUT E HAVE THEM ON ANY SYSTEM. SO...SHOULD BE OKAY?
        let fieldscount = vm.DefaultSystemEntities.map(ent => {
            return ent.AllFields.length
          }).reduce((a, b) => a + b, 0)
          let fieldcounter = 0
          vm.DefaultSystemEntities.map(ent => {
            let entobj = Object.assign({},ent)
            entobj.id = entobj.DisplayName.split(' ').join('_')
            delete entobj.AllFields
            TableCollectionRef.collection('entities').doc(entobj.id).set(entobj).then(newnetobj => {
              ent.AllFields.map(field => {
                TableCollectionRef.collection('entities').doc(entobj.id).collection('AllFields').doc(field.id).set(field).then(newfieldoc => {
                  fieldcounter++
                  if(fieldcounter === fieldscount){
                      resolve('Completed Entities')
                  }
                })
              })
            })

          })
        })
      },
      InstallSuitetoClientDB(systemobj){
        
        let vm = this
        return new Promise(function(resolve, reject) {
        //1.ConfigMaster, "ReplicateClientMaster" refers
        //okay think clearly here. DO WE EVER USE CONFIGMASTER? Let's keep it for now but yeah I think we only need it in RA
        //2. SystemConfig
        //2.1 AppFonts
        //2.2 STD Entities (done via RAsys op install)
        //2.3. Special (if applicable) - LiaisonTopics/ SubscriptionPackages
        //3.NEW
        //3.1 Websites single entry from "Website"
        //3.2 SocialNetworks single entry from "SocialNetwork"
        //3.3 Libraries - "Assets" provide PhotoLibraries "System_Assets"/"Assets", then "per library"
        //3.4 Databases - "per each" TBD 
        //potentially remains in our best interest to keep "entities" creation to remain along with RA DB install because same shit
        //So...
        vm.$store.commit('setCustomProcessingDialog',true)
        vm.$store.commit('setCustomProcessingDialogText','Installing Fonts')
        vm.ReplicateFonts().then(fontres => {
          if(fontres){
            //IF THERE WERE App entities, OR SPECIAL WE COULD HANDLE IN RASYS OP INSTALL, AS IT NEED BE THERE ANYWAY RIGHT?
            //So now only remaining is module installs
            //ah so close!!! WTF!!!
            vm.$store.commit('setCustomProcessingDialogText','Installing AutoNumbers')
            vm.CopyAutoNumbers().then(autnrres => {
              if(autnrres){
            vm.$store.commit('setCustomProcessingDialogText','Installing PDF Templates')
                 vm.CopyPDFTemplates().then(pdfres => {
                  if(pdfres){
                  vm.$store.commit('setCustomProcessingDialogText','Installing System Assets')
                    vm.CopyPhotoLib().then(photlibres => {
                      if(photlibres){
                  vm.$store.commit('setCustomProcessingDialogText','Installing Accounting Ledgers')
                        vm.CopyAccountingLedger().then(acctledgres => {
                          if(acctledgres){
                          vm.$store.commit('setCustomProcessingDialogText','Installing Website Module')
                          let website = vm.ActiveSuiteTemplate ? vm.ActiveSuiteTemplate.Website : vm.ActiveSystemWebsite
                          let socialnetwork = vm.ActiveSuiteTemplate ? vm.ActiveSuiteTemplate.SocialNetwork : vm.ActiveSystemSocialNetwork
                          
                           vm.InstallSiteModule(vm.clientDB,'Websites',website).then(websiteres => {
                            if(websiteres){
                          vm.$store.commit('setCustomProcessingDialogText','Installing Social Network Module')
                              vm.InstallSiteModule(vm.clientDB,'SocialNetworks',socialnetwork).then(socialnetres => {
                                if(socialnetres){
                          vm.$store.commit('setCustomProcessingDialogText','Installing Library Module')
                                  vm.InstallSiteLibrary().then(sitelibres => {
                                    if(sitelibres){
                          vm.$store.commit('setCustomProcessingDialogText','Installing Database Module')
                                      vm.InstallSiteDatabase().then(sitedbres => {
                                        if(sitedbres){
                                       vm.clientDB.collection('SystemConfig').doc(vm.NewSite.id).set(systemobj).then(newsysdoc => {
                                         vm.$store.commit('setCustomProcessingDialogText','Updating Template')
                                         let currentid = vm.ActiveSuiteTemplate ? vm.ActiveSuiteTemplate.id : vm.$store.state.ActiveSuiteid
                                          db.collection('SystemConfig').doc(currentid).update({
                                            Offsiteid: vm.NewSite.id
                                          }).then(upddoc => {
                                            //vm.CancelSuiteInstallerDialog()
                                            resolve('done InstallSuitetoclientDB')
                                          })
                                       })
                                        }
                                      })
                                    }
                                  })
                                }
                              })
                            }
                          
                          })
                          }
                        })
                      }
                    })
                  }
                })
              }
             
            })
          }
        })
        })
        
      },
      InstallSiteModule(refdb,modref,moditem){
        let vm = this        
        return new Promise(function(resolve, reject) {
          let modobj = {}
          let clientref = refdb.collection(modref).doc(vm.NewSite.id)
          if(moditem){
            modobj = Object.assign(moditem)
            modobj.Owner = vm.NewSite.Owner
            modobj.Ownerid = vm.NewSite.Ownerid
            modobj.Admin = vm.NewSite.Admin
            modobj.Adminid = vm.NewSite.Adminid
            let currentref = db.collection(modref).doc(vm.$store.state.ActiveSuiteid)
            clientref.set(modobj).then(newwebdoc => {
              currentref.collection('Pages').get().then(res => {
                const changes = res.docChanges();
                let total = res.docs.length
                let counter = 0
                if(total === 0){
                  resolve('completed '+modref)
                }
                else{
                  changes.forEach(change => {
                      if (change.type === 'added') {
                        let itemobj = {
                          ...change.doc.data(),
                          id: change.doc.id
                        }
                        console.log(itemobj)
                        clientref.collection('Pages').doc(itemobj.id).set(itemobj).then(newdoc => {
                          counter++
                          if(counter === total){
                            resolve('completed '+modref)
                          }
                        })
                      }
                    })
                }
                    
              })
            })
          }
          else{
            modobj.Active = false
            clientref.set(modobj).then(newwebdoc => {
              resolve('completed Website')
            })
          }
        })
      },
      InstallSiteLibrary(){
        // ActiveSystemLibrary: '',
        // ActiveSystemDatabase: '',
        let vm = this        
        return new Promise(function(resolve, reject) {
          //yeah for now, we only install systemassets yes but, did we not do that already?, yep for now yes so straight return
          resolve('completed InstallSiteLibrary')
        })
      },
      InstallSiteDatabase(){
        // ActiveSystemLibrary: '',
        // ActiveSystemDatabase: '',
        let vm = this        
        return new Promise(function(resolve, reject) {
          //until we figure this shit out yeah straight return
          resolve('completed InstallSiteDatabase')
        })
      },
      CopyPhotoLib(){
        let vm = this        
        return new Promise(function(resolve, reject) {
          let photolib = Object.assign({},vm.SystemPhotoLibraries.find(obj => obj.id === 'System_Assets'))
          if(vm.ActiveSuiteTemplate){            
          photolib.CustomTags = []
          photolib.CustomTagsInteger = 1000000
          }
          else{
            //installing from ActiveSuiteid so truly nothing, nothing, nothing it remains as is it is preconfigured
          }
          let clientref = vm.clientDB.collection('Libraries').doc(vm.NewSite.id).collection('PhotoLibraries').doc(photolib.id)
          clientref.set(photolib).then(addedsysassetdoc => {
            let assets = vm.ActiveSuiteTemplate ? vm.ActiveSuiteTemplate.Assets : []
            let total = assets.length
            let counter = 0
            if(total === 0){
              if(vm.ActiveSuiteTemplate){
                resolve('completed CopyPhotoLib')
              }
              else{
                //okay we installing from activesuiteid etc so
                vm.ReplicateAssets(photolib).then(newastdoc => {
                  resolve('completed CopyPhotoLib')
                })
              }            
            }
            else{
              assets.map(ast => {
                clientref.collection('Assets').doc(ast.id).set(ast).then(newastdoc => {
                  counter++
                  if(counter === total){
                    resolve('completed CopyPhotoLib')
                  }
                })
              })
            }
          })
        })
    },
      CopyAccountingLedger(){
        let vm = this        
        return new Promise(function(resolve, reject) {
          let collection = ['General']
          let total = collection.length
          let counter = 0
                collection.map(col => {
                    let docpath = vm.RADB.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('Ledgers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        vm.clientDB.collection('Databases').doc(vm.NewSite.id).collection('Ledgers').doc(docid).set(docdata).then(newledgdoc => {
                          counter++
                          if(counter === total){
                            resolve('completed CopyAccountingLedger')
                          }
                        })
                    })
                })
        })
        },
      CopyPDFTemplates(){
        let vm = this        
        return new Promise(function(resolve, reject) {
             let pdfcollection = ['Purchase_Orders','Sales_Invoices','Sales_Quotes']
             let total = pdfcollection.length
             let counter = 0
                pdfcollection.map(col => {
                    let docpath = vm.RADB.collection('SystemConfig').doc('ConfigMaster').collection('pdfexportfunctions').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        vm.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('pdfexportfunctions').doc(docid).set(docdata)
                         vm.clientDB.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc(docid).set(docdata).then(newdoc => {
                              counter++
                              if(counter === total){
                                resolve('completed CopyPDFTemplates')
                              }
                        })
                    })
                })
        })
        },
    CopyAutoNumbers(){
        let vm = this        
        return new Promise(function(resolve, reject) {
             let autonumberscollection = ['StoreOrderEntityDefault','StoreSupplierEntityDefault','User_Companies_Company_ID',
                 'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number','GoodsReceivingVouchers_GRV_Number','PackingLists_Number','Payment_Invoices_Invoice_Number',
                 'PickingSlips_Number','PurchaseRequisitions_Number','Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number','Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
               let total = autonumberscollection.length
               let counter = 0
               autonumberscollection.map(col => {
                    let docpath = vm.RADB.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        docdata.LastNumbers = []
                        docdata.Last_Number = 0
                        docdata.Last_Value = 0
                        let docid = col
                        vm.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(docid).set(docdata).then(configdoc => {
                        if(!docdata.IsEntityDefault){
                          vm.clientDB.collection('SystemConfig').doc(vm.NewSite.id).collection('AutoNumbers').doc(docid).set(docdata).then(newdoc => {
                            counter++
                            if(counter === total){
                              resolve('completed CopyAutoNumbers')
                              }
                          })
                        }
                        else{
                          counter++
                          if(counter === total){
                            resolve('completed CopyAutoNumbers')
                            }
                        }
                        })
                    })
                })
        })
        },
      ReplicateAssets(photolib){
        let vm = this     
        return new Promise(function(resolve, reject) {
        db.collection('Libraries').doc(vm.$store.state.ActiveSuiteid).collection('PhotoLibraries').doc(photolib.id).collection('Assets').onSnapshot(res => {
          const changes = res.docChanges();
          let totalassets = res.docs.length
          let assetcounter = 0
              changes.forEach(change => {
                if (change.type === 'added') {
                  let assetdoc = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                  //console.log(assetdoc)
                  vm.clientDB.collection('Libraries').doc(vm.NewSite.id).collection('PhotoLibraries').doc(photolib.id).collection('Assets').doc(assetdoc.id).set(assetdoc).then(newdoc => {
                    assetcounter++
                    if(assetcounter === totalassets){
                      resolve('completed AppFonts')
                    }
                  })
                }
              })
        })
        })
      },
      ReplicateFonts(){   
        let vm = this     
        return new Promise(function(resolve, reject) {
        db.collection('SystemConfig').doc('ConfigMaster').collection('AppFonts').onSnapshot(res => {
          const changes = res.docChanges();
          let totalfonts = res.docs.length
          let fontcounter = 0
              changes.forEach(change => {
                if (change.type === 'added') {
                  let fontdoc = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                  //console.log(fontdoc)
                  vm.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AppFonts').doc(fontdoc.id).set(fontdoc).then(newdoc => {
                    fontcounter++
                    if(fontcounter === totalfonts){
                      resolve('completed AppFonts')
                    }
                  })
                }
              })
        })
        })
      },
      RegressNewSiteStepper(){
        this.SuiteInstallerStep--
      },
      CancelSuiteInstallerDialog(){
        this.SuiteInstallerDialog = false
        this.SuiteInstallerStep = 1
        this.NewSite = Object.assign({},this.DefaultNewSite)
        this.firebaseConfig = ''
        this.clientApp = {}
        this.clientDB = ''
        this.ClientFireStore = {}
        this.ClientAppURL = ''
        this.NewSiteAdminPW = ''
        this.reNewSiteAdminPW = ''
        this.SystemFromEmailHost = ''
        this.SystemFromEmailAddress = ''
        this.SystemFromEmailPassword = ''
        this.SystemFromEmailPort = ''
        this.SystemFromEmailSecure = ''
      },
      InitializeClientApp(){
        this.clientApp = firebase.apps.find(obj => obj.name === this.System.Name)
        this.clientDB = this.clientApp.firestore()
        this.NewSite.id = this.System.Offsiteid
        let website = this.ActiveSystemWebsite
        //console.log('website',website)
        this.InstallSiteModule(this.clientDB,'Websites',website).then(websiteres => {
          if(websiteres){
          }
        })
      },
      ReplicatetoClientSuite(){
        //this.SuiteInstallerDialog = true
        this.NewSite.SkipListing = false
        if(this.System.SkipListing){
          this.NewSite.SkipListing = this.System.SkipListing
        }
        this.NewSite.Owner = this.System.Owner
        if(this.NewSite.Owner){
          this.NewSite.Ownerid = this.System.Ownerid
        }
        this.NewSite.Admin = this.System.Admin
        if(this.NewSite.Admin){
          this.NewSite.Adminid = this.System.Adminid
        }   
        this.InitializeClientApp()
      },
      ActivateSuiteTemplateInstallerDialog(){        
        let obj = this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate : this.System
        this.SuiteInstallerDialog = true
        this.NewSite.SkipListing = false
        if(this.ActiveSuiteTemplate.SkipListing){
          this.NewSite.SkipListing = this.ActiveSuiteTemplate.SkipListing
        }
        this.NewSite.Owner = this.OwnershipUsersArray.find(obj => obj.id === this.ActiveSuiteTemplate.Clientid)
        if(this.NewSite.Owner){
          this.NewSite.Ownerid = this.ActiveSuiteTemplate.Clientid
        }
        this.NewSite.Admin = this.OwnershipUsersArray.find(obj => obj.id === this.ActiveSuiteTemplate.Ownerid)
        if(this.NewSite.Admin){
          this.NewSite.Adminid = this.ActiveSuiteTemplate.Ownerid
        }      
        this.NewSite.Name = this.ActiveSuiteTemplate.Name 
        this.NewSite.CompanyName = this.ActiveSuiteTemplate.Name
        console.log('this.NewSuiteTemplate',this.NewSuiteTemplate,'this.ActiveSuiteTemplate',this.ActiveSuiteTemplate,this.NewSite,this.System,obj,
        this.ActiveSystemWebsite,this.ActiveSystemSocialNetwork,this.ActiveSystemLibrary,this.ActiveSystemDatabase,this.$store.state.PluginDataBase,this.$store.state)
        //Garden Route Cleaning Service where are the assets for this active lib?
      },   
      PrepNewSiteBackend(){
         let first = this.firebaseConfig.split('const firebaseConfig = ')
            // console.log(first)
            // console.log(first[1])
            let lines = first[1].split("\n")
            let splitted = lines.map(line => {
              return line.split(':')
            })
            let fixed = splitted.map(split => {
              let obj = {}
              if(typeof split[1] !== 'undefined'){
              obj[split[0].split(' ').join('')] = split[1].split(" ").join('').split('"').join('').split(',').join('').trim()
              return obj
              }
            })
            // console.log('const firebaseConfig = ',fixed)
            // console.log('const firebaseConfig = ',fixed[1])
            let dburltry = lines[3].split('databaseURL:').join('').split('"').join('').trim()
            let appIdtry = lines[7].split('appId:').join('').split('"').join('').trim()
            let twoclientfirebaseConfig = { apiKey: fixed[1].apiKey, authDomain: fixed[2].authDomain, projectId: fixed[3].projectId, storageBucket: fixed[4].storageBucket, messagingSenderId: fixed[5].messagingSenderId, appId: fixed[6].appId, measurementId: fixed[7].measurementId }
          
            twoclientfirebaseConfig.appId = appIdtry
            this.ClientFireStore = twoclientfirebaseConfig
            this.ClientAppURL = 'https://'+twoclientfirebaseConfig.projectId+'.web.app'
          const EditedSystem = {
            // name: this.Name,
            firebaseConfig: this.firebaseConfig,
            ClientFireStore: this.ClientFireStore,
            ClientAppURL: this.ClientAppURL
            }
            console.log('EditedSystem',EditedSystem)
            this.SuiteInstallerStep++
      },
      ProcessClientBUandAdmin(){
        this.$store.commit('setCustomProcessingDialog',true)
        this.$store.commit('setCustomProcessingDialogText','Installing Business Unit')
        let ref = this.RADB.collection('systems').doc()
        this.NewSite.id = ref.id

      let vm = this
      let HeadofficeObj = {
        Name: 'Head Office',
        Description: 'The default, main Business Unit',
        Top: true
      }
      vm.$store.dispatch('CreateSearchTitle',HeadofficeObj.Name).then(hqsearchtitle => {
        HeadofficeObj.NameQuery = hqsearchtitle
        let GuestObj = {
          Name: 'Guest',
          Description: 'A Business Unit completely unrelated to others meant to give basic Signed In Permissions to any Guest Users',
          NameQuery: [
            'g',
            'gu',
            'gue',
            'gues',
            'guest',
          ],
          IsGuestBU: true,
          FinalStructure: true
        }
        vm.$store.dispatch('CreateSearchTitle',HeadofficeObj.Name).then(guesbusearchtitle => {
          GuestObj.NameQuery = guesbusearchtitle          
            let payload = {
              NewclientBU: HeadofficeObj,
              SystemName: vm.NewSite.Name,
              ClientFireStore: vm.ClientFireStore
            }
            let guestpayload = {
              NewclientBU: GuestObj,
              SystemName: vm.NewSite.Name,
              ClientFireStore: vm.ClientFireStore
            }
            vm.$store.dispatch('ProcessClientBU', payload).then(response => {
              HeadofficeObj.id = response
              //vm.ClientDBBusinessUnits.push(HeadofficeObj)
              vm.$store.dispatch('ProcessClientBU', guestpayload).then(guestresponse => {
                GuestObj.id = guestresponse
                //vm.ClientDBBusinessUnits.push(GuestObj)
                vm.CreateSystemAdministrator()
              })
            })
          })
      })
    }, 
      CreateSystemAdministrator(){
        this.$store.commit('setCustomProcessingDialog',true)
        this.$store.commit('setCustomProcessingDialogText','Creating Administrator')
        //console.log(this.ClientDBBusinessUnits)
      let vm = this
      let buobj =  {id: 'Head_Office',Name: 'Head Office'}
     
      const user = {
        email: this.NewSite.Admin.Email,
        password: this.NewSiteAdminPW,
        Title: this.NewSite.Admin.Title,
        Name: this.NewSite.Admin.Name,
        Surname: this.NewSite.Admin.Surname,
        Gender: this.NewSite.Admin.Gender,
        fullname: this.NewSite.Admin.Full_Name,
        rolesarrayDBRules: {SystemAdmin: true},
        rolesarrayQuery: ['System Admin'],
        Business_Unit: {Name: buobj.Name, id: buobj.id},
        Business_Unitid: buobj.id,
        IsGuestUser: false,
        IsSystemAdmin: true,
      }
      // if(this.TutorialStep === 2 && element === 'AddAministratorsbtn'){
      //     this.$emit('UpdateTutorialStep',3)
      // }
      let payload = {
        user: user,
        SystemName: this.NewSite.Name,
        ClientFireStore: this.ClientFireStore
      }
      this.$store.dispatch('ClientAdminsignUpAction', payload).then(response => {
        let userdocid = response.user.uid
        vm.clientApp = firebase.apps.find(obj => obj.name === payload.SystemName)
        vm.clientDB = vm.clientApp.firestore()
        
        vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userdocid).set({
              IsSystemAdmin: true,
              rolesarrayDBRules: user.rolesarrayDBRules,
              rolesarrayQuery: user.rolesarrayQuery,
              IsGuestUser: user.IsGuestUser,
              Business_Unit: user.Business_Unit,
              Business_Unitid: user.Business_Unitid,
              SubordinateArrayDBrules: {
                [userdocid]: true
              },
              Subordinatearrayquery: [userdocid],
              Disabled: false,
            })
        vm.clientDB.collection('SocialNetworks').doc(this.NewSite.id).collection('groupmembers').doc(userdocid).set({
              Disabled: false,
            })
        vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(userdocid).set({
          Disabled: false,
        })
        vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(userdocid).set({
              Title: user.Title,
              Name: user.Name,
              Surname: user.Surname,
              Full_Name: user.fullname,
              Gender: user.Gender,
              Email: user.email,
              Created_On: new Date(),
              Disabled: false,
              Business_Unit: user.Business_Unit,
              Business_Unitid: user.Business_Unitid,
            })
            if(vm.RAUserObj){
              vm.$store.commit('setCustomProcessingDialog',false)
              vm.$store.commit('setCustomProcessingDialogText','')
              vm.SuiteInstallerStep = 6
            }
            else{
              vm.SuiteInstallerStep++              
              vm.$store.commit('setCustomProcessingDialog',false)
              vm.$store.commit('setCustomProcessingDialogText','')
            }
        
      })
    },  
      PrepareNewSiteInvoice(){
         let vm = this
          let Status = vm.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
          let billingaccount = {
              // ...vm.BillingAccount,
                Account_Name: vm.NewSite.Name,
                Client_Reference: vm.NewSite.Name,
                User: {
                    id: vm.NewSite.Owner.id,
                    Full_Name: vm.NewSite.Owner.Full_Name,
                    Name: vm.NewSite.Owner.Name,
                    Surname: vm.NewSite.Owner.Surname
                },
                Userid: vm.NewSite.Owner.id,
                Billing_Type: {ID: 1000002, Name: 'COD'},
                Total_Invoiced: 0,
                Total_Paid: 0,
                Outstanding_Balance: 0,
                Created_By: {Full_Name: vm.NewSite.Owner.Full_Name, id: vm.NewSite.Owner.id},
                Created_Byid: vm.NewSite.Owner.id,
                Created_On: new Date(),
                Type_Default: false,
                Modified_By: {Full_Name: vm.NewSite.Owner.Full_Name, id: vm.NewSite.Owner.id},
                Modified_Byid: vm.NewSite.Owner.id,
                Modified_On: new Date(),
                IsFinal: false,
                Status: Status,
                Status_Reason: Status.Options.find(obj => obj.Name === 'Open')
          }
          const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            let payload = {
              Docid: 'BillingAccounts_Customer_ID',
              Data: billingaccount,
              siteid: process.env.VUE_APP_RA_PROVIDER_ID
            }
            vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')
            
            AssignAutoNumber(payload).then(result => {
              billingaccount.Customer_ID = result.data
              billingaccount.id = billingaccount.Customer_ID
              console.log('billingaccount',billingaccount)
        let invtype = vm.$store.state.InvoiceTypes.find(obj => obj.Name === 'Site Subscription')
            let invpayload = {
            Docid: 'DispatchInstructions_Number',
            Data: {Invoice_Type: invtype}
            }
            vm.$store.commit('setCustomProcessingDialogText','Creating Invoice Number')
            AssignAutoNumber(invpayload).then(result => {
            
            vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
            let contact = ''
            let PDFHeaderObj = {
                Company_Name: vm.ActiveBillingSystem.Company_Name,
                Company_Address_Prop: 'PO_Bill_To_Address_',
                Billing_Address_Prop: 'SO_Delivery_Address_',
                Primary_Field_Name: 'Invoice_Number',
                Date: new Date(),        
                Customer_ID: billingaccount.Customer_ID,
                Business_Unit: vm.AppMonetizeBU,
                Business_Unitid: vm.AppMonetizeBU.id,
                Reference_Number: billingaccount.id,
                BillingAccount: {Client_Reference: billingaccount.Client_Reference,Customer_ID: billingaccount.Customer_ID,id: billingaccount.id},
                BillingAccountid: billingaccount.id,
                Client: {
                    id: vm.userLoggedIn.id,
                    Full_Name: vm.userLoggedIn.Full_Name,
                    Name: vm.userLoggedIn.Name,
                    Surname: vm.userLoggedIn.Surname
                },
                Customer_Company: billingaccount.Account_Name,
                Customer_Contact: contact,
                Clientid: vm.userLoggedIn.id,
                Recordid: result.data,
                Delivery: false,
                Shipping_Method: {ID: 1000001,Name: 'FOB'},
                Due_Date: new Date(),
                Royalty_Percentage: vm.ActiveBillingSystem.Base_App_Royalties
            }
            //we don't do a LookupObj here do we? because you are not paying the WD here, vm is about charging for plugins, right?
            PDFHeaderObj.Customer_Company = vm.userLoggedIn.Full_Name
            vm.Company_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name.split('Company_Address_').join(PDFHeaderObj.Company_Address_Prop)
                let brdname = brd.Name
                if(vm.System[brdname]){
                    PDFHeaderObj[brdname] = vm.System[brdname]
                }
                })
                vm.Billing_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name
                let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
                if(billingaccount[brdname]){
                    PDFHeaderObj[newname] = billingaccount[brdname]
                }
                })
            
            let PDFDocLineItems = []
            let descr = `<p>Site Subscription for plugins of from Suite Template "`+this.ActiveSuiteTemplate.Name+`" </p>`
            descr = descr+`<br>`
            let item = {
                Nr: 1,
                Name: 'Monthly Plugins',
                Description: descr,
                Price: vm.SuiteTotal,
                Qty: 1,
                //maybe no LookupObj at all?
                LookupObj: {
                  id: vm.ActiveSuiteTemplate.id,
                  identifier: 'Name',
                  // BillingAccount: vm.ActiveSuiteTemplate.BillingAccount,
                  // BillingAccountid: vm.ActiveSuiteTemplate.BillingAccountid,
                  Name: vm.ActiveSuiteTemplate.Name,                  
                  Owner: vm.ActiveSuiteTemplate.Owner,
                  Ownerid: vm.ActiveSuiteTemplate.Ownerid 
                }
            }
            
            PDFDocLineItems.push(item)
            let taxtotal = 0
            let taxperc = 0
            let total = 0
            if(vm.AppMonetizeBU && typeof vm.AppMonetizeBU.Tax_Percentage !== 'undefined'){
                taxperc = vm.AppMonetizeBU.Tax_Percentage
            }
            if(taxperc > 0){
                total = subpack.Price*(taxperc/100)
            }
            taxtotal = Number(total.toFixed(2))
            let PDFFooterObj = {
                Footer_Note: '',
                Sub_Total: vm.SuiteTotal,
                Discount_Total: 0,
                Freight_Total: 0,
                Tax_Total: taxtotal,
                Tax_Percentage: taxperc,
                Grand_Total: Number(taxtotal)+Number(vm.SuiteTotal),
                TotalFieldProp: 'Invoice_Total',
                TotalFieldValue: vm.SuiteTotal
            }
            let note = `<p>This amount would be the monthly subscription for the site.</p>`
            // `<p>`+vm.System.Company_Name+` merely faciitates the transaction, as vm is a third party purchase.</p>`
            PDFFooterObj.Footer_Note = note
            PDFHeaderObj.Payment_Terms = ''
            if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'COD'){
                PDFHeaderObj.Payment_Terms = 'COD'
                PDFHeaderObj.Payment_Period = 0
            }
            else if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'Credit'){          
                PDFHeaderObj.Payment_Period = billingaccount.Days_for_Payment
                PDFHeaderObj.Payment_Terms = 'Net '+billingaccount.Days_for_Payment
            }

            vm.$store.commit('setPDFFunctionName','Sales_Invoices')
            vm.$store.commit('setPDFHeaderObj',PDFHeaderObj)
            vm.$store.commit('setPDFFooterObj',PDFFooterObj)
            vm.$store.commit('setPDFDocLineItems',PDFDocLineItems)
            vm.$store.commit('EmitMarketDialogInteractMethod','PrepareNewSiteHosting')
            vm.$store.commit('EmitMarketDialogType','Market Content')
            vm.$store.commit('setPDFDownloadOnly',false)
            //  DO NOT do this, appnot picks up value cahnge in function, otherwise funciton may be uncalled this.$store.commit('setExportingtoPDFDialog',true)
            })
            })
      },
      InstallMarketplaceSiteTemplate(path,pagespath,navlistprop,navlistitems,navlistprops,newpath,siteobj){
        //console.log(path,pagespath,navlistprop,navlistitems,navlistprops,newpath,siteobj)
              //okay what is missing?
              //1. Name the pages..well like...if you wanted to right?
              //2. Update the Menu Items according to these new page names...
              //so yeah maybe you do want to choose names maybe not, if so change menu items names as well all rigged and ready, let's add bool or something. Logicn being still missing in Ui
              //A) NEED TO KNOW I AM INSTALLING MULTIPLE PAGES AND MENU WILL CHANGE
              //B) NEED TO SEE SOMETHING LANDINGPAGESINGLE SHOULD NOT TRY RENDER HERE
              navlistprops.map(prp => {
                    if(this.ActiveTemplate.TemplateProps && typeof this.ActiveTemplate.TemplateProps[prp] !== 'undefined'){
                        siteobj[prp] = this.ActiveTemplate.TemplateProps[prp]
                    }
                })
              let addedcomps = 0
              
                if(!this.TemplateInstallationMethod){
                  this.ComputedTemplateChildren.map(child => {
                    
                    //this I simply cannot do, I mean do I just striaght install and overwrite any pages?
                    //if that's the case dude then make sure client Ui confirms this that they know it's all overwritten!
                    //but for testing purposes I am going to add them with id "test_" just so I can see what would happen to other pages
                    let childid = child.TemplateObj.Name.split(' ').join('_')
                    let childpage = Object.assign({},child.TemplateObj)
                    childpage.Name = child.TemplateObj.Name
                    childpage.PageRoute = child.TemplateObj.PageRoute
                    delete childpage.id
                    pagespath.doc(childid).set(childpage).then(addedchild => {
                      let menuitem = Object.assign({},navlistitems.find(obj => obj.id === child.Templateid))
                      menuitem.Path = childpage.PageRoute
                      menuitem.icon = childpage.PageIcon
                      menuitem.title = childpage.Name
                      menuitem.id = childid
                      addedcomps++
                      //Breaking it I know but Path,Title and id needs to change. on Menu Items PLUS on this crooking version of add page
                      if(addedcomps === this.ComputedTemplateChildren.length){
                        siteobj[navlistprop] = navlistitems
                        //okay so if RATemplateView add RA_Parentid to children and add RA_Original_Templateid to each
                        //but in the same token we dont even save template id anywhere as is so...nevermind? Meaning we never know what Template was used anyway right so skip it right?
                        path.update(siteobj).then(updatedMaster => {
                          this.DeactivateActiveTemplateComponent()
                          this.DeactivateNewPageDialog()
                          this.$router.push(newpath)
                        })
                      
                      }
                    })
                  })
                }
                else{
                  let payload = {
                    path: path,pagespath: pagespath,navlistprop: navlistprop,navlistitems: navlistitems,navlistprops: navlistprops,newpath: newpath,siteobj: siteobj,children: this.ComputedTemplateChildren,template: this.ActiveTemplate
                  }
                  this.AssignSocialItemInteractMethod(this.TemplateInstallationMethod,payload)
                   this.$store.commit('setTemplateInstallationMethod','')
                }
      },
      PrepareNewSiteHosting(){
        this.PrepMarketplaceTemplateInvoice(true)
      },
      PrepMarketplaceTemplateInvoice(ForNewSite){
        let vm = this
       let doc = this.$store.state.PDFExportDoc
        vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
        let NewPOObject = this.PDFDataRecord
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        let storagelocation = 'Sales_Invoices/'+NewPOObject.BillingAccountid+'/'+NewPOObject.Invoice_Number
        var storageRef = firebase.storage().ref(storagelocation);
        var uploadTask = storageRef.put(blobpdf);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {     
              let PDF_File = {
                StorageRef: storagelocation,
                Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                fileurl: url,
                FileType: 'application/pdf',
                ModifiedDate: new Date(),
                Name: NewPOObject.Invoice_Number,
                filename: NewPOObject.Invoice_Number
              }
              let filepayload = {
                item: PDF_File,
                result: {data: [url]}
              }
              console.log('filepayload',filepayload,ForNewSite)
              this.$store.commit('SetSocialItemInteractMethodProp',filepayload) 
              this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
              vm.$store.commit('setOrderConfirmationPDF',PDF_File)
              vm.$store.commit('setCustomProcessingDialog',false)
              vm.$store.commit('setOrderConfirmationDialog',true)
              if(ForNewSite){
                vm.$store.commit('EmitMarketDialogInteractMethod','ProcessNewSiteHostingInvoice')
              }
              else{
                 vm.$store.commit('EmitMarketDialogInteractMethod','ProcessMarketTemplateInvoice')
              }             
            })
        
      },
      ProcessMarketTemplateInvoice(){
        let PDF_File = this.$store.state.OrderConfirmationPDF
         let vm = this
          let invtype = this.$store.state.InvoiceTypes.find(obj => obj.Name === 'Market Content')
          vm.$store.commit('setCustomProcessingDialogText','Processing Order')
          vm.$store.commit('setCustomProcessingDialog',true)
          let NewPOObject = Object.assign({},this.PDFDataRecord)
          NewPOObject.Invoice_Type = invtype
          NewPOObject.Amount_Paid = NewPOObject.Grand_Total
          NewPOObject.Progress = {ID: 1000004, Name: 'Paid'} //Just assuming online payment gonna be running
          NewPOObject.Latest_PDF_File = PDF_File
          NewPOObject.Invoice_Date = new Date(),
          NewPOObject.LookupObj = {
            id: this.ActiveTemplate.id,
            identifier: 'Name',
            BillingAccount: this.ActiveTemplate.BillingAccount,
            BillingAccountid: this.ActiveTemplate.BillingAccountid,
            Name: this.ActiveTemplate.Name,
            Owner: this.ActiveTemplate.Owner,
            Ownerid: this.ActiveTemplate.Ownerid ,
            Type: 'Marketplace Template',
            //NOT suite right?
            Collection: 'marketplacetempaltes'
          }
          NewPOObject.Royalty_Percentage = this.ActiveBillingSystem.Base_App_Royalties
          let PrimaryString = NewPOObject.Invoice_Number
                    var lowertext = "";                
                    var p;
                    let buildup = ''
                    //console.log(PrimaryString.length,PrimaryString)
                    for (p = 0; p < PrimaryString.length; p++) {
                        buildup = buildup+ PrimaryString[p]
                        if(p === PrimaryString.length-1){
                            lowertext += buildup.toLowerCase();
                        }
                        else{
                            lowertext += buildup.toLowerCase() + ",";
                        }
                    } 
                    NewPOObject.SearchQuery = []
                    NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(NewPOObject.Invoice_Number.trim().split(' '))         
                    NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(lowertext.split(','))  
                    NewPOObject.SearchQuery = NewPOObject.SearchQuery.map(entry => {
                        return entry.toLowerCase()
                    })
          let Dates = ['Due_Date','Invoice_Date']
          Dates.map(d => {
            NewPOObject[d] = {
              Type: 'Date',
              Date: NewPOObject[d]
            }
          })
          let findocpayload = {
            Collection: 'Sales_Invoices',
            ParentObj: NewPOObject,
            IDProp: 'Invoice_Number',
            siteid: this.$store.state.ActiveSuiteid
            }
            console.log(findocpayload)
            this.CheckObjectforUndefined(1,findocpayload,'SubscriptionOrder')
            const functions = firebase.functions();
            const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
            CreateFinancialDocument(findocpayload).then(result => {
              vm.$store.commit('setPDFExportDoc','')
              vm.$store.commit('setPDFDataRecord','')
            vm.$store.commit('setOrderConfirmationPDF','')
                vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
               this.PrepareMarketplaceTemplateInstallation() //Just assuming online payment gonna be running               
                console.log('this.userLoggedIn',this.userLoggedIn)
                setTimeout(() => {
                    vm.$store.commit('setCustomProcessingDialogText','Done')
                    vm.$store.commit('setCustomProcessingDialog',false)
                    vm.$store.commit('setOrderConfirmationDialog',false)
                },500)
            })
            vm.$store.commit('setCustomProcessingDialogText','Done')
            vm.$store.commit('setCustomProcessingDialog',false)
            vm.$store.commit('setOrderConfirmationDialog',false)
      },
      ProcessNewSiteHosting(){
         let vm = this
         vm.$store.commit('setCustomProcessingDialogText','Initiating')
        let NewPOObject = Object.assign({},this.PDFDataRecord)
        let newbillingaccount = NewPOObject.BillingAccount
        vm.NewSite.id = newbillingaccount.id
        let buobj = vm.userLoggedIn.Business_Unit
          let curruser = vm.userLoggedIn
          let status = vm.$store.state.DefaultStatusField
          let baseobj = {          
            Business_Unit: buobj,
            Business_Unitid: buobj.id,
            Created_On: curruser.Created_On,
            Owner: {id: vm.NewSite.Owner.id, Name: vm.NewSite.Owner.Name, Surname: vm.NewSite.Owner.Surname, Full_Name: vm.NewSite.Owner.Full_Name},
            Ownerid: vm.NewSite.Owner.id,
            Admin: {id: vm.NewSite.Admin.id, Name: vm.NewSite.Admin.Name, Surname: vm.NewSite.Admin.Surname, Full_Name: vm.NewSite.Admin.Full_Name},
            Adminid: vm.NewSite.Admin.id,
            Created_By: {id: curruser.id, Name: curruser.Name, Surname: curruser.Surname, Full_Name: curruser.Full_Name},
            Created_Byid: curruser.id,
            Created_On: new Date(),
            Modified_By: {id: curruser.id, Name: curruser.Name, Surname: curruser.Surname, Full_Name: curruser.Full_Name},
            Modified_Byid: curruser.id,
            Modified_On: new Date(),
            Status: status.DefaultOption,
            Status_Reason: status.DefaultLevel2Option,
          }
          let systemobj = {
              ...baseobj,
              BillingAccount: {Client_Reference: newbillingaccount.Client_Reference,id: newbillingaccount.id},
              BillingAccountid: newbillingaccount.id,
              ClientAppURL: vm.ClientAppURL,
              ClientFireStore: vm.ClientFireStore,
              firebaseConfig: vm.firebaseConfig,
              QuickstartStep: 6,
              id: newbillingaccount.id,
              Name: vm.NewSite.Name,
            }
            
        let inheritedprops = ['PublishStatus','PluginDataBase','SelectedTemplates','SkipClientInfo','HasClientRecord','Client','Clientid',
        'Owner','Ownerid','RouteName','ClientisNonMember','Description','Message_to_Client','Systemid']
        inheritedprops.map(prp => {
          if(typeof vm.ActiveSuiteTemplate[prp] !== 'undefined'){
            systemobj[prp] = vm.ActiveSuiteTemplate[prp]
          }
        })
            vm.$store.dispatch('CreateSearchTitle',systemobj.Name).then(syssearchtitle => {
              systemobj.NameQuery = syssearchtitle  
              systemobj.SearchQuery = syssearchtitle
              let website = vm.ActiveSuiteTemplate ? vm.ActiveSuiteTemplate.Website : vm.ActiveSystemWebsite
              let socialnetwork = vm.ActiveSuiteTemplate ? vm.ActiveSuiteTemplate.SocialNetwork : vm.ActiveSystemSocialNetwork
              //mid term I believe we will scratch this "ActiveSuiteTempalte" nonsense entirely
              //actually we should start allowing now the refdb poushd, in this case "clientdb" becomes "db.collection"
              //e.g. db.collection('SystemConfig').doc(newbillingaccount.id).set(systemobj)
              vm.InstallSiteModule(db,'Websites',website).then(websiteres => {
                  if(websiteres){
                vm.$store.commit('setCustomProcessingDialogText','Installing Social Network Module')
                    vm.InstallSiteModule(db,'SocialNetworks',socialnetwork).then(socialnetres => {
                      if(socialnetres){
                        vm.$store.commit('setCustomProcessingDialogText','Installing Library Module')
                                vm.InstallSiteLibrary().then(sitelibres => {
                                  if(sitelibres){
                        vm.$store.commit('setCustomProcessingDialogText','Installing Database Module')
                                    vm.InstallSiteDatabase().then(sitedbres => {
                                      if(sitedbres){
                              db.collection('SystemConfig').doc(vm.NewSite.id).set(systemobj).then(newsysdoc => {
                                vm.$store.commit('setCustomProcessingDialogText','Updating Template')
                                db.collection('SystemConfig').doc(vm.ActiveSuiteTemplate.id).update({
                                  Siteid: vm.NewSite.id,
                                  SkipListing: vm.NewSite.SkipListing
                                }).then(upddoc => {
                                  //okay correction. we actually need
                                  //somehow trigger users that can access the site , users array?
                                  //userroles, groupmembers, featuredmembers, businessunits
                                  //vm.CancelSuiteInstallerDialog()
                                  vm.$store.commit('setCustomProcessingDialogText','Done')
                                  vm.$store.commit('setCustomProcessingDialog',false)
                                  vm.CancelSuiteInstallerDialog()
                                })
                                 
                                
                              })
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                
                })
            })
      },
      ProcessNewSiteHostingInvoice(){
        let vm = this
        let PDF_File = this.$store.state.OrderConfirmationPDF
        let NewPOObject = Object.assign({},vm.PDFDataRecord) 
        let invtype = vm.$store.state.InvoiceTypes.find(obj => obj.Name === 'Site Subscription')
          vm.$store.commit('setCustomProcessingDialogText','Processing Order')
          vm.$store.commit('setCustomProcessingDialog',true)
          NewPOObject.Invoice_Type = invtype
          NewPOObject.Amount_Paid = NewPOObject.Grand_Total
          NewPOObject.Progress = {ID: 1000004, Name: 'Paid'}
          NewPOObject.Latest_PDF_File = PDF_File
          NewPOObject.Invoice_Date = new Date(),
          vm.$store.dispatch('CreateSearchTitle',NewPOObject.Invoice_Number).then(invsearchtitle => {
          NewPOObject.SearchQuery = invsearchtitle
          let Dates = ['Due_Date','Invoice_Date']
          Dates.map(d => {
            NewPOObject[d] = {
              Type: 'Date',
              Date: NewPOObject[d]
            }
          })
          let Status = vm.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
          let newbaobj = {
            ...vm.PDFDataRecord.BillingAccount,
            Account_Type: {ID: 1000007, Name: 'Site Account'},
              Created_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                Type_Default: false,
                Modified_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                PaymentSchedule: 'Monthly',
                IsFinal: false,
                Status: Status,
                Status_Reason: Status.Options.find(obj => obj.Name === 'Open'),
                Latest_Invoice_Date: NewPOObject.Created_On,
                User: {
                    id: vm.NewSite.Owner.id,
                    Full_Name: vm.NewSite.Owner.Full_Name,
                    Name: vm.NewSite.Owner.Name,
                    Surname: vm.NewSite.Owner.Surname
                },
                Userid: vm.NewSite.Owner.id,
          }
          let findoc = {...NewPOObject,
            BillingAccountObj: newbaobj}
          let findocpayload = {
            Collection: 'Sales_Invoices',
            ParentObj: findoc,
            IDProp: 'Invoice_Number',
            siteid: this.$store.state.ActiveSuiteid
            }
            console.log(findocpayload)
            vm.CheckObjectforUndefined(1,findocpayload,'SubscriptionOrder')
            const functions = firebase.functions();
            const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
            CreateFinancialDocument(findocpayload).then(result => {
              vm.$store.commit('setPDFExportDoc','')
              vm.$store.commit('setPDFDataRecord','')
            vm.$store.commit('setOrderConfirmationPDF','')
                vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
                if(vm.NewSuiteTemplateModule){
                  vm.ProcessNewSiteHosting() //Just assuming online payment gonna be running
                  //haha no wait where did PrepareMarketplaceTemplateInstallation go?
                }
                else{
                  vm.ProcessNewSiteHosting() //Just assuming online payment gonna be running
                }
                
               
            })
          })
      },
      CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
      PrepareMarketplaceTemplateInstallation(){
        //HERE WE INSTALL MARKETPLACE TEMPLATE
          //console.log('this.ActiveMarketTemplateChild.Components',this.ActiveMarketTemplateChild.Components,this.MarketTemplateUse)
          //SO ACTUALLY
          if(this.NewSuiteTemplateModule){
            this.SelectedTemplates[this.NewSuiteTemplateModule.id] = this.ActiveTemplate.id
            this.DeactivateActiveTemplateComponent()
            this.DeactivateNewPageDialog()
            this.NewSuiteTemplate.PluginDataBase[this.NewSuiteTemplateModule.id].Active
            this.ToggleUpdatingPluginList()
        }
          else{
            if(this.TemplateisPage(this.ActiveTemplate)){
            let pageobj = this.ActiveMarketTemplateChild.TemplateObj
            let props = ['PageTabs','TransposedRowsandCols','PageContentFont','PageHeadersFontSize','PageHeadersFont','PageIcon','PageBGGradient','PageBGAsset','PageBGColor','ClearNavbarColor']
            props.map(prp => {
              if(typeof pageobj[prp] !== 'undefined'){
                this.NewPage[prp] = pageobj[prp]
              }
            })
            //this.SaveNewPage()
            this.DeactivateActiveTemplateComponent()
          }
          else if(this.TemplateisSite(this.ActiveTemplate)){            
            let path = ''
            let pagespath = ''
            let navlistprop = ''
            let navlistitems = []
            let navlistprops = []
            let newpath = ''
            let siteobj = {Templateid: this.ActiveTemplate.id}
            if(this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'){
              navlistprop ='MenuItems'
              navlistitems = this.ActiveTemplate.TemplateProps.Navlist
              //this.ActiveMarketTemplateChild.TemplateProps.NavlistProp redundant, I could isntall feaured member on a website page if I relaly wanted to right?
              path = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.$route.params.id)
              pagespath = path.collection('Pages')
              // [this.ActiveMarketTemplateChild.TemplateProps.NavlistProp]
              
              navlistprops = ['DefaultPage','PublicNavbarDark','PublicNavbarStyle','PublicNavbarText','PublicNavbarSelectedText','PrependedPublicNavbarActions','AppendedPublicNavbarActions','Public_NavBar_IMG','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG']
              newpath = '/FeaturedMember/'+this.$route.params.id+'/'+this.ComputedTemplateChildren[this.ComputedTemplateChildren.length-1].Name.split(' ').join('_')
            }
             if(this.GroupSiteTemplate){
              navlistprop ='GroupNavbarItems'
              navlistitems = this.ActiveTemplate.TemplateProps.Navlist
              path = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.ActiveGroup.id)
              pagespath = path.collection('pages')
              navlistprops = ['DefaultPage','PublicNavbarDark','PublicNavbarStyle','PublicNavbarText','PublicNavbarSelectedText','PrependedPublicNavbarActions','AppendedPublicNavbarActions','Public_NavBar_IMG','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG']
              newpath = '/Group-PageEditor/'+this.ActiveGroup.id+'/'+this.ComputedTemplateChildren[this.ComputedTemplateChildren.length-1].Name.split(' ').join('_')
             }
             else if(this.ActiveTemplate.Category.Name === 'Website'){
              navlistprop ='PublicNavbarItems'
              navlistitems = this.ActiveTemplate.TemplateProps.Navlist
              path = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
              pagespath = path.collection('Pages')
              navlistprops = ['PublicNavbarItems','PublicNavbarText','Public_Landing_Page','KeyWords','Description','PublicNavbarStyle','Logo','KeyWords',
              'PublicNavbarDark','Public_NavBar_IMG','Public_NavBar_Gradient','PublicNavbarSelectedText','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG',
              'SystemBarEnabled','SystemBarDark','SloganMarquee','SystemBarColor','SiteTelephoneLabel','SiteSlogan','SiteEmail','SiteTelephoneNumber','AppendedPublicNavbarActions','PrependedPublicNavbarActions',
              'Company_Address_City','Company_Address_Country_Region','Company_Address_Postal_Code','Company_Address_State_Province','Company_Address_Street_Name',
              'Company_Address_Street_Number','Company_Address_Suburb','Telephone','Published']
            newpath = '/PageEditor/'+this.ComputedTemplateChildren[this.ComputedTemplateChildren.length-1].Name.split(' ').join('_')
             }
             else if(this.ActiveTemplate.Category.Name === 'Social Network'){
              navlistprop ='SocialNavbarItems'
              navlistitems = this.ActiveTemplate.TemplateProps.Navlist
              path = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
              pagespath = path.collection('Pages')
              navlistprops = ['SocialNavbarItems','Social_Landing_Page','Social_Module_Name','Group_Categories','Base_App_Royalties','Credit_Tokens_ROE','Social_NavBar_Gradient',
              'Social_Parallax_Gradient','Social_Parallax',
              'Social_NavBar_Header_IMG','Social_NavBar_IMG','Social_Navbar_Subtitle','Monetization_BU','Member_Ribbon','Payout_Minimum','Payout_Schedule']
              newpath = '/PageEditor/'+this.ComputedTemplateChildren[this.ComputedTemplateChildren.length-1].Name.split(' ').join('_')
             }
             //console.log('navlistprops',navlistprops)
             navlistprops.map(prp => {
                if(this.ActiveTemplate.TemplateProps && typeof this.ActiveTemplate.TemplateProps[prp] !== 'undefined'){
                    siteobj[prp] = this.ActiveTemplate.TemplateProps[prp]
                }
            })
            if(!this.GroupSiteTemplate){
              this.InstallMarketplaceSiteTemplate(path,pagespath,navlistprop,navlistitems,navlistprops,newpath,siteobj)
            }
            else{
              //BTW GROUP DEFPAGE = {Name: 'pagename',Type: 'Group Component'} dunno why but yeah
              //does your active group have permissions to eveyrthing? if not you need subscription packages on offer that matches creteria of plugins used by the template
              //understand one thing well....This is rigged for dynamic monetization setup. So today the template may be "free" because today site articles are not paid.
              //tomorrow though...different story. And then installation of the plugin would triger those subscriptions.
              //..it's on a whole differnt level that we would manage groups that exist that have scope that has since become paid...
              let pluginchecks = [{Pluginid: 'Group_Featured_Members'},{Pluginid: 'Group_Monetization',PackageProp: 'Can_Monetize'},
              {Pluginid: 'Site_Articles',SystemProp: 'Group_Articles_Paid',FeatureScopeProp: 'Articles'},{Pluginid: 'Site_Blogs',SystemProp: 'Group_Blogs_Paid',FeatureScopeProp: 'Blogs'},
              {Pluginid: 'Site_Events',SystemProp: 'Group_Events_Paid',FeatureScopeProp: 'Events'},{Pluginid: 'Site_Meetups',SystemProp: 'Group_Meetups_Paid',FeatureScopeProp: 'Meetups'},
              {Pluginid: 'Site_Articles',SystemProp: 'Group_Web_Forms_Paid',FeatureScopeProp: 'Web Forms'}]
              let requiredplugins  = pluginchecks.map(chck => {
                  if(this.ActiveTemplate.PluginDataBase && this.ActiveTemplate.PluginDataBase[chck.Pluginid] && this.ActiveTemplate.PluginDataBase[chck.Pluginid].Active){
                    //does not mean it's required,so let's return only the "non matches"
                    return this.GroupSubscriptionPackage && !this.ActiveBillingSystem[chck.SystemProp] && chck.PackageProp && !this.GroupSubscriptionPackage[chck.PackageProp] || 
                      this.ActiveBillingSystem[chck.SystemProp] && this.GroupSubscriptionPackage && !this.GroupSubscriptionPackage.FeatureScope || 
                     this.ActiveBillingSystem[chck.SystemProp] && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope && !this.GroupSubscriptionPackage.FeatureScope.includes(chck.FeatureScopeProp) || 
                     this.ActiveBillingSystem[chck.SystemProp] && !this.GroupSubscriptionPackage
                  }
              })
              if(requiredplugins.length > 0 && !this.TemplateInstallationMethod){
                //sorry but we need to first tell you the packages we got for these required plugins
              }
              else{
                if(!this.TemplateInstallationMethod){
                //either you on the right package or no plugins installed, nevermind let's continue installation
                this.InstallMarketplaceSiteTemplate(path,pagespath,navlistprop,navlistitems,navlistprops,newpath,siteobj)
                }
                else{
                  //okay so  from account view, where subscription will be assigned with applicable method
                  this.InstallMarketplaceSiteTemplate(path,pagespath,navlistprop,navlistitems,navlistprops,newpath,siteobj)
                }
              }
            }
          }
          else{
            //TBD, I am yet to install a single page to a site
          }
          }

          
      },
      ActivateActiveTemplateComponent(temp,comp){
        this.ActiveTemplate = temp
        this.ViewingTemplate = true
      },
      DeactivateActiveTemplateComponent(forinstall){
        this.OverrideActiveTemplate = false
        if(forinstall){
          this.Templateselected = true
          this.DeactivateViewMarketplaceTemplates()
        }
        else{
          this.ActiveTemplate = ''
          this.ViewingTemplate = false
          this.Templateselected = false
        }
        
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
      ActivateMarketplaceTemplateDialog(payload){
        this.MarketplaceTemplate = Object.assign({},payload)
        this.MarketplaceTemplateDialog = true
        //Components???
      },
      DeactivateMarketplaceTemplateDialog(){
        this.MarketplaceTemplateDialog = false
        this.MarketplaceTemplate = Object.assign({},this.DefaultMarketplaceTemplate)
        this.SelectedMarketplaceTemplateComponents = []
      },
      PrepareAppTemplate(payload){
        this.DeactivateMarketplaceTemplateDialog()
        this.$store.commit('setBuilderView',{Name: 'Database Builder'})
        setTimeout(() => {
        this.$store.commit('SetSocialItemInteractMethodProp',payload) 
        this.$store.commit('SetSocialItemInteractMethod','ToggleNewAppDialog')   
        }, 150);
      },
      StoreandRoutetoMarketplaceTemplate(){
        let newobj = Object.assign({},this.MarketplaceTemplate)
        if(this.MarketplaceTemplate && this.MarketplaceTemplate.Category && this.MarketplaceTemplate.Category.Name === 'Database App'){
            
          if(!this.UserMarketAccount && this.AppMonetizeBU){
           vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
           vm.$store.commit('setCustomProcessingDialog',true)
           const functions = firebase.functions();
           const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
           let payload = {
             Data: vm.userLoggedIn,
             AppMonetizeBU: vm.AppMonetizeBU,
            siteid: vm.$store.state.ActiveSuiteid
           }
           CreateMarketAccount(payload).then(result => {
             //console.log(result)
              newobj.BillingAccount = {Client_Reference: 'My Market Account',Customer_ID: result,id: result},
              newobj.BillingAccountid = result,
             vm.$store.commit('setCustomProcessingDialogText','Done Creating')
             vm.PrepareAppTemplate(newobj)
           })
         }
         else{
          newobj.BillingAccount = {Client_Reference: this.UserMarketAccount.Client_Reference,Customer_ID: this.UserMarketAccount.Customer_ID,id: this.UserMarketAccount.id},
          newobj.BillingAccountid = this.UserMarketAccount.id,
          this.PrepareAppTemplate(newobj)
         }
        }
        else{
          let path = db.collection('marketplacetemplates')
          let ref = path.doc()
          let newdocid = ref.id
          let addedcomps = 0
          delete newobj.Components
          //remember this could be from a template in template single or straigt from myTemplates col.
          if(this.SelectedMarketplaceTemplateComponents.length > 0){
            //This is no longer used is it???????
            newobj.Children = []
            this.SelectedMarketplaceTemplateComponents.map((comp,i) => {
              //THIS IS WRONG WE WILL NEED TO INSTALL THE CHILD COMPONENTS, BUT GET BACK TO IT SHORTLY!
              let comppath = path
              let compref = comppath.doc()
              let compid = compref.id
              comp.Parentid = newdocid
              newobj.Children.push(compid)
              comppath.doc(compid).set(comp).then(compdoc => {
                addedcomps++
                if(addedcomps === this.SelectedMarketplaceTemplateComponents.length){
                  path.doc(newdocid).set(newobj).then(newdoc => {
                    this.DeactivateMarketplaceTemplateDialog()
                    this.$router.push('/MarketplaceTemplate/'+newdocid)
                    })
                } 
              })
            })
          }
          else{
            //Take Note, we did consider adding the "mockup group" under TemplateObj, but no other Template works that way
            //You would also have the challenge of having to choose the group logo before site is created to receive assets
            //So setting up the group becomes step 1 in the group site builder
            if(newobj && newobj.Category.Name === 'Web Page'){
              newobj.TemplateProps = []
            }
            path.doc(newdocid).set(newobj).then(newdoc => {
              if(newobj && newobj.Category.Name === 'Web Page'){
                let pagespath = path.doc(newdocid).collection('Pages')
                let defpage = Object.assign({},this.DefaultNewPage)
                  defpage.Name = 'Home'
                  defpage.PageIcon = 'mdi-home'
                  defpage.Overview = 'Default page for the template'
                  defpage.PageRoute = 'Home'
                  defpage.PublishType = 'Public'
                  pagespath.doc('Home').set(defpage).then(newdefpage => {
                     this.DeactivateMarketplaceTemplateDialog()
                    this.$router.push('/MarketplaceTemplate/'+newdocid)
                  })
            }
            else{
              this.DeactivateMarketplaceTemplateDialog()
            this.$router.push('/MarketplaceTemplate/'+newdocid)
            }
            
            })
          }
        }
      
      },
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
        SaveNewPage(){
        this.NewPage.PageRoute = this.NewPage.Name.split(' ').join('-')
        let dbref = ''
        let newpath = ''
        let parentid = ''
        let newpageid = ''
        if(this.$route.name === 'DocumentationLibraryEditor'){
          dbref = this.ActiveDocumentationLibrary.Database.collection('Libraries').doc(this.System.id).collection('DocumentationLibraries').doc(this.$route.params.id).collection('Pages')
          newpath = '/DocumentationLibraryEditor/'+this.$route.params.id+'/'+this.NewPage.Name.split(' ').join('_')
        }
        
        // else if(this.$route.name === 'MyMarketplaceTemplates'){ THIS HAS GOT TO BE WRONG RIGHT??? WE DO NOT ADD PAGES FROM MYMARKETPLACECOLLECTION?
        else if(this.$route.name === 'MarketplaceTemplate'){
          //basically let's assign the parent template first
          parentid = this.NewPageSite.id
          dbref = dbref = db.collection('marketplacetemplates')
          
          let ref = dbref.doc()
          newpageid = ref.id
          let category = ''
          let categories = this.$store.state.TemplateCategories
              if(this.NewPageSite.Name === 'Website'){
                category = categories.find(obj => obj.Name === 'Web Page')
              }
              else if(this.NewPageSite.Name === 'Social Network'){
                category = categories.find(obj => obj.Name === 'Social Page')
              }
              else if(this.NewPageSite.Name === 'Featured Member'){
                category = categories.find(obj => obj.Name === 'Featured Member Page')
              } 
              else if(this.NewPageSite.Name === 'Group Site'){
                category = categories.find(obj => obj.Name === 'Group Site Page')
              }  
           let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            let systemurl = this.System.ClientAppURL
            let defobj = {
                Templateid: '',
                Systemid: systemid,
                ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                Name: this.NewPage.Name,
                Icon: this.NewPage.PageIcon,
                // TemplateProps: {},
                TemplateObj: this.NewPage,
                Category: category,
                Parentid: parentid
            } 
            this.NewPage = Object.assign({},defobj)
            this.NewPage.Name = this.NewPage.TemplateObj.Name
        }
        else if(this.$route.name === 'Suite Template Website'){
          dbref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('Pages')
          newpath = ''
        }
        else if(this.$route.name === 'Suite Template Social Network'){
          dbref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('Pages')
          newpath = ''
        }
        else if(this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'){
          dbref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.$route.params.id).collection('Pages')
          newpath = '/FeaturedMemberEditor/'+this.$route.params.id+'/'+this.NewPage.Name.split(' ').join('_')
        }
        else if(this.IsGroupView){
          dbref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).collection('pages')
          newpath = '/Group-PageEditor/'+this.$route.params.id+'/'+this.NewPage.Name.split(' ').join('_')
        }
        else {
          dbref = this.NewPage.PublishType === 'Public' ? db.collection('Websites').doc(this.System.id).collection('Pages') :  db.collection('SocialNetworks').doc(this.System.id).collection('Pages')
          newpath = '/PageEditor/'+this.NewPage.Name.split(' ').join('_')
        }
        if(!newpageid){
          newpageid = this.NewPage.Name.split(' ').join('_')
        }
        //console.log(dbref,dbref.doc(this.NewPage.Name.split(' ').join('_')))
        //console.log(newpageid)
          dbref.doc(newpageid).set(this.NewPage).then(doc => {
            //console.log('this.NewPageSite',this.NewPageSite)
             if(this.NewPageSite){
                  this.$store.commit('setNewSitePageid',newpageid)
                  this.AssignSocialItemInteractMethod('PerformSitePageAddition',this.NewPage)
                  this.DeactivateNewPageDialog()
                }
            else if(this.NewPage.SubPage){
              this.NewPage.id = this.NewPage.Name.split(' ').join('_')
               this.AssignSocialItemInteractMethod('PushtoPreviousNavItem',this.NewPage)
             }
             if(this.NewPage.DefaultLandingPage){
              //console.log('says it will')
               this.AssignSocialItemInteractMethod('UpdateSocialDefaultLandingPage','/'+this.NewPage.PageRoute)
             }
             else{
                this.DeactivateNewPageDialog()
                if(newpath && this.$route.name !== 'MyMarketplaceTemplates'){
                  //alt if(newpath && !parentsite){
                  this.$router.push(newpath)              
                }  
             }
                     
          })
      },
      DeactivateNewPageDialog(){
        this.NewPageDialog = false
        this.NewPage = Object.assign({},this.DefaultNewPage)
        if(this.Templateselected){
          this.Templateselected = false
          this.$store.commit('setActiveMarketTemplateChild','')
        }
      },
      ToggleNewPageDialog(type){
        console.log(type,this.$route.name)
        this.NewPage.PublishType = type
        if(this.RandomInteractProp === 'New Page as SubPage'){
          this.NewPage.SubPage = true
          this.$store.commit('SetRandomInteractProp','')
        }
        if(this.RandomInteractProp === 'Default Social Landing Page'){
          this.NewPage.DefaultLandingPage = true
          this.$store.commit('SetRandomInteractProp','')
        }
        //console.log(this.NewPage)
        this.NewPageDialog = !this.NewPageDialog
      },
      ToggleNewDocLibDialog(type){
        this.NewDocLib.PublishType = type
        this.NewDocLibDialog = !this.NewDocLibDialog
      },
      DeactivateNewDocLibDialog(){
        this.NewDocLibDialog = false
        this.NewDocLib = Object.assign({},this.DefaultNewDocLib)
      },
      SaveNewDocLib(){
        this.NewDocLib.PageRoute = this.NewDocLib.Name.split(' ').join('-')
        let dbref = ''
        let newpath = ''
        if(this.IsGroupView){
          dbref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).collection('pages')
          newpath = '/Group-PageEditor/'+this.$route.params.id+'/'+this.NewDocLib.Name.split(' ').join('_')
        }
        else {
          dbref = db.collection('Libraries').doc(this.System.id).collection('DocumentationLibraries')
          
        }
          dbref.doc(this.NewDocLib.Name.split(' ').join('_')).set(this.NewDocLib).then(doc => {
            let intropage = Object.assign({},this.NewDocLib)
            intropage.Name = this.NewDocLib.FirstPageName
            intropage.PageRoute = intropage.Name.split(' ').join('-')
            delete intropage.LibraryNavbarItems
            delete intropage.FirstPageName
            newpath = '/DocumentationLibraryEditor/'+this.NewDocLib.Name.split(' ').join('_')+'/'+intropage.Name.split(' ').join('_')
            dbref.doc(this.NewDocLib.Name.split(' ').join('_')).collection('Pages').doc(intropage.Name.split(' ').join('_')).set(intropage).then(doc => {
              this.DeactivateNewDocLibDialog()
              this.$router.push(newpath)
            })
            
          })
      },
        AssignSocialItemInteractMethod(item,SecondProp){
      //console.log('AssignSocialItemInteractMethod',item,SecondProp)
      this.$store.commit('SetSocialItemInteractMethodProp','') 
      this.$store.commit('SetSocialItemInteractMethod','')
      if(SecondProp){
        this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
      else if(item){
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
    },
    }
}
</script>

<style>

</style>



