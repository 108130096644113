
<template>
<v-layout row style="align-items: center;height: 100%;" class="justify-center">
  <v-dialog v-model="WebFormDialogFancy" width="400">
    <v-card class="transition-fast-in-fast-out" width="400"
        style="height: 100%;bottom: 0;position: absolute;right: 0;">
        <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" style="position: absolute;z-index:2;padding:0;"
        v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
    
    <WebFormTab :PageName="PageName" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
        :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="FooterTab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"									
        />
    </v-card>
    </v-dialog>
    <div :class="FooterFormClass(tab)" :cols="12" :xl="tab.FooterNote ? 8 : 12" :lg="tab.FooterNote ? 8 : 12" :md="tab.FooterNote ? 8 : 12" :sm="12"
    style="padding: 0px!important;">
        <v-card class="flex transparent"      
      flat :style="'width: 100%;padding: 12px;'+TabFormStyle(tab)">    <!-- first below is list of fields linked from web form.  -->
      <v-list-item dense v-if="tab.FooterFormFields">
        <v-list-item dense v-for="field in StructuredFooterFormFields" :key="field.itemObjKey">
          <v-avatar v-if="tab.FooterFormIconInvert" size="55" :style="tab.FooterFormBTNColor ? 'background-color: '+tab.FooterFormBTNColor.hexa : ''">
            <v-icon dark size="45">mdi-map-marker-outline</v-icon>
        </v-avatar>
        <v-avatar size="55" v-if="!tab.FooterFormIconInvert">
            <v-icon :style="tab.FooterFormBTNColor ? 'color: '+tab.FooterFormBTNColor.hexa : ''" size="45">mdi-map-marker</v-icon>
        </v-avatar>
        <v-list-item-content style="padding: 10px;">            
          <v-list-item-title style="font-size: 1.15rem;font-weight: 500;">
            {{field.DisplayName}}
          </v-list-item-title>
          <v-list-item-subtitle style="padding-top: 10px;">
             <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="field" 
                    :field="{...field,Name: 'Response'}" :NewImageType="true" :Solo="true"
                :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                :SystemEntities="SystemEntities" :PrimaryFieldName="'Name'" :RelatedObj="''" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                    />
          </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>    
        <v-list-item dense class="justify-end">
        <v-list-item-action class="justify-center">
          <v-btn @click="SetFooterTab()"
          style="border-radius: 10px;font-weight: 400;" width="200" dark 
          :style="tab.FooterFormBTNColor ? 'background-color: '+tab.FooterFormBTNColor.hexa : ''">
            {{tab.FooterFormButtonName}}</v-btn>
        </v-list-item-action>
        </v-list-item>
      </v-list-item>
        </v-card>
    </div>
    <div v-if="tab.FooterNote" class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12">
         <v-card dark flat class="transparent">
                <v-card-title>{{tab.FooterNoteTitle}}</v-card-title>
                <v-card-text class="caption" v-html="tab.FooterNoteDescription"> 
                </v-card-text>
            </v-card>
    </div>
</v-layout>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
export default {
    props: ['System','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {
      WebFormTab,
      FieldValueEditerComponent
        },
    data() {
    return {      
      WebFormDialogFancy: false, 
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        FooterTab: '',
        StructuredFooterFormFields: []
    }
    },
    computed:{
      ComputedFooterFormFields(){
        return this.tab.FooterRelatedEntityFields.map(field => {
          return this.tab.FooterFormFields.find(obj => obj.id === field) 
        })
      },
        TitleLines(){
            return this.tab.TitleLines ? 
            this.tab.TitleLines :
            []
        },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      ComputedTab(){
        return this.tab
      }
    },
    watch: {
       ComputedFooterFormFields: {
            handler: function(newvalue, oldvalue) {
              this.StructureFooterFormFields()
            },
            deep: true
       }
    },
    created(){
      this.StructureFooterFormFields()
    },
    
    methods: {
      StructureFooterFormFields(){
        this.StructuredFooterFormFields = this.ComputedFooterFormFields
      },
        DeactivateWebDialog(){
            this.WebFormDialogFancy = false
        },
        SetFooterTab(){
          console.log(this.tab)
          this.FooterTab = {
            FormFields: this.tab.FooterFormFields.filter(field => {
            return !field.MappedField || field.MappedField && !field.MappedField.LoggedInUser || this.userLoggedIn
          }),
            // .map(field => {
            //   let fieldobj = Object.assign({},field)
            //   //.Response for prepopulated
            //   return fieldobj
            // }),
            FormName: this.tab.FooterFormName,
            FormEmailRecipient: this.tab.FooterFormEmailRecipient,
            RelatedEntity: this.tab.FooterRelatedEntity,
            StatusDefault: this.tab.FooterStatusDefault,
            Status_ReasonDefault: this.tab.FooterStatus_ReasonDefault,
            Title: this.tab.FooterFormName,
            HeaderFontSize: typeof this.tab.FooterFormHeaderSize ? this.tab.FooterFormHeaderSize : 18 ,
            HeaderFontFamily: 'Anton'
          }
          console.log(this.FooterTab)
          this.WebFormDialogFancy = true
        },
        FooterFormClass(tab){
            let size = 12
            if(tab.FooterNote){
                size = 8
            }
            let cls = 'col-sm-12 col-md-'+size+' col-lg-'+size+' col-xl-'+size+' col-12'
            return cls
        },
      TabFormStyle(tab){
        let style = ''
        if(tab.FooterFormGradient){
            style=style+tab.FooterFormGradient
        }
        if(tab.FooterFormBorderStyle){
            style=style+tab.FooterFormBorderStyle
        }
        return style
      }

    }    
}
</script>

<style>
</style>