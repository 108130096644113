<template>
  <div>
     <v-card>
        <!-- <v-card-title class="primary white--text">       -->
        <v-toolbar flat dark height="100px">           
          <v-toolbar-title>New Event</v-toolbar-title>
         
        </v-toolbar>
            <!-- <v-list>          
          
        </v-list> -->
        <!-- </v-card-title> -->
        <v-list dense>
          <v-row style="padding: 20px;">
              <div class="flex xl6 lg6 md6 sm12 xs12">
                <v-list-item v-if="selectedEventImagelocalURL && !$store.state.TemplateView">
                <v-img :src="selectedEventImagelocalURL" height="300px" contain/>
              </v-list-item>
              
              <v-list-item v-if="!$store.state.TemplateView && !this.ProvidedImage"> 
                          
                  <input
                        style="display: none"
                        type="file"
                        @change="onEventImageSelect($event)"
                        ref="EventImageInputter">                
                        <v-btn @click="onEventImageUpload(EditedEvent.id)" v-if="selectedEventImage">Save</v-btn>
                        <v-btn @click="closeEventImageUpload()" v-if="selectedEventImage">Cancel</v-btn>
                        <v-btn @click="$refs.EventImageInputter.click()" v-else  color="pop" dark icon><v-icon>mdi-pencil</v-icon></v-btn><v-btn :href="EventImage" target="_new" color="secondary" dark icon><v-icon>mdi-eye</v-icon></v-btn>
                
                  </v-list-item>
                  <v-list-item v-if="$store.state.TemplateView">
                    <v-img class="black" height="150" contain									
                                    :src="EditedEvent.EventImage ? EditedEvent.EventImage : require('@/assets/ImageHolder.png')"									
                                    >
                                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                                
                                        <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteEventCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                                    </v-row>
                              </v-img>	
                  </v-list-item>
                    <v-list-item> 
            <v-list-item-content>             
              <v-text-field dense v-model="EditedEvent.name" label="Subject" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
              </v-list-item-content>
              </v-list-item>
               <v-list-item>
                <DateandTimeField  :col="col" @UpdateNewRecordQuickEdit="UpdateNewRecordQuickEdit"
                    :Record="EditedEvent" :CanEdit="true" :Editing="true" :TableQuick="true"
                    :System="System" :SystemEntities="SystemEntities" :field="StartField"
                />
                </v-list-item>
               <v-list-item>
                <DateandTimeField  :col="col" @UpdateNewRecordQuickEdit="UpdateNewRecordQuickEdit"
                    :Record="EditedEvent" :CanEdit="true" :Editing="true" :TableQuick="true"
                    :System="System" :SystemEntities="SystemEntities" :field="EndField"
                />
                </v-list-item>
              <!-- <v-list-item-content>
                <DateandTimeField  :col="col" @UpdateNewRecordQuickEdit="UpdateNewRecordQuickEdit"
                    :Record="EditedEvent" :CanEdit="true" :Editing="true" :TableQuick="true"
                    :System="System" :SystemEntities="SystemEntities" :field="StartField"
                />
              </v-list-item-content>
              <v-list-item-content>
                <DateandTimeField  :col="col" @UpdateNewRecordQuickEdit="UpdateNewRecordQuickEdit"
                    :Record="EditedEvent" :CanEdit="true" :Editing="true" :TableQuick="true"
                    :System="System" :SystemEntities="SystemEntities" :field="EndField"
                />
              </v-list-item-content>
               </v-list-item> -->
           <v-list-item>
              <v-list-item-content>
                <v-checkbox dense v-model="EditedEvent.AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              </div>
               <div class="flex xl6 lg6 md6 sm12 xs12">
                 <v-list-item v-if="userLoggedIn">
              <v-switch v-if="!$store.state.UserisGuest && System.Guests_can_Social && EditedEvent.id && EditedEvent.UserisModerator || userLoggedIn && !$store.state.UserisGuest && System.Guests_can_Social && !EditedEvent.id
               || System.Guests_can_Social && GroupData && EditedEvent.id && EditedEvent.UserisModerator
               || System.Guests_can_Social && GroupData && !EditedEvent.id" v-model="EditedEvent.GuestsIncluded"  :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
              </v-list-item>
          <v-list-item v-if="!$store.state.TemplateView && userLoggedIn">
            <v-list-item-content v-if="userLoggedIn">
          <v-autocomplete :disabled="UserisGuest" dense :items="UsersArray" label="Owner" item-text="Full_Name" v-model="EditedEvent.Owner" return-object></v-autocomplete>
            </v-list-item-content>
          </v-list-item >
          
            <div  v-if="!$store.state.TemplateView && userLoggedIn && !UserisGuest" class="hovercontent mx-3"><v-icon @click="InvitingAttendees = !InvitingAttendees">mdi-account-multiple-plus<p class="tooltiptext">Invite Attendees</p></v-icon></div>
           
            <ContentEditableField style="padding:15px;"
                :FieldObject="EditedEvent" :FieldName="'details'"
                :FieldValue="EditedEvent.details" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
          <v-list-item v-if="InvitingAttendees">
            <v-list-item-avatar tile >               
                      <v-btn small @click="ToggleSelectDialog()" color="pop" dark>To:</v-btn>
                      </v-list-item-avatar>
            <v-list-item-content>               
                      <v-combobox dense v-model="Attendees" multiple  label="To" readonly :rules="[$store.state.formrules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content> 
          </v-list-item>
        
              <v-list-item>
                 
                <v-list-item-content>             
                 <v-text-field dense v-model="EditedEvent.Location" label="Location" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
                </v-list-item-content>
                <v-list-item-content>             
                 <v-checkbox dense v-model="EditedEvent.EventisMapLocation" label="Has Map Location"></v-checkbox>
                </v-list-item-content>
                <v-list-item-content v-if="EventisMapLocation">             
                 <v-text-field dense v-model="EditedEvent.EventLocationLink" label="Location Link" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
                </v-list-item-content>
                
              </v-list-item>
              
              <v-list-item  v-if="!$store.state.TemplateView && userLoggedIn"> 
              <v-select :disabled="!userLoggedIn" dense return-object v-model="EditedEvent.whocanview" item-text="Name" :items="ViewAccess" label="Who can View" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-select>
             
              </v-list-item>
              <v-list-item  v-if="!$store.state.TemplateView && userLoggedIn"> 
                      
              <v-select :disabled="!userLoggedIn" dense v-model="EditedEvent.whocanjoin" item-text="Name" :items="JoinAccess" label="Who can Join" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-select>
             
              </v-list-item>
               <v-list-item  v-if="!$store.state.TemplateView && userLoggedIn">                 
                <v-select :disabled="!userLoggedIn" dense multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-list-item>
              </div>
          </v-row>
             
              
         <!-- <v-card-text>
              <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text> -->

          
        </v-list>
         <!-- <v-card-actions v-if="EditedEvent && !$store.state.TemplateView">
              <TaggingComponent :AppisDarkModeCard="AppisDarkModeCard" :UsercanTag="UserisOwner" :record="EditedEvent" />
            </v-card-actions> -->
        <v-card-actions v-if="!FromAdvert">
          <v-btn @click="CancelDialog()" dark outlined color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn dark outlined color="success" @click="$store.state.TemplateView ? save() : AddNewEvent(EditedEvent)">Save</v-btn>
        </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import DateandTimeField from '@/components/Database/Fields/Input/DateandTimeField'
export default {
    props: ['System','SystemEntities','UpdateAdvert',
    'SiteModerators','AccessRoles','ProvidedSubject',
    'ProvidedDescription','FromAdvert','ProvidedIMGFile','ProvidedImage'],
    components: {ContentEditableField,DateandTimeField},
    data() {
        return {
            StartField: {id: 'start',Name: 'start',DisplayName: 'Start',Type: 'Date',HasTimeInput: true},
            EndField: {id: 'end',Name: 'end',DisplayName: 'End',Type: 'Date',HasTimeInput: true},
            EditedEvent: {AllDayEvent: false,EventisMapLocation: false,color: 'red',Attendees: []},
            NewEventDialog: false,
            EventstartdateMenu: false,
            AppointmentOwner: '',
            InvitingAttendees: false,
            AttendeeSelectDialog: false,
            EventRecordFilter: '',
            EventViewAccess: '',
            EventJoinAccess: '',
            Moderators: [],
            EventImage: '',
            UserAttendance: '',
            UserAttendanceOptions: [
                {Name: 'Going',ID: 1000001},
                {Name: 'Not Going',ID: 1000002},
                {Name: 'Maybe',ID: 1000003},
            ],
            selectedEventImage: '',
            selectedEventImagelocalURL: '',
            HasImage: false,
          
            EventenddateMenu: false,
            EventEndDate: '',
            EventStartTime: '',
            EventStartTimeMenu: false,
            AllDayEvent: false,
            EventEndTime: '',
            EventEndTimeMenu: false,
            EventStartDate: '',
            EventName: '',
            EventLocation: '',
            EventisMapLocation: false,
            EventLocationLink: '',
            AddressStreet_Number:  '',
            AddressStreet_Name:  '',
            AddressSuburb:  '',
            AddressCity:  '',
            AddressPostal_Code:  '',
            AddressState_Province:  '',
            AddressCountry_Region:  '',
            EventSubject: '',
            EventDetails: '',
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            today: format(new Date(),'yyyy-MM-dd'),
            
            EventModerator: false,
            Attending_Users: []
        }
    },	
    computed:{
        ViewAccess(){
            return this.AccessRoles.filter(type => {
                //console.log('type',type)
                return this.userLoggedIn || type.Name === 'Public'
            })
        },
        JoinAccess(){
            if(this.EditedEvent.whocanview)
           // console.log(this.EventViewAccess)
            return this.AccessRoles.filter(role => {
                return role.ID >= this.EditedEvent.whocanview.ID
            })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        UpdateAdvert(v){   
          if(v){
            this.AddNewEvent(this.EditedEvent)
          }
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(!this.userLoggedIn || this.UserisGuest || this.userIsAdmin){
            this.EditedEvent.whocanview = this.ViewAccess.find(obj => obj.Name === 'Public')
            this.EditedEvent.whocanjoin = this.JoinAccess.find(obj => obj.Name === 'Public')
            this.EditedEvent.GuestsIncluded = true
        }
        if(this.userLoggedIn){
          this.EditedEvent.Owner = {id: this.userLoggedIn.id,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name}
          this.EditedEvent.Ownerid = this.userLoggedIn.id
        }
        if(this.ProvidedSubject){
            this.EditedEvent.name = this.ProvidedSubject
        }
        if(this.ProvidedDescription){
            this.EditedEvent.details = this.ProvidedDescription
        }
        if(this.ProvidedImage){
            this.EditedEvent.EventImage = this.ProvidedIMGFile
        }
    },
    methods:{
        UpdateNewRecordQuickEdit(payload,field){
        //console.log('UpdateNewRecordQuickEdit payload,field',payload,field)
        if(field.Type === 'Date'){
          this.EditedEvent[field.id] = payload.DateString
          this.EditedEvent[field.id+'Object'] = payload.DateObject
          if(field.HasTimeInput){
            this.EditedEvent[field.id+'Time'] = payload.TimeResponse
          } 
          this.EditedEvent[field.id.split('_').join('').toLowerCase()] = payload.DateObject.getTime()
          //console.log(this.EditedEvent)
        }            
      },
        AddNewEvent(EditedEvent){
            let vm = this
            //finally event now also needs - status, nameQuery, searchQuery, EditedEvent.AllDayEvent, GuestsIncluded
            this.$store.dispatch('CreateSearchTitle',EditedEvent.name).then(searchtitle => {
            EditedEvent.nameQuery = searchtitle
            EditedEvent.SearchQuery = searchtitle
            EditedEvent.status = 'Open'
            vm.$emit('AddNewEvent',EditedEvent)
            })
        },
        onEventImageSelect(event) {
        this.selectedEventImage = event.target.files[0]
        this.selectedEventImagelocalURL = URL.createObjectURL(this.selectedEventImage)
        this.HasImage = false
      },
        onEventImageUpload(eventid){
          let filename = this.selectedEventImage.name.split('.')[0]+'_'+this.selectedEventImage.size+'.'+this.selectedEventImage.name.split('.')[1]
          let EventImageStorageRef = ''
          if(this.$route.name === 'GroupSingle'){
            EventImageStorageRef = 'GroupEvents/'+this.$route.params.id+'/'+eventid+ '/EventImage/'+new Date()+'/'+filename
              }
              else{
                EventImageStorageRef = 'Events/'+eventid+ '/EventImage/'+new Date()+'/'+filename
              }
              this.$emit('ActivateProcessing',true)
                var storageRef = firebase.storage().ref(EventImageStorageRef);
            var uploadTask = storageRef.put(this.selectedEventImage);
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                this.EventsDB.doc(eventid).update({
                    EventImage: url,
                    EventImageStorageRef: EventImageStorageRef,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
            })
            this.$emit('ActivateProcessing',false)
            this.selectedEventImage = ''
                }),
                  this.Machine_Photofilesnackbar = true
        },
        closeEventImageUpload(){

        },
    }
}
</script>

<style>

</style>



