<template>
  <v-main :class="SinglePreview ? 'background' : 'v-main background'" 
  :style="SinglePreview ? 'margin-top:50px;padding: 0px; important!' : 'margin-top:50px;'">
    <!-- <div :class="SinglePreview ? '' : 'v-main__wrap'"> -->
    <!-- <v-btn outlined @click="EditingSingleView = !EditingSingleView">{{EditingSingleView ? 'Cancel' : 'Edit'}}
      </v-btn> -->
      <!-- <v-layout> -->
      <v-navigation-drawer style="z-index:10;margin-top: 50px;" app :permanent="!MobileView" left>
        <!-- Tabs {{Tabs}} -->
        <v-card-title row class="subtleoverline justify-center" style="padding-top: 10px;"
        >
        {{CurrentEntity.SingleName}}
        </v-card-title>
        <v-layout row class="justify-center" style="padding-bottom: 20px;"
        >
          <v-icon size="120">
            {{CurrentEntity.Table_Icon}}
            </v-icon>
        </v-layout>
        <v-card-title row class="grey white--text subtleoverline justify-center" style="padding-top: 20px;"
        >
        {{Record[CurrentEntity.Primary_Field_Name]}}
        </v-card-title>
        <v-list class="transparent" style="padding-left:15px;padding-right:15px;">
        
          <v-list-item v-if="userIsAdmin">
              <v-menu								
            :close-on-content-click="false"                            									
            transition="scale-transition"                            									
            >	
            <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on">
              <v-icon>mdi-plus</v-icon>
              Add
              </v-btn>
            </template>
            <v-card max-width="350">
              <v-card-title>
                New Dashboard
                </v-card-title>
              <v-card-text>
              <v-text-field v-model="NewDashName" label="Name"/>
              <v-text-field v-model="NewDashIcon" label="Icon"/>     
              </v-card-text>         
              <v-card-actions>
                <v-spacer>
                  </v-spacer>
                  <v-btn dark @click="AddNewDashboardTab()" outlined color="success">Save
                    </v-btn>
              </v-card-actions>
              </v-card>
              </v-menu>
              <v-btn outlined dark color="success">
              <v-icon @click="StoreSingleDashView(this.Tabs)">mdi-content-save</v-icon>
              Save
              </v-btn>
          </v-list-item>
        <div v-for="(tab,tabi) in ComputedTabs" :key="tab.itemObjKey">
         <v-list-item @click="ActivateEntityTab(tab,tabi)"
           style="padding-left:10px;"
            
            >
             <v-list-item-icon>
                    <v-icon>{{tab.Icon}}</v-icon><v-badge color="error" v-if="tab.notifications" overlap >
                        <span slot="badge"> {{tab.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-title>{{tab.Name}}
                    </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
        </div>
        </v-list>
      </v-navigation-drawer> 
      <!-- below a hidden list of actions per unisue entity. we gotta decide though whether all palced in same central place, or how we want to handle them
      note "action Button on the dashbaord view removed until we figure this out. too much stuff going on here to figure itout" 
      Also see SalesOrderSingle and old TableRecordComponent
      quick sarch for "Receive Payment" brings out quite a few things
      -->
      
      <v-card-text v-if="CurrentEntity && !ShowEntityTab">
         <v-btn style="width: 100%;" outlined @click="CopyRecordLink(Record)" v-if="Record.CanView">
          <!-- <v-btn style="width: 100%;" :to="'/'+CurrentEntity.SingleName.split(' ').join('')+'/'+Record.id" outlined
          v-if="Record.CanView">
          View</v-btn> -->
        <span>Copy Link</span>
        <v-icon>mdi-link</v-icon>
        </v-btn>
            <v-list-item  @click="NoPropEmit('ToggleExportingtoPDFDialog')">
          <v-list-item-title  class="subtleoverline ">Export to PDF</v-list-item-title>
          <v-list-item-action>
          <v-icon class="actionicon" >mdi-file-pdf-box</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item :to="'/ClassifiedPreview/'+CurrentEntity.SingleName.split(' ').join('')+'/'+$route.params.id" v-if="CanModerateClassified"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Preview Classified
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-certificate-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
   
        <v-list-item :to="'/WikiPreview/'+CurrentEntity.SingleName.split(' ').join('')+'/'+$route.params.id" v-if="CanModerateWiki"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Preview Wiki
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-certificate-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
    
        <v-list-item @click="NoPropEmit('ActivateReceivingDialog')" v-if="CanReceiveGoods"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Receive Goods
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-warehouse</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="NoPropEmit('ActivateWarehouseMovementDialog')" v-if="MovementHistoryActive"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Movement History
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-timeline-clock-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
            <v-btn dark v-if="SalesInvoiceReady"
            color="success" @click="SendInvoiceonCommsChannel()">Send Invoice</v-btn> 
            <v-btn dark v-if="BAneedsOwner" :to="'/BillingAccount/'+RecordData.BillingAccountid"
            color="white" outlined ><v-icon>mdi-alert</v-icon>No Account Owner</v-btn>
            <v-btn dark v-if="SalesInvoiceReady && SalesInvoicecanhavePayment"
              color="success" @click="ActivateInvoicePaymentDialog()">Receive Payment</v-btn>  
                <v-list-item :to="'/Roadmap'" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && Record.RoadmapItem"  class="listoutline">
                <v-list-item-title  class="subtleoverline">
                On Roadmap
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-road-variant</v-icon>
                </v-list-item-action>
                </v-list-item>
                <v-list-item @click="NoPropEmit('ActivateForumDiscussionDialog')" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && !Record.Forum"  class="listoutline">
                <v-list-item-title  class="subtleoverline">
                Create Forum
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-publish</v-icon>
                </v-list-item-action>
                </v-list-item>
                <v-list-item :to="'/Forum/'+Record.Forum.id" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && Record.Forum"  class="listoutline">
                <v-list-item-title  class="subtleoverline">
                Visit Forum
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-publish</v-icon>
                </v-list-item-action>
                </v-list-item>
                <v-list-item @click="NoPropEmit('ToggleRATicketLog')" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && RAUser && !Record.RATicketid"  class="listoutline">
                <v-list-item-title  class="subtleoverline">
                Escalate to RapidApps
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-publish</v-icon>
                </v-list-item-action>
                </v-list-item>
                <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
                  <v-btn class="mx-3" color="success" @click="ActivateInvoicePaymentConfirmationDialog()" dark v-if="CanRecordPayment">Record Payment</v-btn>
                  <v-btn class="mx-3" color="success" @click="GetShortLivedURL(Record.Proof_of_Payment)"  dark v-if="InvoicePaidinFull">View POP</v-btn>
                  
                <v-icon v-if="$route.meta && $route.meta.SystemBuilder" style="background-color:green;" @click="UpdateSystem()" color="white" class="soloactionicon">mdi-content-save</v-icon>
                </v-layout>
        </v-card-text>
        <v-card-text v-if="CurrentEntity && !ShowEntityTab && MobileView">
        <div v-for="(tab,tabi) in ComputedTabs" :key="tab.itemObjKey">
         <v-list-item @click="ActivateEntityTab(tab,tabi)"
           style="padding-left:10px;"
            
            >
             <v-list-item-icon>
                    <v-icon>{{tab.Icon}}</v-icon><v-badge color="error" v-if="tab.notifications" overlap >
                        <span slot="badge"> {{tab.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-title>{{tab.Name}}
                    </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
        </div>
      </v-card-text>
       <component v-if="ShowEntityTab"
       :is="SingleDashboard" :CanEdit="CanEdit" :CanDelete="CanDelete" @StoreSingleDashView="StoreSingleDashView"  
      :ActiveSections="ComputedTabSections" :CanEditDashboard="userIsAdmin"
      :AllTabs="ComputedTabs" :ActiveEntityTabIndex="ActiveEntityTabIndex"
      :RelatedObj="RelatedObj" :CanGoBack="TableView" @GoBack="CancelActiveEntityTab"
        :System="System" :SystemEntities="SystemEntities" :CurrentEntity="CurrentEntity"
        :Record="Record" :AllFields="AllFields" :DefaultIcon="CurrentEntity.Table_Icon"
        :MyActivitiesArray="MyActivitiesArray" :UnreadNotifications="UnreadNotifications"
        @onPhotoUpload="onPhotoUpload" @SaveDocumentField="SaveDocumentField"
        @SaveRecord="SaveRecord" :RADB="RADB"
        />
  <!-- </div> -->
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
// import SingleDashboard from '@/components/Dashboards/SingleDashboard'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import NewLandingPage from '@/components/WebPages/NewLandingPage';
export default {
    props: ['System','SystemEntities','CurrentEntity','CanEdit','CanDelete','SinglePreview','SampleOnly','RouteID','SystemPhotoLibraries','EntityCollectionRef',
    'MyActivitiesArray','UnreadNotifications','Record','RADB'],
    components: {LandingPageSingle,NewLandingPage},
    data() {
        return {
          NewDashName: '',
          NewDashIcon: '',
            UploadingFileProgress: 0,
          ShowEntityTab: false,
            EntityTab: '',
            ActiveEntityTabIndex: -1,
            EditingSingleView: false,
            SingleBuildTabs: [],
            SubCollections: [],
            RecordData: {},
            RelatedObj: {},
            EntityReady: false,
            Tabs: [
              // {
              //   Name: 'Dashboard',
              //   Icon: 'mdi-monitor-dashboard',
              //   Sections: [
                 
              //   ]
              // },
              {
                Name: 'Record Info',
                Icon: 'mdi-format-list-text',
                Sections: [
                  {
                    Flat: true,
                    Transparent: true,
                    Tile: true,
                    Elevation: 0,
                    FlexXLRG:12,									
                    FlexLarge: 12,									
                    FlexMedium: 12,									
                    FlexSmall: 12,									
                    FlexXSmall: 12,
                    Name: 'Record Headers',
                    Rows: [
                      {
                        Name: 'Record Headers',
                        Flat: false,
                        Transparent: false,
                        Elevation: 4,
                          Columns: [
                            {                              
                              FlexXLRG:4,									
                              FlexLarge: 4,									
                              FlexMedium: 4,									
                              FlexSmall: 12,									
                              FlexXSmall: 12,
                              Name: 'Owner',
                              Type: 'Field Card',
                              Field: {id: 'Owner',FieldType: 'User Avatar'},
                            },
                            {                              
                              FlexXLRG:4,									
                              FlexLarge: 4,									
                              FlexMedium: 4,									
                              FlexSmall: 12,									
                              FlexXSmall: 12,
                              Name: 'Status',
                              Type: 'Field Card',
                              Field: {id: 'Status',FieldType: 'Option Toggle'},
                            },
                            {                              
                              FlexXLRG:4,									
                              FlexLarge: 4,									
                              FlexMedium: 4,									
                              FlexSmall: 12,									
                              FlexXSmall: 12,
                              Name: 'Create Date',
                              Type: 'Field Card',
                              Field: {id: 'Created_On',FieldType: 'Date Graphic'},
                            },
                            
                          ] 
                      }
                      
                      ]

                  }
                ]
              }
            ],
        }
    },	
    computed:{
      TableView(){
        return this.$route && this.$route.meta && this.$route.meta.TableBuilder
      },
      AppointmentsQuery(){
        return this.CurrentEntity ? db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Appointment').where('Regarding_Type', '==', this.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID) : ''
      },
      TasksQuery(){
        this.CurrentEntity ? 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Task').where('Regarding_Type', '==', this.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID) :
        ''
      },
      EmailsQuery(){
        this.CurrentEntity ? 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Email').where('Regarding_Type', '==', this.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID) :
        ''
      },
      NotesQuery(){
        this.CurrentEntity ? 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Note').where('Regarding_Type', '==', this.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID) :
        ''
      },
      PhoneCallsQuery(){
        this.CurrentEntity ? 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Phone Call').where('Regarding_Type', '==', this.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID) :
        ''
      },
      MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      InvoicePaidinFull(){
        return this.EntityisPurchaseInvoice && this.Record && typeof this.Record.Amount_Paid === 'number' && this.Record.Invoice_Total <= this.Record.Amount_Paid
      },
      CanRecordPayment(){
        return this.userisCreditorsClerk && this.EntityisPurchaseInvoice && !this.InvoicePaidinFull
      },
      userisCreditorsClerk(){
          return this.Record && this.Record.id ? this.IsAccountingClerk('Creditor',this.Record.Business_Unitid) :''
        },
      EntityisPurchaseInvoice(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Purchase Invoice'
      },
      EntityisPurchaseInvoice(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Purchase Invoice'
      },
      StoreItemPublisher(){
        return this.EntityStoreActive && this.UserWarehouseObj && this.UserWarehouseObj.Publish
      },
      WarehousesArray(){
        return this.$store.state.WarehousesArray
      },
      ActiveWarehouse(){
        return this.Record && this.Record.Warehouseid ? this.WarehousesArray.find(obj => obj.id === this.Record.Warehouseid) : ''
      },
      UserWarehouseObj(){
          return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.ActiveWarehouse && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
      },
      EntityisAppStore(){
        return this.CurrentEntityApp && this.CurrentEntityApp.Warehouse_Product_Master === this.CurrentEntity.id && this.CurrentEntityApp.Plugins.find(obj => obj.id === 'Online_Store')
      },
      CurrentEntityApp(){
              return this.CurrentEntity ? this.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid) : ''
      },
      EntityStoreActive(){
          return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.EntityisAppStore
        },
      BypassInboundWIP(){
        let appplugins = []
        if(this.CurrentEntityApp){
          appplugins = this.CurrentEntityApp.Plugins.map(plug => {return plug.id})
        }
        return this.CurrentEntityApp && !appplugins.includes('Warehouse_Inbound')
      },
      CanModerateClassified(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.UserisClassifiedModerator
      },
      UserisClassifiedModerator(){
        //for now admin only
        return this.userIsAdmin || this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === this.CurrentEntity.id.split('_').join(' ')+' Classified Listings')
      },
      CanReceiveGoods(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.CanEdit && this.BypassInboundWIP
      },
      MovementHistoryActive(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'
      },
      DataTableswithLookuptoCurrentEntity(){
            if(this.CurrentEntity){
                return this.ComputedEntities.map(entity => {
                    let entityobject = {
                        id: entity.id
                    }
                    let oncheck = entity.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
                    if(oncheck){
                        entityobject.RelationshipField = {
                            id: oncheck.id,
                            Name: oncheck.Name,
                            DisplayName: oncheck.DisplayName,
                            Type: oncheck.Type,
                            LookupFieldName: oncheck.LookupFieldName,
                            RelationshipName: entity.DisplayName+' ('+oncheck.DisplayName+')',
                            Entityid: entity.id
                        }
                        entityobject.RelationshipName = entity.DisplayName+' ('+oncheck.DisplayName+')'
                    }
                    return entityobject
                }).filter(entity => {
                    return entity.RelationshipField
                })
            }
            else{
                return []
            }
        },
      EntityName(){
            return this.CurrentEntity.DisplayName
        },
      BaseStorageRef(){
            return this.EntityName.split(' ').join('')+'/'
        },
      RecordStorageRef(){
        return this.BaseStorageRef+'/'+this.Record.id
      },
      EntityDataRef(){
            return this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.Record.id) : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id) : 
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id)
        },
      SingleDashboard(){
        return () => import(`@/components/Dashboards/SingleDashboard.vue`);
        },
      AllFields(){
        return this.CurrentEntity.AllFields
      },
      ComputedTabSections(){
        return this.EntityTab && this.EntityTab.Sections ?
        this.EntityTab.Sections 
        : [] 
      },
        ConfigEntityID(){
            return this.CurrentEntity.id
        },
        ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
        },
        TemplateView(){
            return this.ActiveMarketTemplate
        },
        TemplateEntities(){
            return this.$store.state.TemplateEntities
        },
        ComputedEntities(){
            return this.TemplateView ? this.TemplateEntities : this.SystemEntities
        },
        DynamicRecordData(){
      let vm = this
      let dataobj = {}
      //this should become OpsRecordID not routeparams, not work along with subcol entry view
      dataobj.id = this.RecordData.id
      //console.log('this.SystemEntities',this.SystemEntities)
      let entity = this.ComputedEntities.find(obj => obj.id === this.ConfigEntityID)
      //console.log('entity',entity,this.EntityReady)
      
      if(entity && this.EntityReady){
      if(entity.SubCollections){
        dataobj.SubCollections = this.SubCollections
        this.SubCollections.map(subcol => {
          if(subcol.id === 'BookedUsers'){
            ///console.log('subcol',subcol)
            dataobj.BookedUsers = subcol.Data.map(bookeduser => {
              let usermatch = this.UsersArray.find(obj => obj.id === bookeduser.id)              
              //console.log('usermatch',usermatch)
              let userobj = Object.assign({},usermatch)
              userobj.BookingObj = Object.assign({},bookeduser)
              if(bookeduser.BookingDate){
               userobj.BookingDate = bookeduser.BookingDate 
              }
              else if(bookeduser.Created_On){
                userobj.BookingDate = bookeduser.Created_On 
              }              
              return userobj
            })
          }
        })
      }
      if(this.RelatedDataTabs){
        //this.EntityReady = false
        dataobj.RelatedTables = this.RecordData.RelatedTables
        //this.EntityReady = true
        //console.log('dataobj.RelatedTables',dataobj.RelatedTables)
      }
      //console.log('dataobj.RelatedTables',dataobj.RelatedTables,this.RelatedDataTabs,this.RecordData.RelatedTables)
      entity.AllFields.map(field => {
        //console.log('field.Name ',field.Name,this[field.Name])
        if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
          field.FieldBreakdown.map(breakdown => {
            dataobj[breakdown.Name] = this.RecordData[breakdown.Name]
          })
        }
        else if(field.Type === 'Date' && this.RecordData[field.Name] && this.RecordData[field.Name].toDate){
          let dateobject = this.RecordData[field.Name].toDate()
          dataobj[field.Name] = format(dateobject,'yyyy-MM-dd')
          if(field.HasTimeInput){
            let hours = dateobject.getHours()
            let mins = dateobject.getMinutes()
            if(hours === 0){
              hours = '00'
            }
            if(mins === 0){
              mins = '00'
            }
            field.TimeResponse = hours+':'+mins
            dataobj[field.Name+'TimeResponse'] = field.TimeResponse
          }
        }
        else{
          if(this[field.Name]){
            dataobj[field.Name] = this[field.Name]
          }
          else if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]           
            if(field.Type === 'Option Set' && field.HasDeepHierarchy){
               let primopt = field.Options.find(obj => obj.ID === this.RecordData[field.Name].ID)
              if(primopt && field.Levels && field.Levels[1] && this.RecordData[field.Levels[1].FieldName]){   
                dataobj[field.Levels[1].FieldName] = this.RecordData[field.Levels[1].FieldName] 
                let secopt = primopt.Options.find(obj => obj.ID === this.RecordData[field.Levels[1].FieldName].ID)          
                if(secopt && field.Levels && field.Levels[2] && this.RecordData[field.Levels[2].FieldName]){
                  dataobj[field.Levels[2].FieldName] = this.RecordData[field.Levels[2].FieldName]
                  dataobj[field.Levels[2].FieldName].Options = secopt.Options
                  //console.log(field)
                }
              }
            }
          }
          
        }
        // if(this[field.Name]){
        //  dataobj[field.Name] = this[field.Name] 
        // }        
      })
      if(this.FeatureNavList){
        this.FeatureNavList.FeatureList.map(field => {
          if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]
          }
        })
      }
      
      //console.log('dataobj',entity,this.SubCollections,dataobj)
      if(this.RecordData.Order_Progress){
        dataobj.Order_Progress = this.RecordData.Order_Progress
      }
      return dataobj    
      }
      
    },
        ComputedTabs(){
          return this.CurrentEntity.UserFieldViews ? this.Tabs.filter(tab => {
                  let pass = this.userIsAdmin
                  this.CurrentEntity.UserFieldViews.map(fieldview => {
                  pass = !this.Record || this.Record && !this.Record[fieldview.Fieldid] || 
                  this.Record && this.Record[fieldview.Fieldid] && this.Record && this.Record[fieldview.Fieldid].id
                   && this.Record && this.Record[fieldview.Fieldid].id !== this.userLoggedIn.id || 
                   this.Record && this.Record[fieldview.Fieldid] && this.Record && this.Record[fieldview.Fieldid].id
                   && this.Record && this.Record[fieldview.Fieldid].id === this.userLoggedIn.id && fieldview.Tabs.includes(tab.Name) 
                  })
                  return pass
                }) : this.Tabs
        },
        ComputedSingleBuildTabs(){
            let AllFields = this.CurrentEntity.AllFields
      return this.SingleBuildTabs.map(tab => {
        let elementsprop = ''
        if(tab.Elements){
          elementsprop = 'Elements'
        }
        else{
          elementsprop = 'Sections'
        }
        //alert(elementsprop)
        if(tab[elementsprop]){
          tab[elementsprop] = tab[elementsprop].map(section => {
            //console.log(section)
          if(section.Fields){
            section.Fields = section.Fields.map(field => {
             
              let fieldobj = AllFields.find(obj => obj.id === field.Name)
              //console.log('AllFields,field.Name',AllFields,field.Name,fieldobj)
              if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID !== 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup',fieldobj.RelatedRecord.split(' ').join('')+'Array',this.$store.state[fieldobj.RelatedRecord+'Array'],this.$store.state)
                  fieldobj.Array = this.$store.state[fieldobj.RelatedRecord.split(' ').join('')+'Array']
                  if(fieldobj.IsMultiple){
                    fieldobj.Array = fieldobj.Array.map(item => {                      
                            let itemobj = {
                            [fieldobj.LookupFieldName]: item[fieldobj.LookupFieldName],
                            id: item.id
                            }
                            return itemobj
                        })
                    }
                }
                else if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID === 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup','Users',this.$store.state['Users'],this.$store.state)
                  fieldobj.Array = this.$store.state['Users']
                }
                if(!fieldobj){
                  console.log('missing field ',field)
                  fieldobj = field
                }
              return fieldobj
            })
          }
          //prepare updated permissionname Props just prior to GetCabinetDocuments
          //CustomRolesDefault
          //console.log(section.Name,section.DataViewName,section,this.$store.state)
          if(section.DataViewName === 'Data Single Document Register' || section.DataViewName === 'Data Single Gallery'){
          //  this.CustomRolesDefault.map(defrole => {
          //    console.log(defrole.Prop,section.Name,section[defrole.Prop])
          //  })
            if(section.CreatePermission === 'Inherited'){
              section.CanCreate = this.CanEdit
            }
            else if(section.CreatePermission === 'Custom Role'){
              section.CustomCreateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanCreate = true
                }
              })
            }
            else if(section.CreatePermission === 'Admin'){
              section.CanCreate = this.userIsAdmin
            }
            if(section.GetPermission === 'Inherited'){
              section.CanGet = true
            }
            else if(section.GetPermission === 'Custom Role'){
              section.CustomGetRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanGet = true
                }
              })
            }
            else if(section.GetPermission === 'Admin'){
              section.CanGet = this.userIsAdmin
            }
            if(section.ListPermission === 'Inherited'){
              section.CanList = true
            }
            else if(section.ListPermission === 'Custom Role'){
              section.CustomListRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanList = true
                }
              })
            }
            else if(section.ListPermission === 'Admin'){
              section.CanList = this.userIsAdmin
            }
            if(section.UpdatePermission === 'Inherited'){
              section.CanEdit = this.CanEdit
            }
            else if(section.UpdatePermission === 'Custom Role'){
              section.CustomUpdateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanEdit = true
                }
              })
            }
            else if(section.UpdatePermission === 'Admin'){
              section.CanEdit = this.userIsAdmin
            }
            if(section.DeletePermission === 'Inherited'){
              if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Custom Roles'){
                section.CanDelete = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'CanDelete']
              }
              else if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
               if(this.deleteAll){
                  section.CanCreate =  true
                }
                else if(this.deleteUnitdown){
                   if(this.userBUChildren.includes(this.DynamicRecordData.Business_Unitid)){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteUserUnit){
                  if(this.DynamicRecordData.Business_Unitid === this.userBUID){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteOwner){
                  if(this.DynamicRecordData.Ownerid === this.UserRecord.id){
                    section.CanCreate = true  
                    }
                }
              }
            }
            else if(section.DeletePermission === 'Custom Role'){
              section.CustomDeleteRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanDelete = true
                }
              })
            }
            else if(section.DeletePermission === 'Admin'){
              section.CanDelete = this.userIsAdmin
            }
          }
          else if(section.DataViewName === 'Data Single SubCollection'){
            //console.log(section)
            let subcol = this.SubCollections.find(obj => obj.id === section.Entityid)
            section.CanCreate = this.CanEdit
            section.CanDelete = this.CanDelete
            section.CanEdit = this.CanEdit

          }
          // if(this.SelectedElement && this.SelectedElement.DisplayName !== section.DisplayName){
          //   section.ShowComponents = false
          // }
          return section
        })
        // .filter(elmnt => {
        //   return elmnt.ShowComponents
        // })
        }
         //tab.ShowComponents = false
        return tab
      }).filter(tab => {
        return tab.Elements.length > 0
      })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
      ComputedTabs: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue && newvalue){ 
                    this.PrepRender()
              }
            },deep: true
        },
        CurrentEntity: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue && newvalue){ 
                    this.PrepRender()
              }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.CurrentEntity){
        this.PrepRender()
      }
    },
    methods:{
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      CopyRecordLink(item){
        let route = '/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+item.id
        let successmsg = 'Copied link for '+item[this.CurrentEntity.Primary_Field_Name]
        let payload = {route: route,successmsg: successmsg}
        console.log(payload)
        this.$store.dispatch('CopyRouteToClipBoard',payload)
      },
      AddNewDashboardTab(){
        let newdash = {
          Name: this.NewDashName,
          Icon: this.NewDashIcon,
          Sections: []
        }
        this.Tabs.push(newdash)
      },
      IsAccountingClerk(accountingclerkprop,BUid){
        return this.userLoggedIn.BusinessAccounting && BUid && this.userLoggedIn.BusinessAccounting[BUid] && this.userLoggedIn.BusinessAccounting[BUid][accountingclerkprop]
      },
      GetShortLivedURL(item){
      //console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
       let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },   
      SaveDocumentField(payload){
        //console.log(payload)
        let file = payload.file
        let fieldname = payload.fieldname
        let vm = this
        let filename = file.name
        let storepath = this.RecordStorageRef+'/'+fieldname+'/'+filename+'/'+new Date()
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        
        vm.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+filename);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingFile = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
              });
            });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            vm.EntityDataRef.update({
                    [fieldname]: UploadObject,
            })
            // console.log('UploadObject',UploadObject)
            //   this.$emit('UpdateEditableField',fieldname,UploadObject,this.AdditionalSaveMethod,this.Record)
              vm.$emit('ActivateUploadingSingleFileNotification',false)
          })
          }, 1000);
      },   
        onPhotoUpload(payload) {
          let filename = payload.filename
          let file = payload.file
          let field = payload.field
          let storepath = this.RecordStorageRef+'/'+field.id+'/'+file.name+'/'+new Date()
        let vm = this
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,file,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              ThumbURL: thumbresult,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            vm.EntityDataRef.update({
                    [field.Name]: UploadObject,
                    [field.Name+'ThumbURL']: UploadObject.ThumbURL
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added '+field.DisplayName+' to Record',
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            // delete field.localURL
            // delete field.file
                })
         })
      },
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
                  type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
      SaveRecord(payload){
        //console.log(this.$store.state.ActiveSuiteid,this.$store.state,this.EntityDataRef)
       // console.log('payload',payload)
        let record = payload.Record
        let fields = payload.Fields.concat(this.CurrentEntity.AllFields.find(obj => obj.Primary))
        let ref = this.EntityDataRef
        let updateobj = {}
        updateobj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        updateobj.Modified_Byid = this.userLoggedIn.id
        updateobj.Modified_On = new Date()
        fields.map(field => {
          if(typeof record[field.id] !== 'undefined'){
            updateobj[field.id] = record[field.id]
            if(field.Type === 'Lookup'){
              updateobj[field.id+'id'] = updateobj[field.id].id
            }
            if(field.Type === 'Option Set' && field.HasDeepHierarchy){
               let primopt = field.Options.find(obj => obj.ID === record[field.Name].ID)
              if(primopt && field.Levels && field.Levels[1] && record[field.Levels[1].FieldName]){   
                updateobj[field.Levels[1].FieldName] = record[field.Levels[1].FieldName] 
                let secopt = primopt.Options.find(obj => obj.ID === record[field.Levels[1].FieldName].ID)          
                if(secopt && field.Levels && field.Levels[2] && record[field.Levels[2].FieldName]){
                  updateobj[field.Levels[2].FieldName] = record[field.Levels[2].FieldName]
                  updateobj[field.Levels[2].FieldName].Options = secopt.Options
                  //console.log(field)
                }
              }
            } 
            if(field.Type === 'Checklist'){
              updateobj[field.id+'Date'] = new Date()
            }
            //DATES AND "TIMERSPONSE"
            //PHYSICOL ADDRESS TYPE FIELDS....ETC.
            //if owner, see DashUserAvatar, this.AssignBU() function, triggering notificaiton to owner as well etc etcetc
            //and a bunch of other stuff, seirously but okay
          }
        })
        ref.update(updateobj)
      },
      StoreSingleDashView(receivedtabs){
        //console.log('alltabs',receivedtabs)
        let delprops = ['CanCreate','CanGet','CanList','CanEdit','CanDelete','FieldObj','FieldObjects']
            let ref = this.CurrentEntity && this.CurrentEntity.id ? this.EntityCollectionRef : 
            this.IsPrimarySite ? RADB.collection('systems').doc(this.$store.state.ActiveSuiteid).collection('apps').doc(this.ActiveSuiteApp.id) :
            db.collection('systems').doc(this.$store.state.ActiveSuiteid)
            //console.log('ref',ref)
            let tabs = receivedtabs.map(tabobj => {
              let tab = Object.assign({},tabobj)
              tab.Sections = tab.Sections.map(sectobj => {
                let sect = Object.assign({},sectobj)
                sect.Rows = sect.Rows.map(rowobj => {
                    let row = Object.assign({},rowobj)
                    row.Columns = row.Columns.map(colobj => {
                        let col = Object.assign({},colobj)
                        delprops.map(prp => {
                            delete col[prp]
                        })
                        return col
                    })
                    delprops.map(prp => {
                            delete row[prp]
                        })
                    return row
                })
                
                delprops.map(prp => {
                        delete sect[prp]
                    })
                return sect
            })
            delprops.map(prp => {
                        delete tab[prp]
                    })
                return tab
            })
            //console.log('ref',ref,'tabs',tabs)
            ref.update({
                SingleDashTabs: tabs
            })
      },
      CancelActiveEntityTab(){        
        this.EntityTab = ''
        this.ActiveEntityTabIndex = -1
        this.ShowEntityTab = false
        this.ScrolltoTop()
        if(this.TableView){
          this.$emit('ReduceQuickStep')
        }
      },
      ActivateEntityTab(tab,tabi){
        //console.log(tab,tabi)
        this.EntityTab = ''
        this.ActiveEntityTabIndex = -1
        this.ShowEntityTab = false
        this.ScrolltoTop()
        setTimeout(() => {
          this.ActiveEntityTabIndex = tabi
          this.EntityTab = Object.assign({},tab)
          
        this.ShowEntityTab = true
         //console.log(this.EntityTab)
        }, 10);
        
      },
        PrepRender(){
           //alert('PrepRender')
          //  console.log('this.CurrentEntity',this.CurrentEntity)
            this.SingleBuildTabs = this.CurrentEntity.SingleBuildTabs ? this.CurrentEntity.SingleBuildTabs : []
                    this.SubCollections = this.CurrentEntity.SubCollections ? this.CurrentEntity.SubCollections : []
                    this.StatusField = this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
            let Status = this.StatusField.DefaultOption
            let StatusReason = this.StatusField.DefaultLevel2Option
            if(this.SampleOnly){
            this.RecordData = {
                Created_On: new Date(),   
                Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Created_Byid: this.userLoggedIn.id,
                Modified_On: new Date(),   
                Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Modified_Byid: this.userLoggedIn.id,
                Business_Unit: {id: 'Head_Office',Name: 'Head Office'},
                Business_Unitid: 'Head_Office',
                Owner: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Ownerid: this.userLoggedIn.id,
                Status: Status,
                Status_Reason: StatusReason,
                id: process.env.VUE_APP_RA_SYSTEM_ID
            }
            this.RelatedObj = {id: this.RouteID, [this.CurrentEntity.Primary_Field_Name]: this.RecordData[this.CurrentEntity.Primary_Field_Name],identifier: this.CurrentEntity.Primary_Field_Name,collection: this.CurrentEntity.id.split('_').join('').toLowerCase(),Link: this.CurrentEntity.SingleName.split(' ').join('') }
            this.EntityReady = true
            //console.log('this.EntityReady',this.EntityReady)
            }
            if(this.CurrentEntity.SingleDashTabs){
              this.Tabs = this.CurrentEntity.SingleDashTabs
              //here we sfilter by UserFieldViews
              this.ActivateEntityTab(this.ComputedTabs[0],0)
             
            }
            //console.log(this.Tabs)
            //this.PrepRelatedTables()
            
        },
        PrepRelatedTables(){
          //console.log('this.DataTableswithLookuptoCurrentEntity',this.DataTableswithLookuptoCurrentEntity)
          this.DataTableswithLookuptoCurrentEntity.map(ent => {
            
           let relatedtable = {
                  id: ent.id,
                  TableName: ent.DisplayName,
                  StoreQuery: tablename+'Query',
                  StoreArrayName: tablename+'Array',
                  StoreData: []
                }                
                
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  //console.log('this.$store.state[storearray]',this.$store.state[storearray],this.RouteID)
                  relatedtable.StoreData = this.$store.state[storearray].filter(record => {
                    return record[ent.RelationshipField.id+'id'] === this.Record.id
                  })
                }
                else if(this.$store.state[storequery]){
                  this.GetRelatedData(relatedtable,this.$store.state[storequery],ent.RelationshipField.id)
                  // let storepayload = {
                  //         query: this.$store.state[storequery],
                  //         arrayname: storearray
                  //       }
                  //       console.log(storepayload,vm.$store.state,storearray)
                  //       this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                          // relatedtable.StoreData = vm.$store.state[storearray]
                        // })
                } 
          })
          
        },
        GetRelatedData(table,query,fieldid){        
        let fieldvalue = fieldid+'id'
          //alert(this.RouteID)
          //console.log('query',query)
          if(!Array.isArray(query)){
          query.where(fieldvalue,'==',this.RouteID).onSnapshot(res => {
            const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  table.StoreData.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
              })
          })
          }
          else{
            // let length = query.length
            // query.map((singlequery,singlequeryindex) => {
              query[0].where(fieldvalue,'==',this.RouteID).onSnapshot(res => {
                const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      table.StoreData.push({
                        ...change.doc.data(),
                        id: change.doc.id
                      })
                    }
                  })
              // })
            })
          }
      },
    }
}
</script>

<style>

</style>



