
<template>
<div :style="tab.Size ? 'height: '+tab.Size+'vh;' : ''">
<v-row style="height: 100%;"v-if="!tab.Type || tab.Type === 'Text/IMG'"
          :align="tab.Alignment.includes('flex-') ? tab.Alignment.split('flex-').join('') : tab.Alignment"
          :justify="tab.Justify.split('justify-').join('')"
        >
        <div :style="ChildStyle(tab,'HTML Box')"
        :class="TextBoxClass(tab)">    
          <HeroHTMLBox 
          :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
          </div>
          <div :style="ChildStyle(tab,'Image')"
          :class="IMGBoxClass(tab)">
          <HeroImage :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
        </div>
        <HeroFooter v-if="tab.HasFooter" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
    <div :style="tab.SocialButtonType === 'Bottom Right' ? 'position: absolute;right: 0px;bottom: 0px;' : 'position: absolute;left: 0px;bottom: 0px;'"
    v-if="tab.HasSocialButtons && tab.SocialButtonsObject && !tab.SocialButtonType || 
    tab.HasSocialButtons && tab.SocialButtonsObject && tab.SocialButtonType.includes('Bottom')">
    <!-- Follow Us On -->
    <v-btn dark v-for="btn in tab.SocialMediaButtons" :key="btn.itemObjKey" fab icon>
      <v-icon dark>
        {{btn.icon}}
      </v-icon>
    </v-btn>
    </div>
  </v-row>
  <v-row style="height: 100%;" v-if="tab.Type === 'IMG/Text'"
          :align="tab.Alignment.includes('flex-') ? tab.Alignment.split('flex-').join('') : tab.Alignment"
          :justify="tab.Justify.split('justify-').join('')"
        >
        <div :style="ChildStyle(tab,'Image')"
          :class="IMGBoxClass(tab)">   
          <HeroImage :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
          </div>
          <div :style="ChildStyle(tab,'HTML Box')"
        :class="TextBoxClass(tab)"> 
          <HeroHTMLBox 
          :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
        </div>
        <HeroFooter v-if="tab.HasFooter" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
    <div :style="tab.SocialButtonType === 'Bottom Right' ? 'position: absolute;right: 0px;bottom: 0px;' : 'position: absolute;left: 0px;bottom: 0px;'"
    v-if="tab.HasSocialButtons && tab.SocialButtonsObject && !tab.SocialButtonType || 
    tab.HasSocialButtons && tab.SocialButtonsObject && tab.SocialButtonType.includes('Bottom')">
    <!-- Follow Us On -->
    <v-btn dark v-for="btn in tab.SocialMediaButtons" :key="btn.itemObjKey" fab icon>
      <v-icon dark>
        {{btn.icon}}
      </v-icon>
    </v-btn>
    </div>
  </v-row>
<v-row style="height: 100%;"v-if="tab.Type === 'Text Only'"
          :align="tab.Alignment.includes('flex-') ? tab.Alignment.split('flex-').join('') : tab.Alignment"
          :justify="tab.Justify.split('justify-').join('')"
        >
        <div :style="ChildStyle(tab,'HTML Box')"
        :class="TextBoxClass(tab)">    
          <HeroHTMLBox 
          :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
          </div>
        <HeroFooter v-if="tab.HasFooter" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
            :System="System"
          />
    <div :style="tab.SocialButtonType === 'Bottom Right' ? 'position: absolute;right: 0px;bottom: 0px;' : 'position: absolute;left: 0px;bottom: 0px;'"
    v-if="tab.HasSocialButtons && tab.SocialButtonsObject && !tab.SocialButtonType || 
    tab.HasSocialButtons && tab.SocialButtonsObject && tab.SocialButtonType.includes('Bottom')">
    <!-- Follow Us On -->
    <v-btn :href="tab.SocialButtonsObject[btn.title].link" target="new" dark v-for="btn in tab.SocialMediaButtons" :key="btn.itemObjKey" fab icon>
     <v-icon  :style="tab.SMBIconColor ? 'color: '+tab.SMBIconColor.hexa+';' : ''" dark>
        {{tab.SocialButtonsObject[btn.title].icon}}
      </v-icon>
    </v-btn>
    </div>
  </v-row>
<v-list class="transparent"
 :style="tab.SocialButtonType === 'Menu Right' ? 'position: absolute;right: 0px;bottom: 50%;' : 
 'position: absolute;left: 0px;bottom: 50%;'"
  v-if="tab.HasSocialButtons && tab.SocialButtonsObject && tab.SocialButtonType.includes('Menu')">
  <v-list-item v-for="(btn,btni) in tab.SocialMediaButtons" :key="btn.itemObjKey" style="padding: 0px;">
   <v-btn dark width="47" height="47" 
   :style="SMBStyle(tab,btni)"
   :href="tab.SocialButtonsObject[btn.title].link" target="new"  fab tile>
  <v-icon :style="tab.SMBIconColor ? 'color: '+tab.SMBIconColor.hexa+';' : ''" dark>
    {{tab.SocialButtonsObject[btn.title].icon}}
  </v-icon>
</v-btn>
  </v-list-item>
</v-list> 
 </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import HeroHTMLBox from '@/components/WebPages/RenderComponents/HeroBanner/HeroHTMLBox';
import HeroImage from '@/components/WebPages/RenderComponents/HeroBanner/HeroImage';
import HeroFooter from '@/components/WebPages/RenderComponents/HeroBanner/HeroFooter/HeroFooter';
export default {
    props: ['System','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {
      HeroHTMLBox,
      HeroImage,
      HeroFooter
        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        TitleLines(){
            return this.tab.TitleLines ? 
            this.tab.TitleLines :
            []
        },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
      TextBoxClass(tab){
        let xl = 6
        let lg = 6
        let md = 6
        if(typeof tab.TextFlexXLRG !== 'undefined'){
          xl = tab.TextFlexXLRG   
        }
        if(typeof tab.TextFlexLarge !== 'undefined'){
          lg = tab.TextFlexLarge   
        }
        if(typeof tab.TextFlexMedium !== 'undefined'){
          md = tab.TextFlexMedium   
        }
        //console.log('xl'+xl+' lg'+lg+' md'+md+' sm12 xs12')
        return 'flex xl'+xl+' lg'+lg+' md'+md+' sm12 xs12'
      },
      IMGBoxClass(tab){
        let xl = 6
        let lg = 6
        let md = 6
        if(typeof tab.IMGFlexXLRG !== 'undefined'){
          xl = tab.IMGFlexXLRG   
        }
        if(typeof tab.IMGFlexLarge !== 'undefined'){
          lg = tab.IMGFlexLarge   
        }
        if(typeof tab.IMGFlexMedium !== 'undefined'){
          md = tab.IMGFlexMedium   
        }
        //console.log('xl'+xl+' lg'+lg+' md'+md+' sm12 xs12')
        return 'flex xl'+xl+' lg'+lg+' md'+md+' sm12 xs12'
      },
      SMBStyle(tab,btni){
        let style = tab.SocialButtonType === 'Menu Right' ? 
        btni === 0 ? 'border-radius: 15px 1px 1px 1px;' : btni-1+2 === tab.SocialMediaButtons.length ? 'border-radius: 1px 1px 1px 15px;' : '' : 
        btni === 0 ? 'border-radius: 1px 15px 1px 1px;' : btni-1+2 === tab.SocialMediaButtons.length ? 'border-radius: 1px 1px 15px 1px;' : ''
        if(tab.SMBColor){
          style = style+'background-color: '+tab.SMBColor.hexa+';'
        }
        return style
      },
      ChildStyle(tab,type){
        let style = type === 'HTML Box' ? 
        'padding-left: 20px;padding-right: 25px;'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 
        this.CheckIMGFlexAlign(tab)
        if(type === 'HTML Box' && tab.TextBoxGradient){
          style = style+tab.TextBoxGradient
        }
        return style
      },
      CheckIMGFlexAlign(tab){
        let style = ''
          if(tab.IMGFlexAlign){
            if(tab.IMGFlexAlign === 'Start'){
              style = style+'align-self: flex-start;'
            }
            else if(tab.IMGFlexAlign === 'Center'){
              style = style+'align-self: center;'
            }
            else if(tab.IMGFlexAlign === 'End'){
              style = style+'align-self: self-end;'
            }
          }
        return style
      },
      ActivateElementConfigComp(compname,tab,i){
        //console.log(compname,tab,i)
            this.$store.commit('setActiveElmntConfigComp',compname)
            this.OpenDialog(tab,i)
        },
        BreakStyle(tab,titlecon,brk){
            let style = 'padding-left: '+brk.PaddingLeft+'rem;line-height: 1em;font-size: '+brk.FontSize+'rem;font-weight: '+brk.FontWeight+';'
            if(brk.Color.hexa){
              style = style+'color: '+brk.Color.hexa+';'
            }
            else{
              style = style+'color: '+brk.Color+';'
            }
            if(brk.FontFamily){
                style = style+'font-family: '+brk.FontFamily+';'
            }
            return style
        },
        TitleStyle(tab,titlecon){
            let style = 'padding: 0px;place-items: '+titlecon.Place+';margin-left: '+titlecon.Margin+'%;'
            if(titlecon.Shadow){
                style = style+'text-shadow: 4px 4px 4px #00000088;'
            }
            return style
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(tabHeaderFontFamily){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+tabHeaderFontFamily+';'  
               }
               else{
                return 'font-family: '+tabHeaderFontFamily+';'
               }
               
           }
           else{
            return 'font-family: '+this.PageHeadersFont+';'
           }
       },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      AvatarStyle(tab){
        if(typeof tab.IMGHeight !== 'undefined'){
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:'+tab.IMGHeight+'px;' : 'height:'+tab.IMGHeight+'px;width: '+tab.IMGHeight+'px;min0width:'+tab.IMGHeight+'px;'
        }
        else{
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:150px;' : 'height:150px;width: 150px;min0width:150px;'
        }
      },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      ActionButtonOpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog){
        this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog)
      },
      DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
     OpenDialog(tab,tabindex){
        //console.log('opem dial')
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },

    }    
}
</script>

<style>
</style>