
<template>
<v-card flat class="transparent" width="100%" height="100%" style="padding-bottom:60px;">
<!-- <v-layout column class="createnotice-container" v-if="userisContentAdmin">
    <v-icon @click="AddSocialItemtoNotice()" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
  </v-layout> -->
<v-layout column class="meetupqr-container">
    <v-icon @click="DownloadQR('https://ignite-youth.firebaseapp.com',RoutePath,RouteID,title+' QR Code')" class="soloactionicon white" x-large color="black">mdi-qrcode</v-icon>
  </v-layout>
  <v-layout column class="meetupcheckedin-container" v-if="UserIsModerator">
    <v-dialog v-if="CheckedInUsers.length > 0" max-width="800" v-model="WhoCheckedInDialog">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="soloactionicon blue" x-large color="white">mdi-account-question</v-icon>
      </template>
      <v-card>
       
        <v-card-text>
          <v-toolbar  rounded src="@/assets/RABaseBG.jpeg" elevation="12" color="primary white--text">
        <v-toolbar-title>
          Checked In Users
        </v-toolbar-title>
        <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field dark
            v-model="checkinsearch"            
            label="Search"
            class="mediumoverline"
            single-line
            hide-details
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-layout row class="justify-center" style="padding-top: 20px;">
          <v-flex  xs12 sm6 md4 lg3 style="padding:10px;" v-for="member in CheckedInUsersSearched" :key="member.id">
            <v-card  max-width="300px" >
            <v-layout class="justify-center">
          
           <v-avatar
              color="grey"
              size="80"                     
            >
            <v-img v-if="member.UserIMG" :src="member.UserIMG"></v-img>
            <v-img v-if="!member.UserIMG"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>         
          </v-layout>
          <v-layout class="justify-center mx-3">
              <h4 class="font-weight-thin black--text centertext my-1"><router-link :to="'/SiteMember/'+member.id" target="_blank">{{member.Full_Name}}</router-link></h4>
          </v-layout>
          <v-layout class="justify-center mx-3">
          <span class="caption">
              Checked in at {{member.Time.toDate().toLocaleTimeString('en-US')}}
              </span>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="ConfirmCheckin(member)" dark color="success" v-if="!member.Confirmed">
              Confirm</v-btn>
              <span v-if="member.Confirmed" class="caption">Confirmed by {{member.Confirmed_By.Full_Name}}</span>
          </v-card-actions>
            </v-card>
          </v-flex>
      </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
<SocialSharing v-if="userLoggedIn && !$store.state.TemplateView" :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="userLoggedIn" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>

    <v-parallax
            height="150"
            :src="GroupData.logo"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Meetup.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-layout class="justify-center" v-if="!EditingMeetup">
              <v-card class="my-3 mx-3" hover width="90%" >
                <v-card-title v-if="FullArticleData.PlannedStatus" :class="PlannedStatusClass">
                           <v-icon large color="white">{{PlannedStatusIcon}}</v-icon>{{FullArticleData.PlannedStatus}}</v-card-title>
                  <v-card-actions style="font-size:10px">
                  <v-chip small color="secondary" class="white--text"  v-if="UserRecord.id">
                    {{author}}
                  </v-chip>
                  
                  <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator"
                                 v-if="UserRecord.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="pop" class="white--text" v-on="on" v-if="UserRecord.id">
                              {{PubStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <div  v-for="status in PublishStatusOptions" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator"
                                 v-if="UserRecord.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateStatus(status.Name)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>
                  <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator"
                                 v-if="UserRecord.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="blue" class="white--text" v-on="on" v-if="UserRecord.id">
                             <v-icon>mdi-calendar-question</v-icon>{{FullArticleData.PlannedStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <v-list-item class="green" @click="CheckPlannedStatus('Still Happening')">
                            Still Happening
                          </v-list-item>
                          <v-list-item class="warning" @click="CheckPlannedStatus('On Hold')">
                            On Hold
                          </v-list-item>
                          <v-list-item  class="error" @click="CheckPlannedStatus('Cancelled')">
                            Cancelled
                          </v-list-item>
                        </v-list>
                  </v-menu>
                 <v-spacer></v-spacer>
                  <span  v-if="createdon && createdon.toDate && UserRecord">{{createdon.toDate()}}</span>
                  <v-icon @click="EditItem()" v-if="UserIsModerator || userIsAdmin || UserisTemplateOwner">mdi-pencil</v-icon>
                </v-card-actions>
                
                <v-img
                  height="350px"
                  v-bind:src="image"
                  v-if="image"
                ></v-img>
                
                        <v-card-title class="headline">
                           {{ title }}</v-card-title>
                           
                           <div class="mx-2" >
                             <v-chip color="error">{{MeetupStatus}}</v-chip>
                           <v-chip-group v-if="FullArticleData.start">
                             <span><v-chip color="success">Starting</v-chip>{{ FullArticleData.start.toDate() }}</span>
                           </v-chip-group>
                           <v-chip-group v-if="FullArticleData.end">
                             <span><v-chip color="warning">Ending</v-chip>{{ FullArticleData.end.toDate() }}</span>
                           </v-chip-group>
                           </div>
                        
                            
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{meetuppubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="meetupcontent">
                </v-card-text>
                <v-card-actions>
                  
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <!-- <v-spacer></v-spacer>
                  <v-btn @click="ShareMeetup()">Share<v-icon>mdi-share-circle</v-icon></v-btn> -->
                </v-card-actions>
                <v-card-actions v-if="PubStatus !== 'Draft' && userLoggedIn.CheckedInMeetupid !== RouteID && UsersCanCheckIn">
                  <!-- Only if NOT checkin already, and only if no less than 30mins prior start time -->
                  <v-spacer></v-spacer><v-btn plain @click="CheckUserInforMeetup()">Check In<v-icon>mdi-account-check</v-icon></v-btn>
                </v-card-actions>
                <v-card-actions v-if="userLoggedIn.CheckedInMeetupid === RouteID">
                  <!-- Only if NOT checkin already, and only if no less than 30mins prior start time -->
                  <v-spacer></v-spacer><v-btn color="green" plain>Checked In<v-icon>mdi-account-check</v-icon></v-btn>
                </v-card-actions>
                
                <!-- <v-card-actions>
                  <TaggingComponent :AppisDarkModeCard="AppisDarkModeCard" :UsercanTag="UserIsModerator" :record="FullArticleData" />
                </v-card-actions> -->
                <SocialSitePosts style="padding-bottom:50px;" v-if="SocialSitePostsActive" :dark="AppisDarkMode" class="mx-3" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" 
                  :UserRecord="UserRecord" :UserorGroupID="UserRecord.id" :UserView="false" :GalleryTimeline="true" :Slice="10" />
                 <SocialCommentsandLikes v-if="SocialCommentsActive"  :panel="0"
            :PathName="'Meetup'"  :GroupID="$route.name === 'GroupMeetup' ? $route.params.id : ''" :CanSocial="UserCanSocialTopic" :notificationitem="'Meetup'" :RelatedType="'Site Meetup'" :SocialItem="FullArticleData" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
              </v-card>
              </v-layout>
      <v-layout class="justify-center" v-if="EditingMeetup">
        <EditMeetup :title="title" :pubdatastring="pubdatastring" :CurrentModerators="Moderators" :GroupData="GroupData"
        @StopEdit="StopEdit" :CurrentImage="image" :FullArticleData="FullArticleData" :GuestsIncluded="GuestsIncluded"
        :SiteModerators="SiteModerators"  :caption="caption" @ActivateProcessing="ActivateProcessing"
        :CurrentImageShy="ImageShy" :Currentmeetupcontent="meetupcontent"/>
      </v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import EditMeetup from '@/components/SuitePlugins/Meetups/Edit/Single'
import TaggingComponent from '@/components/SocialNetwork/TaggingComponent';
import SocialSharing from '@/components/SocialNetwork/SocialSharing'
import SocialCommentsandLikes from '@/components/SocialNetwork/SocialCommentsandLikes';
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';

export default {
    props: ['System','SiteModerators','FriendsList'],
      components: {
        EditMeetup,
        SocialSharing,
        SocialCommentsandLikes,
        SocialSitePosts,
        TaggingComponent
  },
    data() {
        return {
          GuestsIncluded: false,
          PublishStatusOptions: [
            {
              Name: 'Draft',
              Class: 'green',
              Warning: 'Unpublish the item?'
            },
            {
              Name: 'Published (Internal)',
              Class: 'warning',
              Warning: 'Make the item avialable for viewing by all Users?'
            },
            {
              Name: 'Published (Public)',
              Class: 'error',
              Warning: 'Make the item available for viewing by the Public?'
            }
          ],
          CheckedInUsers: [],
          WhoCheckedInDialog: false,
          checkinsearch: '',
          ShareItemDialog: false,
          InternalLinkType: '',
          LinkComponent: '',
          LinkID: '',
          SharedCollection: '',
          NoticeCoverIMGProp: 'image',
          FullArticleData: '',
          PublicMeetups: [],
          SiteModerators: [],
         Moderators: [],
            meetupquil: false,
            snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        meetupdialog: false,
        meetupcontent: '',
        PubStatus: '',
        UserIsNOTModerator: true,
        
        IsAuthor: false,
        author: null,
        authorid: null,
        //userid: this.$store.state.user.id,
        groupmeetups: [],
        groups: [],       
        meetupid: this.$route.params.id,
        content: null,
        image: '',
        ImageShy: false,
        createdon: '',
        meetuppubDate: '',
        UserCanEdit: false,
        MeetupPrivacy: null,
        PublishStatus: null,
        
        PublishStatusTypes: [
        {index: 0, text: 'Draft'},
        {index: 1, text: 'Published'},
          ],  
        title: null,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                MeetupPrivacy: null,
                PublishStatus: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                MeetupPrivacy: null,
                PublishStatus: null,                
            },
            UserRecord: {},
            GroupData: {},
            EditingMeetup: false,    
        }
    },
    computed: {
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      ContentPublicColPath(){
        return this.$route.name === 'GroupMeetup' ? this.ContentColPath : !this.TemplateRootPath ?  
        db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('sitemeetupswiki') :
        this.TemplateRootPath.collection('samplesitemeetupswiki')
      },
      ContentColPath(){
        //quick tip here. Edit and New components reuqire collection to update, or add docs. thus, not interchangeable with CollectionRef on "feed" or "collection view"
        //the CollectionRef would add permission queries like for group aticles "where gourpid...etc"
        return this.$store.state.TemplateView ? this.ContentRootPath.collection('samplesitemeetups') : 
        this.$route.name === 'GroupMeetup' ? this.ContentRootPath.collection('groupmeetups') : this.ContentRootPath.collection('sitemeetups')
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      SocialSitePostsActive(){
        return this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && this.System && !this.System.Disable_Guest_Posts && this.UserRecord
        || this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && this.System && this.System.Disable_Guest_Posts && !this.$store.state.UserisGuest && this.UserRecord
      },
      SocialCommentsActive(){
        return this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && this.System && !this.System.Disable_Guest_Posts
        || this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && this.System && this.System.Disable_Guest_Posts && !this.$store.state.UserisGuest
      },
      CheckedInUsersSearched(){
        return this.CheckedInUsers
        .filter(user => {
          if(this.checkinsearch){
            return user.Full_Name.toLowerCase().includes(this.checkinsearch.toLowerCase())
          }
          else{
            return user
          }
        })
      },
      PlannedStatusIcon(){
        if(this.FullArticleData.PlannedStatus === 'Still Happening'){
          return 'mdi-calendar'
        }
        else if(this.FullArticleData.PlannedStatus === 'On Hold'){
          return 'mdi-calendar-question'
        }
        else if(this.FullArticleData.PlannedStatus === 'Cancelled'){
          return 'mdi-calendar-remove'
        }
      },
      PlannedStatusClass(){
        if(this.FullArticleData.PlannedStatus === 'Still Happening'){
          return 'success headline white--text'
        }
        else if(this.FullArticleData.PlannedStatus === 'On Hold'){
          return 'warning headline white--text'
        }
        else if(this.FullArticleData.PlannedStatus === 'Cancelled'){
          return 'error headline white--text'
        }
      },
      UsersCanCheckIn(){
        var durationInMinutes = 30
        if(this.FullArticleData.CheckAheadMinutes){
          durationInMinutes = this.FullArticleData.CheckAheadMinutes
        }
        let start = this.FullArticleData.start
        let end = this.FullArticleData.start
        let today = new Date()
        if(end && end.toDate){
          let endobj = end.toDate()
          endobj.setMinutes(endobj.getMinutes() + durationInMinutes);
          console.log(endobj)
          if(endobj < today){
            return false
          }
          else{
            if(start && start.toDate){
              let startobj = start.toDate()
              startobj.setMinutes(startobj.getMinutes() - durationInMinutes);
              console.log(startobj)
              if(startobj > today){
                return false
              }
              else{
                return true
              }
            }
          }
        }
      },
      MeetupStatus(){
        let start = this.FullArticleData.start
        let end = this.FullArticleData.end
        let today = new Date()
        if(end && end.toDate){
          let endobj = end.toDate()
          console.log(endobj)
          if(endobj < today){
            return 'Already Passed'
          }
          else{
            if(start && start.toDate){
              let startobj = start.toDate()
              console.log(startobj)
              if(startobj < today){
                return 'Already Started'
              }
              else{
                return 'Not Yet Started'
              }
            }
          }
        }
      },
      userisContentAdmin(){
        return this.userIsAdmin
      },
      UserCanSocialTopic(){
        if(this.$route.name === 'GroupMeetup'){
          let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
          return memberobj
        }
        else{
          return this.userLoggedIn
        }
      },
      UserIsModerator(){
          let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
          return this.authorid === this.UserRecord.id || UserModeratorobj
        },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupMeetup' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Meetup' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },  
      RoutePath(){
          return this.$route.name === 'GroupMeetup' ? '/'+this.GroupData.id+'/Meetup/' : '/Meetup/'
        },
      RouteID(){
          return this.$route.name === 'GroupMeetup' || this.$store.state.TemplateView ? this.$route.params.slug : this.$route.params.id
        },
      RouteDoc(){
          return this.ContentColPath.doc(this.RouteID)
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        RelatedMeetups () {
            return this.groupmeetups.filter(meetup => {
                return meetup.meetupid === this.meetupid
            })
        },
        
            
    ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
            
    },
    watch: {
        ActiveGroup: {
              handler: function(newvalue, oldvalue) {
                  if(oldvalue !== newvalue){
                      this.GetGroup()
                  }
              },
              deep: true
      },
      UserIsModerator(value) {
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)              
            }
        },
      SocialItemInteractMethod(v){      
        if(v){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      }
    },
    created() {
        document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.userLoggedIn){ 
      this.UserRecord = this.userLoggedIn
    }
    if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.getMeetupInformation()
      }
      else{
       if(this.$route.name === 'GroupMeetup'){
          this.InternalLinkType = 'Group-Meetup/'+this.$route.params.id+'/Meetup'
          this.SharedCollection = 'groupmeetups'
          this.LinkComponent = 'GroupMeetupShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else{
          this.InternalLinkType = 'Site Meetup'
          this.SharedCollection = 'sitemeetups'
          this.LinkComponent = 'SiteMeetupShared'
          this.LinkID = this.$route.params.id
        } 
        if(this.userLoggedIn){
          this.FocusedViewToggle()
          this.IntranetViewToggle()
          this.getMeetupInformation()
        }
        else{
          this.getPublicMeetupInformation()
        }
      }
      
        
        
        
    },
    methods: {
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },
      ConfirmCheckin(member){
        this.RouteDoc.collection('checkins').doc(member.id).update({
          Confirmed: true,
          Confirmed_By: {id: this.userLoggedIn.id, Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname},
          confirmed_On: new Date()
        })
      },
      DownloadQR(appurl,path,final,name){
      var QRAPI = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data="
      var options = {
                      method: 'GET',
                      mode: 'cors'
                    }
      var RoutingUrl = path+final
      var hostingURL = appurl  
      var QRUrl = hostingURL+RoutingUrl
      var FetchURL = QRAPI+QRUrl
      fetch(FetchURL, options).then((resp) => resp.blob()
        .then(QRimage => {
        var QRLocalURL = URL.createObjectURL(QRimage)
            var element = document.createElement('a')
            element.setAttribute('href',QRLocalURL)
            element.setAttribute('download', name+' QRCode')
            element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
      )
    },
      ActivateMultipleFilesUploadNotification(boolean){
        this.$emit('ActivateMultipleFilesUploadNotification',boolean)
      },
      CheckUserInforMeetup(){
        let NewCheckin = {
          Name: this.userLoggedIn.Name,
          Surname: this.userLoggedIn.Surname,
          Full_Name: this.userLoggedIn.Full_Name,
          Time: new Date(),
        }
        if(this.userLoggedIn.Profile_Photo){
          NewCheckin.UserIMG = this.userLoggedIn.Profile_Photo
        }
        console.log(this.userLoggedIn.id,NewCheckin)
        this.RouteDoc.collection('checkins').doc(this.userLoggedIn.id).set(NewCheckin).then(doc => {
          console.log('did the checkin on meetup')
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).update({
            CheckedInMeetupid: this.RouteID,
            CheckedinMeetupName: this.title,
            CheckedInMeetupRoute: this.$route.path
          }).then(userdoc => {
            //Now still need to create systemactivity that user checked in
            let sysacttype = 'Meetup Check in'
            //should be "Check in" or "Meetup Check in"
            contentvar = 'checked in to '+this.title
            this.CreateSystemActivity(this.title,this.RouteID,contentvar,sysacttype) 
          })
        })
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.LinkID,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this[this.NoticeCoverIMGProp]
        } 
        console.log(socialitem)     
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      ShareMeetup(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      StopEdit(){
        this.EditingMeetup = false
        this.ActivateProcessing(false)
      },
      EditItem(){
        this.EditingMeetup = true
      },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        vm.ContentRootPath.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      CheckPlannedStatus(plannedstatus){
        confirm('this will update the Meetup as '+plannedstatus+'. Do you want to continue?') && this.UpdatePlannedStatus(plannedstatus)
      },
      UpdatePlannedStatus(status){   
          let docid = ''
          if(this.$route.name === 'GroupMeetup'){
            docid = this.$route.params.slug
          }
          else{
            docid = this.$route.params.id
          }
        this.RouteDoc.update({
          PlannedStatus: status,
        })
        let contentvar = 'confirmed "'+this.title+'" as '+status
        this.CreateSystemActivity(this.title,docid,contentvar,'New Meetup')           
      },
      CheckStatus(status){
        if(status === 'Draft'){
          confirm('this will update the Meetup for all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Internal)'){
          confirm('this will make the Meetup avialable for viewing by all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Public)'){
          confirm('this will make the Meetup available for viewing by the Public. Do you want to continue?') && this.UpdateStatus(status)
        }
      },
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
      UpdateStatus(status){
          let docid = ''
          if(this.$route.name === 'GroupMeetup'){
            docid = this.$route.params.slug
          }
          else{
            docid = this.$route.params.id
          }
          let PriorStatus = this.PublishStatus
        this.RouteDoc.update({
          PublishStatus: status,
          PublishDate: new Date()
        })
        let wikipost = {
            tags: [],
            title: this.title,
            UpdatingUser: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            UpdatingUserid: this.UserRecord.id,
            author: this.author,
            authorid: this.authorid,
            content: this.meetupcontent,
            updatedon: new Date(),
            pubDate: this.DateFormatter(this.pubdatastring),
            Comments: [],
            ImageShy: this.ImageShy,
            PublishStatus: status,
            PublishDate: new Date(),
            PublicURL: this.title.split(' ').join('-')
          }
          let contentvar = ''
          let sysacttype = 'New Meetup'
        if(status === 'Published (Internal)'){
          contentvar = 'published an Internal Meetup'
          this.CreateSystemActivity(this.title,docid,contentvar,sysacttype)                           
        }
        else if(status === 'Published (Public)' && this.coverimage || status === 'Published (Public)' && this.image){
          wikipost.coverimage = this.image
          this.ContentPublicColPath.doc(docid).set(wikipost) 
          if(this.FullArticleData.Category){
             this.ContentPublicColPath.doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }
          
          contentvar = 'published an Public Meetup'    
          this.CreateSystemActivity(this.title,docid,contentvar,sysacttype)    
        }
        else if(status === 'Published (Public)' && !this.coverimage || status === 'Published (Public)' && !this.image){
          this.ContentPublicColPath.doc(docid).set(wikipost)
          if(this.FullArticleData.Category){
             this.ContentPublicColPath.doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }     
          contentvar = 'published an Public Meetup'    
          this.CreateSystemActivity(this.title,docid,contentvar,sysacttype)     
        }
        else if(PriorStatus === 'Published (Public)'){
           this.ContentPublicColPath.doc(docid).update({
              PublishStatus: status,
            })
            if(this.FullArticleData.Category){
             this.ContentPublicColPath.doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }
        }
      },
      CreateSystemActivity(docname,docid,contentvar,sysacttype){
      const systemactivity = {
                            user: this.UserRecord,
                            contentvar: contentvar,
                            location: '/Meetup/',
                            docname: docname,
                            docid: docid,
                            type: sysacttype,
                          }                          
                          this.$store.dispatch('createSystemActivity',systemactivity)
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetGroup(){
          this.GroupData = this.ActiveGroup
        },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            UserMeetuprelation(docref){
              console.log('checking rleation to meetups')
              console.log(this.authorid)
              console.log(this.Moderators)
              console.log(this.UserRecord)
                if(this.authorid === this.UserRecord.id) {
            this.UserCanEdit = true
            this.IsAuthor = true
            
            this.UserIsNOTModerator = false
            this.GetSiteModerators()
            this.GetCheckedInUsers(docref)
                }
                else {
            this.UserCanEdit = false
            let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
            if(UserModeratorobj){
              this.GetCheckedInUsers(docref)
              let vm = this
              this.GetSiteModerators().then(function(result) {
                  if(result){
                    console.log('checking if moderators')
                    let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
                    if(UserModeratorobj){
                      if(UserModeratorobj.ModerationPermissions.includes('Public Meetups')){
                        vm.UserIsPublicModerator = true
                      }
                      
                    }
                  }
                })
              
              this.UserIsNOTModerator = false
            }
                }
            },
        getPublicMeetupInformation(){
          let vm = this
          let query = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
          let splitat = 2
          if(this.$route.name === 'GroupArticle'){
           query = this.ContentColPath.where('groupid','==',this.$route.params.id)
           splitat = 4
          }
          else{
            query = query.collection('sitemeetupswiki')
          }
          return new Promise(function(resolve, reject) {
        query.where('PublishStatus','==','Published (Public)').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.PublicMeetups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        }).then(function(result) {
                  // this.UpdateRequestStatus()
                  var meetupdata = vm.PublicMeetups.find(obj => obj.PublicURL == window.location.pathname.split('/')[splitat])
                console.log(meetupdata)
              
                vm.title = meetupdata.title
                vm.content = meetupdata.content
                vm.meetupcontent = meetupdata.content
                vm.author = meetupdata.author
                vm.authorid = meetupdata.authorid
                vm.PubStatus = meetupdata.PublishStatus
                vm.ImageShy = meetupdata.ImageShy
                vm.image = meetupdata.coverimage
                vm.createdon = meetupdata.createdon
                // vm.pubdatastring = format(meetupdata.pubDate.toDate(),'yyyy-MM-dd')
                // vm.meetuppubDate = vm.TimestampFormatterSTRINGDayText(meetupdata.pubDate.toDate())
                vm.PublishStatus = meetupdata.PublishStatus
                vm.MeetupPrivacy = meetupdata.MeetupPrivacy
                vm.editedItem.MeetupPrivacy = meetupdata.MeetupPrivacy
                vm.editedItem.PublishStatus = meetupdata.PublishStatus
        })
        },
    GetCheckedInUsers(docref){      
      docref.collection('checkins').onSnapshot(res => {       
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let checkineduser = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.CheckedInUsers.find(obj => obj.id === checkineduser.id)
                if(!oncheck){
                 this.CheckedInUsers.push(checkineduser) 
                }                
              }
              else if (change.type === 'modified') {
                let checkineduser = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.CheckedInUsers.find(obj => obj.id === checkineduser.id)
                if(!oncheck){
                 this.CheckedInUsers.push(checkineduser) 
                }
                else{
                  let index = this.CheckedInUsers.indexOf(oncheck)
                  // alert(index)
                  this.CheckedInUsers.splice(index,1,checkineduser)
                }                
              }
            })
      })
    },
    GetSocialComments(docid){
          this.ContentRootPath.collection('socialsitecomments').where('relatedid','==',docid).onSnapshot(res => {
            if(!this.FullArticleData.Comments){
             this.FullArticleData.Comments = [] 
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  Replies: [],
                  id: change.doc.id
                }
                this.GetSocialLike(commentobj.id,commentobj)
                let oncheck = this.FullArticleData.Comments.find(obj => obj.id === commentobj.id)
                if(!oncheck){
                    this.FullArticleData.Comments.push(commentobj)
                  }
                this.ContentRootPath.collection('socialsitereplies').where('relatedid','==',commentobj.id).onSnapshot(repliesres => {
                    const replychanges = repliesres.docChanges();
                    replychanges.forEach(repchange => {
                    if (repchange.type === 'added') {
                      let replyobj = {
                        ...repchange.doc.data(),
                          Replies: [],
                          id: repchange.doc.id
                        }
                        this.GetSocialLike(replyobj.id,replyobj)
                        let reponcheck = commentobj.Replies.find(obj => obj.id === replyobj.id)
                        if(!reponcheck){
                         commentobj.Replies.push(replyobj) 
                        }
                      }
                    })
                })
                
              }
            })
          })
        },
    GetSocialLike(docid,docobj){
      this.ContentRootPath.collection('socialsitelikes').where('relatedid','==',docid).onSnapshot(res => {
            if(!docobj.Likes){
              docobj.Likes = []
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                 let oncheck = docobj.Likes.find(obj => obj.id === commentobj.id)
                  if(!oncheck){
                    docobj.Likes.push(commentobj)
                  } 
               
              }
            })
          })
    },
    async getMeetupInformation() {
        this.RouteDoc.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var meetupdata = snapshot.data()
          this.FullArticleData = meetupdata
          this.FullArticleData.id = this.RouteID
           this.$emit('PushRecordName',this.FullArticleData.title)
           
           if(!this.$store.state.TemplateView){
          this.GetSocialComments(this.RouteID)
          this.GetSocialLike(this.RouteID,this.FullArticleData)
           }
        console.log(meetupdata)
        if(meetupdata.caption){
          this.caption = meetupdata.caption
        }
        this.Moderators = meetupdata.moderatorrolesarrayQuery
        this.title = meetupdata.title
        this.content = meetupdata.content
        this.meetupcontent = meetupdata.description
        this.author = meetupdata.author
        this.authorid = meetupdata.authorid
        this.PubStatus = meetupdata.PublishStatus
        if(typeof meetupdata.ImageShy !== 'undefined'){
          this.ImageShy = meetupdata.ImageShy
        }
        if(typeof meetupdata.coverimage !== 'undefined'){
          this.image = meetupdata.coverimage
        }
        this.createdon = meetupdata.createdon
        // this.pubdatastring = format(meetupdata.PublishDate.toDate(),'yyyy-MM-dd')
        // this.meetuppubDate = this.TimestampFormatterSTRINGDayText(meetupdata.PublishDate.toDate())
        this.PublishStatus = meetupdata.PublishStatus
        if(meetupdata.GuestsIncluded){
          this.GuestsIncluded = meetupdata.GuestsIncluded
        }
        else{
          this.GuestsIncluded = false
        }
        this.MeetupPrivacy = meetupdata.MeetupPrivacy
        this.editedItem.MeetupPrivacy = meetupdata.MeetupPrivacy
        this.editedItem.PublishStatus = meetupdata.PublishStatus
      console.log('meetup id is '+this.meetupid)
        console.log('user id is '+this.UserRecord.id)
        console.log('author id is '+this.authorid)
         if(!this.$store.state.TemplateView){
           let docref = this.RouteDoc
        this.UserMeetuprelation(docref)
         }
        },
        error => {
          if(error.code === 'permission-denied'){
            if(!this.userLoggedIn){
              this.$router.push('/404')  
            }
            else{
              this.$router.push('/PermissionError')
            }            
          }
        })
    
        },
    Closedialog() {
        this.meetupdialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
   
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {
align-content: center;
margin-left: 0.5em
}
    
</style>