<template>
  <div>
      <v-carousel v-model="model" :height="CarouselHeight" v-if="BannerAds.length>0" cycle>
         
        <v-carousel-item
        v-for="bannerad in BannerAds" :key="bannerad.itemObjKey"
        >
         <v-icon dark v-if="Popup" @click="CloseBannerDialogItem(bannerad)" class="soloactionicon red" style="position: absolute; top:10px;right: 10px;z-index:100;">
            mdi-close
        </v-icon>
        <v-img :src="bannerad.BannerImage && bannerad.BannerImage.fileurl? bannerad.BannerImage.fileurl : bannerad.BannerImage"
            height="100%">
        <v-sheet
            color="rgba(24,24,24,0.9)"
            :class="Popup ? 'mx-1 my-4' : 'mx-10 my-4'"
            height="80%"
            :width="Popup? '80%' : '60%'"
            tile
        >       
            <v-row
            class="fill-height"
            align="center"
            justify="center"
            >
            <v-card class="transparent" flat width="100%" min-height="270">
                <v-card-title :style="MiniView? 'padding-bottom: 0px;' : ''" :class="MiniView ? 'smallbanneroverline white--text mx-3' : 'largeoverline white--text mx-3'">
                {{bannerad.BannerHeader}}                                
                </v-card-title>
                
                <v-card-text style="font-size: 10px" :class="MiniView ? 'white--text mx-3' : 'overline white--text mx-3'">
                {{bannerad.BannerDescription}}
                </v-card-text>
                
            <v-card-actions class="mx-3">
                <v-btn v-if="!bannerad.RouteQueries" color="links black--text" :x-large="!MiniView" :small="MiniView" :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                <v-btn v-if="bannerad.RouteQueries" color="links black--text" :x-large="!MiniView" :small="MiniView"  @click="ActivateRouteQuery(bannerad)">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
            </v-card-actions>
            </v-card>
            </v-row>                        
        </v-sheet>
        </v-img>
        </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','BannerAds','Popup'],
    components: {},
    data() {
        return {

        }
    },
    computed:{
        CarouselHeight(){
            if(!this.Popup){
                return this.MiniView ? '240' : '400'
            }
            else{
                return this.MiniView ? '300' : this.WindowHeight*0.7
            }
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
    },
    created(){

    },
    methods:{
        CloseBannerDialogItem(item){
            this.$emit('CloseBannerDialogItem',item)
        },
        ActivateRouteQuery(item){
        let path = ''
        if(item.BannerLink){
            path = item.BannerLink
        }
        let newroute = {path: path, query: {}}
        this.$emit('CloseBannerDialogItem',item)
        item.RouteQueries.map((routequery,routequeryindex) => {
            newroute.query[routequery.Prop] = routequery.Value
            if(routequeryindex-1+2 === item.RouteQueries.length){
                this.$router.push(newroute)
            }
        })
        
      },
    }
}
</script>

<style>

</style>


