<template>
   <v-card flat tile class="transparent">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
           <!-- <v-list  v-if="WikiType && $route.meta.ClassifiedListingBuilder">                             
                <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Title' || EditedTab.Name === 'Image' || EditedTab.Name === 'Parallax'">									
                    <v-switch v-model="EditedTab.ContentFromField" label="From Field"/>									
                </v-list-item>
           </v-list> -->
           
           <!-- <v-list-item v-if="EditedTab.HasWaves">
             <v-text-field
          label="Box Gradient"
          v-model="EditedTab.WavesGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
           </v-list-item>
           <v-list-item>
            <v-textarea v-model="EditedTab.SVGPath" label="SVG Code"/>
           </v-list-item>
           <v-list-item>
            <svg height="100%" width="100%" v-html="EditedTab.SVGPath">
                
            </svg>
           </v-list-item>
           <v-list-item dense v-if="EditedTab.HasWaves">
            <v-slider
                    label="Wave Height"
                        v-model="EditedTab.WaveHeight"
                        :max="100"
                        :min="10"
                        hide-details
                        class="align-center"
                    />
            </v-list-item>
           <v-list-item v-if="EditedTab.HasWaves">
            <v-switch v-model="EditedTab.WavesAnimated" label="Waves Animated"/>
           </v-list-item> -->
           <!-- <v-list-item @click="ToggleAdvancedCSSDialog()">
            Advanced CSS
           </v-list-item> -->
          <v-list-item v-if="EditedTab.Name === 'Hero Banner'">
            <v-switch v-model="EditedTab.HasFooter" label="Has Footer"
            />
          </v-list-item>
           <v-list-item v-if="EditedTab.Name === 'Hero Banner'">
            <v-select :items="['Text Only','Text/IMG','IMG/Text','Text/IMG/Title','Title/IMG/Text']"
            v-model="EditedTab.Type" label="Type"
            />
          </v-list-item>
          <!-- okay so when we do hero banner quite f simple
          IMG gets flex, text gets flex. both must be set. ready? flex xl8 lg8 md9 sm12 xs12 -->
            <v-list-item v-if="EditedTab.Name === 'Hero Banner'">
                <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Text Box
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list-item dense style="padding: 0px;">
                            <v-text-field
                            label="TextBox Gradient" clearable
                            v-model="EditedTab.TextBoxGradient"
                            />
                            <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                        </v-list-item>
                    <v-slider thumb-label="always" v-model="EditedTab.TextFlexXLRG" label="Text XLRG" min="4" max="12" step="1"/>
                    <v-slider thumb-label="always" v-model="EditedTab.TextFlexLarge" label="Text LRG" min="4" max="12" step="1"/>
                    <v-slider thumb-label="always" v-model="EditedTab.TextFlexMedium" label="Text MED" min="4" max="12" step="1"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="EditedTab.Type !== ' Text Only'">
                    <v-expansion-panel-header>
                        IMG Sizing
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    <v-slider thumb-label="always" v-model="EditedTab.IMGFlexXLRG" label="IMG XLRG" min="4" max="12" step="1"/>
                    <v-slider thumb-label="always" v-model="EditedTab.IMGFlexLarge" label="IMG LRG" min="4" max="12" step="1"/>
                    <v-slider thumb-label="always" v-model="EditedTab.IMGFlexMedium" label="IMG MED" min="4" max="12" step="1"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            </v-list-item>
          <v-list-item v-if="EditedTab.Name === 'Hero Banner'">
            <v-switch v-model="EditedTab.HasSocialButtons" label="Has Social Buttons"
            />
          </v-list-item>
          <v-list-item v-if="EditedTab.Name === 'Hero Banner' && EditedTab.HasSocialButtons">
           <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Social Button Settings
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list-item>
                 <v-menu style="z-index: 100!important;"								
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Btn						
                    </template>									
                <v-color-picker style="z-index: 100!important;" v-model="EditedTab.SMBColor"></v-color-picker>									
                </v-menu>
                <v-menu style="z-index: 100!important;"								
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Icon						
                    </template>									
                <v-color-picker style="z-index: 100!important;" v-model="EditedTab.SMBIconColor"></v-color-picker>									
                </v-menu>
                </v-list-item>                    
                    <v-select style="z-index: 100!important;"
                    v-model="EditedTab.SocialButtonType" :items="['Menu Left','Menu Right','Bottom Right','Bottom Left']"/>
                    <v-select return-object multiple style="z-index: 100!important;" item-text="title"
                    @change="CheckSocialMediaButtons(EditedTab)" v-model="EditedTab.SocialMediaButtons" :items="SocialButtonOptions"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
           </v-expansion-panels>
          </v-list-item>
          <v-list-item v-if="EditedTab.Name === 'Hero Banner' && EditedTab.HasSocialButtons && EditedTab.SocialButtonsObject">
           <v-expansion-panels v-if="EditedTab.SocialMediaButtons">
            <v-expansion-panel v-for="btn in EditedTab.SocialMediaButtons" :key="btn.itemObjKey">
                <v-expansion-panel-header>{{btn.title}}
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="EditedTab.SocialButtonsObject[btn.title]">
                    <v-text-field v-model="EditedTab.SocialButtonsObject[btn.title].link" :label="btn.title+' Link'"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
           </v-expansion-panels>
          </v-list-item>
          <v-list-item v-if="EditedTab.Name === 'Hero Banner' && EditedTab.HasFooter">
           <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Footer Settings
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                 <v-select style="z-index:100!important;" clearable v-model="EditedTab.FooterType" label="Type" 
                :items="['Footer Form']"/>
                <v-switch v-model="EditedTab.FooteratBottom" label="Footer at Bottom"/>
                <v-list-item dense style="padding: 0px;">
                    <v-text-field
                    label="Footer Gradient" clearable
                    v-model="EditedTab.FooterGradient"
                    />
                    <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                
                <v-slider thumb-label="always" v-model="EditedTab.FooterFlexXLRG" label="Footer XLRG" min="4" max="12" step="1"/>
                <v-slider thumb-label="always" v-model="EditedTab.FooterFlexLarge" label="Footer LRG" min="4" max="12" step="1"/>
                <v-slider thumb-label="always" v-model="EditedTab.FooterFlexMedium" label="Footer MED" min="4" max="12" step="1"/>
                <v-list-item>									
                <v-select style="z-index: 100!important;" :items="['justify-start','justify-center','justify-end','justify-space-between']" v-model="EditedTab.FooterJustify" 									
                    label="Horizontal Align"/>									
                </v-list-item>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="EditedTab.FooterType === 'Footer Form'">
                    <v-expansion-panel-header>
                        Footer Form
                    </v-expansion-panel-header>
                <v-expansion-panel-content>
                     <v-select  clearable style="z-index: 100!important;"                 
                      v-model="EditedTab.FooterRelatedEntity" label="Entity" :items="WebFormEntities" item-text="id"/>
                      <v-select multiple :items="RelatedEntityFooterFields" clearable label="Fields"
                      v-if="RelatedEntity" style="z-index: 100!important;" item-text="id" 
                       @change="Twopropemit('AddFooterFormEntityFields',EditedTab.FooterRelatedEntity,EditedTab)"
                      v-model="EditedTab.FooterRelatedEntityFields"
                      />
                    <v-text-field v-model="EditedTab.FooterFormEmailRecipient" :rules="[$store.state.formrules.email]" label="Recipient Email"/>
                    <v-list-item dense style="padding: 0px;">
                    <v-text-field
                    label="Footer Form Gradient" clearable
                    v-model="EditedTab.FooterFormGradient"
                    />
                    <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                <v-list-item dense style="padding: 0px;">
                    <v-text-field
                    label="Footer Form Border" clearable
                    v-model="EditedTab.FooterFormBorderStyle"
                    />
                </v-list-item>
                <v-list-item dense style="padding: 0px;">
                    <v-text-field
                    label="Button Name" clearable
                    v-model="EditedTab.FooterFormButtonName"
                    />
                </v-list-item>
                
                <v-list-item>
                 <v-menu style="z-index: 100!important;"								
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Button Color						
                    </template>									
                <v-color-picker style="z-index: 100!important;" v-model="EditedTab.FooterFormBTNColor"></v-color-picker>									
                </v-menu>
                </v-list-item>
                
                <v-switch style="z-index:100!important;" v-model="EditedTab.FooterFormIconInvert" label="Icon Invert" 
                />
                <!-- button name - also btn color, also btn borders -->
                     <v-switch style="z-index:100!important;" v-model="EditedTab.FooterNote" label="Has Note" 
                />
                </v-expansion-panel-content>
                </v-expansion-panel>
           </v-expansion-panels>
          </v-list-item>
          <v-list-item v-if="EditedTab.Name === 'Hero Banner' && EditedTab.HasFooter && EditedTab.FooterNote">
           <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Footer Note
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-text-field v-model="EditedTab.FooterNoteTitle" label="Title"/>
                  <ContentEditableField
                    :FieldObject="EditedTab" :FieldName="'FooterNoteDescription'"
                    :FieldValue="EditedTab.FooterNoteDescription" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                </v-expansion-panel-content>
            </v-expansion-panel>
           </v-expansion-panels>
          </v-list-item>
           <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.FlipCard || 
           EditedTab.Name === 'Card Row' && EditedTab.Type === 'Draw Boxes'">
                  <v-text-field
          label="Label Gradient"
          v-model="EditedTab.LabelGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                
            
            <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Bootstrap Boxes'">
                 <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>STD Box Color						
                    </template>									
                <v-color-picker v-model="EditedTab.STDColor"></v-color-picker>									
                </v-menu>
                </v-list-item>
                 <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Bootstrap Boxes'">
                 <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>STD Font Color						
                    </template>									
                <v-color-picker v-model="EditedTab.STDFontColor"></v-color-picker>									
                </v-menu>
                </v-list-item>
                 <v-list-item v-if="EditedTab.Name === 'Card Row' &&EditedTab.Type === 'Bootstrap Boxes'">
                 <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Hover Box Color						
                    </template>									
                <v-color-picker v-model="EditedTab.HoverColor"></v-color-picker>									
                </v-menu>
                </v-list-item>
                 <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Bootstrap Boxes'">
                 <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Hover Font Color						
                    </template>									
                <v-color-picker v-model="EditedTab.HoverFontColor"></v-color-picker>									
                </v-menu>
                </v-list-item>
                <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === '3D Carousel'">
                  <v-text-field
          label="Box Gradient"
          v-model="EditedTab.BoxGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                
                <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.FlipCard && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes' || 
                EditedTab.Name === 'Card Row' && EditedTab.HoverCard && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes' || 
           EditedTab.Name === 'Card Row Single' && EditedTab.FlipCard || EditedTab.Name === 'Card Row Single' && EditedTab.HoverCard">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Label BG						
                    </template>									
                <v-color-picker v-model="EditedTab.LabelBGColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
                <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.HoverCard && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes'">
                
                <v-text-field
                        v-model.number="EditedTab.CardHeight"
                        class="mt-0 pt-0"
                        type="number"
                        >  
                        <template v-slot:append>
                            <v-btn small
                            @click="ImageHeight(EditedTab,'CardHeight','down',50)"
                            >50
                            <v-icon> mdi-chevron-down </v-icon>
                            </v-btn>
                            <v-btn small
                            @click="ImageHeight(EditedTab,'CardHeight','up',50)"
                            >50
                            <v-icon> mdi-chevron-up </v-icon>
                            </v-btn>
                            </template>              
                        </v-text-field>
                </v-list-item>
             <v-list-item dense v-if="EditedTab.Name !== 'Image' && EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'Info Sheet' && EditedTab.Name !== 'RSS Feed' && EditedTab.Name !== 'Card Link' && EditedTab.Name !== 'Card Blocks'" >
                    <v-switch v-model="EditedTab.HasImage" label="HasImage"/>
                    <v-switch  v-if="EditedTab.Name === 'Clipped Tab'" v-model="EditedTab.Animated" label="Animated"/>                    
                </v-list-item>
                 <v-list-item dense v-if="EditedTab.Name === 'Hero Banner' || 
                 EditedTab.Name === 'Card Row Single' ||EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Timeline Item' || EditedTab.Name === 'Store Product Single' || 
                 EditedTab.Name === 'Clipped Tab'">
                        <v-switch  v-if="EditedTab.Name === 'Clipped Tab'" v-model="EditedTab.HasContent" label="Has Content"/>
                    <v-switch v-model="EditedTab.HasActionButton" label="Has Action Button"/>
                        <v-switch  v-if="EditedTab.Name === 'Clipped Tab'" v-model="EditedTab.Floating" label="Floating"/>
                </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row'">
                <v-select clearable @change="CheckCardRowStyle(EditedTab)" v-model="EditedTab.Type" label="Type" 
                :items="['Standard','Flip Cards','Bootstrap Boxes','Draw Boxes',
                'Hover Cards','Magazine Style','3D Carousel','Infographic Box']"/>
            </v-list-item>
             <v-list-item dense v-if="EditedTab.Name === 'Card Row' && 
                EditedTab.Type !== '3D Carousel' && EditedTab.Type !== 'Magazine Style' && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes'">
               <v-switch v-model="EditedTab.MainatBottom" label="Main at Bottom"/>
            </v-list-item>
             <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Infographic Box'">
                
                <v-select v-model="EditedTab.ListPosition" label="List Position" :items="['Left','Right']"/>
             </v-list-item>
             <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Magazine Style'">
                <v-switch @change="UpdateClearBoxes(EditedTab)" label="Clear Boxes" v-model="EditedTab.ClearBoxes" />
             </v-list-item>
             <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Magazine Style'">
                <v-color-picker v-model="EditedTab.MagazineHighlightColor"></v-color-picker>
             </v-list-item>
             
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type !== 'Magazine Style' && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes'">
                <v-switch  @click="CheckforFliporHover('HoverCard')"  label="Flip Card" v-model="EditedTab.FlipCard" />
                <v-switch @click="CheckforFliporHover('FlipCard')" label="Hover Card" v-model="EditedTab.HoverCard" />
                <v-switch label="Contain IMG" v-model="EditedTab.ContainIMG" />
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type !== 'Magazine Style' && EditedTab.FlipCard && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes'">
            <v-slider thumb-label="always" v-model="EditedTab.TitleSize" label="Title Size" min="0.25" max="4" step="0.25"/>
            </v-list-item>
            
             <v-list-item dense v-if="EditedTab.Name === 'Parallax'">
                <v-switch label="Has HUD Items" v-model="EditedTab.HasHUDItems" />
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">
                <v-switch label="Has Text Box" v-model="EditedTab.HasTextBox" />
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' && EditedTab.HasTextBox">
                <v-switch label="FlipCard Text" v-model="EditedTab.FlipCard" />
                
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">
                <v-switch label="Transparent" v-model="EditedTab.Transparent" />								
                
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type !== 'Magazine Style' && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes'">
                <v-switch label="IMG on Top" v-model="EditedTab.IMGonTop" />
                 <v-switch label="Tiled IMG" v-model="EditedTab.TiledIMG" />
                 <v-switch label="Full IMG" v-model="EditedTab.FullIMG" />
                
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && !Refreshing && EditedTab.Type !== 'Draw Boxes' && EditedTab.Type !== 'Bootstrap Boxes'">
                <v-text-field
                        v-model.number="EditedTab.IMGHeight"
                        class="mt-0 pt-0"
                        type="number"
                        >  
                        <template v-slot:append>
                            <v-btn small
                            @click="ImageHeight(EditedTab,'IMGHeight','down',50)"
                            >50
                            <v-icon> mdi-chevron-down </v-icon>
                            </v-btn>
                            <v-btn small
                            @click="ImageHeight(EditedTab,'IMGHeight','up',50)"
                            >50
                            <v-icon> mdi-chevron-up </v-icon>
                            </v-btn>
                            </template>              
                        </v-text-field>
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">								
                                    <v-switch label="Has Logo" v-model="EditedTab.HasLogo" />									
                                    <v-switch label="Logo  at Bottom" v-model="EditedTab.LogoIsBottom" v-if="EditedTab.HasLogo"/>
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' && EditedTab.HasLogo || EditedTab.Name === 'Image' && EditedTab.HasLogo">
                                    <v-dialog max-width="800">								
                                        <template v-slot:activator="{ on }">									
                                    <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>									
                                            <v-tabs v-if="!UseGroupAssets">									
                                            <v-tab v-for="lib in GalleryLibraries" :key="lib.itemObjKey">									
                                                {{lib.name}}									
                                            </v-tab>									
                                            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">							
                                                <v-card width="800">									
                                                <PhotoLibraryViewer :FullGallery="true"	:TableCollectionRef="lib.TableCollectionRef" :TableName="lib.name"							
                                                @AssignImage="AssignImage" :IMGProp="'LogoSRC'" :CurrentEntity="lib" :AppisDarkMode="true"									
                                                :tab="EditedTab" />									
                                                </v-card>									
                                            </v-tab-item>									
                                        </v-tabs>				
                                        <v-card-text v-if="UseGroupAssets">
                                            <v-row  v-if="SystemAssets.length > 0">
                                                <v-col
                                                v-for="n in SystemAssets"
                                                :key="n.itemOvjKey"
                                                class="d-flex child-flex"
                                                cols="3"
                                                >
                                                <v-img
                                                    :src="n.ThumbURL"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                                    @click="AssignImage(EditedTab,'LogoSRC',n)"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>                                        
                                    </v-dialog>							
                                    <v-select v-if="EditedTab.HasLogo" v-model="EditedTab.LogoJustify" label="Logo Justify" :items="['start','end','center']"/>
            </v-list-item>
         
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {
        PhotoLibraryViewer,
        ContentEditableField
        },
    data() {
        return {
            Refreshing: false,
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        RelatedEntityFooterFields(){
            return this.RelatedEntity ? this.RelatedEntity.AllFields.filter(field => {
                let allowedtypes = ['Single Line Text','Common Field','Number Field','Option Set','Boolean','Single File Upload']
                let allowedcommontypes = ['Email','Telephone Number','URL Link']
                return allowedtypes.includes(field.Type) && allowedcommontypes.includes(field.CommonFieldType) || 
                allowedtypes.includes(field.Type) && !field.CommonFieldType
            }) : []
        },
        RelatedEntity(){
            let entid = this.EditedTab.FooterRelatedEntity && this.EditedTab.FooterRelatedEntity.id ? 
            this.EditedTab.FooterRelatedEntity.id : this.EditedTab.FooterRelatedEntity
            let entobj = this.WebFormEntities.find(obj => obj.id === entid)
            //console.log('entobj',this.EditedTab.FooterRelatedEntity)
            return entobj
        },
        SocialButtonOptions(){
            return [
                {icon: 'mdi-twitter',title: 'Twitter',link: ''},
                {icon: 'mdi-instagram',title: 'Instagram',link: ''},
                {icon: 'mdi-facebook',title: 'Facebook',link: ''},
                {icon: 'mdi-youtube',title: 'Youtube',link: ''},
                {icon: 'mdi-linkedin',title: 'LinkedIn',link: ''}
            ]
        },
        AdvancedCSSDialog(){
            return this.$store.state.AdvancedCSSDialog
        },
    },
    created(){

    },
    methods:{
        CheckSocialMediaButtons(EditedTab){
            if(!EditedTab.SocialButtonsObject){
                EditedTab.SocialButtonsObject = {}
            }
            EditedTab.SocialMediaButtons.map(btn => {
                if(!EditedTab.SocialButtonsObject[btn.title]){
                    EditedTab.SocialButtonsObject[btn.title] = btn
                }
            })
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        //console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        //console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
        CheckforFliporHover(offprop){
            if(this.EditedTab.HoverCard && this.EditedTab.FlipCard){
                this.EditedTab[offprop] = false
            }
            //console.log('this.EditedTab',this.EditedTab)
            if(offprop === 'FlipCard'){
                if(typeof this.EditedTab.CardHeight === 'undefined'){
                    this.EditedTab.CardHeight = 300
                }
            }
        },
        ToggleAdvancedCSSDialog(){
            this.$store.commit('setEditedTab',this.EditedTab)
            this.$store.commit('ToggleAdvancedCSSDialog',!this.AdvancedCSSDialog) 
        },
        UpdateClearBoxes(tab){
            if(!tab.ClearBoxes){
                tab.Elements.map(elmnt => {
                    elmnt.BoxTransparency = ''
                    elmnt.Elevation = 2
                })
            }
        },
        isOdd(num) {
        return num % 2;
        },
        CheckCardRowStyle(tab){
            if(tab.Type === 'Infographic Box'){
                tab.Elements.map((elmnt,elmntindex) => {
                    let number = elmntindex-1+2
                    elmnt.IMGonTop = true
                    elmnt.IMGHasTitle = true
                    elmnt.InfographicBox = true
                    elmnt.IMGTitleStyle = 'height: 130px;line-height: 130px;width: 130px;text-align: center;'
                    elmnt.IMGTitle = number.toString()
                    elmnt.IMGTitleFontSize = 130
                    elmnt.IMGTitleColor = { "alpha": 1, "hex": "#FFFFFF", "hexa": "#FFFFFFFF", "hsla": { "h": 0, "s": 0, "l": 1, "a": 1 }, "hsva": { "h": 0, "s": 0, "v": 1, "a": 1 }, "hue": 0, "rgba": { "r": 255, "g": 255, "b": 255, "a": 1 } }
                    elmnt.IMGTitleBGColor = { "alpha": 1, "hex": "#13BDFF", "hexa": "#13BDFFFF", "hsla": { "h": 196.77966101694915, "s": 1, "l": 0.5372549019607843, "a": 1 }, "hsva": { "h": 196.77966101694915, "s": 0.9254901960784314, "v": 1, "a": 1 }, "hue": 196.77966101694915, "rgba": { "r": 19, "g": 189, "b": 255, "a": 1 } }
                    elmnt.IMGBorderRadius = 130
                    elmnt.FontSize = 20
                })
                tab.Justify = 'justify-space-between'
                tab.ListPosition = 'Right'
            }
            else if(tab.Type === 'Magazine Style'){
                tab.Elements.map((elmnt,elmntindex) => {
                    if(!elmnt.MagType){
                        if(!this.isOdd(elmntindex)){
                           elmnt.MagType = 'Type 1'
                        }
                        else{
                            elmnt.MagType = 'Type 2'
                        }
                    }
                    elmnt.InfographicBox = false
                })
                if(!tab.MagazineHighlightColor){
                    tab.MagazineHighlightColor = {hex: '#FF0000'}
                }
            }
            else{
                tab.Elements.map(elmnt => {
                    elmnt.InfographicBox = false
                })
            }
           // console.log('tab.Elements',tab.Elements)
            if(tab.Type === 'Standard' || tab.Type === 'Bootstrap Boxes' || tab.Type === 'Draw Boxes'){
                tab.FlipCard = false
                tab.Elements.map(elmnt => {
                    elmnt.Type = tab.Type
                    elmnt.FlipCard = false
                })
                tab.HoverCard = false
                tab.Elements.map(elmnt => {
                    elmnt.HoverCard = false
                })
                if(tab.Type === 'Bootstrap Boxes'){
                    let props = ['STDColor','STDFontColor','HoverColor','HoverFontColor']
                    props.map(prp => {
                        if(typeof tab[prp] === 'undefined'){
                            tab[prp] = prp === 'STDColor' || prp === 'HoverFontColor' ? '#ffffff' : prp === 'HoverColor' ? '#0000ff' : prp === ' STDFontColor' ? '#162e4d' : ''
                        }
                        tab.Elements.map(elmnt => {
                            elmnt[prp] = tab[prp]
                        })
                    })
                }
            }
            else if(tab.Type === 'Hover Cards'){
                tab.FlipCard = false
                tab.Elements.map(elmnt => {
                    elmnt.Type = tab.Type
                    elmnt.FlipCard = false
                })
                tab.HoverCard = true
                if(!tab.LabelBGColor){
                    tab.LabelBGColor = {
                        hexa: '#ffffff55'
                    }
                }
                tab.Elements.map(elmnt => {
                    elmnt.Type = tab.Type
                    elmnt.HoverCard = true
                    if(!elmnt.LabelBGColor){
                    elmnt.LabelBGColor = {
                        hexa: '#ffffff55'
                    }
                }
                })
            }
            else if(tab.Type === 'Flip Cards'){
                tab.HoverCard = false
                tab.Elements.map(elmnt => {
                    elmnt.HoverCard = false
                })
                tab.FlipCard = true
                tab.Elements.map(elmnt => {
                    elmnt.Type = tab.Type
                    elmnt.FlipCard = true
                })
            }
            // ['Standard','Flip Cards','Bootstrap Boxes','Draw Boxes',
            //     'Hover Cards','Magazine Style','3D Carousel','Infographic Box']
        },
        ImageHeight(tab,prop,direction,int){
            if(tab[prop] === 'undefined' || isNaN(tab[prop])){
                tab[prop] = Number(50)
            }
            else{
                if(direction === 'down'){
                    tab[prop] = Number(tab[prop])-Number(int)
                }
                else{
                    tab[prop] = Number(tab[prop])+Number(int)
                }
            }
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 50);
            //console.log(tab)
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single" && EditedTab.Name !== 'Title')
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
