
<template>
<div :style="tab.Type !== 'Bootstrap Boxes' ? 'padding: 5px;' : ''">
  
  <!-- selectedIndex === 'undefined' -->  
    <CardRowStandard v-if="!tab.FlipCard && !tab.HoverCard && 
    tab.Type !== 'Draw Boxes' && tab.Type !== 'Bootstrap Boxes' && 
    tab.Type !== 'Flip Cards' && tab.Type !== 'Hover Cards' || 
    tab.Type === 'Standard'"
    :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
    :PageHeadersFontSize="PageHeadersFontSize" :tabindex="tabindex" :rowindex="rowindex"									
    :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"		
    @OpenDialog="OpenDialog" @ActionButtonOpenDialog="ActionButtonOpenDialog"
    />
    <CardRowFlipCards v-if="tab.FlipCard && !tab.HoverCard && tab.Type !== 'Draw Boxes' || 
    tab.Type === 'Flip Cards'"
    :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
    :PageHeadersFontSize="PageHeadersFontSize" :tabindex="tabindex" :rowindex="rowindex"									
    :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"		
    @OpenDialog="OpenDialog" @ActionButtonOpenDialog="ActionButtonOpenDialog"
    />
    <CardRowHoverCards v-if="!tab.FlipCard && tab.HoverCard && tab.Type !== 'Draw Boxes' || 
    tab.Type === 'Hover Cards'"
    :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
    :PageHeadersFontSize="PageHeadersFontSize" :tabindex="tabindex" :rowindex="rowindex"									
    :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"		
    @OpenDialog="OpenDialog" @ActionButtonOpenDialog="ActionButtonOpenDialog"
    />
    <CardRowDrawBoxes  v-if="tab.Type === 'Draw Boxes'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
    :PageHeadersFontSize="PageHeadersFontSize" :tabindex="tabindex" :rowindex="rowindex"									
    :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"		
    @OpenDialog="OpenDialog" @ActionButtonOpenDialog="ActionButtonOpenDialog"
    />
    <CardRowBootstrap  v-if="tab.Type === 'Bootstrap Boxes'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
    :PageHeadersFontSize="PageHeadersFontSize" :tabindex="tabindex" :rowindex="rowindex"									
    :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"		
    @OpenDialog="OpenDialog" @ActionButtonOpenDialog="ActionButtonOpenDialog"
    />
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import CardRowStandard from '@/components/WebPages/RenderComponents/CardRow/CardRowStandardReadOnly';
import CardRowFlipCards from '@/components/WebPages/RenderComponents/CardRow/CardRowFlipCardsReadOnly';
import CardRowHoverCards from '@/components/WebPages/RenderComponents/CardRow/CardRowHoverCardsReadOnly';
import CardRowDrawBoxes from '@/components/WebPages/RenderComponents/CardRow/CardRowDrawBoxesReadOnly';
import CardRowBootstrap from '@/components/WebPages/RenderComponents/CardRow/CardRowBootstrapReadOnly';

export default {
    props: ['System','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID','selectedIndex'],
    components: {
      CardRowStandard,
      CardRowFlipCards,
      CardRowHoverCards,
      CardRowDrawBoxes,
      CardRowBootstrap
        },
    data() {
    return {      
      WebFormDialogFancy: false, 
      WebFormDialogNonFancy: false,  
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){

    },
    
    methods: {
      DrawBoxStyle(tab){
        let style = tab.BGColor ? 'border: 5px solid '+tab.BGColor.hex+';background-color: #ffffff00!important;' : 
  'border: 5px solid white;background-color: #ffffff00!important;'
      if(tab.LabelGradient){
        style = style+tab.LabelGradient
      }
        return style
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      AvatarStyle(tab){
        if(typeof tab.IMGHeight !== 'undefined'){
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:'+tab.IMGHeight+'px;' : 'height:'+tab.IMGHeight+'px;width: '+tab.IMGHeight+'px;min0width:'+tab.IMGHeight+'px;'
        }
        else{
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:150px;' : 'height:150px;width: 150px;min0width:150px;'
        }
      },
      DeactivateWebDialog(){
            this.WebFormDialogFancy = false
            this.WebFormDialogNonFancy = false
        },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>