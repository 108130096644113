<template>
  <span>
    
    <v-dialog v-model="ProductDialog" transition="dialog-bottom-transition"
        max-width="600">
           
        <v-card :dark="tab.DarkForm" 
        :style="tab.DarkForm ? 'background: linear-gradient(90deg, rgba(48,48,48,0.95) 0%, rgba(48,48,48,0.95) 100%);' : 
        'background: linear-gradient(90deg, rgba(235,235,235,0.95) 0%, rgba(235,235,235,0.95) 100%);'">
            <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" style="position: absolute;z-index:2;padding:0;"
            v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
       <EasyProductDialog :Directories="Directories" :Product="ActiveProduct" :PriceField="'Price'"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        :StoreProducts="StoreProducts" :EditingProduct="false" v-if="ProductDialog"
        :ProductCategories="ProductCategories" :ShippingGroups="ShippingGroups" @DeactivateDialog="DeactivateDialog"
        :ProductCollectionRef="ProductCollectionRef" :ShippingGroupsRef="ShippingGroupsRef" :CategoriesRef="CategoriesRef"
              />
        </v-card>
    </v-dialog>
   <!-- right so AGAIN -->
      <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'URL' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200"  :outlined="tab.Outlined" :href="tab.ActionButtonRoute" target="_blank" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
       <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
    </v-btn>
    <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'Route' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined" :to="'/'+tab.ActionButtonRoute" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
       <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
    </v-btn>
    <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'Add Cart Item' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined" @click="ToggleAddItemtoCart(tab.Product)" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
       <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{CartItemText}}</span>
    </v-btn>
     <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'Element Tag' && tab.Fancy" 
     :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined" 
     @click="SetElementIntoView(tab.ActionButtonElementIndex)"
     :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
       <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
    </v-btn>
    <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'File Download' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined"  @click="DownloadFile(tab.DownloadFile)" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
    </v-btn>
    <v-dialog v-model="WebFormDialogFancy" width="400">
            <template v-slot:activator="{ on }">
    <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" 
    v-if="tab.ActionButtonType === 'Web Form Dialog' && tab.Fancy && !tab.PopupStyle || tab.ActionButtonType === 'Content Dialog' && tab.Fancy" 
    :dark="tab.ActionBtnDark" v-on="on" x-large width="200"  :outlined="tab.Outlined" :style="tab.Outlined? 'font-size:0.7em;color:'+tab.ButonColor.hexa+';' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
    </v-btn>
            </template>
           
            <v-card :dark="tab.DarkForm" v-if="!FullScreenGalleryContentBox" class="transition-fast-in-fast-out" width="400"
                style="height: 100%;bottom: 0;position: absolute;right: 0;">
                <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" style="position: absolute;z-index:2;padding:0;"
                v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
            <WebFormTab :PageName="PageName" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"									
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"									
                />
                 <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :IsContentDialog="true"								
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :System="System"							
                />
            </v-card>
             <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"  v-else-if="WebFormDialogFancy"						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :IsContentDialog="true"								
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :System="System"							
                />
    </v-dialog>
    <v-dialog v-model="PopupStyleDialog" transition="dialog-bottom-transition"
        max-width="600">
            <template v-slot:activator="{ on }">
    <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" 
    v-if="tab.PopupStyle" 
    :dark="tab.ActionBtnDark" v-on="on" x-large width="200"  :outlined="tab.Outlined" :style="tab.Outlined? 'font-size:0.7em;color:'+tab.ButonColor.hexa+';' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
    </v-btn>
            </template>
           
        <v-card :dark="tab.DarkForm" 
        :style="tab.DarkForm ? 'background: linear-gradient(90deg, rgba(48,48,48,0.95) 0%, rgba(48,48,48,0.95) 100%);' : 
        'background: linear-gradient(90deg, rgba(235,235,235,0.95) 0%, rgba(235,235,235,0.95) 100%);'">
            <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" style="position: absolute;z-index:2;padding:0;"
            v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
        <WebFormTab :PageName="PageName" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"									
            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
            :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"									
            />
        </v-card>
    </v-dialog>
        <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'URL' && !tab.Fancy" :dark="tab.ActionBtnDark" :href="tab.ActionButtonRoute" :outlined="!tab.Outlined" target="_blank" :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
           <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
        </v-btn>
        <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'Route' && !tab.Fancy" :dark="tab.ActionBtnDark" :to="'/'+tab.ActionButtonRoute" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
           <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
        </v-btn>
         <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'Element Tag' && !tab.Fancy" 
         :dark="tab.ActionBtnDark" 
          @click="SetElementIntoView(tab.ActionButtonElementIndex)"
         :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
           <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
        </v-btn>
         <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'File Download' && !tab.Fancy" :dark="tab.ActionBtnDark" @click="DownloadFile(tab.DownloadFile)" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
           <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
        </v-btn>
    <v-dialog v-model="WebFormDialogNonFancy" width="400">
            <template v-slot:activator="{ on }">
        <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" v-if="tab.ActionButtonType === 'Web Form Dialog' && !tab.Fancy && !tab.PopupStyle || tab.ActionButtonType === 'Content Dialog' && !tab.Fancy" :dark="tab.ActionBtnDark" v-on="on" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
           <v-icon v-if="tab.ActionButtonIcon"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonIcon}}
    </v-icon><span v-if="!tab.ActionButtonIconOnly"
    :style="tab.ActionBTNTextColor ? 'color:'+tab.ActionBTNTextColor.hexa+';' : ''">{{tab.ActionButtonName}}</span>
        </v-btn>
            </template>
            <v-card :dark="tab.DarkForm" v-if="!FullScreenGalleryContentBox"  class="transition-fast-in-fast-out" width="400"
                style="height: 100%;bottom: 0;position: absolute;right: 0;">
                 <v-btn :text="tab.ActionButtonText" :width="tab.ActionButtonFullWidth ? 100 : ''" style="position: absolute;z-index:2;padding:0;" v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
                <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"								
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"						
                />
                
                <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :System="System"									
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"						
                />
            </v-card>
             <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else-if="WebFormDialogNonFancy"						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :System="System"									
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"						
                />
    </v-dialog>
  </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import EasyProductDialog from '@/components/SuitePlugins/EasyProducts/EasyProductDialog';

export default {
    props: ['System','SystemEntities','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {WebFormTab,EasyProductDialog},
    data() {
        return {
            ActiveProduct: '',
            ProductDialog: false,
            WebFormDialogFancy: false, 
            PopupStyleDialog: false,
            WebFormDialogNonFancy: false,
        }
    },
    computed:{
        CartItemText(){
            let oncheck = this.CartItems.find(obj => obj.id === this.tab.Product.id)
            if(oncheck){
                return oncheck.Qty+' in Cart'
            }
            else{
                return 'Add to Cart'
            }
        },
        CartItems(){
            return this.$store.state.CartItems
        },
        FullScreenGalleryContentBox(){
            let dialbox = this.tab.ContentDialogBox
            return this.tab.ContentDialogType && this.tab.ContentDialogType.Name === 'Gallery' && dialbox && dialbox.FullScreenDialog && dialbox.Assets[0]
        },
        ContentDialogBoxType() {
            if(this.tab.ContentDialogType){
                return () => import(`@/components/WebPages/RenderComponents/`+this.tab.ContentDialogType.ComponentName.split(' ').join('')+`ReadOnly`);
            }
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
    },
    created(){

    },
    methods:{
        DeactivateDialog(){
            this.ProductDialog = false
            this.ActiveProduct = ''
        },
        ToggleAddItemtoCart(Product){
            //console.log('ToggleAddItemtoCart',Product)
            this.ActiveProduct = Product
            this.ProductDialog = true
        },
        SetElementIntoView(elementindex){
            let index = elementindex
            let tabid = elementindex+'_Tab'
            let elmnt = document.getElementById(tabid)
            if(elmnt){
                elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        },
        DownloadFile(file){
            this.GetShortLivedURL(file)
        },
        GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            console.log(item)
            GetSignedInURL(item).then(result => {
            let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
            })
        },
         DeactivateWebDialog(){
            this.WebFormDialogFancy = false
            this.WebFormDialogNonFancy = false
            this.PopupStyleDialog = false
        },
    }
}
</script>

<style>

</style>
