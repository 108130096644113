
<template>

  <v-card :class="tab.Tight ? tab.BoxTransparency : tab.BoxTransparency+' mx-3'" height="100%" :color="tab.BGColor.hex" :dark="tab.IsDark" :tile="tab.Tile"  :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
     
    <v-row class="justify-center">
        <v-icon :style="'color:'+tab.HeaderColor.hex+';'" x-large>{{tab.CounterIcon}}</v-icon>
        </v-row>
    <v-row class="justify-center">
        <span class="display-2 d-flex" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.HeaderColor.hex">
            <span v-if="tab.IsCurrency" style="font-size: 28px;">			
           {{CurrencyFormatter(tab.CounterInteger,$store.state.DefaultCurrency.Currency)}}</span> <span v-if="!tab.IsCurrency">	
            {{tab.CounterInteger}}</span>            
        </span>
        </v-row>
  
        <v-row :class="tab.Center" >
            <span :style="'color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                {{tab.Title}}
                </span>
        </v-row>
        <v-row v-if="tab.SubTitle" :class="tab.Center" >
            <span :style="'color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                {{tab.SubTitle}}
                </span>
        </v-row>
      <v-row :class="tab.Center" v-if="tab.HasCounterData || tab.HasAnalysisDrillDown">
          <v-btn color="blue" x-small  @click="EmitDrillDown(tab.AnalysisDrillDownMethod,tab)" v-if="tab.HasAnalysisDrillDown">
                Analyze
            </v-btn>
           <v-dialog v-model="CounterDataDialog" width="400">
                <template v-slot:activator="{ on }">
                    <v-btn x-small  v-on="on" v-if="tab.HasCounterData">
                        Show
                    </v-btn>
                    </template>
                <v-card class="transition-fast-in-fast-out" width="400"
                    style="height: 100%;bottom: 0;position: absolute;right: 0;">
                    
                    <v-card-title class="recordtoolbar white--text">
                       <v-btn @click="CounterDataDialog = !CounterDataDialog" color="red" icon><v-icon>mdi-close</v-icon></v-btn>Counter Data
                    </v-card-title>
                    <v-card-text>
                        <v-virtual-scroll 
                            width="100%"
                            class="transparent"
                            :items="tab.Data"
                            :item-height="TabCounterDataItemHeight"
                            :height="WindowHeight*0.9"
                          >
                              <template v-slot:default="{ item }">
                                <!-- <v-list dense > -->
                            <ObjectViewer :DataObject="item"
                            />
                            <v-divider>
                            </v-divider>
                        <!-- </v-list> -->
                              </template>
                        </v-virtual-scroll>
                    </v-card-text>
                </v-card>
           </v-dialog>
        </v-row>
    <!-- <v-card-text class="display-2 d-flex'" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex">
        
        </v-card-text> -->
        <!-- <v-card-text v-if="tab.HasImage">
        <v-layout :class="tab.Justify" row>
            <v-avatar size="110" tile>
        <v-img  v-if="tab.HasImage && tab.IMG"
        :contain="!tab.Clipped"
        :height="tab.Height"
                :src="tab.IMG"
                :style="CheckifClipped(tab)"
            
                />
                <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
        :height="tab.Height"
                src="@/assets/ImageHolder.png"
                />
            </v-avatar>
        </v-layout>
    </v-card-text> -->
    <!-- <v-list-item dense class="caption justify-center"> -->
        <v-list dense style="padding-top: 20px;" v-if="tab.ExtendedFilters">
        <v-list-item v-for="filter in tab.ExtendedFilters" :key="filter.itemObjKey"
        :ripple="filter.Count > 0"
        :style="filter.Count === 0 ? 'cursor: default !important;' : ''"
        @click="filter.Count === 0 ? '' : InteractMethod(tab,tab.ExtendedFilterField,filter)"
        dense :class="$vuetify.theme.dark ? 'caption darkdetailslistoutline' : 'caption detailslistoutline'">
            <v-list-item-content class="caption">
                <!-- :class="filter.Count > 0 ? filter.Color+'--text' : 'grey--text'"> -->
                <v-list-item-title style="font-weight: 400;" :class="filter.Count === 0 ? 'grey--text' : ''">
                {{filter.Name}}
                </v-list-item-title>
                <!-- <v-list-item-subttitle :class="filter.Count > 0 ? filter.Color+'--text' : 'grey--text'"> -->
                <v-list-item-subtitle :class="filter.Count === 0 ? 'grey--text' : ''">
                    {{filter.Count}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-avatar :color="filter.Count > 0 ? filter.Color : 'grey'" size="20">
                </v-avatar>
            </v-list-item-action>
        </v-list-item>
        </v-list>
    <!-- </v-list-item> -->
    </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ObjectViewer from '@/components/WebPages/RenderComponents/ObjectViewer';


export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','SampleOnly'],
    components: {
        ObjectViewer
        },
    data() {
    return {      
        CounterDataDialog: false,
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        TabCounterDataItemHeight(){
            if(this.tab.Data && this.tab.Data.length > 0){
                let data = Object.entries(this.tab.Data[0])
                //console.log(data)
                let propslength =  data.length
                return propslength*45
            }
        },
      WindowHeight(){
          return window.innerHeight
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        InteractMethod(tab,ExtendedFilterField,filter){
            //console.log(tab,ExtendedFilterField,filter)
            this.$emit('InteractMethod',tab,ExtendedFilterField,filter)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      EmitDrillDown(MethodName,tab){
          this.$emit('EmitDrillDown',MethodName,tab)
      },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           //console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        


    