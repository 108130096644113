<template>
  <div>
    
    <v-avatar size="40" v-if="Display === 'YM Card'" :class="'noticeboarddate '+Color+' '+TextColor+'--text justify-center'" rounded>        
            {{MonthCreated}}
                <br>
                {{YearCreated}}
        </v-avatar>
    <v-avatar size="40" v-if="Display === 'Card'" :class="'noticeboarddate '+Color+' white--text justify-center'" rounded>        
            {{MonthCreated}}
                <br>
                {{DayCreated}}
        </v-avatar>
        <div v-if="Display === 'Read Date'">
            {{ReadDate}}
        </div>
        <div v-if="Display === 'String Date'">
            {{StringDate}}
        </div>
        <div v-if="Display === 'UTC Date'">
            {{Value.toUTCString()}}
        </div>
          <v-list-item class="justify-start" v-if="Display === 'Full Time Card'">
             <v-list-item-avatar rounded size="40" :class="'noticeboarddate '+Color+' white--text justify-center'">
                {{MonthCreated}}
                <br>
                {{DayCreated}}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="text-align: start;">       
           {{Value.toDateString().split(' ')[0]}}
              </v-list-item-title>
              <v-list-item-subtitle style="text-align: start;">
            {{Value.toLocaleTimeString().split(' ')[0]}}
              </v-list-item-subtitle>
            </v-list-item-content>
             <v-list-item-avatar rounded size="40" :class="'noticeboarddate '+Color+' white--text justify-center'">               
            {{Value.toLocaleTimeString().split(' ')[1]}}
            </v-list-item-avatar>
          </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','Value','Display','Color','TextColor'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        DateObject(){
            return this.Value && this.Value.toDate ? this.Value.toDate() : 
            this.Value
            //asuming it is prepped 
        },
        MonthCreated(){
            return this.DateCardFormatter(this.DateObject,'month')
        },
        DayCreated(){
            return this.DateCardFormatter(this.DateObject,'date')
        },
        YearCreated(){
          return this.DateObject.getFullYear()
        },
        ReadDate(){
            return this.TimestampFormatterSTRINGDayText(this.DateObject)
        },
        StringDate(){
            return this.TimestampFormatterSTRING(this.DateObject)
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        DateCardFormatter(dateobj,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = dateobj.getHours();
       
        var min = dateobj.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = dateobj.getDate();
        var month = months[dateobj.getMonth()];
        var year = dateobj.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(dateobj,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        if(sec < 10){
          sec = "0"+sec
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
    }
}
</script>

<style>

</style>



