<template>
  <div style="padding: 20px;"> 
    <v-dialog v-model="JobDialog" max-width="450" min-width="300">
        <v-card tile v-if="JobDialog">
            <v-card-title class="overline justify-end">
                {{AsHighlight ? 'Highlight/ Achievement' : 'Position'}}<v-spacer>
                    </v-spacer>
                    <v-btn absolute right small @click="DeactivateJobDialog()" style="z-index:100!important;" fab icon><v-icon small>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
            <v-text-field v-if="!AsHighlight" dense outlined v-model="Job.Employer" label="Employer" placeholder="Name of Employer"/>
            <v-text-field v-if="AsHighlight" dense outlined v-model="Job.Institution" label="Institution" placeholder="Name of Institution"/>
            <v-text-field dense outlined v-model="Job.Title" label="Title" :placeholder="AsHighlight ? 'Highlight Title' : 'Title of Position'"/>  
            </v-card-text>
            <v-card-text>                              
            <ContentEditableField :FieldID="'Job_Description'"
            :FieldObject="Job" :FieldName="'Description'"
            :FieldValue="Job.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-card-text>
            <v-card-text>
            <FieldValueEditerComponent v-if="!AsHighlight"
            @UploadFileSelect="UploadFileSelect" 
            @UpdateEditableField="UpdateEditableField" :Record="Job" 
            :field="{id: 'Start',Name: 'Start',DisplayName:'Start',Type: 'Date'}"
            :CurrentEntity="CurrentEntity"
            />
            <FieldValueEditerComponent v-if="!AsHighlight"
            @UploadFileSelect="UploadFileSelect" 
            @UpdateEditableField="UpdateEditableField" :Record="Job" 
            :field="{id: 'End',Name: 'End',DisplayName:'End',Type: 'Date'}"
            :CurrentEntity="CurrentEntity"
            />
            <FieldValueEditerComponent v-if="AsHighlight"
            @UploadFileSelect="UploadFileSelect" 
            @UpdateEditableField="UpdateEditableField" :Record="Job" 
            :field="{id: 'Achieved',Name: 'Achieved',DisplayName:'Achieved',Type: 'Date'}"
            :CurrentEntity="CurrentEntity"
            />
                <!-- qucikdialman 1. employer  2. position title 3.time period 4. Description -->
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn outlined color="success"
                @click="SaveJob()">Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="QualificationDialog" max-width="450" min-width="300">
        <v-card tile>
            <v-card-title class="overline justify-end">
            <v-btn absolute right small @click="DeactivateQualificationDialog()" style="z-index:100!important;" fab icon><v-icon small>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field dense outlined v-model="Qualification.Title" label="Title" placeholder="Title of Qualification"/>
                <v-switch v-model="Qualification.Completed" label="Completed"/>
                <v-autocomplete dense v-model="Qualification.Type" return-object
                outlined placeholder="Type of Qualification" label="Type"
                    :items="QualificationTypes" item-text="Name"
                />
                <KeyWordManager :RecordType="'Qualification'" :PreventRating="true"
                :PreventMultiple="true" :Recordid="FeaturedMember.id" v-if="QualificationDialog"
                    :RecordObj="Qualification" :ObjectProp="'Institute'"
                    :KeywordType="{ID: 2000005,Name: 'Member Institutions'}"
                    @UpdateRecordKeywords="UpdateQualificationInstitution"
                />
                <KeyWordManager :RecordType="'Qualification'" :PreventRating="true"
                :PreventMultiple="true" :Recordid="FeaturedMember.id" v-if="QualificationDialog"
                    :RecordObj="Qualification" :ObjectProp="'Field'"
                    :KeywordType="{ID: 2000006,Name: 'Member Fields of Study'}"
                    @UpdateRecordKeywords="UpdateQualificationInstitution"
                />
                <!-- <v-text-field dense outlined v-model="Qualification.Title" label="Title"/>
                <v-text-field dense outlined v-model="Qualification.Title" label="Title"/>
                start - end -  -->
                <v-menu 
                    v-model="QualificationStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y  class="smallinput"
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field  class="smallinput"
                        :placeholder="'Start'"
                        prepend-icon="mdi-calendar-month"
                        readonly  :rules="[$store.state.formrules.required] "
                        v-on="on"
                        v-model="QualificationStart"
                        ></v-text-field>
                        </template>
                    <v-date-picker v-model="QualificationStart"
                            @input="QualificationStartDateMenu = false"></v-date-picker>
                    </v-menu>
                <v-menu 
                    v-model="QualificationFinishDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y  class="smallinput"
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field  class="smallinput"
                        :placeholder="'Finish'"
                        prepend-icon="mdi-calendar-month"
                        readonly  :rules="[$store.state.formrules.required] "
                        v-on="on"
                        v-model="QualificationFinish"
                        ></v-text-field>
                        </template>
                    <v-date-picker v-model="QualificationFinish"
                            @input="QualificationFinishDateMenu = false"></v-date-picker>
                    </v-menu>
                    <input id="qualificationupload"
                    @change="QualificationFileSelect($event)"
                     name="qualificationupload" accept="application/pdf" style="visibility: hidden;display: none;"
                type="file">
                <label for="qualificationupload"><span v-if="QualificationFile" class="texbtn btnhover elevation-1">
                  <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Change File
                  </span><span v-if="!QualificationFile" class="texbtn btnhover elevation-1">
                    <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Select File
                  </span>
                  {{QualificationFile.name}}
                </label>
                <!-- <input @change="QualificationFileSelect($event)" name="qualificationupload" type="file" accept="application/pdf"> -->
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn outlined color="success"
                @click="SaveQualification()">Save
                </v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
     <HelperComponent :HelperActive="HelperActive" :HelperElements="ComputedHelperElements" :OffsetX="'left'" :OffsetXVal="150" :OffsetY="'top'" :OffsetYVal="0"
    @DeactivateHelper="DeactivateHelper" @ActivateHelper="ActivateHelper" @StopHelper="StopHelper"  :KeepPulseIndexes=[]
    :HelperID="ActiveHelperID"
    />
    <v-card-title v-if="!HidePage">
        <v-btn id="pageeditbtn" absolute @click="RoutetoTemplate()" outlined style="width:50%;left: 25%;">
            Edit Page
        </v-btn>
    </v-card-title>
              <v-list-item class="justify-center" v-if="!HidePage">
           <!-- <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
            <v-progress-circular v-on="on" v-bind="attrs" class="mediumoverline"
                :rotate="360"
                :size="180"
                :width="30"
                :value="progressvalue"
                :color="'purple'"
                >
                {{ progressvalue }}%
                </v-progress-circular>
            </template>
             <span v-html="ComputedProgressItem ? ComputedProgressItem.Tootlip : 'Select an item'">
                </span>
           </v-tooltip> -->
             <QRImageFieldCard  :elmntid="progressvalue === 100 ? 'QRImage' : ''"
        :System="System" :SystemEntities="SystemEntities" :id="progressvalue === 100 ? '' : 'QRImage'"
        :col="QRFieldCol" :Record="FeaturedMember" :CanEdit="false"
        @SaveRecord="SaveRecord" :CurrentEntity="ComputedActiveEntity"
    /> 
                </v-list-item>
                  <v-card-text>
                    <!-- background or transtab -->
                    <v-tabs class="transtab" centered>
                        <v-tab>Basic</v-tab>
                        <v-tab-item>
                   <v-row class="justify-start">                
                <v-col :style="col.Type === 'Counter' ? 'flex-grow: 0!important;' : 'flex-basis: auto;'" v-for="(col,coli) in InfoColumns" :key="col.itemObjKey"
                :xl="col.FlexXLRG" :lg="col.FlexLarge" :md="col.FlexMedium" :sm="col.FlexSmall" :xs="col.FlexXSmall">
                  
                    <v-card :class="row.Transparent ? 'transparent' : ''" :tile="row.Tile" :flat="row.Flat" :elevation="row.Flat? 0 : row.Elevation"
                     height="100%" min-height="20px" width="100%" :style="row.Rounded ? 'border-radius: 25px;' : ''"
                     :color="col.Type === 'Counter' ? col.Color? col.Color : 'red' : ''"
                    >
                     <v-card-subtitle class="subtleoverline" v-if="!HeaderlessColTypes.includes(col.Type)" >{{col.Name}}
                    </v-card-subtitle>                   
                    <v-card-text>
                        <DashProgressCard v-if="col.Type === 'Progress Card'"
                         :System="System" :SystemEntities="SystemEntities" :elmntid="'progresscard'"
                          :col="col" :Record="ComputedProgressItem" :CanEdit="false"
                          @SaveRecord="SaveRecord"
                        />
                        <DashFieldGroup v-if="col.Type === 'Field Group'" id="memberdetails"
                        :System="System" :SystemEntities="SystemEntities"
                    :col="col" :Record="FeaturedMember" :CanEdit="true"
                    :EntityDataRef="EntityDataRef"
                    :RelatedObj="RelatedObj"
                    @SaveRecord="SaveRecord"
                    />
                    <DashFieldCard v-if="col.Type === 'Field Card'"
                         :System="System" :SystemEntities="SystemEntities"
                        :col="col" :Record="FeaturedMember" :CanEdit="true"
                        :EntityDataRef="EntityDataRef" :CurrentEntity="CurrentEntity"
                         :RelatedObj="RelatedObj" @SaveDocumentField="SaveDocumentField"
                        @SaveRecord="SaveRecord" @onPhotoUpload="onPhotoUpload"
                        />
                    </v-card-text>
                    </v-card>
                </v-col>
                   </v-row>
     <!-- <DashFieldGroup v-if="ComputedCol.Type === 'Field Group'"
            :System="System" :SystemEntities="SystemEntities"
        :col="ComputedCol" :Record="FeaturedMember" :CanEdit="true"
        :EntityDataRef="EntityDataRef"
        :RelatedObj="RelatedObj"
        @SaveRecord="SaveRecord"
        /> -->
                        </v-tab-item>
                        <v-tab>Experience</v-tab>
                        
                        <v-tab-item>
                             <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-img contain :src="FeaturedMember && FeaturedMember.Profile_Photo && FeaturedMember.Profile_Photo.fileurl ? 
                                    FeaturedMember.Profile_Photo.fileurl : FeaturedMember.Profile_Photo ? 
                                    FeaturedMember.Profile_Photo : require('@/assets/BnPFullLogo.png')"/>
                                </v-avatar>
                            About Me
                            </v-card-title>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-account-question</v-icon>
                                </v-avatar>
                            Characteristics
                            </v-card-title>
                                <v-card-text>
                                    <KeyWordManager :RecordType="'Resume'" :PreventAdd="true" :Recordid="FeaturedMember.id"
                                        :RecordObj="FeaturedMember" :ObjectProp="'Characteristics'"
                                        :KeywordType="{ID: 2000001,Name: 'Member Characteristics'}"
                                        @UpdateRecordKeywords="UpdateRecordKeywords"
                                    />
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-soccer</v-icon>
                                </v-avatar>
                            Hobbies                                   
                            </v-card-title>
                                <v-card-text>
                                  <KeyWordManager :RecordType="'Resume'" :Recordid="FeaturedMember.id"
                                        :RecordObj="FeaturedMember" :ObjectProp="'Hobbies'"
                                        :KeywordType="{ID: 2000002,Name: 'Member Hobbies'}"
                                        @UpdateRecordKeywords="UpdateRecordKeywords"
                                    />
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-arm-flex</v-icon>
                                </v-avatar>
                            Skills
                            </v-card-title>
                                <v-card-text>
                              <KeyWordManager :RecordType="'Resume'" :Recordid="FeaturedMember.id"
                                :RecordObj="FeaturedMember" :ObjectProp="'Skills'"
                                :KeywordType="{ID: 2000003,Name: 'Member Skills'}"
                                @UpdateRecordKeywords="UpdateRecordKeywords"
                                    />
                                </v-card-text>
                            </v-card>
                             <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-arrow-decision</v-icon>
                                </v-avatar>
                            Methodologies
                            </v-card-title>
                                <v-card-text>
                              <KeyWordManager :RecordType="'Resume'" :Recordid="FeaturedMember.id"
                                :RecordObj="FeaturedMember" :ObjectProp="'Methodologies'"
                                :KeywordType="{ID: 2000007,Name: 'Member Methodologies'}"
                                @UpdateRecordKeywords="UpdateRecordKeywords"
                                    />
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-factory</v-icon>
                                </v-avatar>
                            Industries
                            </v-card-title>
                                <v-card-text>
                                   <KeyWordManager :RecordType="'Resume'" :Recordid="FeaturedMember.id"
                                :RecordObj="FeaturedMember" :ObjectProp="'Industries'"
                                :KeywordType="{ID: 2000004,Name: 'Member Industries'}"
                                @UpdateRecordKeywords="UpdateRecordKeywords"
                                    />
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-book-education</v-icon>
                                </v-avatar>
                            Qualifications<v-spacer></v-spacer>
                                    <v-btn @click="ActivateQualificationDialog()" outlined><v-icon>mdi-plus</v-icon>Add New</v-btn>
                            </v-card-title>
                                <v-card-text>
                                    <v-autocomplete @change="UpdateMemberProp('HighestSchoolQualification')"
                                    v-model="FeaturedMember.HighestSchoolQualification" label="School Qualification" 
                                    item-text="Name" outlined dense
                                    placeholder="Highest School Qualification"
                                    :items="SchoolQualifications" return-object/>
                                    <v-list v-if="FeaturedMember.Qualifications && !RefreshingQualifications">
                                        <v-list-item @click="ActivateQualificationDialog(qual,quali)" v-for="(qual,quali) in PreparedQualifications(FeaturedMember.Qualifications)" :key="qual.itemObjKey">
                                            <!-- <v-list-item-avatar>
                                                <v-icon>mdi-eye</v-icon>
                                            </v-list-item-avatar> -->
                                             <v-list-item-avatar :class="qual.TextColor+'--text'" :color="qual.Color">
                                                {{qual.Abbr}}
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{qual.Title}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{qual.Institute.Name}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <DateRender :Value="qual.FinishObject" :TextColor="qual.TextColor"
                                                    :Display="'YM Card'" :Color="qual.Color"
                                                    /> 
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-timeline-text</v-icon>
                                </v-avatar>
                            Work History<v-spacer></v-spacer>
                            <v-btn @click="ActivateJobDialog()" outlined><v-icon>mdi-plus</v-icon>Add New</v-btn>
                            </v-card-title>
                            <v-card-text>
                                <!-- positions loop -->
                                <v-list v-if="FeaturedMember.Jobs">
                                    <TimelineTab  :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                                    :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                                    :tab="ComputedJobsElmnt" @OpenDialog="OpenDialog" :i="i"
                                    />
                                </v-list>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" class="siteconfigcard">
                            <v-card-title style="border-radius: 15px;" class="justify-start mediumoverline">
                                <v-avatar size="80" class="mx-1">
                                    <v-icon x-large>mdi-seal</v-icon>
                                </v-avatar>
                            Highlights/ Achievements<v-spacer></v-spacer>
                                    <v-btn @click="ActivateJobDialog(true)" outlined><v-icon>mdi-plus</v-icon>Add New</v-btn>
                            </v-card-title>
                                <v-card-text>
                                     <v-list v-if="FeaturedMember.Highlights">
                                    <TimelineTab  :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                                    :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                                    :tab="ComputedHighlightsElmnt" @OpenDialog="OpenDialog" :i="i"
                                    />
                                </v-list>
                                </v-card-text>
                            </v-card>
                   
                        </v-tab-item>
                    </v-tabs>
                  </v-card-text>
    <!-- </v-card> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DashFieldGroup from '@/components/Database/Fields/Hybrid/DashFieldGroup'
import DashFieldCard from '@/components/Database/Fields/Hybrid/DashFieldCard'
import DashProgressCard from '@/components/Database/Fields/Hybrid/DashProgressCard'
import QRImageFieldCard from '@/components/Database/Fields/Hybrid/FieldCards/QRImageFieldCard'
import HelperComponent from '@/components/General/HelperComponent'
import KeyWordManager from '@/components/SuitePlugins/FeaturedMembers/KeyWordManager';
import DateRender from '@/components/Database/Fields/ReadOnly/DateRender';	
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import TimelineTab from '@/components/WebPages/RenderComponents/TimelineTabReadOnly';
export default {
    props: ['System','SystemEntities','FeaturedMemberid','ProvidedMember','HidePage'],
    components: {DashFieldGroup,DashFieldCard,DashProgressCard,QRImageFieldCard,TimelineTab,
    HelperComponent,KeyWordManager,DateRender,ContentEditableField,FieldValueEditerComponent},
    data() {
        return {
            JobsElmnt: {	
                StaticVariables: ['Items'],				
            Category: 'Misc',								
                RowColor: '#00ffff',									
                Name: 'Timeline',									
                Items: [									
                    {									
                    HasIcon: true,									
                    Name: 'Timeline Item',									
                    Title: 'Lorem Ipsum Dolor',									
                    Description: 'Lorem ipsum dolor sit amet, no nam oblique veritus.',									
                    HasActionButton: true,									
                    ActionButtonName: 'Button',	
                    ActionButtonType: 'URL',
                    ActionButtonRoute: 'https://www.rapidapps.co.za',
                    ButonColor: {
                    hex: '#ff0000ff',
                    hexa: '#ff0000ff'
                    },										
                    TitleColor: 'white',									
                    color: 'red lighten-2',									
                    icon: 'mdi-star',									
                    },									
                    {									
                    HasIcon: true,									
                    Name: 'Timeline Item',									
                    Title: 'Lorem Ipsum Dolor',									
                    Description: 'Lorem ipsum dolor sit amet, no nam oblique veritus.',									
                    HasActionButton: true,									
                    ActionButtonName: 'Button',	
                    ActionButtonType: 'URL',
                    ActionButtonRoute: 'https://www.rapidapps.co.za',
                    ButonColor: {
                    hex: '#ff0000ff',
                    hexa: '#ff0000ff'
                    },									
                    TitleColor: 'white',									
                    color: 'purple darken-1',									
                    icon: 'mdi-book-variant',									
                    },									
                ],									
                ShowComponents: true,									
                Icon: 'mdi-timeline-text-outline',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus.`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            JobDialog: false,
            AsHighlight: false,
            Job: {},
            QualificationStart: '',
            QualificationFinish: '',
            QualificationStartDateMenu: false,
            QualificationFinishDateMenu: false,
            RefreshingQualifications: false,
            QualificationFile: '',
            QualificationTypes:  [
                {ID: 1000001,Name: 'Certificate'},{ID: 1000002,Name: 'Higher Certificate'},
                {ID: 1000003,Name: 'National Diploma'},{ID: 1000004,Name: 'Bachelors'},
                {ID: 1000005,Name: 'Honours'},{ID: 1000006,Name: 'Masters'},
                {ID: 1000007,Name: 'Doctorate'}],
            SchoolQualifications: [
                {ID: 1000001,Name: 'Grade 9'},{ID: 1000002,Name: 'Grade 10'},
                {ID: 1000003,Name: 'Grade 11'},{ID: 1000004,Name: 'Matric'}],
            QualificationDialog: false,
            //I thnk "Highest School" then being Gr9/Gr10/Gr11/Matric
            Qualification: {
                Finish: format(new Date(),'yyyy-MM-dd'),
                Start: format(new Date(),'yyyy-MM-dd')
            },
            DefaultQualification: {
                Finish: format(new Date(),'yyyy-MM-dd'),
                Start: format(new Date(),'yyyy-MM-dd')
            },
            QualificationIndex: -1,
            ActiveHelperID: 'FeaturedMemberBuilder',
            HelperElements: [
                    {elmntid: 'memberdetails',Icon: 'mdi-account',Title: 'Details',Text: `Here you will capture your information needed to complete your profile.`},
                    {elmntid: 'pageeditbtn',Icon: 'mdi-pencil',Title: 'Page Builder',Text: `Here you will build your page, once the informaiton has ben captured.`},
                    {elmntid: 'QRImage',Icon: 'mdi-qrcode',Title: 'QR Image',Text: `Your QR Image  will appear here once completed.`},
                    {elmntid: 'progresscard',Icon: 'mdi-progress-alert',Title: 'Progress',Text: `The Progress Card will guide you through the needed steps.`},                    
            ],
            ActivatingHelper: false,
            HelperActive: false,
            ActiveProgressItem: {title: 'Site General',ViewName: 'SiteGeneralView',icon: 'mdi-cogs',StepRequirements: [
         
          {Check: 1, Name: 'Basic Info',ParentObj: 'FeaturedMember',Prop: {Name: 'Basic_Info',Type: 'Object',ChildProps: []},
          Tootlip: `<p>Let's add contact information so people can get in contact with you.<br> This info will also become available on your electronic contact card.</p>`},
          {Check: 2, Name: 'Profile Photo',ParentObj: 'FeaturedMember',Prop: {Name: 'Profile Photo',Type: 'Object',ChildProps: []},
          Tootlip: `<p>Lets add a Profile Photo.</p>`},
            {Check: 3, Name: 'Personal',ParentObj: 'FeaturedMember',Prop: {Name: 'Personal',Type: 'Object',ChildProps: []},
          Tootlip: `<p>Let's capture some more personal information.</p>`},
          {Check: 4, Name: 'Overview',ParentObj: 'FeaturedMember',Prop: {Name: 'Overview',Type: 'Object',ChildProps: []},
          Tootlip: `<p>Tell people about yourself.<br>Who you are, personality and characteristics.</p>`},
           {Check: 5, Name: 'Objectives',ParentObj: 'FeaturedMember',Prop: {Name: 'Objectives',Type: 'Object',ChildProps: []},
          Tootlip: `<p>Let's tell the audience what you're Objectives are.<br>This helps them undertand what you are looking for with new opportunities.</p>`},
          
            {Check: 6, Name: 'Creating Pages',ParentObj: 'FeaturedMember',Prop: {Name: 'Published',DisplayName: 'Has Pages',Type: 'Object',
            ChildProps: [
                {Name: 'Published',DisplayName: 'Has Pages',Type: 'Boolean'},{Name: 'DefaultPage',DisplayName: 'Has Pages',Type: 'Single Line Text'}
            ]},
          Optional: false,Tootlip: `<p>Let's make this beautiful with a public facing landing page!</p>`}
          ]},
            HeaderlessColTypes: [
                'Counter',
                'Field Card',
                // 'Field Group'
            ],
            row: {
                Name: 'Some Row',
                Columns: []
            },
            FeaturedMember: '',
            RelatedObj: {},
            DefaultCol: {
                Flat: true,
                Transparent: true,
                Elevation: 0,
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 12,									
                FlexXSmall: 12,
                Name: 'Some Col',
            },            
            AllFieldTypes: ['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
        'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
        'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Social Lookup','Custom Object'],
            SliderFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            ProgressFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            CurrencyFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            LinkFieldTypes: ['Common Field','Shared Doc Link','Route Path','Lookup','Social Lookup','Parent Field'],
            ToggleFieldTypes: ['Boolean','Parent Field'],
            OptionsFieldTypes: ['Option Set','Parent Field','Radio Group'],
            TextFieldTypes: ['Single Line Text','Common Field','Boolean','Option Set','AutoNumber Field',
                    'Number Field','Rollup Calculation','Calculated Field','Lookup','Social Lookup',
                    'Parent Field'],
            WriteupTypes: ['Multiple Lines Text']

        }
    },	
    computed:{
        ComputedHighlightsElmnt(){
            let elmnt = Object.assign({},this.JobsElmnt)
            elmnt.Items = this.FeaturedMember.Highlights.map(job => {
                let jobobj = Object.assign({},job)
                jobobj.Title = job.Title +' ('+job.Institution+')'
                if(job.Achieved){
                    jobobj.TitleDate = job.Achieved
                }
                jobobj.AchievedObject = jobobj.Achieved.toDate ? jobobj.Achieved.toDate() : jobobj.Achieved
                jobobj.TimestampSort = jobobj.AchievedObject.getTime()
                return jobobj
            }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
            return elmnt
        },
        ComputedJobsElmnt(){
            let elmnt = Object.assign({},this.JobsElmnt)
            elmnt.Items = this.FeaturedMember.Jobs.map(job => {
                let jobobj = Object.assign({},job)
                jobobj.Title = job.Title +' ('+job.Employer+')'
                if(job.End){
                    jobobj.TitleDate = job.End
                }
                jobobj.EndObject = jobobj.End.toDate ? jobobj.End.toDate() : jobobj.End
                jobobj.TimestampSort = jobobj.EndObject.getTime()
                return jobobj
            }).sort((a, b) => {
                        var key1 = a.TimestampSort;
                        var key2 = b.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
            return elmnt
        },
        ComputedHelperElements(){
            return this.HelperElements.filter(elmnt => {
                return this.progressvalue === 100 ? elmnt.Title === 'QR Image' : elmnt
            }).map(elmnt => {
                if(this.progressvalue === 100){
                    elmnt.Text = 'This QR Image links directly to your profile. You can download, share and use it.'
                }
                return elmnt
            })
            //basiclaly only QR once published
        },
        HelperHalted(){
            return this.StoppedHelpers.includes(this.ActiveHelperID)
        },
        StoppedHelpers(){
            return this.$store.state.StoppedHelpers
        },
        AppURL(){
            return this.System && this.System.ClientAppURL ? this.System.ClientAppURL : window.location.host
        },
        QRFieldCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'
            col.Field = {id: 'MemberPageQR',Name: 'MemberPageQR',DisplayName: 'Page QR',Type: 'Common Field',CommonFieldType: 'QR Link',QRLinkFieldType: 'Static URL'}
            col.Field.FieldType = this.progressvalue === 101 ? 'QRImageField' : ''
            col.Field.StaticQRURL = this.AppURL+'/FeaturedMember/'+this.FeaturedMemberid+'/'+this.FeaturedMemberDefaultPage
            col.FieldObj = col.Field
            return col
        },
        ComputedProgressItem(){
            let item = Object.assign({},this.ActiveProgressItem)
            item.Steps = item.StepRequirements.length
            item.IncompletedSteps = item.StepRequirements.filter(req => {
            let incomplete = false
            if(req.Name === 'Basic Info'){
                req.Prop.ChildProps = this.BasicFields
            }
            else if(req.Name === 'Profile Photo'){
                req.Prop.ChildProps = [this.AvatarField]
            }
            else if(req.Name === 'Personal'){
                req.Prop.ChildProps = this.PersonalFields
            }
            else if(req.Name === 'Overview'){
                req.Prop.ChildProps = [Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Objectives'))]
            }
            else if(req.Name === 'Objectives'){
                req.Prop.ChildProps = [Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Overview'))]
            }
                if(!this[req.ParentObj]){
                    incomplete = true
                }
                else if(this[req.ParentObj] && !this[req.ParentObj][req.Prop.Name] && req.Prop.Type !== 'Object'){
                    incomplete = true
                }
                else if(req.Prop.Type === 'Object'){
                    req.Prop.ChildProps.map(childprp => {
                        if(!this[req.ParentObj][childprp.Name] && !childprp.Optional){
                            incomplete = true
                        }
                        else if(this[req.ParentObj][childprp.Name] && this[req.ParentObj][childprp.Name].length === 0 && childprp.Type === 'Array' && childprp.Checking === 'Length'&& !childprp.Optional){
                            incomplete = true
                        }
                    })
                }
            return incomplete
            })
            item.StepRequirements = item.StepRequirements.map(req => {
                let incompletecheck = item.IncompletedSteps.find(obj => obj.Check === req.Check)
                if(!incompletecheck){
                    req.Completed = true
                }
                return req
            })
            let Incomplete = item.IncompletedSteps.length 
            item.StepsCompleted = item.Steps-Incomplete     
            item.Progress = Math.floor((item.StepsCompleted/item.Steps)*Math.floor(100))
            item.Tootlip = `All done`
            if(item.Progress < 100){
            item.Tootlip = item.IncompletedSteps[0].Tootlip
            }
            return item
        },
        progressvalue(){
        return this.ComputedProgressItem ? this.ComputedProgressItem.Progress : 0
        },
        UserMarketAccount(){
        return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
        },
        UserSiteAccount(){
        return this.IsPrimarySite ? '' : 
        this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.id === this.$store.state.ActiveSuiteid) : ''
        },
        UserBillingAccounts(){
        return this.$store.state.PrimaryUserObj && this.$store.state.PrimaryUserObj.BillingAccounts ? this.$store.state.PrimaryUserObj.BillingAccounts : []
        },
        AppMonetizeBU(){
            return this.$store.state.PrimaryMonetizeBU 
            //System.Monetization_BU ? this.System.Monetization_BU : ''
            //we now also have SiteMonetizationBU which we can work in later should user have monetizaiton occuring on their site
        },
        FeaturedMemberDefaultPage(){
          return this.$store.state.FeaturedMemberDefaultPage
        },
        ExperienceColumns(){
            return []
        },
        InfoColumns(){
            return [this.ProgressCol,this.AvatarFieldCol,this.BasicFieldsCol,this.PersonalCol,this.OverviewCol,this.ObjectivesCol]//we need
            //education (at least secondary and tertiary, not just single line text, list items)
            //skils , strengths, weakenesses, languages
            //industries (ANOTHER list)
            //Work history (LIST)
        },
        PersonalFields(){
            return [
                {id: 'Age',Name: 'Age',DisplayName: 'Age',Type: 'Number Field',Min: 18,Max: 99},
                {id: 'Gender',Name: 'Gender',DisplayName: 'Gender',Type: 'Option Set',Options: ['Male','Female'],TextOnly: true},
                {id: 'Race',Name: 'Race',DisplayName: 'Race',Type: 'Option Set',Options: ['Asian','Black','Coloured','Indian','White'],TextOnly: true},
                {id: 'Nationality',Name: 'Nationality',DisplayName: 'Nationality',Type: 'Option Set',Options: ['South African','Other'],TextOnly: true},
                {id: 'Relationship',Name: 'Relationship',DisplayName: 'Relationship',Type: 'Option Set',Options: ['Married','Unmarried'],TextOnly: true},
                {id: 'City_Town',Name: 'City_Town',DisplayName: 'City/Town',Type: 'Single Line Text'},
                {id: 'Driving_License',Name: 'Driving_License',DisplayName: 'Driving License',Type: 'Option Set',Options: ['Code A1','Code A','Code B','Code EB','Code C1','Code C','Code EC1','Code EC'],TextOnly: true},
                
            ]
        },
        ProgressCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Progress Card'   
            col.Name = 'Progress'         
            col.Color = 'purple'
            col.Field = {id: 'Progress',Name: 'Progress',Type: 'Number Field',DisplayName: 'Progress',IsPercentage: true}
            col.FieldObj = col.Field
            return col
        },
        PersonalCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Name = 'Personal'
            col.Type = 'Field Group'
            col.Fields = this.PersonalFields
            col.FieldObjects = col.Fields.map(field => {
                        let fieldobj = Object.assign({},this.AllFields.find(obj => obj.id === field.id))
                        fieldobj.FieldType = this.GetFieldType(fieldobj)
                        return fieldobj
                    })
            return col
        },
        BasicFieldsCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Name = 'Basic'
            col.Type = 'Field Group'
            col.Fields = this.BasicFields
            col.FieldObjects = col.Fields.map(field => {
                        let fieldobj = Object.assign({},this.AllFields.find(obj => obj.id === field.id))
                        fieldobj.FieldType = this.GetFieldType(fieldobj)
                        return fieldobj
                    })
            return col
        },
        AvatarFieldCol(){            
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'            
            col.Field = Object.assign({},this.FieldCardFields.find(obj => obj.id === 'Profile_Photo'))
            col.FieldObj = col.Field
            return col
        },
        ObjectivesCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'
            col.Field = Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Objectives'))
            col.Field.FieldType = this.GetFieldType(col.Field)
            col.FieldObj = col.Field
            return col
        },
        OverviewCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'
             col.Field = Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Overview'))
            col.Field.FieldType = this.GetFieldType(col.Field)
            col.FieldObj = col.Field
            return col
        },
        FieldCardFields(){
            return this.AllFields.map(field => {
                let fieldobj = Object.assign({},field)
                if(field.Type === 'Option Set'){
                    fieldobj.FieldType = 'Option Toggle'
                }
                else if(field.Type === 'Lookup' && field.RelatedBuildID === 'Users' || field.Type === 'Social Lookup' && field.RelatedBuildID === 'Users'){
                    fieldobj.FieldType = 'User Avatar'
                }
                else if(field.Type === 'Single File Upload'){
                    //console.log('fieldobj',fieldobj)
                    fieldobj.FieldType = field.FeaturedPhoto ? 'Data Image Field' : 'Data Upload File Field'
                }
                else if(field.Type === 'Common Field'){
                    fieldobj.FieldType = field.CommonFieldType === 'QR Link' ? 'QRImageField' : 
                    field.CommonFieldType === 'Physical Address' ? 'PhysicalAddressField' : 
                    field.CommonFieldType === 'Postal Address' ? 'PostalAddressField' : ''
                }
                else if(field.Type === 'Date'){
                    fieldobj.FieldType = 'Date Graphic'
                }
                else if(field.Type === 'Multiple Lines Text'){
                    fieldobj.FieldType = 'Writeup'
                }
                return {id: fieldobj.id,FieldType: fieldobj.FieldType,DisplayName: fieldobj.DisplayName,Name: fieldobj.Name}
            }).filter(field => {
                return field.FieldType
            })
        },
        AvatarField(){
            return {
                id: 'Profile_Photo',
                Name: 'Profile_Photo',
                DisplayName: 'Photo (MUST be 400 x 400)',
                Type: 'Single File Upload',
                FeaturedPhoto: true
            }
        },
        BasicFields(){
            let basicfields = [
                {id: 'Full_Name',Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text'},{id: 'Name',Name: 'Name',DisplayName: 'Name',Type: 'Single Line Text'},{id: 'Surname',Name: 'Surname',DisplayName: 'Surname',Type: 'Single Line Text'}
            ]
            let contactfields = [
                {id: 'Email',Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
                {id: 'Mobile_Number',Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'}
            ]
            let fields = basicfields.concat(contactfields)
            
            return fields
        },
        DescriptiveFields(){
            let morefields = [{id: 'Overview',Name: 'Overview',DisplayName: 'Overview',Type: 'Multiple Lines Text',IsContentEditable: true},
            {id: 'Objectives',Name: 'Objectives',DisplayName: 'Objectives',Type: 'Multiple Lines Text',IsContentEditable: true}]
            let fields = morefields
            //we need
            //general location (not friggin addresS!!! just like "Benoni Gauteng")
            //education (at least secondary and tertiary, not just single line text, list items)
            // skils list - strengths, weakenesses, languages
            //industries (ANOTHER list)
            //Gender, Race, Title, NAtionality, Relationship (e/g/ Married), Age
            //Work history (LIST)
            //the issue we have here is the ContentEditable items on the template like the "writeup" section, include a couple of fields
            //this is probably going to be more like a table, gotta think about this
            return fields
        },
        AllFields(){
            return this.BasicFields.concat(this.DescriptiveFields,[this.AvatarField],this.PersonalFields)
        },
        ComputedActiveEntity(){
            return {id: 'Featured_Members',AllFields: this.AllFields,WikiFields: this.AllFields,Galleries: [],DocumentRegisters: [],SubCollections: [],Primary_Field_Name: 'Full_Name'}
        },
        EntityDataRef(){
            return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.FeaturedMemberid)
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ReceiveQualificationFile']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetFeaturedMember(this.FeaturedMemberid)
    },
    methods:{
        ActivateJobDialog(AsHighlight){
            this.JobDialog = true
            if(AsHighlight){
                this.AsHighlight = true
            }
            else{
                this.AsHighlight = false
            }
        },
        DeactivateJobDialog(){
            this.JobDialog = false
            this.Job = {}
            this.AsHighlight = false
        },
        SaveJob(){
            let prop = this.AsHighlight ? 'Highlights' : 'Jobs'
            if(!this.FeaturedMember[prop]){
                this.FeaturedMember[prop] = []
            }
            let item = this.Job
            if(!this.AsHighlight){
              item.Start = new Date(this.Job.Start)
                item.End = new Date(this.Job.End)  
            }
            else{
                item.Achieved = new Date(this.Job.Achieved)
            }
            
            this.FeaturedMember[prop].push(item)
            this.UpdateMemberProp(prop)
            this.DeactivateJobDialog()
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(FieldObject,prop,value)
    },
        PreparedQualifications(Qualifications){
            return Qualifications.map(qual => {
                //console.log('qual',qual)
                let qualobj = Object.assign({},qual)
                qualobj.TextColor = 'white'
                if(qual.Type.Name === 'Certificate'){
                    qualobj.Color = 'yellow'
                    qualobj.Abbr = 'Ce'
                    qualobj.TextColor = 'black'
                }
                else if(qual.Type.Name === 'Higher Certificate'){
                    qualobj.Color = 'orange'
                    qualobj.Abbr = 'HC'
                }
                else if(qual.Type.Name === 'National Diploma'){
                    qualobj.Color = 'red'
                    qualobj.Abbr = 'NP'
                }
                else if(qual.Type.Name === 'Bachelors'){
                    qualobj.Color = 'blue'
                    qualobj.Abbr = 'Ba'
                }
                else if(qual.Type.Name === 'Honours'){
                    qualobj.Color = 'cyan'
                    qualobj.Abbr = 'Ho'
                }
                else if(qual.Type.Name === 'Masters'){
                    qualobj.Color = 'purple'
                    qualobj.Abbr = 'Ma'
                }
                else if(qual.Type.Name === 'Doctorate'){
                    qualobj.Color = 'black'
                    qualobj.Abbr = 'PhD'
                }
                //console.log('qualobj',qualobj)
                //NQF 5/6/7/8/9
                qualobj.FinishObject = qual.Finish.toDate ? qual.Finish.toDate() : qual.Finish
                qualobj.TimestampSort = qualobj.FinishObject.getTime()
                return qualobj
            }).sort((a, b) => {
                        var key1 = a.TimestampSort;
                        var key2 = b.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
        },
        QualificationFileSelect(event) {
            this.QualificationFile = event.target.files[0]
        },
        ReceiveQualificationFile(payload){
            this.Qualification.File = payload.UploadObject
            this.FinalizeQualification()
        },
        SaveQualification(){
            if(!this.FeaturedMember.Qualifications){
                this.FeaturedMember.Qualifications = []
            }
            this.Qualification.Start = new Date(this.QualificationStart)
            this.Qualification.Finish = new Date(this.QualificationFinish)
            if(this.QualificationFile){
                let filename = this.QualificationFile.name
                let payload = {
                        file: this.QualificationFile,
                        filename: filename,
                        storepath: 'FeaturedMember/'+this.FeaturedMember.id+'/Qualifications/'+new Date()+'/'+filename,
                        returnmethod: 'ReceiveQualificationFile',
                        PassedObj: {}
                    }
                    this.$store.commit('SetSocialItemInteractMethodProp',payload) 
                    this.$store.commit('SetSocialItemInteractMethod','ProcessSingleFileUpload')
            }
            else{
                this.FinalizeQualification()
            }
        },
        FinalizeQualification(){
            if(this.QualificationIndex === -1){
                this.FeaturedMember.Qualifications.push(this.Qualification) 
            }   
            else{
                this.FeaturedMember.Qualifications.splice(this.QualificationIndex,1,this.Qualification)
            }                    
            this.UpdateMemberProp('Qualifications')
            this.RefreshQualifications()
            this.DeactivateQualificationDialog()  
        },
        RefreshQualifications(){
            this.RefreshingQualifications = true
            setTimeout(() => {
                this.RefreshingQualifications = false
            }, 20);
        },
        ActivateQualificationDialog(item,index){
            if(item){
                this.Qualification = item
                this.QualificationIndex = index
                let start = this.Qualification.Start.toDate()
                let fin = this.Qualification.Finish.toDate()
                this.QualificationStart = format(start,'yyyy-MM-dd')
                this.QualificationFinish = format(fin,'yyyy-MM-dd')
            }
            this.QualificationDialog = true
        },
        DeactivateQualificationDialog(){
            this.QualificationDialog = false
            this.Qualification = Object.assign({},this.DefaultQualification)
            this.QualificationIndex= -1
            this.QualificationFile = ''
        },
        UpdateQualificationInstitution(updpayload){
            this.Qualification = {...this.Qualification,...updpayload}
            //console.log(this.Qualification)
        },
        UpdateMemberProp(prop){
            this.EntityDataRef.update({
                [prop]: this.FeaturedMember[prop]
            })
        },
        UpdateRecordKeywords(updpayload){
            this.EntityDataRef.update(updpayload)
        },
        ActivateHelper(HelperID){
            this.ActiveHelperID = HelperID
            if(!this.HelperHalted){
            this.ActivatingHelper = true
            setTimeout(() => {
            this.HelperActive = true
            this.ActivatingHelper = false
                
            }, 1000);
            }
        },
        StopHelper(HelperID){
          this.StoppedHelpers.push(HelperID)
          this.DeactivateHelper()
      },
      DeactivateHelper(){
          this.HelperActive = false            
      },
        CheckforMarketAccount(){
            let vm = this
            if(!this.UserMarketAccount && this.AppMonetizeBU){
                vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
                vm.$store.commit('setCustomProcessingDialog',true)
                const functions = firebase.functions();
                const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
                let payload = {
                    Data: vm.userLoggedIn,
                    AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
                }
                CreateMarketAccount(payload).then(result => {
                    console.log(result)
                    vm.$store.commit('setCustomProcessingDialogText','Done Creating')
                    vm.$store.commit('setCustomProcessingDialog',false)
                })
                }
        },
        RoutetoTemplate(){
            let path = this.FeaturedMemberDefaultPage ? '/FeaturedMember/'+this.userLoggedIn.id+'/'+this.FeaturedMemberDefaultPage : '/FeaturedMemberCreator/'+this.userLoggedIn.id
            this.$router.push(path)
        },
        SaveRecord(payload){
            this.CheckforMarketAccount()
        //console.log('payload',payload)
        let record = payload.Record
        let fields = payload.Fields
        let ref = this.EntityDataRef
        let updateobj = {}
        updateobj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        updateobj.Modified_Byid = this.userLoggedIn.id
        updateobj.Modified_On = new Date()
        fields.map(field => {
          if(typeof record[field.id] !== 'undefined'){
            updateobj[field.id] = record[field.id]
            if(field.Type === 'Lookup'){
              updateobj[field.id+'id'] = updateobj[field.id].id
            }
            //DATES AND "TIMERSPONSE"
            //PHYSICOL ADDRESS TYPE FIELDS....ETC.
            //if owner, see DashUserAvatar, this.AssignBU() function, triggering notificaiton to owner as well etc etcetc
            //and a bunch of other stuff, seirously but okay
          }
        })
       // console.log(ref,updateobj)
        ref.update(updateobj)
      },
        onPhotoUpload(payload) {
          let filename = payload.filename
          let file = payload.file
          let field = payload.field
          let storepath = 'Sites/'+this.$store.state.ActiveSuiteid+'/FeaturedMembers/'+field.id+'/'+file.name+'/'+new Date()
            let vm = this
            var storageRef = firebase.storage().ref(storepath)
            var uploadTask = storageRef.put(file)
            vm.$emit('ActivateProcessing',true)
            vm.PrepareThumbnail(filename,file,storepath).then(thumbresult => {
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                let UploadObject = {
                fileurl: url,
                url: url,
                ThumbURL: thumbresult,
                FileType: file.type,
                Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                ModifiedDate: new Date(file.lastModified),
                Size: file.size,
                StorageRef: storepath,
                filename: filename
                }
                vm.EntityDataRef.update({
                        [field.Name]: UploadObject,
                        [field.Name+'ThumbURL']: UploadObject.ThumbURL
                })
                vm.$emit('ActivateProcessing',false)
                let snackbarcontent = {
                snackbartimeout: 4000,
                snackbartext: 'Added '+field.DisplayName+' to Record',
                snackbartop: true,
                }
                vm.$emit('ActivateSnackbar',true,snackbarcontent)
                // delete field.localURL
                // delete field.file
                    })
            })
        },
        PrepareThumbnail(filename,file,storageref){
            let vm = this
            return new Promise(function(resolve, reject) {	
            file.tmpsrc = URL.createObjectURL(file)
                //this.OutboundPhotosforUpload.push(file)
                var img = document.createElement("IMG");
                    img.setAttribute('width',150)
                    img.setAttribute('src',file.tmpsrc)
                    document.body.appendChild(img)
                    let vm = this
                    img.onload = function() {
                    var c = document.createElement("canvas");
                    var ctx = c.getContext("2d");
                    var canvasRatio = img.naturalHeight / img.naturalWidth
                    c.width = 400
                    c.height = c.width*canvasRatio
                    ctx.drawImage(img, 0, 0, c.width, c.height);
                    c.toBlob(blob => {
                    let blobfile = new File([blob], filename, {
                    type: file.type,
                });
                //console.log(blobfile)
                var storageRef = firebase.storage().ref(storageref+'_thumb')
                    document.body.removeChild(img)
                    var uploadTask = storageRef.put(blobfile)
                    uploadTask
                    .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((thumburl) => {
                        //console.log(thumburl)
                        resolve(thumburl)
                        })
                })
                    }
            })
        },
        GetFieldType(field){
            //the below validates ParentFieldObjType but does NOT prepare it, so a "Lookup" could become "text" for parent field
            let linkcommontypes = ['URL Link']
            let linkfieldtypes = this.LinkFieldTypes.filter(type => {return type !== 'Common Field'})
            if(this.SliderFieldTypes.includes(field.Type) && field.IsSlider || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.SliderFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsSlider ){
                return 'SliderField'
            }
            else if(this.ProgressFieldTypes.includes(field.Type) && field.IsPercentage || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ProgressFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsPercentage ){
                return 'ProgressField'
            }
            else if(this.CurrencyFieldTypes.includes(field.Type) && field.IsCurrency || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.CurrencyFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsCurrency ){
                return 'CurrencyField'
            }
            else if(this.ToggleFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ToggleFieldTypes.includes(field.ParentFieldObj.Type)){
                return 'ToggleField'
            }
            else if(this.WriteupTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.WriteupTypes.includes(field.ParentFieldObj.Type)){
                return 'Writeup'
            }            
            else if(this.OptionsFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.OptionsFieldTypes.includes(field.ParentFieldObj.Type)){
                return 'OptionsField'
            }
            else if(linkfieldtypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && linkfieldtypes.includes(field.ParentFieldObj.Type) || 
            linkcommontypes.includes(field.CommonFieldType) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && linkcommontypes.includes(field.ParentFieldObj.CommonFieldType)){
                return field.Type === 'Route Path' ? 'RoutePathField' : 
                field.Type === 'Social Lookup' ? 'SocialLookupField' : 
                field.CommonFieldType === 'URL Link' ? 'URLLinkField' : 'LookupField'
            }
            else if(field.Type === 'Date' || 
            field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Date'){
                return 'DateField'
            }
            else if(field.Type === 'Number Field' || 
            field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Number Field'){
                //by now only basic number field remaining
                return 'NumberField'
            }
            else if(this.TextFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.TextFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj){
                return field.CommonFieldType === 'Email' ? 'EmailField' : field.CommonFieldType === 'Telephone Number' ? 'TelephoneNumberField' : 
                field.CommonFieldType === 'Physical Address' ? 'PhysicalAddressField' : field.CommonFieldType === 'Postal Address' ? 'PostalAddressField' : 'TextField'
            }
            else{
                return 'TextField'
            }
            //DateField NumberField Option of fucking snap....this was supposed to happen...
        },
        GetFeaturedMember(FeaturedMemberid){
            let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(FeaturedMemberid)
            query.onSnapshot(snapshot => {
                //For query clash possibly new arrays being 'Characteristics','Hobbies','Highlights','Industries','Skills','Methodologies,'Qualifications','Jobs'
                // let data = snapshot.data()
                // let backuppath = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(FeaturedMemberid+'_Backup')
                // backuppath.set(data)
                this.FeaturedMember = snapshot.data()
                this.FeaturedMember.id = FeaturedMemberid
                this.FeaturedMember.Query = query
                let userprops = ['Name','Surname','Full_Name','Email','Mobile_Number']
                if(this.FeaturedMember.id === this.userLoggedIn.id){
                userprops.map(prp => {
                    if(typeof this.FeaturedMember[prp] === 'undefined' && this.userLoggedIn[prp] !== 'undefined'){
                        this.FeaturedMember[prp] = this.userLoggedIn[prp]
                    }
                })
                }
                this.RelatedObj = {id: FeaturedMemberid, Full_Name: this.FeaturedMember.Full_Name,identifier: 'Full_Name',collection: 'featuredmembers' }
                if(this.ProvidedMember){
                    this.ProvidedMember.Props.map(prp => {
                        if(typeof this.ProvidedMember[prp.SlaveProp] !== 'undefined'){
                            this.FeaturedMember[prp.SlaveProp] = this.ProvidedMember[prp.SlaveProp]
                        }
                    })
                    let member = {...this.FeaturedMember}
                    delete member.Query
                    delete member.id
                    query.update({
                        member
                    })
                }
                this.ActivateHelper(this.ActiveHelperID)               
        })
    }
    }
}
</script>

<style>

</style>



