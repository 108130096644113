<template>
        <span>
            <v-icon v-if="SingleFormEditing" class="links--text" @click="UpdateEditing()">mdi-cogs</v-icon>
             
            <v-dialog v-model="dialog" max-width="500px">
               <v-card>
                  <v-img
                  width="500"
                  src="@/assets/RABaseBG-Soft.png"
                  ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                    <v-card-title class="mediumoverline white--text">
                      Register Documents
                    </v-card-title></div>
                    <v-form ref="Enquiriesform" id="Enquiriesform">
                      <v-card-text>
                            <v-select  :rules="[$store.state.formrules.required]" :items="section.PrimaryOptions"
                            label="Select Primary Type" v-model="editedItem.PrimarySelectedOption" item-text="Name" return-object>
                            </v-select>
                             <!-- //.DocRegAdditionalFields.map? -->
                            <input  v-if="!editedItem.fileurl"
                            type="file"
                            @change="onFileselected($event)"
                            ref="fileInputer"
                            id="InputElement"
                            >
                            <v-btn icon @click="GetShortLivedURL(editedItem)" v-if="editedItem.fileurl">View
                                </v-btn>
                        </v-card-text>
                      <v-card-actions class="white documenttypeshadowinvert">                  
                      <v-btn color="orange" dark class="overline" @click="closedialog()">Cancel</v-btn>
                        <v-spacer></v-spacer>
                      <v-btn color="green" dark  class="overline" @click="saveDoc()" v-if="selectedFile || editedItem.fileurl">Save</v-btn>
                    </v-card-actions>
                    </v-form>
                    </v-img>
                </v-card>
            </v-dialog>
            <v-card flat min-height="200px"  :dark="AppisDarkMode">
                <v-layout row class="justify-center mx-1">
                  <v-card flat width="100%">
                      <v-data-table 
                      :dark="AppisDarkMode"          
                      :headers="Headers"           
                      :items="withIcon"            
                      class="elevation-1" 
                      :show-select="Selecting"   
                      v-model="Selected"   
                      item-key="Name" 
                      single-expand
                      show-expand  
                      v-if="!UpdatingTable"
                      >           
                        <template v-slot:top>           
                          <v-toolbar flat color="blue darken-4 white--text">            
                              <v-toolbar-title>Upload</v-toolbar-title>           
                              <v-divider            
                              class="mx-4"            
                              inset           
                              vertical            
                              ></v-divider>           
                                      
                              <v-spacer></v-spacer>                                           
                              <v-menu> 
                              <template v-slot:activator="{ on }">            
                                  <v-icon  v-on="on" dark  id="NewBtn">mdi-dots-vertical</v-icon>            
                              </template>
                              <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
                                <v-list-item class="listoutline"  @click="ActivateCabinetDialog()"  v-if="CanCreate">
                                    <v-list-item-title class="subtleoverline">
                                      New Documents
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item  class="listoutline" v-if="!Selecting" @click="Selecting = !Selecting">
                                    <v-list-item-title class="subtleoverline">
                                      Select to Remove
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="green">mdi-check</v-icon>
                                    </v-list-item-action>
                                  </v-list-item> 
                                  <v-list-item v-if="Selecting" @click="Selecting = !Selecting,Selected = []">
                                  <v-list-item-title class="subtleoverline">
                                      Cancel Select
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="red">mdi-cancel</v-icon>
                                    </v-list-item-action>
                                    </v-list-item>
                                  <v-list-item  class="listoutline" v-if="Selecting && Selected.length>0"  @click="ConfirmTableTransfer()">
                                    <v-list-item-title class="subtleoverline">
                                      Remove from Table
                                    </v-list-item-title>
                                  <v-list-item-action>
                                      <v-icon class="actionicon" color="green">mdi-check</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>  
                              </v-list>
                             </v-menu>           
                          </v-toolbar>            
                          </template> 
                         <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding-top:10px;padding-bottom:10px;">
                            <b>File Name and Type:</b> <v-spacer></v-spacer>{{item.Name}}<br>
                             <b>File Type: </b>  <v-spacer> </v-spacer>{{item.FileType}} <br>
                            <b>Primary Classification:</b> <v-spacer></v-spacer> <span v-if="item.PrimarySelectedOption && item.PrimarySelectedOption.Name">{{item.PrimarySelectedOption.Name}}</span><br>
                            <b>Original Date:</b> <span v-if="item.ModifiedDate && item.ModifiedDate.toDate">{{item.ModifiedDate.toDate()}}</span><span v-if="item.ModifiedDate && !item.ModifiedDate.toDate">{{item.ModifiedDate}}</span> prior to upload.<br>
                            <b>Upload Details: </b> <span v-if="item.Created_By">{{item.Created_By.Full_Name}}</span> on <span v-if="item.Created_On && item.Created_On.toDate">{{item.Created_On.toDate()}}</span><span v-if="item.Created_On && !item.Created_On.toDate">{{item.Created_On}}</span><br>
                            <b>Modification Details: </b><span v-if="item.Modified_By">{{item.Modified_By.Full_Name}}</span> on <span v-if="item.Modified_On && item.Modified_On.toDate">{{item.Modified_On.toDate()}}</span><span v-if="item.Modified_On && !item.Modified_On.toDate">{{item.Modified_On}}</span>
                          </td>
                        </template>
                          <template v-slot:[`item.PrimarySelectedOption`]="{ item }">
                            <v-chip :color="Documentoptions(item.PrimarySelectedOption,section.PrimaryOptions) === 'Option Removed' ? 'grey white--text' : 'warning'" v-if="item.PrimarySelectedOption">{{ Documentoptions(item.PrimarySelectedOption,section.PrimaryOptions) }}</v-chip>
                          </template>
                          <template v-slot:[`item.FileType`]="{ item }">
                            <v-chip style="width: 100%" outlined
                            v-if="item.FileType"><v-icon :color="item.FileTypeIconColor">{{item.FileTypeIcon}}</v-icon>{{ item.FileType.split('/')[1].substr(0,20) }}</v-chip>
                          </template>
                          <template v-slot:[`item.action`]="{ item }">            
                            <v-icon           
                            small           
                            class="mr-2"  
                             v-if="CanEdit"          
                            @click="editItem(item)"            
                            >           
                                mdi-pencil            
                            </v-icon>           
                            <v-icon           
                                small 
                                 v-if="CanDelete"          
                                @click="deleteItem(item)"           
                            >           
                                mdi-delete            
                            </v-icon>           
                            <v-btn x-small icon  @click="GetShortLivedURL(item)" v-if="CanGet">
                              <v-icon color="grey lighten-1">mdi-eye</v-icon>
                            </v-btn>            
                          </template>         
                          </v-data-table>
                    </v-card>

                </v-layout>

             </v-card>
        </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['tab','section','sectionindex','Fields','AppisDarkMode','System',
    'CanEdit','Record','Cols','Business_Unit','PrimaryFieldName','RelatedObj',
    'AppointmentsQuery','ActivitiesArray','SingleFormEditing','EntityName',
    'EntityCollectionRef','BaseStorageRef','EntityDataRef','CanCreate','CanDelete'
    ,'CanGet','SampleOnly'],
    components: {

    },
    data(){
        return{
            MutatedRecord: '',
            Editing: false,
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
            Headers: [
                { text: 'Name', value: 'Name', sortable: true},						
                    { text: 'Class', value: 'PrimarySelectedOption', sortable: true},						
                    { text: 'File Type', value: 'FileType', sortable: true },						
                    { text: 'Action', value: 'action', sortable: true },
            ],
            Selecting: false,
            Selected: [],
            // : [],
            
            UpdatingTable: false,
            
            dialog: false,
            
            selectedFile: '',
            UploadingFile: false,
            UploadingFileProgress: 0,
            editedItem: {
            PrimarySelectedOption: '',
            },
            DefaultItem: {
            PrimarySelectedOption: '',
            },
            editedIndex: -1,
        }
    },
    computed:{
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
        FinalStorageRef(){
            return this.BaseStorageRef+this.SectionStorageRef
        },
        SectionStorageRef(){
            return this.section.Name.split(' ').join('')+'/'+this.Record.id+'/'
        },
        roottableentities(){
        return this.$store.state.roottableentities
        },
        SectionDBRef(){
            return this.$route.meta && this.$route.meta.EntityID && this.$store.state.NewTableStructure && !this.roottableentities.includes(this.$route.meta.EntityID) ? this.EntityDataRef.collection('DocumentRegisters').doc(this.section.Name).collection('Items') : 
            this.EntityDataRef.collection(this.section.Name.split(' ').join('').split('_').join('').toLowerCase())
        },
        withIcon(){
      return this.section.DataArray.map(doc => {
        //console.log(doc)
        let match = this.$store.state.FileTypesandIcons.find(obj => obj.Type.includes(doc.FileType))
        if(match){
          doc.FileTypeIcon = match.Icon
          doc.FileTypeIconColor = match.Color
          doc.FileTypeName = match.Name
        }
        else{
          doc.FileTypeIcon = 'mdi-file-question-outline'
          doc.FileTypeIconColor = 'blue'
          doc.FileTypeName = 'Unknown Type'
        }
        if(!doc.Name){
          doc.Name = doc.filename
        }
        return doc
      })
    },
        UsersArray(){
      return this.$store.state.UsersArray
    },
        userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        HeaderDarkLightStyle(){
            if(this.AppisDarkMode){
                return 'white--text font-weight-light'
            }
            else{
                return 'primary--text font-weight-light'
            }
            },
    },
    watch: {
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateCabinetDialog']   
        if(v && acceptedmethods.includes(v) && this.section.DisplayName === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
       
    },
    methods:{
      ActivateCabinetDialog(){
        this.dialog = !this.dialog
      },
        onFileselected(event){
      this.selectedFile = event.target.files[0]
      this.selectedFile.FileModifiedDate = new Date(this.selectedFile.lastModified)
    },
        Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
          if(option){
            return option.Name  
          }
          else{
              return 'Option Removed'
          }        
        }
        else{
          return value +'OLD'
        }
        
      },
        GetShortLivedURL(item){      
      let itemobj = Object.assign({},item)
      if(itemobj.StorageRef[0] === '/'){
        itemobj.StorageRef = itemobj.StorageRef.slice(1)
      }
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(itemobj).then(result => {
          let payload = {
            item: itemobj,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
    editItem (item) {
        this.editedIndex = this.section.DataArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.recordid = item.id
        let btn = document.getElementById('NewBtn')
        btn.click()
        this.dialog = true
      },
      closedialog (){
      this.dialog = false
      this.selectedFile = ''
      this.PrimarySelectedOption = ''
      this.editedItem = this.DefaultItem
      this.editedIndex = -1
      let inputelement = document.getElementById('InputElement')
      if(inputelement){
        inputelement.value = ''
      }
    },
    deleteItem (item) {
      confirm('Are You sure you want to delte this item? There is no going back') && this.ProcessDeleteItem(item)
    },
    ProcessDeleteItem(item){
      let vm = this
      let arrayindex = this.section.DataArray.indexOf(item)
      this.section.DataArray.splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
           vm.SectionDBRef.doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
      UpdateEditing(){
          if(!this.SingleFormEditing){
            this.Editing = !this.Editing
          }
          else{
            this.$emit('OpenDialog',this.section,this.sectionindex,true)
          }
            
            //console.log(this.Record,this.MutatedRecord)
            //this.$emit('UpdateEditableField',this.FieldName,FieldValue,this.AdditionalSaveMethod,this.MutatedRecord)
        },
        saveDoc(){
      
      if (this.editedIndex > -1) {
        let filename = this.editedItem.id+'_'+this.editedItem.Name
        let vm = this
        const path = this.FinalStorageRef 
        this.editedItem.Modified_By = {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
        this.editedItem.Modified_Byid = this.userLoggedIn.id
        this.editedItem.Modified_On = new Date()
        let existingstorageref = this.editedItem.StorageRef
        this.MoveFiletoNewLocation(this.editedItem.fileurl, path+this.editedItem.PrimarySelectedOption.Name+'/'+filename).then(url => {
        let OldPath = ''
        OldPath = this.editedItem.StorageRef
        //console.log(OldPath)
        if(existingstorageref){
            let storageRef = firebase.storage().ref(existingstorageref);																
            storageRef.delete()	
          }	
        this.editedItem.StorageRef = path+this.editedItem.PrimarySelectedOption.Name+'/'+filename
        this.editedItem.fileurl = url
        //console.log(this.editItem)
        //console.log(OldPath)
        this.section.DataArray[this.editedIndex] = this.editedItem
        this.SectionDBRef.doc(this.editedItem.id).set(this.editedItem).then(function() {
              console.log("Document successfully written!");
              
              vm.UpdatingTable = true
                setTimeout(() => {
                vm.UpdatingTable = false
                vm.closedialog()
              }, 50);
          })
          .catch(function(error) {
              console.error("Error writing document: ", error);
          });
       //   if(this.Parent.Name !== ''){
      // db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(this.$route.params.id).update({
      //   Parent: {Name: this.Parent.Name,id: this.Parent.id},
      //   Parentid: this.Parent.id,
      //   Modified_By: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
      //   Modified_Byid: this.userLoggedIn.id,
      //   Modified_On: new Date()
      //         });
      // } 
                            
                            })
        
      }
      
    
      else{
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingFile = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        console.log(barelmnt,bartextelmnt)
        const path = this.FinalStorageRef
        let filename = newdocid+'_'+this.selectedFile.name
        console.log(filename)
        const Newdocument = { 
          PrimarySelectedOption: this.editedItem.PrimarySelectedOption,
          FileType: this.selectedFile.type,
          Name: this.selectedFile.name,
          Modified_By: {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
          Modified_Byid: this.userLoggedIn.id,
          Modified_On: new Date(),
          StorageRef: path+this.editedItem.PrimarySelectedOption.Name+'/'+filename,
          Created_By: {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
          Created_Byid: this.userLoggedIn.id,
          Created_On: new Date(),
          ModifiedDate: this.selectedFile.FileModifiedDate
        }
         //.DocRegAdditionalFields.map?

        const TEMPARRAY = this.section.DataArray
        var storageRef = firebase.storage().ref(Newdocument.StorageRef);
        //Newdocument.StorageRef = path+this.editedItem.PrimarySelectedOption.Name+'/'+filename
          var uploadTask = storageRef.put(this.selectedFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.selectedFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingFile = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
              });
            });
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                Newdocument.fileurl = url
                //TEMPARRAY.push(Newdocument)
                	
                this.SectionDBRef.doc(newdocid).set(Newdocument).then(doc => {
                  this.closedialog()
                  if(this.SampleOnly){
                    //alert('yep sample only')
                    this.section.DataArray.push(Newdocument)
                  }
                })
              })
                
        }, 1000);
      }
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      //console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    }
}
</script>

<style>

</style>