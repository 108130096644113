<template>
  <div>
     
    <!-- This clears yet another 2000 lines on App comp if we do this right.  -->
   
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

import DirectoryBuilder from '@/components/Directories/DirectoryBuilder';
import DBNavlistsComponent from '@/components/General/DBNavlists';
import SystemTableFiltersComponent from '@/components/Database/SystemTableFiltersComponent';
import WebFormBuilder from '@/components/SuitePlugins/WebForms/WebFormBuilder';
import SurveyBuilder from '@/components/WebPages/SurveyBuilder';
import FAQAdmin from '@/components/SuitePlugins/FAQ/FAQAdmin'
import PDFExportFunctionBuilder from '@/components/SuitePlugins/PDFBuilder/PDFExportFunctionBuilder'
import ClientPortal from '@/components/Client/ClientPortal'
import WikiCollectionBuilder from '@/components/Directories/DirectoryBuilder';
import WebForms from '@/components/SuitePlugins/WebForms/WebForms';
import RMAdmin from '@/components/SuiteBuilder/Support/RMAdministration.vue'
import SecurityOverview from '@/components/Database/SecurityOverview.vue'
import LandingPages from '@/components/WebPages/LandingPages'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import BannerAds from '@/components/WebPages/BannerAds'
import Campaigns from '@/components/WebPages/Campaigns';
import CampaignBuilder from '@/components/WebPages/CampaignBuilder';
import NewBannerAd from '@/components/WebPages/NewBannerAd';
import Roadmap from '@/components/SuiteBuilder/Support/Roadmap'
import SystemConfig from '@/components/Database/DataSingleViewComponent';
import MyProfile from '@/views/MyProfile'
import ChatbotBuilder from '@/components/SuitePlugins/Chatbot/ChatbotBuilder'
import DataImports from '@/views/DataImports'
import DataImport from '@/views/DataImport/Single'
import MyActivities from '@/views/MyActivities'
import MySchedule from '@/components/SuitePlugins/Activities/MySchedule'
import MyTeamSchedule from '@/components/SuitePlugins/Activities/MyTeamSchedule'
import MyActivitiesbyType from '@/views/Activities/MyActivitiesbyType';
import MyTeamActivities from '@/views/MyTeamActivities'
import MyTeamMemberActivities from '@/views/MyTeamMemberActivities'
import TeamMemberActivities from '@/views/TeamActivities/TeamMemberActivities'
import PipelineManagement from '@/components/SuitePlugins/Pipelines/PipelineManagement'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder'
import SocialMediaPlatforms from '@/components/SuitePlugins/SocialCentre/SocialMediaPlatforms'
import Monetization from '@/components/SuitePlugins/Monetization/Monetization'
import LinkedInAuth from '@/components/SuitePlugins/SocialCentre/LinkedInAuth'
import Goals from '@/components/SuitePlugins/Goals/Goals'
import PDFExportTemplates from '@/components/SuitePlugins/PDFBuilder/PDFExportTemplates'
import GroupEdit from '@/components/SuitePlugins/Groups/Edit/Single';
import GroupNewBlog from '@/components/SuitePlugins/Blogs/New/Single';
import GroupNewArticle from '@/components/SuitePlugins/Articles/New/Single';
import GroupNewPoll from '@/components/SuitePlugins/Polls/New/Single';
import Groups from '@/components/SuitePlugins/Groups/Groups';
import GroupSingle from '@/components/SuitePlugins/Groups/Single';
import SiteMeetupNew from '@/components/SuitePlugins/Meetups/New/Single';
import SitePollNew from '@/components/SuitePlugins/Polls/New/Single';
import SignUp from '@/views/signup'

import LedgersCollection from '@/components/SuitePlugins/Accounting/LedgersCollection';
import DocumentationLibraryEditor from '@/components/WebPages/NewLandingPage';
import FeaturedMemberViewer from '@/components/WebPages/LandingPageSingle'
import FeaturedMemberEditor from '@/components/WebPages/NewLandingPage';
import FeaturedMemberCreator from '@/components/WebPages/FeaturedMemberCreator';
import VacancySingleRoute from '@/components/SuitePlugins/Vacancies/VacancySingleRoute';

import SuiteTemplates from '@/components/SuitePlugins/SuiteTemplates/SuiteTemplates';
import SuiteTemplateMainComp from '@/components/SuitePlugins/SuiteTemplates/SuiteTemplateMain';
import SuiteTemplateWebsiteComponent from '@/components/SuitePlugins/SuiteTemplates/SuiteTemplateWebsite';
import SuiteTemplateSocialNetwork from '@/components/SuitePlugins/SuiteTemplates/SuiteTemplateSocialNetwork';
import SuiteTemplateDatabaseApp from '@/components/SuitePlugins/SuiteTemplates/SuiteTemplateDatabaseApp';

import MarketplaceTemplates from '@/components/SuitePlugins/MarketplaceTemplates/MarketplaceTemplates';
import MarketplaceTemplateSingle from '@/components/SuitePlugins/MarketplaceTemplates/MarketplaceTemplateSingle';
import Templates from '@/components/SuitePlugins/WebAppBuilder/Templates';

import SiteSingle from '@/components/SuitePlugins/WebAppBuilder/SiteSingle';

import GettingStarted from '@/components/SuiteBuilder/GettingStarted';
import SuiteApp from '@/components/SuiteBuilder/SuiteApps/SuiteApp';
import WarehouseTransaction from '@/components/SuitePlugins/Warehousing/WarehouseTransaction';
import WarehousesCollection from '@/components/SuitePlugins/Warehousing/WarehousesCollection';
import WarehouseSingle from '@/components/SuitePlugins/Warehousing/WarehouseSingle';
import Picking from '@/components/SuitePlugins/Warehousing/Processing/Picking';
import Dispatching from '@/components/SuitePlugins/Warehousing/Processing/Dispatching';
import BackorderProcessing from '@/components/SuitePlugins/Warehousing/BackorderProcessing';
import GoodsReceiving from '@/components/SuitePlugins/Warehousing/Processing/GoodsReceiving';
import PurchaseOrderSingle from '@/components/SuitePlugins/Warehousing/PurchaseOrderSingle';
import PurchaseOrdersCollection from '@/components/SuitePlugins/Warehousing/PurchaseOrdersCollection';
import SalesOrderSingle from '@/components/SuitePlugins/Warehousing/SalesOrderSingle';
import GroupFeaturedMemberView from '@/components/SuitePlugins/Groups/GroupFeaturedMemberView';
import DigitalBusinessCards from '@/components/SuitePlugins/DigitalBusinessCards/DigitalBusinessCards';
import DigitalBusinessCardSingle from '@/components/SuitePlugins/DigitalBusinessCards/DigitalBusinessCardSingle';
import GroupFeaturedMemberEdit from '@/components/SuitePlugins/Groups/GroupFeaturedMemberEdit';
import SiteMembers from '@/components/SocialNetwork/SiteMembers'
import SiteMemberSingle from '@/components/SocialNetwork/SiteMemberSingle';
import Glossary from '@/components/SuitePlugins/Glossary/Glossary';
import SitePostSingle from '@/components/SocialNetwork/SitePostSingle'
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts'
import NoticeNew from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeNew'
import NoticeSingle from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeSingle'
import SocialDashboard from '@/components/Dashboards/SocialDashboard'

import SiteArticles from '@/components/SuitePlugins/Articles/SiteArticles';
import SiteArticleSingle from '@/components/SuitePlugins/Articles/Single';
import SiteArticleNew from '@/components/SuitePlugins/Articles/New/Single';
import SiteBlogs from '@/components/SuitePlugins/Blogs/SiteBlogs';
import SiteBlogSingle from '@/components/SuitePlugins/Blogs/Single';
import SiteBlogNew from '@/components/SuitePlugins/Blogs/New/Single';
import Classifieds from '@/components/SuitePlugins/Classifieds/Classifieds';
import ClassifiedSingle from '@/components/SuitePlugins/Classifieds/ClassifiedSingle';
import DirectoryViewer from '@/components/Directories/DirectoryViewer';
import SiteEventsSocial from '@/components/SuitePlugins/Events/SiteEvents';
import SiteForums from '@/components/SuitePlugins/Forums/SiteForums';
import SiteForumSingle from '@/components/SuitePlugins/Forums/SiteForumSingle';

import GroupPollsSingle from '@/components/SuitePlugins/Polls/Single';
import GroupBlog from '@/components/SuitePlugins/Blogs/Single';
import GroupForumSingle from '@/components/SuitePlugins/Forums/SiteForumSingle';
import GroupArticleSingle from '@/components/SuitePlugins/Articles/Single';
import MyDashboard from '@/components/Dashboards/MyDashboard'
import SiteMeetups from '@/components/SuitePlugins/Meetups/SiteMeetups';
import SiteMeetupSingle from '@/components/SuitePlugins/Meetups/Single';

import SitePolls from '@/components/SuitePlugins/Polls/SitePolls';
import SitePollSingle from '@/components/SuitePlugins/Polls/Single';
export default {
    props: ['System','SystemEntities','ActivePlugins','SocialNavActive','Notifications'],
    components: {
    FAQAdmin,PDFExportFunctionBuilder,ClientPortal,WikiCollectionBuilder,WebForms,RMAdmin,SecurityOverview,LandingPages,NewLandingPage,BannerAds,Campaigns,
  CampaignBuilder,NewBannerAd,Roadmap,SystemConfig,MyProfile,ChatbotBuilder,DataImports,DataImport,MyActivities,MySchedule,MyTeamSchedule,MyActivitiesbyType,
   MyTeamActivities,MyTeamMemberActivities,TeamMemberActivities,PipelineManagement,DashboardBuilder,SocialMediaPlatforms,Monetization,LinkedInAuth,MyDashboard,Goals,PDFExportTemplates,SuiteApp,
   GroupEdit,GroupNewBlog,GroupNewArticle,GroupNewPoll,Groups,GroupSingle,SiteMeetupNew,SitePollNew,DocumentationLibraryEditor,
   FeaturedMemberViewer,FeaturedMemberEditor,FeaturedMemberCreator,VacancySingleRoute,MarketplaceTemplates,MarketplaceTemplateSingle,Templates,GettingStarted,
   SuiteTemplates,SuiteTemplateWebsiteComponent,SuiteTemplateSocialNetwork,SuiteTemplateDatabaseApp,SuiteTemplateMainComp,
   WarehouseTransaction,WarehousesCollection,WarehouseSingle,Picking,Dispatching,BackorderProcessing,GoodsReceiving,PurchaseOrderSingle,PurchaseOrdersCollection,SalesOrderSingle,
   GroupFeaturedMemberView,DigitalBusinessCards,DigitalBusinessCardSingle,GroupFeaturedMemberEdit,SiteMembers,
SiteMemberSingle,Glossary,SitePostSingle,SocialSitePosts,NoticeNew,NoticeSingle,SocialDashboard,SiteArticles,SiteArticleSingle,
SiteArticleNew,SiteBlogs,SiteBlogSingle,SiteBlogNew,Classifieds,ClassifiedSingle,SiteEventsSocial,SiteForums,
SiteForumSingle,GroupSingle,GroupPollsSingle,GroupBlog,GroupForumSingle,GroupArticleSingle,SiteMeetups,
SiteMeetupSingle,SitePolls,SitePollSingle},
    data() {
        return {
          MemberCompanyVacancyCalls: [],
          AdvertDirectoryCall: '',
          VacancyDirectoryCall: '',
          MyAdvertsCalled: false,
          MyVacanciesCalled: false,
          ActiveStoreCall: '',
          StoreCalled: false,
          SubMenuActive: false,
          ActiveSubmenu: '',
          VisitorNotifications: [],
          ActiveHelperID: '',
          HelperElements: [
                {elmntid: 'visitortogglebar',Icon: 'mdi-account',Title: 'Visitor Bar',Text: `This will become the new Social Nav. Until then, use this menu to change the "Vistior" and interact as a sample User`},
                {elmntid: 'messagesbtn',Icon: 'mdi-message',Title: 'Messages',Text: `Here you can view your messages`},
                {elmntid: 'notificationsbtn',Icon: 'mdi-bell-check',Title: 'Notifications',Text: `Your Notifications will appear here.`},
                {elmntid: 'friendsbtn',Icon: 'mdi-account-multiple',Title: 'Friends',Text: `This is where you can see your friendslist.`},
                {elmntid: 'groupsbtn',Icon: 'mdi-account-group',Title: 'Groups',Text: `A quick navigation to Groups you belong to.`},
                {elmntid: 'invitesbtn',Icon: 'mdi-human-greeting',Title: 'Invites',Text: `Finally, you can see groups you are invited to that you have not yet responded to.`},
        ],
          ActivatingHelper: false,
          HelperActive: false,
          VisitorToggle: false,
          ActiveVisitor: '',
       MarketplaceTemplateRouteNames: ['MarketplaceTemplate'],
       SuiteTemplateRouteNames: ['Suite Template Main','Suite Template Website','Suite Template Social Network','Suite Template Database App'],     
      PerformedPluginBasedRouteCheck: false,
      SiteAdminRoutes: [        
        {
          path: '/Campaigns',
          name: 'campaigns',
          component: Campaigns,
          meta: {
            IsTopic: true,
            TopicCategory: 'Administration',
            TopicName: 'Campaigns'
          },
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/CampaignBuilder',
          name: 'campaignbuilder',
          component: CampaignBuilder,
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/BannerAds',
          name: 'bannerads',
          component: BannerAds,
          meta: {
            IsTopic: true,
            TopicCategory: 'Administration',
            TopicName: 'Banner Ads'
          },
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/LandingPages',
          name: 'landingpages',
          component: LandingPages,
          meta: {
            IsTopic: true,
            TopicCategory: 'Administration',
            TopicName: 'Landing Pages'
          },
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/BannerAd/Edit/:id',
          name: 'newbannerad',
          component: NewBannerAd,
          props:true,
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/LandingPage/Edit/:id',
          name: 'newlandingpage',
          component: NewLandingPage,
          props:true,
          meta: {
            
          },
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/PageBuilder',
          name: 'PageBuilder',
          component: NewLandingPage,
          props:true,
          meta: {
            PageBuilder: true
          },
          ORPlugins: ['Campaign_Builder']
        },
        {
          path: '/PageEditor/:id',
          name: 'PageEditor',
          component: NewLandingPage,
          props:true,
          meta: {
            PageEditor: true
          },
          ORPlugins: ['Website_Builder','Social_Network_Builder']
        },
      ],
      AdminRoutes: [
        {
          path: '/Getting-Started',
          name: 'GettingStarted',
          component: GettingStarted,
          Basic: true,
        },
        {
          path: '/DocumentationLibraryEditor/:id/:slug',
          name: 'DocumentationLibraryEditor',
          component: DocumentationLibraryEditor,
          ORPlugins: ['Documentation_Builder'],
        },
        {
          path: '/Monetization',
          name: 'Monetization',
          component: Monetization,
          ORPlugins: ['Monetization','Group_Directories','Group_Monetization']
        },
        {
          path: '/SocialMediaPlatforms',
          name: 'SocialMediaPlatforms',
          component: SocialMediaPlatforms,
          ORPlugins: ['Social_Centre']
        },
        {
          path: '/Goals',
          name: 'Goals',
          component: Goals,
          ANDPlugins: ['Goals','Database_Builder']
          //Basic: true
        },
       {
        path: '/WebFormBuilder/:id',
        name: 'WebFormEditor',
        component: WebFormBuilder,
        props: true,
        ORPlugins: ['Web_Forms']
      },
      {
        path: '/WebFormBuilder-New',
        name: 'WebFormBuilderNew',
        component: WebFormBuilder,
        props: true,
        ORPlugins: ['Web_Forms']
      },
      {
        path: '/SurveyBuilder/:id',
        name: 'SurveyBuilder',
        component: SurveyBuilder,
        ORPlugins: ['Surveys']
      },
      {
        path: '/SurveyBuilder-New',
        name: 'SurveyBuilderNew',
        component: SurveyBuilder,
        ORPlugins: ['Surveys']
      },
        {
          path: '/DirectoryBuilder/:id',
          name: 'DirectoryBuilder',
          component: DirectoryBuilder,
          ORPlugins: ['Database_Builder','Featured_Members']
        },
        {
          path: '/DBNavlists',
          name: 'DBNavlists',
          component: DBNavlistsComponent
        },
         {
          path: '/SystemTableFilters',
          name: 'SystemTableFiltersComponent',
          component: SystemTableFiltersComponent,
          Basic: true
        },
        
          {
            path: '/FAQAdmin',
            name:'FAQAdmin', 
            component: FAQAdmin,
            ORPlugins: ['FAQ']
          },
          {
            path: '/PDFExportTemplates',
            name:'PDFExportTemplates', 
            component: PDFExportTemplates,
            ORPlugins: ['PDF_Builder']
          },
          {
            path: '/PDFExportFunctionBuilder/:slug',
            name: 'PDFExportFunctionBuilder',
            component: PDFExportFunctionBuilder,
            ORPlugins: ['PDF_Builder']
          },
          {
            path: '/PipelineManagement/:id',
            name:'PipelineManagement', 
            component: PipelineManagement,
            ORPlugins: ['Pipelines']
            //Basic: true
          },
          {
            path: '/WikiCollectionBuilder/:id',
            name: 'WikiCollectionBuilder',
            component: WikiCollectionBuilder,
            meta: {
              IsTopic: true,
              TopicCategory: 'Administration',
              TopicName: 'Wiki Collection Builder',
              WikiCollectionBuilder: true,
              WikiType: true,
            },
            ORPlugins: ['Database_Builder']
          },
          //MISSING!! WHAT WAS THIS???
          // {
          //   path: '/ContentLibraryBuilder',
          //   name: 'ContentLibraryBuilder',
          //   component: ContentLibraryBuilder,
          //   meta: {
          //     IsTopic: true,
          //     TopicCategory: 'Administration',
          //     TopicName: 'HTML Library Builder',
          //     ContentLibraryBuilder: true,
          //   }
          // },
          {
            path: '/WebForms',
            name: 'WebForms',
            component: WebForms,
            meta: {
              IsTopic: true,
              TopicCategory: 'Administration',
              TopicName: 'Web Forms',
              WebFormCollection: true,
            },
            ORPlugins: ['Web_Forms']
          },
          {
    path: '/RMAdministration',
    name: 'RMAdmin',
    component: RMAdmin,
    ORPlugins: ['Cabinets']
  },
  {
    path: '/SecurityOverview',
    name: 'SecurityOverview',
    component: SecurityOverview,
    props:true,
    Basic: true
  },
  {
    path: '/Roadmap',
    name: 'Roadmap',
    component: Roadmap,
    Basic: true
  },
  {
    path: '/SystemConfig/:id',
    name: 'SystemConfig',
    component: SystemConfig,
    props: true,
    meta: {
      RecordisSystem: true,
      Collection: 'SystemConfig',
      DataSingleBuilder: true,
      SystemConfigBuilder: true,
      icon: 'mdi-apps',
      identifier: 'Name',
      RouteEventID: 2001,
      type: 'System Config',
      subcat: 'System Config',
      EntityID: 'Systems',
      viewicon: 'mdi-library',
      SingleRecordNavMenuView: true,
      },
    Basic: true
  },
   {
    path: '/DataImports',
    name: 'DataImports',
    component: DataImports,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Imports'
    },
    ORPlugins: ['Data_Importer']
  },
  {
    path: '/DataImport/:id',
    name: 'DataImport',
    component: DataImport,
    ORPlugins: ['Data_Importer']
  },
  {
    path: '/ChatbotBuilder',
    name: 'ChatbotBuilder',
    component: ChatbotBuilder,
    ORPlugins: ['Chatbot']
  },
      ],
      STDUserRoutes: [
        {
        path: '/MyAccount',
        name: 'MyAccount',
        component: ClientPortal,
        Basic: true
        //ORPlugins: ['Client_Portal']
      },
        {
          path: '/Groups',
          name: 'Groups',
          component: Groups,
          meta: {
            RouteEventID: 1013,
            IsTopic: true,
            TopicCategory: 'Social Page',
            TopicName: 'Groups'
          },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
            path: '/NewPoll',
            name: 'SitePollNew',
            component: SitePollNew,
            //MISSING!! 
            ORPlugins: ['Site_Polls']
          },
        {
            path: '/NewMeetup',
            name: 'SiteMeetupNew',
            component: SiteMeetupNew,            
            //MISSING!! 
            ORPlugins: ['Site_Meetups']
          },
        //   {
        //   path: '/Site/:id',
        //   name: 'SiteSingle',
        //   component: GroupSingle,
        //   meta: {
        //     RouteEventID: 1014,
        //     icon: 'mdi-account-group-outline',
        //     type: 'Social',
        //     identifier: 'Name',
        //     subcat: 'Group',
        //     viewicon: 'mdi-account-group-outline',
            
        //     }
        // },
        {
          path: '/Group/:id',
          name: 'GroupSingle',
          component: GroupSingle,
          meta: {
            RouteEventID: 1014,
            icon: 'mdi-account-group-outline',
            type: 'Social',
            identifier: 'Name',
            subcat: 'Group',
            viewicon: 'mdi-account-group-outline',
            
            },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/Edit/:id',
          name: 'GroupEdit',
          component: GroupEdit,
          props: true,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewArticle/:id',
          name: 'GroupNewArticle',
          component: GroupNewArticle,
          props: true,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewMeetup/:id',
          name: 'GroupNewMeetup',
          component: SiteMeetupNew,
          props: true,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewBlog/:id',
          name: 'GroupNewBlog',
          component: GroupNewBlog,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group-PageEditor/:id/:slug',
          name: 'GroupPageEditor',
          component: NewLandingPage,
          meta: {
          },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group-PageBuilder/:id',
          name: 'GroupPageBuilder',
          component: NewLandingPage,
          meta: {
          },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewPoll/:id',
          name: 'GroupNewPoll',
          component: GroupNewPoll,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
        path: '/GroupWebFormBuilder/:id',
        name: 'GroupWebFormEditor',
        component: WebFormBuilder,
        props: true,
          //MISSING!! 
          ANDPlugins: ['Site_Groups','Web_Forms']
      },
          {
          path: '/GroupWebFormBuilder',
          name: 'GroupWebFormBuilder',
          component: WebFormBuilder,
          props: true,
          //MISSING!! 
          ANDPlugins: ['Site_Groups','Web_Forms']
        },
        {
        path: '/CustomDashboard/:slug',
        name: 'CustomDashboard',
        component: DashboardBuilder,
        Basic: true,
        meta: {
          RouteEventID: 1037,
        },
          //ORPlugins: ['Custom_Dashboard']
      },
        {
          path: '/LinkedInAuth',
          name: 'LinkedInAuth',
          component: LinkedInAuth,
           ORPlugins: ['Social_Centre']
        },      
       {
          path: '/MyActivities/:id',
          name: 'MyActivitiesbyType',
          component: MyActivitiesbyType,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/MyTeamSchedule',
          name: 'MyTeamSchedule',
          component: MyTeamSchedule,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/MySchedule',
          name: 'MySchedule',
          component: MySchedule,
          ORPlugins: ['Activities_and_Team']
        },
        
        {
          path: '/MyTeamActivities',
          name: 'MyTeamActivities',
          component: MyTeamActivities,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/MyTeamActivities/:id',
          name: 'MyTeamMemberActivities',
          component: MyTeamMemberActivities,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/TeamMemberActivities/:id/:prop',
          name: 'TeamMemberActivities',
          component: TeamMemberActivities,
          ORPlugins: ['Activities_and_Team']
        },
        // {
        //   path: '/Activity/:id',
        //   name: 'activity',
        //   component: Activity
        // },
        {
          path: '/MyActivities',
          name: 'MyActivities',
          component: MyActivities,
          ORPlugins: ['Activities_and_Team']
        },
      ],
      NonGuestRoutes: [{
              path: '/WarehouseTransaction/:slug/:id',
              name: 'WarehouseTransaction',
              component: WarehouseTransaction,
              meta: {
                RouteEventID: 1046,
                id: 'WarehouseTransaction',
                icon: 'mdi-warehouse',
                type: 'Warehouse',
                subcat: 'WarehouseTransaction',
                viewicon: 'mdi-warehouse',
                IsTopic: true,
                TopicCategory: 'Warehouse',
                TopicName: 'Warehouse Transaction'
                }
            },
            {
              path: '/Warehouses/:id',
              name: 'WarehousesCollection',
              component: WarehousesCollection,
              meta: {
                RouteEventID: 1047,
                id: 'WarehousesCollection',
                icon: 'mdi-warehouse',
                type: 'Warehouse',
                subcat: 'WarehousesCollection',
                viewicon: 'mdi-warehouse',
                IsTopic: true,
                TopicCategory: 'Warehouse',
                TopicName: 'Warehouse Collection'
                }
            },
            {
              path: '/Warehouse/:slug/:id',
              name: 'WarehouseSingle',
              component: WarehouseSingle,
              meta: {
                RouteEventID: 1048,
                id: 'WarehouseSingle',
                icon: 'mdi-warehouse',
                type: 'Warehouse',
                subcat: 'WarehouseSingle',
                viewicon: 'mdi-warehouse',
                IsTopic: true,
                TopicCategory: 'Warehouse',
                TopicName: 'Warehouse Single'
                }
            },
            {
              path: '/SalesOrder/:slug/:id',
              name: 'SalesOrderSingle',
              component: SalesOrderSingle,
              meta: {
                RouteEventID: 1049,
                id: 'SalesOrderSingle',
                icon: 'mdi-cart',
                type: 'SCM',
                subcat: 'SalesOrderSingle',
                viewicon: 'mdi-cart',
                IsTopic: true,
                TopicCategory: 'SalesOrder',
                TopicName: 'Sales Order Single'
                }
            },
            {
              path: '/Dispatching/:slug/:id',
              name: 'Dispatching',
              component: Dispatching,
              meta: {
                RouteEventID: 1053,
                id: 'Dispatching',
                icon: 'mdi-cube-send',
                type: 'Dispatching',
                subcat: 'Dispatching',
                viewicon: 'mdi-cube-send',
                IsTopic: true,
                TopicCategory: 'Dispatching',
                TopicName: 'Dispatching'
                }
            },
            {
              path: '/Picking/:id',
              name: 'Picking',
              component: Picking,
              meta: {
                RouteEventID: 1053,
                id: 'Picking',
                icon: 'mdi-hand-okay',
                type: 'Picking',
                subcat: 'Picking',
                viewicon: 'mdi-hand-okay',
                IsTopic: true,
                TopicCategory: 'Picking',
                TopicName: 'Picking '
                }
            },
            {
              path: '/PurchaseRequisitions',
              name: 'PurchaseRequisitions',
              component: BackorderProcessing,
              meta: {
                RouteEventID: 1056,
                id: 'PurchaseRequisitions',
                icon: 'mdi-factory',
                type: 'Purchase Requisitions',
                subcat: 'PurchaseRequisitions',
                viewicon: 'mdi-factory',
                IsTopic: true,
                TopicCategory: 'Purchase Requisitions',
                TopicName: 'Purchase Requisitions'
                }
            },
            {
              path: '/BackorderProcessing/:id',
              name: 'BackorderProcessing',
              component: BackorderProcessing,
              meta: {
                RouteEventID: 1050,
                id: 'BackorderProcessing',
                icon: 'mdi-factory',
                type: 'Backorder Processing',
                subcat: 'BackorderProcessing',
                viewicon: 'mdi-factory',
                IsTopic: true,
                TopicCategory: 'Backorder Processing',
                TopicName: 'Backorder Processing'
                }
            },
            {
              path: '/PurchaseOrder/:slug/:id',
              name: 'PurchaseOrderSingle',
              component: PurchaseOrderSingle,
              meta: {
                RouteEventID: 1051,
                id: 'PurchaseOrderSingle',
                icon: 'mdi-cash-register',
                type: 'Purchase Order',
                subcat: 'PurchaseOrder',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'Purchase Order',
                TopicName: 'Purchase Order Single'
                }
            },
            {
              path: '/PurchaseOrders/:id',
              name: 'WarehousePurchaseOrders',
              component: PurchaseOrdersCollection,
              meta: {
                RouteEventID: 1057,
                id: 'WarehousePurchaseOrders',
                icon: 'mdi-cash-register',
                type: 'Warehouse Purchase Orders',
                subcat: 'WarehousePurchaseOrders',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'WarehousePurchaseOrders',
                TopicName: 'Warehouse Purchase Orders'
                }
            },
            {
              path: '/PurchaseOrders',
              name: 'PurchaseOrders',
              component: PurchaseOrdersCollection,
              meta: {
                RouteEventID: 1058,
                id: 'PurchaseOrders',
                icon: 'mdi-cash-register',
                type: 'Purchase Orders',
                subcat: 'PurchaseOrders',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'PurchaseOrders',
                TopicName: 'Purchase Orders'
                }
            },
            {
              path: '/GoodsReceiving/:slug',
              name: 'GoodsReceivingCollection',
              component: GoodsReceiving,
              meta: {
                RouteEventID: 1052,
                id: 'GoodsReceiving',
                icon: 'mdi-cash-register',
                type: 'Goods Receiving',
                subcat: 'GoodsReceiving',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'Goods Receiving',
                TopicName: 'Goods Receiving Collection'
                }
            },
        {
        path: '/App/:id',
        name: 'SuiteApp',
        component: SuiteApp,
        meta: {
          RouteEventID: 1059,
          IsTopic: true,
          TopicCategory: 'Suite App Single',
          TopicName: 'Suite Apps'
        }
      },
        {
        path: '/MyProfile',
        name: 'MyProfile',
        component: MyProfile,
        meta: {
          IsTopic: true,
          TopicCategory: 'Users',
          TopicName: 'My Profile'
        }
      },
       {
            path: '/MyDashboard',
            name: 'MyDashboard',
            component: MyDashboard,
            ORPlugins: ['Activities_and_Team','Pipelines','Goals','Social_Network_Builder']
          },
          {
        path: '/DashboardBuilder/:slug',
        name: 'DashboardBuilder',
        component: DashboardBuilder,  
        // props:true,      
        ORPlugins: ['Custom_Dashboard']
      },
      ],
      SignedInSocialRoutes: [
        //SuiteTemplateSingle
        {
          path: '/Your-Domain/:id/Main',
          name: 'Suite Template Main',
          component: SuiteTemplateMainComp,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Website',
          name: 'Suite Template Website',
          //component: SuiteTemplateWebsite,
          component: SuiteTemplateWebsiteComponent,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network',
          name: 'Suite Template Social Network',
          component: SuiteTemplateSocialNetwork,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Articles',
          name: 'SuiteTemplateSiteArticles',
          component: SiteArticles,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/NewArticle',
          name: 'SuiteTemplateSiteArticleNew',
          component: SiteArticleNew,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Article/:slug',
          name: 'SuiteTemplateSiteArticleSingle',
          component: SiteArticleSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Blogs',
          name: 'SuiteTemplateSiteBlogs',
          component: SiteBlogs,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/NewBlog',
          name: 'SuiteTemplateSiteBlogNew',
          component: SiteBlogNew,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network/Blog/:slug',
          name: 'SuiteTemplateSiteBlogSingle',
          component: SiteBlogSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network/Classifieds',
          name: 'SuiteTemplateClassifieds',
          component: DirectoryViewer,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Classified/:slug',
          name: 'SuiteTemplateClassifiedSingle',
          component: ClassifiedSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/ClassifiedEnquiry/:slug',
          name: 'SuiteTemplateClassifiedEnquiry',
          component: Classifieds,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Events',
          name: 'SuiteTemplateEvents',
          component: SiteEventsSocial,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network/Events/:slug',
          name: 'SuiteTemplateSiteEventSocialSingle',
          component: SiteEventsSocial,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Forums',
          name: 'SuiteTemplateSiteForums',
          component: SiteForums,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network/Forum/:slug',
          name: 'SuiteTemplateSiteForumSingle',
          component: SiteForumSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network/GroupDashboard/:slug',
          name: 'SuiteTemplateGroupDashboard',
          component: MyDashboard,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Glossary',
          name: 'SuiteTemplateGlossary',
          component: Glossary,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/SiteMembers',
          name: 'SuiteTemplateSiteMembers',
          component: SiteMembers,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/SiteMember/:slug',
          name: 'SuiteTemplateSiteMemberSingle',
          component: SiteMemberSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/DigitalBusinessCards',
          name: 'SuiteTemplateDigitalBusinessCards',
          component: DigitalBusinessCards,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/BusinessMember/:slug',
          name: 'SuiteTemplateBusinessMember',
          component: DigitalBusinessCardSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/SocialDashboard',
          name: 'SuiteTemplateSocialDashboard',
          component: SocialDashboard,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Meetups',
          name: 'SuiteTemplateSiteMeetups',
          component: SiteMeetups,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/NewMeetup',
          name: 'SuiteTemplateSiteMeetupNew',
          component: SiteMeetupNew,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Meetup/:slug',
          name: 'SuiteTemplateSiteMeetupSingle',
          component: SiteMeetupSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Polls',
          name: 'SuiteTemplateSitePolls',
          component: SitePolls,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/NewPoll',
          name: 'SuiteTemplateSitePollNew',
          component: SitePollNew,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/SitePoll/:slug',
          name: 'SuiteTemplateSitePollSingle',
          component: SitePollSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        //AND GROUPS
        {
          path: '/Your-Domain/:id/Social-Network/Groups',
          name: 'SuiteTemplateGroups',
          component: Groups,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/GroupWebFormBuilder',
          name: 'SuiteTemplateGroupWebFormBuilder',
          component: WebFormBuilder,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/GroupWebFormBuilder/:slug',
          name: 'SuiteTemplateGroupWebFormEditor',
          component: WebFormBuilder,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Group/:slug',
          name: 'SuiteTemplateGroupSingle',
          component: GroupSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Group/Edit/:slug',
          name: 'SuiteTemplateGroupEdit',
          component: GroupEdit,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Group/NewArticle/:slug',
          name: 'SuiteTemplateGroupNewArticle',
          component: GroupNewArticle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
         {
          path: '/Your-Domain/:id/Social-Network/Group/NewBlog/:slug',
          name: 'SuiteTemplateGroupNewBlog',
          component: GroupNewBlog,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Group/NewMeetup/:slug',
          name: 'SuiteTemplateGroupNewMeetup',
          component: SiteMeetupNew,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/Your-Domain/:id/Social-Network/Group/NewPoll/:slug',
          name: 'SuiteTemplateGroupNewPoll',
          component: GroupNewPoll,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        
        {
          path: '/Your-Domain/:id/Database-App/:slug',
          name: 'Suite Template Database App',
          component: SuiteTemplateDatabaseApp,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        {
        path: '/Your-Domain/:id/CustomDashboard/:slug',
        name: 'SuiteTemplateCustomDashboard',
        component: DashboardBuilder,
          ORPlugins: ['Web_App_Builder'],
        },
        {
        path: '/Your-Domain/:id/DirectoryBuilder/:slug',
        name: 'SuiteTemplateDirectoryBuilder',
        component: DirectoryBuilder,
          ORPlugins: ['Web_App_Builder'],
        },
        {
          path: '/MySuiteTemplates',
          name: 'MySuiteTemplates',
          component: SuiteTemplates,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
        },
        //let's try cheat it in with CheckTemplateRoutes
        //if there is a Marketplace_Templates plugin
        //take special method take all routes propogate them
        
        //looking to add cols views
        //Articles, Blogs, Classifieds, Events, "FAQAdmin from Social Net config right?", Forums, Glossary, Groups/ Meetups,Polls
        //Then single view for all except Events, Glossary
        //Then "edit" view for all with "Single" except Forums as "Edit" for Forums always been done straight on "Single", also unsure of "Polls" and how to edit?
        // {
        //   path: '/MarketplaceTemplate/:id/Articles',
        //   name: 'MarketplaceTemplateSiteArticles',
        //   component: SiteArticles,
        //   //Basic: true
        //   ORPlugins: ['Featured_Members'],
        // },
        // {
        //   path: '/MarketplaceTemplate/:id/Article/:slug',
        //   name: 'MarketplaceTemplateSiteArticleSingle',
        //   component: SiteArticleSingle,
        //   //Basic: true
        //   ORPlugins: ['Featured_Members'],
        // },
        //Problem in Suite
        //Suite template path for "Articles"
        //path: '/Your-Domain/:id/Social-Network/Articles',
        //but what about article sing...oh nevermind
        //path: '/Your-Domain/:id/Social-Network/Article/:slug',
        {
          path: '/MyMarketplaceTemplates',
          name: 'MyMarketplaceTemplates',
          component: MarketplaceTemplates,
          //Basic: true
          ORPlugins: ['Marketplace_Templates'],
        },
         {
          path: '/FeaturedMemberCreator/:id',
          name: 'FeaturedMemberCreator',
          component: FeaturedMemberCreator,
          ORPlugins: ['Featured_Members'],
        },
        {
          path: '/FeaturedMemberEditor/:id/:slug',
          name: 'FeaturedMemberEditor',
          component: FeaturedMemberEditor,
          ORPlugins: ['Featured_Members'],
        },
          {
            path: '/Articles',
            name: 'SiteArticles',
            component: SiteArticles,
          ORPlugins: ['Site_Articles'],
          Channel: 'Site Articles',
            meta: {
              RouteEventID: 1003,
              IsTopic: true,
              TopicCategory: 'Social Page',
              TopicName: 'Articles'
            }
          },
        {
              path: '/Blogs',
              name: 'SiteBlogs',
              component: SiteBlogs,
            ORPlugins: ['Site_Blogs'],
          Channel: 'Site Blogs',
              meta: {
                RouteEventID: 1001,
                IsTopic: true,
                TopicCategory: 'Social Page',
                TopicName: 'Blogs'
              }
            },
            {
                path: '/SiteMembers',
                name: 'SiteMembers',
                component: SiteMembers,
                ORPlugins: ['Social_Network_Builder'],
                meta: {
                  RouteEventID: 1035,
                }
              },
               {
                path: '/SiteMember/:id',
                name: 'SiteMemberSingle',
                component: SiteMemberSingle,
                ORPlugins: ['Social_Network_Builder'],
                meta: {
                  RouteEventID: 1036,
                }
              },
              {
                path: '/Glossary',
                name: 'Glossary',
                component: Glossary,
                ORPlugins: ['Glossary'],
                meta: {
                  RouteEventID: 1034,
                  IsTopic: true,
                  TopicName: 'Glossary',
                  TopicCategory: 'General'
                }
              },
              {
                path: '/Feed',
                //is this for Groups???? or is it "query" to "comment"?
                name: 'SocialSitePosts',
                component: SocialSitePosts,
                ORPlugins: ['Social_Network_Builder'],
                props:true,
                meta: {
                  RouteEventID: 1060,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Site Post'
                }
              },
              {
                path: '/SitePosts/:id/:slug',
                //is this for Groups???? or is it "query" to "comment"?
                name: 'SitePostSingle',
                component: SitePostSingle,
                ORPlugins: ['Social_Network_Builder'],
                props:true,
                meta: {
                  RouteEventID: 1044,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Site Post'
                }
              },
              {
                path: '/SitePosts/:id',
                name: 'SitePostSingle',
                component: SitePostSingle,
                ORPlugins: ['Social_Network_Builder'],
                props:true,
                meta: {
                  RouteEventID: 1044,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Site Post'
                }
              },
              {
                path: '/NoticeSingle/:id',
                name: 'noticesingle',
                component: NoticeSingle,
                ORPlugins: ['Digital_NoticeBoard'],
                props:true,
                meta: {
                  RouteEventID: 1031,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Notices'
                }
              },
              {
                path: '/NoticeNew/:id',
                name: 'noticenew',
                component: NoticeNew,
                ORPlugins: ['Digital_NoticeBoard'],
                props:true
              },
              {
                path: '/SocialDashboard',
                name: 'SocialDashboard',
                component: SocialDashboard,
                ORPlugins: ['Social_Network_Builder'],
              },
              
                 {
                  path: '/NewArticle',
                  name: 'SiteArticleNew',
                ORPlugins: ['Site_Articles'],
                  component: SiteArticleNew
                },                
                {
                  path: '/NewBlog',
                  name: 'SiteBlogNew',
                ORPlugins: ['Site_Blogs'],
                  component: SiteBlogNew
                },
                {
                  path: '/Events',
                  //has as plain  name: 'Events', that is wrong although "data table?" yeah sould be "siteevents" unsure
                  //wanted to change to name: 'SiteEvents', but will keep as "events" dude, fix on intranet navbar, seriously
                  name: 'Events',
                  ORPlugins: ['Site_Events'],
          Channel: 'Site Events',
                  component: SiteEventsSocial,
                  meta: {
                    RouteEventID: 1007,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Events'
                  }
                },
                {
                  path: '/Meetups',
                  name: 'SiteMeetups',
                  ORPlugins: ['Site_Meetups'],
          Channel: 'Site Meetups',
                  component: SiteMeetups,
                  meta: {
                    RouteEventID: 1042,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Meetups'
                  }
                },
                {
                  path: '/Polls',
                  name: 'SitePolls',
                  ORPlugins: ['Site_Polls'],
          Channel: 'Site Polls',
                  component: SitePolls,
                  meta: {
                    RouteEventID: 1009,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Polls'
                  }
                },
                {
                  path: '/SitePoll/:id',
                  name: 'SitePollSingle',
                  ORPlugins: ['Site_Polls'],
                  component: SitePollSingle,
                  meta: {
                    RouteEventID: 1010,
                    icon: 'mdi-poll-box',
                    type: 'Social',
                    identifier: 'PollName',
                    subcat: 'Site Poll',
                    viewicon: 'mdi-poll-box',
                    collection:'sitepolls'
                    }
                },
      ],
      PublicSocialRoutes: [
          {
          path: '/Site/:id',
          name: 'SiteSingle',
          component: SiteSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Web_App_Builder'],
          },
         {
          path: '/MarketplaceTemplate/:id',
          name: 'MarketplaceTemplate',
          component: MarketplaceTemplateSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Marketplace_Templates'],
        },
         {
          path: '/MarketplaceTemplate/:id',
          name: 'MarketplaceTemplate',
          component: MarketplaceTemplateSingle,
          props: true,
          //Basic: true
          ORPlugins: ['Marketplace_Templates'],
        },
        {
              path: '/Templates',
              name: 'templates',
              component: Templates,
              ORPlugins: ['Web_App_Builder']
            },
            {
              path: '/SignUp',
              name: 'signup',
              component: SignUp,
              ORPlugins: ['Public_Signup']
            },
              {
                path: '/BusinessMember/:id',
                name: 'BusinessMember',
                component: DigitalBusinessCardSingle,
                ORPlugins: ['Digital_Business_Cards']
              },
              {
                path: '/DigitalBusinessCards',
                name: 'DigitalBusinessCards',
                component: DigitalBusinessCards,
                ORPlugins: ['Digital_Business_Cards']
              },  
              {
                path: '/FeaturedGroupMember/:slug/:id',
                name: 'GroupFeaturedMemberView',
                component: GroupFeaturedMemberView,
                ORPlugins: ['Group_Featured_Members']
              },
              {
                path: '/FeaturedGroupMember/Edit/:slug/:id',
                name: 'FeaturedGroupMemberEdit',
                component: GroupFeaturedMemberEdit,
                props: true,
                ORPlugins: ['Group_Featured_Members']
              },
              {
                path: '/FeaturedMember/:id/:slug',
                name: 'FeaturedMemberViewer',
                component: FeaturedMemberViewer,
                ORPlugins: ['Featured_Members']
              },
              {
                path: '/Vacancy/:id',
                name: 'VacancySingle',
                component: VacancySingleRoute,
                ORPlugins: ['Vacancies'],
                meta: {
                    RouteEventID: 1061,
                    icon: 'mdi-briefcase',
                    type: 'Social',
                    identifier: 'Title',
                    subcat: 'Vacancy',
                    viewicon: 'mdi-briefcase',
                    collection:'vacancies'
                    }
              },
              
             
                {
                  path: '/Article/:id',
                  name: 'SiteArticleSingle',
                  component: SiteArticleSingle,
                ORPlugins: ['Site_Articles'],
                  meta: {
                    RouteEventID: 1004,
                    icon: 'mdi-poll-box',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Article',
                    viewicon: 'mdi-poll-box',
                    collection:'sitearticles'
                    }
                },               
                {
                  path: '/Blog/:id',
                  name: 'SiteBlogSingle',
                  component: SiteBlogSingle,
                ORPlugins: ['Site_Blogs'],
                  meta: {
                    RouteEventID: 1002,
                    icon: 'mdi-blogger',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Blog',
                    viewicon: 'mdi-blogger',
                    collection:'siteblogs'
                    }
                },
                {
                  path: '/Classifieds',
                  name: 'Classifieds',
                 component: DirectoryViewer, 
                  ORPlugins: ['Classifieds'],
                 //actualy long term correct?
                 // component: Classifieds,
                  meta: {
                    RouteEventID: 1011,
                    TopicCategory: 'Social Page',
                  }
                },
                {
                  path: '/Classified/:id',
                  name: 'ClassifiedSingle',
                ORPlugins: ['Classifieds'],
                  component: ClassifiedSingle,
                  meta: {
                    RouteEventID: 1012,
                  }
                },
                {
                  path: '/ClassifiedEnquiry/:id',
                  name: 'ClassifiedEnquiry',
                  ORPlugins: ['Classifieds'],
                  component: Classifieds,
                  props: {
                    IsUserView: true
                  },
                  meta: {
                    RouteEventID: 1012,
                  }
                },                
                
                 {
                  path: '/Events/:id',
                  name: 'SiteEventSocialSingle',
                  ORPlugins: ['Site_Events'],
                  component: SiteEventsSocial,
                  meta: {
                    RouteEventID: 1008,
                    icon: 'mdi-calendar-month',
                    type: 'Social',
                    identifier: 'name',
                    subcat: 'Site Event',
                    viewicon: 'mdi-calendar-month',
                    }
                },
                {
                  path: '/Forums',
                  name: 'SiteForums',
                  ORPlugins: ['Site_Forums'],
                  component: SiteForums,
                  meta: {
                    RouteEventID: 1005,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Forums'
                  }
                },
                {
                  path: '/Forum/:id',
                  name: 'SiteForumSingle',
                  ORPlugins: ['Site_Forums'],
                  component: SiteForumSingle,
                  meta: {
                    RouteEventID: 1006,
                    icon: 'mdi-forum-outline',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Forum',
                    viewicon: 'mdi-forum-outline',
                    collection:'siteforumdiscussions'
                    }
                },
                {
                  path: '/Group-Article/:id/Article/:slug',
                  name: 'GroupArticle',
                  ORPlugins: ['Site_Groups'],
                  component: GroupArticleSingle,
                  meta: {
                    RouteEventID: 1018,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Article',
                    collection:'grouparticles'
                  }
                },
                
                {
                  path: '/Group-Classified/:id/Classified/:slug',
                  name: 'GroupClassified',
                  ORPlugins: ['Site_Groups'],
                  component: ClassifiedSingle,
                  meta: {
                    RouteEventID: 1021,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Classified',
                    collection:'groupclassifieds'
                  }
                },
                {
                  path: '/Group-Meetup/:id/Meetup/:slug',
                  name: 'GroupMeetup',
                  ORPlugins: ['Site_Groups'],
                  component: SiteMeetupSingle,
                  meta: {
                    RouteEventID: 1019,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Meetup',
                    collection:'groupmeetups'
                  }
                },
                {
                  path: '/GroupDashboard/:id',
                  name: 'GroupDashboard',
                  ORPlugins: ['Site_Groups'],
                  component: MyDashboard
                },
                
                {
                  path: '/Group-Blog/:id/Blog/:slug',
                  name: 'GroupBlog',
                  ORPlugins: ['Site_Groups'],
                  component: GroupBlog,
                  meta: {
                    RouteEventID: 1016,
                    IsTopic: true,
                    TopicName: 'Group Blog',
                    TopicCategory: 'Social Group',
                    Help: 'www.carebears.com',
                    collection:'groupblogs'
                  }
                },
                {
                  path: '/Group-Forum/:id/Discussion/:slug',
                  name: 'GroupForum',
                  ORPlugins: ['Site_Groups'],
                  component: GroupForumSingle,
                  meta: {
                    RouteEventID: 1020,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Forum',
                    collection:'groupforumdiscussions'
                  }
                },
                {
                  path: '/Group-Page/:id/:slug',
                  name: 'GroupPage',
                  ORPlugins: ['Site_Groups'],
                  component: GroupSingle,
                  meta: {
                    RouteEventID: 1015,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Page',
                    type: 'Group Landing Pages'
                  }
                },
                
                {
                  path: '/Group-Poll/:id/Poll/:slug',
                  name: 'GroupPoll',
                  ORPlugins: ['Site_Groups'],
                  component: GroupPollsSingle,
                  meta: {
                    RouteEventID: 1024,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Poll',
                    collection:'grouppolls'
                  }
                },
                {
                  path: '/Meetup/:id',
                  name: 'SiteMeetupSingle',
                  ORPlugins: ['Site_Meetups'],
                  component: SiteMeetupSingle,
                  meta: {
                    RouteEventID: 1043,
                    icon: 'mdi-blogger',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Meetup',
                    viewicon: 'mdi-blogger',
                    collection:'sitemeetups'
                    }
                },
              
            ],
      GuestRoutes: [
        {
        path: '/MyAccount',
        name: 'MyAccount',
        component: ClientPortal,  
        ORPlugins: ['Public_Signup']
      },
      
      ],
      EagleRoutes: [
        {
        path: '/Ledgers/:id',
        name: 'LedgersCollection',
        component: LedgersCollection,
        meta: {
          RouteEventID: 1055,
          id: 'LedgersCollection',
          icon: 'mdi-cash-register',
          type: 'Ledgers Collection',
          subcat: 'LedgersCollection',
          viewicon: 'mdi-cash-register',
          IsTopic: true,
          TopicCategory: 'Ledgers Collection',
          TopicName: 'Ledgers Collection'
          },
             
        ORPlugins: ['Online_Store','Monetization','Group_Monetization','Group_Directories','Cash_Loans','Loan_Orders','Accounting_Ledger']
      }
      
      ],
      SitesCalled: false,
        }
    },	
    computed:{
      MyMemberCompanies(){
        return this.$store.state.MyMemberCompanies
      },
      SiteContentAdmin(){
        return this.userLoggedIn && this.userLoggedIn.SiteContentAdmin
      },
      RAAdmin(){
          return this.RAUserObj && this.RAUserObj.IsSystemAdmin
      },
      RAUserObj(){
          return this.$store.state.RAUserObj
      },
      UserSites(){
        return this.userLoggedIn && this.userLoggedIn.Sites ? this.userLoggedIn.Sites : []
      },
      UserApps(){
        return this.AppsDataBase.filter(item => {
          return this.userLoggedIn && this.userLoggedIn.Apps.includes(item.id) || this.userIsAdmin
        })
      },
      SuiteAppView(){
        let routes = ['TableViewer','EntryViewer','StockMovement','SuiteApp','LedgersCollection','MarketplaceTemplateSuiteApp']
        let routeprops = ['TableBuilder','DataSingleBuilder','DataCollection']
        let pass = routes.includes(this.$route.name)
        routeprops.map(prp => {
          if(this.$route.meta && this.$route.meta[prp]){
            pass = true
          }
        })
        //we COULD actually prevent if UserApps does not include
        return pass
      },
      ComputedNotifications(){
        return this.ActiveMarketTemplate && this.ActiveVisitor ? this.VisitorNotifications : this.Notifications
      } ,     
      HelperHalted(){
          return this.StoppedHelpers.includes(this.ActiveHelperID)
      },
      StoppedHelpers(){
          return this.$store.state.StoppedHelpers
      },
      ActiveVisitorFriendsList(){
        return this.TemplateUsers
      },
      ActiveVisitorGroups(){
        return []
      },
      ActiveVisitorGroupInvites(){
        return []
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
        SystemisRA(){
          return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        AppsPath(){
          return this.$store.state.AppsPath
        },
        PluginsPath(){
          return this.$store.state.PluginsPath
        },
        PluginDataBase(){
          return this.$store.state.PluginDataBase
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        AllAppPlugins(){
        return this.AppsDataBase.map(app => {
            let appobj = Object.assign({},app)
            return appobj.Plugins
        }).flat()
        },
        AllAppPluginsIDs(){
          return this.AllAppPlugins.map(plug => {
            return plug.id
          })
        },
        SuitehasWarehouse(){
        return this.AllAppPlugins.find(obj => obj.id === 'Warehouse_&_Products')
        },     
        ComputedActivePlugins(){
        return this.ActivePlugins.filter(plug => {
            //stupid, since already only call "Active" on query...BUT...because compute the prop we can watch
            return plug.Active
        })
        },   
        ComputedPluginObj(){
        let plugindb = {}
        this.ActivePlugins
        .map(plug => {
            plugindb[plug.id] = plug
        })
        return plugindb
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        ActiveSuiteid() {
        return this.$store.state.ActiveSuiteid
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },    
        WebAppBuilderActive(){
          return this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active
        },   
        SuiteTemplateMain(){
          return this.$route.name === 'Suite Template Main'
        },
        SuiteTemplateWebsite(){
          return this.$route.name === 'Suite Template Website'
        },
        SuiteTemplateSocialNetwork(){
          return this.$route.name === 'Suite Template Social Network'
        },
        SuiteTemplateDatabase(){
          return this.$route.name === 'Suite Template Database App'
        },
        SuiteTemplatesRoute(){
          return this.SuiteTemplateView || this.$route.name === 'MySuiteTemplates'
        },
        SuiteTemplatePreview(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        SuiteTemplateView(){
          //console.log('this.$route.name',this.$route.name)
            return this.SuiteTemplateRouteNames.includes(this.$route.name) || this.$route.name.includes('SuiteTemplate') && this.$route.name !== 'MySuiteTemplates'
        },
        ActiveSuiteTemplate(){
                return this.$store.state.ActiveSuiteTemplate
        },
        SuiteTemplates(){
        return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
        },
        OtherSuiteTemplates(){
        return this.$store.state.OtherSuiteTemplates
        },
        MySuiteTemplates(){
        return this.$store.state.MySuiteTemplates
        },
        ClientSuiteTemplates(){
        return this.$store.state.ClientSuiteTemplates
        },
        MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.ActiveSuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
            return this.SuiteTemplateView ? [] : this.$store.state.OtherMarketplaceTemplates
            //just not allowed WHYWHYWHYWHYWHYW???????????????????
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        
        MatchedMarketTemplate(){
          return this.MarketPlaceTemplateView ? this.MarketplaceTemplates.find(obj => obj.id === this.$route.params.id) : ''
        },
        MarketPlaceTemplateView(){
          // I warn now...return this.$route.name.includes('MarketplaceTemplate') is NOT gonna work. then 'MarketplaceTemplates' will also believe it's viewing a template
          //MarketplaceTemplateRouteNames, what about SuiteTemplateRouteNames though? TBD
          //let routenames = ['MarketplaceTemplate','MyMarketplaceTemplates','MarketplaceTemplateSiteArticles','MarketplaceTemplateSiteArticleSingle']
          //console.log('this.MarketplaceTemplateRouteNames',this.MarketplaceTemplateRouteNames)
          return this.MarketplaceTemplateRouteNames.includes(this.$route.name)
        },
        WebsiteTemplate(){
          return this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Website'
        },
        SocialNetworkTemplate(){
          return this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Social Network'
        },
        FeaturedMemberTemplate(){
          return this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
          return this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Group Site'
        },
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
          },
        ActiveTemplateSiteType(){
          if(this.WebsiteTemplate){
            return 'Website Template'
          }
          else if(this.SocialNetworkTemplate){
            return 'Social Network Template'
          }
          else if(this.ActiveMarketTemplate){
            return 'Featured Member'
          }
          else if(this.GroupSiteTemplate){
            return 'Group Site'
          }
          else{
            return 'System'
          }
        },
        ActiveTemplateSite(){
          //console.log('this.PublicNavbarEditing',this.PublicNavbarEditing)
          //Forget previous attempts and misuse of the name, it was misued for Online store and perhaps traces in WH. It's irrelevant renamed to ActiveStore. 
          //ActiveTemplateSite NOW is whatever has a navigational view likely scoped to
          //std website, social network, group site, feat mem site, market template site
          //On second thought we don't need this....we don't need a state.ActiveTemplateSite...
          //below just a halfassed approach BUT..in time modulairy for public navbars will follow from the "WebsiteTemplate" etc etc
          if(this.ActiveTemplateSiteType === 'System'){
            return ''
          }
          else if(this.FeaturedMemberView){
            return this.ActiveFeaturedMember
          }
          else if(this.ActiveMarketTemplate && this.PublicNavbarEditing || this.ActiveMarketTemplate &&  this.ActiveMarketTemplate.Ownerid !== this.userLoggedIn.id){
            return this.ActiveMarketTemplate.TemplateProps
          }
          //and as is, with this semi approach, this ActiveTemplateSite has no use whatsoever. 
          //as we will currently simply navigate with manual props, but all this will be used once we ready
          //for now the closes workaround let system mean "nothing" otherwise return neccesary.
        },
        SiteGroupsActive(){
          return this.PluginDataBase.Site_Groups && this.PluginDataBase.Site_Groups.Active
        },
        FeaturedMembersActive(){
          return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        FeaturedMemberView(){
          return this.$route.name === 'FeaturedMemberViewer' || this.PublicNavbarEditing && this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'
        },
        
    MyCompany(){
      return this.$store.state.MyCompany
    },
    InviteGroups(){
      return this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties ? this.ActiveMemberGroup.AdditionalProperties.InviteGroups : []
    },
    ActiveMemberGroup(){
      return this.$store.state.ActiveMemberGroup
    },
    CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
      },
    ActiveMemberGroupAllowedChannels(){
      return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels ? this.ActiveMemberGroup.SocialChannels : ''
    },
    CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
    UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
    UserHasMemberGroup(){
      return this.userLoggedIn && this.userLoggedIn.MemberGroupid
    },
    UserMemberGroup(){
      return this.UserHasMemberGroup ? this.userLoggedIn.MemberGroup : ''
    },
    RouteObj(){
    return this.$route
  },
  ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SystemConfig').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
    },
    watch: {
      MyMemberCompanies: {
            handler: function(newvalue, oldvalue) {
              if(newvalue, oldvalue){
                newvalue.map(comp => {
                  if(!this.MemberCompanyVacancyCalls.includes(comp.id)){                  
                  this.MemberCompanyVacancyCalls.push(comp.id)
                  this.GetCompanyJobApplications(comp.id)
                  }
                })                  
                }
              } ,deep: true    
      },
      UserSites: {
            handler: function(newvalue, oldvalue) {
              if(newvalue, oldvalue){
                if(!this.SitesCalled){
                this.GetUserSites()                
                this.SitesCalled = true
                }
              }              
            },deep: true
      },
      MatchedMarketTemplate: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue && newvalue && newvalue.id){       
                  //because we assign ActiveMarketTemplate PRIOR to all props being called so let's try watch if the Array match is there, then reassign. let's see    
                  console.log('reassigning storeact temp')       
                  this.$store.commit('setActiveMarketTemplate',newvalue)
                  //still too many calls do we do PreArray call or not?
                  if(typeof newvalue !== 'undefined'){
                      if(newvalue.Ownerid === this.userLoggedIn.id){
                      this.$store.commit('setTemplateOwner',true)
                      }
                      else{
                      this.$store.commit('setTemplateOwner',false)
                      }
                      this.$store.commit('setTemplateView',true)
                      if(newvalue.TemplateProps && newvalue.TemplateProps.Name){
                      this.$store.commit('setTemplateSocialRouteName',newvalue.TemplateProps.Name)
                      }
                      this.$store.commit('setTemplateRootPath',db.collection('marketplacetemplates').doc(newvalue.id))
                  }
                  if(this.userLoggedIn){
                  //this.ActiveVisitor = this.userLoggedIn
                  this.$store.commit('setActiveVisitor',this.userLoggedIn)
                  }
                }
            },
            deep: true
      },
        RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  this.$store.commit('setPDFBuilderActive',false)
                  if(this.SuiteTemplateView){
                    //console.log('is suite temp view')
                    let temp = this.SuiteTemplates.find(obj => obj.id === this.$route.params.id)
                    if(temp && temp.id){
                      this.GetActiveSuiteTemplateProperties(temp)
                    }
                    
                    
                  }
                  else{
                    //console.log('is NOT suite temp view',this.SuiteTemplateRouteNames)
                    this.$store.commit('setActiveSuiteTemplate','')
                    if(!this.MarketPlaceTemplateView){
                      //ONLY IF NOT MarketPlaceTemplateView or Suite TempalteRoute
                        this.$store.commit('setTemplateOwner',false)
                        this.$store.commit('setTemplateView',false)
                        this.$store.commit('setTemplateSocialRouteName','')
                        this.$store.commit('setTemplateRootPath','')
                      //ONLY IF NOT MarketPlaceTemplateView or Suite TempalteRoute  
                      } 
                  }
                  //console.log('checking this.SuiteAppView',this.SuiteAppView)
                  if(!this.SuiteAppView){
                    this.$store.commit('setActiveSuiteApp','')
                  }
                  else{                    
                    //set app if possible??
                  }
                  if(this.MarketPlaceTemplateView){
                    let temp = this.MarketplaceTemplates.find(obj => obj.id === this.$route.params.id)
                    //console.log('temp',temp)
                  }
                  if(!this.MarketPlaceTemplateView){ 
                    //ACTUALLY - need to consider this may be Suite Template...    
                    //Suite TEmp need to receive all commits on ActiveMarketTemplate and such               
                    this.$store.commit('setActiveMarketTemplateChild','')
                    this.$store.commit('setActiveMarketTemplate','')
                    if(!this.SuiteTemplateView){
                      //ONLY IF NOT SuiteTemplateView or Suite TempalteRoute
                        this.$store.commit('setTemplateOwner',false)
                        this.$store.commit('setTemplateView',false)
                        this.$store.commit('setTemplateSocialRouteName','')
                        this.$store.commit('setTemplateRootPath','')
                      //ONLY IF NOT SuiteTemplateView or Suite TempalteRoute  
                      }                    
                    this.$store.commit('setActiveVisitor','')
                  }
                  if(this.$route.name !== 'FeaturedMemberEditor' && this.$route.name !== 'FeaturedMemberViewer'){                    
                    this.$store.commit('setActiveFeaturedMember','')
                  }
                  let IsGroupView = false
                  this.$store.commit('SetActiveChatRecord','')
                  if(this.$store.state.GroupRoutes.includes(this.$route.name)){
                    IsGroupView = true
                  }
                  else{
                    this.$store.commit('setActiveGroup','')
                    this.$store.commit('setUserMemberObj','')
                    this.$store.commit('setGroupForbidden',false)
                  }
                  this.$store.commit('setIsGroupView',IsGroupView)
                  let clearstoresearch = true 
                  if(oldvalue.name === 'StoreItemSingle'){
                    clearstoresearch  = false
                  }
                  if(clearstoresearch){
                    this.$store.commit('SetStoreSearch','')
                  }  
                  if(this.$route.name !== 'StoreItemSingle' && this.$route.meta && this.$route.meta.RouteEventID !==1027 && this.StoreView){
                    this.StoreView = false
                  }              
                  this.$store.commit('SetViewOrderNavbar',false)
                  this.$store.commit('SetCanReceive',false)
                  this.$store.commit('SetCanViewMovementHistory',false)
                  this.$store.commit('SetCurrentItemModerator','')
                  this.$store.commit('SetStoreItemPublisher','')                  
                  this.$store.commit('SetCurrentEntity','')
                  this.$store.commit('SetCurrentDataTabs',[]) 
                  this.$store.commit('SetSingleNavTabs',[])  
                  this.$store.commit('SetSelectedDataTabs',[])                
                  if(this.$route.meta && this.$route.meta.RouteEventID === 1027 && this.userIsAdmin){
                    this.$store.commit('SetCurrentItemModerator',true)
                  }
                }
            },
            deep: true
        },        
        SuiteTemplates: {
                handler: function(newvalue, oldvalue) {
                  if(newvalue !== oldvalue){
                    if(this.SuiteTemplateView){
                      if(!this.ActiveSuiteTemplate || this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.id !== this.$route.params.id){
                        let temp = this.SuiteTemplates.find(obj => obj.id === this.$route.params.id)
                         if(temp && temp.id){
                          this.GetActiveSuiteTemplateProperties(temp)
                        }
                      }                    
                      }
                  }
                },deep: true
        },
        ActiveSuiteid: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.StoreCalled = false
                this.$store.commit('setCartItems',[])
                this.PrepareSocialEntities()
              }
            },deep: true
        },
        userLoggedIn: {
            handler: function(newvalue, oldvalue) {
              let vm = this
        if (newvalue !== null && newvalue !== undefined && newvalue.id && !oldvalue || 
        newvalue !== null && newvalue !== undefined && newvalue.id && oldvalue && !oldvalue.id) {
          
          
          if(this.userLoggedIn && this.userLoggedIn.id && !this.PerformedPluginBasedRouteCheck){
            //unsure how we do this if vacancies were to be public, but for now yeah
                  this.CheckVacancies()
            let assetpayload = {
              query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userassets').where('Created_Byid','==',this.userLoggedIn.id),
              arrayname: 'UserAssets',
              Snapshot: 'MyUserAssetsCall'
            }
            this.$store.dispatch('GetResetArray',assetpayload).then(function(result) { 
            })
            this.PluginBasedRouteCheck(true)
            //if(this.userIsAdmin || this.userLoggedIn.AdvertCreator){              
              let payload = {
              query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('adverts').where('Ownerid','==',this.userLoggedIn.id),
              arrayname: 'MySiteAdverts',
              Snapshot: 'MySiteAdvertsCall'
            }
            this.$store.dispatch('GetResetArray',payload).then(function(result) { 
            })
            //}
          }
          //This is a BIG one on restructure...since SystemisRA this swopped. many things would have sweopped anyway but yeah
          if(this.SystemisRA){
          if(this.userLoggedIn.IsWebDeveloper || this.userLoggedIn.IsSuiteTemplateCreator){
            let pluginquery = db.collection('plugins')
              let pluginpayload = {                
                query: pluginquery,
                arrayname: 'RAPlugins',
                // Subcolpath: 'carouselitems',
                // SubcolName: 'CarouselItems'
                }
                this.$store.dispatch('GetCollectionArray',pluginpayload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
                //below are "cheap" checks but if user values change don't recall you get?
                if(this.userLoggedIn.IsWebDeveloper && this.$store.state.MyMarketplaceTemplates.length === 0){
                  this.GetMarketplaceTemplates()
                }
          }
          else if(this.WebAppBuilderActive && this.$store.state.PrimaryUserObj && !this.$store.state.PrimaryUserObj.IsSystemAdmin){
            //perhaps just check if these plugins exist 
            let pluginquery = db.collection('plugins')
              let pluginpayload = {                
                query: pluginquery,
                arrayname: 'RAPlugins',
                // Subcolpath: 'carouselitems',
                // SubcolName: 'CarouselItems'
                }
                this.$store.dispatch('GetCollectionArray',pluginpayload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
                if(this.FeaturedMembersActive && this.userLoggedIn.IsFeaturedMember){
                this.GetMarketplaceTemplates()
              }
          }
          else if(this.FeaturedMembersActive && this.userLoggedIn.IsFeaturedMember){
            this.GetMarketplaceTemplates()
          }
          else{
            //just for now
            //this.GetMarketplaceTemplates()
          }
          }        
          if(!this.SystemisRA && this.$store.state.RAPlugins.length === 0){
              let pluginquery = db.collection('plugins')
              let pluginpayload = {                
                query: pluginquery,
                arrayname: 'RAPlugins',
                provider: 'RapidApps',
                Subcolpath: 'carouselitems',
                SubcolName: 'CarouselItems'
                }
                this.$store.dispatch('GetArrayandSubcols',pluginpayload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
            }
          let newpath = this.$store.state.ReroutePath
           if(this.userLoggedIn && newpath){
             if(this.$route.path !== newpath){
               this.$store.commit('setReroutePath', '') 
               this.$router.push(newpath)
             }
            }          
          this.PrepareSocialEntities()
          if(this.SuiteTemplateView){
            let temp = this.SuiteTemplates.find(obj => obj.id === this.$route.params.id)
             if(temp && temp.id){
                this.GetActiveSuiteTemplateProperties(temp)
              }
          }
          if(!this.UserisGuest){
            if(this.userIsAdmin && this.$route.name !== 'GettingStarted'){
                //this.$router.push('/Getting-Started')
                if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                if(vm.userLoggedIn.Primary_Appid){
                  vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
                }
                }
              }
             else if(vm.userLoggedIn.Primary_Appid){
               if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                  vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
               }
              }
              //replacing GetApps
             let pluginpayload = {                
              query: this.AppsPath,
              arrayname: 'AppsDataBase',
              SubCollections: [
                {
                  Subcolpath: 'plugins',
                  SubcolName: 'Plugins'
                },
                {
                  Subcolpath: 'sharedplugins',
                  SubcolName: 'SharedPlugins'
                }
              ]              
              }
              this.$store.dispatch('GetArrayandMultipleSubcols',pluginpayload).then(function(result) {    
                console.log(vm.$store.state.AppsDataBase)
              }) 
            this.AddNonGuestRoutes()
            if(this.userLoggedIn.ModerationPermissions){
              let permissions = this.userLoggedIn.ModerationPermissions
              //console.log('this.userLoggedIn.ModerationPermissions',permissions)
              if(permissions.includes('Banner Ads')){
                this.AddBannerAdsModeratorRoutes()
              }
              if(permissions.includes('Landing Pages')){
                this.AddLandingPageModeratorRoutes()
              }              
            }
            
          }
          else{
            this.GetPluginRoutes('GuestRoutes')
            let pluginpayload = {                
              query: this.AppsPath,
              arrayname: 'AppsDataBase',
              SubCollections: [
                {
                  Subcolpath: 'plugins',
                  SubcolName: 'Plugins'
                },
                {
                  Subcolpath: 'sharedplugins',
                  SubcolName: 'SharedPlugins'
                }
              ]              
              }
              this.$store.dispatch('GetArrayandMultipleSubcols',pluginpayload).then(function(result) {    
                console.log(vm.$store.state.AppsDataBase)
              })
          }
          this.GetPluginRoutes('STDUserRoutes')
          
        }
      },deep: true
      },
      
      userIsEagleViewer (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value){
          this.GetPluginRoutes('EagleRoutes')
        }
      },
      SiteContentAdmin (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value){
          this.GetPluginRoutes('SiteAdminRoutes')   
        }
      },
      userIsAdmin (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value){
          this.GetPluginRoutes('GuestRoutes')
          this.GetPluginRoutes('EagleRoutes')
          this.GetPluginRoutes('AdminRoutes')
          this.GetPluginRoutes('SiteAdminRoutes')          
          this.GetPluginRoutes('STDUserRoutes')
          //console.log(this.$store.state.RAPlugins)
        }
      },
    ComputedActivePlugins: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){  
                this.PluginBasedRouteCheck() 
                
              }
            },deep: true
    },
    // ComputedApps: {
        //not used
    //         handler: function(newvalue, oldvalue) {
    //           if(newvalue !== oldvalue){
    //             console.log(newvalue, oldvalue)
    //             this.$store.commit('setAppsDataBase',newvalue) 
    //           }
    //         },deep: true
    // },
    ComputedPluginObj: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('setPluginDataBase',newvalue) 
                this.CheckTemplateRoutes()
                this.CheckAdvertList()
                //this.CheckVacancies()
                this.CheckStoreProducts()
                }
            },deep: true
    },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ReceiveActiveVisitor']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.CheckTemplateRoutes()
      this.CheckStoreProducts()
      //this.CheckVacancies()
      this.CheckAdvertList()
      this.CheckforMarketplaceTemplateandView()
      if(this.userLoggedIn && !this.ActiveVisitor){
        this.$store.commit('setActiveVisitor',this.userLoggedIn)
      }
      //nothing do this post sign in this.CheckTemplateRoutes()
    },
    methods:{
      AddRoute(route){
      let routes = this.$router.getRoutes()
      let oncheck = routes.find(obj => obj.name === route.name)
      if(!oncheck){
        this.$router.addRoute(route)
      }
    },
      GetUserSites(){
        //okay jsut to showcase actually exactly the same thing applies always
        if(this.userIsAdmin){
          this.GetSuiteTemplates()
          this.GetClientSuiteTemplate()
        }
        else if(this.userLoggedIn.IsSuiteTemplateCreator){
          this.GetSuiteTemplates()
          this.GetClientSuiteTemplate()
        }
        else{
          this.GetSuiteTemplates()
          this.GetClientSuiteTemplate()
        }
      },
      GetSites(query,Array){
        query.onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
          if (change.type === 'added') {
              let item = {
                  ...change.doc.data(),
                  id: change.doc.id
              }
              this[Array].push(item)
          }
          else if (change.type === 'modified') {
              let item = {
                  ...change.doc.data(),
                  id: change.doc.id
              }
              let oncheck = this[Array].find(obj => obj.id === item.id)
              if(oncheck){
                let arrayobjindex = this[Array].indexOf(oncheck)
                this[Array].splice(arrayobjindex, 1, item);
              }
              
          } 

          })
        })
      },
      GetActiveSuiteTemplateProperties(temp){
         //let temp = JSON.parse(JSON.stringify(tempobj))
        if(!temp.templates){
          temp.templates = []
        }
        let tempref = db
        let colchecks = [
          {Array: 'SocialNetworks',Single: 'SocialNetwork',SubCollections: ['Pages']},
          {Array: 'Websites',Single: 'Website',SubCollections: ['Pages']},
          {Array: 'Libraries',Single: 'Library',SubCollections: []},
          {Array: 'Databases',Single: 'Database',SubCollections: []}
          ]
        colchecks.map((col,colindex) => {
          // if(!temp[col.Single]){
          //   temp[col.Single] = {}
          // }
          let colref = tempref.collection(col.Array).doc(temp.id)
          colref.onSnapshot(snapshot => {
             let itemdata = snapshot.data()
             if(itemdata){
              temp[col.Single] = itemdata
             }
             if(colindex-1+2 === colchecks.length){
             this.$store.commit('setActiveSuiteTemplate',temp)
             if(typeof temp !== 'undefined'){
                      if(temp.Ownerid === this.userLoggedIn.id){
                      this.$store.commit('setTemplateOwner',true)
                      }
                      else{
                      this.$store.commit('setTemplateOwner',false)
                      }
                      this.$store.commit('setTemplateView',true)
                      if(temp.SocialNetwork && temp.SocialNetwork.Name){
                      this.$store.commit('setTemplateSocialRouteName',temp.SocialNetwork.Name)
                      }
                      this.$store.commit('setTemplateRootPath',db.collection('marketplacetemplates').doc(temp.id))
                  }
             }
             //this.$store.commit('setActiveSuiteTemplate',temp)
          })
        })
      },
      
      ReceiveActiveVisitor(visitor){
        let ActiveVisitor = visitor
        if(!ActiveVisitor && this.userLoggedIn){
          ActiveVisitor = this.userLoggedIn
        }
        this.$store.commit('setActiveVisitor',ActiveVisitor)

      },
      
      CheckforMarketplaceTemplateandView(){
        if(this.MarketPlaceTemplateView){
          //console.log('for sure a marketplace view')
          let temp = this.MarketplaceTemplates.find(obj => obj.id === this.$route.params.id)
          if(!temp && this.userLoggedIn && this.userLoggedIn.id){
            //console.log('oh no there is no template')
            this.GetMarketplaceTemplate()
          }
          else{
            //console.log('yes tehre is a template')
          }
          //console.log('temp',temp)
        }
        else{
          //console.log('this not picking up as a marketplace view')
        }
      },
      GetMarketplaceTemplate(){
        let vm = this
        db.collection('marketplacetemplates').doc(this.$route.params.id).get().then(snapshot => {
          let data = snapshot.data()
          data.id = this.$route.params.id
          data.Assets = []
          data.CategoryFields = []
          data.Directories = []
          //the real tricky bit....we gotta push this sucker to the array now, After calling subcols. but this comp all over so it's all good right?
          if(data.Ownerid === this.userLoggedIn.id){
            //push to MyMarketplaceTemplates            
            let payload = {
              itemobj: data,
              arrayname: 'MyMarketplaceTemplates',
            }
            this.$store.dispatch('SetItemPreArrayCall',payload).then(function(result) {    
              //console.log(result)
              let temp = vm.MarketplaceTemplates.find(obj => obj.id === vm.$route.params.id)
              //console.log('temp',temp)
              vm.$store.commit('setActiveMarketTemplate',temp)
              if(typeof temp !== 'undefined'){
                      if(temp.Ownerid === vm.userLoggedIn.id){
                      vm.$store.commit('setTemplateOwner',true)
                      }
                      else{
                      vm.$store.commit('setTemplateOwner',false)
                      }
                      vm.$store.commit('setTemplateView',true)
                      if(temp.TemplateProps && temp.TemplateProps.Name){
                      vm.$store.commit('setTemplateSocialRouteName',temp.TemplateProps.Name)
                      }
                      vm.$store.commit('setTemplateRootPath',db.collection('marketplacetemplates').doc(temp.id))
                  }
            })
          }
          else{
            //push to OtherMarketplaceTemplates
             let payload = {
              itemobj: data,
              arrayname: 'OtherMarketplaceTemplates',
            }
            this.$store.dispatch('SetItemPreArrayCall',payload).then(function(result) {    
              //console.log(result)
             let temp = vm.MarketplaceTemplates.find(obj => obj.id === vm.$route.params.id)
              //console.log('temp',temp)
              vm.$store.commit('setActiveMarketTemplate',temp)
              if(typeof temp !== 'undefined'){
                      if(temp.Ownerid === vm.userLoggedIn.id){
                      vm.$store.commit('setTemplateOwner',true)
                      }
                      else{
                      vm.$store.commit('setTemplateOwner',false)
                      }
                      vm.$store.commit('setTemplateView',true)
                      if(temp.TemplateProps && temp.TemplateProps.Name){
                      vm.$store.commit('setTemplateSocialRouteName',temp.TemplateProps.Name)
                      }
                      vm.$store.commit('setTemplateRootPath',db.collection('marketplacetemplates').doc(temp.id))
                  }
            })
          }
        },
          error => {
            if(error.code === 'permission-denied'){
             //NOW WE KNOW HE DONT HAVE ACCESS        
            }
          })
      },
      CheckVacancies(){
        if(this.PluginDataBase && this.PluginDataBase.Vacancies && this.PluginDataBase.Vacancies.Active && !this.MyVacanciesCalled){
           this.GetVacancyDirectory()
          this.GetVacancies()
        }
      },
      CheckAdvertList(){
        if(this.PluginDataBase && this.PluginDataBase.Advert_Directory && this.PluginDataBase.Advert_Directory.Active && !this.MyAdvertsCalled){
          this.GetAdvertDirectory()
          this.GetAdverts()
        }
      },
      GetVacancies(){
        //console.log('GetVacancies',this.userLoggedIn)
        //but two very different angles here...
        //1. see the vacancy and apll applications
        //2. manage applications. 
        if(!this.userLoggedIn){
        let payload1 = {                
              //ideally .where('Members','array-contains',this.userLoggedIn.id) but cannot do "does ont contain" reverse so...yeah
                query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('vacancies').where('PublishStatus','==','Published'),
                arrayname: 'PublicVacancies',
                }
            this.$store.dispatch('GetCollectionArray',payload1).then(function(result) {    
              //console.log(vm.$store.state.RAPlugins)
            })
        }
        else if(this.userLoggedIn){
          this.GetJobApplications()
          
        let payload2 = {                
              //ideally .where('Members','array-contains',this.userLoggedIn.id) but cannot do "does ont contain" reverse so...yeah
                query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('vacancies').where('Ownerid','==',this.userLoggedIn.id),
                arrayname: 'MyVacancies',
                }
            this.$store.dispatch('GetCollectionArray',payload2).then(function(result) {    
              //console.log(vm.$store.state.RAPlugins)
            })
            if(!this.UserisGuest){
              let payload3 = {                
              query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('vacancies').where('Ownerid','!=',this.userLoggedIn.id),
              arrayname: 'VacanciesOther',
              }
              this.$store.dispatch('GetCollectionArray',payload3).then(function(result) {    
                //console.log(vm.$store.state.RAPlugins)
              })
            }
        }
      },
      GetCompanyJobApplications(compid){
        //while backend is for management of job applications for client
        //it is done through entity
        //this here is ONLY applicable if you have a company with a vacancy listed, that's it.
        //ONLY issue here is - what is vacancies for this particular comp already called?
        if(!this.UserisGuest || this.UserisGuest && this.userLoggedIn.VacancyOfficerClient){
        let compjobapplications = {  
                query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('membercompanies').doc(compid).collection('jobapplications'),
                arrayname: 'MyCompanyJobApplications',
                }
            this.$store.dispatch('GetCollectionArray',compjobapplications)
        }
        //haha...okay another question now...yes companies trigger this, fine. But is it not perhaps vacancies that trigger this?
        //apply for job does this 1) creae db entity "jobapplications" record. oncreate or on update functions replicates where neccesary
        //question is where is the most neccesary version for jobapplications. under the vacancy? or under the company?
        //remember now it's gonna mean record company as well, but yes should be easy. got it?
      },
      GetJobApplications(){
        console.log('GetJobApplications')
        //Users call their applications from user subcol
        //all users can apply for jobs so ling they signed in so
          let myjobapplications = {                
            //ideally .where('Members','array-contains',this.userLoggedIn.id) but cannot do "does ont contain" reverse so...yeah
              query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).collection('jobapplications'),
              arrayname: 'MyJobApplications',
              }
          this.$store.dispatch('GetCollectionArray',myjobapplications)
      },
      GetAdverts(){
        let payload = {
              query: db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('Directories').doc('Adverts').collection('Items'),
              arrayname: 'SiteAdverts',
              Snapshot: 'SiteAdvertsCall'
            }
            this.$store.dispatch('GetResetArray',payload).then(function(result) { 
            })
      //okay so if a user is a logged in user we call from db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('adverts')
      },
      GetVacancyDirectory(){
        if(typeof this.VacancyDirectoryCall === 'function'){
          this.VacancyDirectoryCall();
        }
        this.VacancyDirectoryCall = db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('Directories').doc('Vacancies').onSnapshot(snapshot => {
          let data = snapshot.data()
          if(data){
            data.id = 'Adverts'
            this.$store.commit('setAdvertDirectory',data)
            this.MyVacanciesCalled = true
          }
        })
      },
      GetAdvertDirectory(){
        if(typeof this.AdvertDirectoryCall === 'function'){
          this.AdvertDirectoryCall();
        }
        this.AdvertDirectoryCall = db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('Directories').doc('Adverts').onSnapshot(snapshot => {
          let data = snapshot.data()
          if(data){
            data.id = 'Adverts'
            this.$store.commit('setAdvertDirectory',data)
            this.MyAdvertsCalled = true
          }
        })
      },
      CheckStoreProducts(){
        if(this.PluginDataBase && this.PluginDataBase.Easy_Products && this.PluginDataBase.Easy_Products.Active && !this.StoreCalled){
            this.GetStore()
            let prodpayload = {
              query: db.collection('Stores').doc(this.$store.state.ActiveSuiteid).collection('storeproducts'),
              arrayname: 'StoreProducts',
              Snapshot: 'StoreProductsCall'
            }
            this.$store.dispatch('GetResetArray',prodpayload).then(function(result) { 
            })
            let shipgrouppayload = {
              query: db.collection('Stores').doc(this.$store.state.ActiveSuiteid).collection('shippinggroups'),
              arrayname: 'ShippingGroups',
              Snapshot: 'ShippingGroupsCall'
            }
              this.$store.dispatch('GetResetArray',shipgrouppayload).then(function(result) { 
            })
            let prodcatpayload = {
              query: db.collection('Stores').doc(this.$store.state.ActiveSuiteid).collection('productcategories'),
              arrayname: 'ProductCategories',
              Snapshot: 'ProductCategoriesCall'
            }
              this.$store.dispatch('GetResetArray',prodcatpayload).then(function(result) { 
            })
            console.log(this.$store.state)
            this.StoreCalled = true
          }
      },
      GetStore(){
        if(typeof this.ActiveStoreCall === 'function'){
          this.ActiveStoreCall();
        }
        this.ActiveStoreCall = db.collection('Stores').doc(this.$store.state.ActiveSuiteid).onSnapshot(snapshot => {
          let data = snapshot.data()
          if(data){
            data.id = this.$store.state.ActiveSuiteid
            this.$store.commit('setActiveStore',data)
          }
        })
      },
      CheckTemplateRoutes(){
        //JUST REMEMEBR ANYTHING THT HAS A SLUG IS IGNORED, WE KEEP AN IGNORED LIST HERE FOR DEV REF
        //HOWEVER...uhm. Screw it. I was trying to use GroupPage, for like "snb page" but it's pointless
        let ignoredlist = [
          //From NonGuestRoutes
          "SitePostSingle - '/SitePosts/:id/:slug' VERSION SAME ROUTE NAME...",
          //From PublicSocialRoutes
          'GroupFeaturedMemberView','FeaturedGroupMemberEdit','FeaturedMemberViewer','GroupArticle','GroupMeetup',
          'GroupBlog','GroupForum','GroupPage','GroupPoll',
          //From STDUserRoutes
          'GroupPageEditor',"'GroupPageBuilder' SPECIFICALLY BECAUSE NOTHING ELSE GROUP PAGE WORKS ANYWAY",
          'TeamMemberActivities',
        ]     
        //HOWEVER MANUAL ROUTING WOULD HAVE TO HAPPEN FOR A GROUP TEMPLATE, THAT'S GONNA BE EXTREMELY HARD TO ACHIEVE...
        //MIND YOU THAT'S A PRIORITY...//HOWEVER NOTE TeamMemberActivities passes ":prop" so..that an answer?
        if(this.PluginDataBase && this.PluginDataBase.Marketplace_Templates && this.PluginDataBase.Marketplace_Templates.Active){          

          let prop = 'MarketplaceTemplate'
          // let idroutes = [
          // From NonGuestRoutes 'SiteArticles','SiteBlogs','SiteMembers','Glossary','noticesingle','noticenew','SocialDashboard',
          // From PublicSocialRoutes 'SiteArticleNew','SiteBlogNew','Events','SiteMeetups','SitePolls','SiteForums',
          // From STDUserRoutes 'Groups','GroupWebFormBuilder'
          // ]
           //idroutes - has no id, adds id to path form paramsid of template     
           let idroutes = [
            //From NonGuestRoutes
            'SiteArticles','SiteBlogs','SiteMembers','Glossary','noticesingle','noticenew','SocialDashboard',
          //From PublicSocialRoutes
          'SiteArticleNew','SiteBlogNew','Events','SiteMeetups','SitePolls','SiteForums','Classifieds',
          //From STDUserRoutes
          'Groups','GroupWebFormBuilder'
          ]     
          let slugroutes = [
          //From NonGuestRoutes
            'SiteMemberSingle','SitePollSingle',
          //From PublicSocialRoutes
          'BusinessMember','DigitalBusinessCards','SiteArticleSingle','SiteBlogSingle','ClassifiedSingle','ClassifiedEnquiry',
          'SiteEventSocialSingle','SiteForumSingle','GroupDashboard','SiteMeetupSingle',
          //From STDUserRoutes
          'SitePollNew','SiteMeetupNew','GroupSingle','GroupEdit','GroupNewArticle','GroupNewMeetup','GroupNewBlog','GroupNewPoll',
          'GroupWebFormEditor','CustomDashboard','DirectoryBuilder']
          //slugroutes - has id, has no slug, we add slug
          //console.log(prop,idroutes,slugroutes)
          this.CheckandAddTemplates(prop,idroutes,slugroutes)
          let appsingleroute = this.NonGuestRoutes.find(obj => obj.name === 'SuiteApp')
          let templateapprouteobj = Object.assign({},appsingleroute)
          templateapprouteobj.name = 'MarketplaceTemplateSuiteApp'
          templateapprouteobj.path = '/MarketplaceTemplate/App/:id'
          let existingroutes = this.$router.getRoutes()
          let oncheck = existingroutes.find(obj => obj.name === templateapprouteobj.name)
          if(!oncheck){
            this.MarketplaceTemplateRouteNames.push(templateapprouteobj.name)
            this.AddRoute(templateapprouteobj)
          }
          //console.log(templateapprouteobj,oncheck)
        }
      },
      CheckandAddTemplates(prop,idroutes,slugroutes){
        //console.log(prop,idroutes,slugroutes)
        //routenames because SuiteTemplates and MArketplaceTemplates not same, Suite has more
        //not gonna work like this, rmeember slug and id? well typically "Article" has "id"...
        // so the route "path" deteremines params and MUST change...how??? 
        //path: '/Articles' changes to path: '/MarketplaceTemplate/:id/Articles'
        //'path: '/Article/:id', should change to  path: '/MarketplaceTemplate/:id/Article/:slug'
        //And this makes it VERY CLEAR why we simply cannot do all group related stuff. Simly ANYTHING with a slug...cannot be replicated for Templates. Impossible.
        //Also then any social comp for groups, absolutey impossible. CANNOT HAVE GROUP PAGE!!!! Also cannot have "group article single" etc.
        let allroutes = this.PublicSocialRoutes.concat(this.SignedInSocialRoutes,this.GuestRoutes,this.NonGuestRoutes,this.STDUserRoutes,this.EagleRoutes,this.AdminRoutes,this.SiteAdminRoutes)
        let allroutescheck = allroutes.map(route => {
          return route.name
        })
        //console.log(allroutescheck)
        let idrouteitems = allroutes.filter(item => {
          return idroutes.includes(item.name)
        }).map(route => {
          let routeobj = Object.assign({},route)
          delete routeobj.meta //YES PLEASE!!         
          routeobj.name = prop+route.name
          routeobj.path = '/'+prop+'/:id'+route.path
          //e.g. path: '/Articles' changes to path: '/MarketplaceTemplate/:id/Articles'
          return routeobj
        })
        let idrouteitemscheck = idrouteitems.map(item => {
          return item.path
        })
        let slugrouteitems = allroutes.filter(item => {
          return slugroutes.includes(item.name)
        }).map(route => {
          let routeobj = Object.assign({},route)
          delete routeobj.meta //YES PLEASE!!         
          routeobj.name = prop+route.name
          routeobj.path = '/'+prop+'/:id'+route.path.split('/:id').join('/:slug')
          //e.g. 'path: '/Article/:id', should change to  path: '/MarketplaceTemplate/:id/Article/:slug'
          //now also like 'CustomDashboard' we put slug and no id..stupid yeah, but above caters for ...
          //'/CustomDashboard/:slug' would become '/MarketplaceTemplate/:id/CustomDashboard/:slug'
          //only remaining issue is component code to receive id as opposed to slug, you get?
          return routeobj
        })
        let slugrouteitemscheck = slugrouteitems.map(item => {
          return item.path
        })
        let existingroutes = this.$router.getRoutes()
        idrouteitems.map(route => {
          //no check except "is it on?"
          let oncheck = existingroutes.find(obj => obj.name === route.name)
          if(!oncheck){
            this[prop+'RouteNames'].push(route.name)
            this.AddRoute(route)
          }
        })
        slugrouteitems.map(route => {
          //no check except "is it on?"
          let oncheck = existingroutes.find(obj => obj.name === route.name)
          if(!oncheck){
            this[prop+'RouteNames'].push(route.name)
            this.AddRoute(route)
          }
        })
        //console.log(idrouteitemscheck,slugrouteitemscheck)
        //prop is 'MarketplaceTemplate' or 'SuiteTemplate' making 'SiteArticleSingle' then [prop]+'SiteArticleSingle'
        this.CheckforMarketplaceTemplateandView()
      },
      AddLandingPageModeratorRoutes(){
        let routes = [
          {
            path: '/LandingPages',
            name: 'landingpages',
            component: LandingPages,
            meta: {
              IsTopic: true,
              TopicCategory: 'Administration',
              TopicName: 'Landing Pages'
            }
          },
          {
            path: '/LandingPage/Edit/:id',
            name: 'newlandingpage',
            component: NewLandingPage,
            props:true,
            meta: {
              
            }
          },] 
          routes.map(route => {
          this.AddRoute(route)
        })
      },
      AddBannerAdsModeratorRoutes(){
        let routes = [{
            path: '/BannerAds',
            name: 'bannerads',
            component: BannerAds,
            meta: {
              IsTopic: true,
              TopicCategory: 'Administration',
              TopicName: 'Banner Ads'
            }
          },
          {
            path: '/BannerAd/Edit/:id',
            name: 'newbannerad',
            component: NewBannerAd,
            props:true
          },] 
          routes.map(route => {
          this.AddRoute(route)
        })
      },
      AddNonGuestRoutes(){
        //console.log(this.NonGuestRoutes)
        this.NonGuestRoutes.map(route => {
          this.AddRoute(route)
        })
      },
      AddSTDUserRoutes(){
        this.STDUserRoutes.map(route => {
          this.AddRoute(route)
        })
      },
      
      
        GetClientSuiteTemplate(){
      let othertemplpayload = {                
            query: db.collection('SystemConfig').where('Clientid','==',this.userLoggedIn.id),
            arrayname: 'ClientSuiteTemplates',
            subcolquery: db.collection('systems'),
            SubCollections: [
              {
                Subcolpath: 'apps',
                SubcolName: 'Apps'
              },
               {
                Subcolpath: 'plugins',
                SubcolName: 'Plugins'
              },
            ]
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',othertemplpayload).then(function(result) {    
            }) 
    },
    GetSuiteTemplates(){
      let vm = this
      //console.log('this.GetMarketplaceTemplates')
      let mytemppayload = {                
            query: db.collection('SystemConfig').where('Ownerid','==',this.userLoggedIn.id),
            arrayname: 'MySuiteTemplates',
            subcolquery: db.collection('systems'),
            SubCollections: [
              {
                Subcolpath: 'apps',
                SubcolName: 'Apps'
              },
               {
                Subcolpath: 'plugins',
                SubcolName: 'Plugins'
              },
            ]
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',mytemppayload).then(function(result) {    
             
            })
            //Illegal for non admin right?
            if(this.userIsAdmin){
             let othertemplpayload = {                
            query: db.collection('SystemConfig').where('Ownerid','!=',this.userLoggedIn.id),
            arrayname: 'OtherSuiteTemplates',
            subcolquery: db.collection('systems'),
            SubCollections: [
              {
                Subcolpath: 'apps',
                SubcolName: 'Apps'
              },
               {
                Subcolpath: 'plugins',
                SubcolName: 'Plugins'
              },
            ]
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',othertemplpayload).then(function(result) {    
            }) 
            }
    },
    GetMarketplaceTemplates(){
      let vm = this
      if(!this.$store.state.MarketplaceTemplatesCalled){
      this.$store.commit('setMarketplaceTemplatesCalled',true)
      //console.log('this.GetMarketplaceTemplates')
      let mytemppayload = {                
            query: db.collection('marketplacetemplates').where('Ownerid','==',this.userLoggedIn.id),
            subcolquery: db.collection('marketplacetemplates'),
            arrayname: 'MyMarketplaceTemplates',
            SubCollections: [
              {
                Subcolpath: 'Directories',
                SubcolName: 'Directories'
              },
               {
                Subcolpath: 'sampleusers',
                SubcolName: 'Users'
              },
              {
                Subcolpath: 'CategoryFields',
                SubcolName: 'CategoryFields'
              },
              {
                Subcolpath: 'Assets',
                SubcolName: 'Assets'
              },
              {
                Subcolpath: 'Pages',
                SubcolName: 'Pages'
              },
              {
                Subcolpath: 'Modules',
                SubcolName: 'Modules'
              },
              {
                Subcolpath: 'plugins',
                SubcolName: 'Plugins'
              },
            ]              
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',mytemppayload).then(function(result) {    
             
            })
            let otherquery = this.userIsAdmin ? db.collection('marketplacetemplates').where('Ownerid','!=',this.userLoggedIn.id) : 
            db.collection('marketplacetemplates').where('PublishStatus','==','Published').where('Ownerid','!=',this.userLoggedIn.id)
            //query: db.collection('marketplacetemplates').where('PublishStatus','==','Published').where('Ownerid','!=',this.userLoggedIn.id),
             let othertemplpayload = {                
            query: otherquery,
            subcolquery: db.collection('marketplacetemplates'),
            arrayname: 'OtherMarketplaceTemplates',
            SubCollections: [
              {
                Subcolpath: 'Directories',
                SubcolName: 'Directories'
              },
               {
                Subcolpath: 'sampleusers',
                SubcolName: 'Users'
              },
              {
                Subcolpath: 'CategoryFields',
                SubcolName: 'CategoryFields'
              },
              {
                Subcolpath: 'Assets',
                SubcolName: 'Assets'
              },
              {
                Subcolpath: 'Pages',
                SubcolName: 'Pages'
              },
              {
                Subcolpath: 'Modules',
                SubcolName: 'Modules'
              },
              {
                Subcolpath: 'plugins',
                SubcolName: 'Plugins'
              },
            ]              
            }
            this.$store.dispatch('GetArrayandMultipleSubcols',othertemplpayload).then(function(result) {    
            }) 
      }
    },
    PrepareSocialEntities(){
      //console.log('PrepareSocialEntities',this.SiteGroupsActive)
      //if(this.PluginDataBase.Site_Groups && this.PluginDataBase.Site_Groups.Active){
        if(this.SiteGroupsActive || this.FeaturedMembersActive){
         let entities = this.$store.state.SocialEntities
                if(this.SiteGroupsActive){
                let groupRecordPrimaryField = {Name: 'name',DisplayName: 'Name',Type: 'Single Line Text',Primary: true,IsHeader: true,HeaderIndex: 0}
                let groupentity = {id: 'Site_Groups',DisplayName: 'Site Groups',SingleName: 'Group',Entity_Type: {ID: 2000000,Name: 'Group Table'},Table_Icon: 'mdi-account-group',
                Security_Structure: {ID: 1000003,Name: 'Social'},datasetsecuritystructure: 'Social',SingleBuildTabs: [],RecordPrimaryField: groupRecordPrimaryField,AllFields: [
                    groupRecordPrimaryField,
                    {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text'},
                    {Name: 'Logo',DisplayName: 'Logo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                    {Name: 'Telephone',DisplayName: 'Telephone',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                ],Primary_Field_Name: 'name',FeatureList: [],Siteid: this.$store.state.ActiveSuiteid}
                let groupcat = ''
                if(this.System.Group_Categories){
                  groupcat = Object.assign({},this.System.Group_Categories)
                  groupcat.IsDynamic = false
                  groupcat.IsHeader = true
                  groupcat.HeaderIndex = 2
                }
                if(groupcat){
                  groupentity.AllFields.push(groupcat)
                  this.PrepareGroupsEntity(entities,groupentity)
                }
                else{
                  this.PrepareGroupsEntity(entities,groupentity)
                }  
                }
                 if(this.FeaturedMembersActive){
                  this.InitiateFeatMemEntity()
                }
                                
        }
    },
    InitiateFeatMemEntity(){
      //console.log('InitiateFeatMemEntity')
      let entities = this.$store.state.SocialEntities
      let fmentRecordPrimaryField = {Name: 'Full_Name',DisplayName: 'Full Name',Type: 'Single Line Text',Primary: true,IsHeader: true,HeaderIndex: 0}
      let fment = {id: 'Featured_Members',DisplayName: 'Featured Members',SingleName: 'Featured Member',Entity_Type: {ID: 2000001,Name: 'Featured Memeber Table'},Table_Icon: 'mdi-account-star',
      Security_Structure: {ID: 1000003,Name: 'Social'},datasetsecuritystructure: 'Social',SingleBuildTabs: [],RecordPrimaryField: fmentRecordPrimaryField,AllFields: [
          fmentRecordPrimaryField,
          {Name: 'Overview',DisplayName: 'Overview',Type: 'Multiple Lines Text',IsContentEditable: true},
          {Name: 'Profile_Photo',DisplayName: 'Profile Photo',Type: 'Single File Upload',IsFeaturedPhoto: true},
          {Name: 'Mobile_Number',DisplayName: 'Mobile Number',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
            {Name: 'Email',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email'},
      ],Primary_Field_Name: 'name',FeatureList: [],Siteid: this.$store.state.ActiveSuiteid}
        this.PrepareFeatMemEntity(entities,fment)
    },
    PrepareFeatMemEntity(entities,fment){
      //console.log('PrepareFeatMemEntity',entities)
      let oncheck = entities.find(obj => obj.id === fment.id && obj.Siteid === this.$store.state.ActiveSuiteid)
      if(!oncheck){
                 entities.push(fment)
                this.$store.commit('setSocialEntities',entities)
                let payload = {
                    collectionname: 'featuredmembers',
                    collectionstate: 'SiteFeaturedMembersQuery',
                    query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').where('Published','==',true)
                  }
                  this.$store.dispatch('SetUserQueryPermission',payload)
                let arraypayload = {
                  arrayname : 'SiteFeaturedMembersArray', 
                  array: []
                }
                this.$store.commit('setCollectionArray',arraypayload) 
                }
    },
    PrepareGroupsEntity(entities,groupentity){
      let oncheck = entities.find(obj => obj.id === groupentity.id && obj.Siteid === this.$store.state.ActiveSuiteid)
      if(!oncheck){
        //console.log('!voncheck')
                 entities.push(groupentity)
                this.$store.commit('setSocialEntities',entities)
                let loggedingroups = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members')
                 let nonguestgroups = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Non Guest Site Members')
                if(this.userLoggedIn && this.userLoggedIn.IsGuestUser){
                  if(this.CompanyOnlyFilter){
                    loggedingroups = loggedingroups.where('Companyid','==',this.UserCompanyid)
                  }
                    let payload = {
                    collectionname: 'groups',
                    collectionstate: 'SiteGroupsQuery',
                    query: [db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'),
                    loggedingroups]
                  }
                this.$store.dispatch('SetUserQueryPermission',payload)
                }
                else{
                  if(this.CompanyOnlyFilter){
                    loggedingroups = loggedingroups.where('Companyid','==',this.UserCompanyid)
                    nonguestgroups = nonguestgroups.where('Companyid','==',this.UserCompanyid)
                  }
                  let payload = {
                    collectionname: 'groups',
                    collectionstate: 'SiteGroupsQuery',
                    query: [db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'),
                    loggedingroups,nonguestgroups]
                  }
                  this.$store.dispatch('SetUserQueryPermission',payload)
                }
                let arraypayload = {
                  arrayname : 'SiteGroupsArray', 
                  array: []
                }
                this.$store.commit('setCollectionArray',arraypayload) 
                }
    },
        GetPluginRoutes(ArrayName){
      //console.log(this.AllAppPluginsIDs)
      let routes = this.$router.getRoutes()
      this[ArrayName].map(route => {
            let oncheck = routes.find(obj => obj.name === route.name)
            if(!oncheck){
              if(route.ANDPlugins){
                let pass = true
                route.ANDPlugins.map(plug => {
                  if(!this.ComputedPluginObj[plug] && !this.AllAppPluginsIDs.includes(plug)|| this.ComputedPluginObj[plug] && !this.ComputedPluginObj[plug].Active && !this.AllAppPluginsIDs.includes(plug)){
                    pass = false
                  }
                })
                if(route.Channel && this.ActiveMemberGroupAllowedChannels && !this.ActiveMemberGroupAllowedChannels.includes(route.Channel)){
                  pass = false
                }
                if(pass){
                  this.AddRoute(route)
                }
              }
              else if(route.ORPlugins){
                //right now the routes try propogate BEFORE this runs...console.log(this.AllAppPluginsIDs)
                let length = route.ORPlugins.length
                for (let i = 0; i < length; i++) {
                  let plug = route.ORPlugins[i]
                  if(ArrayName === 'EagleRoutes'){
                    //console.log(length,i,route,route.name,plug,this.ComputedPluginObj,this.ComputedPluginObj[plug], this.AllAppPluginsIDs,this.AllAppPluginsIDs.includes(plug))
                  }
                  
                  if (this.ComputedPluginObj[plug] && this.ComputedPluginObj[plug].Active || this.AllAppPluginsIDs.includes(plug)) {
                    //console.log(route.name,plug,this.ComputedPluginObj[plug])
                    if(route.Channel && this.ActiveMemberGroupAllowedChannels && !this.ActiveMemberGroupAllowedChannels.includes(route.Channel)){
                    this.AddRoute(route)
                    break;
                    }
                    else if(route.Channel && !this.ActiveMemberGroupAllowedChannels){
                    this.AddRoute(route)
                    break;
                    }
                     else if(!route.Channel){
                      this.AddRoute(route)
                    break;
                    }                    
                    }
                    else if(route.name === 'DocumentationLibraryEditor'){
                      //ArrayName === 'EagleRoutes'
                      //console.log(route.name,plug,this.ComputedPluginObj[plug],this.RAAdmin,this.RAUserObj)
                      if(this.RAAdmin){
                    this.AddRoute(route)
                    break;
                      }
                    }
                    else if(route.name === 'PDFExportFunctionBuilder' && this.userIsAdmin){
                      this.AddRoute(route)
                    break;
                    }
                }
              }
              else if(route.Basic){
                if(route.Channel && this.ActiveMemberGroupAllowedChannels && !this.ActiveMemberGroupAllowedChannels.includes(route.Channel)){
                 this.AddRoute(route)
                }
                else if(route.Channel && !this.ActiveMemberGroupAllowedChannels){
                this.AddRoute(route)
                }
                else if(!route.Channel){
                this.AddRoute(route)
                }
              }
            }
          })
    },
        PluginBasedRouteCheck(postsignin){
      if(postsignin){
        this.PerformedPluginBasedRouteCheck = true
      }
      //console.log('this.ComputedActivePlugins',this.ComputedActivePlugins)
      this.GetPluginRoutes('PublicSocialRoutes')  
      let signedinuser = this.userLoggedIn
      let guestuser = this.UserisGuest
      let nongueststduser = this.userLoggedIn && !this.userIsEagleViewer && !this.userIsAdmin
      let nonadmineagle = this.userIsEagleViewer && !this.userIsAdmin
      let adminuser = this.userIsAdmin
                if(signedinuser){
                  //console.log('PluginBasedRouteCheck signedinuser')
                  this.GetPluginRoutes('SignedInSocialRoutes')  
                  //alert('yo ey!')
                  //for now turned back on cause ey, it's crazy, but long term this really gotta get some thinking applied
                  //I know for a fact admin is getting double calls due to this, does not seem to affecta nybody else. but this is WRONG
                  this.GetMarketplaceTemplates()
                  //this.CheckTemplateRoutes()
                }          
                if(guestuser){
                  //console.log('PluginBasedRouteCheck guestuser')
                  this.GetPluginRoutes('GuestRoutes')
                }
                else if(nongueststduser){
                  //console.log('PluginBasedRouteCheck nongueststduser')
                  this.GetPluginRoutes('STDUserRoutes')
                  this.AddNonGuestRoutes()
                }
                else if(nonadmineagle){
                  //console.log('PluginBasedRouteCheck nonadmineagle')
                  this.GetPluginRoutes('STDUserRoutes')
                  this.GetPluginRoutes('EagleRoutes')
                  this.AddNonGuestRoutes()
                }
                else if(adminuser){
                  //AddAdminRoutes
                  //console.log('PluginBasedRouteCheck adminuser')
                  this.GetPluginRoutes('GuestRoutes')
                  this.GetPluginRoutes('EagleRoutes')
                  this.GetPluginRoutes('AdminRoutes')
                  this.GetPluginRoutes('STDUserRoutes')
                  this.AddNonGuestRoutes()                  
                }
                else{
                  
                  //console.log('PluginBasedRouteCheck actually nothing, seems dude not signed in?')
                }
                //console.log('PluginBasedRouteCheck this is postsignin? ',postsignin)
                // setTimeout(() => {
                //   this.RouterReady = true  
                // }, 4000);
    },
    }
}
</script>

<style>

</style>



