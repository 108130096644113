<template>
  <div>
    <MemberCompanyDialog v-if="NewCompanyDialog" @SelectCompany="SelectCompany"
            :System="System" :SystemEntities="SystemEntities"
            @DeactivateNewCompanyDialog="DeactivateNewCompanyDialog"
                />
    <v-dialog v-model="ProductOptionDialog" max-width="600"  
    transition="dialog-bottom-transition">
    <v-card rounded>
        <v-card-title >
            Add product option
        </v-card-title>
        <v-card-subtitle style="padding-top: 10px;">
            You will be able to edit this later as well
        </v-card-subtitle>
        <v-card-title class="justify-end">
            <v-spacer>
            </v-spacer>
           <v-btn-toggle v-model="OptionTypeFilter">
            <v-btn small >
                <v-icon small>mdi-view-list</v-icon>
            </v-btn>
            <v-btn small >
                <v-icon small>mdi-format-color-fill</v-icon>
            </v-btn>
            </v-btn-toggle>
        </v-card-title>
        <v-card-subtitle v-if="ProductCategory.AdditionalOptions">
            <v-chip-group col>
                <v-chip @click="AddCategoryOptiontoProduct(opt)" v-for="opt in UnusedCategoryOptions" :key="opt.itemObjKey">
                    {{opt.DisplayName}}
                </v-chip>
            </v-chip-group>
        </v-card-subtitle>
        <v-card-text v-if="OptionTypeFilter === 0">
            <v-text-field v-if="NewOptionSetIndex === -1" outlined dense placeholder="Option Name"
            label="Type in option name"
            v-model="NewOptionSet.Name" :rules="[$store.state.formrules.required]"/>
              <v-text-field v-if="NewOptionSetIndex > -1" outlined dense placeholder="Option Name"
            label="Type in option name"
            v-model="NewOptionSet.DisplayName" :rules="[$store.state.formrules.required]"/>
            <v-combobox item-text="Name" multiple outlined dense chips placeholder="Press Enter to include"
            label="Type in options"
            v-model="NewOptionSet.Options" :rules="[$store.state.formrules.required]"/>
        </v-card-text>
        <v-card-actions>
            <v-btn outlined color="warning" @click="CancelProductOptionDialog()">Cancel</v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn dark class="green" @click="SaveNewAdditionalOptionSet()">Save</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
        <v-list>
            <v-btn v-if="userLoggedIn" outlined color="success" @click="CheckSaveStoreProduct()"
            >Save</v-btn>
            <v-row class="justify-center">
                <v-col cols="12" xl="6" lg="6" md="12">
                    <v-card-title>
                General
            </v-card-title>
            <v-card-text>   
            <v-list-item>
           <v-btn v-if="!ActiveProduct.Company" @click="ActivateNewCompanyDialog()" width="100%"><v-icon>mdi-plus</v-icon> Add Company</v-btn>
           <v-btn v-if="ActiveProduct.Company" @click="ActivateNewCompanyDialog()" width="100%">{{ActiveProduct.Company.Company_Name}}</v-btn>
          </v-list-item> 
                 <v-text-field outlined dense placeholder="Title"
                label="Type in Advert Title"
                v-model="ActiveProduct.Title" :rules="[$store.state.formrules.required]"/>
                 <v-list-item class="justify-center">
                <ContentEditableField style="padding-bottom: 30px;"
                :FieldObject="ActiveProduct" :FieldName="'Description'"
                :FieldValue="ActiveProduct.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-list-item>
            <!-- remember to calculate the cost and stuff, but yeah ok maybe stupid but yeah -->
           
            <v-combobox chips item-text="Name" label="KeyWords" multiple
               outlined  v-model="ActiveProduct.KeyWords" placeholder="Select KeyWords"
                :items="KeyWordOptions"/>
                <!-- 1) TEL NR 2) LINK -->
                 <v-text-field outlined dense placeholder="Contact Number"
                label="Type in Contact Number" prepend-icon="mdi-phone"
                v-model="ActiveProduct.TelephoneNumber" :rules="[$store.state.formrules.required,$store.state.formrules.telnr]"/>
            </v-card-text>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="12">
                    <v-card-title>
                Grouping
            </v-card-title>
            <v-expansion-panels :disabled="CatDisabled">
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        Category
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" 
                        :Record="ActiveProduct" :field="CategoryField" @onPhotoFileselected="onPhotoFileselected"
                        :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" 
                        :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                        @UploadFileSelect="UploadFileSelect"
                        />
                        <!-- <v-combobox chips item-text="DisplayName"
                        v-model="ActiveProduct.Category"
                        :items="AllProductCategories"/> -->
                        <!-- <v-card>
                            Category Image
                        </v-card> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
<!--       
        <v-list-item style="padding-top:10px;">
         <v-textarea outlined dense placeholder="Return Refund Policy"
                label="Type in this Product's Return Refund Policy"
                v-model="ActiveProduct.Return_Refund_Policy" disabled/>
        </v-list-item> -->
                </v-col>
            </v-row>
            
            
        </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent'
import MemberCompanyDialog from '@/components/Database/Fields/Hybrid/MemberCompanyDialog'
export default {
    props: ['System','SystemEntities','ActiveProduct','EditingProduct','ProcessQuickSave',
    'AdvertsCollectionRef'],
    components: {ContentEditableField,FieldValueEditerComponent,MemberCompanyDialog},
    data() {
        return {
          NewCompanyDialog: false,
            OptionTypeFilter: 0,
            ProductOptionDialog: false,
            NewOptionSetIndex: -1,
            NewOptionSet: {
                Name: ''
            },
            DefaultNewOptionSet: {
                Name: ''
            },
          editedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',            
          },
          defaulteditedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',
          },
          menu: false,
        }
    },	
    computed:{
        CatDisabled(){
            return this.ActiveProduct && this.ActiveProduct.Link || 
            this.ActiveProduct && this.ActiveProduct.SystemTicketid || 
            this.ActiveProduct && this.ActiveProduct.SystemTicketid
        },
        KeyWordOptions(){
            return this.$store.state.SiteKeyWordOptions.filter(kw => {
                return kw.Type && kw.Type.Name === 'Site' || kw.Type && kw.Type.Name === 'Advert'
            })
        },
        UnusedCategoryOptions(){
            return this.ProductCategory && this.ProductCategory.AdditionalOptions.filter(opt => {
                return !this.ProductAdditionalOptions.find(obj => obj.Name === opt.Name) 
            })
        },
        ProductAdditionalOptions(){
            return this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
        },
      CategoryField(){
        return this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
        this.AdvertDirectory.CategoryField : ''
      },
      AdvertDirectory(){
            return this.$store.state.AdvertDirectory
        },
      AllProductCategories(){
        return this.ProductsCatsLevel3.concat(this.ProductsCatsLevel2,this.ProductCategories)
      },
      ProductsCatsLevel3(){
            return this.ProductsCatsLevel2.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductsCatsLevel2(){
            return this.ProductCategories.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
        ProductCategory(){
            return this.ActiveProduct && this.ActiveProduct.Category && this.ProductCategories.find(obj => obj.id === this.ActiveProduct.Category.id) ? 
            this.ProductCategories.find(obj => obj.id === this.ActiveProduct.Category.id) : ''
        },
        ProductRef(){
            return this.AdvertsCollectionRef.doc(this.ActiveProduct.id)
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ComputedStoreProducts(){
          return this.StoreProducts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
    },
    watch: {
        ProcessQuickSave(v){
            if(v){
                this.SaveStoreProduct()
            }
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignProductIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        
    },
    methods:{
        SelectCompany(comp){
            let compbj = {
                Company_Name: comp.Company_Name,
                id: comp.id
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('adverts').doc(this.ActiveProduct.id).update({
                Company: comp,
                Companyid: comp.id
            }).then(upddoc => {
                this.DeactivateNewCompanyDialog()
            })
        },
      ActivateNewCompanyDialog(){
        this.NewCompanyDialog = true
      },
      DeactivateNewCompanyDialog(){
        this.NewCompanyDialog = false
      },
        AddCategoryOptiontoProduct(opt){
            let options = this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
            let newinteger = 1000000+options.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: opt.Name,
                DisplayName: opt.Name,
                Type: 'Option Set',
                Options: opt.Options,
                lastassignedinteger: 1000000+opt.Options.length,
            }
            options.push(newopt)
            this.ProductRef.update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
                this.CancelProductOptionDialog()
            })
        },
        SaveNewAdditionalOptionSet(){
            let options = this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
            if(this.NewOptionSetIndex === -1){
            let newinteger = 1000000+options.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: this.NewOptionSet.Name,
                DisplayName: this.NewOptionSet.Name,
                Type: 'Option Set',
                Options: this.NewOptionSet.Options.map((opt,opti) => {
                    return {ID: 1000001+opti,Name: opt}
                }),
                lastassignedinteger: 1000000+this.NewOptionSet.Options.length,
            }
            options.push(newopt)
            this.ProductRef.update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
                this.CancelProductOptionDialog()
            })
            }
            else{
                console.log('yeah update only')
            }
           
        },
        CancelProductOptionDialog(){
            this.ProductOptionDialog = false
            this.NewOptionSet = Object.assign({},this.DefaultNewOptionSet)
            this.NewOptionSetIndex = -1
        },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      AssignProductIMG(){
        this.ActiveProduct.IMG = this.SelectedImage.fileurl
            this.AdvertsCollectionRef.doc(this.ActiveProduct.id).update({
                IMG: this.ActiveProduct.IMG
            })
        this.$store.commit('setIMGProp','')
      },
      CancelStoreProduct(openafter){
        this.menu = false
        this.editedProduct = Object.assign({},this.defaulteditedProduct)
        if(openafter){
          setTimeout(() => {
            this.menu = true
          }, 500);
        }
      },
      CheckSaveStoreProduct(){
        if(this.ActiveProduct.Category){
            if(typeof this.ActiveProduct.Category.ID === 'undefined'){
                this.AddCategory(this.ActiveProduct.Category).then(newcat => {
                    this.ActiveProduct.Category = newcat
                    this.SaveStoreProduct()
                })
            }
            else{
               this.SaveStoreProduct() 
            }
        }
        else{
            this.SaveStoreProduct()
        }
      },
      AddCategory(cat){
        
        let vm = this
        return new Promise(function(resolve, reject) {	
            let field = vm.CategoryField ? vm.CategoryField : {}
            let options = field.Options ? field.Options : []
            let newinteger = 1000000+options.length
            newinteger++
            let newcat = {
                ID: newinteger,
                Name: cat,
                DisplayName: cat,
                AdditionalOptions: []
            }
            options.push(newcat)
            field.lastassignedinteger = newinteger
            db.collection('Stores').doc(vm.AdvertDirectory.id).update({
                CategoryField: field
            }).then(newcatdoc => {
                resolve(newcat)
            })
        })
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      SaveStoreProduct(){
        let updobj = {}
        let props = ['Category','Title','Description','Price','KeyWords','TelephoneNumber','Link',
        'Product_Group','Product_Class','Product_Type','LinkType']
        //console.log(props)
        props.map(prp => {
            if(typeof this.ActiveProduct[prp] !== 'undefined'){
                updobj[prp] = this.ActiveProduct[prp]
            }
        })
        //console.log(updobj)
        let unregisteredkeywords = updobj.KeyWords.filter(kw => {
                return !kw.id
            })
        if(unregisteredkeywords.length > 0){
            unregisteredkeywords.map((kw,kwindex) => {
                    let path = db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords')
                    let ref = path.doc()
                    let newdocid = ref.id
                    let user = this.userLoggedIn
                    let ownerobj = {          
                            Created_By: {Full_Name: user.Full_Name, id: user.id},
                            Created_Byid: user.id,
                            Owner: {Full_Name: user.Full_Name, id: user.id},
                            Ownerid: user.id,
                            Created_On: new Date(),
                            Modified_By: {id: user.id, Name: user.Name, Surname: user.Surname, Full_Name: user.Full_Name},
                            Modified_Byid: user.id,
                            Modified_On: new Date(),
                    }
                    let kwobj = {
                        Name: kw,
                        Type: {ID: 1000004, Name: 'Advert'},
                        Original_Recordid: this.ActiveProduct.id,
                        ...ownerobj,
                        id: newdocid
                    }
                    console.log(kwobj)
                    db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords').doc(newdocid).set(kwobj).then(newkwdoc => {
                    let sitekwobj = updobj.KeyWords.find(obj => obj === kwobj.Name)
                    // console.log(updobj,sitekwobj)
                    // sitekwobj = kwobj
                    let index = updobj.KeyWords.indexOf(sitekwobj)
                    updobj.KeyWords.splice(index,1,kwobj)
                    if(kwindex-1+2 === unregisteredkeywords.length){
                        this.UpdateSearchandSite(updobj,this.ProductRef)
                    }
                })
            })
        }
        else{
            this.UpdateSearchandSite(updobj,this.ProductRef)
        }
        // this.ProductRef.update(updobj).then(newprod => {
        // //   this.CancelStoreProduct(true)
        //     this.ActivateSnackbar(true,'Successfully updated the product!') 
        // })
      },
      GetSiteKeyWordsSearchQuery(KeyWords,query){
        let vm = this
        return new Promise(function(resolve, reject) {
            KeyWords.map((kw,i) => {
                let title = kw.Name
                //console.log('kw',kw,title)
                    vm.$store.dispatch('CreateSearchTitle',kw.Name).then(searchtitle => {
                    query = query.concat(searchtitle)
                    if(i-1+2 === KeyWords.length){
                        resolve(query)
                    }
                    })
                })
        })
        },
      UpdateSearchandSite(site,siteref){
        //console.log('UpdateSearchandSite')
            let query = []
        this.$store.dispatch('CreateSearchTitle',site.Title).then(searchtitle => {
            query = searchtitle
            if(site.KeyWords && site.KeyWords.length > 0){
            this.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                query = query.concat(kwsearch)
                site.SearchQuery = query
                siteref.update(site).then(upddoc => {
                    //this.CancelUpdateSiteInfo()
                })
            })
            }
            else{
                site.SearchQuery = query
                siteref.update(site).then(upddoc => {
                    //this.CancelUpdateSiteInfo()
                })
            }
            
        })
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    }
}
</script>

<style>

</style>



