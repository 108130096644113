<template>
  <div>
    <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;" @click="ActivateOrder(record)" 
        v-for="record in Orders" :key="record.itemObjKey">
            
            <v-list-item-avatar tile>
            <DateRender :Value="record.Created_On"
              :Display="'Card'" :Color="'grey'"
              /> 
            </v-list-item-avatar> 
            <v-list-item-content>
            <v-list-item-title>
            {{record.Name}} {{record.Surname}}
            </v-list-item-title>
            <v-list-item-subtitle>
                <div v-html="record.Email"/>
            </v-list-item-subtitle>
            </v-list-item-content>  
            <v-list-item-actions>
                {{CurrencyFormatter(record.Total,$store.state.DefaultCurrency.Currency)}}
            </v-list-item-actions>                 
        </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DateRender from '@/components/Database/Fields/ReadOnly/DateRender';	

export default {
    props: ['System','SystemEntities','Orders'],
    components: {DateRender},
    data() {
        return {

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ActivateOrder(record){
            this.$emit('ActivateOrder',record)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    }
}
</script>

<style>

</style>



