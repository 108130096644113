<template>
<div>
    <v-dialog width="330" v-model="NewClock">
        <v-card width="330" v-if="NewClock"
        dark :style="'padding:10px;background-color: '+PrimColor+';border-radius: 30px;'" >
            <v-card-subtitle class="overline">Enter Time:
            </v-card-subtitle>
            <v-list-item dense class="justify-center">
                <v-list-item-content style="padding: 5px;" 
                >
                <v-list-item-title 
                :style="'background-color: '+ActiveSecColor('Hours')+';line-height: inherit;flex: none!important;font-size: 3em;padding:5px;border-radius: 15px;'">
                  <div v-html="HRPicker"/>
                </v-list-item-title>
                <v-list-item-subtitle class="caption" style="text-align: start;font-weight: 300;">
                    Hours
                </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content style="flex: none!important;font-size: 3em;">
                    :
                </v-list-item-content>
                <v-list-item-content style="padding: 5px;" 
                >
                <v-list-item-title 
                :style="'background-color: '+ActiveSecColor('Minutes')+';line-height: inherit;flex: none!important;font-size: 3em;padding:5px;border-radius: 15px;'">
                <div v-html="MINPicker"/>
                </v-list-item-title>
                <v-list-item-subtitle class="caption" style="text-align: start;font-weight: 300;">
                    Minutes
                </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                style="flex: none!important;font-size: 3em;padding:15px;border-radius: 5px;">
                    <v-list-item-title>
                        <v-btn :color="TimeConvention === 'AM' ? 'red' : 'purple'"
                            @click="SetTimeConvention('AM')">AM
                         </v-btn>
                    </v-list-item-title>
                    <v-list-item-title>
                         <v-btn :color="TimeConvention === 'PM' ? 'red' : 'purple'"
                            @click="SetTimeConvention('PM')">PM
                         </v-btn>
                    </v-list-item-title>
                </v-list-item-action>
            </v-list-item>
            <v-card-actions class="justify-end">
                <v-spacer>
                </v-spacer>
                 <v-btn text @click="ToggleNewClock()">Cancel
                </v-btn>
                <v-btn text @click="SetTimeValues()">OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
<v-list-item>
    <v-list-item-content style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action v-if="!Editing" style="font-weight: 300;">
        {{FieldDisplayValue}}
    </v-list-item-action>
    <v-list-item-action v-if="Editing">
       <v-menu 
                    v-model="DateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field 
                        :label="field.DisplayName"
                        prepend-icon="mdi-calendar-month"
                        readonly  :rules="field.IsMandatory? [$store.state.formrules.required] : []"
                        v-on="on"
                        v-model="DateString"
                        ></v-text-field>
                        </template>
                    <v-date-picker  v-model="DateString" :readonly="field.ReadOnly"
                            @input="UpdateNewRecordQuickEdit(field),DateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-text-field @click="ToggleNewClock()"
                            v-model="TimeResponse"
                            :label="field.Name+' Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            :id="field.Name+'Time'"
                            clearable v-if="field.HasTimeInput"
                            ></v-text-field>
                  
    </v-list-item-action>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing','TableQuick'],
    components: {},
    data() {
        return {
            TimeSelector: '',
            TimeConvention: 'AM',   
            NewClock: false,         
          DateMenu: false,
          TimeMenu: false,
          DateString: '',
          TimeResponse: '',
        }
    },	
    computed:{
        PopColor(){
            return 'rgb(247,54,62)'
        },
        SecColor(){
            return 'rgb(113,19,92)'
        },
        PrimColor(){
            return 'rgb(78,13,58)'
        },
        HRPicker(){
            return `
            <select id="timepickerhours" class="timepicker__select"
            style="--seccol: `+this.SecColor+`;--popcol: `+this.PopColor+`;"
            >
			${this.HoursDropdown.join("")}
		    </select>`
        },
        MINPicker(){
            return `
            <select id="timepickerminutes" class="timepicker__select"
            style="--seccol: `+this.SecColor+`;--popcol: `+this.PopColor+`;"
            >
			${this.MinutesDropDown.join("")}
		    </select>`
        },
        AvailableHours(){
            return [1,2,3,4,5,6,7,8,9,10,11,12]
        },
        AvailableMinutes(){
            return [0,5,10,15,20,25,30,35,40,45,50,55]
        },
        HoursDropdown(){
            return this.AvailableHours.map(hr => {
                return this.numberToOption(hr)
            })
        },
        MinutesDropDown(){
            return this.AvailableMinutes.map(min => {
                return this.numberToOption(min)
            })
        },
        DateObject(){
        let dateobject = ''
        if(!this.field.HasTimeInput){
            dateobject = new Date(this.DateString)
        }
        else if(this.field.HasTimeInput){                       
            dateobject = new Date(this.DateString+' '+this.TimeResponse)
        }
        return dateobject
        },
    FieldDisplayValue(){
        return this.FieldValue.toDate ? this.DateFieldValue(this.FieldValue.toDate(),this.field) :
        this.DateFieldValue(this.FieldValue,this.field)
    },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        Record: {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    this.AssignDateValue()
                }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.AssignDateValue()
    },
    methods:{
        ActiveSecColor(sel){
            if(this.TimeSelector === sel){
                return this.PopColor
            }
            else{
                return this.SecColor
            }
        },
        SetTimeConvention(conv){
            this.TimeConvention = conv
        },
        SetNewClock(){
            setTimeout(() => {                                  
                let hrelemnt = document.getElementById('timepickerhours')
                let minelmnt = document.getElementById('timepickerminutes')
                hrelemnt.addEventListener("click", () => {
                    hrelemnt.classList.toggle("active")
                    minelmnt.classList.remove("active")
                    if(this.TimeSelector === 'Hours'){
                      this.TimeSelector = ''  
                    }
                    else{                        
                      this.TimeSelector = 'Hours'  
                    }
                    
                })
                minelmnt.addEventListener("click", () => {
                    hrelemnt.classList.remove("active")
                    minelmnt.classList.toggle("active")
                    if(this.TimeSelector === 'Minutes'){
                      this.TimeSelector = ''  
                    }
                    else{                        
                      this.TimeSelector = 'Minutes'  
                    }
                })
                // hrelemnt.addEventListener("onclick", this.ToggleTimeSelectorHours)
                // minelmnt.addEventListener("onclick", this.ToggleTimeSelectorMinutes)
                //console.log('yes added listeners')
                let hrstring = this.TimeResponse.split(':')[0]
                let minstring = this.TimeResponse.split(':')[1]
                let hrnr = Number(hrstring)
                let minnr = Number(minstring)
                if(hrnr>=12){
                    if(hrnr > 12){
                        this.TimeConvention = 'PM'
                    }
                    hrnr=hrnr-12
                }
                else{
                    this.TimeConvention = 'AM'
                }
                hrstring = hrnr.toString()
                let hropt = this.AvailableHours.find(obj => obj.toString() === hrstring)
                let hrind = this.AvailableHours.indexOf(hropt)
                if(hrnr<10){
                    hropt = '0'+hropt
                }
                //console.log(hropt,hrstring,this.AvailableHours,hrind,hrelemnt,hrelemnt.options[hrind].text)
                hrelemnt.value = hrelemnt.options[hrind].text
                //hrelemnt.selectedIndex = hrind
                //console.log(minstring)
                let minstringmatch = minstring
                // if(minnr<10){
                //     minstringmatch = minstring.split('0')[1]
                // }
                let minopt = this.AvailableMinutes.find(obj => obj >= 10 && obj.toString() === minstring || 
                obj < 10 && '0'+obj.toString() === minstring)
                
                //console.log(minopt,minstring,this.AvailableMinutes,minelmnt.options[0].text)
                let minind = this.AvailableMinutes.indexOf(minopt)
                minelmnt.value = minelmnt.options[minind].text
                //minelmnt.selectedIndex = minind
                }, 50);
        },
        ToggleNewClock(){
            if(this.NewClock){                                               
                let hrelemnt = document.getElementById('timepickerhours')
                let minelmnt = document.getElementById('timepickerminutes')
                // hrelemnt.removeEventListener('click', this.ToggleTimeSelectorHours)
                // minelmnt.removeEventListener('click', this.ToggleTimeSelectorMinutes)
                this.TimeSelector = ''
                this.NewClock = !this.NewClock
            }
            else{
                this.NewClock = !this.NewClock
                this.SetNewClock()  
            }
        },
        SetTimeValues(){
            let hrelemnt = document.getElementById('timepickerhours')
            let minelmnt = document.getElementById('timepickerminutes')
            let hrval = hrelemnt.options[hrelemnt.selectedIndex].text
            let minval = minelmnt.options[minelmnt.selectedIndex].text
            let timeconvval = this.TimeConvention
            //+timeconvval
            let hrnr = Number(hrval)
            if(timeconvval === 'PM'){
               hrnr = hrnr+12
               //console.log(hrnr)
               hrval = hrnr.toString()
            }
            if(hrnr<10){
                hrval = hrval.split('0')[1]
            }
            let timeresponse = hrval+':'+minval
            this.TimeResponse = timeresponse
            //console.log(timeresponse,this.TimeResponse,timeconvval)
            this.UpdateNewRecordQuickEdit(this.field)
            this.ToggleNewClock()
        },
        numberToOption(number) {
            const padded = number.toString().padStart(2, "0");
            return `<option style="font-size: 0.4em;" value="${padded}">${padded}</option>`;
        },
        UpdateNewRecordQuickEdit(field){            
            if(this.TableQuick){
                let payload = {
                    DateString: this.DateString,
                    DateObject: this.DateObject
                } 
                if(this.field.HasTimeInput){
                    payload.TimeResponse = this.TimeResponse
                }
                this.$emit('UpdateNewRecordQuickEdit',payload,field)
            }
        },
        DateFieldValue(javadate,field,direct,asobject){
            let date = ''
            if(typeof javadate === 'string'){
                date = new Date(javadate)
            }
            else{
                date = javadate
            }
            if(!direct){
             if(field.HasTimeInput){
                return date
                }
                else{
                    return date.toDateString()
                }   
            }
            else{
                if(field.HasTimeInput){
                    return date+this.TimeResponse
                }
                else{
                    return date.toDateString()
                }
            }
            
        },
        AssignDateValue(){
            //console.log(this.Record)
            if(this.FieldValue){
                let dateobject = this.FieldValue.toDate ? this.FieldValue.toDate() : this.FieldValue
                if(this.Record[this.field.id+'Object'] && this.TableQuick){
                    dateobject = this.Record[this.field.id+'Object']
                }
                this.DateString = typeof dateobject === 'string' ? dateobject : format(dateobject,'yyyy-MM-dd')
                if(this.field.HasTimeInput){
                    let hours = dateobject.getHours()
                    let mins = dateobject.getMinutes()
                    if(hours === 0){
                    hours = '00'
                    }
                    if(mins === 0){
                    mins = '00'
                    }
                    this.TimeResponse = hours+':'+mins
                    //this.Record[field.Name+'TimeResponse'] = field.TimeResponse
                }
            }
        },
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
    }
}
</script>

<style>
.timepicker__select{
    border:0px;
    outline:0px;
    color: white;
    background-color: var(--seccol);
}
.timepicker__select.active{
    background-color: var(--popcol);
}
</style>



