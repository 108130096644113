<template>
  <div>
    <v-dialog persistent v-model="MemberDetailsDialog"max-width="450" min-width="300">
      <v-card v-if="ApplyingMember" tile>
        <v-card-title class="mediumoverline">
          {{ApplyingMember.User.Full_Name}}'s Application
          <v-spacer>
          </v-spacer>
          <v-btn fab icon @click="CancelViewMemberDetails()">
            <v-icon>mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs>
            <v-tab>Overview
            </v-tab>
            <v-tab-item>
              <div v-html="ApplyingMember.Overview"/>
            </v-tab-item>
            <v-tab>Objectives
            </v-tab>
            <v-tab-item>
              <div v-html="ApplyingMember.Objectives"/>
            </v-tab-item>
            <v-tab>Higlights
            </v-tab>
            <v-tab-item>
              <TimelineTab v-if="ApplyingMember.Highlights" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
            :tab="ComputedHighlightsElmnt" @OpenDialog="OpenDialog" :i="i"
            />
            <div v-if="!ApplyingMember.Highlights">
              This Member has not captured any Career Highlights
            </div>
            </v-tab-item>
            
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card elevation="0" class="siteconfigcard">
        <v-card-title v-if="!ActiveLibTypeComp" class="mediumoverline">
          My Vacancies
          <v-spacer></v-spacer>
           <v-btn v-if="!ActiveVacancy" outlined
          @click="ActivateNewVacancyDialog({})" >Add New</v-btn>
        </v-card-title>
        <v-card-text v-if="!ActiveVacancy">
         <v-list v-for="vac in ComputedVacancies" :key="vac.itemObjKey">
              <!--   -->
              <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;"
               @click="ActivateVacancy(vac)">
                  <v-list-item-avatar class="white--text" :color="vac.Color" v-if="!vac.Company || vac.Company && !vac.Company.Logo"
                  :size="MiniView ? 25 : 50" tile >
                    {{vac.AvatarTitle}}
                  </v-list-item-avatar>
                  <v-list-item-avatar class="white--text" v-if="vac.Company && vac.Company.Logo" :size="MiniView ? 25 : 50" tile >
                    <img style="object-fit: cover;"
                    :src="vac.Company.Logo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                    {{vac.Title}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{vac.Company.Company_Name}}
                    </v-list-item-subtitle>
                     <v-list-item-subtitle>
                      {{vac.Location_City}}
                    </v-list-item-subtitle>
                  </v-list-item-content>    
                  <v-list-item-action >
                    <v-avatar tile  style="border-radius: 10px!important;" :color="vac.PublishStatus === 'Draft' ? 'red' : 'green'">
                      <v-icon dark>
                        {{vac.PublishStatus === 'Draft' ? 'mdi-pencil' : 'mdi-check'}}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>                
              </v-list-item>
              <v-list-item class="justify-end">
               <v-chip><v-icon>mdi-clock-outline</v-icon>{{vac.PublishAgeing}}
                    </v-chip>
                    <v-chip v-if="vac.Renumeration"><v-icon>mdi-account-cash</v-icon>{{vac.Renumeration}}
                    </v-chip>
                    <v-chip><v-icon>mdi-briefcase</v-icon>{{vac.Employment_Type.Name}}
                    </v-chip>
              </v-list-item>
          </v-list>
      </v-card-text>
      <v-card-text v-if="ActiveVacancy">
        <v-tabs centered v-if="!UserisGuest && ActiveVacancy.id || ActiveVacancy.Ownerid === this.userLoggedIn.id && ActiveVacancy.id">
          <v-tab>Advert
          </v-tab>
          <v-tab-item>
          <VacancySingle :Directories="Directories" :Editing="Editing" @ConfirmPublish="ConfirmPublish"
          :ActiveVacancy="ActiveVacancy" @GoBack="GoBack" @Edit="Edit" @SaveVacancy="SaveVacancy"
          :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
          @DeactivateActivesession="DeactivateActivesession"  :SubscriptionPackages="SubscriptionPackages"
          />
          </v-tab-item>
          <v-tab>
            Applications
          </v-tab>
          <v-tab-item>
            <v-card-text>
              <v-list  v-for="jobapp in ActiveVacancyApplications" :key="jobapp.itemObjKey">
              <v-list-item v-if="!MiniView">
                <v-list-item-avatar tile>
                <DateRender :Value="jobapp.Created_On.toDate()"
                      :Display="'Card'" :Color="'green'"
                      />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn text style="text-transform: none;"  @click="ViewMemberDetails(jobapp)">
                    {{jobapp.User.Full_Name}}
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                  <div v-html="smart_substr(jobapp.Motivation,150)">
                  </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content style="text-align: center;" v-for="prp in FMProps" :key="prp.itemObjKey">
                <v-list-item-title>
                    {{prp.Prop}}
                  </v-list-item-title>
                  <v-list-item-subtitle style="padding-bottom:10px;">
                  <v-menu              
                        top
                        offset-y>
                        <template v-slot:activator="{ on }">
                    <v-btn fab v-on="on" dark color="warning white--text">
                        <v-icon>
                        {{prp.icon}}
                        </v-icon>
                    </v-btn>
                        </template>
                        <v-card tile max-width="450" min-width="300">
                          <v-card-title class="mediumoverline justify-space-between">
                           {{prp.Prop}}
                            <v-icon right>
                        {{prp.icon}}
                        </v-icon>
                          </v-card-title>
                          <v-card-text v-if="jobapp[prp.Prop]">
                              <v-tooltip bottom v-for="qual in jobapp[prp.Prop]" :key="qual.itemObjKey">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip :style="qual.GradientStyle" color="background" v-bind="attrs" v-on="on">
                                  {{qual.Title ? qual.Title : qual.Name}}
                                </v-chip>                  
                              </template>
                                <span v-if="qual.Field">
                                  {{qual.Field.Name}}
                                </span>
                                <span v-else>
                                  {{qual.Value}} (Self Rated)
                                </span>
                            </v-tooltip>
                          </v-card-text>
                          <v-card-text v-if="!jobapp[prp.Prop]">
                            This Member has not captured any {{prp.Prop}}
                          </v-card-text>
                        </v-card>
                    </v-menu>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action >
                  <v-btn :color="jobapp.Progress && jobapp.Progress.Color ? jobapp.Progress.Color : ''" outlined>
                    {{jobapp.Progress ? jobapp.Progress.Name : 'Received'}}</v-btn>
                  <!-- <v-btn :to="'/FeaturedMember/'+jobapp.Userid+'/Home'" outlined>View CV</v-btn> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="MiniView">
                <v-list-item-avatar tile>
                <DateRender :Value="jobapp.Created_On.toDate()"
                      :Display="'Card'" :Color="'green'"
                      />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{jobapp.User.Full_Name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                  <div v-html="smart_substr(jobapp.Motivation,150)">
                  </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="MiniView">
                <v-list-item-content style="text-align: center;" v-for="prp in FMProps" :key="prp.itemObjKey">
                  <v-list-item-subtitle style="padding-bottom:10px;">
                  <v-menu              
                        top
                        offset-y>
                        <template v-slot:activator="{ on }">
                    <v-btn small fab v-on="on" dark color="warning white--text">
                        <v-icon>
                        {{prp.icon}}
                        </v-icon>
                    </v-btn>
                        </template>
                        <v-card tile max-width="450" min-width="300">
                          <v-card-title class="mediumoverline justify-space-between">
                            {{prp.Prop}}
                            <v-icon right>
                        {{prp.icon}}
                        </v-icon>
                          </v-card-title>
                          <v-card-text v-if="jobapp[prp.Prop]">
                              <v-tooltip bottom v-for="qual in jobapp[prp.Prop]" :key="qual.itemObjKey">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip :style="qual.GradientStyle" color="background" v-bind="attrs" v-on="on">
                                  {{qual.Title ? qual.Title : qual.Name}}
                                </v-chip>                  
                              </template>
                                <span v-if="qual.Field">
                                  {{qual.Field.Name}}
                                </span>
                                <span v-else>
                                  {{qual.Value}} (Self Rated)
                                </span>
                            </v-tooltip>
                          </v-card-text>
                          <v-card-text v-if="!jobapp[prp.Prop]">
                            This Member has not captured any {{prp.Prop}}
                          </v-card-text>
                        </v-card>
                    </v-menu>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
                <v-card-actions class="justify-end" v-if="MiniView">
                  <v-btn :color="jobapp.Progress && jobapp.Progress.Color ? jobapp.Progress.Color : ''" outlined>
                    {{jobapp.Progress ? jobapp.Progress.Name : 'Received'}}</v-btn>
                  <!-- <v-btn :to="'/FeaturedMember/'+jobapp.Userid+'/Home'" outlined>View CV</v-btn> -->
                </v-card-actions>
              </v-list>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
         <VacancySingle :Directories="Directories" :Editing="Editing" @ConfirmPublish="ConfirmPublish"
         :ActiveVacancy="ActiveVacancy" @GoBack="GoBack" @Edit="Edit" @SaveVacancy="SaveVacancy"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @DeactivateActivesession="DeactivateActivesession"  :SubscriptionPackages="SubscriptionPackages"
         v-if="UserisGuest && ActiveVacancy.Ownerid !== this.userLoggedIn.id || !ActiveVacancy.id"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import VacancySingle from '@/components/SuitePlugins/Vacancies/VacancySingle';
import DateRender from '@/components/Database/Fields/ReadOnly/DateRender';	
import TimelineTab from '@/components/WebPages/RenderComponents/TimelineTabReadOnly';
export default {
    props: ['System','SystemEntities'],
    components: {VacancySingle,DateRender,TimelineTab},
    data() {
        return {
            MemberDetailsDialog: false,
            ApplyingMember: '',
            Editing: false,
            ActiveVacancy: '',
            EmploymentTypes: [
                {ID: 1000001,Name: 'Full Time'},
                {ID: 1000001,Name: 'Part Time'},
                {ID: 1000001,Name: 'Internship'},
                {ID: 1000001,Name: 'Temporary'}
            ]
        }
    },	
    computed:{
      ComputedHighlightsElmnt(){
            let elmnt = Object.assign({},this.JobsElmnt)
            if(this.ApplyingMember && this.ApplyingMember.Highlights){
            elmnt.Items = this.ApplyingMember.Highlights.map(job => {
                let jobobj = Object.assign({},job)
                jobobj.Title = job.Title +' ('+job.Institution+')'
                if(job.Achieved){
                    jobobj.TitleDate = job.Achieved
                }
                jobobj.AchievedObject = jobobj.Achieved.toDate ? jobobj.Achieved.toDate() : jobobj.Achieved
                jobobj.TimestampSort = jobobj.AchievedObject.getTime()
                return jobobj
            }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
            }
            return elmnt
        },
      MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      FMProps(){
        //if small with icons could do all?
        //'Characteristics','Hobbies','Highlights','Industries','Skills','Methodologies,'Qualifications','Jobs'
        return [
          {icon: 'mdi-factory',Prop: 'Industries'},
          {icon: 'mdi-arm-flex',Prop: 'Skills'},
          {icon: 'mdi-book-education',Prop: 'Qualifications'},
          {icon: 'mdi-arrow-decision',Prop: 'Methodologies'},
          {icon: 'mdi-soccer',Prop: 'Hobbies'}
        ]
      },
      ActiveVacancyApplications(){
        return this.MyCompanyJobApplications.filter(jobapp => {
          return jobapp.Vacancyid === this.ActiveVacancy.id
        }).map(jobapp => {
          let jobappobj = Object.assign({},jobapp)
          this.FMProps.map(entry => {
            let prp = entry.Prop
            if(jobapp[prp]){
              jobapp[prp] = jobapp[prp].map(item => {
                let itemobj = Object.assign({},item)
                let grad = item.Value
                itemobj.GradientStyle = 'background: linear-gradient(90deg, rgba(35,255,0,0.7) '+grad+'%, rgba(133,166,171,0) '+grad+'%);'
                return itemobj
              })
            }
          })
          return jobappobj
        })
      },
        MyCompanyJobApplications(){
          return this.$store.state.MyCompanyJobApplications
        },
        OwnerObj(){
        return {          
                  Created_By: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                  Created_Byid: this.userLoggedIn.id,
                  Owner: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                  Ownerid: this.userLoggedIn.id,
                  Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                  Modified_Byid: this.userLoggedIn.id,
          }
      },
        Vacancies(){
            return this.$store.state.MyVacancies.concat(this.$store.state.VacanciesOther)
        },
        ComputedVacancies(){
            return this.Vacancies.map(vac => {
                let vacobj = Object.assign({},vac)
                vacobj.PublishAgeing = '4 Days Ago'
                //{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}
                vacobj.AvatarTitle = vacobj.Title.split(' ')[0].substr(0,1)
                if(vacobj.Title.split(' ')[1]){
                 vacobj.AvatarTitle = vacobj.AvatarTitle+vacobj.Title.split(' ')[1].substr(0,1)
                }      
                vacobj.Color = this.RandomColor()          
                return vacobj
            })
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      ViewMemberDetails(jobapp){
        this.ApplyingMember = jobapp
        this.MemberDetailsDialog = true
      },
      CancelViewMemberDetails(){
        this.MemberDetailsDialog = false
        this.ApplyingMember = ''
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        ConfirmPublish(vac){
            let status = vac.PublishStatus === 'Draft' ? 'Published' : 'Draft'
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('vacancies').doc(vac.id).update({
                PublishStatus: status
            })
        },
        SaveVacancy(vac){
            if(!vac.PublishStatus){
                vac.PublishStatus = 'Draft'
                vac = {...vac,...this.OwnerObj}
            }
            if(vac.id){
            }
            else{
            let path = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('vacancies')
            let ref = path.doc()
            let docid = ref.id
            //console.log(path.doc(docid),vac)
            path.doc(docid).set(vac)
            }
        },
        ActivateNewVacancyDialog(vac){
            this.Editing = true
            this.ActivateVacancy(vac)
        },
        Edit(){
            this.Editing = true
        },
        GoBack(){
            if(this.Editing){
                this.Editing = false
            }
            else{
              this.ActiveVacancy = ''  
            }
            
        },
        ActivateVacancy(vac){
            this.ActiveVacancy = vac
        },
        RandomColor(){
            let colors = [									
                '#F44336',									
                '#E91E63',									
                '#9C27B0',									
                '#673AB7',									
                '#3F51B5',									
                '#2196F3',									
                '#03A9F4',									
                '#00BCD4',									
                '#009688',									
            ]									
            let ranint = Math.floor(Math.random() * Math.floor(colors.length))	
            return colors[ranint]
        },
    }
}
</script>

<style>

</style>



