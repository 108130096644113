<template>
  <div>
    
     <v-card-subtitle class="mediumoverline" style="padding-top:30px;">
                       Transactions
                    </v-card-subtitle>
                     <v-card  class="siteconfigcard"  width="100%">
                   <v-list>
                    <v-list-item v-if="!MiniView">
                           Due Date Filter <v-spacer>
                               </v-spacer>
                           <FieldValueEditerComponent v-for="field in StatementFilters" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="DateObject" :field="field" @onPhotoFileselected="onPhotoFileselected"
                            :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                            @UploadFileSelect="UploadFileSelect"
                                />
                       </v-list-item>
                        <v-list-item v-if="MiniView">
                           Statement 
                       </v-list-item>
                        <v-list-item v-if="MiniView">
                           <FieldValueEditerComponent v-for="field in StatementFilters" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="DateObject" :field="field" @onPhotoFileselected="onPhotoFileselected"
                            :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                            @UploadFileSelect="UploadFileSelect"
                                />
                       </v-list-item>
                       <v-list class="transparent">
                        <v-list-item class="justify-center">
                           <v-list-item-content>
                                 File
                           </v-list-item-content>
                           <v-list-item-content>
                              Order
                           </v-list-item-content>
                            <v-list-item-content>
                              Due Date
                           </v-list-item-content>
                           <v-list-item-actions>
                               Progress
                           </v-list-item-actions>
                       </v-list-item>
                       <v-list-item class="detailslistoutline" v-for="trans in BillingTransactions" :key="trans.itemObjKey">
                           <v-list-item-content>
                               <v-list-item-title>
                                   <v-chip  @click="GetShortLivedURL(trans.Latest_PDF_File)">
                                   {{trans.id}}</v-chip>
                               </v-list-item-title>
                               <v-list-item-subtitle class="caption">
                                   {{trans.Created_On.toDate()}}
                               </v-list-item-subtitle>
                           </v-list-item-content>
                           <v-list-item-content v-if="trans.LookupObj">
                               {{trans.LookupObj.id}}
                           </v-list-item-content>
                            <v-list-item-content v-if="trans.Due_Date">
                              {{TimestampFormatterSTRINGDayText(trans.Due_Date.toDate())}}
                           </v-list-item-content>
                           <v-list-item-actions v-if="trans.Progress">
                               <v-chip>
                                   {{trans.Progress.Name}}
                               </v-chip>
                           </v-list-item-actions>
                       </v-list-item>
                       </v-list>
                   </v-list>
                     </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
export default {
    props: ['System','SystemEntities','ActiveAccount','CompanyView','CompanyRecord','CompanyBillingAccounts'],
    components: {FieldValueEditerComponent},
    data() {
        return {
          DateObject: {},
          StatementFilters: [
                {Name: 'Start_Date',DisplayName: 'Start Date',Type: 'Date'},
                {Name: 'End_Date',DisplayName: 'End Date',Type: 'Date'},
            ],
            SalesInvoices: [],
          SalesQuotes: [],
        }
    },	
    computed:{
       ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        SalesInvoiceEntity(){
          return this.SystemEntities.find(obj => obj.id === 'Sales_Invoices')
        },
        SalesQuoteEntity(){
          return this.SystemEntities.find(obj => obj.id === 'Sales_Quotes')
        },
        BillingTransactions(){
            return this.SalesQuotes.concat(this.SalesInvoices).filter(item => {
              if(this.DateObject.Start_Date  && this.DateObject.End_Date){
                return item.Due_Date
              }
              else{
                return item
              }
            }).filter(item => {
                let itemdate = new Date(item.Due_Date.toDate())
                let startdate = itemdate.setHours(0,0,0,0)
                let startdatefilter = new Date(this.DateObject.Start_Date).setHours(0,0,0,0)
                let enddatefilter = new Date(this.DateObject.End_Date).setHours(23,59,59,999)
                    return this.DateObject.Start_Date && this.DateObject.End_Date ? startdate >= startdatefilter && itemdate <= enddatefilter : item        
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.CompanyView){
        let date = new Date()
        let enddate = this.EndofMonth(date)
        let startdate = this.StartofMonth(date)
        // this.DateObject.Start_Date = format(startdate,'yyyy-MM-dd')
        //this.DateObject.End_Date = format(enddate,'yyyy-MM-dd')
      }
        if(this.SalesInvoiceEntity){
          let salesinvquery = ''
          if(!this.CompanyView){
            salesinvquery = db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Sales_Invoices').collection('Records').where('BillingAccountid','==',this.ActiveAccount.id)
          }
          else{
            salesinvquery = db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Sales_Invoices').collection('Records').where('Companyid','==',this.CompanyRecord.id)
          }
          this.GetSalesInvoices(salesinvquery)
        }
        if(this.SalesQuoteEntity){
          let salesquotequery = ''
          if(!this.CompanyView){
            salesquotequery = db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Sales_Quotes').collection('Records').where('BillingAccountid','==',this.ActiveAccount.id)
          }
          else{
            salesinvquery = db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Sales_Invoices').collection('Records').where('Companyid','==',this.CompanyRecord.id)
          }
          this.GetSalesQuotes(salesquotequery)
        }
         
    },
    methods:{
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
      StartofMonth(d){
            d.setDate(1);	
            d.setHours(0,0,0,0);
            return d
        }, 
      EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },	
        GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
           let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
        GetSalesInvoices(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let invoiceobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    this.SalesInvoices.push(invoiceobj)
                }
                 if (change.type === 'modified') {
                    let invoiceobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.SalesInvoices.find(obj => obj.id === invoiceobj.id)
                    if(oncheck){
                        let index = this.SalesInvoices.indexOf(oncheck)
                        this.SalesInvoices.splice(index,1,invoiceobj)
                    }
                }
                if (change.type === "removed") {                      

                      let invoiceobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.SalesInvoices.find(obj => obj.id === invoiceobj.id)
                      if(oncheck){
                        let index = this.SalesInvoices.indexOf(oncheck)
                        this.SalesInvoices.splice(index, 1);
                      }
                  }
                })
            })
        },
        GetSalesQuotes(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let invoiceobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    this.SalesQuotes.push(invoiceobj)
                }
                if (change.type === 'modified') {
                    let invoiceobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.SalesQuotes.find(obj => obj.id === invoiceobj.id)
                    if(oncheck){
                        let index = this.SalesQuotes.indexOf(oncheck)
                        this.SalesQuotes.splice(index,1,invoiceobj)
                    }
                }
                if (change.type === "removed") {                      

                      let invoiceobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.SalesQuotes.find(obj => obj.id === invoiceobj.id)
                      if(oncheck){
                        let index = this.SalesQuotes.indexOf(oncheck)
                        this.SalesQuotes.splice(index, 1);
                      }
                  }
                })
            })
        },
        
    }
}
</script>

<style>

</style>



