<template>
  <div>
    <v-dialog v-model="ExtractPluginDialog" max-width="400">
      <v-card>
        <v-card-title>
          Extract to Plugin
        </v-card-title>
        <v-card-text>
          Here you are going to convert this System or its parts as plugins
          <FieldsForm :FormFields="PluginHeaders" :Validating="Validating" :DataTableView="true" @save="save" :DataObject="ExtractPlugin" :tab="{}" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelExtractPluginDialog()" dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="PrepareandSubmitPlugin()" dark color="success">
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="InvoicePaymentDialog" max-width="800">
        <v-card tile  width="100%" flat v-if="RecordData">
          <v-card-title class="justify-space-between recordtoolbar white--text mediumoverline">
            Payment Receipt<v-spacer></v-spacer>{{RecordData.id}}
          </v-card-title>
          <v-card-text>
            <FieldValueEditerComponent v-for="field in PaymentReceiptHeaders" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="paymentreceipt" :field="field" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="'Quote_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelectPOP"
                />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="CloseQuotePaymentDialog()" dark color="warning">
              Cancel
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="UploadPOP()">Save</v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
          <v-dialog max-width="400" v-model="NewLibraryDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewLibrary.Type}}
              </v-card-title>
              <v-card-text>
                 <v-text-field v-model="NewLibrary.DisplayName" label="DisplayName" />
                 <v-text-field v-model="NewLibrary.SingleName" label="SingleName" />
                <!-- <v-text-field v-model="NewPage.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="NewLibrary.Icon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewLibraryDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewLibrary()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
    <v-dialog v-model="InvoicePaymentDialog" max-width="800">
        <v-card tile  width="100%" flat v-if="RecordData">
          <v-card-title class="justify-space-between recordtoolbar white--text mediumoverline">
            Payment Receipt<v-spacer></v-spacer>{{RecordData.id}}
          </v-card-title>
          <v-card-text>
            <FieldValueEditerComponent v-for="field in PaymentReceiptHeaders" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="paymentreceipt" :field="field" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="'Quote_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelectPOP"
                />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="CloseQuotePaymentDialog()" dark color="warning">
              Cancel
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="UploadPOP()">Save</v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
          <v-dialog max-width="400" v-model="NewLibraryDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewLibrary.Type}}
              </v-card-title>
              <v-card-text>
                 <v-text-field v-model="NewLibrary.DisplayName" label="DisplayName" />
                 <v-text-field v-model="NewLibrary.SingleName" label="SingleName" />
                <!-- <v-text-field v-model="NewPage.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="NewLibrary.Icon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewLibraryDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewLibrary()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
     <v-dialog max-width="400" v-model="LiaisonTopicDialog">
             <v-card >
                       <v-card-title class="recordtoolbar white--text">
                         Configure Liaison Portal
                       </v-card-title>
                       <v-card-text>
                         Configure the Entity as a Liaison portal item, to open up liaison portal between Guest Dashboard, and Internal Pipelines.
                       </v-card-text>
                       <v-card-text>
                         <v-switch v-model="CurrentEntity.HasLiason" label="Turned On"/>
                         <v-text-field v-if="CurrentEntity.HasLiason" label="Topic Name (must be unique to Table)" v-model="CurrentEntity.LiaisonTopicName"/>
                         <v-text-field v-if="CurrentEntity.HasLiason" label="Topic Icon" v-model="CurrentEntity.LiasonTopicIcon"/>
                         <v-select v-if="CurrentEntity.HasLiason" label="Client Field" return-object :items="CurrentEntityUserLookupFields" item-text="DisplayName" v-model="CurrentEntity.ClientLookupField"/>
                       </v-card-text>
                       <v-card-actions>
                         <v-btn>Cancel</v-btn>
                         <v-spacer></v-spacer>
                         <v-btn :disabled="!CurrentEntity.HasLiason" @click="UpdateClientLiaisonPortal()">Save</v-btn>
                       </v-card-actions>
                </v-card>
            </v-dialog>
    <v-dialog v-model="AutomationDialog" width="400" persistent>
        <AutomationDialogComponent v-if="AutomationDialog" @ToggleAutomationDialog="ToggleAutomationDialog"
        :System="ComputedSystem" :SystemEntities="ComputedSystemEntities" :ActiveSession="ActiveSession" :record="AutomationRecord"

        />
         </v-dialog>
    <v-dialog v-model="WokrflowBuilderDialog" width="400" persistent>
        <WorkFlowBuilder v-if="WokrflowBuilderDialog" @ToggleAutomationDialog="ToggleWorkflowBuilderDialog"
        :System="System" :SystemEntities="SystemEntities" :CurrentEntity="CurrentEntity"

        />
         </v-dialog>
    <!-- <v-layout column style="bottom:125px;right:50px;position: fixed;z-index:2;"> -->
      <v-dialog persistent v-if="BookedUser" v-model="BookingDialog" width="400">
        <v-card  width="400" class="transition-fast-in-fast-out" style="height: 100%;bottom: 0;position: absolute;right: 0;">
          <v-card-title class="recordtoolbar white--text">
            Confirmed Booking
          </v-card-title>
          <v-list-item>
                    
                    <!-- <v-list-item-content > -->
                    <v-list-item-avatar class="blue" tile size="80">
                      <img v-if="BookedUser.Profile_Photo "
                      :src="BookedUser.Profile_Photo"
                      style="object-fit: cover;"              
                      >
                      <img v-if="!BookedUser.Profile_Photo "
                        src="@/assets/BlankProfilePic.png"  
                        style="object-fit: cover;"            
                      >                      
                      </v-list-item-avatar>
                    <!-- </v-list-item-content> -->
                    <v-list-item-content >
                      <v-list-item-title>{{BookedUser.Full_Name}}</v-list-item-title>                    
                      <v-list-item-subtitle>
                        <router-link  :to="'/MyProfile'">View My Profile</router-link>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{BookedUser.Email}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Online: Online Now
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
              <v-divider>
              </v-divider>
          <v-card-text style="padding:20px;">
            <!-- <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="{}"
                :sectionindex="''" :tabindex="''" :SampleOnly="true" :System="System"
                :PrimaryFieldName="''" :SingleFormEditing="false" :CurrentEntity="CurrentEntity"
                :Record="BookedUser.BookingObj" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="BookingHeaders" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                /> -->
            <FieldsForm :FormFields="BookingHeaders" @UploadFileSelect="UploadFileSelect" @GetShortLivedURL="GetShortLivedURL"
            :DataTableView="true"  :DataObject="BookedUser.BookingObj" :tab="{}" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="warning" dark @click="DeactivateBookedUserDialog()">Cancel</v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="UpdateBookedUser(BookedUser)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
     </v-dialog>
     <v-dialog v-if="DynamicRecordData" v-model="BookedUsersDialog" width="400">								
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" ><v-icon style="bottom:125px;right:50px;position: fixed;z-index:2;"
          v-if="CanEdit && CurrentEntity && CurrentEntity.Entity_Type && CurrentEntity.Entity_Type.Name === 'Booking Schedule'"  x-large  class="soloactionicon blue" color="white" >mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-card  width="400" class="transition-fast-in-fast-out" style="height: 100%;bottom: 0;position: absolute;right: 0;">
          
          <v-card-title class="recordtoolbar white--text">
            Booked Users
            <v-spacer></v-spacer>
            <v-btn @click="BookedUsersDialog = !BookedUsersDialog" color="red" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-text-field label="Search" v-model="BookingSearch"/>
            <v-virtual-scroll 
                width="100%"
                class="transparent"
                :items="BookedUsers"
                :item-height="60"
                :height="WindowHeight*0.85"
              >
              
                  <template v-slot:default="{ item }">
                    <v-list dense class="caption">
                      <v-list-item @click="ActivateBookedUserDialog(item)">
                        <v-list-item-avatar>
                          <img v-if="item.Profile_Photo"
                            :src="item.Profile_Photo"
                            style="object-fit: cover;"              
                            >
                            <img v-if="!item.Profile_Photo "
                              src="@/assets/BlankProfilePic.png"  
                              style="object-fit: cover;"            
                            >   
                        </v-list-item-avatar>
                        <v-list-item-content>
                          {{item.Full_Name}}
                        </v-list-item-content>
                         <v-list-item-content>
                          {{item.BookingDate}}
                        </v-list-item-content>
                        
                      </v-list-item>
                    </v-list>
                  </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
     </v-dialog>
  <!-- </v-layout> -->
  <!-- removed recordtoolbar from "color" AND id -->
      <v-toolbar :style="$route.name === 'SystemConfig' ? 'margin-top:-40px;' : ''" tile dark :color="Status && Status.ID === 1000001 ? '':'warning'" 
      :class="RecordToolbarClass" width="100%" :id="$route.name === 'SystemConfig' ? '' : ''" v-if="!WikiMode">
       
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <SystemBuilderComponent v-if="$route.meta && $route.meta.SystemBuilder && EntityReady && !$route.query.Record" @AuthenticateClientApp="AuthenticateClientApp" :CurrentEntity="CurrentEntity" :DynamicRecordData="DynamicRecordData"
        :ClientDBBusinessUnits="ClientDBBusinessUnits" :ClientDBAdministrators="ClientDBAdministrators" :QuickstartStep="QuickstartStep" :clientDB="clientDB" :clientApp="clientApp"
        :QuickStartDialog="QuickStartDialog" :ClientFireStore="ClientFireStore" :SystemObj="SystemObj" :SystemEntities="SystemEntities" :System="System"
        :ClientappSignedInEmail="ClientappSignedInEmail" :ClientAppSignedIn="ClientAppSignedIn" :BaseClientSystemReady="BaseClientSystemReady"
        :ClientAppAuthDialog="ClientAppAuthDialog" :ViewingSystemID="$route.params.id" :EntityDataRef="EntityDataRef" :ClientAppUser="ClientAppUser"
       :EntityCollectionRef="EntityCollectionRef"
      />
       
      <v-list :class="$vuetify.theme.dark ? 'purple' : 'lime accent-1'" >
      <v-list-item v-if="userIsAdmin && SystemisRA && $route.meta.RecordisSystem || CanEdit && SystemisRA && CurrentEntity && CurrentEntity.id === 'Systems' && !$route.meta.RecordisSystem" @click="ReplicateSystemConfig()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Update System Config
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-alert</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="CanEdit && System.SystemBuilder && CurrentEntity && CurrentEntity.id === 'Systems' && !$route.meta.RecordisSystem" @click="ActivateExtractPluginDialog()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Extract Plugin
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" >mdi-cash-register</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-dialog v-model="SystemCurrencyDialog"
          transition="scale-transition"
          width="400"
        >
          <template v-slot:activator="{ on }">
            <v-list-item  v-if="userIsAdmin && $route.name === 'SystemConfig'" v-on="on" class="listoutline">
              <v-list-item-title  class="subtleoverline">
                Update Currency
              </v-list-item-title>
            <v-list-item-action>
                <v-icon class="actionicon" color="pop">mdi-currency-brl</v-icon>
              </v-list-item-action>
            </v-list-item>
            </template>
            <v-card>
              <v-card-title class=" ">
                System Currency
              </v-card-title>
              <v-card-text>
                 <v-select label="Currency" return-object :items="$store.state.Currencies" item-text="Currency" v-model="SystemDefaultCurrency"/>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="CancelSystemCurrencyDialog()"  class="warning">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="UpdateSystemCurrency()"  class="success">Save</v-btn>
              </v-card-actions>
            </v-card>          
        </v-dialog>
      <v-list-item @click="NoPropEmit('ToggleHelp')" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item  @click="NoPropEmit('ToggleChangeStatusDialog')" class="listoutline">
            <v-list-item-title  class="subtleoverline">
              {{StatusToggleName}}
            </v-list-item-title>
          <v-list-item-action>
              <v-icon class="actionicon" >mdi-power</v-icon>
            </v-list-item-action>
          </v-list-item>
        <v-list-item @click="NoPropEmit('UpdateSystem')" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="NoPropEmit('EditOwnership')" v-if="CanEditOwnership"  :class="EditingOwnership? 'red listoutline' : 'listoutline'">
          <v-list-item-title  class="subtleoverline">
            {{EditingOwnership ? 'Cancel' : 'Edit Ownership'}}
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="ToggleAutomationDialog()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline ">
           Automations
          </v-list-item-title> 
        <v-list-item-action>
            <v-icon class="actionicon" >mdi-auto-fix</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item  @click="NoPropEmit('ToggleExportingtoPDFDialog')">
          <v-list-item-title  class="subtleoverline ">Export to PDF</v-list-item-title>
          <v-list-item-action>
          <v-icon class="actionicon" >mdi-file-pdf-box</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- modular tabs edit toggle -->
         <v-list-item :to="'/ClassifiedPreview/'+CurrentEntity.SingleName.split(' ').join('')+'/'+$route.params.id" v-if="CanModerateClassified"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Preview Classified
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-certificate-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
   
        <v-list-item :to="'/WikiPreview/'+CurrentEntity.SingleName.split(' ').join('')+'/'+$route.params.id" v-if="CanModerateWiki"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Preview Wiki
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-certificate-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
    
        <v-list-item @click="NoPropEmit('ActivateReceivingDialog')" v-if="CanReceiveGoods"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Receive Goods
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-warehouse</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="NoPropEmit('ActivateWarehouseMovementDialog')" v-if="MovementHistoryActive"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Movement History
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-timeline-clock-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item @click="NoPropEmit('EditSingleForm')" v-if="CanEditLayout"  :class="SingleFormEditing? 'red listoutline' : 'listoutline'">
          <v-list-item-title  class="subtleoverline">
            {{SingleFormEditing ? 'Cancel Form Edit' : 'Edit Form'}}
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-format-line-style</v-icon>
          </v-list-item-action>
        </v-list-item>
      <v-list-item v-if="CurrentEntity.EntityProcesses && CurrentEntity.EntityProcesses.length > 0 && CanEdit" @click="NoPropEmit('AssignProcess')"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Assign Process
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="warning">mdi-swap-vertical-variant</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :to="'/PipelineManagement/'+CurrentEntity.id" v-if="CanEditLayout"  class="listoutline">
          <v-list-item-title  class="subtleoverline ">
           Manage Pipelines
          </v-list-item-title> 
        <v-list-item-action>
            <v-icon color="warning">mdi-pipe</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="ActivatePipelineworkflowdialog()" v-if="CanEditLayout"  class="listoutline">
          <v-list-item-title  class="subtleoverline ">
           Manage Automations
          </v-list-item-title> 
        <v-list-item-action>
            <v-icon color="warning">mdi-auto-fix</v-icon>
          </v-list-item-action>
        </v-list-item>
         
           <v-list-item @click="ActivateLiaisonTopicDialog()" v-if="CanEditLayout"  class="listoutline">
                        <v-list-item-title  class="subtleoverline ">
                        Portal Item
                        </v-list-item-title>
                      <v-list-item-action>
                          <v-icon color="warning">mdi-comment-outline</v-icon>
                        </v-list-item-action>
                      </v-list-item>
        <v-list-item @click="NoPropEmit('EditTableState')" v-if="CanEditLayout && StatusField"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
           Manage Table State
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-power-settings</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="NoPropEmit('ActivateRoadmapItemDialog')" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && !DynamicWikiData.RoadmapItem"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
           Create Roadmap Item
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-road-variant</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :to="'/Roadmap'" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && DynamicWikiData.RoadmapItem"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
           On Roadmap
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-road-variant</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="NoPropEmit('ActivateForumDiscussionDialog')" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && !DynamicWikiData.Forum"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
           Create Forum
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-publish</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :to="'/Forum/'+DynamicWikiData.Forum.id" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && DynamicWikiData.Forum"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
           Visit Forum
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-publish</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="NoPropEmit('ToggleRATicketLog')" v-if="userIsAdmin && CurrentEntity.id === 'System_Tickets' && RAUser && !DynamicWikiData.RATicketid"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
           Escalate to RapidApps
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-publish</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- <SecurityRulesUpdateComponent v-if="$route.meta && $route.meta.RecordisSystem && userIsAdmin" :System="System" :SystemEntities="SystemEntities"
        /> -->
        <v-list-item @click="NoPropEmit('RoutetoEntityBuilder')" v-if="CurrentEntity.Entity_Type && CurrentEntity.Entity_Type.Name === 'Entity Builder'"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            {{SingleFormEditing ? 'Cancel Entity Form Edit' : 'Entity Builder'}}
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-format-line-style</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- EntityReady -->
          <!-- <v-btn @click="EntityReady =! EntityReady">ToggleEntityView</v-btn> -->
    
      </v-list>                        
   </v-menu>       
   <v-toolbar-title>
   <v-list width="100%" class="transparent">
   <v-list-item v-if="DynamicRecordData">
  <v-list-item-content >
    <span class="headline"><span class=" hidden-sm-and-down">{{CurrentEntity.SingleName}} - </span><span class="headline font-weight-light">{{ DynamicRecordData[CurrentEntity.Primary_Field_Name]  }}</span></span>                   
    <v-list-item-subtitle v-if="Status">
       {{Status.Name}}
    </v-list-item-subtitle>
  </v-list-item-content>
  </v-list-item>
 
    </v-list>
   </v-toolbar-title>
   <v-spacer v-if="!MiniView"></v-spacer><v-list-item-content v-if="OwnerSecurity" :style="MiniView ? 'text-align: -webkit-right;' : 'text-align: -webkit-right;padding-right: 90px;'">
        <v-list-item-title :class="MobileView ? 'overline' : ''">
          <v-icon :small="MobileView">mdi-office-building</v-icon>
        {{RecordData.Business_Unit ?RecordData.Business_Unit.Name: ''}}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          >
            <template v-slot:activator="{ on }">
          <v-chip style="padding: 2px;" :small="MobileView" outlined v-on="on">
           <v-avatar size="35">
              <v-img :src="RecordData.Owner.Profile_Photo" v-if="RecordData.Owner && RecordData.Owner.Profile_Photo"/>
              <v-img src="@/assets/BlankProfilePic.png" v-if="!RecordData.Owner || !RecordData.Owner.Profile_Photo"/>
              </v-avatar>
          {{RecordData.Owner ? RecordData.Owner.Full_Name: ''}}
          </v-chip>
           </template>
           <v-card tile max-width="350">
             <v-card-text>
           <UserLookup :UsersLookupArray="PotentialOwnerUsersArray" :ModelProp="'Owner'" :RecordObj="RecordData" @UpdateUserLookupProp="UpdateOwningUser"
            :FieldLabel="'Owner'" :ReadOnly="false"
            :Rules="[]"
            />
             </v-card-text>
           </v-card>
          </v-menu>
        </v-list-item-subtitle>
      </v-list-item-content> 
   <v-btn dark v-if="SalesInvoiceReady"
   color="success" @click="SendInvoiceonCommsChannel()">Send Invoice</v-btn> 
   <v-btn dark v-if="BAneedsOwner" :to="'/BillingAccount/'+RecordData.BillingAccountid"
   color="white" outlined ><v-icon>mdi-alert</v-icon>No Account Owner</v-btn>
   <v-btn dark v-if="SalesInvoiceReady && SalesInvoicecanhavePayment"
    color="success" @click="ActivateInvoicePaymentDialog()">Receive Payment</v-btn>   
            <!-- <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="OnePropEmit('NavigatetoTab',item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>        -->
 </v-toolbar> 
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/Fields/FieldsForm';	
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import SystemBuilderComponent from '@/components/RAExclusive/SystemBuilderComponent';
import WorkFlowBuilder from '@/components/SuitePlugins/Automations/WorkFlowBuilder'
import AutomationDialogComponent from '@/components/SuitePlugins/Automations/AutomationDialogComponent'
import BottomNavigation from '@/components/Navigation/BottomNavigation'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import UserLookup from '@/components/General/UserLookup'
export default {
    props: ['System','SystemEntities','Status','StatusToggleName','EntityReady','ConfigSystemEntities',
    'ClientFireStore','clientDB','ClientDBAdministrators','ClientDBBusinessUnits','QuickstartStep','EntityDataRef','EntityCollectionRef','BaseClientSystemReady',
    'SystemEntities','System','BannerTabs','RAUser','ClientAppUser',
    'QuickStartDialog','ClientAppAuthDialog','ViewingSystemID','SystemObj','ClientappSignedInEmail','ClientAppSignedIn','CurrentEntity','DynamicRecordData','RecordData',
    'CanModerateClassified','CanModerateWiki','CanEdit','CanEditOwnership','EditingOwnership','CanEditLayout','StatusField','SingleFormEditing','DynamicWikiData','ExportingtoPDFDialog'],
    components: {SystemBuilderComponent,FieldsForm,SingleTabFieldsSection,WorkFlowBuilder,AutomationDialogComponent,BottomNavigation,FieldValueEditerComponent,UserLookup},
    data() {
        return {
          ExtractPluginDialog: false,
          ExtractPlugin: {},
          PluginHeaders: [],
          NewLibraryDialog: false,
          DefaultNewLibrary: {
            Collection: '',
            CustomTagsInteger: 1000000,
            CustomTags: [],
            DisplayName: '',
            Icon: '',
            PublishType: 'Public',
            Security_Structure: 'Custom Roles',
            SingleName: '',
            SinglePath: '',
            Type: '',
          },
          NewLibrary: {
            Collection: '',
            CustomTagsInteger: 1000000,
            CustomTags: [],
            DisplayName: '',
            Icon: '',
            PublishType: 'Public',
            Security_Structure: 'Custom Roles',
            SingleName: '',
            SinglePath: '',
            Type: '',
          },
          SystemCurrencyDialog: false,
          SystemDefaultCurrency: '',
          ActiveSession: '',
          AutomationRecord: {},
          AutomationDialog: false,
          AutomationButtons: [],
          WokrflowBuilderDialog: false,
          BookingHeaders: [],
          BookingDialog: false,
          BookedUser: '',
          BookingSearch: '',
          BookedUsersDialog: false,
          LiaisonTopicDialog: false,
          InvoicePaymentDialog: false,
          paymentreceipt: {
            Amount_Paid: 0,
            SelectedPOPFile: {}
          },
          PaymentReceiptHeaders: [
            {id: 'Amount_Paid',DisplayName: 'Amount Paid', Name: 'Amount_Paid',Type: 'Number Field'},
            {id: 'SelectedPOPFile',DisplayName: 'POP File', Name: 'SelectedPOPFile',Type: 'Single File Upload'},
          ],
          
        }
    },
    computed:{
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      OwnerSecurity(){
        return this.CurrentEntity.Security_Structure.Name === 'Business Unit' || this.HasOwnerField
      },
      HasOwnerField(){
        return this.CurrentEntity && this.CurrentEntity.AllFields ? this.CurrentEntity.AllFields.find(obj => obj.id === 'Owner') : ''
      },
      ActiveGroup(){
        return this.$store.state.ActiveGroup
      },
      UsersArray(){
      return this.$store.state.UsersArray
    },
      PotentialOwnerUsersArray(){
        if(this.CurrentEntity){
            let entityprop = this.CurrentEntity.DisplayName.split(' ').join('')
            return this.UsersArray
            .filter(user => {
              //technically this checks whom I have permissions to assign to. Issue is I don't know if THEY have access...
                 let datasetsecuritystructure = this.CurrentEntity.Security_Structure.Name
                //console.log('PotentialOwnerUsersArray ',datasetsecuritystructure,entityprop+'editAll',this.$store.state[entityprop+'editAll'],this.$store.state)
                if(datasetsecuritystructure === 'Business Unit'){
                    return this.$store.state[entityprop+'editAll'] || 
                        this.$store.state[entityprop+'editUnitdown'] && this.userBUChildren.includes(user.Business_Unitid) || 
                        this.$store.state[entityprop+'editUserUnit'] && this.userBUID === user.Business_Unitid || 
                        this.$store.state[entityprop+'editOwner'] && this.userLoggedIn.id === user.id
                }
                else if(datasetsecuritystructure === 'Custom Roles'){
                    return this.$store.state[entityprop+'CanCreate']
                }
                else if(datasetsecuritystructure === 'Group Member'){
                        return this.ActiveGroup.TeamMembers.includes(user.id) || this.ActiveGroup.Administrators.includes(user.id)
                    }   
                
            })
        }
        },
      SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      RecordToolbarClass(){
        if(this.$route.name === 'SystemConfig'){
          return 'stickytopbanner2'
        }
        else{
          return this.AppStylingView? 'stickytopbanner2appstyling' : 'stickytopbanner2'
        }
        
      },
      SalesInvoiceReady(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Sales Invoice'
        && this.RecordData && this.RecordData.Latest_PDF_File && this.RecordData.Progress && this.RecordData.Progress.Name === 'Invoiced' && this.RecordData.Client
        && this.RecordData.Owner &&this. RecordData.BillingAccount && this.RecordData.BillingAccount.Owner
      },
      BAneedsOwner(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Sales Invoice'
        && this.RecordData && this.RecordData.Latest_PDF_File && this.RecordData.Progress && this.RecordData.Progress.Name === 'Invoiced' && this.RecordData.Client
        && this.RecordData.Owner &&this. RecordData.BillingAccount && !this.RecordData.BillingAccount.Owner
      },
      SalesInvoicecanhavePayment(){
        return this.RecordData.Progress.Name === 'Invoiced' && this.userisDebtorsClerk
      },
      userisDebtorsClerk(){
        return this.IsAccountingClerk('Debtor',this.RecordData.Business_Unitid) || this.userIsAdmin
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      MovementHistoryActive(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'
      },
      CanReceiveGoods(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.CanEdit && this.BypassInboundWIP
      },
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
      CurrentEntityApp(){
            return this.CurrentEntity ? this.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid) : ''
    },
      BypassInboundWIP(){
          let appplugins = []
          if(this.CurrentEntityApp){
            appplugins = this.CurrentEntityApp.Plugins.map(plug => {return plug.id})
          }
          return this.CurrentEntityApp && !appplugins.includes('Warehouse_Inbound')
        },
      
      ComputedSystem(){
        let obj = Object.assign({},this.System)
        return obj
      },
      ComputedSystemEntities(){
        let array = JSON.parse(JSON.stringify(this.SystemEntities))
        return array
      },
      BookedUsersSubCollection(){
        if(this.CurrentEntity && this.CurrentEntity.SubCollections){
            let subcol = this.CurrentEntity.SubCollections.find(obj => obj.id === 'BookedUsers')
            return subcol
          }
      },
        BookingDialogHeaders(){
          if(this.BookedUsersSubCollection && this.BookedUser && this.BookedUser.BookingObj){
            let array = JSON.parse(JSON.stringify(this.BookedUsersSubCollection.Headers))
            return array
            .filter(header => {
              return header.value !== 'Full_Name'
            })
            .map(header => {
              if(this.BookedUser.BookingObj[header.value]){
                header.Response = this.BookedUser.BookingObj[header.value]
              }
              //always has user field. so always map that. Should inf act always be mapped and crap but we could do static run on it
              return header
            })
          }
        },
      BookedUsers(){
        if(this.DynamicRecordData && this.DynamicRecordData.BookedUsers){
          return this.DynamicRecordData.BookedUsers
        //.concat(this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers,this.DynamicRecordData.BookedUsers)
        .filter(user => {
          return this.BookingSearch? user.Full_Name.toLowerCase().includes(this.BookingSearch.toLowerCase()) : user
        })
        }
        
      },
      WindowHeight(){
          return window.innerHeight
      },
      CurrentEntityUserLookupFields(){
        if(this.CurrentEntity && this.CurrentEntity.AllFields){
          return this.CurrentEntity.AllFields.filter(field => {
            return field.Type === 'Lookup' && field.RelatedBuildID === 'Users'
          }).map(field => {
            let fieldobj = Object.assign({},field)
            delete fieldobj.CanEdit
            delete fieldobj.CanDelete
            delete fieldobj.Response
            delete fieldobj.Array
            return fieldobj
          })
        }
      },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
    },
    watch: {
        SocialItemInteractMethod(v){      
          let acceptedmethods = ['ToggleAutomationDialog','SetActivityRegarding','ToggleTimesheetDialog','ToggleNewPhotoLibraryDialog']  
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      }
    },
    created(){
        this.SystemDefaultCurrency = Object.assign({},this.$store.state.DefaultCurrency)
        this.ActiveSession = Object.assign({},this.CurrentEntity)
        if(this.ActiveSession && this.ActiveSession.id){
         this.ActiveSession.collection = this.ActiveSession.id.split('_').join('').toLowerCase() 
         if(this.ActiveSession.automations){
          this.PrepareAutomations()
        } 
        }
        
        //console.log('this.ActiveSession',this.ActiveSession)
        
    },
    methods:{
      UpdateOwningUser(Prop,Value,RecordObj){
        this.$emit('UpdateOwningUser',Prop,Value,RecordObj)
      },
      GetAllEntityProps(configdb,build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(configdb,build).then(function(allfieldsresult) {
                if(allfieldsresult){
                  
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(configdb,build).then(function(subcolresult) {
                        // console.log(subcolresult)
                        
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(configdb,build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(configdb,build).then(function(formsresult) {
                            vm.GetDocumentRegisters(configdb,build).then(function(docregresult) {  
                                vm.GetGalleries(configdb,build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(configdb,build).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                    resolve(build)
                                    
                                
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
    GetEntityAllFields(configdb,build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
                //console.log(configdb.doc(build.id).collection('AllFields'))
            configdb.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(configdb,build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(configdb,build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('EntityProcesses').where('Status','!=','InActive').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(configdb,build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(configdb,build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(configdb,build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(configdb,build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
      PrepareandSubmitPlugin(){
        let vm = this
        // let pluginentity = this.SystemEntities.find(obj => obj.id === 'Plugins')
        // let statusfield = pluginentity.AllFields.find(obj => obj.id === 'Status')
        // let Status = statusfield.Options.find(obj => obj.Name === 'Active')
        // let StatusReason = Status.Options.find(obj => obj.Name === 'Open')
        //console.log(statusfield,Status,StatusReason)
        this.ExtractPlugin = {
          Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
          Modified_Byid: vm.userLoggedIn.id,
          Modified_On: new Date(),
          Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
          Created_Byid: vm.userLoggedIn.id,
          Created_On: new Date(),
          Business_Unit: {id: vm.userLoggedIn.Business_Unit.id,Name: vm.userLoggedIn.Business_Unit.Name},
          Business_Unitid: vm.userLoggedIn.Business_Unit.id,
          // Status: Status,
          // Status_Reason: StatusReason,
          State_Modified_Date: new Date()
        }
        this.PluginHeaders.map(header => {
          //console.log(header.id,header.Response)
          if(typeof header.Response !== 'undefined'){
            this.ExtractPlugin[header.id] = header.Response
          }          
        })
        let pluginid = this.ExtractPlugin.Name.split(' ').join('_')
        
        let PrimaryString = this.ExtractPlugin.Name
        var lowertext = "";                
        var p;
        let buildup = ''
        //console.log(PrimaryString.length,PrimaryString)
        for (p = 0; p < PrimaryString.length; p++) {
            buildup = buildup+ PrimaryString[p]
            if(p === PrimaryString.length-1){
                lowertext += buildup.toLowerCase();
            }
            else{
                lowertext += buildup.toLowerCase() + ",";
            }
        } 
        this.ExtractPlugin.SearchQuery = []
        this.ExtractPlugin.SearchQuery = this.ExtractPlugin.SearchQuery.concat(this.ExtractPlugin.Name.trim().split(' '))         
        this.ExtractPlugin.SearchQuery = this.ExtractPlugin.SearchQuery.concat(lowertext.split(','))  
        this.ExtractPlugin.SearchQuery = this.ExtractPlugin.SearchQuery.map(entry => {
            return entry.toLowerCase()
        })
        this.PreparePluginEntities().then(Installation_Tables => {
          console.log(Installation_Tables)
      //console.log(this.ExtractPlugin)
        this.PreparePluginTableLookups(Installation_Tables).then(Installation_Tables => {
              let totaltables = Installation_Tables.length
              Installation_Tables.map((preppedtable,tableindex)=> {
               let tableobj = Object.assign({},preppedtable)
                  this.ExtractPlugin.Installation_Tables[tableindex] = {id: tableobj.id,DisplayName: tableobj.DisplayName}
                  //console.log(tableobj.id)
                  let subcols = ['AllFields','SubCollections','EntityProcesses','DigitizedForms','DocumentRegisters','Galleries','automations']
                  let finaltable = Object.assign({},tableobj)
                  delete finaltable.id
                  subcols.map(prop => {
                    delete finaltable[prop]
                  })
                  finaltable.Creating_Plugin = {Name: this.ExtractPlugin.Name, id: pluginid}
                  finaltable.Creating_Pluginid = pluginid
                  finaltable.Template_System = {Name: this.DynamicRecordData.Name,id: this.$route.params.id}
                  finaltable.Template_Systemid = this.$route.params.id
                  finaltable.Created_On = new Date()
                  finaltable.Modified_On = new Date()
                  finaltable.Created_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                  finaltable.Created_Byid = vm.userLoggedIn.id,
                  finaltable.Modified_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                  finaltable.Modified_Byid = vm.userLoggedIn.id,
                  //console.log(tableobj,finaltable,db.collection('entities').doc(tableobj.id))
                  db.collection('entities').doc(tableobj.id).set(finaltable).then(newdoc => {
                    let subcolentries = subcols.map(subcol => {
                      //console.log(subcol,tableobj[subcol])
                      return tableobj[subcol]
                    }).flat()
                    //console.log(tableobj.id,subcolentries)
                    let totalsubcolentries = subcolentries.length
                    let entrycounter = 0
                    subcols.map((subcol,subcolindex) => {
                      
                      tableobj[subcol].map(subcolentry => {
                        entrycounter = entrycounter-1+2
                        db.collection('entities').doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(newsubcoldoc => {
                          if(tableindex-1+2 === totaltables && entrycounter === totalsubcolentries){
                            db.collection('plugins').doc(pluginid).set(this.ExtractPlugin)
                            console.log(this.ExtractPlugin)
                          }
                        })
                      })
                    })
                  })
                
                })
        })
        })
        
        //console.log(this.ExtractPlugin)
      },
      PreparePluginTableLookups(Installation_Tables){
        console.log(Installation_Tables)
        let vm = this
        return new Promise(function(resolve, reject) {
          Installation_Tables.map((table,tableindex)=> {
            let lookupfields = table.AllFields.filter(field => {
              return field.Type === 'Lookup'
            })
            lookupfields.map(lufield => {
              let entity = Installation_Tables.find(obj => obj.Templateid === lufield.RelatedBuildID)
              if(entity && !entity.System_Required){
                //while there is a default template for each entity, if the entity is "System_Reqruied" the id, Name, and sec strucure will be readonly
                  //therefore no need to change the id either.
                  //we could have separated Icon. Sec Structure, Name, id, and primary field. but system_reuired truly means all of that
               lufield.RelatedBuildID = entity.id 
              }              
            })
            let lusubcols = table.SubCollections.filter(subcol => {
              return subcol.LookupBuilds
            })
            lusubcols.map(subcol => {
              subcol.LookupBuilds.map(lubuild => {
                let entity = Installation_Tables.find(obj => obj.Templateid === lubuild.id)
                if(entity && !entity.System_Required){
                  //while there is a default template for each entity, if the entity is "System_Reqruied" the id, Name, and sec strucure will be readonly
                  //therefore no need to change the id either.
                  lubuild.id = entity.id
                }                
              })
            })
            if(tableindex-1+2 === Installation_Tables.length){
              //console.log(Installation_Tables)
              resolve(Installation_Tables)
            }
          })
        })
      },
      PreparePluginEntities(){
         let vm = this
         let tables = JSON.parse(JSON.stringify(vm.ExtractPlugin.Installation_Tables))
         let totaltables = tables.length
          // console.log('calling props')
          return new Promise(function(resolve, reject) {
            tables.map((table,tableindex)=> {
                //console.log(table.id)
                //let preptable = Object.assign({},table)
                vm.GetAllEntityProps(db.collection('systems').doc(vm.$route.params.id).collection('entities'),table).then(tableobj => {
                  //console.log(tableobj.id)
                  let ref = db.collection('entities').doc()
                  // if(!tableobj.System_Required){
                    //we must decide if templates for "system required" can differ because if they can, they need new id
                    tableobj.Templateid = tableobj.id
                    tableobj.id = ref.id
                  // }                  
                  if(tableindex-1+2 === totaltables){
                    //['AllFields','SubCollections']
                    resolve(tables)
                  }
                })
            })
          })
      },
      ActivateExtractPluginDialog(){
        let headers = []
        let pluginent = this.SystemEntities.find(obj => obj.id === 'Plugins')
        let subcols = this.DynamicRecordData.SubCollections
        let entitysubcol = subcols.find(obj => obj.id === 'entities')
        //console.log(this.SystemEntities,pluginent)
        if(pluginent && pluginent.AllFields){
          
          headers = pluginent.AllFields.filter(header => {
            return header.IsHeader || header.id === 'Installation_Tables'
          })
          .map(field => {
            if(field.id === 'Installation_Tables' && entitysubcol){
              field.Array = entitysubcol.Data
            }
            return field
          }).map(field => {
            let fieldobj = Object.assign({},field)
            return fieldobj
          })
        }
        this.PluginHeaders = headers
        this.ExtractPluginDialog = true
        console.log(this.ExtractPlugin)
      },
      CancelExtractPluginDialog(){
        this.ExtractPluginDialog = false
        this.ExtractPlugin = {}
        console.log(this.ExtractPlugin)
      },
      UploadFileSelectPOP(file,field,Record){
        //console.log(file,field,Record)
        Record[field.Name] = {UploadFile: file, FileName: file.name}
        },
      UploadPOP(){
        let vm = this
        //SelectedPOPFile
        //paymentreceipt.Receiving_Document.UploadFile
        vm.$store.commit('setCustomProcessingDialog',true)
        vm.$store.commit('setCustomProcessingDialogText','Uploading POP')
        let file = vm.paymentreceipt.SelectedPOPFile.UploadFile
        let storagelocation = 'Sales_Invoices/'+vm.RecordData.id+'/Payments/'+file.name+new Date()
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(file);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Capturing Details')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: file.name,
                            filename: file.name
                          }
                          this.ProcessInvoicePayment(PDF_File)
                        })
      },
      ProcessInvoicePayment(PDF_File){
        this.$store.commit('setCustomProcessingDialogText','Updating Invoice')
        //PaidinFull
        db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Sales_Invoices').collection('Records').doc(this.RecordData.id).update({
          Amount_Paid: Number(this.paymentreceipt.Amount_Paid),
          Proof_of_Payment: PDF_File,
          Progress: {ID: 1000004, Name: 'Paid'}
        }).then(quoteupdatedoc => {
          this.CloseInvoicePaymentDialog()
          this.$store.commit('setCustomProcessingDialog',false)
        })
      },
      ActivateInvoicePaymentDialog(){
        this.paymentreceipt.Amount_Paid = Number(this.RecordData.Grand_Total.toFixed(2))
        //console.log(this.paymentreceipt)
        this.InvoicePaymentDialog = true
      },
      CloseInvoicePaymentDialog(){
        this.InvoicePaymentDialog = false
        this.paymentreceipt = {
            Amount_Paid: 0,
            SelectedPOPFile: {}
          }
      },
      IsAccountingClerk(accountingclerkprop,BUid){
        return this.userLoggedIn.BusinessAccounting && BUid && this.userLoggedIn.BusinessAccounting[BUid] && this.userLoggedIn.BusinessAccounting[BUid][accountingclerkprop]
      },
      SaveNewClientcomms(NewComms,record){
        //  commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
             // db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id)
             //nope!!! hehehehe. Uhm, topic now, and whatever else now...uhm...
         let clientrecordobj = {
                name: record.id,
                topicid: NewComms.topicid,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Clientid: record.Userid
            }  
          if(!this.HasClientRecord){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id).set(clientrecordobj) 
          }
         
        let commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
        //console.log(commscol)
        let owneruser = record.User
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Reminder for payment on '+record.id,
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record.id,
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
      SendInvoiceonCommsChannel(){
        let vm = this
        let record = vm.RecordData.BillingAccount
        console.log(record)
        let NewComms = {
          description: 'Please see the Invoice '+vm.RecordData.id+' for your Account '+record.id,
          Created_On: new Date(),   
          Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
          Created_Byid: vm.userLoggedIn.id,
          senderid: vm.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: 'Billing Accounts',
          recordlocation: '/BillingAccount/'+record.id,
          topicid: 'Billing_Accounts',
        }
        let PDF_File = vm.RecordData.Latest_PDF_File
        NewComms.StorageRef = PDF_File.StorageRef
        NewComms.newdocid = vm.RecordData.Invoice_Number
        NewComms.FileType = PDF_File.FileType
        NewComms.lastModifiedDate = PDF_File.ModifiedDate
        NewComms.fileurl = PDF_File.fileurl
        NewComms.Uploadfilename = vm.RecordData.Invoice_Number
        vm.SaveNewClientcomms(NewComms,record)
       // vm.$store.commit('setCustomProcessingDialog',false)
        setTimeout(() => {
          // /WarehouseTransaction/Z8WSyTNan523HzF3GOWk/INV-00000002?TransactionType=DispatchInstructions
            //vm.$router.push('/DispatchInstruction/'+vm.SelectedWarehouse.id+'/'+NewPOObject.Invoice_Number)
          }, 500)
      },
      
      SaveNewLibrary(){
        this.NewLibrary.Collection = this.NewLibrary.DisplayName.split(' ').join('').toLowerCase()
        this.NewLibrary.SinglePath = '/'+this.NewLibrary.SingleName.split(' ').join('').toLowerCase()
        let col = ''
        if(this.NewLibrary.Type === 'Photo Library'){
          col = 'PhotoLibraries'
        }
          db.collection('SystemConfig').doc(this.System.id).collection(col).doc(this.NewLibrary.DisplayName.split(' ').join('_')).set(this.NewLibrary).then(doc => {
            this.DeactivateNewLibraryDialog()
          })
      },
      DeactivateNewLibraryDialog(){
        this.NewLibraryDialog = false
        this.NewLibrary = Object.assign({},this.DefaultNewLibrary)
      },
      ToggleNewPhotoLibraryDialog(type){
        this.NewLibrary.Type = type
        this.NewLibraryDialog = !this.NewLibraryDialog
      },
      ViewActivities(){
        this.$emit('ViewActivities')
      },
      SetActivityRegarding(type){
     
      this.$emit('SetActivityRegarding',type)
    },
      ToggleTimesheetlistdialog(){
        //alert('booh')
        this.$emit('ToggleTimesheetlistdialog')
      },
      ToggleTimesheetDialog(){
        //alert('booh')
        this.$emit('ToggleTimesheetDialog')
      },
      ActivateLiaisonDialog(){
        //alert('booh')
        this.$emit('ActivateLiaisonDialog')
      },
      ActivateLiaisonTopicDialog(){
        this.LiaisonTopicDialog = true
      },
      SelectBottomNavMenuItem(item){
        //console.log(item)
        if(item.OnePropEmit){
          this.$emit('OnePropEmit',item.Method,item.Prop)
        }
        else if(item.Prop){
          this[item.Method](item.Prop)
        }
        else if(item.IsRoute){
          this.$router.push(item.Path)
        }
        else{
          this[item.Method]()
        }
        },
      CancelSystemCurrencyDialog(){
        this.SystemCurrencyDialog = false
        this.SystemDefaultCurrency = Object.assign({},this.$store.state.DefaultCurrency)
      },
      UpdateSystemCurrency(){
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
          DefaultCurrency: this.SystemDefaultCurrency
        })
        this.CancelSystemCurrencyDialog()
      },
      PrepareAutomations(){
            //console.log('PrepareAutomations',this.ActiveSession)
            let array = JSON.parse(JSON.stringify(this.ActiveSession.automations))
            let automationswithconditionalbuttons = array.map(auto => {
                auto.Conditions = auto.Conditions.filter(cond => {
                        return cond.CallfromButton
                    })
                return auto
            })
            .filter(auto => {
                return auto.Conditions.length > 0
            })
            //console.log('PrepareAutomations automationswithconditionalbuttons',automationswithconditionalbuttons)
            this.AutomationButtons = automationswithconditionalbuttons.map(auto => {
                return auto.Conditions.map(cond => {
                    let obj = {}
                        obj.ButtonName = cond.ApprovalButtonText,
                        obj.Steps = cond.Steps
                        obj.ResultActions = []
                        cond.ResultActions.map(act => {
                            let matchobj = auto.AutomationActions.find(obj => obj.MethodID === act.MethodID)
                            //console.log('PrepareAutomations ',act,matchobj.Record.id,this.SystemEntities)
                            if(matchobj){
                                let temprecord = matchobj.Record
                                matchobj.Record = this.SystemEntities.find(obj => obj.id === matchobj.Record.id)
                                matchobj.Record.Relationship = temprecord.Relationship
                                matchobj.Record.collection = matchobj.Record.id.split('_').join('').toLowerCase()
                                if(temprecord.RelationshipField){
                                  matchobj.Record.RelationshipField = temprecord.RelationshipField  
                                }
                                obj.ResultActions.push(matchobj)
                            }
                        })
                        return obj
                    })
                    //the key to minimal intervention is here. possibly specifically in resultactions. 
                    //we need a 2 step window. step 1 = conditions met or unmet. Step 2 being, the dialog
                    //but seeing as multiple actions could apply I think the actions you want is the key
                    //aha!!! we render buttons on datarecord right? Well, we need to render the data, do the comparisons and validations and everything within there,
                    //pusht he "buton" as a prop to the data. That's what we should do. 
               
            }).flat()
            //console.log('PrepareAutomations AutomationButtons',this.AutomationButtons)
        },
      ToggleAutomationDialog(){
            if(!this.AutomationDialog){
              this.AutomationRecord = Object.assign({},this.DynamicWikiData)
              //console.log(this.AutomationRecord,this.AutomationButtons)
              this.AutomationRecord.AutomationButtons = []
            //console.log(record,this.AutomationButtons)   
                this.AutomationButtons.map((auto,index) => {
                    this.AutomationRecord.AutomationButtons.push(auto)
                    if(index-1+2 === this.AutomationButtons.length){
                      this.AutomationDialog = true
                    } 
                })      
            }
            else{
              this.AutomationDialog = false
              this.AutomationRecord = {}
            }
            
        },
      ToggleWorkflowBuilderDialog(){
            this.DeactivatePipelineworkflowdialog()
        },
        ActivatePipelineworkflowdialog(){
            this.WokrflowBuilderDialog = true
        },
        DeactivatePipelineworkflowdialog(){
            this.WokrflowBuilderDialog = false
        },
      GetShortLivedURL(fileobject){
        this.$emit('GetShortLivedURL',fileobject)
      },
      UploadFileSelect(file,field,Record){
      //console.log(file,field,Record,this.BookingHeaders)
      field.UploadFile = file
      field.FileName = file.name
      let uploadfileobj = {
        id: field.value,
        UploadFile: file,
        FieldObj: field
      }
      let oncheck = Record.UploadFiles.find( obj => obj.id === uploadfileobj.id)
      if(!oncheck){
        Record.UploadFiles.push(uploadfileobj)
      }
      else{
        let index = Record.UploadFiles.indexOf(oncheck)
        Record.UploadFiles[index] = Object.assign({},uploadfileobj)
      }
      
    },
      UpdateBookedUser(user){
        //console.log(this.EntityDataRef,user,this.BookingHeaders)
        this.BookingHeaders.map(header => {
          if(header.Response){
              this.EntityDataRef.collection('BookedUsers').doc(user.id).update({
                [header.value]: header.Response,
                Modified_On: new Date(),
                Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Modified_Byid:  this.userLoggedIn.id, 
          })    
          }  
        })
      },
      ActivateBookedUserDialog(user){
        this.BookedUsersDialog = false
        this.BookedUser = user
        this.BookingHeaders = JSON.parse(JSON.stringify(this.BookingDialogHeaders))
        this.BookingDialog = true
      },
      DeactivateBookedUserDialog(){
        this.BookingHeaders = []
        this.BookingDialog = false
        this.BookedUser = ''
        this.BookedUsersDialog = true
      },
      UpdateClientLiaisonPortal(){
        //console.log(this.EntityCollectionRef,this.CurrentEntity)
        
        if(typeof this.CurrentEntity.HasLiason !== 'undefined'){
          this.EntityCollectionRef.update({
            HasLiason: this.CurrentEntity.HasLiason
          })
          let clienttopic = {
              id: this.CurrentEntity.id,
              name: this.CurrentEntity.LiaisonTopicName,
              icon: this.CurrentEntity.LiasonTopicIcon,
              ClientLookupField: this.CurrentEntity.ClientLookupField
            }
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('LiaisonTopics').doc(clienttopic.id).set(clienttopic).then(res => {
              //console.log('yay we added topic')
            }) 
        }
        if(typeof this.CurrentEntity.LiaisonTopicName !== 'undefined'){
          this.EntityCollectionRef.update({
            LiaisonTopicName: this.CurrentEntity.LiaisonTopicName
          })
        }
        if(typeof this.CurrentEntity.LiasonTopicIcon !== 'undefined'){
          this.EntityCollectionRef.update({
            LiasonTopicIcon: this.CurrentEntity.LiasonTopicIcon
          })
        }
        if(typeof this.CurrentEntity.ClientLookupField !== 'undefined'){
          this.EntityCollectionRef.update({
            ClientLookupField: this.CurrentEntity.ClientLookupField
          })
        }
        this.LiaisonTopicDialog = false
      },
        ReplicateSystemConfig(){
          if(this.$route.meta.RecordisSystem){
            //update Ra ConfigMaster for future project replications
            this.UpdateRASystemConfig()
          }
          else{
            //only update client SystemConfig/ConfigMaster and leave client SystemConfig/process.env.VUE_APP_RA_SYSTEM_ID unaffected
            this.UpdateClientConfigMaster()
          }
          
        },
        UpdateClientConfigMaster(){
          let systemsentity = this.SystemEntities.find(obj => obj.id === 'Systems')
          let entitiesentity = this.SystemEntities.find(obj => obj.id === 'Entities')
          this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').update({
            SingleBuildTabs: systemsentity.SingleBuildTabs
          })
          systemsentity.AllFields.map(field => {
            this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('AllFields').doc(field.id).set(field).then(fielddoc => {
              //console.log('update field '+field.Name)
            })
          })
          this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities').update({
            SingleBuildTabs: entitiesentity.SingleBuildTabs
          })
          entitiesentity.AllFields.map(field => {
            this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities').collection('AllFields').doc(field.id).set(field).then(fielddoc => {
              //console.log('update field '+field.Name)
            })
          })
          this.CopyAutoNumbers()
          this.CopyPDFTemplates()
          this.CopyAccountingLedger()
        },
        CopyAccountingLedger(){
          let collection = ['General','Sales_Invoices','Sales_Quotes']
                collection.map(col => {
                    let docpath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Ledgers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection('Ledgers').doc(docid).set(docdata)
                    })
                })
        },
        CopyPDFTemplates(){
             let collection = ['Purchase_Orders','Sales_Invoices','Sales_Quotes']
                collection.map(col => {
                    let docpath = db.collection('SystemConfig').doc('ConfigMaster').collection('pdfexportfunctions').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        this.clientDB.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc(docid).set(docdata)
                    })
                })
        },
        CopyAutoNumbers(){
             let autonumberscollection = ['StoreOrderEntityDefault','StoreSupplierEntityDefault','User_Companies_Company_ID',
                 'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number','GoodsReceivingVouchers_GRV_Number','PackingLists_Number','Payment_Invoices_Invoice_Number',
                 'PickingSlips_Number','PurchaseRequisitions_Number','Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number','Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
                autonumberscollection.map(col => {
                    let docpath = db.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        docdata.LastNumbers = []
                        docdata.Last_Number = 0
                        docdata.Last_Value = 0
                        let docid = col
                        this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(docid).set(docdata)
                        if(!docdata.IsEntityDefault){
                          this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection('AutoNumbers').doc(docid).get().then(autonrsnap => {
                            let autodoc = autonrsnap.data()
                            if(!autodoc){
                              this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection('AutoNumbers').doc(docid).set(docdata)
                            }
                          })
                        }
                    })
                })
        },
        UpdateRASystemConfig(){
          //console.log(this.ConfigSystemEntities)
          let systemsentity = this.SystemEntities.find(obj => obj.id === 'Systems')
          let entitiesentity = this.SystemEntities.find(obj => obj.id === 'Entities')
          //console.log(db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems'),systemsentity.SingleBuildTabs,db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('AllFields'),systemsentity.AllFields)
          db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').update({
            SingleBuildTabs: systemsentity.SingleBuildTabs
          })
          systemsentity.AllFields.map(field => {
            db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('AllFields').doc(field.id).set(field).then(fielddoc => {
              //console.log('update field '+field.Name)
            })
          })
          //console.log(db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities'),entitiesentity.SingleBuildTabs,db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities').collection('AllFields'),entitiesentity.AllFields)
          db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities').update({
            SingleBuildTabs: entitiesentity.SingleBuildTabs
          })
          entitiesentity.AllFields.map(field => {
            db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities').collection('AllFields').doc(field.id).set(field).then(fielddoc => {
              //console.log('update field '+field.Name)
            })
          })
        },
        AuthenticateClientApp(){
            this.$emit('AuthenticateClientApp')
        },
        NoPropEmit(Method){
            this.$emit('NoPropEmit',Method)
        },
        OnePropEmit(Method,Prop){
            this.$emit('OnePropEmit',Method,Prop)
        }
    }
}
</script>

<style>

</style>
