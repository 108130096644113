<template>
  <div>
   <!-- This needs work but yeah
     <v-layout row class="justify-center">
    <v-flex class="xl2 lg2 md3 sm4 xs6" v-for="(tab,i) in ComputedMenuItems" :key="tab.itemObjKey">
    <CardRowBootstrap  :PageHeadersFont="'Anton'" :PageContentFont="'Montserrat'" :System="System"									
    :PageHeadersFontSize="32" :tabindex="tabindex" :rowindex="rowindex"									
    :tab="tab" :i="i" :PageID="PageID" :PageName="'App'" :Small="true"		
    @OpenDialog="OpenDialog" @ActionButtonOpenDialog="ActionButtonOpenDialog"
    />
    </v-flex>
   </v-layout> -->
    <SingleDashboard :ActiveSections="ComputedTabSections"
        :System="System" :SystemEntities="SystemEntities" :AllFields=[]
        :MyActivitiesArray="MyActivitiesArray" :UnreadNotifications="UnreadNotifications"

        />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SystemActivitiesFeed from '@/components/SocialNetwork/SystemActivitiesFeed';
import NoticeboardFeed from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeboardFeed';
import MyTeamSchedule from '@/components/SuitePlugins/Activities/MyTeamSchedule'
import CardRowBootstrap from '@/components/WebPages/RenderComponents/CardRow/CardRowBootstrapReadOnly';
import SingleDashboard from '@/components/Dashboards/SingleDashboard'
export default {
    props: ['System','SystemEntities','MyActivitiesArray','UnreadNotifications'],
    components: {SystemActivitiesFeed,NoticeboardFeed,MyTeamSchedule,CardRowBootstrap,
    SingleDashboard},
    data() {
        return {
            DefBootCard: {	
                    IMG: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fweb-developer-vector-illustration-5de192c700231.png?alt=media&token=1dc9a48c-aaf4-473b-87c0-9f7ef84b827f',
                    HasImage: true,								
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Card Row Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,	
                Elevation: 2,								
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Type',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet.</div>`,									
                FlexXLRG: 3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,									
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,									
                									
                									
            },	
            DefaultTab: {
                Sections: [
                    {
                    Flat: true,
                    Transparent: true,
                    Tile: true,
                    Elevation: 0,
                    FlexXLRG:9,									
                    FlexLarge: 9,									
                    FlexMedium: 8,									
                    FlexSmall: 12,									
                    FlexXSmall: 12,
                    Rows: [
                        {
                        Name: 'Counters',
                        Flat: false,
                        Transparent: false,
                        Elevation: 4,Rounded: true,
                        Columns: [
                            {
                                
                                Icon: 'mdi-tag-faces',
                                Name: 'Total Users',
                                Type: 'Counter',
                                Color: '#FF000088'
                            },
                            {
                                Icon: 'mdi-tag-faces',
                                Name: 'Total Users',
                                Type: 'Counter',
                                Color: '#00FF0088'
                            },
                            {
                                Icon: 'mdi-tag-faces',
                                Name: 'Total Users',
                                Type: 'Counter',
                                Color: '#FF000088'
                            },
                             {
                                Icon: 'mdi-tag-faces',
                                Name: 'Total Users',
                                Type: 'Counter',
                                Color: '#FF000088'
                            },
                        ]
                        },
                        {
                        Pluginid: 'Activities_and_Team',
                        Name: 'Tasks',
                        Flat: false,
                        Transparent: false,
                        Elevation: 4,Rounded: true,
                        Columns: [
                            {
                                FlexXLRG:12,									
                                FlexLarge: 12,									
                                FlexMedium: 12,									
                                FlexSmall: 12,									
                                FlexXSmall: 12,
                                Name: 'Team Activities',
                                Graph: {
                                    Name: 'Team Activities',
                                    ComponentPath: 'components/SuitePlugins/Activities/ActivitiesSummary',
                                    Color: 'orange'
                                },
                                Type: 'Bar Graph',
                                // ComponentPath: 'components/SuitePlugins/Activities/ActivitiesSummary',
                                //     Type: 'Bar Graph',
                                //     Color: 'orange'
                            },
                            // {
                            //     FlexXLRG:3,									
                            //     FlexLarge: 3,									
                            //     FlexMedium: 12,									
                            //     FlexSmall: 12,									
                            //     FlexXSmall: 12,
                            //     Name: 'Team Activities',
                            //     ComponentPath: 'components/SuitePlugins/Activities/ActivitiesSummary',
                            //     Type: 'Progress Card',
                            //     Color: '#FF0000BB'
                            // }
                        ]
                        },
                        
                         {
                        Name: 'Database',
                        Columns: [
                            {
                                FlexXLRG:12,									
                                FlexLarge: 12,									
                                FlexMedium: 12,									
                                FlexSmall: 12,									
                                FlexXSmall: 12,
                                Type: 'Table',
                                Name: 'Database'
                            }
                        ]
                         },
                    ]
                    },
                    {
                    Flat: true,
                    Transparent: false,
                    Elevation: 0,
                    FlexXLRG:3,									
                    FlexLarge: 3,									
                    FlexMedium: 4,									
                    FlexSmall: 12,									
                    FlexXSmall: 12,
                    Rows: [
                        {
                        Name: 'Appointments', 
                        Flat: true,
                        Transparent: true,
                        Elevation: 4,
                        Columns: [
                            {
                                Name: 'Team Appointments',
                                Type: 'Calendar',
                                Calendar: {
                                    ComponentPath: 'components/SuitePlugins/Activities/MyTeamSchedule',
                                }
                                
                            }
                        ]
                        },
                        {
                        Name: 'Notices',
                        Columns: [
                            {
                                Name: 'Notices',
                                Type: 'Feed',
                                Feed: 'System Activities',
                            }
                        ]
                        }
                    ]
                    }
                ]
            },
            Totals: [
                {title: 'Total Users',icon :'mdi-tag-faces',Color: 'red'},
                {title: 'Totals 2',icon: 'mdi-help',Color: 'orange'},
                {title: 'Totals 3',icon: 'mdi-help',Color: 'blue'},
                {title: 'Totals 4',icon: 'mdi-help',Color: 'green'}
            ]
        }
    },	
    computed:{
        ComputedMenuItems(){
            return this.ActiveSuiteMenuItems.map(item => {
                let tab = Object.assign({},this.DefBootCard)
                tab.CardIcon = item.icon
                tab.Title = item.title
                tab.Type === 'Bootstrap Card'
                delete tab.Description
                return tab
            })
        },
        ActiveSuiteMenuItems(){
            return this.$store.state.ActiveSuiteMenuItems
        },
        ComputedTabSections(){
            return this.DefaultTab.Sections.filter(sect => {
                return !sect.Pluginid || sect.Pluginid && this.AppPluginIDs.includes(sect.Pluginid)
                // && this.AppPlugins.find(obj => obj.id === sect.Pluginid)
            }).map(sect => {
                sect.Rows = sect.Rows.filter(row => {
                    return !row.Pluginid || row.Pluginid && this.AppPluginIDs.includes(row.Pluginid)
                }).map(row => {
                    row.Columns = row.Columns.filter(col => {
                        return !col.Pluginid || col.Pluginid && this.AppPluginIDs.includes(col.Pluginid)
                    }).map(col => {
                        if(col.Type === 'Table'){
                            col.EntityID = this.ActiveSuiteApp.AppDefaultTable
                        }
                        return col
                    }).filter(col => {
                        return col.Type !== 'Table' || col.Type === 'Table' && col.EntityID
                    })
                    return row
                })
                return sect
            })
        },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        AppPluginIDs(){
            return this.AppPlugins.map(plug => {
                return plug.id
            })
        },
        AppPlugins(){
            return this.ActiveSuiteApp && this.ActiveSuiteApp.Plugins ? this.ActiveSuiteApp.Plugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            }) : []
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



