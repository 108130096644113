<template>
  <v-card tile flat height="100%" width="100%" :style="ActiveAccount ? '' : 'margin-top:-60px;'"> 
      <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[POPDFFunctionData]"		:PassedFunction="POPDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
        
        <v-dialog v-model="NewAccountDialog" max-width="300px">
            <v-card flat max-width="600px" min-width="300px" :style="MiniView">
                <v-card-title>Select Account Type</v-card-title>
                <v-card-text>
                    <v-select @change="SelectRelevantDialog(BillingAccount.Account_Type)" :items="FilteredBATypes" label="Type" return-object item-text="Name" v-model="BillingAccount.Account_Type"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="DeactivateAccountSelectDialog()" dark color="warning">Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="NewLoanAccountDialog" max-width="300px">
            <v-card flat max-width="600px" min-width="300px" :style="MiniView">
                <v-card-title>Select Account Type</v-card-title>
                <v-card-text>
                   <v-text-field label="Reference" v-model="BillingAccount.Client_Reference"
                   />
                </v-card-text>
                 <v-card-actions>
                    <v-btn @click="DeactivateNewLoanAccountDialog()" dark color="warning">Cancel
                    </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="SaveNewLoanAccount()" dark color="success">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      <v-dialog persistent v-model="GroupAccountDialog" :max-width="DirectoryListing ? 650 : 400">
         <v-card flat :max-width="DirectoryListing ? 650 : 400" min-width="300px" :style="MiniView">
                <v-form style="padding:0px;">
                <v-card-title class="purple white--text"> <v-avatar
                            color="lime" 
                            class="subheading purple--text"
                            size="24"
                            v-text="step"
                        ></v-avatar>
                        <span style="padding-left: 10px;">{{GroupDialTitle}}
                        </span>
                       
                    <v-spacer></v-spacer>
                    <v-icon dark @click="DeactivateGroupAccountDialog()" class="red soloactionicon">
                        mdi-close
                    </v-icon>
                </v-card-title>
                    <v-card
                class="mx-auto"
                :max-width="DirectoryListing ? 650 : 400"
            >
                <v-card-title class="text-h6 font-weight-regular justify-space-between">
                        <span>{{ currentTitle }}</span>
                        </v-card-title>
                      <v-row class="justify-center">
                         <v-col cols="12" sm="12" md="6" v-if="GroupsDirectoryEntity && DirectoryListing" style="min-height: 400px;">
                            <!-- </v-tab-item>
                            <v-tab v-if="GroupsDirectoryEntity">Preview
                            </v-tab>
                            <v-tab-item v-if="GroupsDirectoryEntity"> -->
                                
                                <!-- {{GroupsDirectoryEntity}} -->
                                <ClassifiedListingListItem v-if="ComputedGroupListing" :TaggedFields="GroupsDirectoryEntity.TaggedFields" :TagField="GroupsDirectoryEntity.TagField" :CurrentEntity="GroupsDirectoryEntity"
                       :HidePrice="GroupsDirectoryEntity.HidePrice" :HideTitle="GroupsDirectoryEntity.HideTitle" :HideDescription="GroupsDirectoryEntity.HideDescription" @PushActiveProduct="PushActiveProduct"
                       :item="ComputedGroupListing" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                       :FeatureList="FeatureList" :CompareItems="CompareItems" :IMGIsCarousel="GroupsDirectoryEntity.IMGIsCarousel" :CartItems="CartItems"
                       :FlexXLRG="FlexXLRG" :FlexLarge="FlexLarge" :FlexMedium="FlexMedium" :FlexSmall="FlexSmall" :FlexXSmall="FlexXSmall" style="max-width: 250px;"	
                       />
                            <!-- </v-tab-item>
                        </v-tabs> -->
                        </v-col>
                        <v-col  cols="12" sm="12" :md="DirectoryListing ? 5 : 12"> 
                        <v-window v-model="step">
                        <v-window-item :value="1">
                            <v-card-text>
                            <FieldsForm :FormFields="AccountFields" :Validating="Step1Validating" :DataTableView="false" @save="ProgressWindow"
                            @UploadFileSelect="UploadFileSelect" :DataObject="BillingAccount" :tab="{}" />
                            </v-card-text>
                        </v-window-item>
                        <v-window-item :value="2">
                            <v-card-text >
                                
                           <FieldsForm :FormFields="GroupAddressFields" :Validating="Step2Validating" :DataTableView="false" @save="ProgressWindow"
                           @UploadFileSelect="UploadFileSelect" :DataObject="NewGroup" :tab="{}" />
                          
                                </v-card-text>
                        </v-window-item>
                        <v-window-item :value="3">
                            <v-card-text >
                                 <!-- Here Group details (group header info) -->
                           <FieldsForm :FormFields="GroupFieldsOne" :Validating="Step3Validating" :DataTableView="false" @save="ProgressWindow"
                           @UploadFileSelect="UploadFileSelect" :DataObject="NewGroup" :tab="{}" />
                          
                                </v-card-text>
                        </v-window-item>
                         <v-window-item :value="4">
                            <v-card-text >
                                 <!-- Here Group details (group header info) -->
                           <FieldsForm :FormFields="GroupFieldsTwo" :Validating="Step4Validating" :DataTableView="false" @save="ProgressWindow"
                           @UploadFileSelect="UploadFileSelect" :DataObject="NewGroup" :tab="{}" />
                          
                                </v-card-text>
                        </v-window-item>
                        <v-window-item :value="5">
                            <v-card-text>
                            <!-- What features would you like on the Group? -->
                            <FieldsForm :FormFields="GroupFeatureFields" :Validating="Step5Validating" :DataTableView="false" @save="ProgressWindow"
                            @UploadFileSelect="UploadFileSelect" :DataObject="NewGroupFeaturesConfig" :tab="{}" />
                            </v-card-text>
                        </v-window-item>
                        <v-window-item :value="6">
                            <v-card-text>
                            <!-- What Content would you need on the Group? -->
                            <FieldsForm :FormFields="GroupScopeFields" :Validating="Step6Validating" :DataTableView="false" @save="ProgressWindow"
                            @UploadFileSelect="UploadFileSelect" :DataObject="NewGroup" :tab="{}" />
                            </v-card-text>
                        </v-window-item>
                        <v-window-item :value="7">
                            <v-card-text>
                                <v-list class="transparent">
                                    You have made the following Selections:
                                    <v-list-item class="overline">
                                        Selected Scope:
                                    </v-list-item>
                                    <v-list-item v-for="scope in NewGroupScope" :key="scope.itemObjKey">
                                        <v-list-item-content>
                                        {{scope}}
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox v-model="AlwaysTrue" readonly :label="NewGroupPaidScope.includes(scope) ? 'Is Paid' : 'FREE'"/>
                                        </v-list-item-action>
                                    </v-list-item>
                                     <v-list-item class="overline">
                                        Selected Features:
                                    </v-list-item>
                                    <v-list-item v-for="feat in NewGroupFeatures" :key="feat.itemObjKey">
                                        <v-list-item-content>
                                        {{feat}}
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox v-model="AlwaysTrue" readonly :label="NewGroupPaidFeatures.includes(feat) ? 'Is Paid' : 'FREE'"/>
                                        </v-list-item-action>
                                    </v-list-item>                                
                                </v-list>
                            <FieldsForm :FormFields="PackageFields" :Validating="Step7Validating" :DataTableView="false" @save="ProgressWindow"
                            @UploadFileSelect="UploadFileSelect" :DataObject="BillingAccount" :tab="{}" />
                            </v-card-text>
                        </v-window-item>
                        </v-window>
                        </v-col>
                       
                      </v-row>

                        <v-divider></v-divider>

                        <v-card-actions class="lime">
                        <v-btn
                            :disabled="step === 1"
                            text
                            @click="ClearValidateForm()"
                        >
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            :disabled="step === 8"
                            color="purple" dark
                            depressed
                            @click="ValidateForm()"
                        >
                            Next
                        </v-btn>
                        </v-card-actions>
                        <!-- <v-divider></v-divider>
                        <v-card-actions>
                        <v-layout class="justify-center">
                        <v-btn width="100%" dark class="accent" @click="$router.push('PhoneLogin')">Use Phone <v-icon>mdi-phone</v-icon></v-btn>                     
                        </v-layout>
                        <v-layout class="justify-center">
                        <v-btn width="100%" dark class="success" @click="$router.push('Login')">Back to Login <v-icon>mdi-lock-open</v-icon></v-btn>                     
                        </v-layout>  
                        </v-card-actions> -->
                    </v-card>
                </v-form>
            </v-card>
      </v-dialog>
       <v-dialog v-model="ViewFileDialog" fullscreen>
        <v-card tile class="white" flat>
          <v-btn v-if="!finalPDF_File" @click="CloseViewFileDialog()"  style="z-index:2;position: fixed;top: 60px;width:100%;" dark class="warning">Close</v-btn>
          <v-btn v-if="finalPDF_File" @click="ProcessNewInvoice(finalPDF_File)"  style="z-index:2;position: fixed;top: 60px;width:100%;" dark class="success" >Confirm Order</v-btn>
          <v-btn v-if="finalPDF_File" @click="CloseViewFileDialog()"  style="z-index:2;position: fixed;top: 100px;width:100%;" dark class="warning" >Change Details</v-btn>
          <embed v-if="ViewFileURL" :height="WindowHeight-25" :width="WindowWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="WindowHeight-25" :width="WindowWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>
      <v-snackbar top timeout="4000"
      v-model="snackbar"
    >
    {{snackbartext}}
      </v-snackbar>
       <v-layout class="justify-center background">
        <div style="padding-top: 20px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
            <div v-if="!ActiveAccount">
            <v-card-title class="justify-start mediumoverline" style="padding-bottom: 0px;">
                <v-icon size="30">mdi-bank</v-icon> My {{SiteAccountsOnly ? 'Site Accounts' : 'Accounts'}}<v-spacer>
                    </v-spacer>
                <v-btn outlined v-if="FilteredBATypes.length > 0" @click="CheckNewAccountDialog()">Add {{SiteAccountsOnly ? 'Site' : 'Account'}}
                    <v-icon>mdi-plus</v-icon>
                            </v-btn>
            </v-card-title>
        </v-btn>
        <v-btn outlined v-if="GroupAccountsActive" @click="ActivateGroupAccountDialog()">Add Group Account</v-btn>
        <!-- <v-btn outlined v-if="SystemisRA" @click="ActivateSuiteAccountDialog()">Add Suite Account</v-btn> -->
        
        <!-- basically one more thing. If there is a group BA that does NOT have a Group...then higlight it -->
            <v-row class="justify-start" style="padding:20px;width: 100%;" v-if="!Activesession">
                <v-col style="flex-grow: 0!important;" v-for="account in ColorizedUserBillingAccounts" :key="account.itemObjKey">
                    <v-card elevation="4" :color="account.Color ? account.Color: 'red'"  @click="SelectActiveAccount(account)" :height="150" :width="150" tile dark>
                    <v-card-title class="justify-center" style="padding-bottom: 0px;">
                        <v-icon size="70">mdi-bank</v-icon>
                        {{account.id}}
                    </v-card-title>
                    <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                        {{account.Client_Reference}}
                    </v-card-title>
                    </v-card>
                </v-col>
                </v-row>
                
            </div>
    <!-- <v-list v-if="!ActiveAccount" style="margin-top: 60px;" width="100%">
        <v-list-item @click="SelectActiveAccount(account)" v-for="account in UserBillingAccounts" :key="account.itemObjKey">
            {{account.Client_Reference}}
        </v-list-item>
        <v-btn v-if="AllowedBATypes.length > 0" @click="ActivateAccountSelectDialog()">Add Account
        </v-btn>
        <v-btn v-if="GroupAccountsActive" @click="ActivateGroupAccountDialog()">Add Group Account</v-btn>
        <v-btn v-if="SystemisRA" @click="ActivateSuiteAccountDialog()">Add Suite Account</v-btn> -->
        <!-- Add Account, select type, but if MembGroup then types based on what memgroup allows...and the options avilale to admin is provided by computation of plugins etc.AllowedBATypes  -->
    <!-- </v-list> -->
    <ActiveBillingAccount v-if="ActiveAccount" @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="ClientStoreOrders"
          :SubscriptionPackages="SubscriptionPackages" :ActiveAccount="ActiveAccount" @DeactivateActiveAccountView="DeactivateActiveAccountView"
          :AvailableGroupSubscriptionPackages="AvailableGroupSubscriptionPackages" @ActivateGroupAccountDialog="ActivateGroupAccountDialog"
          @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />     
        </div>
       </v-layout>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import FieldsForm from '@/components/Database/Fields/FieldsForm';	
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
import ActiveBillingAccount from '@/components/Client/ActiveBillingAccount'
import ClassifiedListingListItem from '@/components/Directories/ClassifiedListingListItem';
export default {
    props: ['System','SystemEntities','ClientStoreOrders','SubscriptionPackages','Directories','TypeFilters','ActiveSession'],
    components: {FieldValueEditerComponent,FieldsForm,PDFExporter,ActiveBillingAccount,ClassifiedListingListItem},
    data() {
        return {
            TemplatePayload: '',
            ActiveTemplate: '',
            GroupsDirectoryEntity: null,
            NewLoanAccountDialog: false,
            NewAccountDialog: false,
        //    SelectedWarehouse: {
        //         Business_Unit: {
        //         Name: 'Head Office',
        //         id: 'Head_Office'
        //         },
        //         Business_Unitid: 'Head_Office'
        //     },
            Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Billing_Address: {Name: 'Billing_Address',FieldBreakdown: [],DisplayName: 'Billing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
            Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
            SOLineItems: [],
            NewInvoice_Number: '',
            finalgroupobj: '',
            finalacctobj: '',
            finalGroupMemberobj: '',
            finalPDF_File: '', 
            FinalReviewDialog: false,
            NEWSOInvoiceDialog: false,
            PDFDownloadOnly: false,
            ExportingtoPDFDialog: false,
            PDFExportFunctionData: '',
            AlwaysTrue: true,
            GroupPrivacyTypes: [
        { index: 1, Name: "Invite Only" },
        { index: 2, Name: "Non Guest Site Members" },
        { index: 3, Name: "All Site Members" },
        { index: 4, Name: "Public" },
      ],
            
            GroupAccountDialog: false,
            AccountFields: [
          {Name: 'Account_Name',DisplayName: 'Account Name',Type: 'Single Line Text', IsMandatory: true},
          {Name: 'Account_Email',DisplayName: 'Account Email',Type: 'Common Field',CommonFieldType: 'Email', IsMandatory: true},
          {Name: 'Account_Phone',DisplayName: 'Account Phone',Type: 'Common Field',CommonFieldType: 'Telephone Number', IsMandatory: true},
          {Name: 'Account_Website',DisplayName: 'Account Website (Optional)',Type: 'Common Field',CommonFieldType: 'URL Link'},
          {Name: 'Client_Reference',DisplayName: 'My Reference',Type: 'Single Line Text', IsMandatory: true},
        ],
        BillingAccount: {},
        NewGroup: {},
        NewGroupFeaturesConfig: {},
        Validating: false,
        Step1Validating: false,
        Step2Validating: false,
        Step3Validating: false,
        Step4Validating: false,
        Step5Validating: false,
        Step6Validating: false,
        Step7Validating: false,
        ValidationSteps: {

        },
        step: 1,
        AccountTypesDials: [
            {ID: 1000001, Name: 'Store Account',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000002, Name: 'Group Account',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000003, Name: 'Suite Ownership',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000004, Name: 'App Membership',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000005, Name: 'Cash Loan Account',DialogMethod: 'ActivateLoanOrderAccountDialog'},
            {ID: 1000006, Name: 'Market Account',DialogMethod: 'ActivateMarketAccountDialog'},
        ],
        rules: {
          
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            ViewFileDialog: false,
      ViewFileURL: '',
      ViewIMGURL: '',
            ActiveAccount: '',
            snackbar: false,
            snackbartext: '',
            GroupScopeOptions: [
                {
                DisplayName: "Articles",
                Path: "/Articles",
                Boolean: true,
                },
                {
                DisplayName: "Blogs",
                Path: "/Blogs",
                Boolean: true,
                },
                {
                DisplayName: "Classifieds",
                Path: "/Classifieds",
                Boolean: true,
                },
                {
                DisplayName: "Events",
                Path: "/Events",
                Boolean: true,
                },
                {
                DisplayName: "Forums",
                Path: "/Forums",
                Boolean: true,
                },
                // {
                // DisplayName: 'Groups',
                // Path: '/Groups',
                // Boolean: true,
                // },
                {
                DisplayName: "Meetups",
                Path: "/Meetups",
                Boolean: true,
                },
                {
                DisplayName: "Polls",
                Path: "/Polls",
                Boolean: true,
                },
            ],
            GroupFeatures: [
                {Name: 'Web Page Builder',Tooltip: `<p>Web Page Builder allows you to build custom pages for your Group</p>`},
                // {Name: 'Keywords'},
                {Name: 'Web Forms',Tooltip: `<p>With Web Forms activated, you can add your own Web Forms to these pages.</p>`},
                {Name: 'Group Teams',Tooltip: `<p>Teams would allow you to assign enquiries and tasks to group members, that you marked as "Team Members". </p>`},
                {Name: 'Team Dashboard',Tooltip: `<p>Team Dashboard wouldgive Team Members access to the Group Dashboard.</p>`},
                {Name: 'Custom Entities',Tooltip: `<p>Custom Entities expands your Group with the ability toa dd custom data tables to it.</p>`},
                {Name: 'Custom Dashboards',Tooltip: `<p>Custom Dashboards would allow you to build dashboards for custom entities created for your Group.</p>`}
                ],
            newgroupid: '',
            GroupFields: [],
            GroupFeatureFields: [],
            GroupScopeFields: [],
            samplelogurl: '',
            RefreshingPreview: false,
            POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Group',propvalue: 'Group',value: 'Group.name',Type: 'Lookup',LookupFieldName: 'name',class: 'overline'},
              {text: 'Name',propvalue: 'Name',value: 'Name',Type: 'Single Line Text',class: 'overline'},
              {text: 'Description',propvalue: 'Description',value: 'Description',Type: 'Multiple Lines Text',IsContentEditable: true,class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},              
            ],
        }
    },
    computed:{
        AccountTypes(){
            return this.$store.state.AccountTypes
        },
        DirectoryListing(){
            return this.ActiveSession && this.ActiveSession.name === 'Org Directory'
        },
        ComputedGroupListing(){
            if(!this.RefreshingPreview){
            let obj = Object.assign({},this.NewGroup)
            //console.log('obj',obj,'this.samplelogurl',this.samplelogurl)
            if(obj.Logo && obj.Logo.UploadFile){
                //NewGroup.Logo.UploadFile
                    obj.Photo = {
                    ThumbURL: this.samplelogurl
                    }
                }
                if(obj.DefaultPage && obj.DefaultPage.Type === 'Group Page'){
                    obj.Path = '/Group-Page/'+obj.id+'/'+obj.DefaultPage.Name
                }
                else{
                    obj.Path = '/Group/'+obj.id
                }
                obj.Title = obj.name
                return obj
            }
        },
        UserMarketAccount(){
        return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
        },
        UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
        },
        SiteAccountsOnly(){
            return this.ActiveSession && this.ActiveSession.name === 'My Sites'
        },
        FilteredBATypes(){
            return this.AllowedBATypes.filter(type => {
                //console.log(type,this.TypeFilters)
                return this.TypeFilters ? this.TypeFilters.includes(type.Name) : type
            }).filter(type => {
                return this.UserMarketAccount ? type.Name !== 'Market Account' : type
            })
        },
        AllowedBATypes(){
            return this.ActiveMemberGroup && this.ActiveMemberGroup.AllowedBATypes ? this.AccountTypes.filter(type => {
                return this.ActiveMemberGroup.AllowedBATypes.includes(type.Name) || this.userIsAdmin
            }) : this.userIsAdmin ? this.AccountTypes : []
        },
        ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
        UserisGuest(){
            return this.userLoggedIn ? this.userLoggedIn.IsGuestUser : 'undefined'     
        },
        CanSocial(){
            return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
            this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
        },
        GroupAccountsActive(){
            //technically though, 1) is the plugin active I think Site Groups? 2) does the user have ActiveMemberGroup that include this BA Type "Group Account"? if no memgroup plugin suffice
            //and THEN yes 3) packages
            return this.GroupOwnershipPackages && this.GroupOwnershipPackages.length > 0
        },
        DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
        SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
        },
        GroupsPluginActive(){
            return this.PluginDataBase.Site_Groups && this.PluginDataBase.Site_Groups.Active
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        GroupDialTitle(){
            switch (this.step) {
            case 1: return 'Account Basics'
            case 2: return 'Invoice Address'
            case 3: return 'Group Headers'
            case 4: return 'Group Details'
            case 5: return 'Group Features'
            case 6: return 'Group Scope'
            case 7: return 'Select Package'
            default: return 'New Account'
            }
        },
        GroupFieldsOne(){
            return this.GroupFields.filter(field => {
                let fields = ['Group_Categories','name','GroupPrivacy','Telephone']
                return fields.includes(field.Name)
            })
        },
        GroupFieldsTwo(){
            return this.GroupFields.filter(field => {
                let fields = ['Logo','Description']
                return fields.includes(field.Name)
            })
        },
        BusinessUnitsArray(){
            return this.$store.state.BusinessUnitsArray
        },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : {id: 'Guest',Name: 'Guest'}
        },
        POLineHeadersforTable(){
        return this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
      },
        ComputedNewPOLineItems(){
            //1 line item, that's the subpackage you on
            let arr = []
            let pack = this.SelectedPackage
            //console.log(pack)
            if(pack){
                let descr = `<p>`+pack.PaymentSchedule+` subscription to our `+pack.Name+` Package</p>`
                descr = descr+`<br>`+pack.Description
                let item = {
                    Nr: 1,
                    Name: pack.Name,
                    Description: descr,
                    Price: pack.Price,
                    Qty: 1,
                    Group: {
                        name: this.NewGroup.name,
                        id: this.newgroupid
                    }
                }
                arr.push(item)
            }
            return arr
        },
        PDFPages(){
        return this.POPDFFunctionData.Pages
      },
      ComputedNewPOLineItemsTable(){
        let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;border: 1px solid grey;border-collapse: collapse;">
                    <tr style="border-bottom: 1px solid grey;padding-bottom: 3px;">`
        this.POLineHeadersforTable.map((head,headindex) => {
            if(headindex-1+2 === headerslength){
            html = html+`
            <th class="overline" style="text-align: center;padding: 8px;border: 1px solid grey;">`+head.text+`</th>            
            </tr>`
            }
            else{
            html = html+`
            <th class="overline" style="text-align: center;padding: 8px;border: 1px solid #dddddd;">`+head.text+`</th>`    
            }
        })
        html = html+`
        </tr>`
        this.ComputedNewPOLineItems.map(lineitem => {
          html = html+`
          <tr class="detailslistoutline">`
          this.POLineHeadersforTable.map((head,headnindex) => {
            //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
            let value = lineitem[head.propvalue]
            if(head.Type === 'Number Field' && head.IsCurrency){
              value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
            }
            else if(head.Type === 'Date' && !head.HasTimeInput){
              value = value.toDateString()
            }
            else if(head.Type === 'Lookup'){
              value = value[head.LookupFieldName]
            }
            if(headnindex-1+2 === headerslength){
            html = html+`
            <td style="text-align: left;padding: 8px;border: 1px solid #dddddd;">`+value+`</td>            
            </tr>`
            }
            else{
            html = html+`
            <td style="text-align: left;padding: 8px;border: 1px solid #dddddd;">`+value+`</td>`    
            }
          })
        })
        html = html+`
        </table>`
        //console.log(html)
        return html
      },
      POPDFFunctionData(){
        let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //ComputedNewPOLineItemsTable
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'ComputedNewPOLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.ComputedNewPOLineItemsTable
        newelemnt.Justify = 'center'
        if(this.ComputedNewPOLineItems.length <= 3){
          newelemnt.PaddingTop = 400
        }
        else if(this.ComputedNewPOLineItems.length <= 6){
          newelemnt.PaddingTop = 375
        }
        else if(this.ComputedNewPOLineItems.length <= 9){
          newelemnt.PaddingTop = 375
        }
        else if(this.ComputedNewPOLineItems.length <= 12){
          newelemnt.PaddingTop = 350
        }
        else if(this.ComputedNewPOLineItems.length <= 15){
          newelemnt.PaddingTop = 325
        }
        else if(this.ComputedNewPOLineItems.length <= 18){
          newelemnt.PaddingTop = 300
        }
        else {
          newelemnt.PaddingTop = 300
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata
      },
      ComputedSOInvoiceObject(){
        let POObject = {}
        if(this.NEWSOInvoiceDialog){
          if(this.OrderforDelivery){
          POObject.Delivery = true
        }
        else{
          POObject.Delivery = false
        }
        POObject.Created_On = new Date()
        POObject.Client = ''
        POObject.Customer_Company = ''
        POObject.Customer_Contact = ''
        POObject.Customer_ID = ''
        if(this.userLoggedIn){
          POObject.Customer_Contact = this.NewGroup.name
          POObject.Client = {
              id: this.userLoggedIn.id,
              Full_Name: this.userLoggedIn.Full_Name,
              Name: this.userLoggedIn.Name,
              Surname: this.userLoggedIn.Surname
          }
          POObject.Clientid = this.userLoggedIn.id
        }
        if(this.BillingAccount){
          POObject.Customer_Company = this.BillingAccount.Account_Name
        }
        if(this.BillingAccount.Customer_ID){
          POObject.Customer_ID = this.BillingAccount.Customer_ID
        }
        let capass = true
        POObject.Invoice_Number = 'Invoice (TBD) #0000001'
        if(this.NewInvoice_Number){
          POObject.Invoice_Number = this.NewInvoice_Number
        }
        POObject.Progress = {ID: 1000001,Name: 'Draft'}
        if(this.PaidinFull){
          POObject.Progress = {ID: 1000004,Name: 'Paid'}
        }
        POObject.RecordPrimaryFieldName = 'Invoice_Number'
        POObject.Company_Name = this.System.Name
        // this.Collection_Address.FieldBreakdown.map(brd => {
        //   let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
        //   if(this.SelectedSupplier[brd.Name]){
        //     POObject[newname] = this.SelectedSupplier[brd.Name]
        //   }
        //   else{
        //     capass = false
        //   }
        // })
        let dapass = true
        this.Invoicing_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Invoicing_Address_').join('SO_Delivery_Address_')
          if(this.BillingAccount[brd.Name]){
            POObject[newname] = this.BillingAccount[brd.Name]
          }
          else{
            dapass = false
          }
        })
        let btapass = true
        this.Company_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Company_Address_').join('PO_Bill_To_Address_')
          let brdname = brd.Name
          if(this.System[brdname]){
            POObject[newname] = this.System[brdname]
          }
          else{
            btapass = false
          }
        })
        POObject.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Created_Byid = this.userLoggedIn.id
        POObject.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Modified_Byid = this.userLoggedIn.id
        POObject.Created_On = new Date()
        POObject.Modified_On = new Date()
        POObject.BillingAccount = {Client_Reference: this.BillingAccount.Client_Reference,Customer_ID: this.BillingAccount.Customer_ID,id: this.BillingAccount.id}
        POObject.BillingAccountid = this.BillingAccount.id
        POObject.Reference_Number = this.BillingAccount.id
        // if(dapass){
        // POObject.SO_Delivery_Address_Text = ``+POObject.SO_Delivery_Address_Street_Number+` `+POObject.SO_Delivery_Address_Street_Name+`, `+POObject.SO_Delivery_Address_Suburb+`, `+POObject.SO_Delivery_Address_Postal_Code+`<br>
        // `+POObject.SO_Delivery_Address_City+`, `+POObject.SO_Delivery_Address_State_Province+`,<br>
        // `+POObject.SO_Delivery_Address_Country_Region  
        // }
        // if(btapass){        
        // POObject.PO_Bill_To_Address_Text = ``+this.SelectedWarehouse.Bill_To_Address_Street_Number+` `+this.SelectedWarehouse.Bill_To_Address_Street_Name+`, `+this.SelectedWarehouse.Bill_To_Address_Suburb+`, `+this.SelectedWarehouse.Bill_To_Address_Postal_Code+`<br>
        // `+this.SelectedWarehouse.Bill_To_Address_City+`, `+this.SelectedWarehouse.Bill_To_Address_State_Province+`,<br>
        // `+this.SelectedWarehouse.Bill_To_Address_Country_Region
        // }
        // POObject.Warehouse = {Name: this.SelectedWarehouse.Name,id: this.SelectedWarehouse.id}
        // POObject.Warehouseid = this.SelectedWarehouse.id
        let subpack = {}
        if(this.SelectedPackage){
            subpack = this.SelectedPackage
        }
        let note = `<p>This is a Subscripton Order, and the grand total value reflecting on the invoice will be payable on a recurring basis. <br>The subscrition plan you opted for will make this grand total payable on a `
        if(subpack){
            note = note+` `+subpack.PaymentSchedule+` `
        }
        note = note+` basis.</p>`
        POObject.Business_Unit = {Name: this.AppMonetizeBU.Name, id: this.AppMonetizeBU.id}
        POObject.Business_Unitid = this.AppMonetizeBU.id
        POObject.Footer_Note = note
        POObject.Sub_Total = this.POSubTotal  
        POObject.Invoice_Total = this.VatExclusiveInvoiceTotal
        //POObject.Supplier_Account_Type = this.BillingAccount.Billing_Type
        //alert(this.BillingAccount.Billing_Type.Name)
        POObject.Payment_Terms = ''
        if(this.BillingAccount.Billing_Type && this.BillingAccount.Billing_Type.Name === 'COD'){
          POObject.Payment_Terms = 'COD'
          POObject.Payment_Period = 0
        }
        else if(this.BillingAccount.Billing_Type && this.BillingAccount.Billing_Type.Name === 'Credit'){          
          POObject.Payment_Period = this.BillingAccount.Days_for_Payment
          POObject.Payment_Terms = 'Net '+this.BillingAccount.Days_for_Payment
        }
        //POObject.Supplier_Account_Type = this.BillingAccount.Billing_Type
        POObject.Shipping_Method = {ID: 1000001,Name: 'FOB'}
        POObject.Due_Date = this.InvoiceFinalPaymentDate
        POObject.Tax_Total = this.TaxTotal
        POObject.Tax_Percentage = this.TaxPercentage
        POObject.Discount_Total = 0
        POObject.Freight_Total = 0
        if(typeof POObject.Delivery === ' undefined'){
          POObject.Delivery = !this.BillingAccount.Collection
        }
        POObject.Grand_Total = this.GrandTotal
        
        return POObject
        }
        else{
          return ''
        }
        
      },
      SelectedPackage(){
          console.log(this.BillingAccount,this.AvailableGroupSubscriptionPackages)
        if(this.BillingAccount && this.BillingAccount.Subscription_Package){
          let subpack = this.AvailableGroupSubscriptionPackages.find(obj => obj.id === this.BillingAccount.Subscription_Package.id)
          return subpack ? subpack : ''    
          }
      },
      POSubTotal(){
          return this.SelectedPackage ? this.SelectedPackage.Price : 0    
          
      },
      VatExclusiveInvoiceTotal(){
          return this.POSubTotal
      },
      TaxPercentage(){
          return this.AppMonetizeBU && typeof this.AppMonetizeBU.Tax_Percentage !== 'undefined' ? this.AppMonetizeBU.Tax_Percentage :0
      },
      TaxTotal(){
        let taxperc = 0
        let total = 0
        if(this.AppMonetizeBU && typeof this.AppMonetizeBU.Tax_Percentage !== 'undefined'){
            taxperc = this.AppMonetizeBU.Tax_Percentage
        }
        if(taxperc > 0){
            total = this.POSubTotal*(taxperc/100)
        }
        return Number(total.toFixed(2))
      },
      GrandTotal(){
          return this.POSubTotal+this.TaxTotal
      },
      PDFPOData(){
        let sampleobj = JSON.parse(JSON.stringify(this.ComputedSOInvoiceObject))
        //console.log('sampleobj',sampleobj.Created_On)
        sampleobj.SubCollections = []
        // this.PDFPages.map(page => {
          // if(page.Elements){
          //   page.Elements.map(elmnt => {
          //     //console.log('elmnt',elmnt)  
          //     if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type !== 'Option Set' && elmnt.DataField.Type !== 'Lookup' && elmnt.DataField.Type !== 'Radio Group'){
          //       sampleobj[elmnt.DataField.Name] = elmnt.DataField.DisplayName
          //     }
          //     else if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type === 'Option Set'){
          //       sampleobj[elmnt.DataField.Name] = {ID: 1000001, Name: elmnt.DataField.DisplayName}
          //     }
          //     //console.log('sampleobj',sampleobj)
          //   })
          // }
        // })
        return sampleobj
      },
        ValidationStepsLength(){
            if(this.GroupAccountDialog){
                return 5
            }
        },
        NewGroupAccountObjectProp(){
            if(this.step === 1 || this.step === 2 || this.step === 7){
                return 'BillingAccount'
            }
            else if(this.step === 3  || this.step === 4 || this.step === 6){
                return 'NewGroup'
            }
            else if(this.step === 5){
                return 'NewGroupFeaturesConfig'
            }
        },
        GroupAddressFields(){
        return [this.Invoicing_Address]
        },
        NewGroupAccountFieldsProp(){
            //console.log(this.GroupAddressFields)
            if(this.step === 1){
                return 'AccountFields'
            }
            else if(this.step === 2){
                return 'GroupAddressFields'
            }
            else if(this.step === 3){
                return 'GroupFieldsOne'
            }
            else if(this.step === 4){
                return 'GroupFieldsTwo'
            }
            else if(this.step === 5){
                return 'GroupFeatureFields'
            }
            else if(this.step === 6){
                return 'GroupScopeFields'
            }
            else if(this.step === 7){
                return 'PackageFields'
            }
        },
        PackageFields(){
            //console.log(this.NewGroupFeatures,this.NewGroupPaidFeatures,this.NewGroupScope,this.NewGroupPaidScope)
            let fieldobj = {id: 'Subscription_Package',Name: 'Subscription_Package',DisplayName: 'Subscription Package',value: 'Subscription_Package',text: 'Subscription Package',
            Type: 'Lookup',LookupFieldName: 'Name',Array: this.AvailableGroupSubscriptionPackages, Response: '',IsMandatory: true,
            LookupSubtitleField: {Name: 'Price',Type: 'Number Field',IsCurrency: true},
            LookupCaptionField: {Name: 'Description'},
            LookupAvatarField: {Name: 'IMG'},DialClass: 'whiteoverlay',
            Has_Selection_Dial: true,CardFlat: true,CardDark: false,DialType: 'Lookup Cards',CardWidth: '100vw',CardHeight: '100vh',CardClass: 'transparent',DialHeader: 'Select a Subscription Package',DialCaption: 'The packages available for your selections are:'}
            return [fieldobj]
        },
        AvailableGroupSubscriptionPackages(){
            //we use NewGroupFeatures and dissect what is paid with NewGroupPaidFeatures, then we use NewGroupScope to dissect what is paid through NewGroupPaidScope for this. if lengths of both is 0, it's FREE            
           if(this.NewGroupPaidFeatures && this.NewGroupPaidFeatures.length === 0 && this.NewGroupPaidScope && this.NewGroupPaidScope.length === 0){
               return [
                   {id: 'FREE',PaymentSchedule: 'Lifetime',
                   Name: 'FREE', Price: 0}
               ]
           }
           else{
              return this.GroupOwnershipPackages
              .filter(subpack => {
               //first let's check if subpack ContentScope includes the required list in this.NewGroupPaidScope
               let pass = true
               this.NewGroupPaidScope.map(Content => {
                   if(!subpack.ContentScope.includes('Public Group '+Content)){
                       pass = false
                   }
                   //subpack.ContentScope.includes('Internal Group '+Content)
               })
                return pass
              })
                .filter(subpack => {
                    //now let's check if subpack FeatureScope includes the required list in this.NewGroupPaidFeatures
               let pass = true
               this.NewGroupPaidFeatures.map(scope => {
                   if(!subpack.FeatureScope.includes(scope)){
                       pass = false
                   }
               })
                return pass
                })
                //what about PaidCategoryScope?
           }
        },
        GroupOwnershipPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Group Ownership'
      })
    },
        NewGroupPaidFeatures(){
            //console.log(this.NewGroupFeatures)
            return this.NewGroupFeatures
            .filter(feat => {
                let prop = 'Group_'+feat.split(' ').join('_')+'_Paid'
                return this.System[prop] && this.System[prop] !== 'Not Paid'
            })
        },
        NewGroupPaidScope(){
            //console.log(this.NewGroupScope)
            return this.NewGroupScope
            .filter(feat => {
                let prop = 'Group_'+feat+'_Paid_Type'
                return this.System[prop] && this.System[prop] !== 'Not Paid'
            })
        },
        NewGroupFeatures(){
            return this.step === 7 ? this.GroupFeatureFields.filter(field => {
                return this.NewGroupFeaturesConfig && this.NewGroupFeaturesConfig[field.Name]
            })
            .map(field => {
                return field.DisplayName
            }) : []
        },
        NewGroupScope(){
            return this.step === 7 ? this.GroupScopeOptions.filter(opt => {
                //console.log(this.NewGroup.AssignedGroupScope)
                return this.NewGroup.AssignedGroupScope && this.NewGroup.AssignedGroupScope.includes(opt.DisplayName)
            })
            .map(field => {
                return field.DisplayName
            }) : []
        },

        
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
                
        StoreOrderEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Entity_Type && ent.Entity_Type.Name === 'Store Order'
            }).map(ent => {
                let entobj = Object.assign(ent)
                entobj.CartSubCol = ent.SubCollections.find(obj => obj.LookupBuilds && obj.LookupBuilds[0] && obj.LookupBuilds[0].Entity_Type && obj.LookupBuilds[0].Entity_Type.Name === 'Store Product')
                return entobj
            })
        },
        CurrentEntity(){
            return this.SystemEntities.find(obj => obj.id === 'Billing_Accounts')
        },
        ColorizedUserBillingAccounts(){
            return this.FilteredUserBillingAccounts.map(ba => {
                let baobj = Object.assign({},ba)
                baobj.Color = this.RandomColor()
                return baobj
            })
        },
        FilteredUserBillingAccounts(){
            return this.TypeFilters? this.UserBillingAccounts.filter(ba => {
                //console.log(this.TypeFilters,ba)
                return this.TypeFilters.includes(ba.Account_Type.Name)
            }) : this.UserBillingAccounts
        },
        UserBillingAccounts(){
        return this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
      UserhasGroupAccount(){
        return this.UserBillingAccounts && this.UserBillingAccounts.find(obj => obj.Account_Type.Name === 'Group Account')
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        WindowWidth(){
            return window.innerWidth
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
    },
    watch: {
        GroupsPluginActive: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                if(this.GroupsPluginActive){
                   this.PrepareGroupFields() 
                }
              }
            },deep: true
        },
        ValidationStepsLength: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                  this.ValidationSteps = {}
                for(var x = 1; x <= this.ValidationStepsLength; x++){
                    //console.log(x)
                    this.ValidationSteps[x] = false
                }
                //console.log(this.ValidationSteps)
              }
            },deep: true
        },
    },
    created(){
        if(this.GroupsPluginActive){
            this.PrepareGroupFields()
        }
        //console.log(this.$store.state.SocialEntities)
        this.ScrolltoTop()
            this.GetPOPDFFunction()
           
            this.PrepareAddressField('Company_Address')
            this.PrepareAddressField('Billing_Address')
            this.PrepareAddressField('Invoicing_Address')
            
            // let hq = this.$store.state.BusinessUnitsArray.find(obj => obj.id === 'Head_Office')
            // console.log(hq)
            // this.PrepareAddressField('Invoice_Address')
            // this.PrepareAddressField('Collection_Address')  
            
    },
    methods:{
        CheckNewAccountDialog(){
            if(this.SiteAccountsOnly){
                this.$store.commit('SetSocialItemInteractMethodProp','') 
                this.$store.commit('SetSocialItemInteractMethod','ActivateSuiteTemplateDialog')
            }
            else{
                this.ActivateAccountSelectDialog()
            }           
        },
        RandomColor(){
            let colors = [									
                '#F44336',									
                '#E91E63',									
                '#9C27B0',									
                '#673AB7',									
                '#3F51B5',									
                '#2196F3',									
                '#03A9F4',									
                '#00BCD4',									
                '#009688',									
            ]									
            let ranint = Math.floor(Math.random() * Math.floor(colors.length))	
            return colors[ranint]
        },
        DeactivateNewLoanAccountDialog(){
            this.NewLoanAccountDialog = false
            this.$store.commit('setCustomProcessingDialog',false)
            this.BillingAccount = {}
        },
        SaveNewLoanAccount(){
            //console.log(this.BillingAccount)
            //ProcessNewGroupAccount
            let vm = this
            let newdocid = ''
            vm.$store.commit('setCustomProcessingDialog',true)
            vm.$store.commit('setCustomProcessingDialogText','Preparing Info')
            vm.BillingAccount.Account_Name = vm.BillingAccount.Client_Reference
            vm.BillingAccount.User = {
              id: vm.userLoggedIn.id,
              Full_Name: vm.userLoggedIn.Full_Name,
              Name: vm.userLoggedIn.Name,
              Surname: vm.userLoggedIn.Surname
          }
          vm.BillingAccount.Billing_Type = {ID: 1000002, Name: 'Credit'}
            vm.BillingAccount.Credit_Limit = 0
            vm.BillingAccount.Total_Invoiced = 0
            vm.BillingAccount.Total_Paid = 0
            vm.BillingAccount.Outstanding_Balance = 0
        // vm.BillingAccount.Payment_Period = this.BillingAccount.Days_for_Payment
        //   vm.BillingAccount.Payment_Terms = 'Net '+this.BillingAccount.Days_for_Payment
          vm.BillingAccount.Business_Unit = {Name: vm.AppMonetizeBU.Name, id: vm.AppMonetizeBU.id}
          vm.BillingAccount.Business_Unitid = vm.AppMonetizeBU.id
          vm.BillingAccount.Userid = vm.userLoggedIn.id
            let Status = vm.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
          let newaccount = {
              ...vm.BillingAccount,
                Created_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                Type_Default: false,
                Modified_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                IsFinal: false,
                Status: Status,
                Status_Reason: Status.Options.find(obj => obj.Name === 'Open')
          }
          const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            let payload = {
              Docid: 'BillingAccounts_Customer_ID',
              Data: newaccount,
                siteid: vm.$store.state.ActiveSuiteid
            }
            vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')
            
            AssignAutoNumber(payload).then(result => {
              newaccount.Customer_ID = result.data
              vm.BillingAccount.Customer_ID = result.data
              vm.BillingAccount.id = result.data
              newaccount.id = result.data
              newdocid = result.data
              console.log(newaccount)
               db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('billingaccounts').doc(newaccount.id).set(newaccount).then(newdoc => {
                vm.DeactivateNewLoanAccountDialog()
               })
            })
        },
        ActivateMarketAccountDialog(){
            let vm = this
            if(!this.UserMarketAccount && this.AppMonetizeBU){
            vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
            vm.$store.commit('setCustomProcessingDialog',true)
            const functions = firebase.functions();
            const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
            let payload = {
                Data: vm.userLoggedIn,
                AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
            }
            //console.log(payload)
            //  vm.$store.commit('setCustomProcessingDialogText','Done Creating') //for now just
            CreateMarketAccount(payload).then(result => {
                console.log(result)
                vm.$store.commit('setCustomProcessingDialogText','Done Creating')
                vm.$store.commit('setCustomProcessingDialog',false)
            })
            }
        },
        SelectRelevantDialog(acctype){
            let type = this.AccountTypesDials.find(obj => obj.Name === acctype.Name)
            this.DeactivateAccountSelectDialog()
            this[type.DialogMethod]()
        },
        ActivateLoanOrderAccountDialog(){
            this.BillingAccount.Client_Reference = this.userLoggedIn.Full_Name+"'s Loan Account"
            if(this.userLoggedIn.Company){
                this.BillingAccount.Company = this.userLoggedIn.Company
                this.BillingAccount.Companyid = this.userLoggedIn.Companyid
            }
            this.BillingAccount.Account_Type = this.AccountTypes.find(obj => obj.Name === 'Cash Loan Account')
            this.NewLoanAccountDialog = true
        },
        ActivateAccountSelectDialog(){
            this.NewAccountDialog = true
        },
        DeactivateAccountSelectDialog(){
            this.NewAccountDialog = false
            this.$store.commit('setCustomProcessingDialog',false)
            this.BillingAccount = {}
        },
        PreparePDFViewing(InvoiceData){
            let vm = this
            console.log(InvoiceData)
            let PDFHeaderObj = {
                ...InvoiceData,
                Invoice_Number: InvoiceData.id,
                Primary_Field_Name: 'Invoice_Number',
                Company_Address_Prop: 'PO_Bill_To_Address_',
                Billing_Address_Prop: 'SO_Delivery_Address_',
            }
            vm.Company_Address.FieldBreakdown.map(brd => {
            let newname = brd.Name.split('Company_Address_').join(PDFHeaderObj.Company_Address_Prop)
            let brdname = brd.Name
            if(vm.System[brdname]){
                PDFHeaderObj[brdname] = vm.System[brdname]
            }
            })
            vm.Billing_Address.FieldBreakdown.map(brd => {
            let newname = brd.Name
            let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
            if(InvoiceData[brdname]){
                PDFHeaderObj[newname] = InvoiceData[brdname]
            }
            })
            
            let PDFDocLineItems = InvoiceData.Line_Items.map((lineitem,lineitemindex) => {
                lineitem.Nr = lineitemindex-1+2
                lineitem.Price = lineitem.Unit_Price
                return lineitem
            })
            

            let PDFFooterObj = {
                ...InvoiceData,                
                TotalFieldProp: 'Invoice_Total',
                TotalFieldValue: InvoiceData.Invoice_Total
            }
            //actually we DO need to calculate the subtotals and jazz from lineitems.
            //Per perhaps the point of this test is to calcualate on call. thus it shold likely move to the funciton
            //front end easiest and simpletst though so leave there for now
            let costtotals = PDFDocLineItems
            .filter(line => {
                return line.Line_Type === 'Debit' || line.Line_Type === 'Payment'
            })
            .map(line => {
                let total = Number(line.Sub_Total)
                return total
            }).reduce((a, b) => a + b, 0)
            let earningtotals = PDFDocLineItems
            .filter(line => {
                return line.Line_Type === 'Earnings'
            })
            .map(line => {
                let total = Number(line.Sub_Total)
                return total
            }).reduce((a, b) => a + b, 0)
            PDFFooterObj.Sub_Total = costtotals-earningtotals
            PDFFooterObj.Tax_Total = 0
            if(PDFFooterObj.Sub_Total > 0){
                PDFFooterObj.Tax_Total = costtotals*(PDFFooterObj.Tax_Percentage/100)
            }
            PDFFooterObj.Grand_Total = PDFFooterObj.Sub_Total+PDFFooterObj.Tax_Total
            PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
            <br>Negative Amounts means payment is due to you.`
            if(PDFFooterObj.Grand_Total > 0){
                PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
                You currently owe on this account.`
            }
            else{
                PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
                You are currently owed a payout on this account.`
            }
            PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
            <br>Always bear in mind the final run at the end of your billing cycle will determine the final result.`
            vm.$store.commit('EmitMarketDialogType','View Statement Invoice')
            vm.$store.commit('setPDFFunctionName','Sales_Invoices')
            vm.$store.commit('setPDFHeaderObj',PDFHeaderObj)
            vm.$store.commit('setPDFFooterObj',PDFFooterObj)
            vm.$store.commit('setPDFDocLineItems',PDFDocLineItems)
            vm.$store.commit('setPDFDownloadOnly',true)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : '',
            [field.Name+'_Street_Name'] : '',
            [field.Name+'_Suburb'] : '',
            [field.Name+'_City'] : '',
            [field.Name+'_Postal_Code'] : '',
            [field.Name+'_State_Province'] : '',
            [field.Name+'_Country_Region'] : ''
        }
        //console.log(field)
        },  
        PrepareGroupFields(){
                this.GroupFeatureFields = this.GroupFeatures.map(feat => {
                    let fieldobj = {
                        id: feat.Name.split(' ').join('_'),
                        Name: feat.Name.split(' ').join('_'),
                        value: feat.Name.split(' ').join('_'),
                        text: feat.Name,
                        DisplayName: feat.Name,
                        Response: false,
                        Type: 'Boolean',
                    }
                    if(feat.Tooltip){
                       fieldobj.Tooltip = feat.Tooltip 
                    }
                    return fieldobj
                })
                let AssignedGroupScopeField = {
                    id: 'AssignedGroupScope',
                    Name: 'AssignedGroupScope',
                    value: 'AssignedGroupScope',
                    text: 'Content Requirements',
                    DisplayName: 'Content Requirements',
                    Response: [],
                    Type: 'Radio Group',
                    TextOnly: true,
                    Options: [],
                }
                
                AssignedGroupScopeField.Options = this.GroupScopeOptions.map((scope,scopeindex) => {
                    let integer = 1000000+(scopeindex-1+2)
                    let optobj = {
                        ID: integer,
                        Name: scope.DisplayName,
                    }
                    //AssignedGroupScope is the field, the return is array. like Radio Button but only, return text...
                    if(scope.Tooltip){
                       optobj.Tooltip = scope.Tooltip 
                    }
                    return optobj
                })
                //what about for instance ItemPass: this.PluginDataBase.Site_Forums?
                //console.log(AssignedGroupScopeField)
                this.GroupScopeFields = [AssignedGroupScopeField]
                let entity = this.$store.state.SocialEntities.find(obj => obj.id === 'Site_Groups')
                this.GroupsDirectoryEntity = Object.assign({},this.Directories.find(obj => obj.id === 'Group_Categories'))
                if(!this.GroupsDirectoryEntity || this.GroupsDirectoryEntity && !this.GroupsDirectoryEntity.CategoricalListType){
                    this.GroupsDirectoryEntity = Object.assign({},this.$store.state.DefaultDirectories.find(obj => obj.id === 'Groups'))
                    this.GroupsDirectoryEntity.id = 'Group_Categories'
                    this.GroupsDirectoryEntity.Name = 'Group Categories'
                }
                //console.log('this.GroupsDirectoryEntity',this.GroupsDirectoryEntity,entity,this.$store.state.DefaultDirectories,this.Directories)
            let additionalfields = [
                {id: 'GroupPrivacy',Name: 'GroupPrivacy',value: 'GroupPrivacy',DisplayName: 'Viewable by',text: 'Viewable by',Type: 'Option Set',Options: this.GroupPrivacyTypes.reverse(), TextOnly: true}
            ]
            //console.log(entity.AllFields)
            this.GroupFields = entity.AllFields
            .map(field => {
                let fieldobj = Object.assign({},field)
                fieldobj.IsMandatory = true
                fieldobj.Response = ''
                if(fieldobj.HasDeepHierarchy){
                    fieldobj.Level1Response = ''
                }
                if(fieldobj.IsFeaturedPhoto){
                    fieldobj.localURL = ''
                }
                fieldobj.text = fieldobj.DisplayName
                //console.log('fdieldobj',fieldobj)
                return fieldobj
            })
            .map(field => {
                if(field.Name === 'Description'){
                    field.IsContentEditable = true
                }
                return field
            }).concat(additionalfields)
        },
        UploadFileSelect(file,field,Record){
        Record[field.Name] = {UploadFile: file, FileName: file.name}
        field.localURL = URL.createObjectURL(file)
        if(field.Name === 'Logo' && Record === this.NewGroup){
            this.samplelogurl = URL.createObjectURL(file)
            this.RefreshPreview()
        }
        //console.log(file,field,Record)
        },
        ClearValidateForm(){
            this['Step'+this.step+'Validating'] = false
            this.step--
        },
        ValidateForm(){
            this['Step'+this.step+'Validating'] = false
            setTimeout(() => {
              this['Step'+this.step+'Validating'] = true  
            }, 50);
            
        
      },
      StepsClearValidateForm(){
            this.ValidationSteps[this.step] = false
            this.step--
            setTimeout(() => {
            }, 50);
        },
        StepsValidateForm(){
            this.ValidationSteps[this.step] = false
            //console.log(this.ValidationSteps[this.step])
            setTimeout(() => {
              this.ValidationSteps[this.step] = true  
              //console.log(this.ValidationSteps[this.step])
            }, 50);
        },
        RefreshPreview(){
            this.RefreshingPreview = true
            setTimeout(() => {
                this.RefreshingPreview = false
            }, 50);
        },
      ProgressWindow(){  
        this.RefreshPreview()
          //console.log('sure as hell progressing')
          let objectprop = this.NewGroupAccountObjectProp
          let fieldsprop = this.NewGroupAccountFieldsProp             
        let length = this[fieldsprop].length
          this[fieldsprop].map((field,fieldindex) => {
            if(typeof field.Response !== 'undefined' && field.Type !== 'Single File Upload'){
              this[objectprop][field.Name] = field.Response
              if(field.Type === 'Lookup'){
                  this[objectprop][field.Name] = {
                     [field.LookupFieldName]: this[objectprop][field.Name][field.LookupFieldName],
                     id: this[objectprop][field.Name].id
                  }
                  this[objectprop][field.Name+'id'] = this[objectprop][field.Name].id
              }
              if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'){
                  field.FieldBreakdown.map(brk => {
                      this[objectprop][brk.Name] = field.Response[brk.Name]
                  })
                  delete this[objectprop][field.Name]
              }
              
              if(field.Type === 'Option Set' && field.HasDeepHierarchy){
                  //console.log(field)
                  let levelslength = field.Levels.length
              field.Levels.map((lvl,lvlindex) => {
                    if(lvl.id === 1){
                    this[objectprop][lvl.FieldName] = {ID: field.Response.ID, Name: field.Response.Name}
                    if(field.id === 'Group_Categories'){
                        this[objectprop].GroupCategory = this[objectprop][lvl.FieldName]
                    }
                    }
                    //console.log('Level'+lvlindex+'Response')
                    if(field['Level'+lvlindex+'Response']){
                    this[objectprop][lvl.FieldName] = {ID: field['Level'+lvlindex+'Response'].ID,Name: field['Level'+lvlindex+'Response'].Name}
                    }
                    
                    if(lvlindex-1+2 === levelslength && field.id === 'Group_Categories'){
                        delete this[objectprop]['Group_Categories']
                    }
                })
              }
            //   if(field.id === 'Group_Categories'){
            //       delete this[objectprop][field.Name]
            //   }
            }
            if(fieldindex-1+2 === length){
              
              //this.Validating = false
              //console.log(this.BillingAccount,this.NewGroup)
              if(this.step === 7 && !this.NewInvoice_Number){
                //this means no subscription///but does this.Newgroup have .id? can from child activeba acct statement...
                if(this.NewGroup.id){
                    //package fields my bob, she plays by BillingAccount not ActiveAccount
                    //which is why we assign on dial open when making step 3
                    let newaccount = {...this.BillingAccount}
                    if(this.SelectedPackage){
                        newaccount.PaymentSchedule = this.SelectedPackage.PaymentSchedule
                        //the vat exclusive total please.
                        newaccount.Total_Invoiced = this.GrandTotal
                        if(newaccount.PaymentSchedule === 'Lifetime'){
                            newaccount.IsFinal = true
                        }
                    }
                    //one thing left here.....What does CreateFinancialDoc do? Will it create the BA?
                    //well it will "SET" the BA yes, just look for BillingAccountObj, but it's all good so long as we assign the active account right?
                    this.ProcessNewGroup(this.NewGroup.id,newaccount)
                    this.$store.commit('setCustomProcessingDialog',true)
                    this.$store.commit('setCustomProcessingDialogText','Preparing Info')
                    //console.log(this.NewGroup,newaccount)
                }
                else{
                 this.ProcessNewGroupAccount()   
                }                  
              }
              else if(this.step === 7 && this.NewInvoice_Number){
                //ONLY happens if you recreate e.g. not happy with selected package
                  this.CreateSubscriptionInvoice(true)
              }
              else{
               this.step++   
              }
              
            }
          })
      },
      DeactivateExportingtoPDFDialog(doc){      
        if(!this.PDFDownloadOnly){
         this.UploadNewInvoice(doc) 
        }        
        this.ExportingtoPDFDialog = false
        this.PDFDownloadOnly = false
      },
      UploadNewInvoice(doc){
          let vm = this
        vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
        //vm.$store.commit('setCustomProcessingDialog',true)
        //console.log(this.NewPOLineItems,this.SelectedTransactionLineItems,this.ComputedSOInvoiceObject)
        let NewPOObject = this.ComputedSOInvoiceObject
        //almost unsure whether this matters, we don't use invoices to create a new scheduled run. unless we should. so therefore let's keep in for now
        
        //also have Content Subscription Order, Group Subscription Order, but this is site order
        //so actually we need to get autonumber. But first let's create autonumber field, see it's logic and take it from there see if it can be used
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        //console.log(blobpdf,bloburl)
        let storagelocation = 'Sales_Invoices/'+vm.finalgroupobj.BillingAccountid+'/'+NewPOObject.Invoice_Number
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(blobpdf);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          //vm.$store.commit('setCustomProcessingDialogText','Preparing Invoice')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: NewPOObject.Invoice_Number,
                            filename: NewPOObject.Invoice_Number
                          }
                          vm.finalPDF_File = PDF_File
                          vm.$store.commit('setCustomProcessingDialog',false)
                           vm.ViewFileDialog = true
                            vm.ViewFileURL = PDF_File.fileurl
                        })
          
      },
      ProcessNewInvoice(PDF_File){
          let vm = this
          let brklength = this.Invoicing_Address.FieldBreakdown.length
          this.Invoicing_Address.FieldBreakdown.map((brk,brkindex) => {
            this.finalgroupobj[brk.Name] = this.finalacctobj[brk.Name]
            if(brkindex-1+2 === brklength){
                vm.$store.commit('setCustomProcessingDialogText','Processing Order')
                vm.$store.commit('setCustomProcessingDialog',true)
                let NewPOObject = Object.assign({},this.PDFPOData)
                NewPOObject.Recurring = true
                    
                    NewPOObject.PaymentSchedule = vm.finalacctobj.PaymentSchedule
                    NewPOObject.Invoice_Type = {ID: 1000002, Name: 'Subscription Order'}
                    NewPOObject.Subscription_Type = this.$store.state.SubscriptionPackageTypes.find(obj => obj.Name === 'Group Ownership')
                    NewPOObject.IsFinal = false
                NewPOObject.Progress = {ID: 1000002, Name: 'Invoiced'}
                NewPOObject.GroupObj = vm.finalgroupobj
                NewPOObject.BillingAccountObj = vm.finalacctobj
                NewPOObject.GroupMember = vm.finalGroupMemberobj
                NewPOObject.Latest_PDF_File = PDF_File
                NewPOObject.Invoice_Date = new Date()
                let PrimaryString = NewPOObject.Invoice_Number
                var lowertext = "";                
                var p;
                let buildup = ''
                //console.log(PrimaryString.length,PrimaryString)
                for (p = 0; p < PrimaryString.length; p++) {
                    buildup = buildup+ PrimaryString[p]
                    if(p === PrimaryString.length-1){
                        lowertext += buildup.toLowerCase();
                    }
                    else{
                        lowertext += buildup.toLowerCase() + ",";
                    }
                } 
                NewPOObject.SearchQuery = []
                NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(NewPOObject.Invoice_Number.trim().split(' '))         
                NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(lowertext.split(','))  
                NewPOObject.SearchQuery = NewPOObject.SearchQuery.map(entry => {
                    return entry.toLowerCase()
                }) 
                let findocpayload = {
                    Collection: 'Sales_Invoices',
                    ParentObj: NewPOObject,
                    IDProp: 'Invoice_Number',
                    siteid: this.$store.state.ActiveSuiteid
                    }
                    if(vm.ActiveTemplate){
                        //if we don't delete it will create account again, which is not the wirst thing but created on and modeified on dates then change
                        delete NewPOObject.BillingAccountObj
                    }
                    console.log(findocpayload)
                    const functions = firebase.functions();
                    const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
                    CreateFinancialDocument(findocpayload).then(result => {
                        vm.$store.commit('setPDFExportDoc','')
                        vm.$store.commit('setPDFDataRecord','')
                        vm.$store.commit('setOrderConfirmationPDF','')
                        vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
                        setTimeout(() => {
                            vm.$store.commit('setCustomProcessingDialogText','Done')
                            vm.$store.commit('setCustomProcessingDialog',false)
                            if(vm.ActiveTemplate){
                                db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('billingaccounts').doc(vm.ActiveAccount.id).update({
                                    Group: {name: vm.NewGroup.name,id: vm.NewGroup.id},
                                    Groupid: vm.ActiveAccount.id
                                }).then(updbadoc => {
                               
                                //FINALLY!!! Now we can install the template right???
                                //haha too late!!!!!!!
                                //Assign the Group Props based on "siteobj" PRIOr toe ven starting the dialog
                                //THEN over here we simply add the pages
                                //On second thought....there is nothing to assign apart from navbar items prop which we do below and then update???
                                //oh yeah "DefaultPage" that looks like {Name: 'Home',Type: 'Group Page'}
                                //Okay so...yes let's move with it - do we change "DEfaultPage" then straight on tempaltes? 
                                let path = vm.TemplatePayload.path
                                let siteobj = vm.TemplatePayload.siteobj
                                let pagespath = vm.TemplatePayload.pagespath
                                let navlistitems = vm.TemplatePayload.navlistitems
                                let navlistprop = vm.TemplatePayload.navlistprop
                                let children = vm.TemplatePayload.children
                                let newpath = vm.TemplatePayload.newpath
                                //http://localhost:8080/Group-PageEditor/WIL0001/Home
                                let addedcomps = 0
                                children.map(child => {                    
                                    //this I simply cannot do, I mean do I just striaght install and overwrite any pages?
                                    //if that's the case dude then make sure client Ui confirms this that they know it's all overwritten!
                                    //but for testing purposes I am going to add them with id "test_" just so I can see what would happen to other pages
                                    let childid = child.TemplateObj.Name.split(' ').join('_')
                                    let childpage = Object.assign({},child.TemplateObj)
                                    childpage.Name = child.TemplateObj.Name
                                    childpage.PageRoute = child.TemplateObj.PageRoute
                                    delete childpage.id
                                    pagespath.doc(childid).set(childpage).then(addedchild => {
                                    let menuitem = Object.assign({},navlistitems.find(obj => obj.id === child.Templateid))
                                    menuitem.Path = childpage.PageRoute
                                    menuitem.icon = childpage.PageIcon
                                    menuitem.title = childpage.Name
                                    menuitem.id = childid
                                    addedcomps++
                                    //Breaking it I know but Path,Title and id needs to change. on Menu Items PLUS on this crooking version of add page
                                    if(addedcomps === children.length){
                                        siteobj[navlistprop] = navlistitems
                                        //okay so if RATemplateView add RA_Parentid to children and add RA_Original_Templateid to each
                                        //but in the same token we dont even save template id anywhere as is so...nevermind? Meaning we never know what Template was used anyway right so skip it right?
                                        path.update(siteobj).then(updatedMaster => {
                                            vm.DeactivateGroupAccountDialog()
                                        vm.$router.push(newpath)
                                        
                                        })
                                    
                                    }
                                    })
                                })

                                })
                            }
                            else{
                            vm.$router.push('/Group/'+NewPOObject.GroupObj.BillingAccountid)
                            vm.DeactivateGroupAccountDialog()
                            }
                        },500)
                    })
            }
          })
          
      },
      ChangeSubscriptionPackage(){
          //first trigger dialog to "change package"this.DownloadPO()
      },
      CancelDialog(){
          //Completely unsure how to do this!!
      },
      DownloadPO(downloadonly){
        //vm.$store.commit('setCustomProcessingDialog',false)
        this.ExportingtoPDFDialog = true
        this.PDFDownloadOnly = downloadonly
      },
      CreateSubscriptionInvoice(recreate){
          this.$store.commit('setCustomProcessingDialog',true)
          let text = 'Preparing Invoice'
          if(recreate){
              text = 'Regenerating Invoice'
          }
          this.$store.commit('setCustomProcessingDialogText',text)
          this.NEWSOInvoiceDialog = true
          this.DownloadPO()
      },
      GetPOPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Sales_Invoices').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.PDFExportFunctionData = functiondata
        })
      },
      ProcessNewGroup(newdocid,newaccount){
         const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
        let vm = this
              //vm.$store.commit('setCustomProcessingDialogText','Preparing Account')
               console.log(vm.NewGroup)
               //okay here is the trick. DO NOT add BA, DO NOT add Group
            //   db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(newaccount.id).set(newaccount).then(newdoc => {
                  let uploadfile = vm.NewGroup.Logo.UploadFile
                  var storageRef = firebase
                    .storage()
                    .ref("Group/"+newdocid+"/Group_Logos/"+new Date()+uploadfile.name);
                var uploadTask = storageRef.put(uploadfile);
                vm.$store.commit('setCustomProcessingDialogText','Uploading Group Logo')
                uploadTask
                    .then((snapshot) => snapshot.ref.getDownloadURL())
                    .then((url) => {
                  vm.$store.commit('setCustomProcessingDialogText','Preparing Group')
                  let group = {...vm.NewGroup,
                  logo: url,
                Created_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Owner: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Ownerid: vm.userLoggedIn.id,
                Created_On: new Date(),
                GroupPrivacyIcon: 'mdi-lock',
                GroupPublishStatus: 'Draft',
                EventsPrivacy: 'Members Only',
                ArticlesPrivacy: 'Members Only',
                BlogPrivacy: 'Members Only',
                PollsPrivacy: 'Members Only',
                MembersPrivacy: 'Members Only',
                NewsPrivacy: 'Members Only',
                ForumPrivacy: 'Members Only',
                MembersPrivacy: 'Members Only',
                MeetupsPrivacy: 'Members Only',
                TeamMembers: [vm.userLoggedIn.id],                
                PostsbyApproval: false,
              }
              delete group.Logo
              
                let nameQuery = []
                let nameStringArray = group.name.split('')
                var lowertext = "";                
                var p;
                let buildup = ''
                console.log(nameStringArray.length,nameStringArray)
                for (p = 0; p < nameStringArray.length; p++) {
                    buildup = buildup+ nameStringArray[p]
                        if(p === nameStringArray.length-1){
                        lowertext += buildup.toLowerCase();
                        }
                        else{
                        lowertext += buildup.toLowerCase() + ",";
                        }
                        }                   
                nameQuery = lowertext.split(',')  
                nameQuery = nameQuery.concat(group.name.split(' ')) 
                console.log(nameQuery)
                group.nameQuery = nameQuery.map(entry => {
                                return entry.toLowerCase()
                                })
                group.SearchQuery = group.nameQuery
                group.Administrators = [vm.userLoggedIn.id]
                //   db.collection('SocialNetworks').doc(vm.$store.state.ActiveSuiteid).collection('groups').doc(newdocid).set(group).then(newgroupdoc => {
                      //vm.$store.commit('setCustomProcessingDialogText','Creating Group Admin Member')
                            const GroupMember = { 
                            Userid: vm.userLoggedIn.id,
                            Groupid: newdocid,
                            Follower: true,
                            Administrator: true,
                            BlogsModerator: false,
                            ArticlesModerator: false,
                            EventsModerator: false,
                            PollsModerator: false,
                            MeetupsModerator: false,
                            ForumsModerator: false,
                            UserRole: 'Creator',
                            Status: 'Active',
                            GroupPrivacy: group.GroupPrivacy,
                            UserName: vm.userLoggedIn.Full_Name,
                            IsTeamMember: true,
                            MembershipDate: new Date()
                            }
                            console.log(GroupMember)
                            GroupMember.MembershipDate = new Date()
                            //first create new inv number
                             let invpayload = {
                            Docid: 'DispatchInstructions_Number',
                            Data: {Invoice_Type: {ID: 1000002,Name: 'Subscription Order'}},
                            siteid: this.$store.state.ActiveSuiteid
                            }
                            vm.$store.commit('setCustomProcessingDialogText','Creating Invoice Number')
                            AssignAutoNumber(invpayload).then(result => {
                            vm.NewInvoice_Number = result.data
                            vm.finalgroupobj = group
                            vm.finalgroupobj.id = group.BillingAccountid
                            vm.finalacctobj = newaccount
                            vm.finalacctobj.Group = {
                                name: group.name,
                                id: group.BillingAccountid
                            }
                            vm.finalacctobj.Groupid = group.BillingAccountid
                            vm.finalacctobj.Latest_Invoiceid = vm.NewInvoice_Number
                            vm.finalacctobj.Latest_Invoice_Date = new Date()
                            delete vm.finalacctobj.Subscription_Packageid
                            vm.finalGroupMemberobj = GroupMember
                            vm.CreateSubscriptionInvoice()
                            })
                                // db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(vm.userLoggedIn.id).collection('groups').doc(newdocid).set(GroupMember).then(doc => {
                                        
                                // })
                                
                            // })
              })
            //   })
                //Okay we need to process, but proceed with some caution basically 1. Creating the Billing Account fo the user now 2. Create the group with the BA linked 3. update the BA with the Group obj (as if lookup)
                //we may need to add Social Lookup as a field to BA, and rener only if "Account_Type" is "GRoup "
      },
      ProcessNewGroupAccount(){
          let vm = this
          vm.$store.commit('setCustomProcessingDialog',true)
          vm.$store.commit('setCustomProcessingDialogText','Preparing Info')
          let ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc()
            let newdocid = ref.id
            //who cares right, we just replace this number further down anyway with new ba number as id but okay
          let acounttype = this.AccountTypes.find(obj => obj.Name === 'Group Account')
          this.BillingAccount.Account_Type = acounttype
         
          vm.BillingAccount.User = {
              id: vm.userLoggedIn.id,
              Full_Name: vm.userLoggedIn.Full_Name,
              Name: vm.userLoggedIn.Name,
              Surname: vm.userLoggedIn.Surname
          }
          vm.BillingAccount.Billing_Type = {ID: 1000001, Name: 'COD'}
          vm.BillingAccount.Business_Unit = {Name: vm.AppMonetizeBU.Name, id: vm.AppMonetizeBU.id}
          vm.BillingAccount.Business_Unitid = vm.AppMonetizeBU.id
          vm.BillingAccount.Userid = vm.userLoggedIn.id
            let Status = vm.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
          let newaccount = {
              ...vm.BillingAccount,
                Created_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                Type_Default: false,
                Modified_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                PaymentSchedule: vm.SelectedPackage.PaymentSchedule,
                IsFinal: false,
                Status: Status,
                Status_Reason: Status.Options.find(obj => obj.Name === 'Open')
          }
          if(vm.SelectedPackage){
              newaccount.PaymentSchedule = vm.SelectedPackage.PaymentSchedule
              //the vat exclusive total please.
              newaccount.Total_Invoiced = vm.GrandTotal
              if(newaccount.PaymentSchedule === 'Lifetime'){
                  newaccount.IsFinal = true
              }
          }
          
            const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            let payload = {
              Docid: 'BillingAccounts_Customer_ID',
              Data: newaccount,
                siteid: vm.$store.state.ActiveSuiteid
            }
            vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')
            
            AssignAutoNumber(payload).then(result => {
              newaccount.Customer_ID = result.data
              vm.BillingAccount.Customer_ID = result.data
              vm.BillingAccount.id = result.data
              newaccount.id = result.data
              newdocid = result.data
               vm.BillingAccount.Group = {
                id: newdocid,
                name: vm.NewGroup.name
            }
              vm.NewGroup.BillingAccount = {
                  id: result.data,
                  Client_Reference: newaccount.Client_Reference
              }
              vm.NewGroup.BillingAccountid = result.data
              group.BillingAccount = {
                  Client_Reference: newaccount.Client_Reference,
                  id: newaccount.id
              }
              //group.BillingAccountid = newaccount.id WHY removed?
              group.BillingAccountid = newaccount.id
              vm.ProcessNewGroup(newdocid,newaccount)
              
                
            })
          
      },
      DeactivateGroupAccountDialog(){
            this.GroupAccountDialog = false
            this.$store.commit('setCustomProcessingDialog',false)
            this.BillingAccount = {}
            this.NewGroup = {}
            this.NewGroupFeaturesConfig = {}
            this.finalPDF_File = ''
            this.finalgroupobj = ''
            this.finalacctobj = ''
            this.finalGroupMemberobj = ''
            this.TemplatePayload = ''
            this.ActiveTemplate = ''
            let channelsfield = this.GroupScopeFields[0]
            channelsfield.ReadOnly = false
            channelsfield.Tooltip = ''
            let webpagebuilderfield = this.GroupFeatureFields.find(obj => obj.id === 'Web_Page_Builder')
            webpagebuilderfield.ReadOnly = false
        },
        ActivateGroupAccountDialog(group,payload){
            console.log(group,payload)
            if(group){
                this.NewGroup = Object.assign({},group)
                if(payload){
                    this.TemplatePayload = payload
                    this.ActiveTemplate = payload.template
                    if(this.ActiveTemplate && this.ActiveTemplate.TemplateProps && this.ActiveTemplate.TemplateProps.DefaultPage){
                        this.NewGroup.DefaultPage = this.ActiveTemplate.TemplateProps.DefaultPage
                    }
                    //sure the below will happen as well but
                    //No let's dissect this template.
                    let pluginchecks = [{Pluginid: 'Group_Featured_Members'},{Pluginid: 'Group_Monetization',PackageProp: 'Can_Monetize'},
                    {Pluginid: 'Site_Articles',SystemProp: 'Group_Articles_Paid',FeatureScopeProp: 'Articles'},{Pluginid: 'Site_Blogs',SystemProp: 'Group_Blogs_Paid',FeatureScopeProp: 'Blogs'},
                    {Pluginid: 'Site_Events',SystemProp: 'Group_Events_Paid',FeatureScopeProp: 'Events'},{Pluginid: 'Site_Meetups',SystemProp: 'Group_Meetups_Paid',FeatureScopeProp: 'Meetups'},
                    {Pluginid: 'Site_Articles',SystemProp: 'Group_Web_Forms_Paid',FeatureScopeProp: 'Web Forms'}]
                    pluginchecks.map(chck => {
                        if(this.ActiveTemplate.PluginDataBase && this.ActiveTemplate.PluginDataBase[chck.Pluginid] && this.ActiveTemplate.PluginDataBase[chck.Pluginid].Active){
                            //just a little reverse engineering, the subpackages will pick up
                            this.NewGroup.AssignedGroupScope.push(chck.FeatureScopeProp)
                        }
                    })
                //what about for instance ItemPass: this.PluginDataBase.Site_Forums?
                //console.log(AssignedGroupScopeField)
                    let channelsfield = this.GroupScopeFields[0]
                    channelsfield.Response = this.NewGroup.AssignedGroupScope
                    channelsfield.ReadOnly = true
                    channelsfield.Tooltip = '<p>These Options are required for the template you have selected. You will be able to change this later but it may affect the Pages on the template.</p>'
                    //GroupFeatures ? what about Web Page Builder and at least Web Forms??
                    this.NewGroup.Web_Page_Builder = true
                    let webpagebuilderfield = this.GroupFeatureFields.find(obj => obj.id === 'Web_Page_Builder')
                    webpagebuilderfield.Response = true
                    webpagebuilderfield.ReadOnly = true
                    //OMW okay just hear me out on a couple of things but...let me be honesst, should we not just simply assign all the values now???
                    //like, we could even assign pages or somethign I don't know? but that's all we shold do after group is create,d assign pages...
                }
                this.BillingAccount = Object.assign({},this.ActiveAccount)
                this.step = 3
                console.log('this.NewGroup',this.NewGroup,this.BillingAccount)
                this.GroupFields.map(field => {
                    if(typeof this.NewGroup[field.Name] !== 'undefined'){
                        field.Response = this.NewGroup[field.Name]
                    }
                })
                this.GroupAccountDialog = true
                
            }
            else{
            this.GroupAccountDialog = true

            }
        },
        ActivateSuiteAccountDialog(){
            this.GroupAccountDialog = true
        },
        ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
            console.log(window.innerHeight)
            this.ViewFileDialog = true
          }
          else{
            this.ViewFileURL = result.data[0]
            var ua = navigator.userAgent.toLowerCase();
            console.log(ua)
            var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
            if(isAndroid) {
              window.open(this.ViewFileURL)
            }
            else{
              console.log(window.innerHeight)
              this.ViewFileDialog = true
            }
          }
        })
    },
        SelectActiveAccount(account){
            this.ActiveAccount = Object.assign({},account)
        },
        DeactivateActiveAccountView(){
            this.ActiveAccount = ''
        }
    }
}
</script>

<style>

</style>


