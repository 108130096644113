
<template>
  <div class="users v-main__wrap">
  <v-dialog v-model="importresultsdialog" max-width="800px">
        <v-card dark height="100%" width ="100%" class="center RAWebBtnGraddark">
          <!-- <v-img src="@/assets/RABaseBG.jpeg"> -->
          <v-card-title>
            Import Result<v-spacer></v-spacer><v-icon @click="CloseImportresultsdialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Successful Imports ({{SuccessfulImportsArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in SuccessfulImportsArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Partial Failures ({{PartialFailuresArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in PartialFailuresArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer><v-btn href="/DataImports" target="_blank" color="green" dark>View Import Record</v-btn>
          
          </v-card-actions>
          <!-- </v-img> -->
        </v-card>
      </v-dialog>

      <v-dialog v-model="ImportingProgressDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Importing Records</h2><br>
                Now Importing - 
                          <div class="red--text">
                          <p>{{Importcount}}</p>
                          </div>
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
                  <v-layout row class="justify-center mx-3 my-3">
                      <progress id="importprogressbar">
                      </progress>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
    
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome!User.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
     
           <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>
    
    <v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RASolidA.jpeg"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="RAWebBtnGraddark text-center" cols="12">
      <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large>mdi-face</v-icon>Users</span>
      
    </v-col>
  </v-row>
    </v-parallax>

    <v-layout class="justify-center" style="padding-top: 70px"  id="usersskeleton">
      <v-flex lg10 md10> 
    <v-sheet
        color="secondary"
        class="mx-6 my-3"
        style="margin-top:150px;"
      >
      <div class="hidden-sm-and-down">
            <h1 class="Header white--text">Users</h1>
        </div>
      </v-sheet>
  </v-flex>
 </v-layout>

        
    <v-layout class="justify-center" style="padding-top: 70px">
      <v-flex lg10 md10> 
     <div id="usersdata" style="display:none">
    
    <v-snackbar v-model="Profile_Photofilesnackbar" :timeout="4000" top color="success">
      <span>Awesome! Data saved successfully.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="InviteUserDialog" max-width="500">
      <v-card>
        <v-card-title>
          Invite User
        </v-card-title>
        <v-card-text>
          <v-switch v-model="IsGuestUser" label="Guest User" />
          <v-text-field v-model="InvitingUserEmail" label="Email" :rules="[$store.state.formrules.required,$store.state.formrules.email,NotonInvitedList]" />
          <v-select v-model="InviteUserBU" :items="BusinessUnitArray" label="Business Unit" :rules="[$store.state.formrules.required]" item-text="Name" return-object/>
          <v-select v-model="ModulePath" :items="Modules" label="Module" :rules="[$store.state.formrules.required]" item-text="Name" return-object/>
          <v-switch v-model="InviteUserisAdmin" label="User is Administrator" />
          <!-- <div contenteditable id="previewsendemail" class="postinputbox" v-html="PresetEmailBody"/> -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelInvitingUserEmail()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="SendInvitetoUser()">Invite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
        
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field readonly filled v-model="UserFullName" label="FullName"></v-text-field>
                <v-text-field v-model="editedItem.Name" label="Name"></v-text-field>
                <v-text-field v-model="editedItem.Surname" label="Surname"></v-text-field>
                <v-text-field v-model="Email" label="Email"> </v-text-field>
                <v-text-field v-model="Password" label="Password" type="password"> </v-text-field>
                  <v-select label="Select Title" v-model="editedItem.Title" :items="TitleArray" item-text="Name"> </v-select>                    
                <v-select label="Select Gender" v-model="editedItem.Gender" :items="GenderArray" item-text="Name"> </v-select>
                  <v-combobox v-if="editedIndex > -1"
                    v-model="editedItem.Business_Unit"
                    :items="BusinessUnitArray"
                    item-text="Name"
                    hide-selected
                    label="Search Business Unit"
                    small-chips
                    solo
                    
                  ></v-combobox>
                  <v-combobox v-if="editedIndex > -1"
                    v-model="editedItem.Manager"
                    :items="ManagerArray"
                    item-text="Full_Name"
                    hide-selected
                    label="Search Manager"
                    small-chips
                    solo
                    
                  ></v-combobox>
              </v-col>
            </v-row>
        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importdialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Import Dialog</span>
        </v-card-title>
        <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field v-model="Import.Name" label="Name"></v-text-field>
                <input v-if="!ImportFile"
                          type="file"
                          @change="onImportfileSelected($event)"
                          ref="ImportFileinputter"
                          id="fileUpload"
                          >
                <v-btn @click="DownloadUsersImportTemplate()">Download Template</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar v-if="UsersArray" elevation="6" dense  style="margin-bottom:30px;">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="BusinessUnitArrayFilter"
          v-model="BusinessUnitfilter"
          item-text="Name"
          label="Search BusinessUnits"
          autowidth
          return-object
          chips
          
          />
          </div>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="ManagerArrayFilter"
          v-model="Managerfilter"
          item-text="Full_Name"
          label="Search Users"
          autowidth
          return-object
          chips
          
          />
          </div>
          <v-btn outlined fab small class="elevation-6"  style="margin-top:25px;" >
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-btn>
          </v-layout>
        </v-toolbar>
    <v-layout row class="justify-center">
       <v-card class="transparent" flat width="90%">
         <v-toolbar  rounded class="RAWebBtnGraddark" elevation="12" style="margin-bottom:-40px;" color="primary white--text">
        <v-toolbar-title>
          <v-select dark
          label="View"
          item-text="Name"
          v-model="UsersView"
          return-object
          :items="UsersViews">
          </v-select>
        </v-toolbar-title>
        <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-sequential</v-icon>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-grid</v-icon>
        <v-spacer></v-spacer>
        <v-text-field dark
            v-model="search"
            
            label="Search"
            class="mediumoverline"
            single-line
            hide-details
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" v-if="userIsAdmin" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dark :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }">
              <v-list-item  @click="AcceptInviteUserDialog()"  v-if="userIsAdmin" class="listoutline">
                  <v-list-item-title  class="subtleoverline">
                    Invite User
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="green">mdi-share-circle</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-menu>
      </v-toolbar>
       </v-card>
    </v-layout>
<v-data-table
    :dark="AppisDarkMode"
    :page.sync="page"
        @page-count="pageCount = $event"
        :headers="headers"
        :items-per-page="itemsPerPage"
        hide-default-footer
    :items="UsersbyView"
    class="elevation-1"
  >
    <template v-slot:top>
      <div style="padding-top:40px;"/>


    </template>
    <template v-slot:[`item.Full_Name`]="{ item }">
        <v-btn outlined fab small @click="ViewItem(item)"  v-if="item && userIsAdmin" class="elevation-6 accent--text"><v-icon small>mdi-face</v-icon></v-btn>
        {{item.Full_Name}}
      </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn outlined fab x-small @click="editItem(item)" v-if="item && userIsAdmin" class="elevation-6 orange--text">
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" v-if="item && userIsAdmin && item.disabled" class="elevation-6 red--text">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
    </template>
    <template v-slot:[`item.Welcomed`]="{ item }">
      <v-icon
      v-if="item.Welcomed"
        small
        class="mr-2"
        color="green"
      >
        mdi-check-bold
      </v-icon>
      <v-icon
      v-if="!item.Welcomed && userIsAdmin"
        small
        @click="ConfirmSendWelcomeMessage(item)"
        color="red"
      >
        mdi-close
      </v-icon>
      
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
    <template v-slot:footer>
        
        <v-card outlined>
        <v-pagination
          v-model="page"
          circle
          :length="pageCount"
          
        ></v-pagination>
        <v-text-field
        dense
          class="primary--text mx-4"
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
        </v-card>
      </template>
  </v-data-table>
      
    
    
    </div>
      </v-flex>
    </v-layout>
  </div>
</template>



<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'


export default {
  props: ['AppisDarkMode','System'],
  data() {
    return {
      IsGuestUser: true,
      Modules : [
      {
        Name: 'RapidApps',
        URL: 'https://rapidapps-7d6e7.web.app'
      },
      {
        Name: 'RapidApps',
        URL: 'https://rapidapps-7d6e7.web.app'
      },
      ],
       ModulePath: {
        Name: 'RapidApps',
        URL: 'https://rapidapps-7d6e7.web.app'
      },
      InviteUserBU: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      UsersViews: [
        {
          ID: 1000001,
        Name: "Enabled Users",
        Filters: [
          {
            FieldName: 'disabled',
            MatchingValue: false,
            FieldType: 'Boolean'
          }
        ]
      },
      {
        ID: 1000002,
        Name: "Disabled Users",
        Filters: [
          {
            FieldName: 'disabled',
            MatchingValue: true,
            FieldType: 'Boolean'
          }
        ]
      }
      ],
      UsersView: {
          ID: 1000001,
        Name: "Enabled Users",
        Filters: [
          {
            FieldName: 'disabled',
            MatchingValue: false,
            FieldType: 'Boolean'
          }
        ]
      },
      InvitedUsersArray: [],
      InviteUserDialog: false,
      InvitingUserEmail: '',
      InviteUserisAdmin: false,
      EmailBody: '',
      rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
      Title: '',
      Gender: '',
      TitleArray: [
          {index: 0, Name: 'Mr'},
          {index: 1, Name: 'Mrs'},
          {index: 2, Name: 'Ms'},

        ],
        GenderArray: [
          {index: 0, Name: 'Male'},
          {index: 1, Name: 'Female'},

        ],
      profilephotofilesnackbar: false,
      selectedprofilephotoFile: '',
      profilephotolocalURL: '',
      BusinessUnitArray: [],
      BusinessUnitfilter: {Name: ''},
      ManagerArray: [],
      Managerfilter: {Full_Name: ''},
      mainrecordid: '',
      dialog: false,
      importdialog: false,
      ImportHeaders: [],
      NewImportsArray: [],
      PartialFailuresArray: [],
      SuccessfulImportsArray: [],
      CSVResult: [],
      Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Contacts', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
      ImportFile: '',
      userimportheaders: [
      
        { text: 'Title', value: 'Title'},
        { text: 'Email', value: 'Email'},
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Gender', value: 'Gender'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      UserRecord: '',
      UsersImportArray: [],
      initialize: '',
      search: '',
      headers: [
      
        { text: 'Title', value: 'Title',class: "overline black--text"},
        { text: 'FullName', value: 'Full_Name',class: "overline black--text"},
        { text: 'Name', value: 'Name',class: "overline black--text"},
        { text: 'Surname', value: 'Surname',class: "overline black--text"},
        { text: 'Gender', value: 'Gender',class: "overline black--text"},
        { text: 'Business Unit', value: 'Business_Unit.Name',class: "overline black--text"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline black--text"},
        
        { text: 'Welcomed', value: 'Welcomed', sortable: false ,class: "overline black--text"},
        { text: 'Actions', value: 'action', sortable: false ,class: "overline black--text"},
      ],
      snackbar: false,
      EditedUsersnackbar: false,
      UserEditdialog: false,
      newUserdialog: false,
      UsersArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
          Full_Name: '',
          Name: '',
          Surname: '',
          Business_Unit: {id: '',  Name: ''},
          Manager: {id: '',  Full_Name: ''},
          profilephoto: '',
      },
      editedItem: {
        id: '',
          Full_Name: '',
          Name: '',
          Surname: '',
          Business_Unit: {id: '',  Name: ''},
          Manager: {id: '',  Full_Name: ''},
          profilephoto: '',
      },
      Email: '',
      Password: '',

    }
  },

  created(){
    this.ScrolltoTop()
     this.$store.commit('setBuilderView',{Name: 'Database Builder'}) 
this.GetRequestingUser()
    setTimeout(() => {
  document.getElementById('usersskeleton').style.display = 'none'
  document.getElementById('usersdata').style.display = 'block'      
  },1500)

  },
  computed: {
    NotonInvitedList(){
      if(this.InvitingUserEmail){
        let onlistcheck = this.InvitedUsersArray.find(obj => obj.Email === this.InvitingUserEmail)
        if(onlistcheck){
          return () => (typeof onlistcheck === 'undefined') || 'Invite already exists for this email address'
        }
        else{
          return true
        }
      }
      else{
        return () => (this.InvitingUserEmail !== '') || 'No Email Selected'
      }
      
    },
    userLoggedIn () {
    return this.$store.getters.user
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    UserFullName(){
      return this.editedItem.Name+' '+this.editedItem.Surname
    },
    FinalUsersBusinessUnitArray(){
      return this.UsersArray.map(User => {
        let lookupobject = this.BusinessUnitArray.find(Business_Unit => Business_Unit.id === User.Business_Unitid)
        User.Business_Unit = lookupobject
        return User
      })
      },
    FinalUsersManagerArray(){
      return this.UsersArray.map(User => {
        let lookupobject = this.ManagerArray.find(Manager => Manager.id === User.Managerid)
        User.Manager = lookupobject
        return User
      })
      },

    formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
    UsersSearched() {
      return this.UsersArray.filter(User => {
        if(this.search !== ''){
        if(User.Full_Name){return User.Full_Name.toLowerCase().includes(this.search.toLowerCase())}
        }
        else {
          return User
        }
      })
      },
      UsersFilteredandSearched() {
      return this.UsersSearched.filter(User => {if(this.BusinessUnitfilter.Name){if(User.Business_Unit.Name){ return User.Business_Unit.id.includes(this.BusinessUnitfilter.id)}} else {return User}}).filter(User => {if(this.Managerfilter.Full_Name){if(User.Manager.Full_Name){ return User.Manager.id.includes(this.Managerfilter.id)}} else {return User}})
      },
      UsersbyView() {
      return this.UsersFilteredandSearched.map(user => {
        console.log(this.UsersView)
        if(typeof this.UsersView !== 'undefined' && typeof this.UsersView.ID !== 'undefined'){
          user.FilterMatch = false
          console.log('had UsersView and try to return')
          return this.ViewFilterMatch(user)
        }
        else{
          console.log('nope has NO UsersView and try to return')
          user.FilterMatch = true
          return user
        }
        
        // user = this.ViewFilterMatch(user)
        
      }).filter(user => {
        console.log(user.FilterMatch)
        return user.FilterMatch === true
      })
      
      },
        
        BusinessUnitArrayFilter(){
          return this.UsersArray.map(User => {
            return User.Business_Unit
          })
        },
        ManagerArrayFilter(){
          return this.UsersArray.map(User => {
            return User.Manager
          })
        },
        HeaderNames() {
        return this.userimportheaders.map(header => {
          return header.value.split('.')[0]
        }).filter(header => {
          return header !== 'id'
        })
      },
      HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
         let correctheader = header
          return correctheader
        })
      }

    },
  methods: {
    ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    ViewFilterMatch(record){
          let p
          for (p = 0; p < this.UsersView.Filters.length; p++) {
                let filter = this.UsersView.Filters[p]
                console.log(filter)
                  if(filter.FieldType === 'Boolean'){
                  console.log(typeof record[filter.FieldName],record[filter.FieldName])
                  if(filter.MatchingValue === false){
                    if(typeof record[filter.FieldName] === 'undefined' || record[filter.FieldName] === filter.MatchingValue){
                    console.log(record)
                    console.log(filter.FieldName)
                    record.FilterMatch = true
                    }
                  }
                  else{
                    if(typeof record[filter.FieldName] !== 'undefined' && record[filter.FieldName] === filter.MatchingValue){
                    console.log(record)
                    console.log(filter.FieldName)
                    record.FilterMatch = true
                    }
                  }
                  
                }
                console.log(p)
                console.log(this.UsersView.Filters.length-1)
                if(p === this.UsersView.Filters.length-1){
                  return record
                }
              } 

          
      
    },
    ConfirmSendWelcomeMessage(item){
      // if(item.roles)
      // this.SendWelcomeMessage(item)
      console.log(item)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(item.id).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        if(userdata.rolesarrayQuery){
          confirm('This User has roles. Send the Welcome Message now?') && this.SendWelcomeMessage(item)
        }
        else{
          confirm('This User has NO ASSIGNED ROLES. Strongly advise you do not send welcome message. Send anyway?') && this.SendWelcomeMessage(item)
        }
      })
    },
    SendWelcomeMessage(item){
      console.log(item)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(item.id).update({
        Welcomed: true
      })
      
      let path = this.ModulePath.URL
      this.EmailBody = `Good Day<div><br></div><div>Your `+this.System.Name+` account has been succesfully created.</div><div>Please click on the below link below to log in.</div><div><br></div>
      <a href="`+path+`">Open the App</a><br>
      <div>Happy Networking!</div>`
      console.log(this.EmailBody)
        let EmailObject = {          
          EmailFrom: 'noreply@businessandpeople.co.za',
          EmailTo: item.Email,
          EmailSubject: this.System.Name+' - Account Approved!',
          EmailBody: this.EmailBody,

        }
            //alert('email sending')
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })

            let userobj = this.UsersArray.find(obj => obj.id === item.id)
      let userindex = this.UsersArray.indexOf(userobj)
      this.UsersArray[userindex].Welcomed = true
    },
    GetInvitedUsers(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userinvites').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.InvitedUsersArray.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }


        })
      })
    },
    CancelInvitingUserEmail(){
      this.InviteUserDialog = false
      this.InvitingUserEmail = ''
      this.InviteUserisAdmin = false
      this.InviteUserBU = ''
      this.ModulePath = ''
    },
    SendInvitetoUser(){
      if(this.InviteUserisAdmin){
        confirm('Are you sure you want to create the User with Administrative permissions? It would give the user access to everything, including adding and removing administrators. Click okay to continue') && this.ProcessInvitetoUser()
      }
      else{
        this.ProcessInvitetoUser()
      }
    },
    ProcessInvitetoUser(){
      console.log('will invite user on '+this.InvitingUserEmail)
      let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc()
      let newdocid = ref.id
      let NewUserObj = {
        Email: this.InvitingUserEmail,
        Status: {
          ID: 1000001,
          Name: 'Sent'
        },
        Business_Unit: {
          id: this.InviteUserBU.id,
          Name: this.InviteUserBU.Name
        },
        Business_Unitid: this.InviteUserBU.id,
        Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
        Created_Byid: this.UserRecord.id,
        Created_On: new Date(),
        IsGuestUser: this.IsGuestUser
      }
      NewUserObj.Expired = false
      if(this.InviteUserisAdmin){
        NewUserObj.rolesarrayQuery = ['System Admin']
        NewUserObj.rolesarrayDBRules = {SystemAdmin: true}
      }
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userinvites').doc(newdocid).set(NewUserObj).then(doc => {
        console.log('created user invite record')
      })

      let path = this.ModulePath.URL+'/UserInvite/'+newdocid
      this.EmailBody = `Good Day<div><br></div><div>You have been invited to join `+this.System.Name+`. </div><div>Please click on the below link to accept and process your invite.</div><div><br></div>
      <a href="`+path+`">Open your Invite</a><br>
      <div>Please note that this link is PRIVATE and you can NOT share the link with anybody else, lest you leave your pending profile at risk for attacks</div>
      <div>Thank you in advance</div>`
      console.log(this.EmailBody)
        let EmailObject = {          
          EmailFrom: 'noreply@businessandpeople.co.za',
          EmailTo: this.InvitingUserEmail,
          EmailSubject: 'Invite to join - '+this.System.Name,
          EmailBody: this.EmailBody,

        }
            //alert('email sending')
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.CancelInvitingUserEmail()
            })
    },
    AcceptInviteUserDialog(){
      this.InviteUserDialog = true
    },
    async GetRequestingUser(){
        var vm = this;
        if(this.userIsAdmin){
          this.GetInvitedUsers()
        }
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.GetUsers()
              
              vm.GetBusinessUnitsBusinessUnit()

              vm.GetManagerArray()            
            })
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          })
      },
    RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
    closeImport(){
      this.importdialog = false
      this.ImportFile = null
      this.Import.Name = ''
    },

    saveImport() {
      let importLocalURL = URL.createObjectURL(this.ImportFile)
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this
      reader.addEventListener('load', function(e) {
            var text = e.target.result;
            vm.CSVConvertToJSON(text)
      });
    },


    CheckDelimiter(lines){
      let headertest = lines[0].split("|");
      if(headertest.length ===1){
        this.ConversionCSVtoJJSON(lines,'|')
      }
      else{
        this.ConversionCSVtoJJSON(lines, '|')
      }
      //console.log('headertest')
      //console.log(headertest)
    },
    ConversionCSVtoJJSON(lines,delimiter){
      let dataonly = Object.assign([],lines)
      dataonly.splice(0,1)
      console.log('used '+delimiter)
      console.log(dataonly)
      this.CSVResult = []
      this.ImportHeaders=lines[0].split(delimiter);
      console.log('this.ImportHeaders')
      console.log(this.ImportHeaders)
      for(var i=1;i-1+2<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Full_Name == obj.Full_Name)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)


    }
      this.CSVResult.map(async (res,i) => {
        // await this.sleep(1000)
        this.UpdateFiresStore(res,i)
        
      })
    },
    CSVConvertToJSON(text){
     
      this.NewImportsArray = []
      this.PartialFailuresArray = []
      
      let lines = text.split("\n")
      this.CheckDelimiter(lines)
      
    },
    
    sleep(millis) {
    return new Promise(resolve => setTimeout(resolve, millis));
    },
    UpdateFiresStore(SingleResult, Index){
      
      console.log("Index")
      console.log(Index)
      let ResultLength = this.CSVResult.length
      console.log("ResultLength")
      console.log(ResultLength)
      console.log("Index-1+2")
      console.log(Index-1+2)
      let OriginalImportObject = Object.assign({},SingleResult)
      let ImportUser = {
        Full_Name: SingleResult.Name+' '+SingleResult.Surname,
        Email: SingleResult.Email.toLowerCase(),
        Name: SingleResult.Name,
        Surname: SingleResult.Surname,
        }
        if(SingleResult.Title){
          ImportUser.Title = SingleResult.Title
        }
        if(SingleResult.Gender){
          ImportUser.Gender = SingleResult.Gender
        }

        let FailureObject = Object.assign({},ImportUser)
        FailureObject.Failures = []


        if(SingleResult.Business_Unit !== ''){
          let GetObj = this.BusinessUnitArray.find(comp => comp.Name.toLowerCase() == SingleResult.Business_Unit.toLowerCase())
          if(typeof GetObj === 'undefined'){
            FailureObject.Business_Unit = 'Failed - No Matching Record Found'
            ImportUser.hasFailures = true
            let FailureInstance = {Type: 'No Matching Lookup Record', Name: 'Business_Unit'}
            FailureObject.Failures.push(FailureInstance)
          }
          else{
          let Business_UnitObject = {id: GetObj.id,Name: GetObj.Name}
          ImportUser.Business_Unit = Business_UnitObject
          ImportUser.Business_Unitid = Business_UnitObject.id
          }

        }          

        if(ImportUser.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }

        this.NewImportsArray.push(OriginalImportObject)
        // db.collection('contacts').add(ImportUser).then({
        // })
        this.$store.dispatch('signUpbyAdminImportAction', ImportUser).then(response => {
          console.log('store response promise n users')
          // console.log(response)
          // console.log(response.user)
          // console.log(response.user.uid)
          let UserDataObjtect = {
              Name: ImportUser.Name,
              Surname: ImportUser.Surname,
              Full_Name: ImportUser.Full_Name,
              Gender: ImportUser.Gender,
              Title: ImportUser.Title,
              Email: ImportUser.Email,
              Business_Unit: ImportUser.Business_Unit,
              Business_Unitid: ImportUser.Business_Unitid,
              SubordinateArrayDBrules:{[response.user.uid]: true},
              Subordinatearrayquery: [response.user.uid],
              uid: response.user.uid
            }
            console.log('UserDataObjtect')
            console.log(UserDataObjtect)
          this.UsersImportArray.push(UserDataObjtect)
          let AllImportObj = this.NewImportsArray.find(imp => imp.Full_Name == ImportUser.Full_Name)
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = response.user.uid
          console.log(this.NewImportsArray[AllImportObjIndex])
          console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Full_Name == ImportUser.Full_Name)

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = response.user.uid
          console.log(this.PartialFailuresArray[PartialImportObjIndex])
          console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Full_Name == ImportUser.Full_Name)
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = response.user.uid
          console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          console.log(this.SuccessfulImportsArray)
          }
          if (this.UsersImportArray.length === ResultLength){
        console.log("NewImportsArray")
        console.log(this.NewImportsArray)
        console.log("PartialFailuresArray")
        console.log(this.PartialFailuresArray)
        this.SaveUserArray()
        let CreatedOn = format(new Date(),'yyyy-MM-dd')
        let User = this.UserRecord
        let RefinedHeaders = this.ImportHeaders.map(header => {
          let obj = {text: header, value: header}
          return obj
        })
        console.log("this.NewImportsArray at final length")
        console.log(this.NewImportsArray)
        let DataImport = {name: this.Import.Name, dataset: 'Users', datasetrecordpath: 'User', createdon: CreatedOn, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
        db.collection('dataimports').add(DataImport).then(doc => {
        var storageRef = firebase.storage().ref('DataImport/'+CreatedOn+'/'+this.ImportFile.name);
        var uploadTask = storageRef.put(this.ImportFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('dataimports').doc(doc.id).update({
                    csvfile: url
            })
            this.ImportFile = ''
                }),
                  this.importsnackbar = true
        })

        }

        })
    },

    SaveUserArray(){
     let length = this.UsersImportArray.length
      this.UsersImportArray.map((user,i) => {
          if(i-1+2 === length){
            this.saveOnebyOne(user,true)
          }
          else{
            this.saveOnebyOne(user,false)
          }
          
      })
    },

    saveOnebyOne(user,boolean){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).set({
        Name: user.Name,
        Surname: user.Surname,
        Full_Name: user.Full_Name,
        Gender: user.Gender,
        Title: user.Title,
        Email: user.Email,
        Business_Unit: user.Business_Unit,
        Business_Unitid: user.Business_Unitid,
        SubordinateArrayDBrules:{[user.uid]: true},
        Subordinatearrayquery: [user.uid]
      })
      if(boolean){
        this.closeImport()
      }
    },
    onImportfileSelected(event){
      this.ImportFile = event.target.files[0]
    },

    DownloadUsersImportTemplate(){
      let copyText = this.HeaderNamesSplit

      var csvfilename = 'Users.csv'
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
      element.setAttribute('download', csvfilename)
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      // }
    },

    ResetFilters() {
      this.BusinessUnitfilter = {Name: ''}

      this.Managerfilter = {Full_Name: ''}
    },
    ResetSearch() {
      this.search = ''
    },

    GetUsers() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },

      GetBusinessUnitsBusinessUnit() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
      //console.log(this.FinalUsersBusinessUnitArray)
    })
      },

      GetManagerArray() {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ManagerArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
      //console.log(this.FinalUsersManagerArray)
    })
      },


      ViewItem (item) {
        this.$router.replace('/User/'+item.id)
      },

      deleteItem (item) {
        const index = this.UsersArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(item.id).delete() && this.UsersArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.UsersArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },


      save () {
      const EditedUser = {
    Full_Name: this.UserFullName,
    Name: this.editedItem.Name,
    Surname: this.editedItem.Surname,
    Gender: this.editedItem.Gender,
    Title: this.editedItem.Title
        }
      
      if(this.editedItem.Business_Unit){
      EditedUser.Business_Unit= this.editedItem.Business_Unit.Name
      EditedUser.Business_Unitid= this.editedItem.Business_Unit.id
      }
      if(this.editedItem.Manager){
      EditedUser.Manager= this.editedItem.Manager.Full_Name
      EditedUser.Managerid= this.editedItem.Manager.id
      }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.mainrecordid).update({
        Full_Name: EditedUser.Full_Name,
        Name: EditedUser.Name,
        Surname: EditedUser.Surname,
        Gender: EditedUser.Gender,
        Title: EditedUser.Title
        
              });
        if(this.editedItem.Business_Unit){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.mainrecordid).update({
        Business_Unit: {Name: this.editedItem.Business_Unit.Name,id: this.editedItem.Business_Unit.id},
        Business_Unitid: this.editedItem.Business_Unit.id,
        })
        }
        if(this.editedItem.Manager){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.mainrecordid).update({
        Manager: {Full_Name: this.editedItem.Manager.Full_Name,id: this.editedItem.Manager.id},
        Managerid: this.editedItem.Manager.id,
        })
        }
              Object.assign(this.UsersArray[this.editedIndex], this.editedItem)
              this.closeUsereditDialog
              this.EditedUsersnackbar = true
        }
         else {
          const NewUser = {
            Full_Name: EditedUser.Full_Name,
            Name: EditedUser.Name,
            Surname: EditedUser.Surname,
            Email: this.Email,
            password: this.Password,
            Title: this.editedItem.Title,
            Gender: this.editedItem.Gender
          }
          
          this.$store.dispatch('signUpbyAdminImportAction', NewUser)
        }
        this.close()
      },

  }
}

</script>

 <style>
  
    
</style>
<style scoped>
.v-data-table{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#757575;
}

</style>
    