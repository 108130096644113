      <template>
<v-card flat class="transparent" :dark="AppisDarkMode">
   <!-- for Feedview 
    <v-navigation-drawer v-if="$route.name === 'SocialSitePosts'" left app height="100%" :dark="RenderDark" >
   </v-navigation-drawer>
   <v-navigation-drawer v-if="$route.name === 'SocialSitePosts'" right app height="100%" :dark="RenderDark" >
    <v-list-item  v-for="post in PostsTimeline" :key="post.itemObjkey">
      {{post.id}}
    </v-list-item>
   </v-navigation-drawer> -->
  <v-dialog v-model="FullScreenCarouselIMGDialog" fullscreen persistent>
    <v-card tile width="100%" height="100%" class="black" style="overflow:hidden;">
      <!-- <div class="d-block"> -->
      <!-- @click="CancelFullScreenCarouselIMGDialog()" -->
      
      <v-btn absolute fab style="top:25px;" top left dark class="accent growheight" @click="CancelFullScreenCarouselIMGDialog()"><v-icon>mdi-close</v-icon></v-btn>
      <v-tooltip bottom open-on-hover>
          <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs"
          absolute fab
          :style="CommentsDialogButtonStyle"
          top right dark class="secondary"
          @click="CommentssDialog = !CommentssDialog">
          <v-icon v-if="!CommentssDialog">mdi-arrow-collapse</v-icon>
          <v-icon v-if="CommentssDialog">mdi-arrow-expand</v-icon>
          </v-btn>
          </template>
          <span>{{CommentssDialog? 'Close Comments' : 'View Comments'}}</span>
      </v-tooltip>
      <v-tooltip bottom open-on-hover>
          <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs"
          v-if="UserIsActivePostOwner"
          absolute fab
          :style="TaggingButtonStyle"
          top right dark :class="ClickingandTagging? 'red' : 'primary'"
          @click="ClickingandTagging? DeactivateClickandTag() : ActivateClickandTag()">
          <v-icon>mdi-tag-faces</v-icon>
          </v-btn>
          </template>
          <span>{{ClickingandTagging? 'Close Tagging' : 'Tag People or Comment'}}</span>
      </v-tooltip>

      <v-tooltip bottom open-on-hover>
          <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs"
          absolute v-if="ActiveMapTags && ActiveMapTags.length>0"
          fab :style="ViewTagsButtonStyle" top right dark :class="ViewingTags? 'red' : 'accent'"
          @click="ViewingTags = !ViewingTags">
          <v-icon>mdi-comment-eye</v-icon>
          </v-btn>
          </template>
           <span>{{ViewingTags? 'Close Tags' : 'View Tags'}}</span>
      </v-tooltip>    
    <v-btn v-if="ActivePostImages[CarouselIMG-1]" absolute fab :style="ArrowLeftStyle"  top left dark class="accent growleft" @click="GoBackCarouselIMG()">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn v-if="ActivePostImages[CarouselIMG+1]" absolute fab :style="ArrowRightStyle" top right dark class="accent growright" @click="AdvanceCarouselIMG()">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-right</v-icon>
    </v-btn>
       <img v-if="ActivePostImages[CarouselIMG] && ActivePostImages[CarouselIMG].fileurl"
       :src="ActivePostImages[CarouselIMG].fileurl" ref="myimagemap" :id="ActivePost.id+'imagemap'+CarouselIMG"
        style="object-fit: scale-down;width:100%;height:100%;z-index:1;"
       alt="Workplace" usemap="#workmap">      
       <v-icon large color="links" class="soloactionicon" v-if="ClickandTagAction" :style="'position:absolute;top:'+ClickTopPosition+'px;left:'+ClickLeftPosition+'px;'">mdi-pin</v-icon>
        <v-btn-toggle 
        v-if="ClickandTagAction"
              v-model="tagaction"
              class="transparent"
              rounded dark
              :style="'position:absolute;top:'+ClickTopPosition+'px;left:'+ClickLeftPosition+'px;'"
            >
          <span v-for="tagbtn in ClickandTagButtons" :key="tagbtn.itemObjKey">
            <v-menu max-width="400" :left="tagbtn.Left" :right="tagbtn.Right"  :close-on-content-click="false" :close-on-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large fab v-bind="attrs" dark :class="tagbtn.Class" :style="'top:'+tagbtn.TopPosition+'px;left:'+tagbtn.LeftPosition+'px;'" v-on="on" ><v-icon>{{tagbtn.Icon}}</v-icon></v-btn>
                </template>
                  <v-card>
                    <v-card-title>
                      {{tagbtn.MethodTitle}}
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <v-autocomplete v-if="tagbtn.HasArray" :items="tagbtn.ArrayContent"
                          :label="tagbtn.ArrayIdentifierDisplay"
                          :item-text="tagbtn.ArrayIdentifier"
                          v-model="tagbtn.SelectedItem"
                          return-object/>
                          <div id="tagbtncomment" contenteditable="true" v-if="tagbtn.HasComment" style="width:200px;" :class="AppisDarkMode? 'postinputboxdark' : 'postinputbox'"/>
                        
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer>
                      </v-spacer>
                      <v-btn v-if="tagbtn.SelectedItem" @click="ProcessTagging(tagbtn,ActivePost,ActivePostImages[CarouselIMG])">Tag</v-btn>
                      <v-btn  v-if="tagbtn.HasComment" @click="ProcessTagging(tagbtn,ActivePost,ActivePostImages[CarouselIMG])">Post</v-btn>
                    </v-card-actions>
                  </v-card>
            </v-menu>
          </span>
    </v-btn-toggle>
    
      <map name="workmap" v-if="ActivePostImages[CarouselIMG] && ActivePostImages[CarouselIMG].MapTags && ActivePostImages[CarouselIMG].MapTags.length>0">
        <div class="maptag" :style="maptag.Style" v-for="maptag in ActiveMapTags" :key="maptag.itemObjKey">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
        <v-btn :class="ViewingTags? '' : 'maptagitem'"
          v-bind="attrs"
          v-on="on"
          :to="maptag.TagObject? maptag.TagObject.Path : ''"
          fab>
          <v-icon  >
            {{maptag.TagIcon}}
          </v-icon>
        </v-btn>
        </template>
        <span v-if="maptag.TagObject">
            {{maptag.TagObject[maptag.TagObject.Identifier]}}
        </span>
        <div style="width:200px;" v-if="maptag.HasTagContent" v-html="maptag.Content">
          
        </div>
        </v-tooltip>
        </div>
      </map>
      <!-- </div> -->
    </v-card>
    <v-navigation-drawer id="commentsnavbar"  app right v-model="CommentssDialog">
          <v-card-title v-if="EditingCarousel" class="mx-3">
        
        <v-list-item>
          <v-list-item-avatar>
            
              <img v-if="UserRecord.Profile_Pic"
                :src="UserRecord.Profile_Pic"
                :alt="UserRecord.Full_Name"
                style="object-fit: cover;"
            >
            <img v-if="!UserRecord.Profile_Pic"
                src='@/assets/BlankProfilePic.png'
                :alt="UserRecord.Full_Name"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              <b><router-link :to="'/SiteMember/'+UserRecord.id">{{UserRecord.Full_Name}}</router-link></b>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
      </v-card-title>
      <v-card-title v-if="!EditingCarousel" class="mx-3">
        <v-list-item>
          <v-list-item-avatar>
              <img v-if="ActivePost.creatorimg"
                :src="ActivePost.creatorimg"
                :alt="ActivePost.creatorname"
                style="object-fit: cover;"
            >
            <img v-if="!ActivePost.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="ActivePost.creatorname"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              
              <v-list-item-subtitle>
                <b><router-link :to="'/SiteMember/'+ActivePost.Creatorid">{{ActivePost.creatorname}}</router-link></b>
              </v-list-item-subtitle>
              <v-list-item-subtitle style="font-size:10px" v-if="ActivePostImages[CarouselIMG] && ActivePostImages[CarouselIMG].Modified_On && ActivePostImages[CarouselIMG].Modified_On.toDate">
                {{ActivePostImages[CarouselIMG].Modified_On.toDate()}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
          actually tags may come in here -->
      </v-card-title>
      <v-divider></v-divider>
        <SocialCommentsandLikes class="mx-3" :IsChild="true" :ChildProp="'Name'" :Child="ActivePostImages[CarouselIMG]" :HideSummary="false" :Flat="true" :ChildType="'Post Image'"
        :ChildArrayProp="'Images'"  :CanSocial="UserCanSocialTopic"
        :PathName="'SitePosts'" :panel="0" :notificationitem="'Post'" :RelatedType="'Site Post Images'" :SocialItem="ActivePost" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
          </v-navigation-drawer>
  </v-dialog>

  <v-dialog v-model="PostImagesCarousel"  fullscreen persistent>

    <v-card >
      
      <v-btn absolute fab style="top:25px;" top right dark class="accent" @click="PostImagesCarousel = !PostImagesCarousel,ActivePost = ''"><v-icon>mdi-close</v-icon></v-btn>
      <v-card-title v-if="EditingCarousel">
        
        <v-list-item>
          <v-list-item-avatar>
            
              <img v-if="UserRecord.Profile_Pic"
                :src="UserRecord.Profile_Pic"
                :alt="UserRecord.Full_Name"
                style="object-fit: cover;"
            >
            <img v-if="!UserRecord.Profile_Pic"
                src='@/assets/BlankProfilePic.png'
                :alt="UserRecord.Full_Name"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              <b><router-link :to="'/SiteMember/'+UserRecord.id">{{UserRecord.Full_Name}}</router-link></b>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
      </v-card-title>
      <v-card-title v-if="!EditingCarousel">
        <v-list-item>
          <v-list-item-avatar>
              <img v-if="ActivePost.creatorimg"
                :src="ActivePost.creatorimg"
                :alt="ActivePost.creatorname"
                style="object-fit: cover;"
            >
            <img v-if="!ActivePost.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="ActivePost.creatorname"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              
              <v-list-item-subtitle>
                <b><router-link :to="'/SiteMember/'+ActivePost.Creatorid">{{ActivePost.creatorname}}</router-link></b>
              </v-list-item-subtitle>
              <v-list-item-subtitle style="font-size:10px" v-if="ActivePostImages[CarouselIMG] && ActivePostImages[CarouselIMG].Modified_On && ActivePostImages[CarouselIMG].Modified_On.toDate">
                {{ActivePostImages[CarouselIMG].Modified_On.toDate()}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
          actually tags may come in here -->
      </v-card-title>
      <v-card-text>
        <v-row>
        <v-flex xl8 lg8 md8 sm12 xs12>
    <v-carousel v-model="CarouselIMG" :height="ImageMaxHeight+150">
     <v-carousel-item
        class="black"
        @click="ActivateFullScreenCarousel()"
        v-for="(item,i) in ActivePostImages"
        :key="i"
        style="align-self:center;"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      <v-img :src="$vuetify.breakpoint.xs ||$vuetify.breakpoint.sm ? item.ThumbURL : item.fileurl" contain height="100%"></v-img>
     
      </v-carousel-item>
    </v-carousel>
        </v-flex>
        <v-flex xl3 lg4 md4 sm12 xs12>
        <SocialCommentsandLikes :IsChild="true" :ChildProp="'Name'" :Child="ActivePostImages[CarouselIMG]" :HideSummary="false" :Flat="true" :ChildType="'Post Image'"
        :ChildArrayProp="'Images'"  :CanSocial="UserCanSocialTopic"
        :PathName="'SitePosts'" :panel="0" :notificationitem="'Post'" :RelatedType="'Site Post Images'" :SocialItem="ActivePost" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
        </v-flex>
        </v-row>
      </v-card-text>
  
      <v-card-actions v-if="ActivePostImages[CarouselIMG]">
        <v-list dense class="transparent" width="100%">
         
          <v-list-item v-if="EditingCarousel">
            <v-textarea v-model="ActivePostImages[CarouselIMG].Description" label="Description"/>
       
          </v-list-item>
          <v-list-item v-if="!EditingCarousel && ActivePostImages[CarouselIMG].Description">
            {{ActivePostImages[CarouselIMG].Description}}
       
          </v-list-item>
        </v-list>
      </v-card-actions>
      <v-card-actions>
      </v-card-actions>
    </v-card>

  </v-dialog>
  
  <v-card flat id="postwrapper" class="transparent" v-if="$route.name !== 'SiteMeetupSingle' || $route.name === 'SiteMeetupSingle' && userLoggedIn.CheckedInMeetupid === $route.params.id">
      <v-layout row class="justify-center my-1">
          <v-btn-toggle dark id="PostInsertButtons">
            <!-- need to still replace  style="display:none" -->
                <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" multiple @change="getImage($event)" hidden ref="postimage">           
              <v-btn icon small @click="$refs.postimage.click()">
                <v-icon small>mdi-file-image</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="link()">mdi-link</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="addphone()">mdi-phone</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="addemail()">mdi-email</v-icon>
              </v-btn>
              <v-btn icon small onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon small>mdi-format-list-bulleted</v-icon>
              </v-btn>
        </v-btn-toggle>
    </v-layout>                        
      <v-layout row class="justify-center">  
        <v-card flat :width="inputwidth"  class="transparent">   
          <v-card-text v-if="selectedImagespreview.length>0">
            <v-layout row class="align-center mx-3">
              <v-img
                    :src="selectedImagespreview[0].fileurl"                   
                    aspect-ratio="1"
                    max-height="400"
                    contain
                    class="transparent"
                  />
              
            </v-layout>
            <v-layout row class="align-center mx-3">
                 <v-col
                v-for="(card,i) in selectedImagespreview.slice(0,ImagesSlice)"
                :key="card.itemObjKey"
                :cols="ImagesColumns"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-2"  @click="OpenCarousel(card,i,true)"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.fileurl"                   
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                     <div
                      v-if="hover && EditingMultipleFileUpload1"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      Edit
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
                </v-layout>

          </v-card-text>  
          <!-- <v-btn disabled @click="OnceOfImageCountRestructure()">OnceOfImageCountRestructure</v-btn>
          <v-btn disabled @click="OnceOffIMGTaggedUserempties()">OnceOffIMGTaggedUserempties</v-btn>
          <v-btn disabled @click="OnceOfImageTagRestructure()">OnceOfImageTagRestructure</v-btn>          
          <v-btn disabled @click="OnceOffIMGTaggedtoPostTagged()">OnceOffIMGTaggedtoPostTagged</v-btn> -->
          
        
          <div flat v-if="PostingEnabled" contenteditable="true" :class="AppisDarkMode? 'postinputboxdark' : 'postinputbox'"  id="postinput">
          </div> 
          <v-card-actions class="my-1"  v-if="PostingEnabled" >
            <v-spacer></v-spacer>
            <v-checkbox :disabled="$route.name === 'SiteMeetupSingle'" v-if="userLoggedIn.CheckedInMeetupid" v-model="PosttoMeetup" :label="'Post to '+userLoggedIn.CheckedinMeetupName"/>
            <v-btn @click="CreatePost()" id="CreatePostButton" text>
              <!-- need to still replace  style="display:none" -->
              Post</v-btn><v-btn @click="CancelPost()"  style="display:none" id="CancelPostButton" icon ><v-icon color="red">mdi-cancel</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-card>
  <!-- <v-dialog v-model="GalleryPostImagesCarousel"  fullscreen persistent>
    <template v-slot:activator="{ on }"> -->
    <v-card flat class="transparent" v-if="GalleryTimeline" style="padding-bottom:50px;">
      <v-card-title>
                  Gallery
                </v-card-title>
    <v-row>
      
    <v-col
      v-for="(img,index) in SocialSitePostsGallery"
      :key="img.itemObjKey"
      class="d-flex child-flex"
      :cols="PostsGallerySize"
    >
      <v-img  v-on="on" @click="ActivateFullScreenCarousel(true,index)" 
        :src="img.ThumbURL"
        aspect-ratio="1"
        class="grey lighten-2"
        style="border-radius: 25px;cursor: pointer;"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>    
    </v-col>
  </v-row>
  </v-card>
    <!-- </template>
    <v-card >
      <v-btn absolute fab style="top:25px;" top right dark class="accent" @click="GalleryPostImagesCarousel = !GalleryPostImagesCarousel"><v-icon>mdi-close</v-icon></v-btn>
     
      <v-card-title v-if="EditingCarousel">
        
        <v-list-item>
          <v-list-item-avatar style="object-fit: contain;">
              <img v-if="UserRecord.Profile_Pic"
                :src="UserRecord.Profile_Pic"
                :alt="UserRecord.Full_Name"
                style="object-fit: cover;"                
            >
            <img v-if="!UserRecord.Profile_Pic"
                src='@/assets/BlankProfilePic.png'
                :alt="UserRecord.Full_Name"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              <b><router-link :to="'/SiteMember/'+UserRecord.id">{{UserRecord.Full_Name}}</router-link></b>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
      </v-card-title>
      <v-card-title v-if="!EditingCarousel">
        <v-list-item  v-if="SocialSitePostsGallery && SocialSitePostsGallery[CarouselIMG] && SocialSitePostsGallery[CarouselIMG].postobj">
          <v-list-item-avatar>
              <img v-if="SocialSitePostsGallery[CarouselIMG].postobj.creatorimg"
                :src="SocialSitePostsGallery[CarouselIMG].postobj.creatorimg"
                :alt="SocialSitePostsGallery[CarouselIMG].postobj.creatorname"
                style="object-fit: cover;"
            >
            <img v-if="SocialSitePostsGallery[CarouselIMG].postobj && !SocialSitePostsGallery[CarouselIMG].postobj.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="SocialSitePostsGallery[CarouselIMG].postobj.creatorname"
                style="object-fit: cover;"
            >
            
          </v-list-item-avatar>
            <v-list-item-content style="font-size:10px">
              <b><router-link :to="'/SiteMember/'+SocialSitePostsGallery[CarouselIMG].postobj.Creatorid">{{SocialSitePostsGallery[CarouselIMG].postobj.creatorname}}</router-link></b>
              <span v-if="SocialSitePostsGallery[CarouselIMG].postobj.Meetuptitle">
                while at <b><router-link :to="'/Meetup/'+SocialSitePostsGallery[CarouselIMG].postobj.Meetupid">{{SocialSitePostsGallery[CarouselIMG].postobj.Meetuptitle}}</router-link></b>
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer><span class="caption">{{new Date()}}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
        <v-flex xl8 lg8 md8 sm12 xs12>
          <v-carousel v-model="CarouselIMG" :height="ImageMaxHeight+150">
          <v-carousel-item
              class="black"
              v-for="img in SocialSitePostsGallery"
              :key="img.itemObjKey"
              style="align-self:center;"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
            <v-img :src="$vuetify.breakpoint.xs ||$vuetify.breakpoint.sm ? img.ThumbURL : img.fileurl" contain height="100%"></v-img>
          
            </v-carousel-item>
          </v-carousel>
        </v-flex>
       
        <v-flex xl4 lg4 md4 sm12 xs12 v-if="SocialSitePostsGallery && SocialSitePostsGallery[CarouselIMG] && SocialSitePostsGallery[CarouselIMG].postobj">
          
        <SocialCommentsandLikes :IsChild="true" :ChildProp="'Name'" :Child="SocialSitePostsGallery[CarouselIMG]" :HideSummary="false" :Flat="true" :ChildType="'Post Image'"
        :ChildArrayProp="'Images'"  :CanSocial="UserCanSocialTopic"
        :PathName="'SitePosts'" :panel="0" :notificationitem="'Post'" :RelatedType="'Site Post Images'" :SocialItem="SocialSitePostsGallery[CarouselIMG].postobj" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
        </v-flex>
        </v-row>
      </v-card-text>
  
      <v-card-actions v-if="ActivePostImages[CarouselIMG]">
        <v-list dense class="transparent" width="100%">
         
          <v-list-item v-if="EditingCarousel">
            <v-textarea v-model="ActivePostImages[CarouselIMG].Description" label="Description"/>
       
          </v-list-item>
          <v-list-item v-if="!EditingCarousel && ActivePostImages[CarouselIMG].Description">
            {{ActivePostImages[CarouselIMG].Description}}
       
          </v-list-item>
        </v-list>
      </v-card-actions>
      <v-card-actions>
      </v-card-actions>
    </v-card>
    </v-dialog> -->
  
  
 <v-card flat class="transparent" v-if="!GalleryTimeline">
   <v-row
       v-for="post in PostsTimeline" :key="post.itemObjkey"
      align="center"
      >
      <v-card style="min-height: 100vh;" outlined class="mx-1 my-1" width="100%" :id="post.id">
        <v-card-title >
          <v-list dense :class="!PostsbyApproval || PostsbyApproval && post.Approved ? 'transparent' : 'transparent'" width="100%">
            <v-list-item v-if="PostsbyApproval && !post.Approved && UserisGroupAdmin">
             <v-chip @click="ApprovegroupPost(post)" small color="success" class="white--text" v-on="on" v-if="UserRecord.id">
                  Approve<v-icon small>mdi-check</v-icon>
                </v-chip>
            </v-list-item>
          <v-list-item>
          <v-list-item-avatar>
              <img v-if="post.creatorimg"
                :src="post.creatorimg"
                :alt="post.creatorname"
                style="object-fit: cover;"
            >
            <img v-if="!post.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="post.creatorname"
            >
            
          </v-list-item-avatar>
             <v-list-item-content style="font-size:10px" v-if="post.Creatorid !== post.WallID && UserorGroupID !== post.WallID && post.WallType === 'Group'">
              <span><b><router-link :to="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</router-link></b> on <v-chip small :to="'/'+post.WallType+'/'+post.WallID">{{post.ShareName}}</v-chip></span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.Creatorid !== post.WallID && UserorGroupID !== post.WallID && post.WallType === 'SiteMember'">
              <span><b><router-link :to="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</router-link></b> on <router-link :to="'/'+post.WallType+'/'+post.WallID">{{post.ShareName}}</router-link>'s Wall</span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.Creatorid === post.WallID || UserorGroupID === post.WallID">
              <span><b><router-link :to="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</router-link></b></span><span v-if="post.Meetuptitle"> while at <b><router-link :to="post.CheckedInMeetupRoute">{{post.Meetuptitle}}</router-link></b></span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.createdon">
              <span v-if="post.createdon.toDate">{{post.createdon}}</span>
              <span v-else>{{post.createdon}}</span>
            </v-list-item-content>
            <v-list-item-action v-if="userLoggedIn.id === post.WallID || userLoggedIn.id === post.Creatorid || UserMemberObj && UserMemberObj.Administrator">
              <v-menu v-model="post.DeletePostDialog" max-width="300">
               <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab icon>
                <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
               </template>
               <v-card>
                 <v-card-text>
                   Are you sure you want to delete this post entirely?
                 </v-card-text>
                 <v-card-actions>
                   <v-btn dark plain outlined class="warning" @click="post.DeletePostDialog = !post.DeletePostDialog">Cancel</v-btn>
                   <v-spacer></v-spacer>
                   <v-btn dark plain outlined class="error" @click="DeletePost(post)">Delete</v-btn>
                 </v-card-actions>
               </v-card>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          
        
           <v-list-item v-if="post.IsLink && post.InternalLink && post.ComponentName && !SharedPost">
               
             <SocialPostSharedItem :LinkID="post.LinkID" :collectionname="post.collectionname" :UserRecord="UserRecord" :Location="post.InternalLinkType"
              />
            </v-list-item>
        
            <v-list-item >
          <v-list-item-content  v-html="post.post" style="font-size: 0.7em;font-weight: 400;">
              
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="post.Images" class="black">
            <!-- :aspect-ratio="post.Images[0].DisplayRatio.Ratio"
              :max-width="450/post.Images[0].DisplayRatio.Ratio" -->
            <v-img
              :max-height="post.Images.length === 1 ? WindowHeight/1.6 : '335'"
              contain
              :width="post.Images.length === 1 ? '100%' : 150/post.Images[0].DisplayRatio.Ratio"
               @click="OpenCarousel(post,'Start')"
              :src="post.Images[0].ThumbURL"                   
              
                  />
                  <v-row class="mx-1">
                    <v-col
                      v-for="(card,i) in post.Images.slice(1,ImagesSlice)"
                      :key="card.itemObjKey"
                      class="d-flex child-flex"
                      :cols="GetImageColumns(post.Images.slice(1,ImagesSlice))"
                    >
                      <v-img
                        :src="card.ThumbURL"
                        :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                         @click="OpenCarousel(post,i)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
           <!-- <div class="layout justify-start row mx-0">
             
             <div :class="'col col-'+GetImageColumns(post.Images.slice(1,ImagesSlice))" v-for="(card,i) in post.Images.slice(1,ImagesSlice)"
                :key="card.itemObjKey" 
                :cols="ImagesColumns">
            
                  <v-img @click="OpenCarousel(post,i)"
                    :src="card.ThumbURL"                   
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                   
                  </v-img>
             </div>
           </div> -->
          </v-list-item>
      </v-list> 
        </v-card-title>
        <!-- HERE STARTS WHAT SHOULD BECOME LIKES AND COMMENTS COMP -->
              <!-- </v-card-actions> -->
              
             <SocialCommentsandLikes v-if="!SharedPost" :FromSocialPosts="true" :PathName="'SitePosts'"
             :CanSocial="UserCanSocialTopic" :notificationitem="'Post'" :RelatedType="'Site Post'"
             :SocialItem="post" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
             
           
                  <!-- HERE ENDS WHAT SHOULD BECOME LIKES AND COMMENTS COMP -->
                     
                   
              
            </v-card>  
          
            </v-row>
            <v-list-item v-if="$route.name !== 'SitePostSingle'" class="justify-center">
            <v-btn small @click="GetSitePosts(CollectionRef)">
              Load More
            </v-btn>
            </v-list-item>
          </v-card>  
  

</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SocialPostSharedItem from '@/components/SocialNetwork/SocialPostSharedItem';
import SocialCommentsandLikes from '@/components/SocialNetwork/SocialCommentsandLikes';
import SocialPostView from '@/components/SocialNetwork/SocialPostView';
export default {
    props: ['UserRecord','Slice','UserorGroupView','AppisDarkMode','UserorGroupID','UserObj','WallType','GalleryTimeline','ShareName','CanPost','UserMemberObj','PostsbyApproval'],
    components: {
      SocialPostSharedItem,
      SocialCommentsandLikes,
      SocialPostView
        },
    data() {
        return {
          lastVisible: '',  
          // LastItemElmnt: '',
          CommentssDialog: false,
          ViewingTags: false,
          ClickingandTagging: false,
          tagsize:50,
          // MapFlexDominant: 0,
          DeletePostDialog: false,        
          PosttoMeetup: true,
          PostImagestotallist: 0,
          PostImagesuploadTotal: 0,
          PostImagsrunningpercenteage: 0,
          PostImagesrunningProgress: 0,
          PostImagesuploadPercentage: 0,
          PostImageslistuploadcount: 0,
          BaseMapTags: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('sitemaptags'),
          BaseCollectionRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsiteposts'),
          CollectionRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsiteposts'),
          ImageCollectionRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsiteimages'),
          CommentsBaseRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitecomments'),
          RepliesBaseRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitereplies'),
          LikesBaseRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsitelikes'),
          NewCommentInput: '',
          NewReplyInput: '',
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
            WallID: '',
            selectedImages: '',
            selectedImagespreview: [],
            NewPostImages: [],
            PostImagesCarousel: false,
            GalleryPostImagesCarousel: false,
            EditingCarousel: false,
            CarouselIMG: '',
            ActivePostImages: [],
            ActivePost: '',
            FullScreenCarouselIMGDialog: false,
            ClickandTagAction: false,
            ClickandTagButtons: [],
            tagaction: undefined,
            ClickTopPosition: 0,
            ClickLeftPosition: 0,
            ImageTagHeight: 0,
            ImageTagWidth: 0,
            WindowWidth: 0,
            WindowHeight: 0

        }
        
    },
    mounted(){
      window.addEventListener("resize", this.ResizeWindow);
      this.postelmnt = document.getElementById("postinput") 
          if(this.postelmnt){
            document.getElementById("postinput").addEventListener("paste", function(e) {
              e.preventDefault();
              if (e.clipboardData && e.clipboardData.getData) {
                var text = e.clipboardData.getData("text/plain");
                document.execCommand("insertHTML", false, text);
              } else if (window.clipboardData && window.clipboardData.getData) {
                var text = window.clipboardData.getData("Text");
                insertTextAtCursor(text);
              }
            });
          } 
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.ResizeWindow);
    },
    computed: {
      UserisTemplateOwner(){
          return this.$store.state.TemplateOwner
        }, 
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      // LastItemInView(){
      //   console.log(this.LastItemElmnt)
      //   return this.LastItemElmnt ? this.elementInViewport(this.LastItemElmnt) :''
      // },
      // LastItemElmnt(){
      //   if(this.lastVisible){
      //     return document.getElementById(this.lastVisible.id)
      //   }
      // },
      UserisGroupAdmin(){
        return this.UserMemberObj && this.UserMemberObj.Administrator
      },
      PostingEnabled(){
        return this.$route.name !== 'SitePostSingle' && this.WallType !== 'Group' || this.$route.name !== 'SitePostSingle' && this.WallType === 'Group' && this.CanPost
      },
      ArrowRightStyle(){
        return this.CommentssDialog? 'top:'+(this.WindowHeight/2)+'px;right:'+(15+this.CommentsNavbarWidth)+'px;' : 'top:'+(this.WindowHeight/2)+'px;right:15px;'
      },
      ArrowLeftStyle(){
        return this.CommentssDialog? 'top:'+(this.WindowHeight/2)+'px;' : 'top:'+(this.WindowHeight/2)+'px;'
      },
      ViewTagsButtonStyle(){
        if(!this.$vuetify.breakpoint.xs){
          return this.CommentssDialog? 'top:25px;right:'+(135+this.CommentsNavbarWidth)+'px;' : 'top:25px;right:135px;'
        }
        else{
          return this.CommentssDialog? 'top:145px;right:'+(15+this.CommentsNavbarWidth)+'px;' : 'top:25px;right:135px;'
        }
      },
      TaggingButtonStyle(){
         if(!this.$vuetify.breakpoint.xs){
          return this.CommentssDialog? 'top:25px;right:'+(75+this.CommentsNavbarWidth)+'px;' : 'top:25px;right:75px;'
         }
         else{
           return this.CommentssDialog? 'top:85px;right:'+(15+this.CommentsNavbarWidth)+'px;' : 'top:25px;right:75px;'
         }
      },
      CommentsDialogButtonStyle(){
        return this.CommentssDialog? 'top:25px;right:'+(15+this.CommentsNavbarWidth)+'px;' : 'top:25px;right:15px;'
      },
      CommentsNavbarWidth(){
        let elmnt = document.getElementById('commentsnavbar')
        if(elmnt){
          return elmnt.offsetWidth
        }
        
      },
      UserIsActivePostOwner(){
        return this.ActivePost.WallID === this.userLoggedIn.id || this.userLoggedIn.id === this.ActivePost.Creatorid
      },
      ScreenOrientation(){
        if((screen.width-screen.height) > 0){
            return 'Landscape'          
          }
          else{
            return 'Portrait'
          }
      },
      CanvasStyle(){
        if(this.ImageDom && this.ImageDom.dominant === 'width'){
          return 'position:absolute;top:'+this.MapSlaveOffsetTotal+'px;left:'+this.MapDomOffsetTotal+'px;border-color:red;border-style: dotted;z-index:0;'
        }
        else{
          return 'position:absolute;top:'+this.MapDomOffsetTotal+'px;left:'+this.MapSlaveOffsetTotal+'px;border-color:red;border-style: dotted;z-index:0;'
        }
        
      },
      windowinnerWidth(){
        return window.innerWidth
      },
      PostsGallerySize(){
        if(this.$vuetify.breakpoint.xs){
          return 6
        }
        else if(this.$vuetify.breakpoint.sm){
          return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return 3
        }
        else if(this.$vuetify.breakpoint.lg){
          return 2
        }
        else{
          return 2
        }
      },
      SocialSitePostsGallery(){
        return this.PostsTimeline.filter(post => {
          return post.Images && post.Images.length > 0
        })
        .map(post => {
          let postimages = post.Images.map((img,childindex) => {
            let imgobj = Object.assign({},img)
            imgobj.postobj = post
            imgobj.childindex = childindex
            return imgobj
          })
          // console.log(post,postimages)
          return postimages
        }).flat()
      },
      UserCanSocialTopic(){
        if(this.$route.name === 'GroupSingle'){
          // let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
          return this.PostingEnabled
        }
        else{
          return this.userLoggedIn
        }
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      ImagesColumns(){
        if(this.$vuetify.breakpoint.xl){
          return 3
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else {
          return 4
        }
      },
      ImageMaxHeight(){
        
        if(this.$vuetify.breakpoint.xl){
          return 450
        }
        else if(this.$vuetify.breakpoint.lg){
          return 350
        }
        else if(this.$vuetify.breakpoint.md){
          return 200
        }
        else {
          return 150
        }
      },
      ImagesSlice(){
        if(this.$vuetify.breakpoint.xl){
          return 9
        }
        else if(this.$vuetify.breakpoint.lg){
          return 9
        }
        else if(this.$vuetify.breakpoint.md){
          return 7
        }
        else {
          return 3
        }
      },
      ComputedSocialReplies(){
        return this.SocialReplies.map(mainreply => {
          this.SocialReplies.map(reply => {
                    if(reply.relatedid === mainreply.id){
                      let replyonmainreplycheck = mainreply.Replies.find(obj => obj.id == reply.id)
                      if(!replyonmainreplycheck){
                        mainreply.Replies.push(reply)
                      }
                    }
                  })
          return mainreply
        }).map(mainreply => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === mainreply.id){
                      let likeonmainreplycheck = mainreply.Likes.find(obj => obj.id == like.id)
                      if(!likeonmainreplycheck){
                        mainreply.Likes.push(like)
                      }
                    }
                  })
          return mainreply
        })
      },
      ComputedSocialComments(){
        return this.SocialComments.map(comment => {
          this.ComputedSocialReplies.map(reply => {
                    if(reply.relatedid === comment.id){
                      let replyoncommentcheck = comment.Replies.find(obj => obj.id == reply.id)
                      if(!replyoncommentcheck){
                        if(reply.Replies){
                          comment.Replies = comment.Replies.concat(reply.Replies)
                        }
                        comment.Replies.push(reply)
                      }
                    }
                  })
          return comment
        }).map(comment => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === comment.id){
                      let likeoncommentcheck = comment.Likes.find(obj => obj.id == like.id)
                      if(!likeoncommentcheck){
                        comment.Likes.push(like)
                      }
                    }
                  })
          return comment
        })
      },
      UsersStore(){
      return this.$store.state.UsersArray
    },
      PostsTimeline () {
        return this.Posts.map(post => {
          // this.ComputedSocialComments.map(comment => {
          //   if(comment.relatedid === post.id && !comment.IsChild){
          //     let oncheck = post.Comments.find(obj => obj.id == comment.id)
          //       if(!oncheck){
          //         post.Comments.push(comment)
          //       }
          //     }   
          //     else if(comment.relatedid === post.id && comment.IsChild && post.Images){
          //       post.Images = post.Images.map(img => {
          //         if(!img.Comments){
          //           img.Comments = []
          //         }
          //         if(comment.Child === img.Name){
          //          let oncheck = img.Comments.find(obj => obj.id == comment.id)
          //           if(!oncheck){
          //             img.Comments.push(comment)
          //           } 
          //         }
                  
          //           return img
          //       })
              
          //     }
          // })
          return post
        }).map(post => {
          // this.SocialLikes.map(like => {
          //   if(like.relatedid === post.id){
          //     let oncheck = post.Likes.find(obj => obj.id == like.id)
          //       if(!oncheck){
          //         post.Likes.push(like)
          //       }
          //     }   
          // })
          return post
        }).map(post => {
          // this.ComputedSocialReplies.map(reply => {
          //   if(reply.relatedid === post.id){
          //     let oncheck = post.Likes.find(obj => obj.id == reply.id)
          //       if(!oncheck){
          //         post.Likes.push(reply)
          //       }
          //     }   
          // })
          return post
        })
        .map(post => {
          //console.log(post.Creatorid,this.UsersStore,post)
          let postinguser = this.UsersStore.find(obj => obj.id === post.Creatorid)
          if(postinguser && postinguser.Profile_Photo){
            post.creatorimg = postinguser.Profile_Photo
          }
          return post
        })
        .map(post => {          
          if(post.createdon.toDate){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        })
        .sort((a, b) => {
            var keHeightPositionClicked = b.createdon;
            var keHeightPositionFinal = a.createdon;

            if (keHeightPositionClicked < keHeightPositionFinal) {
                return -1;
            } else if (keHeightPositionClicked == keHeightPositionFinal) {
                return 0;
            } else {
                return 1;
            }
      })
      },
      ImageMap(){
        var imagemap = document.getElementById(this.ActivePost.id+'imagemap'+this.CarouselIMG);
        return imagemap
      },
      ImageDom(){
        if(this.ActiveMap){
          let displayratio = this.ActiveMap.height/this.ActiveMap.width
          
          if(displayratio < 1){
            let ImageDOMObj = {
              dominant : 'width',
              slave: 'height',
              windowdominant: 'offsetWidth',
              windowslave: 'innerHeight',
              dompositionprop: 'LeftPosition',
              slavepositionprop: 'TopPosition',
              taggedmapdomposition: 'MapWidthPosition',
              taggedmapslaveposition: 'MapHeightPosition',
              WindowDomProp: 'WindowWidth',
              WindowSlaveProp: 'WindowHeight'
            }
            return ImageDOMObj
           }
          else if(displayratio > 1){
            let ImageDOMObj = {
              dominant : 'height',
              slave: 'width',
              windowdominant: 'offsetHeight',
              windowslave: 'innerWidth',
              dompositionprop: 'TopPosition',
              slavepositionprop: 'LeftPosition',
              taggedmapdomposition: 'MapHeightPosition',
              taggedmapslaveposition: 'MapWidthPosition',
              WindowDomProp: 'WindowHeight',
              WindowSlaveProp: 'WindowWidth'
            }
            return ImageDOMObj
          }
          else if(displayratio === 1){
            let ImageDOMObj = {
              dominant : 'height',
              slave: 'width',
              windowdominant: 'offsetHeight',
              windowslave: 'innerWidth',
              dompositionprop: 'TopPosition',
              slavepositionprop: 'LeftPosition',
              taggedmapdomposition: 'MapHeightPosition',
              taggedmapslaveposition: 'MapWidthPosition',
              WindowDomProp: 'WindowHeight',
              WindowSlaveProp: 'WindowWidth'
            }
            return ImageDOMObj
          }
        }
        
      },
      MyImageMap(){
        return this.$refs.myimagemap
      },
      MapFlexDominant(){
        // if(this.MyImageMap){
          
        //   // let elmnt = document.getElementById('imagemap')
        //   // console.log('from MapFlexDominant',this.ImageMap,this.ImageDom,elmnt)
        //   // if(elmnt){
        //     return this.MyImageMap[this.ImageDom.windowdominant]
        //   // }
         
        // }
        if(this.ImageDom){
         return this.ImageDom.dominant === 'width' ? this[this.ImageDom.WindowDomProp]-16 : this[this.ImageDom.WindowDomProp] 
        }
        
      },
      MapDominant(){
        if(!this.FlexInverts){
        return this.MapisSmallerthanFlex ? this.MapItemDOMMax : this.MapFlexDominant
        }
        else{
          return this.MapSlave/this.MapRatio
        }
      },
      MapItemDOMMax(){
        if(this.ActiveMap && this.ImageDom){
          return this.ActiveMap[this.ImageDom.dominant]
        }
      },
      MapisSmallerthanFlex(){
        if(this.MapItemDOMMax && this.MapFlexDominant){
          return this.MapFlexDominant > this.MapItemDOMMax
        }
      },
      MapPositionRatio(){
        if(this.MapisSmallerthanFlex){
          return 1
        }
        else if(this.FlexInverts){
          //console.log('this.ActiveMap[this.ImageDom.slave]',this.ActiveMap[this.ImageDom.slave])
          return this.MapSlave/this.ActiveMap[this.ImageDom.slave]
        }
        else{
          return this.MapFlexDominant/this.MapItemDOMMax
        }
      },
      MapDomOffsetTotal(){
        if(this.MapisSmallerthanFlex){
          return (this.MapFlexDominant - this.MapItemDOMMax)/2
        }
        else if(this.FlexInverts){
          return (this.MapDominant - this.MapFlexDominant)/2
        }
        else{
          return 0
        }
      },
      MapRatio(){
        
        if(this.ActiveMap){
          
          return this.ActiveMap[this.ImageDom.slave]/this.ActiveMap[this.ImageDom.dominant]
        }        
      },
      FlexRatio(){
        if(this.ImageDom){
       return this.MapFlexSlave/this.MapFlexDominant
        }
      },
      FlexInverts(){
        if(this.FlexRatio<this.MapRatio && !this.MapisSmallerthanFlex){
          return true
        }
        else{
          return false
        }
      },
      MapSlave(){
        if(!this.FlexInverts){
           return this.MapDominant*this.MapRatio
        }
        else{
          return this.MapFlexSlave-16
        }
       
      },
      MapFlexSlave(){
        // if(this.ImageDom){
        //     return window[this.ImageDom.windowslave]
        // }
        if(this.ImageDom){
         return this[this.ImageDom.WindowSlaveProp]
        }
      },
      MapSlaveOffsetTotal(){
        return (this.MapFlexSlave-this.MapSlave)/2
      },
      //imagedom
        //mapdom as in??
        //flexdom
        //mapdomoffsettotal
        //mapslaveoffsettotal
        //mapdomsmaller than flex dom
      ActiveMap(){
        return this.ActivePostImages[this.CarouselIMG]
      },
      ActiveMapTags(){
        //ActivePostImages[CarouselIMG].MapTags
        if(this.ActivePost && this.ActivePostImages && this.ActivePostImages[this.CarouselIMG] && this.ActivePostImages[this.CarouselIMG].MapTags){
          // let mapitem = this.ActivePostImages[this.CarouselIMG]
         return this.ActivePostImages[this.CarouselIMG].MapTags.map(maptag => {
           //Okay very close now
           //we do however need to ask, if the window is landscap and imagedom.dominant is width then there is a 16px padding
           //not sure if that's statically true but ...if this be the case then flex widht etc no issue. 
              //console.log(maptag)
              // if(mapitem){
                if(this.MapisSmallerthanFlex){
                  maptag[this.ImageDom.slavepositionprop] = maptag[this.ImageDom.taggedmapslaveposition]+this.MapSlaveOffsetTotal
                  maptag[this.ImageDom.dompositionprop] = maptag[this.ImageDom.taggedmapdomposition]+this.MapDomOffsetTotal
                  maptag.Style = 'top:'+(maptag.TopPosition-(this.tagsize/2))+'px;left:'+(maptag.LeftPosition-(this.tagsize/2))+'px;position:absolute;width:'+this.tagsize+'px;height:'+this.tagsize+'px;'
                }
                else if(this.FlexInverts){
                  maptag[this.ImageDom.slavepositionprop] = maptag[this.ImageDom.taggedmapslaveposition]*this.MapPositionRatio
                  maptag[this.ImageDom.dompositionprop] = (maptag[this.ImageDom.taggedmapdomposition]*this.MapPositionRatio)-this.MapDomOffsetTotal
                  maptag.Style = 'top:'+(maptag.TopPosition-(this.tagsize/2))+'px;left:'+(maptag.LeftPosition-(this.tagsize/2))+'px;position:absolute;width:'+this.tagsize+'px;height:'+this.tagsize+'px;'
                }
                else{
                  maptag[this.ImageDom.slavepositionprop] = (maptag[this.ImageDom.taggedmapslaveposition]*this.MapPositionRatio)+this.MapSlaveOffsetTotal
                  maptag[this.ImageDom.dompositionprop] = maptag[this.ImageDom.taggedmapdomposition]*this.MapPositionRatio
                  maptag.Style = 'top:'+(maptag.TopPosition-(this.tagsize/2))+'px;left:'+(maptag.LeftPosition-(this.tagsize/2))+'px;position:absolute;width:'+this.tagsize+'px;height:'+this.tagsize+'px;'
                }
              // }
              
              return maptag
            }) 
        }
        
      },

    },


    watch: {
    //   lastVisible : {
    //         handler: function(newvalue, oldvalue) {
    //           if(newvalue !== oldvalue){
    //            console.log('this.lastVisible LastItemInView',this.lastVisible)
    //             if(this.lastVisible){
    //                 this.LastItemElmnt = document.getElementById(this.lastVisible.id)
    //             }  
    //           }
                
    //         },
    //         deep: true
    //     },
    //   LastItemInView (value) {
    //     console.log('checking LastItemInView')
    //   if (value !== null && value !== undefined && value === true) {
    //    console.log('yes, LastItemInView in view')

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
    // WritingPost (value) {
    //   if (value !== null && value !== undefined && value === true) {
    //     // this.inputwidth = '90%'
    //     // let postelmnt = document.getElementById('postinput')
    //     setTimeout(() => {
    //         this.postelmnt.focus()
    //       }, 100); 

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
  },



    created(){
      if(this.$route.name === 'SocialSitePosts'){
        this.$emit('IntranetViewToggle',true)
      }
      this.WindowWidth = window.innerWidth;
      this.WindowHeight = window.innerHeight;
      
      if(this.ActiveMarketTemplate){
        this.CollectionRef = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesocialsiteposts')
        this.BaseCollectionRef = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesocialsiteposts')
        this.ImageCollectionRef = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesocialsiteimages')
        this.BaseMapTags = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesitemaptags')
        this.CommentsBaseRef = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesocialsitecomments')
        this.RepliesBaseRef = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesocialsitereplies')
        this.LikesBaseRef = db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('samplesocialsitelikes')
      }
      else if(this.ActiveSuiteTemplate){
        this.CollectionRef = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesocialsiteposts')
        this.BaseCollectionRef = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesocialsiteposts')
        this.ImageCollectionRef = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesocialsiteimages')
        this.BaseMapTags = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesitemaptags')
        this.CommentsBaseRef = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesocialsitecomments')
        this.RepliesBaseRef = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesocialsitereplies')
        this.LikesBaseRef = db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id).collection('samplesocialsitelikes')
      }
      if(this.$route.name === 'SitePostSingle'){     
        this.WallID = this.UserorGroupID        
        let docid = ''
        if(this.$route.name === 'SitePostSingle'){
          docid = this.$route.params.id
        }
        this.CollectionRef = this.CollectionRef.where(firebase.firestore.FieldPath.documentId(), '==', docid)
        this.GetSitePosts(this.CollectionRef,false,docid)
        if(this.$route.name === 'SitePostSingle'){
        setTimeout(() => {
          let panel = document.getElementById(this.$route.params.id+'commentpanel')
          console.log('commentsandlikespanel',panel) 
          panel.click()
          setTimeout(() => {
          let elmnt = document.getElementById(this.$route.params.slug)
          if(elmnt){
            elmnt.scrollIntoView()
          }
          }, 500);  
        }, 2000);
        }
        

      }
      else if(this.UserorGroupView){
        this.WallID = this.UserorGroupID
        this.CollectionRef = this.CollectionRef.where('WallID','==',this.WallID)
        if(this.PostsbyApproval && !this.UserisGroupAdmin){
          this.CollectionRef = this.CollectionRef.where('Approved','==',true)
          // if(this.$route.name !== 'SiteMemberSingle'){
            this.CollectionRef = this.CollectionRef.orderBy('createdon', 'desc')
          // }          
          this.GetSitePosts(this.CollectionRef)
        }
        else{
          // if(this.$route.name !== 'SiteMemberSingle'){
            this.CollectionRef = this.CollectionRef.orderBy('createdon', 'desc')
          // }
          this.GetSitePosts(this.CollectionRef)
        }
      }
      else if(this.$route.name === 'SiteMeetupSingle'){
        this.WallID = this.UserorGroupID
        this.PosttoMeetup = true
        this.CollectionRef = this.CollectionRef.where('Meetupid','==',this.$route.params.id).orderBy('createdon', 'desc')
        this.GetSitePosts(this.CollectionRef)
      }
      else if(this.$route.name === 'SiteMemberSingle' && this.GalleryTimeline){
        this.GetSitePosts(this.CollectionRef.where('TaggedUsers','array-contains',this.$route.params.id),true)
        this.CollectionRef = this.CollectionRef.where('Creatorid','==',this.$route.params.id)
        this.WallID = this.UserRecord.id
        this.GetSitePosts(this.CollectionRef)
      }
      else{
        this.CollectionRef = this.CollectionRef.orderBy('createdon', 'desc')
        this.WallID = this.UserRecord.id
        this.GetSitePosts(this.CollectionRef)
      }
      // alert(this.WallID)
      // this.GetSocialComments()
      // this.GetSocialLikes()
      // this.GetSocialReplies()
        
          
                  
          
        
      // }, 300);

    },

    methods: {
      // elementInViewport(el) {
      //   console.log('LastItemInView elmnt in viewport ',el)
      //   var top = el.offsetTop;
      //   var left = el.offsetLeft;
      //   var width = el.offsetWidth;
      //   var height = el.offsetHeight;

      //   while(el.offsetParent) {
      //     el = el.offsetParent;
      //     top += el.offsetTop;
      //     left += el.offsetLeft;
      //   }

      //   return (
      //     top >= window.pageYOffset &&
      //     left >= window.pageXOffset &&
      //     (top + height) <= (window.pageYOffset + window.innerHeight) &&
      //     (left + width) <= (window.pageXOffset + window.innerWidth)
      //   );
      // },
      ApprovegroupPost(post){
        post.Approved = true
        this.BaseCollectionRef.doc(post.id).update({
          Approved: true
        })
      },
      AdvanceCarouselIMG(){
        if(!this.GalleryTimeline){
          this.CarouselIMG++
        }
        else{
          let newpostobj = this.SocialSitePostsGallery[this.CarouselIMG-1+2].postobj
          this.ActivePost = newpostobj
          this.CarouselIMG++
        }
      },
      GoBackCarouselIMG(){
        if(!this.GalleryTimeline){
          this.CarouselIMG--
        }
         else{
          let newpostobj = this.SocialSitePostsGallery[this.CarouselIMG-1].postobj
          this.ActivePost = newpostobj
          this.CarouselIMG--
        }
      },
      OnceOffIMGTaggedtoPostTagged(){
        this.PostsTimeline.map(post => {
          if(post.Images){
            post.Images.map(img => {
              let TaggedUsers = img.TaggedUsers
            let postTaggedUsers = []
              if(post.TaggedUsers){
                postTaggedUsers = post.TaggedUsers
              }
                TaggedUsers.map(taggeduserid => {
                  let oncheck = postTaggedUsers.find(obj => obj === taggeduserid)
                  if(!oncheck){
                    postTaggedUsers.push(taggeduserid)
                  }
                })
                  this.BaseCollectionRef.doc(post.id).update({
                    TaggedUsers: postTaggedUsers
                  })
                  console.log(post.id,postTaggedUsers)
              })
            }
          })
      },
      OnceOffIMGTaggedUserempties(){
        this.PostsTimeline.map(post => {
          if(post.Images){
            post.Images.map(img => {
              let TaggedUsers = []
              if(!img.MapTags){
                 this.ImageCollectionRef.doc(img.id).update({
                  TaggedUsers: TaggedUsers
                })
              }
            })
          }
        })
      },
      OnceOfImageTagRestructure(){
        this.PostsTimeline.map(post => {
          if(post.Images){
            post.Images.map(img => {
              let TaggedUsers = []
              if(img.MapTags){
                TaggedUsers = img.MapTags.filter(tag => {
                  return tag.TagObjectid
                })
                .map(tag => {
                  return tag.TagObjectid
                }).flat()
                this.ImageCollectionRef.doc(img.id).update({
                  TaggedUsers: TaggedUsers
                })
               console.log('hasmaptags',img,TaggedUsers)
              }
            })
          }
        })
      },
      OnceOfImageCountRestructure(){
        console.log(this.PostsTimeline)
        this.PostsTimeline.map(post => {
          let ImageCount = 0
          if(post.Images){
            ImageCount = post.Images.length
          }
          this.BaseCollectionRef.doc(post.id).update({
            ImageCount: ImageCount
          })
          .then(doc => {
            if(post.Images){
              post.Images.map(img => {
                let newimgobj = Object.assign({},img)
                newimgobj.Comments = []
                newimgobj.Likes = []
                newimgobj.relatedid = post.id
                newimgobj.Type = 'Social Post'
                newimgobj.WallID = post.WallID
                this.ImageCollectionRef.add(newimgobj).then(imgdoc => {
                  console.log('created new image but where are the maptags? created under '+imgdoc.id)
                })
              })
            }
          })
        })
      },
      ActivateClickandTag(){
        let map = document.getElementById(this.ActivePost.id+'imagemap'+this.CarouselIMG)   
        map.addEventListener("click", this.ClickandTag);
        this.ClickingandTagging = true
        this.ViewingTags = true
      },
      DeactivateClickandTag(){
        let map = document.getElementById(this.ActivePost.id+'imagemap'+this.CarouselIMG)   
        map.removeEventListener("click", this.ClickandTag);
        this.ClickingandTagging = false
        this.ClickandTagAction = false
        this.ViewingTags = false
        //console.log(this.ActiveMapTags.length)
      },
      ResizeWindow(event){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
      },
      DeletePost(post){
        this.BaseCollectionRef.doc(post.id).delete().then(doc => {
          let postobj = this.Posts.find(obj => obj.id === post.id)
          if(postobj){
           let index = this.Posts.indexOf(postobj) 
           this.Posts.splice(index,1)
          }
        })
      },  
      
      
      GetMapTags(post,activemapobj){
        //console.log(activemapobj)
        if(!activemapobj.MapTags){
          activemapobj.MapTags = []
        }
        this.BaseMapTags.where('Postid','==',post.id).where('MapItem.Name','==',activemapobj.Name).onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if(change.type === 'added'){
              let maptagobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              
              let oncheck = activemapobj.MapTags.find(obj => obj.id === maptagobj.id)
              if(!oncheck){
                activemapobj.MapTags.push(maptagobj)
              }
              
            }
          })
        })
      },
      SaveTagging(tagitem){
        console.log('tagitem '+tagitem)
        this.ClickandTagAction = false
        let NewComment = {
                content: tagitem.Content,
                Likes: [],
                Shares: [],
                tags: [],
                Creator: tagitem.Created_By,          
                Creatorid: tagitem.Created_Byid,
                creatorname: tagitem.Created_By.Full_Name,
                createdon: tagitem.Created_On,
                Replies: [],
                relatedtype: 'SocialPosts',
                relatedid: tagitem.Postid,
                relatedowner: tagitem.PostOwnerid                
              }
              if(this.UserRecord.Profile_Photo){
                NewComment.creatorimg = this.UserRecord.Profile_Photo
              }
        if(!tagitem.MapItem.MapTags){
          tagitem.MapItem.MapTags = []
        }
        
        console.log(tagitem,this.ActivePostImages)
        return this.BaseMapTags.add(tagitem).then(doc => {
          console.log('added new sitemaptags doc '+doc.id)
          this.ClickandTagAction = false
          //tagitem.MapItem.MapTags.push(tagitem)
        })
      },
      CommenttoMap(tagbtn,post,mapitem){
        let vm = this
        let elmnt = document.getElementById('tagbtncomment')
        let content = elmnt.innerHTML
    
        let tagitem = {
          MapHeightPosition: tagbtn.MapHeightPosition,
          MapWidthPosition: tagbtn.MapWidthPosition,
          HasTagObject: false,
          HasTagContent: true,
          Content: content,
          Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
          Created_Byid: vm.UserRecord.id,
          Created_On: new Date(), 
          Postid: post.id,
          PostOwner: post.Creator,
          PostOwnerid: post.Creatorid,
          TagIcon: 'mdi-comment-outline'
        }
        if(mapitem){
          // tagitem.MapItem = mapitem
          tagitem.MapItem = Object.assign({},mapitem)
          delete tagitem.MapItem.MapTags
        }
        this.SaveTagging(tagitem)
        
      },
      TagUser(user,tagbtn,post,mapitem){  
        console.log(mapitem)
        let vm = this      
        let tagitem = {
          MapHeightPosition: tagbtn.MapHeightPosition,
          MapWidthPosition: tagbtn.MapWidthPosition,
          HasTagObject: true,
          HasTagContent: false,
          TagObject: {Full_Name: user.Full_Name, id: user.id,Array: tagbtn.ArrayName,Identifier: tagbtn.ArrayIdentifier, Path:'/SiteMember/'+user.id},
          TagObjectid: user.id,
          Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
          Created_Byid: vm.UserRecord.id,
          Created_On: new Date(), 
          Postid: post.id,
          PostOwner: post.Creator,
          PostOwnerid: post.Creatorid,
          Content: vm.UserRecord.Full_Name+' tagged '+user.Full_Name+' on a photo',
          TagIcon: 'mdi-face-recognition'
        }
        if(mapitem){
          tagitem.MapItem = Object.assign({},mapitem)
          delete tagitem.MapItem.MapTags
        }
        if(this.userLoggedIn.id !== tagitem.TagObjectid){
          this.NotifyTaggedUser(tagitem)
        }
        let TaggedUsers = mapitem.TaggedUsers
        TaggedUsers.push(tagitem.TagObjectid)
        this.UpdateImageTaggedUsers(mapitem,TaggedUsers)
        
        this.SaveTagging(tagitem)
      },
      UpdateImageTaggedUsers(mapitem,TaggedUsers){
        this.ImageCollectionRef.doc(mapitem.id).update({
          TaggedUsers: TaggedUsers
        })
        let postTaggedUsers = []
        let postobj = this.PostsTimeline.find(obj => obj.id === mapitem.relatedid)
        if(postobj){
          if(postobj.TaggedUsers){
            postTaggedUsers = postobj.TaggedUsers
          }
            TaggedUsers.map(taggeduserid => {
              let oncheck = postTaggedUsers.find(obj => obj === taggeduserid)
              if(!oncheck){
                postTaggedUsers.push(taggeduserid)
              }
            })
          this.BaseCollectionRef.doc(postobj.id).update({
            TaggedUsers: postTaggedUsers
          })
        }
        //so now every image needs these props
        // newimgobj.TaggedUsers = [createdbyid??]
        // newimgobj.Comments = []
        // newimgobj.Likes = []
        // newimgobj.relatedid = post.id
        // newimgobj.Type = 'Social Post'
      },
      NotifyTaggedUser(tagitem){
        const NewNotification = {
                    postid: tagitem.Postid,
                    Type: 'Image Tag',
                    Header: this.userLoggedIn.Full_Name+' posted on your wall',
                    Content: tagitem.Content,
                    CreatorFullName: this.userLoggedIn.Full_Name,
                    CreatorID: this.userLoggedIn.id,
                    Owner: tagitem.TagObject.Full_Name,
                    Ownerid: tagitem.TagObjectid,
                    Read: false,
                    Path: '/SitePosts/'+tagitem.Postid,
                    Message: 'Click to view Post',
                    Date: new Date()
                    }
                    console.log(NewNotification)
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
      },
      ProcessTagging(tagbtn,post,mapitem){
        if(tagbtn.SelectedItem){
         this[tagbtn.Method](tagbtn.SelectedItem,tagbtn,post,mapitem) 
        }
        else{
          this[tagbtn.Method](tagbtn,post,mapitem)
        }
        
      },
      CancelFullScreenCarouselIMGDialog(){
        this.DeactivateClickandTag()
        document.removeEventListener('keydown', this.FullScreenNavigate)
        this.FullScreenCarouselIMGDialog = false     
        this.ClickandTagAction = false
           
        
      },
      ActivateFullScreenCarousel(fromgallery,index){
        if(!fromgallery){
          //console.log(this.CarouselIMG)
          this.CarouselIMG++
          this.CarouselIMG--
          this.FullScreenCarouselIMGDialog = true
          setTimeout(() => {
              //  console.log('ImageMap',this.ImageMap)
              //  console.log('ActiveMap',this.ActiveMap)  
              //  console.log('MapFlexDominant',this.MapFlexDominant)             
              //  console.log('MapItemDOMMax',this.MapItemDOMMax)
              //  console.log('MapDominant',this.MapDominant)
              //  console.log('MapisSmallerthanFlex',this.MapisSmallerthanFlex)
              //  console.log('MapPositionRatio',this.MapPositionRatio)
              //  console.log('MapDomOffsetTotal',this.MapDomOffsetTotal)
              //  console.log('this.ImageDom.slave,this.ImageDom.dominant',this.ImageDom.slave,this.ImageDom.dominant)
              //  console.log('imagedom props on this.MyImageMap',this.ActiveMap[this.ImageDom.slave],this.ActiveMap[this.ImageDom.dominant])
              //  console.log('MapRatio',this.MapRatio)
              //  console.log('MapSlave',this.MapSlave)
              //  console.log('MapFlexSlave',this.MapFlexSlave)
              //  console.log('MapSlaveOffsetTotal',this.MapSlaveOffsetTotal)
              // console.log('FlexRatio',this.FlexRatio)
              // console.log('FlexInverts',this.FlexInverts)
          
          document.addEventListener('keydown', this.FullScreenNavigate);  
          }, 1000);   
        }
        else{
          this.ActivePost = ''
          this.ActivePostImages = []
          this.CarouselIMG = index
          console.log(this.SocialSitePostsGallery,index)
          this.ActivePost = this.SocialSitePostsGallery[index].postobj
          this.ActivePostImages = this.SocialSitePostsGallery
          this.CarouselIMG++
          this.CarouselIMG--
          this.FullScreenCarouselIMGDialog = true
          document.addEventListener('keydown', this.FullScreenNavigate);
        }
        
        
      },
      ClickandTag(event){
        var WidthPositionClicked = event.clientX
        var WidthPositionFinal = 0
        var HeightPositionClicked = event.clientY
        var HeightPositionFinal = 0
        // console.log('HeightPositionClicked',HeightPositionClicked,'WidthPositionClicked',WidthPositionClicked)
        // console.log('this.MapisSmallerthanFlex',this.MapisSmallerthanFlex)
        // console.log('this.FlexInverts',this.FlexInverts)
        // console.log('this.MapRatio',this.MapRatio)
        // console.log('this.MapPositionRatio',this.MapPositionRatio)
        // console.log('this.FlexRatio',this.FlexRatio)
        // console.log('this.MapDomOffsetTotal',this.MapDomOffsetTotal)
        // console.log('this.MapSlaveOffsetTotal',this.MapSlaveOffsetTotal)
        if(this.ImageDom.dominant === 'width'){
          if(!this.MapisSmallerthanFlex && !this.FlexInverts){
            WidthPositionFinal = WidthPositionClicked/this.MapPositionRatio
            HeightPositionFinal = (HeightPositionClicked-this.MapSlaveOffsetTotal)/this.MapPositionRatio
          }
          else if(this.FlexInverts){
            WidthPositionFinal = (WidthPositionClicked+this.MapDomOffsetTotal)/this.MapPositionRatio
            HeightPositionFinal = HeightPositionClicked/this.MapPositionRatio
          }
          else{        
            WidthPositionFinal = WidthPositionClicked-this.MapDomOffsetTotal
            if(this.ScreenOrientation === 'Landscape'){
              WidthPositionFinal = WidthPositionFinal-12
            }
            HeightPositionFinal = HeightPositionClicked-this.MapSlaveOffsetTotal
          }
        }
        else if(this.ImageDom.dominant === 'height'){
          if(!this.MapisSmallerthanFlex && !this.FlexInverts){
            WidthPositionFinal = (WidthPositionClicked-this.MapSlaveOffsetTotal)/this.MapPositionRatio
            HeightPositionFinal = HeightPositionClicked/this.MapPositionRatio
          }
          else if(this.FlexInverts){
            WidthPositionFinal = (WidthPositionClicked/this.MapPositionRatio)
            if(this.ScreenOrientation === 'Landscape'){
              WidthPositionFinal = WidthPositionFinal-12
            }
            HeightPositionFinal = (HeightPositionClicked+this.MapDomOffsetTotal)/this.MapPositionRatio
          }
          else{        
            WidthPositionFinal = WidthPositionClicked-this.MapSlaveOffsetTotal
            HeightPositionFinal = HeightPositionClicked-this.MapDomOffsetTotal
          }
        }
        this.ImageTagHeight = HeightPositionFinal
        this.ImageTagWidth = WidthPositionFinal
        this.PrepareclickButtons(event)
        //console.log(HeightPositionFinal,WidthPositionFinal)
        
      },
      PrepareclickButtons(event){
        this.ClickandTagButtons = []
        let AddPeopleButton = {
          Name: 'Tag',
          Icon: 'mdi-tag-faces',
          Left: true,
          Right: false,
          TopPosition: 40,
          LeftPosition: -40,
          MapHeightPosition: this.ImageTagHeight,
          MapWidthPosition: this.ImageTagWidth,
          Class: 'accent',
          Method: 'TagUser',
          
          HasArray: true,
          MethodTitle: 'Tag Users',
          ArrayName: 'UsersStore',
          ArrayContent: this.UsersStore,
          ArrayIdentifier: 'Full_Name',
          ArrayIdentifierDisplay: 'Full Name',
          ArrayHasIMgProp: true,
          IMGPropValue: 'Profile_Photo',
          SelectedItem: ''
        }
        let AddCommentButton = {
          Name: 'Tag',
          Icon: 'mdi-comment-outline',
          Left: false,
          Right: true,
          TopPosition: -40,
          LeftPosition: -100,
          MapHeightPosition: this.ImageTagHeight,
          MapWidthPosition: this.ImageTagWidth,
          Class: 'success',
          Method: 'CommenttoMap',
          HasArray: false,
          HasComment: true,
        }
        this.ClickTopPosition = event.clientY
        this.ClickLeftPosition = event.clientX
        this.ClickandTagButtons.push(AddPeopleButton)
        this.ClickandTagButtons.push(AddCommentButton)
        this.ClickandTagAction = true
      },
      
      FullScreenNavigate(event){
        let vm = this
        if (event.code === 'ArrowLeft' && vm.FullScreenCarouselIMGDialog && vm.ActivePostImages[vm.CarouselIMG-1]) {
            vm.CarouselIMG = vm.CarouselIMG-1
            
          }
          else if(event.code === 'ArrowRight' && vm.FullScreenCarouselIMGDialog && vm.ActivePostImages[vm.CarouselIMG+1]){
            vm.CarouselIMG = vm.CarouselIMG+1
            
          }
      },
      
      GetImageColumns(array){
        let length = array.length
        let int = 0
        if(length<5){
          int = 6
          
        }
        else if(length<7){
          int = 4
        }
        else if(length<13){
          int = 3
        }
        else{
          int = 2
        }
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
            return int*2
          }
          else{
            return int
          }
        
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        // console.log(finaldate)
        return finaldate
      },
      
      GetSocialLikes(){
          this.LikesBaseRef.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialLikes.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        GetSocialReplies(){
          this.RepliesBaseRef.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialReplies.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
      GetSocialComments(){
          this.CommentsBaseRef.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.SocialComments.find(obj => obj.id === commentobj.id)
                if(!oncheck){
                  this.SocialComments.push(commentobj)
                }
                
              }
            })
          })
        },
        GetSitePosts(query,usergallery,postrouteid){
          let vm = this
          let finalquery = query
          if(this.lastVisible && this.$route.name !== 'SiteMemberSingle'){
            finalquery = finalquery.startAfter(this.lastVisible).limit(3)
          }
          else if(this.$route.name !== 'SiteMemberSingle' && this.$route.name !== 'SitePostSingle'){
            finalquery = finalquery.limit(3)
          }  
          else if(this.$route.name === 'SitePostSingle'){
            finalquery = finalquery.limit(1)
          }      
          finalquery.onSnapshot(res => {
            if(!usergallery){
              this.lastVisible = res.docs[res.docs.length-1]; 
            }           
            //console.log('lastVisible',this.lastVisible)
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let postobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                delete postobj.Images
                this.GetPostImages(postobj,usergallery).then(imgresultobj => {
                  this.GetCommentsLikesandReplies(imgresultobj).then(result => {
                    if(postrouteid){
                      if(result.id === postrouteid){
                        let oncheck = vm.Posts.find(obj => obj.id === result.id)
                        if(!oncheck){
                          vm.Posts.push(result)
                        }                        
                      }
                    }
                    else{
                      let oncheck = vm.Posts.find(obj => obj.id === result.id)
                        if(!oncheck){
                          vm.Posts.push(result)
                        }
                    }
                    
                  })
                })
              }
            })
          })
        },
        GetPostImages(postobj,usergallery){
          let vm = this
          return new Promise(function(resolve, reject) {
            let query = vm.ImageCollectionRef.where('relatedid','==',postobj.id)
            if(usergallery){
              query = vm.ImageCollectionRef.where('relatedid','==',postobj.id).where('TaggedUsers','array-contains',vm.$route.params.id)
            }
            query.onSnapshot(res =>{
           
              let listlength = res.docs.length
                if(listlength === 0){
                      resolve(postobj)
                }
                else{
                  if(!postobj.Images){
                   postobj.Images = [] 
                  }                  
                  const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      let imgobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                      }
                      // imgObj.MapTags = []
                      vm.GetMapTags(postobj,imgobj)
                      postobj.Images.push(imgobj)
                      if(postobj.Images.length === listlength){
                        resolve(postobj)
                      }
                    }
                  })
                }
            })
          })
        },
        GetCommentsLikesandReplies(postobj){
          let vm = this
          return new Promise(function(resolve, reject) {
            vm.GetAllPostComments(postobj).then(postcommentresult => {
              vm.GetItemSocialLikes(postobj,'relatedid',postobj.id).then(postlikesresult => {
                    if(postlikesresult){
                      if(postobj.Images){               
                        postobj.Images.map(img => {
                            vm.GetAllPostImagesComments(postobj,img).then(postimagescommentresult => {
                            if(postcommentresult && postimagescommentresult){
                              //console.log(postobj)
                              resolve(postobj)
                            }
                          }) 
                        })
                        postobj.Images.map(img => {                            
                           vm.GetItemSocialLikes(img,'Child',img.Name).then(postimageslikesresult => {
                            if(postimageslikesresult && postimageslikesresult){
                              //console.log(postobj)
                              resolve(postobj)
                            }
                          }) 
                        })
                        
                      }
                      else{
                        resolve(postobj)
                      }
                    }
                  }) 
              
              
            })
          })
        },
        
        GetItemSocialLikes(item,propname,propvalue){
          let vm = this
          item.Likes = []
          return new Promise(function(resolve, reject) {
            vm.LikesBaseRef.where(propname,'==',propvalue).onSnapshot(res => {
              let listlength = res.docs.length
                if(listlength === 0){
                      resolve(item)
                }
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  item.Likes.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
                if(listlength === item.Likes.length){
                  resolve(item)
                  }
              })
            })
          })
        },
        GetItemSocialReplies(item,propname,propvalue){
          let vm = this
          item.Replies = []
           return new Promise(function(resolve, reject) {
            vm.RepliesBaseRef.where(propname,'==',propvalue).onSnapshot(res => {
              let listlength = res.docs.length
                if(listlength === 0){
                      resolve(item)
                }
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  let commentobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = item.Replies.find(obj => obj.id === commentobj.id)
                    if(!oncheck){
                      item.Replies.push(commentobj)
                      vm.GetItemSocialLikes(commentobj,'relatedid',commentobj.id).then(postlikesresult => {
                            if(postlikesresult && listlength === item.Replies.length){
                              resolve(item)
                            }
                         })
                      
                    }
                }
              })
            })
           })
        },
        GetAllPostComments(postobj){
          let vm = this
          postobj.Comments = []
          return new Promise(function(resolve, reject) {
            vm.CommentsBaseRef.where('relatedid','==',postobj.id).where('relatedtype','==','Site Post').onSnapshot(res => {
                let listlength = res.docs.length
                if(listlength === 0){
                  // vm.GetItemSocialLikes(postobj,'relatedid',postobj.id).then(postlikesresult => {
                  //   if(postlikesresult){
                      resolve(postobj)
                  //   }
                  // })                  
                }
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let commentobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = postobj.Comments.find(obj => obj.id === commentobj.id)
                    if(!oncheck){
                      postobj.Comments.push(commentobj)
                       if(listlength === postobj.Comments.length){
                          vm.GetItemSocialLikes(commentobj,'relatedid',commentobj.id).then(postlikesresult => {
                            if(postlikesresult){
                              vm.GetItemSocialReplies(commentobj,'relatedid',commentobj.id).then(commentreplyresult => {
                                if(commentreplyresult){
                                  resolve(postobj)
                                }
                                
                              })
                            }
                          }) 
                        }
                    }
                    
                  }
                })
              })
          })
        },
        GetAllPostImagesComments(postobj,img){
          let vm = this
          img.Comments = []
          
          return new Promise(function(resolve, reject) {
            vm.CommentsBaseRef.where('relatedid','==',postobj.id).where('relatedtype','==','Site Post Images').where('Child','==',img.Name).onSnapshot(res => {
                let listlength = res.docs.length
                //console.log('img.Name',img.Name,listlength)
                if(listlength === 0){
                  resolve(postobj)
                }
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let commentobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = img.Comments.find(obj => obj.id === commentobj.id)
                    if(!oncheck){
                      img.Comments.push(commentobj)
                       if(listlength === img.Comments.length){
                          vm.GetItemSocialLikes(commentobj,'relatedid',commentobj.id).then(postlikesresult => {
                            if(postlikesresult){
                              vm.GetItemSocialReplies(commentobj,'relatedid',commentobj.id).then(commentreplyresult => {
                                if(commentreplyresult){
                                  resolve(postobj)
                                }
                                
                              })
                            }
                          }) 
                        }
                    }
                    
                  }
                })
              })
          })
        },
        CancelPostActive(){
          var x = document.activeElement.id      
          let postwrapper = document.getElementById('postwrapper')  
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let createpostbtn = document.getElementById('CreatePostButton')
          let stylebuttons = document.getElementById('PostInsertButtons')     
              cancelpostbtn.style.display = 'none'
              createpostbtn.style.display = 'none'
              stylebuttons.style.display = 'none'
              postwrapper.style.outlineStyle = 'none'
              postwrapper.style.outlineColor = 'black'
              postwrapper.style.outlineWidth = "0px"
      },
      OpenCarousel(item,index,frompreview){
        // this.ActivePost = item
        if(frompreview){
          this.ActivePostImages = this.selectedImagespreview
          this.EditingCarousel = true
          this.CarouselIMG = 0
          this.PostImagesCarousel = true
        }
        else{
          this.ActivePost = item
          this.ActivePostImages = item.Images
          // this.ActivePostImages.map(img => {
          //   this.GetMapTags(item,img)
          // })
          //console.log(item)
          if(index === 'Start'){
            this.CarouselIMG = 0
          }
          else if(index === 0){
            this.CarouselIMG = index-1+2
          }
          else{
            this.CarouselIMG = index-1+2
          }
        this.ActivateFullScreenCarousel()  
        }
        //this.PostImagesCarousel = true
      },
      getImage(event) {
      this.selectedImages = event.target.files
      
      for(var prop in this.selectedImages) {
        if(this.selectedImages[prop] && this.selectedImages[prop].lastModified){
          let file = this.selectedImages[prop]
          //file.FileModifiedDate = new Date(file.lastModified)
          let localurl = URL.createObjectURL(file)          
          let item = {
            fileurl: localurl,
            Name: file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
          }
          let img = new Image();
          img.onload = function () {
            item.width = this.width
            item.height = this.height
          };
          img.src = localurl;
          this.selectedImagespreview.push(item)
        }        
      }
      // this.selectedImagespreview = this.selectedImages.map(imagefile => {
      //   return URL.createObjectURL(imagefile)
      // })
      // console.log(this.selectedImages)
      // console.log(this.selectedImagespreview)
    },
    AddPostImages(LocalImages){
      this.$emit('ActivateMultipleFilesUploadNotification',true)   
      let fileList = this.selectedImages
      this.PostImagsrunningpercenteage = 0
      this.PostImagesrunningProgress = 0 
      //this.UploadingPostImages = true
      setTimeout(() => {
      this.PostImagestotallist = fileList.length
      this.PostImagesuploadTotal = fileList.length*100
      const topelmnt = document.getElementById('uploadfilesprogressbar')
      topelmnt.style.display = "block";
      const elmnt = document.getElementById('uploadfilesprogressgroupbar') 
      let oldtotalprogresselementptag = document.getElementById("uploadfilestotalprogressbarptag")
      let oldtotalprogresselement = document.getElementById("uploadfilestotalprogressbar")
      if(oldtotalprogresselementptag){
        oldtotalprogresselementptag.remove();
      }   

      if(oldtotalprogresselement){
        oldtotalprogresselement.remove();
      }
  
      if(elmnt.childNodes[0]){
        elmnt.innerHTML = '';
      }     
      const totalprogresselementptag = document.createElement('p')
      totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
      totalprogresselementptag.setAttribute('id','uploadfilestotalprogressbarptag')
      const totalprogresselement = document.createElement('progress')
      totalprogresselement.setAttribute('id','uploadfilestotalprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
      var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.PostImagestotallist+' files uploaded');
      totalprogresselementptag.appendChild(startingtotaltext);
      elmnt.append(totalprogresselement) 
      elmnt.append(totalprogresselementptag) 
      // this.totalelement  = document.createElement('p')
      Array.from(Array(fileList.length).keys()).map((x,index) => {
        this.PreparePostThumbnails(fileList[x],index,fileList.length)
      })
      }, 300);
      },
      PreparePostThumbnails(file,index,listlength){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.PostImagesforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            // console.log(blobfile)
            let storeagelocation = 'SocialPosts/PostImages/'+ new Date()+'/'+ filename
            var storageRef = firebase.storage().ref(storeagelocation+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      // console.log(thumburl)
                      vm.AddPostImagesSingle(file,index,listlength,thumburl,storeagelocation)
                    })
              })
      }
      },
      AddPostImagesSingle(file,fileindex,listlength,thumburl,storeagelocation) {
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        const totalprogresselement = document.getElementById('uploadfilestotalprogressbar')
        const totalprogresselementptag = document.getElementById('uploadfilestotalprogressbarptag')
        const elmnt = document.getElementById('uploadfilesprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)    
        let vm = this
        //const ImageArray = this.PostImagesImagesUploads
        
        var storageRef = firebase.storage().ref(storeagelocation);        
        var uploadTask = storageRef.put(file);
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.PostImagesuploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.PostImagesuploadPercentage)
                var textnode = document.createTextNode(vm.PostImagesuploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.PostImagesuploadPercentage === 100){
                  vm.PostImagesrunningProgress = vm.PostImagesrunningProgress+vm.PostImagesuploadPercentage
                  vm.PostImagsrunningpercenteage = Number(((vm.PostImagesrunningProgress/vm.PostImagesuploadTotal)*100).toFixed(2))                  
                  vm.PostImageslistuploadcount = vm.PostImageslistuploadcount-1+2
                  var totaltext = document.createTextNode(vm.PostImagsrunningpercenteage+'% complete  - '+vm.PostImageslistuploadcount+' of '+vm.PostImagestotallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.PostImagsrunningpercenteage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  //console.log('File available at', downloadURL);
                });
              });
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                      let newimage = {
                              Name: filename,
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Created_Byid: vm.UserRecord.id,          
                              Created_On: new Date(), 
                              //ModifiedDate: file.FileModifiedDate,
                              Modified_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Modified_Byid: vm.UserRecord.id,
                              Modified_On: new Date(),                              
                              StorageRef: storeagelocation
                              }
                              if(file.lastModifiedDate){
                                newimage.ModifiedDate = file.lastModifiedDate
                              }
                              else{
                                newimage.ModifiedDate = new Date(file.lastModified)
                              }
                      vm.NewPostImages.push(newimage)
                    //ImageArray.push(newimage)
                    // db.collection('capitalequipmentjobs').doc(this.$route.params.id).collection('outboundphotosimagesuploads').add(newimage).then(imagedoc => {
                    //   console.log('created new doc '+imagedoc)
                    //   })
                      if(vm.PostImagsrunningpercenteage === 100){
                      //this.UploadingPostImages = false
                      vm.$emit('ActivateMultipleFilesUploadNotification',false)
                      if(vm.NewPostImages.length === listlength){
                        vm.CreatePost()
                      }
                      
                     
                      
                      vm.PostImagestotallist = 0
                      vm.PostImagesuploadTotal = 0
                      
                      vm.PostImagesrunningProgress = 0
                      vm.PostImagesuploadPercentage = 0
                      vm.PostImageslistuploadcount = 0
                      // this.selectedImages = []
                      //elmnt.style.display = "none";
                      }
                  })
      },
    ProcessImage(){
      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;
          const img = document.createElement("img");          
          img.src = dataURI;
          img.width = 400
          img.style.marginLeft = "auto"
          img.style.marginRight = "auto"    
          img.style.display = 'block'    
          let NEWeditorContent = document.getElementById(elementid)
          document.execCommand('insertHTML', false, '<br>');               
          NEWeditorContent.appendChild(img);
          document.execCommand('insertHTML', false, '<br>');
          
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },
    

      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
      
      
      CancelPost(){
        this.postelmnt.innerHTML = ''
        this.NewPostImages = []
        this.selectedImagespreview = []
        this.selectedImages = ''
        this.PostImagsrunningpercenteage = 0
        this.EditingCarousel = false
        this.CarouselIMG = 0
        this.CancelPostActive()
      },
      CreatePost(){
        // let postelmnt = document.getElementById('postinput')
        let postinput = this.postelmnt.innerHTML
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          creatorname: this.UserRecord.Full_Name,
          post: postinput,
          createdon: new Date(),
          Comments: [],
          WallID: this.WallID,
          WallType: this.WallType,
          ShareName: this.ShareName,
        }
        // console.log('NewPost',NewPost.WallType)
        if(!NewPost.WallType){
          NewPost.WallType = 'SiteMember'
          NewPost.ShareName = this.UserRecord.Full_Name
        }
        if(this.UserRecord.Profile_Photo){
          NewPost.creatorimg = this.UserRecord.Profile_Photo
        }
        if(this.selectedImages.length > 0 && this.PostImagsrunningpercenteage !== 100){
          this.AddPostImages(this.selectedImagespreview)
        }
        else{
          // console.log('this.NewPostImages.length',this.NewPostImages.length)
          if(this.NewPostImages.length > 0){
            //alert('got image length')
            NewPost.Images = this.NewPostImages.map(file => {
              let localobj = this.selectedImagespreview.find(obj => obj.Name == file.Name)
              //alert(file.Name)
              if(localobj.Description){
                file.Description = localobj.Description
              }
              file.height = localobj.height
              file.width = localobj.width             
              
              if(file.width>file.height){
                let ratio = file.height/file.width
                file.DisplayRatio = this.GetImageRatio(ratio)
              }
              else if(file.width === file.height){
                 let ratio = file.height/file.width
                  file.DisplayRatio = this.GetImageRatio(ratio)
              }
              else{
                 let ratio = file.width/file.height
                file.DisplayRatio = this.GetImageRatio(ratio,true)
              }
              return file
            })
            // console.log('NewPost.Images',NewPost.Images)
            this.ProcessPost(NewPost)
          }
          else{
            this.ProcessPost(NewPost)
          }
          
        // console.log(NewPost.post)
        this.CancelPost()
        }
        
      },
      GetImageRatio(ratio,invert){
        let ComputerDisplay = {Name: 'Computer Display',Ratio: 0.8}
        let SDTV = {Name: 'Computer Display',Ratio: 0.75}
        let DSLR = {Name: 'Computer Display',Ratio: 0.67}
        let WideScreen = {Name: 'Computer Display',Ratio: 0.625}
        let HDTV = {Name: 'Computer Display',Ratio: 0.5625}
        let CinemaFilm = {Name: 'Computer Display',Ratio: 0.5405}
        let Panorama = {Name: 'Panorama',Ratio: 0.5}
        if(ratio<=Panorama.Ratio){
          if(invert){
            Panorama.Inverted = true
          }
            return Panorama
        }
        else if(ratio<=CinemaFilm.Ratio){
          if(invert){
            CinemaFilm.Inverted = true
          }
            return CinemaFilm
        }
        else if(ratio<=HDTV.Ratio){
          if(invert){
            HDTV.Inverted = true
          }
            return HDTV
        }
        else if(ratio<=WideScreen.Ratio){
          if(invert){
            WideScreen.Inverted = true
          }
            return WideScreen
        }
        else if(ratio<=DSLR.Ratio){
          if(invert){
            DSLR.Inverted = true
          }
            return DSLR
        }
        else if(ratio<=SDTV.Ratio){
          if(invert){
            SDTV.Inverted = true
          }
            return SDTV
        }
        else{
          if(invert){
            ComputerDisplay.Inverted = true
          }
            return ComputerDisplay
        }
      },
      ProcessPost(NewPost){
        //alert('adding post')
        let HasNewImages = false
        let NewImages = []
        //console.log(NewPost)
        if(NewPost.Images){
          HasNewImages = true
          NewImages = JSON.parse(JSON.stringify(NewPost.Images))
          delete NewPost.Images          
        }
        //no actually add to the post the meetup, cause that's going to matter idiot
        if(this.userLoggedIn.CheckedInMeetupid && this.PosttoMeetup){
          NewPost.Meetupid = this.userLoggedIn.CheckedInMeetupid
          NewPost.Meetuptitle = this.userLoggedIn.CheckedinMeetupName
          NewPost.CheckedInMeetupRoute = this.userLoggedIn.CheckedInMeetupRoute
        }
        this.BaseCollectionRef.add(NewPost).then(doc => {
          //console.log('created new post under '+doc.id)
          if(HasNewImages){
            NewImages.map(img => {
              let imgobj = Object.assign({},img)
              imgobj.relatedid = doc.id
              imgobj.TaggedUsers = []
              imgobj.Comments = []
              imgobj.Likes = []
              imgobj.Type = 'Social Post'
              imgobj.WallID = NewPost.WallID
              this.ImageCollectionRef.add(imgobj).then(imgdoc => {
                //console.log('created new img under '+imgdoc.id)
              })
            })            
          }
            // console.log('this.selectedImages',this.selectedImages)
            // this.ProcessPost(NewPost)
          if(NewPost.Creatorid !== NewPost.WallID){
          const NewNotification = {
                    postid: doc.id,
                    Type: 'Wall Post',
                    Header: this.UserRecord.Full_Name+' posted on your wall',
                    Content: NewPost.post,
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: this.UserRecord.Full_Name,
                    Ownerid: NewPost.WallID,
                    Read: false,
                    Path: '/SitePosts/'+doc.id,
                    Message: 'Click to view Post',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
        }
        })
      }
    },
}

</script>

<style>
.postinputbox{
  outline: rgb(111, 204, 247);
  outline-width: 1px;
  outline-style: solid;
  background-color: white;
  min-height: 80px;
}
.postinputboxdark{
  outline: rgb(111, 204, 247);
  outline-width: 1px;
  outline-style: solid;
  background-color: rgb(43, 42, 42);
  min-height: 80px;
}
.postoutline {
  outline: 1px solid #dedee9;
  background-color: grey;
}
.postoutline:focus {
  outline: 1px solid #dedee9;
  background-color: white;
}
.Chipinput{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  min-height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.NEWChipinput{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 8px 25px;
  min-height: 35px;
  width: 90%;
  font-size: 14px;
  line-height: 14px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.NEWChipinput[contenteditable]:empty::before {
  content: "Insert comment";
  color: gray;
}
.commentpost{
  background-color: #87B6E6  
}
.greyBG{
  background-color: #f0f2f5;
}
</style>

