<template>
  <div >
    <div class="dynbubblenav" :id="btnid" :style="'--toppos: 45%;--rightpos: 30%;'">        
    <v-btn  @click="ToggleBubbleMenu()" fab large class="hamburger elevation-6">       
        <v-icon color="primary" size="35">{{Icon}}</v-icon>       
        
        </v-btn>
        <v-btn @click="EmitMethod(btn)" class="bubblemenuitem" fab v-for="btn in Items" :key="btn.itemObjKey">
            <v-icon :color="btn.Color">{{btn.icon}}</v-icon>
        </v-btn>
        </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','Icon','btnid','Items'],
    components: {},
    data() {
        return {
            
        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        EmitMethod(btn){
            let payload = {
                Method: btn.Method,
                Record: this.Record
            }
            this.$emit('EmitMethod',payload)
        },
        ToggleBubbleMenu(item){
            let nav = document.getElementById(this.btnid)
            nav.classList.toggle("open")
            let submenus = nav.getElementsByClassName('bubblemenuitem')
            //console.log(submenus.length)
            for(var x=0;x<submenus.length;x++){
              let submenu = submenus[x]
            }
      },
    }
}
</script>

<style>

</style>



