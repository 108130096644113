<template>
  <div style="padding: 20px;">
    <v-dialog max-width="600"
      v-model="menu"
    >
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>New Product</v-list-item-title>
              <v-list-item-subtitle>Enter Product Information</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>          
          <v-list-item>
           <v-text-field outlined v-model="editedProduct.Title" label="Title"/>
          </v-list-item>
          <v-list-item>
           <v-text-field outlined v-model.number="editedProduct.Price" type="number" label="Price"/>
          </v-list-item>
           <v-list-item>
           <v-select outlined v-model="editedProduct.Category" :items="AllProductCategories" item-text="Name"  label="Category"/>
          </v-list-item>
          <v-list-item>
            <ContentEditableField
            :FieldObject="{id: 'Description',Name: 'Description',DisplayName: 'Description'}" :FieldName="'Description'"
            :FieldValue="editedProduct.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="CancelStoreProduct()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="SaveStoreProduct()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
      <!-- <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Products for your Suite
      </v-card-title> -->
      <!-- <v-btn @click="DeactivateActivesession()" right style="z-index: 2;" absolute outlined color="red">Exit</v-btn> -->
       <v-card elevation="0" class="siteconfigcard">
        <v-card-title v-if="!ActiveLibTypeComp" class="mediumoverline">
          Products for your Suite
          <v-spacer></v-spacer>
           <v-btn v-if="!ActiveProduct" outlined
          @click="ActivateNewProductDialog()" >Add New</v-btn>  
      
    <v-btn v-if="ActiveProduct" outlined @click="CancelActiveProduct()">Back
          </v-btn>
          <v-btn v-if="ActiveProduct" outlined @click="PreviewProduct()">{{EditingProduct ? 'Preview' : 'Cancel Preview'}}
          </v-btn>  
        </v-card-title>  
        <div v-if="ActiveProduct">
                
          <v-row>
            <v-col cols="12" v-if="!EditingProduct">
              <EasyProductSingle :Directories="Directories" :ActiveProduct="ActiveProduct"
                  :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
                  :EditingProduct="EditingProduct"
                  :ProductCollectionRef="ProductCollectionRef" :ShippingGroupsRef="ShippingGroupsRef" :CategoriesRef="CategoriesRef"
              />
            </v-col>
            <v-col cols="12" xl="9" lg="9" md="8" sm="7" v-if="EditingProduct">
              <EasyProductEdit v-if="EditingProduct" :Directories="Directories" :ActiveProduct="ActiveProduct"
              :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
              @ActivateSnackbar="ActivateSnackbar"
                  :ProductCollectionRef="ProductCollectionRef" :ShippingGroupsRef="ShippingGroupsRef" :CategoriesRef="CategoriesRef"
              />
              
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="5" v-if="EditingProduct">
              <EasyProductSingle :Directories="Directories" :ActiveProduct="ActiveProduct"
                  :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
                  :EditingProduct="EditingProduct"
                  :ProductCollectionRef="ProductCollectionRef" :ShippingGroupsRef="ShippingGroupsRef" :CategoriesRef="CategoriesRef"
              />
              <!-- I am however ocnsidering two things, 1) preview single 2) preview in col view, the card etc -->
            </v-col>
          </v-row>         
        </div>
      <v-card-text v-if="!ActiveLibTypeComp && !ActiveProduct">
         <v-list v-if="!OldProductStyle">
          
          
              <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;" @click="ActivateProduct(prod)" v-for="prod in ComputedStoreProducts" :key="prod.itemObjKey">
                  <v-list-item-avatar  v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="'border-radius: 10px!important;'">
                    <img  style="object-fit: cover;"
                    :src="prod.IMG ? prod.IMG : 
                  System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                    {{prod.Title}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div v-html="prod.Description"/>
                    </v-list-item-subtitle>
                    <v-list-item-title>
                    {{CurrencyFormatter(prod.Price,$store.state.DefaultCurrency.Currency)}}
                    </v-list-item-title>
                  </v-list-item-content>                    
              </v-list-item>
          </v-list>
         <v-list v-if="OldProductStyle">
              <v-list-item @click="ActivateLibTypComponent(prodtype)" v-for="prodtype in ProductTypes" :key="prodtype.itemObjKey">
                  <v-list-item-avatar v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="MiniView ? '' : 'align-self: center;padding-right: 10px;'">
                    <v-icon :size="40">{{prodtype.Table_Icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-action>
                    <v-list-item-title style="align-self: start;" class="smallbanneroverline">
                      <v-icon v-if="MiniView" :size="20">{{prodtype.Table_Icon}}</v-icon>
                      {{prodtype.DisplayName}}
                    </v-list-item-title>
                    </v-list-item-action>
              </v-list-item>
          </v-list>
      </v-card-text>
      <v-card-text v-if="ActiveLibTypeComp">
        <!-- <component :is="ActiveLibTypeComponent" :System="System" :SystemEntities="SystemEntities" @GoBack="GoBack"
        :Directories="Directories" :DocumentationLibraries="DocumentationLibraries"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
        /> -->
        <!-- okay so here is the deal, what are the products? well it would be items in store, basically. let's add one, then see -->
        <v-list>
            <v-btn outlined @click="ActiveLibTypeComp = ''">Back
            </v-btn>
        </v-list>
        <v-list>
              <TableMain :System="System" :SystemEntities="SystemEntities"
            :ProvidedEntityid="ActiveLibTypeComp.id" :Directories="Directories"
            :UnreadNotifications="UnreadNotifications"
            />
        </v-list>
      </v-card-text>
       </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TableMain from '@/components/Database/TableMain';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import EasyProductEdit from '@/components/SuitePlugins/EasyProducts/EasyProductEdit';
import EasyProductSingle from '@/components/SuitePlugins/EasyProducts/EasyProductSingle';
export default {
    props: ['System','SystemEntities','UnreadNotifications','SystemPhotoLibraries','SystemDocumentLibraries','ActiveTemplate','DocumentationLibraries','Directories'],
    components: {TableMain,ContentEditableField,EasyProductEdit,EasyProductSingle},
    data() {
        return {
          importdialog: false,
          EditingProduct: true,
          editedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',            
          },
          defaulteditedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',
          },
          menu: false,
            ActiveProduct: '',
            LibraryTypes: [
                {
                Icon: 'mdi-folder-image', Name: 'Photo Libraries', ConfigComponent: 'components/SuitePlugins/PhotoLibrary/PhotoLibrariesConfig'
                },
                {
                Icon: 'mdi-library-shelves', Name: 'Document Libraries', ConfigComponent: 'components/SuitePlugins/DocumentLibrary/DocumentLibrariesConfig'
                },
                 {
                Icon: 'mdi-view-list', Name: 'Directories', ConfigComponent: 'components/Library/DirectoryLibrariesConfig'
                },
                 {
                Icon: 'mdi-book-education', Name: 'Documentation', ConfigComponent: 'components/Library/DocumentationLibrariesConfig'
                }
                
            ],
            ActiveLibTypeComp: ''
        }
    },	
    computed:{
      AllProductCategories(){
        return this.ProductsCatsLevel3.concat(this.ProductsCatsLevel2,this.ProductCategories)
      },
      ProductsCatsLevel3(){
            return this.ProductsCatsLevel2.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductsCatsLevel2(){
            return this.ProductCategories.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
      CategoryField(){
        return this.ActiveStore && this.ActiveStore.CategoryField ? 
        this.ActiveStore.CategoryField : ''
      },
      ActiveStore(){
        return this.$store.state.ActiveStore
      },
      StoreRef(){
        return db.collection('Stores').doc(this.$store.state.ActiveSuiteid)
      },
      CategoriesRef(){
        return this.StoreRef.collection('productcategories')
      },
      ShippingGroupsRef(){
        return this.StoreRef.collection('shippinggroups')
      },
      ProductCollectionRef(){
        return this.StoreRef.collection('storeproducts')
      },
        ProductTypes(){
            return this.SystemEntities.filter(ent => {
                return ent.Entity_Type.Name === 'Inventory Article'
            })
        },
        ActiveLibTypeComponent(){
          if(this.ActiveLibTypeComp){
                  return () => import(`@/`+this.ActiveLibTypeComp+`.vue`);	 
              }
        },  
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        StoreProducts(){
          return this.$store.state.StoreProducts
        },
        ComputedStoreProducts(){
          return this.StoreProducts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignProductIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      
        System: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id && !this.ActiveTemplate){
                     this.SystemInstance = this.System
                }
            },deep: true
        },
    },
    created(){
    },
    methods:{
      ActivateNewProductDialog(){
        this.menu = true
      },
      ActivateImportTableDialog() {
      this.importdialog = true;
    },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      CancelActiveProduct(){
        this.ActiveProduct = ''
        this.EditingProduct = true
      },
      DeactivateActivesession(){
        this.$emit('DeactivateActivesession')
      },
      PreviewProduct(){
        this.EditingProduct = !this.EditingProduct
      },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      AssignProductIMG(){
        this.ActiveProduct.IMG = this.SelectedImage.fileurl
            this.ProductCollectionRef.doc(this.ActiveProduct.id).update({
                IMG: this.ActiveProduct.IMG
            })
        this.$store.commit('setIMGProp','')
      },
      CancelStoreProduct(openafter){
        this.menu = false
        this.editedProduct = Object.assign({},this.defaulteditedProduct)
        if(openafter){
          setTimeout(() => {
            this.menu = true
          }, 500);
        }
      },
      SaveStoreProduct(){
        this.editedProduct.AdditionalOptions = []
        this.ProductCollectionRef.add(this.editedProduct).then(newprod => {
          this.CancelStoreProduct(true)
        })
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      ActivateProduct(prod){
        this.ActiveProduct = prod
      },
      GoBack(){
        this.ActiveLibTypeComp = ''
      },
      ActivateLibTypComponent(prodtype){
        this.ActiveLibTypeComp = prodtype
      },
    }
}
</script>

<style>

</style>



