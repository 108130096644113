
<template>
<v-card flat  :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent' : 'transparent'" width="100%" height="100%">
    <!-- :flat="NoticeSelect" -->
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">Forums</h4>
              </v-col>
            </v-row>
            </v-parallax>
    <v-dialog v-model="NewDiscussionDialog" max-width="600px">
        <v-card
        class="mx-auto"
        max-width="600px"
        
      >
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Discussion</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
            <v-card-text>
              <v-text-field v-model="NewForum.Title" label="Title"/>
              <v-switch v-if="!$store.state.UserisGuest && System.Guests_can_Social || System.Guests_can_Social && GroupData" v-model="GuestsIncluded" :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
              <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewForum" :field="ForumCategoriesField" @onPhotoFileselected="onPhotoFileselected"
                :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                @UploadFileSelect="UploadFileSelect"
                  />
                  <!-- removed  v-if="!$store.state.TemplateView" from fieldeditor and below combo box
                <v-combobox return-object item-text="Name"v-if="$store.state.TemplateView"
                  chips v-model="NewForum.Category" :items="ForumsCategories" label="Category" >
                </v-combobox>  -->
                <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewForum" :field="ForumDescriptionField" @onPhotoFileselected="onPhotoFileselected"
                :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                @UploadFileSelect="UploadFileSelect" 
                  />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="CancelNewForum()" outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="CreateDiscussion()">
                    Post
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-layout class="justify-center">
<v-card flat class="transparent" width="90%" v-if="!NoticeSelect">
    <v-layout class="justify-end"><v-btn outlined color="accent" @click="OpenAboutDialog('AboutSiteForums')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Forums</v-btn>
    <v-btn outlined color="accent" @click="ActivateNewDiscussionDialog()" v-if="UserIsModerator || userIsAdmin || UserisTemplateOwner"><v-icon>mdi-plus</v-icon>New Discussion</v-btn>
    </v-layout>
    <v-layout class="justify-start">

      <v-chip-group>
        <v-chip  :color="opt.Color" @click="ActivateClassFilter(opt)"
        v-for="opt in ForumCategoriesField.Options" :key="opt.itemObjKey">
          {{opt.Name}}
        </v-chip>
      </v-chip-group>
    </v-layout>
    <v-layout class="justify-start">
      <v-chip-group
            v-if="ClassFilter"
            active-class="primary--text"
            column
          >
            <v-chip  :color="cat.Color"
              v-for="cat in ClassFilter.Options"
              :key="cat"
              @click="ActivateCategoryFilter(cat)"
            >
              {{ cat.Name }}
            </v-chip>
          </v-chip-group>
    </v-layout>
    <v-card  v-for="forum in PostsTimeline" :key="forum.itemObjKey" elevation="3" :to="SingleRoutePrefix+forum.id">
       
        <v-list three-line class="my-3 white">
            <v-list-item>
                <v-list-item-avatar>
              <img v-if="forum.creatorimg"
                :src="forum.creatorimg"
                :alt="forum.creatorname"
                 :href="'/SiteMember/'+forum.Creatorid"
            >
            <img v-if="!forum.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="forum.creatorname"
                 :href="'/SiteMember/'+forum.Creatorid"
            >
            
          </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title v-if="forum.title" v-html="forum.title"></v-list-item-title>
                <v-list-item-title v-if="!forum.title" v-html="forum.title.substr(0,50)"></v-list-item-title>
                <v-list-item-subtitle v-html="forum.post.substr(0,450)"></v-list-item-subtitle>
                </v-list-item-content>
               
                <v-list-item-action style="font-size:12px">
                    <span v-if="forum.createdon.toDate">{{forum.createdon}}</span>
                    <span v-else>{{forum.createdon}}</span>
                </v-list-item-action>
            
                <v-list-item-action style="font-size:12px">
                    <v-badge dark overlap>
                    <v-icon color="blue" @click="LikePost(post)">mdi-thumb-up-outline</v-icon>
                    <span slot="badge"> {{forum.Likes.length}} </span>
                </v-badge>
                </v-list-item-action>
                <v-list-item-action style="font-size:12px">
                    <v-badge dark overlap>
                    <v-icon color="orange">mdi-comment-outline</v-icon>
                    <span slot="badge"> {{forum.Comments.length}} </span>
                </v-badge>
                </v-list-item-action>
               
            </v-list-item>

        </v-list>        
    </v-card>
  

</v-card>
    </v-layout>
    <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="PostsTimeline" item-text="title" v-model="selectedSocialItem" return-object label="Forum"/>
</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';

export default {
    props: ['CanCreate','NoticeSelect','System','SystemEntities','GroupData','Editor'],
    components: {
      FieldValueEditerComponent
        },
    data() {
        return {
          ForumsCategories: [],
          GuestsIncluded: false,
            CategoryFilter: '',
            ClassFilter: '',
            NewForum: {Title: ''},
            InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
            DiscussionTitle: '',
            NewDiscussionDialog: false,
            Forums: [],
          NewCommentInput: '',
          NewReplyInput: '',
          ForumCategoriesField: {},
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
            UserisModerator: false,
            SiteModerators: []
        }
        
    },

    computed: {
      groupid(){
        return this.$route.params.id && !this.$store.state.TemplateView ? this.$route.params.id : ''
      },
      SingleRoutePrefix(){
        //$route.name !== 'GroupSingle' ? '/Forum/'+forum.id : '/Group-Forum/'+$route.params.id+'/Discussion/'+forum.id
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Forum/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Forum/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Forum/' : '/Group-Forum/'+this.$route.params.id+'/Discussion/'
        }
      },
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },  
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupForums' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Forums' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },   
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ActiveGroup(){
        return this.$store.state.ActiveGroup
      },
      ContentCategoriesPath(){
        return this.ActiveGroup ? this.ContentRootPath.collection('groups').doc(this.ActiveGroup.id).collection('ForumsCategories') : 
        this.ContentRootPath.collection('ForumsCategories')
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      }, 
      CollectionQuery(){
        if(this.TemplateRootPath){
          return this.CollectionRef
        }
        else if(this.NonGroupRoute){
         return this.CollectionRef
        }
        else{
          return this.CollectionRef.where('groupid','==',this.$route.params.id)
        }
      },    
      CollectionRef(){
        if(this.TemplateRootPath){
          return this.TemplateRootPath.collection('samplesiteforumdiscussions')
        }
        else if(this.NonGroupRoute){
         return this.ContentRootPath.collection('siteforumdiscussions')
        }
        else{
          return this.ContentRootPath.collection('groupforumdiscussions')
        }
        
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      UserisTeamMember(){
        return this.GroupData && this.GroupData.TeamMembers ? this.GroupData.TeamMembers.find(obj => obj === this.userLoggedIn.id) : false
      },
      UserisGroupMember(){
        return this.GroupData && this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.find(obj => obj.id === this.GroupData.id) : false
      },
      ForumDescriptionField(){
        let field = {
          Name: 'post',
          DisplayName: 'post',
          Type: 'Multiple Lines Text',
          IsContentEditable: true
        }
        return field
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserIsModerator(){
        if(this.$route.name === 'GroupSingle'){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.ForumsModerator
          }
          else{
              if(this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Forums')){
                return true
              }
              else{
                return this.userIsAdmin
              }
          }
      },
      UserRecord(){
            return this.$store.state.userobj
          },
      ComputedSocialReplies(){
        return this.SocialReplies.map(mainreply => {
          this.SocialReplies.map(reply => {
                    if(reply.relatedid === mainreply.id){
                      let replyonmainreplycheck = mainreply.Replies.find(obj => obj.id == reply.id)
                      if(!replyonmainreplycheck){
                        mainreply.Replies.push(reply)
                      }
                    }
                  })
          return mainreply
        }).map(mainreply => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === mainreply.id){
                      let likeonmainreplycheck = mainreply.Likes.find(obj => obj.id == like.id)
                      if(!likeonmainreplycheck){
                        mainreply.Likes.push(like)
                      }
                    }
                  })
          return mainreply
        })
      },
      ComputedSocialComments(){
        return this.SocialComments.map(comment => {
          this.ComputedSocialReplies.map(reply => {
                    if(reply.relatedid === comment.id){
                      let replyoncommentcheck = comment.Replies.find(obj => obj.id == reply.id)
                      if(!replyoncommentcheck){
                        if(reply.Replies){
                          comment.Replies = comment.Replies.concat(reply.Replies)
                        }
                        comment.Replies.push(reply)
                      }
                    }
                  })
          return comment
        }).map(comment => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === comment.id){
                      let likeoncommentcheck = comment.Likes.find(obj => obj.id == like.id)
                      if(!likeoncommentcheck){
                        comment.Likes.push(like)
                      }
                    }
                  })
          return comment
        })
      },
      PostsTimeline () {
        return this.Posts.map(post => {
          this.ComputedSocialComments.map(comment => {
            if(comment.relatedid === post.id){
              let oncheck = post.Comments.find(obj => obj.id == comment.id)
                if(!oncheck){
                  post.Comments.push(comment)
                }
              }   
          })
          return post
        }).map(post => {
          this.SocialLikes.map(like => {
            if(like.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == like.id)
                if(!oncheck){
                  post.Likes.push(like)
                }
              }   
          })
          return post
        }).map(post => {
          this.ComputedSocialReplies.map(reply => {
            if(reply.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == reply.id)
                if(!oncheck){
                  post.Likes.push(reply)
                }
              }   
          })
          return post
        }).map(post => {          
          if(typeof post.createdon.toDate !== 'undefined'){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        })
        .filter(post => {
          if(this.ClassFilter){
            return post.Class && post.Class.ID === this.ClassFilter.ID
          }
          else{
            return post
          }
        })
        .filter(post => {
          if(this.CategoryFilter){
            return post.Category && post.Category.ID === this.CategoryFilter.ID
          }
          else{
            return post
          }
        })
        .sort((a, b) => {
            // console.log('sorting again')
            // console.log(a.createdon)
            var key1 = this.DateFormatterwithTime(a.createdon);
            var key2 = this.DateFormatterwithTime(b.createdon);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },

    },

    watch: {
      
      SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['ActivateNewDiscussionDialog']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    

    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      if(this.$store.state.TemplateView){
        this.GetSitePosts(this.TemplateRootPath.collection('samplesiteforumdiscussions'))
        //this.ForumCategoriesField = Object.assign({},this.System.Forum_Categories)
        this.ForumCategoriesField = Object.assign({},this.System.Forum_Categories)
        // console.log('this.ActiveMarketTemplate.CategoryFields',this.ActiveMarketTemplate.CategoryFields)
        // console.log('this.ActiveMarketTemplate.Assets',this.ActiveMarketTemplate.Assets)
        let tempfield = this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.CategoryFields ? this.ActiveSuiteTemplate.CategoryFields.find(obj => obj.id === 'Forum_Categories') : this.ActiveMarketTemplate.CategoryFields.find(obj => obj.id === 'Forum_Categories')
        // console.log('tempfield',tempfield)
        if(tempfield){
          this.ForumCategoriesField = Object.assign({},tempfield)
        }
        this.ForumCategoriesField.Name = 'Class'
        this.ForumCategoriesField.DisplayName = 'Class'
        this.ForumCategoriesField.IsDynamic = false
        this.ForumCategoriesField.Options = this.ForumsCategories
        //console.log('this.ForumCategoriesField',this.ForumCategoriesField)
        this.GetSiteForumsCategories()
      }
      else{
        this.CheckRouting()
       this.IntranetViewToggle()
       if(!this.ActiveGroup){
        this.ForumCategoriesField = Object.assign({},this.System.Forum_Categories)
        this.ForumCategoriesField.Name = 'Class'
        this.ForumCategoriesField.DisplayName = 'Class'
        this.ForumCategoriesField.IsDynamic = false
       }
       else{
        this.ForumCategoriesField = JSON.parse(JSON.stringify(this.System.Forum_Categories))
        this.ForumCategoriesField.Name = 'Class'
        this.ForumCategoriesField.DisplayName = 'Class'
        this.ForumCategoriesField.IsDynamic = false
        this.ForumCategoriesField.Options = this.ForumsCategories
        this.GetSiteForumsCategories()
       }
       
      }
       
    },
    methods: {
      GetSiteForumsCategories(){
        this.ContentCategoriesPath.onSnapshot(res => {
            const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  this.ForumsCategories.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
              })
          })
      },
      CancelNewForum(){
        this.NewForum = {Title: ''}
        this.NewDiscussionDialog = false
      },
      ActivateCategoryFilter(cat){
        this.CategoryFilter = cat
      },
      ActivateClassFilter(opt){
        if(this.ClassFilter === opt){
          this.ClassFilter = ''
        }
        else{
           this.ClassFilter = opt
        }
       
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
      DateFormatterwithTime(date){
      if(date){
        // console.log(date)
        // console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        // console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      CheckRouting(){
      if(this.System.Social_Content_for_Guests || this.$store.state.UserisGuest){
            this.GuestsIncluded = true
          }
              let vm = this
        this.GetSiteModerators().then(function(result) {
          if(result){
            //console.log('checking if moderator')
            let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
            if(UserModeratorobj){
              if(UserModeratorobj.ModerationPermissions.includes('Forums')){
                //console.log('yes Forums included')
                vm.UserisModerator = true
              }
            }
            if(vm.NonGroupRoute){
              vm.InternalLinkType = 'Forum',
              vm.LinkComponent = 'SiteForumShared'
              vm.SharedCollection = 'siteforumdiscussions'
              if(!vm.ChannelRestricted || vm.userIsAdmin){
                if(vm.userLoggedIn.UserisGuest){
                  if(!vm.CompanyOnlyFilter){
                    vm.GetSitePosts(vm.ContentRootPath.where('GuestsIncluded','==',true))
                  }
                  else{
                    vm.GetSitePosts(vm.CollectionQuery.where('Companyid','==',vm.UserCompanyid))
                  }
                  
                }
                else{
                  if(!vm.CompanyOnlyFilter){
                    vm.GetSitePosts(vm.CollectionQuery)
                  }
                  else{
                    vm.GetSitePosts(vm.CollectionQuery.where('Companyid','==',vm.UserCompanyid))
                  }
                } 
              }
              
            }
            else{
              
              vm.InternalLinkType = 'Group-Forum/'+vm.$route.params.id+'/Forum'
              vm.LinkComponent = 'GroupForumShared'
              vm.SharedCollection = 'groupforumdiscussions'
              let colref = vm.CollectionQuery
              if(vm.UserisTeamMember){
                vm.GetSitePosts(colref)
              }
              else if(vm.userLoggedIn && !vm.$store.state.UserisGuest){              
                vm.GetSitePosts(colref)
              }
              else{
                vm.GetSitePosts(colref.where('GuestsIncluded','==',true))
              }
            }
            // vm.GetSocialComments()
            // vm.GetSocialLikes()
            // vm.GetSocialReplies()
          }
        })
        if(this.$route.params.id){
            //console.log('has single')
        }
        },
      
    GetSiteModerators(){
        let vm = this
        //console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        vm.ContentRootPath.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
        Activatedialog(){
          this.dialog = true
        },
        OpenEditdialog() {
          this.dialog = true
          // $nuxt.$router.push('/new_group/'+this.tempid)
      },
        ToggleEdit(item,dialprop){
        if(this.$store.state.TemplateView && this.Editor && this.UserisTemplateOwner){
          this.editedItem = Object.assign({},item)
          this.OpenEditdialog()
        }
        else if(this.$store.state.TemplateView && !this.Editor){
          //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
          this.OpenAboutDialog(dialprop)
        }
        
      } ,  
        OpenAboutDialog(dialprop){      
        this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
        this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
      },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      save(){
        ///TBD but see CreateDiscussion below and NEwForm
      },
        CreateDiscussion(){
          let vm = this
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.NewForum.Title,
          Creator: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},          
          Creatorid: this.userLoggedIn.id,
          creatorname: this.userLoggedIn.Full_Name,
          post: this.NewForum.post,
          createdon: new Date(),
          Comments: [],
          
          GuestsIncluded: this.GuestsIncluded
        }
        if(this.$store.state.TemplateView){          
            NewPost.Class = this.NewForum.Class
            if(this.NewForum.Category){
               NewPost.Category = this.NewForum.Category
            }
          }
          if(!this.$store.state.TemplateView) {
            //for now but we going the other route later
            NewPost.Class = this.NewForum.Class
            NewPost.Category = this.NewForum.Category
          }
        if(this.UserRecord.Profile_Photo){
          NewPost.creatorimg = this.UserRecord.Profile_Photo
        }
        let col = ''
        if(this.$store.state.TemplateView){
              col = this.TemplateRootPath.collection('samplesiteforumdiscussions')
        }
        else if(this.$route.name !== 'GroupSingle'){
          col = this.CollectionRef
        }
        else{
          col = this.CollectionRef
          NewPost.groupid = this.groupid
        }
        if(!NewPost.groupid && vm.userLoggedIn && vm.userLoggedIn.Company){
          NewPost.Company = vm.userLoggedIn.Company
          NewPost.Companyid = vm.userLoggedIn.Companyid
        }
         this.$store.dispatch('CreateSearchTitle',NewPost.title).then(searchtitle => {
              NewPost.titleQuery = searchtitle
            col.add(NewPost)
        //console.log(NewPost.post)
        this.CancelNewDiscussion()
         })
      
        },
        CancelNewDiscussion(){
            this.DiscussionTitle = ''
            this.NewDiscussionDialog = false
        },
        ActivateNewDiscussionDialog(){
          if(this.ForumCategoriesField && this.ForumCategoriesField.Options && this.ForumCategoriesField.Options.length > 0){
            this.NewDiscussionDialog = true
          }
          else{
            let msg = 'Speak to your Suite Adminsitrator about adding Forum Categories first'
            if(this.UserisTemplateOwner){
              //you see, now we got what we need and can carry on
              msg = 'You need to add Forum Categories to the template first'
            }
            alert(msg)
          }      
          
        },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      ReplyBoxDeactivate(replyboxbreak,input){
        let replyboxbreakelmnt = document.getElementById(replyboxbreak)
        let inputelmnt = document.getElementById(input)
        inputelmnt.value = ''
        replyboxbreakelmnt.style.display = 'none'
      },
      ReplyBoxActivate(replyboxbreak,input){
        let replyboxbreakelmnt = document.getElementById(replyboxbreak)
        let inputelmnt = document.getElementById(input)
        replyboxbreakelmnt.style.display = ''
        inputelmnt.focus()
      },
      GetSocialLikes(){
          this.ContentRootPath.collection('socialsitelikes').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialLikes.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        GetSocialReplies(){
          this.ContentRootPath.collection('socialsitereplies').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialReplies.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
      GetSocialComments(){
          this.ContentRootPath.collection('socialsitecomments').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialComments.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        GetSitePosts(col){
          col.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.Posts.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        CancelPostActive(){
          var x = document.activeElement.id      
          let postwrapper = document.getElementById('postwrapper')  
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let createpostbtn = document.getElementById('CreatePostButton')
          let stylebuttons = document.getElementById('PostInsertButtons')     
              cancelpostbtn.style.display = 'none'
              createpostbtn.style.display = 'none'
              stylebuttons.style.display = 'none'
              postwrapper.style.outlineStyle = 'none'
              postwrapper.style.outlineColor = 'black'
              postwrapper.style.outlineWidth = "0px"
      },
      getImage() {
        var canvas=document.createElement("canvas"); 
        var ctx=canvas.getContext("2d");
        var cw=canvas.width;
        var ch=canvas.height;
        var maxW=400;
        var maxH=400;
      let elementid = 'postinput'
  
      var file = document.querySelector("input[type=file]").files[0];
      var fileSize = file.size;
      console.log(fileSize/1000000+'mb')
      var img = new Image;
        img.onload = function() {
          var iw=img.width;
          var ih=img.height;
          var scale=Math.min((maxW/iw),(maxH/ih));
          var iwScaled=iw*scale;
          var ihScaled=ih*scale;
          canvas.width=iwScaled;
          canvas.height=ihScaled;
          
          ctx.drawImage(img,0,0,iwScaled,ihScaled);
          
      let basetext = canvas.toDataURL("image/jpeg",0.6);
        console.log(basetext)
        let NEWeditorContent = document.getElementById(elementid)
        let newimg = document.createElement("img")
        newimg.src = basetext;
        newimg.width = 400
        newimg.style.marginLeft = "auto"
        newimg.style.marginRight = "auto"    
        newimg.style.display = 'block'
        document.execCommand('insertHTML', false, '<br>');
        NEWeditorContent.appendChild(newimg);
        document.execCommand('insertHTML', false, '<br>');
        }
        img.src = URL.createObjectURL(file);
      
    },

    ProcessImage(){
      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;
          const img = document.createElement("img");          
          img.src = dataURI;
          img.width = 400
          img.style.marginLeft = "auto"
          img.style.marginRight = "auto"    
          img.style.display = 'block'    
          let NEWeditorContent = document.getElementById(elementid)
          document.execCommand('insertHTML', false, '<br>');               
          NEWeditorContent.appendChild(img);
          document.execCommand('insertHTML', false, '<br>');
          
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },
    

      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
      
      LikePost(post){
        let UserhasLiked = post.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
            relatedtype: 'Social Post',
            relatedid: post.id,
            relatedowner: post.Creatorid
        }
        // post.Likes.push(newlike)
        let likedbref = ''
        if(this.$store.state.TemplateView){
          likedbref = this.ContentRootPath.collection('samplesocialsitelikes')
        }
        else{
          likedbref = this.ContentRootPath.collection('socialsitelikes')
        }
        likedbref.add(newlike).then(doc => {
          if(newlike.Likedbyid !== post.Creatorid){
          const NewNotification = {
                    postid: post.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' likes your post',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: post.Creator.Full_Name,
                    Ownerid: post.Creatorid,
                    Read: false,
                    Path: '/SitePosts/'+post.id,
                    Message: 'Click to view Likes',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
        })
        }
        else{
          alert('you already liked this item')
        }
      },
      CommentReplyActivate(i,comment){
        // comment.Replying = true
        setTimeout(() => {
          let elmnt = document.getElementById(i+'commentreply')
        elmnt.style.display = 'block'
        console.log(comment)
        console.log(elmnt)
        }, 300);
        
      },
      
      ReplytoComment(comment,i){
          if(!comment.Replies){
            comment.Replies = []
          }
          let inputelmnt = document.getElementById(comment.id+'replyinputchip')
          let NewReply = 
          {
            content: inputelmnt.value,
            Likes: [],
            Shares: [],
            tags: [],
            Replies: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Comments: [],
            relatedtype: 'Social Comment',
            relatedid: comment.id,
            relatedowner: comment.Creatorid
            
          }
          
          if(this.UserRecord.Profile_Photo){
            NewReply.creatorimg = this.UserRecord.Profile_Photo
            }
           this.ContentRootPath.collection('socialsitereplies').add(NewReply).then(doc => {
          if(NewReply.Creatorid !== comment.Creatorid){
          const NewNotification = {
                    commentid: comment.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' replied to your comment',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: comment.Creator.Full_Name,
                    Ownerid: comment.Creatorid,
                    Read: false,
                    Path: '/SitePosts/'+comment.id,
                    Message: 'Click to view Comments',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
        })
          comment.Replying = false
          this.NewReplyInput = ''
          inputelmnt.value = ''
        },

        CommenttoPost(post){
          if(!post.Comments){
            post.Comments = []
          }
          let inputelmnt = document.getElementById('commentinputchip')
          let expandelmnt = document.getElementById(post.id+'commentpanel')
          let NewComment = 
          {
            content: inputelmnt.value,
            Likes: [],
            Shares: [],
            tags: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Replies: [],
            relatedtype: 'Social Post',
            relatedid: post.id,
            relatedowner: post.Creatorid
            
          }
          post.Comments.push(NewComment)
          if(this.UserRecord.Profile_Photo){
          NewComment.creatorimg = this.UserRecord.Profile_Photo
        }
          this.ContentRootPath.collection('socialsitecomments').add(NewComment).then(doc => {
          if(NewComment.Creatorid !== post.Creatorid){
          const NewNotification = {
                    postid: post.id,
                    Type: 'New Comment',
                    Header: this.UserRecord.Full_Name+' commented on your post',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: post.Creator.Full_Name,
                    Ownerid: post.Creatorid,
                    Read: false,
                    Path: '/SitePosts/'+post.id,
                    Message: 'Click to view Post',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
        })
          post.NewCommentInput = ''
          inputelmnt.value = ''
          expandelmnt.click()
        },
      LikeReply(reply){
        let UserhasLiked = reply.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
          relatedtype: 'Social Reply',
            relatedid: reply.id,
            relatedowner: reply.Creatorid
        }
        // post.Likes.push(newlike)
        this.ContentRootPath.collection('socialsitelikes').add(newlike).then(doc => {
          if(newlike.Likedbyid !== reply.Creatorid){
          const NewNotification = {
                    replyid: reply.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' likes your comment',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: reply.Creator.Full_Name,
                    Ownerid: reply.Creatorid,
                    Read: false,
                    Path: '/SitePosts/'+reply.id,
                    Message: 'Click to view Likes',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
                
        })
        }
        else{
          alert('you already liked this item')
        }
      },
      ReplytoReply(reply,comment){
        if(!reply.Replies){
            reply.Replies = []
          }
          console.log(reply)
          let inputelmnt = document.getElementById(reply.id+'replytoreplyinputchip')
          let NewReply = 
          {
            content: inputelmnt.value,
            Replies: [],
            Likes: [],
            Shares: [],
            tags: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Comments: [],
            relatedtype: 'Social Reply',
            relatedid: comment.id,
            relatedowner: reply.Creatorid
            
          }
          
          if(this.UserRecord.Profile_Photo){
            NewReply.creatorimg = this.UserRecord.Profile_Photo
            }
           this.ContentRootPath.collection('socialsitereplies').add(NewReply).then(doc => {
          if(NewReply.Creatorid !== reply.Creatorid){
          const NewNotification = {
                    replyid: reply.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' replied to your comment',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: reply.Creator.Full_Name,
                    Ownerid: reply.Creatorid,
                    Read: false,
                    Path: '/SitePosts/'+reply.id,
                    Message: 'Click to view Comments',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
        })
          reply.Replying = false
          this.NewReplyInput = ''
          inputelmnt.value = ''
      },
      LikeComment(comment){
        let UserhasLiked = comment.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
          relatedtype: 'Social Comment',
            relatedid: comment.id,
            relatedowner: comment.Creatorid
        }
        // post.Likes.push(newlike)
        this.ContentRootPath.collection('socialsitelikes').add(newlike).then(doc => {
          if(newlike.Likedbyid !== comment.Creatorid){
          const NewNotification = {
                    commentid: comment.id,
                    Type: 'New Like',
                    Header: this.UserRecord.Full_Name+' likes your comment',
                    Content: 'Click to view Likes',
                    CreatorFullName: this.UserRecord.Full_Name,
                    CreatorID: this.UserRecord.id,
                    Owner: comment.Creator.Full_Name,
                    Ownerid: comment.Creatorid,
                    Read: false,
                    Path: '/SitePosts/'+comment.id,
                    Message: 'Click to view Likes',
                    Date: new Date()
                    }
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

                    })
          }
        })
        }
        else{
          alert('you already liked this item')
        }
      },
      CancelPost(){
        this.postelmnt.innerHTML = ''
        this.CancelPostActive()
      },
      CreatePost(){
        // let postelmnt = document.getElementById('postinput')
        let postinput = this.postelmnt.innerHTML
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          creatorname: this.UserRecord.Full_Name,
          post: postinput,
          createdon: new Date(),
          Comments: []
        }
        if(this.UserRecord.Profile_Photo){
          NewPost.creatorimg = this.UserRecord.Profile_Photo
        }
        this.ContentRootPath.collection('socialsiteposts').add(NewPost)
        console.log(NewPost.post)
        this.CancelPost()
      },
    },
}

</script>

<style>
.postinputbox{
  outline: rgb(111, 204, 247);
  outline-width: 1px;
  outline-style: solid;
  background-color: white;
  min-height: 80px;
}
.postoutline {
  outline: 1px solid #dedee9;
  background-color: grey;
}
.postoutline:focus {
  outline: 1px solid #dedee9;
  background-color: white;
}
.Chipinput{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.commentblock {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #f0f2f5
}
.commentpost{
  background-color: #87B6E6  
}
.greyBG{
  background-color: #f0f2f5;
}
</style>           
    