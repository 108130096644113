import { render, staticRenderFns } from "./SuiteMainHome.vue?vue&type=template&id=71bf5145&"
import script from "./SuiteMainHome.vue?vue&type=script&lang=js&"
export * from "./SuiteMainHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports