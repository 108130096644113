
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
            <v-card :class="tab.BoxTransparency+' '+tab.CustomClass+' mx-3'"  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"
             :id="tab.ElementID+'_Tab'"
            :style="tab.AnimationCSS && tab.AnimationCSS.Tab ? 
            'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'+tab.AnimationCSS && tab.AnimationCSS.Tab : 
            'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
                              <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                                <v-card-title v-if="$route.name === 'PageEditor'">
                                  <v-switch style="position: absolute;right:20px;" dense label="PreviewMode" v-model="PreviewMode"/>
                                </v-card-title>
                                <!-- <v-divider inset class="white" >
                                </v-divider> -->
                            <table class="HTMLTable" style="width: 100%;">
                  <tr>
                      <th :class="tab.TableHeaderColor? 'HTMLTableContent '+tab.TableHeaderColor+' white--text lesssubtleoverline' : 'HTMLTableContent lesssubtleoverline'"  style="word-break:break-all;text-align: center;padding:20px;" v-for="header in tab.Headers" :key="header.itemObjKey">{{header.DisplayName}}</th>                                
                    </tr>
                    <tr class="detailslistoutline" v-for="record in tab.Data" :key="record.itemObjKey">
                      <td class="HTMLTableContent caption" :style="header.Type === 'Single File Upload'  ? 'text-align: center;' : 'text-align: center;'" v-for="header in tab.Headers" :key="header.itemObjKey">
                                <span v-if="header.Primary"> 
                                <!-- <v-avatar tile>
                                    <v-img contain
                                    :src="record[{{content.WikiName.split(' ').join('')}}ImageField.value]"
                                    />
                                </v-avatar>    -->
                            <span class="caption">
                                <FieldViewerComponent :field="header" :Record="record" v-if="PreviewMode" />
                                <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" v-if="!PreviewMode" :Record="record" :field="header" @onPhotoFileselected="onPhotoFileselected"
                                    :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                    :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                        />
                                </span>                   
                                </span>
                                <span v-if="!header.Primary">                        
                                <FieldViewerComponent :field="header" :Record="record" v-if="PreviewMode"  />
                                <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" v-if="!PreviewMode" :Record="record" :field="header" @onPhotoFileselected="onPhotoFileselected"
                                    :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                    :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                        />
                                </span>
                                </td>
                            </tr>
                        </table>
                             
                             <v-card-text v-if="tab.HasImage">
                                 <v-layout :class="tab.Justify" row>
                                 <v-img  v-if="tab.HasImage && tab.IMG"
                                 :contain="!tab.Clipped"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        :style="CheckifClipped(tab)"
                                       
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                 </v-layout>
                             </v-card-text>
                        
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">
                            <v-btn :dark="tab.ActionBtnDark"  :style="'background-color:'+tab.ButonColor.hex">
                                {{tab.ActionButtonName}}
                            </v-btn>
                        </v-card-actions>
                            </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';

export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex'],
    components: {
        FieldViewerComponent,
        FieldValueEditerComponent,
        TitleTab
        },
    data() {
    return {      
        ImageRecord: '',
        ImageField: '',
        PreviewMode: true,
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      UploadFilesList(){
        return this.$store.state.UploadFilesList
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
    SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      
      
      
      
      

    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        //console.log(this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v) && this.$route.meta && this.$route.meta.TableBuilder || 
        v && acceptedmethods.includes(v) && this.tab.Entityid === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    
    methods: {
        TitleAnimStyle(tab){
          return tab.AnimationCSS && tab.AnimationCSS.Title ? 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)+tab.AnimationCSS.Title : 
                                'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
        },
        onPhotoFileselected(field,file,files,Record){ 
            this.$emit('onPhotoFileselected',field,file,files,Record,this.tab)
            
        },
        UploadFileSelect(file,field,Record){
            Record[field.Name] = URL.createObjectURL(file)  
            let fileList = this.UploadFilesList.concat([file])
            this.$store.commit('setUploadFilesList',fileList)
            let filestorageref = ''
            if(this.$route.name === 'PageEditor'){
                filestorageref = 'Pages/'+this.$route.params.id+'/'+new Date()+'/'
            }            
            //let dbrecordref = this.TableCollectionRef
            this.$store.commit('setUploadFileStorageRef',filestorageref)
            this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
            console.log(file,field,Record)
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    