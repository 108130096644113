<template>
  <v-main style="margin-top: 60px;">
   <v-card-title style="border-radius: 15px;"
    :class="$vuetify.theme.dark ? 'SoftBuilderGradient justify-center mediumoverline' : 'SoftBuilderGradient justify-center mediumoverline'">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      My Marketplace Templates
      <v-spacer>
      </v-spacer>
      <v-menu			
            :close-on-content-click="false"                            									
            transition="scale-transition"                            									
            >									
            <template v-slot:activator="{ on }">						
            <v-btn v-if="!ActiveTemplate" v-on="on" outlined>Add Template</v-btn>
            </template>
            <v-card tile>
            <v-card-title class="black white--text">
                Choose Below
            </v-card-title>
            <v-card-text>
                <v-select return-object v-model="NewTempPrimCat" label="Category" :items="TemplateCategories" item-text="Name"/>
                <!-- <v-select return-object v-model="NewTempSecCat" label="Secondary Category" :items="SecondaryCategories" item-text="Name"/> -->
            </v-card-text>
            <v-card-actions>
                <v-btn dark outlined color="warning">Cancel</v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="ActivateMarketTemplateDialog()" dark outlined color="success">Start</v-btn>
            </v-card-actions>
            </v-card>  	
        </v-menu>
      <!-- <v-btn absolute right style="right: 100px;" @click="ActivateMarketTemplateDialog()" outlined>
        <v-icon>mdi-plus</v-icon>
        New
      </v-btn> -->
    </v-card-title>
    <v-card v-if="!PreviewMode" class="transparent" flat tile>
        <v-card-text>
            <!-- If you think about it, ideally, this comp as is, serves as a recyclable component, we should put this active comp into PlguinandTemplatesInteraction comp 
                <MarketTemplatesViewer
            :Templates="Templates"
            /> -->
        <MarketplaceTemplateCarousel :System="System" :SystemEntities="SystemEntities"
              :temp="temp" :Templates="MarketplaceTemplatePackages" @ActivateActiveTemplateComponent="ActivateActiveTemplateComponent"
              />
        </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'	
import MarketplaceTemplateCarousel from '@/components/SuitePlugins/MarketplaceTemplates/MarketplaceTemplateCarousel';	
export default {
    props: ['System','SystemEntities','SitePages','RADB'],
    components: {ContentEditableField,MarketplaceTemplateCarousel},
    data() {
        return {
            ViewingTemplate: false,
            NewTempPrimCat: '',
            NewTempSecCat: '',
            PrimaryCategories: [
                {ID:1000001,Name: 'Site'},
                {ID:1000002,Name: 'Page'}
            ],
            SecondaryCategories: [
                {ID: 1000001, Name: 'Website'},
                {ID: 1000002, Name: 'Social Network'},
                {ID: 1000003, Name: 'Group Site'},
                {ID: 1000004, Name: 'Featured Member'}
            ],
            TransfertoRA: false,
            Editing: false,
            NewCoverIMG: '',
            SampleCoverIMG: '',
            Refreshing: false,
            ActiveTemplate: '',
            ActiveTemplatechild: '',
            PreviewingMode: false
        }
    },	
    computed:{
        TemplateCategories(){
            return this.$store.state.TemplateCategories.filter(cat => {
                return cat.RootType
            })
        },
        MarketTemplatesDBRef(){
            return db.collection('marketplacetemplates')
        },
        ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
        SiteTemplate(){
            return this.TemplateisSite(this.ActiveMarketTemplate)
        },
        WebsiteTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Website'
        },
        SocialNetworkTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Social Network'
        },
        FeaturedMemberTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Group Site'
        },
        PreviewMode(){
            return !this.UserCanEdit || this.UserCanEdit && this.PreviewingMode
        },
        UserCanEdit(){
            return this.ActiveTemplate && this.ActiveTemplate.Ownerid === this.userLoggedIn.id || 
            this.$route.name === 'MyMarketplaceTemplates'
            //for support must add  || this.userIsAdmin once past testing
        },
        BuilderView(){
      return this.$store.state.BuilderView
      },
      NetworkBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
       UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
        RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
        TemplateCols(){
            let cols = 12
            if(this.$vuetify.breakpoint.xl){
                cols = 2
            }
            else if(this.$vuetify.breakpoint.lg){
                cols = 3
            }
            else if(this.$vuetify.breakpoint.md){
                cols = 4
            }
            else if(this.$vuetify.breakpoint.sm){
                cols = 6
            }
            return cols
        },
        MyTemplatesView(){
            return this.$route.name === 'MyMarketplaceTemplates'
        },
        MarketplaceTemplatePackages(){
            return this.MyMarketplaceTemplates.filter(temp => {
                return !temp.Parentid
            }).map(temp => {
                let tempobj = Object.assign({},temp)
                if(typeof tempobj.Price === 'undefined' || tempobj.Price === 0){
                    tempobj.Price = 'Free'
                }
                return tempobj
            })
        },
        MarketplaceTemplates(){
        return this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        NewSitePageid(){
        return this.$store.state.NewSitePageid
      },
    },
    watch: {
        ActiveTemplate: {
            handler: function(newvalue, oldvalue) {
             //console.log('oldvalue',oldvalue, 'newvalue',newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue){   
                    if(!newvalue.id && this.BuilderView){
                        this.$store.commit('setBuilderView','')
                    }   
                    if(newvalue.id){
                        //console.log('this.TemplateisSite(newvalue)',this.TemplateisSite(newvalue))
                        //this.$store.commit('setActiveMarketTemplate',newvalue)
                        //we don't need ActiveMarketTempalte unless we change how you "preview" to a "wholly complete preview."
                        //So for now leave off ride with ActiveTemplateSite rather
                        //even so IF we do this, it would be "routed" and picked up by apppluginsandroutes comp
                        if(this.TemplateisSite(newvalue)){
                            this.$store.commit('setActiveTemplateSite',newvalue)
                            this.SetBuilderView()
                            //console.log('uau')
                        }
                        else if(newvalue.Category && newvalue.Category.Name === 'Database App'){
                            this.$router.push('/MarketplaceTemplate/'+newvalue.id)
                        }
                    }         
                    
                    if(newvalue && newvalue.Category && newvalue.Category.Name === 'Featured Member'){
                        //okay it's a fet member do we have one active?
                        //do we include "page" here?
                        if(!this.ActiveFeaturedMember){
                            //we need to set active member how do we do that?
                            let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.userLoggedIn.id)
                            query.onSnapshot(snapshot => {
                                let FeaturedMember = snapshot.data()
                                FeaturedMember.id = this.userLoggedIn.id
                                FeaturedMember.Query = query
                                //console.log('this.FeaturedMember ',FeaturedMember)
                                this.$store.dispatch('GetActiveFeaturedMember',FeaturedMember) 
                                //console.log(this.$store.state.ActiveFeaturedMember)
                            })
                        }
                    }
                }
            },deep: true
        },
        // ActiveTemplatechild: {
        //     handler: function(newvalue, oldvalue) {
        //         if(newvalue !== oldvalue){
        //             if(newvalue.id){
        //                 this.$store.commit('setActiveMarketTemplate',newvalue)
        //                 console.log(this.$store.state.ActiveMarketTemplate)
        //             }   
        //         }
        //     },deep: true
        //decided not to because now the ActiveMarketTemplate is parent and could receive nabar items etc!
        // },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.$store.commit('setBuilderView','')
        this.$store.commit('setSiteDialog',false)
        this.$store.commit('setActiveMarketTemplate','')
    },
    methods:{
        TemplateisPage(temp){
            let pagecats = this.$store.state.TemplateCategories.filter(obj => obj.IsPage).map(obj => {
            return obj.ID
            })
            return temp && temp.Category && pagecats.includes(temp.Category.ID)
        },
        TemplateisSite(temp){
            let sitecats = this.$store.state.TemplateCategories.filter(obj => obj.IsSite).map(obj => {
            return obj.ID
            })
            return temp && temp.Category && sitecats.includes(temp.Category.ID)
        },
        SitePageType(temp){
            return temp.Category.Name === 'Site'
        },
        SitePrimaryPage(temp){
            let childtemplates = this.MarketplaceTemplates.filter(child => {
                return child.Parentid === temp.id
            })
            let defpageprop = ''
            if(temp.Category.Name === 'Website' || temp.Category.Name === 'Featured Member'){
                    defpageprop = 'Public_Landing_Page'
                }
                else if(this.SocialNetworkTemplate){
                    defpageprop = 'Social_Landing_Page'
                }
            if(temp.TemplateProps[defpageprop]){
                return childtemplates.find(obj => obj.TemplateObj.Name.split('/').join('').split(' ').join('').split('-').join('').split('_').join('') === temp.TemplateProps[defpageprop])
            }
            else if(childtemplates[0]){
                return childtemplates[0]
            }
        },
        ActivateMarketTemplateDialog(){
            this.ActivateTemplateDialog(this.NewTempPrimCat,this.NewTempSecCat)
            this.NewTempPrimCat = ''
            this.NewTempSecCat = ''
        },
        ActivateTemplateDialog(primcat,seccat){
            let ref = this.MarketTemplatesDBRef.doc()
            let Templateid = ref.id
            let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            let systemurl = this.System.ClientAppURL
            let defobj = {
                Templateid: '',
                Systemid: systemid,
                ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                PluginDataBase: {},
                TemplateObj: {},
                Category: primcat,
            } 
            let payload = Object.assign({},defobj)
            payload.Category = primcat
            if(primcat.Name === 'Site'){
                payload.Templateid = Templateid
                payload.TemplateProps = {
                     Navlist: [],
                     NavlistProp: ''
                }
                if(primcat.Name === 'Featured Member'){
                    payload.TemplateProps.NavlistProp = 'MenuItems'
                }
                else if(primcat.Name === 'Website'){
                    payload.TemplateProps.NavlistProp = 'PublicNavbarItems'
                }
                else if(primcat.Name === 'Social Network'){
                    payload.TemplateProps.NavlistProp = 'SocialNavbarItems'
                }
                else if(primcat.Name === 'Group Site'){
                    payload.TemplateProps.NavlistProp = 'GroupNavbarItems'
                }
                
            }
            this.$store.commit('SetSocialItemInteractMethodProp',payload) 
            this.$store.commit('SetSocialItemInteractMethod','ActivateMarketplaceTemplateDialog') 
            
        },
        RoutetoAll(){
            this.$router.push('/MyMarketplaceTemplates')
            this.CancelTemplateView()
            this.$store.commit('setActiveTemplateSite','')
        },
        
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
         // This should be in AppNotifications
        CancelTemplateView(){
            this.ActiveTemplate = ''
            this.ActiveTemplatechild = ''
            this.Editing = false
            this.TransfertoRA = false
        },
        SetBuilderView(){
            if(this.UserCanEdit){
                //for support must add  || this.userIsAdmin once past testing
                this.$store.commit('setBuilderView',{Name: 'Marketplace Site'})
            }                
        },
        ActivateActiveTemplateComponent(temp,toRA){
        this.ActiveTemplate = temp
        this.TransfertoRA = toRA
        // if(!this.TransfertoRA){????
             if(this.TemplateisSite(this.ActiveTemplate)){
                this.SetBuilderView()  
                this.$router.push('/MarketplaceTemplate/'+temp.id)              
            }
            else if(this.ActiveTemplate.Category.Name === 'Web Page'){
                //same things urgh
                //this.SetBuilderView()  
                if(this.UserCanEdit){
                this.$store.commit('setBuilderView',{Name: 'Website Builder'})
                }
                this.$router.push('/MarketplaceTemplate/'+temp.id)              
            }
            else if(this.BuilderView){
                this.$store.commit('setBuilderView','')
            }
        // }
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
    }
}
</script>

<style>
</style>



