

<template>
    <div :style="IntranavStyling">
        <!-- <v-card  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover'  ,position: 'fixed',top: '30px'}" width="100%" class="stickytop" flat tile> -->
               <v-toolbar v-if="$route.name !== 'GroupPage' && !AppBuilderView" height="50" :class="AppStylingView ? 'socialappsearchbar stickytop3AppStylingView' : 'socialappsearchbar stickytop3'" :style="this.AppStylingView ? '' : 'z-index:100'" flat>
             <!-- class="hidden-md-and-up" -->
          
          <!--<v-toolbar-title class="mediumoverline hidden-sm-and-down">
            Intranet Menu
          </v-toolbar-title> -->
          <v-layout v-if="UserRecord && UserRecord.id">
              
        <!-- <div id="notificationbar"> -->
                   <v-toolbar-title class="mediumoverline">
                <v-text-field style="padding-top:20px;" label="Search" append-icon="mdi-file-find-outline"  light dense  background-color="white" v-model="search" @keydown.enter="ActivateSearch()"/>
              </v-toolbar-title>   
                  <v-spacer></v-spacer>
                  
          <v-list  class="transparent">
                  <v-list-item>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                          <template v-slot:activator="{ on }">
                            <v-badge :color="NotificationsColor" overlap
                              >

                              <v-icon
                              
                              v-on="on">
                              mdi-bell-check
                            </v-icon>

                            <span slot="badge"> {{UnreadNotifications.length}} </span>
                            </v-badge>
                           
                          </template>

                          <v-tabs dark>
                                <v-tab>Unread</v-tab>
                                <v-tab-item>
                                  <v-card
                                  elevation="16"
                                  :width="$vuetify.breakpoint.xs ? '300' : '450'"
                                  class="mx-auto"
                                >
                                  <v-virtual-scroll
                                  :items="UnreadNotifications"
                                  height="360"
                                  item-height="100%"
                                 
                                >
                                <template v-slot:default="{ item }">
                                  <v-list-item :key="item" :class="{newbasicoutlined: item.Read}"  @click="NotificationRead(item)" two-line>
                                     <v-list-item-content>
                                     <v-list-item-title>
                                       {{item.Header}}
                                     </v-list-item-title>
                                    <v-list-item-subtitle >                                    
                                      <p v-if="!$vuetify.breakpoint.xs" class="notificationcontent">{{item.Message}}<span class="tooltiptext"><h4>{{item.CreatorFullName}}</h4>{{item.Content.substr(0, 50)}}</span></p>
                                    </v-list-item-subtitle>
                                     </v-list-item-content>
                                  </v-list-item>
                                  
                                </template>
                                </v-virtual-scroll>
                                  </v-card>
                                </v-tab-item>
                                <v-tab>Read</v-tab>
                                <v-tab-item>
                                   <!-- <v-icon @click="ClearReadNotifications()" color="red">mdi-close</v-icon> -->
                                  <v-card
                                  elevation="16"
                                  :width="$vuetify.breakpoint.xs ? '300' : '450'"
                                  class="mx-auto"
                                >
                                  <v-virtual-scroll
                                  :items="ReadNotifications"
                                  height="360"
                                  item-height="77"
                                >
                                <template v-slot:default="{ item }">
                                   <v-list-item :key="item" :class="{newbasicoutlined: item.Read}" two-line>
                                     <v-list-item-content>
                                     <v-list-item-title>
                                       {{item.Header}}
                                     </v-list-item-title>
                                    <v-list-item-subtitle >                                    
                                      <p v-if="!$vuetify.breakpoint.xs" class="notificationcontent">{{item.Message}}<span class="tooltiptext"><h4>{{item.CreatorFullName}}</h4>{{item.Content.substr(0, 50)}}</span></p>
                                    </v-list-item-subtitle>
                                     </v-list-item-content>
                                      <v-list-item-action>
                                      <v-btn x-small @click="MarkasUnread(item)">Mark Unread</v-btn><v-btn x-small @click="ViewItem(item)">View</v-btn>
                                      </v-list-item-action>
                                  </v-list-item>
                                </template>
                                </v-virtual-scroll>
                                  </v-card>
                                </v-tab-item>
                              </v-tabs>


                            </v-menu>                            
                      </v-list-item>                                            
                  </v-list>
                  <v-list class="transparent">
                  <v-list-item>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                          <template v-slot:activator="{ on }">
                            <v-badge :color="MessagesColor" overlap
                              >

                              <v-icon
                              
                              v-on="on">
                              mdi-message
                            </v-icon>

                            <span slot="badge"> {{UnreadMessages.length}} </span>
                            </v-badge>
                          </template>
                                <v-list flat width="100%">
                                  <h3 class="viewingnotificationheader">Conversations</h3><br>
                                  <v-card @click="ActivateMessage(msg)" outlined flat width="350px" class="messageboxheader" v-for="msg in ConversingUsers" :key="msg.itemObjKey">
                                  <v-list two-line :class="msg.Color">
                                    <v-list-item>
                                      
                                        <v-list-item-avatar  size="35">
                                      <!-- <v-avatar size="35"> -->
                                    <v-img :src="msg.Profile_Photo" v-if="msg.Profile_Photo"/>
                                        <v-img src="@/assets/BlankProfilePic.png" v-if="!msg.Profile_Photo"/>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-text="msg.Full_Name"></v-list-item-title>
                                          <v-list-item-subtitle v-if="msg.IsGroup"><v-chip small>Group</v-chip></v-list-item-subtitle>
                                          <v-list-item-subtitle v-if="msg.lastMessage" v-text="msg.lastMessage.message"></v-list-item-subtitle>
                                        </v-list-item-content>
                                          <v-spacer>
                                            </v-spacer>
                                            <span style="font-size:8px">{{msg.lastMessage.SendTime}}</span>
                                    </v-list-item>
                                    
                                  </v-list>
                                </v-card>
                                  
                                </v-list>                                
                            </v-menu>
                      </v-list-item>                                            
                  </v-list>
                  <v-icon class="actionicon mx-2" @click="ToggleAppDarkMode()">
                      mdi-invert-colors
                    </v-icon>
                  <v-list  class="transparent">
                <v-list-item>
                   <ProfileActionsDropdown  :ClientDB="ClientDB" :System="System"
                   @ToggleAppStylingView="ToggleAppStylingView"/>
                </v-list-item>
        </v-list>
                  
          </v-layout>
           
                       
          </v-toolbar>
               <v-list id="logobar" v-if="$route.name !== 'GroupPage'" :style="LogoBarStyle" width="100%" :class="AppStylingView ? 'stickytopAppStylingView' : 'stickytop'">
                  <v-list-item>
                    <!-- <v-list-item-content > -->
                    <v-list-item-avatar tile size="80">
                      <v-img contain :src="System.Social_NavBar_IMG ? System.Social_NavBar_IMG : require('@/assets/BnPFullLogo_tiny.png')" v-if="!EditingNavbar"/> 
                      <v-img class="black" contain v-if="EditingNavbar"							
                        :src="System.Social_NavBar_IMG ? System.Social_NavBar_IMG : require('@/assets/BnPFullLogo_tiny.png')"									
                        >
                        <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    
                            <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteSocialNavbarIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </v-row>
                    </v-img>           
                    </v-list-item-avatar>
                    <!-- </v-list-item-content> -->
                    <v-list-item-content >
                      <v-list-item-title class="largeoverline white--text" v-if="!EditingNavbar">{{System.Name}}</v-list-item-title> 
                      <v-list-item-title class="largeoverline white--text" v-if="EditingNavbar">
                        <v-text-field  dark class="RAWebBtnGraddarksoft" dense label="Name" v-model="System.Name"/>
                        </v-list-item-title>                    
                      <v-list-item-subtitle class="overline white--text" v-if="!EditingNavbar">
                        {{System.Social_Navbar_Subtitle ? System.Social_Navbar_Subtitle : 'a Brand new ERA '}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="overline white--text" v-if="EditingNavbar">
                        <v-text-field  dark class="RAWebBtnGraddarksoft" dense label="Subtitle" v-model="System.Social_Navbar_Subtitle"/>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
             </v-list>
               <!-- <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="left"
                      >
               <img contain height="40px" src="@/assets/RAInvert.png">

                <span class="largeoverline white--text">RapidApps</span>
               </v-row>  -->
            <!-- </v-card> -->
            <!-- <v-card width="100%" flat class="transparent" style="margin-bottom:30px">
            <v-system-bar style="position:fixed;z-index:4;" window :dark="AppisDarkMode">
           <v-spacer></v-spacer>
           <input clearable class="searchinput" v-model="search" type="text" id="fname" name="fname" @keydown.enter="ActivateSearch()">
            <v-icon color="white">mdi-file-find-outline</v-icon>            
          </v-system-bar>
          
            </v-card> -->
            
            <PrimaryNavigation v-if="PrimaryNavigationActive" :style="PrimaryNavstyle" :BottomNavigationItems="PrimaryNavigationItems" :ThemeColor="BottomNavThemeColor" :ThemeBGColor="BottomNavClass"
    :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView" :BottomMenu="true" :TopMenu="false"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
    <SecondaryNavigation v-if="SecondaryNavigationActive" :style="SecondaryNavstyle" :BottomNavigationItems="SecondaryNavigationItems" :ThemeColor="BottomNavThemeColor" :ThemeBGColor="BottomNavClass"
    :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :BottomMenu="false" :TopMenu="true"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
          
    <div>
      <HelperComponent :HelperActive="HelperActive" :HelperElements="HelperElements" :OffsetX="'left'" :OffsetXVal="50" :OffsetY="'top'" :OffsetYVal="0"
    @DeactivateHelper="DeactivateHelper" @ActivateHelper="ActivateHelper" @StopHelper="StopHelper"  :KeepPulseIndexes=[]
    :HelperID="ActiveHelperID"
    />
    <!-- :class="MiniView || !ActiveSuiteApp ? 'visitordrawercontainermini transparent' : 'visitordrawercontainer transparent'" -->
    <v-list v-if="HasActiveVisitor"
     :style="MiniView ? '--btm:24vh' : '--btm:18vh'" :class="VisitorToggle ? 'transparent visitordrawercontainer open' : 'transparent visitordrawercontainer'">
    <!--  :style="VisitorToggle && MiniView ? 'top: 30vh;' : VisitorToggle ? 'top: 25vh;' : 'top:70vh;'" -->
        <!-- :style="VisitorToggle ? 'left: 100px!important;' : 'left: -190px!important;'"  -->
        <v-list :class="MiniView ? 'transparent visitordrawermini' : 'transparent visitordrawer'" width="350">
          <v-list-item   @click="CheckActiveVisitor()"
           :class="MiniView ? 'grey visitordrawertoggle' : 'grey visitordrawertoggle'" style="z-index: 2;">
           <v-list-item-content style="padding: 0px;">
          <UserLookup :UsersLookupArray="TemplateUsers"  :ModelProp="'ActiveVisitor'" :RecordObj="this" @UpdateUserLookupProp="UpdateUserLookupProp"
                  :FieldLabel="'View As'" :Rules="[$store.state.formrules.required]" v-if="ActiveMarketTemplate" style="max-height: 70px;"
                  /> 
                  <v-chip style="max-width: 200px;" v-if="!ActiveMarketTemplate && ActiveVisitor">
                    {{ActiveVisitor.Full_Name}}
                  </v-chip>
           </v-list-item-content>
           <v-list-item-action >
            <v-badge
          avatar
          bordered
          overlap
        >
          <template v-slot:badge>
            <v-avatar :color="ComputedNotifications.length > 0 ? 'red white--text' : 'grey white--text'">
            {{ComputedNotifications.length}}
            </v-avatar>
          </template>
          <v-avatar style="cursor: pointer;" id="visitortogglebar" color="blue" size="40"  @click="ActivateVisitorToggle()">
            <img style="object-fit: cover;" :src="ActiveVisitor && ActiveVisitor.Profile_Photo ? ActiveVisitor.Profile_Photo : require('@/assets/BlankProfilePic.png')"/>
          </v-avatar>
        </v-badge>
           </v-list-item-action>
        </v-list-item>
        
        <!-- this is BRILLIANT but I don't think it works here. -->
          <div id="activevisitorbubble" class="bubblenav">
          <div id="activevisitorham" class="hamburger">
          <span class="hamburgerline"></span>
          <span class="hamburgerline"></span>
          <span class="hamburgerline"></span>
          </div>
          <div class="bubblemenuitem" style="--i: 0">
            <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
          <div class="bubblemenuitem" style="--i: 1">
            <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
          <div class="bubblemenuitem" style="--i: 2">
           <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
          <div class="bubblemenuitem" style="--i: 3">
            <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
        </div> 
        <!-- Two things, we are sampling the user view, but we also possibly creating a new "my circles" view
        1. My Social Profile, 2. Invited Groups, 3. My Groups, 4. My Friends
        But for display purposes the focus is 
        a) notifications
        b) messenger -->
        
        <v-list width="300px" height="100vh" style="--top: 90px;box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);" :class="SubMenuActive ? 'submenulist submenuactive' : 'submenulist'">
           <v-list-item class="submenuitem" @click="ActivateSubmenu('Messages')">
            <v-list-item-avatar>
              <v-icon>mdi-keyboard-return
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{ActiveSubmenu}}
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="ActiveSubmenu === 'Notifications'">
             <v-virtual-scroll 
              class="transparent" :style="'margin-top:20px;height: 60vh;'"
                :items="ComputedNotifications"
                :height="$vuetify.breakpoint.xs ? 300 : 300"
                :item-height="$vuetify.breakpoint.xs ? 110 : 110"
              >
              <template v-slot:default="{ item }">
             <v-list dense>
              <v-list-item dense :class="{newbasicoutlined: item.Read}"  @click="NotificationRead(item)" two-line>
                  <v-list-item-content>
                  <v-list-item-title>
                    {{item.Header}}
                  </v-list-item-title>
                <v-list-item-subtitle  v-if="item.Type !== 'Friendship Requested'">                                    
                  <p v-if="!$vuetify.breakpoint.xs" class="notificationcontent">{{item.Message}}
                    <!-- This just not working for us anymore <span class="tooltiptext"><h4>{{item.CreatorFullName}}</h4>{{item.Content.substr(0, 50)}}</span> -->
                    </p>
                </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item dense class="justify-space-between" v-if="item.Type === 'Friendship Requested'">
                <v-btn small dark color="success">
                  Accept
                </v-btn>
                <v-btn small dark color="error">
                  Decline
                </v-btn>
              </v-list-item>
              <v-list-item dense class="justify-space-between" v-if="item.Type !== 'Friendship Requested'">
                <v-btn dark color="blue" small @click="MarkasUnread(item)">Mark Unread</v-btn>
                <v-btn dark color="success" small @click="ViewItem(item)">View</v-btn>
                <!-- <v-btn small dark color="success">
                  Accept
                </v-btn>
                <v-btn small dark color="error">
                  Decline
                </v-btn> -->
              </v-list-item>
             </v-list>
            </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="ActiveSubmenu === 'Messages'">
          </v-card-text>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="ActiveSubmenu === 'Friends'">
            <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="ComputedFriendsList"
                    :item-height="50"
                    :height="ComputedFriendsList.length === 0 ? 300 : 300"
                  >
                  <template v-slot:default="{ item }">
            <v-list-item @click="ActivateMessage(item)">
                 
                  <v-badge
                   v-if="item.onlinestatus === 'online'"
                    top
                    color="light-green accent-3"
                    dot
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-avatar size="35">
                      <v-img :src="item.Profile_Photo" v-if="item.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"/>
                    </v-avatar>
                  </v-badge>
                  <v-avatar size="35" v-if="item.onlinestatus !== 'online'">
                      <v-img :src="item.Profile_Photo" v-if="item.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"/>
                    </v-avatar>
                
                 <v-list-item-title class="socialmenufont--text">
                   {{item.Full_Name}}
                 </v-list-item-title>
               </v-list-item>
                  </template>
             </v-virtual-scroll>
          </v-card-text>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="ActiveSubmenu === 'Groups'">
            <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="ComputedGroups"
                    :item-height="35"
                    :height="ComputedGroups.length === 0 ? 30 : 140"
                  >
                  <template v-slot:default="{ item }">
            <v-list-item  :to="'/Group/'+item.id">
                 <v-list-item-avatar size="30">
                   <v-img :src="item.logo" v-if="item.logo"/>
                   <v-img src="@/assets/logo.png" v-if="!item.logo"/>
                 </v-list-item-avatar>
                 <v-list-item-title class="socialmenufont--text">
                   {{item.name}}
                 </v-list-item-title>
               </v-list-item>
                  </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="ActiveSubmenu === 'Invited Groups'">
            <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="ComputedGroupInvites"
                    :item-height="35"
                    :height="ComputedGroupInvites.length === 0 ? 30 : 140"
                  >
                  <template v-slot:default="{ item }">
            <v-list-item  :to="'/Group/'+item.id">
                 <v-badge color="warning" overlap>
                 <v-list-item-avatar size="30">
                   <v-img :src="item.logo" v-if="item.logo"/>
                   <v-img src="@/assets/logo.png" v-if="!item.logo"/>
                 </v-list-item-avatar><span slot="badge"> 1</span>
                 </v-badge>
                 <v-list-item-title class="socialmenufont--text">
                   {{item.name}}
                 </v-list-item-title>
               </v-list-item>
                  </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-list>
        <v-list dense id="menulist" :class="SubMenuActive ? 'menulist submenuactive' : 'menulist'" width="300px" height="100vh" style="--top: 70px;box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);" >
        <v-list-item :to="'/SiteMember/'+ActiveVisitor.id">
            <v-list-item-avatar class="blue" tile size="40">
              <img v-if="ActiveVisitor.Profile_Photo "
              :src="ActiveVisitor.Profile_Photo "    
              style="object-fit: cover;"          
              >
              <img v-if="!ActiveVisitor.Profile_Photo "
                src="@/assets/BlankProfilePic.png"   
                style="object-fit: cover;"           
              >                      
              </v-list-item-avatar>
            <v-list-item-content >
              <v-list-item-title>{{ActiveVisitor.Full_Name}}</v-list-item-title>                    
            
            </v-list-item-content>
          </v-list-item>
        <v-list-item class="submenuitem" @click="ActivateSubmenu('Messages')">
          <v-list-item-avatar>
            <v-icon>mdi-message
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content id="messagesbtn">
          Messages
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="submenuitem" @click="ActivateSubmenu('Notifications')">
          <v-list-item-avatar>
            <v-icon>mdi-bell-check
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content id="notificationsbtn">
          Notifications ({{ComputedNotifications.length}})
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="submenuitem" @click="ActivateSubmenu('Friends')">
          <v-list-item-avatar>
            <v-icon>mdi-account-multiple
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content id="friendsbtn">
          Friends
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="submenuitem" @click="ActivateSubmenu('Groups')">
          <v-list-item-avatar>
            <v-icon>mdi-account-group
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content id="groupsbtn">
          Groups
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="submenuitem" @click="ActivateSubmenu('Invited Groups')">
          <v-list-item-avatar>
            <v-icon>mdi-human-greeting
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content id="invitesbtn">
          Invites
          </v-list-item-content>
        </v-list-item>
        </v-list>
        </v-list>
      </v-list> 
    <!-- This clears yet another 2000 lines on App comp if we do this right.  -->
   
  </div>   
    <v-navigation-drawer v-model="socialquicknav" right class="fixedquicknav"  id="socialsitequicknav"
          :class="AppisDarkModeCard"
            fixed :dark="AppisDarkMode" >
            <v-icon color="warning" @click="socialquicknav = false">mdi-close</v-icon>
          <v-list dense width="240px" height="100%" :dark="AppisDarkMode" class="transparent">
          <v-list-item>
             <span class="socialmenufont--text intranetnavlinks">My Social Profile</span>
            </v-list-item>
            <v-list-item :to="'/SiteMember/'+UserRecord.id">
              <!-- <v-list-item-content > -->
              <v-list-item-avatar class="blue" tile size="40">
                <img v-if="UserRecord.Profile_Photo "
                :src="UserRecord.Profile_Photo "    
                style="object-fit: cover;"          
                >
                <img v-if="!UserRecord.Profile_Photo "
                  src="@/assets/BlankProfilePic.png"   
                  style="object-fit: cover;"           
                >                      
                </v-list-item-avatar>
              <!-- </v-list-item-content> -->
              <v-list-item-content >
                <v-list-item-title>{{UserRecord.Full_Name}}</v-list-item-title>                    
              
              </v-list-item-content>
            </v-list-item>
          <v-list-item>
             <span class="socialmenufont--text intranetnavlinks">Invited Groups</span>
            </v-list-item>
            <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="invitedgroups"
                    :item-height="35"
                    :height="invitedgroups.length === 0 ? 30 : 140"
                  >
                  <template v-slot:default="{ item }">
            <v-list-item @click="socialquicknav = false" :to="'/Group/'+item.id">
                 <v-badge color="warning" overlap>
                 <v-list-item-avatar size="30">
                   <v-img :src="item.logo" v-if="item.logo"/>
                   <v-img src="@/assets/logo.png" v-if="!item.logo"/>
                 </v-list-item-avatar><span slot="badge"> 1</span>
                 </v-badge>
                 <v-list-item-title class="socialmenufont--text">
                   {{item.name}}
                 </v-list-item-title>
               </v-list-item>
                  </template>
            </v-virtual-scroll>
            <v-divider></v-divider>
         

            <v-list-item>
             <span class="socialmenufont--text intranetnavlinks">Your Groups</span>
            </v-list-item>
            <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="groups"
                    :item-height="35"
                    :height="groups.length === 0 ? 30 : 140"
                  >
                  <template v-slot:default="{ item }">
            <v-list-item @click="socialquicknav = false" :to="'/Group/'+item.id">
                 <v-list-item-avatar size="30">
                   <v-img :src="item.logo" v-if="item.logo"/>
                   <v-img src="@/assets/logo.png" v-if="!item.logo"/>
                 </v-list-item-avatar>
                 <v-list-item-title class="socialmenufont--text">
                   {{item.name}}
                 </v-list-item-title>
               </v-list-item>
                  </template>
            </v-virtual-scroll>
            <v-divider></v-divider>
            <v-list-item>
              <span class="socialmenufont--text intranetnavlinks">Your Friends</span>
            </v-list-item>
             <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="FriendsList"
                    :item-height="35"
                    :height="FriendsList.length === 0 ? 30 : 140"
                  >
                  <template v-slot:default="{ item }">
            <v-list-item @click="ActivateMessage(item)">
                 
                  <v-badge
                   v-if="item.onlinestatus === 'online'"
                    top
                    color="light-green accent-3"
                    dot
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-avatar size="35">
                      <v-img :src="item.Profile_Photo" v-if="item.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"/>
                    </v-avatar>
                  </v-badge>
                  <v-avatar size="35" v-if="item.onlinestatus !== 'online'">
                      <v-img :src="item.Profile_Photo" v-if="item.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"/>
                    </v-avatar>
                
                 <v-list-item-title class="socialmenufont--text">
                   {{item.Full_Name}}
                 </v-list-item-title>
               </v-list-item>
                  </template>
             </v-virtual-scroll>
          </v-list>
          </v-navigation-drawer>
          <v-card id="messagebox" class="messagebox" :style="MessageBoxPadRight" width="300px" v-if="MessageDialog">
         
          <v-card class="messageboxheader" dark>
            <v-img height="80" src="@/assets/RABaseBG.jpeg">
            <v-list two-line class="transparent">
              <v-list-item>
                
                  <v-list-item-avatar  size="35">
              <v-img :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="MessagetoUser.Full_Name"></v-list-item-title>
                    <v-list-item-subtitle v-text="MessagetoUser.onlinestatus"></v-list-item-subtitle>
                  </v-list-item-content>
            
            <v-spacer>
                </v-spacer>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-phone</v-icon></v-btn>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-video</v-icon></v-btn>
                  <v-btn icon @click="DeactivateUserMessage"><v-icon color="red">mdi-close</v-icon></v-btn>
                  
        
              </v-list-item>
              
            </v-list>
            </v-img>
          </v-card>
            
             <v-list class="messagelist" id="messagelist" flat>
               <v-list-item v-for="message in ConversationAscending" :key="message.itemObjKey">
                 <v-list flat class="transparent" width="100%">
                   <v-list-item :dark="message.dark">
                 <v-list-item-avatar size="25"  v-if="message.ReadType === 'Received'">
                   <v-img :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                 </v-list-item-avatar>
                 <v-list-item-content :class="message.Color">
                   <span style="padding-left:10px;padding-right:1px;" v-html="message.message"></span>
                   
                 </v-list-item-content>
               </v-list-item>
               <v-list-item>
                 <v-list-item-subtitle style="font-size:10px">
                   {{message.SendTime}}
                 </v-list-item-subtitle>
               </v-list-item>
                 </v-list>
               </v-list-item>            
             </v-list>
             <v-list>
                <v-list-item>
                  
                <span  class="newmessagepost mx-3" role="textbox" contenteditable id="messageinputchip"  @keydown.enter="CreateMessage(MessagetoUser,UserRecord)"  placeholder="Type Message"/>
                <v-icon>mdi-share</v-icon>
               </v-list-item>
                 </v-list>
             
        </v-card>
        <!-- <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
        <SystemTicketsLogging :SystemEntities="SystemEntities" :db="ClientDB"
        @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog> -->
    <v-list class="transparent" style="z-index: 5;width:100%;">
    <v-btn width="100" absolute dark outlined class="BoldBuilderGradient"  @click="ActivateEditingNavbar()" right style="z-index:5;top:50px;" v-if="UserCanEditBar && !EditingNavbar && !EditingNavbarBG">
        Edit
    </v-btn>
    <v-btn width="100" absolute dark outlined class="BoldBuilderGradient" @click="ActivateEditingNavbarBG()" right style="z-index:5;top:90px;" v-if="UserCanEditBar && !EditingNavbar && !EditingNavbarBG">
        BG
        <!-- SampleSiteSocialNavbarBGIMG Social_NavBar_Header_IMG','Social_NavBar_Gradient -->
        
    </v-btn>
    
    <v-btn dark @click="CancelEditingNavbar('EditingNavbar'),CancelEditingNavbar('EditingNavbarBG')" color="warning" absolute right style="z-index:5;top:50px;" v-if="EditingNavbar || EditingNavbarBG">
        Cancel
    </v-btn>
    <v-btn dark @click="UpdateNavbarChanges()" color="green" absolute right style="z-index:5;top:90px;" v-if="EditingNavbar || EditingNavbarBG">
        Save
    </v-btn>
     <v-img class="black" style="z-index:4;top:50px;" v-if="EditingNavbarBG"							
            :src="SocialNavbarHeaderIMG" height="120"								
            >
             <v-list-item class="white" style="padding-top:0px;width: 50%;height:15px;" dense>
                <v-text-field dark class="RAWebBtnGraddarksoft"
                label="Navbar Gradient"
                v-model="System.Social_NavBar_Gradient"
            
                />
                <a href="https://cssgradient.io/" target="_blank">Get Code</a>
            </v-list-item>
            <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
        
                <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteSocialNavbarBGIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
        </v-img> 
    </v-list>
    </div>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
import ProfileActionsDropdown from '@/components/Navigation/ProfileActionsDropdown'
import PrimaryNavigation from '@/components/Navigation/BottomNavigation'
import SecondaryNavigation from '@/components/Navigation/BottomNavigation'
import UserLookup from '@/components/General/UserLookup'
import HelperComponent from '@/components/General/HelperComponent'
export default {
  props: ['AppisDarkMode','CurrentRecordName','FriendsList','Notifications','System','SitePages','SystemEntities','ActiveSearch',
  'AppStylingView','GraphicalElements','ActivityStatusField','SocialQuickNavPush','SystemPhotoLibraries'],
  components: {SystemTicketsLogging,ProfileActionsDropdown,PrimaryNavigation,SecondaryNavigation,
  UserLookup,HelperComponent},
  data() {
    return {
      SubMenuActive: false,
          ActiveSubmenu: '',
          //SampleVisitorProps: ['VisitorNotifications','VisitorGroups','VisitorGroupInvites',''],
          VisitorNotifications: [],
          VisitorGroups: [],
          ActiveHelperID: '',
          HelperElements: [
                {elmntid: 'visitortogglebar',Icon: 'mdi-account',Title: 'Visitor Bar',Text: `This will become the new Social Nav. Until then, use this menu to change the "Vistior" and interact as a sample User`},
                {elmntid: 'messagesbtn',Icon: 'mdi-message',Title: 'Messages',Text: `Here you can view your messages`},
                {elmntid: 'notificationsbtn',Icon: 'mdi-bell-check',Title: 'Notifications',Text: `Your Notifications will appear here.`},
                {elmntid: 'friendsbtn',Icon: 'mdi-account-multiple',Title: 'Friends',Text: `This is where you can see your friendslist.`},
                {elmntid: 'groupsbtn',Icon: 'mdi-account-group',Title: 'Groups',Text: `A quick navigation to Groups you belong to.`},
                {elmntid: 'invitesbtn',Icon: 'mdi-human-greeting',Title: 'Invites',Text: `Finally, you can see groups you are invited to that you have not yet responded to.`},
        ],
        ActivatingHelper: false,
        HelperActive: false,
        VisitorToggle: false,
        ActiveVisitor: '',
        NavbarEditProps : ['Social_Navbar_Subtitle','Social_NavBar_IMG','Name','Social_NavBar_Header_IMG','Social_NavBar_Gradient'],
        EditingNavbarBG: false,
        EditingNavbar: false,
      TicketLog: false,
      NavRoutes: [
        {Name: 'Suite Template Social Network',Topic: 'Suite Template Social Network',Type: 'Single',Single: 'Page'},
        {Name: 'MarketplaceTemplate',Topic: 'Marketplace Site Social Pages',Type: 'Single',Single: 'Page'},
        // {Name: 'MarketplaceTemplateSiteArticles',Topic: 'Marketplace Site Articles',Type: 'Collection',Moderator: 'Articles',Single: 'Article',
        // HasCreate: true,CreatePath: '/NewArticle',WIPandStats: true},
        // {Name: 'MarketplaceTemplateSiteArticleSingle',Topic: 'Marketplace Site Articles',Type: 'Single',Moderator: 'Articles',Single: 'Article',
        // HasPublishStatus: true,HasEdit: true,EditMethod: 'EditItem',HasShareList: true,ShareMethod: 'ShareBlog',ShareNotice: true,
        // WIPandStats: true,HasList: true,DropdownList: true,CreatePath: '/NewArticle',ListPath: '/Articles'},
        {Name: 'SiteArticles',Topic: 'Site Articles',Type: 'Collection',Moderator: 'Articles',Single: 'Article',
        HasCreate: true,CreatePath: '/NewArticle',WIPandStats: true},
        {Name: 'SiteArticleSingle',Topic: 'Site Articles',Type: 'Single',Moderator: 'Articles',Single: 'Article',
        HasPublishStatus: true,HasEdit: true,EditMethod: 'EditItem',HasShareList: true,ShareMethod: 'ShareBlog',ShareNotice: true,
        WIPandStats: true,HasList: true,DropdownList: true,CreatePath: '/NewArticle',ListPath: '/Articles'},
        {Name: 'SiteArticleNew',Topic: 'Site Articles',Type: 'New',Moderator: 'Articles',Single: 'Article',HasList: true, ListPath: '/Articles'},
        {Name: 'SiteBlogs',Topic: 'Site Blogs',Type: 'Collection',Moderator: 'Blogs',Single: 'Blog',
        HasCreate: true,CreatePath: '/NewBlog',WIPandStats: true},
        {Name: 'SiteBlogSingle',Topic: 'Site Blogs',Type: 'Single',Moderator: 'Blogs',Single: 'Blog',
        HasPublishStatus: true,HasEdit: true,EditMethod: 'EditItem',HasShareList: true,ShareMethod: 'ShareBlog',ShareNotice: true,
        WIPandStats: true,HasList: true,DropdownList: true,CreatePath: '/NewBlog',ListPath: '/Blogs'},
        {Name: 'SiteBlogNew',Topic: 'Site Blogs',Type: 'New',Moderator: 'Blogs',Single: 'Blog',HasList: true, ListPath: '/Blogs'},
        {Name: 'Events',Topic: 'Site Events',Type: 'All',Moderator: 'Events',Single: 'Event',HasCreate: true,CreateMethod: 'ActivateNewEvent'},
        {Name: 'SiteForums',Topic: 'Site Forums',Type: 'Create Collection',Moderator: 'Forums',Single: 'Discussion',HasCreate: true,CreateMethod: 'ActivateNewDiscussionDialog'},
        {Name: 'SiteForumSingle',Topic: 'Site Forums',Type: 'Single',Moderator: 'Forums',Single: 'Discussion',HasShareList: true,ShareNotice: true,ShareMethod: 'ShareBlog',HasList: true, ListPath: '/Forums'},
        {Name: 'SiteMeetups',Topic: 'Site Meetups',Type: 'Collection',Moderator: 'Meetups',Single: 'Meetup',
        HasCreate: true,CreatePath: '/NewArticle',WIPandStats: true},
        {Name: 'SiteMeetupSingle',Topic: 'Site Meetups',Type: 'Single',Moderator: 'Meetups',Single: 'Meetup',
        HasPublishStatus: true,HasEdit: true,EditMethod: 'EditItem',HasShareList: true,ShareMethod: 'ShareBlog',ShareNotice: true,
        WIPandStats: true,HasList: true,DropdownList: true,CreatePath: '/NewMeetup',ListPath: '/Meetups'},
        {Name: 'SiteMeetupNew',Topic: 'Site Meetups',Type: 'New',Moderator: 'Meetups',Single: 'Meetup',HasList: true, ListPath: '/Meetups'}, 
        {Name: 'SiteMemberSingle',Topic: 'Site Member',Type: 'Single',Moderator: 'Members',Single: 'Member',HasList: true, ListPath: '/SiteMembers'},
        {Name: 'SiteMembers',Topic: 'Site Members',Type: 'Collection',Moderator: 'Members',Single: 'Member'},    
        {Name: 'noticesingle',Topic: 'Site Notices',Type: 'Single',Moderator: 'Notices',Single: 'Notice',HasEdit: true,EditMethod: 'OpenEditor',
        WIPandStats: true, SingleStatsMethod: 'ToggleChartsView',SingleStats: true,SingleStatsTitle: 'View Replies',SingleStatsIcon: 'mdi-reply'},
        {Name: 'noticenew',Topic: 'Site Notices',Type: 'Write',Moderator: 'Notices',Single: 'Notice',HasPublishStatus: true},
        {Name: 'SitePolls',Topic: 'Site Polls',Type: 'Collection',Moderator: 'Polls',Single: 'Poll'},
        {Name: 'SitePollSingle',Topic: 'Site Polls',Type: 'Single',Moderator: 'Polls',Single: 'Poll'},
        {Name: 'SitePollNew',Topic: 'Site Polls',Type: 'New',Moderator: 'Polls',Single: 'Poll'},
        {Name: 'SocialSitePosts',Topic: 'Site Posts',Type: 'Collection',Moderator: 'Site Posts',Single: 'Post'},
        {Name: 'SitePostSingle',Topic: 'Site Posts',Type: 'Single',Moderator: 'Site Posts',Single: 'Post'},
        {Name: 'Groups',Topic: 'Site Groups',Type: 'Create Collection',Moderator: 'Groups',Single: 'Group'},
        {Name: 'GroupSingle',Topic: 'Site Groups',Type: 'Single',Moderator: 'Groups',Single: 'Group',HasList: true, ListPath: '/Groups',HasEdit: true,EditMethod: 'EditGroup',HasShareList: true,ShareNotice: true,ShareMethod: 'ShareBlog',},
        {Name: 'GroupEdit',Topic: 'Site Groups',Type: 'Single',Moderator: 'Groups',Single: 'Group',HasList: true, ListPath: '/Groups'},
        {Name: 'GroupFeaturedMemberView',Topic: 'Featured Group Members',Type: 'Single',Moderator: 'Groups',Single: 'Group Featured Member'},
        {Name: 'FeaturedGroupMemberEdit',Topic: 'Featured Group Members',Type: 'EditItem',Moderator: 'Groups',Single: 'Group Featured Member'},
        {Name: 'FeaturedMemberViewer',Topic: 'Featured Members',Type: 'Single',Single: 'Featured Member'},
        {Name: 'FeaturedMemberEditor',Topic: 'Featured Members',Type: 'EditItem',Single: 'Featured Member'},
        {Name: 'GroupArticle',Topic: 'Group Articles',Type: 'Single',Moderator: 'Groups',Single: 'Group Article'},
        {Name: 'GroupNewArticle',Topic: 'Group New Article',Type: 'Single',Moderator: 'Groups',Single: 'Group Article'},
        {Name: 'GroupBlog',Topic: 'Group Blogs',Type: 'Single',Moderator: 'Groups',Single: 'Group Blog'},
        {Name: 'GroupNewBlog',Topic: 'Group New Blogs',Type: 'Single',Moderator: 'Groups',Single: 'Group Blog'},
        {Name: 'GroupForum',Topic: 'Group Forums',Type: 'Single',Moderator: 'Groups',Single: 'Group Forum'},
        {Name: 'GroupMeetup',Topic: 'Group Meetups',Type: 'Single',Moderator: 'Groups',Single: 'Group Meetup'},
        {Name: 'GroupPoll',Topic: 'Group Polls',Type: 'Single',Moderator: 'Groups',Single: 'Group Poll'}, 
        {Name: 'GroupNewPoll',Topic: 'Group New Poll',Type: 'Single',Moderator: 'Groups',Single: 'Group Poll'},  
        {Name: 'SocialPage',Topic: 'Site Social Pages',Type: 'Single',Single: 'Page'},
        {Name: 'PageEditor',Topic: 'Site Social Pages',Type: 'Single',Single: 'Page'},
        {Name: 'DocumentationLibrary',Topic: 'Documentation',Type: 'Single',Single: 'Documentation'}, 
        {Name: 'DocumentationLibraryEditor',Topic: 'Documentation',Type: 'Single',Single: 'Documentation'},        
        {Name: 'GroupPage',Topic: 'Group Pages',Type: 'Single',Single: 'Group Page'},
        {Name: 'Classifieds',Topic: 'Site Classifieds',Type: 'Collection',Moderator: 'Classifieds',Single: 'Poll'}, 
        {Name: 'ClassifiedSingle',Topic: 'Site Classifieds',Type: 'Single',Moderator: 'Classifieds',Single: 'Poll',HasList: true, ListPath: '/Classifieds'},  
        {Name: 'MyProfile',Topic: 'MyProfile',Type: 'System',ActionList: true},
        {Name: 'BusinessMember',Topic: 'BusinessMember',Type: 'System',ActionList: true},
      ],
      SocialComponents: [            
          { title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
          { title: 'Events',Pluginid: 'Site_Events', icon: 'mdi-calendar-star',ComponentName: 'MyEvents',sitecollectionname: 'siteevents',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupevents',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
          { title: 'Blogs',Pluginid: 'Site_Blogs', icon: 'mdi-typewriter',ComponentName: 'MyBlogs',sitecollectionname: 'siteblogs',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupblogs',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
          { title: 'Articles',Pluginid: 'Site_Articles', icon: 'mdi-email-newsletter',ComponentName: 'MyArticles',sitecollectionname: 'sitearticles',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouparticles',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
          { title: 'Classifieds',Pluginid: 'Classifieds', icon: 'mdi-newspaper',ComponentName: 'MyClassifieds',sitecollectionname: 'classifieds',sitecolfield: 'Created_Byid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupclassifieds',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
          { title: 'Forums',Pluginid: 'Site_Forums', icon: 'mdi-forum',ComponentName: 'MyForumThreads',sitecollectionname: 'siteforumdiscussions',sitecolfield: 'Creatorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupforumdiscussions',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
          { title: 'Polls',Pluginid: 'Site_Polls', icon: 'mdi-vote',ComponentName: 'MyPolls',sitecollectionname: 'sitepolls',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouppolls',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
          { title: 'Notices',Pluginid: 'Digital_NoticeBoard', icon: 'mdi-bulletin-board',ComponentName: 'MyNoticeBoardPosts',sitecollectionname: 'notices',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupnotices',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
          ],
      rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
      darkcolor: 'blue-grey',
      lightcolor: 'blue-grey',
       UserRecord: '',
      RecordName: '',
      SocialQuickNavTop: '225px',
      socialquicknav: false,
      MessageDialog: false,
      MessagetoUser: '',
      groups: [], 
      invitedgroups: [],
      UsersArray: [],
      
      drawer:false,
      SearchView: false,
      search: '',
      pubitems: [
        {
          DisplayName:'Glossary',
          Path:'/Glossary',
        },
        {
          DisplayName:'Social',
          Path:'/Social',
          SubItems: [
            {
            DisplayName: 'Members',
            Path: '/SiteMembers',
            Boolean: false,
            },
            
            {
            DisplayName: 'Articles',
            Path: '/Articles',
            Boolean: true,
            },
            {
            DisplayName: 'Blogs',
            Path: '/Blogs',
            Boolean: true,
            },
            {
            DisplayName: 'Classifieds',
            Path: '/Classifieds',
            Boolean: true,
            },
            {
            DisplayName: 'Events',
            Path: '/Events',
            Boolean: true,
            },
            {
            DisplayName: 'Forums',
            Path: '/Forums',
            Boolean: true,
            },
            {
            DisplayName: 'Groups',
            Path: '/Groups',
            Boolean: true,
            },
             {
            DisplayName: 'Meetups',
            Path: '/Meetups',
            Boolean: true,
            },
            {
            DisplayName: 'Polls',
            Path: '/Polls',
            Boolean: true,
            },
            
          ]
        },
        
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  computed: {
    SingleRoutePrefix(){
      return this.ActiveSuiteTemplate ? '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network' :
      this.ActiveMarketTemplate ? '/MarketplaceTemplate/'+this.$route.params.id
      : ''
    },
    ActiveVisitisUser(){
      return this.ActiveVisitor && this.userLoggedIn
        && this.ActiveVisitor.id === this.userLoggedIn.id
    },
    StoreActiveVisitor(){
      return this.$store.state.ActiveVisitor
    } ,   
      ComputedNotifications(){
        return !this.ActiveVisitisUser ? this.VisitorNotifications : this.UnreadNotifications
      } ,   
      ComputedFriendsList(){
        return !this.ActiveVisitisUser ? this.ActiveVisitorFriendsList : 
        this.FriendsList
      },
      ComputedGroups(){
        return !this.ActiveVisitisUser ? this.VisitorGroups : this.groups
      },
      ComputedGroupInvites(){
        return !this.ActiveVisitisUser ? this.ActiveVisitorGroupInvites : this.invitedgroups 
      },
      HelperHalted(){
          return this.StoppedHelpers.includes(this.ActiveHelperID)
      },
      StoppedHelpers(){
          return this.$store.state.StoppedHelpers
      },
      ActiveVisitorFriendsList(){
        return this.TemplateUsers
      },
      ActiveVisitorGroupInvites(){
        return []
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveMarketTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
    ActiveSuiteTemplate(){
        return this.$store.state.ActiveSuiteTemplate
    },
    ConfigPath(){
    return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) : 
    this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : 
    db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
    },
    UserCanEditBar(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Ownerid === this.userLoggedIn.id || this.userIsAdmin
    },
    ReceivedMessages(){
        return this.$store.state.ReceivedMessages
    },
    SentMessages(){
        return this.$store.state.SentMessages
    },
    ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
    },
    CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
    },
    ClientDB(){
      return db
    },
    SiteDialog(){
            return this.$store.state.SiteDialog
        },
    IntranavStyling(){
      if(!this.SiteDialog){
        return ''
      }
      else{
        return ''
      }      
    },
    MyCirclesNotifications(){
      return this.invitedgroups.length
    },
    CurrentItemModerator(){
      return this.$store.state.CurrentItemModerator
    },
    UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
  WebsiteTemplate(){
          return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Website'
        },
        SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
        SocialNetworkTemplate(){
      return this.SuiteSocialNetwork ? this.SuiteSocialNetwork :
      this.ActiveMarketTemplate && this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Social Network'
    },
        FeaturedMemberTemplate(){
          return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
          return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Category && this.ActiveMarketTemplate.Category.Name === 'Group Site'
        },
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
          },
        ActiveTemplateSiteType(){
          if(this.WebsiteTemplate){
            return 'Website Template'
          }
          else if(this.SocialNetworkTemplate){
            return 'Social Network Template'
          }
          else if(this.ActiveMarketTemplate){
            return 'Featured Member'
          }
          else if(this.GroupSiteTemplate){
            return 'Group Site'
          }
          else{
            return 'System'
          }
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        ActiveTemplateSite(){
          if(this.ActiveTemplateSiteType === 'System'){
            return ''
          }
          else if(this.FeaturedMemberView){
            return this.ActiveFeaturedMember
          }
          //else if(this.ActiveMarketTemplate && this.PublicNavbarEditing || this.ActiveMarketTemplate &&  this.ActiveMarketTemplate.Ownerid !== this.userLoggedIn.id){ somewhere we try route owner back to edit view
          //by keeping Activesite switched off...but I don't think it applies here it should be applicable in "app"
          else if(this.ActiveMarketTemplate && this.PublicNavbarEditing || this.ActiveMarketTemplate){
            return this.ActiveMarketTemplate.TemplateProps
          }
        },
    LogoBarStyle(){
      if(this.System.Social_NavBar_Header_IMG && this.System.Social_NavBar_Gradient){
          return { backgroundImage: this.System.Social_NavBar_Gradient.split('background: ')[1].split(';')[0]+`,url('`+this.SocialNavbarHeaderIMG+`')`,backgroundSize: `cover`,position: 'fixed',top: this.AppStylingView ? '285px' : '50px'}
      }
      else if(this.System.Social_NavBar_Header_IMG){
        return { backgroundImage: `url('`+this.SocialNavbarHeaderIMG+`')`,backgroundSize: `cover`,position: 'fixed',top: this.AppStylingView ? '285px' : '50px'}
      }
      else if(this.System.Social_NavBar_Gradient){
        return { backgroundImage: this.System.Social_NavBar_Gradient.split('background: ')[1].split(';')[0]+',url(' + this.SocialNavbarHeaderIMG + ')',backgroundSize: `cover`,position: 'fixed',top: this.AppStylingView ? '285px' : '50px'}
      }
      else{
        return { backgroundImage: 'url(' + this.SocialNavbarHeaderIMG + ')',backgroundSize: 'cover'  ,position: 'fixed',top: this.AppStylingView ? '285px' : '50px'}
      }
    },
    Social_NavBar_IMG(){
      let graphic = this.GraphicalElements.find(obj => obj.Prop === 'Social_NavBar_IMG')
      return graphic.path
    },
    SocialNavbarHeaderIMG(){
        return this.System.Social_NavBar_Header_IMG ? this.System.Social_NavBar_Header_IMG : require('@/assets/RASectionBG.jpeg')
    },
    Social_NavBar_Header_IMG(){
      let graphic = this.GraphicalElements.find(obj => obj.Prop === 'Social_NavBar_Header_IMG')
      return graphic.path
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    SocialMenuFontColor(){
      if(this.AppisDarkMode){
        return 'secondary'
      }
      else{
        return 'pop'
      }
    },
    
    CurrentisFavorite(){
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          return true
        }
        else {
          return false
        }
      }
      else{
        return false
      }
    },
    BottomMenu(){
        return !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && this.$route.name !== 'MyDashboard' && !this.$route.meta || 
        !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && this.$route.name !== 'MyDashboard' && this.$route.meta && !this.$route.meta.DataSingleBuilder
      },
      TopMenu(){
        return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$route.name === 'MyDashboard' || this.$route.meta && this.$route.meta.DataSingleBuilder
      },
    BottomNavClass(){
      return 'socialappnavbar'
    },
    BottomNavThemeColor(){
      return 'socialmenufont'
    },
    PrimaryNavstyle(){
      //return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'position: fixed;bottom: 50px;z-index: 5;' : 'position: fixed;top: 165px;z-index: 5;'
      return 'position: fixed;top: 160px;z-index: 6;'
    },
    SecondaryNavstyle(){
      //return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'position: fixed;bottom: 50px;z-index: 5;' : 'position: fixed;top: 165px;z-index: 5;'
      return 'position: fixed;bottom: 50px;z-index: 6;'
    },
    MarketplaceTemplates(){
    return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
    },
    OtherMarketplaceTemplates(){
    return this.$store.state.OtherMarketplaceTemplates
    },
    MyMarketplaceTemplates(){
    return this.$store.state.MyMarketplaceTemplates
    },
    ComputedTemplateChildren(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Children ? 
        this.MarketplaceTemplates.filter(temp => {
            return this.ActiveMarketTemplate.Children.includes(temp.id)
        }) :
        []
    },
    TemplateNavlist(){
      return this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Navlist ? 
      this.ActiveMarketTemplate.TemplateProps.Navlist : []
    },
    SocialNavPages(){
      if(this.ActiveMarketTemplate){
        return this.TemplateNavlist.map(item => {
            let newobj = {
              title: item.title,
              //really because yes System seems to auto assign children but it's not right Children: []
            }
            if(item.Children){
              newobj.Children = item.Children.map(child => {
                let newchild = {
                  IsRoute: true,
                  Path: child.Path,
                  title: child.title
                }
                if(this.ActiveMarketTemplate){
                  newchild.id = child.id    
                  newchild.Method = 'AssignSocialItemInteractMethod'
                  newchild.Prop = 'RoutetoTemplatePage'
                  newchild.SecondProp = {id: newchild.id,title: newchild.title}
                }
                //console.log('newchild.Path',newchild.Path)
                return newchild
              })
            }
            
            if(this.ActiveMarketTemplate){   
                newobj.id = item.id           
                newobj.Method = 'RoutetoTemplatePage'
                newobj.Prop = {id: newobj.id,title: newobj.title}
                //newobj.SecondProp = {id: newobj.id,title: newobj.title}
                //truly markettempsing not open to receive this...we don't wnat routes F that.
                //So we got option..push to marketplace template....then two things need to follow
                //1. psuh to marketplacetemplatesingl with props....
                //2. realize props there which then on load activates the computed children. route to page
            }
            return newobj
          })
      }
      else if(this.System.SocialNavbarItems){
          return this.System.SocialNavbarItems.map(item => {
            let newobj = {
              title: item.title,
            }
            if(item.Children){
              newobj.Children = item.Children.map(child => {
                let newchild = {
                  IsRoute: true,
                  Path: '/'+child.Path,
                  title: child.title
                }
                return newchild
              })
            }
            else{
              newobj.Path = '/'+item.Path
              newobj.IsRoute = true
            }
            return newobj
          })
        }
        else{
          return []
        }
    },
    DBNavbarItems(){
      let array = []
      if(this.System.DBNavbarItems){
        array = JSON.parse(JSON.stringify(this.System.DBNavbarItems))
            return array.map(item => {
              item.Children = item.Children.filter(child => {
                //console.log(child,vm.$store.state[child.QueryName])
                return this.$store.state[child.QueryName]
              }).map(child => {
                let entity = this.SystemEntities.find(obj => obj.id === child.title.split(' ').join('_'))
                //console.log('entity',entity,child.title.split(' ').join('_'),this.SystemEntities)
                if(entity){
                  child.icon = entity.Table_Icon     
                  child.EntityRecord = entity    
                  child.Path = '/'+entity.id.split('_').join('')         
                }
                
                return child
              })
              return item
            })
          }
       return array
    },
    
    UserFavorites(){
      return this.userLoggedIn.FavoriteLinks ? this.userLoggedIn.FavoriteLinks : []
    },
    NavUserFavorites(){
      return this.UserFavorites.map(fav => {
        let favobj = {
          title: fav.DisplayName,
          icon: '',
          Path: fav.Path,
          IsRoute: true,
          // RouteBlank: true
        }
        return favobj
      })
    },
    
    PrimaryNavigationActive(){
      return this.userLoggedIn && this.$route.name != 'GroupPage'
    },
    SecondaryNavigationActive(){
      return this.userLoggedIn && this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupEdit' && this.$route.name != 'GroupPage' && !this.AppBuilderView
    },
    AppBuilderView(){
      return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView
    },
    BuilderView(){
    return this.$store.state.BuilderView
    },
    WebsiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Website Builder'
    },
    DBBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Database Builder'
    },
    NetworkBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
    },
    SecondaryNavigationItems(){
      return this.MainSocialSecondaryNavigationItems
    },
    PublishStatusOptions() {
    return [
            {
              Name: 'Draft',
              Class: 'green',
              Warning: 'Unpublish the item?'
            },
            {
              Name: 'Published (Internal)',
              Class: 'warning',
              Warning: 'Make the item avialable for viewing by all Users?'
            },
            {
              Name: 'Published (Public)',
              Class: 'error',
              Warning: 'Make the item available for viewing by the Public?'
            }
            ]
    },
    ModeratorActions(){
          let items = [
            {
              title: 'Publish Status',
              ItemPass: this.ActiveRoute.HasPublishStatus,
              icon: 'mdi-publish',
              Children: this.PublishStatusOptions.map(opt => {
                let optobj ={
                  title: opt.Name,
                  Method: 'AssignSocialItemInteractMethod',
                  ItemPass: true,
                  Prop: 'CheckStatus',
                  SecondProp: opt.Name
                }
                if(optobj.title === 'Draft'){
                  optobj.icon = 'mdi-pencil'                  
                }
                else if(optobj.title === 'Published (Internal)'){
                  optobj.icon = 'mdi-account-group-outline'
                }
                else if(optobj.title === 'Published (Public)'){
                  optobj.icon = 'mdi-globe-model'
                }
                return optobj
              }),
              Method: 'AssignSocialItemInteractMethod',
              Prop: 'ActivatePublishMenu',      
              //PublishStatusOptions UpdateStatus      
              },
              {
              title: 'Edit '+this.ActiveRoute.Single,
              ItemPass: this.ActiveRoute.HasEdit && this.ActiveRoute.EditMethod,
              icon: 'mdi-pencil',  
              Method: 'AssignSocialItemInteractMethod',
              Prop: this.ActiveRoute.EditMethod,            
              },
          ]
          return items
          
        },
    ActiveRoute(){
      if(this.$route.meta && this.$route.meta.RouteEventID === 1027){
        return this.NavRoutes.find(obj => obj.Name === 'SocialPage')
      }
      else if(this.$route.meta && this.$route.meta.RouteEventID === 1015){
        return this.NavRoutes.find(obj => obj.Name === 'GroupPage')
      }
      else if(this.SocialNetworkTemplate){
        let alteredroutename = this.ActiveSuiteTemplate ? this.$route.name.split('SuiteTemplate').join('') : this.$route.name.split('MarketplaceTemplate').join('')
        console.log('alteredroutename',this.$route.name,'to'+alteredroutename)
        return this.NavRoutes.find(obj => obj.Name === this.$route.name) ? this.NavRoutes.find(obj => obj.Name === this.$route.name) : 
        this.ActiveSuiteTemplate ? this.NavRoutes.find(obj => obj.Name === alteredroutename) : this.NavRoutes.find(obj => obj.Name === alteredroutename)
        //Great but does the path not change?
      }
      else{
        //console.log(this.$route.name)
        return this.NavRoutes.find(obj => obj.Name === this.$route.name)
      }
      
    },
    UserActions(){
      //console.log('this.ActiveRoute ActiveRoute',this.ActiveRoute)
          let items = [
            {
              title: 'Edit',
              icon: 'mdi-pencil',
              ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name !== 'SocialPage' && this.CurrentItemModerator,
              Children: this.ModeratorActions
            },
            {
              title: 'Edit',
              icon: 'mdi-pencil',
              ItemPass: this.ActiveRoute.Type === 'Single' && this.ActiveRoute.Name === 'SocialPage' && this.CurrentItemModerator,
              IsRoute: true,                    
              Path: '/PageEditor'+this.$route.path.split('-').join('_'),
              Builder: 'Social Network Builder'
            },
            {
              title: 'Create '+this.ActiveRoute.Single,
              icon: 'mdi-plus',
              ItemPass: this.ActiveRoute.HasCreate && this.UserisItemModerator(this.ActiveRoute.Moderator) && this.ActiveRoute.CreatePath,
              IsRoute: true,
              Path: this.ActiveRoute.CreatePath
              },
              {
                title: 'List',
                icon: 'mdi-view-list',
                ItemPass: this.ActiveRoute.HasList && this.ActiveRoute.DropdownList,
                Children: [
                 
                  {
                    title: 'All '+this.ActiveRoute.Topic,
                    icon: 'mdi-view-list',
                    ItemPass: this.ActiveRoute.ListPath,
                    IsRoute: true,                    
                    Path: this.ActiveRoute.ListPath
                    },
                    {
                    title: 'Create '+this.ActiveRoute.Single,
                    icon: 'mdi-plus',
                    ItemPass:  this.ActiveRoute.CreatePath && this.UserisItemModerator(this.ActiveRoute.Moderator) && this.ActiveRoute.CreatePath && !this.ActiveRoute.HasCreate,
                    IsRoute: true,
                    Path: this.ActiveRoute.CreatePath
                    },
                ]
              },
              {
                title: 'All '+this.ActiveRoute.Topic,
                icon: 'mdi-view-list',
                ItemPass: this.ActiveRoute.HasList && !this.ActiveRoute.DropdownList && this.ActiveRoute.ListPath,
                IsRoute: true,                    
                Path: this.ActiveRoute.ListPath
              },
              
              {
              title: 'Create '+this.ActiveRoute.Single,
              icon: 'mdi-plus',
              ItemPass: this.ActiveRoute.HasCreate && this.UserisItemModerator(this.ActiveRoute.Moderator) && this.ActiveRoute.CreateMethod,
              Method: 'AssignSocialItemInteractMethod',
              Prop: this.ActiveRoute.CreateMethod
              },
              {
              title: 'Actions',
              icon: 'mdi-clipboard-list',
              ItemPass: this.ActiveRoute.ActionList,
              Children: [
               
                {
                title: 'Invite User',
                icon: 'mdi-share-circle',
                Method: 'AcceptInviteUserDialog',
                Prop: '',
                ItemPass: this.userLoggedIn.CanInviteUsers || this.userIsAdmin,
                },
                {
                title: 'Create Activity',
                icon: 'mdi-clipboard-list',
                Method: 'ToggleNewTaskDialog',
                Prop: '',
                ItemPass: true,
                },
                {
                title: 'Timesheet',
                icon: 'mdi-ticket-confirmation',
                Method: 'ToggleStopWatchDialog',
                Prop: '',
                ItemPass: true,
                },
                                
              ]
              },
              {
              title: 'Dashboards',
              icon: 'mdi-monitor-dashboard',
              ItemPass: true,
              notifications: this.MyCirclesNotifications,
              Children: [
                 {
                    ItemPass: this.ActiveRoute.WIPandStats && this.UserisItemModerator(this.ActiveRoute.Moderator),
                    title: this.ActiveRoute.Topic+' Dashboard',
                    icon: 'mdi-monitor-dashboard',
                    Method: 'AssignPushedNavItem',
                    Prop: this.SocialComponents.find(obj => obj.title === this.ActiveRoute.Moderator),                    
                    },
                    {
                    title: this.ActiveRoute.SingleStatsTitle,
                    icon: this.ActiveRoute.SingleStatsIcon,
                    ItemPass: this.ActiveRoute.SingleStats && this.ActiveRoute.SingleStatsMethod &&this.ActiveRoute.Type === 'Single' && this.CurrentItemModerator ||
                    this.ActiveRoute.SingleStats && this.ActiveRoute.SingleStatsMethod &&this.ActiveRoute.Type === 'Single' && this.UserisItemModerator(this.ActiveRoute.Moderator),
                    Method: 'AssignSocialItemInteractMethod',
                    Prop: this.ActiveRoute.SingleStatsMethod,                     
                  },
                // {
                // title: 'My Circles',
                // icon: 'mdi-account-circle',
                // ItemPass: true,
                // Method: 'SocialQuickNavActivate',
                // Prop: true,
                // notifications: this.MyCirclesNotifications,
                // },
              ]
              },
              {
              title: 'Social',
              icon: 'mdi-home-group',
              ItemPass: true,
              Children: [
                {title: 'Glossary',IsRoute: true, Path: '/Glossary',icon: 'mdi-file-word',ItemPass: this.ReferencedPluginDataBase.Glossary},
                {title: 'Members',IsRoute: true,Path: '/SiteMembers',Boolean: false,icon: 'mdi-tag-faces',ItemPass: true},
                {title: 'Articles',IsRoute: true,Path: '/Articles',Boolean: true,icon: 'mdi-email-newsletter',ItemPass: this.ReferencedPluginDataBase.Site_Articles},
                {title: 'Blogs',IsRoute: true,Path: '/Blogs',icon: 'mdi-typewriter',Boolean: true,ItemPass: this.ReferencedPluginDataBase.Site_Blogs},
                {title: 'Classifieds',IsRoute: true,Path: '/Classifieds',icon: 'mdi-newspaper',Boolean: true,ItemPass: this.ReferencedPluginDataBase.Classifieds},
                {title: 'Events',IsRoute: true,Path: '/Events',icon: 'mdi-calendar-star',Boolean: true,ItemPass: this.ReferencedPluginDataBase.Site_Events},
                {title: 'Forums',IsRoute: true,Path: '/Forums',icon: 'mdi-forum',Boolean: true,ItemPass: this.ReferencedPluginDataBase.Site_Forums},
                {title: 'Groups',IsRoute: true,Path: '/Groups',Boolean: true,icon: 'mdi-account-group',ItemPass: this.ReferencedPluginDataBase.Site_Groups},
                {title: 'Meetups',IsRoute: true,Path: '/Meetups',Boolean: true,icon: 'mdi-human-greeting-proximity',ItemPass: this.ReferencedPluginDataBase.Site_Meetups}, 
                {title: 'Polls',IsRoute: true,Path: '/Polls',Boolean: true,icon: 'mdi-vote',ItemPass: this.ReferencedPluginDataBase.Site_Polls},
                
              ]
              },
             {
              title: 'Share',
              icon: 'mdi-share-circle',
              ItemPass: this.ActiveRoute.HasShareList && this.ActiveRoute.ShareMethod || this.ActiveRoute.HasShareList && this.ActiveRoute.ShareNotice,
              Children: [
                {
                  title: 'Walls',
                  icon: 'mdi-post-outline',
                  ItemPass: true,
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: this.ActiveRoute.ShareMethod
                },
                {
                  title: 'Create Notice',
                  icon: 'mdi-clipboard-alert',
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'AddSocialItemtoNotice',
                  ItemPass: this.ActiveRoute.ShareNotice && this.UserisItemModerator('Notices')
                }
              ]
            },
            

          ]
          return items
          .map(item => {
            if(item.Children){
              item.Children = item.Children.filter(child => {
                return child.ItemPass
              })
              //console.log(item,this.SocialNetworkTemplate)
              if(this.SocialNetworkTemplate){
                if(item.title === 'Social' || item.title === 'List'){
                  item.Children = item.Children.filter(child => {
                      child.Path = this.SingleRoutePrefix+child.Path
                      return child
                  })
                }
                if(item.title === 'Dashboards'){
                  //I honestly feel it should become "AboutSocialDashboardsdialog" and sure add a sample dashboard. But that's it
                  //so Method and Prop manipulation
                }
              }  
            }
            if(this.SocialNetworkTemplate){
              if(item.title === 'Create '+this.ActiveRoute.Single){
                  //here we work with ActiveRoute
                  if(this.ActiveRoute.CreateMethod){
                     //confirmation - this is "socialinterc" received by relevant child comp no need to do anything
                  }
                  else if(this.ActiveRoute.CreatePath){
                    item.Path = this.SingleRoutePrefix+this.ActiveRoute.CreatePath
                  }
                  
                }  
              else if(item.title === 'Create '+this.ActiveRoute.Single){
                //here we work with ActiveRoute
                if(this.ActiveRoute.CreateMethod){
                    //confirmation - this is "socialinterc" received by relevant child comp no need to do anything
                  }
                  else if(this.ActiveRoute.CreatePath){
                    item.Path = this.SingleRoutePrefix+this.ActiveRoute.CreatePath
                  }
                
              }
              else if(item.title === 'All '+this.ActiveRoute.Topic){
                    item.Path = this.SingleRoutePrefix+this.ActiveRoute.ListPath
              }
            }
            return item
          })
          .filter(item => {
            return item.ItemPass
          })
        },
    ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
        ReferencedPluginDataBase(){
           // console.log('this.ActiveMarketTemplate',this.ActiveMarketTemplate)
            return this.ActiveMarketTemplate && this.ActiveMarketTemplate.PluginDataBase ? this.ActiveMarketTemplate.PluginDataBase : 
            this.ActiveMarketTemplate && this.ActiveMarketTemplate.PluginDataBase ? {} : this.PluginDataBase
        },
    PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
    MainSocialSecondaryNavigationItems(){
      //<v-btn outlined color="accent"  :to="$route.name !== 'GroupSingle' && $route.name !== 'GroupPage' ? '/NewArticle' : '/Group/NewArticle/'+$route.params.id" target="_blank" v-if="UserModerator || CanCreate"><v-icon>mdi-plus</v-icon>New Article</v-btn>
      let items = this.UserActions
      return items
      .filter(item => {
        if(item.Children){
          return item.Children.length > 0
        }
        else{
          return item
        }
      })
    },
    PrimaryNavigationItems(){
      return this.MainSocialPrimaryNavigationItems
    },
    SocialModuleRoute(){
      //actually should be +'/'+this.ActiveMarketTemplate.TemplateProps.Social_Landing_Page.split(' ').join('-') but surprise surprise we have no pageroutes...
      // the "/" makes it "reroute as opposed to push"
      return this.SocialNetworkTemplate ? 
             this.ActiveSuiteTemplate ? '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network' : 
             '/MarketplaceTemplate/'+this.ActiveMarketTemplate.id : 
             this.System.Social_Landing_Page ? this.System.Social_Landing_Page.split(' ').join('-') : ''
    },
    MainSocialPrimaryNavigationItems(){
      //need favorites
      let socialpageviewitems = [
         
         {
        title: 'Shortcuts',
        icon: 'mdi-star-face',
        Children: [
          {
          title: 'Add as Favorite',
          icon: 'mdi-star-outline',
          Method: 'AddPageAsFavorite',
          Prop: ''
           },
           {
            title: this.System.Social_Module_Name,
            icon: 'mdi-book-open-page-variant',
            IsRoute: true,
            Path: this.SocialModuleRoute,
            DefaultSocialPage: true,
          },
           {
          title: 'My Favorites',
          icon: 'mdi-star',
          Children: this.NavUserFavorites
          },
          
        ],
        },
        {
        title: 'Pages',Boolean: false,
        icon: 'mdi-book-open-page-variant',
        Children: this.SocialNavPages
        }, 
        {
          title: 'Main',
          icon: 'mdi-menu',
          Children: [
             
            {
          title: 'My Account',
          icon: 'mdi-star-face',
          IsRoute: true,
            Path: '/MyAccount'
           },
           
         
         
          {
        title: 'Records',
        icon: 'mdi-database',
        Children: this.DBNavbarItems
        },
        // {
        //   icon: 'mdi-library',
        //   title: 'Libraries',
        //   Path: '/Libraries',
        //   target: 'self',
        //   Boolean: 'Libraries',
        //   Children: this.SystemPhotoLibraries.map(item => {
        //       // item.Children = item.Children.filter(child => {
        //       //   return vm.$store.state[child.QueryName]
        //       // })
        //       let libraryitem = {
        //         Path: '/'+item.DisplayName.split(' ').join(''),
        //         target: '',
        //         icon: item.Icon,
        //         title: item.DisplayName

        //       }
        //       return libraryitem
        //     })
        //   },
          {
              title: 'Styling',
              icon: 'mdi-palette-advanced',
              Method: 'ToggleAppStylingView',
              Prop: '',
              ItemPass: this.userIsAdmin,
              },
              {
              title: 'Suite Admin',
              icon: 'mdi-cogs',
              IsRoute: true,
              Path: '/Getting-Started',
              ItemPass: this.userIsAdmin,
              },
          ]
          },
      ]
      return  socialpageviewitems.map(item => {
          if(item.title === 'Social'){
            item.Children = item.Children.filter(socialcomp => {
              return this.System['Site_Has_'+socialcomp.title] || !socialcomp.Boolean
            })
          }
          else if(item.title === 'Shortcuts'){
            item.Children = item.Children.map(child => {
              if(child.title === 'Add as Favorite' || child.title === 'Remove Favorite'){
                
                if(this.CurrentisFavorite){
                  child.title = 'Remove Favorite'
                  child.icon = 'mdi-star-off'
                }
                else{
                  child.title = 'Add as Favorite'
                  child.icon = 'mdi-star-outline'
                }
              }
              return child
            })            
          }
          else if(item.title === 'Main'){
             item.Children = item.Children.map(child => {
              if(child.title === 'My Dashboard' && this.UserisGuest){
                child.title = 'My Account'
                child.Path = '/MyAccount'
              }
              else if(child.title === 'Records'){
                child.Children = child.Children.filter(baby => {
                  return baby.Children.length > 0
                })
              }
              return child
            })
            item.Children = item.Children.filter(child => {
              if(child.title === 'Records'){
                return child.Children.length > 0
              }
              else if(child.title === 'My Profile'){
                return !this.UserisGuest
              }
              else if(child.title === 'Styling' || child.title === 'System Config'){
                return this.userIsAdmin
              }
              else{
                return child
              }
            })
          }
          return item
        })
        
    },
    
    UserDBNavList(){
      if(this.userLoggedIn && this.userLoggedIn.DBNavList){
        //console.log('this.userLoggedIn.DBNavList',this.userLoggedIn.DBNavList)
        let list = this.DBNavLists.find(obj => obj.id === this.userLoggedIn.DBNavList.id)
        if(list){
          return list
        }
      }
    },

     
     NotificationsColor(){
      if(this.UnreadNotifications.length > 0){
        return 'pop'
      }
      else{
        return 'secondary'
      }
    },
    MessagesColor(){
      if(this.UnreadMessages.length > 0){
        return 'pop'
      }
      else{
        return 'secondary'
      }
    },
    MessageBoxPadRight(){
        if (this.socialquicknav === true) {
        return 'right: 260px;'
        }
        else{
          return 'right: 0px;'
        }
    },
    GroupConversation(){
      return this.Conversation.find(obj => obj.groupid)
    },
    Conversation(){
      let arr1 = this.SentMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.recipientid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.senderid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'background messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      //console.log(arr1)
      return arr1.concat(arr2)
    },

    ConversationAscending(){
      return this.Conversation.map(msg => {
        //console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    AllUserConversations(){
      let arr1 = this.SentMessages.map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'background messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      //console.log(arr1)
      return arr1.concat(arr2)
    },
    AllUserConversationsAscending(){
      return this.AllUserConversations.map(msg => {
        //console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    AllUserConversationsAscendingGroups(){
      let array = this.AllUserConversationsAscending.filter(conv => {
        return conv.groupname
      })
      .map(groupmsg => {
        if(groupmsg.senderid === this.userLoggedIn.id){
          return {Full_Name: groupmsg.groupname,id: groupmsg.recipientid,Profile_Photo: groupmsg.grouplogo,IsGroup: true,groupid: groupmsg.groupid}
        }
        else{
          return {Full_Name: groupmsg.groupname,id: groupmsg.senderid,Profile_Photo: groupmsg.grouplogo,IsGroup: true,groupid: groupmsg.groupid}
        }        
      })
      let finalarray = []
      array.map(msg => {
        let oncheck = finalarray.find(obj => obj.groupid === msg.groupid)
        if(!oncheck){
          finalarray.push(msg)
        }
      })
      return finalarray
    },
    ConversingUsers(){
      let users = this.UsersArray.filter(user => {
        let recipientcheck = this.AllUserConversationsAscending.find(msg => msg.recipientid == user.id)
        let sendercheck = this.AllUserConversationsAscending.find(msg => msg.senderid == user.id)
        if(sendercheck){
          user.lastMessage = Object.assign({},sendercheck)
          user.SendTime = this.TimestampFormatterSTRING(sendercheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(sendercheck.SendTime)
          return user
        }
        else if(recipientcheck){
          user.lastMessage = Object.assign({},recipientcheck)
          user.SendTime = this.TimestampFormatterSTRING(recipientcheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(recipientcheck.SendTime)
          return user
        }
        
      }).map(user => {
        let sendercheck = this.UnreadMessages.find(msg => msg.senderid == user.id)
       
        if(sendercheck){
          user.Color = ' grey lighten-2'          
        }
        else{
          user.Color = ' white'
        }
        //console.log(user)
        return user
      }).filter(user => {
        return user.id !== this.UserRecord.id
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      let groups = this.AllUserConversationsAscendingGroups.filter(user => {
        let recipientcheck = this.AllUserConversationsAscending.find(msg => msg.recipientid == user.id)
        let sendercheck = this.AllUserConversationsAscending.find(msg => msg.senderid == user.id)
        if(sendercheck){
          user.lastMessage = Object.assign({},sendercheck)
          user.SendTime = this.TimestampFormatterSTRING(sendercheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(sendercheck.SendTime)
          return user
        }
        else if(recipientcheck){
          user.lastMessage = Object.assign({},recipientcheck)
          user.SendTime = this.TimestampFormatterSTRING(recipientcheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(recipientcheck.SendTime)
          return user
        }
        
      }).map(user => {
        let sendercheck = this.UnreadMessages.find(msg => msg.senderid == user.id)
       
        if(sendercheck){
          user.Color = ' grey lighten-2'          
        }
        else{
          user.Color = ' white'
        }
        //console.log(user)
        return user
      }).filter(user => {
        return user.id !== this.UserRecord.id
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      let arr = users.concat(groups)
      
      return arr
    },
    UnreadMessages(){
      return this.AllUserConversationsAscending.filter(msg => {
        return !msg.Read === true && msg.ReadType === 'Received'
      })
    },
     UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
    ReadNotifications(){
      return this.Notifications.filter(notificaton => {
        return notificaton.Read === true
      })
    },
    AppisDarkModeCard(){
        if(this.AppisDarkMode){
          //darken-4
          return 'socialappnavbar stickytop2'
        }
        else{
          //lighten-4
          return 'socialappnavbar stickytop2'
        }
      },
    userLoggedIn () {
      return this.$store.getters.user
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    InvitedGroups(){
      return this.invitedgroups
    },
    StoreInvitedGroups(){
      return this.$store.state.InvitedGroups
    },
    SocialItemInteractMethod(){
    return this.$store.state.SocialItemInteractMethod
    },
    SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
    },
    SelectedImage(){
    return this.$store.state.SelectedGalleryAsset
    },
    },
  
  mounted() {
  window.addEventListener('scroll', this.handleScrollQuickNav)
  },
  beforeDestroy() {
        window.removeEventListener('scroll', this.handleScrollQuickNav);
    },
  created() {
    this.UserRecord = this.userLoggedIn
    if(this.userLoggedIn && !this.StoreActiveVisitor){
      this.ActiveVisitor = this.userLoggedIn
      this.AssignSocialItemInteractMethod('ReceiveActiveVisitor',this.ActiveVisitor)
    }
    else if(this.StoreActiveVisitor){
      this.ActiveVisitor = this.StoreActiveVisitor
    }
    if (this.UserRecord && this.UserRecord.id) {
          //console.log('this.UserRecord',this.UserRecord.id)
            
          
          this.GetUsers()  
          this.GetUserGroups()
          this.GetUserInvitedGroups()
          //this.getGroups() this.$store.commit('SetInvitedGroups',v) 
        }
  },
  watch:{
      ActiveVisitor: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){ 
                    this.VisitorNotifications = []
                    this.VisitorGroups = []
                  if(newvalue && newvalue.id && newvalue.id !== this.userLoggedIn.id){
                    this.GetVisitorItems(this.ConfigPath.collection('samplenotifications').where('Ownerid','==',this.ActiveVisitor.id),'VisitorNotifications')
                    this.GetVisitorItems(this.ConfigPath.collection('samplegroups').where('Ownerid','==',this.ActiveVisitor.id),'VisitorGroups')
                }
            }
          },
      },
    SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['AssignSampleSiteSocialNavbarIMG','AssignSampleSiteSocialNavbarBGIMG']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
        InvitedGroups : {
            handler: function(newvalue, oldvalue) {
                if(this.StoreInvitedGroups !== this.InvitedGroups){
                  this.$store.commit('SetInvitedGroups',this.InvitedGroups)
                }   
            },
            deep: true
        },
        ActiveSearch (v){
          if(!v){
            this.search = ''
          }
        },
        'search' (){
            this.CheckActivateSearch()
        },
      
  },
  methods: {
    CheckActiveVisitor(visitor){
      this.AssignSocialItemInteractMethod('ReceiveActiveVisitor',visitor)
      },
      GetVisitorItems(query,array){
        query.onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              let itemobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              this[array].push(itemobj)
            }  
          })
        })
      },
      StopHelper(HelperID){
          this.StoppedHelpers.push(HelperID)
          this.DeactivateHelper()
      },
      ActivateHelper(HelperID){
            this.ActiveHelperID = HelperID
            if(!this.HelperHalted){
            this.ActivatingHelper = true
            setTimeout(() => {
            this.HelperActive = true
            this.ActivatingHelper = false
                
            }, 1000);
            }
        },
      DeactivateHelper(){
          this.HelperActive = false            
      },
      ActivateSubmenu(submenu){
        this.SubMenuActive = !this.SubMenuActive
        if(this.SubMenuActive){
        this.ActiveSubmenu = submenu
        }
        else{
          this.ActiveSubmenu = ''
        }
      },
      ActivateVisitorToggle(){
        this.VisitorToggle = !this.VisitorToggle
        if(this.VisitorToggle){
          this.ActivateHelper('VisitorToggleBar')
        }
        if(this.VisitorToggle || !this.VisitorToggle){
          //bubblenav
          let ham  = document.getElementById('activevisitorham')
          ham.addEventListener("click", () => {
            let nav = document.getElementById('activevisitorbubble')
            nav.classList.toggle("open")
            nav.classList.toggle("presub")
            let submenus = document.getElementsByClassName('bubblemenuitem')
            for(var x=0;x<submenus.length;x++){
              let submenu = submenus[x]
              //console.log(submenu)
              submenu.addEventListener("click", () => {
            let nav = document.getElementById('activevisitorbubble')
            nav.classList.toggle("submenu")
              })
            }
          })
        }
        else{

        }
      },
      UpdateUserLookupProp(Prop,Value,RecordObj){
        RecordObj[Prop] = Value
        if(this.ActiveMarketTemplate && Prop === 'ActiveVisitor'){
          //here we need to set store state active visitor changing "UserRecord" throuhgout platform
          //this.$store.commit('setActiveVisitor',Value)
          this.AssignSocialItemInteractMethod('ReceiveActiveVisitor',Value)
        }
    },
    RoutetoTemplatePage(page){
      //let routes = this.$router.getRoutes()
      //ActiveSuiteTemplate depends on snb or web build but I guess safe to say from intranetnavbar it is snb
      let routename = this.ActiveSuiteTemplate ? 'Suite Template Website' : 'MarketplaceTemplate'
      // let newroute = routes.find(obj => obj.name === routename)
      // newroute.params = {Page: page}
      // newroute.props = true
      // //newroute.query = {Page: page.title}
      // console.log(newroute)
      // this.$router.push(newroute)
      if(this.$route.name !== routename){
        this.$router.push({name: routename,params: {Page: page}})
      }
      else{
        this.AssignSocialItemInteractMethod('RoutetoTemplatePage',page)
      }
    },
    ActivateGalleryDialog(prp){
      if(!this.ActiveMarketTemplate){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
      }
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
    AssignSampleSiteSocialNavbarBGIMG(){
        //SampleSiteSocialNavbarBGIMG
        this.System.Social_NavBar_Header_IMG = this.SelectedImage.fileurl
        console.log(this.System.Social_NavBar_Header_IMG)
        this.RefreshEditingDialog('EditingNavbarBG')
        this.$store.commit('setIMGProp','')
      },
    AssignSampleSiteSocialNavbarIMG(){
        this.System.Social_NavBar_IMG = this.SelectedImage.fileurl
        this.RefreshEditingDialog('EditingNavbar')
        this.$store.commit('setIMGProp','')
      },
    RefreshEditingDialog(prp){
        this[prp] = false
        setTimeout(() => {
            this[prp] = true
        }, 20);
    },
    ActivateEditingNavbarBG(){
        this.EditingNavbarBG = true
    },
    ActivateEditingNavbar(){
        let props = this.NavbarEditProps
        props.map(prp => {
            if(typeof this.System[prp] !== 'undefined'){

            }
        })
        this.EditingNavbar = true
    },
    CancelEditingNavbar(prp){
        this[prp] = false
    },
    UpdateNavbarChanges(){
        let updobj = {}
        let props = this.NavbarEditProps
        if(this.ActiveMarketTemplate){
            let updobj = Object.assign({},this.System)            
            props.map(prp => {
                if(typeof this.System[prp] !== 'undefined'){
                    updobj[prp] = this.System[prp]
                }
            })
            this.ConfigPath.update({
                TemplateProps: updobj
            }).then(updateddoc => {
                this.CancelEditingNavbar('EditingNavbar')
                this.CancelEditingNavbar('EditingNavbarBG')
            })
        }
        else{
                        
            props.map(prp => {
                if(typeof this.System[prp] !== 'undefined'){
                    updobj[prp] = this.System[prp]
                }
            })
            this.ConfigPath.update(updobj).then(updateddoc => {
                this.CancelEditingNavbar('EditingNavbar')
                this.CancelEditingNavbar('EditingNavbarBG')
                
            })
        }
    },
    UserisItemModerator(item){
      return this.userLoggedIn && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.includes(item) || this.userIsAdmin
    },
    AssignSocialItemInteractMethod(item,SecondProp){
      //console.log('AssignSocialItemInteractMethod',item,SecondProp)
      this.$store.commit('SetSocialItemInteractMethodProp','') 
      this.$store.commit('SetSocialItemInteractMethod','')
      if(SecondProp){
        this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
      else if(item){
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
    },
    SelectBottomNavMenuItem(item){
      if(item.Builder){
        this.$store.commit('setBuilderView',{Name: item.Builder})
      }
      if(!item.Children && !item.Method && !item.RouteBlank){
        //console.log(item.Path)
        this.$router.push(item.Path)
      }
      else if(item.Method){
        if(item.Method === 'IntranetViewToggle' && item.Prop === true){
          this[item.Method](item.Prop,true)
        }
        else if(item.SecondProp){
          this[item.Method](item.Prop,item.SecondProp)
        }
        else{
          this[item.Method](item.Prop)
        }
      }
    },
    SocialQuickNavActivate(){
      this.socialquicknav = !this.socialquicknav
    },
    AssignPushedNavItem(item){
      this.$emit('AssignPushedNavItem',item)
    },
    
    ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
    ClearReadNotifications(){
      this.ReadNotifications.map(not => {
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).delete().then(doc => {
          console.log('successfully deleted notificaiton '+not.id)
        })
      })
    },
    GetUserInvitedGroups(){
      
      db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('Invitees','array-contains',this.UserRecord.id).onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let groupdata = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(groupdata.id).collection('invites').doc(this.UserRecord.id).onSnapshot(snapshot => {
                  let invitedata = snapshot.data()
                  if(invitedata.Status.ID === 1000001){
                    let invite = Object.assign({},groupdata)
                    invite.InviteData = invitedata
                    let oncheck = this.invitedgroups.find(obj => obj.id === invite.id)
                    if(!oncheck){
                     this.invitedgroups.push(invite) 
                    }                    
                  }
                })
                
              }
            })
      })
    },
    GetUserGroups(){
      this.UserRecord.groups.map(group => {
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(group.id).onSnapshot(snapshot => {
          let groupdata = snapshot.data()
          if(groupdata){
            groupdata.id = group.id
            this.groups.push(groupdata)
          }
          
        })
      })
    },
    RemoveFavorite(pathcheck){
      let FavoriteLinks = this.UserRecord.FavoriteLinks
      let FavoriteIndex = FavoriteLinks.indexOf(pathcheck)
      FavoriteLinks.splice(FavoriteIndex,1)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
        FavoriteLinks: FavoriteLinks
      })
    },
    AddPageAsFavorite(){
      let routeitem = this.$router.options.routes.find(obj => obj.name === 'myprofile')
      console.log("routeitem",routeitem)
      console.log(this.$route.meta)
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          confirm('This item is already a favorite. Would you like to remove it from Favorites?') && this.RemoveFavorite(pathcheck)
        }
        else{
        let display = ''
        
        if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        
        }
      }
      else{
        let display = ''
      if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }      
      }
      
      // this.UserRecord.FavoriteLinks.find
    },
    StoreFavorite(display,path){
      let FavoriteLinks = []
        if(this.UserRecord.FavoriteLinks){
          FavoriteLinks = this.UserRecord.FavoriteLinks
        } 
        FavoriteLinks.push({
          DisplayName: display,
          Path: path
        })
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
          FavoriteLinks: FavoriteLinks
        })
    },
    ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
    
    CreateMessage(userto,userfrom){
      
        let inputelmnt = document.getElementById('messageinputchip')
        let NewMessage = {
          recipientid: userto.id,
          senderid: userfrom.id,
          message: inputelmnt.innerHTML,
          Created_On: new Date(),
          Read: false
        }
        let firstmessage = this.ConversationAscending[0]
        //user can only review once, unless they remove old review and new review replace old one
        if(firstmessage){
          NewMessage.Replytoid = firstmessage.id
          if(firstmessage.IsProductRating){
            NewMessage.IsProductRating = firstmessage.IsProductRating
            NewMessage.topicid = firstmessage.topicid
            NewMessage.relatedid = firstmessage.relatedid
            NewMessage.relatedtype = firstmessage.relatedtype
            NewMessage.producttitle = firstmessage.producttitle
          }
          // if(firstmessage.IsGroupRating){
          //   NewMessage.IsGroupRating = firstmessage.IsGroupRating
          // }
        }
        if(this.GroupConversation){
          NewMessage.groupid = this.GroupConversation.groupid
          NewMessage.grouplogo = this.GroupConversation.grouplogo
          NewMessage.groupname = this.GroupConversation.groupname
          NewMessage.GroupSender = false
        }
        //If the very first message is a "conversation id" and we keep it in there, then we could have "replies"
        db.collection('usermessages').add(NewMessage)
        inputelmnt.innerHTML = ''
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            
        }, 50);
      },
    handleScrollQuickNav(){
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        this.SocialQuickNavTop = '115px'
        }
        else{
          this.SocialQuickNavTop = '225px'
        }
    },
    TogglesocialSiteQuickNav(){
      if(this.socialquicknav){
        this.socialquicknav = false
      }
      else{
        this.socialquicknav = true
        setTimeout(() => {
        let socialsitequicknavelmnt = document.getElementById('socialsitequicknav')
        if(socialsitequicknavelmnt){
      socialsitequicknavelmnt.style.top = this.SocialQuickNavTop
    }
    }, 50);
      }
    },
    // ActivateMessageDialog(user){
    //   sdf
    // },
    DeactivateUserMessage(){
        this.MessageDialog = false
        this.MessagetoUser = ''
        this.socialquicknav = true
      },
      ActivateMessage(user){
        this.socialquicknav = false
        this.MessageDialog = true
        this.MessagetoUser = user
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            this.ReceivedMessages.map(msg => {
              if(msg.ReadType === 'Received' && msg.Read === false){
                this.UpdateMessageasRead(msg)
              }
            })
            document.querySelector(".newmessagepost[contenteditable]").addEventListener("paste", function(e) {
              e.preventDefault();
              var text = "";
              if (e.clipboardData && e.clipboardData.getData) {
                text = e.clipboardData.getData("text/plain");
              } else if (window.clipboardData && window.clipboardData.getData) {
                text = window.clipboardData.getData("Text");
              }
              document.execCommand("insertHTML", false, text);
            });
        }, 200);
      },
      UpdateMessageasRead(msg){
        db.collection('usermessages').doc(msg.id).update({
          Read: true
        })
      },
    getGroups() {
           db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.groups.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
      })
    })

    },
    GetUsers(){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
        if (change.type === 'modified') {
           // console.log('here is change')
          // console.log(change.doc.data())
          let userdata = change.doc.data()
          let userobj = this.UsersArray.find(obj => obj.id == change.doc.id)
          let userindex = this.UsersArray.indexOf(userobj)
          if(this.UsersArray[userindex].onlinestatus !== userdata.onlinestatus){
            this.UsersArray[userindex].onlinestatus = userdata.onlinestatus
          }
          
        }  
      })
    })
      },
    DateFormatterwithTime(date){
      if(date){
        // console.log(date)
        // console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},

TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
    
    IntranetViewToggle(){
      // confirm('This will take you to the data version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',false)
      },
      
    

     ViewItem(notification){
      this.$router.push(notification.Path)
    },
    NotificationRead(notification){
      this.$emit('NotificationRead',notification)
    },
    MarkasUnread(notification){
      this.$emit('MarkasUnread',notification)
    },

    
    CheckActivateSearch(){
      // console.log(this.search === '')
      // console.log(this.SearchView)
      if(this.search === '' && this.SearchView){
        // console.log('conditions met bro')
      this.$emit('DeactivateSearch')
      this.SearchView = false
      }
      
    },
    ActivateSearch(){    
      this.$emit('DeactivateSearch')
      // console.log(this.search) 
      this.SearchView = true
      this.$emit('ActivateSearch',this.search)
    },
    
  }

}
</script>

<style>
.messagebox{
  position: fixed;
  bottom: 60px;
  transition: right 0.5s;
  z-index: 101;
}
.messageblock {
  border: none;
  cursor: pointer;
  border-radius: 25px;
}
.newmessagepost{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 2px 25px;
  min-height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 16px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.newmessagepost[contenteditable]:empty::before {
  content: "Type message";
  color: gray;
}
.messagelist {
  height:200px;/* or any height you want */
  overflow-y:auto
}
.messageboxheader{
  z-index: 105;
}
.systembar{
    background-repeat: repeat;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
.waytop{
  z-index: 100;
}
.searchinput{
  background-color: white;
  color: #474747;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  top: 30px;
  z-index: 4
}
.stickytopAppStylingView{
  top: 260px;
  z-index: 4
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 4
}

.stickytop3AppStylingView{
  position: fixed;
  margin-top:85px;
  width: 100%;
  z-index: 4
}
</style>
    