<template>
  <div>
            <v-card tile>
            <v-card-title class="justify-space-between recordtoolbar white--text">
               Advanced Find <v-icon class="soloactionicon red" dark @click="ToggleAdvancedFindDialog()">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle class="recordtoolbar white--text">
              {{CurrentEntity.DisplayName}}
            </v-card-subtitle>
            <v-card-text style="min-height: 200px;">
                        <v-btn class="mb-2" small @click="AddConditionCheck(FindCond,'Condition')">Add Filter</v-btn>
                     
                        <div  v-if="FindCond.Checks && FindCond.Checks.length>0">
                        <v-list dense class="my-1 mx-1 basicoutlined" v-for="(check,checkindex) in FindCondChecks" :key ="check.itemObjKey">  
                   
                      <v-list-item>
                        <v-list-item-action>
                            <v-btn @click="ConfirmCheckDelete(check,checkindex)" fab icon dark color="error">
                                <v-icon>
                                    mdi-delete-forever
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <v-list-item-content>
                            
                          {{check.CheckNumber}}
                          </v-list-item-content>
                          <v-list-item-content>
                          
                          <v-select label="Condition" :items="['IF','ALWAYS']" v-model="check.Condition" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.Condition">
                              <v-combobox :items="AllCurrentBuildRelatedBuilds" item-text="Main" return-object label="Record" v-model="check.Record"></v-combobox>
                              </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="check.Condition !== 'ALWAYS'">
                        <v-list-item-content v-if="check.Record">
                            <!-- <select class="form-control" name="template" v-model="check.FieldtoCheck">
                                <option v-for="item in check.RecordFields" :key="item.itemObjKey">
                                {{ item.DisplayName }}asdas
                                </option>
                            </select> -->
                          <v-autocomplete :filter="RecordFieldFilter" label="Field" :items="check.RecordFields" v-model="check.FieldtoCheck" return-object>
                              <template v-slot:selection="data">
                                <v-list-item-content>
                                    <v-list-item-subtitle v-html="data.item.DisplayName"></v-list-item-subtitle>
                                    
                                </v-list-item-content>
                            </template>
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title  v-html="data.item.DisplayName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="'('+data.item.Index+') '+data.item.Type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                          </v-autocomplete>
                          </v-list-item-content> 
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type !== 'Date' && check.FieldtoCheck.Type !== 'Lookup' && check.FieldtoCheck.Type !== 'Checklist'">
                          <v-select  v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Single Line Text' ||
                          check.FieldtoCheck && check.FieldtoCheck.Type === 'Multiple Lines Text'" label="Parameter"
                          :items="['===','!==','includes','does not include','Contains Data','Does Not Contain Data']" v-model="check.Parameter" size="1"></v-select>
                          <v-select  v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Single Line Text' ||
                          check.FieldtoCheck && check.FieldtoCheck.Type === 'Route Path'" label="Parameter"
                          :items="['===','!==','includes','does not include','Contains Data','Does Not Contain Data']" v-model="check.Parameter" size="1"></v-select>
                          <v-select  v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Boolean'" label="Parameter" :items="['true','false','false or undefined']" v-model="check.Parameter" size="1"></v-select>
                          <v-select  v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Single File Upload'" label="Parameter" :items="['Contains Data','Does Not Contain Data']" v-model="check.Parameter" size="1"></v-select>
                          <v-select  v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Option Set' || check.FieldtoCheck && check.FieldtoCheck.Type === 'Global Option Set'" label="Parameter" :items="['===','!==','in','not in','Contains Data','Does Not Contain Data']" v-model="check.Parameter" size="1"></v-select>
                          <v-select  v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Number Field'
                          || check.FieldtoCheck && check.FieldtoCheck.Type === 'Calculated Field'
                          || check.FieldtoCheck && check.FieldtoCheck.Type === 'Rollup Calculation'" label="Parameter" :items="['===','!==','<','>','>=','<=','Contains Data','Does Not Contain Data']" v-model="check.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.Parameter && check.FieldtoCheck.Type !== 'Date' && check.FieldtoCheck.Type !== 'Lookup' && check.FieldtoCheck.Type !== 'Boolean'">
                          <v-select label="Value" item-text="Name" :multiple="check.Parameter.includes('in')"
                          return-object v-if="check.FieldtoCheck.Type === 'Option Set' || check.FieldtoCheck && check.FieldtoCheck.Type === 'Global Option Set'" :items="check.FieldtoCheck.Options" v-model="check.Value"></v-select>
                          <v-text-field label="Value"
                          v-if="check.FieldtoCheck && check.FieldtoCheck.Type !== 'Option Set'  && check.FieldtoCheck.Type !== 'Global Option Set'
                          && check.Parameter !== 'Contains Data' && check.Parameter !== 'Does Not Contain Data' && check.FieldtoCheck.Type !== 'Number Field'
                          && check.FieldtoCheck.Type !== 'Calculated Field'" v-model="check.Value"></v-text-field>
                          <v-text-field label="Value" type="number" v-if="check.FieldtoCheck.Type === 'Number Field' || check.FieldtoCheck.Type === 'Calculated Field'" v-model.number="check.Value"></v-text-field>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.Parameter && check.FieldtoCheck && check.FieldtoCheck.Type === 'Boolean'">
                          <v-switch v-if="check.Parameter === 'true'" :value="true" readonly filled></v-switch>
                          <v-switch  v-if="check.Parameter === 'false'" :value="false" readonly filled></v-switch>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Checklist'">
                            <v-select label="Check Type" :items="['Preset Options']" v-model="check.ChecklistParameter"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Date'">
                            <v-select label="Date Check Type" :items="['Contains Data','Does Not Contain Data','Specific Date','Preset Options','Other Field']" v-model="check.DateParameter"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Lookup' && check.FieldtoCheck.RelatedBuildID !== RouteID">
                            <v-select label="Lookup Check Type" :items="['Preset Options','Other Field']" v-model="check.LookupParameter"></v-select>
                          </v-list-item-content>  
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Lookup' && check.FieldtoCheck.RelatedBuildID === RouteID">
                            <v-select label="Lookup Check Type" :items="['Preset Options','Other Field','Current Record']" v-model="check.LookupParameter"></v-select>
                          </v-list-item-content>                        
                          <v-list-item-content v-if="check.DateParameter && check.FieldtoCheck.Type && check.FieldtoCheck.Type === 'Date'">
                          <v-select  v-if="check.DateParameter === 'Other Field'" :items="DateComparisonParameters" v-model="check.Parameter" size="1"></v-select>
                          <v-select  v-if="check.DateParameter !== 'Other Field'" :items="DateParameters" v-model="check.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.ChecklistParameter && check.FieldtoCheck.Type === 'Checklist'">
                           <v-select  v-if="check.ChecklistParameter === 'Preset Options'" 
                           :items="['All Checked True','All Checked False','Any Checked True','Any Checked False']" v-model="check.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="check.LookupParameter && check.FieldtoCheck.Type && check.FieldtoCheck.Type === 'Lookup'">
                          <v-select  v-if="check.LookupParameter === 'Other Field'" :items="['===','!==','Same Business Unit','Same Owner']" v-model="check.Parameter" size="1"></v-select>
                          </v-list-item-content>                                           
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Date' && check.DateParameter && check.DateParameter === 'Preset Options'
                          || check.DateParameter && check.Parameter && check.FieldtoCheck.Type === 'Date'">
                          <v-select label="Value" v-if="check.DateParameter === 'Preset Options'" :items="ComputedDatePresetOptions" v-model="check.Value"></v-select>
                          <v-menu  v-if="check.DateParameter === 'Specific Date'"
                            v-model="check.DateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="check.Value"
                                label="Date"
                                prepend-icon="mdi-calendar-month"
                                readonly
                                v-on="on">
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="check.Value" @input="check.DateMenu = false"></v-date-picker>
                            </v-menu>                          
                          </v-list-item-content>
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Date' && check.DateParameter && check.DateParameter === 'Preset Options'
                          || check.DateParameter && check.Parameter && check.FieldtoCheck.Type === 'Date'">
                          <v-switch label="Include Undefined" v-model="check.IncludeUndefined"
                          />
                          </v-list-item-content>
                          <v-list-item-content v-if="check.FieldtoCheck && check.FieldtoCheck && check.FieldtoCheck.Type === 'Lookup' && check.LookupParameter && check.LookupParameter === 'Preset Options' || check.LookupParameter && check.Parameter && check.FieldtoCheck.Type === 'Lookup'">
                          <v-select label="Value" v-if="check.LookupParameter === 'Preset Options'" :items="['Contains Data','Does Not Contain Data']" v-model="check.Value"></v-select>           
                          </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Date'">
                          <v-list-item-content v-if="check.DateParameter === 'Other Field'">
                          <v-select label="Record" :items="AllCurrentBuildRelatedBuilds" item-text="Main" v-model="check.DateCheckRecord" return-object></v-select> 
                          </v-list-item-content>
                          <v-list-item-content v-if="check.DateParameter === 'Other Field' && check.DateCheckRecord" >
                          <v-select label="Value" :items="check.DateCheckRecord.DateFields" v-model="check.Value" item-text="DisplayName"></v-select>
                          </v-list-item-content>                
                          </v-list-item> 
                          <v-list-item v-if="check.FieldtoCheck && check.FieldtoCheck.Type === 'Lookup'">
                          <v-list-item-content v-if="check.LookupParameter === 'Other Field'">
                          <v-select label="Record" :items="AllCurrentBuildRelatedBuilds" item-text="Main" v-model="check.LookupCheckRecord" return-object></v-select> 
                          </v-list-item-content>
                          <v-list-item-content v-if="check.LookupParameter === 'Other Field' && check.LookupCheckRecord">
                          <v-select label="Matching Lookup" :items="check.LookupCheckRecord.LookupFields" v-model="check.Value" item-text="DisplayName"></v-select>
                          </v-list-item-content>                    
                          </v-list-item>                          
                          <v-list-item>
                          <!-- <v-list-item-content v-if="check.Value || check.DateParameter === 'Contains Data' || check.DateParameter === 'Does Not Contain Data' || check.Parameter === 'Contains Data' || check.Parameter === 'Does Not Contain Data'">
                            <v-checkbox label="Process Action(s) Here?" v-model="check.TriggersAction"></v-checkbox>
                          <v-combobox v-if="check.TriggersAction" label="Select Action(s)" :items="EditedAdvancedFind.AdvancedFindActions" item-text="MethodName" v-model="check.ResultActions" size="1" multiple></v-combobox>
                          </v-list-item-content> -->
                          </v-list-item> 
                        </v-list>
                        </div>
                    </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                <!-- <v-btn
                    text
                    @click="ReverseWorkFlowStep()"
                >
                    Cancel
                </v-btn> -->
                <v-spacer></v-spacer>
                <!-- <v-btn
                    dark
                    color="warning"
                    depressed
                    @click="TestFilter()"
                >
                    Test
                </v-btn> -->
                <v-btn
                    dark
                    color="success"
                    depressed
                    @click="SaveAdvancedFind()"
                >
                    {{AdvancedFindSaveName}}
                </v-btn>
                </v-card-actions>
            </v-card>
        <!-- </v-dialog> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
    name: 'WorkFlowBuilder',
    props: ['System','SystemEntities','CurrentEntity','Data','Checks','AdvancedFindSaveName'],
    components: {},
    data() {
        return {
            Today: '',
            TimelineFilterOptions: [			
        {			
          ID: 1000000,			
          FilterName: 'This Day (Per 2 Hrs)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Day',			
          StartDateVariable: 'ThisToday',			
          EndDateVariable: 'ThisTodayEnd',			
          ChartLabels: ['0AM - 2AM','2AM - 4AM','4AM - 6AM','6AM - 8AM','8AM - 10AM','10AM - 12PM','12PM - 14PM','14PM - 16PM','16PM - 18PM','18PM - 20PM','20PM - 22PM','22PM - 0AM'],			
          ChartLabelsVariables: ['ThisToday','Todayby2AM','Todayby4AM','Todayby6AM','Todayby8AM','Todayby10AM','Todayby12PM','Todayby14PM','Todayby16PM','Todayby18PM','Todayby20PM','Todayby22PM'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'Endof2Hours',			
        },
        {			
          ID: 1000001,			
          FilterName: 'This Week (Per Day)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Week',			
          StartDateVariable: 'ThisMonday',			
          EndDateVariable: 'ThisSunday',			
          ChartLabels: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],			
          ChartLabelsVariables: ['ThisMonday','ThisTuesday','ThisWednesday','ThisThursday','ThisFriday','ThisSaturday','ThisSunday'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'EndofDay',			
        },			
        {			
          ID: 1000002,			
          AccumulatorID: 1000001,			
          FilterName: 'This Month (Per Week)',			
          FilterDescription: 'When viewing, this filter returns each Week for the total number of Weeks of the Current Month, and relevant results on each Week. For Goals, the results shown is by default filtered by the current Week',			
          GiveName: 'Per Week',			
          Name: 'This Month',			
          StartDateVariable: 'CurrentMonthStart',			
          EndDateVariable: 'CurrentMonthEnd',			
          ChartLabelTrigger: 'CurrentMonthName',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentMonthLabels',         			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofWeek',			
        },			
        {			
          ID: 1000003,			
          AccumulatorID: 1000002,			
          FilterName: 'This Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month',			
          Name: 'This Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
        },			
        {			
          ID: 1000004,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Q1','Q2','Q3','Q4'],			
          ChartLabelsVariables: ['FiscalQ1Start','FiscalQ2Start','FiscalQ3Start','FiscalQ4Start'],			
          ChartlabelPeriodEndMethodX: 'EndofQuarter',			
          ChartlabelPeriodEndMethodY: 'EndofQuarter'			
        },			
        {			
          ID: 1000005,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Quarter (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Quarter, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Quarter',			
          StartDateVariable: 'CurrentFiscalQStart',			
          EndDateVariable: 'CurrentFiscalQEnd',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentFiscalLabels',			
          ChartLabelTrigger: 'CurrentFiscalPeriod',			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
          			
        },			
        			
      ],
            DatePresetOptions: [
              'Day of View','Last Week','This Week','Next Week','Last Month','This Month','Next Month',
              'Last Year','This Year','Next Year','Last Fiscal Period','Next Fiscal Period','This Fiscal Period','Last Fiscal Year','This Fiscal Year','Next Fiscal Year'  
            ],
            DateComparisonParameters: ['===','!==','On or After','On or Before','After','Before','Same Week','Same Month','Same Year','Same Fiscal Period','Same Fiscal Year'],
            DateParameters: ['===','!==','On or Before','On or After','Before','After'],
            BuildAdvancedFinds: [],
            SavingAdvancedFinds: false,
            AutoFieldeditItem: '',
            AdvancedFindFielddialog: false,
            TempParentInput: '',
            LastAdvancedFindInteger: 1000000,
            FindCond: {Checks: [],CheckNumber: 1},
            FindAction: {},
            FieldFilter: {Output: '', Input:[], Name: {}, InputType: 'Parent Field'},
            FieldFilterSourceFields: [],
            DefaultFieldFilter: {Output: '', Input:[], Name: {}, InputType: 'Parent Field'},
            EditedAdvancedFind: {Conditions: []
          },
            DefaultEditedAdvancedFind: {Conditions: []},
        }
    },
    computed:{
        ComputedDatePresetOptions(){
            return this.DatePresetOptions.filter(item => {
                return !item.includes('Fiscal')
            })
        },
        LastYear(){
            return {Start: this.StartofLastYear,End: this.EndofLastYear}
        },
        StartofLastYear(){
            var year = this.EndofLastYear.getFullYear();			
            var month = 0;				
            var d = new Date(year, month, 1)	
            d.setHours(0,0,0,0)	
            return d
        },
        EndofLastYear(){
          let d = this.ThisJanuaryStart
          d.setDate(d.getDate() - 1)
            return this.EndofDay(d) 
        },
        NextYear(){
            return {Start: this.StartofNextYear,End: this.EndofNextYear}
        },
        StartofNextYear(){
            let d = this.ThisDecemberEnd
            d.setDate(d.getDate() + 1)
            d.setHours(0,0,0,0)
            return d
        },
        EndofNextYear(){
            var year = this.StartofNextYear.getFullYear();			
            var month = 11;				
            var d = new Date(year, month, 31)		
            return this.EndofDay(d)
        },
        NextMonth(){
            return {Start: this.StartofNextMonth,End: this.EndofNextMonth}
        },
        StartofNextMonth(){
            let d = this.EndofMonth(this.Today)
            d.setDate(d.getDate() + 1)
            return d
        },
        EndofNextMonth(){
            let d = this.EndofMonth(this.StartofNextMonth)
            return d
        },
        LastMonth(){
            return {Start: this.StartofLastMonth,End: this.EndofLastMonth}
        },
        StartofLastMonth(){		
            let d = this.EndofLastMonth	
            d.setDate(1)		
            d.setHours(0,0,0,0)			
        return d			
        },
        EndofLastMonth(){			
            let d = this.Today; // current date
            d.setDate(1); // going to 1st of the month
            d.setHours(-1); // going to last hour before this date even started.
            d.setHours(23,59,59,999)
            return d
        },
        LastWeek(){
            return {Start: this.LastweekFirstDate,End: this.LastweekLastDate}
        },
        LastweekFirstDate(){			
            let newtoday = new Date(this.Today)			
            var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
            let date = new Date(newtoday.setDate(lastdaylastweek - 7))			
            date.setHours(0,0,0,0)			
            return date			
        },
        LastweekLastDate(){			
            let newtoday = new Date(this.Today)			
            var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
            let date = new Date(newtoday.setDate(lastdaylastweek))			
            date.setHours(23,59,59,999)			
            return date			
        },	
        NextWeek(){
            return {Start: this.NextweekFirstDate,End: this.NextweekLastDate}
        },
        NextweekFirstDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek + 7))			
          date.setHours(0,0,0,0)			
          return date			
      },	
        NextweekLastDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek))			
          date.setHours(23,59,59,999)			
          return date			
      },
        DayofView(){
            return {Start: this.Today,End: this.EndofDay(this.Today)}
        },
        ThisWeek(){
            return {Start: this.ThisMonday,End: this.ThisSunday}
        },
        ThisMonth(){
            return {Start: this.CurrentMonthStart,End: this.CurrentMonthEnd}
        },
        ThisYear(){
            return {Start: this.ThisJanuaryStart,End: this.ThisDecemberEnd}
        },
        CurrentMonthStart(){
            let d = this.Today
            d.setDate(1);	
            d.setHours(0,0,0,0);
            return d
        }, 
        CurrentMonthEnd(){
            let d = this.Today
            return this.EndofMonth(d)
        }, 
        ThisMonday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+1))			
            date.setHours(0,0,0,0)			
            return date			
      },	
      ThisSunday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+7))			
          date.setHours(0,0,0,0)			
          return date			
      },
    
    ComputedToday(){			
          let date = new Date(this.Today)			
          date.setHours(0,0,0,0)			
          return date			
      },	
    ThisJanuaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 0;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },	
    ThisDecemberEnd(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 11;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return this.EndofDay(d)
    },
    FindCondChecks(){
        return this.FindCond.Checks.map(checkobj => {
            if(checkobj.Record){
                let Record = this.SystemEntities.find(obj => obj.id === checkobj.Record.id)
                checkobj.RecordFields = Record.AllFields.map((field,fieldindex) => {
                let fieldobj = Object.assign({},field)
                fieldobj.Index = fieldindex
                delete field.text
                delete field.value
                return fieldobj
                })
                // .filter(field => {
                //     return field.Type === 'Date'
                // })
                console.log(checkobj.RecordFields,Record.AllFields)
            }
            return checkobj 
        })
    },
      BasicSystemEntities(){
        return this.SystemEntities.map(entity => {
          let entityobj = {
            id: entity.id,
            DisplayName: entity.DisplayName,
            datasetsecuritystructure: entity.datasetsecuritystructure,
            RecordPrimaryField: entity.RecordPrimaryField,
          }
          if(entity.Table_Icon){
            entityobj.Table_Icon = entity.Table_Icon
          }
          return entityobj
        })
      },
      ComputedFieldFilterSourceFields(){
        return this.FieldFilterSourceFields.map(field => {
          let fieldobj = Object.assign({},field)
          delete fieldobj.Array
          delete fieldobj.MappedValues
          delete fieldobj.RecordData
          return fieldobj
        })
      },
      MutatedFindCond(){
        let obj = Object.assign({},this.FindCond)
        if(obj && obj.Checks){
          obj.Checks = obj.Checks.map(condstep => {
          if(condstep.LookupParameter === 'Preset Options' && condstep.FieldtoCheck){
                            if(condstep.Value === 'Contains Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Value === 'Does Not Contain Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Value === '==='){
                                if(condstep.FieldtoCheck.Type === 'Option Set'){
                                    condstep.CheckParams = record[condstep.FieldtoCheck.Name].Name+' '+condstep.Value.Name
                                }                                
                            }
                            
                            else {
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value  
                            }
                        }
                        else if(condstep.FieldtoCheck){
                            let fieldcheck = condstep.FieldtoCheck
                            if(condstep.Parameter === 'Contains Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Parameter === 'Does Not Contain Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Parameter === '==='){
                                if(condstep.FieldtoCheck.Type === 'Option Set' && condstep.Value){
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                                }                                
                            }
                            else if(condstep.FieldtoCheck.Type === 'Boolean'){
                              condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter 
                            }
                            else {
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value   
                            }
                        }
                        return condstep
        })
        }
        
        return obj
      },
        RelatedRecordState(){
      if(this.RelatedRecord){
        let statusobj = this.RelatedRecord.AllFields.find(obj => obj.Name === 'Status')
        if(statusobj){
          return statusobj
        }
      }
    },
        
        SystemConfig(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID)
        },
        ConfigDB(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id).collection('automations')
        },
        AllFields() {
        return this.CurrentEntity.AllFields
        },
        LookupFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Lookup'
            })
        },
        DateFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Date'
            })
        },
        NumberFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Number Field'
            })
        },
        ParentFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Parent Field'
            })
        },
        MappedFields(){
        return this.AutoActRecordFields.filter(field => {
            return field.Input && field.Input.length > 0
        })
        .map(field => {
          let fieldobj = Object.assign({},field)
          fieldobj.Input = fieldobj.Input.map(input => {
            delete input.Array
            return input
          })
          delete fieldobj.Array
          return fieldobj
        })
    },
        
        RelatedFields(){
        return this.AllRelatedFields.filter(field => {
          return field.Type === 'Single Line Text' || field.Type === 'Date' || field.Type === 'Number Field'
        })
      },
      AllRelatedFields(){
          let arr1 = this.AllFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr4 = this.LookupBuilds.map(build => {
              let AllLookupBuildFields = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
             
              return AllLookupBuildFields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let AllLookupBuildLookups = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return AllLookupBuildLookups
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let AllParentBuildFields = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })

              
              return AllParentBuildFields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      OnlyDateFields(){
        let arr1 = this.DateFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
         
          let arr4 = this.LookupBuilds.map(build => {
              let builddatefields = build.AllFields.filter(field => {return field.type === 'Date'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
            
             
              return builddatefields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let builddatefields = build.AllFields.filter(field => {return field.type === 'Date'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return builddatefields
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          // console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let builddatefields = build.AllFields.filter(field => {return field.type === 'Date'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return builddatefields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      OnlyNumberFields(){
        let arr1 = this.NumberFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
         
          let arr4 = this.LookupBuilds.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
            
             
              return buildnumberfields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return buildnumberfields
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          // console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return buildnumberfields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      DatesandNumberFields(){
          let arr1 = this.NumberFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr2 = this.DateFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr4 = this.LookupBuilds.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
             
              return buildnumberfields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return buildnumberfields
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          // console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return buildnumberfields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr2,arr4,arr5,arr6)
          return arr3
      },
      
      LookupBuilds() {
      return this.SystemEntities.map(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.LookupFields.find(obj => obj.RelatedBuildID == build.id)
        
        if(lookupobj){
          //console.log('this.LookupBuilds lookupobj',lookupobj)
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
         // console.log('this.LookupBuilds newbuild',newbuild)          
        }
        return newbuild
      }).filter(build => {
        return build.SourceField
      })
      },
      ParentBuilds() {
      return this.SystemEntities.map(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.ParentFields.find(obj => obj.ParentFieldObj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.RelatedRecord = lookupobj.RelatedRecord
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          }
        return newbuild
      }).filter(build => {
        return build.SourceField
      })
      },
      CreateActionBuilds() {
        if(this.EditedAdvancedFind && this.EditedAdvancedFind.AdvancedFindActions){
        let NewActions = this.EditedAdvancedFind.AdvancedFindActions
        let NewCreateActions = NewActions.filter(action => {
            return action.ActionType === 'Create' && action.Record && action.Record.id
          })
          // console.log('this.AdvancedFindActions')
          // console.log(NewCreateActions)
          return NewCreateActions.map(action => {
        // let lookupobj = this.LookupFields.find(obj => obj.RelatedBuildID == build.id)
        // if(lookupobj){
          //console.log('source action',action)
          let NewBuildsArray = Array.from(this.SystemEntities)
          
          let buildobj = NewBuildsArray.find(obj => obj.id === action.Record.id)
          if(buildobj){
          let actionbuild = {DisplayName: buildobj.DisplayName,id: buildobj.id, AllFields: buildobj.AllFields}          
          actionbuild.SourceField = action.MethodID
          actionbuild.sourcename = action.Record.DisplayName+' ('+action.MethodName+')'
          actionbuild.Main = actionbuild.sourcename
          actionbuild.Relationship = 'Create Method Build'
          actionbuild.RelationshipField = actionbuild.SourceField
          actionbuild.source = action.Record
          return actionbuild
          }
        // }
      })
        }
        else return []
      },
      ParentBuildsLookups() {
          let LookupBuildsArray = Array.from(this.ParentBuilds)
          
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
            let newbuild = Object.assign({},lookupbuild)
                let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
                if(lookupobj){
                    newbuild.SourceField = build.SourceField
                    newbuild.ParentSourceField = lookupobj.Name
                    newbuild.Parentsourcename = build.DisplayName
                    return newbuild
                }
        })
        return newarray       
      }).flat()
      },
      ParentLookupBuilds() {
      return this.SystemEntities.filter(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.ParentFields.find(obj => obj.ParentFieldObj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          return newbuild
        }
      })
      },
      LookupBuildsLookups() {
          let LookupBuildsArray = Array.from(this.LookupBuilds)
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
            let newbuild = Object.assign({},lookupbuild)
            let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
            if(lookupobj){
                newbuild.SourceField = build.SourceField
                newbuild.ParentSourceField = lookupobj.Name
                newbuild.Parentsourcename = build.DisplayName
                return newbuild
            }
        })
        return newarray       
      }).flat()
      },
      
      LookupBuildsParents() {
          let LookupBuildsArray = Array.from(this.LookupBuildsParents)
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
        let newbuild = Object.assign({},lookupbuild)
        let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
        if(lookupobj){
            newbuild.SourceField = build.SourceField
            newbuild.ParentSourceField = lookupobj.Name
            newbuild.Parentsourcename = build.DisplayName
          return newbuild
        }
        })
        return newarray       
      }).flat()
      },
      RelatedNumberFields(){
          let relbuildobj = this.SystemEntities.find(obj => obj.id === this.RelatedRecord.id)
          return relbuildobj.AllFields.filter(yyfield => {return field.Type == 'Number Field'})
      },
      FieldsforAdvancedFind(){
      if(typeof this.FieldFilter !== 'undefined'){
      // if(this.FieldFilter.Type === 'Single Line Text'){
      //   return this.AllFields
      // }
      // else {
      // return this.AllFields.filter(field => {
      //  return field.Type === this.FieldFilter.Type && field.Type !== 'Option Set'
      // })
      // }
        return this.FieldFilter.Input
      }
    },
      AllCurrentBuildRelatedBuilds(){
        //    if(!this.SavingAdvancedFinds){
      let NewLookupBuilds = Array.from(this.LookupBuilds)
      let NewParentBuilds = Array.from(this.ParentBuilds)
      // console.log('this.ParentBuilds',this.ParentBuilds)
      // console.log('this.LookupBuilds',this.LookupBuilds)
      let arr1 = NewLookupBuilds.map(build => {
        //console.log('this.LookupBuilds NewLookupBuilds build',build)
        build.Main = build.DisplayName+' ('+build.SourceField+')'
        build.Relationship = 'Lookup Field Build'
        build.RelationshipField = build.SourceField
        return build
      })
      let CurrentBuildObj = this.CurrentEntity
      CurrentBuildObj.Main = CurrentBuildObj.DisplayName
      CurrentBuildObj.Relationship = 'Build Self'
      let arr2 = [CurrentBuildObj]
      let arr3 = NewParentBuilds.map(build => {
        build.Main = build.DisplayName+' ('+build.SourceField+')'
        build.Relationship = 'Parent Field Build'
        build.RelationshipField = build.SourceField
        return build
      })
      // console.log('arr1',arr1)
      // console.log('arr3',arr3)
      let arr4 = []
     
          arr4 = this.CreateActionBuilds
      //console.log('source arr1',arr1,'arr2',arr2,'arr3',arr3,'arr4',arr4)
      return arr1.concat(arr2,arr3,arr4).map(source => {
        let sourceobj = {}
        //console.log('source',source)
              if(source.Main && source.Relationship && source.RelationshipField){
                sourceobj = {Relationship: source.Relationship, RelationshipField: source.RelationshipField, id: source.id, DisplayName: source.DisplayName, Main: source.Main}
              }
              else if(source.Main && source.Relationship && !source.RelationshipField){
                 sourceobj = {Relationship: source.Relationship, id: source.id, DisplayName: source.DisplayName, Main: source.Main}
              }
              else if(source.Main && !source.Relationship && !source.RelationshipField){
                 sourceobj = {id: source.id, DisplayName: source.DisplayName, Main: source.Main}
              }
              else if(!source.Main && source.Relationship && source.RelationshipField){
                 sourceobj = {Relationship: source.Relationship, RelationshipField: source.RelationshipField, id: source.id, DisplayName: source.DisplayName, }
              }
              else if(source.Main && !source.Relationship && source.RelationshipField){
                 sourceobj = {RelationshipField: source.RelationshipField, id: source.id, DisplayName: source.DisplayName,  Main: source.Main}
              }
              else if(!source.Main && source.Relationship && !source.RelationshipField){
                 sourceobj = {Relationship: source.Relationship, id: source.id, DisplayName: source.DisplayName, }
              }
              else{
                sourceobj = {id: source.id, DisplayName: source.DisplayName, }
              }
              if(source.RelatedRecord){
                sourceobj.RelatedRecord = source.RelatedRecord
              }
              if(source.sourcename){
                sourceobj.sourcename = source.sourcename
              }
              if(source.SourceField){
                sourceobj.SourceField = source.SourceField
              }
              if(source.ParentSourceField){
                sourceobj.ParentSourceField = source.ParentSourceField
              }
              if(source.Parentsourcename){
                sourceobj.Parentsourcename = source.Parentsourcename
              }
              if(source.RecordPrimaryField){
                sourceobj.RecordPrimaryField = source.RecordPrimaryField
              }
              if(source.Table_Icon){
                sourceobj.Table_Icon = source.Table_Icon
              }
              return sourceobj
      })
    //   }
    //   else{
    //       return []
    //   }
    },
    FieldFilterRelevantFields(){
        // console.log('this.FieldFilter',this.FieldFilter)
      if(this.FieldFilter && this.FieldFilter.source && this.ComputedFieldFilterSourceFields){
       if(this.FieldFilter.Type === 'Single Line Text'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === 'Single Line Text'
      })
       }
       else if(this.FieldFilter.Type === 'Radio Group'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === 'Radio Group'
      })
       }
       else if(this.FieldFilter.Type === 'Common Field'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === this.FieldFilter.Type && field.CommonFieldType === this.FieldFilter.CommonFieldType
      })
       }
       else if(this.FieldFilter.Type === 'Multiple Lines Text'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === 'Multiple Lines Text' || field.Type === 'Single Line Text'
      })
       }
       else if(this.FieldFilter.Type === 'Number Field'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Number Field' || field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation'
            })
          }
          else if(this.FieldFilter.Type === 'Date'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Date'
            })
          }
          else if(this.FieldFilter.Type === 'Option Set'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Option Set' && JSON.stringify(field.Options) === JSON.stringify(this.FieldFilter.Options) 
            })
          }
          else if(this.FieldFilter.Type === 'Global Option Set'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Global Option Set' && JSON.stringify(field.Options) === JSON.stringify(this.FieldFilter.Options) 
            })
          }
          else if(this.FieldFilter.Type === 'Boolean'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Boolean'
            })
          }
          else if(this.FieldFilter.Type === 'Single File Upload'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Single File Upload'
            })
          }
       else if(this.FieldFilter.Type === 'Lookup'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        if(field.Primary){
        // console.log('buildfield')
        // console.log(field)
        // console.log('this.FieldFilter.source')
        // console.log(this.FieldFilter.source)
        }
        return field.Type === 'Lookup' && field.RelatedBuildID === this.FieldFilter.RelatedBuildID || this.FieldFilter.source.id === this.FieldFilter.RelatedBuildID && field.Primary
      })
       }
       else if(this.FieldFilter.Type === 'Parent Field'){
         if(this.FieldFilter.ParentFieldObj.Type === 'Lookup'){
           return this.ComputedFieldFilterSourceFields.filter(field => {
              return field.Type === 'Lookup' && field.RelatedBuildID === this.FieldFilter.ParentFieldObj.RelatedBuildID || field.ParentFieldObj && field.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.RelatedBuildID
      })
         }
         else if(this.FieldFilter.ParentFieldObj.ParentFieldObj && this.FieldFilter.ParentFieldObj.ParentFieldObj.Type === 'Lookup'){
           return this.ComputedFieldFilterSourceFields.filter(field => {
              return field.ParentFieldObj && field.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.ParentFieldObj.RelatedBuildID || field.ParentFieldObj && field.ParentFieldObj.ParentFieldObj && field.ParentFieldObj.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.ParentFieldObj.RelatedBuildID
          })
         }
         else if(this.FieldFilter.ParentFieldObj.Type === 'Single Line Text'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Single Line Text'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Number Field'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Number Field'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Date'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Date'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Boolean'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Boolean'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Single File Upload'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Single File Upload'
            })
          }
       }
      }
    },
        FieldInputOptions(){
      if(this.FieldFilter.Type === 'Single Line Text'){
        return ['Combined Value','Parent Field']
      }
      if(this.FieldFilter.Type === 'Common Field'){
        return ['Parent Field']
      }
      if(this.FieldFilter.Type === 'Multiple Lines Text'){
        return ['Combined Value','Parent Field']
      }
      if(this.FieldFilter.Type === 'Option Set'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Radio Group'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Global Option Set'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Number Field' || this.FieldFilter.Type === 'Date'){
        return ['Parent Field','Calculated Value']
      }
      if(this.FieldFilter.Type === 'Lookup' || this.FieldFilter.Type === 'Parent Field'){
        return ['Parent Field']
      }
      if(this.FieldFilter.Type === 'Boolean'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Single File Upload'){
        return ['Parent Field']
      }
    },
        RelatedRecord(){
            if(this.FindAction && this.FindAction.Record && this.FindAction.Record.id){
                return this.SystemEntities.find(obj => obj.id === this.FindAction.Record.id)
            }
        },
        AutoActRecordFields(){
            if(this.RelatedRecord){
                return this.RelatedRecord.AllFields
                // .filter(field => {
                //     return field.IsHeader
                // })
            }
            else{
                return []
            }
        },
        
        NewAdvancedFind(){
            let NewAdvancedFind = {
              ID: this.LastAdvancedFindInteger,
              Name: 'New AdvancedFind',
              AdvancedFindActions: [],
              Conditions: []
          }
          return NewAdvancedFind
        },
        BuildAdvancedFindsShortened(){
      let NewArray = Array.from(this.BuildAdvancedFinds)
      let AutoArray = NewArray.map(auto => {
        if(auto.AdvancedFindActions.length >0){
        let Actions = auto.AdvancedFindActions
        let Conditions = auto.Conditions.map(condition => {
          condition.ResultActions = condition.ResultActions.map(resultact => {            
            return {MethodID: resultact.MethodID, MethodName: resultact.MethodName}
          })
          let buttonobj = this.ConditionswithButtons.find(obj => obj.CheckNumber === condition.CheckNumber)
          if(buttonobj && buttonobj.ApprovalButtonText){
            condition.ApprovalButtonText = buttonobj.ApprovalButtonText
          }
          return condition
        })
        auto.Conditions = Conditions
        auto.AdvancedFindActions = Actions
        }
        return auto
      
      })
      return AutoArray
    },    
    ConditionswithButtons(){
      let NewArray = Array.from(this.BuildAdvancedFinds)
      return NewArray.map(auto => {
        return auto.Conditions
      }).flat().filter(cond => {
        return cond.CallfromButton
      })
    },
        
    },
    created(){
        this.Today = new Date()
        this.Today.setHours(0)
        this.Today.setMinutes(0)
        this.Today.setSeconds(0)
        if(this.Checks){
            this.FindCond.Checks = JSON.parse(JSON.stringify(this.Checks))
        } 
    },
    methods:{
        EndofMonth(date){			
        var month = date.getMonth()			
        var year = date.getFullYear()			
        var d = new Date(year, month + 1, 0)			
        d.setHours(23,59,59,999)			
        return d			
        },
        EndofDay(date){			
        var day = date.getDate()			
        var month = date.getMonth()			
        var year = date.getFullYear()			
        var d = new Date(year, month, day)			
        d.setHours(23,59,59,999)			
        return d			
        },	
        EndofWeek(date){			
     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();			
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },	
        ConfirmCheckDelete(check,checkindex){
            confirm('Are you sure you want to delete this check?') && this.ProcessDeleteStep(checkindex)
        },
        ProcessDeleteStep(checkindex){
            this.FindCond.Checks.splice(checkindex,1)
        },
        
      LookupPass(condstep,fieldcheck,record){
            let checkrecord = ''
            let value = record[fieldcheck.Name]
            let valuecheck = ''
            if(value){
                valuecheck = value[fieldcheck.LookupFieldName]
            }            
            if(condstep.LookupParameter === 'Preset Options'){
                if(fieldcheck.Type === 'Lookup' && record[fieldcheck.Name]){
                    valuecheck = record[fieldcheck.Name][fieldcheck.LookupFieldName]
                }
                //I DO NOT THINK THE LITTLE MESS BELOW WILL EVER BE TURNED ON AGAIN
                else if(fieldcheck.Type === 'Option Set' && record[fieldcheck.Name]){
                    valuecheck = record[fieldcheck.Name].Name
                }
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[fieldcheck.Name]){
                        valuecheck = 'No Value in '+fieldcheck.Name
                    }
                
                else{
                    valuecheck = record[fieldcheck.Name]
                    
                }
                if(typeof record[condstep.FieldtoCheck.Name] !== 'undefined' && condstep.Parameter === 'Contains Data'){
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                    condstep.CheckValue = valuecheck
                }
                else if(typeof record[condstep.FieldtoCheck.Name] === 'undefined' && condstep.Parameter === 'Does Not Contain Data'){
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                    condstep.CheckValue = valuecheck
                }
                else if(typeof record[condstep.FieldtoCheck.Name] !== 'undefined' && condstep.Parameter === '==='){
                    if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                        condstep.CheckParams = record[condstep.FieldtoCheck.Name].Name+' '+condstep.Value.Name
                        condstep.Pass = true
                        condstep.icon = 'mdi-check'
                        condstep.Color = 'green'
                        condstep.CheckValue = valuecheck
                    }                                
                }
                else {
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
                }
            }
            //I DO HOWEVER THINK THAT WE KNOW WE CHECKING THE LOOKUP RECORD RIGHT NOW. AND PERHAPS THE QUESTION IS SHOULD WE NOT MAKE REFERENCE TO THE CORRECT PASS FORMULA?
             else if(fieldcheck.Type === 'Option Set' && record[fieldcheck.Name]){
                    valuecheck = record[fieldcheck.Name].Name
                }
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[fieldcheck.Name]){
                        valuecheck = 'No Value in '+fieldcheck.Name
                    }
                
                else{
                    valuecheck = record[fieldcheck.Name]
                    
                }
                if(fieldcheck.Type === 'Option Set'){
                  condstep = this.OptionSetPass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Number Field' || fieldcheck.Type === 'Calulated Field' || fieldcheck.Type === 'Rollup Calculation'){
                  condstep = this.NumberPass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Date'){
                  condstep = this.DatePass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Checklist'){
                    condstep = this.ChecklistPass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else if(fieldcheck.Type === 'Boolean'){
                  condstep = this.BooleanPass(condstep,fieldcheck,record)
                }
                else {
                  condstep = this.ParamsPass(condstep,fieldcheck,record,record[fieldcheck.Name])
                }
            // else{
            //   //actually only lookup checks that checks for lookup of lookup is of this type...how do we want this to react?
            // }
            //console.log(condstep,record,condstep.FieldtoCheck.Name,record[condstep.FieldtoCheck.Name])
            return condstep
        },
        OptionSetPass(condstep,fieldcheck,record){
            let checkrecord = ''
            let value = checkrecord[fieldcheck.Name]
            let valuecheck = ''
            if(!value){
                valuecheck = 'No Value in '+fieldcheck.Name
            }
            else{
                valuecheck = record[fieldcheck.Name].Name
            }
            if(record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Contains Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(!record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Does Not Contain Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(condstep.Parameter === 'in'){
              let values = condstep.Value.map(val => {
                return val.ID
              })
                if(record[condstep.FieldtoCheck.Name] && values.includes(record[condstep.FieldtoCheck.Name].ID)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === 'not in'){
              let values = condstep.Value.map(val => {
                return val.ID
              })
                if(record[condstep.FieldtoCheck.Name] && !values.includes(record[condstep.FieldtoCheck.Name].ID)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+values                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === '==='){
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === '!=='){
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].ID !== condstep.Value.ID){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }  
                else if(!record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            return condstep
        },
        BooleanPass(condstep,fieldcheck,record){
            let checkrecord = ''
            let value = checkrecord[fieldcheck.Name]
            let valuecheck = value
            if(typeof value === 'undefined' && condstep.Parameter !== 'false or undefined'){
                valuecheck = 'No Value in '+fieldcheck.Name 
            }
            else if(typeof value === 'undefined' && condstep.Parameter === 'false or undefined'){
                valuecheck = 'No Value in '+fieldcheck.Name
                condstep.Pass = true
            }
            else if(!value && condstep.Parameter === 'false or undefined'){
                valuecheck = value
                condstep.Pass = true
            }
            else if(!value && condstep.Parameter === 'false'){
                condstep.Pass = true
                valuecheck = value
            }
            else if(value && condstep.Parameter === 'true'){
                condstep.Pass = true
                valuecheck = value
            }
            //DO WE RATHER PUT PARAMSPASS IN HERE? CAN BOOLEAN BE DOE NOT CONTAIN OR WHATEVER?                                
            if(condstep.Pass){
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter
                condstep.CheckValue = valuecheck
            }
            else{
                condstep.Pass = false
                condstep.icon = 'mdi-cancel'
                condstep.Color = 'red'                                    
                condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter                                
                condstep.CheckValue = valuecheck
            }
            return condstep
        },
        NumberPass(condstep,fieldcheck,record){
            let value = record[fieldcheck.Name]
            let valuecheck = value
            if(typeof valuecheck !== 'undefined'){
                //console.log(record[this.CurrentEntity.Primary_Field_Name],condstep.Parameter,condstep.Value,valuecheck)
                if(condstep.Parameter === '===' && valuecheck === condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '!==' && valuecheck !== condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '<' && valuecheck < condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '>' && valuecheck > condstep.Value){                                        
                    condstep.Pass = true
                    //console.log("condstep.Parameter === '>' && valuecheck > condstep.Value",condstep.Pass)
                }
                else if(condstep.Parameter === '>=' && valuecheck >= condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === '<=' && valuecheck <= condstep.Value){
                    condstep.Pass = true
                }
                else if(condstep.Parameter === 'Contains Data'){
                    condstep.Pass = true
                }
            }
            else{
                if(condstep.Parameter !== 'Does Not Contain Data'){
                valuecheck = 'No Value in '+fieldcheck.Name 
                }
                else{
                    valuecheck = 'No Value in '+fieldcheck.Name 
                    condstep.Pass = true
                }
            }
            //console.log(condstep)
            return condstep
        },
        ParamsPass(condstep,fieldcheck,record,valuecheck){
            //was checkrecord
            if(record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Contains Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(!record[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Does Not Contain Data'){
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                condstep.CheckValue = valuecheck
            }
            else if(condstep.Parameter === '==='){
                if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                } 
                else if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }  
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[condstep.FieldtoCheck.Name]){
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                    condstep.CheckValue = valuecheck
                }     
                //otehweise exact match, kind of silly but yeah                        
            }
            else if(condstep.Parameter === 'does not include'){
                let check = condstep.Value.toLowerCase()
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].toLowerCase() && !record[condstep.FieldtoCheck.Name].toLowerCase().includes(check)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                }
                else{
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
                }
            }
            else if(condstep.Parameter === 'includes'){
                let check = condstep.Value.toLowerCase()
                if(record[condstep.FieldtoCheck.Name] && record[condstep.FieldtoCheck.Name].toLowerCase() && record[condstep.FieldtoCheck.Name].toLowerCase().includes(check)){
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                    condstep.Pass = true
                    condstep.icon = 'mdi-check'
                    condstep.Color = 'green'
                    condstep.CheckValue = valuecheck
                }
                else{
                    condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
                }
            }
            else if(condstep.FieldtoCheck.Type !== 'Boolean'){
                condstep.Pass = false
                condstep.icon = 'mdi-cancel'
                condstep.Color = 'red'
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value                                
                condstep.CheckValue = valuecheck 
            }
                return condstep
        },
        ChecklistPass(condstep,fieldcheck,record){
          let value = record[fieldcheck.Name]
          let valuecheck = value
          let options = fieldcheck.Options
          let pass = false
          if(value){
           if(condstep.ChecklistParameter === 'Preset Options'){
            if(condstep.Parameter === 'All Checked True'){
              pass = true
              options.map(opt => {
                  if(!value[opt.ID] || value[opt.ID] === 'undefined'){
                    pass = false
                  }
              })
            }
            else if(condstep.Parameter === 'All Checked False'){
              pass = true
              options.map(opt => {
                  if(value[opt.ID] || value[opt.ID] === 'undefined'){
                    pass = false
                  }
              })
            }
            else if(condstep.Parameter === 'Any Checked True'){
              pass = false
              options.map(opt => {
                  if(value[opt.ID]){
                    pass = true
                  }
              })
            }
            else if(condstep.Parameter === 'Any Checked False'){
              pass = false
              options.map(opt => {
                  if(!value[opt.ID]){
                    pass = true
                  }
              })
            } 
          }          
          }
          if(pass){
                //now do what you do when passed
                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value
                condstep.Pass = true
                condstep.icon = 'mdi-check'
                condstep.Color = 'green'
                condstep.CheckValue = valuecheck
            }
            else{
                //did not pass now get going
                  condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
            }
            return condstep
        },
        DatePass(condstep,fieldcheck,record){
            let value = record[fieldcheck.Name]
            let valuecheck = value
            if(valuecheck && valuecheck.toDate){
                //console.log('has to date function',record[fieldcheck.Name])
                valuecheck = valuecheck.toDate()
            }
            else{
                //console.log('does not have to date function',record[fieldcheck.Name])
                valuecheck = new Date(valuecheck)
            }
            if(valuecheck){
                if(condstep.DateParameter === 'Contains Data'){

                }
                else if(condstep.DateParameter === 'Does Not Contain Data'){
                    
                }
                else if(condstep.DateParameter === 'Other Field'){
                    //TBD but DateComparisonParameters would apply
                }
                else if(condstep.DateParameter === 'Preset Options'){
                    let startdate = ''
                    let enddate = ''
                    let pass = false
                        if(condstep.Parameter === '===' || condstep.Parameter === '!=='){//BOTH START AND END APPLIES
                            startdate = this[condstep.Value.split(' ').join('')].Start
                            enddate = this[condstep.Value.split(' ').join('')].End
                        }
                        else if(condstep.Parameter === 'Before' ){//NO END DATE -  ONLY SOONER THAN START  
                            startdate = this[condstep.Value.split(' ').join('')].Start                   
                        }
                        else if(condstep.Parameter === 'On or Before'){//NO START DATE - ONLY SOONER THAN END
                            enddate = this[condstep.Value.split(' ').join('')].End
                        }
                        else if(condstep.Parameter === 'After'){//NO START DATE - ONLY LATER THAN END
                            enddate = this[condstep.Value.split(' ').join('')].End                   
                        }
                        else if(condstep.Parameter === 'On or After' ){//NO END DATE - ONLY LATER THAN START
                            startdate = this[condstep.Value.split(' ').join('')].Start
                        }
                    
                    //console.log('startdate',startdate,'enddate',enddate,'valuecheck',valuecheck)
                    if(startdate){
                        startdate = startdate.getTime()
                    }
                    if(enddate){
                        enddate = enddate.getTime()
                    }     
                    
                        if(startdate && enddate && condstep.Parameter === '!=='){//it must be between teh dates
                            pass = valuecheck.getTime() > enddate || valuecheck.getTime() < startdate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime() || typeof record[fieldcheck.Name] === 'undefined' || pass
                            }
                        }
                        else if(startdate && condstep.Parameter === 'Before'){//it must be before
                            pass = valuecheck.getTime() < startdate
                            if(condstep.IncludeUndefined){                              
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(enddate && condstep.Parameter === 'On or Before'){//it must be before
                            pass = valuecheck.getTime() <= enddate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(startdate && condstep.Parameter === 'On or After'){//it must be after
                            pass = valuecheck.getTime() >= startdate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }
                        else if(enddate && condstep.Parameter === 'After'){//it must be after
                            pass = valuecheck.getTime() > enddate
                            if(condstep.IncludeUndefined){
                              pass = valuecheck.getTime() !== valuecheck.getTime()
                            }
                        }    
                        //console.log(condstep.IncludeUndefined,valuecheck.getTime() !== valuecheck.getTime(),pass)                     
                        if(pass){
                            //now do what you do when passed
                            condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value
                            condstep.Pass = true
                            condstep.icon = 'mdi-check'
                            condstep.Color = 'green'
                            condstep.CheckValue = valuecheck
                        }
                        else{
                            //did not pass now get going
                             condstep.Pass = false
                                condstep.icon = 'mdi-cancel'
                                condstep.Color = 'red'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                                condstep.CheckValue = valuecheck
                        }
               
                }
            }
            else{
                //undefined date, definitely do not pass
                 condstep.Pass = false
                    condstep.icon = 'mdi-cancel'
                    condstep.Color = 'red'
                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value                                
                    condstep.CheckValue = valuecheck
            }
            return condstep
        },
        PassCheck(Checks,record){
            let FinalChecks = JSON.parse(JSON.stringify(Checks))
            return FinalChecks.map(step => {
                let condstep = Object.assign({},step)
                //console.log(condstep)
                let fieldcheck = condstep.FieldtoCheck
                
                let checkrecord = ''
                let valuecheck = ''                
                if(condstep.Record && condstep.Record.Relationship === 'Lookup Field Build'){
                    checkrecord = record[condstep.Record.RelationshipField]
                }
                else{
                    checkrecord = record
                }
                let value = checkrecord[fieldcheck.Name]
                //console.log(fieldcheck,checkrecord,value)
                if(fieldcheck.Type === 'Lookup' && value){
                    valuecheck = value[fieldcheck.LookupFieldName]
                }
                else if(fieldcheck.Type === 'Option Set' && value){
                    valuecheck = value.Name
                }
                else if(condstep.FieldtoCheck.Type === 'Option Set' && !value){
                    valuecheck = 'No Value in '+fieldcheck.Name
                }
                if(fieldcheck.Type === 'Lookup'){
                    //weird we seem to need reocrd not checkrecord so yeahh
                    condstep = this.LookupPass(condstep,fieldcheck,checkrecord)
                    if(condstep.LookupParameter === 'Preset Options'){
                        //I think this means, all is done, preset options where done in lookuppass
                    }
                    else{
                        //WOULD THIS BE checkrecord OR record?
                      //condstep = this.ParamsPass(condstep,fieldcheck,checkrecord,valuecheck)
                    }
                   
                }
                else if(fieldcheck.Type === 'Option Set'){
                    condstep = this.OptionSetPass(condstep,fieldcheck,checkrecord)
                    //NOW OPTION SET ALSO NO NEED PARAMSPASScondstep = this.ParamsPass(condstep,fieldcheck,checkrecord,valuecheck)
                }
                else if(fieldcheck.Type === 'Boolean'){
                    condstep = this.BooleanPass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else if(fieldcheck.Type === 'Number Field' || fieldcheck.Type === 'Calculated Field' || fieldcheck.Type === 'Rollup Calculation'){
                    condstep = this.NumberPass(condstep,fieldcheck,checkrecord)
                    //NOT NEEDED, NR PASS DOES ALL condstep = this.ParamsPass(condstep,fieldcheck,record)
                }
                else if(fieldcheck.Type === 'Date'){
                    condstep = this.DatePass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else if(fieldcheck.Type === 'Checklist'){
                    condstep = this.ChecklistPass(condstep,fieldcheck,checkrecord)
                    //NOPE BOOLEAN DOES IT ALL condstep = this.ParamsPass(condstep,fieldcheck,checkrecord)
                }
                else{
                    condstep = this.ParamsPass(condstep,fieldcheck,checkrecord,valuecheck)
                }
                //console.log(condstep)
                return condstep
            })
        },
    TestFilter(){        
        let data = this.Data.map(record => {
            let recordobj = Object.assign(record)
            return recordobj
        })
        data = data.map(recordobj => {
            let record = Object.assign({},recordobj)      
            let Checks = JSON.parse(JSON.stringify(this.FindCond.Checks)) 
            record.Passed = this.PassCheck(Checks,record).filter(check => { 
                //console.log(record[this.CurrentEntity.Primary_Field_Name],'check.Pass',check.Pass)
                return check.Pass
            })
            record.Unpassed = this.PassCheck(Checks,record).filter(check => { 
                //console.log(record[this.CurrentEntity.Primary_Field_Name],'check.Pass',check.Pass)
                return !check.Pass
            })
             //console.log(record[this.CurrentEntity.Primary_Field_Name],'Passed',record.Passed,'Failed',record.Unpassed,Checks)
            return record
        })
        let passedrecords = data.filter(item => {
            return item.Unpassed.length === 0
        })
        let failedrecords = data.filter(item => {
            return item.Unpassed.length > 0
        })
        console.log('passedrecords',passedrecords,'failedrecords',failedrecords,this.FindCond.Checks)
    },
    SaveAdvancedFind(){
        this.$emit('SaveAdvancedFind',this.FindCond.Checks)
    },
    RecordFieldFilter (item, queryText, itemText) {
        console.log(itemText)
          const DisplayName = item.DisplayName.toLowerCase()
          const Type = item.Type.toLowerCase()
          const searchText = queryText.toLowerCase()
          return DisplayName.indexOf(searchText) > -1 || Type.indexOf(searchText) > -1
    },
      
        AddConditionCheck(child,type){
       if(typeof child.Checks === 'undefined'){
        child.Checks = []
        let stepnumberstring = child.CheckNumber+'.1'
        // console.log(stepnumberstring)
        let stepnumber = Number(stepnumberstring)
        // console.log(stepnumber)
        let NewStep = {
        Name: 'Name',
        CheckNumber: stepnumberstring,
        Action: {FieldtoCheck: {}},
      }
      let parentindex = this.EditedAdvancedFind.Conditions.indexOf(child)
     
      child.Checks.push(NewStep)
      }
      else{
        // console.log(child)
        let stepsuffix = child.Checks.length-1+2
      let stepnumberstring = child.CheckNumber+'.'+stepsuffix
      // console.log(stepnumberstring)
      let stepnumber = Number(stepnumberstring)
      // console.log(stepnumber)
      let NewStep = {
        Name: 'Name',
        CheckNumber: stepnumberstring,
        Action: {FieldtoCheck: {}},
      }
      let parentindex = this.EditedAdvancedFind.Conditions.indexOf(child)
     
      child.Checks.push(NewStep)
      }
      
      // console.log(this.EditedAdvancedFind.Conditions)
    },
            CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
    }
}
</script>

<style>

</style>
