<template>
  <v-main>
    <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[POPDFFunctionData]"		:PassedFunction="POPDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
    <v-dialog style="z-index:10 !important;" max-width="800" v-model="NEWSOInvoiceDialog" v-if="OfficialSelectedSalesOrder">
      	
      <v-card id="NewPOElement">
        <v-card-title class="elevation-3 recordtoolbar white--text justify-center">New Invoice Review
        </v-card-title>
        <v-card-text>
        <v-card-text>
          <v-row class="justify-space-between"
            no-gutters
          >
            <v-col cols="6" style="align-self: center;"
            >
             <v-img width="80%"             
                contain
                src="@/assets/logo.png">
              </v-img>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>
                    {{SelectedWarehouse.Company_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>              
             <v-list-item dense v-html="ComputedSOInvoiceObject.PO_Bill_To_Address_Text">
              </v-list-item>
            </v-col>
            <v-col cols="6" style="margin-top: 30px;">
               <v-card-title class="mediumoverline"> Invoice Number here
                  </v-card-title>
                <v-list class="background" style="margin-top: 30px;">
                 
                   <v-list-item dense v-for="field in ComputedPORightHeaderFields" :key="field.itemObjKey">
                      <v-list-item-content  :style="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address' ? 'height: 350px;padding-left: 5px;' : 'height: 100%;padding-left: 5px;'">
                          {{field.DisplayName}}
                      </v-list-item-content>
                      <!-- <v-divider></v-divider> -->
                      <v-list-item-content  style="padding-left: 5px;justify-self: end;" v-if="field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'">
                       
                      <FieldViewerComponent  :field="field" :Record="ComputedSOInvoiceObject"
                      />
                      </v-list-item-content>
                      <v-list-item-content class="background" v-if="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address'">
                         
                    <v-list class="transparent mx-4" outlined>
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="configtext--text">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="datatext--text">
                                {{ComputedSOInvoiceObject[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                      </v-list-item-content>
                      
                  </v-list-item>
                </v-list>
            </v-col>
          </v-row>
        
        </v-card-text>
        <v-card-text>
           <v-row v-if="ComputedSOInvoiceObject.Client"
            no-gutters
          >
            <v-col>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ComputedSOInvoiceObject.Customer_Contact}}
                  </v-list-item-subtitle>
                  <v-list-item-title style="font-size: 18px;">
                    {{ComputedSOInvoiceObject.Customer_Company}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- {{SelectedSupplier.Invoice_Address_Country_Region}} -->
             <v-list-item dense v-html="ComputedSOInvoiceObject.SO_Delivery_Address_Text">
              </v-list-item>
            </v-col>
            <v-col >
            
            </v-col>
           </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <!-- hide-default-footer -->
        <!-- <v-card-text>
          <span  v-html="ComputedNewPOLineItemsTable">
          </span>
        </v-card-text> -->
        <v-card-text style="padding:30px;border: 1px solid grey;">
          <v-form ref="polineitemsform">
          <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="POLineHeadersforTable"
      :items="ComputedNewPOLineItems"
      
     
     class="transparent"
      >
      <template v-slot:[`item.Qty`]="{ item }">
        <v-text-field readonly style="font-size:13px;" :rules="PRQuantityRules(item)" v-model.number="item.Qty"  type="number"/>
      </template>
      <template v-slot:[`item.Price`]="{ item }">
        <v-text-field :readonly="!item.Last_Quoted_Price" style="font-size:13px;" :value="CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)" v-model.number="item.Price" :label="item.Last_Quoted_Price ? CurrencyFormatter(item.Last_Quoted_Price,$store.state.DefaultCurrency.Currency)+' (Q)' : ''"  type="number"/>
      </template>
      <template v-slot:[`item.Created_On`]="{ item }">
        {{item.Created_On.toDateString()}}
         <!-- <v-menu 
            v-model="item.Delivery_DateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            >
            <template v-slot:activator="{ on }">
                <v-text-field class="smallinput"
                label="Delivery Date"
                prepend-icon="mdi-calendar-month"
                readonly  :rules="[]"
                v-on="on"
                v-model="item.Delivery_Date_Input"
                ></v-text-field>
                </template>
            <v-date-picker  v-model="item.Delivery_Date_Input"
                    @input="item.Delivery_DateMenu = false"></v-date-picker>
            </v-menu> -->
      </template>
      <template v-slot:[`item.Sub_Total`]="{ item }">
         {{CurrencyFormatter(item.Sub_Total,$store.state.DefaultCurrency.Currency)}}
      </template>
          </v-data-table>
          </v-form>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-text>
           <v-row
            no-gutters
            style="padding-top: 10px;"
          >
            <v-col cols="6">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Notes
                  </v-list-item-subtitle>
                  <span class="caption" v-html="ComputedSOInvoiceObject.Footer_Note">
                  </span>
                </v-list-item-content>
              </v-list-item>
           
            </v-col>
            <v-col cols="6">
              <v-list-item  dense>
                <v-list-item-content>
                <v-list-item-subtitle>
                 SubTotal
                </v-list-item-subtitle>
                </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(ComputedSOInvoiceObject.Sub_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Discount ({{SelectedSupplier.Default_Discount}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(DiscountValue,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="ComputedSOInvoiceObject.Delivery">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Freight
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(ShippingTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="SelectedBusinessUnit.Tax_Percentage">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Tax ({{SelectedBusinessUnit.Tax_Percentage}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(TaxTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="border: 2px solid black;">
                 <v-list-item-content>
                <v-list-item-title>
                  Total:
                </v-list-item-title>
                 </v-list-item-content>
                <v-list-item-content>
                  {{CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
            </v-col>
           </v-row>
        </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelProcessNewSOInvoice()" dark color="warning">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
           <v-btn dark class="blue" @click="DownloadPO(true)">
            <v-icon>mdi-download</v-icon>Download
          </v-btn>
          <v-btn dark class="success" @click="ProcessPO()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-card class="transparent" style="margin-top:60px;" flat tile>
         <!-- <v-btn @click="TestGetGLObject()">
           TestGetGLObject
         </v-btn> -->
          <v-card-title  :class="!MiniView ? 'mediumoverline recordtoolbar white--text' : 'subtleoverline recordtoolbar white--text'">
              <v-icon :size="MiniView ? 30 : 45" dark>mdi-cube-send</v-icon>Dispatching <v-spacer></v-spacer> {{ActiveWarehouse.Name}}
          </v-card-title>
      <v-layout class="justify-center" row style="margin-top:60px;">
     <v-flex lg11 md11>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       <v-toolbar-title>{{ActiveEntity ? ActiveEntity.Warehouse_Dispatch_After.Name : 'Dispatching'}} WIP</v-toolbar-title>
      
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        Select SO
       > Create Invoice
        <v-spacer></v-spacer>
        <v-text-field 
          v-model="prsearch"
          append-icon="mdi-file-find-outline"
          label="Search PR"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="prsearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
          <!-- <v-dialog v-model="WarehouseDialog" width="400">
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="recordtoolbar white--text">
                New Warehouse
              </v-card-title>
              <v-card-text>
                <FieldsForm :FormFields="OpenPRHeaders" :Validating="Validating" :DataTableView="true" @save="SaveWarehouse" :DataObject="editedWarehouse" :tab="{}" />
               
              </v-card-text>
               <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="CloseWarehouseDialog()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
     </v-toolbar>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       
       <v-list-item dense>
         <v-list-item-content>
            <v-select :items="SelectableSalesOrders" @change="ClearSelectedPrLineItems()" clearable item-text="id" return-object label="Sales Order" v-model="SelectedSalesOrder"
       />
         </v-list-item-content>
         <!-- <v-list-item-content>
            <v-select :items="SelectableSuppliers" @change="ClearSelectedPrLineItems(),CheckForSupplier()" clearable item-text="Supplier_Name" return-object label="Supplier" v-model="SelectedSupplier"
       />
         </v-list-item-content> -->
         <v-list-item-action>
          <v-btn :disabled="!EnableSelect || SelectedTransactionLineItems.length === 0" @click="PrepareInvoiceSOObject()" >Create Invoice</v-btn>
         </v-list-item-action>
       </v-list-item>      
     </v-toolbar>
     </v-flex>
   </v-layout>
    <v-layout class="justify-center" row>
     <v-flex lg10 md10>
       <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="OpenPRHeaders"
      :items="OpenPRsSearched"
      :show-select="EnableSelect"      
      item-key="id"
      v-model="SelectedTransactionLineItems"
     
     :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
      >
       <!-- <template v-slot:[`item.Warehouse.Name`]="{ item }">
        <v-btn outlined fab small :to="'/Warehouse/'+item.Warehouse.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.Warehouse.Name}}
      </template> -->
      <template v-slot:[`item.StockObj.Item_Name`]="{ item }">
        <v-btn outlined fab small :to="'/'+item.StockObj.single.split(' ').join('')+'/'+item.StockObj.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.StockObj.Item_Name}}
      </template>
      <template v-slot:[`item.Sales_Order.Name`]="{ item }">
        <v-btn outlined fab small :to="item.Sales_Order.Path ? item.Sales_Order.Path : ''" class="elevation-6 accent--text"><v-icon small >mdi-cash-register</v-icon></v-btn>
        {{item.Sales_Order.Name}}
      </template>
      <template v-slot:[`item.Transaction`]="{ item }">
        <v-btn outlined fab small  @click="ViewRouteQuery(item.Transaction)" class="elevation-6 accent--text"><v-icon small >mdi-cash-register</v-icon></v-btn>
        {{item.Transaction.Name}}
      </template>
      
      <template v-slot:[`item.Supplier.Supplier_Name`]="{ item }">
        <v-btn outlined fab small :to="'/StoreSupplier/'+item.Supplier.id" class="elevation-6 accent--text"><v-icon small >mdi-factory</v-icon></v-btn>
        {{item.Supplier.Supplier_Name}}
      </template>
       <!-- <template v-slot:[`item.actions`]="{ item }">
     <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item.CanEdit">
       
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item.CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>         
      </template>-->
       </v-data-table>
      </v-flex>
  </v-layout>
      </v-card>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
export default {
    props: ['System','SystemEntities'],
    components: {FieldViewerComponent,PDFExporter},
    data() {
        return {
            SOLineItems: [],
          NewInvoice_Number: '',
          PDFDownloadOnly: false,
          ExportingtoPDFDialog: false,
          rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
          },
          Bill_To_Address: {Name: 'Bill_To_Address',FieldBreakdown: []},
          Delivery_Address: {Name: 'Delivery_Address',FieldBreakdown: []},
          Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
          Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
          PORightHeaderFields: [
            {Name: 'Created_On',DisplayName: 'Invoice Date',Type: 'Date'},
            {id: 'Customer_ID',Name: 'Customer_ID',DisplayName: 'Customer ID',Type: 'Single Line Text'},
          ],
          NewPOObject: {Supplier: {},Footer_Note: `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`},
          DefaultNewPOObject: {Supplier: {},Footer_Note: `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`},
          NewPOLineItems: [],
          NEWSOInvoiceDialog: false,
            prsearch: '',
            POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Warehouse',Type: 'Lookup',LookupFieldName: 'Name',propvalue: 'Warehouse',value: 'Warehouse.Name',class: 'overline',TableHidden: true},
              {text: 'Item',propvalue: 'StockObj',value: 'StockObj.Item_Name',Type: 'Lookup',LookupFieldName: 'Item_Name',class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Created Date',propvalue: 'Created_On',value: 'Created_On',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',propvalue: 'Sales_Order',value: 'Sales_Order.Name',class: 'overline',TableHidden: true},
            ],
            OpenPRHeaders: [
              {text: 'Warehouse',value: 'Warehouse.Name',class: 'overline'},
              {text: 'Stock Obj',value: 'StockObj.Item_Name',class: 'overline'},
              {text: 'Qty',value: 'Qty',class: 'overline'},
            //   {text: 'Price',value: 'Price',class: 'overline'},
            //   {text: 'Supplier',value: 'Supplier.Supplier_Name',class: 'overline'},
            //   {text: 'Outstanding Qty',value: 'Outstanding_Qty',class: 'overline'},
              {text: 'Sales Order',value: 'Sales_Order.Name',class: 'overline'},
              {text: 'Transaction',value: 'Transaction',class: 'overline'}
            ],
            TransactionalLineItems: [],
            SelectedTransactionLineItems: [],
            SelectedSupplier: '',
            OrderforDelivery: true,
            PDFExportFunctionData: '',
            DefaultTransactionPath: '',
            LinesRef: '',
            SelectedSalesOrder: '',
            OfficialSelectedSalesOrder: '',
            SelectedBillingAccount: '',
        }
    },
    watch: {
        SelectedSalesOrder(v) {
            if (v) {
              this.OfficialSelectedSalesOrder = this.SelectedSalesOrder.Sales_OrderObj
              console.log(this.SelectedSalesOrder,this.OfficialSelectedSalesOrder)
                if(this.OfficialSelectedSalesOrder.BillingAccount){
                   this.GetSoBillingAccount(); 
                }
                this.GetSalesOrderLines();
                // setTimeout(() => {
                //     this.SelectedPODialog = true;
                // }, 1000);
            }
            else{
                this.SelectedBillingAccount = ''
            }
        },
        WarehouseApp(v){
            if(v){
                if(!this.ActiveSuiteApp){
                this.$store.commit('setActiveSuiteApp',this.WarehouseApp)   
                }
            }
        },
        ActiveWarehouse(value){
          if(value){
          this.SelectedWarehouse = value
          }
      },
    },
    computed:{
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        WarehouseApp(){           
            return this.AppsDataBase.find(obj => obj.id === this.ViewWarehouse.Primary_Appid)
        },
        ViewWarehouse(){
          return this.WarehousesArray.find(obj => obj.id === this.$route.params.id)
        },        
        ActiveWarehouse(){
            return this.$store.state.ActiveWarehouse
        },        
        WarehousesArray(){
            return this.$store.state.WarehousesArray
        },
        LookupObjSelectedSalesOrder(){
          let obj = Object.assign({},this.SelectedSalesOrder)
          delete obj.Sales_OrderObj
          delete obj.PrimaryName
          delete obj.SubColRecordid
          delete obj.PrimaryName
          return obj
        },
        SelectableSalesOrders(){
            return this.TransactionalLineItems.map(item => {
              let newobj = Object.assign({},item.LookupObj)
              newobj.Sales_OrderObj = item.Sales_OrderObj
              return newobj
            })
            .map(item => {
                item.PrimaryName = item[item.identifier]
                return item
            })
        },
        
        ActiveEntity(){
            let entity = this.SystemEntities.find(obj => obj.id === this.$route.params.slug)
            console.log('entity',entity)
            return entity
        },
      PDFPages(){
        return this.POPDFFunctionData.Pages
      },
      POPDFFunctionData(){
        let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //ComputedNewPOLineItemsTable
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'ComputedNewPOLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.ComputedNewPOLineItemsTable
        newelemnt.Justify = 'center'
        if(this.ComputedNewPOLineItems.length <= 3){
          newelemnt.PaddingTop = 425
        }
        else if(this.ComputedNewPOLineItems.length <= 6){
          newelemnt.PaddingTop = 400
        }
        else if(this.ComputedNewPOLineItems.length <= 9){
          newelemnt.PaddingTop = 375
        }
        else if(this.ComputedNewPOLineItems.length <= 12){
          newelemnt.PaddingTop = 350
        }
        else if(this.ComputedNewPOLineItems.length <= 15){
          newelemnt.PaddingTop = 325
        }
        else if(this.ComputedNewPOLineItems.length <= 18){
          newelemnt.PaddingTop = 300
        }
        else {
          newelemnt.PaddingTop = 300
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata
      },
      PDFPOData(){
        let sampleobj = JSON.parse(JSON.stringify(this.ComputedSOInvoiceObject))
        console.log('sampleobj',sampleobj.Created_On)
        sampleobj.SubCollections = []
        // this.PDFPages.map(page => {
          // if(page.Elements){
          //   page.Elements.map(elmnt => {
          //     //console.log('elmnt',elmnt)  
          //     if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type !== 'Option Set' && elmnt.DataField.Type !== 'Lookup' && elmnt.DataField.Type !== 'Radio Group'){
          //       sampleobj[elmnt.DataField.Name] = elmnt.DataField.DisplayName
          //     }
          //     else if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type === 'Option Set'){
          //       sampleobj[elmnt.DataField.Name] = {ID: 1000001, Name: elmnt.DataField.DisplayName}
          //     }
          //     //console.log('sampleobj',sampleobj)
          //   })
          // }
        // })
        return sampleobj
      },
      POLineHeadersforTable(){
        return this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
      },
      ComputedNewPOLineItemsTable(){
        let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;padding:5px;border: 1px solid grey;">
                    <tr>`
        this.POLineHeadersforTable.map(head => {
          html = html+`
          <th class="overline" style="text-align: left;">`+head.text+`</th>`
        })
        html = html+`
        </tr>`
        this.ComputedNewPOLineItems.map(lineitem => {
          html = html+`
          <tr class="detailslistoutline">`
          this.POLineHeadersforTable.map((head,headnindex) => {
            //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
            let value = lineitem[head.propvalue]
            if(head.Type === 'Number Field' && head.IsCurrency){
              value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
            }
            else if(head.Type === 'Date' && !head.HasTimeInput){
              value = value.toDateString()
            }
            else if(head.Type === 'Lookup'){
              value = value[head.LookupFieldName]
            }
            html = html+`
            <td style="text-align: left;">`+value+`</td>`
            if(headnindex-1+2 === headerslength){
              html = html+`
              </tr>`
            }
          })
        })
        html = html+`
        </table>`
        return html
      },
      ComputedNewPOLineItems(){
        let arr = this.NewPOLineItems.map((item,index) => {
        //   if(typeof item.Delivery_DateMenu === 'undefined'){
        //     item.Delivery_DateMenu = false
        //   }
          if(item.Created_On.toDate){
              item.Created_On = item.Created_On.toDate()
          }
        //   if(typeof item.Delivery_Date_Input === 'undefined'){
        //       let date = item.Created_On
        //       if(date.toDate){
        //           date = date.toDate()
        //       }
        //     item.Delivery_Date_Input = date.toISOString().substring(0,10)
                     
        //   }
          
          item.Nr = index-1+2
          return item
        }).map(item => {
          //item.Created_On = new Date(item.Delivery_Date_Input)    
          item.Sub_Total = Number(item.Qty)*Number(item.Price)
          return item
        })
        return arr
      },
      CollectionorDeliveryDisabled(){
        let disabled = false
        if(this.SelectedSupplier.Collection){
          disabled = true
        }
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(typeof this.ComputedSOInvoiceObject[newname] === 'undefined'){
            disabled = true
          }
        })
        return disabled
      },
      POSubTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      InvoiceFinalPaymentDate(){
         let d = new Date()
         d.setDate(d.getDate() + 7);
        return d
      },
      ComputedSOLineItems(){
        return this.SelectedTransactionLineItems.map(translineitem => {
          let solineobj = translineitem.LookupObj
          solineobj.Sales_Orderid = solineobj.id
          delete solineobj.id
          solineobj.Purchase_Requisitionid = translineitem.Transaction_Docid
          solineobj.PRLine_Itemid = translineitem.id
          solineobj.Qty = translineitem.Qty
          solineobj.Outstanding_Qty = translineitem.Outstanding_Qty
          solineobj.Open_Allocations = translineitem.Open_Allocations
          solineobj.Created_On = translineitem.Created_On
          solineobj.Modified_On = translineitem.Modified_On
          solineobj.Warehouse = translineitem.Warehouse
          solineobj.Warehouseid = translineitem.Warehouseid
          solineobj.StockObj = translineitem.StockObj

          return solineobj
        })
      },
      PaidinFull(){
        let amntpaid = 0
        let total = 0
        if(this.OfficialSelectedSalesOrder && this.OfficialSelectedSalesOrder.id){
          amntpaid = this.OfficialSelectedSalesOrder.Amount_Paid.toFixed(2)
          total = this.OfficialSelectedSalesOrder.Grand_Total.toFixed(2)
        }
        return this.OfficialSelectedSalesOrder && this.OfficialSelectedSalesOrder.id ? Number(amntpaid) >= Number(total) : ''
        },
      ComputedSOInvoiceObject(){
        let POObject = {}
        if(this.NEWSOInvoiceDialog){
          if(this.OrderforDelivery){
          POObject.Delivery = true
        }
        else{
          POObject.Delivery = false
        }
        POObject.Created_On = new Date()
        POObject.Client = ''
        POObject.Customer_Company = ''
        POObject.Customer_Contact = ''
        POObject.Customer_ID = ''
        if(this.OfficialSelectedSalesOrder && this.OfficialSelectedSalesOrder.User){
          POObject.Customer_Contact = this.OfficialSelectedSalesOrder.User.Full_Name
          POObject.Client = this.OfficialSelectedSalesOrder.User
          POObject.Clientid = this.OfficialSelectedSalesOrder.User.id
        }
        if(this.SelectedBillingAccount){
          POObject.Customer_Company = this.SelectedBillingAccount.Account_Name
          POObject.Customer_ID = this.SelectedBillingAccount.Customer_ID
        }
        let capass = true
        POObject.Invoice_Number = 'Invoice (TBD) #0000001'
        if(this.NewInvoice_Number){
          POObject.Invoice_Number = this.NewInvoice_Number
        }
        POObject.Progress = {ID: 1000001,Name: 'Draft'}
        if(this.PaidinFull){
          //let's hope for now it would always be due to an ActiveQuoteid prop, not another reason for being "Paid"
          POObject.Advance_Payment_id = this.OfficialSelectedSalesOrder.Active_Quoteid
          POObject.Progress = {ID: 1000004,Name: 'Paid'}
          POObject.Amount_Paid = Number(this.GrandTotal)
        }
        POObject.RecordPrimaryFieldName = 'Invoice_Number'
        POObject.Company_Name = this.SelectedWarehouse.Company_Name
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(this.SelectedSupplier[brd.Name]){
            POObject[newname] = this.SelectedSupplier[brd.Name]
          }
          else{
            capass = false
          }
        })
        let dapass = true
        this.Delivery_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Delivery_Address_').join('SO_Delivery_Address_')
          if(this.OfficialSelectedSalesOrder[brd.Name]){
            POObject[newname] = this.OfficialSelectedSalesOrder[brd.Name]
          }
          else{
            dapass = false
          }
        })
        let btapass = true
        this.Bill_To_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Bill_To_Address_').join('PO_Bill_To_Address_')
          let brdname = brd.Name.split('Bill_To_Address_').join('Address_')
          if(this.SelectedBusinessUnit[brdname]){
            POObject[newname] = this.SelectedBusinessUnit[brdname]
          }
          else{
            btapass = false
          }
        })
        POObject.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Created_Byid = this.userLoggedIn.id
        POObject.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Modified_Byid = this.userLoggedIn.id
        POObject.Created_On = new Date()
        POObject.Modified_On = new Date()
        POObject.BillingAccount = {Client_Reference: this.SelectedBillingAccount.Client_Reference,Customer_ID: this.SelectedBillingAccount.Customer_ID,id: this.SelectedBillingAccount.id}
        POObject.BillingAccountid = this.SelectedBillingAccount.id
        if(dapass){
        POObject.SO_Delivery_Address_Text = ``+POObject.SO_Delivery_Address_Street_Number+` `+POObject.SO_Delivery_Address_Street_Name+`, `+POObject.SO_Delivery_Address_Suburb+`, `+POObject.SO_Delivery_Address_Postal_Code+`<br>
        `+POObject.SO_Delivery_Address_City+`, `+POObject.SO_Delivery_Address_State_Province+`,<br>
        `+POObject.SO_Delivery_Address_Country_Region  
        }
        if(btapass){        
        POObject.PO_Bill_To_Address_Text = ``+this.SelectedWarehouse.Bill_To_Address_Street_Number+` `+this.SelectedWarehouse.Bill_To_Address_Street_Name+`, `+this.SelectedWarehouse.Bill_To_Address_Suburb+`, `+this.SelectedWarehouse.Bill_To_Address_Postal_Code+`<br>
        `+this.SelectedWarehouse.Bill_To_Address_City+`, `+this.SelectedWarehouse.Bill_To_Address_State_Province+`,<br>
        `+this.SelectedWarehouse.Bill_To_Address_Country_Region
        }
        POObject.Warehouse = {Name: this.SelectedWarehouse.Name,id: this.SelectedWarehouse.id}
        POObject.Warehouseid = this.SelectedWarehouse.id
        POObject.Business_Unit = {Name: this.SelectedWarehouse.Business_Unit.Name, id: this.SelectedWarehouse.Business_Unitid}
        POObject.Business_Unitid = this.SelectedWarehouse.Business_Unitid
        POObject.Footer_Note = `<p>The amount of the Invoice is to be paid in full, on a "COD" basis. Payment to be made to the agreed Account.</p>`
        POObject.Sub_Total = this.POSubTotal  
        POObject.Invoice_Total = this.VatExclusiveInvoiceTotal
        //POObject.Supplier_Account_Type = this.SelectedSupplier.Billing_Type
        //alert(this.SelectedSupplier.Billing_Type.Name)
        POObject.Payment_Terms = ''
        if(this.SelectedSupplier.Billing_Type && this.SelectedSupplier.Billing_Type.Name === 'COD'){
          POObject.Payment_Terms = 'COD'
          POObject.Payment_Period = 0
        }
        else if(this.SelectedSupplier.Billing_Type && this.SelectedSupplier.Billing_Type.Name === 'Credit'){          
          POObject.Payment_Period = this.SelectedSupplier.Days_for_Payment
          POObject.Payment_Terms = 'Net '+this.SelectedSupplier.Days_for_Payment
        }
        //POObject.Supplier_Account_Type = this.SelectedSupplier.Billing_Type
        POObject.Shipping_Method = {ID: 1000001,Name: 'FOB'}
        POObject.Due_Date = this.InvoiceFinalPaymentDate
        POObject.Tax_Total = this.TaxTotal
        POObject.Tax_Percentage = this.TaxPercentage
        POObject.Discount_Total = this.DiscountValue
        POObject.Freight_Total = this.ShippingTotal
        if(typeof POObject.Delivery === ' undefined'){
          POObject.Delivery = !this.SelectedSupplier.Collection
        }
        POObject.Grand_Total = this.GrandTotal
        
        return POObject
        }
        else{
          return ''
        }
        
      },
      ComputedPORightHeaderFields(){
        return this.OrderforDelivery ? this.PORightHeaderFields : this.PORightHeaderFields.filter(field => {
          return field.Name !== 'Shipping_Method'
        })
      },
      GrandTotal(){
        let total = this.POSubTotal
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        if(this.TaxTotal !== 0){
          total = total+this.TaxTotal
        }
        return Number(total.toFixed(2))
      },
      VatExclusiveInvoiceTotal(){
        let total = this.POSubTotal
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        return Number(total.toFixed(2))
      },
      ShippingTotal(){
        if(this.SelectedSupplier.Collection){
          return 0
        }
        else{
          return 0
        }
      },
      COGSAvgTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.StockObj.Average_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      COGSLatestQuotedPriceTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.StockObj.Latest_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      COGSLatestPurchasePriceTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.StockObj.Latest_Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      SelectedBusinessUnit(){
        return this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.SelectedWarehouse.Business_Unitid)
      },
      TaxPercentage(){
        return this.SelectedBusinessUnit && typeof this.SelectedBusinessUnit.Tax_Percentage !== 'undefined' ? this.SelectedBusinessUnit.Tax_Percentage : 0
      },
      TaxTotal(){
        if(this.SelectedBusinessUnit && this.SelectedBusinessUnit.id){
          if(this.SelectedBusinessUnit.Tax_Percentage !== 0){            
            let total = this.POSubTotal*(this.SelectedBusinessUnit.Tax_Percentage/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      DiscountValue(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          if(typeof this.SelectedSupplier.Default_Discount !== 'undefined' && this.SelectedSupplier.Default_Discount !== 0){
            let total = this.POSubTotal*(this.SelectedSupplier.Default_Discount/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      TextSupplierInvoiceAddress(){        
        return this.SelectedSupplier && this.SelectedSupplier.id ? ``+this.SelectedSupplier.Invoice_Address_Street_Number+` `+this.SelectedSupplier.Invoice_Address_Street_Name+`, `+this.SelectedSupplier.Invoice_Address_Suburb+`, `+this.SelectedSupplier.Invoice_Address_Postal_Code+`<br>
        `+this.SelectedSupplier.Invoice_Address_City+`, `+this.SelectedSupplier.Invoice_Address_State_Province+`,<br>
        `+this.SelectedSupplier.Invoice_Address_Country_Region : ''
      },
      TextBillToAddress(){        
        return this.ComputedSOInvoiceObject? ``+this.ComputedSOInvoiceObject.Bill_To_Address_Street_Number+` `+this.ComputedSOInvoiceObject.Bill_To_Address_Street_Name+`, `+this.ComputedSOInvoiceObject.Bill_To_Address_Suburb+`, `+this.ComputedSOInvoiceObject.Bill_To_Address_Postal_Code+`<br>
        `+this.ComputedSOInvoiceObject.Bill_To_Address_City+`, `+this.ComputedSOInvoiceObject.Bill_To_Address_State_Province+`,<br>
        `+this.ComputedSOInvoiceObject.Bill_To_Address_Country_Region : ''
      },
      TextDeliveryAddress(){        
        return ``+this.ComputedSOInvoiceObject.PO_Delivery_Address_Street_Number+` `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Street_Name+`, `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Suburb+`, `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Postal_Code+`<br>
        `+this.ComputedSOInvoiceObject.PO_Delivery_Address_City+`, `+this.ComputedSOInvoiceObject.PO_Delivery_Address_State_Province+`,<br>
        `+this.ComputedSOInvoiceObject.PO_Delivery_Address_Country_Region
      },
      SelectableSuppliers(){
        if(this.SelectedWarehouse){
          return this.OpenPRsSearched.map(pr => {
            let supplobj = pr.Supplier
            return supplobj
          })
        }
        else{
          return []
        }
      },
      EnableSelect(){
        console.log(this.OfficialSelectedSalesOrder,this.SelectedSalesOrder)
        return this.OfficialSelectedSalesOrder && this.OfficialSelectedSalesOrder.id
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        OpenPRsSearched(){
          return this.OpenTransactionalLineItems.filter(pr => {
            if(this.OfficialSelectedSalesOrder){
              return pr.LookupObj && pr.LookupObj.id === this.OfficialSelectedSalesOrder.id
            }
            else{
              return pr
            }
          }).filter(pr => {
            if(this.SelectedSupplier){
              return pr.Supplierid === this.SelectedSupplier.id
            }
            else{
              return pr
            }
          })
        },
        OpenTransactionalLineItems(){
          return this.TransactionalLineItems.map(pr => {
            let so = {Name: ''}
            let today = new Date()
            if(pr.LookupObj){
              so.Name = pr.LookupObj[pr.LookupObj.identifier]
              so.Path = '/'+pr.LookupObj.single.split(' ').join('')+'/'+pr.LookupObj.id
            }
            pr.Sales_Order = so
            // if(!pr.Supplier){
            //   pr.Supplier = pr.Preferred_Supplier
            //   pr.Supplierid = pr.Preferred_Supplier.id
            // }
            // if(!pr.Price){
            //   pr.Price = pr.Last_Quoted_Price
            // }
            // if(pr.Purchase_Leadtime){
            //   pr.Delivery_Date = new Date(today.getFullYear(),today.getMonth(),today.getDate()+pr.Purchase_Leadtime)
            // }
            
            return pr
          })
        },
        StoreOrderEntity(){
            return this.ActiveEntity && this.ActiveEntity.Entity_Type.Name === 'Store Order'
        },
        WarehousesArray(){
          return this.$store.state.WarehousesArray
        },
        WarehouseStoreEntity(){
          return this.SelectedWarehouse.Store_Entity
        },
        SOEtntityCartCollection(){
          let subcol = ''
          if(this.WarehouseStoreEntity){
            this.ActiveEntity.SubCollections.map(busubcol => {
            let match = busubcol.LookupBuilds.find(obj => obj.id === this.WarehouseStoreEntity)
            if(match){
              subcol = busubcol
            }
          })
          }
          
          return subcol ? subcol.id : ''
        },
        SOLineItemsRef(){
            return this.SelectedSalesOrder ? db.collection(this.ActiveEntity.id.split('_').join('').toLowerCase()).doc(this.SelectedSalesOrder.id).collection(this.SOEtntityCartCollection) : ''
        },
    },
    created(){
      if(this.ActiveWarehouse){
        this.SelectedWarehouse = this.ActiveWarehouse
      }
      if(!this.ActiveSuiteApp && this.WarehouseApp){
         this.$store.commit('setActiveSuiteApp',this.WarehouseApp)   
        }
        if(!this.StoreOrderEntity){
            this.$router.push('/404')
        }
        else{
         this.GetPOPDFFunction()
        let items = ''
        if(this.ActiveEntity.Warehouse_Dispatch_After){
            items = this.ActiveEntity.Warehouse_Dispatch_After.Name
        }
        if(items && items === 'Picking'){
            this.DefaultTransactionPath = 'PickingSlips'
            this.LinesRef = 'PickingSlipsLine_Items'
        }
        if(items && items === 'Packing'){
            this.DefaultTransactionPath = 'PackingLists'
            this.LinesRef = 'PackingListsLine_Items'
        }
        if(this.LinesRef){
         this.GetTransactionalLineItems(this.LinesRef)
        
        this.PrepareAddressField('Bill_To_Address')
        this.PrepareAddressField('Delivery_Address')
        this.PrepareAddressField('Invoice_Address')
        this.PrepareAddressField('Collection_Address')   
        }
           
        }
      
    },
    methods:{
      
      TestGetGLObject(allocation){
        let vm = this
        const functions = firebase.functions();
          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let autopayload = {
            Docid: 'PickingSlips_Number',
            Data: {},
            VerificationArray: ['Warehouses',this.$route.params.id,'PickingSlips'],
            MustVerify: true,
            siteid: this.$store.state.ActiveSuiteid
          }
          console.log(autopayload)
          AssignAutoNumber(autopayload).then(result => {
            console.log(result)
          })
      },
        GetSalesOrderLines(){
            console.log('SOLineItemsRef',this.SOLineItemsRef)
            this.SOLineItemsRef.onSnapshot(res => {
                //alert(res.docs.length)
                const changes = res.docChanges();
                console.log(changes)
              changes.forEach(change => {
                  if (change.type === 'added') {
                      let prlineobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      this.SOLineItems.push(prlineobj)
                  }
                  if (change.type === 'modified') {
                      let prlineobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      let oncheck = this.SOLineItems.find(obj => obj.id === prlineobj.id)
                      if(oncheck){
                        let index = this.SOLineItems.indexOf(oncheck)
                        this.SOLineItems.splice(index, 1, prlineobj);
                      }
                  }
                  if (change.type === "removed") {                      

                      let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.SOLineItems.find(obj => obj.id === probj.id)
                      if(oncheck){
                        let index = this.SOLineItems.indexOf(oncheck)
                        this.SOLineItems.splice(index, 1);
                      }
                  }
              })
          })
        },
        GetSoBillingAccount(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.OfficialSelectedSalesOrder.BillingAccount.id).onSnapshot(snapshot => {
                let billingdata = snapshot.data()
                this.SelectedBillingAccount = {...billingdata,id: this.OfficialSelectedSalesOrder.BillingAccount.id}
            })
        },
      QuickAssignAutoNumberMethod(newstringprefix,nrstring,docdata,index){    
          return new Promise(function(resolve, reject) {
          let finalstring = ''
          let nrstring = '0'
            if(docdata.NumberingString){
              nrstring = docdata.NumberingString
            }      
          let newnr = index-1+2
          let newnrlength = newnr.toString().length
          let newstringcount = docdata.Digits-newnrlength
          for(let x = 0; x < newstringcount; x++){ 
            newstringprefix = newstringprefix+nrstring
            if(x-1+2 === newstringcount){
              finalstring = newstringprefix+newnr.toString()
              resolve({
                Last_Number: newnr,
                Last_Value: finalstring
              })
            }
          }
          })
        },
      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      PRQuantityRules(item){
        return  [ 
              v => !!v || "This field is required",
              v => ( v && v >= item.Min_Qty ) || "Qty should be no less "+item.Min_Qty,
          ]
      },
      DeactivateExportingtoPDFDialog(doc){        
        if(!this.PDFDownloadOnly){
         this.ProcessNewInvoice(doc) 
        }        
        this.ExportingtoPDFDialog = false
        this.PDFDownloadOnly = false
      },
      DownloadPO(downloadonly){
        this.ExportingtoPDFDialog = true
        this.PDFDownloadOnly = downloadonly
      },
      ProcessPO(){
        if(this.$refs.polineitemsform.validate()){
          let vm = this
          vm.$store.commit('setCustomProcessingDialog',true)
          vm.$store.commit('setCustomProcessingDialogText','Registering Invoice')
          this.PDFDownloadOnly = false
          const functions = firebase.functions();
          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let payload = {
            Docid: 'DispatchInstructions_Number',
            Data: {Invoice_Type: {ID: 1000001, Name: 'Store Order'}},
            siteid: this.$store.state.ActiveSuiteid
          }
          AssignAutoNumber(payload).then(result => {
          //console.log(result)
          //console.log(result.data)
          vm.NewInvoice_Number = result.data
          vm.$store.commit('setCustomProcessingDialogText','Creating PDF')
          vm.DownloadPO(vm.PDFDownloadOnly)
          })
          //AssignAutoNumber function to get new nr assign to "this.NewInvoice_Number"
          //THEN this.DownloadPO(this.PDFDownloadOnly)  
        }
        else{
          alert('Please check all the Order line values')
        }
        
      },
      
      ProcessNewInvoice(doc){
        let vm = this
        vm.$store.commit('setCustomProcessingDialogText','Uploading PDF')
        //console.log(this.NewPOLineItems,this.SelectedTransactionLineItems,this.ComputedSOInvoiceObject)
        let NewPOObject = this.ComputedSOInvoiceObject
        //so actually we need to get autonumber. But first let's create autonumber field, see it's logic and take it from there see if it can be used
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        //console.log(blobpdf,bloburl)
        let storagelocation = 'Sales_Invoices/'+vm.OfficialSelectedSalesOrder.id+'/'+NewPOObject.Invoice_Number
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(blobpdf);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Preparing Invoice')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: NewPOObject.Invoice_Number,
                            filename: NewPOObject.Invoice_Number
                          }

                          NewPOObject.Owner = vm.OfficialSelectedSalesOrder.Owner
                          NewPOObject.Ownerid = vm.OfficialSelectedSalesOrder.Ownerid
                          NewPOObject.COGS_Total = vm.COGSLatestQuotedPriceTotal
                          NewPOObject.Sub_Total = vm.POSubTotal
                          NewPOObject.Invoice_Total = vm.VatExclusiveInvoiceTotal
                          NewPOObject.Grand_Total = vm.GrandTotal
                          NewPOObject.Tax_Total = vm.TaxTotal
                          NewPOObject.Tax_Percentage = vm.TaxPercentage
                          NewPOObject.Latest_PDF_File = PDF_File
                          NewPOObject.LookupObj = vm.LookupObjSelectedSalesOrder
                          NewPOObject.ReferenceProp = 'Invoice_Number'
                          NewPOObject.Reference_Number = vm.SelectedSalesOrder.id
                          NewPOObject.Invoice_Date = new Date(),
                          NewPOObject.Invoice_Type = {ID: 1000001, Name: 'Store Order'}
                          delete NewPOObject.RecordPrimaryFieldName
                          console.log(NewPOObject,vm.ComputedNewPOLineItems,vm.SelectedTransactionLineItems,vm.ComputedSOLineItems)
                            vm.$store.commit('setCustomProcessingDialogText','Preparing PO Lines')
                            let finalpolines = vm.ComputedNewPOLineItems.map((polineitem,polineindex) => {
                                delete polineitem.TransactionalLineItems
                                polineitem.Progress = NewPOObject.Progress
                                polineitem.Modified_By = NewPOObject.Modified_By
                                polineitem.Modified_Byid = NewPOObject.Modified_Byid
                                polineitem.Created_By = NewPOObject.Modified_By
                                polineitem.Created_Byid = NewPOObject.Created_Byid
                                //polineitem.Transaction_Docid = NewPOObject.Invoice_Number
                                // polineitem.Delivered = 0
                                // polineitem.Received = 0
                                // polineitem.Returned = 0
                                // polineitem.Invoiced = 0
                              return {Data: polineitem}
                            })
                            vm.$store.commit('setCustomProcessingDialogText','Sending to Server')
                            let bulkoutboundpayload = {
                                    Collection: 'DispatchInstructions',
                                    Data: [],
                                    ParentObj: NewPOObject,
                                    Warehouse: NewPOObject.Warehouseid
                                  }
                                  bulkoutboundpayload.Data = finalpolines
                                  console.log('bulkoutboundpayload',bulkoutboundpayload)                               
                                  const functions = firebase.functions();
                                  const ConsumeBulkStockQty = functions.httpsCallable('ConsumeBulkStockQty');
                                  ConsumeBulkStockQty(bulkoutboundpayload).then(result => {
                                    vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+NewPOObject.Invoice_Number)
                                      setTimeout(() => {
                                        vm.$store.commit('setCustomProcessingDialog',false)
                                        setTimeout(() => {
                                          // /WarehouseTransaction/Z8WSyTNan523HzF3GOWk/INV-00000002?TransactionType=DispatchInstructions
                                            vm.$router.push('/WarehouseTransaction/'+vm.SelectedWarehouse.id+'/'+NewPOObject.Invoice_Number+'?TransactionType=DispatchInstructions')
                                          }, 500)
                                      }, 500);
                                  })
                          //  })
                          
                          //now I got the Po object, but I still need 1. PO Line items, and 2.SO Line items...
                        })
      },
      GetPOPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Sales_Invoices').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.PDFExportFunctionData = functiondata
        })
      },
      ToggleDialog(dialogname){					
      this[dialogname] = !this[dialogname]					
    },		
      RefreshDialog(){
        this.NEWSOInvoiceDialog = false
        setTimeout(() => {
          this.NEWSOInvoiceDialog = true
        }, 10);
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
      
      CancelProcessNewSOInvoice(){
        this.NEWSOInvoiceDialog = false
        this.NewPOLineItems = [] 
      },
      
      ClearSelectedPrLineItems(){
        this.SelectedTransactionLineItems = []
      },
      CheckForSupplier(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          this.GetSupplierInformation(this.SelectedSupplier.id)
        }
      },
      GetSupplierInformation(supplierid){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('storesuppliers').doc(supplierid).onSnapshot(snapshot => {
          let supplierdata = snapshot.data()
          console.log('supplierdata',supplierdata.Default_Discount,supplierdata.Tax_Percentage,this.Invoice_Address.FieldBreakdown)
          if(supplierdata){
            this.SelectedSupplier.Default_Delivery_Terms = supplierdata.Default_Delivery_Terms
            if(supplierdata.Default_Delivery_Terms && supplierdata.Default_Delivery_Terms.Name === 'Collection'){
              this.SelectedSupplier.Collection = true
              this.OrderforDelivery = false
            }
            this.SelectedSupplier.Billing_Type = supplierdata.Billing_Type
            this.SelectedSupplier.Days_for_Payment = supplierdata.Days_for_Payment
            
            if(typeof supplierdata.Tax_Percentage !== 'undefined'){
              this.SelectedSupplier.Tax_Percentage = Number(supplierdata.Tax_Percentage)
            }
            else{
              this.SelectedSupplier.Tax_Percentage = 0
            }
            if(typeof supplierdata.Default_Discount !== 'undefined'){
              this.SelectedSupplier.Default_Discount = Number(supplierdata.Default_Discount)
            }
             else{
              this.SelectedSupplier.Default_Discount = 0
            }
            this.Invoice_Address.FieldBreakdown.map(brd => {
              if(supplierdata[brd.Name]){
                this.SelectedSupplier[brd.Name] = supplierdata[brd.Name]
              }
            })
        this.Collection_Address.FieldBreakdown.map(brd => {
              //console.log(brd.Name,supplierdata[brd.Name],this.SelectedSupplier)
              if(supplierdata[brd.Name]){
                this.SelectedSupplier[brd.Name] = supplierdata[brd.Name]
              }
            })
            // this.PrepareInvoiceSOObject()
          }
          else{
            alert('Supplier not Found')
          }
          
        })
      },
      GetStockAverage(newobj){
          // let vm = this
          return new Promise(function(resolve, reject) {
            db.collection(newobj.StockObj.list.split('_').join('').split(' ').join('').toLowerCase()).doc(newobj.StockObj.id).onSnapshot(snapshot => {
              let stockdata = snapshot.data()
              newobj.StockObj.Average_Price = Number(stockdata.Average_Price)
              newobj.StockObj.Latest_Price = Number(stockdata.Latest_Price)
              resolve(newobj)
            })
          })
        },
      PrepareLineItems(){
        let vm = this
        let listlength = this.SelectedTransactionLineItems.length
        this.SelectedTransactionLineItems.map((translineitem,lineitemindex) => {
          let newobj = {}
          newobj.StockObj = Object.assign({},translineitem.StockObj)
          vm.GetStockAverage(newobj).then(resultobj => {
          resultobj.Warehouse = Object.assign({},translineitem.Warehouse)
          resultobj.Warehouseid = translineitem.Warehouse.id
          resultobj.Qty = translineitem.Qty
          resultobj.Min_Qty = translineitem.Qty
        //   resultobj.Supplier = {Supplier_Name: vm.SelectedSupplier.Supplier_Name,id: vm.SelectedSupplier.id}
        //   resultobj.Supplierid = vm.SelectedSupplier.id
          //resultobj.Last_Quoted_Price = translineitem.Last_Quoted_Price
          resultobj.Price = translineitem.Price
          // resultobj.Sub_Total = translineitem.Price*translineitem.Qty
        //   resultobj.BillingAccount = {Supplier_Name: vm.SelectedBillingAccount.Client_Reference,id: vm.SelectedBillingAccount.id}
        //   resultobj.BillingAccountid = vm.SelectedBillingAccount.id
          // resultobj.Purchase_Leadtime = translineitem.Purchase_Leadtime
          // resultobj.Delivery_Date = translineitem.Delivery_Date
          resultobj.TransactionalLineItems = [translineitem.id]
          resultobj.Created_On = translineitem.Created_On
          let oncheck = vm.NewPOLineItems.find(obj => obj.StockObj.id === translineitem.StockObj.id)
          if(!oncheck){
            vm.NewPOLineItems.push(resultobj)
          }
          else{
            oncheck.Qty = oncheck.Qty+translineitem.Qty
            oncheck.Min_Qty = oncheck.Qty
            oncheck.TransactionalLineItems = oncheck.TransactionalLineItems.concat(resultobj.TransactionalLineItems)
            // oncheck.Sub_Total = Number(oncheck.Sub_Total)+Number(resultobj.Sub_Total)
          }
          console.log(vm.SOLineItems,translineitem,resultobj)
          let solinematch = vm.SOLineItems.find(obj => obj.id === translineitem.LookupObj.SubColRecordid)
          if(solinematch){
              resultobj.Price = solinematch.Unit_Price
              //newobj.SalesOrderLineid = solinematch.id 
              
              //newobj.Order_Qty = solinematch.Qty
              console.log(solinematch)
          }
          //newobj.SalesOrderid = translineitem.LookupObj.id
              resultobj.BillingAccount = {Client_Reference: vm.SelectedBillingAccount.Client_Reference,Customer_ID: vm.SelectedBillingAccount.Customer_ID,id: vm.SelectedBillingAccount.id}
              resultobj.BillingAccountid = vm.SelectedBillingAccount.id
              resultobj.Preceding_Transaction_Docid = translineitem.Transaction_Docid
              resultobj.Preceding_TransactionLineid = translineitem.id
              resultobj.Preceding_TransactionDocCollection = vm.DefaultTransactionPath
              resultobj.Preceding_TransactionDocLineCollection = vm.LinesRef
              console.log(translineitem.LookupObj)
              resultobj.LookupObj = Object.assign({},translineitem.LookupObj)
              delete resultobj.LookupObj.Open_Allocations
              delete resultobj.LookupObj.Outstanding_Qty
              if(lineitemindex-1+2 === listlength){
                vm.NEWSOInvoiceDialog = true
              } 
          })
          
        })
        
        // setTimeout(() => {
          
        // }, 300);
        
        console.log(this.SelectedTransactionLineItems,this.SelectedWarehouse,this.SelectedSupplier,this.NewPOLineItems,this.NewPOObject)
      },
      PrepareInvoiceSOObject(){
        this.CancelProcessNewSOInvoice()
        this.PrepareLineItems()
        
      },
      CreatePO(){
        this.CancelProcessNewSOInvoice()
        //actually only get suppl info if suppl array not present
        this.GetSupplierInformation(this.SelectedSupplier.id)
        
      },
        ResetSearch(){
            this.prsearch = ''
        },
        ViewRouteQuery(LookupObj){
        let newroute = { path: LookupObj.RoutePath, query: LookupObj.RouteQuery}
        this.$router.push(newroute)
      },
        GetSalesOrder(probj){
            let vm = this
          return new Promise(function(resolve, reject) {
            db.collection(probj.LookupObj.list.split('_').join('').split(' ').join('').toLowerCase()).doc(probj.LookupObj.id).onSnapshot(snapshot => {
              let sodata = snapshot.data()
            //   probj.Preferred_Supplier = invartdata.Preferred_Supplier
            //   probj.Last_Quoted_Price = invartdata.Last_Quoted_Price
            //   probj.Purchase_Leadtime = invartdata.Purchase_Leadtime
                probj.Sales_OrderObj = {...sodata,id: probj.LookupObj.id}
                probj.Transaction = {
                    Name: probj.Transaction_Docid,
                    //WarehouseTransaction/Z8WSyTNan523HzF3GOWk/PI-SL-00000002?TransactionType=PickingSlips
                    RoutePath: '/WarehouseTransaction/'+vm.SelectedWarehouse.id+'/'+probj.Transaction_Docid,
                    RouteQuery: {TransactionType: vm.DefaultTransactionPath}
                }
                //yeah sure, should we not get the order line item?
              resolve(probj)
            })
          })
        },
        
        GetTransactionalLineItems(list){
          let vm = this
          this.WarehousesArray.map(wh => {
            if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Ordering){
              //.orderBy('Created_On', 'desc')
              //.where('Qty','>=',1)
              db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(wh.id).collection(list).where('Ready_for_Dispatching','==',true).onSnapshot(res => {
                const changes = res.docChanges();
                //alert(res.docs.length)
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                      probj.Warehouse.Name = wh.Name
                      vm.GetSalesOrder(probj).then(result => {
                        vm.TransactionalLineItems.push(result)
                      })
                  
                  }
                  if (change.type === 'modified') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                      probj.Warehouse.Name = wh.Name
                      vm.GetSalesOrder(probj).then(result => {
                        let oncheck = vm.TransactionalLineItems.find(obj => obj.id === result.id)
                          if(oncheck){
                            let index = vm.TransactionalLineItems.indexOf(oncheck)
                            vm.TransactionalLineItems.splice(index, 1, result);
                          }
                      })
                  
                  }
                })  
              })
            }
          })
        }
    }
}
</script>

<style>

</style>


