<template>
  <div>
      <InfoSheet v-for="elmnt in HighlightedAlertElements" :key="elmnt.itemObjKey" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
        :tab="elmnt" @InteractMethod="InteractMethod" :CanInteract="CanInteract"
        />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
export default {
    props: ['System','SystemEntities','Alerts','CanInteract'],
    components: {InfoSheet},
    data() {
        return {
            basicinfosheet:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,								
            },
        }
    },
    computed:{
        HighlightedAlertElements(){
            return this.Alerts.map(item => {
                let passcol = item.Color ? item.Color : 'red'
                let failcol = item.Color === 'green' ? 'red' : 'green'
                let passicon = item.AlertIcon ? item.AlertIcon : 'mdi-alert'
                let failicon = item.AlertIcon === 'mdi-check' ? 'mdi-check' : 'mdi-check'
               if(item.Array && item.Array.length > 0){
                        item.Description = `You have a total of <span style="font-size: 1.5rem;color: `+passcol+`;">`+item.Array.length+` `+item.IncompleteName+` `+item.TableName+`</span>.`
                        item.Color = passcol
                        item.AlertIcon = passicon
                        if(item.TablePath){
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath  
                        }
                        if(!item.AlertDescription){
                            item.Description = item.Description+` View them now?`
                        }
                        else{
                            item.Description = item.Description+`<br> `+item.AlertDescription
                        }
                        
                    }
                    else if(!item.Array || item.Array && item.Array.length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = failcol
                        item.AlertIcon = failicon
                    }
                return item
            }).map(item => {
                let elmnt = Object.assign({},this.basicinfosheet)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                if(item.QuickDashType){
                    elmnt.QuickDashType = item.QuickDashType
                    elmnt.Results = item.Results
                }
                return elmnt
            })
        },
    },
    created(){

    },
    methods:{
        InteractMethod(tab){
            
            let elmntindex = this.HighlightedAlertElements.indexOf(tab)
            //console.log(tab,elmntindex,this.Alerts[elmntindex])
            this.$emit('InteractMethod',this.Alerts[elmntindex])
        },
    }
}
</script>

<style>

</style>
