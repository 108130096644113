
<template>
  <v-main flat class="background" style="padding-left:110px;padding-top:80px;">
    <v-dialog width="400" v-model="MonetizationPackageDialog">
      <v-card flat tile>
        <v-card-title>{{editedSubscriptionPackage.Type ? editedSubscriptionPackage.Type.Name+ ' Configuration' : 'Configuration'}}</v-card-title>
        <v-card-text class="background">
        
          <v-select
            v-model="editedSubscriptionPackage.PaymentSchedule"
            label="Payment Schedule"
            :items="['Monthly', 'Annual','Lifetime']"
          />
          <v-text-field label="Name" v-model="editedSubscriptionPackage.Name">
          </v-text-field>
          <ContentEditableField style="padding:15px;"
            :FieldObject="editedSubscriptionPackage" :FieldName="'Description'"
            :FieldValue="editedSubscriptionPackage.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        

          <v-select v-if="editedSubscriptionPackage.Type && editedSubscriptionPackage.Type.Name === 'Group Membership'"
            v-model="editedSubscriptionPackage.ContentScope"
            label="Content Scope" multiple
            :items="GroupContentPackageScopeOptions"
          />
          
          <v-text-field
            label="Price"
            type="number"
            v-model.number="editedSubscriptionPackage.Price"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelMonetizaitonPackageDialog()" dark color="orange"
            >Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="SaveMonetizationPackage()" dark color="green"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" color="red" @click="helpsheet = !helpsheet"
          >close</v-btn
        >
        <div>
          Here you can see a preview of what your <v-icon>group</v-icon> Group
          looks like from the Group <v-icon>search</v-icon> search table
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Group.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-layout class="justify-center fill-height" >
      <v-flex xs12 s12 md12 :class="ShowGroupsasPortal ? 'lg12' : 'lg10'">
        <v-layout row v-if="groupdata && groupdata.GroupPublishStatus === 'Unpaid'" style="margin-top: 20px;">
          <v-alert width="100%"
            border="top"
            :colored-border="true"
            :icon="'mdi-alert'"
            :elevation="6"
            :color="'red'"
            
          >
          <v-card-text >
            This Group has payment issues. Please resolve the outstanding Payment Amount to regain access
          </v-card-text>
          <!-- <v-card-actions v-if="tab.HasActionButton">
            <v-btn :to="tab.RoutePath">Go</v-btn>
          </v-card-actions> -->
          </v-alert>
        </v-layout>
        <!--  fill-height below -->
        <v-layout row fill-height>
          <v-list v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && OldStyle" color="blue-grey darken-4" width="250" dark height="100%">
            <v-list-item
              class="lightblurry"
              v-for="item in ComputedAssignedGroupScope"
              :key="item.title"
              @click="ActivateSection(item)"
            >
              <v-list-item-icon class="spin">
                <v-icon v-if="item.title !== 'System Tickets'">{{
                  item.icon
                }}</v-icon>
                <v-badge
                  color="red"
                  overlap
                  v-if="item.title === 'System Tickets'"
                  dark
                >
                  <v-icon dark>
                    {{ item.icon }}
                  </v-icon>
                  <span slot="badge"> {{ AllSystemTickets.length }} </span>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider vertical></v-divider>
          <v-layout col class="justify-center">
            <v-scroll-y-transition mode="in-out" v-if="GeneralView">
              <v-card flat
                width="100%"
                class="transparent"
              >
               <v-btn @click="SeeAvailableDirectoryPackages()" x-large color="links" outlined width="100%" v-if="GroupDirectoryPackages && GroupDirectoryPackages.length > 0">
                List in Directory! ({{GroupDirectoryPackages.length}} available)
              </v-btn>
                <v-icon light :color="GroupPublishStatusIconColor">{{
                  GroupPublishStatusIcon
                }}</v-icon>
                <h4 :class="GroupPublishStatusColor">
                  Your Group is {{ GroupPublishStatusText }} to the Network
                </h4>
                <v-btn
                  @click="ToggleHelp()"
                  color="#e4003b"
                  dark
                  fab
                  small
                  absolute
                  top
                  right
                  ><v-icon>mdi-help</v-icon></v-btn
                >

                <v-row>
                  <v-col>
                    <v-card color="#e9eced" dark
                      ><v-btn :to="'/group/' + groupid">View Group</v-btn
                      ><v-img
                        :src="logo"
                        :gradient="GroupPublishStatusPreviewGradient"
                        max-height="160px"
                      >
                        <v-list-item three-line>
                          <v-list-item-content class="align-self-start">
                            <v-container grid-list-xl>
                              <v-layout row wrap>
                                <v-flex xs12 s12 md3 lg2>
                                  <v-layout column>
                                    <v-avatar tile
                                      ><v-img :src="logo"></v-img
                                    ></v-avatar>
                                  </v-layout>
                                </v-flex>

                                <v-layout row>
                                  <h2>
                                    <v-list-item-title
                                      class="mx-3"
                                      v-text="editedItem.Name"
                                    ></v-list-item-title>
                                  </h2>
                                </v-layout>

                                <v-layout column>
                                  <v-flex xs12 s12 md10 lg8>
                                    <h6 id="Grouptitle" class="mx-3">
                                      {{ editedItem.GroupCategory }}
                                      <v-icon
                                        v-if="editedItem.EndorsingNetwork"
                                        light
                                        color="white"
                                        medium
                                        >cloud_done</v-icon
                                      >
                                    </h6></v-flex
                                  >
                                </v-layout>
                                <v-layout column>
                                  <h5 class="mx-3">
                                    Group has a Privacy of
                                    {{ editedItem.GroupPrivacy }}
                                    <v-icon>{{
                                      editedItem.GroupPrivacyIcon
                                    }}</v-icon>
                                  </h5>
                                </v-layout>
                                <v-layout row wrap> </v-layout> </v-layout
                              ><v-divider></v-divider><br />
                              <v-layout row wrap>
                                <v-list-item-action-text
                                  v-text="editedItem.Description"
                                ></v-list-item-action-text
                              ></v-layout>
                            </v-container>
                          </v-list-item-content> </v-list-item
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <v-card elevation="0"
                width="100%"
                class="background align-content-center"
              >
               <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Group Settings
                </v-card-subtitle>
              <v-card-text>
                <v-card elevation="0" class="siteconfigcard">
                     <v-card-title class="mediumoverline">
                      Group General <v-spacer></v-spacer>                    
                  </v-card-title>
                    <v-card-text>
                       <v-list-item dense>
                         <v-text-field
                          id="Grouptitle"
                          class="Grouptitle"
                          v-model="editedItem.name"
                          @change="ChangesareMade()"
                        ></v-text-field>
                       </v-list-item>
                       <v-list-item dense>
                          <v-avatar
                  class="profile"
                  :size="$vuetify.breakpoint.smAndUp ? 164 : 128"
                  color="white"
                  tile
                  v-if="!selectedGroupImage"
                >
                  <v-img class="Image" :src="logo" width="150px"></v-img
                ></v-avatar>
                <v-avatar
                  class="profile"
                  :size="$vuetify.breakpoint.smAndUp ? 164 : 128"
                  color="white"
                  tile
                  v-if="selectedGroupImage"
                >
                  <v-img
                    class="Image"
                    :src="GroupImagelocalURL"
                    width="150px"
                  ></v-img
                ></v-avatar>
                <input
                  style="display: none"
                  type="file"
                  @change="onGroupImageselected($event)"
                  ref="fileInputer"
                />
                <v-btn
                  dark
                  v-if="!selectedGroupImage"
                  color="#e4003b"
                  class="font-weight-light"
                  @click="$refs.fileInputer.click()"
                  >Change Picture</v-btn
                >

                <v-btn
                  dark
                  v-if="selectedGroupImage"
                  color="#e4003b"
                  class="font-weight-light"
                  @click="onUploadGroupImageFile()"
                  >Save Picture</v-btn
                >
                <v-btn
                  dark
                  v-if="selectedGroupImage"
                  color="#048abf"
                  class="font-weight-light"
                  @click="closeGroupImage()"
                  >Cancel</v-btn
                >
                       </v-list-item>
                       <v-list-item dense>
                          <ContentEditableField style="padding:15px;"
                            :FieldObject="editedItem" :FieldName="'Description'"
                            :FieldValue="editedItem.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                       </v-list-item>
                       <v-list class="transparent" v-if="GroupCatNavlistFields && GroupCatNavlistFields.length > 0">
                          <!-- <v-list-item v-for="field in GroupCatNavlistFields" :key="field.itemObjKey"> -->
                          <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="groupdata" :field="field" @onPhotoFileselected="onPhotoFileselected"
                            :CurrentEntity="CurrentEntity" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"  v-for="field in GroupCatNavlistFields" :key="field.itemObjKey"
                            :SystemEntities="SystemEntities" :PrimaryFieldName="'name'" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                              />
                          <!-- </v-list-item> -->
                      </v-list>
                      <v-list class="transparent" v-if="GrouphasPaidOptions">
                       <v-list-item dense>
                         <v-icon>mdi-gift</v-icon>  Group Package
                       </v-list-item>
                       <v-list-item dense>
                         <v-btn plain text v-if="GroupBillingAccount && !GroupBillingAccount.Subscription_Packageid">Your Billing Account is unpaid</v-btn>
                          <v-select v-if="GroupBillingAccount" :rules="[$store.state.formrules.required]" :items="GroupOwnershipPackages" disabled
                              label="Choose your package" v-model="GroupBillingAccount.Subscription_Package"
                              item-text="Name"
                              return-object
                              />
                       </v-list-item>
                      </v-list>
                    </v-card-text>
                </v-card>
                <v-card elevation="0" class="siteconfigcard">
                     <v-card-title class="mediumoverline">
                      Accessibility <v-spacer></v-spacer>                    
                  </v-card-title>
                    <v-card-text>
                       <v-list-item dense>
                         Group Privacy settings
                       </v-list-item>
                       <v-list-item dense>                         
                          <v-select
                            :items="ComputedPrivacyTypes"
                            @change="ChangesareMade(), (ChangestoGroupPrivacy = true)"
                            name="Privacy"
                            v-model="editedItem.GroupPrivacy"
                            :value="editedItem.GroupPrivacy"
                            
                          >
                          </v-select>
                       </v-list-item>
                       <v-list-item dense>
                         Who can Join
                       </v-list-item>
                       <v-list-item dense>
                      <v-select
                        :items="JoinPrivacyTypes"
                        @change="ChangesareMade(), (ChangestoJoinPrivacy = true)"
                        name="Privacy"
                        v-model="editedItem.WhocanJoin"
                        :value="editedItem.WhocanJoin"
                      >
                      </v-select>
                       </v-list-item>
                       <v-list-item dense>
                         <v-icon>mdi-search-web</v-icon> Default Page
                       </v-list-item>      
                        <v-list-item dense>                
                          <v-select
                            :items="DefaultPageOptions"
                            item-text="Name"
                            clearable
                            @click:clear="UpdateDefaultPage()"
                            @change="UpdateDefaultPage()"
                            name="Privacy"
                            return-object
                            v-model="editedItem.DefaultPage"
                          >
                          </v-select>
                        </v-list-item>
                       <v-list-item dense>
                        <v-icon :color="GroupPublishStatusIconColor">{{
                            GroupPublishStatusIcon
                          }}</v-icon>
                          Group publishing Status
                          <v-select
                            :items="ComputedPublishStatusTypes"
                            @change="ChangesareMade(),ChangestoGroupStatus = true"
                            name="Privacy"
                            :disabled="groupdata && groupdata.GroupPublishStatus === 'Unpaid' || GroupBillingAccount && !GroupBillingAccount.Subscription_Packageid"
                            v-model="editedItem.GroupPublishStatus"
                            :value="editedItem.GroupPublishStatus"
                          >
                            <v-text-field
                              :value="editedItem.GroupPublishStatus"
                              label="Publish Status"
                            ></v-text-field>
                          </v-select>
                                </v-list-item>
                              </v-card-text>
                              <v-card-actions>
                          <v-btn
                            dark
                            @click="SaveUpdatedGroup()"
                            color="#e4003b"
                            v-if="Changes"
                          >
                            Save Changes
                          </v-btn>
                            <v-btn
                            dark
                            @click="CancelGroupUpdate()"
                            color="#048abf"
                            v-if="Changes"
                          >
                            Cancel Changes
                          </v-btn>
                          </v-card-actions>
                </v-card>
                 <v-card elevation="0" class="siteconfigcard">
                  <v-card-title class="mediumoverline">
                      Scope <v-spacer></v-spacer>                    
                  </v-card-title>
                    <v-card-text>
                      <v-list-item dense>
                        <v-icon>mdi-toggle</v-icon> Group Scope
                      </v-list-item>
                       <v-list-item dense>                          
                          <v-select
                            :items="GroupScope"
                            @change="ChangesareMade()"
                            label="Group Scope"
                            v-model="editedItem.AssignedGroupScope"
                            multiple
                            :value="editedItem.AssignedGroupScope"
                          >
                          </v-select>
                       </v-list-item>
                    </v-card-text>
                </v-card>
              </v-card-text>
                </v-card>
              </v-card>
            </v-scroll-y-transition>
            <v-scroll-y-transition mode="in-out" v-if="PostsView">
              <v-card flat
                width="100%"
                class="transparent"
              >
               <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Posts Channel settings
                </v-card-subtitle>
              <v-card-text>
                <v-card elevation="0" class="siteconfigcard">
                     <v-card-title class="mediumoverline">
                      Accessibility <v-spacer></v-spacer>                    
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                        <v-select
                          :items="SubPrivacyTypes"
                          @change="ChangesareMade()"
                          v-model="editedItem.PostPrivacy"
                          label="Who can View Wall"
                        ></v-select>
                      </v-list-item>
                      <v-list-item dense v-if="editedItem.PostPrivacy">
                        <v-select
                          :items="PostPrivacyTypes"
                          @change="ChangesareMade()"
                          v-model="editedItem.WhocanPost"
                          label="Who can Post"
                        ></v-select>
                      </v-list-item>
                      <v-switch @change="ChangesareMade()" v-if="editedItem.PostPrivacy" v-model="editedItem.PostsbyApproval" label="Posts by Approval"/>
                    </v-card-text>
                    <v-card-actions>Changes 
                      <v-btn
                        dark
                        @click="SaveUpdatedGroup()"
                        color="#e4003b"
                        v-if="Changes"
                      >
                        Save Changes
                      </v-btn>
                      <v-btn
                        dark
                        @click="CancelGroupUpdate()"
                        color="#048abf"
                        v-if="Changes"
                      >
                        Cancel Changes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </v-card-text>
              </v-card>
            </v-scroll-y-transition>
             <v-scroll-y-transition mode="in-out" v-if="MonetizationView">
              <v-card elevation="0"
                width="100%"
                class="background align-content-center"
              >
               <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Monetization Packages and settings
                </v-card-subtitle>
              <v-card-text>
                <v-card elevation="0" class="siteconfigcard">
                     <v-card-title class="mediumoverline">
                      Payouts <v-spacer></v-spacer>
                    
                  </v-card-title>
                    <v-card-text>
                      <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        All Payouts on {{System.Name}} occur <v-btn color="links" outlined small>{{System.Payout_Schedule}}</v-btn>
                      </v-list-item-title>
                       <v-list-item-subtitle>
                        The minimum earnings required to receive a payout is at {{CurrencyFormatter(System.Payout_Minimum,$store.state.DefaultCurrency.Currency)}}.<br>
                        Would you like to set a higher value?
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                      <v-list-item >
                          <v-text-field type="number" :rules="PayoutMinimumRule(item)" v-model.number="groupdata.Payout_Minimum" label="Payout Minimum"/>
                    </v-list-item>
                     
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer>
                      </v-spacer>
                      <v-btn dark color="success" @click="UpdatePayoutMinimum()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              <div v-if="GroupSubscriptionPackage && GroupSubscriptionPackage.Can_Monetize_Content && !RefreshingMonetizationOptions">
                <v-list class="transparent" v-for="channel in MonetizationChannels" :key="channel.itemObjKey">
              <v-card elevation="0" class="siteconfigcard">
                <v-card-subtitle class="mediumoverline">
                      {{channel.Name}} Monetization
                  </v-card-subtitle>
                 
                 <v-list-item dense>
                    <v-autocomplete dense item-text="Name" :label="channel.Name+' Channel Charge'" :items="[{ID: 10000001, Name: 'None'},{ID: 10000002, Name: 'Membership Packages'},{ID: 1000003, Name: 'Token Based'}]" @change="MonetizationChangesareMade()" v-model="channel.MonetizationSettings"
                  >
                  <v-list-item
                    slot="prepend-item"
                    class="grey--text">
                   <p>Choose the right BEFORE you start monetizing...<br></p>
                </v-list-item>
                  <template v-slot:item="data">
                    <div>
                          <v-list-item dense>
                           {{data.item.Name}}
                            </v-list-item>
                            <v-list-item class="caption" dense>
                               <p v-if="data.item.Name === 'None'">
                                  No charge to anybody.<br>
                                  "Privacy" setting becomes primary access configuration for {{channel.Name}} .
                                  </p>
                                <p v-if="data.item.Name === 'Membership Packages'">
                                  Access granted through Monetizaiton Packages only.<br>
                                  Will allow charging Tokens for Non Members only.<br>
                                  "Privacy" setting becomes "Preview filter" for {{channel.Name}}<br>
                                  ({{channel.Name}} can be previewed by  {{channel.PrivacySettings}})
                                </p>
                                 <p v-if="data.item.Name === 'Token Based'">
                                   Access based on Token amount set on create of items in my {{channel.Name}}<br>
                                   Will allow charging Tokens for Non Members as well as Members.<br>
                                  "Privacy" setting becomes "Preview filter" for {{channel.Name}}<br>
                                  ({{channel.Name}} can be previewed by {{channel.PrivacySettings}})
                                </p>
                          </v-list-item>
                    </div>
                    </template>
                  </v-autocomplete>
                   <!-- <v-select
                   label="Monetization" @change="ChangesareMade()"
                   :items="['None','Membership Packages','Token Purchases']"
                   v-model="BlogsMonetization"
                   /> -->
                  </v-list-item>
                   <v-card-subtitle style="padding-top: 0px;padding-bottom: 30px;">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{channel.Name}} {{channel.MonetizationSettings === 'None' ? 'viewable by ' : 'can be previewed by'}} - <span class="blue--text">{{channel.PrivacySettings}}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        (set in... <v-btn outlined x-small @click="ActivateSection(channel.ScopeItem)">{{channel.ScopeName}}</v-btn>..."Privacy")
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  </v-card-subtitle>
                  <v-list-item>
                    
                   <v-switch v-if="channel.MonetizationSettings === 'Membership Packages'"
                    :label="channel.Name+' Pay per View'" @change="MonetizationChangesareMade()"
                    v-model="channel.PayperView"
                   />
                  </v-list-item>
                  <v-list-item>
                   <v-text-field v-if="channel.MonetizationSettings === 'Membership Packages' && channel.PayperView"
                   :label="channel.Name+' Tokens per View'"
                   @change="MonetizationChangesareMade()"
                   type="number"
                    v-model.number="channel.CreditCharge"
                   />
                  </v-list-item>
              </v-card>
                </v-list>
                <h4 v-if="MonetizationChanges">
                  You have unsaved changes. You can cancel them all and lose all
                  changes, or save them now
                </h4>
                <v-card-actions>
                  <v-btn
                    dark
                    @click="UpdateGroupMonetizationOptions()"
                    color="#e4003b"
                    v-if="MonetizationChanges"
                  >
                    Save Changes
                  </v-btn>
                  <v-btn
                    dark
                    @click="CancelGroupMonetizationUpdate()"
                    color="#048abf"
                    v-if="MonetizationChanges"
                  >
                    Cancel Changes
                  </v-btn>
                </v-card-actions>
                </div>
                <v-list class="transparent">
                  
                  <v-card elevation="0" class="siteconfigcard">
                     <v-card-title class="mediumoverline">
                      Packages <v-spacer></v-spacer>
                      <v-btn @click="ActivateMonetizationPackagesDialog()">
                    Add Package
                  </v-btn>
                  </v-card-title>
                  <v-list-item v-for="subpack in GroupSubscriptionPackages" :key="subpack.itemObjKey">
                     <v-list-item-content>
                        {{ subpack.Name }}
                      </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="ActivateMonetizationPackagesDialog(subpack)"
                        >mdi-cogs</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                  </v-card>
                </v-list>
              </v-card-text>
              </v-card>
             </v-scroll-y-transition>            
             <v-scroll-y-transition mode="in-out" v-if="WebsiteView">
              <v-card flat
                width="100%"
                class="transparent"
              >
              
              <v-card-subtitle class="background darken-1 mediumoverline">
                  <v-icon>mdi-palette</v-icon>Styling
                </v-card-subtitle>
                <v-card-text>
                  <v-list>
                    <v-btn dark color="success" @click="UpdateGroupStyling()">
                      Save
                    </v-btn>
                    <v-list-item>
                     <v-select item-text="Name" :items="GroupAssets" v-model="groupdata.NavBar_IMG" return-object>
                       <template v-slot:item="{ item }">
                          <span>
                           <v-img height="50" :src="item.ThumbURL"/>
                          </span>
                        </template>
                     </v-select>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field :rules="[$store.state.formrules.telnr]" v-model="groupdata.Telephone" label="Telephone" />
                    </v-list-item>
                    <v-list-item>
                          <v-combobox
                        v-model="groupdata.KeyWords"
                        :items="KeyWordOptions"
                        label="KeyWords/ Products"
                        multiple
                        chips
                        >
                        <template v-slot:selection="data">
                            <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                            >
                            <v-avatar
                                class="accent white--text"
                                left
                                v-text="data.item.slice(0, 1).toUpperCase()"
                            ></v-avatar>
                            {{ data.item }}
                            </v-chip>
                            </template>
                    </v-combobox>
                    </v-list-item>
                    <v-list-item>
                      Navbar BG Color
                     <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="200px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>
                          </template>
                          <v-color-picker v-model="groupdata.NavbarBGColor"></v-color-picker>
                        </v-menu>
                    </v-list-item>
                    <v-list-item>
                      Navbar Text Color
                      <v-autocomplete
                        :items="LibraryColors"
                        label="Nav Items Text Color"
                        v-model="groupdata.NavbarTextColor"
                      >
                        <template v-slot:item="{ item }">
                          <span :class="item"
                            >{{ item }}
                            <p style="font-size: 12px">
                              The Quick brown fox jumps over the lazy dog
                            </p>
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-list-item>
                    <v-list-item>
                      <v-autocomplete
                        :items="AdvancedComputedLibraryColors"
                        label="Nav Items BG Color"
                        v-model="groupdata.NavItemsBGColor"
                      >
                        <template v-slot:item="{ item }">
                          <span :class="item"
                            >{{ item }}
                            <p style="font-size: 12px">
                              The Quick brown fox jumps over the lazy dog
                            </p>
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-list-item>
                     <v-list-item>
                      <v-autocomplete
                        :items="LibraryColors"
                        label="Nav Items Text Color"
                        v-model="groupdata.NavItemsTextColor"
                      >
                        <template v-slot:item="{ item }">
                          <span :class="item"
                            >{{ item }}
                            <p style="font-size: 12px">
                              The Quick brown fox jumps over the lazy dog
                            </p>
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-list-item>
                    
                  </v-list>
                </v-card-text>
                
                <v-card-subtitle v-if="FeatureListOptions && FeatureListOptions.length > 0" class="background darken-1 mediumoverline">
                  <v-icon>mdi-information</v-icon>Additional Info
                </v-card-subtitle>
                <v-card-text v-if="FeatureListOptions && FeatureListOptions.length > 0">
                  <v-list>
                     <v-dialog
                          max-width="400px"
                          offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn  v-on="on">
                                    Update
                                  </v-btn>
                              </template>
                              <v-card-subtitle class="accent white--text">
                                Info Options
                              </v-card-subtitle> 
                              <v-list>
                                  <v-list-item v-for="feature in FeatureListOptions" :key="feature.itemObjKey">
                                      <v-text-field v-if="feature.Type === 'Number Field'" type="number" v-model.number="groupdata[feature.Name]" :label="feature.DisplayName">
                                      </v-text-field>
                                        <v-switch v-if="feature.Type === 'Boolean'" v-model="groupdata[feature.Name]" :label="feature.DisplayName">
                                        </v-switch>
                                        <v-menu offset-y :close-on-content-click="false" bottom left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-if="feature.HoverDescription" small v-on="on">mdi-information-outline</v-icon>
                                    </template>                         
                                      <v-card width="200" shaped elevation="6">
                                        <v-card-title>
                                          {{feature.DisplayName}}
                                        </v-card-title>
                                        <v-img v-if="!feature.DescriptionIMG"
                                          src="@/assets/ImageHolder.png"
                                          height="100px"
                                        ></v-img>
                                        <v-img v-if="feature.DescriptionIMG"
                                          :src="feature.DescriptionIMG"
                                          height="100px"
                                        ></v-img>
                                        
                                        <v-card-text v-html="feature.HoverDescription"/>
                                      </v-card>
                                    </v-menu>
                                  </v-list-item>
                              </v-list>
                              <v-btn @click="SaveSiteAditionalInfo()">
                            Save
                          </v-btn>
                          </v-dialog>
                          
                        <v-list-item v-for="feature in GroupDefinedFeatureListOptions" :key="feature.itemObjKey">
                              <v-text-field disabled v-if="feature.Type === 'Number Field'" type="number" v-model.number="groupdata[feature.Name]" :label="feature.DisplayName">
                              </v-text-field>
                                <v-switch disabled v-if="feature.Type === 'Boolean'" v-model="groupdata[feature.Name]" :label="feature.DisplayName">
                                </v-switch>
                                 <v-menu offset-y :close-on-content-click="false" bottom left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-if="feature.HoverDescription" small v-on="on">mdi-information-outline</v-icon>
                                    </template>                         
                                      <v-card width="200" shaped elevation="6">
                                        <v-card-title>
                                          {{feature.DisplayName}}
                                        </v-card-title>
                                        <v-img v-if="!feature.DescriptionIMG"
                                          src="@/assets/ImageHolder.png"
                                          height="100px"
                                        ></v-img>
                                        <v-img v-if="feature.DescriptionIMG"
                                          :src="feature.DescriptionIMG"
                                          height="100px"
                                        ></v-img>
                                        
                                        <v-card-text v-html="feature.HoverDescription"/>
                                      </v-card>
                                    </v-menu>
                          </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-subtitle class="background darken-1 mediumoverline">
                  <v-icon>mdi-search-web</v-icon>Pages
                </v-card-subtitle>
                <v-card-text>
                  <v-list>
                    <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y v-model="NewPagedialog"
                          min-width="350px"
                        >
                          <template v-slot:activator="{ on }">
                             <v-btn v-if="WebPageBuilderAvailable || groupdata.Pages.length === 0" v-on="on" target="blank">
                                Add New
                              </v-btn>
                          </template>
                         <v-card>
                           <v-card-title class="recordtoolbar white--text overline">
                             New Page Details
                           </v-card-title>
                           <v-card-text>
                             <v-text-field dense v-model="PageName" label="Name" />
                              <v-text-field dense v-model="PageRoute" label="Route" />
                              <v-textarea dense v-model="PageOverview" label="Overview" />
                           </v-card-text>
                           <v-card-actions>
                             <v-btn  small dark color="warning" @click="CancelPageAdd()">Cancel</v-btn>
                             <v-spacer>
                             </v-spacer>
                             <v-btn small dark color="success" @click="AddNewPage()">
                               Save</v-btn>
                           </v-card-actions>
                         </v-card>
                        </v-menu>
                  
                    <v-list-item v-for="page in groupdata.Pages" :key="page.itemObjKey">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{page.Name}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{page.OverView}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-actions>
                        <v-btn :href="'/Group-PageEditor/'+$route.params.id+'/'+page.id" target="blank">Edit</v-btn>
                      </v-list-item-actions>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                 <v-card-subtitle v-if="WebFormsAvailable" class="background darken-1 mediumoverline">
                  <v-icon>mdi-form-select</v-icon>Web Forms
                </v-card-subtitle>
                <v-card-text v-if="WebFormsAvailable" >
                  <v-list>
                    <v-btn @click="NavigatetoGroupWebFormBuilder()">
                      Add New
                    </v-btn>
                    <v-list-item v-for="entity in ComputedGroupEntities" :key="entity.itemObjKey">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{entity.DisplayName}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-html="entity.Description">
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-actions>
                        
                        <v-btn @click="NavigatetoGroupWebFormEditor(entity.id)" >Edit</v-btn>
                      </v-list-item-actions>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-subtitle v-if="TeamDashboardAvailable" class="background darken-1 mediumoverline">
                  <v-icon>mdi-account-group</v-icon>Team
                </v-card-subtitle>
                <v-card-text v-if="TeamDashboardAvailable">
                  <v-list>
                     <v-dialog
                                    max-width="400px"
                                    offset-y>
                                        <template v-slot:activator="{ on }">
                                          <v-btn  v-on="on">
                                              Add New
                                            </v-btn>
                                        </template>
                                        <v-card-subtitle class="accent white--text">
                                          Members
                                        </v-card-subtitle> 
                                        <v-list>
                                            <v-list-item>
                                                <v-text-field clearable v-model="MemberSearch" label="Search">
                                                </v-text-field>
                                            </v-list-item>
                                            <v-list-item v-for="user in nonteamgroupmemberssearched" :key="user.itemObjKey">
                                         
                                                <v-list-item-avatar>
                                                    <v-avatar size="30" v-if="user.Profile_Photo">
                                                        <v-img :src="user.Profile_Photo" />
                                                    </v-avatar>
                                                    <v-avatar size="30" v-if="!user.Profile_Photo">
                                                        <v-img src="@/assets/BlankProfilePic.png" />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    {{user.UserName}}
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                  <v-checkbox v-model="user.IsTeamMember" label="Is Team Member"/>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <router-link :to="'/SiteMember/'+user.recordid">View Profile</router-link>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-dialog>
                      <v-btn @click="SaveTeamMembers()">
                            Save
                          </v-btn>
                    <v-list-item v-for="member in teamgroupmembers" :key="member.itemObjKey">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{member.UserName}}
                        </v-list-item-title>
                        <v-list-item-subtitle >
                          {{member.UserRole}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-actions>
                        
                        <v-btn @click="NavigatetoGroupWebFormEditor(entity.id)" >Edit</v-btn>
                      </v-list-item-actions>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                 <v-card-subtitle class="background darken-1 mediumoverline">
                  <v-icon>mdi-folder-image</v-icon>Assets
                </v-card-subtitle>
                <v-card-text>
                  <v-card v-if="UploadFileListFiles.length === 0" flat width="60%" @dragover.prevent @drop.prevent="AddLibraryAssets($event, FilterType)" id="drop-area">
                    <v-layout class="justify-center">
                      <v-card-title class="grey--text">
                          Drop any attachment files in here
                      </v-card-title>
                    </v-layout>
                  </v-card>
                  <v-row  v-if="UploadFileListFiles.length > 0">
                    <v-col
                      v-for="n in UploadFileListFiles"
                      :key="n.itemOvjKey"
                      class="d-flex child-flex"
                      cols="3"
                    >
                      <v-img
                        :src="n"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row  v-if="GroupAssets.length > 0">
                    <v-col
                      v-for="n in GroupAssets"
                      :key="n.itemOvjKey"
                      class="d-flex child-flex"
                      cols="3"
                    >
                      <v-img
                        :src="n.ThumbURL"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="CancelUploadLibraryAssets()">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="UploadLibraryAssets()">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-scroll-y-transition>
            
            <v-scroll-y-transition mode="in-out" v-if="BlogsView">
              <v-card flat
                width="100%"
                class="transparent"
              >
                <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Blogs Channel settings
                </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          Accessibility <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                        <v-select
                          :items="GetSubPrivacyTypes('Blogs')"
                          @change="ChangesareMade()"
                          v-model="editedItem.BlogPrivacy"
                          label="Privacy"
                        ></v-select>
                      </v-list-item>
                      <v-list-item dense>
                        <v-autocomplete
                          multiple
                          @change="ChangesareMade()"
                          v-model="BlogsModerators"
                          item-text="UserName"
                          :items="groupmembers"
                          label="Blog Moderators"
                          return-object
                        />
                      </v-list-item>
                        </v-card-text>
                          <v-card-actions>
                            <v-btn
                              dark
                              @click="SaveUpdatedGroup()"
                              color="#e4003b"
                              v-if="Changes"
                            >
                              Save Changes
                            </v-btn>
                            <v-btn
                              dark
                              @click="CancelGroupUpdate()"
                              color="#048abf"
                              v-if="Changes"
                            >
                              Cancel Changes
                            </v-btn>
                          </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
            </v-scroll-y-transition>
            <v-scroll-y-transition mode="in-out" v-if="ArticlesView">
              <v-card flat
                width="100%"
                class="transparent"
              >
                 <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Articles Channel settings
                </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          Accessibility <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                    <v-select
                      :items="GetSubPrivacyTypes('Articles')"
                      @change="ChangesareMade()"
                      name="Privacy"
                      v-model="editedItem.ArticlesPrivacy"
                      label="Privacy"
                      :value="editedItem.ArticlesPrivacy"
                    ></v-select>
                  </v-list-item>
                  <v-list-item>
                    <!-- Teamgroupmembers -->
                    <v-autocomplete
                      multiple
                      @change="ChangesareMade()"
                      v-model="ArticlesModerators"
                      item-text="UserName"
                      :items="groupmembers"
                      label="Article Moderators"
                      return-object
                    />
                  </v-list-item>
                  <!-- <v-list-item>
                    <v-switch
                      v-model="ArticlesUpdateExternal"
                      label="Articles Update External App"
                    />
                  </v-list-item>
                  <v-list-item v-if="ArticlesUpdateExternal">
                    <v-textarea
                      v-model="ArticlesExternalfirebaseConfig"
                    ></v-textarea>
                  </v-list-item> -->
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    dark
                    @click="SaveUpdatedGroup()"
                    color="#e4003b"
                    v-if="Changes"
                  >
                    Save Changes
                  </v-btn>
                  <v-btn
                    dark
                    @click="CancelGroupUpdate()"
                    color="#048abf"
                    v-if="Changes"
                  >
                    Cancel Changes
                  </v-btn>
                </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
            </v-scroll-y-transition>
            <v-scroll-y-transition mode="in-out" v-if="PollsView">
              <v-card flat
                width="100%"
                class="transparent"
              >
                 <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Polls Channel settings
                </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          Accessibility <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                    <v-select
                    :items="GetSubPrivacyTypes('Polls')"
                    @change="ChangesareMade()"
                    name="Privacy"
                    v-model="editedItem.PollsPrivacy"
                    label="Privacy"
                    :value="editedItem.PollsPrivacy"
                  >
                  </v-select>
                  </v-list-item>
                  <v-list-item>
                    <!-- Teamgroupmembers -->
                    <v-autocomplete
                    multiple
                    @change="ChangesareMade()"
                    v-model="PollsModerators"
                    item-text="UserName"
                    :items="groupmembers"
                    label="Polls Moderators"
                    return-object
                  />
                  </v-list-item>
                  <!-- <v-list-item>
                    <v-switch
                      v-model="ArticlesUpdateExternal"
                      label="Articles Update External App"
                    />
                  </v-list-item>
                  <v-list-item v-if="ArticlesUpdateExternal">
                    <v-textarea
                      v-model="ArticlesExternalfirebaseConfig"
                    ></v-textarea>
                  </v-list-item> -->
                </v-card-text>
                <v-card-actions>
                 <v-btn
                  dark
                  @click="SaveUpdatedGroup()"
                  color="#e4003b"
                  v-if="Changes"
                >
                  Save Changes
                </v-btn>
                  <v-btn
                  dark
                  @click="CancelGroupUpdate()"
                  color="#048abf"
                  v-if="Changes"
                >
                  Cancel Changes
                </v-btn>
                </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
            </v-scroll-y-transition>
            <v-scroll-y-transition mode="in-out" v-if="ForumsView">
               <v-card flat
                width="100%"
                class="transparent"
              >
                 <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Forums Channel settings
                </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          Accessibility <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                
                   <v-select
                    :items="GetSubPrivacyTypes('Forums')"
                    @change="ChangesareMade()"
                    name="Privacy"
                    v-model="editedItem.ForumPrivacy"
                    label="Privacy"
                    :value="editedItem.ForumPrivacy"
                  ></v-select>
                  </v-list-item>
                  <v-list-item>
                    <!-- Teamgroupmembers -->
                    <v-autocomplete
                    multiple
                    @change="ChangesareMade()"
                    v-model="ForumsModerators"
                    item-text="UserName"
                    :items="groupmembers"
                    label="Forums Moderators"
                    return-object
                  />
                  </v-list-item>
                  <!-- Need to add ForumCategories
                    <v-list-item>
                      <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="System" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
            @AddGallerySubcolImages="AddGallerySubcolImages"
            />
                  </v-list-item> -->
                </v-card-text>
                <v-card-actions>
                 <v-btn
                  dark
                  @click="SaveUpdatedGroup()"
                  color="#e4003b"
                  v-if="Changes"
                >
                  Save Changes
                </v-btn>
                  <v-btn
                  dark
                  @click="CancelGroupUpdate()"
                  color="#048abf"
                  v-if="Changes"
                >
                  Cancel Changes
                </v-btn>
                </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
           
            </v-scroll-y-transition>
            <v-scroll-y-transition mode="in-out" v-if="EventsView">
                <v-card flat
                width="100%"
                class="transparent"
              >
                 <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Events Channel settings
                </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          Accessibility <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                   <v-select
                    :items="GetSubPrivacyTypes('Events')"
                    @change="ChangesareMade()"
                    name="Privacy"
                    v-model="editedItem.EventsPrivacy"
                    label="Privacy"
                    :value="editedItem.EventsPrivacy"
                  ></v-select>
                  </v-list-item>
                  <v-list-item>
                    <!-- Teamgroupmembers -->
                   <v-autocomplete
                    multiple
                    @change="ChangesareMade()"
                    v-model="EventsModerators"
                    item-text="UserName"
                    :items="groupmembers"
                    label="Events Moderators"
                    return-object
                  />
                  </v-list-item>
                </v-card-text>
                <v-card-actions>
                 <v-btn
                  dark
                  @click="SaveUpdatedGroup()"
                  color="#e4003b"
                  v-if="Changes"
                >
                  Save Changes
                </v-btn>
                  <v-btn
                  dark
                  @click="CancelGroupUpdate()"
                  color="#048abf"
                  v-if="Changes"
                >
                  Cancel Changes
                </v-btn>
                </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
            </v-scroll-y-transition>

            <v-scroll-y-transition mode="in-out" v-if="MeetupsView">
              <v-card flat
                width="100%"
                class="transparent"
              >
                 <v-card-subtitle class="recordtoolbar mediumoverline white--text">
                  Change the Meetups Channel settings
                  </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          Accessibility <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                    <v-select
                      :items="GetSubPrivacyTypes('Meetups')"
                      @change="ChangesareMade()"
                      name="Privacy"
                      v-model="editedItem.MeetupsPrivacy"
                      label="Privacy"
                      :value="editedItem.MeetupsPrivacy"
                    >
                      <v-text-field
                        :value="editedItem.MeetupsPrivacy"
                        label="Privacy"
                      ></v-text-field>
                    </v-select>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      multiple
                      @change="ChangesareMade()"
                      v-model="MeetupsModerators"
                      item-text="UserName"
                      :items="groupmembers"
                      label="Meetup Moderators"
                      return-object
                    />
                  </v-list-item>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    dark
                    @click="SaveUpdatedGroup()"
                    color="#e4003b"
                    v-if="Changes"
                  >
                    Save Changes
                  </v-btn>
                  <v-btn
                    dark
                    @click="CancelGroupUpdate()"
                    color="#048abf"
                    v-if="Changes"
                  >
                    Cancel Changes
                  </v-btn>
                </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
            </v-scroll-y-transition>
            <v-scroll-y-transition mode="in-out" v-if="AdminView">
              <v-card flat
                width="100%"
                class="transparent"
              >
                <v-card-subtitle>
                  Change Admin settings on {{ groupdata.name }}
                </v-card-subtitle>
                  <v-card-text>
                    <v-card elevation="0" class="siteconfigcard">
                        <v-card-title class="mediumoverline">
                          API Configuration <v-spacer></v-spacer>                        
                      </v-card-title>
                        <v-card-text>
                          <v-list-item dense>
                    <v-autocomplete
                      multiple
                      @change="CheckifEmpty()"
                      v-model="Administrators"
                      item-text="UserName"
                      :items="groupmembers"
                      label="Administrators"
                      :rules="[$store.state.formrules.required]"
                      return-object
                    />
                  </v-list-item>
                  <v-tabs>
                    <v-tab>Invited Users </v-tab>
                    <v-tab-item>
                      These Users have been invited but have not yet responded
                    </v-tab-item>
                    <v-tab>Invite More </v-tab>
                    <v-tab-item>
                      Invite more Users

                      <v-data-table
                        v-model="UsersInviting"
                        show-select
                        :items="UnInvitedUsers"
                        :headers="NewInviteUsersHeaders"
                      />
                      <v-btn
                        v-if="UsersInviting.length > 0"
                        @click="ProcessInviteToUsers()"
                      >
                        Send Invite</v-btn
                      >
                    </v-tab-item>
                    <v-tab>Invited Users </v-tab>
                    <v-tab-item>
                      <v-select
                        label="Status"
                        v-model="SelectedInviteStatus"
                        :items="InviteStatusOptions"
                        item-text="Name"
                        return-object
                      />
                      <v-data-table
                        v-model="SelectedInvitedUsers"
                        show-select
                        :items="InvitedUsersListFiltered"
                        :headers="OpenInvitesUsersHeaders"
                      />
                      <v-btn v-if="SelectedInvitedUsers.length > 0">
                        Update Invite</v-btn
                      >
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    dark
                    @click="SaveUpdatedGroup()"
                    color="#e4003b"
                    v-if="Changes"
                  >
                    Save Changes
                  </v-btn>
                  <v-btn
                    dark
                    @click="CancelGroupUpdate()"
                    color="#048abf"
                    v-if="Changes"
                  >
                    Cancel Changes
                  </v-btn>
                </v-card-actions>
                    </v-card>
                  </v-card-text>
              </v-card>
            </v-scroll-y-transition>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- <v-layout justify-center style="padding-top: 50px">
      <v-flex xs12 s12 m12 :class="ShowGroupsasPortal ? 'lg12' : 'lg10'">
        <v-tabs light>
          <v-tab v-for="scope in AssignedGroupScope" :key="scope.itemObjKey">
            {{ scope }}
          </v-tab>

          <v-tab-item
            class="tabsinner"
            v-for="scope in AssignedGroupScope"
            :key="scope.itemObjKey"
          >
            <component
              v-if="scope"
              :is="'Group' + scope"
              :UserRecord="UserRecord"
            >
            </component>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout> -->
    <SecondaryNavigation v-if="SecondaryNavigationActive" :style="'position: fixed;bottom: 50px;z-index: 5;'" :BottomNavigationItems="SecondaryNavigationItems" :ThemeColor="'socialmenufont'" :ThemeBGColor="'socialappnavbar'"
    :UsersArray="UsersStore" :CurrentRecordName="groupdata.name" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :BottomMenu="false" :TopMenu="true"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
  </v-main>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import format from "date-fns/format";
import axios from "axios";
import SecondaryNavigation from '@/components/Navigation/BottomNavigation'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
  props: ["UserMembershipid", "System","GroupEntities","Directories",'SubscriptionPackages'],
  middleware: "auth",
  components: {
    SecondaryNavigation,
    FieldValueEditerComponent,
    ContentEditableField
    // GroupArticles,
    // GroupEvents,
    // GroupForums,
    // GroupMeetups,
  },
  data() {
    return {
      GroupSubscriptions: [],
      
      MonetizationPackageDialog: false,
      editedSubscriptionPackage: {
        Name: "",
        Description: "",
        Scope: [],
        Price: 0,
        Type: "",
      },
      editedMonetizaitonIndex: -1,
      defaultMonetizationPackage: {
        Name: "",
        Description: "",
        Scope: [],
        Price: 0,
        Type: "",
      },
      PageOverview: '',	
      PageIcon: '',								
      PageName: '',
      PageRoute: '',
      CatNavList: '',
      LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
      
      TableCollectionRef: '',
      UploadFileListFiles: [],
      UploadFilesList: [],
      GroupAssets: [],
      ArticlesUpdateExternal: false,
      ArticlesExternalfirebaseConfig: "",
      rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      groupdata: "",
      ModeratorTypes: [
        {
          Name: "BlogsModerators",
          Prop: "BlogsModerator",
        },
        {
          Name: "Administrators",
          Prop: "Administrator",
        },
        {
          Name: "ArticlesModerators",
          Prop: "ArticlesModerator",
        },
        {
          Name: "EventsModerators",
          Prop: "EventsModerator",
        },
        {
          Name: "PollsModerators",
          Prop: "PollsModerator",
        },
        {
          Name: "MeetupsModerators",
          Prop: "MeetupsModerator",
        },
        {
          Name: "ForumsModerators",
          Prop: "ForumsModerator",
        },
      ],
      Administrators: [],
      BlogsModerators: [],
      MonetizationChanges: false,
      RefreshingMonetizationOptions: false,
      ArticlesMonetization: '',
      ArticlesPayperView: false,
      ArticlesCreditCharge: 0,
      BlogsMonetization: '',
      BlogsPayperView: false,
      BlogsCreditCharge: 0,
      ClassifiedsMonetization: '',
      ClassifiedsPayperView: false,
      ClassifiedsCreditCharge: 0,
      EventsMonetization: '',
      EventsPayperView: false,
      EventsCreditCharge: 0,
      ForumsMonetization: '',
      ForumsPayperView: false,
      ForumsCreditCharge: 0,
      MeetupsMonetization: '',
      MeetupsPayperView: false,
      MeetupsCreditCharge: 0,
      PollsMonetization: '',
      PollsPayperView: false,
      PollsCreditCharge: 0,
      ArticlesModerators: [],
      ArticlesMonetization: '',
      EventsModerators: [],
      PollsModerators: [],
      MeetupsModerators: [],
      ForumsModerators: [],
      // ScopeComponent: '',
      // GroupScope: [],
      AssignedGroupScope: [],
      snackbar: null,
      // GroupCategoryTypes: [],
      UserRecord: {},
      PrivacyTypes: [
        { index: 1, text: "Invite Only" },
        { index: 2, text: "Non Guest Site Members" },
        { index: 3, text: "All Site Members" },
        { index: 4, text: "Public" },
      ],
      PublishStatusTypes: [
        { index: 0, text: "Draft" },
        { index: 1, text: "Published" },
      ],
      EndorsingNetwork: null,
      WhocanJoin: '',
      MembershipbyApproval: false,
      GroupisNetwork: false,
      Changes: false,
      ChangestoGroupStatus: false,
      ChangestoGroupPrivacy: false,
      ChangestoJoinPrivacy: false,
      GroupisPrivate: false,
      GroupisMembersOnly: false,
      GroupPrivacy: null,
      GroupPublishStatus: null,
      GroupCategory: null,
      BlogPrivacy: null,
      EventsPrivacy: null,
      ArticlesPrivacy: null,
      PollsPrivacy: null,
      MeetupsPrivacy: null,
      MembersPrivacy: null,
      ForumPrivacy: null,
      // userid: this.$store.state.user.id,
      UserisAdmin: false,
      UserisMember: false,
      UserhasAccess: false,
      blogdialog: false,
      selectedfile: null,
      selectedGroupImage: null,
      GroupImagelocalURL: null,
      groupblogs: [],
      groups: [],
      content: null,
      logo: "",
      image: "",
      Description: null,
      name: null,
      editedItem: {
        GroupCategory: null,
        name: null,
        content: null,
        DefaultPage: null,
        Description: null,
        image: null,
        BlogPrivacy: null,
        EventsPrivacy: null,
        ArticlesPrivacy: null,
        PollsPrivacy: null,
        MeetupsPrivacy: null,
        MembersPrivacy: null,
        ForumPrivacy: null,
        GroupPublishStatus: null,
        EndorsingNetwork: null,
        GroupPrivacy: null,
        WhocanJoin: '',
        MembershipbyApproval: false,
        PostPrivacy: null,
        PostsbyApproval: false,
        WhocanPost: null,
        GroupPrivacyIcon: null,
        AssignedGroupScope: []
      },
      defaultItem: {
        GroupCategory: null,
        name: null,
        content: null,
        DefaultPage: null,
        Description: null,
        image: null,
        BlogPrivacy: null,
        EventsPrivacy: null,
        ArticlesPrivacy: null,
        PollsPrivacy: null,
        MeetupsPrivacy: null,
        MembersPrivacy: null,
        ForumPrivacy: null,
        GroupPublishStatus: null,
        EndorsingNetwork: null,
        GroupPrivacy: null,
        WhocanJoin: '',
        MembershipbyApproval: false,
        PostPrivacy: null,
        PostsbyApproval: false,
        WhocanPost: null,
        GroupPrivacyIcon: null,
        AssignedGroupScope: []
      },
      blogtitle: null,
      blogcontent: null,
      image: null,
      helpsheet: null,
      ViewToggle: false,
      PostsView: false,
      MonetizationView: false,
      WebsiteView: false,
      GeneralView: true,
      BlogsView: false,
      EventsView: false,
      MeetupsView: false,
      AdminView: false,
      ArticlesView: false,
      PollsView: false,
      ForumsView: false,
      NewInviteUsersHeaders: [
        { text: "Title", value: "Title", class: "overline black--text" },
        { text: "FullName", value: "Full_Name", class: "overline black--text" },
        { text: "Name", value: "Name", class: "overline black--text" },
        { text: "Surname", value: "Surname", class: "overline black--text" },
        { text: "Gender", value: "Gender", class: "overline black--text" },
        {
          text: "Business Unit",
          value: "Business_Unit.Name",
          class: "overline black--text",
        },
      ],
      OpenInvitesUsersHeaders: [
        { text: "Title", value: "Title", class: "overline black--text" },
        { text: "IsMember", value: "IsMember", class: "overline black--text" },
        {
          text: "Invite Status",
          value: "Invitation.Status.Name",
          class: "overline black--text",
        },
        { text: "FullName", value: "Full_Name", class: "overline black--text" },
        { text: "Name", value: "Name", class: "overline black--text" },
        { text: "Surname", value: "Surname", class: "overline black--text" },
        { text: "Gender", value: "Gender", class: "overline black--text" },
        {
          text: "Business Unit",
          value: "Business_Unit.Name",
          class: "overline black--text",
        },
      ],
      SelectedInviteStatus: { Name: "Invited", ID: 1000001 },
      InviteStatusOptions: [
        { Name: "Invited", ID: 1000001 },
        { Name: "Accepted", ID: 1000002 },
        { Name: "Ignored", ID: 1000003 },
      ],
      SelectedInvitedUsers: [],
      UsersInviting: [],
      Invitations: [],
      Invitees: [],
      GroupScopeOptions: [
        {
          DisplayName: "Articles",
          Path: "/Articles",
          Boolean: true,
        },
        {
          DisplayName: "Blogs",
          Path: "/Blogs",
          Boolean: true,
        },
        {
          DisplayName: "Classifieds",
          Path: "/Classifieds",
          Boolean: true,
        },
        {
          DisplayName: "Events",
          Path: "/Events",
          Boolean: true,
        },
        {
          DisplayName: "Forums",
          Path: "/Forums",
          Boolean: true,
        },
        // {
        // DisplayName: 'Groups',
        // Path: '/Groups',
        // Boolean: true,
        // },
        {
          DisplayName: "Meetups",
          Path: "/Meetups",
          Boolean: true,
        },
        {
          DisplayName: "Polls",
          Path: "/Polls",
          Boolean: true,
        },
      ],
      MemberSearch: '',
      SimliarGroupsArray: [],
      BaseGroupContentTypes: [
        "Group Articles",
        "Group Blogs",
        "Group Classifieds",
        "Group Events",
        "Group Forums",
        "Group Meetups",
        "Group Polls",
      ],
    };
  },
  computed: {
    GroupSubscriptionPackages(){
      return this.ActiveGroup && this.ActiveGroup.SubscriptionPackages ? this.ActiveGroup.SubscriptionPackages : []
    },
    GroupOwnershipPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Group Ownership'
      })
    },
    groupmembers(){
            return this.ActiveGroup && this.ActiveGroup.GroupMembers ? this.ActiveGroup.GroupMembers : []
        },
    ActiveGroup(){
        return this.$store.state.ActiveGroup
    },
    MonetizationChannels(){
      return this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.GroupContentMonetization ?
      this.GroupSubscriptionPackage.GroupContentMonetization.filter(channel => {
        return channel.Can_Monetize
      }).map(channel => {
        //'BlogsMonetization' CHECK 'BlogsPayperView' 'BlogsCreditCharge' actually credit charge is if credit charge is fixed so "blogCreditsFixed" and if so "credit carge"
       channel.ScopeName = channel.Name.split('Group ').join('')
       channel.ScopeItem = this.ComputedAssignedGroupScope.find(obj => obj.title === channel.ScopeName)
       
        //or e.g. ChannelMain = this[channel.Name.split('Group ')+'Monetization'], while on save we loop through channels to update group...update({[channel.Name.split('Group ')+'Monetization']: channel.ChannelMain})
        channel.MonetizationSettings =  this[channel.ScopeName+'Monetization']
        channel.PayperView =  this[channel.ScopeName+'PayperView']
        channel.CreditCharge =  this[channel.ScopeName+'CreditCharge']
        channel.PrivacySettings = this[channel.ScopeName+'Privacy']
        if(channel.Name === 'Group Blogs'){
          channel.PrivacySettings = this.BlogPrivacy
        }
        if(!channel.PrivacySettings){
          channel.PrivacySettings = 'Nobody'
        }
        return channel
     }).filter(channel => {
       return this.AssignedGroupScope.includes(channel.ScopeName)
     }) : []
    },
    
    GroupDirectoryPackages(){
      return this.SubscriptionPackages
      .filter(subpack => {
        return subpack.Type.Name === 'Group Directory'
      })
      .filter(pack => {
        let oncheck = this.GroupSubscriptions.find(obj => obj.Packageid === pack.id)
        return oncheck
      })
    },
    GroupContentPackageScopeOptions(){
      // return this.BaseGroupContentTypes.filter(type => {
      //   return this.CanMonetizeContent(type)
      // })
      return this.MonetizationChannels
      .filter(channel => {
        return channel.MonetizationSettings === 'Membership Packages'
      })
      .map(channel => {
        return channel.Name
      })
      //CanMonetizeContent('Group Blogs')
    },
    PackagesRef(){
      return this.GroupDB.collection('SubscriptionPackages')
    },
    UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
    GroupBillingAccount(){
      if(this.groupdata.BillingAccount){
        return this.UserBillingAccounts.find(obj => obj.id === this.groupdata.BillingAccount.id)
      }
    },
    GroupSubscriptionPackage(){
      //console.log(this.GroupBillingAccount,this.SubscriptionPackages)
      if(this.GroupBillingAccount){
        return this.SubscriptionPackages.find(obj => obj.id === this.GroupBillingAccount.Subscription_Packageid)
      }
    },
    GroupsArray(){
      return this.$store.state.SiteGroupsArray ? this.$store.state.SiteGroupsArray : this.SimliarGroupsArray
    },
    GroupsQuery(){
      return this.$store.state.SiteGroupsQuery.map((query,queryindex) => {
        query = query.where(this.LowestCatLevel.FieldName,'==',this.groupdata[this.LowestCatLevel.FieldName])
        //the funamental flaw in this, is that we now break the full query. Essentially the system will now not call the std query , have to loop ourselves
        return query
      })
    },
    GroupCatField(){
       if(this.System.Group_Categories && this.groupdata && this.groupdata.GroupCategory){
        let groupent = this.$store.state.SocialEntities.find(obj => obj.id === 'Site_Groups') 
        return groupent.AllFields.find(obj => obj.id === 'Group_Categories')
       }
       else{
         return ''
       }
    },
    LowestCatLevel(){
      if(this.GroupCatField){
        //console.log('GroupCatField',this.GroupCatField)
      let levels = ''
      let levellength = 0
      if(this.GroupCatField.Levels){
        levels = this.GroupCatField.Levels
        levellength = levels.length
        let match = ''
        for(var x = 0; x < levellength; x++){         
          match = levels[levellength-x-1]
          if(match){
            break
          }
        }
        return match
      }
      }
      else{
        return ''
      }
    },
    KeyWordOptions(){
      //console.log('LowestCatLevel',this.LowestCatLevel,this.$store.state.SiteGroupsArray,this.SimliarGroupsArray)
      if(this.LowestCatLevel){
      return this.SimliarGroupsArray.filter(group => {
        return group.KeyWords
      }).map(group => {
        return group.KeyWords
      }).flat()
      }
      else{
        return []
      }
      
    },
    CurrentEntity(){
      let entity = {id: 'Group_Categories',HidePrice: true,TaggedFields: [],AdditionalFields: [],NavList: [],CategoricalListType: 'Social Groups',DisplayName: 'Social Groups',SingleName: 'Social Group',AllFields: [
                            {Name: 'name',DisplayName: 'Name',Type: 'Single Line Text',Primary: true},
                            {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text'},
                            {Name: 'Logo',DisplayName: 'Logo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                            {Name: 'Telephone',DisplayName: 'Telephone',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                        ],FeatureList: []}
      entity.AllFields = entity.AllFields.concat(this.GroupCatNavlistFields)
      return entity
    },
    TeamMembers(){
      return this.groupdata && this.groupdata.TeamMembers ? this.groupdata.TeamMembers : [] 
    },
    Teamgroupmembers(){
      return this.groupmembers.filter(member => {
        return this.TeamMembers.find(obj => obj === member.id)
      })
    },
    SecondaryNavigationActive(){
      return this.groupdata
    },
    UserDBNavList(){
      if(this.userLoggedIn && this.userLoggedIn.DBNavList){
        //console.log('this.userLoggedIn.DBNavList',this.userLoggedIn.DBNavList)
        let list = this.DBNavLists.find(obj => obj.id === this.userLoggedIn.DBNavList.id)
        if(list){
          return list
        }
      }
    },
    SecondaryNavigationItems(){
      let items = [
        {
          title: 'Back',
          icon: 'mdi-chevron-left',
          ItemPass: true,
          IsRoute: true,                    
          Path: '/Group/'+this.$route.params.id
        },
        {
          title: 'Menu',
          icon: 'mdi-menu',
          ItemPass: true,
          Children: this.MenuItems
        },
        {
          title: 'Social',
          icon: 'mdi-home-group',
          ItemPass: true,
          Children: [
            {title: 'Glossary',IsRoute: true, Path: '/Glossary',icon: 'mdi-file-word',ItemPass: true},
            {title: 'Members',IsRoute: true,Path: '/SiteMembers',Boolean: false,icon: 'mdi-tag-faces',ItemPass: true},
            {title: 'Articles',IsRoute: true,Path: '/Articles',Boolean: true,icon: 'mdi-newspaper',ItemPass: this.PluginDataBase.Site_Articles},
            {title: 'Blogs',IsRoute: true,Path: '/Blogs',Boolean: true,ItemPass: this.PluginDataBase.Site_Blogs},
            {title: 'Classifieds',IsRoute: true,Path: '/Classifieds',icon: 'mdi-newspaper-variant',Boolean: true,ItemPass: this.PluginDataBase.Classifieds},
            {title: 'Events',IsRoute: true,Path: '/Events',Boolean: true,ItemPass: this.PluginDataBase.Site_Events},
            {title: 'Forums',IsRoute: true,Path: '/Forums',icon: 'mdi-forum-outline',Boolean: true,ItemPass: this.PluginDataBase.Site_Forums},
            {title: 'Groups',IsRoute: true,Path: '/Groups',Boolean: true,icon: 'mdi-account-group',ItemPass: this.PluginDataBase.Site_Groups},
            {title: 'Meetups',IsRoute: true,Path: '/Meetups',Boolean: true,icon: 'mdi-human-greeting-proximity',ItemPass: this.PluginDataBase.Site_Meetups}, 
            {title: 'Polls',IsRoute: true,Path: '/Polls',Boolean: true,icon: 'mdi-vote',ItemPass: this.PluginDataBase.Site_Polls},
            
          ]
          },
      ]
      return items
    },
    PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
    MenuItems(){
      return this.ComputedAssignedGroupScope.map(scope => {
        let scopeobj = {
          title: scope.title,
          Method: 'ActivateSection',
          Prop: scope,
          icon: scope.icon,
          ItemPass: true,
        }
        if(scope.PreDialog){
          scopeobj.PreDialog = scope.PreDialog
          scopeobj.Writeup = scope.Writeup
          scopeobj.IMG = scope.IMG
        }
        return scopeobj
      })
    },
    SocialItemInteractMethod(){
    return this.$store.state.SocialItemInteractMethod
    },
    SocialItemInteractMethodProp(){
      return this.$store.state.SocialItemInteractMethodProp
    },
    ComputedGroupEntities(){
      return this.GroupEntities
      .filter(entity => {
        return entity.groupid === this.$route.params.id
      })
    },
    ComputedInviteRestriction(){
      if(this.groupdata.JoinbyInviteOnly){
        return 'Join by Invite Only'
      }
      else if(this.groupdata.NonGuestsOnly){
        return 'Non Guests Only'
      }      
    },
    ComputedIviteRestrictionOptions(){

    },
    
    PostPrivacyTypes(){
      let MembersOnlyGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
      ];
      let NonGuestSiteMembersGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
      ];
      let SiteMembersOnlyGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
        { index: 4, text: "All Site Members" },
      ];
      let PublicGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
        { index: 4, text: "All Site Members" },
        { index: 5, text: "Public" },
      ];
      if (this.editedItem.PostPrivacy === "Team Members Only") {
        return MembersOnlyGroup.filter(item => {
          return item.text === 'Team Members Only'
        })
      }
      if (this.editedItem.PostPrivacy === "Members Only") {
        return MembersOnlyGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
      if (this.editedItem.PostPrivacy === "Non Guest Site Members") {
        return NonGuestSiteMembersGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
      if (this.editedItem.PostPrivacy === "All Site Members") {
        return SiteMembersOnlyGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
      if (this.editedItem.PostPrivacy === "Public") {
        return PublicGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
    },
    JoinPrivacyTypes(){
      return this.ComputedPrivacyTypes.filter(type => {
        return type.text !== 'Public'
      }).filter(type => {
        if(this.GroupPrivacy === 'Non Guest Site Members'){
          return type.text !== 'All Site Members'
        }
        else if(this.GroupPrivacy === 'Invite Only'){
          return type.text !== 'All Site Members' && type.text !== 'Non Guest Site Members'
        }
        else{
          return type
        }
      })
    },
    ComputedPrivacyTypes(){
      return this.PrivacyTypes.filter(type => {
        if(!this.WebPageBuilderAvailable){
          return type.text !== 'Public'
        }
        else{
          return type
        }
      }).filter(type => {
        if(type.text === 'Non Guest Site Members'){
          return !this.$store.state.UserisGuest
        }
        else{
          return type
        }
      })
    },
    WebPageBuilderAvailable(){
      return this.System.Group_Web_Page_Builder_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Web Page Builder') || !this.System.Group_Web_Page_Builder_Paid
    },
    WebFormsAvailable(){
      return this.System.Group_Web_Forms_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Web Forms') || !this.System.Group_Web_Forms_Paid
    },
    TeamsAvailable(){
      return this.System.Group_Teams_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Group Teams') || !this.System.Group_Teams_Paid
    },
    TeamDashboardAvailable(){
      return this.System.Group_Team_Dashboard_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Team Dashboard') || !this.System.Group_Team_Dashboard_Paid
    },
    CustomEntitiesAvailable(){
      return this.System.Group_Custom_Entities_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Custom Entities') || !this.System.Group_Custom_Entities_Paid
    },
    CustomDashboardsAvailable(){
      return this.System.Group_Custom_Dashboards_Paid && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.FeatureScope.includes('Custom Dashboards') || !this.System.Group_Custom_Dashboards_Paid
    },
    ComputedPublishStatusTypes(){
      return this.PublishStatusTypes.map(item => {
        if(this.editedItem.IsPaid && item.text === 'Published' && this.editedItem.GroupPublishStatus !== 'Published'){
          item.text = 'Request Publish'
        }
        return item
      })
    },
     UserMemberObj(){
      return this.$store.state.UserMemberObj
    },
    UserisAdministrator() {
      return this.userLoggedIn && this.groupdata && this.groupdata.Administrators.includes(this.userLoggedIn.id)
    },
    MonetizationPackages(){
        if(this.GrouphasPaidOptions){
          return this.System.GroupMonetizationPackages.filter(item => {
            return this.ComputedGroupCategory.MonetizationPackages.includes(item.ID)
          })
        }
        else{
          return []
        }
      },
    GrouphasPaidOptions(){
      return this.ComputedGroupCategory && this.ComputedGroupCategory.MonetizationPackages && this.ComputedGroupCategory.MonetizationPackages.length > 0
    },
    ComputedGroupCategory(){
      if(this.System && this.System.Group_Categories && this.editedItem.GroupCategory){
        return this.System.Group_Categories.Options.find(opt => opt.ID === this.editedItem.GroupCategory.ID)
      }      
    },
    
    ComputedGroupMonetizationPackage(){
      if(this.editedItem.MonetizationPackage && this.GrouphasPaidOptions){
        return this.MonetizationPackages.find(obj => obj.ID === this.editedItem.MonetizationPackage.ID)
      }
    },
    GroupDefinedFeatureListOptions(){
      return this.FeatureListOptions
      .filter(feature => {
        return typeof this.groupdata[feature.Name] !== 'undefined'
      })
    },
    FeatureListOptions(){
      return this.CatNavList? this.CatNavList.FeatureList : []
    },
    GroupCatNavlistFieldValues(){
      let obj = {}
      this.GroupCatNavlistFields.map(field => {
        obj[field.Name] = this.groupdata[field.Name]  
      })
      return obj
    },
    GroupCatNavlistFields(){
      return this.CatNavList ? this.CatNavList.AdditionalFields : []
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    nonteamgroupmemberssearched(){
      return this.nonteamgroupmembers.filter(member => {
        if(this.MemberSearch){
         return member.UserName.toLowerCase().includes(this.MemberSearch.toLowerCase()) 
        }
        else{
          return member
        }
      })
    },
    nonteamgroupmembers(){
      return this.Computedgroupmembers.filter(member => {
        return member.Userid !== this.userLoggedIn.id && !member.IsTeamMember
      })
    },
    teamgroupmembers(){
      return this.Computedgroupmembers.filter(member => {
        return member.Userid === this.userLoggedIn.id || member.IsTeamMember
      })
    },
    Computedgroupmembers(){
        return this.groupmembers.map(member => {
          let userobj = this.UsersStore.find(obj => obj.id == member.Userid)
          if(userobj && userobj.Profile_Photo){
          member.Profile_Photo = userobj.Profile_Photo
          }
          return member
        })
      },
    ComputedGroupEntities(){
      return this.GroupEntities.filter(entity => entity.groupid === this.$route.params.id)
    },
    DefaultPageOptions(){
      let arr1 = this.groupdata.Pages.map(page => {
        let obj = {
          Name: page.Name,
          Type: 'Group Page'
        }
        return obj
      })
      let arr2 = this.GroupScopeOptions.map(opt => {
        let obj = {
          Name: opt.DisplayName,
          Type: 'Group Component'
        }
        return obj
      })
      let arr3 = [
        {
          Name: 'Wall',
          Type: 'Social Page'
        }
      ]
      return arr1.concat(arr2,arr3)
    },
    GroupScope() {
      return this.GroupScopeOptions
      .filter((socialcomp) => {
        return this.GroupContentAvailable(socialcomp.DisplayName)
      })
      .map((item) => {
        return item.DisplayName;
      });
    },
    GroupClassComputed(){
      return this.GroupCategoryComputed && this.GroupCategoryComputed.Options && this.editedItem[this.System.Group_Categories.Levels[1].FieldName] ? this.GroupCategoryComputed.Options.find(obj => obj.ID === this.editedItem[this.System.Group_Categories.Levels[1].FieldName].ID) : ''
    },
    GroupCategoryComputed(){
      return this.editedItem.GroupCategory ? this.GroupCategoryTypes.find(obj => obj.ID === this.editedItem.GroupCategory.ID) :''
    },
    GroupCategoryTypes() {
      if (
        this.System &&
        this.System.Group_Categories &&
        this.System.Group_Categories.Options
      ) {
        return this.System.Group_Categories.Options;
      } else {
        return [];
      }
    },
    InvitedUsersIdStrings() {
      return this.UsersInviting.map((user) => {
        return user.id;
      });
    },
    UnInvitedUsers() {
      return this.UsersStore.filter((user) => {
        let check = this.Invitees.find((obj) => obj === user.id);
        if (!check) {
          return user;
        }
      });
    },
    groupid(){
      return this.$route.params.id
    },
    InvitedUsersList() {
      return this.UsersStore.map((user) => {
        let userobj = Object.assign({},user)
        let check = this.Invitees.find((obj) => obj === userobj.id);
        if (check) {
          userobj.Invitation = check
          return userobj;
        }
      })
        .map((user) => {          
          let membercheck = this.groupmembers.find(
            (obj) => obj.Userid === user.id
          );
          if (membercheck) {
            user.IsMember = true;
          }
          return user;
        })
        .filter((user) => {
          return !user.IsMember && userobj.Invitation
        });
    },
    InvitedUsersListFiltered() {
      return this.InvitedUsersList.filter((user) => {
        return (
          typeof user.Invitation !== "undefined" &&
          user.Invitation.Status &&
          user.Invitation.Status.ID === this.SelectedInviteStatus.ID
        );
      });
    },
    UsersStore() {
      return this.$store.state.UsersArray;
    },
    BlogModeratorsID() {
      return this.BlogsModerators.map((mod) => {
        return mod.Userid;
      });
    },
    ArticlesModeratorsID() {
      return this.ArticlesModerators.map((mod) => {
        return mod.Userid;
      });
    },
    EventsModeratorsID() {
      return this.EventsModerators.map((mod) => {
        return mod.Userid;
      });
    },
    PollsModeratorsID() {
      return this.PollsModerators.map((mod) => {
        return mod.Userid;
      });
    },
    MeetupsModeratorsID() {
      return this.MeetupsModerators.map((mod) => {
        return mod.Userid;
      });
    },
    ForumsModeratorsID() {
      return this.ForumsModerators.map((mod) => {
        return mod.Userid;
      });
    },
    AdministratorsID() {
      return this.Administrators.map((mod) => {
        return mod.Userid;
      });
    },
    
    ComputedAssignedGroupScope() {
      let arr1 = [];
      let GeneralObj = {
        title: "General",
        Prop: "GeneralView",
        icon: "mdi-book-information-variant",
        PreDialog: true,
        IMG: require('@/assets/logo.png'),
        Writeup: `<p>General Settings for your Group such as
        <ul>
          <li>Description</li>
          <li>Listed Phone Number</li>
          <li>Default Landing Page</li>
          <li>Publish Status</li>
          <li>Group Privacy</li>
          <li>Group Scope</li>
        </ul></p>`
        
      };
      let PostObj = {
        title: "Posts",
        Prop: "PostsView",
        icon: "mdi-post",
        PreDialog: true,
        IMG: require('@/assets/logo.png'),
        Writeup: `<p>Configure who can view and create Posts </p>`
      };
      let PageObj = {
        title: "Website",
        Prop: "WebsiteView",
        icon: "mdi-search-web",
        PreDialog: true,
        IMG: require('@/assets/logo.png'),
        Writeup: `<p>Configure your Website</p>`
      };
      let AdminObj = {
        title: "Admin",
        Prop: "AdminView",
        icon: "mdi-eye",
        PreDialog: true,
        IMG: require('@/assets/logo.png'),
        Writeup: `<p>If you own a RapidApps solution you can integrate it with this Group to syncronize:
          <ul>
          <li>Articles, Blogs etc.</li>
          <li>Data Tables</li>
        </ul>
        </p>`
      };
      arr1.push(GeneralObj);
      let monetizationobj = {
          icon: 'mdi-cash-register',
          title: 'Monetization',
          Prop: "MonetizationView",
          PreDialog: true,
          IMG: require('@/assets/logo.png'),
          Writeup: `<p>Set up Monetization and earn from your Group with options by creating and manging Subscription Packages, or earn using credit Tokens. Royalties to `+this.System.Name+` may apply</p>`
        }
       if(this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.Can_Monetize_Content){
      // || this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.GroupContentMonetization && this.GroupSubscriptionPackage.GroupContentMonetization.length > 0){
        arr1.push(monetizationobj)
      }
      else{
        let mononcheck = arr1.find(obj => obj.title === 'Monetization')
        if(mononcheck){
          let index = arr1.indexOf(mononcheck)
          arr1.splice(index,1)
        }
      }
      arr1.push(PostObj)
      if(this.WebPageBuilderAvailable){
        arr1.push(PageObj)
      }     
            
      arr1.push(AdminObj);
      let arr2 = [];
      this.AssignedGroupScope.map((scope) => {
        let scopeobj = {
          title: scope,
          Prop: scope + "View",
          PreDialog: true,
          IMG: require('@/assets/logo.png'),
          Writeup: `<p>Configure who can view and create `
        };
        if (scope === "Blogs") {
          scopeobj.icon = "mdi-typewriter";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        if (scope === "Classifieds") {
          scopeobj.icon = "mdi-newspaper";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        if (scope === "Articles") {
          scopeobj.icon = "mdi-email-newsletter";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        if (scope === "Polls") {
          scopeobj.icon = "mdi-vote";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        if (scope === "Forums") {
          scopeobj.icon = "mdi-forum";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        if (scope === "Events") {
          scopeobj.icon = "mdi-calendar-outline";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        if (scope === "Meetups") {
          scopeobj.icon = "mdi-account-supervisor-circle";
          scopeobj.Writeup = scopeobj.Writeup+` `+scope+` </p>`
        }
        arr2.push(scopeobj);
      });
     
      return arr1.concat(arr2);
    },
    SubPrivacyTypes() {
      //Right now Team Members Only applies only when "TeamDashboardAvailable", which is not what we base this on
      //we allow you to have teams, in orde to better control and stuff. maybe basically, i should be "teamembersallowed" which could refer to monetization or if no monetization
      //point being we need to make this work through monetization one way or another. 
      
      let MembersOnlyGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
      ];
      let NonGuestSiteMembersGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
      ];
      let SiteMembersOnlyGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
        { index: 4, text: "All Site Members" },
      ];
      let PublicGroup = [
        { index: 1, text: "Team Members Only" },
        { index: 2, text: "Members Only" },
        { index: 3, text: "Non Guest Site Members" },
        { index: 4, text: "All Site Members" },
        { index: 5, text: "Public" },
      ];

      if (this.GroupPrivacy === "Invite Only") {
        return MembersOnlyGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
      if (this.GroupPrivacy === "Non Guest Site Members") {
        return NonGuestSiteMembersGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
      if (this.GroupPrivacy === "All Site Members") {
        return SiteMembersOnlyGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
      if (this.GroupPrivacy === "Public") {
        return PublicGroup.filter(item => {
          if(item.text === 'Team Members Only'){
            return this.TeamsAvailable
            //return item
          }
          else{
            return item
          }
        })
      }
    },
    GroupPrivacyIcon() {
      if (this.editedItem.GroupPrivacy === "Invite Only") {
        return "mdi-lock";
      }
      if (this.editedItem.GroupPrivacy === "Non Guest Site Members") {
        return "mdi-account-group-outline";
      }
      if (this.editedItem.GroupPrivacy === "All Site Members") {
        return "mdi-account-group";
      }
      if (this.editedItem.GroupPrivacy === "Public") {
        return "mdi-globe-model";
      }
    },
    GroupPublishStatusIcon() {
      if (this.GroupPublishStatus === "Draft") {
        return "mdi-eye-off";
      }
      if (this.GroupPublishStatus === "Published") {
        return "mdi-eye";
      }
    },
    GroupPublishStatusText() {
      if (this.GroupPublishStatus === "Draft") {
        return "NOT VISIBLE";
      }
      if (this.GroupPublishStatus === "Published") {
        return "VISIBLE AND PUBLISHED";
      }
    },
    GroupPublishStatusColor() {
      if (this.GroupPublishStatus === "Draft") {
        return "redText";
      }
      if (this.GroupPublishStatus === "Published") {
        return "greenText";
      }
    },
    GroupPublishStatusIconColor() {
      if (this.GroupPublishStatus === "Draft") {
        return "red";
      }
      if (this.GroupPublishStatus === "Published") {
        return "green";
      }
    },
    GroupPublishStatusPreviewGradient() {
      if (this.GroupPublishStatus === "Draft") {
        return "to top, rgba(12,12,12,1), rgba(12,12,12,0.5), rgba(12,12,12,1)";
      }
      if (this.GroupPublishStatus === "Published") {
        return "to top, rgba(12,12,12,1), rgba(22,59,75,0.92), rgba(112,203,243,1)";
      }
    },
    ActiveTemplate(){
      return this.$store.state.ActiveTemplate
    },
    GroupRoot(){
      return this.ActiveTemplate ? '' :
      db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
    },
    GroupDB(){
      return this.ActiveTemplate ? '' :
      this.GroupRoot.collection('groups').doc(this.$route.params.id)
    },
    GroupEntitiesDB(){
      return this.GroupDB.collection('entities')
    },
    AdvancedComputedLibraryColors(){
      let arr1 = this.LibraryColors
      let arr2 = [
        'primary',
        'accent',
        'secondary',
        'pop',
        'morning',
        'night',
        'fieldcard',
        'links',
        'background',
      ]
      let arr3 = arr1.concat(arr2)
      let arr4 = []
      arr3.map(color => {
        arr4.push(color)
        arr4.push(color+' lighten-1')
        arr4.push(color+' lighten-2')
        arr4.push(color+' lighten-3')
        arr4.push(color+' lighten-4')
        arr4.push(color+' lighten-5')
        arr4.push(color+' darken-1')
        arr4.push(color+' darken-2')
        arr4.push(color+' darken-3')
        arr4.push(color+' darken-4')
        arr4.push(color+' darken-5')
        arr4.push(color+' accent-1')
        arr4.push(color+' accent-2')
        arr4.push(color+' accent-3')
        arr4.push(color+' accent-4')
      })
      return arr4
    },
  },
  
   watch: {
     
     ViewToggle(v){
       if(v){
        this.ScrolltoTop() 
       }
       
     },
     ComputedAssignedGroupScope: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('setGroupScopeTabs',newvalue)
              }
            },deep: true
     },
     SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateSection']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
     LowestCatLevel: {
            handler: function(oldvalue, newvalue) {      
              if(oldvalue !== newvalue && this.LowestCatLevel){
               if(this.GroupsQuery){
                 //GroupsArray
                  if(this.$store.state.SiteGroupsArray && this.$store.state.SiteGroupsArray.length > 0){

                  }
                  else{
                    this.GroupsQuery.map(query => {
                     //this.GetSimilarGroups(query) 
                    })                    
                  }
                }                         
              }
            },
            deep: true
        },
    GroupCatNavlistFieldValues: {
            handler: function(oldvalue, newvalue) {      
              if(oldvalue !== newvalue){
               console.log('GroupCatNavlistFieldValues',this.GroupCatNavlistFieldValues)   
               if(this.GroupCatNavlistFieldValues){
                 this.Changes = true
               }                         
              }
            },
            deep: true
        },
      ActiveGroup: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.getGroupInformation()
                }
            },
            deep: true
    },
   },
  created() {
    this.ScrolltoTop()
    // console.log("groupmembers", this.groupmembers);
    //this.pushedgroupmembers = JSON.parse(JSON.stringify(this.groupmembers))
    // console.log(this.groupid);
    if(this.ActiveGroup){
      this.getGroupInformation();
    }
    
    this.FocusedViewToggle();
    this.$emit("IntranetViewToggle", false);
    if(this.BuilderView && this.BuilderView.Name === 'Database Builder'){
    
    }
    else{
      this.$store.commit('setBuilderView',{Name: 'Social Network Builder'})
    }
    if(this.ComputedAssignedGroupScope && this.ComputedAssignedGroupScope.length > 0){
      this.$store.commit('setGroupScopeTabs',this.ComputedAssignedGroupScope)
    }
    this.GetRequestingUser();
    
        
  },
  methods: {
    CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    UpdatePayoutMinimum(){
      this.GroupDB.update({
        Payout_Minimum: this.groupdata.Payout_Minimum
      })
    },
    PayoutMinimumRule(item){
        return  [ 
              v => !!v || "This field is required",
              v => ( v && v >= this.System.Payout_Minimum ) || "Should be no less "+this.System.Payout_Minimum,
          ]
      },
    ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    
      CloseMarketDialog(){
        this.$store.commit('EmitMarketDialogObject','')
        this.$store.commit('EmitMarketDialogOptions',[])
        this.$store.commit('EmitMarketDialogType','')
        this.$store.commit('EmitMarketDialogDescription','')
        this.$store.commit('EmitMarketDialogTitle','')        
        this.$store.commit('EmitMarketDialogAvatar','')
        this.$store.commit('EmitMarketDialog',false)
      },
    
    SeeAvailableDirectoryPackages(){
      console.log(this.GroupDirectoryPackages)
      this.$store.commit('EmitMarketDialogObject',this.groupdata)
      this.$store.commit('EmitMarketDialogOptions',this.GroupDirectoryPackages)
      this.$store.commit('EmitMarketDialogType','Group Directory Listing')
      this.$store.commit('EmitMarketDialogDescription',`<p>Below are the packages avilable to choose from for `+this.groupdata.name+`</p>`)
      this.$store.commit('EmitMarketDialogTitle',this.groupdata.name+' Packages')        
      this.$store.commit('EmitMarketDialogAvatar',this.groupdata.logo)
      this.$store.commit('EmitMarketDialogInteractMethod','SelectPackage')
      this.$store.commit('EmitMarketDialog',true)
    },
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      console.log(this.field,FieldObject,this.Record)
    },
    
    SaveMonetizationPackage(keepdialog) {
      let ref = this.PackagesRef.doc()
      let newdocid = ref.id
      if(this.editedSubscriptionPackage.id){
        newdocid = this.editedSubscriptionPackage.id
      }
      this.PackagesRef.doc(newdocid).set(this.editedSubscriptionPackage)
      if(!keepdialog){
        this.CancelMonetizaitonPackageDialog();
      }      
    },
    CancelMonetizaitonPackageDialog() {
      this.MonetizationPackageDialog = false;
      this.editedSubscriptionPackage = Object.assign({},
        this.defaultMonetizationPackage
      );
    },
    ActivateMonetizationPackagesDialog(item) {
      if (item) {
        this.editedSubscriptionPackage = item;
      }
      else{
        let typeobj = this.$store.state.SubscriptionPackageTypes.find(obj => obj.Name === 'Group Membership')
          this.editedSubscriptionPackage.Type = typeobj
      }
      this.MonetizationPackageDialog = true;
    },
    UpdateGroupMembershipCharge(){
      this.GroupDB.update({
        MembershipCharge: this.groupdata.MembershipCharge
      })
    },
    CanMonetizeContent(contenttype){
      let contentmonetizationoptions = []
      if(this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.GroupContentMonetization){
        contentmonetizationoptions = this.GroupSubscriptionPackage.GroupContentMonetization
        .filter(opt => {
          return opt.Can_Monetize
        })
        .map(opt => {
          //because these optiosn have "Name" and "Exempt from Royalties"
          return opt.Name
        })
      }
      console.log(contentmonetizationoptions)
      return contentmonetizationoptions.includes(contenttype)
    },
    GetSimilarGroups(query){
        query.onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach((change) => {
            if (change.type === "added") {
              this.SimliarGroupsArray.push({
                ...change.doc.data(),
                id: change.doc.id,
              });
            }
          });
        })
    },
    onPhotoFileselected(field,file){
        field.localURL = URL.createObjectURL(file)
        field.UploadFile = file
        // this.Record[field.Name+'localURL'] = 
        console.log(this.Record,field,file)
      },
    UploadFileSelect(file,field,Record){
      console.log(file,field,Record)
      field.UploadFile = file
      field.FileName = file.name
    },
    SelectBottomNavMenuItem(item){
      if(!item.Children && !item.Method && !item.RouteBlank){
        this.$router.push(item.Path)
      }
      else if(item.Method){
        if(item.Method === 'IntranetViewToggle' && item.Prop === true){
          this[item.Method](item.Prop,true)
        }
        else if(item.SecondProp){
          this[item.Method](item.Prop,item.SecondProp)
        }
        else{
          this[item.Method](item.Prop)
        }
      }
    },
    UpdateDefaultPage(){
      // if(this.editedItem.DefaultPage){
            this.GroupDB.update({
            DefaultPage: this.editedItem.DefaultPage,
          })
        // } 
    },
    CancelPageAdd(){
      this.NewPagedialog = false
      this.PageName = ''
      this.PageIcon = ''
      this.PageRoute = ''
      this.PageOverview = ''
    },
    ShowGroupsasPortal(){
      return !this.System.GroupSinglePortal
    },
    AddNewPage(){
      let pageobj = {
        Name: this.PageName,
        Overview: this.PageOverview,
        PageBGAsset: '',
        PageBGGradient: '',
        PageContentFont: 'Montserrat',
        PageHeadersFont: 'Raleway',
        PageHeadersFontSize: 42,
        PageIcon: '',
        PageRoute: this.PageRoute.split(' ').join('-'),
        PageTabs: [],
        PublishType: ''
      }
      this.GroupDB.collection('pages').doc(pageobj.Name.split(' ').join('_')).set(pageobj).then(newpage => {
        this.$router.push('/Group-PageEditor/'+this.$route.params.id+'/'+pageobj.PageRoute)
        this.$emit("IntranetViewToggle", false);
      })
      this.CancelPageAdd()
    },
    GetSubPrivacyTypes(Content){
      let prop = 'Group_'+Content+'_Paid_Type'
      return this.SubPrivacyTypes.filter(type => {
        if(this.System[prop] && this.System[prop] === 'Public' && !this.GroupSubscriptionPackage 
        || this.System[prop] && this.System[prop] === 'Public' && this.GroupSubscriptionPackage && !this.GroupSubscriptionPackage
        || this.System[prop] && this.System[prop] === 'Public' && this.GroupSubscriptionPackage && !this.GroupSubscriptionPackage.ContentScope.includes('Public Group '+Content)){
          return type.text !== 'Public'
        }
        else{
          return type
        }
      })
    },
    // /SubPrivacyTypes
    GroupContentAvailable(Content){
      let prop = 'Group_'+Content+'_Paid_Type'
      //console.log(prop,this.System,this.System[prop],this.GroupSubscriptionPackage,this.groupdata,this.groupdata.BillingAccount,this.GroupBillingAccount)
      return this.System[prop] && this.System[prop] === 'Public' && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.ContentScope.includes('Public Group '+Content)
      || this.System[prop] && this.System[prop] === 'Public' && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.ContentScope.includes('Internal Group '+Content)
      || this.System[prop] && this.System[prop] === 'Internal and Public' && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.ContentScope.includes('Public Group '+Content)
      || this.System[prop] && this.System[prop] === 'Internal and Public' && this.GroupSubscriptionPackage && this.GroupSubscriptionPackage.ContentScope.includes('Internal Group '+Content)
      || !this.System[prop] || this.System[prop] === 'Not Paid'
    },
    SaveSiteAditionalInfo(){
      this.FeatureListOptions.map(feature => {
        if(typeof this.groupdata[feature.Name] !== 'undefined'){
           this.GroupDB.update({
            [feature.Name]: this.groupdata[feature.Name]
          })
        }
      })
    },
    UpdateGroupStyling(){
      if(this.groupdata.NavBar_IMG){
         this.GroupDB.update({
          NavBar_IMG: this.groupdata.NavBar_IMG
        })
      }     
      if(this.groupdata.Telephone){
        this.GroupDB.update({
          Telephone: this.groupdata.Telephone
        })
      }
      if(this.groupdata.KeyWords){
        this.GroupDB.update({
          KeyWords: this.groupdata.KeyWords
        })
      }
      if(this.groupdata.NavbarTextColor){
        this.GroupDB.update({
          NavbarTextColor: this.groupdata.NavbarTextColor
        })
      }
      if(this.groupdata.NavbarBGColor){
        this.GroupDB.update({
          NavbarBGColor: this.groupdata.NavbarBGColor
        })
      }
      if(this.groupdata.NavItemsBGColor){
        this.GroupDB.update({
          NavItemsBGColor: this.groupdata.NavItemsBGColor
        })
      }
      if(this.groupdata.NavItemsTextColor){
        this.GroupDB.update({
          NavItemsTextColor: this.groupdata.NavItemsTextColor
        })
      }
      
    },
    
    SaveTeamMembers(){
      // console.log(this.groupmembers,this.pushedgroupmembers)
      let addedmembers = this.groupmembers.filter(member => {return this.groupmembers.find(obj => obj.Userid === member.Userid && !obj.IsTeamMember)})
      // console.log(addedmembers)
      let removedmembers = this.groupmembers.filter(member => {return this.groupmembers.find(obj => obj.Userid === member.Userid && !obj.IsTeamMember && !obj.Administrator)})
      // console.log(removedmembers)
      addedmembers.map(member => {
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(member.Userid).collection('groups').doc(this.$route.params.id).update({
          IsTeamMember: true
        })
      })
      let TeamMembers = addedmembers.map(member => {return member.Userid})
      this.GroupDB.update({
        TeamMembers: TeamMembers
      })
     
    },
    NavigatetoGroupWebFormEditor(entityid){
         let routeData = this.$router.resolve({name: 'GroupWebFormEditor', query: {GroupID: this.$route.params.id},params: {id: entityid}});
        window.open(routeData.href, '_blank');
      
    },
    NavigatetoGroupWebFormBuilder(){

      let routeData = this.$router.resolve({name: 'GroupWebFormBuilder', query: {GroupID: this.$route.params.id}});
      window.open(routeData.href, '_blank');
    },
    
    CancelUploadLibraryAssets(){
            this.PhotoTitle = ''
            this.PhotoThumbnail = ''
            this.PhotoDescription = ''
            this.PhotoTags = []
            this.UploadFilesList = []
            this.UploadFileListFiles = []
            this.dialog = false
            //this.UploadsPreviews = []
      },

      UploadLibraryAssets(){
          Array.from(Array(this.UploadFilesList.length).keys()).map(x => {
        this.PreparePhotosThumbnail(this.UploadFilesList[x],x,this.UploadFilesList.length)
      })
      },
      PreparePhotosThumbnail(file,index,listlength){
        this.$emit('ActivateProcessing',true)
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
     
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            // console.log(blobfile)
            let ref = 'Group/'+vm.$route.params.id+'/PageAssets/'+new Date()+'/'+ file.name
            // var storageRef = firebase.storage().ref(ref)
            var thumbstorageRef = firebase.storage().ref(ref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = thumbstorageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      // console.log(thumburl)
                      vm.AddLibraryAssetsSingle(file,index,listlength,thumburl,ref)
                    })
              })
      }
      },

      AddLibraryAssetsSingle(file,index,listlength,thumburl,ref) {
        var storageRef = firebase.storage().ref(ref)
        var uploadTask = storageRef.put(file);
        let vm = this
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                 
                      const newphoto = {
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Created_Byid: this.UserRecord.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.lastModifiedDate,
                              Modified_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Modified_Byid: this.UserRecord.id,
                              Modified_On: new Date(),
                              Name: file.name,
                              path: url,
                              Path: url,
                              // DataTags: vm.PhotoTagObjects,
                              // DataTagsid: vm.PhotoTagObjectIDs,
                              StorageRef: ref
                              
                              }
                              // console.log(newphoto)
                    this.TableCollectionRef.add(newphoto).then(photo => {
                      vm.dialog = false
                        vm.$emit('ActivateStandbyDialog',false)
                        let snackbarcontent = {
                          timeout: 4000,
                          text: 'Successfully added the item '+file.name,
                          title: 'Added '+file.name+' to '+this.TableName,
                          show: true,
                          thumbnail: thumburl
                        }
                        vm.$emit('ActivateNotificationSnackbar',true,snackbarcontent)
                        if(index-1+2 === listlength){
                          vm.$emit('ActivateProcessing',false)
                          vm.CancelUploadLibraryAssets()
                        }
                      })
                      })              
      },
      DeletePhoto(photo){
            confirm('Are you sure?') && this.ProcessDeletePhoto(photo)
        },
        ProcessDeletePhoto(photo){
            let vm = this
            let photoindex = this.LibraryAssetsArray.find(obj => obj.id === photo.id)
                let thumstorageRef = firebase.storage().ref(photo.StorageRef+'_thumb');
                
                    let storageRef = firebase.storage().ref(photo.StorageRef);	
                        storageRef.delete().then(function() {									
                            // File deleted successfully
                                    console.log('actual file successfully deleted')
                                thumstorageRef.delete().then(function() {									
                                // File deleted successfully		
                                console.log('thumb file successfully deleted')
                                vm.TableCollectionRef.doc(photo.id).delete().then(function() {
                                    vm.LibraryAssetsArray.splice(photoindex,1)
                                }).catch(function(error) {									
                                // Uh-oh, an error occurred!									
                                });
                                }).catch(function(error) {									
                                // Uh-oh, an error occurred!									
                                });							
                        }).catch(function(error) {									
                            // Uh-oh, an error occurred!									
                        });
        },
    AddLibraryAssets(event){
      this.UploadFilesList = event.target.files || event.dataTransfer.files
      this.UploadFileListFiles = Array.from(Array(this.UploadFilesList.length).keys()).map(file => {
        let tmpsrc = URL.createObjectURL(this.UploadFilesList[file])
        return tmpsrc
      })
      // console.log(this.PhotoTags)
      },
    ProcessInviteToUsers() {
      this.GroupDB.update({
        Invitees: this.InvitedUsersIdStrings,
      });
      this.InvitedUsersIdStrings.map((inviteeid) => {
        let NewGroupInvite = {
          GroupID: this.$route.params.id,
          GroupName: this.groupdata.name,
          Invite_Date: new Date(),
          Invitee: inviteeid,
          Invited_By: {
            Full_Name: this.UserRecord.Full_Name,
            id: this.UserRecord.id,
          },
          Invited_Byid: this.UserRecord.id,
          Status: { ID: 1000001, Name: "Invited" },
        };
        this.GroupDB
          .collection("invites")
          .doc(NewGroupInvite.Invitee)
          .set(NewGroupInvite)
          .then((doc) => {});
      });

      //Which gives these users permissions but they still don't know they were invited so
      //1. Email them
      //2. somehow flag to them they been invited
      //3. somehow the queries should include this or something...what you think? May Social Quicknav needs "Groups I am invited to"?
    },
    CheckifEmpty() {
      let currentuseron = this.Administrators.find(
        (obj) => obj.id === this.UserMembershipid
      );
      if (this.Administrators.length === 0) {
        alert("Cannot have zero Administrators");
        this.Administrators = this.groupmembers.filter((member) => {
          let membercheck = this.groupdata.Administrators.find(
            (obj) => obj === member.Userid
          );
          if (membercheck) {
            return member;
          }
        });
      } else if (
        typeof currentuseron === "undefined" &&
        this.Administrators.length === 1
      ) {
        alert("You cannot remove yourself as Administrator");
        this.Administrators = this.groupmembers.filter((member) => {
          let membercheck = this.groupdata.Administrators.find(
            (obj) => obj === member.Userid
          );
          if (membercheck) {
            return member;
          }
        });
      } else {
        this.ChangesareMade();
      }
    },
    ActivateSection(item) {
      this.ViewToggle = true
      this.GeneralView = false;
      this.MonetizationView = false
      this.WebsiteView = false;
      this.PostsView = false
      this.BlogsView = false;
      this.EventsView = false;
      this.MeetupsView = false;
      this.AdminView = false;
      this.ArticlesView = false;
      this.PollsView = false;
      this.ForumsView = false;
      this[item.Prop] = true;
      setTimeout(() => {
        this.ViewToggle = false
      }, 20);
      // this.ScopeComponent = 'Group'+scope.title
      // console.log(this.ScopeComponent)
    },
    async GetRequestingUser() {
      var vm = this;
      if(this.userLoggedIn){
        this.UserRecord = this.userLoggedIn
      }
      // await firebase.auth().onAuthStateChanged(function (user) {
      //   if (user) {
          
      //     let catnavlist = vm.Directories.find(obj => obj.id === 'Group_Categories')
      //     if(catnavlist){
      //       vm.CatNavList = catnavlist
      //     }          
      //     db.collection("users")
      //       .doc(user.uid)
      //       .onSnapshot((snapshot) => {
      //         var userdetails = snapshot.data();

      //         vm.UserRecord = userdetails;
      //         vm.UserRecord.id = user.uid;
      //       });
      //   }
      // });
    },
    IntranetViewToggle() {
      this.$emit("IntranetViewToggle", true);
    },
    FocusedViewToggle() {
      this.$emit("FocusedViewToggle", false);
    },
    ToggleHelp() {
      this.helpsheet = true;
    },
    GetGroupPages(docref,groupdata){
      docref.collection('pages').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change) => {
          if (change.type === "added") {
            groupdata.Pages.push({
              ...change.doc.data(),
              id: change.doc.id,
            });
          }
        });
      })
    },
    
    GetGroupSubscriptions(){
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('subscriptionorders').where('Groupid','==',this.$route.params.id).onSnapshot(res => {
        const changes = res.docChanges();
                changes.forEach((change) => {
                  if (change.type === "added") {
                    this.GroupSubscriptions.push({
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                })
      })
    },
    getGroupInformation() {
      let docref = this.GroupDB
        this.groupdata = this.ActiveGroup;
        if(!this.groupdata.Payout_Minimum){
          this.groupdata.Payout_Minimum = this.System.Payout_Minimum
        }
        this.GetGroupSubscriptions()
        this.TableCollectionRef = this.GroupDB.collection('pageassets')
        this.GroupAssets = this.groupdata.GroupAssets
        
        // this.groupdata.Pages = []
        // this.GetGroupPages(docref,this.groupdata)
        this.Administrators = this.groupdata.Administrators.map((admin) => {
          let userobj = this.groupmembers.find((obj) => obj.Userid === admin);
          return userobj;
        });
        this.groupmembers.map((member) => {
          this.ModeratorTypes.map((array) => {
            if (this.groupdata.Invitees) {
              this.Invitees = this.groupdata.Invitees;
            }
            this.GroupDB
              .collection("invites")
              .onSnapshot((res) => {
                const changes = res.docChanges();
                changes.forEach((change) => {
                  if (change.type === "added") {
                    this.Invitations.push({
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                });
              });
          });
        });
        this.ModeratorTypes.map((array) => {
          // console.log(array.Name);
          // console.log(groupdata[array.Name]);
          if (this.groupdata[array.Name]) {
            this[array.Name] = this.groupmembers.filter((member) => {
              let membercheck = this.groupdata[array.Name].find(
                (obj) => obj === member.Userid
              );
              if (membercheck) {
                return member;
              }
            });
            //console.log(array.Name, this[array.Name]);
          }
        });

        if (this.groupdata.AssignedGroupScope) {
          this.editedItem.AssignedGroupScope = this.groupdata.AssignedGroupScope;
          this.AssignedGroupScope = this.groupdata.AssignedGroupScope;
        }
        this.EndorsingNetwork = this.groupdata.EndorsingNetwork,
        this.GroupPrivacy = this.groupdata.GroupPrivacy,
        this.GroupCategory = this.groupdata.GroupCategory;
        if(this.groupdata.WhocanJoin){
          this.WhocanJoin = this.groupdata.WhocanJoin
        }
        if(typeof this.groupdata.MembershipbyApproval !== 'undefined'){
          this.MembershipbyApproval = this.groupdata.MembershipbyApproval
        }
        this.PostPrivacy = this.groupdata.PostPrivacy
        this.WhocanPost = this.groupdata.WhocanPost
        this.name = this.groupdata.name;
        this.Description = this.groupdata.Description;
        this.DefaultPage = this.groupdata.DefaultPage
        this.logo = this.groupdata.logo;
        this.GroupPublishStatus = this.groupdata.GroupPublishStatus;
        this.BlogPrivacy = this.groupdata.BlogPrivacy;
        this.BaseGroupContentTypes.map(opt => { 
          let monprop = opt.split(' ').join('').split('Group').join('')+'Monetization'
          let ppvprop = opt.split(' ').join('').split('Group').join('')+'PayperView'
          let credchargeprop = opt.split(' ').join('').split('Group').join('')+'CreditCharge'
          //console.log(monprop)
          if(typeof this.groupdata[monprop] !== 'undefined'){
            this[monprop] = this.groupdata[monprop]
          }
          if(typeof this.groupdata[ppvprop] !== 'undefined'){
            this[ppvprop] = this.groupdata[ppvprop]
          }
          if(typeof this.groupdata[credchargeprop] !== 'undefined'){
            this[credchargeprop] = this.groupdata[credchargeprop]
          }
        })
        this.EventsPrivacy = this.groupdata.EventsPrivacy;
        this.ArticlesPrivacy = this.groupdata.ArticlesPrivacy;
        this.PollsPrivacy = this.groupdata.PollsPrivacy;
        this.MeetupsPrivacy = this.groupdata.MeetupsPrivacy;
        this.MembersPrivacy = this.groupdata.MembersPrivacy;
        (this.ForumPrivacy = this.groupdata.ForumPrivacy),
          (this.editedItem = Object.assign({}, this.groupdata));
          if(!this.editedItem.AssignedGroupScope){
            this.editedItem.AssignedGroupScope = []
          }
          if(!this.editedItem.WhocanJoin){
            this.editedItem.WhocanJoin = ''
          }
          if(!this.editedItem.PostPrivacy){
            this.editedItem.PostPrivacy = ''
          }
          
          if(!this.editedItem.WhocanPost){
            this.editedItem.WhocanPost = ''
          }
          if(typeof this.groupdata.MembershipbyApproval === 'undefined'){
            this.editedItem.MembershipbyApproval = false
          }
           if(typeof this.groupdata.PostsbyApproval === 'undefined'){
            this.editedItem.PostsbyApproval = false
          }
        //console.log(this.editedItem);

        this.GroupDB
          .collection("adminonly")
          .doc("backendconfig")
          .onSnapshot((config) => {
            let configdata = config.data();
            //console.log(configdata);
            if (configdata) {
              if (configdata.ArticlesUpdateExternal) {
                this.ArticlesUpdateExternal = configdata.ArticlesUpdateExternal;
              }
              if (configdata.ArticlesExternalfirebaseConfig) {
                this.ArticlesExternalfirebaseConfig =
                  configdata.ArticlesExternalfirebaseConfig;
              }
            }
          });
        if (this.groupdata.GroupPrivacy === "Public") {
          this.UserhasAccess = true;
        }
        if (this.UserRecord.id === this.groupdata.creator) {
          this.UserhasAccess = true;
          this.UserisAdmin = true;
        }

        if (this.UserisMember === true) {
          this.UserhasAccess = true;
        }
    },
    UpdateGroupMembersModerators(modarray, memberprop) {
      let vm = this;
     // console.log('groupmembers',this.groupmembers)
      // console.log(modarray, memberprop);
      // console.log(this[modarray]);
      return new Promise(function (resolve, reject) {
        let itemcount = 0;
        let listlength = vm.groupmembers.length;
        vm.groupmembers.map((member, i) => {
          let mod = vm[modarray].find((obj) => obj.Userid === member.Userid);
          if (mod) {
            vm.GroupRoot.collection("groupmembers")
              .doc(member.Userid)
              .collection("groups")
              .doc(vm.$route.params.id)
              .update({
                [memberprop]: true,
              })
              .then(() => {
                itemcount = itemcount - 1 + 2;
                if (itemcount === listlength) {
                  resolve("done");
                }
              });
          } else {
            vm.GroupRoot.collection("groupmembers")
              .doc(member.Userid)
              .collection("groups")
              .doc(vm.$route.params.id)
              .update({
                [memberprop]: false,
              })
              .then(() => {
                itemcount = itemcount - 1 + 2;
                if (itemcount === listlength) {
                  resolve("done");
                }
              });
          }
        });
      });
    },
    UpdateGroupMembers() {
      let vm = this;
      return new Promise(function (resolve, reject) {
        if(vm.groupmembers.length === 0){
          resolve("done");
        }
        else{
         vm.UpdateGroupMembersModerators(
          "BlogsModerators",
          "BlogsModerator"
        ).then((blogres) => {
          vm.UpdateGroupMembersModerators(
            "ArticlesModerators",
            "ArticlesModerator"
          ).then((artres) => {
            vm.UpdateGroupMembersModerators(
              "EventsModerators",
              "EventsModerator"
            ).then((evres) => {
              vm.UpdateGroupMembersModerators(
                "PollsModerators",
                "PollsModerator"
              ).then((pores) => {
                vm.UpdateGroupMembersModerators(
                  "MeetupsModerators",
                  "MeetupsModerator"
                ).then((mures) => {
                  vm.UpdateGroupMembersModerators(
                    "ForumsModerators",
                    "ForumsModerator"
                  ).then((fores) => {
                    vm.UpdateGroupMembersModerators(
                      "Administrators",
                      "Administrator"
                    ).then((adres) => {
                      if (vm.ChangestoGroupPrivacy) {
                        let itemcount = 0;
                        let listlength = vm.groupmembers.length;
                        vm.groupmembers.map((member, i) => {
                          vm.GroupRoot.collection("groupmembers")
                            .doc(member.Userid)
                            .collection("groups")
                            .doc(vm.groupid)
                            .update({
                              GroupPrivacy: vm.editedItem.GroupPrivacy,
                            })
                            .then(() => {
                              itemcount = itemcount - 1 + 2;
                              if (itemcount === listlength) {
                                resolve("done");
                              }
                            });
                        });
                      }
                      if (vm.ChangestoJoinPrivacy) {
                        let itemcount = 0;
                        let listlength = vm.groupmembers.length;
                        vm.groupmembers.map((member, i) => {
                          vm.GroupRoot.collection("groupmembers")
                            .doc(member.Userid)
                            .collection("groups")
                            .doc(vm.groupid)
                            .update({
                              WhocanJoin: vm.editedItem.WhocanJoin,
                            })
                            .then(() => {
                              itemcount = itemcount - 1 + 2;
                              if (itemcount === listlength) {
                                resolve("done");
                              }
                            });
                        });
                      }
                      // PostPrivacy: null,
                      // WhocanPost: null,
                      if (vm.ChangestoGroupStatus) {
                        let itemcount = 0;
                        let listlength = vm.groupmembers.length;
                        vm.groupmembers.map((member, i) => {
                          vm.GroupRoot.collection("groupmembers")
                            .doc(member.Userid)
                            .collection("groups")
                            .doc(vm.groupid)
                            .update({
                              GroupPublishStatus: vm.editedItem.GroupPublishStatus,
                            })
                            .then(() => {
                              itemcount = itemcount - 1 + 2;
                              if (itemcount === listlength) {
                                resolve("done");
                              }
                            });
                        });
                      } else {
                        resolve("done");
                      }
                    });
                  });
                });
              });
            });
          });
        });  
        }
       
      });
    },
    CheckandSaveUpdatedGroup(){
      console.log('this.GroupCatNavlistFieldValues',this.GroupCatNavlistFieldValues)
      if(this.GroupCatNavlistFieldValues){
        let length = this.GroupCatNavlistFields.length
        if(length){
        this.GroupCatNavlistFields.map((field,fieldindex) => {
          if(typeof this.groupdata[field.Name] !== 'undefined'){
             this.GroupDB.update({
              [field.Name]: this.groupdata[field.Name]
            })
          }
         
          if(fieldindex-1+2 === length){
            this.SaveUpdatedGroup()
          }
        })  
        }
        else{
          this.SaveUpdatedGroup()
        }
        
      }
      else{
        this.SaveUpdatedGroup()
      }
    },
    SaveUpdatedGroup(simple) {
      let vm = this;
      //vm.$emit('ActivateProcessing',true)
      
      if (this.ArticlesUpdateExternal && this.ArticlesExternalfirebaseConfig) {
        let first = this.ArticlesExternalfirebaseConfig.split(
          "const firebaseConfig = "
        );
        let lines = first[1].split("\n");
        let splitted = lines.map((line) => {
          return line.split(":");
        });
        let fixed = splitted.map((split) => {
          let obj = {};
          if (typeof split[1] !== "undefined") {
            obj[split[0].split(" ").join("")] = split[1]
              .split(" ")
              .join("")
              .split('"')
              .join("")
              .split(",")
              .join("");
            return obj;
          }
        });
        let dburltry = lines[3]
          .split("databaseURL:")
          .join("")
          .split('"')
          .join("");
        let appIdtry = lines[7].split("appId:").join("").split('"').join("");
        let twoclientfirebaseConfig = {
          apiKey: fixed[1].apiKey,
          authDomain: fixed[2].authDomain,
          databaseURL: fixed[3].databaseURL,
          projectId: fixed[4].projectId,
          storageBucket: fixed[5].storageBucket,
          messagingSenderId: fixed[6].messagingSenderId,
          appId: fixed[7].appId,
          measurementId: fixed[8].measurementId,
        };
        twoclientfirebaseConfig.appId = appIdtry;
        twoclientfirebaseConfig.databaseURL = dburltry;
        let ClientFireStore = twoclientfirebaseConfig;
        let ClientAppURL = twoclientfirebaseConfig.databaseURL
          .split(".firebaseio.com")
          .join(".firebaseapp.com")
          .replace(",", "");

        this.GroupDB
          .collection("adminonly")
          .doc("backendconfig")
          .set({
            ArticlesUpdateExternal: this.ArticlesUpdateExternal,
            ArticlesExternalfirebaseConfig: this.ArticlesExternalfirebaseConfig,
            ClientFireStore: ClientFireStore,
            ClientAppURL: ClientAppURL,
          });
      }
      this.UpdateGroupMembers().then((result) => {
       // console.log('result',result,vm.editedItem)
        if (result) {
          let privacyicon = ''
              if (vm.editedItem.GroupPrivacy === "Invite Only") {
                privacyicon = 'mdi-account-group'
              }
              else if (vm.editedItem.GroupPrivacy === "Non Guest Site Members") {
                privacyicon = 'mdi-account-group-outline'
              }
              else if (vm.editedItem.GroupPrivacy === "All Site Members") {
                privacyicon = 'mdi-account-group'
              }
              else if (vm.editedItem.GroupPrivacy === "Public") {
                privacyicon = 'mdi-globe-model'
              }
              // GroupPublishStatusIcon: vm.editedItem.GroupPublishStatusIcon,
           this.$store.dispatch('CreateSearchTitle',vm.editedItem.name).then(searchtitle => {
          let nameQuery = searchtitle 
          this.System.Group_Categories.Levels.map(lvl => {           
            if(this.editedItem[lvl.FieldName]){
              this.GroupDB
              .update({
              [lvl.FieldName]: {ID: this.editedItem[lvl.FieldName].ID,Name: this.editedItem[lvl.FieldName].Name}
              })
            }
          })
          this.GroupDB
              .update({
                AssignedGroupScope: vm.editedItem.AssignedGroupScope,
                GroupCategory: vm.editedItem.GroupCategory,
                Description: vm.editedItem.Description,                
                name: vm.editedItem.name,
                nameQuery: nameQuery,
                GroupPrivacy: vm.editedItem.GroupPrivacy,
                WhocanJoin: vm.editedItem.WhocanJoin,
                MembershipbyApproval: vm.editedItem.MembershipbyApproval,
                PostPrivacy: vm.editedItem.PostPrivacy,
                PostsbyApproval: vm.editedItem.PostsbyApproval,
                WhocanPost: vm.editedItem.WhocanPost,
                EventsPrivacy: vm.editedItem.EventsPrivacy,
                BlogPrivacy: vm.editedItem.BlogPrivacy,
                MeetupsPrivacy: vm.editedItem.MeetupsPrivacy,
                ArticlesPrivacy: vm.editedItem.ArticlesPrivacy,
                PollsPrivacy: vm.editedItem.PollsPrivacy,
                ForumPrivacy: vm.editedItem.ForumPrivacy,
                GroupPrivacyIcon: privacyicon,
                GroupPublishStatus: vm.editedItem.GroupPublishStatus,
                BlogsModerators: vm.BlogModeratorsID,
                Administrators: vm.AdministratorsID,
                ArticlesModerators: vm.ArticlesModeratorsID,
                EventsModerators: vm.EventsModeratorsID,
                PollsModerators: vm.PollsModeratorsID,
                MeetupsModerators: vm.MeetupsModeratorsID,
                ForumsModerators: vm.ForumsModeratorsID,   
                BlogsMonetization: vm.BlogsMonetization   ,
                BlogsPayperView: vm.BlogsPayperView,
                BlogsCreditCharge: vm.BlogsCreditCharge          
              })
              .then(() => {
                if(vm.editedItem.DefaultPage){
                   vm.GroupDB
                  .update({
                    DefaultPage: vm.editedItem.DefaultPage,
                  })
                }   
                if(vm.editedItem.GroupPublishStatus === 'Published'){
                  const systemactivity = {
                    user: vm.UserRecord,
                    contentvar: 'Published a Group',
                    location: '/Group/',
                    docname: vm.editedItem.name,
                    docid: vm.editedItem.id,
                    type: 'New Group',
                  }                          
                  vm.$store.dispatch('createSystemActivity',systemactivity)
                }                  
                vm.$emit('ActivateProcessing',false)
                vm.$router.push('/Group/'+vm.$route.params.id)             
                vm.Changes = false;
                vm.snackbar = true;
              });
           })
          }
      });
    },
    UpdateGroupMonetizationOptions(){
      console.log(this.MonetizationChannels)
      this.MonetizationChannels.map(channel => {
        if(channel.MonetizationSettings){
          this.GroupDB.update({
            [channel.ScopeName+'Monetization']: channel.MonetizationSettings
          })
        }
        if(typeof channel.PayperView !== 'undefined'){
            this.GroupDB.update({
            [channel.ScopeName+'PayperView']: channel.PayperView
          })
        }
        if(typeof channel.CreditCharge !== 'undefined'){
           this.GroupDB.update({
            [channel.ScopeName+'CreditCharge']: channel.CreditCharge
          })
        }
      })
      this.MonetizationChanges = false
    },
    MonetizationChangesareMade(event) {
      this.MonetizationChanges = true;
      this.RefreshingMonetizationOptions = true
      setTimeout(() => {
        this.RefreshingMonetizationOptions = false
      }, 10);
    },
    ChangesareMade(event) {
      this.Changes = true;
    },
    CancelGroupMonetizationUpdate(){
      this.MonetizationChannels.map(channel => {
        //'BlogsMonetization' CHECK 'BlogsPayperView' 'BlogsCreditCharge' actually credit charge is if credit charge is fixed so "blogCreditsFixed" and if so "credit carge"
       
        //or e.g. ChannelMain = this[channel.Name.split('Group ')+'Monetization'], while on save we loop through channels to update group...update({[channel.Name.split('Group ')+'Monetization']: channel.ChannelMain})
        channel.MonetizationSettings =  this[channel.ScopeName+'Monetization']
        channel.PayperView =  this[channel.ScopeName+'PayperView']
        channel.CreditCharge =  this[channel.ScopeName+'CreditCharge']
        return channel
     })
     this.MonetizationChanges = false
    },
    async CancelGroupUpdate() {
      this.editedItem.name = this.name;
      this.editedItem.Description = this.Description;
      this.editedItem.DefaultPage = this.DefaultPage;
      this.editedItem.BlogPrivacy = this.BlogPrivacy;
      this.editedItem.GroupPrivacy = this.GroupPrivacy;
      this.editedItem.EventsPrivacy = this.EventsPrivacy;
      this.editedItem.ArticlesPrivacy = this.ArticlesPrivacy;
      this.editedItem.PollsPrivacy = this.PollsPrivacy;
      this.editedItem.MeetupsPrivacy = this.MeetupsPrivacy;
      this.editedItem.MembersPrivacy = this.MembersPrivacy;
      this.editedItem.ForumPrivacy = this.ForumPrivacy;
      this.editedItem.GroupCategory = this.GroupCategory;
      this.editedItem.AssignedGroupScope = this.AssignedGroupScope;
      this.Changes = false;
    },
    onGroupImageselected(event) {
      this.selectedGroupImage = event.target.files[0];
      this.GroupImagelocalURL = URL.createObjectURL(this.selectedGroupImage);
    },
    closeGroupImage() {
      (this.GroupImagelocalURL = null), (this.selectedGroupImage = null);
    },
    onUploadGroupImageFile() {
      var storageRef = firebase
        .storage()
        .ref("Group/"+this.$route.params.id+"/Group_Logos/"+new Date()+this.selectedGroupImage.name);
      var uploadTask = storageRef.put(this.selectedGroupImage);
      uploadTask
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          this.FileURL = url;

          this.GroupDB.update({
            logo: this.FileURL,
          });
          this.closeGroupImage();
        });
    },
  },
};
</script>

<style>
.greenText {
  color: green;
}
.redText {
  color: red;
}
.tabsinner {
  background-color: #e4003b;
}
</style>

    