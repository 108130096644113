<template>
  <div>
     <v-dialog max-width="600" v-model="NewFieldDialog">                               
        <v-card>
            <v-card-title class="overline">
                {{NewField.Type}}
            </v-card-title>
            <v-card-text v-html="NewField.Description">
            </v-card-text>
            <!-- <v-card-text> -->
                <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="CurrentEntity" @RefreshField="RefreshField" v-if="!RefreshingNewField"
                :SystemEntities="ComputedEntities" :field="NewField" :NewField="AddingNewField" :System="System"
                />
            <v-card-text>
                <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="ComputedEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true" :System="System"
                :PrimaryFieldName="''" :SingleFormEditing="false" :CurrentEntity="CurrentEntity"
                :Record="SampleRecord" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="[NewField]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelNewFieldDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddField(NewField)">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-layout row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-list-item-subtitle>All Fields</v-list-item-subtitle>
    <v-list-item class="detailslistoutline" dense v-for="field in EntityRelevantFields"
        :key="field.itemObjKey" @click="ActivateNewFieldDialog(field,false)"
        >
        <v-list-item-content>
        <v-list-item-title v-text="field.Name"></v-list-item-title>
        
        </v-list-item-content>
        </v-list-item>
        </v-col>
         <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-list-item-subtitle>Unused Fields</v-list-item-subtitle>
    <v-list-item v-if="UnusedFields.length === 0">
        No Unused Fields in Data Table
    </v-list-item>
                            <!-- <v-list-item v-if="UnusedFields.length > 0">
                                <v-text-field v-model="ExistingFieldSearch" label="Search" />
                            </v-list-item> -->
                            <!--  draggable @dragstart="Onepropemit('AssignNewFieldDragging',field)"   -->
                            <v-list-item class="detailslistoutline" dense v-for="field in UnusedFields"
                            :key="field.itemObjKey">
                           <v-list-item-content>
                            <v-list-item-title v-text="field.Name"></v-list-item-title>
                            
                            </v-list-item-content>
                            </v-list-item>
        </v-col>
    </v-layout>
     
    
                            <v-list-item-subtitle>Add New</v-list-item-subtitle>
                            <!-- <v-list-item>
                                <v-text-field v-model="NewFieldSearch" label="Search" />
                            </v-list-item> -->
                            <v-list-item>
                                <v-dialog v-model="importdialog" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                        <span class="headline">Import Dialog</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <v-text-field v-model="ImportFileSessionName" label="Name"></v-text-field>
                                                <input v-if="!ImportFile"
                                                        type="file"
                                                        @change="onImportfileSelected($event)"
                                                        ref="ImportFileinputter"
                                                        id="fileUpload"
                                                        >
                                                <v-btn @click="DownloadFieldsImportTemplate()">Download Template</v-btn>
                                            </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                            </v-list-item>
              <v-chip-group
                active-class="primary--text"
                column
            >
            <v-chip
                @click="ActivateNewFieldDialog(field,true)"
                small  v-for="field in FieldTypesSearched"
                :key="field.itemObjKey"
                >
                {{field.Type}}
                </v-chip>                           
            </v-chip-group>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldConfig from '@/components/Database/Fields/FieldConfig';	
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
export default {
    props: ['System','SystemEntities','RelatedObj','CurrentEntity','EntityCollectionRef','SampleRecord','NewOnly','AllowedFieldTypes','RADB'],
    components: {FieldConfig,SingleTabFieldsSection},
    data() {
        return {
            RefreshingNewField: false,
            NewField: '',
            AddingNewField: false,
            TableoptionsSearch: '',
            NewTableOptionSearch: '',
            ExistingFieldSearch: '',
            ExistingFormFieldSearch: '',
            NewFieldSearch: '',
            FieldTypes: [],
            importdialog: false,
            ImportFile: '',
            CSVResult: [],
            ImportHeaders: [
                { text: 'Name', value: 'Name'},
                { text: 'DisplayName', value: 'DisplayName'},
                { text: 'Type', value: 'Type'},
                { text: 'CommonFieldType', value: 'CommonFieldType'},
                { text: 'LookupTable', value: 'LookupTable'},        
                { text: 'dud', value: 'dud'},
            ],
            ImportFileSessionName: '',
            NewImportsArray: [],
            PartialFailuresArray: [],
        }
    },	
    computed:{
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
        },
        TemplateView(){
            return this.ActiveMarketTemplate
        },
        TemplateEntities(){
            return this.$store.state.TemplateEntities
        },
        ComputedEntities(){
            return this.TemplateView ? this.TemplateEntities : this.SystemEntities
        },
        EntityRelevantFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return this.AllowedFieldTypes.includes(field.Type)
            })
        },
        RAConfigDB(){
            return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
        },
        NeedsRASync(){
            return this.$store.state.NeedsRASync
        },
        CurrentEntity(){
            return this.$store.state.CurrentEntity
        },
        WikiEntityFields(){
            if(this.EditedTab && this.EditedTab.Name === 'Wiki Collection' && this.EditedTab.Entityid){
                let wikientity = this.WikiEntities.find(obj => obj.id === this.EditedTab.Entityid)
                return wikientity.WikiFields
            }
        },
        ComputedWikiEntities(){
            return this.WikiEntities
            .filter(entity => {
                if(this.$route.meta && this.$route.meta.ClassifiedListingBuilder){
                    //we need to get the classifiedentity somehow and pass it's publishtype to the this.Publishttypoe prop
                    //note this is clasifiedeneity, NOT on catnavlist dude
                    return entity
                }
                else{
                    return entity.PublishType === this.PublishType
                }                
            })
        },
        CurrentEntityPhotoFields(){
            return this.CurrentEntity.AllFields
            .filter(field => {
                return field.Type === 'Single File Upload' && field.FeaturedPhoto
            })
        },
        DataTableswithLookuptoCurrentEntity(){
            if(this.CurrentEntity){
                return this.ComputedEntities.map(entity => {
                    let entityobject = {
                        id: entity.id
                    }
                    let oncheck = entity.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
                    if(oncheck){
                        entityobject.RelationshipField = {
                            id: oncheck.id,
                            Name: oncheck.Name,
                            DisplayName: oncheck.DisplayName,
                            Type: oncheck.Type,
                            LookupFieldName: oncheck.LookupFieldName
                        }
                        entityobject.RelationshipName = entity.DisplayName+' ('+oncheck.DisplayName+')'
                    }
                    return entityobject
                }).filter(entity => {
                    return entity.RelationshipField
                })
            }
            else{
                return []
            }
        },
         UnusedTableOptions(){
            let docregisters = this.CurrentEntity.DocumentRegisters
            let galleries = this.CurrentEntity.Galleries
            if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single SubCollection'){
                return this.CurrentEntity.SubCollections.filter(opt => {
                    if(this.TableoptionsSearch){
                        return opt.DisplayName.toLowerCase().includes(this.TableoptionsSearch.toLowerCase())
                    }
                    else{
                        return opt
                    }
                })
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Related Data'){
                return this.DataTableswithLookuptoCurrentEntity
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Document Register'){
                return docregisters
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Gallery'){
                return galleries
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Cabinet'){
                
                return docregisters.concat(galleries).filter(opt => {
                    if(this.TableoptionsSearch){
                        return opt.DisplayName.toLowerCase().includes(this.TableoptionsSearch.toLowerCase())
                    }
                    else{
                        return opt
                    }
                })
            }
        },
        TableOptionsSearched(){
            let array = []
            if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single SubCollection'){
                let stringarray = ['SubCollection']
                stringarray.map(stringopt => {
                    let arrayobj = {
                        Name: '',
                        DisplayName: '',
                        OptionType: stringopt
                    }
                    if(stringopt === 'SubCollection'){
                        arrayobj.ConfigCollection = 'SubCollections'
                        arrayobj.Headers = []
                        arrayobj.HeadersInteger = 1000000
                        
                    }
                    
                    array.push(arrayobj)
                })
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Document Register'){
                let stringarray = ['Document Register']
                stringarray.map(stringopt => {
                    let arrayobj = {
                        Name: '',
                        DisplayName: '',
                        OptionType: stringopt,
                    }
                    if(stringopt === 'Document Register'){
                        arrayobj.ConfigCollection = 'DocumentRegisters'
                    }
                    else if(stringopt === 'Gallery'){
                        arrayobj.ConfigCollection = 'Galleries'
                    }
                    array.push(arrayobj)
                })
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Gallery'){
                let stringarray = ['Gallery']
                stringarray.map(stringopt => {
                    let arrayobj = {
                        Name: '',
                        DisplayName: '',
                        OptionType: stringopt,
                    }
                    if(stringopt === 'Document Register'){
                        arrayobj.ConfigCollection = 'DocumentRegisters'
                    }
                    else if(stringopt === 'Gallery'){
                        arrayobj.ConfigCollection = 'Galleries'
                    }
                    array.push(arrayobj)
                })
            }
            return array.filter(item => {
                if(this.NewTableOptionSearch){
                    return item.Type.toLowerCase().includes(this.NewTableOptionSearch.toLowerCase())
                }   
                else{
                    return item
                }
            })
        },
        FieldTypesSearched(){
            return this.FieldTypes.filter(field => {
                if(this.NewFieldSearch){
                    return field.Type.toLowerCase().includes(this.NewFieldSearch.toLowerCase())
                }   
                else{
                    return field
                }
            })
        },
        UnusedFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return !field.UsedonForm && !field.SystemRequired
            }).filter(field => {
                if(this.ExistingFieldSearch){
                    return field.Name.toLowerCase().includes(this.ExistingFieldSearch.toLowerCase()) || field.DisplayName.toLowerCase().includes(this.ExistingFieldSearch.toLowerCase())
                }
                else{
                    return field
                }
            })
        },
        ExistingFormFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.UsedonForm && !field.SystemRequired
            }).filter(field => {
                if(this.EditedTab){
                    let fields = this.EditedTab.Fields.map(tabfield => {return tabfield.id})
                    return fields.includes(field.id)
                }
                else{
                    return field
                }
            })
            .filter(field => {
                if(this.ExistingFormFieldSearch){
                    return field.Name.toLowerCase().includes(this.ExistingFormFieldSearch.toLowerCase()) || field.DisplayName.toLowerCase().includes(this.ExistingFormFieldSearch.toLowerCase())
                }
                else{
                    return field
                }
            })
        },
        FieldTypesSearched(){
            return this.FieldTypes.filter(field => {
                if(this.NewFieldSearch){
                    return field.Type.toLowerCase().includes(this.NewFieldSearch.toLowerCase())
                }   
                else{
                    return field
                }
            })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        HeaderNames() {
            return this.ImportHeaders.map(header => {
                return header.value.split('.')[0]
            }).filter(header => {
                return header !== 'id'
            })
            },
        HeaderNamesSplit() {
            return this.HeaderNames.map(header => {
            let correctheader = header
                return correctheader
            })
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        console.log('this.RADB',this.RADB)
        let fieldtypes = this.AllowedFieldTypes
        this.FieldTypes = fieldtypes.map(type => {
            let typeobj = {
                Type: type,
                Name: '',
                DisplayName: '',              
            }
            if(type === 'Lookup'){
                //typeobj.Description = 'Here is a Description about Lookup Fields'
            }
            else if(type === 'Parent Field'){
                typeobj.ReadOnly = true
            }
            else if(type === 'Option Set'){
                typeobj.Options = []
                typeobj.lastassignedinteger = 1000000
            }
            return typeobj
        }).sort((a, b) => {			
                    // console.log('sorting again')			
                    // console.log(a.Value)			
                    var key1 = b.Type			
                    var key2 = a.Type			

                    if (key1 > key2) {			
                        return -1;			
                    } else if (key1 == key2) {			
                        return 0;			
                    } else {			
                        return 1;			
                    }			
            })
    },
    methods:{
        onImportfileSelected(event){
        this.ImportFile = event.target.files[0]
        },
        DownloadFieldsImportTemplate(){
            let copyText = this.HeaderNamesSplit
            var csvfilename = 'FieldsImportTemplate.csv'
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
            element.setAttribute('download', csvfilename)
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            // }
        },
        closeImport(){
        this.importdialog = false
        this.ImportFile = null
        this.ImportFileSessionName = ''
        },
        saveImport() {
            let importLocalURL = URL.createObjectURL(this.ImportFile)
            var reader = new FileReader();
            let test = reader.readAsText(this.ImportFile);
            let vm = this
            reader.addEventListener('load', function(e) {
                    var text = e.target.result;
                    vm.CSVConvertToJSON(text)
            });
        },
        CSVConvertToJSON(text){        
            this.NewImportsArray = []
            this.PartialFailuresArray = []            
            let lines = text.split("\n")
            this.CheckDelimiter(lines)        
        },

        CheckDelimiter(lines){
      let headertest = lines[0].split("|");
      if(headertest.length ===1){
        this.ConversionCSVtoJJSON(lines,'|')
      }
      else{
        this.ConversionCSVtoJJSON(lines, '|')
      }
      //console.log('headertest')
      //console.log(headertest)
    },
        ConversionCSVtoJJSON(lines,delimiter){
            let dataonly = Object.assign([],lines)
            dataonly.splice(0,1)
            // console.log(dataonly)
            // console.log('delimiter')
            // console.log(delimiter)
            this.CSVResult = []
            this.ImportHeaders=lines[0].split(delimiter);
            // console.log('this.ImportHeaders')
            // console.log(this.ImportHeaders)
            for(var i=1;i-1+2<lines.length;i++){
            var obj = {};
            var currentline=lines[i].split(delimiter);            
            for(var j=0;j<this.ImportHeaders.length;j++){
                if(this.ImportHeaders[j] === 'Name'){
                obj[this.ImportHeaders[j]] = currentline[j].split(' ').join('_');
                }
                else{
                obj[this.ImportHeaders[j]] = currentline[j];
                }                
            }            
            this.CSVResult.push(obj);
            console.log("this.CSVResult length is "+this.CSVResult.length)
            }
            this.CSVResult.map((res,i) => {                
                delete res.dud
                console.log('res')
                console.log(res)
                this.AddField(res)
                if(i-1+2 === this.CSVResult.length){
                    this.closeImport()
                }
            })
        },
        ProcessAddField(fieldobj){
            let vm = this
            return new Promise(function(resolve, reject) {
                if(vm.NeedsRASync){
                    vm.RAConfigDB.collection('AllFields').doc(fieldobj.id).set(fieldobj).then(radoc => {
                        vm.EntityCollectionRef.collection('AllFields').doc(fieldobj.id).set(fieldobj).then(fielddoc => {
                            resolve('Done')
                        })
                    })
                }
                else{
                    vm.EntityCollectionRef.collection('AllFields').doc(fieldobj.id).set(fieldobj).then(fielddoc => {
                        resolve('Done')
                    })
                }
            })
        },
        AddField(field){
            let vm = this
            let fieldobj = Object.assign({},field)
              if(!field.Name){
             fieldobj.Name = fieldobj.DisplayName.split(' ').join('_')   
            }  
            fieldobj.id = fieldobj.Name          
            fieldobj.text = fieldobj.DisplayName
            fieldobj.value = fieldobj.Name
            delete fieldobj.Array
            delete fieldobj.RecordData
            vm.ProcessAddField(fieldobj).then(fielddoc => {
                if(fieldobj.Type === 'Checklist'){
                let datefield = {
                    id: fieldobj.id+'Date',
                    Name: fieldobj.Name+'Date',
                    DisplayName: fieldobj.Name+' Date',
                    Type: 'Date',
                    text: fieldobj.DisplayName,
                    value: fieldobj.Name,
                    ReadOnly: true
                }
                vm.ProcessAddField(datefield).then(fielddoc => {
                   vm.CancelNewFieldDialog() 
                }) 
                }
                else{
                    vm.CancelNewFieldDialog()
                }
            })           
            
        },
        RefreshField(field){
        this.RefreshingNewField = true
        setTimeout(() => {
          this.RefreshingNewField = false
        }, 100);
      },
        UpdateField(field,prop,value){
            field[prop] = value
            if(!this.AddingNewField){
                
            }
            console.log(field)
        },
        UpdateRelated(field,RelatedBuildID){
            let array = []
            if(field.Type === 'Lookup'){
                array = this.ComputedEntities
            }
            else{
                array = this.$store.state.SocialEntities
            }
            let entity = array.find(obj => obj.id === RelatedBuildID)
            if(entity){
                field.RelatedLocation = entity.SingleName
                field.RelatedRecord = entity.DisplayName
                field.LookupFieldName = entity.RecordPrimaryField.Name
            }
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            console.log('field',field)
            this.NewField = Object.assign({},field)
            this.NewFieldDialog = true
            this.AddingNewField = AddingNewField
            this.ListPanel = ListPanel
            console.log(this.EntityCollectionRef,field)
            // if(tableoption){
            //     this.NewTableOption = tableoption 
            // }
        },
        CancelNewFieldDialog(){
            this.NewFieldDialog = false,
            this.NewField = ''
             this.AddingNewField = false
             this.ListPanel = ''
            //this.NewTableOption = ''
        },
    }
}
</script>

<style>

</style>



