<template>
  <div>
    <v-btn @click="ActiveConfigPath = ''" v-if="ActiveConfigPath">Back</v-btn>
     <component :is="ActiveConfigComponent" v-if="ActiveConfigPath"
     :Directories="Directories" :RADB="RADB"
            :UsersArray="UsersArray" @PickPlugin="PickPlugin"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="true" :Notifications="Notifications" :SystemGuides="SystemGuides"
            :System="System" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin" :SitePages="SitePages"
            :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SubscriptionPackages="SubscriptionPackages"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries" @GoBack="GoBack" :SiteModerators="SiteModerators"
    />
      <v-card elevation="0" class="siteconfigcard" v-if="CurrentModuleActive && SuiteBuilderView && !ActiveConfigComponent">
        <v-card-title>
          <span  class="mediumoverline">
          {{ModuleName}}
          </span>
      </v-card-title>
      <v-card-text>
        <!--  -->
      <v-tabs style="margin-top: 20px;" centered>
          <v-tab>Overview</v-tab>
          <v-tab-item>
              <RAModulePlugin v-if="Module.Module_Category && Module.Module_Category.Name === 'Module'"
        :System="System" :SystemEntities="SystemEntities" :Module="Module" @ActivateActiveConfigPath="ActivateActiveConfigPath"
        /> 
        <v-card elevation="0" class="siteconfigcard" v-if="Module.id === 'Database_Builder'">
              <v-card-subtitle class="mediumoverline">
              Database Config
            </v-card-subtitle>
            <v-card-text >
              <v-row class="justify-center">
                <v-btn @click="ActivateActiveConfigPath('components/Database/GlobalOptionSetConfig')" width="100%">Global Option Sets</v-btn>
              </v-row>
            </v-card-text>
        </v-card>
        <v-card elevation="0" class="siteconfigcard" v-if="Module.id === 'Database_Builder'">
              <v-card-subtitle class="mediumoverline">
              Database Security
            </v-card-subtitle>
            <v-card-text >
              <v-row class="justify-center">
                <div v-for="secmod in SecurityModules" :key="secmod.itemObjKey" style="padding: 20px;" class="flex xl3 lg3 md4 sm6 xs12">
                    <v-card height="250">
                        <v-card-title class="overline">                     
                              {{secmod.Name}}
                              <v-spacer>
                              </v-spacer>
                              <v-icon>{{secmod.DataContent.ContentIcon}}
                            </v-icon>
                        </v-card-title>
                        <v-card-text style="text-align: center;" class="caption" v-html="secmod.DataContent.ContentDescription">
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn small color="purple" outlined style="position: absolute; bottom: 15px;" @click="GotoRoute('/'+secmod.DataContent.ContentLinkName)" dark>
                                Go
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
                </v-row>
            </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab>Validator</v-tab>
          <v-tab-item>
             <v-list>
              <!-- @click="ActivateTableApp(ent)" -->
              <v-list-item v-for="ent in AvailableTables" :key="ent.itemObjKey">
                  <v-list-item-avatar v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="MiniView ? '' : 'align-self: center;padding-right: 10px;'">
                    <v-icon :size="40">{{ent.Table_Icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-select multiple
                        @change="UpdateSharedPlugins(ent)" :items="RegisteredApps" item-text="id" label="Active Apps" v-model="ent.ActiveApps"/>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-list-item-title style="align-self: start;" class="smallbanneroverline">
                      <v-icon v-if="MiniView" :size="20">{{ent.Table_Icon}}</v-icon>
                      {{ent.DisplayName}}
                    </v-list-item-title>
                    </v-list-item-action>
              </v-list-item>
          </v-list>
              <ModuleValidator :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System"
              :Module="Module" :SystemEntities="SystemEntities" :Directories="Directories" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/>
          </v-tab-item>
      </v-tabs>
      
      </v-card-text>
      </v-card>
      <!-- <div v-if="IsPrimarySite"> -->
      <div v-if="!CurrentModuleActive || !SuiteBuilderView">
          <RAModulePlugin v-if="Module.Module_Category && Module.Module_Category.Name === 'Module'"
        :System="System" :SystemEntities="SystemEntities" :Module="Module"
        />  
      </div>
      <!-- <div v-if="CurrentModuleActive && Module.id === 'Database_Builder' && !ActiveConfigComponent">
         <ModuleValidator :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System"
              :Module="Module" :SystemEntities="SystemEntities" :Directories="Directories" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/>  
      </div> -->
        <v-card-text class="BuilderGradient" v-if="IsPrimarySite && !ActiveConfigPath">
          <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RelevantPlugins" :tab="systemguideelmnt" @ActivateModulePlugins="ActivateModulePlugins"
          :ListPreviewOnly="true" v-if="!RefreshingPlugins" :ActiveModuleName="Module.Name"
            />
        </v-card-text>
      <!-- </div> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import RAModulePlugin from '@/components/SuiteBuilder/Main/RAModulePlugin';	
import ModuleValidator from '@/components/SuiteBuilder/Main/ModuleValidator'
export default {
    props: ['System','SystemEntities','SystemPhotoLibraries','SystemDocumentLibraries','SiteModerators','SubscriptionPackages',
    'DocumentationLibraries',
    ,'Module','SitePages','MyActivitiesArray','MyGoals','MyGoalsisEmpty','RADB','Directories','Notifications','SystemGuides','FrequentlyAskedQuestions'],
    components: {RAPluginCarousel,RAModulePlugin,ModuleValidator},
    data() {
        return {
          
          SecurityModules: [
                {
                Name: 'Business Units',
                Module: 'Database Builder',
                DataContent: {
                    ContentLinkName: 'BusinessUnits',
                    ContentIcon: 'mdi-security-network',
                    ContentDescription: `Configure the Business Structure in order to assign Users to particular business Units, and set security roles form there`
                }
                },
                {
                Name: 'Users',
                Module: 'Database Builder',                
                DataContent: {
                    ContentLinkName: 'Users',
                    ContentIcon: 'mdi-tag-faces',
                    ContentDescription: `Configure the Users and their various access permissions`
                }
                },
                {
                Name: 'Security Roles',
                Module: 'Database Builder',
                DataContent: {
                    ContentLinkName: 'SecurityOverview',
                    ContentIcon: 'mdi-server-security',
                    ContentDescription: `Create and Manage Security Roles, and their relationship tot eh various data tables in your database.`
                }
                },

            ],
            ActiveConfigPath: '',
            SingleRecordNavMenuItem: {
            Icon: 'mdi-alert',
            DisplayName: 'Alerts',
            Name: 'Alerts',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Alerts', 
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>The Alerts Section serves as a configuration required for both published and unpublished changes.</p>`,          
            Elements: [],
            Children: [
              {
            Icon: 'mdi-database',
            DisplayName: 'Database Module',
            Name: 'Database Module',
            SystemDashboard: true,
            DashboardType: 'Database Module'
          },
          {
            Icon: 'mdi-share-variant',
            DisplayName: 'Social Module',
            Name: 'Social Module',
            SystemDashboard: true,
            DashboardType: 'Social Module'
          },
          {
            Icon: 'mdi-search-web',
            DisplayName: 'Website Module',
            Name: 'Website Module',
            SystemDashboard: true,
            DashboardType: 'Website Module'
          },
           
            ]
          },
            SystemGuides: [],
          RefreshingPlugins: false,
            SelectedModule: '',
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
        }
    },	
    computed:{    
      AppsPath(){
        return this.$store.state.AppsPath
        },  
      ConfigDB(){
        //need to still cahnge from this.SystemisRA ? to IsPrimarySite
          return this.SystemisRA ? db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID) : 
          this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID)
      },
      ConfigDBAppsPath(){
          return this.ConfigDB.collection('apps')
      },
      AvailableTables(){
          return this.SystemEntities.filter(ent => {
              return !ent.Primary_Appid && !ent.ActiveApps || this.ActiveSuiteApp && ent.ActiveApps && !ent.ActiveApps.includes(this.ActiveSuiteApp.id)
              //you know what? perhaps these apps can in fact be shared continuosly am I right?
          })
      },
      IsPrimarySite(){
        return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
      },
      ActiveConfigComponent(){
        if(this.ActiveConfigPath){          
         return () => import(`@/`+this.ActiveConfigPath+`.vue`);
        }
      },
        RADB(){
            return this.$store.state.RADB
        },
        RAApp(){
            return this.$store.state.RAApp
        },
        ActiveDB(){
            return db
        },
      SelectedModulePlugins(){
          let plugins = []
          if(this.Module){
            if(this.Module.Name === 'Database Builder'){
              return this.RAPluginsMatched(['Database Builder','DBB'])
            }
            else if(this.Module.Name === 'Social Network Builder'){
              return this.RAPluginsMatched(['Social Network Builder','SNB'])
            }
            else if(this.Module.Name === 'Website Builder'){
              return this.RAPluginsMatched(['Website Builder','WB'])
            }
            else if(this.Module.Name === 'Documentation Builder'){
              return this.RAPluginsMatched(['Documentation Builder','DBB'])
            }
          }
          return plugins
        },
      RelevantPlugins(){
        return this.SelectedModulePlugins
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      DocLibBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
      },
      BuilderView(){
    return this.$store.state.BuilderView
    },
        WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        SuiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Suite Builder'
        },
        DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
        },
        NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
      CurrentModuleActive(){
        //return false
        return this.PluginDataBase[this.ModuleName.split(' ').join('_')] && this.PluginDataBase[this.ModuleName.split(' ').join('_')].Active
      },
      NoActiveModule(){
        return !this.WebsiteisActive && !this.SocialNetworkisActive && !this.DatabaseisActive && !this.DocumentationisActive
      },
      WebsiteisActive(){
        return this.$store.state.WebsiteisActive
      },
      SocialNetworkisActive(){
        return this.$store.state.SocialNetworkisActive
      },
      DatabaseisActive(){
        return this.$store.state.DatabaseisActive
      },
      DocumentationisActive(){
        return this.$store.state.DocumentationisActive
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ModuleName(){
            return this.Module.Name
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        RegisteredApps(){
            return this.$store.state.AppsDataBase
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      GotoRoute(route){
            if(this.$route.path !== route){
                this.$router.push(route)
            }
        },
      ActivateTableApp(table){

      },
      UpdateSharedPlugins(table){
        if(!table.ActiveApps){
          table.ActiveApps = []
        }
            //console.log(this.SystemEntities,this.ActiveWebForm)
            //so either we sahre the web form entity via shared plugins
            //or we share simply the entity (although that I don't think we allow I think we only share entity throught shared plugins right?)
            //or we share web forms plugin, which is what shared lugin comes down to, but then somehow propogate which web forms applicable to the sharing...?
            //or we keep it really simple which I prefer. Somehow AppEntities propogate with all shred webforms? new prop "Shared WebForms?"
            
            // this.RegisteredApps.map(app => {
            //     let sharedwithactive = table.ActiveApps.find(obj => obj = app.id)
            //     let SharedWebForms = []
            //     if(app.SharedWebForms){
            //         SharedWebForms = app.SharedWebForms
            //     }
            //     if(sharedwithactive && !SharedWebForms.includes(table.id)){
            //         SharedWebForms.push(table.id)
            //     }
            //     this.AppsPath.doc(app.id).update({
            //         SharedWebForms: SharedWebForms
            //     })
            // })
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).colleciton('entities').doc(table.id).update({
              ActiveApps: table.ActiveApps
            })
        },
      GoBack(path){
        this.ActiveConfigPath = path
      },
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      ActivateActiveConfigPath(path){
        this.ActiveConfigPath = path
        this.ScrolltoTop()
      },
      SelectBuilderView(Module){
        this.$store.commit('setBuilderView',{Name: Module.Name})
      },
      RAPluginsMatched(categorymatches){
         let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || plug.Module_Category.Name === 'General'
        })
      },
      RefreshPlugins(){
        this.RefreshingPlugins = true
        setTimeout(() => {
          this.RefreshingPlugins = false
        }, 10);
      },
        DeslectModule(){
        this.SelectedModule = ''
        this.RefreshPlugins()
        },
        ActivateModulePlugins(item){
            this.SelectedModule = item
            this.RefreshPlugins()
            
        },
        PickPlugin(plugin){
          this.EmitMarketDialog(plugin)
        },
        EmitMarketDialog(SelectedPlugin,prop){
        console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
      },
    }
}
</script>

<style>

</style>



