<template>
  <v-main style="padding: 20px;"> 
    <v-card
        flat
        tile
      >
      <SitePlusQuickBuilder :System="System" :SystemEntities="SystemEntities" v-if="SuiteTemplateDialog"
      :InitialSuiteTemplate="ActiveSite" @CancelSaveSuiteTemplate="CancelSiteUpdate" :PreventClose="true"
      :NewSuiteStep="NewSuiteStep" :Directories="Directories" :SuiteTemplateDialog="SuiteTemplateDialog"
      />
      <!-- <v-tabs class="transtabs transparent" centered>        
     
        <v-tab>Org</v-tab>
        <v-tab-item>
            <v-card-text>
             <v-select outlined item-text="Name" v-model="ActiveSite.OrganisationType"
          :items="SiteOrgTypeField.Options" label="Organisation Type"
        />
        <v-select outlined v-model="ActiveSite.OrgProfileType" label="Profile"
          :items="['Products','Services','Products & Services']"
        />
        <v-select outlined v-model="ActiveSite.ClientsType" label="Clients"
          :items="['B2B','B2C','B2X']"
        />
         <v-select outlined v-model="ActiveSite.SiteApplication"
      @change="ConfigureApplicationOption(ActiveSite.SiteApplication)"
        label="Application" :items="['Site/Listing Only','Site/Listing PLUS','Skip Listing']"
      />
            </v-card-text>
        </v-tab-item>
        <v-tab>Site Scope</v-tab>
        <v-tab-item>
            <v-row class="justify-center" style="padding: 20px;"
            v-for="scope in ComputedScopeOptions" :key="scope.itemObjKey">
                <v-card
                height="100%"
                width="90%"
                >
                <v-card-title class="mediumoverline">
                    <v-icon>{{scope.Icon}}</v-icon>
                    {{scope.DisplayName}}
                </v-card-title>
                <v-card-text >
                    <div v-html="scope.Description"/>
                </v-card-text>
                </v-card>
            </v-row>
        </v-tab-item>
      </v-tabs> -->
    </v-card>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'
import SitePlusQuickBuilder from '@/components/SuitePlugins/WebAppBuilder/SitePlusQuickBuilder'

export default {
    props: ['System','SystemEntities','Directories'],
    components: {SignupPackages,SitePlusQuickBuilder},
    data() {
        return {
            SiteOrgTypeField: {
              id: 'Site_Owner_Type',Name: 'Site_Owner_Type', DisplayName: 'Site Owner Type',
              Type: 'Radio Group',SingleSelect: true, Options: [{ID: 1000001,Name: 'Content Creator'}
              ,{ID: 1000002,Name: 'Sideline Business'},{ID: 1000003,Name: 'Sole Proprietorship'},
              {ID: 1000004,Name: 'Startup'},{ID: 1000005,Name: 'Small Business'},
              {ID: 1000006,Name: 'SME'},{ID: 1000007,Name: 'Corporate Enterprise'},{ID: 1000008,Name: 'Professional Association'},
              {ID: 1000009,Name: 'NPO'}]
            },
            SuiteTemplateDialog: true,
            scopestep: 0,
            scopeoptstep: 0,
            ActiveSite: {},
            ScopeOptions: [
              // okay long story short - should anythign with "PluginOptios" not perhaps be considered a top level entry?
              //it would make life easier
              //okay my notes before I dispatch, example hereof whre makes sense "Companny Communication"
              //if not under "team portal" it now opens up "Clients y/N" and thus no need to have it under two places
              //Team Social Collab (instead of Team Collab) yeah sure same story
              //but now "Client Portal" uhm, for "service management" makes more sense than just saying "service management" but...?
              {ID: 1000001,Icon: 'mdi-account-supervisor-outline', Name: 'Team App',
              Description: `<p>Looking to make your office work digital?</p>`,
              Pluginid: 'Database_Builder',Options: [
                {ID: 1000006,Icon: 'mdi-chart-line', Name: 'Sales Management',
              Description: `<p>Client Database and Opportunity Pipeline Manager?</p>`,
              IncludedPlugins: ['Standard_Data_Table','Standard_Data_Table','Standard_Data_Table']},//IncludedPlugins challenge. 3 custom entities accounts, contacts and then "WIP table" which is either calendar or std table so?
                {ID: 1000007,Icon: 'mdi-warehouse', Name: 'Warehouse Management',
              Description: `<p>Lightweight Consumable Store Management (e/.g. Trucks managing Tires etc)?</p>`,
              IncludedPlugins: ['Warehouse_&_Products','Warehouse_Inbound','Warehouse_Outbound']},//is it inb/outb always included?
                {ID: 1000008,Icon: 'mdi-file-cabinet', Name: 'Filing App',
              Description: `<p>Want a table of Records, to which you can append photos or documents?</p>`,
              IncludedPlugins: ['Standard_Data_Table']},
                {ID: 1000009,Icon: 'mdi-list-status', Name: 'WIP Management',
              Description: `<p>Want to manage projects, or task progress?</p>`,
              IncludedPlugins: ['Standard_Data_Table']}//custom custom custom but is that not inherited from table type plug right?
              ]},
              {ID: 1000002,Icon: 'mdi-bulletin-board',Name: 'Team Portal',
              Description: `<p>Centralizing Internal Communication with your very own Organisation Portal?</p>`,
              Pluginid: 'Social_Network_Builder',Options: [
                {ID: 1000010,Icon: 'mdi-newspaper', Name: 'Organisation Communication',MultiplePluginsAllowed: true,
                PluginOptions: ['Site_Articles','Site_Blogs','Campaign_Builder','Digital_NoticeBoard']},//articles, blogs, campaignbuilder, noticeboard
                {ID: 1000011,Icon: 'mdi-account-group', Name: 'Collaboration',MultiplePluginsAllowed: true,
                PluginOptions: ['Site_Events','Site_Forums','Site_Groups','Site_Meetups','Site_Polls']},//events, forums, groups, meetups, polls
                {ID: 1000012,Icon: 'mdi-monitor-star', Name: 'Personals',
              Description: `<p>Want users to list personals, and advertise unwanted, or wanted goods?</p>`,
              IncludedPlugins: ['Classifieds']},//Classifieds * this one means this only while others you choose from
              ]},
              {ID: 1000003,Icon: 'mdi-account-heart-outline',Name: 'Client Portal',
              Description: `<p>Want a client portal, whereby clients can interact with your Organisation?</p>`,
              Pluginid: 'Social_Network_Builder',Options: [
                {ID: 1000013,Icon: 'mdi-storefront', Name: 'Online Store',
              Description: `<p>Want an Online Store to sell goods online?</p>`,
              IncludedPlugins: ['Online_Store']},
                {ID: 1000014,Icon: 'mdi-calendar-check', Name: 'Online Bookings',
              Description: `<p>Want to publish calendar events, and allow user booking (e.g. a Gym App, or a Training Schedule App)?</p>`,
              IncludedPlugins: ['Booking_Schedule']},
                {ID: 1000015,Icon: 'mdi-room-service', Name: 'Service Management',MultiplePluginsAllowed: true,
                PluginOptions: ['Booking_Schedule','Standard_Data_Table','Calendar_Jobs']},
                // {ID: 1000008, Name: 'Filing App'},
                // {ID: 1000009, Name: 'WIP Management'}
                // really we need to actually also offer "Promotions" (campbuilder), Merch Store (another online store), LMS (when figured),
                //and then other teamp portal stuff but skip bs for now
              ]},
              // {ID: 1000004,Icon: 'mdi-newspaper-variant-multiple-outline',Name: 'Content Portal',
              // Description: `<p>Want a content portal, articles, blogs or other?</p>`,
              // Pluginid: 'Social_Network_Builder',Options: [
              //   {ID: 1000016,Icon: 'mdi-email-newsletter', Name: 'Articles',IncludedPlugins: ['Site_Articles']},
              //   {ID: 1000017,Icon: 'mdi-typewriter', Name: 'Blogs',IncludedPlugins: ['Site_Blogs']},
              //   {ID: 1000018,Icon: 'mdi-forum', Name: 'Forums',IncludedPlugins: ['Site_Forums']},
              //   //let's hit the rest later
              // ]},
              // {ID: 1000004,Icon: 'mdi-library-shelves',Name: 'Libraries',
              // Description: `Looking for a centralized library to host organizational Knowledge Base?`,
              // Pluginid: 'Library_Builder',Options: [
              //   // {ID: 1000006, Name: 'Sales Management'},
              //   // {ID: 1000007, Name: 'Warehouse Management'},
              //   // {ID: 1000008, Name: 'Filing App'},
              //   // {ID: 1000009, Name: 'WIP Management'}
              // ]},//really Libraries is "TBD"
            ],
        }
    },	
    computed:{
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        ComputedScopeOptions(){
        return this.ScopeOptions.map(plus => {
          let plusobj = Object.assign({},plus)
          plusobj.Price = 0
          if(plusobj.Pluginid){
            let plug = this.RAPlugins.find(obj => obj.id === plusobj.Pluginid)
            plusobj.PluginObject = {
              id: plug.id,
              Name: plug.Name,
              Price: plug.Price,
              Description: plug.Description,
              Level: plug.Level,
              Module_Category: plug.Module_Category,
              Type: plug.Type
              }
            plusobj.Price = plug.Price
          }
          plusobj.DisplayName = plus.Name
          plusobj.Name = plusobj.Name.split(' ').join('_')
          plusobj.Options = plusobj.Options.map(opt => {
            let optobj = Object.assign({},opt)
            optobj.ParentName = plusobj.Name
            optobj.DisplayName = optobj.Name
            optobj.Name = optobj.Name.split(' ').join('_')
            if(optobj.IncludedPlugins){
              optobj.IncludedPlugins = optobj.IncludedPlugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug)
                return {
                  id: plugobj.id,
                  Name: plugobj.Name,
                  Icon: plugobj.Icon,
                  Price: plugobj.Price,
                  Description: plugobj.Description,
                  Level: plugobj.Level,
                  Module_Category: plugobj.Module_Category,
                  Type: plugobj.Type
                  }
              })
              optobj.Package = {
                Name: optobj.DisplayName,
                ParentName: optobj.ParentName,
                // SubText: plusobj.DisplayName,
                  Color: {hexa: '#9c27b0 '},
                Description: optobj.Description,
                Price: plusobj.Price+optobj.IncludedPlugins.map(plug => {
                  return plug.Price
                }).reduce((a, b) => a + b, 0),
                // KeyPoints: optobj.IncludedPlugins.map(plug => {
                //   return {
                //     title: plug.Name,
                //     icon: plug.Icon,
                //     Active: true
                //   }
                // })
              }
            }
            if(optobj.PluginOptions){
              optobj.PluginOptions = optobj.PluginOptions.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug)
                let retobj = {
                  id: plugobj.id,
                  Icon: plugobj.Icon,
                  Name: plugobj.Name,
                  Price: plugobj.Price,
                  Description: plugobj.Description,
                  Level: plugobj.Level,
                  Module_Category: plugobj.Module_Category,
                  Type: plugobj.Type,
                  }
                  if(typeof retobj.Active  === 'undefined'){
                    retobj.Active = false
                  }
                  return retobj
              })
              optobj.Package = {
                Name: optobj.DisplayName,
                ParentName: optobj.ParentName,
                // SubText: plusobj.DisplayName,
                Description: `<p>Options for this package includes:</p>`,
                Color: {hexa: '#9c27b0 '},
                Price: plusobj.Price+optobj.PluginOptions.map(plug => {
                  return plug.Price
                }).reduce((a, b) => a + b, 0),
                KeyPoints: optobj.PluginOptions.map(plug => {
                  return {
                    title: plug.Name,
                    icon: plug.Icon,
                    Active: true
                  }
                })
              }
              if(optobj.MultiplePluginsAllowed){
                optobj.Package.PriceFootnote = 'Price indicative of all below selected'
              }
              else {
                optobj.Package.PriceFootnote = 'Price dependant on plugins selected'
              }
            }
            //console.log('optobj.Package',optobj.Package)
            return optobj
          })
          return plusobj
        })
      },
        SitesDirectory(){
            return this.Directories.find(obj => obj.id === 'Systems')
        },
        SitesCategoryField(){
            return this.SitesDirectory && this.SitesDirectory.ListPrimaryField ? this.SitesDirectory.ListPrimaryField : ''
        },
        SiteCategories(){
            let field = this.SitesDirectory.ListPrimaryField
            return field && field.Options ? 
            field.Options : []
        },
        SiteCategoryFields(){
            return [this.SitesCategoryField,this.KeyWordsField]
        },
        SiteKeyWordOptions(){
            return this.$store.state.SiteKeyWordOptions.filter(kw => {
                return kw.Type.Name === 'Site'
            })
        },
        KeyWordsField(){
            return {id: 'KeyWords',Name: 'KeyWords',DisplayName: 'KeyWords',Type: 'Option Set',IsMultiple: true,Options: this.SiteKeyWordOptions,CanAdd: true}
        },
        ComputedActiveEntity(){
            return {id: 'Systems',AllFields: this.AllFields,WikiFields: this.AllFields,Galleries: [],DocumentRegisters: [],SubCollections: [],Primary_Field_Name: 'Name'}
        },
        EntityDataRef(){
            return db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        System: {
        handler: function(newvalue, oldvalue) {
          if(newvalue !== oldvalue){
            this.GetActiveSite()
          }
        }
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetActiveSite()
    },
    methods:{
        NavigateWebsiteBuilder(){
            this.$emit('NavigateWebsiteBuilder')
        },
        ActivateProcessing(bool){
            this.$emit('ActivateProcessing',bool)
        },
        ConfigureApplicationOption(option){
            if(option === 'Skip Listing'){
            this.ActiveSite.PrimaryScope.Listing = false
            this.ActiveSite.PrimaryScope.Website = false
            }
            else{
            this.ActiveSite.PrimaryScope.Listing = true
            this.ActiveSite.PrimaryScope.Website = true
            }
        },
        CancelSiteUpdate(){
            this.SuiteTemplateDialog = false
            this.NewSuiteStep = 1
        },
        next () {
            this.scopestep = this.scopestep + 1 === this.ComputedScopeOptions.length
            ? 0
            : this.scopestep + 1
        },
        prev () {
            this.scopestep = this.scopestep - 1 < 0
            ? this.length - 1
            : this.scopestep - 1
        },        
      ProcessSiteUpdate(site,siteref){
        site.KeyWords = site.KeyWords.map(kw => {
                    let kwobj = {}
                    if(!kw.id){
                        let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
                        let date = new Date()
                        kwobj.Name = kw
                        kwobj.Type = {
                            ID: 1000002,
                            Name: 'Site'
                        },
                        kwobj.Created_By = user  
                        kwobj.Created_Byid = user.id
                        kwobj.Modified_By = user         
                        kwobj.Modified_Byid = user.id
                        kwobj.Created_On = date
                        kwobj.Modified_On = date
                        kwobj.Original_Recordid = this.$store.state.ActiveSuiteid
                    }
                    else{
                        kwobj = Object.assign({},kw)
                    }
                    return kwobj
                })
        let unregisteredkeywords = site.KeyWords.filter(kw => {
                return !kw.id
            })
        if(unregisteredkeywords.length > 0){
                unregisteredkeywords.map((kw,kwindex) => {
                     let path = db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords')
                        let ref = path.doc()
                        let newdocid = ref.id
                        console.log('kw',kw)
                        db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords').doc(newdocid).set(kw).then(newkwdoc => {
                        let sitekwobj = site.KeyWords.find(obj => obj.Name === kw.Name)
                        sitekwobj.id = newdocid
                        if(kwindex-1+2 === unregisteredkeywords.length){
                           this.UpdateSearchandSite(site,siteref)
                        }
                    })
                })
            }
            else{
                this.UpdateSearchandSite(site,siteref)
            }
      },
      GetSiteKeyWordsSearchQuery(KeyWords,query){
        let vm = this
        return new Promise(function(resolve, reject) {
            KeyWords.map((kw,i) => {
                let title = kw.Name
                //console.log('kw',kw,title)
                    vm.$store.dispatch('CreateSearchTitle',kw.Name).then(searchtitle => {
                    query = query.concat(searchtitle)
                    if(i-1+2 === KeyWords.length){
                        resolve(query)
                    }
                    })
                })
        })
        },
      UpdateSearchandSite(site,siteref){
            let vm = this
            let query = []
            if(site.Company_Name){
            vm.$store.dispatch('CreateSearchTitle',site.Company_Name).then(searchtitle => {
                query = searchtitle
                if(site.KeyWords && site.KeyWords.length > 0){
                vm.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                    query = query.concat(kwsearch)
                    site.SearchQuery = query
                    siteref.update(site).then(upddoc => {
                        //this.CancelUpdateSiteInfo()
                    })
                })
                }
                else{
                    site.SearchQuery = query
                    siteref.update(site).then(upddoc => {
                        //this.CancelUpdateSiteInfo()
                    })
                }
                
            })
            }
            else if(site.KeyWords && site.KeyWords.length > 0){
            vm.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                query = query.concat(kwsearch)
                site.SearchQuery = query
                siteref.update(site)
            })
            }
        },
        GetActiveSite(){
            this.ActiveSite = JSON.parse(JSON.stringify(this.System))
            if(!this.ActiveSite.Business_Hours){
                this.ActiveSite.Business_Hours = {
                    WeekDays: {},
                    WeekEnds: {},
                    PublicHolidays: {}
                }
            }
            if(!this.ActiveSite.PrimaryScope){
                this.ActiveSite.PrimaryScope = {
                    Website: true,
                    Listing: true
                }
            }
            if(typeof this.ActiveSite.PluginDataBase === 'undefined'){
                this.ActiveSite.PluginDataBase = {}
            }
            this.RAPlugins.map(plug => {
                //console.log('this.PluginDataBase[plug.id]',this.PluginDataBase[plug.id])
                if(typeof this.PluginDataBase[plug.id] !== 'undefined'){
                this.ActiveSite.PluginDataBase[plug.id] = this.PluginDataBase[plug.id]
                }
            })
    },
    }
}
</script>

<style>

</style>



