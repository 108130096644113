<template>
  <div>
    <v-card-title>
        <v-spacer>
        </v-spacer>
        <v-btn outlined @click="UpdateOptionSet()" color="success">Save</v-btn>
    </v-card-title>
    <v-list>
           <v-dialog             			
                max-width="400px">							
                <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on">Add Option</v-btn>
                </template>
                <v-card>
                <v-card-title>
                    New Filter
                    </v-card-title>
                    <v-card-text>
                    <v-text-field v-model="NewOptionName" label="Name"/>
                    <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                        v-model="NewOption.Type" label="Type"/> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-btn>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddOption(1)">Save</v-btn>
                    </v-card-actions>  
                </v-card>
            </v-dialog>
           
        <v-list-group
            v-for="item in ListPrimaryField.Options"
            :key="item.ID"
            v-model="item.active"
            no-action
        >
            <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title v-text="item.Name"></v-list-item-title>
            </v-list-item-content>
            </template>
             <v-dialog             			
                max-width="400px">							
                <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on">Add Option</v-btn>
                </template>
                <v-card>
                <v-card-title>
                    New Filter
                    </v-card-title>
                    <v-card-text>
                    <v-text-field v-model="NewOptionName" label="Name"/>
                    <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                        v-model="NewOption.Type" label="Type"/> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-btn>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddOption(2,item)">Save</v-btn>
                    </v-card-actions>  
                </v-card>
            </v-dialog>
            <v-btn x-small disabled @click="AppendFilters(1,item)">Append Filters</v-btn>

            <v-list-group
            v-for="child in item.Options"
            :key="child.ID"
           sub-group
            no-action
        >
            <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title v-text="child.Name"></v-list-item-title>
            </v-list-item-content>
            </template>
             <v-dialog             			
                max-width="400px">							
                <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on">Add Option</v-btn>
                </template>
                <v-card>
                <v-card-title>
                    New Filter
                    </v-card-title>
                    <v-card-text>
                    <v-text-field v-model="NewOptionName" label="Name"/>
                    <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                        v-model="NewOption.Type" label="Type"/> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-btn>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddOption(3,child)">Save</v-btn>
                    </v-card-actions>  
                </v-card>
            </v-dialog>
            <v-btn x-small disabled @click="AppendFilters(2,child,item)">Append Filters</v-btn>

            <v-list-item
            v-for="subchild in child.Options"
            :key="subchild.ID"
            >
            <v-list-item-content>
                <v-list-item-title v-text="subchild.Name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
            <v-btn x-small disabled @click="AppendFilters(3,subchild,child,item)">Append Filters</v-btn>
             </v-list-item-content>
            </v-list-item>
        </v-list-group>
        </v-list-group>
        </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            ParentFilter: '',
            ActiveTreeNode: [],
            NewOptionName: '',
            ActiveTreeList: [],

        }
    },	
    computed:{
        ListPrimaryField(){
            return this.ActiveStore && this.ActiveStore.CategoryField ? 
            this.ActiveStore.CategoryField : ''
        },
        ActiveStore(){
            return this.$store.state.ActiveStore
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        UpdateOptionSet(){
            db.collection('Stores').doc(this.$store.state.ActiveStore.id).update({
                CategoryField: this.ListPrimaryField
            })
        },
        AddOption(lvl,parent){
            this.ListPrimaryField.lastassignedinteger = this.ListPrimaryField.lastassignedinteger-1+2
            let newoption = {
                Name: this.NewOptionName,
                DisplayName: this.NewOptionName,
                AdditionalOptions: [],
                ID: this.ListPrimaryField.lastassignedinteger
            }
            let level = this.ListPrimaryField.Levels[lvl-1]
            newoption.Level = level
            newoption.LevelFieldName = level.FieldName
            if(lvl < this.ListPrimaryField.LastLevel){
                newoption.Options = []
                newoption.HasDeepHierarchy
            }
            if(lvl === 1){
               this.ListPrimaryField.Options.push(newoption) 
            }
            else if(lvl !== 1){
                parent.Options.push(newoption)
            }
            this.NewOptionName = ''
            //thus the below shows only issue is knowing which level we wokr from, easy
            //HasDeepHierarchy is lvl not exceeding
            //Level (fullobj)
            //LevelFieldName (txt)
            //Options (IFHasDeepHierarchy)
            
        }
    }
}
</script>

<style>

</style>



