<template>
  <div>
    <v-dialog v-model="ProductOptionDialog" max-width="600"  
    transition="dialog-bottom-transition">
    <v-card rounded>
        <v-card-title >
            Add product option
        </v-card-title>
        <v-card-subtitle style="padding-top: 10px;">
            You will be able to edit this later as well
        </v-card-subtitle>
        <v-card-title class="justify-end">
            <v-spacer>
            </v-spacer>
           <v-btn-toggle v-model="OptionTypeFilter">
            <v-btn small >
                <v-icon small>mdi-view-list</v-icon>
            </v-btn>
            <v-btn small >
                <v-icon small>mdi-format-color-fill</v-icon>
            </v-btn>
            </v-btn-toggle>
        </v-card-title>
        <v-card-subtitle v-if="ProductCategory.AdditionalOptions">
            <v-chip-group col>
                <v-chip @click="AddCategoryOptiontoProduct(opt)" v-for="opt in UnusedCategoryOptions" :key="opt.itemObjKey">
                    {{opt.DisplayName}}
                </v-chip>
            </v-chip-group>
        </v-card-subtitle>
        <v-card-text v-if="OptionTypeFilter === 0">
            <v-text-field v-if="NewOptionSetIndex === -1" outlined dense placeholder="Option Name"
            label="Type in option name"
            v-model="NewOptionSet.Name" :rules="[$store.state.formrules.required]"/>
              <v-text-field v-if="NewOptionSetIndex > -1" outlined dense placeholder="Option Name"
            label="Type in option name"
            v-model="NewOptionSet.DisplayName" :rules="[$store.state.formrules.required]"/>
            <v-combobox item-text="Name" multiple outlined dense chips placeholder="Press Enter to include"
            label="Type in options"
            v-model="NewOptionSet.Options" :rules="[$store.state.formrules.required]"/>
        </v-card-text>
        <v-card-actions>
            <v-btn outlined color="warning" @click="CancelProductOptionDialog()">Cancel</v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn dark class="green" @click="SaveNewAdditionalOptionSet()">Save</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
        <v-list>
            <v-btn outlined color="success" @click="CheckSaveStoreProduct()"
            >Save</v-btn>
            <v-row class="justify-center">
                <v-col cols="12" xl="6" lg="6" md="12">
                    <v-card-title>
                General
            </v-card-title>
            <v-card-text>
                 <v-text-field outlined dense placeholder="Title"
                label="Type in Product Title"
                v-model="ActiveProduct.Title" :rules="[$store.state.formrules.required]"/>
                 <v-list-item class="justify-center">
                <ContentEditableField style="padding-bottom: 30px;"
                :FieldObject="ActiveProduct" :FieldName="'Description'"
                :FieldValue="ActiveProduct.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-list-item>
            <!-- remember to calculate the cost and stuff, but yeah ok maybe stupid but yeah -->
             <v-text-field outlined dense placeholder="Price"
                label="Type in Product Price" type="number"
                v-model.number="ActiveProduct.Price" :rules="[$store.state.formrules.required]"/>
            </v-card-text>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="12">
                    <v-card-title>
                Grouping
            </v-card-title>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Category
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-combobox chips item-text="DisplayName"
                        v-model="ActiveProduct.Category"
                        :items="AllProductCategories"/>
                        <!-- <v-card>
                            Category Image
                        </v-card> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        <v-list-item style="padding-top:10px;">
            <!-- <v-text-field v-if="NewOptionSetIndex === -1" outlined dense placeholder="Option Name"
            label="Type in option name"
            v-model="NewOptionSet.Name" :rules="[$store.state.formrules.required]"/> -->
           
            <v-combobox chips item-text="DisplayName" label="Shipping Group"
               outlined disabled v-model="ActiveProduct.ShippingGroup" placeholder="Select Shipping Group"
                :items="ShippingGroups"/>
        </v-list-item>
                </v-col>
            </v-row>
            
            
        </v-list>
         <v-list>
            
            <v-row class="justify-center">
                <v-col cols="12" xl="6" lg="6" md="12">
                     <v-card-title>
                Additional Info
            </v-card-title>
        <!-- add info Sections can add more sections -->
        <v-expansion-panels>
                <v-expansion-panel>                    
                    <v-expansion-panel-header>
                        Product Options
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                          <v-list-item>                  
                                <v-chip @click="ActivateProductOptionDialog()" v-on="on" outlined>Add Another Option
                            <v-icon>mdi-plus</v-icon>                
                            </v-chip>
                        </v-list-item>
                        <v-divider></v-divider>
                            <v-list class="detailslistoutline" dense v-for="(opt,opti) in ProductAdditionalOptions" :key="opt.itemObjKey">
                            <!-- <v-divider></v-divider> -->
                            <v-list-item dense>
                                <v-list-item-content>
                            {{opt.Name}}
                                </v-list-item-content>
                                <v-list-item-content>
                            <v-chip-group column>
                                <v-chip v-for="childopt in opt.Options" :key="childopt.itemObjKey">
                                    {{childopt.Name}}
                                </v-chip>
                            </v-chip-group>
                                </v-list-item-content>
                                <v-list-item-action>
                                <v-btn outlined small @click="EditProductOption(opt,opti)" class="elevation-6 orange--text"
                               >       
                                    <v-icon small>
                                        mdi-pencil
                                        </v-icon>Edit
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-action>
                                    <v-btn outlined small @click="ConfirmDeleteProductOption(opt,opti)" class="elevation-6 red--text"
                                   >
                                    <v-icon small>
                                        mdi-trash-can-outline
                                    </v-icon>Delete
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-action
                                ><v-switch outlined dense @change="SaveSharedCatOption(opt)"
                                    v-model="opt.CategoryShared" />
                                </v-list-item-action>
                                <v-list-item-action-text>
                                    <v-menu
                                    top
                                    :offset-y="offset"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <v-icon>mdi-information</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-subtitle>
                                            Category Shared
                                        </v-card-subtitle>
                                        <v-card-text>
                                        Share with other Products in Category
                                        <v-switch outlined dense @change="SaveSharedCatOption(opt)"
                                    v-model="opt.CategoryShared" />
                                        </v-card-text>
                                    </v-card>
                                    </v-menu>
                                </v-list-item-action-text>
                            </v-list-item>
                            <!-- <v-divider></v-divider> -->
                            </v-list>
                            <v-divider></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel disabled>                    
                    <v-expansion-panel-header>
                        Custom Text Fields
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>        
      
         <v-list-item style="padding-top: 20px;">
          
           <v-textarea outlined dense placeholder="Return Refund Policy"
                label="Type in this Product's Return Refund Policy"
                v-model="ActiveProduct.Return_Refund_Policy" disabled/>
        </v-list-item>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="12">
                     <v-card-title>
                Store Settings
            </v-card-title>
            <v-expansion-panels disabled>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Store Settings
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list-item>
                        On Sale
                        </v-list-item>
                        <v-list-item>Pre Order (b/O)
                        </v-list-item>
                        <!-- only if Online Store -->
                        <v-list-item>
                        Show Price
                        </v-list-item>
                        <v-list-item>
                        SEO Settings
                        </v-list-item>
                        <v-list-item>
                        Create Coupon
                        </v-list-item>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
                </v-col>
            </v-row>
           
        <!-- add info sections end -->
           
           </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TableMain from '@/components/Database/TableMain';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
    props: ['System','SystemEntities','ActiveProduct','EditingProduct','StoreProducts',
    'ProductCollectionRef','ShippingGroupsRef','CategoriesRef',
    'UnreadNotifications','SystemPhotoLibraries','SystemDocumentLibraries','ActiveTemplate','DocumentationLibraries','Directories'],
    components: {TableMain,ContentEditableField},
    data() {
        return {
            OptionTypeFilter: 0,
            ProductOptionDialog: false,
            NewOptionSetIndex: -1,
            NewOptionSet: {
                Name: ''
            },
            DefaultNewOptionSet: {
                Name: ''
            },
          editedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',            
          },
          defaulteditedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',
          },
          menu: false,
        }
    },	
    computed:{
        UnusedCategoryOptions(){
            return this.ProductCategory && this.ProductCategory.AdditionalOptions.filter(opt => {
                return !this.ProductAdditionalOptions.find(obj => obj.Name === opt.Name) 
            })
        },
        ProductAdditionalOptions(){
            return this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
        },
        ShippingGroups(){
            return this.$store.state.ShippingGroups
        },
        
    
      CategoryField(){
        return this.ActiveStore && this.ActiveStore.CategoryField ? 
        this.ActiveStore.CategoryField : ''
      },
      ActiveStore(){
        return this.$store.state.ActiveStore
      },
      AllProductCategories(){
        return this.ProductsCatsLevel3.concat(this.ProductsCatsLevel2,this.ProductCategories)
      },
      ProductsCatsLevel3(){
            return this.ProductsCatsLevel2.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductsCatsLevel2(){
            return this.ProductCategories.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
        ProductCategory(){
            return this.ActiveProduct && this.ActiveProduct.Category && this.ProductCategories.find(obj => obj.id === this.ActiveProduct.Category.id) ? 
            this.ProductCategories.find(obj => obj.id === this.ActiveProduct.Category.id) : ''
        },
        CategoryOptions(){
            return this.ProductCategory ? 
            this.ProductCategory.AdditionalOptions : []
        },
        ProductRef(){
            return this.ProductCollectionRef.doc(this.ActiveProduct.id)
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ComputedStoreProducts(){
          return this.StoreProducts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignProductIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      
        System: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id && !this.ActiveTemplate){
                     this.SystemInstance = this.System
                }
            },deep: true
        },
    },
    created(){
        
    },
    methods:{
        AddCategoryOptiontoProduct(opt){
            let options = this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
            let newinteger = 1000000+options.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: opt.Name,
                DisplayName: opt.Name,
                Type: 'Option Set',
                Options: opt.Options,
                lastassignedinteger: 1000000+opt.Options.length,
            }
            options.push(newopt)
            this.ProductRef.update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
                this.CancelProductOptionDialog()
            })
        },
        EditProductOption(opt,opti){
            this.NewOptionSet = opt
            this.NewOptionSetIndex = opti
            this.ActivateProductOptionDialog()
        },
        ConfirmDeleteProductOption(){

        },
        ActivateProductOptionDialog(){
            this.ProductOptionDialog = true
        },
        SaveNewAdditionalOptionSet(){
            let options = this.ActiveProduct.AdditionalOptions ? this.ActiveProduct.AdditionalOptions : []
            if(this.NewOptionSetIndex === -1){
            let newinteger = 1000000+options.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: this.NewOptionSet.Name,
                DisplayName: this.NewOptionSet.Name,
                Type: 'Option Set',
                Options: this.NewOptionSet.Options.map((opt,opti) => {
                    return {ID: 1000001+opti,Name: opt}
                }),
                lastassignedinteger: 1000000+this.NewOptionSet.Options.length,
            }
            options.push(newopt)
            this.ProductRef.update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
                this.CancelProductOptionDialog()
            })
            }
            else{
                console.log('yeah update only')
            }
           
        },
        CancelProductOptionDialog(){
            this.ProductOptionDialog = false
            this.NewOptionSet = Object.assign({},this.DefaultNewOptionSet)
            this.NewOptionSetIndex = -1
        },
        SaveSharedCatOption(option){
            let opt = this.ActiveProduct.AdditionalOptions.find(obj => obj.ID === option.ID)
            //console.log('SaveSharedCatOption',opt)
            let options = this.ProductCategory.AdditionalOptions
            //quetion is actually, whether this option exists or not. Issue being, bloody names. but okay let's try
            let match = options.find(obj => obj.Name === opt.Name)
            if(match && !opt.CategoryShared){
                let index = options.indexOf(match)
                options.splice(index,1)
                this.CategoriesRef.doc(this.ActiveProduct.Category.id).update({
                        AdditionalOptions: options
                    }).then(updcat => {
                        this.ActivateSnackbar(true,'Updated Category with Option!')
                    })
            }
            else if(!match && opt.CategoryShared){
                let newinteger = 1000000+options.length
                    newinteger++
                    let newopt = Object.assign({},opt)
                    options.push(newopt)
                    this.CategoriesRef.doc(this.ActiveProduct.Category.id).update({
                        AdditionalOptions: options
                    }).then(updcat => {
                        this.ActivateSnackbar(true,'Removed Option from Category!')
                    })
            }
            else if(match){
                //naturally,this makes no sense, why would it be "false", then change to "true" but STILL on the list?
            }        
            
        },
        OLDSaveCatAdditionalOptionSet(){
            let options = this.ProductCategory.AdditionalOptions
            let newinteger = 1000000+this.ProductCategory.AdditionalOptions.length
            newinteger++
            let newopt = {
                ID: newinteger,
                Name: this.NewOptionSet.Name,
                DisplayName: this.NewOptionSet.Name,
                Type: 'Option Set',
                Options: [],
                lastassignedinteger: 1000000,
            }
            options.push(newopt)
            this.CategoriesRef.doc(this.ActiveProduct.Category.id).update({
                AdditionalOptions: options
            }).then(updcat => {
                this.ActivateSnackbar(true,'Updated Property!')
            })
        },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      AssignProductIMG(){
        this.ActiveProduct.IMG = this.SelectedImage.fileurl
            this.ProductCollectionRef.doc(this.ActiveProduct.id).update({
                IMG: this.ActiveProduct.IMG
            })
        this.$store.commit('setIMGProp','')
      },
      CancelStoreProduct(openafter){
        this.menu = false
        this.editedProduct = Object.assign({},this.defaulteditedProduct)
        if(openafter){
          setTimeout(() => {
            this.menu = true
          }, 500);
        }
      },
      CheckSaveStoreProduct(){
        if(this.ActiveProduct.Category){
            if(typeof this.ActiveProduct.Category.ID === 'undefined'){
                this.AddCategory(this.ActiveProduct.Category).then(newcat => {
                    this.ActiveProduct.Category = newcat
                    this.SaveStoreProduct()
                })
            }
            else{
               this.SaveStoreProduct() 
            }
        }
      },
      AddCategory(cat){
        
        let vm = this
        return new Promise(function(resolve, reject) {	
            let field = vm.CategoryField ? vm.CategoryField : {}
            let options = field.Options ? field.Options : []
            let newinteger = 1000000+options.length
            newinteger++
            let newcat = {
                ID: newinteger,
                Name: cat,
                DisplayName: cat,
                AdditionalOptions: []
            }
            options.push(newcat)
            field.lastassignedinteger = newinteger
            db.collection('Stores').doc(vm.ActiveStore.id).update({
                CategoryField: field
            }).then(newcatdoc => {
                resolve(newcat)
            })
        })
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      SaveStoreProduct(){
        let updobj = {}
        let props = ['Category','Title','Description','Price']
        props.map(prp => {
            if(typeof this.ActiveProduct[prp] !== 'undefined'){
                updobj[prp] = this.ActiveProduct[prp]
            }
        })
        this.ProductRef.update(updobj).then(newprod => {
        //   this.CancelStoreProduct(true)
            this.ActivateSnackbar(true,'Successfully updated the product!') 
        })
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      GoBack(){
        this.ActiveLibTypeComp = ''
      },
      ActivateLibTypComponent(prodtype){
        this.ActiveLibTypeComp = prodtype
      },
    }
}
</script>

<style>

</style>



