<template>
  <div>
    <v-dialog persistent v-model="NewAppDialog" max-width="400">
      <v-card  max-height="650px">
        <v-card-title v-if="newappstep !== 1">
          {{NewAppDialogTitle}}
          <!-- {{NextStep}} -->
          <v-spacer>
            </v-spacer><v-avatar
                            color="primary lighten-2"
                            class="subheading white--text"
                            size="24"
                            v-text="newappstep"
                        ></v-avatar>
            <v-icon @click="ToggleNewAppDialog()" color="red" class="soloactionicon">
              mdi-close</v-icon>
        </v-card-title>
         <v-img height="200"   src="@/assets/AppBuilderLogo.jpg"/>
         <v-list v-if="newappstep > 2" dark style="padding: 0px;">
          <v-list-item class="justify-space-between">
            <v-list-item-content>
              <v-list-item-title>
              Cost</v-list-item-title>
              <v-list-item-subtitle>
              Once Published
              </v-list-item-subtitle>
            </v-list-item-content>
             <v-list-item-action>
              <v-list-item-title>
           {{CurrencyFormatter(NewAppTotalPrice,$store.state.DefaultCurrency.Currency)}}
              </v-list-item-title>
               <v-list-item-subtitle>
              per Month
              </v-list-item-subtitle>
             </v-list-item-action>
          </v-list-item>
          </v-list>
        <!-- <v-card-subtitle v-if="newappstep !== 1">
          {{NewApp && NewApp.Plugins ? NewApp.Plugins.length : ''}} Selected
        </v-card-subtitle> -->
        <!-- <v-card-text> -->
         <v-window v-model="newappstep">
      <v-window-item :value="1">
        <!-- <v-parallax src="@/assets/AppBuilderLogo.jpg" height="200"
        /> -->
         
        <v-card-text>
         Using the App Builder you can finally get your hands on tailored database solutions, and make them accessible to your team. 
        </v-card-text>
      </v-window-item>
       <v-window-item :value="2">
        <v-card-text>
          <v-text-field outlined
            label="App Name"
            v-model="NewApp.Name"
          ></v-text-field>
           <span class="text-caption grey--text text--darken-1">
            This is the name you will use to reference your App
          </span>
          <!-- Future deve need something that serves directly
            <v-autocomplete :items="ComputedIconOptions"
          v-model="NewApp.Icon" item-text="Icon"
          >
          <template v-slot:selection="data">
             <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="NewApp.Icon = ''"
                >
                  <v-avatar left>
                    <v-icon>{{ data.item.Icon }}</v-icon>
                  </v-avatar>
                  {{ data.item.Name }}
                </v-chip>     
          </template>
              <template v-slot:item="data">
                <v-chip                >
                  <v-avatar left>
                    <v-icon>{{ data.item.Icon }}</v-icon>
                  </v-avatar>
                  {{ data.item.Name }}
                </v-chip>               
                </template>
          </v-autocomplete> -->
          <v-text-field outlined
            label="App Icon"
            v-model="NewApp.Icon"
          >
          <template v-slot:append>
            <a href="https://pictogrammers.github.io/@mdi/font/5.9.55/" target="_new">See List</a>
          </template>
          </v-text-field>
          <v-icon>{{NewApp.Icon}}</v-icon>
          <span class="text-caption grey--text text--darken-1">
            This is Icon that will appear in the navigaiton
          </span>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="3">
        <v-card-text>
          <v-row class="justify-end" style="padding:20px;width: 100%;">
            <v-col style="flex-grow: 0!important;">
                <v-card elevation="4" @click="newappstep = 4"
                :height="130" :width="130" tile >
                   <v-card-title class="justify-center" style="padding-bottom: 0px;">
                    <v-icon size="70">mdi-cart</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                    Templates
                   </v-card-title>
                </v-card>
            </v-col>
            <v-col style="flex-grow: 0!important;">
                <v-card elevation="4"  @click="newappstep = 5"
                :height="130" :width="130" tile dark>
                   <v-card-title class="justify-center" style="padding-bottom: 0px;">
                    <v-icon size="70">mdi-apps</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;color: white;">
                    Custom
                   </v-card-title>
                </v-card>
            </v-col>
            </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="4">
        <v-card-text>
          <!-- At this point and time the entities are assigned with their new id's, they are done.  -->
            <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['App'])" :tab="systemguideelmnt"
            :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
              />
        </v-card-text>
      </v-window-item>
        <v-window-item :value="5">
        <v-card-text>
         
          </v-list-item>
          <v-virtual-scroll
                              :items="TablePlugins"
                              :height="200"
                              item-height="35"                  
                            >
                            <template v-slot:default="{ item }">
          <v-list-item dense class="justify-space-between">
                <v-list-item-avatar size="25">
                    <v-icon small>{{item.Icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{item.Name}}
                </v-list-item-content>
                <v-list-item-action-text>
                    {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-action-text>
                <v-list-item-action>
                  <v-badge
                    avatar
                    bordered
                    overlap
                  >
                    <template v-slot:badge>
                      <v-avatar :color="TablePluginPickCount(item) > 0 ? 'orange white--text' : 'grey white--text'">
                      {{TablePluginPickCount(item)}}
                      </v-avatar>
                    </template>
                    
                      
                      <v-btn dark :depressed="item.Strictly_One_Purchase && TablePluginPickCount(item) > 0" :color="item.Strictly_One_Purchase && TablePluginPickCount(item) > 0 ? 'grey' : 'success'" fab x-small @click="PickPlugin(item)">
                      <v-icon>mdi-plus</v-icon></v-btn>
                  </v-badge>
                </v-list-item-action>
                <v-list-item-avatar size="15">
                  <v-btn dark color="red" fab x-small @click="ConfirmRemovePlugin(item)">
                      <v-icon x-small>mdi-minus</v-icon></v-btn>
                </v-list-item-avatar>
                
            </v-list-item>
                            </template>
          </v-virtual-scroll>
          <!-- At this point and time the entities are assigned with their new id's, they are done.  -->
            <!-- <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="TablePlugins" :tab="systemguideelmnt"
            :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
              /> -->
        </v-card-text>
      </v-window-item>
      
      <v-window-item :value="6">
        <v-expansion-panels>
          <v-expansion-panel v-for="table in ConfigRequiredImmediateTables" :key="table.itemObjKey">
            <v-expansion-panel-header>
                <v-list-item dense>
                <v-list-item-action>
                  <v-icon left>{{table.Table_Icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{table.DisplayName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{table.SingleName}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <v-list-item dense>
                  {{table.Entity_Type.Name}}
                </v-list-item>
                <v-list-item dense class="settingsfont">
                  <v-text-field
                  :label="'Table Name (Multiple '+table.DisplayName+')'"
                  v-model="table.DisplayName"
                ></v-text-field>
                </v-list-item>
                <v-list-item dense class="settingsfont">
                  <v-text-field
                  :label="'Single (1 '+table.SingleName+')'"
                  v-model="table.SingleName"
                ></v-text-field>
                </v-list-item>
                <v-list-item dense class="settingsfont">
                  <v-text-field
                  label="Icon"
                  v-model="table.Table_Icon"
                ></v-text-field>
                <v-icon>{{table.Table_Icon}}</v-icon>
                </v-list-item>
                <v-list-item dense class="settingsfont">
                  <v-text-field
                  :readonly="table.Entity_Type.Name === 'Booking Schedule'"
                  :label="table.Entity_Type.Name === 'Booking Schedule' ? 'Primary Field Name (Cannot Change)' : 'Primary Field Name'"            
                  v-model="table.Primary_Field_Name"
                ></v-text-field>
                </v-list-item>
                <v-list-item dense class="settingsfont">
                  <v-select
                  :label="table.Security_Structure && table.Security_Structure.Name === 'Warehouse' ? 'Security Structure (Cannot Change)' : 'Security Structure'" item-text="Name"
                  :items="EntitySecurityOptions" return-object :readonly="table.Security_Structure && table.Security_Structure.Name === 'Warehouse'"
                  v-model="table.Security_Structure"
                ></v-select>
                </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-window-item>
      <v-window-item :value="7">
        <v-card-text>
          <!-- At this point and time the entities are assigned with their new id's, they are done.  -->
            
              <v-virtual-scroll
                              :items="ExtensionPlugins"
                              :height="200"
                              item-height="35"                  
                            >
                            <template v-slot:default="{ item }">
          <v-list-item dense class="justify-space-between">
                <v-list-item-avatar size="25">
                    <v-icon small>{{item.Icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{item.Name}}
                </v-list-item-content>
                <v-list-item-action-text>
                    {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-action-text>
                <v-list-item-action>
                  <v-badge
                    avatar
                    bordered
                    overlap
                  >
                    <template v-slot:badge>
                      <v-avatar :color="ExtensionPluginPickCount(item) > 0 ? 'orange white--text' : 'grey white--text'">
                      {{ExtensionPluginPickCount(item)}}
                      </v-avatar>
                    </template>
                    
                      
                      <v-btn dark :depressed="item.Strictly_One_Purchase && ExtensionPluginPickCount(item) > 0" :color="item.Strictly_One_Purchase && ExtensionPluginPickCount(item) > 0 ? 'grey' : 'success'"
                        fab x-small @click="PickPlugin(item)">
                      <v-icon>mdi-plus</v-icon></v-btn>
                  </v-badge>
                </v-list-item-action>
                <v-list-item-avatar size="15">
                  <v-btn dark color="red" fab x-small @click="ConfirmRemovePlugin(item)">
                      <v-icon x-small>mdi-minus</v-icon></v-btn>
                </v-list-item-avatar>
                
            </v-list-item>
                            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-window-item>
      <!-- <v-window-item :value="5">
        <v-list dense v-for="table in ConfigRequiredInstallingTables" :key="table.itemObjKey">
          <v-list-item dense>
            {{table.Entity_Type.Name}}
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field
            :label="'Table Name (Multiple '+table.DisplayName+')'"
            v-model="table.DisplayName"
          ></v-text-field>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field
            :label="'Single (1 '+table.SingleName+')'"
            v-model="table.SingleName"
          ></v-text-field>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field
            label="Icon"
            v-model="table.Table_Icon"
          ></v-text-field>
          <v-icon>{{table.Table_Icon}}</v-icon>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field
            label="Primary Field Name"
            v-model="table.Primary_Field_Name"
          ></v-text-field>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-select
            :label="table.Security_Structure && table.Security_Structure.Name === 'Warehouse' ? 'Security Structure (Cannot Change)' : 'Security Structure'" item-text="Name"
            :items="EntitySecurityOptions" return-object :readonly="table.Security_Structure && table.Security_Structure.Name === 'Warehouse'"
            v-model="table.Security_Structure"
          ></v-select>
          </v-list-item>
        </v-list>
      </v-window-item> -->
      <v-window-item :value="8">
        Probably would be best to have overview and shit, but for now just add app tog et going testing concept
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="newappstep === 1"
        text
        @click="RegressNewappDialog()"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn dark v-if="CanSkipAppStep"
        color="warning"
        depressed
        @click="SkipNewappDialogStep()"
      >
        Skip
      </v-btn>
      <v-btn v-if="newappstep !== 7 && newappstep !== 3"
        color="primary"
        depressed
        @click="AdvanceNewappDialog()"
      >
        Next
      </v-btn>
      <v-btn v-if="newappstep === 7"
        color="primary"
        depressed
        @click="TESTPrepareNewApp()"
      >
        Install
      </v-btn>
    </v-card-actions>
        <!-- </v-card-text> -->
      </v-card>
    </v-dialog>
    <v-layout  column  v-if="MiniView"
            style="z-index:7;position: fixed;bottom: 100px;left: 30px;z-index:50;" >
            <v-btn icon x-large @click="ToggleSiteDialog()"><v-icon  color="links" x-large>mdi-menu</v-icon></v-btn>							
        </v-layout>
         <Callout  :PageHeadersFont="'Raleway'" :PageContentFont="'Raleway'"									
        :PageHeadersFontSize="30"	:style="BuilderHelperStyleandPosition" v-if="ViewBuilderHelper"						
        :tab="AppBuilderElement" @OpenDialog="OpenDialog" @ActionBtnMethod="ActionBtnMethod"
      />
    <!-- could actually start building apps on Ra this way, limiting to Systemconfig makes it difficult, if route was systembuilder etc -->
     <v-app-bar dark width="100%" id="appbuildermainnavbar" :class="$vuetify.theme.dark ? 'RAWebBtnGraddark' : 'RAWebBtnGradlight'"
     :style="SuiteLiaisonChannelActive ? 'top:15px;position: fixed;z-index:25;font-variant-caps: all-petite-caps;' : 
     'top:0px;position: fixed;z-index:25;font-variant-caps: all-petite-caps;'" v-if="!$route.meta.DataSingleBuilder && !PublicNavbarEditing || $route.name === 'SystemConfig'">
       <v-list width="100%" dense class="transparent">
        
         <v-list-item dense>
          <FavoritesManager :Userid="userLoggedIn.id"
          :System="System" :SystemEntities="SystemEntities"
          />
           
           <v-icon class="actionicon mx-2" @click="ToggleAppDarkMode()">
                      mdi-invert-colors
                    </v-icon>
           <v-list-item-content id="appbuilder-builderselector">
             Builder:
             <v-overflow-btn style="padding-top:15px;widht:50px;" @change="CheckBuilderRoute()"
              :items="ComputedBuilders" placeholder="Builder" v-model="SelectedBuilder"
                item-text="Name"
                  class="elevation-0 noborderoverflow"
                  dense return-object
                  >
                  <template v-slot:no-data>
                    <v-list-item>
                    You do not have any modules purchased. In the screen below you should really choose a module. 
                    </v-list-item>
                  <!-- <v-btn color="primary" @click="ProceedHelpder()">Proceed</v-btn> -->
                </template>
                  </v-overflow-btn>
           </v-list-item-content>
           
           <v-btn v-if="PluginDataBase && PluginDataBase.Social_Network_Builder && System.Social_Landing_Page && CanSocial" @click="IntranetViewToggle(true,true)" text>
              <v-icon dark>
                  mdi-repeat
                </v-icon>Switch to {{System.Social_Module_Name}}
            </v-btn>
           <v-list-item-content v-if="WebsiteBuilderView || NetworkBuilderView && !IsGroupView">
              Page:
             <v-overflow-btn style="padding-top:15px;"
              :items="ComputedSitePages" placeholder="Page" v-model="SelectedPage"
                item-text="Name" @change="EditOtherPage(SelectedPage)"
                  class="elevation-0 noborderoverflow"
                  dense return-object
                  ></v-overflow-btn>
            </v-list-item-content>
            <v-list-item-content v-if="SelectedBuilder.Name === 'App Builder'">
              App:
             <v-overflow-btn style="padding-top:15px;"
              :items="RegisteredApps" placeholder="App" v-model="SelectedApp"
                item-text="Name" @change="EditOtherApp(SelectedApp)"
                  class="elevation-0 noborderoverflow"
                  dense return-object
                  ></v-overflow-btn>
            </v-list-item-content>
            
            <v-list-item-actions v-if="SelectedBuilder && SelectedBuilder.Name === 'Website Builder'
            || SelectedBuilder && SelectedBuilder.Name === 'Social Network Builder'
            || MarketSiteView">
              <v-btn dark small color="success white--text" @click="AssignSocialItemInteractMethod('SavePageAsIs')">
                  Save Page
                  <v-icon small>mdi-content-save</v-icon></v-btn>
            </v-list-item-actions>
            <v-list-item-actions>
                 <ProfileActionsDropdown  :ClientDB="ClientDB" :HideAppStyling="true" :System="System"
                   @ToggleAppStylingView="ToggleAppStylingView"/>
            </v-list-item-actions>
         </v-list-item>
        
       </v-list>
      </v-app-bar>
      <SuiteBuilder v-if="SuiteBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements" :RegisteredApps="RegisteredApps"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities"
   @ToggleNewAppDialog="ToggleNewAppDialog"
    />
     <DocumentationLibraryBuilder v-if="DocLibBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities"
    />
     <FeaturedMemberBuilder v-if="FeaturedMemberBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities"
   @TogglePublicNavbarEditing="TogglePublicNavbarEditing" :PublicNavbarEditing="PublicNavbarEditing" :Directories="Directories"
    />
    <MarketplaceSiteBuilder v-if="MarketplaceSiteBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities"
   @TogglePublicNavbarEditing="TogglePublicNavbarEditing" :PublicNavbarEditing="PublicNavbarEditing"
    />
    
    <WebsiteBuilderComponent v-if="WebsiteBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements" :RADB="RADB"
     @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
     @TogglePublicNavbarEditing="TogglePublicNavbarEditing" :Directories="Directories" :SubscriptionPackages="SubscriptionPackages"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities" @SelectPage="SelectPage"
    />
    <DatabaseBuilderComponent v-if="DBBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities"
    />
    <NetworkBuilderComponent v-if="NetworkBuilderView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" :WebFormEntities="WebFormEntities" @SelectPage="SelectPage"
    /></div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SuiteBuilder from '@/components/SuiteBuilder/Navigators/SuiteBuilder';	
import WebsiteBuilderComponent from '@/components/SuiteBuilder/Navigators/WebsiteBuilderComponent';	
import DatabaseBuilderComponent from '@/components/SuiteBuilder/Navigators/DatabaseBuilderComponent';	
import NetworkBuilderComponent from '@/components/SuiteBuilder/Navigators/NetworkBuilderComponent';	
import DocumentationLibraryBuilder from '@/components/SuiteBuilder/Navigators/DocumentationLibraryBuilder';	
import FeaturedMemberBuilder from '@/components/SuiteBuilder/Navigators/FeaturedMemberBuilder';	
import MarketplaceSiteBuilder from '@/components/SuiteBuilder/Navigators/MarketplaceSiteBuilder';	
import FavoritesManager from '@/components/SuitePlugins/Favorites/FavoritesManager';	
import ProfileActionsDropdown from '@/components/Navigation/ProfileActionsDropdown'
import Callout from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
export default {
    props: ['System','SystemEntities','SitePages','SocialEntities','SystemPhotoLibraries','GroupEntities','GraphicalElements','GradientElements','DocumentationLibraries',
    'RADB','RAApp','PublicNavbarEditing','SuiteLiaisonChannelActive','Directories','SubscriptionPackages'],
    components: {SuiteBuilder,WebsiteBuilderComponent,
    DatabaseBuilderComponent,NetworkBuilderComponent,
    DocumentationLibraryBuilder,FeaturedMemberBuilder,FavoritesManager,
    MarketplaceSiteBuilder,Callout,RAPluginCarousel,ProfileActionsDropdown},
    data() {
        return {
          DefaultMemberGroup: {
                Name: 'System Administrators',
                Description: 'Default System Administrators Group - Non Editable',
                SecurityRoles: ['System Admin'],
                AdditionalProperties: {
                    MonetizationOfficer: false,
                    IsEagleViewer: false,
                    BusinessProfileAdmin: false,
                    SiteContentAdmin: false,
                    IsGuestUser: false,
                    IsSystemAdmin: true,
                    IsTeamActivityManager: false,
                    CanInviteUsers: true,
                    InviteGroups: [],
                    CanExport: true,
                    CanImport: true,
                },  
                CanSocial: true,              
                SocialRestrictions: false,
                SocialChannels: [],
                CompanySocial: false,
                UserTableFilters: [],
                ActivityQueries: {},
                ModerationPermissions: [],
                // DBNavList: '',
            },
          systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
          EntitySecurityOptions: [{ID:1000001,Name:"Custom Roles"},{ID:1000002,Name:"Business Unit"},{ID:1000003,Name:"Warehouse"}],
          NextStep: 1,
          newappstep: 1,
          NewApp: {
            Name: '',
            Plugins: [],
            MenuItems: []
          },
          DefaultNewApp: {
            Name: '',
            Plugins: [],
            MenuItems: []
          },
          NewAppDialog: false,
          ImmediateTables: [],
          InstallingTables: [],
          HelpElmntTop: '',
          HelpElmntLeft: '',
          HelperParentElement: '',
          infoelmnt: {
            Checked: false,
            HasActionButton: true,
            ActionButtonMethod: 'AdvanceHelperMethod',
            ActionhasConfirm: true,
            ActionConfirmLabel: 'Do not Show Again',
            ActionButtonName: 'Got it',
            Fixed: true,
            BorderPosition: 'right',
            Color: 'blue',
            Elevation: 6,
            // AlertIcon: 'mdi-information',
            ColoredBorder: true,
            Width: 400,
            MarginTop: 20,
            MarginBottom: 0,
            MarginLeft: 15,
            MarginRight: 0,
            Description: `<p>Toggle the Builder to change what you are editing</p>`,
            Dense: true,
            Prominent: true,
            Justify: 'justify-end',
            ActionList: [
            ]
          }, 
          SelectedPage: '',
          SelectedApp: '',
          SelectedBuilder: '',
          Builders: [
            {Name: 'Suite Builder'},
            {Name: 'App Builder'},
            {Name: 'Website Builder'},
            {Name: 'Social Network Builder'},
            {Name: 'Database Builder'},
            {Name: 'Documentation Builder'} ,
            {Name: 'Featured Member'},        
            {Name: 'Marketplace Site'},        
               
          ],
          WindowWidth: 0,
          WindowHeight: 0,
          
      snackbartop: true,
      StandbyDialog: false,
      snackbar: false,
  ActiveSession: '',
  CustomRolesDefault: 
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
      BURolesDefault: [
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
      ],
        }
    },
    computed:{
      ComputedIconOptions(){
        return this.$store.state.IconOptions.map(ico => {
          let lowercasename = ico.split('mdi-').join('').split('-')
          let name = ''
          lowercasename.map((w,i) => {
            name = name+w[0].toUpperCase()+w.substring(1)
            if(i-1+2 < lowercasename.length){
              name = name+' '
            }            
          })
          let icon = {
            Icon: ico,
            Name: name
          }
          return icon
        })
      },
      NewAppTotalPrice(){
        return this.NewApp.Plugins.map(plug => {
          let marketplug = JSON.parse(JSON.stringify(plug))
          let Price = marketplug.Price
          if(this.TablePluginPickCount(marketplug) > 1){
            Price = marketplug.Price*this.TablePluginPickCount(marketplug)
          }
          return Price
        }).reduce((a, b) => a + b, 0)
      },
      IsPrimarySite(){
      return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
    },
      ExtensionPlugins(){
            return this.RAPluginsMatched(['Database Builder','DBB']).filter(plug => {
              //if acti and team is included in a new app through the new app template, then it does not return here, based on filtering out "Strictly_One_Purchase" type,
              //from newappplugins. more or less, exaclty but anyway. So therefore
              //..what do we do to showcase that actually it is included? It's silly to excluded it, right? 
              return !plug.Installation_Tables && !plug.Exclusively_as_Extension && !plug.Installs_Tables
            })
        },
      TablePlugins(){
            return this.RAPlugins.filter(plug => {
                return plug.Installation_Tables
            })
        },
      MarketSiteView(){
            return this.SelectedBuilder && this.SelectedBuilder.Name === 'Marketplace Site'
        },
      RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
      ClientDB(){
      return db
    },
      EntitiesArray(){
            return this.$store.state.EntitiesArray
        },
      UsersArray(){
            return this.$store.state.UsersArray
        },
        DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
        CurrentUserProps(){
          let user = this.userLoggedIn
          let userobj = {
            Full_Name: user.Full_Name,
            Name: user.Name,
            Surname: user.Surname,
            id: user.id,
            Email: user.Email
          }
          return userobj
        },
      UsersforProperties(){
      return this.UsersArray.map(user =>{
        let userobj = {
          Full_Name: user.Full_Name,
          Name: user.Name,
          Surname: user.Surname,
          id: user.id,
          Email: user.Email
        }
        return userobj
      })
    }	,
      RegisteredApps(){
            return this.$store.state.AppsDataBase
        },
      AppsPath(){
      return this.$store.state.AppsPath
    },
      CanSkipAppStep(){
        return this.newappstep === 4 && this.NewApp.AppTemplateid || this.newappstep === 5 
      },
      NewAppDialogTitle(){
        switch (this.newappstep) {
          case 1 : return 'App Name'
          case 2 : return 'App Name'
          case 3 : return 'Choose Builder'
          case 4 : return 'Choose Templates'
          case 5 : return 'Choose Tables'
          case 6 : return 'Configure Table'
          case 7 : return 'Choose Extensions'
          case 8 : return 'Install'
          // case 6 : return 'Install'
        } 
      },
      ConfigRequiredImmediateTables(){
        return this.ImmediateTables.filter(table => {
          return !table.System_Required
        })
      },
      ConfigRequiredInstallingTables(){
        return this.InstallingTables.filter(table => {
          return !table.System_Required
        })
      },
      ViewBuilderHelper(){
        return this.AppBuilderHelperActive && typeof this.HelpElmntTop !== 'undefined' && typeof this.HelpElmntLeft !== 'undefined'
      },
      AppBuilderHelperActive(){
        return this.$store.state.AppBuilderHelperActive
      },
      AppBuilderHelperStep(){
        return this.$store.state.AppBuilderHelperStep
      },
      AppBuilderElement(){
        let helperobj = Object.assign({},this.infoelmnt)
        let description = ``
        let btnname = ''
        let method = ''
        let hasconfirm = false
        let confirmlabel = ''
        if(this.AppBuilderHelperStep === 1){
          description = `<p>Here is the Main Builder Menu that is present whenever your are in "Builder View". It remains open regardless which "Builder" (Module) you are editing. </p>`
          method = 'AdvanceHelperMethod'          
          confirmlabel = 'Do not Show Again'
          btnname = 'Got it'
          hasconfirm = true
          helperobj.Description = description
          helperobj.ActionButtonMethod = method
          helperobj.ActionhasConfirm = hasconfirm
          helperobj.ActionConfirmLabel = confirmlabel
          helperobj.ActionButtonName = btnname
        }
        else if(this.AppBuilderHelperStep === 2){
          description = `<p>The "Builder Selector" changes the "Builder" (Module) you are working on. Only purchased modules reflect as options... </p>`
          method = 'AdvanceHelperMethod'          
          confirmlabel = 'Do not Show Again'
          btnname = 'Got it'
          hasconfirm = true
          helperobj.Description = description
          helperobj.ActionButtonMethod = method
          helperobj.ActionhasConfirm = hasconfirm
          helperobj.ActionConfirmLabel = confirmlabel
          helperobj.ActionButtonName = btnname
        }
        return helperobj
      },
      AppBuilderGuideElementid(){
        if(this.AppBuilderHelperActive){
          if(this.AppBuilderHelperStep === 1){
            return 'appbuildermainnavbar'
          }
          else if(this.AppBuilderHelperStep === 2){
            return 'appbuilder-builderselector'
          }
        }
      },
      BuilderHelperStyleandPosition(){
        if(this.ViewBuilderHelper){
         let style = 'z-index:500;position: fixed;'
        style = style+'top:'+this.HelpElmntTop+'px;left:'+this.HelpElmntLeft+'px;'
        return style 
        }
        
      },
      UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
  ActiveMemberGroup(){
      return this.$store.state.ActiveMemberGroup
    },
      CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial || this.userIsAdmin
      },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      ComputedBuilders(){
        return this.Builders.filter(builder => {
          return builder.Name === 'Featured Member' || builder.Name === 'Marketplace Site' ||
          this.PluginDataBase[builder.Name.split(' ').join('_')] && this.PluginDataBase[builder.Name.split(' ').join('_')].Active && this.userIsAdmin || builder.Name === 'Suite Builder' && this.userIsAdmin || builder.Name === 'App Builder' && !this.IsGroupView && this.userIsAdmin
        })
      },
      NoActiveModule(){
        return !this.WebsiteisActive && !this.SocialNetworkisActive && !this.DatabaseisActive && !this.DocumentationisActive
      },
      WebsiteisActive(){
        return this.$store.state.WebsiteisActive
      },
      SocialNetworkisActive(){
        return this.$store.state.SocialNetworkisActive
      },
      DatabaseisActive(){
        return this.$store.state.DatabaseisActive
      },
      DocumentationisActive(){
        return this.$store.state.DocumentationisActive
      },
      DocumentationLibraryObj(){
            let doclibobj = this.DocumentationLibraries.find(obj => obj.id === this.$route.params.id)
            if(this.DocLibEditor && doclibobj){
                return doclibobj
            }
        },
        ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
      SiteDialog(){
            return this.$store.state.SiteDialog
        },
      GroupidRoutes(){
            return this.$store.state.GroupidRoutes
        },
        GroupslugRoutes(){
            return this.$store.state.GroupslugRoutes
        },
        GroupID(){
            if(this.GroupidRoutes.includes(this.$route.name)){
                return this.$route.params.id
            }
            else if(this.$route.query && this.$route.query.GroupID){
                return this.$route.query.GroupID
            }
            else if(this.GroupslugRoutes.includes(this.$route.name)){
                return this.$route.params.slug
            }
        },
      WebFormEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.EntityType && ent.EntityType.Name === 'Web Form'
        })
      },
      IsGroupView(){
        return this.$store.state.IsGroupView
    },
      BuilderView(){
      return this.$store.state.BuilderView
      },
      MarketplaceSiteBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Marketplace Site'
        },
       FeaturedMemberBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Featured Member'
        },
      DocLibBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
      },
      WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
      },
      DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
      },
      NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
      },
      SuiteBuilderView(){
        return !this.DocLibBuilderView && !this.WebsiteBuilderView && !this.DBBuilderView && !this.NetworkBuilderView && !this.FeaturedMemberBuilderView && !this.MarketplaceSiteBuilderView
      },
      BuilderName(){
        return this.SelectedBuilder ? this.SelectedBuilder.Name : ''
      },
      PageName(){
        return this.SelectedPage ? this.SelectedPage.Name : ''
      },
      ComputedSitePages(){
            if(this.IsGroupView){
                return this.ActiveGroup && this.ActiveGroup.Pages ? this.ActiveGroup.Pages : []
            }
            else{
                return this.SitePages.filter(page => {
                  if(this.WebsiteBuilderView){
                    return page.PublishType === 'Public'
                  }
                  else{
                    return page.PublishType === 'Members Only'
                  }
                })
            }
        },
      PageTabsPropName(){
            if(this.$route.meta && this.$route.meta.DataSingleBuilder || this.$route.meta && this.$route.meta.SubColEntryViewer){
                return 'SingleBuildTabs'
            }
            else if(this.$route.meta && this.$route.meta.ClassifiedListingBuilder){
                return 'ClassifiedTabs'
            }
            else if(this.$route.meta && this.$route.meta.WikiListingBuilder){
                return 'WikiTabs'
            }
            else{
                return 'PageTabs'
            }
        //return this.$route.meta && this.$route.meta.DataSingleBuilder || this.$route.meta && this.$route.meta.SubColEntryViewer? 'SingleBuildTabs' : 'PageTabs'
    },		
        PageTabs(){
            return this.EditedPage ? this.EditedPage[this.PageTabsPropName] : []
        },
      EditedPage(){
            return this.$store.state.EditedPage
        },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
        IMGProp(){
          return this.$store.state.IMGProp
        },
        EditedTab(){
          return this.$store.state.EditedTab
        },
        UseGroupAssets(){
            return this.$route.name === 'GroupPageBuilder' && this.ActiveGroup || this.$route.name === 'GroupPageEditor' && this.ActiveGroup
        },
        GalleryAssets(){
          return this.$store.state.GalleryAssets
        },
        GalleryLibraries(){
          return this.$store.state.GalleryLibraries
        },
        ImageCols(){
          if(this.$vuetify.breakpoint.xs){
            return 12
          }
          else if(this.$vuetify.breakpoint.sm){
            return 6
          }
          else if(this.$vuetify.breakpoint.md){
            return 4
          }
          else if(this.$vuetify.breakpoint.lg){
            return 3
          }
          else if(this.$vuetify.breakpoint.xl){
            return 2
          }
        },
        
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        RouteObj(){
          return this.$route
        },
        PluginsPath(){
        return this.$store.state.PluginsPath
      },
      UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
    },
    watch: {
  
      AppBuilderGuideElementid: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue && typeof newvalue !== 'undefined'){
                  console.log(newvalue)
                    this.HelperParentElement = document.getElementById(newvalue)
                    //console.log(this.HelperParentElement)
                    this.getOffset(this.HelperParentElement)
                }
            },deep: true
        },
      MiniView(value){
            if(value){
                this.TurnoffSiteDialog()
            }
            else{
                this.TurnonSiteDialog()
            }
        },
      RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.SelectBuilder()
                }
            },deep: true
        },
        EditedPage: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                   this.AssignPage()
                }
            },deep: true
        },
        BuilderView:{
         handler: function(oldvalue, newvalue) {
          if(oldvalue !== newvalue){
            this.SelectBuilder()
          }
         },deep: true
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['PurchasePlugin','ToggleNewAppDialog']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.SelectBuilder()
      if(this.MiniView){
          this.TurnoffSiteDialog()
      }	
      else{
          this.TurnonSiteDialog()
      }	
      window.onload = setTimeout(() => {
        if(this.AppBuilderGuideElementid){
         this.HelperParentElement = document.getElementById(this.AppBuilderGuideElementid)  
        }   
        //console.log(this.HelperParentElement)
      }, 300);
    },
    methods:{
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      ExtensionPluginPickCount(item){
        return this.NewApp.Plugins.filter(plug => {
          return plug.id === item.id
        }).length
      },
      TablePluginPickCount(plug){
        let tablecount = 0
        let installingtablecount = 0
        let appplug = this.NewApp.Plugins.find(obj => obj.id === plug.id)
        if(appplug){
          let marketplug = this.RAPlugins.find(obj => obj.id === plug.id)
          if(marketplug && marketplug.Installation_Tables){
            tablecount = marketplug.Installation_Tables.length
            installingtablecount = appplug.Installation_Tables.length
          }
        }
        return tablecount > 0 && installingtablecount> 0 ? installingtablecount/tablecount : 0
      },
      TogglePublicNavbarEditing(){
          this.$emit('TogglePublicNavbarEditing')
        },
        ToggleAppStylingView(){
          this.$emit('ToggleAppStylingView')
        },
        IntranetViewToggle(boolean1,boolean2){
        if(boolean1){
          this.$store.commit('setSiteDialog',false)
        }        
        this.$emit('IntranetViewToggle',boolean1,boolean2)
      },
      SelectPage(Page){
        let page = this.ComputedSitePages.find(obj => obj.id === Page.id)
        this.SelectedPage = page
        //console.log(Page,page)
        this.EditOtherPage(this.SelectedPage)
      },
      TESTPrepareNewApp(){
        console.log(this.NewApp,this.InstallingTables)
        //we could bypass here and basically
        if(!this.NewApp.Templateid){
          this.PrepareNewApp()
        }
        else{
          //okay,NOW got an opportunity to prepare Installation_Tables.
          //but let's ask ourselves if this is what we want.?
          //1. We can take the exact replicate with the exact naming convention and essentially give you NO CHANCE to change it. yes that's true.
          //2. OR we can figure it all out a whole different way, essentially uhm...yeah popping them in one by one to let dude choose table name
          //this may be the most important part yet I fail to figure it ou...
          //essentially we talking about step 6 here. You kind of for each table need to go to step 6. AND...
          //THEN you need to ENSURE that the AllFields and shit work as they should. I mean, you are NOT going to use EntitiesArray now...
          //so "jippo" way would be make "newappstep = 5" and this.PickPlugin(for each)
          //really all we got left to worry about then is ConfigRequiredImmediateTables
          
          //I mean tehcnically two potential issues here
          //1. Multiple plugins could exist that install tables, we can't possibly do only 1. 
          //BUT - if we do until immediate tables legnth = tables length..like somehow, know how many prepared etc...of course, right? yeah then
          //2. Not sure how much it would "hurt" now, given how the logic right now, configures "immediate tables" PER PLUGIN treating as "1:1", where technically NOW, we have 1 plugin with "multiple tbale" 
          //(if a plugin was used  more than once, e.g. contacts and accoutns used "standard data table")
          //console.log(tableplug)
          //ONE MORE CHECK NOW THEN..IF...WELL HAHAHA REALLY
          //1. SEE THE COMBINED AMOUNT OF TABLES VS LENGTH OF INSTALLATIONTABLES, AND IF PASS - WELL FUCK IT THEN GO AHEAD.
          // let totaltables = 
          let pluginstables = this.NewApp.Plugins.filter(plug => {
            return plug.Installation_Tables
          }).map(plug => {
            return plug.Installation_Tables
          }).flat()
          let unriggedtables = pluginstables.filter(table => {
            return !table.PluginEntityid
          })
          if(unriggedtables.length > 0){
            //TECHNICALLY SHOULD BE FOR EAHC BUT ANYWAY
            this.newappstep = 5
          let tableplug = Object.assign({},this.NewApp.Plugins.find(obj => obj.Installation_Tables))
          this.PickPlugin(tableplug,true)
          }
          else{
            //console.log('should finalize app now all tables rigged')
            this.PrepareNewApp()
          }
        }
      },
      PrepareNewApp(){
        this.$store.commit('setCustomProcessingDialogText','Preparing App')
        this.$store.commit('setCustomProcessingDialog',true)
        if(this.NewApp.Market_Template){
          let ref = db.collection('markettemplates').doc()
          this.NewApp.id = ref.id
        }
        else{
          this.NewApp.id = this.NewApp.Name.split(' ').join('_')
        }        
        console.log(this.NewApp,this.InstallingTables)
        if(this.InstallingTables.length > 0){
          this.$store.commit('setCustomProcessingDialogText','Installing Tables')
          this.InstallingTables.map(table => {
            table.Primary_Appid = this.NewApp.id
            //ESSENTIALLY THIS IS WHERE IT FALLS FLAT BOB, APP INSTALLS MULTIPLE PLUGINS, NOT TABLES. WHAT WOULD THE PLUGIN BE NOW?
            table.Plugins = []
          })
          let tableplugins = this.NewApp.Plugins.filter(plug => {
            return plug.Installation_Tables && plug.Installation_Tables.length > 0
          })
          let totaltables = this.InstallingTables.length
          let counter = 0
          tableplugins.map((plug,plugindex) => {
               plug.Installation_Tables.map(table => {
                let tablematch = this.InstallingTables.find(obj => obj.PluginEntityid === table.id && obj.PluginInstanceIndex === table.PluginInstanceIndex)
                if(tablematch){
                table.id = tablematch.id
                table.DisplayName = tablematch.DisplayName  
                table.Entity_Type = tablematch.Entity_Type
                }                
                counter = counter-1+2
                if(counter === totaltables){
                  this.StoreNewApp()
                }
               })
          })       
        }
        else{
          this.StoreNewApp()
        }
      },
      StoreNewApp(){    
        //1. minimize any present installation tables properties on NewApp.Plugins, to just id,DisplayName
        //2. determine installation tables, prepare for subcol prop removal, whilst subcol adding. precedde step 3 with complete and final adding of all tables
        //3. prepare plugins to basic minim props
        //4. add app, then add plugins
        //FINALLY Remaini_vouchers just stupid on this level, and "apps" prop was prior all this so removed it
        this.$store.commit('setCustomProcessingDialogText','Finalizing')
        let vm = this   
        console.log(this.NewApp,this.InstallingTables)
        let NewAppObject = Object.assign({},this.NewApp)
        NewAppObject.Active = true
        let Plugins = NewAppObject.Plugins.map(plug => {
          let plugobj = {
              id: plug.id,
              Name: plug.Name,
              Module_Category: plug.Module_Category,
              Active: true
            }
            if(plug.Installation_Tables){
              plugobj.Installation_Tables = plug.Installation_Tables.map(table => {
                let tableobj = {
                  id: table.id,
                  DisplayName: table.DisplayName,
                  Entity_Type: table.Entity_Type
                }
                return tableobj
              })
            }
            return plugobj
        })
        delete NewAppObject.Plugins
        console.log(NewAppObject,Plugins)
        let tables = JSON.parse(JSON.stringify(this.InstallingTables))
        let totaltables = tables.length
        if(totaltables === 0){
          this.FinalizeNewApp(NewAppObject,Plugins)
        }
        else{
          tables.map((preppedtable,tableindex)=> {
               let tableobj = Object.assign({},preppedtable)
               
                  this.InstallingTables[tableindex] = {id: tableobj.id,DisplayName: tableobj.DisplayName}
                  let subcols = ['AllFields','SubCollections','EntityProcesses','DigitizedForms','DocumentRegisters','Galleries','automations']
                  let finaltable = Object.assign({},tableobj)
                  delete finaltable.id
                  subcols.map(prop => {
                    delete finaltable[prop]
                  })
                  finaltable.Created_On = new Date()
                  finaltable.Modified_On = new Date()
                  finaltable.Created_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                  finaltable.Created_Byid = vm.userLoggedIn.id,
                  finaltable.Modified_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                  finaltable.Modified_Byid = vm.userLoggedIn.id
                  //console.log(tableobj.id,finaltable)
                  vm.AddTable(tableobj,finaltable).then(newdoc => {
                   if(finaltable.Entity_Type.Name === 'Classified Listing'){
                      let clsfdentity = {
                        SingleName: finaltable.SingleName,
                        PublishType: 'Public',
                        ClassifiedTabs: []
                      }
                      console.log(clsfdentity)
                      db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('classifiedentities').doc(finaltable.DisplayName.split(' ').join('_')).set(clsfdentity)
                    }
                    let subcolentries = subcols.map(subcol => {
                      return tableobj[subcol]
                    }).flat()
                    let totalsubcolentries = subcolentries.length
                    let entrycounter = 0
                    subcols.map((subcol,subcolindex) => {                      
                      tableobj[subcol].map(subcolentry => {
                        vm.AddTableSubCol(tableobj,subcol,subcolentry).then(newsubcoldoc => {
                        entrycounter = entrycounter-1+2
                          if(tableindex-1+2 === totaltables && entrycounter === totalsubcolentries){
                            this.FinalizeNewApp(NewAppObject,Plugins)
                          }
                        })
                      })
                    })
                  })
                
                })
        }
      },
      AddTableSubCol(tableobj,subcol,subcolentry){
        let vm = this
        let dbtableref = vm.NewApp.Market_Template ? db.collection('marketplacetemplates').doc(vm.NewApp.id).collection('entities') : 
        db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('entities')
        return new Promise(function(resolve, reject) {
        if(vm.$store.state.NeedsRASync){
          vm.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(ranewdoc => {
            dbtableref.doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(newsubcoldoc => {
              resolve(newsubcoldoc)
            })
          })          
        }
        else{
           dbtableref.doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(newsubcoldoc => {
            resolve(newsubcoldoc)
          })
        }
        })
      },
      AddTable(tableobj,finaltable){
        let vm = this
        let dbtableref = vm.NewApp.Market_Template ? db.collection('marketplacetemplates').doc(vm.NewApp.id).collection('entities') : 
        db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('entities')
        //if(this.NewApp.Market_Template){
        return new Promise(function(resolve, reject) {
        if(vm.$store.state.NeedsRASync && !vm.NewApp.Market_Template){
          vm.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).set(finaltable).then(ranewdoc => {
            dbtableref.doc(tableobj.id).set(finaltable).then(newdoc => {
              resolve(newdoc)
            })
          })          
        }
        else{
          dbtableref.doc(tableobj.id).set(finaltable).then(newdoc => {
            resolve(newdoc)
          })
        }
        })
      },
      FinalizeNewApp(NewAppObject,Plugins){
        if(this.NewApp.Market_Template){
          let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
          let date = new Date()
          let systemid = process.env.VUE_APP_RA_SYSTEM_ID
          let systemurl = this.System.ClientAppURL
          let defobj = {
              Templateid: '',
              BillingAccount: this.NewApp.BillingAccount,
              BillingAccountid: this.NewApp.BillingAccountid,
              Description: this.NewApp.Description,
              Systemid: systemid,
              ClientAppURL: systemurl,
              PublishStatus: 'Draft',
              Owner: user,          
              Ownerid: user.id,
              RouteName: this.$route.name,
              Created_By: user,          
              Created_Byid: user.id,
              Modified_By: user,          
              Modified_Byid: user.id,
              Created_On: date,
              Modified_On: date,
              Name: this.NewApp.Name,
              Icon: this.NewApp.Icon,
              TemplateProps: {},
              TemplateObj: {...NewAppObject},
              PluginDataBase: {},
              Category: this.$store.state.TemplateCategories.find(obj => obj.Name === 'Database App'),
          } 
          if(this.NewApp.As_Platform){
            defobj.As_Platform = true
          }
          if(this.NewApp.QuickBuildTemplate){
            defobj.QuickBuildTemplate = true
          }
          let temprops = ['Icon','Name']
          temprops.map(prp => {
            if(typeof this.NewApp[prp] !== 'undefined'){
              defobj.TemplateProps[prp] = this.NewApp[prp]
            }
          })
          Plugins.map(plug => {
            defobj.PluginDataBase[plug.id] = plug
          })
          NewAppObject = defobj
          //the ONLY thing holding us back is ability to as "as pl;atform"
        }
        let newappid = this.NewApp.id
        let pluginslength = Plugins.length
        let newpath = this.NewApp.Market_Template ? '/MarketplaceTemplate/'+this.NewApp.id : 
        '/App/'+this.NewApp.id
        let apppath = this.NewApp.Market_Template ? 
        db.collection('marketplacetemplates').doc(this.NewApp.id) : 
        this.AppsPath.doc(this.NewApp.id)
        let dbref = this.NewApp.Market_Template ? 
        db.collection('marketplacetemplates').doc(this.NewApp.id) : 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        apppath.set(NewAppObject).then(newappdoc => {
          if(pluginslength === 0){
            this.$router.push(newpath)
            this.ToggleNewAppDialog()
          }
          else{
            Plugins.map((plugin,plugindex) => {
              //console.log(this.NewApp.id,plugin.id)
              apppath.collection('plugins').doc(plugin.id).set(plugin).then(newplugdoc => {               
              if(plugin.id === 'Warehouse_&_Products'){
                let articleent = plugin.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Inventory Article')
                let storprodent = plugin.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Store Product')
                this.UpdateAppProp('Article_Master',articleent.id,newappid)
                this.UpdateAppProp('Warehouse_Product_Master',storprodent.id,newappid)
                 if(plugindex-1+2 === pluginslength){
                  this.$router.push(newpath)
                  this.ToggleNewAppDialog()
                }
              }
              else if(plugin.id === 'Online_Store'){
                let storordent = plugin.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Store Order')
                this.UpdateAppProp('Store_Order_Master',storordent.id,newappid)
                let SONumberAuto = {
                  id: storordent.id+'_SO_Number',
                  Digits: 6,
                  DisplayName: 'SO Number',
                  Last_Numbers: [],
                  Last_Number: 0,
                  Last_Value: '',
                  Name: 'SO_Number',
                  NumberingString: '0',
                  Type: 'AutoNumber Field',
                  Suffixes: [],
                  Prefixes: [
                    {Prefix_Value: 'SO-',Type: 'Standard'}
                  ]
                }
                dbref.collection('AutoNumbers').doc(SONumberAuto.id).set(SONumberAuto)
                let invartent = this.SystemEntities.find(obj => obj.id === this.NewApp.Article_Master)
                if(vm.RADB){
                  vm.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.NewApp.Article_Master).update({
                  Has_Store: true,
                  Cart_Entity: storordent.id,
                  NavList: [],
                  ListPrimaryField: invartent.AllFields.find(obj => obj.id === 'Product_Group')
                }).then(radocupdate => {
                  dbref.collection('entities').doc(this.NewApp.Article_Master).update({
                  Has_Store: true,
                  Cart_Entity: storordent.id,
                  NavList: [],
                  ListPrimaryField: invartent.AllFields.find(obj => obj.id === 'Product_Group')
                })
                })
                }
                else{
                 dbref.collection('entities').doc(this.NewApp.Article_Master).update({
                  Has_Store: true,
                  Cart_Entity: storordent.id,
                  NavList: [],
                  ListPrimaryField: invartent.AllFields.find(obj => obj.id === 'Product_Group')
                }) 
                }
                
                if(plugindex-1+2 === pluginslength){
                  this.$router.push(newpath)
                  this.ToggleNewAppDialog()
                }
              }
              else if(plugindex-1+2 === pluginslength){
                  this.$router.push(newpath)
                  this.ToggleNewAppDialog()
                }
              })
            })
          }
        })
      },
      UpdateAppProp(prop,value,appid){
        this.AppsPath.doc(appid).update({
          [prop]: value
        })
      },
      ConfirmRemovePlugin(plug){
        confirm('This will remove the plugin from the New App, are you sure?') && this.RemovePlugin(plug)
      },
      RemovePlugin(plug){
        let plugobj = this.NewApp.Plugins.find(obj => obj.id === plug.id)
        if(plugobj){
          let index = this.NewApp.Plugins.indexOf(plugobj)
          this.NewApp.Plugins.splice(index,1)
        }
        else{
          alert('The plugin is not instlaled on the App')
        }
      },
      PickPlugin(plug,tempalteinstallation){
        //console.log(plug,this.NewApp)
        if(plug.Strictly_One_Purchase && this.TablePluginPickCount(plug) > 0){
          alert('this Plugin can only be installed once')
        }
        else{
          let entcol = ''
        if(this.newappstep === 5){
          let plugincluded = true
          let plugobj = this.NewApp.Plugins.find(obj => obj.id === plug.id)
          if(!plugobj){
            plugobj = Object.assign({},plug)
            if(plug.Installation_Tables && plug.Installation_Tables.length > 0){
             plugobj.Installation_Tables = [] 
            }            
            this.NewApp.Plugins.push(Object.assign({},plug))
            plugincluded = false
          }
          if(tempalteinstallation){
            plugincluded = false
          }
          //plug.Disabled = true
          if(plugobj.Installation_Tables){
           // console.log(plugobj,plugobj.Installation_Tables)
            let tables = JSON.parse(JSON.stringify(plug.Installation_Tables))
            //console.log(plugincluded,tables,plugobj.Installation_Tables)
            this.$store.commit("setCustomProcessingDialogText","Processing")
            this.$store.commit('setCustomProcessingDialog',true)
            if(this.RADB && this.IsPrimarySite){
                entcol = this.RADB.collection('entities')
              }
              else if(this.NewApp.Templateid){
                entcol = db.collection('marketplacetemplates').doc(this.NewApp.Templateid).collection('entities')
              }
              else{
                entcol = db.collection('entities')
              }
              //essentially here, beware because Market_Template 
              this.PreparePluginEntities(entcol,tables).then(InstallingTables => {
              console.log(InstallingTables)
              this.ImmediateTables = []
            this.ImmediateTables = InstallingTables.map(table => {
              let appearances = plugobj.Installation_Tables.filter(plugtable => {
                return plugtable.PluginEntityid === table.PluginEntityid
              })
              table.PluginInstanceIndex = appearances.length
              return table
            })
            //console.log(this.ImmediateTables)
            plugobj = this.NewApp.Plugins.find(obj => obj.id === plug.id)
            //HONESLTY - WHY THIS???????????
            if(plugincluded){
              plugobj.Installation_Tables = plugobj.Installation_Tables.concat(InstallingTables)
            }
            else{
              plugobj.Installation_Tables = InstallingTables
            }  
            //console.log(plugobj.Installation_Tables)          
            //this.InstallingTables = plugobj.Installation_Tables
            this.InstallingTables = InstallingTables
            this.$store.commit('setCustomProcessingDialog',false)
            this.$store.commit("setCustomProcessingDialogText","")
            if(this.ConfigRequiredImmediateTables.length > 0){
              this.NextStep = 5
              this.newappstep = 6
            }  
            })          
          }          
        }
        else if(this.newappstep === 7){
          let plugobj = this.NewApp.Plugins.find(obj => obj.id === plug.id)
          if(!plugobj){
            plugobj = Object.assign({},plug)
            this.NewApp.Plugins.push(plugobj)
          }
          else{
            alert('this Plugin can only be installed once')
          }
          
        }
        // else if(this.newappstep === 5){
        //   this.NewApp.AppTemplate = {id: plug.id, Name: plug.Name}
        //   this.NewApp.AppTemplateid = plug.id
        //   this.AdvanceNewappDialog()
        //   //Plugins.push(plug)
        // }
        }
        
        
        //console.log(this.NewApp)
      },
      RAPluginsMatched(categorymatches){
         let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
            if(this.DataSingleBuilder){
                return item.Exclusively_as_Extension
                //item.Office_Exclusive && this.RAAdmin
            }
            else{
              return item                
            }
        }).filter(plug => {
          let newappplugins = this.NewApp.Plugins.map(plug => {
            return plug.id
          })
        // .map(plug => {
        //   //because we mutate and make it "disbaled selected"
        //   let plugobj = Object.assign({},plug)
        //   return plugobj
        // })
        
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id) && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
        }).filter(item => {
          return item.Office_Exclusive && this.RAAdmin || !item.Office_Exclusive
        })
      },
      ToggleNewAppDialog(newapptemplate){
        // this.NewApp
        this.NewAppDialog = !this.NewAppDialog
        if(!this.NewAppDialog){
          this.NewApp = Object.assign({},this.DefaultNewApp)
          this.NewApp.Plugins = []
          this.NextStep = 1
          this.InstallingTables = []
        }
        else {
          this.newappstep = this.NextStep
        }
        this.$store.commit('setCustomProcessingDialog',false)
        this.$store.commit('setCustomProcessingDialogText','')
        if(newapptemplate){
          if(newapptemplate.Market_Template){
            this.NewApp.Market_Template = true
            this.NewApp.BillingAccount = newapptemplate.BillingAccount
            this.NewApp.BillingAccountid = newapptemplate.BillingAccountid
            if(newapptemplate.As_Platform){
              this.NewApp.As_Platform = true
            }
          }
          else{
            //okay this is a template, but it is a tempalte that we are going to install, warts and all
            //warts and all would howvever mean I would say
            //InstallationTables needs a push of tables.
            //and then I guess a bypassing to installation.
            //possibly them "Templateid" being populated will cause us to consider it. 
            this.NewApp.Icon = newapptemplate.Icon
            this.NewApp.Templateid = newapptemplate.Templateid
            this.NewApp.Plugins = newapptemplate.Plugins.map(plug => {
              let plugobj = Object.assign({},this.RAPlugins.find(obj => obj.id === plug.id))
              plug.Price = plugobj.Price
              return plug
            })
            //okay so basically yeah
            //1. It should in fact be RAPlugins, in order to get price and everything going but whatever...
            //OKAY WE CAN GET VERY TECHINCAL HERE...but the fact is it all depends. 
            //You see, if you create a new app the theory is choose tempaltes or custom. 
            //while we have not yet worked on "template" which is "step 4" of new app dial,
            //here, by quickbuild, we actually leap to step 5. Just about.
            //but we give opportuity to change naming convention
          }  
          this.NewApp.Name = newapptemplate.Name
          this.NewApp.Description = newapptemplate.Description
          this.newappstep = 2
        }
        //console.log(this.NewApp,newapptemplate)
      },
      RegressNewappDialog(){
        if(this.newappstep === 7){
          this.newappstep = 5
        }
        else if(this.newappstep === 5){
          this.newappstep = this.NewApp.Templateid ? 2 : 3
        }
        else{
          this.newappstep--
        }
      },
      SkipNewappDialogStep(){
        //this.newappstep === 4 && this.NewApp.AppTemplateid || this.newappstep === 5 || this.newappstep === 7 && this.NewApp.Plugins && this.NewApp.Plugins.length > 0
        if(this.newappstep === 4 && this.NewApp.AppTemplateid){
          this.AdvanceNewappDialog()
        }
        else if(this.newappstep === 5){
          this.newappstep = 7
        }
      },
      AdvanceNewappDialog(){
        console.log(this.NewApp)
        if(this.newappstep !== 5 && this.newappstep !== 6 && this.newappstep !== 8){
          if(this.newappstep === 2 && this.NewApp.Templateid){
            this.newappstep = 5
          }
          else{
           this.newappstep++ 
          }          
        }
        // else if(this.newappstep === 1){
        //   this.newappstep = 4
        // }
        else if(this.newappstep === 5){
          this.newappstep = 7
          this.NextStep = 8
        }
        else if(this.newappstep === 6){
          let plugintables = this.NewApp.Plugins.filter(plug => {
            return plug.Installation_Tables
          })
          if(plugintables && plugintables.length > 0){
           this.FixTableNaming().then(result => {
            this.FixLookupReferences().then(result => {
              this.newappstep = this.NextStep
              if(this.NextStep === 5){
                this.NextStep = 7
              }
              else{
                this.NextStep++
              }
            })
          }) 
          }
          else{
             this.newappstep = this.NextStep
              this.NextStep++
          }
          
        }
        else if(this.newappstep === 8){
          this.FixTableNaming().then(result => {
            this.FixLookupReferences().then(result => {
              this.newappstep++
            })
          })
        }
        // else{
        //   this.newappstep++
        // }
      },
      getOffset(el) {
      const rect = el.getBoundingClientRect();
      let positionobj = {
        left: rect.left,
        // + window.scrollX,
        top: rect.top
        // + window.scrollY
      }
      this.HelpElmntLeft = positionobj.left
      this.HelpElmntTop = positionobj.top
      console.log(positionobj)
      //return positionobj
    },
      ProceedHelpder(){
        // this.$store.commit('setAppBuilderHelperActive',true)
        // this.$store.commit('setAppBuilderHelperStep',3)
        console.log('whether by side nav, plugins popup, of within the getting start page, here we need to showcase plugins that are "modules"')
      },
      AdvanceHelperMethod(tab){
        // if(this.AppBuilderHelperStep === 2 && this.NoActiveModule){
          let step = this.AppBuilderHelperStep-1+2
          this.HelpElmntLeft = ''
          this.HelpElmntTop = ''
          this.$store.commit('setAppBuilderHelperActive',false)
          setTimeout(() => {
            if(step === 2 || step === 3 && !this.NoActiveModule){
              this.$store.commit('setAppBuilderHelperActive',true)
              this.$store.commit('setAppBuilderHelperStep',step)
            }            
          }, 10); 
        // }
        
        
      },  
      ActionBtnMethod(tab){
        this[tab.ActionButtonMethod](tab)
      },
      FixTableNaming(){
        let vm = this
        return new Promise(function(resolve, reject) {
          vm.InstallingTables.map((table,tableindex)=> {
            let previousprimaryfieldid = table.RecordPrimaryField.Name
            let previousid = table.id
            if(previousprimaryfieldid !== table.Primary_Field_Name.split(' ').join('_')){
              console.log(previousprimaryfieldid,table.Primary_Field_Name.split(' ').join('_'))
              table.RecordPrimaryField.id = table.Primary_Field_Name.split(' ').join('_')
              table.RecordPrimaryField.Name = table.Primary_Field_Name.split(' ').join('_')
              table.RecordPrimaryField.DisplayName = table.Primary_Field_Name
              table.Primary_Field_Name = table.Primary_Field_Name.split(' ').join('_')
              table.id = table.DisplayName.split(' ').join('_')
              let fieldentry = table.AllFields.find(obj => obj.id === previousprimaryfieldid)
              fieldentry.id = table.Primary_Field_Name
              fieldentry.Name = table.Primary_Field_Name
              fieldentry.DisplayName = table.RecordPrimaryField.DisplayName
              table.SingleBuildTabs.map(tab => {
                if(tab.Elements){
                  tab.Elements.map(elmnt => {
                    if(elmnt.Fields){
                      let primfield = elmnt.Fields.find(obj => obj.id === previousprimaryfieldid)
                      if(primfield){
                       primfield.DisplayName = table.RecordPrimaryField.DisplayName
                       primfield.Name = table.Primary_Field_Name 
                      }                      
                    }
                  })
                }
              }) 
            }
            if(previousid !== table.DisplayName.split(' ').join('_')){
              table.id = table.DisplayName.split(' ').join('_')
            }            
             if(tableindex-1+2 === vm.InstallingTables.length){
              //console.log(Installation_Tables)
              resolve(vm.InstallingTables)
            }
          })
        })
      },
      FixLookupReferences(){
        let vm = this
        return new Promise(function(resolve, reject) {
          vm.InstallingTables.map((table,tableindex)=> {
            table.datasetsecuritystructure = table.Security_Structure.Name

            if(table.datasetsecuritystructure === 'Business Unit'){
              vm.BURolesDefault.map(role => {
                table[role.Name] = []
              })
              let bufield = {id: 'Business_Unit',Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
              let ownerfield = {id: 'Owner',Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
              let bufieldon = table.AllFields.find(obj => obj.id === bufield.id)
              if(!bufieldon){
                table.AllFields.push(bufield)
              }
              let ownerfieldon = table.AllFields.find(obj => obj.id === ownerfield.id)
              if(!ownerfieldon){
                table.AllFields.push(ownerfield)
              }
            }
            else if(table.datasetsecuritystructure === 'Custom Roles'){
              vm.CustomRolesDefault.map(role => {
                table[role.Name] = []
              })
            }
            let lookupfields = table.AllFields.filter(field => {
              return field.Type === 'Lookup'
            })
            lookupfields.map(lufield => {
              let entity = vm.InstallingTables.find(obj => obj.PluginEntityid === lufield.RelatedBuildID)
              if(entity && !entity.System_Required){
                //while there is a default template for each entity, if the entity is "System_Reqruied" the id, Name, and sec strucure will be readonly
                  //therefore no need to change the id either.
                  //we could have separated Icon. Sec Structure, Name, id, and primary field. but system_reuired truly means all of that
                lufield.LookupFieldName = entity.Primary_Field_Name.split(' ').join('_')
                lufield.RelatedLocation = entity.SingleName
                lufield.RelatedRecord = entity.DisplayName
                lufield.RelatedBuildID = entity.id 
              }              
            })
            let lusubcols = table.SubCollections.filter(subcol => {
              return subcol.LookupBuilds
            })
            lusubcols.map(subcol => {
              subcol.LookupBuilds.map(lubuild => {
                let entity = vm.InstallingTables.find(obj => obj.PluginEntityid === lubuild.id)
                if(entity && !entity.System_Required){
                  lubuild.single = entity.SingleName.split(' ').join('')
                  lubuild.tempname = entity.SingleName
                  lubuild.temppluralname = entity.DisplayName
                  lubuild.recordprimaryfield = entity.RecordPrimaryField
                  lubuild.identifier = entity.Primary_Field_Name
                  lubuild.id = entity.id
                  //actually Headers and LinkedFields as well...
                }                
              })
            })
            if(tableindex-1+2 === vm.InstallingTables.length){
              resolve(vm.InstallingTables)
            }
          })
        })
      },
      PreparePluginEntities(query,entities){
         //so what if the plugin installs BA, but we already have BA? 
         let vm = this
         let tables = []
         let totaltables = entities.length
          return new Promise(function(resolve, reject) {
            entities.map((table,tableindex)=> {
                vm.GetAllEntityProps(query,table).then(tableobj => {
                  tables.push(tableobj)
                  if(tableindex-1+2 === totaltables){
                    resolve(tables)
                  }
                })
            })
          })
      },
      GetAllEntityProps(configdb,table){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
          configdb.doc(table.id).get().then(tablensap => {
            let build = tablensap.data()
            build.id = table.id
            build.PluginEntityid = table.id
            vm.GetEntityAllFields(configdb,build).then(function(allfieldsresult) {
                if(allfieldsresult){
                  
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(configdb,build).then(function(subcolresult) {
                        // console.log(subcolresult)
                        
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(configdb,build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(configdb,build).then(function(formsresult) {
                            vm.GetDocumentRegisters(configdb,build).then(function(docregresult) {  
                                vm.GetGalleries(configdb,build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(configdb,build).then(function(automationsresult) {
                                    //console.log(build)
                                    resolve(build)
                                    
                                
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
          })        
        })
        },
    GetEntityAllFields(configdb,build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
                //console.log(configdb.doc(build.id).collection('AllFields'))
            configdb.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(configdb,build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(configdb,build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('EntityProcesses').where('Status','!=','InActive').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(configdb,build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(configdb,build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(configdb,build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(configdb,build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
      PurchasePlugin(pack){
          //wuold not have RaUserObj unless "option 3" right?
        let activeuser = this.RAUserObj ? this.RAUserObj : this.userLoggedIn
         let user = {id: activeuser.id,Full_Name: activeuser.Full_Name,Name: activeuser.Name,Surname: activeuser.Surname}
        //console.log(pack)
          let date = new Date()
        pack.Plugins.map(plug => {
             let plugobj = {
              id: plug.id,
              Name: plug.Name,
              Module_Category: plug.Module_Category,
              Active: true,
              Created_By: user,			
              Created_Byid: user.id,	
              Created_On: date, 
              Modified_By: user,
              Modified_Byid: user,
              Modified_On: date,
              Level: plug.Level
            }
            if(plug.Installation_Tables){
              if(this.RADB && this.IsPrimarySite){
                entcol = this.RADB.collection('entities')
              }
              else{
                entcol = db.collection('entities')
              }
              this.PreparePluginEntities(entcol,plug.Installation_Tables).then(InstallingTables => {
                console.log(InstallingTables)
                this.InstallingTables = InstallingTables
                this.NewAppDialog = true
                this.NewApp = plugobj
                this.$store.commit('SetAlertSound','success_sound')
                this.$store.commit('EmitMarketDialog',false)
                this.$store.commit('EmitMarketDialogObject','')
                this.$store.commit('EmitMarketDialogOptions',[])
                this.$store.commit('EmitMarketDialogType','')
                this.$store.commit('EmitMarketDialogDescription','')
                this.$store.commit('EmitMarketDialogTitle','')        
                this.$store.commit('EmitMarketDialogAvatar','')
                console.log(this.InstallingTables,plugobj)
              })              
            }
            else{
              this.PluginsPath.doc(plug.id).set(plugobj).then(obj => {
                this.$store.commit('SetAlertSound','success_sound')
                this.$store.commit('EmitMarketDialog',false)
                this.$store.commit('EmitMarketDialogObject','')
                this.$store.commit('EmitMarketDialogOptions',[])
                this.$store.commit('EmitMarketDialogType','')
                this.$store.commit('EmitMarketDialogDescription','')
                this.$store.commit('EmitMarketDialogTitle','')        
                this.$store.commit('EmitMarketDialogAvatar','')
                this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
                if(plugobj.id === 'Member_Groups'){
                  this.DefaultMemberGroup.Created_By = this.CurrentUserProps	
                  this.DefaultMemberGroup.Created_Byid = this.CurrentUserProps.id		
                  this.DefaultMemberGroup.Created_On = new Date()
                  this.DefaultMemberGroup.Modified_By = this.CurrentUserProps	
                  this.DefaultMemberGroup.Modified_Byid = this.CurrentUserProps.id	
                  this.DefaultMemberGroup.Modified_On = new Date()
                  this.DefaultMemberGroup.Status = this.DefaultStatusField.DefaultOption
                  this.DefaultMemberGroup.Status_Reason = this.DefaultStatusField.DefaultLevel2Option
                  db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('membergroups').doc(this.DefaultMemberGroup.Name.split(' ').join('_')).set(this.DefaultMemberGroup).then(admindoc => {
                    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.CurrentUserProps.id).update({
                      MemberGroup: {Name: this.DefaultMemberGroup.Name, id: this.DefaultMemberGroup.Name.split(' ').join('_')},
                      MemberGroupid: this.DefaultMemberGroup.Name.split(' ').join('_')
                    })
                  })
                }
                else if(plugobj.id === 'Featured_Members'){
                  //install catnavlist
                }
                else if(plugobj.id === 'Monetization' || plugobj.id === 'Marketplace_Templates'){
                  //Some various items require salesinvoices being installed
                  //IF we capture through App ChecktoCopyFinancialDefaults then no worry right? we instlal users sales invoices and even system tickets there so no worry
                }
                else if(plugobj.id === 'Easy_Products'){
                  //install catnavlist
                  let ent = this.$store.state.DefaultDirectories.find(obj => obj.id === 'Store_Products')
                  if(ent){
                   let field =  ent.AllFields.find(obj => obj.id === 'Product_Group')
                   db.collection('Stores').doc(this.$store.state.ActiveSuiteid).set({
                    CategoryField: field,
                    Published: true
                   })
                  }
                  
                }
                else if(plugobj.id === 'Advert_Directory'){
                  //install catnavlist
                  let ent = this.$store.state.DefaultDirectories.find(obj => obj.id === 'Store_Products')
                  if(ent){
                   let field =  ent.AllFields.find(obj => obj.id === 'Product_Group')
                   db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('Directories').doc('Adverts').set({
                    CategoryField: field,
                    Published: true,
                    PublishType: 'Public'
                   })
                  }
                  
                }
                
              })
            }
            //console.log(plugobj)
            
        })
      },
      ToggleSiteDialog(){
            this.$store.commit('setSiteDialog',!this.SiteDialog)
        },
        TurnoffSiteDialog(){
            this.$store.commit('setSiteDialog',false)
        },
        TurnonSiteDialog(){
            this.$store.commit('setSiteDialog',true)
        },
      ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode',!this.$vuetify.theme.dark)
    },
      SetColorstoSystemColors(System){
            this.$emit('SetColorstoSystemColors',System)
        },
      AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
      AssignPage(){
         this.SelectedPage = this.EditedPage
      },
      SelectBuilder(){        
        this.SelectedBuilder = this.BuilderView
      },
      CheckBuilderRoute(){        
        // if(this.IsGroupView && this.SelectedBuilder && this.SelectedBuilder.Name !== 'Website Builder'){
        //   this.$router.push('/Group/Edit/'+this.GroupID)
        // }
        //okay actually it is in here. I have to have default routes my thoughts being..if GroupView it's either gorup edit or..well group edit
        //now I could go and make it quite "simple" for myself here and push to systemconfig always but ahve you seen teh delay here? No I need to understand do I have public pages or social pages and if I do push to them, if not etc.
        let relevantroute = ''
        if(this.SelectedBuilder.Name === 'Website Builder' || this.SelectedBuilder.Name === 'Social Network Builder'){
          //alert('eyeyey')
          if(this.IsGroupView && this.SelectedBuilder.Name === 'Website Builder'){
            relevantroute = this.ComputedSitePages[0]
            if(relevantroute){
              this.$router.push('/Group-PageEditor/'+this.GroupID+'/'+relevantroute.id)
            }
            else{
              this.$router.push('/Group/Edit/'+this.GroupID)
            }
          }
          else if(this.IsGroupView){
            this.$router.push('/Group/Edit/'+this.GroupID)
          }
          else{
            if(this.SelectedBuilder.Name === 'Website Builder'){
              relevantroute = this.SitePages.find(obj => obj.PublishType === 'Public')
            }
            else{
              //at this stage the issue is, if we push to a community page we lose the social component toggle
              //because it's within system config that the tabs exist to set general social comp settings
              relevantroute = this.SitePages.find(obj => obj.PublishType === 'Members Only')
            }
            if(relevantroute){
              this.$router.push('/PageEditor/'+relevantroute.id)
            }
            else{
              this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID)
            }
          }
        }
        else if(this.SelectedBuilder.Name === 'Documentation Builder'){
          let doclib = this.DocumentationLibraries[0]
          let path = '/DocumentationLibraryEditor/'+doclib.id+'/'+doclib.FirstPageName.split(' ').join('-')
          if(this.$route.path !== path){
                this.$router.push(path)
            }
          //alert('yay')
        }
        else if(this.SelectedBuilder.Name === 'Suite Builder'){
          let path = '/Getting-Started'
          if(this.$route.path !== path){
                this.$router.push(path)
            }
          //alert('yay')
        }
        else if(this.SelectedBuilder.Name === 'App Builder'){
          console.log('yay')
        }
        
        else{
          //okay yeah think about it, if we toggle to dbview?
          if(this.IsGroupView){
            this.$router.push('/Group/Edit/'+this.GroupID)
          }
          else{
           // this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID)
           let path = '/Getting-Started'
          if(this.$route.path !== path){
                this.$router.push(path)
            }
          }
        }
        this.$store.commit('setBuilderView',this.SelectedBuilder)
      },
      EditOtherPage(SelectedPage){
        //console.log(SelectedPage)
       if(this.IsGroupView){
            this.$router.push('/Group-PageEditor/'+this.GroupID+'/'+SelectedPage.id)
        }
        else{
          //EditedPage vs SelectedPage for builder select
            this.$router.push('/PageEditor/'+SelectedPage.id)
            this.$store.commit('setEditedPage','')
            //better but still annoying. Should go to the very first in the array of pages, and if none, then systemconfig is the go by sorry but true
        }
      },
      EditOtherApp(SelectedApp){
        this.$router.push('/App/'+SelectedApp.id)
        //this.$store.commit('setActiveSuiteApp',SelectedApp)
      },
      
    }
}
</script>

<style>

</style>



