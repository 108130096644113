<template>
  <div>
  
      <v-row style="padding: 20px;" v-if="SystemBuilderTable">
         
    <SystemBuilderQuickViewComponent :SystemPhotoLibraries="SystemPhotoLibraries"
        :CurrentEntity="CurrentEntity" :Record="Record" :SystemObj="Record" :SystemEntities="SystemEntities" :System="System"
      />
      </v-row>
      <v-row style="padding: 20px;">
              <div v-for="sect in QuickEditSections" :key="sect.itemObjKey" :class="sect.Class">
                <v-card-subtitle v-if="sect.Header" class="mx-1 background subtleoverline justify-center">
                      {{sect.Header}}
                    </v-card-subtitle>
                    <v-list v-if="sect.Type === 'QuickList'">
                      <v-list-item :to="item.Path" class="detailslistoutline" v-for="item in sect.QuickLists" :key="item.itemObjKey">
                        {{item.Name}}
                      </v-list-item>
                    </v-list>
                <div v-if="sect.Type === 'Fields'" style="padding: 20px;">
                    
                  <span v-for="field in sect.Fields" :key="field.itemObjKey">
                    <span v-if="field.Type === 'Route Path'">
                        <v-select :items="RouteOptions(field)" item-text="path" :readonly="field.ReadOnly" :label="field[FieldDisplayProp]"  v-model="Record[field.Name]"/>
                    </span>
                    <!-- {{Record[field.Name]}} -->
                    <v-color-picker v-if="field.Type === 'Color Picker'" v-model="Record[field.Name]"></v-color-picker>
                    <span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'">
                        <v-list dense class="transparent" >
                            <!-- <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title> -->
                            <v-list-item style="padding: 0px;height:20px;" dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content>
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content >
                                <v-text-field class="smallinput" dense :rules="field.IsMandatory? [$store.state.formrules.required]: []" v-model="Record[breakdownfield.Name]" :placeholder="breakdownfield.DisplayName"/>
                                </v-list-item-content>                             
                            </v-list-item>
                        </v-list>
                        </span>
                      <span v-if="field.Type === 'Boolean'">
                    <v-switch :ripple="false" style="height: 20px;" dense class="smallinput machinetoggle" :readonly="field.ReadOnly"  :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Name]" :label="field.DisplayName"></v-switch></span>
                    <v-text-field :readonly="field.ReadOnly" v-if="field.Type === 'Single Line Text'" :label="field.DisplayName" :rules="field.IsMandatory ? [$store.state.formrules.required] : []" v-model="Record[field.Name]" dense/>
                    <v-text-field :readonly="field.ReadOnly" v-if="field.Type === 'Number Field' && !field.Slider" :label="field.DisplayName" :rules="field.IsMandatory ? [$store.state.formrules.required] : []" v-model.number="Record[field.Name]" dense/>
                    <v-slider v-if="field.Type === 'Number Field' && field.Slider"
                    :label="field.DisplayName+ '('+Record[field.Name]+')'"
                        v-model="Record[field.Name]"
                        hide-details
                        class="align-center"
                    />
                    <span v-if="field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation'">
                    <v-text-field filled readonly :rules="field.IsMandatory? [$store.state.formrules.numberfield,$store.state.formrules.required] : [$store.state.formrules.numberfield]" v-model.number="Record[field.Name]" type="number"  :label="field.DisplayName"></v-text-field></span>
                    <span v-if="field.Type === 'Option Set'"><span v-if="!field.IsDynamic">
                    <v-select class="smallinput" :return-object="!field.TextOnly" :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.required]: []" v-model="Record[field.Name]"  :items="field.Options" :label="field[FieldDisplayProp]" item-text="Name"></v-select><span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1]">
                    <v-select class="smallinput" v-if="Record[field.Name]" :readonly="field.ReadOnly" return-object :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Levels[1].FieldName]" :items="Record[field.Name].Options" :label="field.Levels[1].name" item-text="Name"></v-select></span><span v-if="field.Levels && field.Levels[1] && field.Levels[2] && Record[field.Levels[1].FieldName] && Record[field.Levels[1].FieldName].Options">
                    <v-select class="smallinput" v-if="Record[field.Levels[1].FieldName]" :readonly="field.ReadOnly" return-object :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Levels[2].FieldName]" :items="Record[field.Levels[1].FieldName].Options" :label="field.Levels[2].name" item-text="Name"></v-select></span></span><span v-if="field.IsDynamic">
                        <v-dialog             			
                            max-width="400px">							
                            <template v-slot:activator="{ on }">
                            <v-btn x-small v-on="on">Add Option</v-btn>
                            </template>
                            <v-card>
                            <v-card-title>
                                New Option for {{field.DisplayName}}
                                </v-card-title>
                                <v-card-text>
                                <v-text-field v-model="NewOptionName" label="Name"/>
                                <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                                    v-model="NewOption.Type" label="Type"/> -->
                                </v-card-text>
                                <v-card-actions>
                                <v-btn>Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn @click="AddOption(field,1)">Save</v-btn>
                                </v-card-actions>  
                            </v-card>
                        </v-dialog>
                        <v-list-group
                        v-for="item in field.Options"
                        :key="item.ID"
                        v-model="item.active"
                        no-action
                    >
                        <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.Name"></v-list-item-title>
                        </v-list-item-content>
                        </template>
                        <v-dialog             			
                            max-width="400px">							
                            <template v-slot:activator="{ on }">
                            <v-btn x-small v-on="on">Add Option</v-btn>
                            </template>
                            <v-card>
                            <v-card-title>
                                New Option for {{item.Name}}
                                </v-card-title>
                                <v-card-text>
                                <v-text-field v-model="NewOptionName" label="Name"/>
                                <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                                    v-model="NewOption.Type" label="Type"/> -->
                                </v-card-text>
                                <v-card-actions>
                                <v-btn>Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn @click="AddOption(field,2,item)">Save</v-btn>
                                </v-card-actions>  
                            </v-card>
                        </v-dialog>

                        <v-list-group
                        v-for="child in item.Options"
                        :key="child.ID"
                      sub-group
                        no-action
                    >
                        <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="child.Name"></v-list-item-title>
                        </v-list-item-content>
                        </template>
                      <v-list v-if="field.Levels.length > 2">
                        <v-dialog             			
                            max-width="400px">							
                            <template v-slot:activator="{ on }">
                            <v-btn x-small v-on="on">Add Option</v-btn>
                            </template>
                            <v-card>
                            <v-card-title>
                                 New Option for {{child.Name}}
                                </v-card-title>
                                <v-card-text>
                                <v-text-field v-model="NewOptionName" label="Name"/>
                                <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                                    v-model="NewOption.Type" label="Type"/> -->
                                </v-card-text>
                                <v-card-actions>
                                <v-btn>Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn @click="AddOption(field,3,child)">Save</v-btn>
                                </v-card-actions>  
                            </v-card>
                        </v-dialog>
                        
                        <v-list-item
                        v-for="subchild in child.Options"
                        :key="subchild.ID"
                        >
                        <v-list-item-content>
                            <v-list-item-title v-text="subchild.Name"></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        </v-list>
                    </v-list-group>
                    </v-list-group>
            <!-- <v-btn @click="ActivateOptionSetDialog(Record[field.Name],field)">Options</v-btn> -->
            </span></span><span v-if="field.Type === 'Multiple Lines Text' && field.TextOnly">
                    <v-textarea   :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Name]"  :label='field.DisplayName'></v-textarea></span>
                    <ContentEditableField style="padding:5px;padding-bottom:30px;padding-top:20px;" v-if="field.Type === 'Multiple Lines Text' && !field.TextOnly" :FieldObject="Record" :FieldName="field.Name"
                    :FieldValue="Record[field.Name]" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />  
                    <span v-if="field.Type ==='Single File Upload' && field.FeaturedPhoto"> 
                      {{field.id}} 
                      <v-list-item class="justify-center"> 
                        <input :id="field.id" accept="image/*"
                            @change="UploadFileSelect($event,field,Record)" style="visibility: hidden;display: none;"
                        type="file">   
                            <label :for="field.id"><span v-if="Record[field.Name] && Record[field.Name].UploadFile" class="texbtn btnhover elevation-1">
                          <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Change File
                          </span><span v-if="!Record[field.Name] || Record[field.Name] && !Record[field.Name].UploadFile" class="texbtn btnhover elevation-1">
                            <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Select File
                          </span>
                        </label>  
                        </v-list-item>
                        <v-list-item style="padding-bottom: 20px;" class="justify-center" v-if="Record[field.Name] && Record[field.Name].localURL">
                          <v-avatar
                                color="blue"
                                :size="MiniView ? 220 : 340" 
                                tile>
                              <img :src="Record[field.Name].localURL">
                            </v-avatar>
                        </v-list-item>
                        <v-list-item style="padding-bottom: 20px;" class="justify-center" v-if="Record[field.Name] && !Record[field.Name].localURL">
                          <v-avatar
                                color="black"
                                :size="MiniView ? 220 : 340" 
                                tile>
                              <v-img contain :src="Record[field.Name].fileurl"/>
                            </v-avatar>
                        </v-list-item>
                        <v-list-item class="justify-center" v-if="SystemBuilderTable && field.id === 'App_Icon' && AppIconAsset">
                          <v-btn @click="RenderFavIconResizeandStore(AppIconAsset)">Render Icons</v-btn>
                        </v-list-item>
                    </span>
                    <DateField :col="col" v-if="field.Type === 'Date'"
                    :TableQuick="TableQuick" @UpdateNewRecordQuickEdit="UpdateNewRecordQuickEdit"
                      :Record="Record" :CanEdit="true" :Editing="true"
                      :System="System" :SystemEntities="SystemEntities" :field="field"
                      />
                    <!-- <v-menu v-model="field.DateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="smallinput"
                        :label="field.DisplayName"
                        prepend-icon="mdi-calendar-month"
                        readonly  v-if="field.Type === 'Date'" :rules="field.IsMandatory ? [$store.state.formrules.required] : []"
                        v-on="on"
                        v-model="Record[field.Name]"
                        ></v-text-field>
                        </template>
                    <v-date-picker  v-model="Record[field.Name]"
                            @input="field.DateMenu = false"></v-date-picker>
                    </v-menu> -->
                    <span v-if="field.Type === 'Lookup' || field.Type === 'Social Lookup'">
                         <span v-if="field.RelatedBuildID !== 'Users'">
                    <v-autocomplete  class="smallinput" :readonly="field.ReadOnly || field.RelatedReadOnly || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue" :items="field.Array" :item-text="field.LookupFieldName" :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Name]" return-object :label='field.Name'>
                      <template v-slot:no-data>
                          <i class="mx-4">no data</i>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingLookup()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewLookupDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template>
                    </v-autocomplete>
                    </span><span v-if="field.RelatedBuildID === 'Users'">
                      <UserLookup :UsersLookupArray="field.Array"  :ModelProp="field.Name" :RecordObj="Record" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="field.DisplayName" :ReadOnly="field.ReadOnly || Record[field.Name] && !UserCanGetLookup" :Rules="field.IsMandatory? [$store.state.formrules.required] : []"
                      />
                    </span></span>
                  </span>
                  <span v-if="sect.Tooltip" class="text-caption grey--text text--darken-1" v-html="sect.Tooltip">
                  </span>
                </div>
                 <div v-if="sect.Type === 'Gallery'" style="padding: 20px;">
                   <v-row class="justify-center" v-if="sect.CanCreate">
                  <input
                  :id="Record.id+sect.GalleryName"
                    style="display: none"
                    type="file"
                    @change="AddGallerySubcolImages($event,Record,sect.GalleryName)"
                    ref="fileInputer"
                    :multiple="true"
                    accept="image/*"
                    >
                    <v-btn @click="getFile(Record.id+sect.GalleryName)" outlined  small class="elevation-6">
                      <v-icon>mdi-plus-thick</v-icon>Add Images</v-btn>
                   </v-row>
                    <AssetsDirectory :Assets="Record[sect.GalleryName]" :Cols="ImageCols" :HoverPreview="false" @SelectImage="SelectImage" :SelectName="sect.SelectAction"
                       />
                </div>
                <div v-if="sect.Type === 'SubCollection'" style="padding: 20px;">
                    <component :is="sect.SubCollectionComponent" :UsersLookupArray="sect.UsersLookupArray" :UserHeaders="sect.UserHeaders" :Record="Record"
                    :AdditionalHeaders="sect.AdditionalHeaders" :SelectMethod="'OpenUserSubcolDialog'" @OpenUserSubcolDialog="OpenUserSubcolDialog"
                    />
                </div>
                
              </div>
            </v-row>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	
import UserLookup from '@/components/General/UserLookup'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import SystemBuilderQuickViewComponent from '@/components/RAExclusive/SystemBuilderQuickViewComponent';
import DateField from '@/components/Database/Fields/Hybrid/FieldGroups/DateField'
export default {
    props: ['System','SystemEntities','Record','QuickEditSections','TableQuick',
    'SystemBuilderTable','CurrentEntity','SystemPhotoLibraries'],
    components: {
      DateField,
        AssetsDirectory,
        UserLookup,
        ContentEditableField,
        SystemBuilderQuickViewComponent
    },
    data() {
        return {
            NewOptionName: '',
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
          DefaultIcons: [
        {
          Name: 'android-chrome-192x192.png',
          SQRSize: 192,
        },
        {
          Name: 'android-chrome-512x512.png',
          SQRSize: 512,
        },
        {
          Name: 'android-chrome-maskable-192x192.png',
          SQRSize: 192,
        },
        {
          Name: 'android-chrome-maskable-512x512.png',
          SQRSize: 512,
        },
        {
          Name: 'apple-touch-icon.png',
          SQRSize: 180,
        },
        {
          Name: 'apple-touch-icon-60x60.png',
          SQRSize: 60,
        },
        {
          Name: 'apple-touch-icon-76x76.png',
          SQRSize: 76,
        },
        {
          Name: 'apple-touch-icon-120x120.png',
          SQRSize: 120,
        },
        {
          Name: 'apple-touch-icon-152x152.png',
          SQRSize: 152,
        },
        {
          Name: 'apple-touch-icon-180x180.png',
          SQRSize: 180,
        },
        {
          Name: 'favicon-16x16.png',
          SQRSize: 16,
        },
        {
          Name: 'favicon-32x32.png',
          SQRSize: 32,
        },
        {
          Name: 'msapplication-icon-144x144.png',
          SQRSize: 144,
        },
        {
          Name: 'mstile-150x150.png',
          SQRSize: 150,
        },
        // {
        //   Name: 'safari-pinned-tab.svg',
        //   Nosize: true,
        // },

      ],
      FavIcons: [],
        }
    },	
    computed:{
      AppIconAsset(){
       return this.Record.App_Icon        
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 12
        }
        else if(this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.xl){
          return 2
        }
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      UpdateNewRecordQuickEdit(payload,field){
        //console.log('UpdateNewRecordQuickEdit payload,field',payload,field)
        this.$emit('UpdateNewRecordQuickEdit',payload,field)
      },
    RenderFavIconResizeandStore(asset){  
      this.FavIcons = this.DefaultIcons.map(icondef => {
        let iconobj = {
          FullName: icondef.Name,
          Name: icondef.Name,
          Path: asset.fileurl,
          SQRSize: icondef.SQRSize 
        }
        return iconobj
      })
      this.FavIcons.map((icon,i) => {
        this.PrepareIconReSize(icon,i,this.FavIcons.length)
      })
    },
    PrepareIconReSize(asset,index,listlength){
             var img = document.createElement("IMG");
             var a = document.createElement("a");
             document.body.appendChild(a);
              a.style = "display: none";
            img.setAttribute('crossOrigin','anonymous')
            img.setAttribute('src',asset.Path)
            img.setAttribute('width',asset.SQRSize)
            document.body.appendChild(img)
            img.onload = function() {
            var c = document.createElement("canvas");
            c.setAttribute('crossOrigin','anonymous')
            var ctx = c.getContext("2d");
            c.width = img.width
            c.height = img.width
            ctx.drawImage(img, 0, 0, c.width, c.height);
            c.toBlob(blob => {
            let url = window.URL.createObjectURL(blob);
             document.body.removeChild(img)
            a.href = url;
            a.download = asset.FullName;
            a.click();
            window.URL.revokeObjectURL(url);
              })
          }             
      },
      AddOption(field,lvl,parent){
            field.lastassignedinteger = field.lastassignedinteger-1+2
            let newoption = {
                Name: this.NewOptionName,
                ID: field.lastassignedinteger
            }
            let level = field.Levels[lvl-1]
            newoption.Level = level
            newoption.LevelFieldName = level.FieldName
            if(lvl < field.LastLevel){
                newoption.Options = []
                newoption.HasDeepHierarchy
            }
            if(lvl === 1){
               field.Options.push(newoption) 
            }
            else if(lvl !== 1){
                parent.Options.push(newoption)
            }
            this.NewOptionName = ''
            //thus the below shows only issue is knowing which level we wokr from, easy
            //HasDeepHierarchy is lvl not exceeding
            //Level (fullobj)
            //LevelFieldName (txt)
            //Options (IFHasDeepHierarchy)
            //console.log(field)
            this.Record[field.Name] = field
            
        },
      RouteOptions(field){
            let routes = this.$router.getRoutes()
            return routes.filter(route => {
                if(field.PathFilter){
                    if(field.PathFilter === 'None'){
                    return this.NoPathFilterRoutePass(route)
                    }
                    else if(field.PathFilter === 'Non Guest Routes'){
                    return route.name === 'MyDashboard' || route.name === 'MyProfile' || route.name === 'Home' || route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Guest Routes'){
                      //we had this.System.Guests_can_Social && route.meta && route.meta.RouteEventID === 1027 but then typically "store" page not allowed
                    return route.name === 'MyAccount' || route.meta && route.meta.RouteEventID === 1027 || this.System.Guests_can_Social && route.meta &&  route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Public Pages'){
                        return route.meta && route.meta.RouteEventID === 1028
                    }
                    else if(field.PathFilter === 'Internal Pages'){
                      return route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                }                
                else{
                    return route
                }
            })
        },
      getFile(id){
        let fileUpload = document.getElementById(id)
        if (fileUpload != null) {
          fileUpload.click()
        }
      },
        SelectImage(tab, tapprop, asset,IsThis,SelectName){
          this.$emit('SelectImage',tab, tapprop, asset, IsThis,SelectName)
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            this.$emit('UpdateContentEditableField',prop,value,AdditionalSaveMethod,FieldObject)
        },
        UploadFileSelect(event,field,Record){
            this.$emit('UploadFileSelect',event,field,Record)
        },
        AddGallerySubcolImages(event,item,subcolid){ 
          this.$emit('AddGallerySubcolImages',event,item,subcolid)
      },
    }
}
</script>

<style>

</style>



