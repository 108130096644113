<template>
  <v-card flat tile class="transparent">
    <v-card-subtitle class="background darken-1 mediumoverline">
      {{ Name }}
    </v-card-subtitle>
    <v-card-text v-if="EditedTab.Name === 'Table Embed'">
         <v-list-item>
                                    <!-- decided that Photo Library is never to be embedded on table, if you want it in a page you want a gallery or dynamic gallery 
                                    <v-select :items="['Photo Library','System Routes','SubCollection']" item-text="DisplayName" v-model="EditedTab.EntitySource">
                                 </v-select>
                                 In fact I think this may be true for "SubCollection" and also even for System Routes. It does not make sense to have any of it here, but leave until we get there
                                 May want prefiltered data on a page
                                 -->
                                 <v-select :items="['System Routes','SubCollection']" item-text="DisplayName" v-model="EditedTab.EntitySource">
                                 </v-select>
                                </v-list-item>
                                <v-list-item>
                                 <v-select :items="TableEmbedTables" item-text="id" v-model="EditedTab.Entityid">
                                 </v-select>
                                </v-list-item>
                                <v-list-item>
                                    <v-textarea ></v-textarea>
                                </v-list-item> 
                                <v-list-item v-if="EditedTab.EntitySource && EditedTab.EntitySource !== 'Photo Library'">
                                    <v-autocomplete  v-model="EditedTab.ContentFilter" :items="ContentFields" item-text="DisplayName" return-object label="Primary Filter"></v-autocomplete>
                                </v-list-item>   
                                <v-list-item v-if="EditedTab.ContentFilter && EditedTab.ContentFilter.Type === 'Option Set'">
                                    <v-autocomplete multiple v-model="EditedTab.ContentFilterValue" :items="EditedTab.ContentFilter.Options" item-text="Name" return-object label="Filter Value"></v-autocomplete>
                                </v-list-item>
    </v-card-text>
    <v-card-text>
      <v-list-item v-if="EditedTab.Name === 'Card Link'">
        <v-select
          return-object
          :items="CardLinksRender"
          :rules="[$store.state.formrules.required]"
          item-text="Name"
          v-model="EditedTab.DataContent"
          label="Link To"
        >
        </v-select>
      </v-list-item>
      <v-list-item v-if="EditedTab.Name === 'Card Link'">
        <v-textarea
          v-model="EditedTab.ContentDescription"
          :rules="[$store.state.formrules.required]"
          label="Link Description"
        ></v-textarea>
      </v-list-item>
      <v-list-item v-if="EditedTab.Name === 'RSS Feed'">
        <v-text-field v-model="EditedTab.RSSURL" label="RSS URL" />
      </v-list-item>
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'Store Product Single'">
      <v-switch label="Show Price" v-model="EditedTab.ShowsPrice" />
      <v-text-field v-model="EditedTab.Price" />
      <v-switch label="Has BG IMG" v-model="EditedTab.HasBGIMG" />
      <v-switch label="BG IMG is IMG" v-model="EditedTab.BGIMGisIMG" />
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="200px"
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-palette-advanced</v-icon>
        </template>
        <v-color-picker v-model="EditedTab.SubHeaderColor"></v-color-picker>
      </v-menu>

      <v-select
        v-model="EditedTab.SubHeaderFontFamily"
        label="Font"
        :items="FontOptions"
      />
      <v-select
        v-model="EditedTab.SubHeaderFontSize"
        :items="[12, 14, 18, 24, 36, 42, 50, 60, 75, 100]"
      />
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'Counter Single'">
      <v-text-field label="Title" v-model="EditedTab.Title" />
      <v-text-field v-model="EditedTab.CounterInteger" type="number" />
      <v-list-item>
        <v-text-field v-model="EditedTab.CounterIcon" label="Icon" />
        <a href="https://cdn.materialdesignicons.com/4.5.95/" target="_blank"
          >Get Icons</a
        >
      </v-list-item>
      
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'Survey Form'">
      <v-select
        clearable
        @change="
          Twopropemit(
            'AddSurveyEntityFields',
            EditedTab.RelatedEntity,
            EditedTab
          )
        "
        v-model="EditedTab.RelatedEntity"
        label="Entity"
        :items="SurveyFormEntities"
        item-text="id"
      />
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'Web Form'">
      <v-switch v-model="EditedTab.MappedUserFieldStayHidden" label="Hide User Info Fields" />
      <v-text-field
        v-model="EditedTab.FormName"
        :rules="[$store.state.formrules.required]"
        label="Form Name"
      />
      <v-switch v-model="EditedTab.DarkForm" label="Dark Form" />
       <v-menu 								
              :close-on-content-click="false"									
              :nudge-right="40"									
              transition="scale-transition"									
              offset-y									
              min-width="200px"									
              >									
              <template v-slot:activator="{ on }">									
          Text<v-icon v-on="on">mdi-palette-advanced</v-icon>									
              </template>									
          <v-color-picker v-model="EditedTab.ActionBTNTextColor"></v-color-picker>									
          </v-menu>
          <v-menu     									
                                    :close-on-content-click="false"									
                                    :nudge-right="40"									
                                    transition="scale-transition"									
                                    offset-y									
                                    min-width="200px"									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                Btn<v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                    </template>									
                                <v-color-picker v-model="EditedTab.ButonColor"></v-color-picker>									
                                </v-menu>
      <v-text-field
        v-model="EditedTab.FormEmailRecipient"
        :rules="[$store.state.formrules.email]"
        label="Recipient Email"
      />
      <v-switch v-model="EditedTab.EntityForm" label="Entity Form" />      
      <v-select
        clearable
        v-if="EditedTab.EntityForm"
        @change="
          Twopropemit('AddEntityFields', EditedTab.RelatedEntity, EditedTab)
        "
        v-model="EditedTab.RelatedEntity"
        label="Entity"
        :items="WebFormEntities"
        item-text="id"
      />
      <v-btn @click="NoPropsemit('FormFieldsDialogActivate')"> Fields </v-btn>
      <v-select
        label="Button Alignment"
        v-model="EditedTab.ButtonAlignment"
        :items="['justify-start', 'justify-center', 'justify-end']"
      />
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'FAQ'">
      <v-select
        dense
        multiple
        v-model="EditedTab.SelectedFAQ"
        :items="FrequentlyAskedQuestionsSearched"
        label="FAQ"
        return-object
        item-text="Question"
      >
        <template v-slot:prepend-item>
          <v-list-item>
            <v-list-item-content> Filter or </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                dense
                class="mx-3"
                v-model="FAQSearch"
                label="Search"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-chip-group
            v-model="FAQFilterItem"
            v-if="
              FrequentlyAskedQuestionsKeys &&
              FrequentlyAskedQuestionsKeys.length > 0
            "
            active-class="primary--text"
            column
          >
            <v-chip
              small
              v-for="kw in FrequentlyAskedQuestionsKeys"
              :key="kw.itemObjKey"
            >
              {{ kw }}
            </v-chip>
          </v-chip-group>
          <v-divider></v-divider>
        </template>
      </v-select>
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'Info Card'">
      <v-switch v-model="EditedTab.CardsShaped" label="Cards Shaped" />
      <v-switch v-model="EditedTab.CardsOutlined" label="Cards Outlined" />
      <v-switch v-model="EditedTab.CardsFlat" label="Cards Flat" />
      <v-switch v-model="EditedTab.CardsTile" label="Cards Tile" />
      <v-slider
        v-model="EditedTab.CardsElevation"
        min="0"
        max="24"
        label="Cards Elevation"
      ></v-slider>

      <!-- <v-list-item> -->
      <v-switch v-model="EditedTab.HeaderShaped" label="Header Shaped" />
      <v-switch v-model="EditedTab.HeaderOutlined" label="Header Outlined" />
      <v-switch v-model="EditedTab.HeaderFlat" label="Header Flat" />
      <v-switch v-model="EditedTab.HeaderTile" label="Header Tile" />
      <v-slider
        v-model="EditedTab.HeaderElevation"
        min="0"
        max="24"
        label="Header Elevation"
      ></v-slider>

      <!-- </v-list-item> -->
      <v-list-item>
        Header BG
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
          </template>
          <v-color-picker v-model="EditedTab.BGColor"></v-color-picker>
        </v-menu>
      </v-list-item>

      <v-list-item>
        Cards Color
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
          </template>
          <v-color-picker v-model="EditedTab.CardsColor"></v-color-picker>
        </v-menu>
      </v-list-item>
      <v-list-item>
        Chips Outline
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
          </template>
          <v-color-picker v-model="EditedTab.ChipsOutline"></v-color-picker>
        </v-menu>
      </v-list-item>
    </v-card-text>
    <v-card-text v-if="EditedTab.Name === 'Info Sheet'">
      <v-select
        v-model="EditedTab.BorderPosition"
        label="Border Position"
        :items="['left', , 'right', 'top', 'bottom']"
      />
      <v-switch v-model="EditedTab.ColoredBorder" label="Colored Border" />
      <v-switch v-model="EditedTab.Dense" label="Dense" />
      <v-switch v-model="EditedTab.Dismissable" label="Dismissable" />
      <v-switch v-model="EditedTab.Tiled" label="Tiled" />
      <v-switch v-model="EditedTab.Prominent" label="Prominent" />
      <v-switch v-model="EditedTab.Dark" label="Dark" />
      <!-- Dense Dismissable Tiled -->
      <v-autocomplete
        :items="AdvancedComputedLibraryColors"
        label="Color"
        v-model="EditedTab.Color"
      >
        <template v-slot:item="{ item }">
          <span :class="item"
            >{{ item }}
            <p style="font-size: 12px">
              The Quick brown fox jumps over the lazy dog
            </p>
          </span>
        </template>
      </v-autocomplete>
      <v-slider
        v-model="EditedTab.Elevation"
        min="0"
        max="24"
        label="Elevation"
      ></v-slider>
      <v-dialog max-width="1000px" min-height="600">
        <template v-slot:activator="{ on }">
          <v-text-field v-model="EditedTab.AlertIcon" v-on="on" label="Icon" />
        </template>
        <v-card min-height="600">
          <iframe
            width="100%"
            height="600"
            src="https://pictogrammers.github.io/@mdi/font/5.9.55/"
            title="Material Design Icons"
          ></iframe>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-text-field
      v-if="EditedTab.Name === 'Google My Maps'"
      v-model="EditedTab.FrameURL"
      label="URL"
    />
    <v-textarea
      v-if="EditedTab.Name === 'IFRAME'"
      v-model="EditedTab.FrameURL"
      label="URL"
    />
    <v-list-item v-if="EditedTab.Name === 'IFRAME'">
      <v-select
        :items="['start', 'center', 'end']"
        v-model="EditedTab.ContentAlign"
        label="Vertical Align"
      />
    </v-list-item>
    <v-list v-if="EditedTab.Name === 'Timeline Item'">
      <v-list-item>
        <v-text-field v-model="EditedTab.Title" label="Title" />
      </v-list-item>
      <v-list-item>
        <v-textarea v-model="EditedTab.Description" label="Description" />
      </v-list-item>
      <v-list-item>
        <v-text-field v-model="EditedTab.icon" label="Icon" />
        <a href="https://cdn.materialdesignicons.com/4.5.95/" target="_blank"
          >Get Icons</a
        >
      </v-list-item>
      <!-- <v-list-item>									
                                   <v-switch v-model="EditedTab.Dark" label="Dark"/>									
                              </v-list-item> -->
      <v-list-item>
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
          </template>
          <v-color-picker v-model="EditedTab.color"></v-color-picker>
        </v-menu>
      </v-list-item>
    </v-list>
     
  </v-card>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";
import PhotoLibraryViewer from "@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer";
export default {
 props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets','FrequentlyAskedQuestions',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
  components: { PhotoLibraryViewer },
  data() {
    return {
      WrappingOptions: [
        {
          DisplayName: "Top Left",
          FlexValue: "align-self:flex-start",
          LayoutValue: "fill-height justify-start",
          JustifyIcon: "mdi-format-align-left",
        },
        {
          DisplayName: "Top Center",
          FlexValue: "align-self:flex-start",
          LayoutValue: "fill-height justify-center",
          JustifyIcon: "mdi-format-align-center",
        },
        {
          DisplayName: "Top Right",
          FlexValue: "align-self:flex-start",
          LayoutValue: "fill-height justify-end",
          JustifyIcon: "mdi-format-align-right",
        },
        {
          DisplayName: "Center Left",
          FlexValue: "align-self:center",
          LayoutValue: "fill-height justify-start",
          JustifyIcon: "mdi-format-align-left",
        },
        {
          DisplayName: "Absolute Center",
          FlexValue: "align-self:center",
          LayoutValue: "fill-height justify-center",
          JustifyIcon: "mdi-format-align-center",
        },
        {
          DisplayName: "Center Right",
          FlexValue: "align-self:center",
          LayoutValue: "fill-height justify-end",
          JustifyIcon: "mdi-format-align-right",
        },
        {
          DisplayName: "Bottom Left",
          FlexValue: "align-self:flex-end",
          LayoutValue: "fill-height justify-start",
          JustifyIcon: "mdi-format-align-left",
        },
        {
          DisplayName: "Bottom Center",
          FlexValue: "align-self:flex-end",
          LayoutValue: "fill-height justify-center",
          JustifyIcon: "mdi-format-align-center",
        },
        {
          DisplayName: "Bottom Right",
          FlexValue: "align-self:flex-end",
          LayoutValue: "fill-height justify-end",
          JustifyIcon: "mdi-format-align-right",
        },
      ],
      FAQFilterItem: -1,
      FAQSearch: '',
      rules: {
        telnr: (value) => {
          const pattern =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
          return pattern.test(value) || "Not a telephone number.";
        },
        min8Chars: (value) => value.length >= 8 || "Min. 8 characters",
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const urlpattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          );
          return urlpattern.test(value) || "Invalid Link.";
        },
      },
    };
  },
  computed: {
    FrequentlyAskedQuestionsKeys(){
            let keywords = []
            this.FrequentlyAskedQuestions.map(faq => {
                if(faq.KeyWords){
                    faq.KeyWords.map(keyword => {
                        let oncheck = keywords.find(obj => obj === keyword)
                        if(!oncheck){
                            keywords.push(keyword)
                        }
                    })
                }
            })
            return keywords
        },
    FrequentlyAskedQuestionsSearched(){
            return this.FrequentlyAskedQuestionsFiltered.filter(faq => {
                if(this.FAQSearch){
                    return faq.Question.toLowerCase().includes(this.FAQSearch.toLowerCase())
                }
                else{
                    return faq
                }
            })
        },
        FrequentlyAskedQuestionsFiltered(){
            return this.FrequentlyAskedQuestions.filter(faq => {
                if(this.FAQFilterItem > -1){
                    return faq.KeyWords && faq.KeyWords.includes(this.FrequentlyAskedQuestionsKeys[this.FAQFilterItem])
                }
                else{
                    return faq
                }
            })
        },
    CardLinksRender() {
      let arr1 = this.SystemEntities.map((build) => {
        let obj = {
          Name: build.DisplayName,
          ContentLinkName: build.DisplayName,
          ContentLinkType: "Build",
        };
        if (build.Table_Icon) {
          obj.dataseticon = build.Table_Icon;
          obj.ContentIcon = build.Table_Icon;
        } else {
          obj.dataseticon = "mdi-database";
          obj.ContentIcon = "mdi-database";
        }
        return obj;
      });
      let arr2 = this.SitePages.map((build) => {
        let obj = {
          Name: build.Name,
          ContentLinkName: build.Name,
          ContentLinkType: "Page",
        };
        if (build.Icon) {
          obj.dataseticon = build.Icon;
          obj.ContentIcon = build.Icon;
        } else {
          obj.dataseticon = "mdi-eye";
          obj.ContentIcon = "mdi-eye";
        }
        return obj;
      });
      return arr1.concat(arr2);
    },
  },
  created() {},
  methods: {
    AssignGalleryAssets(EditedTab, asset) {
      this.Twopropemit("AssignGalleryAssets", EditedTab, asset);
    },
    TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
    WriteupUpdate(WriteupUpdateMethod) {
      this.NoPropsemit(WriteupUpdateMethod);
    },
    AssignImage(tab, prop, asset, IsThis) {
      this.$emit("AssignImage", tab, prop, asset, IsThis);
    },
    NoPropsemit(MethodName) {
      this.$emit("NoPropsemit", MethodName);
    },
    TabPropEmit(MethodName) {
      this.$emit("TabPropEmit", MethodName);
    },
    Onepropemit(MethodName, prop) {
      console.log(MethodName, prop);
      this.$emit("Onepropemit", MethodName, prop);
    },
    Twopropemit(MethodName, firstprop, secondprop) {
      this.$emit("Twopropemit", MethodName, firstprop, secondprop);
      setTimeout(() => {
        this.TypeChange = "";
      }, 50);
    },
    Threepropemit(MethodName, firstprop, secondprop, thirdprop) {
      this.$emit("Threepropemit", MethodName, firstprop, secondprop, thirdprop);
    },
  },
};
</script>

<style>
</style>
