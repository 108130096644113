<template>
  <div>
    <v-btn @click="ActivateViewportBuilder(item)" v-if="$route.name === 'DirectoryBuilder' && !ViewPortBuilder" icon><v-icon>mdi-cogs</v-icon></v-btn>
    <v-card @click="ActivateSiteListing(item)" style="border-radius: 25px;" min-height="380">
    <!-- item {{item}} -->
    <v-card-text>
        <v-img :class="item.Logo_BG_Color ? 'sitelogobg' : 'background darken-3'"
        :style="item.Logo_BG_Color ? '--bgcol: '+item.Logo_BG_Color.hexa+';margin-top: 15px;' : 
        'margin-top: 15px;'" height="150" contain :src="item.Logo && item.Logo.ThumbURL ? item.Logo.ThumbURL : item.Logo"
        >
        
        </v-img><v-btn v-if="item.SiteType === 'Website'" depressed 
        style="position:absolute;top:20px;left:0px;" tile color="blue" dark>
           <v-icon dark>mdi-web
           </v-icon> Website</v-btn>
        <v-btn v-if="item.SiteType === 'Profile'" depressed 
        style="position:absolute;top:20px;left:0px;" tile color="purple" dark>
           <v-icon dark>mdi-briefcase-variant-outline
           </v-icon> Profile</v-btn>
           <v-btn v-if="item.SiteType === 'Web App'" depressed 
        style="position:absolute;top:20px;left:0px;" tile color="red" dark>
           <v-icon dark>mdi-database
           </v-icon> Web App</v-btn>
            <v-btn v-if="item.SiteType === 'Network'" depressed 
        style="position:absolute;top:20px;left:0px;" tile color="green" dark>
           <v-icon dark>mdi-account-group
           </v-icon> Network</v-btn>
        <v-card-title style="padding-bottom:10px;font-size: 1.2em;" class="mediumoverline">
            {{item.Company_Name}}
        </v-card-title>
        <v-card-title style="padding-top:0px;" class="subtleoverline">
            <!-- {{AvgRatings}}
             <v-rating small dense
                :value="AvgRatings" class="justify-center"
                background-color="amber lighten-1"
                color="amber lighten-1"
                readonly half-increments
                ></v-rating> -->
        </v-card-title>
        <v-card-subtitle style="padding: 0px;padding-left: 10px;">
            {{item.Description}}
        </v-card-subtitle>
        <v-card-subtitle style="padding: 0px;padding-left: 10px;">
            <a :href="'tel:'+item.SiteTelephoneNumber" target="_top">{{item.SiteTelephoneNumber}}</a>
        </v-card-subtitle>
        <v-card-subtitle class="caption" style="padding: 0px;padding-left: 10px;">
           {{AddressText}}
        </v-card-subtitle>
        <v-card-subtitle class="caption" style="padding: 0px;padding-left: 10px;">
           {{BusinessHoursText}}
        </v-card-subtitle>
    </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RatingComponent from "@/components/WebPages/RenderComponents/RatingComponent";
export default {
    props: ['System','SystemEntities','item'],
    components: {RatingComponent},
    data() {
        return {
            AvgRatings: 3.5,
            Company_Address: {Name: 'Company_Address',FieldBreakdown: [
                {Name: 'Company_Address_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'},
                {Name: 'Company_Address_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'},
                {Name: 'Company_Address_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'},
                {Name: 'Company_Address_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'},
                {Name: 'Company_Address_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'},
                {Name: 'Company_Address_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'},
                {Name: 'Company_Address_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
            ],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
        }
    },	
    computed:{
        BusinessHoursText(){
            let string = '('
            this.BusinessHours.map((arritem,i) => {
                if(i === 0){
                    string = string+arritem.Label+' '+arritem.Value
                }
                else{
                  string = string+' || '+arritem.Label+' '+arritem.Value  
                }                
            })
            string = string+')'
            return string === '()' ? '' : string
        },
        BusinessHours(){
            let array = [
                {Label: 'Mon-Fri:',Value: '',Prop: 'WeekDays'},
                {Label: 'Sat-Sun:',Value: '',Prop: 'WeekEnds'},
                {Label: 'Public Holidays:',Value: '',Prop: 'PublicHolidays'}
            ]
            return array
            .filter(arritem => {
                return this.item.Business_Hours && this.item.Business_Hours[arritem.Prop]
                 && this.item.Business_Hours[arritem.Prop].Start && this.item.Business_Hours[arritem.Prop].End
            }).map(arritem => {
                arritem.Value = this.item.Business_Hours[arritem.Prop].Start+' to '+this.item.Business_Hours[arritem.Prop].End
                return arritem
            })
        },
        AddressText(){
            let string = ''
            let breakdown = this.Company_Address.FieldBreakdown.slice(0,3)
            breakdown.map((brk,i) => {
                if(this.item[brk.Name]){
                string = string+this.item[brk.Name]
                if(this.item[brk.Name] && i<breakdown.length-1){
                    string=string+','
                }
                }
            })
            return string
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    },
    methods:{
        ActivateViewportBuilder(item){
            this.$emit('ActivateViewportBuilder',item)
        },
        ActivateSiteListing(item){
            let itemobj = Object.assign({},item)
            itemobj.Company_Address = this.AddressText
            itemobj.Telephone_Number = `<a href="tel:`+item.SiteTelephoneNumber+`" target="_top">`+item.SiteTelephoneNumber+`</a>`
            //console.log(itemobj)
            this.$emit('ActivateSiteListing',itemobj)
        },
        
    }
}
</script>

<style>
.sitelogobg{
    background-color: var(--bgcol)
}
</style>



