<template>
  <div>
         <v-dialog max-width="1000" v-model="OpenFormComponent">
    <DigitizedFormComponent v-if="OpenFormComponent && ActiveForm" @SubmitForm="SubmitForm" :ActiveForm="ActiveForm" :comment="ActiveComment" :userLoggedIn="userLoggedIn"/>
  </v-dialog>
      <v-dialog v-model="FiletoRecordDialog" max-width="400">
       
        <v-card max-width="400">
            <v-card-title class="recordtoolbar white-- text largeoverline">
                Save to Record 
            </v-card-title>
            <v-card-text class="background">
                <v-list class="transparent">
                    <v-list-item>
                        <v-select v-model="DocumentTable" :items="DocumentsAndGalleries" item-text="DisplayName" return-object label="Document Table">
                        </v-select>
                    </v-list-item>
                    <v-list-item>
                        <v-select v-if="DocumentTable && !DocumentTable.IsGallery" return-object v-model="DocumentType" item-text="Name" :items="DocumentTable.PrimaryOptions" label="Document Type">
                        </v-select>
                    </v-list-item>
                    <!-- and if additional fields apply?DocumentTable.DocRegAdditionalFields map -->
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn small dark color="warning" @click="CancelSaveFiletoRecord()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn small dark color="success"  @click="SaveFiletoRecord(ActiveComment)">Save</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
      <v-dialog v-if="!FullonCreate" max-width="500" v-model="TimelineDialog" persistent>
        <v-card
            :class="CommentsOnly ? '' : 'mx-auto'"
            height="100%"
        >
        <v-card-title  class="recordtoolbar white--text"><v-icon v-if="CanEdit" @click="ToggleFollowRecordConfirm(TimelineRecord)" color="red">{{UserisTimelineRecordFollower? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
          Liaison <v-spacer></v-spacer><v-icon @click="RefreshTimelineDialog()" class="soloactionicon" color="green">mdi-refresh</v-icon> 
            <v-icon @click="CloseTimelineRecordDialog()" class="soloactionicon" color="red">mdi-close</v-icon>
                      
        </v-card-title>
        <v-card-subtitle style="padding-top: 20px;" class="recordtoolbar white--text" v-if="!CommentsOnly">
           
                    <v-overflow-btn
                    class="my-2"
                    :disabled="ActiveSession.title === 'Suite Templates' && $store.state.UserisGuest && userLoggedIn.id !== TimelineRecord.Ownerid"
                    :items="ComputedLiaisonChannels"
                    menu-props="top"
                    item-text="Name"
                    label="Channel"
                    return-object
                    target="#dropdown-example-1"
                     v-model="SelectedChannel"
                    ></v-overflow-btn>
         
        </v-card-subtitle>
        <v-card-subtitle v-if="!$route.meta && !CommentsOnly || $route.meta && !$route.meta.DataSingleBuilder && !CommentsOnly" style="padding-top: 15px;" class="recordtoolbar white--text">
             <v-btn color="blue" target="_blank" :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+TimelineRecord.id">
                View Record
            </v-btn>
          
        </v-card-subtitle>
        <v-card-text v-if="!TimelineRecord.HasClientRecord && ActiveSession.ClientLookupField && !TimelineRecord[ActiveSession.ClientLookupField.Name] && !CommentsOnly">
            There is no {{ActiveSession.ClientLookupField.Name}} linked to the record
            <v-btn :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+TimelineRecord.id">See Record</v-btn>
        </v-card-text>
        <v-card-text v-if="!TimelineRecord.HasClientRecord && SelectedChannel.LiaisonProp === 'clientliaison'">
            <v-dialog  max-width="500" v-model="StartLiaisonDialog">
                <template v-slot:activator="{ on }">
                    <v-btn @click="StartLiaisonDialog = true" v-on="on">Start Liaison</v-btn>
                </template>
                <v-card v-if="StartLiaisonDialog" max-width="500">
                    <v-card-title class="recordtoolbar white--text largeoverline">
                        Start Liaison
                    </v-card-title>
                    <v-card-text class="background" style="padding-top: 10px;">
                        <p class="caption red--text">
                            This will open a Communication channel with <span class="blue--text">{{TimelineRecord[ActiveSession.ClientLookupField.Name][ActiveSession.ClientLookupField.LookupFieldName]}}</span>, whom will be a participant and recipient to all Communication put on it.
                        </p>
                        <div v-if="ActiveSession.title === 'Suite Templates'">
                           Personalize your message to {{TimelineRecord[ActiveSession.ClientLookupField.Name][ActiveSession.ClientLookupField.LookupFieldName]}}
                            <ContentEditableField style="padding:15px;"
                            :FieldObject="EmailObject" :FieldName="'Message_to_Client'"
                            :FieldValue="EmailObject.Client_Message" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="StartLiaisonDialog = false" color="warning" small dark>
                            Cancel
                        </v-btn>
                        <v-spacer>
                        </v-spacer>
                        <v-btn @click="CreateClientRecord(TimelineRecord)" color="success" small dark>
                            Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
        <v-card-text :style="CommentsOnly ? 'padding: 0px !important;' : ''" class="background" v-if="SelectedChannel.LiaisonProp === 'clientliaison' && TimelineRecord.HasClientRecord || SelectedChannel.LiaisonProp !== 'clientliaison'">
            <div v-if="LoadingMessages">
                <v-skeleton-loader 
                v-bind="attrs"
                type="list-item-avatar-three-line"
                ></v-skeleton-loader>
                 <v-skeleton-loader 
                v-bind="attrs"
                type="list-item-avatar-three-line"
                ></v-skeleton-loader>
                 <v-skeleton-loader 
                v-bind="attrs"
                type="list-item-avatar-three-line"
                ></v-skeleton-loader>
            </div>
            <v-timeline  dense clipped v-if="!LoadingMessages">
                
                <v-timeline-item v-if="CanEdit"
                    fill-dot
                    class="white--text mb-12"
                    color="orange"
                    large
                >
                    <template v-slot:icon>
                    <span>{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}</span>
                    </template>
                        
                    <v-text-field
                    v-model="input"
                    hide-details
                    flat
                    label="Leave a comment..."
                    solo
                    @keydown.enter="comment(TimelineRecord,null,LiaisonUploadFile)"
                    >
                    <template v-slot:append>
                    <v-menu
                        
                        top
                        offset-y
                        :close-on-content-click="false"
                        >
                        <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-link</v-icon>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-text-field v-model="NewLink" label="link" :rules="[$store.state.formrules.url]"/>
                            </v-list-item>
                            <v-list-item-action>
                                <v-btn @click="link(NewLink,TimelineRecord)">Save</v-btn>
                            </v-list-item-action>
                        </v-list>
                        </v-menu>

                        <input v-if="!LiaisonUploadFile"
                            style="display:none"
                            type="file"
                            @change="onFileselected($event)"
                            id="fileInputer">
                        <v-icon
                        class="mx-0"
                        depressed
                        @click="FileInput('fileInputer')"
                        >
                        mdi-paperclip
                        </v-icon>
                            <v-menu
                        
                        top
                        offset-y
                        v-if="ActiveSession.DigitizedForms.length >0"
                        >
                        <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        class="mx-0"
                        depressed
                        
                        >
                        mdi-dots-vertical-circle-outline
                        </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="comment(TimelineRecord,form)" v-for="form in ActiveSession.DigitizedForms" :key="form.itemObjKey">
                                {{form.Name}}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        class="mx-0"
                        small
                        @click="comment(TimelineRecord,null,LiaisonUploadFile)"
                        >
                        Post
                        </v-btn>
                    </template>
                    </v-text-field>
                    <!-- ActiveSession {{ActiveSession}} -->
                    
                </v-timeline-item>
                <!-- ByLatestLiaison
                this.TimelineRecord[this.SelectedChannel.Array]
                {{TimelineRecord[SelectedChannel.Array]}} -->
                <v-timeline-item
                    class="mb-4"
                    color="grey"
                    icon-color="grey lighten-2"
                    small
                    :hide-dot="comment.hidedot"
                    v-for="comment in ByLatestLiaison()" :key="comment.itemObjKey">
                    <template v-slot:icon>
                        <v-avatar>
                        <img v-if="comment.ProfPic" :src="comment.ProfPic" style="object-fit: cover;" >
                       <v-chip v-if="!comment.ProfPic"
                        class="white--text ml-0"
                        :color="comment.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
                        label
                        small
                        >
                        {{comment.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{comment.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-chip>
                        </v-avatar>
                    </template>
                    <span class="caption">
                    <span v-if="comment.Created_On.toDate">
                            {{comment.Created_On.toDate()}}
                        </span>
                         <span v-if="!comment.Created_On.toDate && comment.Created_On.seconds">
                             {{new Date(comment.Created_On.seconds*1000)}}
                        </span></span>
                    <v-card class="elevation-2">
                        <v-card-text v-html="comment.description">                            
                        </v-card-text>
                       <v-card-text v-if="comment.ThumbURL">
                           <v-avatar tile size="150">
                        <v-img contain
                            :src="comment.ThumbURL"
                                />
                        </v-avatar>                            
                        </v-card-text>
                        <v-card-actions  v-if="comment.fileurl">
                            <v-btn small v-if="!comment.TokenURL"
                            download
                            @click="GetShortLivedURL(comment)"
                            class="blue--text mx-0"
                            >
                            Get Token <v-icon small>mdi-security</v-icon>
                            </v-btn><v-spacer></v-spacer>
                            <v-btn small v-if="comment.TokenURL"
                            :href="comment.TokenURL" target="_blank"
                            class="orange--text mx-0"
                            >
                            View <v-icon>mdi-download</v-icon>
                            </v-btn>
                                <v-btn small v-if="ActiveSession.DocumentRegisters && ActiveSession.DocumentRegisters.length>0 || ActiveSession.Galleries && ActiveSession.Galleries.length>0"
                            @click="OpenSaveFiletoRecord(comment)"
                            class="success--text mx-0"
                            :disabled="comment.Filed"
                            >
                            {{comment.Filed ? 'Filed' : 'Save' }}<v-icon color="success">mdi-content-save-move-outline</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions  v-if="comment.FormObj">
                             <v-btn
                                @click="OpenForm(comment.FormObj,comment)"
                                class="blue--text mx-0"
                                >
                                Open Form
                                </v-btn>
                        </v-card-actions>
                    </v-card>                        
                </v-timeline-item>
                    <v-timeline-item>
                    <v-btn @click="slice = slice+4">More</v-btn>
                    <v-btn v-if="slice>4" @click="slice = slice-4">Less</v-btn>
                </v-timeline-item>
            </v-timeline>
        </v-card-text>
        </v-card>
    </v-dialog>
    <div v-if="FullonCreate && TimelineDialog">
        <v-card
            :class="CommentsOnly ? '' : 'mx-auto'"
            height="100%"
        >
        <v-card-title  class="recordtoolbar white--text"><v-icon v-if="CanEdit" @click="ToggleFollowRecordConfirm(TimelineRecord)" color="red">{{UserisTimelineRecordFollower? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
            Liaison <v-spacer></v-spacer><v-icon @click="RefreshTimelineDialog()" class="soloactionicon" color="green">mdi-refresh</v-icon> 
            <v-icon @click="CloseTimelineRecordDialog()" class="soloactionicon" color="red">mdi-close</v-icon>
                      
        </v-card-title>
        <v-card-subtitle style="padding-top: 20px;" class="recordtoolbar white--text" v-if="!CommentsOnly">
           
                    <v-overflow-btn
                    class="my-2"
                    :disabled="ActiveSession.title === 'Suite Templates' && UserisGuest && userLoggedIn.id !== TimelineRecord.Ownerid"
                    :items="ComputedLiaisonChannels"
                    menu-props="top"
                    item-text="Name"
                    label="Channel"
                    return-object
                    target="#dropdown-example-1"
                     v-model="SelectedChannel"
                    ></v-overflow-btn>
         
        </v-card-subtitle>
        <v-card-subtitle v-if="!$route.meta && !CommentsOnly || $route.meta && !$route.meta.DataSingleBuilder && !CommentsOnly" style="padding-top: 15px;" class="recordtoolbar white--text">
             <v-btn color="blue" target="_blank" :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+TimelineRecord.id">
                View Record
            </v-btn>
              <v-btn @click="GoBack()" v-if="BackView">Back</v-btn>
        </v-card-subtitle>
        <v-card-text v-if="!TimelineRecord.HasClientRecord && ActiveSession.ClientLookupField && !TimelineRecord[ActiveSession.ClientLookupField.Name] && !CommentsOnly">
            There is no {{ActiveSession.ClientLookupField.Name}} linked to the record
            <v-btn :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+TimelineRecord.id">See Record</v-btn>
        </v-card-text>
        <v-card-text v-if="!TimelineRecord.HasClientRecord && SelectedChannel.LiaisonProp === 'clientliaison'"
        >
            <v-dialog  max-width="500">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on">Start Liaison</v-btn>
                </template>
                <v-card  max-width="500">
                    <v-card-title class="recordtoolbar white--text largeoverline">
                        Start Liaison
                    </v-card-title>
                    <v-card-text class="background" style="padding-top: 10px;">
                        <p class="caption red--text">
                            This will open a Communication channel with <span class="blue--text">{{TimelineRecord[ActiveSession.ClientLookupField.Name][ActiveSession.ClientLookupField.LookupFieldName]}}</span>, whom will be a participant and recipient to all Communication put on it.
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="warning" small dark>
                            Cancel
                        </v-btn>
                        <v-spacer>
                        </v-spacer>
                        <v-btn @click="CreateClientRecord(TimelineRecord)" color="success" small dark>
                            Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
        <v-card-text :style="CommentsOnly ? 'padding: 0px !important;' : ''"
        class="background" v-if="SelectedChannel.LiaisonProp === 'clientliaison' && TimelineRecord.HasClientRecord || SelectedChannel.LiaisonProp !== 'clientliaison'">
            <div v-if="LoadingMessages">
                <v-skeleton-loader 
                v-bind="attrs"
                type="list-item-avatar-three-line"
                ></v-skeleton-loader>
                 <v-skeleton-loader 
                v-bind="attrs"
                type="list-item-avatar-three-line"
                ></v-skeleton-loader>
                 <v-skeleton-loader 
                v-bind="attrs"
                type="list-item-avatar-three-line"
                ></v-skeleton-loader>
            </div>
            <v-timeline  dense clipped v-if="!LoadingMessages">
                 <v-timeline-item v-if="CanEdit">
                     <UserLookup style="padding-top: 70px;" :UsersLookupArray="UsersArray" :ModelProp="'TaggedUsers'"
                     :RecordObj="this" @UpdateUserLookupProp="UpdateFollowers" :IDSOnly="true"
                      :FieldLabel="'Tagged Users'" :ReadOnly="false" :IsMultiple="true"
                      :Rules="[]"
                      />
                </v-timeline-item>
                <v-timeline-item v-if="CanEdit"
                    fill-dot
                    class="white--text mb-12"
                    color="orange"
                    large
                >
                    <template v-slot:icon>
                    <span>{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}</span>
                    </template>
                        
                    <v-text-field
                    v-model="input"
                    hide-details
                    flat
                    label="Leave a comment..."
                    solo
                    @keydown="CheckMention($event)"
                    @keydown.enter="comment(TimelineRecord,null,LiaisonUploadFile)"
                    >
                    <template v-slot:append>
                    <v-menu
                        
                        top
                        offset-y
                        :close-on-content-click="false"
                        >
                        <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-link</v-icon>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-text-field v-model="NewLink" label="link" :rules="[$store.state.formrules.url]"/>
                            </v-list-item>
                            <v-list-item-action>
                                <v-btn @click="link(NewLink,TimelineRecord)">Save</v-btn>
                            </v-list-item-action>
                        </v-list>
                        </v-menu>

                        <input v-if="!LiaisonUploadFile"
                            style="display:none"
                            type="file"
                            @change="onFileselected($event)"
                            id="fileInputer">
                        <v-icon
                        class="mx-0"
                        depressed
                        @click="FileInput('fileInputer')"
                        >
                        mdi-paperclip
                        </v-icon>
                            <v-menu
                        
                        top
                        offset-y
                        v-if="ActiveSession.DigitizedForms.length >0"
                        >
                        <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        class="mx-0"
                        depressed
                        
                        >
                        mdi-dots-vertical-circle-outline
                        </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="comment(TimelineRecord,form)" v-for="form in ActiveSession.DigitizedForms" :key="form.itemObjKey">
                                {{form.Name}}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        class="mx-0"
                        small
                        @click="comment(TimelineRecord,null,LiaisonUploadFile)"
                        >
                        Post
                        </v-btn>
                    </template>
                    <!-- <v-menu v-model="Mentioning" offset-y>
                      
                        <UserLookup style="padding-top: 70px;" :UsersLookupArray="UsersArray" :ModelProp="'ActivityOwnerFilter'" :RecordObj="this" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="'Owner'" :ReadOnly="false"
                      :Rules="[]"
                      />
                        </v-menu> -->
                    </v-text-field>
                    <!-- <UserLookup style="padding-top: 70px;" :UsersLookupArray="UsersArray" :ModelProp="'ActivityOwnerFilter'" :RecordObj="this" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="'Owner'" :ReadOnly="false" v-if="Mentioning"
                      :Rules="[]"
                      /> -->
                    <!-- ActiveSession {{ActiveSession}} -->
                   
                </v-timeline-item>
               
                <v-timeline-item
                    class="mb-4"
                    color="grey"
                    icon-color="grey lighten-2"
                    small
                    :hide-dot="comment.hidedot"
                    v-for="comment in ByLatestLiaison()" :key="comment.itemObjKey">
                    <template v-slot:icon>
                        <v-avatar>
                        <img v-if="comment.ProfPic" :src="comment.ProfPic" style="object-fit: cover;" >
                       <v-chip v-if="!comment.ProfPic"
                        class="white--text ml-0"
                        :color="comment.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
                        label
                        small
                        >
                        {{comment.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{comment.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-chip>
                        </v-avatar>
                    </template>
                    <span class="caption">
                    <span v-if="comment.Created_On.toDate">
                            {{comment.Created_On.toDate()}}
                        </span>
                         <span v-if="!comment.Created_On.toDate && comment.Created_On.seconds">
                             {{new Date(comment.Created_On.seconds*1000)}}
                        </span></span>
                    <v-card class="elevation-2">
                        <v-card-text v-html="comment.description">                            
                        </v-card-text>
                       <v-card-text v-if="comment.ThumbURL">
                           <v-avatar tile size="150">
                        <v-img contain
                            :src="comment.ThumbURL"
                                />
                        </v-avatar>                            
                        </v-card-text>
                        <v-card-actions  v-if="comment.fileurl">
                            <v-btn small v-if="!comment.TokenURL"
                            download
                            @click="GetShortLivedURL(comment)"
                            class="blue--text mx-0"
                            >
                            Get Token <v-icon small>mdi-security</v-icon>
                            </v-btn><v-spacer></v-spacer>
                            <v-btn small v-if="comment.TokenURL"
                            :href="comment.TokenURL" target="_blank"
                            class="orange--text mx-0"
                            >
                            View <v-icon>mdi-download</v-icon>
                            </v-btn>
                                <v-btn small v-if="ActiveSession.DocumentRegisters && ActiveSession.DocumentRegisters.length>0 || ActiveSession.Galleries && ActiveSession.Galleries.length>0"
                            @click="OpenSaveFiletoRecord(comment)"
                            class="success--text mx-0"
                            :disabled="comment.Filed"
                            >
                            {{comment.Filed ? 'Filed' : 'Save' }}<v-icon color="success">mdi-content-save-move-outline</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions  v-if="comment.FormObj">
                             <v-btn
                                @click="OpenForm(comment.FormObj,comment)"
                                class="blue--text mx-0"
                                >
                                Open Form
                                </v-btn>
                        </v-card-actions>
                    </v-card>                        
                </v-timeline-item>
                    <v-timeline-item>
                    <v-btn @click="slice = slice+4">More</v-btn>
                    <v-btn v-if="slice>4" @click="slice = slice-4">Less</v-btn>
                </v-timeline-item>
            </v-timeline>
        </v-card-text>
        </v-card>
          </div>
      <v-badge color="warning" class="mx-1" v-if="TimelineRecord.NotificationCount && TimelineRecord.NotificationCount>0 && !CommentsOnly" overlap>
        <span slot="badge"> {{TimelineRecord.NotificationCount}}</span>
        <v-btn color="blue" @click="ReadNotices(TimelineRecord),OpenLiaisonDialog(TimelineRecord)" :id="TimelineRecord.id+'responses'"><v-icon color="white">mdi-comment-edit</v-icon></v-btn>
        </v-badge>
            <v-btn color="blue" @click="OpenLiaisonDialog(TimelineRecord)" v-if="!TimelineRecord.NotificationCount && !CommentsOnly" :id="TimelineRecord.id+'responses'"><v-icon color="white">mdi-comment-edit</v-icon></v-btn>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DigitizedFormComponent from '@/components/Client/DigitizedFormComponent'
import UserLookup from '@/components/General/UserLookup'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'	
export default {
    props: ['System','SystemEntities','RecordData','ActiveSession','CanEdit','ActiveRecord','OperationalDB','LiaisonDialogActive','FullonCreate','CommentsOnly','BackView'],
    components: {DigitizedFormComponent,UserLookup,ContentEditableField},
    data() {
        return {
            EmailObject: {},
            TaggedUsers: [],
            Mentioning: false,
            LoadingMessages: true,
            LiaisonItems: [],
            rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
            Galleries: [],
            DocumentRegisters: [],
            TimelineRecord: '',
            input: null,
            TimelineDialog: false,
            Tabs: 1,
            NewLink: '',
            slice: 4,
            FiletoRecordDialog: false,
            DocumentTable: '',
            DocumentType: '',            
            UploadingFileProgress: 0,
            LiaisonUploadFile: '',
            OpenFormComponent: false,
            ActiveForm: '',
            ActiveComment: '',
            LiaisonChannels: [{Array: 'InternalMessages',Name: 'Internal',LiaisonProp: 'InternalLiaison'},{Array: 'ExternalMessages',Name: 'Client',LiaisonProp: 'clientliaison'}],
            SelectedChannel: {Array: 'InternalMessages',Name: 'Internal',LiaisonProp: 'InternalLiaison'},
            StartLiaisonDialog: false,
        }
    },
    watch: {
        TimelineDialog (value){
            if(value){
            }
            else{
              this.$emit('ToggleLiaisonDialog',value)  
            }            
        },
        LiaisonDialogActive (value) {
            if(value){
                this.OpenLiaisonDialog()
            }
        },
        RecordData: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                this.TimelineRecord = this.RecordData
              }
            },deep: true
        },
        InternalMessages: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                  //console.log('change in RecordData InternalMessages')
              this.TimelineRecord.InternalMessages = this.InternalMessages  
              }
            },
            deep: true
        },
        ExternalMessages: {
            handler: function(oldvalue, newvalue) {              
              if(oldvalue !== newvalue){
                let called = false
                if(this.TimelineRecord.LiaisonCalled){
                    called = true
                }
                  //console.log('change in RecordData ExternalMessages')
                  this.TimelineRecord.ExternalMessages = this.ExternalMessages 
                  this.TimelineRecord.LiaisonCalled = called
            //   let setcollection = {
            //         arrayname : 'EntitiesArray', 
            //         array: []
            //     }   
            //     vm.$store.commit('setCollectionArray', setcollection)   
              }
            },
            deep: true
        },
    },
    computed:{
        ComputedLiaisonChannels(){
            return this.LiaisonChannels.filter(chan => {
                if(this.ActiveSession.ClientLookupField){
                    return chan
                }
                else{
                    return chan.Array !== 'ExternalMessages'
                }
            })
        },
        TableDB(){
            return this.$route.name === 'GroupDashboard' ? 
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).collection('entities').doc(this.ActiveSession.id).collection('datarecords') : 
            this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.ActiveSession.id) && !this.$store.state.rootdbentities.includes(this.ActiveSession.id) ? 
            db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.ActiveSession.id).collection('Records') : 
            this.$store.state.roottableentities.includes(this.ActiveSession.id) ?
            db.collection(this.ActiveSession.id.split('_').join('').toLowerCase()) : 
            db.collection('Databases').doc(this.ActiveSuiteid).collection(this.ActiveSession.id.split('_').join('').toLowerCase())
        },
        InternalMessages(){
            return this.TimelineRecord.InternalMessages
        },
        ExternalMessages(){
            return this.TimelineRecord.ExternalMessages
        },
        DocumentsAndGalleries(){
            let arr1 = this.Galleries.map(gallery => {
                gallery.IsGallery = true
                return gallery
                })
            let arr2 = this.DocumentRegisters
            if(this.ActiveComment && this.ActiveComment.FileType.includes('image')){
                return arr1.concat(arr2)
            }
            else{
                return arr2
            }
            
        },
        UserisTimelineRecordFollower(){
            return this.TimelineRecord && this.TimelineRecord.LiaisonFollowingUsers && this.TimelineRecord.LiaisonFollowingUsers.includes(this.userLoggedIn.id)
        },

        UsersArray(){
      return this.$store.state.UsersArray
    },
        userLoggedIn () {
      return this.$store.getters.user
    },
    },

    created(){
        //console.log('created recordliaison',this.RecordData)
        
        this.TimelineRecord = this.RecordData
        this.TaggedUsers = this.TimelineRecord.LiaisonFollowingUsers
        this.TimelineDialog = false
        this.ActiveSession.title = this.ActiveSession.DisplayName
        this.ActiveSession.topicid = this.ActiveSession.id
        this.ActiveSession.collection = this.ActiveSession.id.split('_').join('').toLowerCase()
        this.TimelineRecord.CanEdit = this.CanEdit
        if(this.FullonCreate){
            //console.log('is this.FullonCreate')
            //console.log('created recordliaison',this.TimelineRecord)
            this.OpenLiaisonDialog()
            this.ReadNotices(this.TimelineRecord)
        }
        else{
            //console.log('is NOT this.FullonCreate')
        }
    },
    
    methods:{
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },   
        UpdateFollowers(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        }, 
        CheckMention(event){
            if(event.key === '@'){
               this.Mentioning = true
            }
        },
        GoBack(){
            this.$emit('GoBack')
        }, 
    ToggleFollowRecordConfirm(record){
            let recordcol = this.TableDB.doc(record.id)
            let LiaisonFollowingUsers = []
            if(this.TimelineRecord.LiaisonFollowingUsers){
                LiaisonFollowingUsers = this.TimelineRecord.LiaisonFollowingUsers
            }
            if(this.UserisTimelineRecordFollower){
                let usermatch = LiaisonFollowingUsers.find(obj => obj === this.userLoggedIn.id)                
                if(usermatch){
                let index = LiaisonFollowingUsers.indexOf(usermatch)
                 LiaisonFollowingUsers.splice(index,1)   
                }                
            }
            else{
                LiaisonFollowingUsers.push(this.userLoggedIn.id)
            }
            //console.log('LiaisonFollowingUsers',LiaisonFollowingUsers)
            recordcol.update({
                LiaisonFollowingUsers: LiaisonFollowingUsers
            }).then(doc => {
                this.CloseTimelineRecordDialog()                  
            })
            
        },   
    GetClientLiaison(obj,query){  
            let vm = this          
            return new Promise(function(resolve, reject) {
                query.onSnapshot(commres => {
                  let listlength = commres.docs.length
                  if(listlength === 0){
                        resolve('nothing')
                        vm.LoadingMessages = false
                    }
                  const comchanges = commres.docChanges();
                  comchanges.forEach(comchange => {
                      if (comchange.type === 'added') {
                          let liaisonobj = {
                                  ...comchange.doc.data(),
                                  id: comchange.doc.id
                                  }
                                  let oncheck = vm.TimelineRecord.ExternalMessages.find(item => item.id === liaisonobj.id)
                                  if(!oncheck){
                                      vm.TimelineRecord.ExternalMessages.push(liaisonobj)
                                      vm.RefreshTimelineDialog()
                                  }
                      }
                      if(vm.TimelineRecord.ExternalMessages.length === listlength){
                          resolve('done')
                          vm.LoadingMessages = false
                      }
                  })
                })
            })
    },
        GetInternalRecordLiaison(query){  
            let vm = this          
            return new Promise(function(resolve, reject) {
                query.onSnapshot(commres => {
                let listlength = commres.docs.length
                //alert(listlength)
                if(listlength === 0){
                    resolve('nothing')
                    vm.LoadingMessages = false
                }
                const comchanges = commres.docChanges();
                comchanges.forEach(comchange => {
                if (comchange.type === 'added' || comchange.type === 'modified') {
                    let liaisonobj = {
                    ...comchange.doc.data(),
                    id: comchange.doc.id
                    }                    
                    if (comchange.type === 'added') {
                      //console.log('liaisonobj',liaisonobj)
                        let oncheck = vm.TimelineRecord.InternalMessages.find(obj => obj.id === liaisonobj.id)
                        if(!oncheck){
                            //console.log('liaisonobj !oncheck',liaisonobj,vm.TimelineRecord.InternalMessages,vm.TimelineDialog)
                            vm.TimelineRecord.InternalMessages.push(liaisonobj)
                            vm.RefreshTimelineDialog()
                        }
                    }
                    else if (comchange.type === 'modified') {
                        let oncheck = vm.TimelineRecord.InternalMessages.find(obj => obj.id === liaisonobj.id)
                        if(oncheck){
                        let index = vm.TimelineRecord.InternalMessages.indexOf(oncheck)
                        vm.TimelineRecord.InternalMessages[index] = liaisonobj
                        vm.RefreshTimelineDialog()
                        }                        
                    }
                    if(vm.TimelineRecord.InternalMessages.length === listlength){
                        resolve('done')
                        vm.LoadingMessages = false
                    }
                }
                })
                    
                })
            })
        },
        OpenSaveFiletoRecord(comment){
            this.ActiveComment = Object.assign({},comment)
            this.FiletoRecordDialog = true
            this.DocumentTable = ''
            this.DocumentType = ''
            this.DocumentRegisters = JSON.parse(JSON.stringify(this.ActiveSession.DocumentRegisters))
            this.Galleries = JSON.parse(JSON.stringify(this.ActiveSession.Galleries))
        },
        SaveFiletoRecord(comment){
            let vm = this
            //console.log(comment)
            // this.FiletoRecordDialog = true
            
            const RecordFilingdocument = {                 
                IsLiaisonFile: true,
                Liaisonid: comment.id,
                FileType: comment.FileType,
                fileurl: comment.fileurl,
                Name: comment.Uploadfilename,
                Modified_By: {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
                Modified_Byid: this.userLoggedIn.id,
                Modified_On: new Date(),
                StorageRef: comment.StorageRef,
                Created_By: {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
                Created_Byid: this.userLoggedIn.id,
                Created_On: new Date(),
                ModifiedDate: comment.lastModifiedDate,
                }
                if(!this.DocumentTable.IsGallery){
                    RecordFilingdocument.PrimarySelectedOption = this.DocumentType
                    let collection = this.DocumentTable.id.split('_').join('').toLowerCase()
                    //but if additional fields apply?DocumentTable.DocRegAdditionalFields.map
                    this.FileRecord(comment,RecordFilingdocument,collection)
                }
                else{ 
                    let collection = this.DocumentTable.id.split('_').join('').toLowerCase()
                    RecordFilingdocument.url = RecordFilingdocument.fileurl
                    RecordFilingdocument.ThumbURL = comment.ThumbURL
                    this.FileRecord(comment,RecordFilingdocument,collection)
                }
            
                
        },
        
        FileRecord(comment,RecordFilingdocument,collection){
            this.TableDB.doc(comment.relatedid).collection(collection).doc(comment.newdocid).set(RecordFilingdocument).then(doc => {
                let commscol = ''
                if(this.SelectedChannel.LiaisonProp === 'InternalLiaison'){
                commscol = this.TableDB.doc(comment.relatedid).collection('InternalLiaison')
                }
                else{
                commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
                }
                   commscol.doc(comment.id).update({
                       Filed: true
                   }).then(doc => {
                       this.CancelSaveFiletoRecord()
                   })
                })
        },
        CancelSaveFiletoRecord(){
            this.FiletoRecordDialog = false
            this.DocumentTable = ''
            this.DocumentType = ''

        },
        RefreshTimelineDialog(){
            if(this.TimelineDialog){
             this.TimelineDialog = false
            setTimeout(() => {
                this.TimelineDialog = true
            }, 10);   
            }
            
        },
        GetShortLivedURL(item){
        //console.log(item)
        const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          //console.log(result)
          item.TokenURL = result.data[0]
          setTimeout(() => {
                item.TokenURL = ''
                let url = item.fileurl
                item.fileurl = ''
                item.fileurl = url
          }, 120000);
          let url = item.fileurl
          item.fileurl = ''
          item.fileurl = url
        })
        },
        CloseTimelineRecordDialog(){
                this.TimelineDialog = false
        },
        CheckClientRecord(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(this.TimelineRecord.id).onSnapshot(snapshot => {
                    let clientrecordata = snapshot.data()
                    //console.log('clientrecordata',clientrecordata)
                    if(clientrecordata && clientrecordata.name){
                        this.TimelineRecord.HasClientRecord = true
                        if(this.ActiveSession.title === 'Suite Templates'){
                            if(!this.TimelineRecord.ExternalMessages){
                                this.TimelineRecord.ExternalMessages = []
                            }
                            this.GetClientLiaison(this.TimelineRecord,db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('senderid','==',this.userLoggedIn.id))
                            this.GetClientLiaison(this.TimelineRecord,db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('recipientid','==',this.userLoggedIn.id)) 
                        }
                        else{
                          this.GetClientLiaison(this.TimelineRecord,db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('relatedid','==',this.TimelineRecord.id))  
                        }                        
                    }
            }) 
        },
        GetLiaisonItems(){            
            this.TimelineRecord.LiaisonCalled = true
            if(this.ActiveSession.ClientLookupField){
                //this.LiaisonChannels.push({Array: 'ExternalMessages',Name: 'Client',LiaisonProp: 'clientliaison'})
                this.SelectedChannel = {Array: 'ExternalMessages',Name: 'Client',LiaisonProp: 'clientliaison'}
                if(!this.TimelineRecord.ExternalMessages){
                    this.TimelineRecord.ExternalMessages = []
                }
                this.CheckClientRecord() 
            }
            if(!this.TimelineRecord.InternalMessages){
                this.TimelineRecord.InternalMessages = []
            }
            //console.log("this.OperationalDB.collection('InternalLiaison')",this.OperationalDB.collection('InternalLiaison'))
            this.GetInternalRecordLiaison(this.OperationalDB.collection('InternalLiaison'))
        },
        CheckforLiaisonCall(){
            if(!this.TimelineRecord.LiaisonCalled){
               this.GetLiaisonItems()
            }
            else{
                this.LoadingMessages = false
            }
        },
        OpenLiaisonDialog(){
            this.CheckforLiaisonCall()
            //this.TimelineRecord = Object.assign({},record)
            this.TimelineDialog = true
            //console.log('OpenLiaisonDialog',this.TimelineRecord,this.ActiveRecord,this.ActiveSession)
        },
        ReadNotices(record){
            record.Notifications.map(not => {
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).update({
                Read: true
              })
            })
        },
        ByLatestLiaison(){
            let Messages = this.TimelineRecord[this.SelectedChannel.Array]
            if(Messages){
             return Messages.map(liase => {
                 if(liase.Created_Byid){
                     let userobj = this.UsersArray.find(obj => obj.id === liase.Created_Byid)
                     if(userobj && userobj.Profile_Photo){
                         liase.ProfPic = userobj.Profile_Photo
                     }
                 }
                if(typeof liase.Created_On.toDate !== 'undefined'){
                     liase.createdon = liase.Created_On.toDate()
                 }
                 else if(typeof liase.Created_On.seconds){
                     let seconds = liase.Created_On.seconds*1000
                     liase.createdon = new Date(seconds)
                 }
                 else{
                     liase.createdon = liase.Created_On
                 }            
                 //console.log(liase.createdon)        
                    liase.TimestampSort = liase.createdon.getTime()
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                  .slice(0,this.slice)   
            }
            
        },
        link(url,record) {
        this.input = `See this link <a href="`+url+`" target="_blank">here</a>`
        document.execCommand("createLink", false, url);

        this.NewLink = ''
        },
        FileInput(elid){
            let elmnt = document.getElementById(elid)
            elmnt.click()
        },
        onFileselected(event){
        this.LiaisonUploadFile = event.target.files[0]
        this.LiaisonUploadFile.FileModifiedDate = new Date(this.LiaisonUploadFile.lastModified)
        setTimeout(() => {
         let newinput = 'Please see attached file - '+this.LiaisonUploadFile.name 
          if(this.input){
            newinput = '. '+newinput
          }
         this.input = this.input+newinput 
         //console.log(this.input) 
        }, 300);
        
        
        },
        OpenAttachment(record){
            //console.log(record)
        },
        CreateClientRecord(record){
            console.log(this.ActiveSession.id)
            let clientrecordobj = {
                name: record[this.ActiveSession.RecordPrimaryField.Name],
                topicid: this.ActiveSession.topicid,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
            }
            if(this.ActiveSession.title === 'Suite Templates'){
                clientrecordobj.relatedtype = this.ActiveSession.title
                clientrecordobj.relatedid = record.id
            }
            if(this.ActiveRecord && this.ActiveRecord.Number){
                clientrecordobj.ActiveProcessNumber = this.ActiveRecord.Number
            }
             clientrecordobj.Clientid = record[this.ActiveSession.ClientLookupField.Name].id 
             console.log('clientrecordobj',clientrecordobj)
             db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id).set(clientrecordobj).then(doc => {
               
                 //console.log('created client record')
                 let notifyowner = record[this.ActiveSession.ClientLookupField.Name]
                 const NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: record.id,
                    Type: 'Feedback',
                    topicid: clientrecordobj.topicid,
                    Header: 'Liaison Started on '+clientrecordobj.name,
                    Content: 'Communication Channel opened for '+clientrecordobj.name,
                    CreatorFullName: record.Owner.Full_Name,
                    CreatorID: record.Owner.id,
                    Owner: {id: notifyowner.id, Full_Name: notifyowner.Full_Name},
                    Ownerid: notifyowner.id,
                    Read: false,
                    Path: '/MyAccount',
                    IsLiaison: true,
                    Liaisonid: record.id,
                    Message: record.Owner.Full_Name+' has opened Communication on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    }
                    if(clientrecordobj.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = clientrecordobj.ActiveProcessNumber
                    }
                     if(this.EmailObject && this.EmailObject.Message_to_Client){
                       NewNotification.Message = NewNotification.Message+`<br>`+this.EmailObject.Message_to_Client
                       if(this.ActiveSession.title === 'Suite Templates'){
                        NewNotification.Path = this.$route.path
                       }
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification)
                this.StartLiaisonDialog = false
             })               

        },
        OpenForm(form,comment){
        this.ActiveForm = form
        this.ActiveComment = comment
        this.OpenFormComponent = true
      },
      SubmitForm(form,comment,fileonly){
        //console.log(form,comment)
        this.ActiveForm = ''
        this.ActiveComment = ''
        this.OpenFormComponent = false
        
        if(!fileonly){
            this.input = 'Submitted'
        form.readonly = true
          this.comment(this.ActiveRecord,form)  
        }
        else{
            //console.log(form)
        }
      },
        comment(record,form,LiaisonUploadFile) {
            console.log(record,form,LiaisonUploadFile)
        let NewComms = {
          description: this.input,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.ActiveSession.title,
          recordlocation: '/'+this.ActiveSession.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.ActiveSession.topicid,
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        if(form){
            NewComms.FormObj = form.Tabs[0].Sections[0].Content[0]
            if(form.readonly){
              NewComms.readonly = true
            }
        }
        if(!LiaisonUploadFile){
         this.SaveNewcomms(NewComms,record)  
        }
        else{
            //first upload the file, return url and include url in NewComms
            this.UploadFileandAttahctoNewComms(NewComms,record)
        }
        
        // this.events.push({
        //   id: this.nonce++,
        //   text: this.input,
        //   time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
        //     return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
        //   }),
        // })

        this.input = null
      },
      SaveNewcomms(NewComms,record){
        console.log(NewComms,record)
        let recordcol = ''
        let commscol = ''
        let owneruser = ''
        let owner = ''
        let LiaisonFollowingUsers = []
        //console.log('this.SelectedChannel',this.SelectedChannel)
          if(this.SelectedChannel.LiaisonProp === 'InternalLiaison'){
              if(record.LiaisonFollowingUsers){
               LiaisonFollowingUsers = record.LiaisonFollowingUsers   
              }              
              LiaisonFollowingUsers = LiaisonFollowingUsers.concat(this.TaggedUsers.filter(userid => {
                return !LiaisonFollowingUsers.includes(userid)
              }))
              recordcol = this.TableDB.doc(record.id)
              commscol = this.TableDB.doc(record.id).collection('InternalLiaison')
              owneruser = record.Owner
                owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
          }
          else{
              commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
              if(this.userLoggedIn.id !== record[this.ActiveSession.ClientLookupField.Name].id){
                owneruser = record[this.ActiveSession.ClientLookupField.Name]
              }
              else{
                owneruser = record.Owner
              }              
                owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
          }
          //console.log('LiaisonFollowingUsers',LiaisonFollowingUsers)
        // console.log('this.ActiveRecord ',record)
        // console.log('this.ActiveRecord ClientLookupField',this.ActiveSession.ClientLookupField.Name)
            if(record.groupid){
                NewComms.groupid = record.groupid
            }
          NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    if(this.$route.name === 'GroupDashboard'){
                       NewNotification.groupid = this.$route.params.id 
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
               if(this.SelectedChannel.LiaisonProp === 'InternalLiaison') {
                   let UserisFollower = LiaisonFollowingUsers.find(obj => obj === NewComms.Created_By.id)
                   if(!UserisFollower){
                       LiaisonFollowingUsers.push(NewComms.Created_Byid)
                       recordcol.update({
                           LiaisonFollowingUsers: LiaisonFollowingUsers
                       })
                   }     
                   else{
                    recordcol.update({
                           LiaisonFollowingUsers: LiaisonFollowingUsers
                       })
                   }              
                    let notificationusers = LiaisonFollowingUsers.filter(user => {
                        return user !== NewComms.Created_By.id && user !== owner.id
                    }).map(userid => {
                        let usermatch = this.UsersArray.find(obj => obj.id === userid)
                        let userobj = {
                            Full_Name: usermatch.Full_Name,
                            Name: usermatch.Name,
                            Surname: usermatch.Surname,
                            id: userid
                        }
                        if(usermatch.IsGuestUser){
                            userobj.IsGuestUser = usermatch.IsGuestUser
                        }
                        return userobj
                    })
                    this.SendLiaisonNotificationstoFollowers(notificationusers,NewComms,record,doc)
               }
               else{                   
                this.LiaisonUploadFile = ''
                this.input = null
               }
            
            }) 
            
      },
      SendLiaisonNotificationstoFollowers(notificationusers,NewComms,record,doc){
          let userlength = notificationusers
          notificationusers.map((user,index) => {
              let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: user,
                    Ownerid: user.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    }
                    if(!user.IsGuestUser){
                        NewNotification.Path = '/'+this.ActiveSession.SingleName.split(' ').join('')+'/'+record.id
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    //console.log(NewNotification,user)
                    this.$store.dispatch('createNotification',NewNotification)
                    if(index-1+2 === userlength){
                        this.LiaisonUploadFile = ''
                        this.input = null
                    }                    
          })                    
      },
      UploadFileandAttahctoNewComms(NewComms,record){
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        let filename = newdocid+'_'+this.LiaisonUploadFile.name
        //console.log(filename)
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = NewComms.relatedtype.split(' ').join('')+'/'+NewComms.relatedid+'/'+this.SelectedChannel.LiaisonProp+'/'+ filename
        NewComms.Uploadfilename = this.LiaisonUploadFile.name
        var storageRef = firebase.storage().ref(path)
        NewComms.StorageRef = path
        NewComms.newdocid = newdocid
        NewComms.FileType = this.LiaisonUploadFile.type
        NewComms.lastModifiedDate = this.LiaisonUploadFile.FileModifiedDate
          var uploadTask = storageRef.put(this.LiaisonUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.LiaisonUploadFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  //console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  //console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
                NewComms.fileurl = downloadURL
                if(NewComms.FileType.includes('image')){
                  vm.$emit('ActivateProcessing',true)
                  vm.PrepareThumbnail(NewComms.Uploadfilename,vm.LiaisonUploadFile,NewComms.StorageRef).then(thumbresult => {
                        NewComms.ThumbURL = thumbresult
                        vm.$emit('ActivateProcessing',false)
                        vm.SaveNewcomms(NewComms,record)
                    })
                }
                else{
                  vm.SaveNewcomms(NewComms,record)
                }
                
              });
            })
                
        }, 300);
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
    }
}
</script>

<style>

</style>
