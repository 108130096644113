<template>
   <v-card flat tile class="transparent">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
          <v-card-text>
            <!-- it's like this. -->
            <v-switch @change="ActivateMultipleButtons()" v-model="EditedTab.MultipleButtons" label="Multiple Buttons"/>
            <ButtonOptions v-if="!EditedTab.MultipleButtons"
             :RenderedContentBlocks="RenderedContentBlocks" style="height: 100%;"
            @ApplytoPeers="ApplytoPeers" @PrepareImport="PrepareImport" @DeleteChild="DeleteChild"
            @ToggleElementAnimationDialog="ToggleElementAnimationDialog" :ActiveButtonMenuSection="ActiveButtonMenuSection"
            :WriteupEditorid="WriteupEditorid" :WriteupUpdateMethod="WriteupUpdateMethod"
            @NoPropsemit="NoPropsemit" @Onepropemit="Onepropemit" @Twopropemit="Twopropemit" @Threepropemit="Threepropemit"
            @AssignGalleryAssets="AssignGalleryAssets" :AdvancedComputedLibraryColors="AdvancedComputedLibraryColors" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
            @AssignImage="AssignImage" :Name="Name"	:UseGroupAssets="UseGroupAssets" :Directories="Directories"
            :FontOptions="FontOptions" :GalleryLibraries="GalleryLibraries"	:AppisDarkMode="AppisDarkMode"	@ActivateNewFieldDialog="ActivateNewFieldDialog"	
            :SystemAssets="SystemAssets" :WebFormEntities="WebFormEntities" :RelatedObj="RelatedObj" :FieldTypes="FieldTypes" :TableEmbedTables="TableEmbedTables"
            :WikiType="WikiType" :CurrentEntity="ComputedActiveEntity" @onImportfileSelected="onImportfileSelected" @DownloadFieldsImportTemplate="DownloadFieldsImportTemplate"
            @closeImport="closeImport" @saveImport="saveImport" @ConfigureTable="ConfigureTable" :SystemEntities="SystemEntities" :System="System"
            @ActivateNewElement="ActivateNewElement" @ActivateTableOptionDialog="ActivateTableOptionDialog"
            @UpdateWikiValue="UpdateWikiValue" @AssignWikiBasedGallery="AssignWikiBasedGallery" @OpenDialog="OpenDialog"
            :EntityFieldsandFeatures="EntityFieldsandFeatures" @AssignDescriptionList="AssignDescriptionList" :GroupData="GroupData"
            :EditedTab="ActiveTab"  :EditedTabindex="EditedTabindex" :DataCountCollections="DataCountCollections" :DynamicWikiData="DynamicWikiData"
            />
            <v-btn v-if="ActiveActionButton" outlined @click="DuplicateButton(ActiveActionButton)">
                Duplicate Button
            </v-btn>
            <v-select v-if="EditedTab.ActionButtons" @change="ConvertTabandButton(SelectedActiveActionButton)" clearable
            v-model="SelectedActiveActionButton" return-object item-text="ActionButtonName" :items="EditedTab.ActionButtons" label="ActiveButton"
            />
            <div v-if="ActiveActionButton"
            >
            <ButtonOptions v-if="ActiveActionButton && EditedTab.MultipleButtons"
             :RenderedContentBlocks="RenderedContentBlocks" style="height: 100%;"
            @ApplytoPeers="ApplytoPeers" @PrepareImport="PrepareImport" @DeleteChild="DeleteChild"
            @ToggleElementAnimationDialog="ToggleElementAnimationDialog" :ActiveButtonMenuSection="ActiveButtonMenuSection"
            :WriteupEditorid="WriteupEditorid" :WriteupUpdateMethod="WriteupUpdateMethod"
            @NoPropsemit="NoPropsemit" @Onepropemit="Onepropemit" @Twopropemit="Twopropemit" @Threepropemit="Threepropemit"
            @AssignGalleryAssets="AssignGalleryAssets" :AdvancedComputedLibraryColors="AdvancedComputedLibraryColors" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
            @AssignImage="AssignImage" :Name="Name"	:UseGroupAssets="UseGroupAssets" :Directories="Directories"
            :FontOptions="FontOptions" :GalleryLibraries="GalleryLibraries"	:AppisDarkMode="AppisDarkMode"	@ActivateNewFieldDialog="ActivateNewFieldDialog"	
            :SystemAssets="SystemAssets" :WebFormEntities="WebFormEntities" :RelatedObj="RelatedObj" :FieldTypes="FieldTypes" :TableEmbedTables="TableEmbedTables"
            :WikiType="WikiType" :CurrentEntity="ComputedActiveEntity" @onImportfileSelected="onImportfileSelected" @DownloadFieldsImportTemplate="DownloadFieldsImportTemplate"
            @closeImport="closeImport" @saveImport="saveImport" @ConfigureTable="ConfigureTable" :SystemEntities="SystemEntities" :System="System"
            @ActivateNewElement="ActivateNewElement" @ActivateTableOptionDialog="ActivateTableOptionDialog"
            @UpdateWikiValue="UpdateWikiValue" @AssignWikiBasedGallery="AssignWikiBasedGallery" @OpenDialog="OpenDialog"
            :EntityFieldsandFeatures="EntityFieldsandFeatures" @AssignDescriptionList="AssignDescriptionList" :GroupData="GroupData"
            :EditedTab="ActiveTab"  :EditedTabindex="EditedTabindex" :DataCountCollections="DataCountCollections" :DynamicWikiData="DynamicWikiData"
            />
            </div>
          </v-card-text>
        </v-card>
</template>





<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import ButtonOptions from '@/components/WebPages/ConfigComponents/ActionButton/ButtonOptions';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,ButtonOptions},
    data() {
        return {
            ActiveTab: '',
            ActiveActionButtonIndex: -1,
            SelectedActiveActionButton: '',
            ActiveActionButton: '',
            ActiveButtonMenuSection: {Name: 'Standard'},
            UploadingFile: false,
            UploadingFileProgress: 0,
            FilelocalURL: '',
            selectedFile: '',
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
          ButtonProps: ['ActionButtonName','ActionButtonIcon','ActionButtonIconOnly','ActionButtonText','ActionButtonFullWidth',
          'ActionButtonType','ContentDialogType','ContentDialogGalType','ContentDialogBox','ActionButtonElementIndex',
          'RelatedEntity','FormName','FormEmailRecipient','DarkForm','SoloInvert','HideFormIMG','PopupStyle',
          'ActionBTNTextColor','ActionButtonRoute','ButtonAlignment','ActionBtnDark','ButonColor','ActionBTNTextColor'],
        }
    },
    computed:{
        ActiveButtonMenuItems(){
                return [
                    {Icon: 'mdi-gesture-tap-button',Name: 'Standard',Class: 'grey'},
                    {Icon: 'mdi-palette-advanced',Name: 'Styling',Class: 'grey'},
                ]
            },
        ContentBlockTypes(){
            return [{Name: 'HTML Box',ComponentName: 'HTMLBox'},{Name: 'Gallery',ComponentName: 'GalleryTab'}]
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
    },
    created(){
        this.ActiveTab = this.EditedTab
    },
    methods:{
        ClearActiveTab(){
            this.ActiveTab = JSON.parse(JSON.stringify(this.EditedTab))
            this.ActiveActionButtonIndex = -1
            this.ActiveActionButton = ''
        },
        ConvertTabandButton(ActiveActionButton){
            if(!ActiveActionButton){
                let updbtn = {}
                let props = this.ButtonProps.map(prp => {
                    if(typeof this.ActiveTab[prp] !== 'undefined'){
                        updbtn[prp] = this.ActiveTab[prp]
                    }
                }) 
                this.EditedTab.ActionButtons[this.ActiveActionButtonIndex] = updbtn
                this.ClearActiveTab()            
            }
            else{
                this.ActiveActionButton = ActiveActionButton
              this.ActiveTab = JSON.parse(JSON.stringify(this.EditedTab))
              this.ActiveActionButtonIndex = this.EditedTab.ActionButtons.indexOf(this.ActiveActionButton)
              let props = this.ButtonProps.map(prp => {
                if(typeof this.ActiveActionButton[prp] !== 'undefined'){
                    this.ActiveTab[prp] = this.ActiveActionButton[prp]
                }
              }) 
            }
            console.log(this.EditedTab.ActionButtons,this.ActiveTab)
        },
        DuplicateButton(ActiveActionButton){
            let newbtn = Object.assign({},ActiveActionButton)
            newbtn.ActionButtonName = newbtn.ActionButtonName+'_new'
            this.EditedTab.ActionButtons.push(newbtn)
            //console.log(newbtn,this.EditedTab)
        },
        ActivateMultipleButtons(){
            if(!this.EditedTab.ActionButtons){
                this.EditedTab.ActionButtons = []
            }
            let props = this.ButtonProps
            let firstbtn = ''
            props.map(prp => {
                if(typeof this.EditedTab[prp] !== 'undefined'){
                    if(!firstbtn){
                        firstbtn = {}
                    }
                    firstbtn[prp] = this.EditedTab[prp]
                }
            })
            if(firstbtn){
            this.EditedTab.ActionButtons.push(firstbtn)
            this.SelectedActiveActionButton = this.EditedTab.ActionButtons[0]
            this.ConvertTabandButton(this.SelectedActiveActionButton)
            }
        },
        ActivateButtonMenuSection(sect){
            this.ActiveButtonMenuSection = sect
        },
        ActivateUploadingSingleFileNotification(bool){
            this.$emit('ActivateUploadingSingleFileNotification',bool)
        },
        OpenTempDoc(file){
            //console.log(file)
            let tempurl = URL.createObjectURL(file)
            //console.log(tempurl)
            window.open(tempurl, '_blank')
        },
        UploadDownloadFileSelect(event,tab){
            this.selectedFile = event.target.files[0]
            this.selectedFile.FileModifiedDate = new Date(this.selectedFile.lastModified)
            this.FilelocalURL = URL.createObjectURL(this.selectedFile)
        },
        CancelDownloadFileSelect(){
            this.FilelocalURL = ''
            this.selectedFile = ''
        },
        CheckifContentDialog(Type,EditedTab){
            if(Type === 'Content Dialog'){
                this.Onepropemit('AddContentDialog',EditedTab)
            }
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
