<template>
<div>
<span v-if="AddressRenderField(field)">
                  <!-- <v-row class=" mx-2">
                    <v-col row>
                      <h4 style="font-weight: 400;" :class="HeaderDarkLightStyle">{{field.DisplayName}}</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row> -->
                    <v-list class="transparent mx-4" width="100%" outlined v-if="!Editing">
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content  style="font-weight: 400;">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content  style="font-weight: 400;">
                                {{Record[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4 >View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                     <FieldValueEditerComponent @UploadFileSelect="UploadFileSelect" @UpdateEditableField="UpdateEditableField" v-if="Editing" :Record="Record" :field="field" :CurrentEntity="CurrentEntity"
                        /></span>
                      <span v-else-if="field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.CommonFieldType === 'Physical Address' && field.RelatedRecord
                      || field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.CommonFieldType === 'Postal Address' && field.RelatedRecord">
                  <v-row class="mx-2">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">{{field.DisplayName}}</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                    <v-list class="transparent mx-4" outlined v-if="!Editing">
                        <v-list-item dense v-for="breakdownfield in field.ParentFieldObj.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content  style="font-weight: 400;">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content  style="font-weight: 400;" v-if="Record[field.RelatedRecord.RelationshipField.Name]">
                                {{Record[field.RelatedRecord.RelationshipField.Name][breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.ParentFieldObj.Type === 'Common Field' && field.ParentFieldObj.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.ParentFieldObj.Type === 'Common Field' && field.ParentFieldObj.CommonFieldType === 'Physical Address' && !Editing">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.ParentFieldObj.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                     <FieldValueEditerComponent style="padding-left:15px;" @UploadFileSelect="UploadFileSelect" @UpdateEditableField="UpdateEditableField" v-if="Editing" :Record="Record" :field="field" :CurrentEntity="CurrentEntity"
                        />
                        </span>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {FieldValueEditerComponent},
    data() {
        return {            
          DateMenu: false,
          TimeMenu: false,
        }
    },	
    computed:{
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ViewMapItem(FieldBreakdown) {
        let combinedlink = ''
        FieldBreakdown.map((breakdown,i) => {
            combinedlink = combinedlink+this.Record[breakdown.Name]
            if(i-1+2 < FieldBreakdown.length){
                combinedlink = combinedlink+'+'
            }
        })
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        
        window.open(FinalLink, '_blank')
      },
        AddressRenderField(field){
        // if(field.IsConditionalRender){
        //   return this.ConditionalRender(field)
        // }
        // else{
        //   return field
        // }
        return true
      },
      RenderField(field){
        if(field.IsConditionalRender){
          return this.ConditionalRender(field)
        }
        else{
          return !field.CommonFieldType && field.Type !== 'Parent Field' && !field.ParentFieldObj || field.CommonFieldType && field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'
          || field.Type === 'Parent Field' && field.ParentFieldObj && !field.ParentFieldObj.CommonFieldType|| field.Type === 'Parent Field' && field.ParentFieldObj
          && field.ParentFieldObj.CommonFieldType && field.ParentFieldObj.CommonFieldType !== 'Physical Address' && field.ParentFieldObj.CommonFieldType !== 'Postal Address'
        }
      },
      ConditionalRender(field){
        if(field.RenderConditionType === 'Boolean Match'){
          return this.ConditionalBooleanRender(field)
        }
        else if(field.RenderConditionType === 'Option Set Match'){
          return this.ConditionalOptionsRender(field)
        }        
      },
      ConditionalOptionsRender(field){
        let options = field.ConditionalOptions
        //console.log(options)
        let recordoption = this.Record[field.ConditionalOptionField]
        //console.log('recordoption',recordoption,options)
        return recordoption && options.includes(recordoption.ID)
      },
      ConditionalBooleanRender(field){
        let render = true
          field.ConditionalBooleans.map(bool => {
            if(this.Record[bool.Field] !== bool.Value){
              render = false
            }
          })
        return render
      },
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
    }
}
</script>

<style>

</style>



