<template>
  <div :style="'left: '+LeftPos+'px;'">
    <!-- <v-overlay> -->
    <!-- <v-overlay class="gradoverlay" color="#ffffff00" absolute :style="tab.ParallaxGradient">
      </v-overlay> -->
        <!-- actually transition from this 
        <v-avatar color="black" size="150"
        style="opacity: 0.3;position: absolute;left: 0%;top: 0%;">
        </v-avatar>
        to what below -->
        <!-- <v-avatar color="black" class="piechart" :size="Height*2"
        :style="HUDBGStyle">
        </v-avatar> -->
     <div :style="HUDBGStyle" >
       <!-- <v-btn class="hudbtn" v-for="huditem in HUDItems" :key="huditem.itemObjKey"
       :style="huditem.Style"
       @click="ActivateHUDItem(huditem)" dark fab icon>
            <v-icon  :size="Height/4">
                {{huditem.Icon}}
            </v-icon>
        </v-btn> -->
      <button @click="ActiveHUDItemAction(huditem)" class="hudbtn"
      v-for="huditem in HUDItems" :key="huditem.itemObjKey"
      :style="huditem.Style+'--hovercol: #9BC13CAA;cursor: pointer;'"> 
      <v-icon :style="huditem.IconStyle+'cursor: pointer;'" dark :size="Height/4">
                {{huditem.Icon}}
            </v-icon>
      </button>
     </div>
     <div id="activevisitorbubble" class="hudnav hudnavcore">
        <!-- <v-avatar color="grey" size="300"> -->
        <v-avatar color="black" :size="Height" style="padding: 15px;z-index:10;"
         v-if="!ActiveHUDItem">
         <!--  @click="ToggleMenu()"  -->
           <img v-if="!ActiveHUDItem" contain style="cursor: pointer;padding: 15px;object-fit: contain;"
          
            :src="System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')"/>
         
        </v-avatar>
        <v-avatar @click="ActiveHUDItemAction(ActiveHUDItem)" 
        color="black" :size="Height" style="padding: 15px;z-index:10;cursor: pointer;"
         v-if="ActiveHUDItem">
           <div v-html="ActiveHUDItem.Description"
           :style="MiniView ? 'font-size: 16px' : 'font-size: 24px'">
           </div>      
           <!-- <v-btn small @click="ActiveHUDItemAction(ActiveHUDItem)" dark
            outlined bottom absolute>Go Now</v-btn>       -->
        </v-avatar>
         <!-- <v-avatar :style="'--xpos: '+huditem.xpos+';--ypos: '+huditem.ypos"
          v-for="huditem in HUDItems" :key="huditem.itemObjKey"
         class="huditem" :size="Height/3">
            <v-btn :x-large="!MiniView" @click="ActivateHUDItem(huditem)" dark fab icon>
                <v-icon  :size="Height/4">
                    {{huditem.Icon}}
                </v-icon>
            </v-btn>
            </v-avatar> -->
        <!-- </v-avatar> -->
        </div> 
        <!-- </v-overlay> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','tab'],
    components: {},
    data() {
        return {
          ClickedHUDItem: false,
            ActiveHUDItem: '',
            WindowWidth: 0,
            WindowHeight: 0,
            NextHUDItem: 0,
        }
    },	
    computed:{
      HUDBGStyle(){
        let style = 'position: absolute;left: -50%;top:-50%;transform: rotate(-135deg);'
        style = style+'overflow: hidden;border: thin solid white;'
        style = style+'height:'+this.Height*2+'px;width:'+this.Height*2+'px;'
        style = style+'border-radius: 50%;'
        style = style+'background: #0000007c;'
        console.log(style)
        //piechart
        // let conicgrad = {
        // '#4e79a7': '0',
        // '#4e79a7': '25%',
        // '#000000': '0',
        // '#000000': '50%',
        // '#000000': '0',
        // '#000000': '75%',
        // '#000000': '0',
        // '#000000': '100%'
        // }
        // style = style+'--conicgrad: '+conicgrad+';'
        //style = style+'background: #4e79a7;'
        return style
      },
        LeftPos(){
            return (this.WindowWidth/2)-(this.Height/2)
        },
        MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
        Height(){
            return this.MiniView ? 150 : 250
        },
        HUDItems(){
            return this.tab.HUDItems.map((item,index) => {
                let itemobj = Object.assign({},item)
                let pardeg = -135
                let deg = index*(360/this.tab.HUDItems.length)
                itemobj.Style = 'transform: rotate('+deg+'deg);--size: '+this.Height+'px;'
                if(this.ActiveHUDItem && this.ActiveHUDItem.Index === item.Index){
                  itemobj.Style = itemobj.Style+'background: #00ACCDAA;'
                }
                else{
                  itemobj.Style = itemobj.Style+'background: #0000007c;'
                }
                itemobj.IconStyle = 'transform: rotate('+(-(deg+pardeg))+'deg);'
                if(index === 0){
                itemobj.xpos = '-300%'
                itemobj.ypos = '-50%'
                }
                else if(index === 1){
                itemobj.xpos = '-80%'
                itemobj.ypos = '-275%'
                }
                else if(index === 2){
                itemobj.xpos = '145%'
                itemobj.ypos = '-50%'
                }
                else if(index === 3){
                itemobj.xpos = '-80%'
                itemobj.ypos = '175%'
                }
                else if(index === 0 && !this.MiniView){
                itemobj.xpos = '-450%'
                itemobj.ypos = '-50%'
                }
                else if(index === 1 && !this.MiniView){
                itemobj.xpos = '-115%'
                itemobj.ypos = '-400%'
                }
                else if(index === 2 && !this.MiniView){
                itemobj.xpos = '245%'
                itemobj.ypos = '-50%'
                }
                else if(index === 3 && !this.MiniView){
                itemobj.xpos = '-115%'
                itemobj.ypos = '320%'
                }
                return itemobj
                //transform: translate(-300%, -50%) scale(1);
                //translate(-80%, -275%) scale(1);
                //translate(145%, -50%) scale(1);
                //translate(-80%, 175%) scale(1);
            })
            // return [
            //     {Icon: 'mdi-help',Description: 'Read more about who we are',Index: 1},
            //     {Icon: 'mdi-account-key',Description: 'Our ESS Option',Index: 3},
            //     {Icon: 'mdi-hand-heart',Description: 'Our Services',Index: 5},
            //     {Icon: 'mdi-phone',Description: 'Make Contact',Index: 8}
            // ]
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
  mounted() {
  window.addEventListener('resize', this.ResizeWindow)
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.ResizeWindow);
  },	
    created(){
        window.onload = setTimeout(() => {
        this.ResizeWindow()
        }, 250)
        setTimeout(() => {
            this.ToggleMenu()
        }, 1000);
        this.AutoPlayHUDItems()
    },
    methods:{
        AutoPlayHUDItems(){
            setTimeout(() => {
              if(!this.ClickedHUDItem){
                this.ActiveHUDItem = this.tab.HUDItems[this.NextHUDItem]     
                if(this.tab.HUDItems[this.NextHUDItem-1+2]){
                this.NextHUDItem++         
                }
                else{
                  this.NextHUDItem = 0
                }
                this.AutoPlayHUDItems()
              }
            }, 3000);
        },
        ResizeWindow(){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
        
      },
        ActiveHUDItemAction(ActiveHUDItem){
            this.ActiveHUDItem = ''
            this.$emit('ActiveHUDItemAction',ActiveHUDItem)
        },
        ActivateHUDItem(item){
          this.ClickedHUDItem = true
            if(this.ActiveHUDItem === item){
                this.ActiveHUDItem = ''
            }
            else{
                this.ActiveHUDItem = item
            }
        },
        ToggleMenu(){
            let nav = document.getElementById('activevisitorbubble')
            nav.classList.toggle("open")
        },
    }
}
</script>

<style>

.hudnav{
     z-index:11;
  /* position: absolute;
  left: 42%;
  bottom: 100px;
  transition: 0.3s;
  transform-origin: left;
  z-index:11;
  display: block; */
  /* put display on for advanced bubble menu coming soon */
}
.hudnav.presub{
  bottom: 100px;
}
.hudnav.submenu{
  position: relative;
  left: 0%;
  top: 20px;
  transform: rotate(90deg)
}
.submenulist{
  position: absolute;
  left: -90%;
  top: var(--top);
  transition: 0.3s;
}
.submenulist.submenuactive{
  left: 0%;
}
.menulist{
  position: absolute;
  left: 0%;
  top: var(--top);
  transition: 0.3s;
}
.menulist.submenuactive{
  left: -80%;
}
.hudnav .hamburger{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 40px;
  height: 30px;
  cursor: pointer;
  z-index: 3;
}
.hudnav .hamburger::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background: #202020; */
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)!important; */
  /*background: linear-gradient(-45deg, #ee9e94de, #d4729bd5, #466ca4dc, #23d5abc9);*/
  z-index: 1;
}

.hudnav .hamburger .hamburgerline{
  z-index: 2;
  width: 100%;
  height: 5px;
  background: #ffffff;
  transition: 0.3s;
  transform-origin: center;
}
.hudnav.open .hamburger .hamburgerline:nth-child(1){
  /* display: none; */
  transform: translate(0, 250%) rotate(45deg);
}
.hudnav.open .hamburger .hamburgerline:nth-child(2){
  /* display: none; */
  transform: rotate(45deg);
}
.hudnav.open .hamburger .hamburgerline:nth-child(3){
  /* display: none; */
  transform: translate(0, -250%) rotate(-45deg);
}
.hudnav .hudmenuitem{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%) scale(0.5);
  justify-items: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  
  /* font-size: 40px; */
  /* color: #FFFFFF; */
  transition: 0.3s;
  transition-delay: calc(0.1s * var(--i));
}
.hudnav.open .hudmenuitem:nth-child(2){
  transform: translate(-240%, -50%) scale(1);
  z-index: 50;
}
.hudnav.open .hudmenuitem:nth-child(3){
  transform: translate(-90%, -180%) scale(1);
}
.hudnav.open .hudmenuitem:nth-child(4){
  transform: translate(60%, -50%) scale(1);
}
.hudnav.open .hudmenuitem:nth-child(5){
  transform: translate(-90%, 105%) scale(1);
}
.hudnav.open .hudmenuitem:nth-child(6){
  transform: translate(65%, -130%) scale(1);
}
.hudnav.open .hudmenuitem:nth-child(7){
  transform: translate(100%, -50%) scale(1);
}
.huditem {
    /* position: absolute;
    z-index: 2; */
}
.hudnav .huditem{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%) scale(0.5);
  justify-items: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  
  /* font-size: 40px; */
  /* color: #FFFFFF; */
  transition: 0.3s;
  transition-delay: calc(0.1s * var(--i));
}
.hudnav.open .huditem{
  transform: translate(var(--xpos),var(--ypos)) scale(1);
  z-index: 3!important;
}
/* .hudnav.open .huditem:nth-child(2){
  transform: translate(-300%, -50%) scale(1);
  z-index: 3!important;
}
.hudnav.open .huditem:nth-child(3){
  transform: translate(-80%, -275%) scale(1);
  z-index: 3!important;
}
.hudnav.open .huditem:nth-child(4){
  transform: translate(145%, -50%) scale(1);
  z-index: 3!important;
}
.hudnav.open .huditem:nth-child(5){
  transform: translate(-80%, 175%) scale(1);
  z-index: 3!important;
} */
.piechart{
  transform: rotate(45deg);
  background:
  conic-gradient(
    #4e79a7 0,
    #4e79a7 25%,
    #4e79a700 0,
    #000000 50%,
    #000000 0,
    #000000 75%,
    #000000 0,
    #000000 100%
	);
}
.hudbtn{
  outline: none;
    /* background-color: lightgreen; */
    
    border: thin solid white;
    /* border-radius: 50%; */
    position: absolute;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    /* width: 150;
    height: 150%; */
    left: 50%;
    bottom: 50%;
    transform-origin: bottom left;
    transition: all 200ms ease-out;
    &:hover {
      box-shadow: 0px 0px 5px 2px #515151;
      z-index: 1;
      background: var(--hovercol)!important;
      /* background: #00ACCDAA!important; */
      
    }
}
</style>



