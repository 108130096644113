
<template>
<v-card flat class="white" tile width="100%" height="100%" style="padding-bottom:60px;">
<!-- <v-layout column class="createnotice-container" v-if="userisContentAdmin">
    <v-icon @click="AddSocialItemtoNotice()" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
  </v-layout> -->

<SocialSharing v-if="userLoggedInn && !$store.state.TemplateView" :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="userLoggedIn" :collectionname="SharedCollection" :SiteModerators="ComputedSiteModerators"/>

   <v-parallax v-if="GroupData.logo"
            height="150"
            :src="GroupData.logo"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
            <v-parallax v-if="!GroupData.logo"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Blog.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout  class="justify-center">
      <!-- <div class="flex xl2 lg9 md10 sm10 xs12">
      here we can put a navbar of sorts
    </div> -->
    <div class="flex xl11 lg11 md11 sm11 xs12" v-if="FullArticleData">
      <v-card outlined flat tile class="mx-3" hover >
     <v-card-actions class="recordtoolbar white--text" style="font-size:10px" v-if="UserIsModerator || userIsAdmin || UserisTemplateOwner">
                  <!-- <v-chip small color="secondary" class="white--text"  v-if="UserRecord.id">
                    {{author}}
                  </v-chip> -->
                  <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator && !userIsAdmin && !UserisTemplateOwner"
                                 v-if="UserRecord.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="pop" class="white--text" v-on="on" v-if="UserRecord.id">
                              {{PubStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <div  v-for="status in PublishStatusOptions" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator && !userIsAdmin && !UserisTemplateOwner"
                                 v-if="UserRecord.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateStatus(status.Name)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>

                 <v-spacer></v-spacer>
                  <span  v-if="createdon && createdon.toDate && UserRecord">{{createdon.toDate()}}</span>
                  <v-icon @click="EditItem()" v-if="UserIsModerator || userIsAdmin || UserisTemplateOwner">mdi-pencil</v-icon>
                </v-card-actions>
          <v-list-item v-if="!EditingBlog">
                  <v-avatar>
                    <v-img :src="AuthorObj.Profile_Photo" v-if="AuthorObj.Profile_Photo"/>
                    <v-img src="@/assets/BlankProfilePic.png" v-if="!AuthorObj.Profile_Photo"/>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                  {{AuthorObj.Full_Name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{blogpubDate}}
                      <!-- <span  v-if="createdon && createdon.toDate && UserRecord">{{TimeStampFormatterSTRING(createdon.toDate())}}</span>
                      <span  v-if="createdon && !createdon.toDate && UserRecord">{{createdon}}</span> -->
                    </v-list-item-subtitle>
                  </v-list-item-content><v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                </v-list-item>
                 <v-card-title v-if="!EditingBlog" class="headline">{{ title }}
                   
                 </v-card-title>
                 <v-card-text v-if="!EditingBlog" v-html="FullArticleData.caption">
                 </v-card-text>
            <v-layout  class="justify-center" v-if="!EditingBlog">
                <v-img
                  contain :height="MiniView ? 300 : 600"
                  v-bind:src="image"
                  v-if="image"
                ></v-img>
            </v-layout>
            <v-layout  class="justify-center" v-if="!EditingBlog">
              <v-card flat class="transparent" tile hover width="100%" >
               
              
                        <!-- <v-card-title class="headline">{{ title }}</v-card-title>
                            
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{blogpubDate}}
                </v-card-actions>
                <v-divider></v-divider> -->
                <v-card-text :style="TeaserView? 'height: 300px;margin-bottom: -300px;overflow: hidden;' : ''" v-html="Computedblogcontent">
                </v-card-text>
                <v-card v-if="TeaserView" height="300" flat tile class="transparent" style="background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));" >
                  
                </v-card>
                <v-list style="padding: 0px;" width="100%" v-if="TeaserView"  class="transparent justify-center" >
                    <v-card-title style="padding-top: 0px;" class="justify-center mediumoverline">This content is available through Membership.</v-card-title>
                    <v-list-item v-if="!PendingMember" dense class="justify-center">
                        <v-card-text style="text-align: center;">
                            Subscribe below
                        </v-card-text>
                    </v-list-item>
                  <v-list-item dense class="justify-center" v-if="!PendingMember">
                    <v-btn @click="EmitMarketDialog()" dark class="success">Subscribe ({{ChannelName}})</v-btn>
                  </v-list-item>
                  <v-list-item v-if="PayperView && !PendingMember" dense class="justify-center">
                    OR
                  </v-list-item>
                  <v-list-item v-if="PayperView && !PendingMember" dense class="justify-center">
                    <v-btn dark @click="ConsumeTokens(GroupData.BlogsCreditCharge)" class="warning">Purchase ({{GroupData.BlogsCreditCharge}} tokens)</v-btn>
                  </v-list-item>
                  <v-list-item v-if="PayperView && !PendingMember" dense class="justify-center">
                    <v-btn outlined @click="UserAvailableTokens < GroupData.BlogsCreditCharge ? ActivateTokenPurchaseDialog() : ''" color="warning">{{UserAvailableTokens === 0 ? 'Insufficient tokens?' : 'You have '+UserAvailableTokens+' tokens'}} </v-btn>
                  </v-list-item>
                  <v-list-item dense class="justify-center" v-if="PendingMember">
                    <v-btn outlined dark color="success">Your Subscription requires payment</v-btn>
                  </v-list-item>
                  </v-list>
                <!-- <v-card-actions>
                  
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                </v-card-actions> -->
                <!-- <v-card-actions  v-if="!TeaserView">
                  <TaggingComponent v-if="userLoggedIn" :AppisDarkModeCard="AppisDarkModeCard" :UsercanTag="UserIsModerator" :record="FullArticleData" />
                </v-card-actions> -->

                 <SocialCommentsandLikes v-if="SocialCommentsActive && !TeaserView"  :panel="0"
            :PathName="'Blog'"  :GroupID="$route.name === 'GroupBlog' ? $route.params.id : ''" :CanSocial="UserCanSocialTopic" :notificationitem="'Blog'" :RelatedType="'Site Blog'" :SocialItem="FullArticleData" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
              </v-card>
             
              </v-layout>
               
      <v-layout class="justify-center" v-if="EditingBlog">
        <EditBlog :title="title" :pubdatastring="pubdatastring" :CurrentModerators="Moderators"
        @StopEdit="StopEdit" :CurrentImage="image" :FullArticleData="FullArticleData"
        @ActivateProcessing="ActivateProcessing" :GuestsIncluded="GuestsIncluded"
        :SiteModerators="ComputedSiteModerators"  :caption="caption" :GroupData="GroupData"
        :CurrentImageShy="ImageShy" :Currentblogcontent="blogcontent"/>
      </v-layout>
      </v-card>
    </div>
    <!-- <div class="flex xl3 lg9 md10 sm10 xs12">
      here we can put a group navbr for instnac,e or navbar giving info about the blogger and "similar" blogs (site level cat) OR "other group articles/blogs" etc.
    </div> -->
    </v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import EditBlog from '@/components/SuitePlugins/Blogs/Edit/Single'
import TaggingComponent from '@/components/SocialNetwork/TaggingComponent';
import SocialSharing from '@/components/SocialNetwork/SocialSharing'
import SocialCommentsandLikes from '@/components/SocialNetwork/SocialCommentsandLikes';

export default {
    props: ['SiteModerators','FriendsList','System'],
      components: {
        EditBlog,
        SocialSharing,
        SocialCommentsandLikes,
        TaggingComponent
  },
    data() {
        return {
          GuestsIncluded: false,
          PublishStatusOptions: [
            {
              Name: 'Draft',
              Class: 'green',
              Warning: 'Unpublish the item?'
            },
            {
              Name: 'Published (Internal)',
              Class: 'warning',
              Warning: 'Make the item avialable for viewing by all Users?'
            },
            {
              Name: 'Published (Public)',
              Class: 'error',
              Warning: 'Make the item available for viewing by the Public?'
            }
          ],
          ShareItemDialog: false,
          InternalLinkType: '',
          LinkComponent: '',
          LinkID: '',
          SharedCollection: '',
          NoticeCoverIMGProp: 'image',
          FullArticleData: '',
          PublicBlogs: [],
         Moderators: [],
            blogquil: false,
            snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        blogdialog: false,
        blogcontent: '',
        PubStatus: '',
        UserIsNOTModerator: true,
        
        IsAuthor: false,
        author: null,
        authorid: null,
        //userid: this.$store.state.user.id,
        groupblogs: [],
        groups: [],  
        content: null,
        image: '',
        ImageShy: false,
        createdon: '',
        blogpubDate: '',
        UserCanEdit: false,
        BlogPrivacy: null,
        PublishStatus: null,
        PublishStatusTypes: [
        {index: 0, text: 'Draft'},
        {index: 1, text: 'Published'},
          ],  
        title: null,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                BlogPrivacy: null,
                PublishStatus: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                BlogPrivacy: null,
                PublishStatus: null,                
            },
            UserRecord: {},
            GroupData: {},
            EditingBlog: false,   
        }
    },
    computed: {
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      ContentPublicColPath(){
        return this.$route.name === 'GroupBlog' ? this.ContentColPath : !this.TemplateRootPath ?  
        db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('siteblogswiki') :
        this.TemplateRootPath.collection('samplesiteblogswiki')
      },
      ContentColPath(){
        //quick tip here. Edit and New components reuqire collection to update, or add docs. thus, not interchangeable with CollectionRef on "feed" or "collection view"
        //the CollectionRef would add permission queries like for group aticles "where gourpid...etc"
        return this.$store.state.TemplateView ? this.ContentRootPath.collection('samplesiteblogs') : 
        this.$route.name === 'GroupBlog' ? this.ContentRootPath.collection('groupblogs') : this.ContentRootPath.collection('siteblogs')
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
       UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
      GroupSubscriptionPackages(){
      return this.ActiveGroup && this.ActiveGroup.SubscriptionPackages ? this.ActiveGroup.SubscriptionPackages : []
    },
       UserMemberObj(){
      return this.$store.state.UserMemberObj
    },
      blogid(){
        return this.$route.name === 'GroupBlog' ? this.$route.params.slug : this.$route.params.id
      }, 
      groupid(){
        return this.$route.name === 'GroupBlog' ? this.$route.params.id : ''
      } , 
      UserisActiveSubscribedMember(){
        return this.UserSubscriptionPackage ? this.UserSubscriptionPackage.ContentScope.includes('Group Blogs') : false
      },
      UserSubscriptionPackage(){
        return this.UserMemberObj ? this.GroupSubscriptionPackages.find(obj => obj.id === this.UserMemberObj.Subscription_Packageid) : ''
      },
      UserisActiveMember() {
      return this.UserMemberObj && this.UserMemberObj.Status === 'Active'
    },
      ChannelName(){
        return this.GroupData ? this.GroupData.name : ''
      },
      Computedblogcontent(){
        if(this.TeaserView){
          return this.smart_substr(this.blogcontent,1800)
        }
        else{
          return this.blogcontent
        }
      },
      UserAvailableTokens(){
        return this.$store.state.UserAvailableTokens
      },
      UserContentPurchases(){
        return this.userLoggedIn && this.userLoggedIn.ContentPurchases ? this.userLoggedIn.ContentPurchases : []
      },
      UserPurchases(){
        return this.UserContentPurchases.map(item => {
          return item.Contentid
        })
      },
      PendingMember(){
        return this.UserMemberObj && this.UserMemberObj.Status === 'InActive'
      },
      TeaserView(){
        //console.log(this.UserPurchases.includes(this.$route.params.slug),!this.UserPurchases.includes(this.$route.params.slug))
        //actually 1. is blog "Membership Packages"? 1.1 Is current dude and active subscribed channel member? 1.1.1 if not memebr is blog offered token purchase?
        //could also check !this.UserMemberObj.BlogsModerator &&  but wht if moderators also pay right
        //console.log(this.UserisActiveSubscribedMember)
        return this.GroupData && this.GroupData.BlogsMonetization === 'Membership Packages' && !this.UserisActiveSubscribedMember
        && !this.UserPurchases.includes(this.$route.params.slug) && !this.UserMemberObj ||
        this.GroupData && this.GroupData.BlogsMonetization === 'Membership Packages' && !this.UserisActiveSubscribedMember
        && !this.UserPurchases.includes(this.$route.params.slug) && this.UserMemberObj && !this.UserMemberObj.Administrator
        || this.GroupData && this.GroupData.BlogsMonetization === 'Token Based' && !this.UserPurchases.includes(this.$route.params.slug) && !this.UserMemberObj.Administrator 
        //and not active member? I don't htink so you can be a memebr of a different package that does not include these items. so activemember almsot irrelevant
        //given you have to be a member, in order to be a subscibed member...
      },
      PayperView(){
        return this.TeaserView && this.GroupData && this.GroupData.BlogsPayperView && this.GroupData.BlogsCreditCharge > 0
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ComputedSiteModerators(){
        if(this.FullArticleData && this.ActiveGroup){
        //console.log(this.FullArticleData.moderatorrolesarrayQuery)
        let itemmoderators = this.FullArticleData.moderatorrolesarrayQuery.map(mod => {
          let userobj = this.UsersArray.find(obj => obj.id === mod)
          return {
            Full_Name: userobj.Full_Name,
            id: userobj.id,
            ModerationPermissions: [],
            Name: userobj.Name,
            Surname: userobj.Surname
          }
        })
        .filter(mod => {
          let oncheck = ''
          if(this.$route.name === 'GroupBlog'){
            oncheck = this.groupmembers.find(obj => obj.id === mod.id)
          }
          else{
            oncheck = this.SiteModerators.find(obj => obj.id === mod.id)
          }
          return !oncheck
        })
        if(this.$route.name === 'GroupBlog'){
          //console.log(this.groupmembers)
            return this.groupmembers.filter(groupmember => {
              return groupmember.BlogsModerator
            }).map(mod => {
              //console.log(mod)
                  return {
                Full_Name: mod.UserName,
                id: mod.Userid,
                ModerationPermissions: [],
                Name: mod.UserName,
                Surname: mod.UserName
              }
            })
        }
        else{
           return this.SiteModerators.concat(itemmoderators)
        }  
        }
        else{
          return this.SiteModerators
        }
               
      },
      groupmembers(){
            return this.ActiveGroup && this.ActiveGroup.GroupMembers ? this.ActiveGroup.GroupMembers : []
        },
      TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveMarketTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
      AuthorObj(){
        return this.authorid && this.$store.state.TemplateView ? this.TemplateUsers.concat(this.UsersArray).find(obj => obj.id === this.authorid) : this.authorid && this.UsersArray ? this.UsersArray.find(obj => obj.id === this.authorid) :''
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      SocialCommentsActive(){
        return this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && !this.System.Disable_Guest_Posts
        || this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && this.System.Disable_Guest_Posts && !this.$store.state.UserisGuest
      },
      userisContentAdmin(){
        return this.userIsAdmin
      },
      UserCanSocialTopic(){
        if(this.$route.name === 'GroupBlog'){
          let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
          return memberobj
        }
        else{
          return this.userLoggedIn
        }
      },
      UserIsModerator(){
        
          let UserModeratorobj = this.ComputedSiteModerators.find(obj => obj.id == this.UserRecord.id)
          //console.log(this.ComputedSiteModerators,UserModeratorobj)
          return this.authorid === this.UserRecord.id || UserModeratorobj
          //|| this.userIsAdmin
        },
     SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupBlog' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Blogs' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        RelatedBlogs () {
            return this.groupblogs.filter(blog => {
                return blog.groupid === this.groupid
            })
        },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
        ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
            
    },
    watch: {
      ActiveGroup: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.GetGroup()
                }
            },
            deep: true
    },
      UserIsModerator(value) {
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)               
            }
        },
      SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {
        document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
     if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.getBlogInformation()
         this.UserRecord = this.userLoggedIn
      }
      else{
        if(this.$route.name === 'GroupBlog'){
            this.InternalLinkType = 'Group-Blog/'+this.$route.params.id+'/Blog'
            this.SharedCollection = 'groupblogs'
            this.LinkComponent = 'GroupBlogShared'
            this.LinkID = this.$route.params.slug
            if(this.ActiveGroup){
            this.GetGroup() 
            }          
          }
          else{
            this.InternalLinkType = 'Blog'
            this.SharedCollection = 'siteblogs'
            this.LinkComponent = 'SiteBlogShared'
            this.LinkID = this.$route.params.id
          }
          if(this.userLoggedIn){ 
          this.UserRecord = this.userLoggedIn
          this.FocusedViewToggle()
          this.IntranetViewToggle()
          this.getBlogInformation()
          }
          else{
            this.getPublicBlogInformation()
          }
      }
      
        
        
        
    },
    methods: {
      ConsumeTokens(amount){
        //so the question is, UserMarketAccount apply in here at all?
        //so I don't mind it not ebing ehre. It's not a signup. but...if it could be B2B of course it would need to be. B2B would need 2 b :P
        let vm = this
        let ref = db.collection('tokentransactions').doc()
        let newdocid = ref.id
        console.log('let us consume tokens')
         let transaction = {
          id: newdocid,
          Amount: amount,
          DebitedAccount: {Client_Reference: this.UserMarketAccount.Client_Reference,Customer_ID: this.UserMarketAccount.Customer_ID,id: this.UserMarketAccount.id,Account_Name: this.UserMarketAccount.Account_Name},
          DebitedAccountid: this.UserMarketAccount.id,
          CreditedAccount: this.GroupData.BillingAccount,
          CreditedAccountid: this.GroupData.BillingAccountid,
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          ROE: Number(this.System.Credit_Tokens_ROE),
          Date: new Date(),
          Type: {ID: 1000003, Name: 'Group Content Purchase'},
          Group: {id: this.$route.params.id,name: this.GroupData.name},
          Groupid: this.$route.params.id,    
          User: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},          
          Userid: this.userLoggedIn.id, 
          Royalty_Percentage: this.System.Base_App_Royalties  
             
        }
        transaction.Content = {
            Type: 'Group Blog',
            Group: transaction.Group,
            Groupid: transaction.Groupid,
            Date: new Date(),
            Documentid: newdocid,
            Contentid: this.$route.params.slug,
            ContentTitle: this.FullArticleData.title
          },
        transaction.Contentid = this.$route.params.slug
        console.log(transaction)
        // db.collection('tokentransactions').add(transaction)
          let invpayload = {
          Data: transaction,
          siteid: vm.$store.state.ActiveSuiteid
          }
          vm.$store.commit('setCustomProcessingDialogText','Processing')
          vm.$store.commit('setCustomProcessingDialog',true)
          const functions = firebase.functions();
          const ConsumeCreditTokens = functions.httpsCallable('ConsumeCreditTokens');
          ConsumeCreditTokens(invpayload).then(result => {
           vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
            setTimeout(() => {
              vm.$store.commit('setCustomProcessingDialog',false)
            }, 1000);
          })
        
      },
      ProcesstokenPurchase(){
        setTimeout(() => {
          this.$store.commit('setCustomProcessingDialog',false)
        }, 50);
           //types = [{ID: 1000001, Name: 'User Token Purchase'},{ID: 1000002, Name: 'Site Content Purchase'},
        //{ID: 1000003, Name: 'Group Content Purchase'},{ID: 1000004, Name: 'Group Token Transfer'},{ID: 1000005, Name: 'Token Withdrawal'}]
        //okay so this would actually geenrate invoice using market dialog.
        //invoice on paid would take care of the credits being passed, but needs this information being ROE and all that jazz
        //now let's not conflict with Percentage and Original Amount please, still need those for tax purposes right? So 
        //Token_Amount and Token_ROE applies
        
        //the major challenge here is that we don't ahve half the crap we need, we create the ba witht he address now, no longer the "group"
        //mainly just 1.ensure create of group stores address on group as well as account and 2. ensure the account name is there as well. uness happy with group name

        let total = 3
        let roe = Number(this.System.Credit_Tokens_ROE)
        let finalroe = roe
        let credittoken =  {
          Active: true,
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          Consuming_Records: [],
          ROE: finalroe,
          Date: new Date(),
          Type: {ID: 1000001, Name: 'User Token Purchase'},
          Remaining_Tokens: total,
          Amount: total,
          //okay so this is the original tokentransactions record id but we cheat it in so
          Documentid: 'mockupinv0001 - btw no BA',
          User: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},          
          Userid: this.userLoggedIn.id,
        }
        console.log(credittoken)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.UserMarketAccount.id).collection('credittokens').add(credittoken)
      },
      ActivateTokenPurchaseDialog(){     
        let vm = this
         if(!this.UserMarketAccount && this.AppMonetizeBU){
           vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
           vm.$store.commit('setCustomProcessingDialog',true)
           const functions = firebase.functions();
           const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
           let payload = {
             Data: vm.userLoggedIn,
             AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
           }
           CreateMarketAccount(payload).then(result => {
             console.log(result)
             vm.$store.commit('setCustomProcessingDialogText','Done Creating')
             vm.ProcesstokenPurchase()
           })
         }
         else{
           vm.ProcesstokenPurchase()
         }
        console.log('definitley going to pass app notification push token packages etc. refer EmitMarketDialog()')
        let transaction = {
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          ROE: Number(this.System.Credit_Tokens_ROE),
          Date: new Date(),
          Type: {ID: 1000001, Name: 'User Token Purchase'},
          
        }
        },
      SelectGroupPackageandSignup(pack){
        console.log(pack)
        let vm = this
        const GroupMember = { 
          Subscription_Package: {Name: pack.Name,id: pack.id},
          Subscription_Packageid: pack.id,
          Userid: vm.userLoggedIn.id,
          Groupid: this.$route.params.id,
          Follower: true,
          Administrator: true,
          BlogsModerator: false,
          ArticlesModerator: false,
          EventsModerator: false,
          PollsModerator: false,
          MeetupsModerator: false,
          ForumsModerator: false,
          UserRole: 'Member',
          Status: 'Active',
          GroupPrivacy: vm.GroupData.GroupPrivacy,
          UserName: vm.userLoggedIn.Full_Name
          }
          console.log(GroupMember)
          GroupMember.MembershipDate = new Date()
              db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(vm.userLoggedIn.id).collection('groups').doc(GroupMember.Groupid).set(GroupMember).then(doc => {
                this.CloseMarketDialog()
              })
        
      },
      CloseMarketDialog(){
        this.$store.commit('EmitMarketDialogObject','')
        this.$store.commit('EmitMarketDialogOptions','')
        this.$store.commit('EmitMarketDialogType','')
        this.$store.commit('EmitMarketDialogDescription','')
        this.$store.commit('EmitMarketDialogTitle','')        
        this.$store.commit('EmitMarketDialogAvatar','')
        this.$store.commit('EmitMarketDialogInteractMethod','')
        this.$store.commit('EmitMarketDialog',false)
      },
      EmitMarketDialog(method,prop){
        this.$store.commit('EmitMarketDialogObject',this.GroupData)
        this.$store.commit('EmitMarketDialogOptions',this.GroupSubscriptionPackages)
        this.$store.commit('EmitMarketDialogType','Group Membership')
        this.$store.commit('EmitMarketDialogDescription',`<p>Below are the packages avilable to choose from, when joining `+this.GroupData.name+`</p>`)
        this.$store.commit('EmitMarketDialogTitle',this.GroupData.name+' Packages')        
        this.$store.commit('EmitMarketDialogAvatar',this.GroupData.logo)
        this.$store.commit('EmitMarketDialogInteractMethod','SelectPackage')
        this.$store.commit('EmitMarketDialog',true)
      },
      smart_substr(str, len) {
            var total = str
            // var temp = str.substr(0, len);
            // if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
            //     temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
            // }
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.LinkID,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this[this.NoticeCoverIMGProp]
        }      
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      StopEdit(){
        this.EditingBlog = false
        this.ActivateProcessing(false)
      },
      EditItem(){
        this.EditingBlog = true
      },
      
      CheckStatus(status){
        if(status === 'Draft'){
          confirm('this will update the Blog for all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Internal)'){
          confirm('this will make the Blog avialable for viewing by all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Public)'){
          confirm('this will make the Blog available for viewing by the Public. Do you want to continue?') && this.UpdateStatus(status)
        }
      },
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
      UpdateStatus(status){
          let docid = ''
          if(this.$route.name === 'GroupBlog'){
            docid = this.$route.params.slug
          }
          else{
            docid = this.$route.params.id
          }
          let PriorStatus = this.PublishStatus
        this.ContentColPath.doc(docid).update({
          PublishStatus: status,
          PublicURL: encodeURI(this.title.split(' ').join('-').split('?').join('')),
          content: this.blogcontent
        })
        let wikipost = {
            tags: [],
            title: this.title,
            UpdatingUser: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            UpdatingUserid: this.UserRecord.id,
            author: this.author,
            authorid: this.authorid,
            content: this.blogcontent,
            caption: this.caption,
            updatedon: new Date(),
            pubDate: this.DateFormatter(this.pubdatastring),
            Comments: [],
            ImageShy: this.ImageShy,
            PublishStatus: status,
            PublicURL: this.title.split(' ').join('-')
          }
        if(status === 'Published (Internal)'){
          this.CreateSystemActivity(this.title,docid,'published a Public Blog','New Blog')
        }
        else if(status === 'Published (Public)' && this.coverimage || status === 'Published (Public)' && this.image){
          wikipost.coverimage = this.image
          this.ContentPublicColPath.doc(docid).set(wikipost)   
          if(this.FullArticleData.Category){
             this.ContentPublicColPath.doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }  
          this.CreateSystemActivity(this.title,docid,'published a Public Blog','New Blog') 
        }
        else if(status === 'Published (Public)' && !this.coverimage || status === 'Published (Public)' && !this.image){
          this.ContentPublicColPath.doc(docid).set(wikipost)   
          if(this.FullArticleData.Category){
             this.ContentPublicColPath.doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }  
          this.CreateSystemActivity(this.title,docid,'published a Public Blog','New Blog')   
        }
        else if(PriorStatus === 'Published (Public)'){
           this.ContentPublicColPath.doc(docid).update({
              PublishStatus: status,
            })
            if(this.FullArticleData.Category){
             this.ContentPublicColPath.doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }
        }
      },
      CreateSystemActivity(docname,docid,contentvar,sysacttype){
      const systemactivity = {
              user: this.UserRecord,
              contentvar: contentvar,
              location: '/Blog/',
              docname: docname,
              docid: docid,
              type: sysacttype,
            }                          
            this.$store.dispatch('createSystemActivity',systemactivity)
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetGroup(){
          this.GroupData = this.ActiveGroup
           if(this.userLoggedIn){
            }
        },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
        addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },

    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'blogcontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        //console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
            UserBlogrelation(){
              // console.log('checking rleation to blogs')
              // console.log(this.authorid)
              // console.log(this.Moderators)
              // console.log(this.UserRecord)
                if(this.authorid === this.UserRecord.id) {
            this.UserCanEdit = true
            this.IsAuthor = true
            
            this.UserIsNOTModerator = false
                }
                else {
            this.UserCanEdit = false
            let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
            if(UserModeratorobj){
              let vm = this
              let UserModeratorobj = vm.ComputedSiteModerators.find(obj => obj.id == vm.UserRecord.id)
                if(UserModeratorobj){
                  if(UserModeratorobj.ModerationPermissions.includes('Public Blogs')){
                    vm.UserIsPublicModerator = true
                  }
                  
                }
              this.UserIsNOTModerator = false
            }
                }
            },
        UpdateBlog(){
          console.log(this.editor.scrollingContainer.innerHTML)

            const UpdatedBlog = {
                content: this.editor.scrollingContainer.innerHTML
            }

            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupblogs').doc(this.blogid).update({
                content: UpdatedBlog.content
            });
              this.snackbar = true
            this.blogquil = false
        },
                UpdateBlogSettings(){


            const UpdatedBlog = {
                PublishStatus: this.editedItem.PublishStatus,
                BlogPrivacy: this.editedItem.BlogPrivacy
            }

            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupblogs').doc(this.blogid).update({
                PublishStatus: UpdatedBlog.PublishStatus,
                BlogPrivacy: UpdatedBlog.BlogPrivacy
            });
              this.snackbar = true
            this.blogquil = false
        },
        onEditorChange() {
// alert('change')
        },
        getPublicBlogInformation(){
          let vm = this
          let query = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
          let splitat = 2
          if(this.$route.name === 'GroupBlog'){
           query = this.ContentColPath.where('groupid','==',this.$route.params.id)
           splitat = 4
          }
          else{
            query = query.collection('siteblogswiki')
          }
          return new Promise(function(resolve, reject) {
        query.where('PublishStatus','==','Published (Public)').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.PublicBlogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Items')
        })
        }).then(function(result) {
          let blogcollection = db.collection('siteblogswiki').where('PublishStatus','==','Published (Public)')
          
          blogcollection.onSnapshot(snapshot => {
                  // this.UpdateRequestStatus()
                  var blogdata = vm.PublicBlogs.find(obj => obj.PublicURL == window.location.pathname.split('/')[splitat])
                console.log(blogdata)
              
                vm.title = blogdata.title
                vm.content = blogdata.content
                vm.blogcontent = blogdata.content
                vm.author = blogdata.author
                vm.authorid = blogdata.authorid
                vm.PubStatus = blogdata.PublishStatus
                vm.ImageShy = blogdata.ImageShy
                vm.image = blogdata.coverimage
                vm.createdon = blogdata.createdon
                vm.pubdatastring = format(blogdata.pubDate.toDate(),'yyyy-MM-dd')
                vm.blogpubDate = vm.TimestampFormatterSTRINGDayText(blogdata.pubDate.toDate())
                vm.PublishStatus = blogdata.PublishStatus
                vm.BlogPrivacy = blogdata.BlogPrivacy
                vm.editedItem.BlogPrivacy = blogdata.BlogPrivacy
                vm.editedItem.PublishStatus = blogdata.PublishStatus
                })

        })
        },
    GetSocialComments(docid){
          this.ContentRootPath.collection('socialsitecomments').where('relatedid','==',docid).onSnapshot(res => {
            if(!this.FullArticleData.Comments){
             this.FullArticleData.Comments = [] 
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  Replies: [],
                  id: change.doc.id
                }
                this.GetSocialLike(commentobj.id,commentobj)
                let oncheck = this.FullArticleData.Comments.find(obj => obj.id === commentobj.id)
                if(!oncheck){
                    this.FullArticleData.Comments.push(commentobj)
                  }
                this.ContentRootPath.collection('socialsitereplies').where('relatedid','==',commentobj.id).onSnapshot(repliesres => {
                    const replychanges = repliesres.docChanges();
                    replychanges.forEach(repchange => {
                    if (repchange.type === 'added') {
                      let replyobj = {
                        ...repchange.doc.data(),
                          Replies: [],
                          id: repchange.doc.id
                        }
                        this.GetSocialLike(replyobj.id,replyobj)
                        let reponcheck = commentobj.Replies.find(obj => obj.id === replyobj.id)
                        if(!reponcheck){
                         commentobj.Replies.push(replyobj) 
                        }
                      }
                    })
                })
                
              }
            })
          })
        },
    GetSocialLike(docid,docobj){
      this.ContentRootPath.collection('socialsitelikes').where('relatedid','==',docid).onSnapshot(res => {
            if(!docobj.Likes){
              docobj.Likes = []
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                 let oncheck = docobj.Likes.find(obj => obj.id === commentobj.id)
                  if(!oncheck){
                    docobj.Likes.push(commentobj)
                  } 
               
              }
            })
          })
    },
    async getBlogInformation() {
        let blogcollection = this.ContentColPath
        let docid = this.$route.params.id
        if(this.$route.name === 'GroupBlog'){
          docid = this.$route.params.slug
        }
        else if(this.$store.state.TemplateView){
          docid = this.$route.params.slug
        }
        let docref = blogcollection.doc(docid)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var blogdata = snapshot.data()
          //console.log('blogdata',blogdata)
          this.FullArticleData = blogdata
          this.FullArticleData.id = docid
          this.$emit('PushRecordName',this.FullArticleData.title)
          if(!this.$store.state.TemplateView){
          this.GetSocialComments(docid)
          this.GetSocialLike(docid,this.FullArticleData)
          }
        //console.log(blogdata)
        if(blogdata.caption){
          this.caption = blogdata.caption
        }
        this.Moderators = blogdata.moderatorrolesarrayQuery
        if(blogdata.GuestsIncluded){
          this.GuestsIncluded = blogdata.GuestsIncluded
        }
        else{
          this.GuestsIncluded = false
        }
        this.title = blogdata.title
        this.content = blogdata.content
        this.blogcontent = blogdata.description
        this.author = blogdata.author
        this.authorid = blogdata.authorid
        this.PubStatus = blogdata.PublishStatus
        if(typeof blogdata.ImageShy !== 'undefined'){
          this.ImageShy = blogdata.ImageShy
        }
        if(typeof blogdata.coverimage !== 'undefined'){
          this.image = blogdata.coverimage
        }
        this.createdon = blogdata.createdon
        this.pubdatastring = format(blogdata.PublishDate.toDate(),'yyyy-MM-dd')
        this.blogpubDate = this.TimestampFormatterSTRINGDayText(blogdata.PublishDate.toDate())
        this.PublishStatus = blogdata.PublishStatus
        this.BlogPrivacy = blogdata.BlogPrivacy
        this.editedItem.BlogPrivacy = blogdata.BlogPrivacy
        this.editedItem.PublishStatus = blogdata.PublishStatus
      // console.log('blog id is '+this.blogid)
      //   console.log('user id is '+this.UserRecord.id)
      //   console.log('author id is '+this.authorid)
      if(!this.$store.state.TemplateView){
        this.UserBlogrelation()
      }
        },
        error => {
          if(error.code === 'permission-denied'){
            if(!this.userLoggedIn){
              this.$router.push('/404')  
            }
            else{
              //this.$router.push('/PermissionError')
            }            
          }
        })
    
        },
      OpenBlogdialog() {
        this.blogdialog = true
    },
    Closedialog() {
        this.blogdialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
     save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.BlogPrivacy = this.editedItem.BlogPrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      },
      
   
     saveBlogSettings() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.BlogPrivacy = this.editedItem.BlogPrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('groupblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {
align-content: center;
margin-left: 0.5em
}
    
</style>
    

    