<template>
  <div style="padding: 20px;">
    <v-dialog v-model="PublishDialog" max-width="600" persistent>
        <v-card height="100%"
          class="mx-auto"
        >
        <v-card-title class="text-h6 font-weight-regular justify-space-between">
          Advert Publishing<v-btn color="red" absolute right top @click="ClosePublishDialog()" class="soloactionicon" icon>
            <v-icon>mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle style="padding-top: 10px;">
          Your Advert has been created. Now give people a landing page.
        </v-card-subtitle>
          <v-divider></v-divider>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-title class="justify-end subtleoverline">          
                Publish Status - <v-chip small>{{ActiveProduct.PublishStatus}}</v-chip>
        </v-card-title>
        <v-card-text class="justify-center">
                <div v-if="CategoryPlugin === 'Featured_Members' && ActiveProduct && HasFeatMem">
                  <v-card rounded>
                    <v-img :height="200" contain :src="ActiveProduct.IMG ? ActiveProduct.IMG : 
                        System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')">
                          </v-img>
                          <v-card-subtitle class="subtleoverline" style="font-weight: bold;">
                            {{ActiveProduct.Title}}
                          </v-card-subtitle>      
                        <v-card-text >
                          <v-card-subtitle class="caption" v-html="ActiveProduct.Description">
                          </v-card-subtitle>
                      </v-card-text>
                  </v-card>
                  <v-row class="justify-center" style="margin-top: 15px;">
                    <v-btn :to="'/FeaturedMember/'+ActiveProduct.Ownerid+'/Home'">
                    View Page
                    </v-btn>
                  </v-row>
                  </div>
                  <v-row class="justify-center" v-if="CategoryPlugin === 'Featured_Members' && !HasFeatMem">
                    <!-- CheckandAdvanceFeaturedMemberAdvert -->
                    <!-- Buddy you need FM FeaturedMemberEditor applies -->
                    <v-btn :to="'/FeaturedMemberCreator/'+userLoggedIn.id">Create Site</v-btn>
                  </v-row>
                     <div style="padding-top:15px;"> 
                      <!-- was  v-if="CategoryPlugin !== 'Featured_Members'" -->
                <v-text-field :disabled="LinkDisabled" outlined dense placeholder="Link"
                  :label="LinkLabel" prepend-icon="mdi-link"
                  v-model="ActiveProduct.Link" :rules="[$store.state.formrules.required,$store.state.formrules.url]"/>
                  <v-select @change="ToggleProcessQuickSave()" outlined dense return-object placeholder="Select the link type from the options" item-text="Name"
                  label="Page Link Type" v-model="ActiveProduct.LinkType" :items="PageLinkTypes"/>
                </div>
                  <v-select class="red" :disabled="!userIsAdmin" outlined dense placeholder="Insert the Desired Key"
                  v-if="userIsAdmin" label="Key" v-model="SecretProp" :items="['Test_Secret_Key','Live_Secret_Key']"/>
              
              </v-card-text>
      </v-window-item>
       <v-window-item :value="2">
        <v-card-text v-if="SignupPackageDialog">
          <v-layout row class="justify-center" :style="MiniView ? 'padding:15px;padding-bottom: 80px' : 'padding: 15px;padding-bottom: 80px'">
                <SignupPackages :System="System" :SystemEntities="SystemEntities" :ViewOnly="false"
          :SignUpLabel="'Select'" @SignupwithPackage="SignupwithPackage" :FullWidth="true" :Shorter="true"
        :SubscriptionPackages="CategorizedAdvertPackages"/>
          </v-layout>
              </v-card-text>
              <v-card-text v-if="NeedsPage">               
                  <v-card width="100%" >
                    <v-btn color="orange" width="100%"  v-if="ActiveProduct.SystemTicketid">
                    Logged Ticket ({{ActiveProduct.SystemTicketid}})
                  </v-btn>                  
                    <SystemTicketsLogging :SystemEntities="SystemEntities" :db="ClientDB"
                    :AutoTicket="AutoTicket" v-if="!ActiveProduct.SystemTicketid"
                    @CancelSubmitSystemTicket="CancelSubmitSystemTicket" @ProvideTicket="CloseAdvertTicket"
                    :UserRecord="userLoggedIn" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
                    </v-card>
              </v-card-text>
      </v-window-item>
    </v-window>
    <v-card-actions>
      <v-btn
        :disabled="step === 1"
        text
        @click="step--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="step === 2 || ActiveProduct.PublishStatus === 'Published'"
        color="primary"
        depressed
        @click="AdvanceStep()"
      >
        Next
      </v-btn>
    </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <v-dialog class="white" v-model="SignupPackageDialog" fullscreen>
        <SignupPackages v-if="SignupPackageDialog" :System="System" :SystemEntities="SystemEntities" :ViewOnly="false"
          :SignUpLabel="'Select'" @SignupwithPackage="SignupwithPackage"
        :SubscriptionPackages="CategorizedAdvertPackages"/>
    </v-dialog> -->
    <v-dialog max-width="600"
      v-model="NewAdvertDialog" persistent
    >
    <NewAdvert :System="System" :SystemEntities="SystemEntities" v-if="NewAdvertDialog"
    :SubscriptionPackages="SubscriptionPackages" :AdvertsCollectionRef="AdvertsCollectionRef"
    @CloseandReactivateAdvert="CloseandReactivateAdvert" :Directories="Directories"
    :ActiveProduct="editedProduct" @CancelStoreProduct="CancelStoreProduct" @RefreshMenu="RefreshMenu"
    />
      <!-- <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>New Advert</v-list-item-title>
              <v-list-item-subtitle>Enter Advert Information</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>          
          <v-list-item>
           <v-text-field outlined v-model="editedProduct.Title" label="Title"/>
          </v-list-item>
           <v-list-item>
           <v-select :disabled="!userIsAdmin" outlined v-model="editedProduct.Category" :items="AllProductCategories" item-text="Name"  label="Category"/>
          </v-list-item>
          <v-list-item>
            <v-card-text>
            <ContentEditableField
            :FieldObject="editedProduct" :FieldName="'Description'"
            :FieldValue="editedProduct.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-card-text>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="CancelStoreProduct()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="SaveStoreProduct()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card> -->
      </v-dialog>
      <!-- <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Products for your Suite
      </v-card-title> -->
      <!-- <v-btn @click="DeactivateActivesession()" right style="z-index: 2;" absolute outlined color="red">Exit</v-btn> -->
       <v-card elevation="0" class="siteconfigcard">
        <v-card-title v-if="!ActiveLibTypeComp" class="mediumoverline">
          My Adverts
          <v-spacer></v-spacer>
           <v-btn v-if="!ActiveProduct" outlined
          @click="ActivateNewProductDialog()" >Add New</v-btn>
          
           
      
    <v-btn v-if="ActiveProduct" outlined @click="CancelActiveProduct()">Back
          </v-btn>
          <v-btn v-if="ActiveProduct" outlined @click="PreviewProduct()">{{EditingProduct ? 'Preview' : 'Cancel Preview'}}
          </v-btn>  
        </v-card-title>  
        <v-btn outlined small width="100%" v-if="userIsAdmin && ActiveProduct">
          {{ActiveProduct.id}}
        </v-btn>
        <v-card-title v-if="ActiveProduct && AdvertPackages && AdvertPackages.length > 0" class="mediumoverline">
          <v-btn dark color="success" width="100%"
          v-if="ActiveProduct" @click="ActivePublishDialog()">
          <v-icon>{{ActiveProduct.PublishStatus === 'Published' ? 'mdi-check' : 'mdi-publish'}}</v-icon>
          {{ActiveProduct.PublishStatus === 'Published' ? ActiveProduct.PublishStatus : 'Publish'}}</v-btn> 
        </v-card-title>
        <div v-if="ActiveProduct">
                
          <v-row>
            <v-col cols="12" v-if="!EditingProduct">
              <AdvertDirectorySingle :ActiveProduct="ActiveProduct"
                  :System="System" :SystemEntities="SystemEntities"
                  :EditingProduct="EditingProduct" :SubscriptionPackages="SubscriptionPackages"
              />
            </v-col>
            <v-col cols="12" xl="9" lg="9" md="8" sm="7" v-if="EditingProduct">
              <AdvertDirectoryEdit v-if="EditingProduct" :ActiveProduct="ActiveProduct"
              :System="System" :SystemEntities="SystemEntities" :ProcessQuickSave="ProcessQuickSave"
              @ActivateSnackbar="ActivateSnackbar" :SubscriptionPackages="SubscriptionPackages"
                  :AdvertsCollectionRef="AdvertsCollectionRef"
              />
              
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="5" v-if="EditingProduct">
              <AdvertDirectorySingle :ActiveProduct="ActiveProduct"
                  :System="System" :SystemEntities="SystemEntities"
                  :EditingProduct="EditingProduct" :SubscriptionPackages="SubscriptionPackages"
              />
              <!-- I am however ocnsidering two things, 1) preview single 2) preview in col view, the card etc -->
            </v-col>
          </v-row>         
        </div>
      <v-card-text v-if="!ActiveLibTypeComp && !ActiveProduct">
         <v-list v-if="!OldProductStyle">
          
          
              <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;" @click="ActivateProduct(prod)" v-for="prod in ComputedActiveAdverts" :key="prod.itemObjKey">
                  <v-list-item-avatar  v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="'border-radius: 10px!important;'">
                    <img  style="object-fit: cover;"
                    :src="prod.IMG ? prod.IMG : 
                  System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                    {{prod.Title}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div v-html="prod.Description"/>
                    </v-list-item-subtitle>
                    <v-list-item-title v-if="prod.Price">
                    {{CurrencyFormatter(prod.Price,$store.state.DefaultCurrency.Currency)}}
                    </v-list-item-title>
                  </v-list-item-content>    
                  <v-list-item-action >
                    <v-avatar tile  style="border-radius: 10px!important;" :color="prod.PublishStatus === 'Draft' ? 'red' : 'green'">
                      <v-icon dark>
                        {{prod.PublishStatus === 'Draft' ? 'mdi-pencil' : 'mdi-check'}}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>                
              </v-list-item>
          </v-list>
      </v-card-text>
       </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TableMain from '@/components/Database/TableMain';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import AdvertDirectoryEdit from '@/components/SuitePlugins/AdvertDirectory/AdvertDirectoryEdit';
import AdvertDirectorySingle from '@/components/SuitePlugins/AdvertDirectory/AdvertDirectorySingle';
import NewAdvert from '@/components/SuitePlugins/AdvertDirectory/NewAdvert';
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'	
export default {
    props: ['System','SystemEntities','SubscriptionPackages','UnreadNotifications','Directories'],
    components: {TableMain,ContentEditableField,AdvertDirectoryEdit,AdvertDirectorySingle,NewAdvert,SignupPackages,SystemTicketsLogging},
    data() {
        return {
          step: 1,
          SecretProp: 'Test_Secret_Key',
          ProcessQuickSave: false,
          AutoTicket: {},
          PublishDialog: false,
          LookupObj: {},
          PreparedOrder: {},
          Now: '',
          SelectedPackage: '',
          NewAdvertStep: 1,
          SignupPackageDialog: false,
          importdialog: false,
          EditingProduct: true,
          editedProduct: {
            KeyWords: [],
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Advert Title',    
            PublishStatus: 'Draft'        
          },
          defaulteditedProduct: {
            KeyWords: [],
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Advert Title',
            PublishStatus: 'Draft'
          },
          NewAdvertDialog: false,
          LinkTypes: [
          {ID: 1000001, Name: 'No Link'},
          {ID: 1000002, Name: this.System.Name+' Platform Link'},
          {ID: 1000003, Name: 'External Link'},
          {ID: 1000004, Name: 'Google Maps Location'},
        ],
            ActiveProduct: '',
        }
    },	
    computed:{
      PageLinkTypes(){
        return this.LinkTypes.filter(option => {
          //okay technically it means we allow now Without Link for Organisation. We should not want to do this but...I get it.
          return this.CategoryPlugin !== 'Featured_Members' || option.ID !== 1000001 && option.ID !== 1000004 
        })
      },
      LinkDisabled(){
        let inactiveoptions = [1000001]
        return this.ActiveProduct && this.ActiveProduct.LinkType && inactiveoptions.includes(this.ActiveProduct.LinkType.ID)
      },
      PageLinkTypes(){
        return [
          {ID: 1000001, Name: 'No Link'},
          {ID: 1000002, Name: this.System.Name+' Platform Link'},
          {ID: 1000003, Name: 'External Link'},
          {ID: 1000004, Name: 'Google Maps Location'},
        ].filter(option => {
          //okay technically it means we allow now Without Link for Organisation. We should not want to do this but...I get it.
          return this.CategoryPlugin !== 'Featured_Members' || option.ID !== 1000001 && option.ID !== 1000004 
        })
      },
      LinkLabel(){
        return this.ActiveProduct && this.ActiveProduct.LinkType ? this.ActiveProduct.LinkType.Name : 'Type in Link'
        },
      HasFeatMem(){
        return this.UserFeaturedMemberSnap && this.UserFeaturedMemberSnap.Full_Name
      },
      UserFeaturedMemberSnap(){
        return this.$store.state.UserFeaturedMemberSnap
      },
      ClientDB(){
        return db
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
      RelatedObj(){
        return this.ActiveProduct ? 
        {id: this.ActiveProduct.id, Title: this.ActiveProduct.Title,identifier: 'Title',collection: 'adverts' } : 
        {}
      },
      NeedsPage(){
        let needpageoptions = [1000002]
        return this.ActiveProduct && this.ActiveProduct.LinkType && needpageoptions.includes(this.ActiveProduct.LinkType.ID)
      },
      OwnerObj(){
        let user = this.userLoggedIn
        let ownerobj = {          
                Created_By: {Full_Name: user.Full_Name, id: user.id},
                Created_Byid: user.id,
                Owner: {Full_Name: user.Full_Name, id: user.id},
                Ownerid: user.id,
                Created_On: this.Now,
                Modified_By: {id: user.id, Name: user.Name, Surname: user.Surname, Full_Name: user.Full_Name},
                Modified_Byid: user.id,
                Modified_On: this.Now,
        }
        return ownerobj
      },
      AppMonetizeBU(){
          return this.System.Monetization_BU ? this.System.Monetization_BU : {id: 'Head_Office',Name: 'Head Office'}
      },
      SystemLineItems(){
        return this.SelectedPackage ? [
          {
                Nr: 1,
                Name: this.SelectedPackage.Name,
                Description: this.SelectedPackage.Description,
                Price: this.SelectedPackage.Price,
                Qty: 1,
                Site: {
                    Name: this.System.Name,
                    id: this.$store.state.ActiveSuiteid
                }
            }
        ] : []
      },
      YocoLineItems(){
        return this.SelectedPackage ? [
          {displayName: this.SelectedPackage.Name,description: this.SelectedPackage.Description,quantity: 1,pricingDetails: {
              price: this.SelectedPackage.Price*100
            }}
        ] : []
      },
      POSubTotal(){
        return this.SystemLineItems.map(line => {
          return line.Qty*line.Price
        }).reduce((a, b) => a + b, 0)
      },
      TaxPercentage(){
          return this.AppMonetizeBU && typeof this.AppMonetizeBU.Tax_Percentage !== 'undefined' ? this.AppMonetizeBU.Tax_Percentage :0
      },
      TaxTotal(){
        let taxperc = 0
        let total = 0
        if(this.AppMonetizeBU && typeof this.AppMonetizeBU.Tax_Percentage !== 'undefined'){
            taxperc = this.AppMonetizeBU.Tax_Percentage
        }
        if(taxperc > 0){
            total = this.POSubTotal*(taxperc/100)
        }
        return Number(total.toFixed(2))
      },
      GrandTotal(){
          return this.POSubTotal+this.TaxTotal
      },
      Discount(){
        return 0
      },
      DiscountTotal(){
        return this.Discount
      },
      VatExclusiveInvoiceTotal(){
       let total = this.POSubTotal
        if(typeof this.PreparedOrder.Discount_Total === 'number'){
          total = total-this.PreparedOrder.Discount_Total
        }
        return Number(total.toFixed(2))
      },
      ActiveBillingSystem(){
        return this.IsPrimarySite ? this.$store.state.PrimarySystem : this.$store.state.PrimarySystem
        //until mini sites can monetize keep as is, but at that point becomes SiteSystem for non primary
      },
      UserLookupObj(){
        return {
                    id: this.userLoggedIn.id,
                    Full_Name: this.userLoggedIn.Full_Name,
                    Name: this.userLoggedIn.Name,
                    Surname: this.userLoggedIn.Surname
                }
      },
      PreparedInvoice(){
        let obj = {
          ...this.OwnerObj,
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          Date_Received: this.Now,
          Sub_Total: this.POSubTotal,
          Tax_Total: this.TaxTotal,
          Tax_Percentage: this.TaxPercentage,
          Freight_Total: 0,
          Vat_Exclusive_Total: this.VatExclusiveInvoiceTotal,
          Invoice_Total: this.VatExclusiveInvoiceTotal,
          Grand_Total: this.GrandTotal,
          Invoice_Type: {ID: 1000002,Name: 'Subscription Order'},
          ReferenceProp: 'Invoice_Number',
          Subscription_Package: {
          id: this.SelectedPackage.id,Name: this.SelectedPackage.Name,Price: this.SelectedPackage.Price,Date: this.Now,Client: this.PreparedOrder.Client,Clientid: this.PreparedOrder.Clientid,
          Order_Number: this.NewSubOrderid,User: this.PreparedOrder.Client,Userid: this.PreparedOrder.Clientid,BillingAccount: this.PreparedOrder.BillingAccount,BillingAccountid: this.PreparedOrder.BillingAccountid,
          Progress: this.PreparedOrder.Progress, Type: this.SelectedPackage.Type, Document_Type: {ID: 1000002,Name: 'Subscription Order'},Site: this.PreparedOrder.Site,Siteid: this.PreparedOrder.Siteid,
          Total_Invoiced: this.PreparedOrder.Grand_Total,Total_Paid: 0,Active: true}
        }
        if(this.SelectedPackage){
          obj.Price = this.SelectedPackage.Price
        }
        return obj
      },
      CategorizedAdvertPackages(){
        return this.ActiveProduct ? this.AdvertPackages.filter(pack => {
          return !pack.CategoryFilter || 
          pack.CategoryFilter && this.ActiveProduct.Product_Group && this.ActiveProduct.Product_Group.ID === pack.CategoryFilter.ID || 
          pack.CategoryFilter && this.ActiveProduct.Product_Class && this.ActiveProduct.Product_Class.ID === pack.CategoryFilter.ID || 
          pack.CategoryFilter && this.ActiveProduct.Product_Type && this.ActiveProduct.Product_Type.ID === pack.CategoryFilter.ID
        }) : this.AdvertPackages
      },
      AdvertPackages(){
        return this.SubscriptionPackages.filter(pack => {
          return pack.Type && pack.Type.Name === 'Advert Package'
        })
      },
      UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
      },
      UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
      AllProductCategories(){
        return this.ProductsCatsLevel3.concat(this.ProductsCatsLevel2,this.ProductCategories)
      },
      ProductsCatsLevel3(){
            return this.ProductsCatsLevel2.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductsCatsLevel2(){
            return this.ProductCategories.filter(cat => {
                return cat.Options
            }).map(cat => {
                return cat.Options
            }).flat()
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
      CategoryField(){
        return this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
        this.AdvertDirectory.CategoryField : ''
      },
      AdvertDirectory(){
            return this.$store.state.AdvertDirectory
        },
      ActiveStore(){
        return this.$store.state.ActiveStore
      },
      StoreRef(){
        return db.collection('Stores').doc(this.$store.state.ActiveSuiteid)
      },
      CategoriesRef(){
        return this.StoreRef.collection('productcategories')
      },
      ProductCollectionRef(){
        return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('adverts')
      },
      AdvertsCollectionRef(){
        return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('adverts')
      },
        ProductTypes(){
            return this.SystemEntities.filter(ent => {
                return ent.Entity_Type.Name === 'Inventory Article'
            })
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        MySiteAdverts(){
          return this.$store.state.MySiteAdverts
        },
        ComputedActiveAdverts(){
          return this.MySiteAdverts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      CategoryPlugin(){
          return this.ActiveProduct && this.ActiveProduct.Product_Group ? this.ActiveProduct.Product_Group.Pluginid : ''
        },
        AutoTicketTitle(){
          return this.CategoryPlugin === 'Site_Events' ? this.ActiveProduct.Title+' - Landing Page' : 
              this.CategoryPlugin === 'Featured_Members' ? this.ActiveProduct.Title+' - FM Page' : this.ActiveProduct.Title+' Business Page'
        },
    },
    watch: {
      NeedsPage(v){   
          if(v){
            this.AutoTicket = {
              Title: this.AutoTicketTitle,
              Description: 'I need a page for my "'+this.ActiveProduct.Product_Group.Name.toUpperCase()+'" Advert "'+this.ActiveProduct.Title+'" ('+this.ActiveProduct.id+').',
              Priority: {ID: 1000003, Name: 'High',Color: 'red'}
            }
            // this.PageRequest = {
            //   ...this.OwnerObj,
            //   Created_On: new Date(),
            //   Modified_On: new Date()
            // }
            //TicketDialog
          }
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignAdvertIMG','SignupwithPackage']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.ComputedActiveAdverts.length === 0){
              this.ActivateNewProductDialog()
      }
      
    },
    methods:{
      CheckandAdvanceFeaturedMemberAdvert(){
        if(this.CategoryPlugin === 'Featured_Members' && this.HasFeatMem){

        }
      },
      AdvanceStep(){
        if(this.step === 1){
          if(!this.NeedsPage){
            this.ConfirmPackage()
          }
          this.step++
        }
        else{
          this.step++
        }
      },
      ClosePublishDialog(){
        this.PublishDialog = false
      },
      ToggleProcessQuickSave(){
        this.ProcessQuickSave = true
            setTimeout(() => {
              this.ProcessQuickSave = false
            }, 30);
      },
      ActivePublishDialog(){
        this.ToggleProcessQuickSave()
        //this.ConfirmPackage()
        this.PublishDialog = true
      },
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      SignupwithPackage(pack){
        if(this.CategoryPlugin === 'Featured_Members' && !this.UserFeaturedMemberSnap.Published){
          let path = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.ActiveProduct.Ownerid)
          path.update({
            Published: true
          })
        }
        this.SelectedPackage = pack
        this.SignupPackageDialog = false
        this.NewAdvertStep++
        // if(pack.Price === 0){
        //   this.BillingAccount
        //   //???
        // }
        this.LookupObj = {
          id: this.ActiveProduct.id,
          identifier: 'Title',
          BillingAccount: {Client_Reference: this.UserMarketAccount.Client_Reference,Customer_ID: this.UserMarketAccount.Customer_ID,id: this.UserMarketAccount.id,Account_Name: this.UserMarketAccount.Account_Name},
          BillingAccountid: this.UserMarketAccount.id,
          Title: this.ActiveProduct.Title,
          Name: this.ActiveProduct.Title,                  
          Owner: this.ActiveProduct.Owner,
          Ownerid: this.ActiveProduct.Ownerid,
          list: 'Adverts',
          Siteid: this.$store.state.ActiveSuiteid,
          Key_Prop: this.SecretProp
        }
        this.PreparedOrder = {
          Active: true,//unless subscirption type is for life whwatever still true right?
          BillingAccount: this.LookupObj.BillingAccount,
          BillingAccountid: this.LookupObj.BillingAccountid,
          Client: this.UserLookupObj, 
          Clientid: this.UserLookupObj.id,
          Date: this.Now,
          Document_Type: {ID: 1000002,Name: 'Subscription Order'},
          Name: this.SelectedPackage.Name,
          Order_Number: '',//**Fix on Checkout payment receipt on actual create */
          Packageid: this.SelectedPackage.id,
          Discount_Total: this.DiscountTotal,
          Price: this.VatExclusiveInvoiceTotal,
          Progress: {ID: 1000003,Name: 'Paid'},
          Type: {ID: 1000012, Name: 'Advert Subscription'},
          User: this.UserLookupObj,
          Userid: this.UserLookupObj.id,
          LookupObj: this.LookupObj
        }
        //console.log(this.SelectedPackage,this.PreparedInvoice)
        this.ReturnYocoCheckoutRequest()
        this.ScrolltoTop()
      },
      ConfirmPackage(){
        //1. bring up the package dialog, like showcase teh fucking thing
        this.SignupPackageDialog = true
        //2. on select a) create salesinvoice and suborder b) proceeed yoco checkout
        //3. pas to checkout all needed props all handled in back by funciton
      },
      ReturnYocoCheckoutRequest(){
        this.Now = new Date()
        let advert = JSON.parse(JSON.stringify(this.ActiveProduct))
        let paymentpayload = {
          currency: 'ZAR',
          cancelUrl: this.System.ClientAppURL+'/'+this.$route.path,
          lineItems: this.YocoLineItems,
          metadata: {LookupObj: this.LookupObj,Userid: this.userLoggedIn.id,Advert: advert,
          Invoice: this.PreparedInvoice, Order: this.PreparedOrder,Line_Items: this.SystemLineItems},
          Key_Prop: this.SecretProp,
        }
        paymentpayload.amount = paymentpayload.lineItems.map(line => {
          return line.quantity*line.pricingDetails.price
        }).reduce((a, b) => a + b, 0)
        //see more checkout body props at https://developer.yoco.com/online/api-reference/core-resources/checkout-object
        //console.log(paymentpayload)
        let payload = {
          url: 'https://payments.yoco.com/api/checkouts',
          siteid: this.$store.state.ActiveSuiteid,
          paymentpayload: paymentpayload        
        }
        const functions = firebase.functions();
        const ReturnYocoCheckoutRequest = functions.httpsCallable('ReturnYocoCheckoutRequest');
        //console.log(payload)
        ReturnYocoCheckoutRequest(payload).then(result => {
          //console.log(result)
          //push to result.redirectUrl
          this.CancelStoreProduct()
          //but reopen paid product? only if paid so...maybe not right?
          window.open(result.data.redirectUrl)
        })
      },
      RegisterYocoWebhook(){
        let bodypayload = {
          name: this.System.Name.split(' ').join('-')+'-Test-Webhook',
          url: 'https://us-central1-business-and-people.cloudfunctions.net/ReceiveYocoPayments'
        }
        
        console.log(bodypayload)
        let payload = {
          url: 'https://payments.yoco.com/api/webhooks',
          siteid: this.$store.state.ActiveSuiteid,
          bodypayload: bodypayload,
          Key_Prop: 'Live_Secret_Key'    
        }
        const functions = firebase.functions();
        const RegisterYocoWebhook = functions.httpsCallable('RegisterYocoWebhook');
        RegisterYocoWebhook(payload).then(result => {
          console.log(result)
          //push to result.redirectUrl
          //window.open(result.data.redirectUrl)
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('APISecrets').doc('Yoco').update({
            [payload.Key_Prop+'WebHook']: result.data
          })
        })
      },
      ActivateNewProductDialog(){
        this.Now = new Date()
        let user = this.userLoggedIn
        let ownerobj = this.OwnerObj
        this.editedProduct = Object.assign({},{...ownerobj,...this.defaulteditedProduct})
        this.NewAdvertDialog = true
        this.ActivateProduct(this.editedProduct)
      },
      ActivateImportTableDialog() {
      this.importdialog = true;
    },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      CancelActiveProduct(){
        this.ActiveProduct = ''
        this.$store.commit('setActiveAdvert','')
        this.EditingProduct = true
      },
      DeactivateActivesession(){
        this.$emit('DeactivateActivesession')
      },
      PreviewProduct(){
        this.EditingProduct = !this.EditingProduct
      },
      AssignAdvertIMG(){
        this.ActiveProduct.IMG = this.SelectedImage.fileurl
        this.ActiveProduct.IMGStorageRef = this.SelectedImage.StorageRef
        this.ActiveProduct.IMGFile = this.SelectedImage
        if(!this.NewAdvertDialog && this.ActiveProduct.id){
            this.AdvertsCollectionRef.doc(this.ActiveProduct.id).update({
                IMG: this.ActiveProduct.IMG
            })
        }
        if(this.NewAdvertDialog){
          this.RefreshMenu()
        }
        this.$store.commit('setIMGProp','')
      },
      RefreshMenu(){
        this.NewAdvertDialog = false
        setTimeout(() => {
          this.NewAdvertDialog = true
        }, 10);
      },
      CloseAdvertTicket(){
        this.ClosePublishDialog()
        this.CloseandReactivateAdvert()
      },
      CloseandReactivateAdvert(){
        let prodid = this.ActiveProduct.id
        this.CancelStoreProduct()
        setTimeout(() => {
        let prod = this.ComputedActiveAdverts.find(obj => obj.id === prodid)
            this.ActivateProduct(prod)
        }, 1500);
      },
      CancelStoreProduct(openafter){
        this.NewAdvertDialog = false
        this.editedProduct = Object.assign({},this.defaulteditedProduct)
        // if(openafter){
        //   setTimeout(() => {
        //     this.ActivateNewProductDialog()
        //   }, 500);
        // }
        this.CancelActiveProduct()
      },
      SaveStoreProduct(){
        this.editedProduct.AdditionalOptions = []
        this.AdvertsCollectionRef.add(this.editedProduct).then(newprod => {
          this.CancelStoreProduct(true)
        })
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      ActivateProduct(prod){
        this.ActiveProduct = prod
        this.$store.commit('setActiveAdvert',this.ActiveProduct)
      },
    }
}
</script>

<style>

</style>



