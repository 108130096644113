<template>
  <div>
    <v-dialog v-model="ApplyDialog" max-width="450" >
      <v-card tile v-if="ApplyDialog">
        <v-card-title class="my-1 mediumoverline">
          Apply for {{ActiveVacancy.Title}}
        </v-card-title>
        <v-card-subtitle>
          at {{ActiveVacancy.Company.Company_Name}}
        </v-card-subtitle>
        <v-card-subtitle>
          Write a Motivation for your Application
        </v-card-subtitle>
        <v-card-text class="my-6">
          <ContentEditableField :FieldID="'Application_Motivation'"
            :FieldObject="Application" :FieldName="'Motivation'"
            :FieldValue="Application.Motivation" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-text>
        <v-card-actions>
          <v-btn outlined @click="DeactivateApplyDialog()">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="ApplyforJob()">Apply</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <MemberCompanyDialog v-if="NewCompanyDialog" @SelectCompany="SelectCompany"
            :System="System" :SystemEntities="SystemEntities"
            @DeactivateNewCompanyDialog="DeactivateNewCompanyDialog"
                />
    <v-card tile>
        <v-card-title v-if="Editing && ActiveVacancy.id" class="mediumoverline">
          <v-btn dark color="success" width="100%"
          @click="ConfirmPublish(ActiveVacancy)">
          <v-icon>{{ActiveVacancy.PublishStatus === 'Published' ? 'mdi-check' : 'mdi-publish'}}</v-icon>
          {{ActiveVacancy.PublishStatus === 'Published' ? ActiveVacancy.PublishStatus : 'Publish'}}</v-btn> 
        </v-card-title>
        <v-card-title v-if="!Editing" class="largeoverline">
            {{ActiveVacancy.Title}}
            <v-spacer>
            </v-spacer>
            <v-btn v-if="!VacancySingleRoute" outlined @click="GoBack()">{{QuickView ? 'Close' : 'Back'}}</v-btn>
            <v-btn v-if="CanEdit && !QuickView" outlined @click="Edit()">Edit</v-btn>
            <v-btn v-if="CanEdit&& !QuickView" color="success" outlined @click="SaveVacancy(ActiveVacancy)">Save</v-btn>
        </v-card-title>
        <v-card-title v-if="Editing" class="largeoverline">
           <v-text-field outlined v-model="ActiveVacancy.Title" label="Title"/>
            <v-spacer>
            </v-spacer>
            <v-btn outlined @click="GoBack()">Preview</v-btn>
        </v-card-title>
        <v-card-subtitle>
            <v-list-item v-if="!Editing">
                 <v-list-item-avatar class="white--text" :color="ActiveVacancy.Color" v-if="!ActiveVacancy.Company || ActiveVacancy.Company && !ActiveVacancy.Company.Logo"
                  :size="MiniView ? 25 : 50" tile >
                    {{ActiveVacancy.AvatarTitle}}
                  </v-list-item-avatar>
                  <v-list-item-avatar class="white--text" v-if="ActiveVacancy.Company && ActiveVacancy.Company.Logo" :size="MiniView ? 25 : 50" tile >
                    <img style="object-fit: cover;"
                    :src="ActiveVacancy.Company.Logo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                    {{ActiveVacancy.Title}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ActiveVacancy.Company.Company_Name}}
                    </v-list-item-subtitle>
                     <v-list-item-subtitle>
                      {{ActiveVacancy.Location_City}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="Editing">
                <v-btn v-if="!ActiveVacancy.Company" @click="ActivateNewCompanyDialog()" width="100%"><v-icon>mdi-plus</v-icon> Add Company</v-btn>
           <v-btn v-if="ActiveVacancy.Company" @click="ActivateNewCompanyDialog()" width="100%">{{ActiveVacancy.Company.Company_Name}}</v-btn>
            </v-list-item>
        </v-card-subtitle>
        <v-card-subtitle>
           <v-list-item class="justify-end" v-if="!Editing">
               <v-chip><v-icon>mdi-clock-outline</v-icon>{{ActiveVacancy.PublishAgeing}}
                    </v-chip>
                    <v-chip v-if="ActiveVacancy.Renumeration"><v-icon>mdi-account-cash</v-icon>{{ActiveVacancy.Renumeration}}
                    </v-chip>
                    <v-chip><v-icon>mdi-briefcase</v-icon>{{ActiveVacancy.Employment_Type? ActiveVacancy.Employment_Type.Name : ''}}
                    </v-chip>
              </v-list-item>
              <v-list-item class="justify-end" v-if="Editing">
                <v-select prepend-inner-icon="mdi-briefcase" item-text="Name" :items="EmploymentTypes" return-object v-model="ActiveVacancy.Employment_Type"
                outlined dense/>
                <v-text-field prepend-inner-icon="mdi-account-cash" outlined v-model="ActiveVacancy.Renumeration" label="Renumeration"/>
              </v-list-item>
        </v-card-subtitle>
        <v-card-title >
            Introduction
        </v-card-title>
        <v-card-text>
            <div v-html="ActiveVacancy.Introduction" v-if="!Editing"/>
            <ContentEditableField :FieldID="'Vacancy_Introduction'" v-if="Editing"
            :FieldObject="ActiveVacancy" :FieldName="'Introduction'"
            :FieldValue="ActiveVacancy.Introduction" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-text>
        <v-card-title>
            Duties & Responsibilities
        </v-card-title>
        <v-card-text>
            <div v-html="ActiveVacancy.Duties" v-if="!Editing"/>
            <ContentEditableField :FieldID="'Vacancy_Duties'" v-if="Editing"
            :FieldObject="ActiveVacancy" :FieldName="'Duties'"
            :FieldValue="ActiveVacancy.Duties" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-text>
        <v-card-title>
            Requirements
            <!-- deg if any, yrs exp, methodologies -->
        </v-card-title>
        <v-card-text>
            <div v-html="ActiveVacancy.Requirements" v-if="!Editing"/>
            <ContentEditableField :FieldID="'Vacancy_Requirements'" v-if="Editing"
            :FieldObject="ActiveVacancy" :FieldName="'Requirements'"
            :FieldValue="ActiveVacancy.Requirements" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn :disabled="!UserFeaturedMemberSnap" @click="ActivateApplyDialog()" v-if="!ActiveJobApplication && ActiveVacancy.id">
            Apply for Job
          </v-btn>
          <v-btn disabled v-if="ActiveJobApplication">
            Already Applied on {{ActiveApplicationDate.toISOString()}}
          </v-btn>
        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import MemberCompanyDialog from '@/components/Database/Fields/Hybrid/MemberCompanyDialog'
export default {
    props: ['System','SystemEntities','ActiveVacancy','Editing','QuickView'],
    components: {ContentEditableField,MemberCompanyDialog},
    data() {
        return {
          ApplyDialog: false,
          Application: {
            Motivation: '',
          },
            Now: '',
            NewCompanyDialog: false,
            EmploymentTypes: [
                {ID: 1000001,Name: 'Full Time'},
                {ID: 1000002,Name: 'Part Time'},
                {ID: 1000003,Name: 'Internship'},
                {ID: 1000004,Name: 'Temporary'}
            ]
        }
    },	
    computed:{
      UserFeaturedMemberSnap(){
        return this.$store.state.UserFeaturedMemberSnap
      },
      MyJobApplications(){
        return this.$store.state.MyJobApplications
      },
      ActiveApplicationDate(){
        return this.ActiveJobApplication ? 
        this.ActiveJobApplication.Created_On.toDate() : ''
      },
      ActiveJobApplication(){
        return this.MyJobApplications.find(obj => obj.Vacancyid === this.ActiveVacancy.id)
      },
      CanApplyforJob(){
        //later probably cannot apply is owner of vacancy but anyways
        return !this.ActiveJobApplication
      },
      DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      VacancyObj(){
        return {
          Title: this.ActiveVacancy.Title,
          Employment_Type: this.ActiveVacancy.Employment_Type,
          id: this.ActiveVacancy.id
        }
      },
      UserObj(){
        let user = this.userLoggedIn
        return {          
                Created_By: {Full_Name: user.Full_Name, id: user.id},
                Created_Byid: user.id,
                User: {Full_Name: user.Full_Name, id: user.id},
                Userid: user.id,
                Created_On: this.Now,
                Modified_By: {id: user.id, Name: user.Name, Surname: user.Surname, Full_Name: user.Full_Name},
                Modified_Byid: user.id,
                Modified_On: this.Now,
        }
      },
      VacancySingleRoute(){
        return this.$route.name === 'VacancySingle'
      },
        CanEdit(){
            return this.VacancySingleRoute ? false :
            !this.ActiveVacancy.id || 
            this.ActiveVacancy && this.ActiveVacancy.Ownerid && this.ActiveVacancy.Ownerid === this.userLoggedIn.id || 
            !this.IsGuestUser
        },
        AvatarTitle(){
            let AvatarTitle = ''
            if(this.ActiveVacancy.Title){
             this.ActiveVacancy.Title.split(' ')[0].substr(0,1)
            if(vacobj.Title.split(' ')[1]){
                this.ActiveVacancy.AvatarTitle = this.ActiveVacancy.AvatarTitle+this.ActiveVacancy.Title.split(' ')[1].substr(0,1)
            }    
            }
            return AvatarTitle            
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        DeactivateApplyDialog(){
            this.ApplyDialog = false
            this.Application = {Motivation:''}
        },
        ActivateApplyDialog(){
          this.Application = {Motivation:''}
          this.ApplyDialog = true
        },
        ApplyforJob(){
          this.Now = new Date()
          let jobapp = {...this.Application,Company: this.ActiveVacancy.Company,Companyid: this.ActiveVacancy.Companyid,
          Vacancy: this.ActiveVacancy,Vacancyid: this.ActiveVacancy.id,...this.UserObj}
          //to record "as advertised we don't do this.VacancyObj but this.ActiveVacancy"
          
          //'Characteristics','Hobbies','Highlights','Industries','Skills','Qualifications','Jobs'
          let fmprops = ['Characteristics','Hobbies','Highlights','Industries','Skills','Qualifications','Jobs','Methodologies',
          'Overview','Objectives']
          fmprops.map(prp => {
            if(typeof this.UserFeaturedMemberSnap[prp] !== 'undefined'){
              jobapp[prp] = this.UserFeaturedMemberSnap[prp]
            }
          })
          //okay so we will let you create a job
          //remember this job is going to be repluicated throughout
          //so it must be created just about same way as WEb Form.
          //question - should it not be web form?
          //no need carry on soldier
         // let status = this.CurrentEntityFields.find(obj => obj.id === 'Status')
          let status = this.DefaultStatusField
          let Status = status.DefaultOption
          let Status_Reason = status.DefaultLevel2Option
          jobapp = {...jobapp,Status: Status, Status_Reason: Status_Reason}
          //progress, status
          let dbref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Job_Applications').collection('Records')
          let newdocref = dbref.doc()
          let newdocid = newdocref.id
          //console.log(jobapp,this.UserFeaturedMemberSnap)
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).collection('jobapplications').doc(newdocid).set(jobapp).then(newdoc => {
            this.DeactivateApplyDialog()
          })
        },
        ConfirmPublish(vac){
            this.$emit('ConfirmPublish',vac)
        },
        SaveVacancy(vac){
            this.$emit('SaveVacancy',vac)
        },
      SelectCompany(comp){
            let compbj = {
                Company_Name: comp.Company_Name,
                id: comp.id
            }
            if(comp.Logo){
                compbj.Logo = comp.Logo
            }
            this.ActiveVacancy.Company = compbj
            this.ActiveVacancy.Companyid = compbj.id
            this.DeactivateNewCompanyDialog()
            // this.Refresh()
            // console.log(this.ActiveVacancy)
        },
      ActivateNewCompanyDialog(){
        this.NewCompanyDialog = true
      },
      DeactivateNewCompanyDialog(){
        this.NewCompanyDialog = false
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(FieldObject,prop,value)
    },
        Edit(){
           this.$emit('Edit') 
        },
        GoBack(){
            this.$emit('GoBack')
        }
    }
}
</script>

<style>

</style>



