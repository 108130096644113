<template>
<div>
  <header style="font-size: 1.5em;"><h1 :style="HeaderFontFamily(tab,tab.HeaderFontFamily)">{{tab.Title}}</h1></header>
  <div :style="`background: `+tab.MagazineHighlightColor.hex+`;content: '';display: block;height: 20px;width: auto;`"/>
   <v-row style="padding:20px;" :class="tab.Justify">
   <v-col
      v-for="(elmnt,elmntindex) in TabElements"
      :key="elmnt"
      :cols="Cols(elmnt)"    
      :class="'mx-1 '+ColMY(elmnt)+' '+elmnt.Justify"  
      :style="ColStyle(tab,elmnt)"                      
        >  
        <div  :class="elmnt.NewLine ? 'flex xl'+elmnt.FlexXLRG+' lg'+elmnt.FlexLarge+' md'+elmnt.FlexMedium+' sm'+elmnt.FlexSmall+' xs'+elmnt.FlexXSmall : 'flex'">
        <div :style="GridNrStyle(elmnt)+'z-index: 2;'">{{elmntindex-1+2}}</div>
        <!-- padding: 20px;ElmntGrid(elmnt) -->
        <CardRowMagazineItem :style="'padding: 20px;'" :elmnt="elmnt" :ElmntNumber="elmnt.ElmntNumber" :elmntindex="elmntindex" @OpenDialog="OpenDialog"
        :PageHeadersFont="PageHeadersFont" :tab="tab" :tab-index="tabindex" :PageContentFont="PageContentFont" :PageHeadersFontSize="PageHeadersFontSize"
              />
        </div>
        </v-col>
   </v-row>
</div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";
import CardRowMagazineItem from "@/components/WebPages/RenderComponents/CardRowMagazineItem";
export default {
  props: [
    "System",
    "SystemEntities",
    "tab",
    "PageHeadersFont",
    "PageContentFont",
    "PageHeadersFontSize",
    "tabindex",
  ],
  components: { CardRowMagazineItem },
  data() {
    return {
      WindowHeight: 0,
      WindowWidth: 0,
      cardcarousel: "",
      selectedIndex: 0,
      DialogCardIndex: -1,
    };
  },
  computed: {
    FlexSizes(){
       return this.$store.state.FlexSizes
    },
    TabElements() {
      return this.tab.Elements.map((elmnt, elmntindex) => {
        elmnt.ElmntNumber = elmntindex - 1 + 2;
        //:xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
        if (elmnt.MagType === 'Type 1' || elmnt.MagType === 'Type 3') {
          // elmnt.FlexXLRG = 4;
          // elmnt.FlexLarge = 4;
          // elmnt.FlexMedium = 4;
          // elmnt.FlexSmall = 4;
          // elmnt.FlexXSmall = 12;
        } else {
          // elmnt.FlexXLRG = 6;
          // elmnt.FlexLarge = 6;
          // elmnt.FlexMedium = 6;
          // elmnt.FlexSmall = 6;
          // elmnt.FlexXSmall = 12;
        }

        return elmnt;
      })
    },
    MiniView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    DefaultPageFont() {
      return `font-family: "` + this.PageContentFont + `", sans-serif;`;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    WindowHeight() {
      return window.innerHeight;
    },
    SocialItemInteractMethod() {
      return this.$store.state.SocialItemInteractMethod;
    },
    SocialItemInteractMethodProp() {
      return this.$store.state.SocialItemInteractMethodProp;
    },
  },
  watch: {
    SocialItemInteractMethod(v) {
      let acceptedmethods = [];
      if (v && acceptedmethods.includes(v)) {
        if (this.SocialItemInteractMethodProp) {
          this[this.SocialItemInteractMethod](
            this.SocialItemInteractMethodProp
          );
          if (this.SocialItemInteractMethod === "ActivateSelectedElement") {
            this.SingleRecordNavMenuItem = "";
          }
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        } else {
          this[this.SocialItemInteractMethod]();
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        }
      }
    },
  },
  mounted() {
  window.addEventListener('resize', this.ResizeWindow)
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.ResizeWindow);
  },	
  created() {
    this.selectedIndex = Math.ceil(this.tab.Elements.length / 2);
    window.onload = setTimeout(() => {
      this.cardcarousel = document.querySelector(".cardcarousel");
      //console.log(this.GetTZ(190,9))
      this.WindowWidth = window.innerWidth;
      this.WindowHeight = window.innerHeight;
    }, 300);
  },
  methods: {
    ColStyle(tab,elmnt){
      let style = ''
      if(elmnt.NewLine){
        style = style+'display: flex;'
      }
      if(elmnt.Alignment){
        style = style+'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment
      }
      else{
        style = style+'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+tab.Alignment
      }
      return style
    },
    Cols(elmnt){
      let breakpoint = this.FlexSizes.find(obj => obj.Breakpoint === this.$vuetify.breakpoint.name)
      if(elmnt.NewLine){
        return 12
      }
      else{
        return elmnt[breakpoint.Prop]
      }
    },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex)
         }
         
     },
    GridNrStyle(elmnt) {
      //we could allow changing grid nr style it is the "1" or "2" on the magcards. push as style and it should work, just keep the color scheme right?
      if (elmnt.MagType === 'Type 1') {
        return "align-self: end;font-size: 480px;margin-top:50px;color: "+this.tab.MagazineHighlightColor.hex+"99;position: absolute;";
      }
      if (elmnt.MagType === 'Type 2') {
        return "font-size: 200px;color: #eee;position: absolute;";
      }
      if (elmnt.MagType === 'Type 3') {
        return "border-radius: 130px;background: "+this.tab.MagazineHighlightColor.hex+";color: white;font-size: 100px;height: 130px;line-height: 130px;width: 130px;text-align: center;position: absolute;";
      }
      if (elmnt.MagType === 'Type 4') {
        return "font-size: 320px;color: "+this.tab.MagazineHighlightColor.hex+"99;position: absolute;";
      }
    },
    ElmntGrid(elmnt) {
      let style = 'style="display: grid;gap: 20px;';
      return style;
    },
    isOdd(num) {
      return num % 2;
    },
    ColMY(tab) {
      if (tab.Name === "Card Block Single") {
        return "";
      } else {
        // return ' my-3'
        return "my-3";
      }
    },
    ActivateCardDialog(elmntindex) {
      this.selectedIndex = elmntindex;
      // else{
      // this.DialogCardIndex = elmntindex
      // }
    },
    ResizeWindow(event) {
      this.WindowWidth = window.innerWidth;
      this.WindowHeight = window.innerHeight;
    },
    AvatarStyle(tab) {
      if (typeof tab.IMGHeight !== "undefined") {
        //this will cause issues cannot do this must disable imgheight config if style used
        return this.$vuetify.breakpoint.xs
          ? "padding: 0px;width: 100%;height:150px;"
          : "padding: 0px;width: 100%;height:" + tab.IMGHeight + "px;";
      } else {
        return "padding: 0px;width: 100%;height:150px;";
      }
    },
    NEWrotateCarousel(elmntindex) {
      this.selectedIndex = elmntindex;
    },
    rotateCarousel(increase, toenlarge) {
      if (increase) {
        this.selectedIndex++;
      } else {
        this.selectedIndex--;
      }
      var angle = (this.selectedIndex / this.cellCount) * -360;
      this.cardcarousel.style.transform =
        "translateZ(-" + this.TranslateZInt + "px) rotateY(" + angle + "deg)";
      if (toenlarge) {
        this.ActivateCardDialog(this.selectedIndex);
      }
    },
    GetTZ(cellSize, numberOfCells) {
      var tz = Math.round(cellSize / 2 / Math.tan(Math.PI / numberOfCells));
      return tz;
    },
    HeaderFontFamily(tab, tabHeaderFontFamily) {
      if (this.PageHeadersFont) {
        if (!tab.HeaderFontSize) {
          return (
            "font-family: " +
            this.PageHeadersFont +
            ", sans-serif;font-size:50px;"
          );
        } else {
          if (tabHeaderFontFamily) {
            return (
              "font-family: " +
              tabHeaderFontFamily +
              ", sans-serif;font-size:" +
              tab.HeaderFontSize +
              "px;"
            );
          } else {
            return (
              "font-family: " +
              this.PageHeadersFont +
              ", sans-serif;font-size:" +
              tab.HeaderFontSize +
              "px;"
            );
          }
        }
      }
      console.log(tab, tabHeaderFontFamily);
    },
  },
};
</script>

<style>


</style>



