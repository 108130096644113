<template>
  <div>
    <!-- underway, much that needs ot be done here, but we could clear 2000 lines of code on "App" is we do this.
    The challenge to this would be the var that must be set. Let's move this css style over and see if it breaks -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','SettingColorstoSystemColors'],
    components: {},
    data() {
        return {
            
      GradientElements: [
        {Name: 'DB Sidebar Gradient',Prop: 'DB_SideBar_Gradient',DBNavbar: true},
        {Name: 'Guest Sidebar Gradient',Prop: 'Guest_SideBar_Gradient',GuestNavbar: true},
        {Name: 'Social Navbar Gradient',Prop: 'Social_NavBar_Gradient',SocialNavbar: true},
        {Name: 'Social Parallax Gradient',Prop: 'Social_Parallax_Gradient',SocialNavbar: true},
        {Name: 'Public Navbar Gradient',Prop: 'Public_NavBar_Gradient',PublicNavbar: true},
      ],
      GraphicalElements: [
        {Name: 'DB Sidebar Image',Prop: 'DB_SideBar_IMG',DBNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},
        {Name: 'Guest Sidebar Image',Prop: 'Guest_SideBar_IMG',GuestNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},
        {Name: 'Social Navbar Image',Prop: 'Social_NavBar_IMG',SocialNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},        
        {Name: 'Social Parallax',Prop: 'Social_Parallax',SocialNavbar: true,path: require('@/assets/RapidappsParallax.jpeg')},
        {Name: 'Social Navbar Header Image',Prop: 'Social_NavBar_Header_IMG',SocialNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},        
        {Name: 'Public Navbar Image',Prop: 'Public_NavBar_IMG',PublicNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},
      ],
      DefaultThemes: [
          {Name: 'light', Colors: [
              {Theme: 'light', Name: 'primary',Element_Name: 'Primary',SystemProp: 'Light_Primary', DefaultColor:  '#468FBE', DefaultColorObject: {hex: '#468FBE'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'secondary',Element_Name: 'Secondary',SystemProp: 'Light_Secondary', DefaultColor:  '#FFCDD2', DefaultColorObject: {hex: '#FFCDD2'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'accent',Element_Name: 'Accent',SystemProp: 'Light_Accent', DefaultColor:  '#89C8F0', DefaultColorObject: {hex: '#89C8F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'recordtoolbar',Element_Name: 'Record Toolbar',SystemProp: 'Light_Record_Toolbar', DefaultColor:  '#89C8F0', DefaultColorObject: {hex: '#89C8F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},              
              {Theme: 'light', Name: 'background',Element_Name: 'Background',SystemProp: 'Light_Background', DefaultColor:  '#EBF0F0', DefaultColorObject: {hex: '#EBF0F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'links',Element_Name: 'Links',SystemProp: 'Light_Links', DefaultColor:  '#FFA200', DefaultColorObject: {hex: '#FFA200'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pop',Element_Name: 'Pop',SystemProp: 'Light_Pop', DefaultColor:  '#FF0000', DefaultColorObject: {hex: '#FF0000'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'morning',Element_Name: 'Morning',SystemProp: 'Light_Morning', DefaultColor:  '#7cb9fa', DefaultColorObject: {hex: '#7cb9fa'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'night',Element_Name: 'Night',SystemProp: 'Light_Night', DefaultColor:  '#091319', DefaultColorObject: {hex: '#091319'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialappsearchbar',Element_Name: 'Social App SearchBar',SystemProp: 'Light_Social_App_SearchBar', DefaultColor:  '#84B2C9', DefaultColorObject: {hex: '#84B2C9'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialappnavbar',Element_Name: 'Social App NavBar',SystemProp: 'Light_Social_App_NavBar', DefaultColor:  '#FFFFFF', DefaultColorObject: {hex: '#FFFFFF'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialmenufont',Element_Name: 'Social Menu Font',SystemProp: 'Light_Social_Menu_Font', DefaultColor:  '#ff0000', DefaultColorObject: {hex: '#ff0000'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbcard',Element_Name: 'DB Card',SystemProp: 'Light_DB_Card', DefaultColor:  '#E3F2FD', DefaultColorObject: {hex: '#E3F2FD'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'datatext',Element_Name: 'Field Values',SystemProp: 'Light_Field_Values', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'configtext',Element_Name: 'Field Labels',SystemProp: 'Light_Field_Labels', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbnavbar',Element_Name: 'Database SideNavbar',SystemProp: 'Light_Database_SideNavbar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbnavlist',Element_Name: 'Database Navlist',SystemProp: 'Light_Database_Navlist', DefaultColor:  '#76FF03', DefaultColorObject: {hex: '#76FF03'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbappbar',Element_Name: 'Database AppBar',SystemProp: 'Light_Database_AppBar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestnavbar',Element_Name: 'Guest SideNavbar',SystemProp: 'Light_Guest_SideNavbar', DefaultColor:  '#9fcad7', DefaultColorObject: {hex: '#9fcad7'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestnavlist',Element_Name: 'Guest Navlist',SystemProp: 'Light_Guest_Navlist', DefaultColor:  '#76FF03', DefaultColorObject: {hex: '#76FF03'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestappbar',Element_Name: 'Guest AppBar',SystemProp: 'Light_Guest_AppBar', DefaultColor:  '#9fcad7', DefaultColorObject: {hex: '#9fcad7'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavheaderbg',Element_Name: 'Public Navbar Header BG',SystemProp: 'Light_Public_Navbar_Header_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavbarbg',Element_Name: 'Public Navbar BG',SystemProp: 'Light_Public_Navbar_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavbaritem',Element_Name: 'Public Navbar Item',SystemProp: 'Light_Public_Navbar_Item', DefaultColor:  '#ffffff', DefaultColorObject: {hex: '#ffffff'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'footerbg',Element_Name: 'Footer BG',SystemProp: 'Light_Footer_BG', DefaultColor:  '#f5f5f5', DefaultColorObject: {hex: '#f5f5f5'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
          ]
          },
          {Name: 'dark', Colors: [
              {Theme: 'dark', Name: 'primary',Element_Name: 'Primary',SystemProp: 'Dark_Primary', DefaultColor:  '#03111f', DefaultColorObject: {hex: '#03111f'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'secondary',Element_Name: 'Secondary',SystemProp: 'Dark_Secondary', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'accent',Element_Name: 'Accent',SystemProp: 'Dark_Accent', DefaultColor:  '#467999', DefaultColorObject: {hex: '#467999'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'recordtoolbar',Element_Name: 'Record Toolbar',SystemProp: 'Dark_Record_Toolbar', DefaultColor:  '#607D8B', DefaultColorObject: {hex: '#607D8B'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},              
              {Theme: 'dark', Name: 'background',Element_Name: 'Background',SystemProp: 'Dark_Background', DefaultColor:  '#474747', DefaultColorObject: {hex: '#474747'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'fieldcard',Element_Name: 'Field Card',SystemProp: 'Dark_Field_Card', DefaultColor:  '#707A81', DefaultColorObject: {hex: '#707A81'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'links',Element_Name: 'Links',SystemProp: 'Dark_Links', DefaultColor:  '#FFA200', DefaultColorObject: {hex: '#FFA200'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pop',Element_Name: 'Pop',SystemProp: 'Dark_Pop', DefaultColor:  '#ff4500', DefaultColorObject: {hex: '#ff4500'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'morning',Element_Name: 'Morning',SystemProp: 'Dark_Morning', DefaultColor:  '#7cb9fa', DefaultColorObject: {hex: '#7cb9fa'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'night',Element_Name: 'Night',SystemProp: 'Dark_Night', DefaultColor:  '#091319', DefaultColorObject: {hex: '#091319'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialappsearchbar',Element_Name: 'Social App SearchBar',SystemProp: 'Dark_Social_App_SearchBar', DefaultColor:  '#84B2C9', DefaultColorObject: {hex: '#84B2C9'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialappnavbar',Element_Name: 'Social App NavBar',SystemProp: 'Dark_Social_App_NavBar', DefaultColor:  '#55798A', DefaultColorObject: {hex: '#55798A'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialmenufont',Element_Name: 'Social Menu Font',SystemProp: 'Dark_Social_Menu_Font', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbcard',Element_Name: 'DB Card',SystemProp: 'Dark_DB_Card', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'datatext',Element_Name: 'Field Values',SystemProp: 'Dark_Field_Values', DefaultColor:  '#b6e1f1', DefaultColorObject: {hex: '#b6e1f1'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'configtext',Element_Name: 'Field Labels',SystemProp: 'Dark_Field_Labels', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbnavbar',Element_Name: 'Database SideNavbar',SystemProp: 'Dark_Database_SideNavbar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbnavlist',Element_Name: 'Database Navlist',SystemProp: 'Dark_Database_Navlist', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbappbar',Element_Name: 'Database AppBar',SystemProp: 'Dark_Database_AppBar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestnavbar',Element_Name: 'Guest SideNavbar',SystemProp: 'Dark_Guest_SideNavbar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestnavlist',Element_Name: 'Guest Navlist',SystemProp: 'Dark_Guest_Navlist', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestappbar',Element_Name: 'Guest AppBar',SystemProp: 'Dark_Guest_AppBar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavheaderbg',Element_Name: 'Public Navbar Header BG',SystemProp: 'Dark_Public_Navbar_Header_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavbarbg',Element_Name: 'Public Navbar BG',SystemProp: 'Dark_Public_Navbar_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavbaritem',Element_Name: 'Public Navbar Item',SystemProp: 'Dark_Public_Navbar_Item', DefaultColor:  '#ffffff', DefaultColorObject: {hex: '#ffffff'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'footerbg',Element_Name: 'Footer BG',SystemProp: 'Dark_Footer_BG', DefaultColor:  '#272727', DefaultColorObject: {hex: '#272727'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
          ]
          }
      ],
        }
    },	
    computed:{
        UILibDarkMode(){
        return this.$vuetify.theme.dark
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SettingColorstoSystemColors(v) {
            if(v){
                this.SetColorstoSystemColors(this.System)
            }
        },
        System : {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
              this.UpdateLinksColor()
              if(newvalue.AppDefaultHeader){
                this.SetHeaderFontStyles(newvalue.AppDefaultHeader)
              }
              else{
                this.SetHeaderFontStyles({Name: 'Roboto',SizeMultiplier: 1})
              }
              if(newvalue.AppDefaultBody){
                this.SetBodyFontStyles(newvalue.AppDefaultBody)
              }
              else{
                this.SetBodyFontStyles({Name: 'Roboto',SizeMultiplier: 1})
              }
              //console.log('f me we firing GetSystemStyling')
            this.GetSystemStyling(this.System)
              }
              
            },
            deep: true
    },
    
        UILibDarkMode(v) {
            if (v) {
               this.UpdateLinksColor()
            }
            else{
              this.UpdateLinksColor()
            }
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.$store.commit('setGradientElements',this.GradientElements)
        this.$store.commit('setGraphicalElements',this.GraphicalElements)
        this.$store.commit('setDefaultThemes',this.DefaultThemes)
        if(this.System){
        this.GetSystemStyling(this.System)
        }
    },
    methods:{
        SetColorstoSystemColors(System){
          setTimeout(() => {
           //console.log('app SetColorstoSystemColors',System,this.DefaultThemes)
            this.DefaultThemes = this.DefaultThemes.map(theme => {
            theme.Colors.map(color => {
                if(System[color.SystemProp]){
                    this.$vuetify.theme.themes[color.Theme][color.Name] = System[color.SystemProp].hex
                    color.DefaultColorObject = System[color.SystemProp]
                }
                else{
                System[color.SystemProp] = color.DefaultColorObject
                this.$vuetify.theme.themes[color.Theme][color.Name] = color.DefaultColorObject.hex
                }
                return color
            })
            return theme
            })
            this.$store.commit('setDefaultThemes',this.DefaultThemes)
            //console.log(this.$store.state.DefaultThemes,this.$vuetify.theme.themes)
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            setTimeout(() => {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            }, 50);
            this.UpdateLinksColor() 
          }, 500);
        
        },
        SetGraphicalElements(System){
        this.GraphicalElements = this.GraphicalElements.map(elmnt => {
            if(System[elmnt.Prop]){
            elmnt.path = System[elmnt.Prop].path
            }
            return elmnt
        })
        },
        GetSystemStyling(System){
        this.SetColorstoSystemColors(System)
        this.SetGraphicalElements(System)
        //console.log('GetSystemStyling(System)',System)
        //this.$vuetify.theme.themes.light.pop = '#eb371b'
        },
        SetBodyFontStyles(bodyfont){
        let root = document.documentElement;
        root.style.setProperty('--app-bodyfont', `"`+bodyfont.Name+`", Tahoma, sans-serif`)
        },
        SetHeaderFontStyles(headerfont){
        let root = document.documentElement;
        root.style.setProperty('--app-headerfont', `"`+headerfont.Name+`", Tahoma, Trebuchet , sans-serif`)
        root.style.setProperty('--app-headerfont--articlecaption', headerfont.SizeMultiplier*1+`em`)
        root.style.setProperty('--app-headerfont--articleoverline',headerfont.SizeMultiplier*2+`em`)
        root.style.setProperty('--app-headerfont--largeoverline', headerfont.SizeMultiplier*2.5+`em`)
        root.style.setProperty('--app-headerfont--display1', headerfont.SizeMultiplier*2.125+`rem`)
        root.style.setProperty('--app-headerfont--display2', headerfont.SizeMultiplier*3+`rem`)
        root.style.setProperty('--app-headerfont--display3', headerfont.SizeMultiplier*3.75+`rem`)
        root.style.setProperty('--app-headerfont--display4', headerfont.SizeMultiplier*6+`rem`)
        root.style.setProperty('--app-headerfont--headline', headerfont.SizeMultiplier*1.5+`rem`)
        root.style.setProperty('--app-headerfont--title', headerfont.SizeMultiplier*1.25+`rem`)
        root.style.setProperty('--app-headerfont--subtitle-2', headerfont.SizeMultiplier*0.875+`rem`)
        root.style.setProperty('--app-headerfont--subtitle-1', headerfont.SizeMultiplier*1+`rem`)
        root.style.setProperty('--app-headerfont--mobile-row__header', headerfont.SizeMultiplier*0.75+`rem`)
        root.style.setProperty('--app-headerfont--subtleoverline', headerfont.SizeMultiplier*0.75+`em`)
        root.style.setProperty('--app-headerfont--intranetnavlinks', headerfont.SizeMultiplier*12+`px`)

        },
        UpdateLinksColor(){
        let root = document.documentElement;
        if(this.System.Light_Links){
            root.style.setProperty('--app-lightlinkscolor', this.System.Light_Links.hex)
        }
        if(this.System.Dark_Links){
            root.style.setProperty('--app-darklinkscolor', this.System.Dark_Links.hex)
        }
        },
    }
}
</script>

<style>
.LimeBuilderGradient { 
  background: linear-gradient(-45deg, rgba(0, 255, 0, 0.5), rgba(128, 0, 128, 0.5));
	background-size: 200% 200%;
	animation: gradientBG 5s ease infinite;
}
.SleekLimeBuilderGradient { 
  background: linear-gradient(-45deg, rgba(0, 255, 0, 0.4), rgba(128, 0, 128, 0.3));
	background-size: 200% 200%;
	animation: gradientBG 5s ease infinite;
}
.BoldBuilderGradient {
	background: linear-gradient(-45deg, #ee9e94de, #d4729bd5, #466ca4dc, #23d5abc9);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}
@keyframes edge {
  0% {
	transform: scaleX(0);
  }
  100% {
	transform: scaleX(1);
  }
}
@keyframes edgetiplft {
	0% {
	transform: scaleX(0);
  left: -13px
  }
  100% {
	transform: scaleX(1);
  left: -51px
  }
}
@keyframes edgetirght {
	0% {
	transform: scaleX(0);
  right: -13px
  }
  100% {
	transform: scaleX(1);
  right: -51px
  }
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes move-wavesforever {
  0% {
   transform: translate3d(-174px,0,0);
  }
  100% { 
    transform: translate3d(0px,0,0);
  }
}
.RAWebBtnGradlight{
  background: linear-gradient(345deg, rgba(78,85,143,1) 0%, rgba(145,100,139,1) 43%, rgba(207,157,182,1) 100%);
}
.RAWebBtnGradlightsoft{
  background: linear-gradient(345deg, rgba(78,85,143,0.5) 0%, rgba(145,100,139,0.5) 43%, rgba(207,157,182,0.5) 100%);
}
.RAWebBtnGraddark{
  background: linear-gradient(345deg, rgba(207,157,182,1) 0%, rgba(145,100,139,1) 43%, rgba(78,85,143,1) 100%);
}
.RAWebBtnGraddarksoft{
  background: linear-gradient(345deg, rgba(207,157,182,0.5) 0%, rgba(145,100,139,0.5) 43%, rgba(78,85,143,0.5) 100%);
}
.RAWebBtnGradShiny{
  background: linear-gradient(315deg, rgba(78,85,143,1) 0%, rgba(145,100,139,1) 43%, rgba(242,108,175,1) 88%, rgba(255,255,255,1) 100%);
}
.BuilderGradient {
	background: linear-gradient(-45deg, #ee9e9480, #d4729b7c, #466ca444, #23d5ab41);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}
.SoftBuilderGradient {
	background: linear-gradient(-45deg, #ee9e9433, #d4729b24, #466ca444, #23d5ab41);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.objectcontentheader {
    align-items: flex-start;
    align-self: flex-start;
}
.v-application a {
  color: var(--app-darklinkscolor) !important;
}
.v-application .theme--light a:not(.theme--dark) {
  color: var(--app-lightlinkscolor) !important;
}
/* .theme--light a {
  color: var(--app-lightlinkscolor) !important;
} */


/* .theme--light .theme--dark a {
  color: var(--app-darklinkscolor) !important;
}
.theme--dark .theme--light a {
  color: var(--app-lightlinkscolor) !important;
} */
/* .dbnavlist .v-list .v-list-item--active{
    color: #FFA200 !important;
} */
.relative{
  position: relative;
}
.cloudbg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0.1;
  }
  
}
.whiteoverlay{
  background: linear-gradient(90deg, rgba(246,246,246,0.8) 0%, rgba(255,255,255,0.5) 100%);
}
.stdparallaxgradient{
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));
}
.stdparallaxgradientinvert{
  background-image: linear-gradient(to right bottom, rgb(248, 239, 191), rgb(0,132,255, 1));
}
.announcementpulse{
  animation: announcepulse 2s infinite;
}
@keyframes announcepulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(230, 196, 5, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(211, 197, 3, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}

}
.BGImage{
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.centertext {
text-align:center;
}
.slightmargin{
  padding: 25px;
}
.elevatedsectionheader{
  z-index: 2;
  box-shadow: 0 2px 8px 0 rgb(100, 100, 100);
}
.overlayed{
  margin-top: -60px;
}
.stickytopconscious{
  top: 160px
}
.publicnav{
  top: 100px
}
.recordparallax{
  top: 160px;
}
.recordparallax2{
  top: 50px;
}
.pagetop{
  top: 50px;
}
.breadcrumbstop{
  top: 50px;
  z-index: 1;
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 10px;
  z-index: 4
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 4
}
.stickytopbanner2{
  position: fixed;
  top: 50px;
  z-index: 4
}
.stickytopbanner2appstyling{
  position: fixed;
  top: 260px;
  z-index: 4
}
.stickytopbanner3{
  position: fixed;
  top: 130px;
  z-index: 4
}
.stickytopbanner4{
  position: fixed;
  top: 220px;
  z-index: 4
}
.leftpadded{
   right: 40px;
}
.minifootermargin{
  padding-bottom: 35px;
}
.templatefootermarginpublic{  
 
  padding-bottom: 30px;
}
.footermargin{  
 
  padding-bottom: 150px;
}
.bottomspace{
  padding-bottom: 40px;
}
.focusedviewborder{
  border: solid;
  border-color: rgb(36, 36, 36);
	border-left-width: 50px;
  border-right-width: 50px;
  border-top-width: 150px;
  border-bottom-width: 150px;
  /* border-image: 
    linear-gradient(
      -45deg, 
      #ee7752, #e73c7e, #23a6d5, #23d5ab
    ) 1 100%;
	animation: gradientBG 15s ease infinite; */
  
}
.interactbounce{
  animation: interactbouncing 1.5s ease-in-out;
}
@keyframes interactbouncing {
	0% {
		transform: scale(1.0);
	}

  20% {
		transform: scale(1.05);
	}

  30% {
		transform: scale(1.0);
	}

  45% {
		transform: scale(1.025);
	}

  50% {
		transform: scale(1.0);
	}

  65% {
		transform: scale(1.01);
	}

  80% {
		transform: scale(1.0);
	}
}
.actionbounce{
  animation: borderlesspulse 3s infinite ease-in-out;
}
.incompletedmandatory{
  border: red;
  color: white;
  font-size: 20px;
  animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes borderlesspulse {
	0% {
		transform: scale(1.0);
	}

  20% {
		transform: scale(1.2);
	}

  30% {
		transform: scale(1.0);
	}

  35% {
		transform: scale(1.1);
	}

  40% {
		transform: scale(1.0);
	}

  45% {
		transform: scale(1.05);
	}

  50% {
		transform: scale(1.0);
	}
}
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
   appearance: none;

  width: 250px;
  height: 20px;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress[value]::-webkit-progress-value {
  background:
	   -webkit-linear-gradient(-45deg, 
	                           transparent 33%, rgba(0, 0, 0, .1) 33%, 
	                           rgba(0,0, 0, .1) 66%, transparent 66%),
	   -webkit-linear-gradient(top, 
	                           rgba(255, 255, 255, .25), 
	                           rgba(0, 0, 0, .25)),
	   -webkit-linear-gradient(left, #FFCDD2, #E53935);

    border-radius: 2px; 
    background-size: 35px 20px, 100% 100%, 100% 100%;
    -webkit-animation: animate-stripes 5s linear infinite;
        animation: animate-stripes 5s linear infinite;
}
@-webkit-keyframes animate-stripes {
   0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes animate-stripes {
   0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
h2{
  color: black;
}
.fixedquicknav{
  position: fixed;
  top: 160px;
}
.txtlink {
    color: rgb(253, 253, 253);
    text-decoration: none;
    font-size: 16px;
}
.rmgradient {
  background-image: linear-gradient(to BOTTOM, #468FBE ,#FFCDD2);
}
.foldertab {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px; 
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: #8f91a1;
  
}
.paddedtab {
  padding: 10px;
  background-image: linear-gradient(to bottom, #8f91a1 ,white);
  
}


.folderyellow{
    clip-path: polygon(0 0, 30% 0, 34% 30%, 100% 30%, 100% 100%, 0 100%);
}
.documenttypeshadow{
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.documenttypeshadowinvert{
    filter: drop-shadow(1px -3px 3px rgba(50, 50, 0, 0.2));
}
.documenttypeshadowinvertcolor{
    filter: drop-shadow(1px -3px 3px rgba(255, 255, 253, 0.2));
}
/* .stripe-4 {
  color: white;
  background: repeating-linear-gradient(
    -55deg,
    #FFA000,
    #FFA000 10px,
    #FF8F00 10px,
    #FF8F00 20px
  ); */
  .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.articleoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--articleoverline) !important;
  line-height: 1.0; 
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.articlecaption {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--articlecaption) !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.largeoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--largeoverline) !important;
  line-height: 1.0; 
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.xtralargeoverline{
  font-weight: 500;
  line-height: 1.0; 
  font-size: 2.6em !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.xtralargeoverlinemini{
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  line-height: 1.0; 
  font-size: 2.0em !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}

.mediumoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.5em;
  letter-spacing: 0.1666666667;

}
.justaboveoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.2em;
  letter-spacing: 0.1666666667;

}
.smallbanneroverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.5em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.navoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  var(--app-headerfont--articlecaption) !important;
  letter-spacing: 0.1666666667;

}
.v-application {
  font-family: var(--app-bodyfont) !important;
  line-height: 1.5;
}
.v-application .display-4 {
  font-size:  var(--app-headerfont--display4)rem !important;
  font-weight: 300;
  line-height: 6rem;
  letter-spacing: -0.015625em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .display-3 {
  font-size:  var(--app-headerfont--display3) !important;
  font-weight: 300;
  line-height: 3.75rem;
  letter-spacing: -0.0083333333em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .display-2 {
  font-size:  var(--app-headerfont--display2) !important;
  font-weight: 400;
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .display-1 {
  font-size:  var(--app-headerfont--display1) !important;
  font-weight: 400;
  line-height: 2.5;
  letter-spacing: 0.0073529412em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .headline {
  font-size:  var(--app-headerfont--headline) !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .title {
  font-size:  var(--app-headerfont--title) !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .v-card__title {
  word-break: normal !important;
  line-height: 1em;
}
.v-application .subtitle-2 {
  font-size:  var(--app-headerfont--subtitle-2) !important;
  font-weight: 500;
  letter-spacing: 0.0071428571em !important;
  line-height: 1.375rem;
  font-family: var(--app-headerfont) !important;
}
.v-application .subtitle-1 {
  font-size:  var(--app-headerfont--subtitle-1) !important;
  font-weight: normal;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem;
  font-family: var(--app-headerfont) !important;
}
.v-application .body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: var(--app-bodyfont) !important;
}
.v-application .body-1 {
  font-size: 1rem !important;
  font-weight: 400;
  letter-spacing: 0.03125em !important;
  line-height: 1.5rem;
  font-family: var(--app-bodyfont) !important;
}
.v-data-table{
  font-family: var(--app-bodyfont) !important;
  color:#757575;
}
.v-data-table .text-start{
  font-family: var(--app-bodyfont) !important;
  font-size: 0.85rem !important;
  color:#757575;
}
.v-data-table__mobile-row__header{
  font-size: var(--app-headerfont--mobile-row__header) !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  font-family: var(--app-headerfont) !important;
}
.v-data-table__mobile-row{
  font-family: var(--app-bodyfont) !important;
  font-size: 1rem !important;
  color:#757575;
}
.v-application .caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-family: var(--app-bodyfont) !important;
}
.v-application .overline {
  font-size: 0.75rem !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: var(--app-bodyfont) !important;
}

/* .subtleoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.1em;
  letter-spacing: 0.1666666667;

} */
.lesssubtleoverline {
  font-size: 0.9em !important;
  font-weight: 400;
  letter-spacing: 0.1666666667em !important;
  font-family: var(--app-headerfont) !important;
}
.subtleoverline {
  font-size: var(--app-headerfont--subtleoverline) !important;
  font-weight: 300;
  letter-spacing: 0.1666666667em !important;
  font-family: var(--app-headerfont) !important;
}
.stdfontsubtleoverline {
  font-weight: 300;
  letter-spacing: 0.1666666667em !important;
  font-family: var(--app-headerfont) !important;
}

.listoutline{
  border-bottom: 0.5px solid #3498db;
}
i.v-icon.actionicon {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactionicon {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactioniconsml {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 1px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactioniconsmlinactive {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 1px;
  /* box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3); */
  
} 
.MorningSun {
  position: absolute;
  top: 160px;
  height: 105px;
  width: 105px;
  background-color: rgba(243, 230, 108,0.95);
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
.MiddaySun {
  position: absolute;
  top: 160px;
  height: 110px;
  width: 110px;
  background-color: #f5e16d;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px #f5ec6d, 0 0 20px rgba(245, 236, 109,0.5), 0 0 30px #f3e66c, 0 0 40px rgba(245, 236, 109,0.5), 0 0 50px rgba(245, 236, 109,0.5), 0 0 60px rgba(245, 236, 109,0.5), 0 0 70px rgba(245, 236, 109,0.5);
  }
  
  to {
    box-shadow: 0 0 20px #f5ec6d, 0 0 30px rgba(243, 218, 108, 0.5), 0 0 40px rgba(243, 218, 108, 0.5), 0 0 50px rgba(243, 218, 108, 0.5), 0 0 60px rgba(243, 218, 108, 0.5), 0 0 70px rgba(243, 218, 108, 0.5), 0 0 80px rgba(243, 218, 108, 0.5);
  }
}
.Moon {
  position: absolute;
  top: 160px;
  height: 100px;
  width: 100px;
  background-color: rgba(238, 237, 234);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 1px 4px 15px rgb(241, 241, 217);
}
.FormFields{
  font-family: 'Segoe UI', Tahoma, Georgia, Verdana, sans-serif;
  color:#757575;
}
.intranetnavlinks {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--intranetnavlinks) !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
  
}
.topcut{
  margin-top: -150px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.topcutsml{
  margin-top: -300px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.topimg{
  margin-bottom: 300px;
}
.invertedenvlopetopcenter{
 margin-top: -300px;
 z-index: -1  ;
}
.invertedenvlopetopcenterforwards{
  animation: invertedenvlopetopcenteranim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetopcenterbackwards{
  animation: invertedenvlopetopcenteranimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetopcenteranim {
  0% {
   margin-top: -300px;
  }



  100% {
   margin-top: -500px;
    
  }
}
@keyframes invertedenvlopetopcenteranimrev {
  0% {
    margin-top: -500px;
  }



  100% {
   margin-top: -300px;
    
  }
}
.envelopeheaderforwards{
  animation: envelopeheaderanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.envelopeheaderbackwards{
  animation: envelopeheaderanimrev 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes envelopeheaderanim {
  0% {
    margin-left: 0px;
  }



  100% {
   margin-top: 200px;
    
  }
}
@keyframes envelopeheaderanimrev {
  0% {
    margin-left: 200px;
  }



  100% {
   margin-top: 0px;
    
  }
}
.invertedenvlopetopleft{
 
  clip-path: polygon(0 0, 25% 0, 0 100%);
}
.invertedenvlopetopleftforwards{
  animation: invertedenvlopetopleftanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetopleftbackwards{
  animation: invertedenvlopetopleftanimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetopleftanim {
  0% {
    clip-path: polygon(0 0, 25% 0, 0 100%);
  }



  100% {
   
    clip-path: polygon(0 0, 5% 0, 0 100%);
  }
}
@keyframes invertedenvlopetopleftanimrev {
  0% {
    clip-path: polygon(0 0, 5% 0, 0 100%);
  }



  100% {
   
    clip-path: polygon(0 0, 25% 0, 0 100%);
  }
}

.invertedenvlopetopright {
 background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));

 clip-path: polygon(100% 0, 75% 0, 100% 100%);
}
.invertedenvlopetoprightforwards{
  animation: invertedenvlopetoprightanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetoprightbackwards{
  animation: invertedenvlopetoprightanimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetoprightanim {
  0% {
    clip-path: polygon(100% 0, 75% 0, 100% 100%);
  }



  100% {
   
    clip-path: polygon(100% 0, 95% 0, 100% 100%);
  }
}
@keyframes invertedenvlopetoprightanimrev {
  0% {
    clip-path: polygon(100% 0, 95% 0, 100% 100%);
  }



  100% {
   
    clip-path: polygon(100% 0, 75% 0, 100% 100%);
  }
}

/* .invertedenvlopetopleft{
  clip-path: polygon(0 0, 50% 0, 0 100%);
} */
.tag-wrap {
 
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.inverted{
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  
}
.straight {
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.parallaxbgcontentgrad {
  background-image: linear-gradient(to right, rgba(1,0,15,0.835171568627451), rgba(9,46,54,0.47942927170868344));
}
.order-container-btn {
  position: fixed;
  top: 70px;
  left: 10px;
  z-index: 5
}
.chat-container {
  position: fixed;
  bottom: 112px;
  left: 50px;
  z-index: 5
}
.fab-container {
  position: fixed;
  bottom: 112px;
  right: 50px;
  z-index: 100
}
.dashboard-straighten-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 5
}
.dashboard-edit-container{
  position: fixed;
  bottom: 60px;
  right: 30px;
  z-index: 5
}
.dashboard-filters-container{
  position: fixed;
  bottom: 120px;
  right: 50px;
  z-index: 5
}
.dashboard-colors-container{
  position: fixed;
  bottom: 120px;
  right: 110px;
  z-index: 5
}
.app-dashboard-straighten-container {
  position: fixed;
  bottom: 60px;
  right: 180px;
  z-index: 5
}
.app-dashboard-edit-container{
  position: fixed;
  bottom: 60px;
  right: 115px;
  z-index: 5
}
.app-dashboard-filters-container{
  position: fixed;
  bottom: 120px;
  right: 115px;
  z-index: 5
}
.app-dashboard-colors-container{
  position: fixed;
  bottom: 120px;
  right: 180px;
  z-index: 5
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #3498db;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
.basepulse {
  color: red;
  animation: basepulseanim 2s;
  animation-iteration-count: infinite
}
@keyframes basepulseanim {
	0% {
    opacity: 0.5;
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(252, 2, 2, 0.7);
	}

	50% {
    opacity: 1.0;
		transform: scale(2);
    color: 0 0 0 10px rgba(189, 14, 14, 0.568);
	}

	100% {
    opacity: 0.5;
		transform: scale(0.95);
    color: 0 0 0 0 rgba(173, 44, 44, 0.274);
	}
}
.detailslistoutline:nth-child(odd) {
  background: rgba(238, 238, 238,0.5)
}
.darkdetailslistoutline:nth-child(odd) {
  background: rgb(51, 51, 51);
}
.darkdetailslistoutline:nth-child(even) {
  background: rgb(97, 97, 97);
}
.carouseloutline:nth-child(odd) {
  background: rgb(255, 255, 255);
}
.carouseloutline:nth-child(even) {
  background: rgb(0, 0, 0);
}
.carouseloutline :hover{
  background-color: rgba(168, 167, 167, 0.4);
}
.TaggedField{
 
    font-weight: bold;
    text-decoration: underline;
    height: 15px;
    padding-top: 1px;
    padding-right: 2px;
    padding-left: 2px;
    overflow: hidden;
    margin-bottom: 0px;
    vertical-align: middle;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value{
    font-size: 24px;
    color: rgb(0, 0, 0);
    line-height: 18px;
    margin-bottom: 6px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title{
      font-size: 12px;
    font-weight: bold;
    color: gray;
}
.fullapp {
    max-width: 100%;
    overflow: hidden;
}
.sideactivitiestoggle-container{
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 100
}
.createnotice-container {
  position: fixed;
  top: 240px;
  right: 50px;
  z-index: 8
}
.meetupqr-container{
  position: fixed;
  top: 300px;
  right: 50px;
  z-index: 100
}
.meetupcheckedin-container{
  position: fixed;
  top: 360px;
  right: 50px;
  z-index: 8
}
.whitelabel .v-input__slot{
  background-color: white!important;
}
.blacklabel .v-input__slot{
  background-color: black!important;
}
.commentblock {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #f0f2f5
}
.commentblockdark {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #607D8B
}
.maptagitem {
  visibility: hidden;
}
    
.maptag:hover .maptagitem {
  visibility: visible;

  /* width: 50px;
  height: 50px; */
}
.growheight {
  transition: all .2s ease-in-out;
  }
.growheight:hover {
  transform: scale(1.1);
  transform: translate(0,-0.25em);
  }
.growright {
  transition: all .2s ease-in-out;
  }
.growright:hover {
  transform: scale(1.1);
  transform: translate(0.5em,0);
  }
.growleft {
  transition: all .2s ease-in-out;
  }
.growleft:hover {
  transform: scale(1.1);
  transform: translate(-0.5em,0);
  }
  .halfparallaxright{
      top: -50%;
    left: 50%;
    width: 50%
}
.halfparallaxleft{
      top: -50%;
    left: 0;
    width: 50%
}
.halfparallaximg .v-parallax__image {
    top: -70%;
    left: 50%;
    width: 50%;
    min-width: 50%;
}
.halfparallaxblock {
    top: -70%;
    left: 50%;
    width: 50%;
    min-width: 50%;
}
.halfparallaxblockright {
    top: -70%;
    left: 50%;
    width: 50%;
    min-width: 50%;
}
.halfparallaxblockleft {
    top: -70%;
    left: 0%;
    width: 50%;
    }
.scrollinfromright{
  animation: animscrollinfromright forwards;
  animation-duration: 1s;
  transition: transform 2s;
}
.scrollinfromrightreverse{
  animation: animscrollinfromright forwards;
  animation-direction: reverse;
  animation-duration: 1s;
  transition: transform 2s;
}

@keyframes animscrollinfromright {
  0% {right: calc(var(--WindowWidth) * -1px); top:0px;}
  100% {right:0px; top:0px;}
}
.scrollinfromleft{
  animation: animscrollinfromleft forwards;
  animation-duration: 1s;
  transition: transform 2s;
}
.scrollinfromleftreverse{
  animation: animscrollinfromleft forwards;
  animation-direction: reverse;
  animation-duration: 1s;
  transition: transform 2s;
}

@keyframes animscrollinfromleft {
  0% {left: calc(var(--WindowWidth) * -1px); top:0px;}
  100% {left:0px; top:0px;}
}
.HTMLTable{
  /* font-family: var(--app-headerfont) !important; */
font-family: var(--app-headerfont) !important;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}
.tinylabel .v-label{
  font-size: 12px;
}
.tinyinput{
  max-width: 50%;
  font-size:12px;
  padding: 0;
}
.smallinput{
  max-width: 100%;
  font-size:1em;
  padding: 0;
  height: 3em;
  
}
.smallinput .v-input__control .v-text-field__details {
    position: absolute;
    margin: -10px 0px;
}
.smallinputspecial{
  max-width: 100%;
  font-size:1em;
  padding: 0;
  /* height: 3em; */
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes alarmring {
  0%, 8%, 19%{
		transform: scale(1);
	}
  4%, 16%, 24%{
		transform: scale(1.05);
	}
  25% {
		transform: scale(1);
	}
  25%, 35%, 45%{
		transform:  rotate(-5deg);
	}
  30%, 40%, 50%{
		transform: rotate(5deg);
	}
  51.25%, 53.75%, 56.25%, 58.75%, 60.25%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0) ;
  }
  50%, 52.5%, 55%, 57.5%, 59% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0) rotate(0deg);
  }
  60.5% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }  
  72.5%, 75%, 77.5%, 79.5%, 81.5%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0);
  }
  71.25%, 73.75%, 76.25%, 78.75%, 80.25% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0) scale(1) rotate(0deg);
  }
}
@keyframes alarmpulse {
  0%, 8%, 19%{
		transform: scale(1);
	}
  4%, 16%, 24%{
		transform: scale(1.05);
	}
  25% {
		transform: scale(1) rotate(0deg);
	}
  100% {
		transform: scale(1) rotate(0deg);
	}
}
@keyframes vibrate {
  51.25%, 53.75%, 56.25%, 58.75%, 60.25%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0);
  }
  50%, 52.5%, 55%, 57.5%, 59% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0);
  }
  60.5% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }  
  72.5%, 75%, 77.5%, 79.5%, 81.5%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0);
  }
  71.25%, 73.75%, 76.25%, 78.75%, 80.25% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }

}
@keyframes wiggle {

  2.5% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    }

  5% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  7.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    }
  10% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  22.5% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    }

  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  27.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    }
  30% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
}
.v-parallax__content{
  padding: 0!important;
}
#map{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: red;
  z-index: 100;
}
.appbartitlecenter .v-app-bar-title__content{
  width: 100%!important;
}
.smalltextarea textarea {
  line-height: 1.0rem!important;
}
.stickytop3{
  position: fixed;
  width: 100%;
  z-index: 4;
  box-shadow: 10px 10px 8px #888888;

}
.shopoverflowfilterbtn .v-select__slot{
  border: 2px solid gainsboro;
  border-radius: 5px;  
}

.shopoverflowfilterbtn .v-input__slot{
  border-style: none !important;
}
.siteconfigcard{
  border-radius: 25px !important;
  padding-top: 15px !important;
  margin-top: 30px !important;
}
.siteconfigcardsections{
  border-radius: 25px !important;
  padding-top: 35px !important;
}
.siteconfigcardtabs 
  .v-tabs-items {
  background-color: rgba(255, 255, 255, 0) !important;
}
.siteconfigcardtabs 
  .v-tabs-bar {
  background-color: rgba(255, 255, 255, 0) !important;
}
.tabtoolbarshown{  
  position: absolute;
  z-index: 3;
  width: 100%;
  top:0px;
  left: 100px;
}
.sitenavitem:hover {
  background-color: #f1f4f6;
}
.sitenavitem:hover .sitenavitembtns{
display: block;
}
.sitenavitembtns{
  display: none;
}
.tabtoolbar{
  display: none;
  position: absolute;
  z-index: 5;
}
.tabonbuild:hover .tabtoolbar {
  display: block;
}
.tabelmnttoolbar{
  display: none;
  position: absolute;
  z-index: 5;
}
.tabelmnttoolbarshown{
  position: absolute;
  z-index: 5;
}
.tabelmnt:hover .tabelmnttoolbar {
  display: block;
}
.noborderoverflow .v-input__slot{
  border-style: none !important;
  box-shadow: none !important;
}
.settingsfont {
  font-size:13px;font-weight: 300;
}
.machinetoggle{
  border-radius: 0px !important;
}
.machinetoggle .v-input--switch__thumb{
  border-radius: 0px;
  height: 22px;
}
.machinetoggle .v-input--switch__track{
  border-radius: 0px;
  height: 16px;
  width: 36px !important;
}
.recordcabinet{
  position: fixed;
  left: -100px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
.recordcabinetmini{
  position: fixed;
  left: -100px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
/* .recordcabinet:hover .recordcabinetdrawer {
  left: 50px;
} */
.dynbubblenav{
  position: absolute;
  right: var(--rightpos);
  top: var(--toppos);
  bottom: 100px;
  transition: 0.3s;
  transform-origin: left;
  z-index:2;
  display: block;
  /* put display on for advanced bubble menu coming soon */
}
.dynbubblenav .bubblemenuitem{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%) scale(0.5);
  justify-items: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  
  /* font-size: 40px; */
  /* color: #FFFFFF; */
  transition: 0.3s;
  transition-delay: calc(0.1s * var(--i));
}
.dynbubblenav.open .bubblemenuitem:nth-child(2){
  transform: translate(-200%, -50%) scale(1);
}
.dynbubblenav.open .bubblemenuitem:nth-child(3){
  transform: translate(-165%, -130%) scale(1);
}
.dynbubblenav.open .bubblemenuitem:nth-child(4){
  transform: translate(-90%, -175%) scale(1);
}
.dynbubblenav.open .bubblemenuitem:nth-child(5){
  transform: translate(-5%, -175%) scale(1);
}
.dynbubblenav.open .bubblemenuitem:nth-child(6){
  transform: translate(65%, -130%) scale(1);
}
.dynbubblenav.open .bubblemenuitem:nth-child(7){
  transform: translate(100%, -50%) scale(1);
}
.dynbubblenav .hamburger{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  /* width: 40px;
  height: 30px; */
  cursor: pointer;
  z-index: 3;
  top: -20px;
}


.dynbubblenav .hamburger .hamburgerline{
  z-index: 2;
  width: 100%;
  height: 5px;
  background: #ffffff;
  transition: 0.3s;
  transform-origin: center;
}
.dynbubblenav.open .hamburger .hamburgerline:nth-child(1){
  /* display: none; */
  transform: translate(0, 250%) rotate(45deg);
}
.dynbubblenav.open .hamburger .hamburgerline:nth-child(2){
  /* display: none; */
  transform: rotate(45deg);
}
.dynbubblenav.open .hamburger .hamburgerline:nth-child(3){
  /* display: none; */
  transform: translate(0, -250%) rotate(-45deg);
}
.bubblenav{
  position: absolute;
  left: 44.5%;
  bottom: 100px;
  transition: 0.3s;
  transform-origin: left;
  z-index:11;
  display: none;
  /* put display on for advanced bubble menu coming soon */
}
.bubblenav.presub{
  bottom: 100px;
}
.bubblenav.submenu{
  position: relative;
  left: 0%;
  top: 20px;
  transform: rotate(90deg)
}
.bubblenav .bubblemenuitem{
  position: absolute;
  top: 70%;
  left: 60%;
  transform: translate(-50%, -50%) scale(0.5);
  justify-items: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  
  /* font-size: 40px; */
  /* color: #FFFFFF; */
  transition: 0.3s;
  transition-delay: calc(0.1s * var(--i));
}
.bubblenav.open .bubblemenuitem:nth-child(2){
  transform: translate(-200%, -50%) scale(1);
}
.bubblenav.open .bubblemenuitem:nth-child(3){
  transform: translate(-165%, -130%) scale(1);
}
.bubblenav.open .bubblemenuitem:nth-child(4){
  transform: translate(-90%, -175%) scale(1);
}
.bubblenav.open .bubblemenuitem:nth-child(5){
  transform: translate(-5%, -175%) scale(1);
}
.bubblenav.open .bubblemenuitem:nth-child(6){
  transform: translate(65%, -130%) scale(1);
}
.bubblenav.open .bubblemenuitem:nth-child(7){
  transform: translate(100%, -50%) scale(1);
}
.bubblenav .hamburger{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 40px;
  height: 30px;
  cursor: pointer;
  z-index: 3;
}
.bubblenav .hamburger::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* background: #202020; */
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)!important; */
  /*background: linear-gradient(-45deg, #ee9e94de, #d4729bd5, #466ca4dc, #23d5abc9);*/
  z-index: 1;
}

.bubblenav .hamburger .hamburgerline{
  z-index: 2;
  width: 100%;
  height: 5px;
  background: #ffffff;
  transition: 0.3s;
  transform-origin: center;
}
.bubblenav.open .hamburger .hamburgerline:nth-child(1){
  /* display: none; */
  transform: translate(0, 250%) rotate(45deg);
}
.bubblenav.open .hamburger .hamburgerline:nth-child(2){
  /* display: none; */
  transform: rotate(45deg);
}
.bubblenav.open .hamburger .hamburgerline:nth-child(3){
  /* display: none; */
  transform: translate(0, -250%) rotate(-45deg);
}

.submenulist{
  position: absolute;
  left: -90%;
  top: var(--top);
  transition: 0.3s;
}
.submenulist.submenuactive{
  left: 0%;
}
.menulist{
  position: absolute;
  left: 0%;
  top: var(--top);
  transition: 0.3s;
}
.menulist.submenuactive{
  left: -80%;
}


.visitordrawercontainer{
  position: fixed;
  left: 0%;
  width: 150px;
  text-decoration: none;
  z-index: 5;
  bottom: var(--btm);
  transition: 1s ease-in-out;
/* transition: left 0.5s ease-in-out,bottom 0.5s ease-in-out 0.5s; */
  /* transition: left 1s linear; */
}
.visitordrawercontainer.open{
  bottom: 70vh;
  left: 0%;
  /* transition: bottom 0.5s linear,left 0.5s linear 0.5s; */
}
.visitordrawercontainermini{
  position: fixed;
  left: -100px;
  width: 150px;
  text-decoration: none;
  z-index: 5;
}
.visitordrawer{
  position: absolute;
  transition: 0.5s;
  border-radius: 0 25px 25px 0;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
  transition: 1s;
}
.visitordrawermini{
  position: absolute;
  transition: 0.5s;
  border-radius: 0 25px 25px 0;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
  transition: 1s;
}
.visitordrawertoggle{
  border-radius: 0 25px 25px 0;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
}
/* .visitordrawer:hover {
  left: 100px;
  
} */
.recordcabinetdrawer{
  transition: 0.5s;
  border-radius: 0 5px 5px 0;
  width: 100%;
  left: 0px;
}
.recordcabinetdrawermini{
  transition: 0.5s;
  border-radius: 0 5px 5px 0;
  width: 100%;
  left: 0px;
}
.recordcabinetdrawer:hover {
  left: 100px;
}
.recordviewtabs{
  position: fixed;
  right: 0px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
.recordviewtabsmini{
  position: fixed;
  right: -100px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
/* .recordviewtabs:hover .recordviewtabssection {
  left: 50px;
} */
.recordviewtabssection{
  transition: 0.5s;
  border-radius: 5px 0 0 5px;
  width: 100%;
  right: 0px;
}
.recordviewtabssectionmini{
  transition: 0.5s;
  border-radius: 5px 0 0 5px;
  width: 100%;
  right: 0px;
}
.recordviewtabssection:hover {
  right: 100px;
}
.newdownsized {
  transform: scale(0.34);
  left: -32.5%;
}
/* .newdownsized {
  transform: scale(0.3);
  width: 350%;
  left: -125%;
  height: 200px;
} */
.downsized {
    -webkit-transform: scale(0.65); /* Saf3.1+, Chrome */
     -moz-transform: scale(0.65); /* FF3.5+ */
      -ms-transform: scale(0.65); /* IE9 */
       -o-transform: scale(0.65); /* Opera 10.5+ */
          transform: scale(0.65);
          max-height: 25vh;
          /* overflow: hidden; */
          /* width: 200%; */
             /* IE6–IE9 */
             /* filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand'); */
}
.alertsheet i{
  align-self: center;
}
.transtab .v-tabs-items {
  background-color: #FFFFFF00 !important;
}
.transtab .v-tabs-bar {
  background-color: #FFFFFF00 !important;
}

.stdmenubarbtn{
  border: none;
  padding: 15px 32px;
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 12px;
}
.stdwebmenubarbtn{
  border: none;
  /* padding: 15px 32px; */
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 12px;
}
.stdmenubarchildbtn{
  border: none;
  /* padding: 15px 32px; */
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  /* display: inline-block;
  margin: 4px 2px; */
  cursor: pointer;
  font-size: 12px;
}
.galleryoverlay{
  background-color: rgba(0, 0, 0, 0.768) !important;
}

/* .pagerow {
  background: linear-gradient(to left, rgba(250, 128, 114, 0) 50%, lightblue 50%);
  background-position: right;
  background-size: 200%;
  transition: .5s ease-out;
}
.pagerow:hover{
  background-position: left;
} */
.pagerow :hover{
  background-image: linear-gradient(to right, rgba(0,132,255, 0.2), rgb(0,132,255, 0.2));
}

.flipcard{
  padding-right:10px;
  padding-left:10px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1000px;
	        perspective: 1000px;
}

.flipcardfront,
.flipcardback{
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
  border-radius: 10px;
	background-position: center;
	-webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	text-align: center;
	min-height: 280px;
	height: auto;
	border-radius: 10px;
	color: #ffffff99;
	font-size: 1.2rem;
}

/* .flipcardback{
  background: #cedce7;
  background: -webkit-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  background: -o-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  background: linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  visibility: hidden; 
}*/

.flipcardfront:after{
	position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: 1;
    background-color: currentColor;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 10px;
}
.flipcard:hover .flipcardfront,
.flipcard:hover .flipcardback
{
    -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.flipcardback{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.flipcardinner{
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
            transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 2rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
            perspective: inherit;
    z-index: 2;
}

.flipcard .flipcardback{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flipcard .flipcardfront{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flipcard:hover .flipcardback{
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          /* visibility: visible; */
}

.flipcard:hover .flipcardfront{
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flipcardfront .flipcardinner p{
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.flipcardfront .flipcardinner p:after{
  content: '';
  width: 4rem;
  height: 2px;
  position: absolute;
  background: currentColor;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -.75rem;
}

.flipcardfront .flipcardinner span{
  color: rgba(255,255,255,0.7);
  font-family: 'Montserrat';
  font-weight: 300;
}
.gradoverlay{
  z-index: 0 !important;
}
.galcarousel .v-carousel__controls{
  display: none !important;
}
@keyframes imgfadeout {
  0% {
    opacity:1;
  }
  100% {
    opacity:1;
  }
}
@keyframes imgfadein {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.mainspecial{
  padding: 0px !important;
}
.unpaddedappbar .v-toolbar__content{
  padding: 0px !important;
}

.comparisonslider {
  position: absolute;
  left: 50%;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
}
.comparisonslider-line {
  position: absolute;
  inset: 0;
  width: .2rem;
  height: 100%;
  background-color: #fff;
  transform: translateX(-50%);
  pointer-events: none;
}

.comparisonslider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: .5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
}
.transtabs {
  background-color: rgba(255,255,255,0)!important;
}
.transtabs .v-tabs-items{
  background-color: rgba(255,255,255,0)!important;
}
.transtabs .v-tabs-bar{
  background-color: rgba(255,255,255,0)!important;
}

.title-ribbon{
    position: absolute;
    width: 106.0%;
    /* or width: 106.5%; */
    height: 55px;
    background: currentColor;
    left: -10px;
    box-shadow: 0px 3px 10px black;

}
.title-ribbon:before{
    content: "";
    position: absolute;
    border-left: 5px solid white;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    border-bottom: 5px solid transparent;
    right: 0px;
    bottom: -10px;
    filter: brightness(0);
}
.title-ribbon:after{
    content: "";
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid white;
    border-top: 5px solid white;
    border-bottom: 5px solid transparent;
    left: 0px;
    bottom: -10px;
    filter: brightness(0);
    
}
.transnav{
  background-color: mediumpurple;
}
.goldgradbg{
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)!important;
}
.goldgradtext{
  color: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)!important;
}
.goldbg{
  background: #FFD700!important
}
.goldtext{
  color: #FFD700!important
}
.bootstraphovercard .v-card{
  background-color: var(--stdcol);
  cursor: pointer;
}
.bootstraphovercard .v-card:hover{
  background-color: var(--hovcol)!important;
}
.bootstraphovercard :hover{
  color: var(--hovfontcol);
}
.bootstraphovercard .v-card__title{
  color: var(--stdfontcol)!important;
}
.bootstraphovercard :hover .v-card__title{
  color: var(--hovfontcol)!important;
}
.bootstraphovercard .v-card__text{
  color: var(--stdfontcol)!important;
}
.bootstraphovercard :hover .v-card__text{
  color: var(--hovfontcol)!important;
}
.bootstraphovercard .v-icon{
  color: var(--hovcol)!important;
}
.bootstraphovercard :hover .v-icon{
  color: var(--hovfontcol)!important;
}
.rahovercard {
  transition: all 0.5s ease-in-out;
  transform: scale(1);
}
.rahovercard :hover{
  transition: all 0.5s ease-in-out;
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  /* transform: rotate(90deg); */
}
.fancybtn{
  font-size:0.7em!important;
  border-radius: 0px!important;
}
.avacircle{
  position: relative;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: #cccccc00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avaimg{
  position: absolute;
  width: 150px;
  height: 150px;
  object-fit: cover;
  background-size: cover;
  border-radius: 50%;
}
.moving-clouds {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.moving-clouds2 {
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c2 80s linear infinite;


}
@keyframes c2 {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.bigslider .v-slider--horizontal .v-slider__track-container {
    height: 20px!important;
}
.bigslider .v-slider__thumb {
    width: 36px!important;
    height: 36px!important;
    left: -18px!important;
}
.btnhover {
  color: var(--col);
}
.btnhover:hover {
  background-color: var(--col);
  color: white;
}
.texbtn {
  border: none;
  padding: 10px 22px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}
.texbtn:hover {
  border: none;
  padding: 10px 22px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}
.bootstrap-form-input {
  padding: 6px!important;
}
.bootstrap-form-input .v-input:not(.v-input--has-state) .v-text-field__details{
  display: none!important;
}
.g-listitem{
  padding-left: 0px;
}
.g-listtitle{
  color: #202124;
  font-size: 14px;
  max-width: 200px;
  display: block;
  line-height: 20px;
  white-space: nowrap;
}
.g-listsubtitle{
  font-size: 12px;
  line-height: 18px;
  color: #4d5156;
  font-style: normal;
}
.g-header{
    font-family: arial,sans-serif;
    font-size: 20px;
    font-weight: 400;
}
.g-description{
    font-family: arial,sans-serif;
    font-size: 14px;
}

@keyframes YSpin3D {
	0% {transform: rotateY(0deg);}
	35% {transform: rotateY(360deg);}
  100% {transform: rotateY(360deg);}
}
.welcomefield{
  
}
/* .welcomefield .v-input__control .v-input__slot .v-input__prepend-inner .v-input__icon .v-icon{ */
.welcomefield .v-input__control .v-input__slot .v-input__prepend-inner{
  border: 0.5px solid currentColor;
  border-radius: 20px;
  padding: 0px;
  height: 40px; 
  width: 40px;
  margin-top: 0px!important;
  margin-left: -24px!important;
}
.welcomefield .v-input__control .v-input__slot .v-input__prepend-inner .v-input__icon{
  margin-top: 4px!important;
}
.welcomefield .v-input__control .v-input__slot .v-input__prepend-inner .v-input__icon .v-icon{
  height: 30px;
  width: 30px;
}
.welcomefield .v-input__control .v-input__slot .v-text-field__slot{
  padding-left: 4px;
}
.quickformtab .v-tab {
  font-size: 0.7em;
}
</style>



