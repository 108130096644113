<template>
  <div>
    <VacancySingle :Directories="Directories" :Editing="Editing" @ConfirmPublish="ConfirmPublish"
         :ActiveVacancy="ActiveVacancy" v-if="ActiveVacancy"
        :System="System" :SystemEntities="SystemEntities"
        />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import VacancySingle from '@/components/SuitePlugins/Vacancies/VacancySingle';

export default {
    props: ['System','SystemEntities'],
    components: {VacancySingle},
    data() {
        return {
            ActiveVacancy: '',
        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetActiveVacancy()
    },
    methods:{
        GetActiveVacancy(){
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('vacancies').doc(this.$route.params.id).onSnapshot(snapshot => {
                let data = snapshot.data()
                if(data){
                    this.ActiveVacancy = {...data,id: this.$route.params.id}
                }
                else{
                    this.$router.push('/404')
                }
            })
        },
    }
}
</script>

<style>

</style>



