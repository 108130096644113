<template>
  <v-card flat tile class="transparent">
    <v-dialog v-model="ImportingProgressDialog" fullscreen="">
      <v-card
        height="100%"
        width="100%"
        class="center"
        color="rgb(240, 240, 240, 0.95)"
      >
        <v-container bg fill-height grid-list-md text-xs-center>
          <v-layout row wrap align-center>
            <v-card-text class="justify-center">
              <h2 class="center">Importing Records</h2>
              <br />
              Now Importing -
              <div class="red--text">
                <p>{{ Importcount }}</p>
              </div>
              <v-layout class="justify-center">
                <div class="loader"></div>
              </v-layout>
              <v-layout row class="justify-center mx-3 my-3">
                <progress id="importprogressbar"></progress>
              </v-layout>
            </v-card-text>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog width="400" v-model="importdialog">
      <v-card>
        <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <span>{{ currentTableTitle }}</span>
      <v-icon class="soloactionicon" @click="closeImport()" color="red">mdi-close</v-icon>
      </v-card-title>
      
      <v-card-text  v-if="EditedTab.Name === 'Card Row'">
        
           <input
            v-if="!ImportFile"
            type="file"
            @change="onImportfileSelected($event)"
            ref="ImportFileinputter"
            id="fileUpload"
          />
          <v-btn @click="DownloadFieldsImportTemplate()">Download Template</v-btn>
      </v-card-text>
      <v-window v-if="EditedTab.Name !== 'Card Row'" v-model="tablemanagestep">
      <v-window-item :value="1">
        <v-card-text>
           <input
            v-if="!ImportFile"
            type="file"
            @change="onImportfileSelected($event)"
            ref="ImportFileinputter"
            id="fileUpload"
          />
        </v-card-text>
      </v-window-item>
      <v-window-item :value="2">
        <v-card-text>
          <v-list-item @click="SelectedHeader = head,tablemanagestep = 3" v-for="head in TabHeaders" :key="head.itemObjKey">
            {{head.DisplayName}}
          </v-list-item>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="3">
        <v-card-text v-if="SelectedHeader">
          <v-text-field v-model="SelectedHeader.DisplayName" label="Display Name"/>
           <v-combobox style="z-index:100;" v-model="SelectedHeader.Type" :items="['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
            'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
            'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Social Lookup','Custom Object']" label="Type"/>
          <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="{}" :WebForm="false" style="z-index:100;"
                :SystemEntities="SystemEntities" :field="SelectedHeader" :NewField="false" :System="System"
                />
        </v-card-text>
      </v-window-item>
      <v-window-item :value="4">
        <v-card-text>
          <table class="HTMLTable" style="width: 100%;">
              <tr>
                  <th class="HTMLTableContent caption"  style="word-break:break-all;" v-for="header in SampleTab.Headers" :key="header.itemObjKey">{{header.DisplayName}}</th>                                
                </tr>
                <tr v-for="record in SampleTab.Data" :key="record.itemObjKey">
                  <td class="HTMLTableContent caption" :style="header.Type === 'Single File Upload'  ? 'text-align: center;' : ''" v-for="header in SampleTab.Headers" :key="header.itemObjKey">
                            <span v-if="header.Primary"> 
                            <!-- <v-avatar tile>
                                <v-img contain
                                :src="record[{{content.WikiName.split(' ').join('')}}ImageField.value]"
                                />
                            </v-avatar>    -->
                        <span class="caption">
                            <FieldViewerComponent :field="header" :Record="record" v-if="PreviewMode" />
                            <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" v-if="!PreviewMode" :Record="record" :field="header" @onPhotoFileselected="onPhotoFileselected"
                                :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="'Name'" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                    />
                            </span>                   
                            </span>
                            <span v-if="!header.Primary">                        
                            <FieldViewerComponent :field="header" :Record="record" v-if="PreviewMode"  />
                            <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" v-if="!PreviewMode" :Record="record" :field="header" @onPhotoFileselected="onPhotoFileselected"
                                :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="'Name'" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                    />
                            </span>
                            </td>
                        </tr>
                    </table>
        </v-card-text>
      </v-window-item>
      </v-window>
        <!-- <v-card-text>
          <input
            v-if="!ImportFile"
            type="file"
            @change="onImportfileSelected($event)"
            ref="ImportFileinputter"
            id="fileUpload"
          />
        </v-card-text> -->
        <v-card-actions>
          <v-btn :disabled="tablemanagestep === 1" @click="tablemanagestep--" dark color="warning"> Back </v-btn>
          <v-spacer> </v-spacer>
          <v-btn
            :disabled="tablemanagestep === 4"
            color="primary"
            depressed
            @click="AdvanceTableManageStep()"
          >
            Next
          </v-btn>
          <v-btn v-if="tablemanagestep === 4"  @click="FinalizeDataImport(NewTabLines,'|',EditedTab)" dark color="success"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-subtitle class="background darken-1 mediumoverline">
      {{ EditedTab.Name }}
    </v-card-subtitle>
    <v-card-text>
      <v-list rounded v-if="EditedTab.Name === 'Timeline'">
        <v-btn
          style="width: 100%"
          @click="AddNewTimelineItem(EditedTab)"
          class="RAWebBtnGradlight"
          dark
          outlined
          >Add</v-btn
        >
        <v-list-item v-for="(item,itemindex) in EditedTab.Items" :key="item.itemObjKey">
          <v-list-item-title>
          {{ item.Title }}
          </v-list-item-title>
          <v-list-item-action>
            <v-switch dense prepend-icon="mdi-gesture-tap-button" v-model="item.HasActionButton"/>
            <!-- <v-btn x-small fab icon><v-icon>mdi-gesture-tap-button
              </v-icon>
            </v-btn> -->
          </v-list-item-action>
          <v-list-item-action >
            <v-btn x-small fab icon>
                    <v-icon x-small class="red--text"  @click="ConfirmremoveTimelineItem(itemindex)" >mdi-delete-forever</v-icon>
                    </v-btn>
          </v-list-item-action>
          <!-- here we need button that activates timeline item aciton button setup -->
        </v-list-item>
      </v-list>
       <v-btn @click="AddHUDItem(EditedTab)"
       v-if="EditedTab.Name === 'Parallax' && EditedTab.HasHUDItems">Add
        </v-btn>
      <v-expansion-panels v-if="EditedTab.HUDItems">
       
        <v-expansion-panel v-for="huditem in EditedTab.HUDItems" :key="huditem.itemObjKey">
          <v-expansion-panel-header>
         <v-icon left>{{huditem.Icon}}</v-icon>{{huditem.Title}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field v-model="huditem.Title"/>
            <v-text-field v-model="huditem.Icon"/>
            <v-textarea v-model="huditem.Description"/>
             <v-text-field type="number" v-model.number="huditem.Index"/>
            {{huditem.Description}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels rounded v-if="EditedTab.Name === 'HTML Table' || EditedTab.Name === 'Card Row'">
        <v-btn  v-if="!ImportFile" style="width: 100%;" @click="ActivateImportTableDialog()" class="RAWebBtnGradlight" dark outlined>Manage</v-btn>
        
        <v-select v-if="EditedTab.Name === 'HTML Table'" style="z-index:100;" v-model="EditedTab.TableHeaderColor" label="Color"
                :items="LibraryColors"
                />
        <v-btn v-if="NewTabLines.length > 0" style="width: 100%;" @click="FinalizeDataImport(NewTabLines,'|')" class="success" dark outlined>Finish Import</v-btn>
        <v-expansion-panel
          v-for="header in EditedTab.Headers"
          :key="header.itemObjKey"
        >
          <v-expansion-panel-header>
            {{ header.DisplayName }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            
           <v-text-field v-model="header.DisplayName" label="Display Name"/>
           <v-combobox style="z-index:100;" v-model="header.Type" :items="['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
            'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
            'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Social Lookup','Custom Object']" label="Type"/>
            <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="{}" :WebForm="false" style="z-index:100;"
                :SystemEntities="SystemEntities" :field="header" :NewField="false" :System="System"
                />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <HTMLTable :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
            :tab="PreviewTab" @OpenDialog="OpenDialog"
            />	 -->
      </v-expansion-panels>
      <v-expansion-panels rounded v-if="EditedTab.Name === 'Card Row'">
         <v-list-item>
          <v-switch v-model="EditedTab.SkipSyncFormatting" label="Style individually"/>
        </v-list-item>
         <v-list-item>
          <v-switch v-model="EditedTab.FromEasyProducts" label="Easy Products"/>
        </v-list-item>
         <v-list-item v-if="!EditedTab.FromEasyProducts && AdvertsDirectoryActive">
          <v-switch v-model="EditedTab.FromAdverts" label="From Adverts"/>
        </v-list-item>
         <v-list-item>
          <v-switch v-model="EditedTab.SkipWriteup" label="Skip Writeup"/>
        </v-list-item>
        <v-list-item v-if="EditedTab.FromEasyProducts">
          <v-autocomplete v-model="EditedTab.EasyProductCat" style="z-index: 100!important;"
          item-text="DisplayName" clearable return-object :items="AllProductCategories"
          label="Product Cat"/>
        </v-list-item>
        <v-list-item v-if="EditedTab.FromAdverts">
          <v-autocomplete v-model="EditedTab.AdvertCat" style="z-index: 100!important;"
          item-text="DisplayName" clearable return-object :items="AllProductCategories"
          label="Product Cat"/>
        </v-list-item>
        <!-- I agree and fully understand but what we should be doing ehre is showcase presets from rapresets col, period
        OKAY BUT HERE IS THE ISSUE WITH THAT - when we assign or select a preset, it adds the actualy preset content. 
        Yep that we DO need to challenge of course - but that's what we fix here then
          
        <v-list v-if="!EditedTab.SkipSyncFormatting">
          <v-list-item v-if="EditedTab.ChildrenStyle">
             
              there is another quesiton here, simply put can we not style the elements for the client? like have quick options?
              <v-select v-model="EditedTab.ChildrenStyle" label="Preset"
                :items="CardRowPresets" v-model="EditedTab.ChildrenStyle.Preset"
              />
          </v-list-item>
          how do we truly do this? Remember now,, it's not ot AHA
        </v-list> -->
        <!-- <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
            <v-chip
                style="z-index: 4;"
                class="ma-2"
                v-bind="attrs"
                v-on="{ ...menu }"
                color="deep-purple accent-4"
                outlined
                :disabled="false"
            >
                <v-icon left>
                {{ EditedTab.Icon }}
                </v-icon>
                {{ EditedTab.Preset_Name }}
                <v-icon right> mdi-chevron-down </v-icon>
            </v-chip>
            </template>
            <v-virtual-scroll
            width="350"
            :items="FilteredRAStorePagePresets(EditedTab,true)"
            :item-height="380"
            height="450"
            class="background"
            >
            <template v-slot:default="{ item }">
                <v-list-item dense class="overline" >
                <v-avatar tile size="35">
                        <v-icon size="35">{{item.Icon}}</v-icon>
                    </v-avatar> {{item.Preset_Name}}
            </v-list-item>
                <v-list-item class="RAWebBtnGradlightsoft"
                dense
                  style="border-radius: 15px;"
                @click="UpdateTabPreset(EditedTab, item, i)"
                >
                <PageTabRenderer
                    :PageHeadersFont="PageHeadersFont"
                    :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize"
                    :System="System"
                    :SystemEntities="SystemEntities"
                    :tab="item"
                    :i="i"
                    :downsized="true"
                    :SampleOnly="true"
                />
                </v-list-item>
            </template>
            </v-virtual-scroll>
        </v-menu> -->
        <v-expansion-panel v-for="(elmnt,elmntindex) in EditedTab.Elements" :key="elmnt.itemObjKey">
          <v-expansion-panel-header>
            {{elmnt.Title}} - {{elmnt.SubTitleTop}} - {{elmnt.SubTitleBottom}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn @click="ConfirmDeleteChild(elmnt,elmntindex)" width="100%" color="red" outlined>Delete</v-btn>
            <v-text-field v-model="elmnt.Title" label="Title"/>
            <v-text-field v-model="elmnt.SubTitleTop" label="Subtitle 1"/>
            <v-text-field v-model="elmnt.SubTitleBottom" label="Subtitle 2"/>
            <ContentEditableField
              :FieldObject="elmnt" :FieldName="'Description'"
              :FieldValue="elmnt.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>







<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";
import PhotoLibraryViewer from "@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer";
import HTMLTable from '@/components/WebPages/RenderComponents/HTMLTable';
import FieldConfig from '@/components/Database/Fields/FieldConfig';
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import PageTabRenderer from '@/components/WebPages/PageTabRenderer.vue';
export default {
  props: [
    "System",
    "SystemEntities",
    "EditedTab",
    "Name",
    "GalleryLibraries",
    "WriteupEditorid",
    "WriteupUpdateMethod",
    "SystemAssets",
    "FontOptions",
    "RelatedObj",
    "DynamicWikiData",
    "AdvancedComputedLibraryColors",
    "DataCountCollections",
    "TableEmbedTables",
    "UseGroupAssets",
    "WebFormEntities",
    "WikiType",
    "WikiBooleanProperties",
    "CurrentEntity",
    "FieldTypes",
    "RenderedContentBlocks",
    "EntityFieldsandFeatures",
  ],
  components: { PhotoLibraryViewer,HTMLTable,FieldConfig,FieldViewerComponent,FieldValueEditerComponent,ContentEditableField,PageTabRenderer },
  data() {
    return {
      LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
      SampleTab: {},
      SelectedHeader: '',
        tablemanagestep: 1,
        NewTabLines: [],
      NewImportsArray: [],
      PartialFailuresArray: [],
      SuccessfulImportsArray: [],
      CSVResult: [],
      Importcount: "",
      ImportHeaders: [],
      ImportFile: "",
      importdialog: false,
      WrappingOptions: [
        {
          DisplayName: "Top Left",
          FlexValue: "align-self:flex-start",
          LayoutValue: "fill-height justify-start",
          JustifyIcon: "mdi-format-align-left",
        },
        {
          DisplayName: "Top Center",
          FlexValue: "align-self:flex-start",
          LayoutValue: "fill-height justify-center",
          JustifyIcon: "mdi-format-align-center",
        },
        {
          DisplayName: "Top Right",
          FlexValue: "align-self:flex-start",
          LayoutValue: "fill-height justify-end",
          JustifyIcon: "mdi-format-align-right",
        },
        {
          DisplayName: "Center Left",
          FlexValue: "align-self:center",
          LayoutValue: "fill-height justify-start",
          JustifyIcon: "mdi-format-align-left",
        },
        {
          DisplayName: "Absolute Center",
          FlexValue: "align-self:center",
          LayoutValue: "fill-height justify-center",
          JustifyIcon: "mdi-format-align-center",
        },
        {
          DisplayName: "Center Right",
          FlexValue: "align-self:center",
          LayoutValue: "fill-height justify-end",
          JustifyIcon: "mdi-format-align-right",
        },
        {
          DisplayName: "Bottom Left",
          FlexValue: "align-self:flex-end",
          LayoutValue: "fill-height justify-start",
          JustifyIcon: "mdi-format-align-left",
        },
        {
          DisplayName: "Bottom Center",
          FlexValue: "align-self:flex-end",
          LayoutValue: "fill-height justify-center",
          JustifyIcon: "mdi-format-align-center",
        },
        {
          DisplayName: "Bottom Right",
          FlexValue: "align-self:flex-end",
          LayoutValue: "fill-height justify-end",
          JustifyIcon: "mdi-format-align-right",
        },
      ],
      rules: {
        telnr: (value) => {
          const pattern =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
          return pattern.test(value) || "Not a telephone number.";
        },
        min8Chars: (value) => value.length >= 8 || "Min. 8 characters",
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const urlpattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          );
          return urlpattern.test(value) || "Invalid Link.";
        },
      },
    };
  },
  computed: {
    PluginDataBase(){
          return this.$store.state.PluginDataBase
        },
    AdvertsDirectoryActive(){
      return this.PluginDataBase && this.PluginDataBase.Advert_Directory && this.PluginDataBase.Advert_Directory.Active
    },
      AllProductCategories(){
        return this.ProductsCatsLevel3.concat(this.ProductsCatsLevel2,this.ProductCategories)
      },
    ProductsCatsLevel3(){
          return this.ProductsCatsLevel2.filter(cat => {
              return cat.Options
          }).map(cat => {
              return cat.Options
          }).flat()
      },
      ProductsCatsLevel2(){
          return this.ProductCategories.filter(cat => {
              return cat.Options
          }).map(cat => {
              return cat.Options
          }).flat()
      },
    CategoryField(){
        return this.EditedTab.FromEasyProducts && this.ActiveStore && this.ActiveStore.CategoryField ? 
        this.ActiveStore.CategoryField : this.EditedTab.FromAdverts && this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
        this.AdvertDirectory.CategoryField : ''
      },
      AdvertDirectory(){
            return this.$store.state.AdvertDirectory
        },
      ActiveStore(){
        return this.$store.state.ActiveStore
      },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
    CardRowPresets(){
      // (WRITEUP)  ['FontBGColor','FontBlendMode','FontUnderline','FontUnderlineColor','FontColor',
      //           'FontSize','WriteupStyle','WriteupFont','HasArticleCols','ColsWrapBox','ColsBorder','ColsBorderWidth','ColsBorderRadius',
      //           'HiglightInitialLetter','TotalArticleCols']
      // (TITLE)    ['DisplayasChip','ChipColor','HeaderFontSize','HeaderColor','HeaderFontFamily','TitleTagDark','FancyTitle',
      //           'SubTitleTopColor','SubTitleBottomColor',
      //           'LabelGradient','HeaderBGColor','HeaderBlendMode','Justify','CenteredHeader','TitleTag','TitleasCurrency']
      // (IMG)      ['Height','FullHeight','IMGonTop','IMGBGColor','DisableContain','IMGCustomStyle',
      //           'Width','ContainIMG','Tile','HasParallaxGradient','Transparent','IMGBlendMode']
      // Style 1 - store card (Bootstrap Boxes, TitleTag - true) - edit options - 
      //Title - TitleTagDark, TitleasCurrency, SubTitleTopColor, SubTitleBottomColor,
      //IMG  - Clear Icons, Height, IMGBGColor, Disable Contain, IMGCustomStyle
      //Writeup - FontSize, WriteupStyle,FontBGColor, FontColor 

      //Style 2 - Gym Card Services (Bootstrap Boxes, insert CardIcon, TitleTag - false,)

      //Style 3 - 3D Carousel FLIP

      //Style 4 - Default (STD Cards)

      //Style 4 - STD IMG Top

      //Style 5 - 3D Carousel CPP style

      //Style 6 - Magazine Style (fucking horrible one this)

      //Style 7 - Hover Cards
      //are we redoing everything here? Is all of this not relevant to "Expand Content" being "advanced" options but yeah they suck there too

      //right there is another bell that just went off, I could take different presets based on the style you selected
      //fuck no! tht is EXACTLY the issue. It makes very little sense Bob. I really truly do not know how to configure this shit myself either there are many options
      //Okay let's ask this - perhaps we redoing presets which is big nono. you get?
      //That's true, that is absolutely true. Yes we doing that and we should not. okay cool
    },
    TabHeaders(){
      return this.EditedTab && this.EditedTab.Name === 'Card Row' ? 
      this.CardRowHeaders : this.EditedTab.Headers
    },
    currentTableTitle(){
      switch (this.tablemanagestep) {		
          case 1:		
            return 'Selected Import'		
          case 2:		
            return 'Selected Header'	
          case 3:		
            return 'Edit '+this.SelectedHeader.Name
          case 4:		
            return 'Finish'	
        }	
    },
  },
  created() {
    if (this.EditedTab.Name === "HTML Table" && this.EditedTab.Headers) {
      this.ImportHeaders = this.EditedTab.Headers.map((head) => {
        return head.Name;
      });
    }
    else if(this.EditedTab.Name === 'Card Row' && !this.EditedTab.ChildrenStyle){
      this.EditedTab.ChildrenStyle = {}
    }
  },
  methods: {
    ConfirmDeleteChild(elmnt,elmntindex){
      confirm('This will delete this elements, there is no undo. Are you sure?') && this.DeleteChild(elmnt,elmntindex)
    },
    DeleteChild(elmnt,elmntindex){
      this.$emit('DeleteChild',elmnt,elmntindex)
    },
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        },
    DownloadFieldsImportTemplate(){
            this.$emit('DownloadFieldsImportTemplate')
        },
    AddHUDItem(EditedTab){
      let newitem = {
        Title: 'Home',
        Icon: 'mdi-home',
        Description: 'More About Us',
        Index: 1
      }
      if(!EditedTab.HUDItems){
        EditedTab.HUDItems = []
      }
      EditedTab.HUDItems.push(newitem)
    },
    ConfirmremoveTimelineItem(itemindex){
      confirm('This will remove the timeline item, and cannot be undone. Are you sure?') && this.RemoveTimelineItem(itemindex)
    },
    RemoveTimelineItem(itemindex){
      this.EditedTab.Items.splice(itemindex,1)
    },
    AdvanceTableManageStep(){
      if(this.tablemanagestep === 3){
        this.SampleTab = JSON.parse(JSON.stringify(this.EditedTab))
        this.FinalizeDataImport(this.NewTabLines,'|',this.SampleTab,true)
        this.tablemanagestep++
      }
      else{
        this.tablemanagestep++
      }      
    },
    onImportfileSelected(event) {
      console.log(this.EditedTab)
      this.ImportFile = event.target.files[0];
      if(this.EditedTab.Name === 'Card Row'){
        console.log('emits?')
      this.$emit('PrepareImport',this.ImportFile)
      }
      else{
      this.PrepareImport()
      this.tablemanagestep = 2  
      }
      
    },
    closeImport() {
      this.importdialog = false;
      this.ImportFile = null;
      this.NewTabLines = []
      this.tablemanagestep = 1
    },
    PrepareImport() {
      //   this.ImportingProgressDialog = true
      //   setTimeout(() => {
      //   const totalprogresselement = document.getElementById('importprogressbar')
      //   totalprogresselement.setAttribute('max','100')
      //   totalprogresselement.setAttribute('value',1)
      // }, 200);
      this.Importcount = "Preparing Import";
      let importLocalURL = URL.createObjectURL(this.ImportFile);
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this;
      reader.addEventListener("load", function (e) {
        var text = e.target.result;
        vm.CSVConvertToJSON(text);
      });
    },
    CSVConvertToJSON(text) {
      this.NewImportsArray = [];
      this.PartialFailuresArray = [];
      //console.log(text)
      let lines = text.split("\n");
      //console.log(lines[0])
      this.ConversionCSVtoJJSON(lines, "|");
    },
    ConversionCSVtoJJSON(lines, delimiter) {
      let dataonly = Object.assign([], lines);
      dataonly.splice(0, 1);
      //console.log(dataonly)
      this.CSVResult = [];
      this.ImportHeaders = lines[0].split(delimiter).map((head) => {
        return head.split("\r")[0];
      });
      //console.log('this.ImportHeaders')
      //console.log(this.ImportHeaders)
      let finalindex = this.ImportHeaders.length - 1;
      let finalheader = this.ImportHeaders[finalindex];
      // console.log(finalheader,this.ImportHeaders)
      if (finalheader.includes("id")) {
        // if(finalheader === 'id'){
        this.Reimport = true;
        confirm("We picked up this is a Reimport. Shall you continue?") &&
          this.ProceedReimport(lines, delimiter);
      } else {
        this.NewTabLines = lines
        this.EditedTab.Headers = this.ImportHeaders.map((head) => {
          let headobj = {
            id: head.split(" ").join("_"),
            Name: head.split(" ").join("_"),
            DisplayName: head,
            Type: "Single Line Text",
          };
          return headobj;
        });
        
      }
    },
    FinalizeDataImport(lines, delimiter,tab,sample){
        //console.log(lines)
        for (var i = 1; i - 1 + 2 < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(delimiter);
          for (var j = 0; j < this.ImportHeaders.length; j++) {
            obj[this.ImportHeaders[j]] = currentline[j];
              obj[this.ImportHeaders[j]].split("\r")[0];
              if(tab.Headers[j] && tab.Headers[j].Type === 'Date' && obj[this.ImportHeaders[j]]){
                obj[this.ImportHeaders[j]] = obj[this.ImportHeaders[j]].split('/').join('-')
            }
          }
          let resultcheck = this.CSVResult.find((res) => res.Name == obj.Name);
          this.CSVResult.push(obj);
        }
        this.Importcount = "Preparing " + this.CSVResult.length + " records...";
        this.CSVResult.map((res, i) => {
          this.UpdateTabTable(res, i,tab,sample);
        });
    },
    UpdateTabTable(res, i,tab,sample) {
      tab.Data.push(res);
      //console.log(res, i, this.ImportHeaders, this.EditedTab);
      // this.ImportFile = ''
      // this.importresultsdialog = true
      // this.importdialog = false
      // this.Import.Name = ''
      // this.ImportingProgressDialog = false
      if(i-1+2 === this.CSVResult.length && !sample){
        this.closeImport()
      }
    },
    ActivateImportTableDialog() {
      this.importdialog = true;
    },
    AddNewTimelineItem(EditedTab) {
      this.Onepropemit("AddNewTimelineItem", EditedTab);
    },
    AssignGalleryAssets(EditedTab, asset) {
      this.Twopropemit("AssignGalleryAssets", EditedTab, asset);
    },
    TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
    WriteupUpdate(WriteupUpdateMethod) {
      this.NoPropsemit(WriteupUpdateMethod);
    },
    AssignImage(tab, prop, asset, IsThis) {
      this.$emit("AssignImage", tab, prop, asset, IsThis);
    },
    NoPropsemit(MethodName) {
      this.$emit("NoPropsemit", MethodName);
    },
    TabPropEmit(MethodName) {
      this.$emit("TabPropEmit", MethodName);
    },
    Onepropemit(MethodName, prop) {
      console.log(MethodName, prop);
      this.$emit("Onepropemit", MethodName, prop);
    },
    Twopropemit(MethodName, firstprop, secondprop) {
      this.$emit("Twopropemit", MethodName, firstprop, secondprop);
      setTimeout(() => {
        this.TypeChange = "";
      }, 50);
    },
    Threepropemit(MethodName, firstprop, secondprop, thirdprop) {
      this.$emit("Threepropemit", MethodName, firstprop, secondprop, thirdprop);
    },
  },
};
</script>

<style>
</style>
