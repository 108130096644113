<template>
  <div>
    <v-row class="justify-start">                
            <v-col :style="'flex-basis: auto;'"
            :xl="PreventRating? 12 : 6" :lg="PreventRating? 12 : 6" :md="PreventRating? 12 : 6" :sm="PreventRating? 12 : 6" :xs="PreventRating? 12 : 6">
            <v-autocomplete v-if="PreventAdd && !PreventMultiple" outlined :placeholder="ObjectProp+' for '+RecordType"
                @change="RecordNewKeywords(ObjectProp)"
                v-model="SelectedKeyWords"
                :items="SiteKeyWordbyType"
                :label="ObjectProp"
                return-object
                item-text="Name"
                multiple
                chips
                >
                <template v-slot:selection="{ item }">
                    <v-chip @click="ActivateOptionConfig(item)">
                        {{item.Name ? item.Name : item}}
                    </v-chip>
                    <!-- <v-list-item v-for="item in data" :key="item.itemObjKey">
                        {{item.Name ? item.Name : data}}
                    </v-list-item> -->
                </template>
                </v-autocomplete>
                <v-combobox v-if="!PreventAdd && !PreventMultiple" outlined :placeholder="ObjectProp+' for '+RecordType"
                @change="RecordNewKeywords(ObjectProp)"
                v-model="SelectedKeyWords"
                :items="SiteKeyWordbyType"
                :label="ObjectProp"
                return-object
                item-text="Name"
                multiple
                chips
                >
                <template v-slot:selection="{ item }">
                    <v-chip @click="ActivateOptionConfig(item)">
                        {{item.Name ? item.Name : item}}
                    </v-chip>
                    <!-- <v-list-item v-for="item in data" :key="item.itemObjKey">
                        {{item.Name ? item.Name : data}}
                    </v-list-item> -->
                </template>
                </v-combobox>
                <v-autocomplete v-if="PreventAdd && PreventMultiple" outlined :placeholder="ObjectProp+' for '+RecordType"
                @change="RecordNewKeywords(ObjectProp)"
                v-model="SelectedSingleKeyword"
                :items="SiteKeyWordbyType"
                :label="ObjectProp"
                return-object
                item-text="Name"
                chips
                >
                <template v-slot:selection="{ item }">
                    <v-chip @click="ActivateOptionConfig(item)">
                        {{item.Name ? item.Name : item}}
                    </v-chip>
                    <!-- <v-list-item v-for="item in data" :key="item.itemObjKey">
                        {{item.Name ? item.Name : data}}
                    </v-list-item> -->
                </template>
                </v-autocomplete>
                <v-combobox v-if="!PreventAdd && PreventMultiple" outlined :placeholder="ObjectProp+' for '+RecordType"
                @change="RecordNewKeywords(ObjectProp)"
                v-model="SelectedSingleKeyword"
                :items="SiteKeyWordbyType"
                :label="ObjectProp"
                return-object
                item-text="Name"      
                chips
                >
                <template v-slot:selection="{ item }">
                    <v-chip @click="ActivateOptionConfig(item)">
                        {{item.Name ? item.Name : item}}
                    </v-chip>
                    <!-- <v-list-item v-for="item in data" :key="item.itemObjKey">
                        {{item.Name ? item.Name : data}}
                    </v-list-item> -->
                </template>
                </v-combobox>
            </v-col>
            <v-col :style="'flex-basis: auto;'" v-if="!PreventRating"
            :xl="6" :lg="6" :md="6" :sm="6" :xs="6"> 
                <!-- style="position: absolute;bottom: 10px;width:45%;" -->
                <div  v-if="ActiveOption">
                    <v-slider v-model="ActiveOption.Value" :label="ActiveOption.Name" min="0" max="100" thumb-label="always"/>
                    <v-btn @click="UpdateOptionValue('Value')" outlined>Update</v-btn>
                </div>                                   
            <SkillsList style="margin-left: 0px!important;"
            v-if="ObjectSelection" :i="0" :IsElement="true" :tabindex="0" :rowindex="0"
            :tab="ComputedDefaultElement"
            />
            </v-col>
        </v-row>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SkillsList from '@/components/WebPages/RenderComponents/SkillsList';
export default {
    props: ['System','SystemEntities','RecordObj','Recordid','ObjectProp','KeywordType','RecordType','PreventAdd','PreventRating','PreventMultiple'],
    components: {SkillsList},
    data() {
        return {
            ActiveOption: '',
            ActiveOptionIndex: -1,
            SelectedKeyWords: [],
            SelectedSingleKeyword: '',
            DefaultElement: {	
                // FromColor: {rgb: {r: 255,g: 0,b: 0}},
                // ToColor: {rgb: {r: 0,g: 255,b: 0}},
                StaticVariables: ['Title','Description','ListItems'],					
            Category: 'Lists',							
                RowColor: '#00ffff',									
                Name: 'Skills List',									
                ShowComponents: true,									
                Icon: 'mdi-account-details',									
                // Title: this.ObjectProp,	
                Title: 'How I rate Myself',
                ListItems: [],		
                InlineItems: true,						
                Description: `Here are some of my Characteristics.`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Elevation: 0,								
            },
        }
    },	
    computed:{
        ObjectSelection(){
            return this.RecordObj[this.ObjectProp]
        },     
        SiteKeyWordOptions(){
            return this.$store.state.SiteKeyWordOptions
        },
        SiteKeyWordbyType(){
            return this.SiteKeyWordOptions.filter(kw => {
                return kw.Type.ID === this.KeywordType.ID
            })
        },
        KeywordItems(){
            return this.RecordObj[this.ObjectProp].Characteristics
        },
        ComputedDefaultElement(){
            let elmnt = this.DefaultElement
            elmnt.ListItems = this.ObjectSelection ? 
            this.ObjectSelection.map(char => {
                let charobj = {
                    Title: char.Name
                }
                if(typeof char.Value === 'undefined') {
                    charobj.Value = 50
                }
                else{
                    charobj.Value = char.Value
                }
                return charobj
            }) : 
            []
            return elmnt
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        ObjectSelection: {
            handler: function(newvalue, oldvalue) {
              //console.log('newvalue, oldvalue',newvalue, oldvalue,this.ObjectProp)
              if(newvalue !== oldvalue && !this.PreventMultiple){
                 this.SelectedKeyWords = JSON.parse(JSON.stringify(newvalue))
              }
              else if(newvalue !== oldvalue && this.PreventMultiple){
                 this.SelectedSingleKeyword = JSON.parse(JSON.stringify(newvalue))
              }
            },
            deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(this.RecordObj && this.RecordObj[this.ObjectProp] && !this.PreventMultiple){
            this.SelectedKeyWords = this.RecordObj[this.ObjectProp]
        }
        else if(this.RecordObj && this.RecordObj[this.ObjectProp] && this.PreventMultiple){
            this.SelectedSingleKeyword = this.RecordObj[this.ObjectProp]
        } 
    },
    methods:{
        UpdateOptionValue(prop){
            this.RecordObj[this.ObjectProp][this.ActiveOptionIndex][prop] = this.ActiveOption[prop]
            this.$emit('UpdateRecordKeywords',{[this.ObjectProp]: this.RecordObj[this.ObjectProp]})
            this.DeactivateOptionConfig()
        },
        DeactivateOptionConfig(){
            this.ActiveOption = ''
            this.ActiveOptionIndex = -1
        },
        ActivateOptionConfig(item){
            this.ActiveOption = item
            if(!this.RecordObj[this.ObjectProp]){
                this.RecordObj[this.ObjectProp] = []
            }
            let itemobj = this.RecordObj[this.ObjectProp].find(obj => obj.Name === item.Name)
            this.ActiveOptionIndex = this.RecordObj[this.ObjectProp].indexOf(itemobj)
        },
        PerKeywordRegistration(kw){ 
            let vm = this
            return new Promise(function(resolve, reject) {
            let path = db.collection('Websites').doc(vm.$store.state.ActiveSuiteid).collection('sitekeywords')
            let ref = path.doc()
            let newdocid = ref.id
            let user = vm.userLoggedIn
            let ownerobj = {          
                    Created_By: {Full_Name: user.Full_Name, id: user.id},
                    Created_Byid: user.id,
                    Owner: {Full_Name: user.Full_Name, id: user.id},
                    Ownerid: user.id,
                    Created_On: new Date(),
                    Modified_By: {id: user.id, Name: user.Name, Surname: user.Surname, Full_Name: user.Full_Name},
                    Modified_Byid: user.id,
                    Modified_On: new Date(),
            }
            let kwobj = {
                Name: kw,
                Type: vm.KeywordType,
                Original_Recordid: vm.Recordid,
                ...ownerobj,
                id: newdocid
            }
            //console.log(kwobj,vm.RecordObj)
                path.doc(newdocid).set(kwobj).then(newkwdoc => {
                    resolve(newkwdoc)
                })
            })
        },
        RecordSingleKeyword(prop){
            let vm = this
           if(this.SelectedSingleKeyword && !this.SelectedSingleKeyword.id){
                this.PerKeywordRegistration(vm.SelectedSingleKeyword).then(newkwdoc => {
                let updobj = {
                    [prop]: vm.SelectedSingleKeyword
                        }
                    vm.$emit('UpdateRecordKeywords',updobj) 
                })
           }
           else{
            let updobj = {
                [prop]: this.SelectedSingleKeyword
            }
           this.$emit('UpdateRecordKeywords',updobj)
           }
           
        },
        RecordNewKeywords(prop){
            if(this.PreventMultiple){
                this.RecordSingleKeyword(prop)
            }
            else{
                let updobj = {
                    [this.ObjectProp]: []
                }
                updobj[this.ObjectProp] = this.SelectedKeyWords
                let unregisteredkeywords = updobj[this.ObjectProp].filter(kw => {
                    return !kw.id
                })
            if(unregisteredkeywords.length > 0){
                unregisteredkeywords.map((kw,kwindex) => {
                    let vm = this
                    this.PerKeywordRegistration(kw).then(newkwdoc => {
                        let sitekwobj = updobj[this.ObjectProp].find(obj => obj === kwobj.Name)
                        let index = updobj[this.ObjectProp].indexOf(sitekwobj)
                        updobj[this.ObjectProp].splice(index,1,kwobj)
                        if(kwindex-1+2 === unregisteredkeywords.length){
                            this.$emit('UpdateRecordKeywords',updobj)
                        }
                    })
                })
            }
            else{
            this.$emit('UpdateRecordKeywords',updobj)
            }
            }
            
        },
    }
}
</script>

<style>

</style>



