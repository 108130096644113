<template>
    
  <v-dialog v-model="NewCompanyDialog" max-width="450" min-width="300">
 <v-card tile>
    <v-card-title class="overline justify-end">
    <v-btn absolute right small @click="DeactivateNewCompanyDialog()" style="z-index:100!important;" fab icon><v-icon small>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-tabs v-model="NewCompanyDialTab" class="quickformtab" centered>
      <v-tab>Select Company
      </v-tab>
      <v-tab-item>
        <v-card-text>
          <v-text-field append-icon="mdi-magnify" dense outlined clearable v-model="CompanySearch" label="Type to Filter..."/>
          <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="AllMemberCompanies"
                    :item-height="55"
                    :height="220"
                  >
                  <template v-slot:default="{ item }">
                  <v-list-item>
            <v-list-item-avatar>
                <v-img :src="item.Logo">
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
          {{item.Company_Name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{item.Owner.Full_Name}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn fab icon @click="EditCompany(item)"><v-icon>mdi-cogs</v-icon></v-btn>
            </v-list-item-action>
            <v-list-item-action>
                <v-btn @click="SelectCompany(item)">Select</v-btn>
            </v-list-item-action>
        </v-list-item>
                  </template>
          </v-virtual-scroll>        
        </v-card-text>
      </v-tab-item>
      <v-tab>{{NewCompany.id ? 'Edit Company' : 'Add Company'}}
      </v-tab>
      <v-tab-item>
        <v-card-text>
          <v-list-item>
            <UserLookup :UsersLookupArray="OwnerUsersArray" :ModelProp="'Owner'" :RecordObj="NewCompany" @UpdateUserLookupProp="UpdateUserLookupProp"
              :FieldLabel="OwnerField.DisplayName" :readonly="UserisGuest" :IsMultiple="false" :IDSOnly="false"
              :Rules="OwnerField.IsMandatory && !UserisGuest? [$store.state.formrules.required] : []"
              />
          </v-list-item>
          <v-list-item>
            <UserLookup :UsersLookupArray="OwnerUsersArray" :ModelProp="'Members'" :RecordObj="NewCompany" @UpdateUserLookupProp="UpdateUserLookupProp"
              :FieldLabel="'Shared With'" :readonly="NewCompany.Ownerid && NewCompany.Ownerid !== userLoggedIn.id" :IsMultiple="true" :IDSOnly="true"
              :Rules="NewCompany.Ownerid && NewCompany.Ownerid === userLoggedIn.id? [$store.state.formrules.required] : []"
              />
          </v-list-item>
          <v-row class="justify-center">
          <v-flex class="xs12 sm6 md6 lg6 xl6">
            <SimpleImageCard :FieldProp="'CompanyLogo'" v-if="NewCompanyDialog"
            :System="System" :SystemEntities="SystemEntities"
            :col="AvatarFieldCol" :Record="NewCompany" :CanEdit="true" @onPhotoUpload="onPhotoUpload"
                />
          </v-flex>
          <v-flex class="xs12 sm6 md6 lg6 xl6">
            <v-text-field dense outlined v-model="NewCompany.Company_Name" label="Customer name"/>
           <v-text-field dense outlined v-model="NewCompany.Email_Address" label="Email address"/>
            <v-text-field dense outlined v-model="NewCompany.Phone_Number" label="Phone number"/>
          </v-flex>
          </v-row>
          <v-row class="justify-center">
          <v-flex class="xs12 sm12 md12 lg12 xl12">
             <v-text-field dense outlined v-model="NewCompany.Physical_Address" label="Physical address (optional)"/>
              <v-text-field dense outlined v-model="NewCompany.VAT_Number" label="VAT Number (optional)"/>
              <!-- slogan, tel nr, logo, bus hr, descr... -->
          </v-flex>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn dark color="success" @click="AddNewCompany()">{{NewCompany.id ? 'Update Company' : 'Add Company'}}</v-btn>
        </v-card-actions>
      </v-tab-item>
    </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SimpleImageCard from '@/components/Database/Fields/Hybrid/SimpleImageCard'
import UserLookup from '@/components/General/UserLookup'
export default {
    props: ['System','SystemEntities','SubscriptionPackages','Directories',
    'AdvertsCollectionRef','ActiveProduct'],
    components: {SimpleImageCard,UserLookup},
    data() {
        return {
          Now: '',
          NewCompanyDialTab: 0,
          CompanySearch: '',
          NewCompanyDialog: true,
          NewCompany: {

          },
          DefaultNewCompany : {

          },
        DefaultCol: {
                Flat: true,
                Transparent: true,
                Elevation: 0,
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 12,									
                FlexXSmall: 12,
                Name: 'Some Col',
            },
        }
    },	
    computed:{
      OwnerField(){
        return {id: 'Owner',Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',ReadOnly: this.UserisGuest,
        LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User',Array: this.OwnerUsersArray, 
        RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: true, 
        IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
      },
      UserisGuest(){
      return this.$store.state.UserisGuest && this.userLoggedIn 
      
    },
    OwnerUsersArray(){
      return this.UsersArray.map(user => {
        let userobj = {}
        let props = ['id','Full_Name','Name','Surname','Profile_Photo','Email','Mobile_Number']
        props.map(prp => {
          if(typeof user[prp] !== 'undefined'){
            userobj[prp] = user[prp]
          }
        })
        return userobj
      })
    },
      UsersArray(){
        return this.$store.state.UsersArray
      },
        MyMemberCompanies(){
            return this.$store.state.MyMemberCompanies
        },
        MemberCompaniesOther(){
            return this.$store.state.MemberCompaniesOther
        },
        AllMemberCompanies(){
            return this.MyMemberCompanies.concat(this.MemberCompaniesOther).filter(comp => {
                return !this.CompanySearch || comp.Company_Name.toLowerCase().includes(this.CompanySearch.toLowerCase())
            })
        },
      AvatarFieldCol(){            
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'            
            col.Field = {
                id: 'Logo',
                Name: 'Logo',
                DisplayName: 'Photo (MUST be 400 x 400)',
                Type: 'Single File Upload',
                FeaturedPhoto: true
            }
            col.FieldObj = col.Field
            return col
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ComputedStoreProducts(){
          return this.StoreProducts
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      OwnerObj(){
        let user = this.userLoggedIn
        let ownerobj = {          
                Created_By: {Full_Name: user.Full_Name, id: user.id},
                Created_Byid: user.id,
                // Owner: {Full_Name: user.Full_Name, id: user.id},
                // Ownerid: user.id,
                Created_On: this.Now,
                Modified_By: {id: user.id, Name: user.Name, Surname: user.Surname, Full_Name: user.Full_Name},
                Modified_Byid: user.id,
                Modified_On: this.Now,
        }
        return ownerobj
      },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignCompanyLogo']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.DefaultNewCompany.Owner = Object.assign({},this.OwnerObj.Modified_By)
        this.DefaultNewCompany.Ownerid = this.userLoggedIn.id
        this.DefaultNewCompany.Members = [this.userLoggedIn.id]
        this.NewCompany = Object.assign({},this.DefaultNewCompany)
    },
    methods:{
      UpdateUserLookupProp(Prop,Value,RecordObj){
        RecordObj[Prop] = Value
        if(Value.id && !Value.IsArray){
           RecordObj[Prop+'id'] = Value.id
        }       
    },
      EditCompany(comp){
        this.NewCompany = Object.assign({},comp)
        this.NewCompanyDialTab = 1
      },  
        SelectCompany(comp){
            this.$emit('SelectCompany',comp)
        },
      AssignCompanyLogo(){
        this.NewCompany.Logo = this.SelectedImage.fileurl
        this.NewCompanyDialog = false
        setTimeout(() => {
        this.NewCompanyDialog = true          
        }, 20);
        this.$store.commit('setIMGProp','')
      },
      AddNewCompany(){
        this.Now = new Date()
        let newcomp = {...this.NewCompany,Business_Unit: {id: 'Head_Office',Name: 'Head Office'},Business_Unitid: 'Head_Office'}
        //console.log(newcomp,this.$store.state.PrimarySystem)
        //this.DeactivateNewCompanyDialog()
        if(this.NewCompany.id){
          newcomp.Modified_On = this.OwnerObj.Modified_On
          newcomp.Modified_By = this.OwnerObj.Modified_By
          newcomp.Modified_Byid = this.OwnerObj.Modified_Byid
          db.collection('SystemConfig').doc(this.$store.state.PrimarySystem.id).collection('membercompanies').doc(this.NewCompany.id).set(newcomp).then(newcompdoc => {
            this.NewCompanyDialTab = 0
        }) 
        }
        else{
          newcomp = {...newcomp,...this.OwnerObj}
          db.collection('SystemConfig').doc(this.$store.state.PrimarySystem.id).collection('membercompanies').add(newcomp).then(newcompdoc => {
            this.DeactivateNewCompanyDialog()
        }) 
        }
       
      },
      ActivateNewCompanyDialog(){
        this.NewCompanyDialog = true
      },
      DeactivateNewCompanyDialog(){
        this.NewCompanyDialog = false
        this.NewCompanyDialTab = 0
        this.CompanySearch = ''
        this.NewCompany = Object.assign({},this.DefaultNewCompany)
        this.$emit('DeactivateNewCompanyDialog')
      },
    }
}
</script>

<style>

</style>



