
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import firebase from 'firebase'
import db from '@/main'
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  //plugins: [createPersistedState()],
  state: { 
    GlobalOptionSets: [],
    MyMemberCompanies: [],
    MemberCompaniesOther: [], 
    UserAssets: [],
    MyUserAssetsCall: '',
    ActiveAdvert: '',
    AdvertDirectory: '',
    ActiveSiteAdvert: '',  
    MySiteAdverts: [],
    MySiteAdvertsCall: '',
    SiteAdverts: [],
    SiteAdvertsCall: '',
    CartItems: [],
    StoreProducts: [],
    StoreProductsCall: '',
    ShippingGroups: [],
    ShippingGroupsCall: '',
    ProductCategories: [],
    ProductCategoriesCall: '',
    UsersCall: '',
    WebAppTypes: [
      {Type: 'Website',Color: 'blue',Icon: 'mdi-web'},
      {Type: 'Web App',Color: 'red',Icon: 'mdi-database'},
      {Type: 'Profile',Color: 'purple',Icon: 'mdi-briefcase-variant-outline'},
      {Type: 'Network',Color: 'green',Icon: 'mdi-account-group'}
    ],
    // IconOptions: ['mdi-ab-testing','mdi-abacus','mdi-abjad-arabic','mdi-abjad-hebrew','mdi-abugida-devanagari','mdi-abugida-thai','mdi-access-point','mdi-access-point-check','mdi-access-point-minus',
    // 'mdi-access-point-network-off','mdi-access-point-off','mdi-access-point-plus','mdi-access-point-remove','mdi-account','mdi-account-alert','mdi-account-alert-outline','mdi-account-arrow-left','mdi-account-arrow-left-outline',
    // 'mdi-account-arrow-right-outline','mdi-account-box','mdi-account-box-multiple','mdi-account-box-multiple-outline','mdi-account-box-outline','mdi-account-cancel','mdi-account-cancel-outline','mdi-account-cash','mdi-account-cash-outline',
    // 'mdi-account-check-outline','mdi-account-child','mdi-account-child-circle','mdi-account-child-outline','mdi-account-circle','mdi-account-circle-outline','mdi-account-clock','mdi-account-clock-outline','mdi-account-cog',
    // 'mdi-account-convert','mdi-account-convert-outline','mdi-account-cowboy-hat','mdi-account-details','mdi-account-details-outline','mdi-account-edit','mdi-account-edit-outline','mdi-account-group','mdi-account-group-outline',
    // 'mdi-account-heart','mdi-account-heart-outline','mdi-account-key','mdi-account-key-outline','mdi-account-lock','mdi-account-lock-outline','mdi-account-minus','mdi-account-minus-outline','mdi-account-multiple',
    // 'mdi-account-multiple-check-outline','mdi-account-multiple-minus','mdi-account-multiple-minus-outline','mdi-account-multiple-outline','mdi-account-multiple-plus','mdi-account-multiple-plus-outline','mdi-account-multiple-remove','mdi-account-multiple-remove-outline','mdi-account-music',
    // 'mdi-account-network','mdi-account-network-outline','mdi-account-off','mdi-account-off-outline','mdi-account-outline','mdi-account-plus','mdi-account-plus-outline','mdi-account-question','mdi-account-question-outline',
    // 'mdi-account-reactivate-outline','mdi-account-remove','mdi-account-remove-outline','mdi-account-search','mdi-account-search-outline','mdi-account-settings','mdi-account-settings-outline','mdi-account-star','mdi-account-star-outline',
    // 'mdi-account-supervisor-circle','mdi-account-supervisor-circle-outline','mdi-account-supervisor-outline','mdi-account-switch','mdi-account-switch-outline','mdi-account-tie','mdi-account-tie-outline','mdi-account-tie-voice','mdi-account-tie-voice-off',
    // 'mdi-account-tie-voice-outline','mdi-account-voice','mdi-adjust','mdi-adobe','mdi-adobe-acrobat','mdi-air-conditioner','mdi-air-filter','mdi-air-horn','mdi-air-humidifier',
    // 'mdi-air-purifier','mdi-airbag','mdi-airballoon','mdi-airballoon-outline','mdi-airplane','mdi-airplane-landing','mdi-airplane-off','mdi-airplane-takeoff','mdi-airport',
    // 'mdi-alarm-bell','mdi-alarm-check','mdi-alarm-light','mdi-alarm-light-off','mdi-alarm-light-off-outline','mdi-alarm-light-outline','mdi-alarm-multiple','mdi-alarm-note','mdi-alarm-note-off',
    // 'mdi-alarm-panel','mdi-alarm-panel-outline','mdi-alarm-plus','mdi-alarm-snooze','mdi-album','mdi-alert','mdi-alert-box','mdi-alert-box-outline','mdi-alert-circle',
    // 'mdi-alert-circle-check-outline','mdi-alert-circle-outline','mdi-alert-decagram','mdi-alert-decagram-outline','mdi-alert-minus','mdi-alert-minus-outline','mdi-alert-octagon','mdi-alert-octagon-outline','mdi-alert-octagram',
    // 'mdi-alert-outline','mdi-alert-plus','mdi-alert-plus-outline','mdi-alert-remove','mdi-alert-remove-outline','mdi-alert-rhombus','mdi-alert-rhombus-outline','mdi-alien','mdi-alien-outline',
    // 'mdi-align-horizontal-left','mdi-align-horizontal-right','mdi-align-vertical-bottom','mdi-align-vertical-center','mdi-align-vertical-top','mdi-all-inclusive','mdi-allergy','mdi-alpha','mdi-alpha-a',
    // 'mdi-alpha-a-box-outline','mdi-alpha-a-circle','mdi-alpha-a-circle-outline','mdi-alpha-b','mdi-alpha-b-box','mdi-alpha-b-box-outline','mdi-alpha-b-circle','mdi-alpha-b-circle-outline','mdi-alpha-c',
    // 'mdi-alpha-c-box-outline','mdi-alpha-c-circle','mdi-alpha-c-circle-outline','mdi-alpha-d','mdi-alpha-d-box','mdi-alpha-d-box-outline','mdi-alpha-d-circle','mdi-alpha-d-circle-outline','mdi-alpha-e',
    // 'mdi-alpha-e-box-outline','mdi-alpha-e-circle','mdi-alpha-e-circle-outline','mdi-alpha-f','mdi-alpha-f-box','mdi-alpha-f-box-outline','mdi-alpha-f-circle','mdi-alpha-f-circle-outline','mdi-alpha-g',
    // 'mdi-alpha-g-box-outline','mdi-alpha-g-circle','mdi-alpha-g-circle-outline','mdi-alpha-h','mdi-alpha-h-box','mdi-alpha-h-box-outline','mdi-alpha-h-circle','mdi-alpha-h-circle-outline','mdi-alpha-i',
    // 'mdi-alpha-i-box-outline','mdi-alpha-i-circle','mdi-alpha-i-circle-outline','mdi-alpha-j','mdi-alpha-j-box','mdi-alpha-j-box-outline','mdi-alpha-j-circle','mdi-alpha-j-circle-outline','mdi-alpha-k',
    // 'mdi-alpha-k-box-outline','mdi-alpha-k-circle','mdi-alpha-k-circle-outline','mdi-alpha-l','mdi-alpha-l-box','mdi-alpha-l-box-outline','mdi-alpha-l-circle','mdi-alpha-l-circle-outline','mdi-alpha-m',
    // 'mdi-alpha-m-box-outline','mdi-alpha-m-circle','mdi-alpha-m-circle-outline','mdi-alpha-n','mdi-alpha-n-box','mdi-alpha-n-box-outline','mdi-alpha-n-circle','mdi-alpha-n-circle-outline','mdi-alpha-o',
    // 'mdi-alpha-o-box-outline','mdi-alpha-o-circle','mdi-alpha-o-circle-outline','mdi-alpha-p','mdi-alpha-p-box','mdi-alpha-p-box-outline','mdi-alpha-p-circle','mdi-alpha-p-circle-outline','mdi-alpha-q',
    // 'mdi-alpha-q-box-outline','mdi-alpha-q-circle','mdi-alpha-q-circle-outline','mdi-alpha-r','mdi-alpha-r-box','mdi-alpha-r-box-outline','mdi-alpha-r-circle','mdi-alpha-r-circle-outline','mdi-alpha-s',
    // 'mdi-alpha-s-box-outline','mdi-alpha-s-circle','mdi-alpha-s-circle-outline','mdi-alpha-t','mdi-alpha-t-box','mdi-alpha-t-box-outline','mdi-alpha-t-circle','mdi-alpha-t-circle-outline','mdi-alpha-u',
    // 'mdi-alpha-u-box-outline','mdi-alpha-u-circle','mdi-alpha-u-circle-outline','mdi-alpha-v','mdi-alpha-v-box','mdi-alpha-v-box-outline','mdi-alpha-v-circle','mdi-alpha-v-circle-outline','mdi-alpha-w',
    // 'mdi-alpha-w-box-outline','mdi-alpha-w-circle','mdi-alpha-w-circle-outline','mdi-alpha-x','mdi-alpha-x-box','mdi-alpha-x-box-outline','mdi-alpha-x-circle','mdi-alpha-x-circle-outline','mdi-alpha-y',
    // 'mdi-alpha-y-box-outline','mdi-alpha-y-circle','mdi-alpha-y-circle-outline','mdi-alpha-z','mdi-alpha-z-box','mdi-alpha-z-box-outline','mdi-alpha-z-circle','mdi-alpha-z-circle-outline','mdi-alphabet-aurebesh',
    // 'mdi-alphabet-greek','mdi-alphabet-latin','mdi-alphabet-piqad','mdi-alphabet-tengwar','mdi-alphabetical','mdi-alphabetical-off','mdi-alphabetical-variant','mdi-alphabetical-variant-off','mdi-altimeter',
    // 'mdi-amazon-alexa','mdi-ambulance','mdi-ammunition','mdi-ampersand','mdi-amplifier','mdi-amplifier-off','mdi-anchor','mdi-android','mdi-android-auto',
    // 'mdi-android-messages','mdi-android-studio','mdi-angle-acute','mdi-angle-obtuse','mdi-alarm-light-off','mdi-alarm-light-off-outline','mdi-broadcast','mdi-broadcast-off','mdi-calendar-clock-outline',
    // 'mdi-chat-question-outline','mdi-check-decagram-outline','mdi-clipboard-clock','mdi-clipboard-clock-outline','mdi-cookie-clock','mdi-cookie-clock-outline','mdi-cookie-edit','mdi-cookie-edit-outline','mdi-cookie-lock',
    // 'mdi-cookie-off','mdi-cookie-off-outline','mdi-cookie-refresh','mdi-cookie-refresh-outline','mdi-dog-side-off','mdi-fan-auto','mdi-fire-off','mdi-firework-off','mdi-food-turkey',
    // 'mdi-gift-off-outline','mdi-gift-open','mdi-gift-open-outline','mdi-kettle-pour-over','mdi-koala','mdi-message-question','mdi-message-question-outline','mdi-message-reply-outline','mdi-message-reply-text-outline',
    // 'mdi-movie-check-outline','mdi-movie-cog','mdi-movie-cog-outline','mdi-movie-minus','mdi-movie-minus-outline','mdi-movie-off','mdi-movie-off-outline','mdi-movie-open-check','mdi-movie-open-check-outline',
    // 'mdi-movie-open-cog-outline','mdi-movie-open-edit','mdi-movie-open-edit-outline','mdi-movie-open-minus','mdi-movie-open-minus-outline','mdi-movie-open-off','mdi-movie-open-off-outline','mdi-movie-open-play','mdi-movie-open-play-outline',
    // 'mdi-movie-open-plus-outline','mdi-movie-open-remove','mdi-movie-open-remove-outline','mdi-movie-open-settings','mdi-movie-open-settings-outline','mdi-movie-open-star','mdi-movie-open-star-outline','mdi-movie-play','mdi-movie-play-outline',
    // 'mdi-movie-plus-outline','mdi-movie-remove','mdi-movie-remove-outline','mdi-movie-settings','mdi-movie-settings-outline','mdi-movie-star','mdi-movie-star-outline','mdi-projector-screen-outline','mdi-robot-happy',
    // 'mdi-script-text-key','mdi-script-text-key-outline','mdi-script-text-play','mdi-script-text-play-outline','mdi-star-shooting','mdi-star-shooting-outline','mdi-surround-sound-2-1','mdi-surround-sound-5-1-2','mdi-table-picnic',
    // 'mdi-tag-arrow-down-outline','mdi-tag-arrow-left','mdi-tag-arrow-left-outline','mdi-tag-arrow-right','mdi-tag-arrow-right-outline','mdi-tag-arrow-up','mdi-tag-arrow-up-outline','mdi-train-car-passenger','mdi-train-car-passenger-door',
    // 'mdi-train-car-passenger-variant','mdi-turkey','mdi-webcam-off'
    // ],
    FileTypesandIcons: [
      {Type: 'application/msword',Icon: 'mdi-file-word',Color: 'blue',Name: 'Word Document'},
      {Type: 'application/vnd.ms-excel',Icon: 'mdi-file-excel',Color: 'success',Name: 'Spreadsheet'},
      {Type: 'application/vnd.ms-powerpoint',Icon: 'mdi-file-powerpoint',Color: 'deep-orange',Name: 'Presentation'},
      {Type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',Icon: 'mdi-file-word',Color: 'blue',Name: 'Word Document'},
      {Type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',Icon: 'mdi-file-excel',Color: 'success',Name: 'Spreadsheet'},
      {Type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',Icon: 'mdi-file-powerpoint',Color: 'deep-orange',Name: 'Presentation'},
      {Type: 'text/plain',Icon: 'mdi-note-text',Color: 'amber',Name: 'Text Note'},
      {Type: 'application/pdf',Icon: 'mdi-file-pdf-box',Color: 'red',Name: 'PDF'},
      {Type: 'text/csv',Icon: 'mdi-comma',Color: 'green',Name: 'CSV'},                
      {Type: 'image',Icon: 'mdi-file-image',Color: 'grey',Name: 'Image'},
      {Type: 'application/x-zip-compressed',Icon: 'mdi-folder-zip',Color: 'grey',Name: 'Zip'},
      {Type: 'text/x-vcard',Icon: 'mdi-smart-card',Color: 'blue',Name: 'VCard'}, 
      {Type: 'audio/mpeg',Icon: 'mdi-waveform',Color: 'blue',Name: 'Sound File'}, 
      
  ],
    SelectedBA: '',
    CanToggleBA: false,
    ActiveSystemWebsite: '',
    ActiveSystemSocialNetwork: '',
    ActiveSystemLibrary: '',
    ActiveSystemDatabase: '',
    EntityConfigOptions: [],
    EntityConfigView: '', 
    DefaultIcons: [
      {Name: 'Fields',Icon: 'mdi-format-list-text'},{Name: 'Data Table View',Icon: 'mdi-file-table-box'},{Name: 'Data Single View',Icon: 'mdi-file-eye'},
      {Name: 'Galleries',Icon: 'mdi-folder-image'},{Name: 'Document Registers',Icon: 'mdi-file-cabinet'},{Name: 'Data Security',Icon: 'mdi-security-network'},
      {Name: 'SubCollections',Icon: 'mdi-database-arrow-down'},{Name: 'Directories',Icon: 'mdi-view-list'},{Name: 'Data Relationships',Icon: 'mdi-file-tree'},
      {Name: 'Data Workflows',Icon: 'mdi-auto-fix'},{Name: 'Data Processes',Icon: 'mdi-pipe'},{Name: 'Data Boards',Icon: 'mdi-list-status'},
      {Name: 'Data Cabinets',Icon: 'mdi-file-cabinet'},{Name: 'Data Views',Icon: 'mdi-table-eye'},{Name: 'Extensions',Icon: 'mdi-view-grid-plus'},{Name: 'PDF',Icon: 'mdi-file-pdf-box'}
    ],
    NeedsRASync: true,
    UserisGuest: false,
    roottableentities: ['Systems','Entities','Plugins','System_Tickets'],//records stored on actual root entcol e.g. "db/systems"
    rootconfigentities: ['Users','Business_Units','User_Companies'], //records stored db/SystemConfig/siteid/entcol e.g. "db/SystemConfig/siteid/users"
    rootdbentities: ['Billing_Accounts'], //records stored e.g. db/Databases/siteid/billingaccounts
    ActiveSuiteid: process.env.VUE_APP_RA_SYSTEM_ID,
    TemplateSecondaryCatFilter: '',
    GradientElements: [],
    GraphicalElements: [],
    DefaultThemes: [],
    DefaultDirectories: [
      {
        id: 'Templates',
        DisplayName: 'Templates',
        SingleName: 'Templates',
        Icon: 'mdi-search-web',
        CategoricalListType: 'Web Apps',
        NavList: [],
        AllFields: [
        ]
      },
      {
        id: 'Systems',
        DisplayName: 'Sites',
        SingleName: 'Sites',
        Icon: 'mdi-apps',
        CategoricalListType: 'Web Apps',
        NavList: [],
        AllFields: [
        ]
      },
      {
          id: 'Store_Products',
          DisplayName: 'Store Products',
          SingleName: 'Store Products',
          Icon: 'mdi-shopping',
          CategoricalListType: 'Web Store Products',
          NavList: [],
          AllFields: [
              {
                  id: 'Product_Group',
                  Name: 'Product_Group',
                  DisplayName: 'Product Group',
                  ListPrimary: true,
                  LastLevel: 3,
                  Levels: [{id: 1,FieldName: 'Product_Group',name: 'Product Group',children: [{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]}]},{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]},{id: 3,FieldName: 'Product_Type',name: 'Product Type'}],
                  LevelTree: [{id: 1,FieldName: 'Product_Group',name: 'Product Group',children: [{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]}]}],
                  Type: 'Option Set',
                  Options: [],
                  lastassignedinteger: 1000000,
                  HasDeepHierarchy: true,
              },
              {
                  id: 'Price',
                  Name: 'Price',
                  DisplayName: 'Price',
                  Type: 'Number Field'
              },
              {
                  id: 'Color',
                  Name: 'Color',
                  DisplayName: 'Color',
                  Type: 'Option Set',
                  lastassignedinteger: 1000002,
                  Options: [
                      {
                      ID: 1000001,
                      Name: 'Blue'
                      },
                      {
                      ID: 1000002,
                      Name: 'Yellow'
                      },
                  ]
              },
              {
                  id: 'Size',
                  Name: 'Size',
                  DisplayName: 'Size',
                  Type: 'Option Set',
                  lastassignedinteger: 1000005,
                  Options: [
                      {
                      ID: 1000001,
                      Name: 'X-Small'
                      },
                      {
                      ID: 1000002,
                      Name: 'Small'
                      },
                      {
                      ID: 1000003,
                      Name: 'Medium'
                      },
                      {
                      ID: 1000004,
                      Name: 'Large'
                      },
                      {
                      ID: 1000005,
                      Name: 'X-Large'
                      },
                  ]
              }
          ]
          },
          {
          id: 'Groups',
          DisplayName: 'Groups',
          SingleName: 'Group',
          Icon: 'mdi-face',
          CategoricalListType: 'Social Groups',
          NavList: [],
          HidePrice: true,
          AllFields: [
              {
                  id: 'Group_Category',
                  Name: 'Group_Category',
                  DisplayName: 'Group Category',
                  ListPrimary: true,
                  LastLevel: 3,
                  Levels: [{id: 1,FieldName: 'Group_Category',name: 'Group Category',children: [{id: 2,FieldName: 'Group_Class',name: 'Group Class',children: [{id: 3,FieldName: 'Group_Type',name: 'Group Type'}]}]},{id: 2,FieldName: 'Group_Class',name: 'Group Class',children: [{id: 3,FieldName: 'Group_Type',name: 'Group Type'}]},{id: 3,FieldName: 'Group_Type',name: 'Group Type'}],
                  LevelTree: [{id: 1,FieldName: 'Group_Category',name: 'Group Category',children: [{id: 2,FieldName: 'Group_Class',name: 'Group Class',children: [{id: 3,FieldName: 'Group_Type',name: 'Group Type'}]}]}],
                  Type: 'Option Set',
                  Options: [],
                  lastassignedinteger: 1000000,
                  HasDeepHierarchy: true,
              },
          ]
          },
          {
          id: 'Classifieds',
          DisplayName: 'Classifieds',
          SingleName: 'Classifieds',
          Icon: 'mdi-face',
          CategoricalListType: 'Social Classifieds',
          NavList: [],
          AllFields: [
              {
                  id: 'Main_Class',
                  Name: 'Main_Class',
                  DisplayName: 'Main Class',
                  ListPrimary: true,
                  LastLevel: 3,
                  Levels: [{id: 1,FieldName: 'Main_Class',name: 'Main Class',children: [{id: 2,FieldName: 'Sub_Class',name: 'Sub Class',children: [{id: 3,FieldName: 'Category',name: 'Category'}]}]},{id: 2,FieldName: 'Sub_Class',name: 'Sub Class',children: [{id: 3,FieldName: 'Category',name: 'Category'}]},{id: 3,FieldName: 'Category',name: 'Category'}],
                  LevelTree: [{id: 1,FieldName: 'Main_Class',name: 'Main Class',children: [{id: 2,FieldName: 'Sub_Class',name: 'Sub Class',children: [{id: 3,FieldName: 'Category',name: 'Category'}]}]}],
                  Type: 'Option Set',
                  Options: [
                      {
                          HasDeepHierarchy: true,
                          ID: 1000001,
                          Name: 'Seeking',
                          LevelFieldName: 'Main_Class',
                          Level: {id: 1,FieldName: 'Main_Class',name: 'Main Class',children: [{id: 2,FieldName: 'Sub_Class',name: 'Sub Class',children: [{id: 3,FieldName: 'Category',name: 'Category'}]}]},
                          Options: []
                      },
                      {
                          HasDeepHierarchy: true,
                          ID: 1000002,
                          Name: 'Offering',
                          LevelFieldName: 'Main_Class',
                          Level: {id: 1,FieldName: 'Main_Class',name: 'Main Class',children: [{id: 2,FieldName: 'Sub_Class',name: 'Sub Class',children: [{id: 3,FieldName: 'Category',name: 'Category'}]}]},
                          Options: []
                      }
                  ],
                  lastassignedinteger: 1000002,
                  HasDeepHierarchy: true,
              },
              {
                  id: 'Price',
                  Name: 'Price',
                  DisplayName: 'Price',
                  Type: 'Number Field'
              },
          ]
          }
  ],
    DefaultSiteCategoryField: {
      id: 'Site_Category',
      Name: 'Site_Category',
      DisplayName: 'Site Category',
      ListPrimary: true,
      LastLevel: 3,
      Levels: [{id: 1,FieldName: 'Site_Category',name: 'Site Category',children: [{id: 2,FieldName: 'Site_Class',name: 'Site Class',children: [{id: 3,FieldName: 'Site_Type',name: 'Site Type'}]}]},{id: 2,FieldName: 'Site_Class',name: 'Site Class',children: [{id: 3,FieldName: 'Site_Type',name: 'Site Type'}]},{id: 3,FieldName: 'Site_Type',name: 'Site Type'}],
      LevelTree: [{id: 1,FieldName: 'Site_Category',name: 'Site Category',children: [{id: 2,FieldName: 'Site_Class',name: 'Site Class',children: [{id: 3,FieldName: 'Site_Type',name: 'Site Type'}]}]}],
      Type: 'Option Set',
      Options: [],
      lastassignedinteger: 1000000,
      HasDeepHierarchy: true,
  },
    formrules: {
      max100Chars: value => value.length < 101 || "Max. 100 characters",
      max150Chars: value => value.length < 151 || "Max. 150 characters",
      numberfield: value => {
        const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
        return pattern.test(value) || "Not a valid number.";
      },
    telnr: value => {
        const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return pattern.test(value) || "Not a telephone number.";
      },
    youtubeurl: value => {
        const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        return pattern.test(value) || "Not a Youtube Video Link.";
      },
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
    MyCompanyJobApplications: [],
    MyJobApplications: [],
    PublicVacancies: [],
    MyVacancies: [],
    VacanciesOther: [],
    SiteKeywordTypes: [
      //{ID: 1000001,Name: '???'},
      {ID: 1000002,Name: 'Site'},
      {ID: 1000003,Name: 'Featured Member'},
      {ID: 1000004,Name: 'Advert'},
      {ID: 2000001,Name: 'Member Characteristics'},
      {ID: 2000002,Name: 'Member Hobbies'},
      {ID: 2000003,Name: 'Member Skills'},
      {ID: 2000004,Name: 'Member Industries'},
      {ID: 2000005,Name: 'Member Institutions'},
      {ID: 2000006,Name: 'Member Fields of Study'},      
      {ID: 2000007,Name: 'Member Methodologies'}      
    ],
    AccountTypes: [
      {ID: 1000001, Name: 'Store Account',Pluginid: 'Online_Store',AppPlugin: true},
      {ID: 1000002, Name: 'Group Account',Pluginid: 'Site_Groups'},
      {ID: 1000003, Name: 'Suite Ownership',Prop: 'SystemisRA'},
      {ID: 1000004, Name: 'App Membership',Pluginid: 'Monetization'},
      {ID: 1000005, Name: 'Cash Loan Account',Pluginid: 'Cash_Loans',AppPlugin: true},
      {ID: 1000006, Name: 'Market Account',Pluginid: 'Monetization'},
      {ID: 1000007, Name: 'Site Account',Pluginid: 'Web_App_Builder'},
  ],
    InvoiceTypes: [
      {ID: 1000001,Name: 'Store Order'},
      {ID: 1000002,Name: 'Subscription Order'},
      {ID: 1000003,Name: 'Project'},
      {ID: 1000004,Name: 'Cash Loan'},
      {ID: 1000005,Name: 'Market Content'},
      {ID: 1000006,Name: 'Site Subscription'},
      {ID: 1000007,Name: 'Site Plugin'},
    ],
    SiteFeaturedMembersArray: [],
    FeaturedMembersArray: [],
    CompanyLoanOrders: [],
    CompanyStoreOrders: [],
    CompanyBillingAccounts: [],
    UserisCompanyAdmin: false,
    TrackingOrders: [],
    ReceivedMessages: [],
    SentMessages: [],
    ActiveMemberGroup: '',
    MemberGroupsArray: [],
    EntitiesArray: [],
    ConfigEntitiesArray: [],
    ReservedDisplayNameProps: ['Modules','Plugins','Tests','Sample Records','Documents','Users','Business Units','Activities','System Tickets','Sales Invoices','Purchase Invoices','SystemDialogs',
  'Statement Invoices','Subscription Orders','Journal Entries','Store Suppliers','Billing Accounts','User Roles','Warehouses','User Companies','Member Groups','Suites','Marketplace Templates',
  'Site Articles','Site Blogs','Site Classifieds','Site Events','Site Forums','Groups','Site Meetups','Site Polls','Client Liaison','User Messages',
  'Site Articles Wiki','Site Blogs Wiki','Password Resets','PDF Export Functions','Notifications','Notices','Goals','Friend Requests','Frequently Asked Questions','Data Imports','Client Records',
    'Group Members','Featured Group Members','Featured Members','Token Transactions','Payment Invoices','Sales Quotes','Timesheets','User Invites','Site Moderators','Chatbot Messages',
  'Social Site Images','Social Site Likes','Social Site Comments','Social Site Posts','System Activities','Site Map Tags','Banner Ads','System Assets','Landing Pages'],
    ReservedSingleNameProps: ['Module','Plugin','Test','Sample Record','Document','User','Business Unit','Activitie','System Ticket','Sales Invoice','Purchase Invoice','SystemDialog',
    'Statement Invoice','Subscription Order','Journal Entry','Store Supplier','Billing Account','User Role','Warehouse','User Company','Member Group','Suite','Marketplace Template',
    'Site Article','Site Blog','Site Classified','Site Event','Site Forum','Group','Site Meetup','Site Poll','Client Liaison','User Message',
    'Site Article Wiki','Site Blog Wiki','Password Reset','PDF Export Function','Notification','Notice','Goal','Friend Request','Frequently Asked Question','Data Import','Client Record',
      'Group Member','Featured Group Member','Featured Member','Token Transaction','Payment Invoice','Sales Quote','Timesheet','User Invite','Site Moderator','Chatbot Message',
    'Social Site Image','Social Site Like','Social Site Comment','Social Site Post','System Activitie','Site Map Tag','Banner Ad','System Asset','Landing Page'],
    ElementAnimationDialog: false,
    BURoles:[
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'CanList', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'CanList', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'CanList', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'CanList', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'CanView', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'CanView', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'CanView', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'CanView', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'CanEdit', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'CanEdit', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'CanEdit', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'CanEdit', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'CanDelete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'CanDelete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'CanDelete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'CanDelete', PermissionLevel: 1},
    ],
    TemplateCategories: [
      {ID: 1000001, Name: 'Site',IsSite: true,RootType: true},{ID: 1000002, Name: 'Web Page',RootType: true,IsPage: true},
      {ID: 1000003, Name: 'Social Page',RootType: false,IsPage: true},
      {ID: 1000004, Name: 'Group Site',IsSite: true,RootType: true},{ID: 1000005, Name: 'Group Site Page',RootType: false,IsPage: true},
      {ID: 1000006, Name: 'Featured Member',IsSite: true,RootType: true},{ID: 1000007, Name: 'Featured Member Page',RootType: false,IsPage: true},
      {ID: 1000008, Name: 'Database App',RootType: true},
      {ID: 1000009, Name: 'Document Library',RootType: true},{ID: 1000010, Name: 'Custom Directory',RootType: true}
    ],
    // TemplateCategories: [
    //   {ID: 1000001, Name: 'Website',IsSite: true,RootType: true},{ID: 1000002, Name: 'Web Page',RootType: true,IsPage: true},
    //   {ID: 1000003, Name: 'Social Network',IsSite: true,RootType: true},{ID: 1000004, Name: 'Social Page',RootType: false,IsPage: true},
    //   {ID: 1000005, Name: 'Group Site',IsSite: true,RootType: true},{ID: 1000006, Name: 'Group Site Page',RootType: false,IsPage: true},
    //   {ID: 1000006, Name: 'Featured Member',IsSite: true,RootType: true},{ID: 1000006, Name: 'Featured Member Page',RootType: false,IsPage: true},
    //   {ID: 1000008, Name: 'Database App',RootType: true},
    //   {ID: 1000009, Name: 'Document Library',RootType: true},{ID: 1000010, Name: 'Custom Directory',RootType: true}
    // ],
    DefaultEntities: [
      {DisplayName: 'Activities',Table_Icon: 'mdi-clipboard-list',Security: 'Business Unit'},
      {DisplayName: 'Business Units',Security: 'Business Unit',Security: 'Custom Roles'},
      {DisplayName: 'System Tickets',Table_Icon: 'mdi-ticket-confirmation',Security: 'Custom Roles'},
      {DisplayName: 'Users',Table_Icon: 'mdi-clipboard-list',Security: 'Custom Roles'}
    ],
    DefaultStatusField: {
      "Created": true,
      "SystemRequired": true,
      "Name": "Status",
      "Primary": false,
      "DefaultLevel2Option": {
          "LevelFieldName": "Status_Reason",
          "ID": 1000003,
          "Level": {
              "name": "Status Reason",
              "FieldName": "Status_Reason",
              "children": [],
              "id": 2
          },
          "Name": "Open"
      },
      "UsedonCompForm": true,
      "IsHeader": true,
      "Type": "Option Set",
      "IsFilter": true,
      "DefaultOption": {
          "LevelFieldName": "Status",
          "Level": {
              "name": "Status",
              "children": [
                  {
                      "id": 2,
                      "name": "Status Reason",
                      "FieldName": "Status_Reason",
                      "children": []
                  }
              ],
              "id": 1,
              "FieldName": "Status"
          },
          "Name": "Active",
          "Options": [
              {
                  "LevelFieldName": "Status_Reason",
                  "ID": 1000003,
                  "Name": "Open",
                  "Level": {
                      "name": "Status Reason",
                      "id": 2,
                      "children": [],
                      "FieldName": "Status_Reason"
                  }
              }
          ],
          "HasDeepHierarchy": true,
          "ID": 1000001
      },
      "IsMandatory": true,
      "Options": [
          {
              "Name": "Active",
              "LevelFieldName": "Status",
              "HasDeepHierarchy": true,
              "Options": [
                  {
                      "ID": 1000003,
                      "LevelFieldName": "Status_Reason",
                      "Name": "Open",
                      "Level": {
                          "FieldName": "Status_Reason",
                          "children": [],
                          "name": "Status Reason",
                          "id": 2
                      }
                  }
              ],
              "ID": 1000001,
              "Level": {
                  "children": [
                      {
                          "id": 2,
                          "FieldName": "Status_Reason",
                          "name": "Status Reason",
                          "children": []
                      }
                  ],
                  "name": "Status",
                  "id": 1,
                  "FieldName": "Status"
              }
          },
          {
              "LevelFieldName": "Status",
              "ID": 1000002,
              "Name": "InActive",
              "HasDeepHierarchy": true,
              "Options": [
                  {
                      "Level": {
                          "id": 2,
                          "name": "Status Reason",
                          "FieldName": "Status_Reason",
                          "children": []
                      },
                      "ID": 1000004,
                      "LevelFieldName": "Status_Reason",
                      "Name": "Closed"
                  }
              ],
              "Level": {
                  "name": "Status",
                  "id": 1,
                  "children": [
                      {
                          "name": "Status Reason",
                          "id": 2,
                          "FieldName": "Status_Reason",
                          "children": []
                      }
                  ],
                  "FieldName": "Status"
              }
          }
      ],
      "DisplayName": "Status",
      "Levels": [
          {
              "FieldName": "Status",
              "id": 1,
              "children": [
                  {
                      "children": [],
                      "name": "Status Reason",
                      "id": 2,
                      "FieldName": "Status_Reason"
                  }
              ],
              "name": "Status"
          },
          {
              "id": 2,
              "name": "Status Reason",
              "FieldName": "Status_Reason",
              "children": []
          }
      ],
      "lastassignedinteger": 1000004,
      "HasDeepHierarchy": true,
      "LastLevel": 2,
      "UsedonForm": false,
      "id": "Status",
      "LevelTree": [
          {
              "children": [
                  {
                      "name": "Status Reason",
                      "FieldName": "Status_Reason",
                      "children": [],
                      "id": 2
                  }
              ],
              "name": "Status",
              "FieldName": "Status",
              "id": 1
          }
      ]
  },
    CSSBlendModes: ['color','color-burn','color-dodge','darken','difference','exclusion','hard-light','hue','lighten','luminosity','multiply','normal','overlay',
    'plus-lighter','saturation','screen','soft-light','unset',
    ],
    CSSColors: [
      'black','silver','gray','white','maroon','red','purple','fuchsia','green','lime','olive','yellow','navy','blue','teal','aqua','aliceblue','antiquewhite','aqua','aquamarine',
      'azure','beige','bisque','black','blanchedalmond','blue','blueviolet','brown','burlywood','cadetblue','chartreuse','chocolate','coral','cornflowerblue','cornsilk','crimson',
      'cyan','darkblue','darkcyan','darkgoldenrod','darkgray','darkgreen','darkgrey','darkkhaki','darkmagenta','darkolivegreen','darkorange','darkorchid','darkred','darksalmon','darkseagreen',
      'darkslateblue','darkslategray','darkslategrey','darkturquoise','darkviolet','deeppink','deepskyblue','dimgray','dimgrey','dodgerblue','firebrick','floralwhite','forestgreen','fuchsia',
      'gainsboro','ghostwhite','gold','goldenrod','gray','green','greenyellow','grey','honeydew','hotpink','indianred','indigo','ivory','khaki','lavender','lavenderblush','lawngreen','lemonchiffon',
      'lightblue','lightcoral','lightcyan','lightgoldenrodyellow','lightgray','lightgreen','lightgrey','lightpink','lightsalmon','lightseagreen','lightskyblue','lightslategray','lightslategrey',
      'lightsteelblue','lightyellow','lime','limegreen','linen','magenta','maroon','mediumaquamarine','mediumblue','mediumorchid','mediumpurple','mediumseagreen','mediumslateblue','mediumspringgreen',
      'mediumturquoise','mediumvioletred','midnightblue','mintcream','mistyrose','moccasin','navajowhite','navy','oldlace','olive','olivedrab','orange','orangered','orchid','palegoldenrod','palegreen',
      'paleturquoise','palevioletred','papayawhip','peachpuff','peru','pink','plum','powderblue','purple','red','rosybrown','royalblue','saddlebrown','salmon','sandybrown','seagreen','seashell','sienna',
      'silver','skyblue','slateblue','slategray','slategrey','snow','springgreen','steelblue','tan','teal','thistle','tomato','turquoise','violet','wheat','white','whitesmoke','yellow','yellowgreen',
    ],
    FontOptions: ['Anton','Architects Daughter','Bahianita','Bangers','Bebas Neue','Berkshire Swash','Caveat','Courgette','Crimson Pro','Dancing Script','Gudea','Hind','Impact','Indie Flower','Lato','Lobster','Macondo Swash Caps','Maven Pro','Metrophobic','Michroma','Montserrat','Mulish','Mukta Vaani',
                  'News Cycle','Niramit','NTR','Nunito Sans','Orbitron','Oswald','Padauk','Palanquin','Parisienne','Playfair Display','Poiret One','Poppins','Quicksand','Raleway','Redressed','Roboto','Tangerine','Tulpen One','Verdana','Vibes','Yellowtail'],
    StorePagePresets: [],
    RAStorePagePresets: [],
    ActiveChatRecord: '',
    TimesheetRelated: '',
    StopwatchDialog: false,
    ActiveSuiteMenuItems: [],
    EntityMasterFields: [],
    SystemMasterFields: [],
    RelatedRecordObj: '',
    StoreEntity: '',
    ActiveWarehouse: '',
    AppBuilderHelperActive: false,
    AppBuilderHelperStep: 0,
    AlertSound: '',
    ConfirmMSGDialog: false,
    ConfirmMSGText: '',
    ConfirmMSGTitle: '',
    ConfirmMSGIcon: '',
    ConfirmMSGIconColor: '',
    AppQuickStartDialog: false,
    RAPluginPackages: [],
    RAPlugins: [],
    RAApp: '',
    RADB: '',
    PluginsPath: '',
    AppsPath: '',
    WebsiteisActive: false,
    SocialNetworkisActive: false,
    DatabaseisActive: false,
    DocumentationisActive: false,
    SubscriptionPackageTypes: [
      {ID: 1000002,Name: 'Suite Plugin Package',Prefix: 'SP-IN-'},
      {ID: 1000003,Name: 'App Membership',Prefix: 'ST-IN-AM-'},//subscribe to app, using packages offered by app. maybe senseless
      {ID: 1000004,Name: 'Group Ownership',Prefix: 'ST-IN-GO-'},//subscribtion plan to own a Group was 'Group Package'
      {ID: 1000005,Name: 'Site Channel',Prefix: 'ST-IN-SC-'},//subscribe to channel content e.g. sitearticles or custom collab moderator space e.g. "fashionbloggers" collab, was "Site Content"
      {ID: 1000006,Name: 'Group Directory Listing',Prefix: 'ST-IN-GDL-'},//featuring in a App offered directory was "Group Directory"
      {ID: 1000007,Name: 'Group Membership',Prefix: 'ST-IN-GM-'},//subscribing to group through group offered package
      {ID: 1000008,Name: 'Group Advertisement',Prefix: 'ST-IN-GA-'},//banners and jazz, offered by App
      {ID: 1000009,Name: 'Group Featured Member',Prefix: 'ST-IN-GFM-'},//featuredgroupmembers, offered by...group...featuredmembers is suite non group, featuredgroupmembers is this one
      {ID: 1000010,Name: 'Site Directory Listing',Prefix: 'SDL-'},
      {ID: 1000011,Name: 'Plugin Purchase',Prefix: 'PLU-'},
      {ID: 1000012,Name: 'Advert Subscription',Prefix: 'AD-'},
      
    ],
    SystemConfigTabs: [],
    SystemConfigEntity: '',
    ActiveDocumentationLibrary: '',
    ActiveFeaturedMember: '',
    ActiveMarketTemplateChild: '',
    MiniRecordDialog: false,
    MiniRecordDialogTitle: '',
    MiniRecordDialogDescription: '',
    MiniRecordPath: '',
    MiniRecordTabs: [],
    MiniRecordObject: '',
    MiniRecordDialogIMG: '',
    
    UploadFilesList: [],
    UploadFileStorageRef: '',
    UploadFilesDBStorageRef: '',
    setPhotoTagObjects: [],

    SystemAssets: [],
    RASystemAssets: [],
    ContentBlocks: [],
    RenderedContentBlocks: [],
    SiteDialog: false,
    EditedPage: '',
    BuilderView: '',
    GroupScopeTabs: [],
    GroupRoutes: ['GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll','FeaturedMemberEdit','FeaturedMemberSingle',
      'GroupSingle','GroupEdit','GroupNewArticle','GroupNewMeetup','GroupNewBlog','GroupPageEditor','GroupPageBuilder','GroupNewPoll','GroupWebFormEditor','GroupWebFormBuilder'],
    GroupidRoutes: ['GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll',
    'GroupSingle','GroupEdit','GroupNewArticle','GroupNewMeetup','GroupNewBlog','GroupPageEditor','GroupPageBuilder','GroupNewPoll'],
    GroupslugRoutes: ['FeaturedMember','FeaturedMemberEdit'],
    IsGroupView: false,

    IMGProp: '',
    SelectedGalleryAsset: '',
    EditedTab: '',
    EditedPageProperties: {},
    dragging: '',
    draggingtab: '',									
    draggingtabindex: -1,									
    draggingelement: '',									
    draggingelementindex: -1,									
    draggingrow: '',									
    draggingrowindex: -1,
    GalleryLibraries: [],
    GalleryAssets: [],

    ActiveGroup: '',
    UserMemberObj: '',
    GroupForbidden: false,
    // WarehouseSuppliersQuery: '',
    // WarehouseSuppliersArray: [],
    PDFDocLineItems: [],
    PDFFunctionName: '',
    PDFHeaderObj: '',
    PDFFooterObj: '',
    PDFExportDoc: '',
    PDFDownloadOnly: true,
    ExportingtoPDFDialog: false,
    OrderConfirmationDialog: false,
    OrderConfirmationPDF: '',
    PDFDataRecord: '',

    UserAvailableTokens: 0,
    CatNavlists: [],
    MarketDialogObject: '',
    MarketDialogOptions: [],
    MarketDialog: false,
    MarketDialogType: '',
    MarketDialogTitle: '',
    MarketDialogAvatar: '',
    MarketDialogInteractMethod: '',
    MarketDialogDescription: '',
    CustomProcessingDialog: false,
    CustomProcessingDialogText: '',
    AllowCloseCustomProcessingDialog: false,
    WarehousesQuery: '',
    WarehousesArray: [],
    AppMode: {ID: 1000001, Name: 'Bird View',icon: 'mdi-bird'},
    SocialItemInteractMethod: '',
    SocialItemInteractMethodProp: '',
    InvitedGroups: [],
    CurrentEntity: '',
    Cart_Entity: 'Store_Orders',
    CurrentItemModerator: '',
    StoreItemPublisher: '',
    StoreSearch: '',
    ViewOrderNavbar: false,
    CanReceive: false,
    CanViewMovementHistory: false,
    SingleNavTabs: [],
    CurrentDataTabs: [],
    SelectedDataTabs: [],
    SocialEntities: [],
    PuchaseOrdersQuery: true,
    SalesInvoicesQuery: '',
    SalesInvoicesArray: [],
    //<!-- user: {
    //  authenticated: false,
    //  id: null,
    //  Full_Name: null
    //    }, -->
    // GymSessionsArray: [],
    Currencies: [
      {Name:'US Dollar',Currency:'USD',LangForm: 'en-US'},
      {Name:'Euro',Currency:'EUR',LangForm: 'en-US'},
      {Name:'British Pound',Currency:'GBP',LangForm: 'en-US'},
      {Name:'Australian Dollar',Currency:'AUD',LangForm: 'en-AU'},
      {Name:'New Zealand Dollar',Currency:'NZD',LangForm: 'en-NZ'},
      {Name:'Japanese Yen',Currency:'JPY',LangForm: 'en-US'},
      {Name:'Rand',Currency:'ZAR',LangForm: 'en-ZA'},
      {Name:'Zimbabwe Dollar',Currency:'ZWL',LangForm: 'en-ZW'},      
    ],
    DefaultCurrency: {Name:'Rand',Currency:'ZAR',LangForm: 'en-ZA'},
    DefaultTax_Percentage: 15,
    Friendships: [],
    Users: [],
    ViewInstanceID: '',
    ReroutePath: '',
    TableData: [],
    SystemObj: {},
    SystemEntities: [],
    IsAdmin: false,
    IsEagleViewer: false,
    SiteContentAdmin: false,
    BusinessProfileAdmin: false,
    RAUserObj: null,
    userobj: null,
    UsePrimaryUserObj: false,
    PrimaryUserObj: null,
    PrimaryMonetizeBU: '',
    SiteMonetizationBU: '',
    PrimarySystem: '',
    SiteSystem: '',
    buobj: null,
    LastRoute: null,
    useridtoken: null,
    user: null,
    status: null,
    error: null,
    RMDocumentsArray: [],
    FriendshipsArray: [],
    BusinessUnitsArray: [],
    BusinessUnitsCanCreate: false,
    BusinessUnitsCanView: false,
    BusinessUnitsCanList: false,
    BusinessUnitsCanEdit: false,
    BusinessUnitsCanDelete: false,
    BusinessUnitsQuery: '',
    SiteUsersArray: [],
    UsersArray: [],
    UsersCanCreate: false,
    UsersCanView: false,
    UsersCanList: false,
    UsersCanEdit: false,
    UsersCanDelete: false,
    UsersQuery: '',
    UserInfoRequired: false,
    UserRequiredInfo: [],
    MyCompany: '',
    ActiveElmntConfigComp: '',
    UserFeaturedMemberSnap: '',
    FeaturedMemberHasPages: false,
    FeaturedMemberDefaultPage: '',
    SiteKeyWordOptions: [],
    FlexSizes: [
      {Prop: 'FlexXLRG',Name: 'X LRG',Breakpoint: 'xl'},								
      {Prop: 'FlexLarge',Name: 'LRG',Breakpoint: 'lg'},	
      {Prop: 'FlexMedium',Name: 'Med',Breakpoint: 'md'},	
      {Prop: 'FlexSmall',Name: 'SML',Breakpoint: 'sm'},	
      {Prop: 'FlexXSmall',Name: 'X SML (Mobile)',Breakpoint: 'xs'},
  ],
  CSSAlignmentOptions: [
    {value: 'align-self: end',name: 'Align End'}
  ],
  NewPageSite: '',
  NewSitePageid: '',
  ActiveMarketTemplate: '',
  ActiveTemplateSite: '',
  TemplateOwner: false,
  TemplateView: false,
  TemplateSocialRouteName: '',
  TemplateRootPath: '',
  TemplateEntitiesSnap: '',
  TemplateEntities: [],
  PluginDataBase: {},
  AppsDataBase: [],
  RAMyMarketplaceTemplates: [],
  RAOtherMarketplaceTemplates: [],
  MySuiteTemplates: [],
  OtherSuiteTemplates: [],
  ClientSuiteTemplates: [],
  ActiveSuiteTemplate: '',
  ActiveSuiteWebsitePages: [],
  ActiveSuiteSocialNetworkPages: [],
  MarketplaceTemplatesCalled: false,
  MyMarketplaceTemplates: [],
  OtherMarketplaceTemplates: [],
  ActiveSuiteApp: '',
  ActiveStore: '',
  ChatDialog: false,
  PageBuilderButtons: '',
  StoppedHelpers: [],
  ActiveVisitor: '',
  TemplateInstallationMethod: false,
  NewTableStructure: true,
  PDFBuilderActive: false,
  FeaturedMembersOverlay: false,
  },
  mutations: {
    setActiveAdvert(state, payload){
      state.ActiveAdvert = payload
    },
    setCartItems(state, payload){
      state.CartItems = payload
    },
    setSelectedBA(state, payload){
      state.SelectedBA = payload
    },
    setCanToggleBA(state, payload){
      state.CanToggleBA = payload
    },
    setActiveSystemWebsite(state, payload){
      state.ActiveSystemWebsite = payload
    },
    setActiveSystemSocialNetwork(state, payload){
      state.ActiveSystemSocialNetwork = payload
    },
    setActiveSystemLibrary(state, payload){
      state.ActiveSystemLibrary = payload
    },
    setActiveSystemDatabase(state, payload){
      state.ActiveSystemDatabase = payload
    },
    setFeaturedMembersOverlay(state, payload){
      state.FeaturedMembersOverlay = payload
    },
    setPDFBuilderActive(state, payload){
      state.PDFBuilderActive = payload
    },
    setEntityConfigOptions(state, payload){
      state.EntityConfigOptions = payload
    },
    setEntityConfigView(state, payload){
      state.EntityConfigView = payload
    },
    setUsersCall(state, payload){
      //state.UsersArray = []
      state.UsersCall = payload
      console.log('setting UsersCall now')
    },
    setSiteUsersArray(state, payload){
      console.log('users were ',this.state.SiteUsersArray.length)
      //state.UsersArray = []
      state.SiteUsersArray = payload
      console.log('users now ',this.state.SiteUsersArray.length)
    },
    setUsersArray(state, payload){
      //console.log('users were ',this.state.UsersArray.length)
      //state.UsersArray = []
      state.UsersArray = payload
      //console.log('users now ',this.state.UsersArray.length)
    },
    setNeedsRASync(state, payload){
      if(process.env.VUE_APP_RA_PROVIDER_ID !== process.env.VUE_APP_PARENT_DB_ID){
        state.NeedsRASync = payload  
      }
      else{
        state.NeedsRASync = false
      }      
    },
    setUserisGuest(state, payload){
      //console.log('setting setUserisGuest on ',state.ActiveSuiteid+' as '+payload)
      state.UserisGuest = payload
    },
    setActiveSuiteid(state, payload){
      state.ActiveSuiteid = payload
    },
    setMarketplaceTemplatesCalled(state, payload){
      state.MarketplaceTemplatesCalled = payload
    },
    setTemplateInstallationMethod (state, payload){
      state.TemplateInstallationMethod = payload
    },
    setTemplateSecondaryCatFilter (state, payload){
      state.TemplateSecondaryCatFilter = payload
    },
    setActiveVisitor (state, payload){
      state.ActiveVisitor = payload
    },
    setStoppedHelpers (state, payload){
      state.StoppedHelpers = payload
    },
    setGradientElements (state, payload){
      state.GradientElements = payload
    },
    setGraphicalElements (state, payload){
      state.GraphicalElements = payload
    },
    setDefaultThemes (state, payload){
      state.DefaultThemes = payload
    },
    setPageBuilderButtons (state, payload){
      state.PageBuilderButtons = payload
    },
    setChatDialog (state, payload){
      state.ChatDialog = payload
    },
    setActiveStore (state, payload){
      state.ActiveStore = payload
    },
    setAdvertDirectory (state, payload){
      state.AdvertDirectory = payload
    },
    setActiveTemplateSite (state, payload){
      state.ActiveTemplateSite = payload
    },
    setFeaturedMemberDefaultPage (state, payload){
      state.FeaturedMemberDefaultPage = payload
    },
    setNewSitePageid (state, payload){
      state.NewSitePageid = payload
    },
    setNewPageSite (state, payload){
      state.NewPageSite = payload
    },
    setUserFeaturedMemberSnap (state, payload){
      state.UserFeaturedMemberSnap = payload
    },
    setFeaturedMemberHasPages (state, payload){
      state.FeaturedMemberHasPages = payload
    },
    setActiveElmntConfigComp (state, payload){
      state.ActiveElmntConfigComp = payload
    },
    setUserisCompanyAdmin (state, payload){
      state.UserisCompanyAdmin = payload
    },
    setMyCompany (state, payload){
      state.MyCompany = payload
    },
    SetActiveMemberGroup (state, payload){
      //console.log('SetActiveMemberGroup',payload)
      state.ActiveMemberGroup = payload
    },
    SetEditedPageProperties (state, payload){
      state.EditedPageProperties = payload
    },
    ToggleElementAnimationDialog (state, payload){
      state.ElementAnimationDialog = !state.ElementAnimationDialog
    },
    SetActiveChatRecord (state, payload) {
      state.ActiveChatRecord = payload
    },
    SetTimesheetRelated (state, payload) {
      state.TimesheetRelated = payload
    },
    SetStopwatchDialog (state, payload) {
      state.StopwatchDialog = payload
    },
    SetActiveSuiteMenuItems (state, payload) {
      state.ActiveSuiteMenuItems = payload
    },
    setRelatedRecordObj (state, payload) {
      state.RelatedRecordObj = payload
    },
    setStoreEntity (state, payload) {
      state.StoreEntity = payload
    },
    setActiveWarehouse (state, payload) {
      state.ActiveWarehouse = payload
    },
    setAppBuilderHelperStep (state, payload) {
      state.AppBuilderHelperStep = payload
    },
    setAppBuilderHelperActive (state, payload) {
      state.AppBuilderHelperActive = payload
    },
    setConfirmMSGText (state, payload) {
      state.ConfirmMSGText = payload
    },
    setConfirmMSGDialog (state, payload) {
      state.ConfirmMSGDialog = payload
    },
    setConfirmMSGTitle (state, payload) {
      state.ConfirmMSGTitle = payload
    },
    setConfirmMSGIcon (state, payload) {
      state.ConfirmMSGIcon = payload
    },
    setConfirmMSGIconColor (state, payload) {
      state.ConfirmMSGIconColor = payload
    },
    SetAlertSound (state, payload) {
      state.AlertSound = payload
    },
    setRAApp (state, payload) {
      state.RAApp = payload
    },
    setRADB (state, payload) {
      state.RADB = payload
    },
    setAppsPath (state, payload) {
      state.AppsPath = payload
    },
    setPluginsPath (state, payload) {
      state.PluginsPath = payload
    },
    setWebsiteisActive (state, payload) {
      state.WebsiteisActive = payload
    },
    setSocialNetworkisActive (state, payload) {
      state.SocialNetworkisActive = payload
    },
    setDatabaseisActive (state, payload) {
      state.DatabaseisActive = payload
    },
    setDocumentationisActive (state, payload) {
      state.DocumentationisActive = payload
    },
    setPluginDataBase (state, payload) {
      state.PluginDataBase = payload
    },
    setActiveSuiteApp (state, payload) {
      state.ActiveSuiteApp = payload
    },
    
    setAppsDataBase (state, payload) {
      state.AppsDataBase = payload
    },
    setAppQuickStartDialog (state, payload) {
      state.AppQuickStartDialog = payload
    },
    setActiveFeaturedMember (state, payload) {
      state.ActiveFeaturedMember = payload
    },
    setActiveSuiteWebsitePages (state, payload) {
      state.ActiveSuiteWebsitePages = payload
    },
    setActiveSuiteSocialNetworkPages (state, payload) {
      state.ActiveSuiteSocialNetworkPages = payload
    },
    setActiveSuiteTemplate (state, payload) {
      state.ActiveSuiteTemplate = payload
    },
    setTemplateOwner (state, payload){
      state.TemplateOwner = payload
    },
    setTemplateView (state, payload){
      state.TemplateView = payload
    },
    setTemplateSocialRouteName (state, payload){
      state.TemplateSocialRouteName = payload
    },
    setTemplateRootPath (state, payload){
      state.TemplateRootPath = payload
    },
    setActiveMarketTemplate (state, payload){
      state.ActiveMarketTemplate = payload
    },
    setActiveMarketTemplateChild (state, payload) {
      state.ActiveMarketTemplateChild = payload
    },
    setActiveDocumentationLibrary (state, payload) {
      state.ActiveDocumentationLibrary = payload
    },
    SetSystemConfigEntity (state, payload) {
      state.SystemConfigEntity = payload
    },
    SetSystemConfigTabs (state, payload) {
      state.SystemConfigTabs = payload
    },
    setUploadFilesList (state, payload) {
      state.UploadFilesList = payload
    },
    setPhotoTagObjects (state, payload) {
      state.PhotoTagObjects = payload
    },
    setUploadFileStorageRef (state, payload) {
      state.UploadFileStorageRef = payload
    },
    setUploadFilesDBStorageRef (state, payload) {
      state.UploadFilesDBStorageRef = payload
    },
    setGroupForbidden (state, payload) {
      state.GroupForbidden = payload
    },
    setUserMemberObj (state, payload) {
      state.UserMemberObj = payload
    },
    setSiteDialog (state, payload) {
      state.SiteDialog = payload
    },
    setMiniRecordDialog (state, payload) {
      state.MiniRecordDialog = payload
    },
    setMiniRecordDialogTitle (state, payload) {
      state.MiniRecordDialogTitle = payload
    },
    setMiniRecordObject (state, payload) {
      state.MiniRecordObject = payload
    },
    setMiniRecordDialogIMG (state, payload) {
      state.MiniRecordDialogIMG = payload
    },
    setMiniRecordDialogDescription (state, payload) {
      state.MiniRecordDialogDescription = payload
    },
    setMiniRecordPath (state, payload) {
      state.MiniRecordPath = payload
    },
    setMiniRecordTabs (state, payload) {
      state.MiniRecordTabs = payload
    },
    setSystemAssets (state, payload) {
      state.SystemAssets = payload
    },
    setContentBlocks (state, payload) {
      state.ContentBlocks = payload
    },
    setRenderedContentBlocks (state, payload) {
      state.RenderedContentBlocks = payload
    },
    setIsGroupView (state, payload) {
      state.IsGroupView = payload
    },
    setGroupScopeTabs (state, payload) {
      state.GroupScopeTabs = payload
    },
    setBuilderView (state, payload) {
      state.BuilderView = payload
    },
    setEditedPage (state, payload) {
      state.EditedPage = payload
    },

    setIMGProp (state, payload) {
      state.IMGProp = payload
    },
    setSelectedGalleryAsset (state, payload) {
      state.SelectedGalleryAsset = payload
    },
    setEditedTab (state, payload) {
      state.EditedTab = payload
    },
    setdragging (state, payload) {
      state.dragging = payload
    },
    setdraggingtab (state, payload) {
      state.draggingtab = payload
    },
    setdraggingtabindex (state, payload) {
      state.draggingtabindex = payload
    },
    setdraggingelement (state, payload) {
      state.draggingelement = payload
    },
    setdraggingelementindex (state, payload) {
      state.draggingelementindex = payload
    },
    setdraggingrow (state, payload) {
      state.draggingrow = payload
    },
    setdraggingrowindex (state, payload) {
      state.draggingrowindex = payload
    },
    setGalleryLibraries (state, payload) {
      state.GalleryLibraries = payload
    },
    setGalleryAssets (state, payload) {
      state.GalleryAssets = payload
    },
    setActiveGroup (state, payload) {
      state.ActiveGroup = payload
    },
    EmitMarketDialogOptions(state, payload) {
      state.MarketDialogOptions = payload
    },
    EmitMarketDialogObject(state, payload) {
      state.MarketDialogObject = payload
    },
    EmitMarketDialog(state, payload) {
      state.MarketDialog = payload
    },
    EmitMarketDialogType (state, payload) {
      state.MarketDialogType = payload
    },
    EmitMarketDialogDescription (state, payload) {
      state.MarketDialogDescription = payload
    },
    EmitMarketDialogTitle (state, payload) {
      state.MarketDialogTitle = payload
    },
    EmitMarketDialogAvatar (state, payload) {
      state.MarketDialogAvatar = payload
    },    
    EmitMarketDialogInteractMethod (state, payload) {
      state.MarketDialogInteractMethod = payload
    }, 
    setPDFExportDoc (state, payload) {
      state.PDFExportDoc = payload
    },
    setPDFFunctionName (state, payload) {
      state.PDFFunctionName = payload
    },
    setPDFHeaderObj (state, payload) {
      state.PDFHeaderObj = payload
    },
    setPDFFooterObj (state, payload) {
      state.PDFFooterObj = payload
    },
    setExportingtoPDFDialog (state, payload) {
      state.ExportingtoPDFDialog = payload
    },
    setPDFDownloadOnly (state, payload) {
      state.PDFDownloadOnly = payload
    },
    setPDFDocLineItems (state, payload) {
      state.PDFDocLineItems = payload
    },
    setUserAvailableTokens (state, payload) {
      state.UserAvailableTokens = payload
    },
    setOrderConfirmationDialog (state, payload) {
      state.OrderConfirmationDialog = payload
    },
    setPDFDataRecord (state, payload) {
      state.PDFDataRecord = payload
    },
    setOrderConfirmationPDF (state, payload) {
      state.OrderConfirmationPDF = payload
    },
    setCustomProcessingDialog (state, payload) {
      state.CustomProcessingDialog = payload
    },
    setCustomProcessingDialogText (state, payload) {
      state.CustomProcessingDialogText = payload
    },
    setAllowCloseCustomProcessingDialog (state, payload) {
      state.AllowCloseCustomProcessingDialog = payload
    },
    setUserRequiredInfo(state, payload) {
      state.UserRequiredInfo = payload
    },
    setUserInfoRequired (state, payload) {
      state.UserInfoRequired = payload
    },
    setUserAdmin (state, payload) {
      state.IsAdmin = payload
    },
    setUserEagleView (state, payload) {
      state.IsEagleViewer = payload
    },
    setSiteContentAdmin (state, payload) {
      state.SiteContentAdmin = payload
    },
    setBusinessProfileAdmin (state, payload) {
      state.BusinessProfileAdmin = payload
    },
    SetSystemObj (state, payload) {
      state.SystemObj = payload.system
    },
    SetSystemDefaultCurrency (state, payload) {
      state.DefaultCurrency = payload
    },
    SetUserStateObj (state, payload) {
      state.user = payload
    },
    AddCatNavlistTable (state, payload) {
      state.CatNavlists.push(payload)
    },
    SetCatNavlistTableQuery (state, payload) {
      let entry = state.CatNavlists.find(obj => obj.EntityID === payload.EntityID)
      if(entry){
        entry.Query = payload.Query
      }
    },
    SetUserIdToken (state, payload) {
      state.useridtoken = payload
    },
    SetLastRoute(state, payload) {
      //console.log(typeof payload.Date.toDate)
      //console.log(typeof payload.Date)
      if(typeof payload.toDate !== 'undefined'){
       let d = payload.Date.toDate()  
        var hr = d.getHours();      
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        payload.Date = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min; 
      }
      
      state.LastRoute = payload
    },
    
    
    setRAUserObj (state, payload) {
      state.RAUserObj = payload
    },
    
    setSiteUserObj (state, payload) {
      //console.log('setUserObj',payload)
      state.userobj = payload
    },
    setSiteMonetizationBU (state, payload) {
      state.SiteMonetizationBU = payload
    },
    setPrimaryMonetizeBU (state, payload) {
      state.PrimaryMonetizeBU = payload
    },
    setSiteSystem (state, payload) {
      state.SiteSystem = payload
    },
    setPrimarySystem (state, payload) {
      state.PrimarySystem = payload
    },
    setUsePrimaryUserObj (state, payload) {
      state.UsePrimaryUserObj = payload
    },
    setUserObj (state, payload) {
      //console.log('setUserObj',payload)
      if(state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID){
        state.userobj = payload
      }
      state.PrimaryUserObj = payload
    },
    SetAppMode (state, payload) {
      state.AppMode = payload
    },
    SetInvitedGroups (state, payload) {
      //console.log('setting SetCurrentEntity as '+payload)
      //alert('setting invited groups')
      state.InvitedGroups = payload
    },
    SetCurrentEntity (state, payload) {
      //console.log('setting SetCurrentEntity as '+payload)
      state.CurrentEntity = payload
    },
    SetCartEntity (state, payload) {
      //console.log('setting SetCurrentEntity as '+payload)
      state.Cart_Entity = payload
    },
    
    SetCurrentItemModerator (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.CurrentItemModerator = payload
    },
    SetStoreItemPublisher (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.StoreItemPublisher = payload
    },
    SetViewOrderNavbar (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.ViewOrderNavbar = payload
    },
    SetStoreSearch (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.StoreSearch = payload
    },
    SetCanReceive (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.CanReceive = payload
    },
    SetCanViewMovementHistory (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.CanViewMovementHistory = payload
    },
    SetSelectedDataTabs (state, payload) {
      state.SelectedDataTabs = payload
    },
    SetSingleNavTabs (state, payload) {
      state.SingleNavTabs = payload
    },
    SetCurrentDataTabs (state, payload) {
      state.CurrentDataTabs = payload
    },
    SetSocialItemInteractMethod (state, payload) {
      state.SocialItemInteractMethod = payload
    },
    SetSocialItemInteractMethodProp (state, payload) {
      state.SocialItemInteractMethodProp = payload
    },
    SetRandomInteractProp (state, payload) {
      state.RandomInteractProp = payload
    },
    SetEntityObj (state, payload) {
      state[payload.ArrayName] = []
      state[payload.QueryName] = ''
    },
    setUserBUObj (state, payload) {
      state.buobj = payload
    },
    setCollectionInteractPermission (state,payload){
      state[payload.queryname] = payload.boolean
    },
    setCollectionQuery (state, payload) {
      state[payload.queryname] = payload.query
    },
    setReroutePath (state, payload){
      state.ReroutePath = payload
    },
    setViewInstanceID (state, payload){
      state.ViewInstanceID = payload
    },
    setSocialEntities (state, payload){
      state.SocialEntities = payload
    },
    setCompanyCollectionArray (state, payload) {
      state.MyCompany[payload.prop][payload.arrayname] = payload.array
      console.log('state.MyCompany',state.MyCompany)
    },
    setCollectionArray (state, payload) {
      state[payload.arrayname] = payload.array
      let entry = state.TableData.find(obj => obj.Name === payload.arrayname)
      if(entry){
        let index = state.TableData.indexOf(entry)
        state.TableData[index].Data = payload.array
      }
      //What to do with "Call"?
      //console.log('state.TableData',state.TableData)
    },
    setCatNavlistData (state, payload) {
      //console.log(payload.EntityID+' is now '+payload.array)
      let entry = state.CatNavlists.find(obj => obj.EntityID === payload.EntityID)
      if(entry){
        entry.Data = payload.array
      }
      //console.log('state.TableData',state.TableData)
    },
    
    setUser (state, payload) {
      state.user = payload
      // state.user.id = payload.id
      // state.user.BusinessUnit = payload.BusinessUnit
      // state.user.FullName = payload.FirstName+' '+payload.Surname
    },

    removeUser (state) {
      state.user = null
    },

    setStatus (state, payload) {
      state.status = payload
    },

    setError (state, payload) {
      state.error = payload
    },
    //SET_USER (state, user) {
      //state.authUser = user

    //},
   //authUser(state, user) {
      //state.user.id = user.uid
      //state.user.Full_Name = user.Full_Name
      //state.user.authenticated = true
     // console.log(state.user.Full_Name)
    //}, -->

  },
  actions: {
    loginWithFirebase ({ commit },payload) {
      //console.log(payload)
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
          .then(function(result) {
            console.log(result.user.uid)
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
            console.log('login user id',result.user.uid)
             
          }).catch(function(error) {
            console.log(error)
            if(error.code === 'auth/wrong-password'){
              alert('This password does not seem correct')
            }
            else if(error.code === 'auth/user-not-found'){
              alert('We do not recognize this email')
            }
            // if(error.message.toLowerCase().includes('password is invalid'))
            // Handle error.
          });


    },
    CopyRouteToClipBoard ({ commit }, payload) {
      let path = '' 
      let route = payload.route
      let successmsg = payload.successmsg
      if(window.location.port.length > 0){          
          path = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+route
      }
      else{
          path = window.location.protocol+'//'+window.location.hostname+route
      }
      navigator.clipboard.writeText(path).then(function() {
        let snackbarcontent = {
            snackbartimeout: 4000,
            snackbartext: successmsg,
            snackbartop: true,
            boolean: true
          }
          commit('SetSocialItemInteractMethodProp', snackbarcontent)
          commit('SetSocialItemInteractMethod', 'CommitSnackbar')
      }, function(err) {
      console.error('Async: Could not copy text: ', err);
      });
    },
    AssignQueryString (state, payload) {
      return new Promise(function(resolve, reject) { 
        console.log(payload)
        let NewDataObj = payload.NewDataObj
        let header = payload.header
        let String = NewDataObj[header.propvalue].trim()
        //console.log('String','"String"')
        var lowertext = "";                
        var p;
        let buildup = ''
        //console.log(String.length,String)
        for (p = 0; p < String.length; p++) {
            buildup = buildup+String[p]
            if(p === String.length-1){
              lowertext += buildup.toLowerCase();
            }
            else{
              lowertext += buildup.toLowerCase() + ",";
            }
          }
          NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(NewDataObj[header.propvalue].trim().split(' '))         
          NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(lowertext.split(','))  
          NewDataObj.SearchQuery = NewDataObj.SearchQuery.map(entry => {
            return entry.toLowerCase()
          })
          resolve(NewDataObj)
        })
        
    },
    FullSTDDate({ commit }, payload) {  
      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
    
      var hr = payload.getHours();
      
      var min = payload.getMinutes();
      if (min < 10) {
          min = "0" + min;
      }
      if(hr < 10){
        hr = "0"+hr
      }

      var daytext = days[payload.getDay()].Longtext
      var date = payload.getDate();
      var month = months[payload.getMonth()];
      var year = payload.getFullYear();
      // 2020-03-15 09:00'
      let finaldate = daytext+' '+date+', '+month+', '+year

      return finaldate
    },
    CreateSearchTitle({ commit }, payload) {  
      let titleQuery = []
      let titleStringArray = payload.split('')
      var lowertext = "";                
      var p;
        let buildup = ''
        //console.log(titleStringArray.length,titleStringArray)
        for (p = 0; p < titleStringArray.length; p++) {
            buildup = buildup+ titleStringArray[p]
              if(p === titleStringArray.length-1){
                lowertext += buildup.toLowerCase();
              }
              else{
                lowertext += buildup.toLowerCase() + ",";
              }
              }                   
        titleQuery = lowertext.split(',')  
        titleQuery = titleQuery.concat(payload.split(' '))
        return titleQuery.map(entry => {
          return entry.toLowerCase()
        })
    },
    CreateVCard({ commit }, payload) {  
      let member = payload
      let item = `BEGIN:VCARD%0D%0AVERSION:3.0%0D%0AN;CHARSET=utf-8:`+member.Surname.split(' ').join('%20')+`;`+member.Name.split(' ').join('%20')+`%0D%0AFN;CHARSET=utf-8:`+member.Name.split(' ').join('%20')+`%20`+member.Surname.split(' ').join('%20')
      if(member.Website){//URL;type=WORK;CHARSET=UTF-8:www.creativepropertyprojects.com
        item = item+`%0D%0AURL;CHARSET=utf-8:`+member.Website.split(' ').join('%20')
      }
      if(member.Position){
        item = item+`%0D%0AORG;CHARSET=utf-8:Company%0D%0ATITLE;CHARSET=utf-8:`+member.Position.split(' ').join('%20')
      }
      if(member.AddressLine1 && member.AddressLine2 && member.AddressLineCity && member.AddressLinePostalCode && member.AddressLineProvince && member.AddressLineCountry){

        item = item+`%0D%0AADR;WORK;PREF;CHARSET=utf-8:;;`+member.AddressLine1.split(' ').join('%20')+`;`+member.AddressLineCity.split(' ').join('%20')+`;`+member.AddressLineProvince.split(' ').join('%20')+`;`+member.AddressLinePostalCode.split(' ').join('%20')+`;`+member.AddressLineCountry.split(' ').join('%20')
      }
      if(member.Email){
        item = item+`%0D%0AEMAIL:`+member.Email
      }
      if(member.Work_Number){
        item = item+`%0D%0ATEL;TYPE=WORK,VOICE:`+member.Work_Number
      }
      if(member.Mobile_Number){
        item = item+`%0D%0ATEL;TYPE=CELL,VOICE:`+member.Mobile_Number
      }
      if(member.Fax_Number){
        item = item+`%0D%0ATEL;TYPE=WORK,FAX:`+member.Mobile_Number
      }
      if(member.Birthday){
        let birthdayjava = member.Birthday.toDate()
        let cardbday = format(birthdayjava,'yyyy-MM-dd')
        item = item+`%0D%0ABDAY;VALUE=DATE:`+cardbday
      }
      
      item = item+`%0D%0AEND:VCARD`
      //console.log(item)
   return item
    },
    signInClientAppAction ({ commit }, payload) {
      return new Promise(function(resolve, reject) { 
      let check = firebase.apps.find(obj => obj.name === payload.SystemName) 
      //console.log('yes this one',check)    
      if(!check){
        const clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)
        clientApp.auth().signInWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          resolve(response)
         
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      }
      else{
        const clientApp = check  
        clientApp.auth().signInWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          resolve(response)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      }
    })
    },
    UserLoginStatus({ commit }, payload) {
    //console.log('will try set status')
      db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(payload.uid).update({
          onlinestatus: payload.status
        }) 
},
    
    GetActiveFeaturedMember({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // payload.query.onSnapshot(snapshot => {
          //console.log('libraryobj',payload)
          let libraryobj = Object.assign({},payload)
          delete libraryobj.Query
          //note, before we did library object setting payload, which included query.
          //Query being assign to this state value makes newlandingpage hang 
          //we changed that now Query no longer part of "ActiveFeaturedMember" etc as seen
          libraryobj.Pages = []
          libraryobj.Assets = []
          libraryobj.PagePresets = []
          let pagequery = payload.Query
          pagequery.collection('Pages').onSnapshot(res => {
            let pageslength = res.docs.length
            const changes = res.docChanges();									
                  changes.forEach(change => {									
                    if (change.type === 'added') {									
                        let pageobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      libraryobj.Pages.push(pageobj)
                    }
                    if (change.type === 'modified') {									
                        let pageobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      let oncheck = libraryobj.Pages.find(obj => obj.id === pageobj.id)
                      if(oncheck){
                          let index = libraryobj.Pages.indexOf(oncheck)
                          libraryobj.Pages.splice(index,1,pageobj)
                      }
                    }
                    if(libraryobj.Pages.length === pageslength){
                      commit('setActiveFeaturedMember', libraryobj)  
                    }
                  })
        })
        if(this.state.user && this.state.user.id === libraryobj.Ownerid || this.state.IsAdmin){
          pagequery.collection('Assets').onSnapshot(res => {
            let assetslength = res.docs.length
            const changes = res.docChanges();									
                  changes.forEach(change => {									
                    if (change.type === 'added') {									
                        let assetobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      libraryobj.Assets.push(assetobj)
                    }
                    if (change.type === 'modified') {									
                        let assetobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      let oncheck = libraryobj.Assets.find(obj => obj.id === assetobj.id)
                      if(oncheck){
                          let index = libraryobj.Assets.indexOf(oncheck)
                          libraryobj.Assets.splice(index,1,assetobj)
                      }
                    }
                    if (change.type === 'removed') {									
                        let assetobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      let oncheck = libraryobj.Assets.find(obj => obj.id === assetobj.id)
                      if(oncheck){
                          let index = libraryobj.Assets.indexOf(oncheck)
                          libraryobj.Assets.splice(index,1)
                      }
                    }
                    if(libraryobj.Assets.length === assetslength){
                      commit('setActiveFeaturedMember', libraryobj)  
                    }
                  })
        })
        pagequery.collection('storedpagepresets').onSnapshot(res => {
          let pageslength = res.docs.length
          const changes = res.docChanges();									
                changes.forEach(change => {									
                  if (change.type === 'added') {									
                      let pageobj = {									
                      ...change.doc.data(),									
                      id: change.doc.id									
                    }	
                    libraryobj.PagePresets.push(pageobj)
                  }
                  if (change.type === 'modified') {									
                      let pageobj = {									
                      ...change.doc.data(),									
                      id: change.doc.id									
                    }	
                    let oncheck = libraryobj.PagePresets.find(obj => obj.id === pageobj.id)
                    if(oncheck){
                        let index = libraryobj.PagePresets.indexOf(oncheck)
                        libraryobj.PagePresets.splice(index,1,pageobj)
                    }
                  }
                  if(libraryobj.PagePresets.length === pageslength){
                    commit('setActiveFeaturedMember', libraryobj)  
                  }
                })
      })
        }
        })
      // })
    },
    GetActiveDocumentationLibrary({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // payload.query.onSnapshot(snapshot => {
          //onsole.log('libraryobj',payload)
          let libraryobj = payload
          libraryobj.Pages = []
          let pagequery = libraryobj.Query.collection('Pages')
          if(payload.Public){
            pagequery = pagequery.where('PublishType','==','Public')
          }
          pagequery.onSnapshot(res => {
            let pageslength = res.docs.length
            const changes = res.docChanges();									
                  changes.forEach(change => {									
                    if (change.type === 'added') {									
                        let pageobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      libraryobj.Pages.push(pageobj)
                    }
                    if (change.type === 'modified') {									
                        let pageobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      let oncheck = libraryobj.Pages.find(obj => obj.id === pageobj.id)
                      if(oncheck){
                          let index = libraryobj.Pages.indexOf(oncheck)
                          libraryobj.Pages.splice(index,1,pageobj)
                      }
                    }
                    if(libraryobj.Pages.length === pageslength){
                      commit('setActiveDocumentationLibrary', libraryobj)  
                    }
                  })
        })
        })
      // })
    },
    GetUsersArray({ commit }, payload) {
      if(typeof this.state.UsersCall === 'function'){
        console.log('killing users snap')
        this.state.UsersCall();
      }
      // return new Promise((resolve, reject) => {
        let array = []
    commit('setUsersCall', payload.onSnapshot(res => {
      const changes = res.docChanges();
          changes.forEach(change => {
          if (change.type === 'added') {
              let userobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                array.push(userobj)
            }
            if(change.type === 'modified'){
              let userobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              let item = array.find(obj => obj.id === userobj.id)
              if(item){
                let index = array.indexOf(item)
                array.splice(index,1,userobj)
              }
            }
            if(change.type === 'removed'){
              let userobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              let item = array.find(obj => obj.id === userobj.id)
              if(item){
                let index = array.indexOf(item)
                array.splice(index,1)
              }
            }
            commit('setUsersArray', array)
          })
    })       
    )
    // })
  },
  //systembuilder stuff
  ClientAdminsignUpAction ({ commit }, payload) {
    return new Promise(function(resolve, reject) {     
    let check = firebase.apps.find(obj => obj.name === payload.SystemName)
    //console.log(check)     
     const userobj = payload.user
    if(check){
      const clientApp = check       
        clientApp.auth().createUserWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          resolve(response)
            
        }).catch(error => {
          console.log(error)
        });
    }
    else{
      const clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)        
      const clientDB = clientApp.firestore()
        clientApp.auth().createUserWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {            
          resolve(response)
        }).catch(error => {
          console.log(error)
        });
    }
  })
    
  },
  ProcessClientBU ({ commit }, payload) {
    // commit('setStatus', 'loading')
    return new Promise(function(resolve, reject) {
    //console.log('I work',firebase.apps.length)
    //console.log(firebase.apps)
    //console.log(payload.ClientFireStore)
    //console.log(payload.SystemName)
    //console.log(payload.NewclientBU)
    let check = firebase.apps.find(obj => obj.name === payload.SystemName)
    //console.log(check)
    let clientApp = {}
    if(check){
      clientApp = check
    }
    else{
      clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)
    }

      const clientDB = clientApp.firestore()
      clientDB.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('businessunits').doc(payload.NewclientBU.Name.split(' ').join('_')).set(payload.NewclientBU)
      .then(doc => {
        let childarrayDBRules = {
          [payload.NewclientBU.Name.split(' ').join('_')]: true
        }
        let childarrayQuery = [
          payload.NewclientBU.Name.split(' ').join('_')
        ]
        clientDB.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('businessunits').doc(payload.NewclientBU.Name.split(' ').join('_')).update({
          childarrayDBRules: childarrayDBRules,
          childarrayQuery: childarrayQuery
        })
        .then(response => {
          resolve(payload.NewclientBU.Name.split(' ').join('_'))
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      })
      // clientDB.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('businessunits').add(payload.NewclientBU)
      // .then(doc => {
      //   let childarrayDBRules = {
      //     [doc.id]: true
      //   }
      //   let childarrayQuery = [
      //     doc.id
      //   ]
      //   clientDB.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('businessunits').doc(doc.id).update({
      //     childarrayDBRules: childarrayDBRules,
      //     childarrayQuery: childarrayQuery
      //   })
      //   .then(response => {
      //     resolve(doc.id)
      //   })
      //   .catch((error) => {
      //     commit('setStatus', 'failure')
      //     commit('setError', error.message)
      //     alert(error.message)
      //   })
      // })
      .catch((error) => {
        commit('setStatus', 'failure')
        commit('setError', error.message)
        alert(error.message)
      })
    })
    
  },
  //systembuilder stuff
  GetArrayandSubcols({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let query = payload.query
        let provider = payload.provider
        query.onSnapshot(res => {
          let arraylength = res.docs.length
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: []
        }   
        commit('setCollectionArray', setcollection)
      }
      else{
          let array = []
          if(this.state[payload.arrayname]){
            array = this.state[payload.arrayname]
          }
          const changes = res.docChanges();
          changes.forEach((change,i) => {
            if (change.type === 'added') {
              let itemobj = change.doc.data()
              itemobj.id = change.doc.id
              if(provider){
                itemobj.Provider = provider 
              }
              itemobj[payload.SubcolName] = []
              query.doc(itemobj.id).collection(payload.Subcolpath).onSnapshot(subcolres => {
                const subcolchanges = subcolres.docChanges();
                let subcollength = subcolres.docs.length
                subcolchanges.forEach((subcolchange,i) => {
                  if (subcolchange.type === 'added') {
                    itemobj[payload.SubcolName].push({
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id})
                    if(itemobj[payload.SubcolName].length === subcollength){
                      let oncheck = array.find(obj => obj.id === itemobj.id)
                      if(!oncheck){
                        array.push(itemobj)
                      }                      
                    }
                  }
                 
                })
                if(subcollength === 0){
                  array.push(itemobj)
                }
              })
            }
             if(change.type === 'modified'){
                    let itemobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                   // console.log('itemobj change',itemobj)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = array.indexOf(oncheck)
                      itemobj[payload.SubcolName] = oncheck[payload.SubcolName]
                      array.splice(index,1,itemobj)
                     // console.log('array update',array)
                    }
                  }
                  if(change.type === 'removed'){
                    let itemobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                   // console.log('itemobj change',itemobj)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = array.indexOf(oncheck)
                      array.splice(index,1)
                     // console.log('array update',array)
                    }
                  }
          })
          let setcollection = {
            arrayname : payload.arrayname, 
            array: array
          }   
          //console.log('store getting that query now ',payload.arrayname)
          commit('setCollectionArray', setcollection) 
          //console.log(payload.arrayname,'array.length ',array.length,'arraylength',arraylength)
          if(array.length === arraylength){
            //console.log('store resolving not as array ',payload.arrayname,payload)
            resolve('Completed NOT Empty')
          }
        } 
        },
        error => {
          if(error.code === 'permission-denied'){
            console.log('permission-denied on GetArrayandSubcols snapshot '+payload.arrayname)
          }
        })
    })
  },

  SetItemPreArrayCall({ commit }, payload) {
    return new Promise((resolve, reject) => {
        let itemobj = payload.itemobj
        let array = []
        if(this.state[payload.arrayname]){          
          array = this.state[payload.arrayname]
        }
          array.push(itemobj)
          if(array.length > 0){          
            let setcollection = {
              arrayname : payload.arrayname, 
              array: [itemobj]
            }   
            commit('setCollectionArray', setcollection)
            resolve('Item Array IS called, Pre-Calling now')
          }
          else{          
            let setcollection = {
              arrayname : payload.arrayname, 
              array: [itemobj]
            }   
            commit('setCollectionArray', setcollection)
            resolve('Item Array NOT called, Pre-Calling now')
          }
    })
  },
  GetItemMultipleSubcols({ commit }, payload) {
    return new Promise((resolve, reject) => {
      //This may become three tier subcols later, however
      //This is for something that is going to be called to an array later
      //it's typically marketplacetemplates or other arrays where routes search for matching item but happens too late
      //issue being, if it gets called on array later, do we then snapshot it now or do we "get" now?
      //Conclusion being we do this as a once off right? Once off only. 
      //In fact I am going to try a complete different cheat approach first before I try this beast
      //Refer AppPlugins and Routes where I will attempt my workaround.
      let subcolquery = payload.subcolquery

      let provider = payload.provider
      query.onSnapshot(res => {
        let arraylength = res.docs.length
        let SubCollections = payload.SubCollections
        let subcolslength = payload.SubCollections.length
    if(!res.docs){
      resolve('Completed Empty')
      let setcollection = {
        arrayname : payload.arrayname, 
        array: []
      }   
      commit('setCollectionArray', setcollection)
    }
    else{
        let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let itemobj = change.doc.data()
            itemobj.id = change.doc.id
            if(payload.arrayname === 'MySuiteTemplates'){
              //console.log('gotcha added item ',itemobj.id,itemobj.Ownerid)
            }
            if(provider){
              itemobj.Provider = provider 
            }
            setTimeout(() => {
               SubCollections.map((scol,scoli) => {
              itemobj[scol.SubcolName] = []
              if(payload.arrayname === 'MySuiteTemplates'){
                //console.log('subcolquery',subcolquery,'and Subcolpath',scol.Subcolpath,'full subcol query ',subcolquery.doc(itemobj.id).collection(scol.Subcolpath))
              }
              let iserr = false
              //const subcolcall = 
              subcolquery.doc(itemobj.id).collection(scol.Subcolpath).onSnapshot(subcolres => {
                const subcolchanges = subcolres.docChanges();
                let subcollength = subcolres.docs.length
                subcolchanges.forEach((subcolchange,i) => {
                  if (subcolchange.type === 'added') {
                    if(payload.arrayname === 'MySuiteTemplates'){
                      //console.log('yay we picked up addition on '+scol.Subcolpath+' for '+itemobj.id)
                    }
                    itemobj[scol.SubcolName].push({
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id})
                    if(itemobj[scol.SubcolName].length === subcollength && scoli-1+2 === subcolslength){
                      let oncheck = array.find(obj => obj.id === itemobj.id)
                      if(!oncheck){
                        array.push(itemobj)
                      }
                      else{
                        let ind = array.indexOf(oncheck)
                        array.splice(ind,1,itemobj)
                      }                      
                    }
                  }
                  if (subcolchange.type === 'modified') {
                    //will still work on this, if rmeoved works
                    let subcolobj = {
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id
                    }
                    //console.log('removed subcolrec',subcolobj.id)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      if(oncheck[scol.SubcolName]){
                      let subcoloncheck = oncheck[scol.SubcolName].find(obj => obj.id === subcolobj.id)
                      if(subcoloncheck){
                        let subcolobjindex = oncheck[scol.SubcolName].indexOf(subcoloncheck)
                        oncheck[scol.SubcolName].splice(subcolobjindex,1,subcolobj)
                      }
                      }
                    }
                  }
                  if (subcolchange.type === 'removed') {
                    let subcolobj = {
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id
                    }
                    //console.log('removed subcolrec',subcolobj.id)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      if(oncheck[scol.SubcolName]){
                      let subcoloncheck = oncheck[scol.SubcolName].find(obj => obj.id === subcolobj.id)
                      if(subcoloncheck){
                        let subcolobjindex = oncheck[scol.SubcolName].indexOf(subcoloncheck)
                        oncheck[scol.SubcolName].splice(subcolobjindex,1)
                      }
                      }
                    }
                  }
                
                })
                if(subcollength === 0 && scoli-1+2 === subcolslength){
                  array.push(itemobj)
                }
              },
              error => {
                if(error.code === 'permission-denied'){
                  console.log('permission-denied on snapshot '+payload.arrayname+' subcol '+scol.SubcolName)
                  if(payload.arrayname === 'MySuiteTemplates'){
                    //console.log("let's die")
                  }
                  //iserr = true          
                }
              })
              if(iserr){
                // subcolcall;
                // console.log('what if we do it again now?')
                // subcolcall;
              }
            })   
            }, 250);
                    
          }
           if(change.type === 'modified'){
                  let itemobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                 // console.log('itemobj change',itemobj)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(oncheck){
                    let index = array.indexOf(oncheck)
                    SubCollections.map((scol,scoli) => {
                      itemobj[scol.SubcolName] = oncheck[scol.SubcolName]
                    })
                    array.splice(index,1,itemobj)
                   // console.log('array update',array)
                  }
                }
                if(change.type === 'removed'){
                  let itemobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                 // console.log('itemobj change',itemobj)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(oncheck){
                    let index = array.indexOf(oncheck)
                    array.splice(index,1)
                   // console.log('array update',array)
                  }
                }
        })
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCollectionArray', setcollection) 
        if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      } 
      },
      error => {
        if(error.code === 'permission-denied'){
          console.log('permission-denied on parent snapshot '+payload.arrayname)
          if(payload.arrayname === 'MySuiteTemplates'){
            //console.log("let's die")
          }
          iserr = true          
        }
      })
  })
},
  GetArrayandMultipleSubcols({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let query = payload.query
      let subcolquery = payload.subcolquery
      if(!subcolquery){
        subcolquery = query
      }
      let provider = payload.provider
      query.onSnapshot(res => {
        let arraylength = res.docs.length
        let SubCollections = payload.SubCollections
        let subcolslength = payload.SubCollections.length
    if(!res.docs){
      resolve('Completed Empty')
      let setcollection = {
        arrayname : payload.arrayname, 
        array: []
      }   
      commit('setCollectionArray', setcollection)
    }
    else{
        let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let itemobj = change.doc.data()
            itemobj.id = change.doc.id
            if(payload.arrayname === 'MySuiteTemplates'){
              //console.log('gotcha added item ',itemobj.id,itemobj.Ownerid)
            }
            if(provider){
              itemobj.Provider = provider 
            }
            setTimeout(() => {
               SubCollections.map((scol,scoli) => {
              itemobj[scol.SubcolName] = []
              if(payload.arrayname === 'MySuiteTemplates'){
                //console.log('subcolquery',subcolquery,'and Subcolpath',scol.Subcolpath,'full subcol query ',subcolquery.doc(itemobj.id).collection(scol.Subcolpath))
              }
              let iserr = false
              //const subcolcall = 
              subcolquery.doc(itemobj.id).collection(scol.Subcolpath).onSnapshot(subcolres => {
                const subcolchanges = subcolres.docChanges();
                let subcollength = subcolres.docs.length
                subcolchanges.forEach((subcolchange,i) => {
                  if (subcolchange.type === 'added') {
                    if(payload.arrayname === 'MySuiteTemplates'){
                      //console.log('yay we picked up addition on '+scol.Subcolpath+' for '+itemobj.id)
                    }
                    itemobj[scol.SubcolName].push({
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id})
                    if(itemobj[scol.SubcolName].length === subcollength && scoli-1+2 === subcolslength){
                      let oncheck = array.find(obj => obj.id === itemobj.id)
                      if(!oncheck){
                        array.push(itemobj)
                      }
                      else{
                        let ind = array.indexOf(oncheck)
                        array.splice(ind,1,itemobj)
                      }                      
                    }
                  }
                  if (subcolchange.type === 'modified') {
                    //will still work on this, if rmeoved works
                    let subcolobj = {
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id
                    }
                    //console.log('removed subcolrec',subcolobj.id)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      if(oncheck[scol.SubcolName]){
                      let subcoloncheck = oncheck[scol.SubcolName].find(obj => obj.id === subcolobj.id)
                      if(subcoloncheck){
                        let subcolobjindex = oncheck[scol.SubcolName].indexOf(subcoloncheck)
                        oncheck[scol.SubcolName].splice(subcolobjindex,1,subcolobj)
                      }
                      }
                    }
                  }
                  if (subcolchange.type === 'removed') {
                    let subcolobj = {
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id
                    }
                    //console.log('removed subcolrec',subcolobj.id)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      if(oncheck[scol.SubcolName]){
                      let subcoloncheck = oncheck[scol.SubcolName].find(obj => obj.id === subcolobj.id)
                      if(subcoloncheck){
                        let subcolobjindex = oncheck[scol.SubcolName].indexOf(subcoloncheck)
                        oncheck[scol.SubcolName].splice(subcolobjindex,1)
                      }
                      }
                    }
                  }
                
                })
                if(subcollength === 0 && scoli-1+2 === subcolslength){
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(!oncheck){
                    array.push(itemobj)
                  }
                  else{
                    let ind = array.indexOf(oncheck)
                    array.splice(ind,1,itemobj)
                  } 
                  
                }
              },
              error => {
                if(error.code === 'permission-denied'){
                  console.log('permission-denied on snapshot '+payload.arrayname+' subcol '+scol.SubcolName)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(!oncheck){
                    array.push(itemobj)
                  } 
                  if(payload.arrayname === 'MySuiteTemplates'){
                    //console.log("let's die")
                  }
                  iserr = true          
                }
              })
              if(iserr){
                // subcolcall;
                // console.log('what if we do it again now?')
                // subcolcall;
              }
            })   
            }, 250);
                    
          }
           if(change.type === 'modified'){
                  let itemobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                 // console.log('itemobj change',itemobj)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(oncheck){
                    let index = array.indexOf(oncheck)
                    SubCollections.map((scol,scoli) => {
                      itemobj[scol.SubcolName] = oncheck[scol.SubcolName]
                    })
                    array.splice(index,1,itemobj)
                   // console.log('array update',array)
                  }
                }
                if(change.type === 'removed'){
                  let itemobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                 // console.log('itemobj change',itemobj)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(oncheck){
                    let index = array.indexOf(oncheck)
                    array.splice(index,1)
                   // console.log('array update',array)
                  }
                }
        })
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCollectionArray', setcollection) 
        if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      } 
      })
  })
},
    GetCompanyCollectionArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      if(!Array.isArray(payload.query)){
        //console.log('not array',payload)
        this.dispatch('GetCompanyCollectionArrayQuery',payload).then(result => {
          resolve(result)
        })
      }
      else{
        //console.log('as array',payload)
        let length = payload.query.length
        //console.log('store array payload.query',payload.query,payload)
        payload.query.map((singlequery,singlequeryindex) => {
          //console.log('store payload.arrayname',payload.arrayname,singlequeryindex,length)
          let newquery = Object.assign(payload)
          newquery.query = singlequery
          newquery.AsArray = true
          this.dispatch('GetCompanyCollectionArrayQuery',newquery).then(result => {
            //console.log('store array result',payload.arrayname,result,singlequeryindex,length)
            if(singlequeryindex-1+2 === length){
            resolve(result)
            }           
          })         
        })
      }
    })
    },
  GetResetArray({ commit }, payload) {
    //alert('ah hell yeah I call')
    return new Promise((resolve, reject) => {
      if(typeof this.state[payload.Snapshot] === 'function'){
        this.state[payload.Snapshot]();
      }
    payload.query.onSnapshot(res => {
      let arraylength = res.docs.length
      //console.log(payload.arrayname,arraylength)
      let counter = 0
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: []
        }   
        commit('setCollectionArray', setcollection)
      }
      else{
       let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }              
          }
          if (change.type === 'modified') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == newobj.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1, newobj);
          }
          if (change.type === 'removed') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == newobj.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1);
          }
        })        
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCollectionArray', setcollection)  
       
        if(payload.AsArray && counter === arraylength){
          //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
          resolve('Completed NOT Empty')
        }
        else if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      }                  
    },
    error => {
      if(error.code === 'permission-denied'){
        console.log('permission-denied on GetCollectionArrayQuery snapshot '+payload.arrayname)
      }
    })       
  })
},
    GetCollectionArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      if(!Array.isArray(payload.query)){
        //console.log('not array',payload)
        this.dispatch('GetCollectionArrayQuery',payload).then(result => {
          resolve(result)
        })
      }
      else{
        //console.log('as array',payload)
        let length = payload.query.length
        //console.log('store array payload.query',payload.query,payload)
        payload.query.map((singlequery,singlequeryindex) => {
          //console.log('store payload.arrayname',payload.arrayname,singlequeryindex,length)
          let newquery = Object.assign(payload)
          newquery.query = singlequery
          newquery.AsArray = true
          this.dispatch('GetCollectionArrayQuery',newquery).then(result => {
            //console.log('store array result',payload.arrayname,result,singlequeryindex,length)
            if(singlequeryindex-1+2 === length){
             resolve(result)
            }           
          })         
        })
      }
    })
  },
  GetCompanyCollectionArrayQuery({ commit }, payload) {
    //alert('ah hell yeah I call')
    return new Promise((resolve, reject) => {
    payload.query.onSnapshot(res => {
      let arraylength = res.docs.length
      let counter = 0
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: [],
          prop: payload.prop
        }   
        commit('setCompanyCollectionArray', setcollection)
      }
      else{
       let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }              
          }
          if (change.type === 'modified') {
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1, {
              ...change.doc.data(),
              id: change.doc.id
            });
          }
          if (change.type === 'removed') {
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1);
          }
        })        
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array,
          prop: payload.prop
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCompanyCollectionArray', setcollection)  
       
        if(payload.AsArray && counter === arraylength){
          //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
          resolve('Completed NOT Empty')
        }
        else if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      }                  
    })       
  })
},
  GetCollectionArrayQuery({ commit }, payload) {
    //alert('ah hell yeah I call')
    return new Promise((resolve, reject) => {
    payload.query.onSnapshot(res => {
      let arraylength = res.docs.length
      //console.log(payload.arrayname,arraylength)
      let counter = 0
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: []
        }   
        commit('setCollectionArray', setcollection)
      }
      else{
       let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }              
          }
          if (change.type === 'modified') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == newobj.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1, newobj);
          }
          if (change.type === 'removed') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == newobj.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1);
          }
        })        
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCollectionArray', setcollection)  
       
        if(payload.AsArray && counter === arraylength){
          //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
          resolve('Completed NOT Empty')
        }
        else if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      }                  
    },
    error => {
      if(error.code === 'permission-denied'){
        console.log('permission-denied on GetCollectionArrayQuery snapshot '+payload.arrayname)
      }
    })       
  })
},
GetCatNavlistDataArray({ commit }, payload) {
  //alert('ah hell yeah I call')
  return new Promise((resolve, reject) => {
    let query = payload.Query
    let setcollection = {
      EntityID : payload.EntityID, 
      array: []
    }   
    commit('setCatNavlistData', setcollection)
    query.onSnapshot(res => {
    let arraylength = res.docs.length
    let counter = 0
    if(arraylength === 0){
      //console.log('says empty')
      resolve('Completed Empty')
      let setcollection = {
        EntityID : payload.EntityID, 
        array: []
      }   
      commit('setCatNavlistData', setcollection)
    }
    else{
      let navlist = this.state.CatNavlists.find(obj => obj.EntityID === payload.EntityID)
      //console.log('says not empty')
     let array = []
      if(navlist.Data){
        array = navlist.Data
      }
      //Below three lines created havoc. catnavlist don't play this wya get schooled
      // if(this.state[payload.arrayname]){
      //   array = this.state[payload.arrayname]
      // }
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let newobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          //console.log(newobj)
          if(payload.CategoricalListType === 'Store Products'){
            newobj.Warehouses = []
            array.push(newobj)
            return query.doc(newobj.id).collection('Warehouses').onSnapshot(res => {
              let whlength = res.docs.length
              //console.log(whlength+' warehosues for '+newobj.id)
                if(whlength === 0){
                   //done
                  
                  counter++
                }
                const changes = res.docChanges();									
                changes.forEach(change => {									
                    if (change.type === 'added') {
                      newobj.Warehouses.push({									
                        ...change.doc.data(),									
                        id: change.doc.id									
                    })
                    if(newobj.Warehouses.length === whlength){
                      //console.log('got all warehouses for '+newobj.id)
                      newobj.Stock_Qty = newobj.Warehouses.map(wh => {
                            if(!wh.Available){
                                wh.Available = 0
                            }
                            return wh.Available
                        }).reduce((a, b) => a + b, 0)
                        //array.push(newobj)
                        //console.log(array)
                        counter++
                        //done
                    }
                    }
                })
            })
          }
          else{
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }
          }
                        
        }
        if (change.type === 'modified') {
          let newobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let arrayobj = array.find(obj => obj.id == newobj.id)
          let arrayobjindex = array.indexOf(arrayobj)
          if(payload.CategoricalListType === 'Store Products'){
            array.splice(arrayobjindex, 1, newobj);
            newobj.Warehouses = []
            query.doc(newobj.id).collection('Warehouses').onSnapshot(res => {
              let length = res.docs.length
                if(length === 0){
                   //done
                   
                  counter++
                }
                const changes = res.docChanges();									
                changes.forEach(change => {									
                    if (change.type === 'added') {
                      newobj.Warehouses.push({									
                        ...change.doc.data(),									
                        id: change.doc.id									
                    })
                    if(newobj.Warehouses.length === length){
                      newobj.Stock_Qty = newobj.Warehouses.map(wh => {
                            if(!wh.Available){
                                wh.Available = 0
                            }
                            return wh.Available
                        }).reduce((a, b) => a + b, 0)
                        array.splice(arrayobjindex, 1, newobj);
                        counter++
                        //done
                    }
                    }
                })
            })
          }
          else{
            array.splice(arrayobjindex, 1, newobj);
          }          
        }
        if (change.type === 'removed') {
          let arrayobj = array.find(obj => obj.id == change.doc.id)
          let arrayobjindex = array.indexOf(arrayobj)
          array.splice(arrayobjindex, 1);
        }
      })        
      let setcollection = {
        EntityID : payload.EntityID, 
        array: array
      }   
      //console.log('store getting that query now ',payload.arrayname)
      commit('setCatNavlistData', setcollection)  
     
      if(payload.AsArray && counter === arraylength){
        //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
        resolve('Completed NOT Empty')
      }
      else if(array.length === arraylength){
        //console.log('store resolving not as array ',payload.arrayname,payload)
        resolve('Completed NOT Empty')
      }
    }                  
  })       
})
},
    SetUserObj ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        payload.db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(payload.userid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = payload.userid
            //console.log(userdata)
            if(!userdata.Services){
              userdata.Services = []
            }
             if(!userdata.Full_Name && !payload.RapidAppsDB){
               let info = []
                if(this.getters.getRequiredInformation){
                  info = this.getters.getRequiredInformation
                }
                info.push('Name') 
                info.push('Surname')                          
                commit('setUserRequiredInfo',info)
                commit('setUserInfoRequired', true) 
              }
              payload.db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('userroles').doc(payload.userid).onSnapshot(snapshot => {
              let userroles = snapshot.data()  
              //console.log('userroles',userroles)            
              if(!userroles.Apps){
                userdata.Apps = []
              }
              let Sites = []
              if(userroles.Sites){
                Sites = userroles.Sites
              }
              userdata.Sites = []
              
              let Cards = []
              if(userroles.Cards){
                Cards = userroles.Cards
              }
              userdata.Cards = []
              userdata.rolesarrayQuery = userroles.rolesarrayQuery
              userdata.rolesarrayDBRules = userroles.rolesarrayDBRules
              userdata.Business_Unit = userroles.Business_Unit
              userdata.Business_Unitid = userroles.Business_Unitid
              let varrolesprops = ['LoanEntities','TrackedEntity','StoreEntities','TrackFilter','CompanyLoanEntities','CompanyStoreEntities','Apps','VacancyOfficerClient',
                'MonetizationOfficer','IsEagleViewer','BusinessProfileAdmin','SiteContentAdmin','IsGuestUser','IsSystemAdmin','IsTeamActivityManager','CanInviteUsers','InviteGroups','CanExport','CanImport',
                'BusinessAccounting','Warehouses','IsSystemAdmin','Regionid','Contact','DBNavList','UserTableFilters','IsWebDeveloper','IsSiteCreator','IsFeaturedMember'
              ] 
              varrolesprops.map(role => {
                if(typeof userroles[role] !== 'undefined'){
                  userdata[role] = userroles[role]
                }
              })
              Sites.map((siteid,siteindex) => {
                let siteobj = {id: siteid}
                db.collection('SystemConfig').doc(siteid).collection('userroles').doc(payload.userid).onSnapshot(sitesnap => {
                  let siteroledoc = sitesnap.data()
                  //console.log('siteroledoc',siteid,siteroledoc)
                  siteobj.User = {...siteroledoc,id: payload.userid,BillingAccounts: []}
                  siteobj.User.CreditTokens = []
                  siteobj.User.Services = []
                  siteobj.User.groups = []
                  //System
                  db.collection('SocialNetworks').doc(siteid).collection('groupmembers').doc(userdata.id).collection('groups').where('Status','==','Active').onSnapshot(groupssnapshot => {
                    let listlength = groupssnapshot.docs.length
                        if(listlength === 0){
                          if(!payload.RapidAppsDB){
                          //  commit('setUserObj', userdata)   
                          //  resolve(userdata) 
                          }
                          else{
                            // commit('setRAUserObj', userdata)   
                            // resolve(userdata) 
                          }
                          
                        }
                        else{
                          const changes = groupssnapshot.docChanges();
                          changes.forEach(change => {
                            if (change.type === 'added') {
                              let usergroupmembershipdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              if(usergroupmembershipdata.Administrator){
                                siteobj.User.groups.push(usergroupmembershipdata)
                              }
                              else{
                                siteobj.User.groups.push(usergroupmembershipdata)
                              }                              
                            }
                            if(siteobj.User.groups.length === listlength){
                              // if(!payload.RapidAppsDB){
                              // commit('setUserObj', siteobj.User)   
                              // resolve(siteobj.User)
                              // }
                              // else{
                              //   commit('setRAUserObj', siteobj.User)   
                              //   resolve(siteobj.User)
                              // }
                            }
                          })
                        }
                  })
                  siteobj.User.Sites = [{Name: 'Main',id: process.env.VUE_APP_RA_SYSTEM_ID,Icon: 'mdi-home'}]
                  //CreditTokens
                  //Services
                  if(siteroledoc.MemberGroup){
                    siteobj.User.MemberGroup = siteroledoc.MemberGroup
                    siteobj.User.MemberGroupid = siteroledoc.MemberGroupid
                  }
                  else{
                    siteobj.User.MemberGroup = null
                    siteobj.User.MemberGroupid = null
                  }
                  let varrolesprops = ['LoanEntities','TrackedEntity','StoreEntities','TrackFilter','CompanyLoanEntities','CompanyStoreEntities','Apps',
                    'MonetizationOfficer','IsEagleViewer','BusinessProfileAdmin','SiteContentAdmin','IsGuestUser','IsSystemAdmin','IsTeamActivityManager','CanInviteUsers','InviteGroups','CanExport','CanImport',
                    'BusinessAccounting','Warehouses','IsSystemAdmin','Regionid','Contact','DBNavList','UserTableFilters','IsWebDeveloper','IsSiteCreator','IsFeaturedMember'
                  ] 
                  varrolesprops.map(prp => {
                    if(typeof siteroledoc[prp] !== 'undefined'){
                      siteobj.User[prp] = siteroledoc[prp]
                    }
                  })       
                  userdata.Sites[siteindex] = siteobj           
                })          
              })
              //we tried the "push" approach but then update to userrole doc on sites, duplicates the site
              
              commit('setUserisGuest', userroles.IsGuestUser)
              
              if(userroles.MemberGroup){
                userdata.MemberGroup = userroles.MemberGroup
                userdata.MemberGroupid = userroles.MemberGroupid
              }
              else{
                userdata.MemberGroup = null
                  userdata.MemberGroupid = null
              }
              if(userroles.Company){
                userdata.Company = userroles.Company
                userdata.Companyid = userroles.Companyid
              }
              //IF TEH FEAT MEMBER PLUGIN EXIST
              let featuredmembersplugin = true
              let featmempath = db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('featuredmembers').doc(payload.userid)
              let featmempagespath = featmempath.collection('Pages')
              if(featuredmembersplugin){
                featmempath.onSnapshot(snapshot => {
                  let featmemdata = snapshot.data()
                  commit('setUserFeaturedMemberSnap',featmemdata)
                  featmempagespath.limit(1).onSnapshot(res => {
                    if(res.docs.length === 0){
                      commit('setFeaturedMemberHasPages',false)
                    }
                    else{
                      commit('setFeaturedMemberHasPages',true)
                      if(featmemdata.DefaultPage){
                        commit('setFeaturedMemberDefaultPage',featmemdata.DefaultPage)
                      }
                      else{
                        //take any of the pages does not matter...first one!
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            let pagedata = {
                              ...change.doc.data(),
                              id: change.doc.id
                            }
                            commit('setFeaturedMemberDefaultPage',pagedata.id)
                          }
                        })
                      }
                    }
                  })
                })
              }
              //if(this.state.SystemObj.Has_Store){
                userdata.BillingAccounts = []
                db.collection('Databases').doc(this.state.ActiveSuiteid).collection('billingaccounts').where('Userid','==',payload.userid).onSnapshot(res => {
                  const changes = res.docChanges();
                          changes.forEach(change => {
                            if (change.type === 'added') {
                              let accountdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              userdata.BillingAccounts.push(accountdata)
                            }
                            if (change.type === 'modified') {
                              let accountdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              let oncheck = userdata.BillingAccounts.find(obj => obj.id === accountdata.id)
                              if(oncheck){
                                let index = userdata.BillingAccounts.indexOf(oncheck)
                                userdata.BillingAccounts.splice(index,1,accountdata)
                              }
                            }
                            if (change.type === 'removed') {
                              let accountdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              let oncheck = userdata.BillingAccounts.find(obj => obj.id === accountdata.id)
                              if(oncheck){
                                let index = userdata.BillingAccounts.indexOf(oncheck)
                                userdata.BillingAccounts.splice(index,1)
                              }
                            }
                            
                          })
                })
              //}
              if(!payload.RapidAppsDB){
              commit('setSiteContentAdmin', userroles.SiteContentAdmin) 
              commit('setBusinessProfileAdmin', userroles.setBusinessProfileAdmin)
              commit('setUserEagleView', userroles.IsEagleViewer)
              commit('setUserAdmin', userroles.IsSystemAdmin)
              }
              userdata.SubordinateArrayDBrules = userroles.SubordinateArrayDBrules
              userdata.Subordinatearrayquery = userroles.Subordinatearrayquery
              userdata.CreditTokens = []
              if(userdata.Market_Accountid){
                let marketacctprops = [{ArrayName: 'CreditTokens',Query: db.collection('Databases').doc(this.state.ActiveSuiteid).collection('billingaccounts').doc(userdata.Market_Accountid).collection('credittokens').where('Userid','==',payload.userid).where('Active','==',true).orderBy('Date','asc')},
                {ArrayName: 'ContentPurchases',Query: db.collection('Databases').doc(this.state.ActiveSuiteid).collection('billingaccounts').doc(userdata.Market_Accountid).collection('contentpurchases').where('Userid','==',payload.userid)}]
                marketacctprops.map(entry => {
                  userdata[entry.ArrayName] = []
                  entry.Query.onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                      if (change.type === 'added') {
                        let newobj = {
                          ...change.doc.data(),
                          id: change.doc.id
                        }
                        let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                        if(!arrayobj){
                          userdata[entry.ArrayName].push(newobj)
                        }              
                      }
                      if (change.type === 'modified') {
                        let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                        let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                        userdata[entry.ArrayName].splice(arrayobjindex, 1, {
                          ...change.doc.data(),
                          id: change.doc.id
                        });
                      }
                      if (change.type === 'removed') {
                        let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                        let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                        userdata[entry.ArrayName].splice(arrayobjindex, 1);
                      }
                    }) 
                  })
                })
              }
              //basically if you have "Market_Accountid" it will call, so when it's creaed updated user record
              //DON'T YOU SEE THE F CLASH HERE??????????????? WHY MA contentpruchases AND user contentpurchases???Why both?!!!
              //perhaps one is "group membership" and the other is other? Scew it leave both, but I believe all should be recorded in MA, it has 1:1 anyway
              let usersocialpackagesarray = [
                //{ArrayName: 'Subscriptions',Query: db.collection('Databases').doc(this.state.ActiveSuiteid).collection('subscriptionorders').where('Userid','==',payload.userid)},
                //suborders need //where('Type.Name','==','Group Membership')
                //where('Type.Name','==','Site Content Channel') or we truly could call absolutely everything?
                {ArrayName: 'ContentPurchases',Query: db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(payload.userid).collection('contentpurchases')},
                // {ArrayName: 'CreditTokens',Query: db.collection('Databases').doc(this.state.ActiveSuiteid).collection('billingaccounts').doc(payload.userid).collection('credittokens').where('Active','==',true).orderBy('Date','asc')},
                {ArrayName: 'AccountCredits',Query: db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(payload.userid).collection('credits')},
                {ArrayName: 'AccountDebits',Query: db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(payload.userid).collection('debits')}
              ]
              usersocialpackagesarray.map(entry => {
                userdata[entry.ArrayName] = []
                entry.Query.onSnapshot(res => {
                  const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      let newobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                      }
                      let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                      if(!arrayobj){
                        userdata[entry.ArrayName].push(newobj)
                      }              
                    }
                    if (change.type === 'modified') {
                      let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                      let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                      userdata[entry.ArrayName].splice(arrayobjindex, 1, {
                        ...change.doc.data(),
                        id: change.doc.id
                      });
                    }
                    if (change.type === 'removed') {
                      let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                      let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                      userdata[entry.ArrayName].splice(arrayobjindex, 1);
                    }
                  }) 
                })
              })
             
             // let AdminTest = queryroles.find(obj => obj === 'System Admin')   
              // if(typeof AdminTest !== 'undefined'){
              //   commit('setUserAdmin', true)
              // }
              // let EagleTest = queryroles.find(obj => obj === 'Eagle view')   
              //   if(typeof EagleTest !== 'undefined'){
              //     commit('setUserEagleView', true)
              //   }
              payload.db.collection('SocialNetworks').doc(this.state.ActiveSuiteid).collection('sitemoderators').doc(userdata.id).onSnapshot(modsnapshot => {
                let moderatordata = modsnapshot.data()
                if(moderatordata && moderatordata.ModerationPermissions){
                  userdata.ModerationPermissions = moderatordata.ModerationPermissions
                  //console.log(userdata.id)
                  payload.db.collection('SocialNetworks').doc(this.state.ActiveSuiteid).collection('groupmembers').doc(userdata.id).collection('groups').where('Status','==','Active').onSnapshot(groupssnapshot => {
                    //console.log('getting groupmembers collection',groupssnapshot.docs.length)
                        userdata.groups = []
                        let listlength = groupssnapshot.docs.length
                        if(listlength === 0){
                          if(!payload.RapidAppsDB){
                           commit('setUserObj', userdata)   
                           resolve(userdata) 
                          }
                          else{
                            commit('setRAUserObj', userdata)   
                            resolve(userdata) 
                          }
                          
                        }
                        else{
                          const changes = groupssnapshot.docChanges();
                          changes.forEach(change => {
                            if (change.type === 'added') {
                              let usergroupmembershipdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              if(usergroupmembershipdata.Administrator){
                                userdata.groups.push(usergroupmembershipdata)
                              }
                              else{
                                userdata.groups.push(usergroupmembershipdata)
                              }                              
                            }
                            if(userdata.groups.length === listlength){
                              if(!payload.RapidAppsDB){
                              commit('setUserObj', userdata)   
                              resolve(userdata)
                              }
                              else{
                                commit('setRAUserObj', userdata)   
                                resolve(userdata)
                              }
                            }
                          })
                        }
                    })
                   
                }
                else{
                  let groupspayload = payload.db.collection('SocialNetworks').doc(this.state.ActiveSuiteid).collection('groupmembers').doc(userdata.id).collection('groups')
                  groupspayload.onSnapshot(groupssnapshot => {
                    userdata.groups = []
                    let listlength = groupssnapshot.docs.length
                    if(listlength === 0){
                      if(!payload.RapidAppsDB){
                      commit('setUserObj', userdata)   
                      resolve(userdata)
                      }
                      else{
                        commit('setRAUserObj', userdata)   
                        resolve(userdata)
                      }
                    }
                    else{
                      const changes = groupssnapshot.docChanges();
                      changes.forEach(change => {
                        if (change.type === 'added') {
                          let usergroupmembershipdata = {
                            ...change.doc.data(),
                            id: change.doc.id
                          }
                          if(usergroupmembershipdata.Administrator){
                            userdata.groups.push(usergroupmembershipdata)
                          }
                          else{
                            userdata.groups.push(usergroupmembershipdata)
                          }                          
                        }
                        if(userdata.groups.length === listlength){
                          if(!payload.RapidAppsDB){
                          commit('setUserObj', userdata)   
                          resolve(userdata)
                          }
                          else{
                          commit('setRAUserObj', userdata)   
                          resolve(userdata)
                          }
                        }
                      })
                    }
                })
                }
              })         
                 
            })     
          })   
        })
    },
    AddEntityObj ({ commit }, payload) {
      commit('SetEntityObj', payload)
    },
    SetUserBUObj ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('businessunits').doc(payload).onSnapshot(snapshot => {
            let budata = snapshot.data()
            budata.id = payload
            //console.log(budata)            
            commit('setUserBUObj', budata)    
            resolve('Completed setUserBUObj')        
          })   
        })
    },
    SetUserQueryPermission({ commit },payload) {
      //console.log('SetUserQueryPermission',payload)
        let setcollectionquery = {
          queryname : payload.collectionstate, 
          query: payload.query
        } 
        commit('setCollectionQuery', setcollectionquery) 
      
    },
    SetUserInteractPermission({ commit },payload) {
      //console.log('SetUserInteractPermission ',payload)
      let setcollectioninteract = {
        queryname : payload.collectionstate, 
        boolean: payload.boolean
      } 
      commit('setCollectionInteractPermission', setcollectioninteract) 
    
    },
    createUserDoc({ commit },payload) {
      commit('setStatus', 'loading')
      const userdoc = {
        Title: payload.Title,
        Name: payload.Name,
        Surname: payload.Surname,
        BusinessUnit: payload.BusinessUnit,
        EmployeeCode: payload.EmployeeCode,
        Position: payload.Position,
        Gender: payload.Gender
      }
      //console.log('userdoc')
      //console.log(userdoc)
      db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').add(userdoc).then({

      })
    },

    createSystemActivity({ commit },payload) {
      commit('setStatus', 'loading')
      const systemactivity = {
        creatorid: payload.user.id,
        creatorname: payload.user.Full_Name,
        post: `<a href="/SiteMember/`+payload.user.id+`">`+payload.user.Full_Name+`</a> `+payload.contentvar+`<a href="`+payload.location+payload.docid+`">`+payload.docname+`</a>`,
        postvar: payload.contentvar+`<a href="`+payload.location+payload.docid+`">`+payload.docname+`</a>`,
        type: payload.type,
        createdon: new Date()
      }
      if(payload.user.Profile_Photo){        
        systemactivity.creatorimg = payload.user.Profile_Photo
      }
      if(payload.user.Company){
        //there is however another side to this coin....if the systemactivity is a "DUAL" activity like say a new friendship, then two companies apply
        //both companies would or should get notifications in fact
        systemactivity.Company = {Company_Name: payload.user.Company.Company_Name,id: payload.user.Companyid}
        systemactivity.Companyid = payload.user.Companyid
      }
      let dbref = db.collection('SocialNetworks').doc(this.state.ActiveSuiteid).collection('systemactivities')
      if(this.state.ActiveSuiteTemplate){
        dbref = db.collection('SocialNetworks').doc(this.state.ActiveSuiteTemplate.id).collection('systemactivities')
      }
      else if(this.state.ActiveMarketTemplate){
        dbref = db.collection('marketplacetemplates').doc(this.state.ActiveMarketTemplate.id).collection('SocialNetworks').doc(this.state.ActiveMarketTemplate.id).collection('systemactivities')
      }
      dbref.add(systemactivity).then({

      })
    },
    signUpAction ({ commit }, payload) {
      //console.log('doing signUpAction')
      commit('setStatus', 'loading')
      // when using return new Promise(function(resolve, reject) { then "this" not accessible
      return new Promise((resolve, reject) => {
      const functions = firebase.functions();
          const CreateUserviaSignup = functions.httpsCallable('CreateUserviaSignup');
          //console.log(payload)
          CreateUserviaSignup(payload).then(result => {
            //console.log('signup result ',result)
            if(result){
               let loginpayload = {
                email: payload.Email,
                password: payload.password
              }
              this.dispatch('loginWithFirebase',loginpayload).then(logresu => {
                resolve(logresu)
              }) 
              
            }
           
            //resolve(result)
          })
        })
    },
    createNotification({ commit },payload) {
      commit('setStatus', 'loading')
      let NewNotification = {
        [payload.itemidprop]: payload.itemid,
        Type: payload.Type,
        Header: payload.Header,
        Content: payload.Content,
        CreatorFullName: payload.CreatorFullName,
        CreatorID: payload.CreatorID,
        Owner: payload.Owner,
        Ownerid: payload.Ownerid,
        Read: false,
        Path: payload.Path,
        Message: payload.Message,
        Date: new Date()
        }
        if(payload.ActiveProcessNumber){
          NewNotification.ActiveProcessNumber = payload.ActiveProcessNumber
        }
        if(payload.topicid){
          NewNotification.topicid = payload.topicid
        }
        if(payload.fileurl){
          NewNotification.fileurl = payload.fileurl
        }
        if(payload.IsLiaison){
          NewNotification.IsLiaison = payload.IsLiaison
          NewNotification.Liaisonid = payload.Liaisonid
        }
        if(payload.groupid){
          NewNotification.groupid = payload.groupid
        }
        db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

        })
    },


    signUpbyAdminImportAction ({ commit }, payload) {
      return new Promise((resolve, reject) => {
       
        var config = {
  apiKey: "AIzaSyBizqYTsY9oNY_0U1L6evK9a22kjUyr9ps",
  authDomain: "rapidapps-7d6e7.firebaseapp.com",
  databaseURL: "https://rapidapps-7d6e7.firebaseapp.com",
  projectId: "rapidapps-7d6e7",
  storageBucket: "rapidapps-7d6e7.appspot.com",
  messagingSenderId: "674721806504",
  appId: "1:674721806504:web:4596bc44637e9f37eb6a9d",
  measurementId: "G-J7T6TGDTTB"
};
        // const secondaryApp = firebase.initializeApp(config, "Secondary");
        if (firebase.apps.length === 1) {
          let secondaryApp = firebase.initializeApp(config, "Secondary")
          secondaryApp.auth().createUserWithEmailAndPassword(payload.Email,  payload.password)
        .then((response) => {
          const userdocid = response.user.uid
          // alert('success')
          const systemactivity = {
            creatorid: userdocid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+userdocid+`">`+payload.Full_Name+`</a> joined RapidApps`,
            type: `New User`,
            createdon: new Date()
          }
          //console.log(systemactivity)
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.Profile_Photo
          }
          //console.log('logging more from store before create')
          //console.log(response)
          resolve(response);
          db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(userdocid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email
          })
          db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('userroles').doc(userdocid).set({
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          //console.log('here is userdocid',userdocid)
          
          db.collection('SocialNetworks').doc(this.state.ActiveSuiteid).collection('systemactivities').add(systemactivity).then({
            
          })
            
        }).catch((error) => {
       
          alert(error.message)
          reject(error);
        })
        }
        else{
          let secondaryApp = firebase.apps[1]
          secondaryApp.auth().createUserWithEmailAndPassword(payload.Email, payload.password)
        .then((response) => {
          // alert('success')
          const userdocid = response.user.uid
          const systemactivity = {
            creatorid: userdocid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+userdocid+`">`+payload.Full_Name+`</a> joined RapidApps`,
            type: `New User`,
            createdon: new Date()
          }
          //console.log(systemactivity)
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.Profile_Photo
          }
          //console.log('logging more from store before create')
          //console.log(response)
          resolve(response);
          db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(userdocid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email,
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          
            
        }).catch((error) => {
       
          alert(error.message)
          reject(error);
        })
        }
        
      
      })
    },

    signUpbyAdminAction ({ commit }, payload) {
      commit('setStatus', 'loading')
      firebase.auth().createUserWithEmailAndPassword(payload.Email, payload.password)
        .then((response) => {
          alert('success')
          const userdocid = response.user.uid
          db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(response.user.uid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email,
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          const systemactivity = {
            creatorid: response.user.uid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+response.user.uid+`">`+payload.Full_Name+`</a> joined RapidApps`,
            type: `New User`,
            createdon: new Date()
          }
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.user.Profile_Photo
          }
          db.collection('SocialNetworks').doc(this.state.ActiveSuiteid).collection('systemactivities').add(systemactivity).then({
    
          })
        })

        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    },

    signInAction ({ commit }, payload) {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          db.collection('SystemConfig').doc(this.state.ActiveSuiteid).collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = response.user.uid
            //console.log(userdata)
            commit('setUserObj', userdata)
          })
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    },


    signOutAction ({ commit }) {
      firebase.auth().signOut()
        .then((response) => {
          commit('setUser', null)
          commit('setStatus', 'success')
          commit('setError', null)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    }
  },

  getters: {
    GymSessionsArray(state){
      return state.GymSessionsArray
    },
    getRequiredInformation(state){
      return state.UserRequiredInfo
    },
    getArrayByDynamic: (state) => (ArrayName) => {
      return state[ArrayName];
    },
    getFriendshipsArray(state){
      return state.FriendshipsArray
    },
    getRMDocumentsArray(state){
      return state.RMDocumentsArray
    },
    
    getBusinessUnitsArray(state){
      return state.BusinessUnitsArray
   },
    getUsersArray(state){
      return state.UsersArray
   },
    userBUID (state) {
      return state.userobj.Business_Unitid
    },
    userBUChildren (state) {
      return state.buobj.childarrayQuery
    },
    userBUParents (state) {
      return state.buobj.childarrayQuery
    },
    status (state) {
      return state.status
    },

    user (state) {
      return state.userobj
    },

    error (state) {
      return state.error
    }
  }
})

  