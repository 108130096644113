
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
            <v-card :class="tab.BoxTransparency+' '+tab.CustomClass+' mx-3'"  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"
            :height="tab.Name === 'Card Row Single' && tab.InfographicBox ? '100%' : ''"
            :style="BoxStyle(tab)">
            <div :style="ArticleColStyle(tab,true)">
                  <v-card-text v-if="tab.HasImage && tab.IMGonTop && tab.Name !== 'Hero Banner'">
                    <div v-if="tab.IMGHasTitle" :style="IMGTitleStyle(tab)+'z-index: 2;position: absolute;'">{{tab.IMGTitle}}</div>
                                 <v-layout :class="tab.Justify" row :style="tab.IMGBGColor ? 'background-color: '+tab.IMGBGColor.hexa+';': ''">
                                 <ImageRenderComp @ActivateElementConfigComp="ActivateElementConfigComp" :Editing="true"
                                    :tab="tab" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                                    :IMGProp="'IMG'"/>
                                 </v-layout>
                             </v-card-text>
                    <TitleTab v-if="tab.Name !== 'Hero Banner'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                                <div :style="ArticleColStyle(tab)">
                                 <v-card flat tile class="transparent" width="100%" height="100%" @click="ActivateElementConfigComp('WriteupComponent',tab,i)">  
                                  <HTMLDescription
                                    :tab="tab" :PageContentFont="PageContentFont" @ActivateElementConfigComp="ActivateElementConfigComp"
                                    />
                                 </v-card>
                              <!-- <v-btn absolute outlined left  @>Edit Text
                                    </v-btn> -->
                             <v-card-text v-if="tab.HasImage && !tab.IMGonTop && tab.Name !== 'Hero Banner'">
                                <div v-if="tab.IMGHasTitle" :style="IMGTitleStyle(tab)+'z-index: 2;position: absolute;'">{{tabindex-1+2}}</div>
                                 <v-layout :class="tab.Justify" row :style="tab.IMGBGColor ? 'background-color: '+tab.IMGBGColor.hexa+';': ''">
                                 <ImageRenderComp @ActivateElementConfigComp="ActivateElementConfigComp" :Editing="true"
                                    :tab="tab" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                                    :IMGProp="'IMG'"/>
                                 </v-layout>
                             </v-card-text>
                                </div>
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">
                                <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                        </v-card-actions>
            </div>
                            </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTab';
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbed';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTab';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';
import ImageRenderComp from '@/components/WebPages/RenderComponents/ImageRenderComp';
export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {
        WebFormTab,
        TitleTab,
        HTMLDescription,
        ActionButtonEmbed,
        ImageRenderComp
        },
    data() {
    return {     
    WebFormDialogFancy: false, 
    WebFormDialogNonFancy: false,
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      DefaultPageFont(){
            return this.tab.WriteupFont ? 
            `font-family: "`+this.tab.WriteupFont+`", sans-serif;` : `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        
        IMGTitleStyle(elmnt) {
            //IMGHasTitle - IMGTitle - IMGTitleColor - IMGTitleFontSize - IMGBorderRadius - IMGTitleStyle
            let style = ''
                if(elmnt.IMGTitleColor){
                    style = style+'color: '+elmnt.IMGTitleColor.hexa+';'
                }
                if(elmnt.IMGBorderRadius){
                    style = style+'border-radius: '+elmnt.IMGBorderRadius+'px;'
                }
                if(elmnt.IMGTitleBGColor){
                    style = style+'background: '+elmnt.IMGTitleBGColor.hexa+';'
                }
                if(elmnt.IMGTitleFontSize){
                    style = style+'font-size: '+elmnt.IMGTitleFontSize+'px;'
                }
                if(elmnt.IMGTitleStyle){
                    //height: 130px;line-height: 130px;width: 130px;text-align: center;
                    style = style+elmnt.IMGTitleStyle
                }
            return style
        },
      ActivateElementConfigComp(compname,tab,i){
            this.$store.commit('setActiveElmntConfigComp',compname)
            this.OpenDialog(tab,i)
        },
        BaseTitleStyle(tab){
            return tab.HeaderBlendMode ? 'mix-blend-mode: '+tab.HeaderBlendMode+';'+this.TitleStyle(tab) : this.TitleStyle(tab)
        },
         ArticleColStyle(tab,boxwrap){
            let style = ''
            
            if(boxwrap && tab.HasArticleCols && !this.MiniView && tab.ColsWrapBox){
                style = 'columns:'+tab.TotalArticleCols+';column-gap:3rem;'
                if(tab.ColsBorder){
                    style = style+'border: '+tab.ColsBorderWidth+'px solid '+tab.ColsBorderColor.hexa+';border-radius: '+tab.ColsBorderRadius+'px;'
                    style = style+'padding:2em;'
                }
                else{
                    //0.65 looks real magazine style have to keep in consideration
                    //2 looks super cool as well dunno
                    style = style+'padding:1em;'
                }
            }
            else if(!boxwrap && tab.HasArticleCols && !this.MiniView && !tab.ColsWrapBox){
                style = 'padding:2em;columns:'+tab.TotalArticleCols+';column-gap:3rem;'
                if(tab.ColsBorder){
                    style = style+'border: '+tab.ColsBorderWidth+'px solid '+tab.ColsBorderColor.hexa+';border-radius: '+tab.ColsBorderRadius+'px;'
                }
            }
            return style
        },
        TabFontStyle(tab){
            return tab.FontBGColor && !tab.FontUnderline ? this.MiniView ? 'background-color: '+tab.FontBGColor+';width: 80%;' : 'background-color: '+tab.FontBGColor+';width: 50%;' :
            tab.FontUnderline && tab.FontUnderlineColor ? 'background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;' :
            ''
        },
        TitleStyle(tab){
            // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
            if(tab.HeaderBGColor){
                return tab.Center? 'background-color: '+tab.HeaderBGColor.hexa+';padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
            else{
                return tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
        },
        BoxStyle(tab){
        if(tab.FullHeight){
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'
        }
       },
     OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog){
          //console.log(this.rowindex)
        //  console.log(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog)
         //only for conentdialog tab,tabindex,'','','','',true
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex,ContentDialog)
         }
         else if(ContentDialog){
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex,'','',ContentDialog)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex,'','',ContentDialog)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>
.magartallp p::first-letter {
    margin: 1% 3% -2px 0;
    padding:0 5% 0;
    font-size: 3em;;
    line-height: 1;
    float: left;
    color:#f0f0f0;
    font-weight:700;
    background: #ff6633;
    /* background: inherit; */
    vertical-align:bottom;
  }
.magartfirstp  p:first-of-type::first-letter {
    margin: 1% 3% -2px 0;
    padding:0 5% 0;
    font-size: 3em;;
    line-height: 1.5;
    float: left;
    color:#f0f0f0;
    font-weight:700;
    background: #ff6633;
    /* background: inherit; */
    vertical-align:bottom;
  }

</style>