<template>
  <div>
    <v-card-title class="mediumoverline">
      <v-btn @click="HideNotes =! HideNotes">{{HideNotes ? 'Show Notes' : 'Hide Notes'}}</v-btn>
    </v-card-title>
    <v-layout v-if="!HideNotes" row class="justify-center mx-3">   
        <v-btn
            fab
            color="primary"
            right
            absolute
            small
            @click="ConfirmAddNote()"
        ><v-icon>mdi-plus-thick</v-icon>
        </v-btn>
        <v-btn dark
            fab style="right:60px;"
            color="green"
            right
            absolute
            small
            @click="SaveNotes()"
        ><v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-card elevation="3" tile class="noteentry my-1 mx-2" max-width="250" :color="NewNote.Color" :dark="NewNote.Dark" v-if="NewNote">
          <v-btn 
          @click="NewNote = ''" outlined width="50%">Cancel</v-btn>
          <v-btn
          @click="AddNotetoDB()" outlined width="50%">Save</v-btn>
          <v-card-title class="justify-space-between">
          
            <v-text-field v-model="NewNote.Title" label="Title"/>
             <input type="color" id="notebgcolor" style="position: absolute;z-index:1;right: 5px;" v-model="NewNote.Color" />        
                                        
          </v-card-title>
          <v-card-text class="justify-center">
             <ContentEditableField style="padding:15px;"
                  :FieldObject="NewNote" :FieldName="'Description'"
                  :FieldValue="NewNote.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
              </v-card-text>
              <v-card flat tile style="min-height: 200px;" @dragover.prevent @drop="recordtaggingfinish(NewNote, $event)">
                 <v-card-text>
                  {{NewNote.Records.length}} Tagged (filters may affect if all show in list below)
                <v-virtual-scroll
                style="padding:4px;"
                    class="transparent"
                      :items="FullDataRecords(NewNote.Records)"
                      :item-height="20"
                      :height="190"
                    >
                <template v-slot:default="{ item }">
                <v-chip  x-small>
                   <v-icon x-small @click="!SingleRecord ? RemoveNoteTagItem(NewNote,item.id) : ''" dark color="red">
                    mdi-close
                  </v-icon>
                  {{item[CurrentEntity.Primary_Field_Name]}}
                </v-chip>
               </template>
                </v-virtual-scroll>
                 </v-card-text>
              </v-card>
              <v-card-actions v-if="!SingleRecord" class="justify-space-between">
                <v-btn small v-if="NewNote.Records.length === 0" @click="TagRecordsinView(NewNote)" dark color="warning"><v-icon small>mdi-tag</v-icon>All in View</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn small v-if="NewNote.Records.length > 0" @click="ClearNoteRecords(NewNote)" dark color="error">Clear</v-btn>
              </v-card-actions>
               <v-card-actions class="justify-space-between">
                
                <v-spacer>
                </v-spacer>
                <v-btn small @click="AddNotetoDB()" dark color="green">Add</v-btn>
              </v-card-actions>
            </v-card>
            <v-dialog v-model="NewNoteActionDial" width="300px">              
              <v-card outlined>
                <v-card-title>
                  New Action - {{ActionNote.Title}}
                </v-card-title>
                  <v-card-text>
                  <v-text-field  dense v-model="NewNoteAction.Description"/>
                  </v-card-text>
                  <v-card-actions class="justify-space-between">
                    <v-btn x-small @click="CancelAddAction(ActionNote)">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn x-small @click="AddAction(ActionNote)">Add</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <!-- <v-layout row class="justify-center" style="padding-bottom: 10px;"> -->
          
          <!-- </v-layout> -->
          <!-- ComputedNotesArray {{ComputedNotesArray}} NotesArray {{NotesArray}} -->
        <v-card elevation="3" tile class="noteentry my-1 mx-2" max-width="250" :color="note.Color" :dark="note.Dark" v-for="note in ComputedNotesArray" :key="note.itemObjKey">
          <v-btn x-small v-if="!note.Editing && note.Editors.includes(userLoggedIn.id) || !note.Editing && userIsAdmin"
          @click="note.Editing = true" fab icon style="position: absolute;top:3px;left:3px;"><v-icon color="red">mdi-pencil</v-icon></v-btn>
          <v-btn v-if="!note.Editing" x-small style="position: absolute;top:3px;left:25px;" @click="ActivateNewNoteActionDial(note)" fab icon>
            <v-icon >mdi-clipboard-check</v-icon> 
            </v-btn>
          <v-btn outlined small v-if="note.Editing" width="50%"
            @click="note.Editing = false">
              Cancel
            </v-btn>
            <v-btn outlined small width="50%" v-if="note.Editing" @click="ConfirmNoteDelete(note.id)">
              Delete
            </v-btn>
            <v-card-title class="justify-end subtleoverline">
              {{note.Owner.Full_Name}}
            </v-card-title>
            <v-card-subtitle class="caption">
              {{note.Created_On.toDate().toLocaleString()}}
            </v-card-subtitle>
          <v-card-title class="justify-space-between">            
            <v-text-field v-if="note.Editing"  v-model="note.Title" label="Title"/>
             <input v-if="note.Editing" type="color" id="notebgcolor" style="position: absolute;z-index:1;right: 5px;" v-model="note.Color" />        
             <span v-if="!note.Editing">
              {{note.Title}}
             </span>                              
          </v-card-title>
          
          <v-card-text v-if="note.Editing" class="justify-center">
             <ContentEditableField style="padding:15px;"
                  :FieldObject="note" :FieldName="'Description'"
                  :FieldValue="note.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
              </v-card-text>
              <v-card-text v-if="!note.Editing" class="justify-center" v-html="note.Description">
              </v-card-text>
              <v-list class="transparent">
                
                <!-- <v-menu offset-y :close-on-content-click="false" top width="300px;">
                    <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab icon><v-icon class="soloactionicon">mdi-plus</v-icon>  </v-btn>
                    </template>
                    <v-card outlined>
                       <v-card-text>
                        <v-text-field  dense v-model="NewNoteAction.Description"/>
                       </v-card-text>
                       <v-card-actions class="justify-space-between">
                        <v-btn x-small @click="AddAction(note)">Add</v-btn>
                       </v-card-actions>
                    </v-card>
                </v-menu> -->
              <!-- <v-icon @click="AddAction(note)" class="soloactionicon">mdi-plus</v-icon>              -->
                  <v-list-item dense class="caption" v-for="act in note.Actions" :key="act.itemObjKey">
                    <!-- <v-list-item-action>
                      <v-checkbox @change="CheckActionCompleted(note,act)" v-model="act.Completed"></v-checkbox>
                    </v-list-item-action> -->
                    <v-list-item-action style="margin-right: 0px !important;">
                      <v-checkbox @change="CheckActionCompleted(note,act)" v-model="act.Completed"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-avatar class="blue" size="25">
                      <span class="white--text caption mx-1" v-if="!act.Completed">
                        {{act.Owner.Name.substr(0,1)}}{{act.Owner.Surname.substr(0,1)}}
                        </span>
                        <span class="white--text caption mx-1" v-if="act.Completed">
                        {{act.Completed_By.Name.substr(0,1)}}{{act.Completed_By.Surname.substr(0,1)}}
                        </span>
                    </v-list-item-avatar>
                    <v-list-item-content class="caption" >
                      <v-list-item-title class="caption"  :style="act.Completed? 'text-decoration: line-through;' : ''"> {{act.Description}}</v-list-item-title>
                    </v-list-item-content>
                     <v-list-item-action class="caption" >
                      <v-icon v-if="note.Editing && note.Editors.includes(userLoggedIn.id)" @click="ConfirmDeleteNoteAction(note,act)">mdi-delete-forever</v-icon>
                     </v-list-item-action>
                  </v-list-item>
              </v-list>
              <v-card flat tile style="min-height: 200px;" v-if="note.Editing" @dragover.prevent @drop="recordtaggingfinish(note, $event)">
                <v-card-text>
                  {{note.Records.length}} Tagged (filters may affect if all show in list below)
               <v-virtual-scroll
                style="padding:4px;"
                    class="transparent"
                      :items="FullDataRecords(note.Records)"
                      :item-height="20"
                      :height="190"
                    >
                <template v-slot:default="{ item }">
                <v-chip  x-small>
                   <v-icon x-small @click="RemoveNoteTagItem(note,item.id)" dark color="red">
                    mdi-close
                  </v-icon>
                  {{item[CurrentEntity.Primary_Field_Name]}}
                </v-chip>
               </template>
                </v-virtual-scroll>
                </v-card-text>                
              </v-card>
               <v-card-actions v-if="note.Editing">
                
                <v-btn small @click="TagRecordsinView(note)" dark color="warning"><v-icon small>mdi-tag</v-icon>All in View</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn small v-if="note.Records.length > 0" @click="ClearNoteRecords(note)" dark color="error">Clear</v-btn>
              </v-card-actions>
              <v-card-actions v-if="note.Editing">
                <v-autocomplete multiple class="caption" @change="CheckEditors(note)" :items="NonGuestUsersArray" v-model="note.Editors" item-text="id" label="Editors">
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeEditor(note,data.item)"
                    >
                      <v-avatar left class="blue">
                        <v-img v-if="data.item.Profile_Photo" :src="data.item.Profile_Photo"></v-img>
                        <span class="white--text caption mx-1" v-if="!data.item.Profile_Photo" >
                        {{data.item.Name.substr(0,1)}}{{data.item.Surname.substr(0,1)}}
                        </span>
                      </v-avatar>
                      {{ data.item.Full_Name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img v-if="data.item.Profile_Photo" :src="data.item.Profile_Photo" style="object-fit: cover;">
                            <img v-if="!data.item.Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title >{{data.item.Full_Name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.Business_Unit" class="caption links--text" v-html="data.item.Business_Unit.Name"></v-list-item-subtitle>
                            <v-list-item-subtitle class="caption" v-html="data.item.Email"></v-list-item-subtitle>                            
                          </v-list-item-content>
                        </template>
                </v-autocomplete>
                <v-spacer>
                </v-spacer>   
              </v-card-actions>
              <v-card-actions v-if="note.Editing">
              
                <v-spacer>
                </v-spacer>             
                <v-btn small @click="SaveNote(note)" dark color="green">Save</v-btn>
              </v-card-actions>
              <v-card-actions v-if="!note.Editing" style="bottom: 0px;position: absolute;right: 0px;">
                <v-btn small @click="FilterbyNoteRecords(note,note.Records)" fab icon><v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>                             
      </v-layout>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
    props: ['System','SystemEntities','NotesArray','CurrentEntity','Data','SingleRecord'],
    components: {ContentEditableField},
    data() {
        return {
          HideNotes: false,
            NewNoteActionDial: false,
          ActionNote: '',
          NewNoteAction: {
            Description: 'Action',
            Completed: false
          },
          DefaultNewNoteAction: {
            Description: 'Action',
            Completed: false
          },
          defaultNewNote: {
            Title: 'New Note',
            Description: 'Add Description to the Note',
            Color: '#ECEC93FF',
            TitleColor: '#EBEB6C',
            Dark: false,
            Editing: false,
            Records: [],
            Editors: [],
            Actions: [],
          },
          NewNote: '',
        }
    },	
    computed:{
        ComputedNotesArray(){
        return this.NotesArray.filter(note => {
          if(this.SingleRecord){
            return this.NotesArray
          }
          else if(this.NotesbyDatainView){
            return note.Records.some(r=> this.DataListIds.includes(r))
          }
          else if(this.NotefilterRecords && this.NotefilterRecords.length > 0){
            return note.Records.some(r=> this.NotefilterRecords.includes(r))
          }
          else{
            return note
          }
        })
      },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      removeEditor (note,item) {
        const index = note.Editors.indexOf(item.id)
        if (index >= 0) note.Editors.splice(index, 1)
      },
      CheckEditors(note){
        if(note.Editors.length === 0){
          note.Editors.push(this.userLoggedIn.id)
        }
        if(!note.Editors.includes(note.Owner.id)){
          note.Editors.push(note.Owner.id)
        }
        this.SaveNote(note,true)
      },
      ConfirmDeleteNoteAction(note,act){
        let actobj = note.Actions.find(obj => obj === act)
        let index = note.Actions.indexOf(actobj)
        confirm('Are you sure you want to remove this action?') && this.ProcessDeleteNoteAction(note,index)
      },
      ProcessDeleteNoteAction(note,index){
        note.Actions.splice(index,1)
        this.SaveNote(note)
      },
      ActivateNewNoteActionDial(note){
        this.ActionNote = note
        this.NewNoteActionDial = true
      },
      CheckActionCompleted(note,act){
        if(act.Completed){
          act.Completed_By = {Full_Name: this.userLoggedIn.Full_Name, id:this.userLoggedIn.id,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname}
          act.Completed_Byid = this.userLoggedIn.id
        }
        this.SaveNote(note)
      },
      CancelAddAction(){
        this.NewNoteAction = Object.assign({},this.DefaultNewNoteAction)
        this.NewNoteActionDial = false
        this.ActionNote = ''
      },
      AddAction(note){
        this.NewNoteAction.Owner = {Full_Name: this.userLoggedIn.Full_Name, id:this.userLoggedIn.id,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname}
        this.NewNoteAction.Ownerid = this.userLoggedIn.id
        this.NewNoteAction.Created_On = new Date()
        note.Actions.push(this.NewNoteAction)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').doc(note.id).set(note).then(addeddoc => {
          this.CancelAddAction()
        })
      },
        TagRecordsinView(note){
        note.Records = note.Records.concat(this.DataFilteredbyNotes.map(data => {
          return data.id
        }).filter(data => { return !note.Records.includes(data)}))
        //console.log(note.Records)
      },
        FullDataRecords(ids){
        return this.Data.filter(data => {
          return ids.includes(data.id)
        })
      },
        SaveNotes(){
        this.NotesArray.map(note => {
          note.Editing = false
           db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').doc(note.id).set(note).then(updatednotedoc => {

           })
        })       
      },
      ConfirmNoteDelete(noteid){
        confirm('Are you sure you want to delete this item?') && this.ProcessNoteDelete(noteid)
      },
      ProcessNoteDelete(noteid){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').doc(noteid).delete()
      },
       SaveNote(note,fromeditors){
        if(!fromeditors){          
        note.Editing = false
        } 
         db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').doc(note.id).set(note).then(addeddoc => {
          
        })
      },
      ConfirmAddNote(){
        let ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').doc()
        let newdocid = ref.id
        this.NewNote = Object.assign({},this.defaultNewNote)
        this.NewNote.Owner = {Full_Name: this.userLoggedIn.Full_Name, id:this.userLoggedIn.id}
        this.NewNote.Ownerid = this.userLoggedIn.id
        this.NewNote.topicid = this.CurrentEntity.id
        this.NewNote.Label = this.Label && this.Label.id ? this.Label.id : ''
        this.NewNote.id = newdocid
        if(this.SingleRecord){
            this.NewNote.Records = [this.Data[0].id]
        }
      },
      AddNotetoDB(){
        this.NewNote.Created_On = new Date()
        this.NewNote.Editors.push(this.userLoggedIn.id)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').doc(this.NewNote.id).set(this.NewNote).then(addeddoc => {
          this.NewNote = ''
        })
      },
    }
}
</script>

<style>

</style>



