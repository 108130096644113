<template>
  <div>
    <v-card-title v-if="EditingProduct">
        Quick Preview
    </v-card-title>
    <v-card rounded>
      <v-img :height="EditingProduct ? '' : 400" contain :src="ActiveProduct.IMG ? ActiveProduct.IMG : 
          System.Logo && System.Logo.fileurl ? System.Logo.fileurl : require('@/assets/logo.png')">
                <v-row v-if="EditingProduct" style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding:0px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">        
                <input style="display: none;" type="file" name="advertsingleimg" ref="advertsingleimg"
                 @change="SelectAdvertIMG($event)" :accept="AllAllowedFileTypes">
                <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('AdvertIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
            </v-img>
            <v-card-subtitle class="subtleoverline" style="font-weight: bold;">
              {{ActiveProduct.Title}}
            </v-card-subtitle>      
          <v-card-text >
            <!-- <v-card-subtitle style="font-weight: bold;">
             Description
            </v-card-subtitle> -->
            <v-card-subtitle class="caption" v-html="ActiveProduct.Description">
            </v-card-subtitle>
        </v-card-text>
        <v-card-text v-if="!EditingProduct">
            Now we previewing, so now it is real, show all additional fields etc
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
export default {
    props: ['System','SystemEntities','ActiveProduct','EditingProduct'],
    components: {},
    data() {
        return {
          AdvertIMG: '',
          SampleAdvertIMG: '',	
          editedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',            
          },
          defaulteditedProduct: {
            Price: 0,
            Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.`,
            Title: 'Product',
          },
          menu: false,
        }
    },	
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    },
    methods:{
      SelectAdvertIMG(event){
        this.AdvertIMG = event.target.files[0]							
        this.ActiveProduct.IMG = URL.createObjectURL(this.AdvertIMG)
      },
      ActivateGalleryDialog(prp){
        if(this.userLoggedIn){
        this.$store.commit('SetSocialItemInteractMethodProp',true)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
        }
        else{
          this.$refs.advertsingleimg.click()
        }
      },      
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    }
}
</script>

<style>

</style>



