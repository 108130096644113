<template>
<v-main style="margin-top:60px;">
    <v-dialog persistent v-model="FAQDialog" max-width="800">
        <v-card width="100%">
            <v-card-title>
                FAQ
            </v-card-title>
            <v-card-text>
                 <v-combobox
                    v-model="editedQuestion.KeyWords"
                    :items="items"
                    label="Response KeyWords"
                    multiple
                    chips
                    >
                    <template v-slot:selection="data">
                        <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                        >
                        <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                        </v-chip>
                        </template>
                </v-combobox>
                <v-text-field v-model="editedQuestion.Question" label="Question"/>
                <ContentEditableField
                :FieldObject="editedQuestion" :FieldName="'Answer'"
                :FieldValue="editedQuestion.Answer" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />

                <v-select :items="['Public','Members Only']" v-model="editedQuestion.PublishType" label="Publish Type" />    
                <v-switch v-model="editedQuestion.HasActionButton" label="Has Action Button"/>
                <v-text-field v-model="editedQuestion.ActionButtonName" label="Action Button Name" />
                <v-select :items="['Route','URL']" v-model="editedQuestion.ActionButtonType" label="Action Button Type" />
                <v-text-field v-model="editedQuestion.ActionButtonRoute" label="Route" />
            </v-card-text>
            <v-card-actions>
                <v-card-actions>
                    <v-btn @click="DeActivateFAQDialog()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="SaveFAQ()">Save</v-btn>
                </v-card-actions>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-card class="siteconfigcard">
    <v-card-title class="my-2 BoldBuilderGradient justify-start mediumoverline">
        FAQ - Admin<v-spacer></v-spacer>
        <v-btn @click="ActivateFAQDialog()">Add New</v-btn>
    </v-card-title>
  <v-card-text style="padding-top:100px;">
   <v-expansion-panels class="mb-6">
        <v-expansion-panel
            v-for="(question,questionindex) in FrequentlyAskedQuestions"
            :key="question.itemObjKey">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                    <v-card-subtitle style="font-size:  1.15em;">
                <v-icon color="primary">mdi-plus</v-icon> {{question.Question}}
                    </v-card-subtitle>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-btn @click="ActivateFAQDialog(question,questionindex)">Edit</v-btn>
                    <v-card-text v-html="question.Answer">
                    </v-card-text>
                    <v-card-actions v-if="question.HasActionButton" :class="question.ButtonAlignment">
                    <v-btn v-if="question.ActionButtonType === 'URL' && question.Fancy" :dark="question.ActionBtnDark" x-large width="200"  :outlined="question.Outlined" :href="question.ActionButtonRoute" target="_blank" :style="question.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+question.ButonColor.hexa">
                        {{question.ActionButtonName}}
                    </v-btn>
                    <v-btn v-if="question.ActionButtonType === 'Route' && question.Fancy" :dark="question.ActionBtnDark" x-large width="200" :outlined="question.Outlined" :to="'/'+question.ActionButtonRoute" :style="question.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+question.ButonColor.hexa">
                        {{question.ActionButtonName}}
                    </v-btn>
                    <v-btn v-if="question.ActionButtonType === 'URL' && !question.Fancy" :dark="question.ActionBtnDark" :href="question.ActionButtonRoute" :outlined="!question.Outlined" target="_blank" :style="question.Outlined? '' : 'background-color:'+question.ButonColor.hexa">
                            {{question.ActionButtonName}}
                        </v-btn>
                        <v-btn v-if="question.ActionButtonType === 'Route' && !question.Fancy" :dark="question.ActionBtnDark" :to="'/'+question.ActionButtonRoute" :outlined="!question.Outlined"  :style="question.Outlined? '' : 'background-color:'+question.ButonColor.hexa">
                            {{question.ActionButtonName}}
                        </v-btn>
                </v-card-actions>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
  </v-card-text>
    </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
    props: ['FrequentlyAskedQuestions'],
    components: {
        ContentEditableField
    },
    data (){
        return{
            items: [],
            FAQDialog: false,
            elmnt: {									
                RowColor: '#00ffff',									
                Name: 'FAQ',									
                ShowComponents: true,									
                Icon: 'mdi-help',									
                Title: 'About',	
                SelectedFAQ: [],								
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,
                HasActionButton: false,
                Fancy: true,
                Outlined: true,
                ButtonColor: {
                    hexa: '#ff0000'
                },
                ButtonAlignment: 'justify-end',
                ActionButtonName: 'Read More',
                ActionButtonType: 'Route',
                ActionButtonRoute: ''								
            },
            editedQuestionIndex: -1,
            editedQuestion: {
                Question: '',
                Answer: '',
                HasActionButton: false,
                PublishType: 'Public',
                Fancy: true,
                Outlined: true,
                ButtonColor: {
                    hexa: '#ff0000'
                },
                ButtonAlignment: 'justify-end',
                ActionButtonName: 'Read More',
                ActionButtonType: 'Route',
                ActionButtonRoute: ''
            },
            defaultQuestion:{
                Question: '',
                Answer: '',
                HasActionButton: false,
                 PublishType: 'Public',
                Fancy: true,
                Outlined: true,
                ButtonColor: {
                    hexa: '#ff0000'
                },
                ButtonAlignment: 'justify-end',
                ActionButtonName: 'Read More',
                ActionButtonType: 'Route',
                ActionButtonRoute: ''
            }
        }
    },
    computed:{
        ComputedElement(){
            let elmnt = this.elmnt
            elmnt.SelectedFAQ = this.FrequentlyAskedQuestions
            return elmnt
        }
    },
    created(){
        
    },
    methods:{
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
            console.log(FieldObject,this.editedQuestion)
            },
        SaveFAQ(){
            if(this.editedQuestionIndex === -1){
                this.editedQuestion.LowerKeyWords = this.editedQuestion.KeyWords.map(keyword => {return keyword.toLowerCase()}),
                db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('frequentlyaskedquestions').add(this.editedQuestion).then(doc => {
                   this.DeActivateFAQDialog()
                })
            }
            else{
                this.editedQuestion.LowerKeyWords = this.editedQuestion.KeyWords.map(keyword => {return keyword.toLowerCase()}),
                db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('frequentlyaskedquestions').doc(this.editedQuestion.id).set(this.editedQuestion).then(doc => {
                    this.DeActivateFAQDialog()
                })
            }
        },
        ActivateFAQDialog(question,questionindex){
            if(question){
                this.editedQuestion = Object.assign({},question)
                this.editedQuestionIndex = questionindex
                this.FAQDialog = true
            }
            else{
                this.FAQDialog = true
            }
        },
        DeActivateFAQDialog(question){
           this.FAQDialog = false
           this.editedQuestion = Object.assign({},this.defaultQuestion)
           this.editedQuestionIndex = -1
        }
    }
}
</script>

<style>

</style>