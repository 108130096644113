<template>
  
<nav>    
<v-app-bar class="unpaddedappbar" app :dark="AppisDarkMode" v-if="TrackedOrder" color="guestappbar" style="padding: 0px;"
  >
  <v-toolbar-title style="position: absolute;left:0px;top: -5px;z-index:5;">
    <v-chip>
    {{TrackedOrder.id}}
    </v-chip>
  </v-toolbar-title>
  <!-- <v-card-subtitle style="position: absolute;left:50%;top: -15px;z-index:5;">
   {{TrackedOrder.id}}</v-card-subtitle> -->
   <v-spacer></v-spacer>
  <v-stepper class="" style="width: 100%;padding: 0px;">
    <v-stepper-header>
      <v-stepper-step v-for="(opt,index) in RecordTrackFilterOptions" :key="opt.itemObjKey"        
        :step="index-1+2"
        :complete="opt.Completed"
      >
      <v-icon>{{opt.Icon}}</v-icon>
        {{opt.Name}}
      </v-stepper-step>

      <v-divider v-if="index-1+2 < TrackFilterOptions.length"></v-divider>

    </v-stepper-header>
  </v-stepper>
</v-app-bar>
<v-app-bar app :dark="AppisDarkMode" :style="TrackedOrder ? 'margin-top:60px;' : ''" color="guestappbar"
  >
  <v-btn v-if="SystemSocialReady && CanSocial" @click="IntranetViewToggle(true,true)" text>
          <v-icon dark>
              mdi-repeat
            </v-icon>{{System.Social_Module_Name}}
        </v-btn>
       
       <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="ToggleAppDarkMode()"><v-icon >mdi-invert-colors</v-icon></v-btn>                            
            </template>
                Toggle Dark Mode
            </v-tooltip>
       <v-icon class="mx-3" @click="ToggleAppStylingView()" v-if="userIsAdmin">mdi-palette-advanced</v-icon>
       <v-spacer>
       </v-spacer>
       <ProfileActionsDropdown  :ClientDB="ClientDB" :HideAppStyling="true" :System="System" :SystemEntities="SystemEntities"
        @ToggleAppStylingView="ToggleAppStylingView"/>
       <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                       <v-btn v-bind="attrs" v-on="on" text @click="ConfirmlogoutFromFirebase()"><v-icon>mdi-exit-to-app</v-icon></v-btn>                            
                        </template>
                         Sign Out
                      </v-tooltip>
    </v-app-bar>
     <v-list  v-if="RoadmapActive && LiaisonTopicItems.length > 0" :style="MiniView ? '--btm:16vh' : '--btm:12vh'" :class="LiaisonNav ? 'transparent visitordrawercontainer open' : 'transparent visitordrawercontainer'">
    <!-- the liaison portal visible if there are liaison topics in the system - either 
    1. we activate Liaison on default services with my profile, support tickets etc  and drop it from here OR
    2. We do this part from here, but ActiveSubmenu becomes Activesession so we have full view of liaison -->
        <v-list :class="MiniView ? 'transparent visitordrawermini' : 'transparent visitordrawer'" width="350">
          <v-list-item 
           :class="MiniView ? 'grey visitordrawertoggle' : 'grey visitordrawertoggle'" style="z-index: 2;">
           <v-list-item-content style="padding: 0px;">
                  <v-chip style="max-width: 200px;">
                    {{userLoggedIn.Full_Name}}
                  </v-chip>
           </v-list-item-content>
           <v-list-item-action >
            <v-badge
          avatar
          bordered
          overlap
        >
          <template v-slot:badge>
            <v-avatar :color="UnreadLiaisonTopicNotifications.length > 0 ? 'red white--text' : 'grey white--text'">
            {{UnreadLiaisonTopicNotifications.length}}
            </v-avatar>
          </template>
          <v-avatar style="cursor: pointer;" id="visitortogglebar" color="blue" size="40"  @click="ActivateLiaisonNav()">
            <img style="object-fit: cover;" :src="userLoggedIn && userLoggedIn.Profile_Photo ? userLoggedIn.Profile_Photo : require('@/assets/BlankProfilePic.png')"/>
          </v-avatar>
        </v-badge>
           </v-list-item-action>
        </v-list-item>
        
        <!-- this is BRILLIANT but I don't think it works here. -->
          <div id="activevisitorbubble" class="bubblenav">
          <div id="activevisitorham" class="hamburger">
          <span class="hamburgerline"></span>
          <span class="hamburgerline"></span>
          <span class="hamburgerline"></span>
          </div>
          <div class="bubblemenuitem" style="--i: 0">
            <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
          <div class="bubblemenuitem" style="--i: 1">
            <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
          <div class="bubblemenuitem" style="--i: 2">
           <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
          <div class="bubblemenuitem" style="--i: 3">
            <v-btn fab >
           <v-icon x-large>mdi-home
           </v-icon>
            </v-btn>
          </div>
        </div> 
        
        <v-list width="300px" height="100vh" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);" :class="SubMenuActive ? 'submenulist submenuactive' : 'submenulist'">
           <v-list-item class="submenuitem" @click="ActivateSubmenu(item)">
            <v-list-item-avatar>
              <v-icon>mdi-keyboard-return
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{ActiveSubmenu.name}}
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="ActiveSubmenu.Data">
             <v-virtual-scroll 
              class="transparent" :style="'margin-top:20px;height: 60vh;'"
                :items="ActiveSubmenu.Data"
                :height="$vuetify.breakpoint.xs ? 300 : 300"
                :item-height="$vuetify.breakpoint.xs ? 110 : 110"
              >
              <template v-slot:default="{ item }">
             <v-list dense>
              <v-list-item dense :class="{newbasicoutlined: item.Read}"  @click="NotificationRead(item)" two-line>
                  <v-list-item-content>
                  <v-list-item-title>
                    {{item.Header}}
                  </v-list-item-title>
                <v-list-item-subtitle  v-if="item.Type !== 'Friendship Requested'">                                    
                  <p v-if="!$vuetify.breakpoint.xs" class="notificationcontent">{{item.Message}}
                    <!-- This just not working for us anymore <span class="tooltiptext"><h4>{{item.CreatorFullName}}</h4>{{item.Content.substr(0, 50)}}</span> -->
                    </p>
                </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item dense class="justify-space-between" v-if="item.Type === 'Friendship Requested'">
                <v-btn small dark color="success">
                  Accept
                </v-btn>
                <v-btn small dark color="error">
                  Decline
                </v-btn>
              </v-list-item>
              <v-list-item dense class="justify-space-between" v-if="item.Type !== 'Friendship Requested'">
                <v-btn dark color="blue" small @click="MarkasUnread(item)">Mark Unread</v-btn>
                <v-btn dark color="success" small @click="ViewItem(item)">View</v-btn>
                <!-- <v-btn small dark color="success">
                  Accept
                </v-btn>
                <v-btn small dark color="error">
                  Decline
                </v-btn> -->
              </v-list-item>
             </v-list>
            </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-text class="white" style="height:300px;overflow-y: hidden;" v-if="!ActiveSubmenu.Data">
          </v-card-text>
        </v-list>
        <v-list dense id="menulist" :class="SubMenuActive ? 'menulist submenuactive' : 'menulist'" width="300px" height="100vh" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);" >
        <v-list-item :to="'/SiteMember/'+userLoggedIn.id">
            <v-list-item-avatar class="blue" tile size="40">
              <img v-if="userLoggedIn.Profile_Photo "
              :src="userLoggedIn.Profile_Photo "    
              style="object-fit: cover;"          
              >
              <img v-if="!userLoggedIn.Profile_Photo "
                src="@/assets/BlankProfilePic.png"   
                style="object-fit: cover;"           
              >                      
              </v-list-item-avatar>
            <v-list-item-content >
              <v-list-item-title>{{userLoggedIn.Full_Name}}</v-list-item-title>                    
            
            </v-list-item-content>
          </v-list-item>
           <!-- <v-badge
          avatar v-for="item in LiaisonTopicItems" :key="item.itemObjKey" class="submenuitem" @click="ActivateSubmenu(item)"
          bordered
          overlap
        >
          <template v-slot:badge>
            <v-avatar :color="item.NotificationCount > 0 ? 'red white--text' : 'grey white--text'">
            {{item.NotificationCount}}
            </v-avatar>
          </template>           -->
        <v-list-item v-for="item in LiaisonTopicItems" :key="item.itemObjKey" class="justify-space-between submenuitem" @click="ActivateSubmenu(item)">
          <v-list-item-avatar>
            <v-icon>{{item.icon}}
            </v-icon>
          </v-list-item-avatar><v-badge
          avatar v-for="item in LiaisonTopicItems" :key="item.itemObjKey" class="submenuitem" @click="ActivateSubmenu(item)"
          bordered
          
        >
          <template v-slot:badge>
            <v-avatar :color="item.NotificationCount > 0 ? 'red white--text' : 'grey white--text'">
            {{item.NotificationCount}}
            </v-avatar>
          </template>
          <v-list-item-content id="messagesbtn">
          {{item.name}}
          </v-list-item-content>
          </v-badge>
        </v-list-item>
        <!-- </v-badge> -->
        </v-list>
        </v-list>
      </v-list> 
    
</nav>
</template>

<script>
// import VehiclesWiki from '@/views/VehiclesWiki'
// import VehicleApplicationsWiki from '@/views/VehicleApplicationsWiki'
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';
import DigitizedFormComponent from '@/components/Client/DigitizedFormComponent'
import MyBillingAccounts from '@/components/Client/MyBillingAccounts'
import MyCompanyComp from '@/components/Client/MyCompanyComp'
import ProfileActionsDropdown from '@/components/Navigation/ProfileActionsDropdown'
import MyProfile from '@/views/MyProfile'
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
  // had ,'userLoggedIn' as prop...?
    props: [{ScheduleEntityComponent: {			
			type: String,
			required: true
		}},'AppisDarkMode','Notifications','NotificationObj','SystemEntities','System','SubscriptionPackages'],
    components: {
        MyProfile,
        DataCollectionViewComponent,
        DigitizedFormComponent,
        MyBillingAccounts,
        MyCompanyComp,
        ProfileActionsDropdown
        // VehiclesWiki,
        // VehicleApplicationsWiki
    },
    data(){
        return {
          LiaisonNav: false,
          SubMenuActive: false,
          ActiveSubmenu: '',
          UserDashboards: [],
          rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
          //PageBGGradient: 'background: linear-gradient(150deg, rgba(46,95,144,0.6) 0%, rgba(192,77,139,0.6) 35%, rgba(238,147,126,0.6) 71%);',
          NewLink: '',
          slice: 4,
          UploadingFileProgress: 0,
          LiaisonUploadFile: '',
          OpenFormComponent: false,
          ActiveForm: '',
          ActiveComment: '',
            MyVehicles: [],
            events: [],
      input: '',
      nonce: 0,
            VehicleApplications: [
            ],
            ActiveRecord: '',
            // Activesession: {name: 'My Profile'},
            Activesession: '',
            VehiclesLinksGroupsearch: '',
      VehiclesWikiViewType: '',
      VehiclesWikiViewbyOptions: [],
            VehiclesWikiMode: false,
            storedrawernav: false,
            storedrawerdialog: false,
            ActiveProductFilter: '',
            MySuiteNavItem: {
                            name: 'My Suites',
                            icon: 'mdi-apps'
                        },
            items: [
                {
                    name: 'My Account',
                    ID: 1001,
                    Children: [
                        {
                            name: 'My Profile',
                            icon: 'mdi-star-face'
                        },
                        // {
                        //     name: 'My Company',
                        //     icon: 'mdi-badge-account-horizontal'
                        // },                        
                        {
                            name: 'My Accounts',
                            icon: 'mdi-bank'
                        },
                        //  {
                        //     name: 'My Suites',
                        //     icon: 'mdi-apps'
                        // }
                    ]
                },
                {
                  name: 'Schedules',
                  ID: 1005,
                  Children: [],
                  icon: 'mdi-calendar',
                  IsSchedule: true,
                },
                {
                  name: 'My Records',
                  ID: 1006,
                  Children: [],
                  icon: 'mdi-file-table',
                  IsTable: true,
                }
                
                // {
                //     name: 'Notifications',
                //     ID: 1004,
                //     Children: []
                // },
                
            ]
        }
    },
    computed:{
      LiaisonTopicItems(){
        return this.NotificationsNavItemChildren.map(child => {
          //console.log('child',child)
          child.Data = child.Data.map(record => {
            let recordobj = Object.assign({},record)
            recordobj.NotificationCount = this.UnreadNotifications.filter(not => {
              return not.clientrecords === record.id
            }).length
            return recordobj
          }) 
          child.NotificationCount = this.UnreadNotifications.filter(not => {
              return not.topicid === child.id
            }).length
          return child
        })
      },
      NotificationsNavItemChildren(){
        //crazy confusing but...Maybe we should get away from this structure?
        //this.Activesession = menuobj.Children.find(obj => obj.id === this.NotificationObj.ActiveSessionid)
        //this.ActiveRecord = this.Activesession.Data.find(obj => obj.id === this.NotificationObj.ActiveRecord)
        //Okay here is the deal...We don't even have liaison topics mind you. I have no F clue how any of this would work. 
        //I think wehen client lookup field is assigned we get LiaisonTopics, that's one...
        //the ClientStoreOrders really is how we identify the orders we use it in BillingAccounts view, which makes sense.
        //But the ONLY think we got LiaisonTopics on...well..really right now there is nothing. not on test case. So there is no way to test this
        return this.NotificationsNavItem? this.NotificationsNavItem.Children : []
      },
      UnreadLiaisonTopicNotifications(){
        return this.UnreadNotifications.filter(not => {
          return this.LiaisonTopicItems.find(obj => obj.id === not.topicid)
        })
      },
      UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
      ComputedSuiteNavItem(){
        let obj = Object.assign({},this.MySuiteNavItem)
        let notifications = this.Notifications.filter(item => {
          return item.topicid === 'Suite_Templates' && !item.Read
        })        
        obj.NotificationCount = notifications.length
        if(obj.NotifyColor> 0){
          obj.NotifyColor = 'red'
        }
        else{
          obj.NotifyColor = 'grey'
        }
        return obj
      },
      ClientDB(){
      return db
    },
      TrackedOrder(){
        return this.TrackingOrders && this.TrackingOrders[0] ? this.TrackingOrders[0] : ''
      },  
      TrackedOrderStatusIndex(){
        let index = -1
        if(this.TrackedOrder){
          let opt = this.TrackFilterOptions.find(obj => obj.ID === this.TrackedOrder.Status_Reason.ID)
          index = this.TrackFilterOptions.indexOf(opt)
        }
        return index
      },
      TrackingOrders(){
        return this.$store.state.TrackingOrders
      },
      RecordTrackFilterOptions(){
        return this.TrackedOrder ? this.TrackFilterOptions.map((opt,index) => {
          let optobj = JSON.parse(JSON.stringify(opt))
          if(this.TrackedOrderStatusIndex >= index){
            optobj.Completed = true
          }
          return optobj
        }) : []
      },
      TrackFilterOptions(){
        return this.TrackFilter ? this.TrackFilter.Options : []
      } , 
      TrackFilter(){
        return this.TrackedEntityStatusField && this.userLoggedIn ? this.TrackedEntityStatusField.Options.find(obj => obj.ID === this.userLoggedIn.TrackFilter) : ''
      },
      TrackedEntityStatusField(){
        return this.TrackedEntity ? this.TrackedEntity.AllFields.find(obj => obj.id === 'Status') : ''
      },
      TrackedEntity(){
        return this.userLoggedIn && this.userLoggedIn.TrackedEntity ? this.SystemEntities.find(obj => obj.id === this.userLoggedIn.TrackedEntity) : ''
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
      SystemSocialReady(){
        return this.PluginDataBase && this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active && this.System.Social_Landing_Page
      },
      CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial || this.userIsAdmin || !this.$store.state.UserisGuest
      },
      SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      NotificationsNavItem(){
            return this.items.find(obj => obj.ID === 1004)
            //actually this.items.find(obj => obj.ID === 1004).Children
            //even more I think we need a special box fox this that looks kick ass. but we need a working verison to know for sure
        },
      ClientStoreOrders(){
        let menuitem = this.items.find(obj => obj.ID === 1004)
        return menuitem.Children.map(Activesession => {
          return Activesession
        }).flat().filter(Activesession => {
          return Activesession.Entity_Type && Activesession.Entity_Type.Name === 'Store Order'
        }).map(channel => {
          return channel.Data
        }).flat()
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
      NavStyling(){

      if(this.System.Guest_SideBar_IMG && this.System.Guest_SideBar_Gradient){
                return { backgroundImage: this.System.Guest_SideBar_Gradient.split('background: ')[1].split(';')[0]+`,url('`+this.System.Guest_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.Guest_SideBar_IMG && !this.System.Guest_SideBar_Gradient){
                return { backgroundImage: `url('`+this.System.Guest_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.Guest_SideBar_Gradient){
                return { backgroundImage: this.System.Guest_SideBar_Gradient.split('background: ')[1].split(';')[0]}
            }
            else if(this.PageBGColor){
                return { backgroundColor: this.PageBGColor+ `!important`}
            }
            else{
                return ''
            }
    },
      ScheduleEntityComponent(){
        if(this.Activesession && this.Activesession.IsSchedule){			
        return () => import(`@/views/${this.Activesession.name.split('_').join('')}.vue`);			
      }
      },
      UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
      UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
      MyCompany(){
      return this.$store.state.MyCompany
    },
    CompanyBillingAccounts(){
        //console.log('this.MyCompany',this.MyCompany)
        return this.$store.state.CompanyBillingAccounts
      },
      UserisCompanyAdmin(){
        //console.log(this.$store.state.UserisCompanyAdmin)
        return this.$store.state.UserisCompanyAdmin
      },
      WebAppBuilderActive(){
        return this.PluginDataBase && this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active && this.RelatedSuiteTemplates.length > 0
      },
      RelatedSuiteTemplates(){
      return this.MySuiteTemplates.concat(this.ClientSuiteTemplates) 
    },
    SuiteTemplates(){
    return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
    },
    OtherSuiteTemplates(){
    return this.$store.state.OtherSuiteTemplates
    },
    MySuiteTemplates(){
    return this.$store.state.MySuiteTemplates
    },
    ClientSuiteTemplates(){
    return this.$store.state.ClientSuiteTemplates
    },
      Computeditems(){
        //console.log(this.UserCompaniesActive,this.UserisCompanyAdmin)
        return this.items
        .map(item => {
          if(item.name === 'My Account'){
            item.Children = item.Children.filter(child => {
              return this.UserCompaniesActive && this.UserisCompanyAdmin || child.name !== 'My Company'
            })         
          }
          return item
        })
        .map(item => {
          if(item.ID === 1001){
            if(!this.UserBillingAccounts){
               item.Children = item.Children.filter(child => {
                return child.Name !== 'My Accounts'
              })
            }
            else{
              // item.Children = item.Children.map(child => {
              //   if(child.Name === 'My Accounts'){
              //    child.Data = this.UserBillingAccounts 
              //   }
              //   return child
                
              // })
            }
          }
          if(item.ID === 1005){
            item.Children = this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name === 'Booking Schedule' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsSchedule = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase()
            return entity
          })
          }
          else if(item.ID === 1006){
            item.Children = this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name !== 'Booking Schedule' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsTable = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase(),
            entity.RouteQueries = [
                {Prop: 'TableType', Value: 'Standard'},
                {Prop: 'TableName', Value: entity.RouteName }
              ]
            return entity
          })
          }
          else if(item.ID === 1002){
            item.Children = this.System.GuestNavbarItems.map(item => {
              let childobj ={
                name: item.title,
                icon: item.icon,
                IsPage: true,
                Path: item.Path,
              }
              return childobj
            })
          }
          
            item.Children = item.Children.map(child => {
                //console.log(child.Data)
              if(child.Data){
               child.Data = child.Data.map(record => {
                 if(record.ClientLiaison){
                   record.ClientLiaison = record.ClientLiaison.map(liase => {
                    liase.createdon = this.TimestampFormatterSTRING(liase.Created_On.toDate())
                    liase.TimestampSort = this.DateFormatterwithTime(liase.createdon)                    
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                 }                  
                  return record
                }) 
              }           
            return child
          })
          return item
        }).filter(item => {
          return item.Children.length > 0
        }).filter(item => {
          //stupid as it may seem this is liaison only which is all that is left. but given that then, I will redo this whole thing in a liaison box as per new socialquicknav
          return item.ID === 1004
        })
      },
      timeline () {
        return this.events.slice().reverse()
      },

        userLoggedIn () {
      return this.$store.getters.user
    },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
        ProductsClassesNEW(){
            return this.StoreProductsEntities
            .filter(entity => {
                return entity.HierarchyField
            })
            .map(entity => {
                let HierarchyField = entity.AllFields.find(obj => obj.id === entity.HierarchyField.id)
                 if(HierarchyField && HierarchyField.Levels && HierarchyField.Options){
                let array = JSON.parse(JSON.stringify(HierarchyField.Options))      
                return array
                .map(option => {
                    //console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    id: option.ID,
                    EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                })
                
            }
            }).flat()  
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        NotificationObj (value) {
            if (value) {
                this.CheckNotificationObj()
            }
        },
        
    },
    created(){
        //console.log(this.System)
      this.GetAllUserDashboards()
      this.$emit('IntranetViewToggle',false)
      if(!this.MiniView){
        this.storedrawernav = true
      }
      if(this.NotificationObj){
           this.CheckNotificationObj()
        }
      if(this.System.GuestNavbarItems){
        let pagesobj = {
              name: 'Pages',
              ID: 1002,
              Children: []
          }
        this.items.push(pagesobj)
      }
      // console.log('userLoggedIn',this.userLoggedIn)
      this.GetClientTopics()
      //this.GetClientWikiTabs()
      // this.GetMyVehicles()
    },
    methods: {
      ActivateLiaisonNav(){
        this.LiaisonNav = !this.LiaisonNav
       let ham  = document.getElementById('activevisitorham')
          ham.addEventListener("click", () => {
            let nav = document.getElementById('activevisitorbubble')
            nav.classList.toggle("open")
            nav.classList.toggle("presub")
            let submenus = document.getElementsByClassName('bubblemenuitem')
            for(var x=0;x<submenus.length;x++){
              let submenu = submenus[x]
              //console.log(submenu)
              submenu.addEventListener("click", () => {
            let nav = document.getElementById('activevisitorbubble')
            nav.classList.toggle("submenu")
              })
            }
          })
      },
      ActivateSubmenu(submenu){
        //So Actually, here...in My Acct, the logic is we view a chatbox of sorts, liaison portal. 
        //Only applies to liaison topics...
        //now...This wonly works if we DO NOT assign "activesession" unless we want to cater for it anyway? TBD when liaison topics apply.
        //Honestly we don't play that way with suite tempaltes so..this kind of on the backburnder until we resolve adding of LiaisonTopics again.
        //Even so still feel simplest resolve is in fact in straight "defaultitems" menu
        // this.SubMenuActive = !this.SubMenuActive
        // if(this.SubMenuActive){
        // this.ActiveSubmenu = submenu
        // //this.ActivateSession(submenu)
        // }
        // else{
        //   //this.AssignSocialItemInteractMethod('DeactivateActivesession')
        //   this.ActiveSubmenu = ''
        // }
        this.AssignSocialItemInteractMethod('ActivateSession',submenu)
      },
      GetAllUserDashboards(){
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
    },
    GetUserDashboards(query){
      let dashboardsitem = this.items.find(obj => obj.ID === 1001)
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let dashboardobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            this.UserDashboards.push(dashboardobj)
            let dashboarditem = {
                      icon: 'mdi-desktop-mac-dashboard',
                      name: dashboardobj.DashboardName,
                      Path: '/CustomDashboard/'+dashboardobj.id,
                      target: 'self',
                      id: dashboardobj.id
                    }
                    let oncheck = dashboardsitem.Children.find(obj => obj.id === dashboarditem.id)
                    if(!oncheck){
                      dashboardsitem.Children.push(dashboarditem)
                    }
          }
        })
      })
    },
      IntranetViewToggle(boolean1,boolean2){
        this.$emit('IntranetViewToggle',boolean1,boolean2)
      },
      ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
     ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      RouteToItem(route){
        this.Activesession = ''
        this.ActiveRecord = ''
        this.$router.push(route)
      },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },
      GetShortLivedURL(item){
        //console.log(item)
        const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          //console.log(result)
          item.TokenURL = result.data[0]
          setTimeout(() => {
                item.TokenURL = ''
                let url = item.fileurl
                item.fileurl = ''
                item.fileurl = url
          }, 120000);
          let url = item.fileurl
          item.fileurl = ''
          item.fileurl = url
        })
        },
      CheckNotificationObj(){
        let menuobj = this.Computeditems.find(obj => obj.ID === 1004)
        //console.log(menuobj)
        this.Activesession = menuobj.Children.find(obj => obj.id === this.NotificationObj.ActiveSessionid)
        this.ActiveRecord = this.Activesession.Data.find(obj => obj.id === this.NotificationObj.ActiveRecord)
        //ActivateActiveRecord
        //console.log(this.ActiveRecord,this.ActiveRecord.Notifications,this.NotificationObj)
        if(this.ActiveRecord.Notifications){
          this.ActiveRecord.Notifications.map(not => {
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).update({
                Read: true
              })
            })        }
        
        },
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean)
      },
      link(url,record) {
        this.input = `See this link <a href="`+url+`" target="_blank">here</a>`
        document.execCommand("createLink", false, url);
        record.TimelineDialog = false
        record.TimelineDialog = true
        this.NewLink = ''
        },
      ToTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      ActivateStoreDrawer(){
        if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
          this.storedrawernav = !this.storedrawernav
        }
        else{
          this.storedrawernav = !this.storedrawernav
        }
      },
      GetChildNotifyCount(child){
        let data = []
        if(child.Data){
          data = child.Data
        }
        return data.map(record => {
          let notificationslist = this.Notifications.filter(obj => {return obj.clientrecords === record.id && obj.Read === false})
          //alert(notificationslist)
          record.NotificationCount = notificationslist.length
          record.Notifications = notificationslist
          if(record.NotificationCount>0){
            record.NotifyColor = 'red'
            child.NotifyColor = 'red'
          }
          else{
            record.NotifyColor = 'grey'
            if(!child.NotifyColor){
             child.NotifyColor = 'grey' 
            }
            
          }
          return record
        }).map(record => {
          return record.NotificationCount
        })
        .reduce((a, b) => a + b, 0)
        
      },

      ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.$emit('SignoutNotifications')
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
      FileInput(elid){
            let elmnt = document.getElementById(elid)
            elmnt.click()
        },
      onFileselected(event){
        this.LiaisonUploadFile = event.target.files[0]
        this.LiaisonUploadFile.FileModifiedDate = new Date(this.LiaisonUploadFile.lastModified)
        setTimeout(() => {
          let newinput = 'Please see attached file - '+this.LiaisonUploadFile.name 
          if(this.input){
            newinput = '. '+newinput
          }
         this.input = this.input+newinput  
         //console.log(this.input) 
        }, 300);
        
        
        },
      SaveNewcomms(NewComms,record){
        NewComms.recipientid = record.Owner.id
        //console.log(NewComms)
           db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').add(NewComms).then(doc => {
            //console.log('created new doc '+doc.id)

            const NewNotification = {
                ActiveProcessNumber: record.ActiveProcessNumber,
                itemidprop: 'clientrecords',
                itemid: NewComms.relatedid,
                Type: 'Feedback',
                topicid: NewComms.topicid,
                Header: 'Response on '+record.name,
                Content: NewComms.description,
                CreatorFullName: NewComms.Created_By.Full_Name,
                CreatorID: NewComms.Created_By.id,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Read: false,
                IsLiaison: true,
                Liaisonid: doc.id,
                Path: '/MyDashboard',
                Message: NewComms.Created_By.Full_Name+' has responded on '+record.name,
                }
            this.$store.dispatch('createNotification',NewNotification)
            this.LiaisonUploadFile = ''
            }) 
      },
      UploadFileandAttahctoNewComms(NewComms,record){
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        let filename = newdocid+'_'+this.LiaisonUploadFile.name
        //console.log(filename)
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = NewComms.relatedtype.split(' ').join('')+'/'+NewComms.relatedid+'/ClientLiaison/'+ filename
        NewComms.Uploadfilename = this.LiaisonUploadFile.name
        var storageRef = firebase.storage().ref(path)
        NewComms.StorageRef = path
        NewComms.newdocid = newdocid
        NewComms.FileType = this.LiaisonUploadFile.type
        NewComms.lastModifiedDate = this.LiaisonUploadFile.FileModifiedDate
          var uploadTask = storageRef.put(this.LiaisonUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.LiaisonUploadFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  //console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  //console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
                NewComms.fileurl = downloadURL
                if(NewComms.FileType.includes('image')){
                  vm.$emit('ActivateProcessing',true)
                  vm.PrepareThumbnail(NewComms.Uploadfilename,vm.LiaisonUploadFile,NewComms.StorageRef).then(thumbresult => {
                        NewComms.ThumbURL = thumbresult
                        vm.$emit('ActivateProcessing',false)
                        vm.SaveNewcomms(NewComms,record)
                    })
                }
                else{
                  vm.SaveNewcomms(NewComms,record)
                }
                
              });
            })
                
        }, 300);
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
      Downloadfile(StorageRef,fileurl,Uploadfilename){
            

        var element = document.createElement('img')
            element.setAttribute('href',fileurl)
            element.setAttribute('download', this.ActiveRecord.name+' - '+Uploadfilename)
            // element.style.display = 'none';
          document.body.appendChild(element);
          //console.log(element)
          element.click();
          document.body.removeChild(element);
      },
      SubmitForm(form,comment){
        //console.log(form,comment)
        this.ActiveForm = ''
        this.ActiveComment = ''
        this.OpenFormComponent = false
        this.input = 'Submitted'
        form.readonly = true
        this.comment(this.ActiveRecord,form)
      },
      OpenForm(form,comment){
        this.ActiveForm = form
        this.ActiveComment = comment
        this.OpenFormComponent = true
      },
      DateFormatterwithTime(date){
      if(date){
        //console.log(date)
        //console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        let hrnumber = Number(hr)
        let minr = Number(min)
        let secnr = Number(sec)
        var ampm = "am";
        if(isNaN(secnr)){
          secnr = 0
        }
        //console.log(yearnumber,monthnumber,daynumber,hrnumber,minr,secnr)
        let newdate = new Date(yearnumber, monthnumber-1, daynumber,hrnumber,minr,secnr)
        //console.log(newdate)
        return newdate
        }
      else{
        return null
      }
},
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      GetClientWikiTabs(){
        let menuitem =  {
              name: 'My Products',
              ID: 1003,
              Children: []
          }
           db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('ClientWikiTables').onSnapshot(res => {
                  let listlength = res.docs.length
                  if(listlength === 0){
                    this.items.push(menuitem)
                  }
                  const changes = res.docChanges();
                  changes.forEach(change => {
                      if(change.type === 'added'){
                        let obj = {
                          ...change.doc.data(),
                          id: change.doc.id
                        }
                        obj.WikiData = []
                        obj.collectionname = obj.id.split('_').join('').toLowerCase()+'wiki'
                        obj.ClientField = obj.ClientLookupField.Name
                        obj.Clientid = obj.ClientField+'id'
                        obj.ClientValueField = obj.ClientLookupField.LookupFieldName
                        db.collection(obj.collectionname).where(obj.Clientid,'==',this.userLoggedIn.id).onSnapshot(wikires => {
                          let wikilength = wikires.docs.length
                          if(wikilength === 0){
                            menuitem.Children.push(obj)
                            if(menuitem.Children.length === listlength){                              
                              this.items.push(menuitem)
                            }
                          }
                          const wikichanges = wikires.docChanges();
                          wikichanges.forEach(wikichange => {
                            if(wikichange.type === 'added'){
                              let wikiobj = {
                                ...wikichange.doc.data(),
                                id: wikichange.id
                              }
                              obj.WikiData.push(wikiobj)
                            }
                            if(obj.WikiData.length === wikilength){
                              menuitem.Children.push(obj)
                              if(menuitem.Children.length === listlength){
                                this.items.push(menuitem)
                              }
                            }
                          })
                        })
                      }
                  });
           })
          // WikiData
      //   this.GetClientTopics()
      // this.GetClientWikiTabs()
//       <div v-if="Activesession.name === 'Vehicles'">
//   <v-list>
//     <v-list-item v-for="vehicle in MyVehicles" :key="vehicle.itemObjKey" :to="'/VehicleApplication-Wiki/'+vehicle.Name">
//       {{vehicle.Name}}
//     </v-list-item>
//   </v-list>
// </div>
      //My Purchases
      //ClientWikiTables
      // {
      //               name: 'My Products',
      //               ID: 1003,
      //               Children: [
      //               ]
      //           },
      },
      GetClientTopics(){
        //console.log(this.SystemEntities)
        let menuobj =  {
                    name: 'Notifications',
                    ID: 1004,
                    Children: []
                }
                this.items.push(menuobj)
                let menuitem = this.items.find(obj => obj.ID === 1004)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('LiaisonTopics').onSnapshot(topicres => {
              // alert('boohooo')
                    const topicchanges = topicres.docChanges(); 
                    let topicslength = topicres.docs.length 
                      if(topicslength === 0){
                        // this.items.push(menuitem)
                      }
                    
                    if(topicslength !== 0){
                      // let commslength = commres.docs.length                 
                      topicchanges.forEach(topicchange => {
                        if (topicchange.type === 'added') {
                          let topicchild = {
                            ...topicchange.doc.data(),
                            id: topicchange.doc.id
                          }
                          let ent = this.SystemEntities.find(obj => obj.id === topicchild.id)
                          if(ent && ent.Entity_Type){
                            topicchild.Entity_Type = ent.Entity_Type
                          }
                          menuitem.Children.push(topicchild)
                          let obj = menuitem.Children.find(item => item.id === topicchange.doc.id)
                          obj.Data = []
                          obj.NotificationCount = 0  
                              db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').where('Clientid','==',this.userLoggedIn.id).where('topicid','==',obj.id).onSnapshot(res => {
                              const changes = res.docChanges();        
                              let recordslength = res.docs.length  
                              if(recordslength === 0){
                                // menuitem.Children.push(obj) 
                                if(menuitem.Children.length === topicslength){
                                  // this.items.push(menuitem)
                                }
                              }          
                              changes.forEach(change => {
                                if (change.type === 'added') {
                                  obj.Data.push({
                                    ...change.doc.data(),
                                    id: change.doc.id
                                  })
                                  let dataobj = obj.Data.find(item => item.id === change.doc.id)
                                  dataobj.Notifications = this.Notifications.filter(notobj => {return notobj.clientrecords === dataobj.id && notobj.Read === false})
                                  obj.NotificationCount = obj.NotificationCount+dataobj.Notifications.length
                                  dataobj.ClientLiaison = []
                                  let senderquery = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('senderid','==',this.userLoggedIn.id).where('relatedid','==',dataobj.id)
                                  let recipientquery = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('recipientid','==',this.userLoggedIn.id).where('relatedid','==',dataobj.id)

                                  senderquery.onSnapshot(commres => {
                                    const comchanges = commres.docChanges();  
                                    comchanges.forEach(comchange => {
                                      if (comchange.type === 'added') {
                                            dataobj.ClientLiaison.push({
                                          ...comchange.doc.data(),
                                          id: comchange.doc.id
                                        })
                                    
                                      }
                                    })                    
                                    })
                                  recipientquery.onSnapshot(commres => {
                                    const comchanges = commres.docChanges();  
                                    comchanges.forEach(comchange => {
                                      if (comchange.type === 'added') {
                                            dataobj.ClientLiaison.push({
                                          ...comchange.doc.data(),
                                          id: comchange.doc.id
                                        })
                                    
                                      }
                                    })                    
                                    })
                                }
                              })                    
                              })                   
                        }
                      })  
                    }
                                      
                    })
        
      },

      
        comment(record,form,LiaisonUploadFile) {
          //console.log(this.Activesession)
        let NewComms = {
          description: this.input,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.Activesession.id.split('_').join(' '),
          topicid: this.Activesession.id,
        }
        if(form){
            NewComms.FormObj = form
            if(form.readonly){
              NewComms.readonly = true
            }
        }
         if(!LiaisonUploadFile){
         this.SaveNewcomms(NewComms,record)  
        }
        else{
          // console.log(this.ActiveSession)
            //first upload the file, return url and include url in NewComms
            this.UploadFileandAttahctoNewComms(NewComms,record)
        }

        this.input = null
      },
        SortClientLiaison(){
          return this.ActiveRecord.ClientLiaison.map(liase => {
            if(liase.Created_Byid){
                     let userobj = this.UsersArray.find(obj => obj.id === liase.Created_Byid)
                     if(userobj && userobj.Profile_Photo){
                         liase.ProfPic = userobj.Profile_Photo
                     }
                 }
                    liase.createdon = liase.Created_On.toDate()
                    liase.TimestampSort = liase.createdon.getTime()
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  }).slice(0,this.slice)
        },
        ActivateActiveRecord(record){
            this.ActiveRecord = Object.assign({},record)
            this.ActiveRecord.Notifications.map(not => {
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).update({
                Read: true
              })
            })
            
            // this.storedrawer = false
        },
        ActivateSession(item){
            //console.log(item,this.$route.name)
          if(item.name === 'My Suites'){
            this.$router.push('/MySuiteTemplates')
          }
          else if(this.$route.name !== 'MyAccount'){
            //console.log('pushin back')
            this.$router.push('/MyAccount')
          }
          setTimeout(() => {
            //console.log('selecting')
            this.AssignSocialItemInteractMethod('ActivateSession',item)
          }, 50);
            
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        //console.log(this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        },
        AssignViewbyOptions(obj){
        //console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
        ToggleVehiclesWikiMode(boolean){
            // this.VehiclesWikiMode = boolean
            this.$router.push({ name: 'VehicleClassifieds', params: {DefaultView: 'ClassifiedsView',ViewType: this.VehiclesWikiViewType,LinksGroupsearch: this.VehiclesLinksGroupsearch }})
        }
    }
}
</script>

<style>

</style>