<template>
  <div style="padding-bottom:50px;margin-top: -12px;">
    <v-dialog max-width="400" v-model="NewPageDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewPage.PublishType}} Page
              </v-card-title>
              <v-card-text>
                 <v-text-field v-model="NewPage.Name" label="Name" />
                <!-- <v-text-field v-model="NewPage.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="NewPage.PageIcon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
                    <ContentEditableField style="padding:15px;"
                       :FieldObject="NewPage" :FieldName="'Overview'"
                       :FieldValue="NewPage.Overview" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                 
                    <v-select v-if="EditedTab && System[EditedTab.CategorySystemProp] && EditedTab.CatNavList" clearable :multiple="!System[EditedTab.CategorySystemProp].HasDeepHierarchy" label="Primary Category" item-text="Name" v-model="EditedTab.PrimaryCategoryFilter" :items="EditedTab.ListPrimaryField.Options" return-object>
                    </v-select>
                    <v-select v-if="EditedTab && System[EditedTab.CategorySystemProp] && EditedTab.CatNavList && EditedTab.PrimaryCategoryFilter"
                    clearable :multiple="!System[EditedTab.CategorySystemProp].HasDeepHierarchy" label="Secondary Category" item-text="Name" v-model="EditedTab.SecondaryCategoryFilter" :items="EditedTab.PrimaryCategoryFilter.Options" return-object></v-select> 
                  <v-select v-if="NewPage.CatNavList  === 'Systems'" 
                  clearable label="Primary Category" item-text="Name" v-model="NewPage.Primary_Category" :items="SiteCategoriesforPackages" return-object>
                    </v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewPageDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewPage()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
     <v-dialog width="400" v-model="MonetizationPackageDialog" persistent>
      <v-card flat tile>
        <v-card-title>{{editedSubscriptionPackage.Type ? editedSubscriptionPackage.Type.Name+ ' Configuration' : 'Configuration'}}
        <v-btn absolute right  icon fab @click="CancelMonetizaitonPackageDialog()" >
          <v-icon class="soloactionicon" dark color="red">mdi-close
          </v-icon>
        </v-btn>
        </v-card-title>
        <v-tabs>
          <v-tab>Basic</v-tab>
          <v-tab-item>
            <v-card-text class="background">
              
          <v-select
            v-model="editedSubscriptionPackage.PaymentSchedule"
            label="Payment Schedule"
            :items="['Monthly', 'Annual','Lifetime']"
          />
          <v-text-field label="Name" v-model="editedSubscriptionPackage.Name">
          </v-text-field>
          <v-checkbox dense
            v-model="editedSubscriptionPackage.Hidden"
            label="Hide from Views" 
          />
          
           <v-text-field v-if="AppMembershipType || SiteListingType || AdvertDirectoryView"
            v-model="editedSubscriptionPackage.SubText" 
            label="Sub Text" 
          />
          <v-autocomplete placeholder="Option Plugin" outlined dense v-if="AdvertDirectoryView"
          label="Select option plugin" item-text="id" v-model="editedSubscriptionPackage.Pluginid" :items="PluginOptions"/>
            <v-autocomplete placeholder="Plugin Template" outlined dense  v-if="AdvertDirectoryView"
          label="Select plugin template" item-text="id" v-model="editedSubscriptionPackage.Templateid" :items="PluginTemplates"><template v-slot:item="{ item }">
                    <v-list-item-content>
                            <v-list-item-title>{{item.Name}} <v-chip small outlined>{{item.Category.Name}}</v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{item.id}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-html="item.Description">{{item.Description}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                </template>
                <template v-slot:selection="{ item }">
                    <span >{{item.Name}}</span>
                </template>
                 </v-autocomplete>
           <v-list-item v-if="TypeAllowsSuitePluginOptions">
            Value of {{CurrencyFormatter(SuitePluginValue,$store.state.DefaultCurrency.Currency)}}
          </v-list-item>
          <v-text-field
            label="Price"
            type="number"
            v-model.number="editedSubscriptionPackage.Price"
          >
          </v-text-field>
            <v-text-field v-if="AppMembershipType || SiteListingType"
            v-model="editedSubscriptionPackage.PriceFootnote" 
            label="Price Footnote" 
          />
          <ContentEditableField style="padding:15px;"
                       :FieldObject="editedSubscriptionPackage" :FieldName="'Description'"
                       :FieldValue="editedSubscriptionPackage.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        <!-- editedSubscriptionPackage.Type.Name === 'Group Directory' -->
         <!-- <v-btn @click="AddKeyPoints(editedSubscriptionPackage)">Add KeyPoint</v-btn> -->
        <v-list-group
          :value="true"
          no-action v-if="AppMembershipType && PackageKeyPoints && !RefreshingKeyPoints"
          
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Key Points</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item style="padding-left: 0px;"
            v-for="(kp,kpindex) in PackageKeyPoints"
            :key="kp.itemObjKey"
            
          >
            <v-list-item-title>
              <v-text-field v-model="kp.title" label="Title"/>
            </v-list-item-title>

            <v-list-item-icon>              
              <v-icon>{{kp.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-action >
              <v-switch v-model="editedSubscriptionPackage.KeyPoints[kpindex].Active"/>
              <!-- <v-btn @click="ProcessDeleteKeyPoint(item)" fab icon><v-icon>mdi-delete-forever</v-icon> -->
              <!-- </v-btn> -->
            </v-list-item-action>
          </v-list-item>
        </v-list-group>       
           
            </v-card-text>
          </v-tab-item>
          <v-tab>Styling</v-tab>
          <v-tab-item>
            
            <v-list-item v-if="AppMembershipType || SiteListingType">
             <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined style="width:100%;" v-on="on">Preview</v-btn>
                  </template>
                  <v-card  height="100%" tile>
                    <SignupPackages :System="System" :SystemEntities="SystemEntities" :ViewOnly="true"
                     :SignUpLabel="'Sign Up/ Select'"
                    :SubscriptionPackages="[editedSubscriptionPackage]"/>
                  </v-card>
              </v-menu>
            </v-list-item>
            <v-list>
              <v-list-item>
            <v-switch v-if="AppMembershipType || SiteListingType"
            v-model="editedSubscriptionPackage.Ribbon" 
            label="Ribbon" 
          />
          <v-text-field style="padding-left:5px;" v-if="AppMembershipType && editedSubscriptionPackage.Ribbon || 
          SiteListingType && editedSubscriptionPackage.Ribbon"
            v-model="editedSubscriptionPackage.RibbonText" 
            label="Ribbon Text" 
          />
              </v-list-item>
            </v-list>
          <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-list-item v-on="on">		
                    <v-list-item-avatar size="25" :style="editedSubscriptionPackage.Color ? 'background-color: '+editedSubscriptionPackage.Color.hexa+';' : 'background-color: #FF0000;'">	
                    </v-list-item-avatar>
                     Card Color			
                  </v-list-item>
                  </template>
                   <v-color-picker v-if="AppMembershipType || SiteListingType" v-model="editedSubscriptionPackage.Color"></v-color-picker>  	
                </v-menu>
          </v-tab-item>
          <v-tab>Access</v-tab>
          <v-tab-item>
        <v-card-text class="background">
          <v-select v-if="AdvertDirectoryView"
            v-model="editedSubscriptionPackage.CategoryFilter" item-text="Name"
            label="Advert Cat" return-object
            :items="AllAdvertCategories"
          />
          <v-select v-if="TypeAllowsSiteContent"
            v-model="editedSubscriptionPackage.Scope"
            label="Site Content Scope" multiple
            :items="SiteContentPackageScopeOptions"
          />
          <v-select v-if="TypeAllowsGroupOptions"
            v-model="editedSubscriptionPackage.ContentScope"
            label="Group Content Scope" multiple
            :items="GroupContentPackageScopeOptions"
          />
         
          <v-select v-if="AppMembershipType"
            v-model="editedSubscriptionPackage.MemberGroup" item-text="Name"
            label="Member Group" return-object
            :items="MemberGroupsArray"
          />
          
          <v-select v-if="TypeAllowsSuitePluginOptions"
            v-model="editedSubscriptionPackage.Plugins"
            label="Plugins" multiple item-text="Name" return-object
            :items="editedSubscriptionPackage.Module.Features"
          />
          <v-select v-if="TypeAllowsGroupOptions"
            v-model="editedSubscriptionPackage.FeatureScope"
            label="Group Features" multiple
            :items="GroupFeaturePackageScopeOptions"
          />
          <!-- Okay so I could create a Directory package for a specific category, in which case I return items with matching package category
          I could also have no category. But in this case I think I should return those withiout prefilter...
          this way when I open my respective group I can only see packages applicable to my category or those applicable to all. Right?
          The reason I add the filter on otion though is to prevent the dude setting up monetizaiton from screwing it up and mixing Directories with wrong packages -->
           <v-select v-if="TypeAllowsGroupDirectoryOptions"
            v-model="editedSubscriptionPackage.CategoryFilter" item-text="Name"
            label="Group Dir Cat" return-object
            :items="GroupCategoriesforPackages"
          />
           <v-select v-if="TypeAllowsGroupDirectoryOptions"
            v-model="editedSubscriptionPackage.DirectoryPages" item-text="id"
            label="Group Dir" multiple return-object
            :items="GroupDirectoryPagesforPackages"
          />
          <v-select v-if="SiteListingType"
            v-model="editedSubscriptionPackage.CategoryFilter" item-text="Name"
            label="Dir Cat" return-object clearable
            :items="SiteCategoriesforPackages"
          />
           <v-select v-if="SiteListingType" clearable
            v-model="editedSubscriptionPackage.DirectoryPages" item-text="id"
            label="Sites Dir" multiple return-object
            :items="SiteDirectoryPagesforPackages"
          />
           <v-text-field v-if="TypeAllowsGroupDirectoryOptions && 
            System.Group_Keywords_Paid" label="KeyWords Included" type="number" v-model.number="editedSubscriptionPackage.KeywordsIncluded">
          </v-text-field>
          <v-select v-if="TypeAllowsGroupOptions"
            v-model="editedSubscriptionPackage.PaidCategoryScope"
            label="Paid Group Categories" multiple item-text="Name"
            :items="ComputedGroupPaidCategories" return-object
          />
          <v-list class="transparent" dense v-for="plug in ConfigBasedPlugins" :key="plug.itemObjKey">
                <v-list-item v-for="prop in plug.Config_Options" :key="prop.itemObjKey">              
                  <span v-if="prop.Type === 'Boolean'">
                      <v-switch  class="smallinput" v-model="prop.Response" :label="prop.Label"></v-switch></span>
                      <span v-if="prop.Type === 'Single Line Text'">
                      <v-text-field class="smallinput"  v-model="prop.Response" :label="prop.Label"></v-text-field></span>
                      <span v-if="prop.Type === 'Number Field'">
                      <v-text-field :rules="[$store.state.formrules.numberfield]" v-model.number="prop.Response" type="number" :label="prop.Label"></v-text-field></span>
                </v-list-item>	
          </v-list>
          <v-list-item dense v-for="plug in VoucherBasedPlugins" :key="plug.itemObjKey">
           <v-text-field dense :label="plug.Name +' Qty'" type="number" v-model.number="plug.Qty">
            </v-text-field>
          </v-list-item>
          
         
        </v-card-text>
          </v-tab-item>
          <v-tab v-if="TypeAllowsGroupOptions">Monetization</v-tab>
          <v-tab-item v-if="TypeAllowsGroupOptions">
            <v-card-text>
              <v-list class="transparent">
                <v-list-item>
                  <v-switch @change="SaveMonetizationPackage(true)" label="Can Monetize Content" v-model="editedSubscriptionPackage.Can_Monetize_Content"/>
                </v-list-item>
                <v-list-item v-for="content in editedSubscriptionPackage.GroupContentMonetization" :key="content.itemObjKey">
                  <v-list-item-content>
                    {{content.Name}}
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-switch label="Can Monetize" v-model="content.Can_Monetize"/>
                  </v-list-item-content>
                   <v-list-item-content>
                    <v-switch label="Exempt Royalties" v-model="content.Exempt_Royalties"/>
                  </v-list-item-content>
                </v-list-item>
                
              </v-list>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
        <v-card-actions>
          <!-- <v-btn @click="CancelMonetizaitonPackageDialog()" dark color="orange"
            >Cancel
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn @click="SaveMonetizationPackage()" dark color="green"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!--      
    <v-list>
      actualy monetizaiton needs to have a navbar to the left, that can be toggled on MiniView. The navbar needs to guide you and the navbar must have breadcrums or something chevron like. Drilldown applies, top menu with children
      We need
      <v-list-item>
        Store - quick setup various stores
      </v-list-item>
      <v-list-item>
        Groups 
      </v-list-item>
      <v-list-item>
        GRoup Directories- quick setup pages for groups or featuredmembers 
      </v-list-item>
      <v-list-item>
        Members Directories- quick setup pages for groups or featuredmembers 
      </v-list-item>
      <v-list-item>
        Content - setup monetizaiton on content, or create new content type based entities
      </v-list-item>
    </v-list> -->
    <div class="justify-center">
      <v-layout  column style="position: fixed;
  bottom: 120px;
  right: 10px;
  z-index: 50" v-if="MiniView">
    <v-btn fab @click="NavbarActive = !NavbarActive" dark color="links">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
      </v-layout>
        <v-navigation-drawer app right :permanent="!MiniView" :style="MiniView && NavbarActive ? 'position: fixed;  ' : ''" v-model="NavbarActive">
      <v-layout style="padding-right:10px;margin-top: 60px;" align-center>
      <!-- <div class="cloudbg" :style="NavbarStyling"/> -->
      <!-- <v-flex class="mt-5 hidden-sm-and-down"> -->
        <v-flex xl6>
          <v-tooltip bottom max-width="200">
          <template v-slot:activator="{ on, attrs }">
          <v-progress-circular v-on="on" v-bind="attrs"
      :rotate="360"
      :size="100"
      :width="15"
      :indeterminate="!ActiveViewItem" 
      :value="progressvalue"
      color="links"
    >
    {{ActiveViewItem ? ActiveViewItem.StepsCompleted+'/'+ActiveViewItem.Steps : ''}}
    </v-progress-circular>
          </template>
          <span v-html="ActiveViewItem ? ActiveViewItem.Tootlip : 'Select an item'">
          </span>
          </v-tooltip>
        </v-flex>
         <v-flex xl6>
          Let's set up your Monetization
        </v-flex>
      </v-layout>
      <v-list>        
        <v-divider></v-divider>
        <div v-for="link in ComputedNavbarItems" :key="link.title">
          <v-list-item active-class="dbnavlist--text" v-if="!link.Children || link.Children.length<0" @click="ActivateView(link)">
          <v-list-item-action>
          <v-icon  color="accent">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="navoverline"  >{{ link.title}}
            </v-list-item-title>
            </v-list-item-content>
             <v-list-item-action v-if="link.Page">
              <v-btn small fab tile class="primary"><v-icon class="secondary--text">mdi-sitemap
           </v-icon></v-btn>
           </v-list-item-action>
          </v-list-item>
            <v-list-group active-class="dbnavlist--text"
              v-if="link.Children && link.Children.length"
              >
                <template v-slot:activator>
                  <v-list-item-action>
                  <v-icon  color="accent">{{ link.icon }}</v-icon>
                </v-list-item-action>
                  <v-list-item-title class="navoverline">{{link.title}}</v-list-item-title>
                </template>
                <div v-for="subsub in link.Children"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title !== 'Dashboard Builder'"
                         active-class="dbnavlist--text"
                          @click="ActivateView(subsub)"
                    >          
                    <v-list-item-action>
                     <v-icon  color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title === 'Dashboard Builder' "
                        
                        @click="OpenDashboardBuilder()"
                    >          
                    <v-list-item-action>
                    <v-icon  color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-group
                    sub-group active-class="dbnavlist--text"
                      v-if="subsub.Children && subsub.Children.length"
                      >
                        <template v-slot:activator>
                          <!-- <v-list-item-action>
                         <v-icon  color="accent">{{ subsub.icon }}</v-icon>
                        </v-list-item-action> -->
                          <v-list-item-title class="navoverline">{{subsub.title}}</v-list-item-title>
                        </template>
                        <v-list-item class="mx-3" v-for="subchild in subsub.Children" :key="subchild.itemObjKey"
                         @click="ActivateView(subchild)"
                      >          
                      <v-list-item-action>
                      <v-icon  color="accent">{{ subchild.icon }}</v-icon>
                          
                        </v-list-item-action>                          
                          <v-list-item-title class="navoverline">{{ subchild.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                </div>
              </v-list-group>
          </div>
      </v-list>
        </v-navigation-drawer>
        <!-- <v-main :class="!MiniView ? 'v-main__wrap' : ''" style="padding-bottom: 0px;"> -->
          <!-- <div :class="NavbarActive ? 'flex xl10 lg9 md8 sm7 xs12': ''"> -->
       <div :class="$route.name === 'Monetization' ? 'v-main' : ''" >
          <v-card-subtitle v-if="ActiveViewItem" class="largeoverline recordtoolbar white--text justify-center">
              <v-icon dark large>{{ActiveViewItem.icon}}</v-icon>{{ActiveViewItem.title}}
            </v-card-subtitle>
         <v-layout class="justify-center background">
           
         <div v-if="SiteGeneralView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
           
            <v-card elevation="0" class="siteconfigcard">
              <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Royalties and Payouts
            </v-card-subtitle>
            <v-card-text >
                <v-dialog v-model="SystemCurrencyDialog"
                transition="scale-transition"
                width="400"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" class="listoutline">
                    <v-list-item-title  class="subtleoverline">
                      Update Currency
                    </v-list-item-title>
                  <v-list-item-action>
                      <v-icon class="actionicon" color="pop">mdi-cogs</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="recordtoolbar white--text">
                      System Currency
                    </v-card-title>
                    <v-card-text>
                      <v-select label="Currency" return-object :items="$store.state.Currencies" item-text="Currency" v-model="SystemDefaultCurrency"/>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="CancelSystemCurrencyDialog()" dark class="warning">Cancel</v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="UpdateSystemCurrency()" dark class="success">Save</v-btn>
                    </v-card-actions>
                  
                  </v-card>          
              </v-dialog>
              <v-list-item >
                      <v-text-field type="number" v-model.number="System.Base_App_Royalties" label="Base App Royalties (%)"/>
                </v-list-item>
                <v-list-item>
                  <v-text-field type="number" v-model.number="System.Credit_Tokens_ROE" label="Credit Tokens ROE"/>
                      
                </v-list-item>
                 <v-list-item >
                   <v-select
                    v-model="System.Payout_Schedule"
                    label="Payout Schedule"
                    :items="['Monthly', 'Quarterly','Annual']"
                  />
                </v-list-item>
                  <v-list-item >
                      <v-text-field type="number" v-model.number="System.Payout_Minimum" label="Payout Minimum"/>
                </v-list-item>
               
                
                <v-list-item>
                  <v-btn small @click="SaveROEandRoyalties()">Save Royalties and Payouts</v-btn>
                </v-list-item>
            </v-card-text>
            </v-card>
            <v-card elevation="0" class="siteconfigcard">
              <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Invoicing Business Unit
            </v-card-subtitle>
            <v-card-text>
              <FieldsForm :FormFields="[Company_Address]" :Validating="Validating" :DataTableView="false"
                @UploadFileSelect="UploadFileSelect" :DataObject="System" :tab="{}" />
                <v-list-item>
                  <v-text-field v-model="System.Company_Name" label="Company Name"/>                
                </v-list-item>
                <v-list-item>
                  <v-text-field :readonly="TaxReadOnly" type="number" v-model.number="System.Tax_Percentage" label="Tax Percentage"/>                
                </v-list-item>
                <v-list-item>
                  <v-select item-text="Name" return-object :items="MonetizationBusinessUnits" v-model="System.Monetization_BU" label="Monetization Business Unit"/>                
                </v-list-item>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="UpdateBU()">Update Settings</v-btn>
            </v-card-actions>
            </v-card>
         </div>
          <div v-if="ContentMonetizationView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
           <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list>
          <v-btn @click="ActivateMonetizationPackagesDialog('SiteMonetizationPackages','SiteMonetizationPackagesInteger','Site Content')">
            Add Package
          </v-btn>
         
          <v-list-item
            v-for="(item, index) in SiteMonetizationPackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('SiteMonetizationPackages','SiteMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
        
      </v-card-text>
    </v-card>
       
   <v-card elevation="0" class="siteconfigcard">
       <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Content
            </v-card-subtitle>
      <v-card-text style="padding-top: 10px">
          <v-btn @click="SaveSiteContentMonetization()"> Save </v-btn>
        <v-list-item class="transparent"
          dense
          v-for="item in ComputedSiteContentTypes"
          :key="item.itemObjKey"
        >
          <v-list-item-content>
            {{ item.Name }}
          </v-list-item-content>
          <v-list-item-action>
            <v-select
              dense
              :label="item.PropLabel"
              v-model="System[item.Prop]"
              :items="item.Options"
            />
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
          </div>
          <div v-if="GroupsMonetizationView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
          <!-- we had Group Features in here but it belongs under Group Directories, it's base don the principle that "for Group Directories, here is what is paid"
              1. Whether a Group does or does not pay for "articles" applies to "all groups" and is set up in GroupDirectoriesView further below, then allowed by package.
              2. Whether a group can or can not monetize off of channels is also allowed by package but there is no "system value". It's wholly dynamic,
               configured in editedSubscriptionPackage.Can_Monetize_Content making it hard to trace but it works
              3. This component and this GroupsMonetizationView is the configuration component. Take note thus, the "GroupsMonetization" component is in fact crucial, but it is nothing but a dashboard -->
    <v-card width="100%" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Group Scope
            </v-card-subtitle>
      <v-card-text
       
        v-if="GroupCategories && GroupCategories.length > 0"
      >
       <v-btn @click="SaveGroupContentScopePaidOptions()"> Save </v-btn>
            
             <v-list-item
                    dense
                    v-for="item in ComputedGroupContentTypes"
                    :key="item.itemObjKey"
                    >
                    <v-list-item-content>
                        {{ item.Name }}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-select
                        dense
                        :label="item.PropLabel"
                        v-model="System[item.Prop]"
                        :items="item.Options"
                        />
                    </v-list-item-action>
                    </v-list-item>
      </v-card-text>
    </v-card>
    
    <v-card width="100%" class="siteconfigcard">
       <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list>
          <v-btn @click="ActivateMonetizationPackagesDialog('GroupMonetizationPackages','GroupMonetizationPackagesInteger','Group Ownership')">
            Add Package
          </v-btn>
          <v-list-item
            v-for="(item, index) in GroupMonetizationPackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('GroupMonetizationPackages','GroupMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card width="100%" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Categories
            </v-card-subtitle>
      <v-card-text
       
        v-if="GroupCategories && GroupCategories.length > 0"
      >
        <v-list>
          <v-btn @click="SaveGroupCategoryInformation()"> Save </v-btn>
          
          <v-list-item
            v-for="cat in GroupCategories"
            :key="cat.itemObjKey"
          >
            <v-list-item-content>
              {{ cat.Name }}
            </v-list-item-content>
            <v-list-item-action>
                  <v-checkbox v-model="cat.IsPaid" label="Is Paid" />
                </v-list-item-action>
            <v-list-item-action>
              <v-select v-if="!cat.IsPaid"
                v-model="cat.MonetizationPackages"
                multiple
                item-text="id"
                :items="ComputedGroupMonetizationPackages"
                label="Proposed Options (on Create)"
              >
                <template v-slot:item="{ item }">
                  {{ item.Name }}
                </template>
              </v-select>
              <v-select v-if="cat.IsPaid"
                v-model="cat.MonetizationPackages"
                multiple
                item-text="id"
                :items="GetPaidGroupMonetizationPackages(cat)"
                label="Proposed Options (on Create)"
              >
                <template v-slot:item="{ item }">
                  {{ item.Name }}
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.Name }}
                </template>
              </v-select>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
          </div>
          
          <div v-if="MemberGroupsView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
             <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list>
          <v-btn @click="ActivateMonetizationPackagesDialog('SuitePackages','SiteMonetizationPackagesInteger','App Membership')">
            Add Package
          </v-btn>
         
          <v-list-item
            v-for="(item, index) in AppMembershipPackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('SuitePackages','SiteMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle  class="mediumoverline">
                Key Points
            </v-card-subtitle>
      <v-card-text>
    <v-list>
          <v-btn @click="AddKeyPoints()">
            Add KeyPoint
          </v-btn>
          <v-list-item v-for="kp in PackageKeyPoints" :key="kp.itemObjKey">
            <v-list-item-content>
              {{ kp.title }}
            </v-list-item-content>
            <v-list-item-action>
              <v-menu :close-on-content-click="false"                            									
                  transition="scale-transition"   >
                  <template v-slot:activator="{ on }">
                    <v-btn fab icon v-on="on">
                    <v-icon >mdi-cogs</v-icon>
                    </v-btn>
                  </template>
                  <v-card tile flat>
                    <v-card-text>
                      <v-list-item style="padding-left: 0px;"
                      >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field v-model="kp.title" label="Title"/>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{kp.title}}
                          </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field v-model="kp.icon" label="Icon"/>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                           <v-icon>{{kp.icon}}</v-icon>
                          </v-list-item-subtitle>
                      </v-list-item-content>
                        <!-- <v-list-item-icon>   
                          <v-text-field v-model="kp.icon" label="Icon"/>           
                          <v-icon>{{kp.icon}}</v-icon>
                        </v-list-item-icon> -->
                        <!-- <v-list-item-action>
                          <v-btn @click="ProcessDeleteKeyPoint(kp)" fab icon><v-icon>mdi-delete-forever</v-icon>
                          </v-btn>
                        </v-list-item-action> -->
                        <v-list-item-action>
                          <v-btn @click="updateKeyPoint(kp)">
                            Save
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
              </v-menu>
            </v-list-item-action>
            <v-list-item-action>
            <v-btn @click="ProcessDeleteKeyPoint(kp)" fab icon><v-icon>mdi-delete-forever</v-icon>
                          </v-btn>
            </v-list-item-action>
          </v-list-item>
          </v-list>
      </v-card-text>
      </v-card>
          </div>
          <div v-if="WebAppBuilderView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
              <v-card width="100%" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Features
            </v-card-subtitle>
      <v-card-text
       
        v-if="SiteCategories && SiteCategories.length > 0"
      >
      <v-btn @click="SaveGroupFeaturePaidOptions()"> Save </v-btn>
      <!-- GroupFeatures: ['Web Page Builder', 'Web Forms','Group Teams,'Team Dashboard','Custom Entities','Custom Dashboards'] -->
             <v-list-item v-if="SiteCategories && SiteCategories.length === 0">
                Please remember to set up Site Categories for your Suite</v-list-item>
             <v-list-item>
                <v-list-item-content> Keywords are Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                   
                    v-model="System['Site_Keywords_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="System['Site_Keywords_Paid']">
                <v-text-field
                    dense type="number" label="Order Quantity"       
                    v-model.number="System['Site_Keywords_Order_Qty']"
                  />
                   <v-text-field
                    dense type="number" label="Order Price"
                    v-model.number="System['Site_Keywords_Order_Price']"
                  />
              </v-list-item>
              <!-- the below portion was under "GroupsMonetizationView" but it does not belong there.
              Still missing props include "Site_Articles_Paid" which basically means, group does or does not need to pay to ahve "Articles"...
              And this belongs under GroupDirectories, all these props check the GroupSubscriptionPackage.-->
              <v-list-item>
                <v-list-item-content> Web Page Builder is Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                   
                    v-model="System['Site_Web_Page_Builder_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
               <!-- I actually don't see any value in configured anything except keywords 
                PERHAPS webpagebilderpage - deteremines if the base website builder plugin price applies or not. But we zeroed it out so, yeah N/A-->
      </v-card-text>
    </v-card>
             <v-card width="100%" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Pages
            </v-card-subtitle>
            <v-card-text>
              <v-list>
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">Add Page</v-btn>
                    <v-icon  v-on="on" color="white">mdi-dots-vertical</v-icon>
                  </template>
                    <v-list>
                      <v-list-item v-for="opt in NewPageOptions" :key="opt.itemObjKey" @click="ToggleNewPageDialog(opt.SecondProp,'Sites Directory')">
                       <v-icon>{{opt.icon}}</v-icon>{{opt.SecondProp}}
                      </v-list-item>
                    </v-list>
              </v-menu>
                <!-- NewPageOptions ToggleNewPageDialog -->
                
                <v-list-item v-for="page in SitesDirectoryPages" :key="page.itemObjKey">
                  <v-list-item-content>
                    {{page.Name}}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
             </v-card>
             <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list>
          <v-btn @click="ActivateMonetizationPackagesDialog('SitesDirectoryMonetizationPackages','SiteMonetizationPackagesInteger','Sites Directory')">
            Add Package
          </v-btn>
         
          <v-list-item
            v-for="(item, index) in SitesDirectoryMonetizationPackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('SitesDirectoryMonetizationPackages','SiteMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
           </div>
           <div v-if="AdvertDirectoryView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
          
             <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list>
          <v-btn @click="ActivateMonetizationPackagesDialog('AdvertMonetizationPackages','SiteMonetizationPackagesInteger','Advert Package')">
            Add Package
          </v-btn>
         
          <v-list-item
            v-for="(item, index) in AdvertMonetizationPackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('AdvertMonetizationPackages','SiteMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
           </div>
           <div v-if="GroupDirectoriesView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
              <v-card width="100%" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Features
            </v-card-subtitle>
      <v-card-text
       
        v-if="GroupCategories && GroupCategories.length > 0"
      >
      <v-btn @click="SaveGroupFeaturePaidOptions()"> Save </v-btn>
      <!-- GroupFeatures: ['Web Page Builder', 'Web Forms','Group Teams,'Team Dashboard','Custom Entities','Custom Dashboards'] -->
             <v-list-item v-if="GroupCategories && GroupCategories.length === 0">
                Please remember to set up Group Categories for your Suite</v-list-item>
             <v-list-item>
                <v-list-item-content> Keywords are Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                   
                    v-model="System['Site_Keywords_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="System['Group_Keywords_Paid']">
                <v-text-field
                    dense type="number" label="Order Quantity"       
                    v-model.number="System['Group_Keywords_Order_Qty']"
                  />
                   <v-text-field
                    dense type="number" label="Order Price"
                    v-model.number="System['Group_Keywords_Order_Price']"
                  />
              </v-list-item>
              <!-- the below portion was under "GroupsMonetizationView" but it does not belong there.
              Still missing props include "Group_Articles_Paid" which basically means, group does or does not need to pay to ahve "Articles"...
              And this belongs under GroupDirectories, all these props check the GroupSubscriptionPackage.-->
              <v-list-item>
                <v-list-item-content> Web Page Builder is Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                   
                    v-model="System['Group_Web_Page_Builder_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
               <v-list-item>
                <v-list-item-content> Web Forms are Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                    
                    v-model="System['Group_Web_Forms_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
               <v-list-item>
                <v-list-item-content> Group Teams Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                    
                    v-model="System['Group_Teams_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content> Team Dashboard Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                    
                    v-model="System['Group_Team_Dashboard_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
               <v-list-item>
                <v-list-item-content> Custom Entities are Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                    
                    v-model="System['Group_Custom_Entities_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
               <v-list-item>
                <v-list-item-content> Custom Dashboards are Paid</v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    dense 
                    
                    v-model="System['Group_Custom_Dashboards_Paid']"
                  />
                </v-list-item-action>
              </v-list-item>
      </v-card-text>
    </v-card>
             <v-card width="100%" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Pages
            </v-card-subtitle>
            <v-card-text>
              <v-list>
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">Add Page</v-btn>
                    <v-icon  v-on="on" color="white">mdi-dots-vertical</v-icon>
                  </template>
                    <v-list>
                      <v-list-item v-for="opt in NewPageOptions" :key="opt.itemObjKey" @click="ToggleNewPageDialog(opt.SecondProp,'Groups Directory')">
                       <v-icon>{{opt.icon}}</v-icon>{{opt.SecondProp}}
                      </v-list-item>
                    </v-list>
              </v-menu>
                <!-- NewPageOptions ToggleNewPageDialog -->
                
                <v-list-item v-for="page in GroupDirectoryPages" :key="page.itemObjKey">
                  <v-list-item-content>
                    {{page.Name}}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
             </v-card>
             <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list>
          <v-btn @click="ActivateMonetizationPackagesDialog('GroupDirectoryMonetizationPackages','SiteMonetizationPackagesInteger','Group Directory')">
            Add Package
          </v-btn>
         
          <v-list-item
            v-for="(item, index) in GroupDirectoryMonetizationPackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('GroupDirectoryMonetizationPackages','SiteMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
           </div>
           <!--  v-if="SuiteBuilderView" for below?? -->
           <div v-if="SuiteBuilderView" style="padding-top: 30px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
              <v-card width="100%" class="siteconfigcard">
                <v-card-subtitle v-if="ActiveViewItem" class="mediumoverline">
                        Modules
                    </v-card-subtitle>
              <v-card-text
              >
                <v-list>
                  <v-list-item @click="ActivateSuiteModule(mod)" v-for="mod in ComputedSuiteModules" :key="mod.itemObjKey">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{mod.Name}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <!-- <v-list-item-action>
                      <v-btn>Go</v-btn>
                    </v-list-item-action> -->
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
             <v-card width="100%" class="siteconfigcard">
            <v-card-subtitle v-if="ActiveViewItem && ActiveSuiteModule" class="mediumoverline">
                Features (per Module)
            </v-card-subtitle>
            <v-card-text>
               <!-- <v-btn  @click="ToggleNewPluginDialog()">Add Plugin<v-icon  color="white">mdi-dots-vertical</v-icon></v-btn> decided Plugins are Features, and Features are static  -->
               <v-list dense class="transparent">
                 <v-list-item dense class="settingsfont" v-for="feat in ActiveSuiteModule.Features" :key="feat.itemObjKey">
                  <v-list-item-content>
                      <v-list-item-title class="settingsfont" >
                      <v-icon small style="padding: 5px;">{{feat.Icon}}</v-icon>{{feat.Name}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn :to="'/Plugin/'+feat.id">Go</v-btn>
                      <!-- possibly this just a dialog reminding you of what the feature trulyt is -->
                    </v-list-item-action>
                 </v-list-item>
               </v-list>                  
            </v-card-text>
             </v-card>
             <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="ActiveSuiteModule" class="mediumoverline">
                Packages
            </v-card-subtitle>
      <v-card-text>
        <v-list v-if="ActiveSuiteModule">
          <v-btn @click="ActivateMonetizationPackagesDialog('SuitePackages','SiteMonetizationPackagesInteger','Suite Plugin Package')">
            Add Package
          </v-btn>
         
          <v-list-item
            v-for="(item, index) in SuitePackages"
            :key="item.itemObjKey"
          >
            <v-list-item-content>
              {{ item.Name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon @click="ActivateMonetizationPackagesDialog('SuitePackages','SiteMonetizationPackagesInteger',item.Type,item, index)"
                >mdi-cogs</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
           </div>
         </v-layout>
       </div>
    </div>
   
    
   
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import FieldsForm from '@/components/Database/Fields/FieldsForm';	
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'	
export default {
  props: ["System", "SystemEntities",'SubscriptionPackages','SitePages','Directories'],
  components: {ContentEditableField,FieldsForm,SignupPackages},
  data() {
    return {
      PackageKeyPointsIndex: 1000000,
      PackageKeyPoints: [],
      RefreshingKeyPoints: false,
      EditedTab: '',
      PrimaryCategoryFilter: '',
      SecondaryCategoryFilter: '',
      BlankPageRow: {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'blank',									
            Elements: [],									
            ShowComponents: true,									
            FlexXLRG:11,									
            FlexLarge: 11,									
            FlexMedium: 11,									
            FlexSmall: 12,									
            FlexXSmall: 12,									
            },
      GroupsDirectoryPageElement: {
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-start',
            RowTransparency: 'transparent',
            SocialComponent: true,
            SocialCompPluginid: 'Site_Groups',
            SocialCompPublicBoolean: 'Site_Has_Groups',
            PublicPages: false,
            Name: 'Site Groups',
            Type: 'Site Groups',
            DisplayName: 'Site Groups',
            HasAlignment: true,
            SliderView: true,
            Excerpt: 250,
            Slice: 6,
            TypeCategories: [],
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-account-group',
            Title: '',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:12,
            FlexLarge: 12,
            FlexMedium: 12,
            FlexSmall: 12,
            FlexXSmall: 12,
            Cols: 12,
            },
      DefaultNewPage: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: []
          },
          NewPage: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: []
          },
          NewPageDialog: false,
      NewPageOptions: [
        {
            title: 'Create Website Page',
            icon: 'mdi-search-web',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPageDialog',
            SecondProp: 'Public'
          },
          {
            title: 'Create Social Page',
            icon: 'mdi-search-web',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPageDialog',
            SecondProp: 'Members Only'
          },
      ],
      ActiveViewItem: '',
      SiteGeneralView: false,
      ContentMonetizationView: false,
      GroupsMonetizationView: false,
      GroupDirectoriesView: false,
      MemberGroupsView: false,
      WebAppBuilderView: false,
      AdvertDirectoryView: false,
      MemberDirectoriesView: false,
      StoreSetupView: false,
      NavbarItems: [
        {title: 'Site General',ViewName: 'SiteGeneralView',icon: 'mdi-cogs',StepRequirements: [
          {Check: 1, Name: 'Confirm Currency',ParentObj: 'System',Prop: 'DefaultCurrency',Optional: true,Tootlip: `<p>The default is set to South African Rand (ZAR), but you can update the overall site currency here</p>`},
          {Check: 2, Name: 'Setup Royalties Percentage',ParentObj: 'System',Prop: 'Base_App_Royalties',Tootlip: `<p>You can earn royalties on Monetization for Groups and Group content. To turn it of change the percentage to 0.</p>`},
          {Check: 3, Name: 'Setup Token ROE',ParentObj: 'System',Prop: 'Credit_Tokens_ROE',Tootlip: `<p>When content and other purchases are made with "Credit Tokens". The conversion rate from your currency to tokens has to be set</p>`},
          {Check: 4, Name: 'Company Address',ParentObj: 'System',Prop: 'Company_Address',Tootlip: `<p>The "Company Address" is needed to have an address on the Monetizing Invoices.</p>`},
          {Check: 5, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
          {Check: 6, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
          {Check: 7, Name: 'Default Business Unit',ParentObj: 'System',Prop: 'Monetization_BU',Tootlip: `<p>Setting default Business Unit which would be managing the invoices etc, affect which debtors clerk can view it.</p>`},
          {Check: 8, Name: 'Set Payout Minimum',ParentObj: 'System',Prop: 'Payout_Minimum',Tootlip: `<p>Prevent bank charges that exceed their monetary value by setting minium payouts.</p>`},
          {Check: 8, Name: 'Set Payout Schedules',ParentObj: 'System',Prop: 'Payout_Schedule',Tootlip: `<p>Set payout Schedules.</p>`},
          ]},
        {title: 'Site Content',Required: ['Content_Monetization'],ViewName: 'ContentMonetizationView',icon: 'mdi-newspaper',StepRequirements: [
          {Check: 1, Name: 'Setup Token ROE',ParentObj: 'System',Prop: 'Credit_Tokens_ROE',Tootlip: `<p>When content and other purchases are made with "Credit Tokens". The conversion rate from your currency to tokens has to be set</p>`},
          {Check: 2, Name: 'Company Address',ParentObj: 'System',Prop: 'Company_Address',Tootlip: `<p>The "Company Address" is needed to have an address on the Monetizing Invoices.</p>`},
          {Check: 3, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
          {Check: 4, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
          ]},
        {title: 'Groups Monetization',Required: ['Groups_Monetization'],ViewName: 'GroupsMonetizationView',icon: 'mdi-account-group',StepRequirements: [
           {Check: 1, Name: 'Confirm Currency',ParentObj: 'System',Prop: 'DefaultCurrency',Optional: true,Tootlip: `<p>The default is set to South African Rand (ZAR), but you can update the overall site currency here</p>`},
          {Check: 2, Name: 'Setup Royalties Percentage',ParentObj: 'System',Prop: 'Base_App_Royalties',Tootlip: `<p>You can earn royalties on Monetization for Groups and Group content. To turn it of change the percentage to 0.</p>`},
          {Check: 3, Name: 'Setup Token ROE',ParentObj: 'System',Prop: 'Credit_Tokens_ROE',Tootlip: `<p>When content and other purchases are made with "Credit Tokens". The conversion rate from your currency to tokens has to be set</p>`},
          {Check: 4, Name: 'Company Address',ParentObj: 'System',Prop: 'Company_Address',Tootlip: `<p>The "Company Address" is needed to have an address on the Monetizing Invoices.</p>`},
          ]},
        {title: 'Group Directories',Required: ['Group_Directories'],ViewName: 'GroupDirectoriesView',icon: 'mdi-account-group',StepRequirements: [
           {Check: 1, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
          {Check: 2, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
          ]},
          {title: 'Member Groups',Required: ['Member_Groups'],ViewName: 'MemberGroupsView',icon: 'mdi-account-supervisor-circle',StepRequirements: [
           {Check: 1, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
          {Check: 2, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
          ]},
           {title: 'Sites',Required: ['Web_App_Builder'],ViewName: 'WebAppBuilderView',icon: 'mdi-folder-home-outline',StepRequirements: [
           {Check: 1, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
          {Check: 2, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
          ]},
          {title: 'Adverts',Required: ['Advert_Directory'],ViewName: 'AdvertDirectoryView',icon: 'mdi-monitor-star',StepRequirements: [
           {Check: 1, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
          {Check: 2, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
          ]},
        // {title: 'Member Directories',ViewName: 'MemberDirectoriesView',icon: 'mdi-account',StepRequirements: [
        //    {Check: 1, Name: 'Confirm Currency',ParentObj: 'System',Prop: 'DefaultCurrency',Optional: true,Tootlip: `<p>The default is set to South African Rand (ZAR), but you can update the overall site currency here</p>`},
        //   {Check: 2, Name: 'Setup Royalties Percentage',ParentObj: 'System',Prop: 'Base_App_Royalties',Tootlip: `<p>You can earn royalties on Monetization for Groups and Group content. To turn it of change the percentage to 0.</p>`},
        //   ]},
        // {title: 'Stores',ViewName: 'StoreSetupView',icon: 'mdi-warehouse',StepRequirements: [
        //   {Check: 5, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
        //   {Check: 6, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
        //   ]},
        //   {title: 'Suite Builder',ViewName: 'SuiteBuilderView',icon: 'mdi-webpack',StepRequirements: [
        //   // {Check: 5, Name: 'Company Name',ParentObj: 'System',Prop: 'Company_Name',Tootlip: `<p>The "Company Name"will be the name appearing on Monetizing Invoices. This will also set the selected Monetizing BU Company Name.</p>`},
        //   // {Check: 6, Name: 'Tax Percentage (VAT)',ParentObj: 'System',Prop: 'Tax_Percentage',Tootlip: `<p>The Tax percentages will define the "VAT" applicable on invoices.</p>`},
        //   ]}
      ],
      SuiteBuilderView: false,
      SuiteModules: [
        {Name: 'Database Builder',
        CatMatches: ['Database Builder','DBB'],
        // Features: [
        //   {Name: 'Timesheets',Icon: 'mdi-account-clock'},
        //   {Name: 'Geolocation',Icon: 'mdi-map-marker-radius-outline'},
        //   {Name: 'Activities',Icon: 'mdi-clipboard-list'},
        //   {Name: 'Digital NoticeBoard',Icon: 'mdi-bulletin-board'},
        //   {Name: 'Pipelines',Icon: 'mdi-pipe'},
        //   {Name: 'Goals',Icon: 'mdi-target'},

        //   {Name: 'Custom Dashboard',Icon: 'mdi-monitor-dashboard'},
        //   {Name: 'Cabinets',Icon: 'mdi-file-cabinet'},
        //   {Name: 'Related Tables',Icon: 'mdi-database-arrow-down-outline'},
        //   {Name: 'PDF Builder',Icon: 'mdi-file-pdf-box'},
        // ]
        },
        {Name: 'Social Network Builder',
        CatMatches: ['Social Network Builder','SNB'],
        // Features: [
        //   {Name: 'Digital NoticeBoard',Icon: 'mdi-bulletin-board'},
        //   {Name: 'Group Directories',Icon: 'mdi-home-group'},
        //   {Name: 'Group Featured Members',Icon: 'mdi-account-star-outline'},
        //   {Name: 'Business Members',Icon: 'mdi-smart-card'},
        //   {Name: 'Document Library',Icon: 'mdi-library-shelves'},
        //   {Name: 'Photo Library',Icon: 'mdi-folder-multiple-image'},
        //   {Name: 'Glossary',Icon: 'mdi-file-word'},
        //   {Name: 'Chatbot',Icon: 'mdi-robot'},
        //   {Name: 'FAQ',Icon: 'mdi-account-question'},
        //   {Name: 'Campaign Builder',Icon: 'mdi-monitor-cellphone-star'},
        //   {Name: 'Favorites',Icon: 'mdi-star-outline'},
        //   {Name: 'Site Search',Icon: 'mdi-cloud-search'},
        // ]
        },
        {Name: 'Website Builder',
        CatMatches: ['Website Builder','WB'],
        // Features: [
        //   {Name: 'Group Directories',Icon: 'mdi-home-group'},
        //   {Name: 'Group Featured Members',Icon: 'mdi-account-star-outline'},
        //   {Name: 'Business Members',Icon: 'mdi-smart-card'},
        //   {Name: 'Document Library',Icon: 'mdi-library-shelves'},
        //   {Name: 'Photo Library',Icon: 'mdi-folder-multiple-image'},
        //   {Name: 'Glossary',Icon: 'mdi-file-word'},
        //   {Name: 'Chatbot',Icon: 'mdi-robot'},
        //   {Name: 'FAQ',Icon: 'mdi-account-question'},
        //   {Name: 'Campaign Builder',Icon: 'mdi-monitor-cellphone-star'},
        // ]
        },
        {Name: 'App',
        CatMatches: ['App'],
        // Features: [
        //   {Name: 'Database Builder',Icon: 'mdi-database'},
        //   {Name: 'Social Network Builder',Icon: 'mdi-database'},
        //   {Name: 'Website Builder',Icon: 'mdi-search-web'},
        // ]
        }
      ],
      ActiveSuiteModule: '',
      NavbarActive: false,
      Validating: false,
      Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
        SystemCurrencyDialog: false,
          SystemDefaultCurrency: '',
    MonetizationArrayProp: '',
    MonetizationIntegerProp: '', 
      BaseSiteContentTypes: [
        "Site Articles",
        "Site Blogs",
        "Site Classifieds",
        "Site Events",
        "Site Forums",
        "Site Meetups",
        "Site Polls",
      ],
      BaseGroupContentTypes: [
        "Group Articles",
        "Group Blogs",
        "Group Classifieds",
        "Group Events",
        "Group Forums",
        "Group Meetups",
        "Group Polls",
      ],
      // GroupMonetizationPackagesInteger: 1000000,
      SiteMonetizationPackagesInteger: 1000000,
      MonetizationPackageDialog: false,
      editedSubscriptionPackage: {
        Name: "",
        Description: "",
        Scope: [],
        Price: 0,
        Type: "",
      },
      editedMonetizaitonIndex: -1,
      defaultMonetizationPackage: {
        Name: "",
        Description: "",
        Scope: [],
        Price: 0,
        Type: "",
      },
      //GroupCategories: [],
      // GroupMonetizationPackages: [],
      PackageTypes: [
        {ID: 1000001, Name: 'App Membership'},
        {ID: 1000002, Name: 'Group Ownership'},
        {ID: 1000003, Name: 'Group Membership'},
        //terrible name.        
         {ID: 1000004, Name: 'Group Directory'},
         {ID: 1000005, Name: 'Site Content'},
         {ID: 1000006, Name: 'Suite Plugin Package'},
         {ID: 1000007, Name: 'Sites Directory'},
          {ID: 1000008, Name: 'Advert Package'},
         
         
         
      ],
      
      SiteMonetizationPackages: [],
      GroupFeatures: ['Web Page Builder','Keywords', 'Web Forms','Group Teams','Team Dashboard','Custom Entities','Custom Dashboards']
      
    };
  },
  computed: {
      AllAdvertCategories(){
        return this.AdvertsCatsLevel3.concat(this.AdvertsCatsLevel2,this.AdvertCategories)
      },
      AdvertsCatsLevel3(){
          return this.AdvertsCatsLevel2.filter(cat => {
              return cat.Options
          }).map(cat => {
              return cat.Options
          }).flat()
      },
      AdvertsCatsLevel2(){
          return this.AdvertCategories.filter(cat => {
              return cat.Options
          }).map(cat => {
              return cat.Options
          }).flat()
      },
      AdvertCategories(){
          return this.CategoryField ? this.CategoryField.Options : []
      },
    CategoryField(){
      return this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
      this.AdvertDirectory.CategoryField : ''
    },      
    AdvertDirectory(){
          return this.$store.state.AdvertDirectory
      },
    SitesDirectory(){
        return this.Directories.find(obj => obj.id === 'Systems')
    },
    SiteCategories(){
        let field = this.SitesDirectory.ListPrimaryField
        return field && field.Options ? 
        field.Options : []
    },
    SiteListingType(){
      return this.editedSubscriptionPackage.Type && this.editedSubscriptionPackage.Type.Name === 'Sites Directory'
    },
    AppMembershipType(){
      return this.editedSubscriptionPackage.Type && this.editedSubscriptionPackage.Type.Name === 'App Membership'
    },
    TypeAllowsSiteContent(){
      return this.editedSubscriptionPackage.Type && this.editedSubscriptionPackage.Type.Name === 'Site Content' || 
      this.AppMembershipType
    },
    TypeAllowsGroupOptions(){
      return this.editedSubscriptionPackage.Type && this.editedSubscriptionPackage.Type.Name === 'Group Ownership' || 
      this.AppMembershipType
    },
    TypeAllowsGroupDirectoryOptions(){
      return this.editedSubscriptionPackage.Type && this.editedSubscriptionPackage.Type.Name === 'Group Directory' || 
      this.AppMembershipType
    },
    TypeAllowsSuitePluginOptions(){
       return this.editedSubscriptionPackage.Type && this.editedSubscriptionPackage.Type.Name === 'Suite Plugin Package'
        //|| this.AppMembershipType
        //This needs thinking. true or false - this is strictly RA, for system building? Is this then not App Membership anyway?
    },
    MemberGroupsArray(){
            return this.$store.state.MemberGroupsArray
        },
    AppMembershipPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'App Membership'
      })
    },
    PackageTypes(){
      return this.$store.state.SubscriptionPackageTypes
    },
    ConfigBasedPlugins(){
      return this.editedSubscriptionPackage && this.editedSubscriptionPackage.Plugins ? 
      this.editedSubscriptionPackage.Plugins.filter(plug => {
        return plug.Config_Options
      })
      : []
    },
    VoucherBasedPlugins(){
      return this.editedSubscriptionPackage && this.editedSubscriptionPackage.Plugins ? 
      this.editedSubscriptionPackage.Plugins.filter(plug => {
        return plug.Voucher_Based
      })
      : []
    },
    PackagePlugins(){
      return this.editedSubscriptionPackage && this.editedSubscriptionPackage.Plugins ? 
      this.editedSubscriptionPackage.Plugins
      : []
    },
    SuitePluginValue(){
      return this.editedSubscriptionPackage && this.editedSubscriptionPackage.Plugins ? 
      this.editedSubscriptionPackage.Plugins.map(plugobj => {
        let subtotal = plugobj.Price
        subtotal = subtotal*plugobj.Qty
        return subtotal
      }).reduce((a, b) => a + b, 0)
       : []
    },
    
    StorePlugins(){
      return this.$store.state.PluginsArray ? this.$store.state.PluginsArray : []
    },
    StorePluginsQuery(){
      return this.$store.state.PluginsQuery
    },
    ComputedSuiteModules(){
      return this.SuiteModules.map(mod => {
        mod.Features = this.StorePlugins.filter(plug => {
          return mod.CatMatches.includes(plug.Module_Category.Name)
          // || plug.Module_Category.Name === 'App'
        })
        return mod
      })
    },
    GroupCategoriesforPackages(){
      return this.GroupCategories.map(cat => {
        let catobj = {
          ID: cat.ID,
          Name: cat.Name
        }
        return catobj
      })
    },
    GroupCategories(){
      return this.System && this.System.Group_Categories && this.System.Group_Categories.Options ? this.System.Group_Categories.Options : []
    },
    SiteCategoriesforPackages(){
      return this.SiteCategories.map(cat => {
        let catobj = {
          ID: cat.ID,
          Name: cat.Name,
          FilterID: cat.ID
        }
        return catobj
      })
    },
    GroupDirectoryPagesforPackages(){
      return this.GroupDirectoryPages
      .map(page=> {
        let pageobj = {
          id: page.id
        }
        let elmnt = ''
        let navlist = ''
        let primfield = ''
        let filterlvl = ''
        let filterid = ''
        page.PageTabs.map(tab => {
          console.log(tab)
          elmnt = tab.Elements.find(obj => obj.CatNavList && obj.CatNavList.CategoricalListType === 'Social Groups')
          if(elmnt){
           navlist = elmnt.CatNavList
          primfield = navlist.ListPrimaryField
          if(primfield.Level){
            filterlvl = primfield.Level.id
            filterid = primfield.ID
          }
           pageobj.Primary_Category = {
            id: elmnt.ListPrimaryField.id
          }
          if(filterlvl){
            pageobj.Primary_Category.FilterLevel = filterlvl
            pageobj.Primary_Category.FilterID = filterid
          } 
          }
          
        })
        console.log(pageobj)
       return pageobj
      })
      .filter(page => {
        console.log(this.editedSubscriptionPackage.CategoryFilter,page.Primary_Category)
        if(this.editedSubscriptionPackage.CategoryFilter){
          return page.Primary_Category && page.Primary_Category.FilterID === this.editedSubscriptionPackage.CategoryFilter.ID
        }
        else{
          return page
        }
      })
    },
    SiteDirectoryPagesforPackages(){
      return this.SitesDirectoryPages
      .map(page=> {
        let pageobj = {
          id: page.id
        }
        // let elmnt = ''
        // let navlist = ''
        // let primfield = ''
        // let filterlvl = ''
        // let filterid = ''
        pageobj.Primary_Category = page.Primary_Category
        console.log(pageobj)
       return pageobj
      })
      .filter(page => {
        console.log(this.editedSubscriptionPackage.CategoryFilter,page.Primary_Category)
        if(this.editedSubscriptionPackage.CategoryFilter){
          return page.Primary_Category && page.Primary_Category.FilterID === this.editedSubscriptionPackage.CategoryFilter.ID
        }
        else{
          return page
        }
      })
    },
    SitesDirectoryPages(){
      return this.SitePages
      .filter(page => {
        return page.CatNavList === 'Systems'
      })
    },
    GroupDirectoryPages(){
      return this.SitePages
      .filter(page => {
        return page.IsDirectory && page.DirectoryType === 'Groups Directory'
      })
    },
    progressvalue(){
      return this.ActiveViewItem ? this.ActiveViewItem.Progress : 0
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    ComputedNavbarItems(){
      return this.NavbarItems.map(item => {  
        item.Steps = item.StepRequirements.length
        item.IncompletedSteps = item.StepRequirements.filter(req => {
          return !this[req.ParentObj] || this[req.ParentObj] && !this[req.ParentObj][req.Prop]
        })
        let Incomplete = item.IncompletedSteps.length 
        item.StepsCompleted = item.Steps-Incomplete     
        item.Progress = Math.floor((item.StepsCompleted/item.Steps)*Math.floor(100))
        item.Tootlip = `All done`
        if(item.Progress < 100){
          item.Tootlip = item.IncompletedSteps[Incomplete-1].Tootlip
        }
        return item
      }).filter(item => {
        if(this.SystemisRA){
          return item
        }
        else{
          return item.title !== 'Suite Builder'
        }
      })
      .filter(item => {
        let pass = true
        if(item.Required){
          item.Required.map(req => {
            if(!this.PluginDataBase[req]){
              pass = false
            }
          })
        }
        return pass
      })
    },
    MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        PluginTemplates(){
            return this.MarketplaceTemplates.filter(temp => {
                //technically now if Pluginid, and if type "feat mem" blablabla
                return temp.PublishStatus === 'Published'
            })
        },
     PluginOptions(){
        let options = ['Campaign_Builder','Featured_Members','Web_App_Builder','Site_Articles','Site_Blogs','Site_Events','Site_Meetups','Vacancies']
        return options.filter(opt => {
            return this.PluginDataBase[opt] && this.PluginDataBase[opt].Active
        })
      },
    PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
    MiniView(){
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    TaxReadOnly(){
      return this.System && this.System.Monetization_BU && typeof this.System.Monetization_BU.Tax_Percentage === 'number'
    },
    MonetizationBusinessUnits(){
      //console.log('thsis.BusinessUnits',this.BusinessUnits)
      return this.BusinessUnits.filter(bu => {
        return bu.id !== 'Guest'
      }).map(bu => {
        let buobj = {
          id: bu.id,
          Name: bu.Name
        }
        if(bu.Tax_Percentage){
          buobj.Tax_Percentage = bu.Tax_Percentage
        }
        if(bu.Company_Name){
          buobj.Company_Name = bu.Company_Name
        }
        return buobj
      })
    },
    BusinessUnits(){
      return this.$store.state.BusinessUnitsArray
    },
    PackagesRef(){
      return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('SubscriptionPackages')
    },
    ComputedGroupMonetizationPackages(){
      return this.GroupMonetizationPackages.map(pack => {
        let packobj = {
          id: pack.id,
          Name: pack.Name
        }
        return packobj
      })
    },
    GroupMonetizationPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Group Ownership'
      })
    },
    SuitePackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Suite Plugin Package'
      }).filter(pack => {
        if(this.ActiveSuiteModule){
          return pack.Module.Name === this.ActiveSuiteModule.Name
        }
        else{
          return false
        }
      })
    },
    SitesDirectoryMonetizationPackages(){
      console.log('this.SubscriptionPackages',this.SubscriptionPackages)
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Sites Directory'
      })
    },
    AdvertMonetizationPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Advert Package'
      })
    },
    GroupDirectoryMonetizationPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Group Directory'
      })
    },
    ComputedGroupPaidCategories(){
        return this.GroupCategories
        .filter(cat => {return cat.IsPaid})
        .map(cat => {
            let catobj = {
                ID: cat.ID,
                Name: cat.Name
            }
            return catobj
        })
    },
    ComputedSiteContentTypes() {
      let ext = "Paid Type";
      return this.BaseSiteContentTypes.map((item) => {
        let itemobj = {
          Name: item,
          Prop: item.split(" ").join("_")+'_'+ext.split(" ").join("_"),
          PropLabel: item + " " + ext,
          Options: ["Not Paid", "Public", "Internal and Public"],
        };
        // if(item === 'Site Groups'){
        //     itemobj.Options.push('By Category')
        // }
        return itemobj;
      });
    },
    ComputedGroupContentTypes() {
      let ext = "Paid Type";
      return this.BaseGroupContentTypes.map((item) => {
        let itemobj = {
          Name: item,
          Prop: item.split(" ").join("_")+'_'+ext.split(" ").join("_"),
          PropLabel: item + " " + ext,
          Options: ["Not Paid", "Public", "Internal and Public"],
        };
        // if(item === 'Site Groups'){
        //     itemobj.Options.push('By Category')
        // }
        return itemobj;
      });
    },
    GroupCategoryMonetization() {
      return this.GroupCategories.filter((cat) => {
        return cat.IsPaid;
      });
    },
    GroupContentPackageScopeOptions(){
        let items = []
        this.ComputedGroupContentTypes.map(item => {
            if(this.System[item.Prop] === 'Public'){
                items.push('Public '+item.Name)    
            }
            else if(this.System[item.Prop] === 'Internal and Public'){
                items.push('Internal '+item.Name)
                items.push('Public '+item.Name)    
            }            
        })
        return items
    },
    GroupFeaturePackageScopeOptions(){
        return this.GroupFeatures.filter(item => {
            let prop = 'Group_'+item.split(' ').join('_')+'_Paid'
            return this.System[prop] && item !== 'Keywords'
        })
    },
    SiteContentPackageScopeOptions() {
      let items = []
        this.ComputedSiteContentTypes.map(item => {
            if(this.System[item.Prop] === 'Public'){
                items.push('Public '+item.Name)    
            }
            else if(this.System[item.Prop] === 'Internal and Public'){
                items.push('Internal '+item.Name)
                items.push('Public '+item.Name)    
            }            
        })
        return items
    },
  },
   watch: {
    PackageKeyPoints: {
            handler: function(newvalue, oldvalue) {
             // console.log('newvalue, oldvalue',newvalue, oldvalue)
              if(newvalue !== oldvalue){
                //somehow, give all packages KeyPoint?
                let appmempackages = this.AppMembershipPackages
               // console.log('appmempackages',appmempackages)
                // appmempackages.map(pack => {
                //   if(!pack.KeyPoints){
                //     pack.KeyPoints = []
                //   }
                //   newvalue.map(val => {
                //     let valobj = Object.assign({},val)
                //     let packvalues = pack.KeyPoints
                //     if(typeof packvalues === 'undefined'){
                //       valobj.Active = false
                //       pack.KeyPoints.push(valobj)
                //     }
                //     else{
                //      packvalues.KeyPoints = packvalues.KeyPoints.map(kp => {
                //       let match = kp.id === valobj.id
                //         if(match){
                //           valobj.Active = kp.Active
                //         }
                //         return kp
                //      })
                //     }
                //   })
                // })
              }
            },deep: true
    },
     MiniView (value) {
        if (value === false) {
          this.NavbarActive = true
        }
        else{
          this.NavbarActive = false
        }
      },
   },
  created() {
    this.GetKeyPoints()
    let vm = this
    if(this.$route.name === 'Monetization'){      
    this.$store.commit('setBuilderView',{Name: 'Social Network Builder'})
    }
    if(this.BusinessUnits.length === 0){
      let storequery = 'BusinessUnitsQuery'
      let storearrayname = 'BusinessUnitsArray'
      console.log(storequery,this.$store.state,'this.$store.state[storequery]',this.$store.state[storequery])
                    //console.log('ssems array is empty for '+storearrayname+'. Lets try query '+storequery)
          if(this.$store.state[storequery]){
              ///console.log('getting a query for '+storequery)
              let payload = {                
              query: this.$store.state[storequery],
              arraymutation: 'set'+storearrayname,
              getter: 'get'+storearrayname,
              arrayname: storearrayname
              }
              this.$store.dispatch('GetCollectionArray',payload)
          }
          else{
            let payload = {
                collectionname: 'businessunits',
                collectionstate: 'BusinessUnitsQuery',
                query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits')
              }      
              vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                let bupayload = {                
                        query: payload.query,
                        arraymutation: 'setBusinessUnitsArray',
                        getter: 'getBusinessUnitsArray',
                        arrayname: 'BusinessUnitsArray'
                      }
                      vm.$store.dispatch('GetCollectionArray',bupayload)
                })
          }
    }
    if(!this.MiniView){
      this.NavbarActive = true
    }
    if(this.SystemisRA){
      this.CheckforPlugins()
    }
    this.SiteGeneralView = true
    this.ActiveViewItem = this.ComputedNavbarItems.find(item => item.title === 'Site General')
    this.ScrolltoTop()
    this.PrepareAddressField('Company_Address')
      if(!this.System.Base_App_Royalties){
        this.System.Base_App_Royalties = 10
        this.System.Credit_Tokens_ROE = 0.1
      }
      this.SystemDefaultCurrency = Object.assign({},this.$store.state.DefaultCurrency)
    // if (this.System.Group_Categories) {
    //   this.GroupCategories = this.System.Group_Categories.Options;
    // }
    // if (this.System.GroupMonetizationPackages) {
    //   this.GroupMonetizationPackages = this.System.GroupMonetizationPackages;
    //   this.GroupMonetizationPackagesInteger =
    //     this.System.GroupMonetizationPackagesInteger;
    // }
    if (this.System.SiteMonetizationPackages) {
      this.SiteMonetizationPackages = this.System.SiteMonetizationPackages;
      this.SiteMonetizationPackagesInteger =
        this.System.SiteMonetizationPackagesInteger;
    }
  },
  methods: {
    updateKeyPoint(kp){
      let KPRef = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('PackageKeyPoints')
      KPRef.doc(kp.id).update(kp)
    },
    AddKeyPoints(){
      //this.PackageKeyPointsIndex++
      let newpoint = {
          title: 'Earn',
          icon: 'mdi-cash-register'
        }
      let KPRef = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('PackageKeyPoints')
      KPRef.add(newpoint).then(newpoint => {

      })
      //SiteMonetizationPackagesInteger???
    },
    GetKeyPoints(){
      let KPRef = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('PackageKeyPoints')
      KPRef.onSnapshot(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.PackageKeyPoints.push(item)
                }
                if (change.type === 'modified') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.PackageKeyPoints.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = this.PackageKeyPoints.indexOf(oncheck)
                      this.PackageKeyPoints.splice(arrayobjindex, 1, item);
                    }
                   
                } 
                if (change.type === 'removed') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.PackageKeyPoints.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = this.PackageKeyPoints.indexOf(oncheck)
                      this.PackageKeyPoints.splice(arrayobjindex, 1);
                    }
                   
                } 
                })
                //console.log('this.PackageKeyPoints',this.PackageKeyPoints)
      })
    },
    RefreshKeyPoints(){
      this.RefreshingKeyPoints = true
      setTimeout(() => {
        this.RefreshingKeyPoints = false
      }, 50);
    },
    ProcessDeleteKeyPoint(item){
      confirm('Are you sure you want to delete this item?') && this.DeleteKeyPoint(item)
    },
    DeleteKeyPoint(item){
      let KPRef = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('PackageKeyPoints')
      KPRef.doc(item.id).delete().then(deleteddoc => {
          this.RefreshKeyPoints()
      })
      //this.RefreshKeyPoints()
    },
    // AddSubpackAdvatages(editedSubscriptionPackage){
    //     if(!editedSubscriptionPackage.KeyPoints){
    //       editedSubscriptionPackage.KeyPoints = []
    //     }
    //     let newpoint = {
    //       title: 'Earn',
    //       icon: 'mdi-cash-register'
    //     }
    //     editedSubscriptionPackage.KeyPoints.push(newpoint)
    //     this.RefreshKeyPoints()
    // },
    CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    CheckforPlugins(){
      let query = this.StorePluginsQuery
      let array = this.StorePlugins
      let storearrayname = 'PluginsArray'
      if(array && array.length > 0){
        //done
      }
      else{
        let payload = {                
        query: query,
        arraymutation: 'set'+storearrayname,
        getter: 'get'+storearrayname,
        arrayname: storearrayname
        }
        this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
          })
      }
    },
    ActivateSuiteModule(mod){
      this.ActiveSuiteModule = mod
    },
     SaveNewPage(){
        this.NewPage.PageRoute = this.NewPage.Name.split(' ').join('-')
        if(this.NewPage.DirectoryType === 'Groups Directory' || this.NewPage.DirectoryType === 'Sites Directory'){
        
        let elmnt = this.NewPage.PageTabs[0].Elements[0]
        if(this.EditedTab && this.EditedTab.PrimaryCategoryFilter){                                
            elmnt.CatNavList.ListPrimaryField = this.EditedTab.PrimaryCategoryFilter
            if(this.EditedTab.SecondaryCategoryFilter){
                elmnt.CatNavList.ListPrimaryField = this.EditedTab.SecondaryCategoryFilter
            }
        }
        //console.log(elmnt)
        }
        let Col = this.NewPage.PublishType === 'Public' ? 'Websites' : 'SocialNetworks'
        
        //console.log(this.NewPage)
          db.collection(Col).doc(this.System.id).collection('Pages').doc(this.NewPage.Name.split(' ').join('_')).set(this.NewPage).then(doc => {
            this.DeactivateNewPageDialog()
          })
      },
      DeactivateNewPageDialog(){
        this.NewPageDialog = false
        this.NewPage = Object.assign({},this.DefaultNewPage)
      },
    ToggleNewPageDialog(type,directorytype){
      this.EditedTab = ''
        this.NewPage.PublishType = type
        if(directorytype && directorytype === 'Groups Directory'){
          this.NewPage.IsDirectory = true
         this.NewPage.DirectoryType = directorytype
          if(directorytype === 'Groups Directory'){
            let newtab = Object.assign({},this.BlankPageRow)
            newtab.FlexXLRG = 12
            newtab.FlexLarge = 12
            newtab.FlexMedium = 12
            newtab.FlexSmall = 12
            newtab.FlexXSmall = 12
            let list = 'Group_Categories'
            let primnavlist = Object.assign({},this.Directories.find(obj => obj.id === list))
            let elmnt = Object.assign({},this.GroupsDirectoryPageElement)
            elmnt.ListPrimaryField = Object.assign({},primnavlist.ListPrimaryField)
            elmnt.CatNavList = Object.assign({},primnavlist)
            elmnt.CategorySystemProp = elmnt.CatNavList.id
            newtab.Elements.push(elmnt)
            this.NewPage.PageTabs.push(newtab)
            this.EditedTab = this.NewPage.PageTabs[0].Elements[0]
          }
        }
        else if(directorytype && directorytype === 'Sites Directory'){
          this.NewPage.CatNavList = 'Systems'
        }
        console.log(this.NewPage,this.EditedTab)
        this.NewPageDialog = !this.NewPageDialog
      },
    ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    ActivateView(item){
      this.ActiveViewItem = ''
      this.ActiveViewItem = item
      this.SiteGeneralView = false
      this.GroupsMonetizationView = false
      this.GroupDirectoriesView = false
      this.MemberGroupsView = false
      this.WebAppBuilderView = false
      this.AdvertDirectoryView= false
      this.SuiteBuilderView = false
      this.MemberDirectoriesView = false
      this.StoreSetupView = false
      this.ContentMonetizationView = false      
      if(item){
        this.ScrolltoTop()
        this[item.ViewName] = true
      }      
    },
    UpdateTaxtoBU(){
      console.log(this.System.Monetization_BU)
      if(this.System.Monetization_BU && typeof this.System.Monetization_BU.Tax_Percentage !== 'undefined'){
        this.System.Tax_Percentage = this.System.Monetization_BU.Tax_Percentage
      }
      if(this.System.Monetization_BU && typeof this.System.Monetization_BU.Company_Name !== 'undefined'){
        this.System.Company_Name = this.System.Monetization_BU.Company_Name
      }
    },
    
    UpdateBU(){
      //let vm = this
      let sysobj = {}
      let buobj = {}
      let sysprops = ['Company_Name','Tax_Percentage','Monetization_BU']
      let buprops = ['Tax_Percentage']
      sysprops.map(prp => {
        if(typeof this.System[prp] !== 'undefined'){
          sysobj[prp] = this.System[prp]
          if(buprops.includes(prp)){
            buobj[prp] = sysobj[prp]
          }
        }
      })
      this.Company_Address.FieldBreakdown.map(brk => {
        if(this.Company_Address.Response[brk.Name]){
          sysobj[brk.Name] = this.Company_Address.Response[brk.Name]
          buobj[brk.Name] = this.Company_Address.Response[brk.Name]
        }        
      })
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update(sysobj).then(updatedsysdoc => {
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(this.System.Monetization_BU.id).update(buobj)
      })
      
    },
    UpdateAddress(){
      let finaddressobj = {}
      this.Company_Address.FieldBreakdown.map(brk => {
        if(this.Company_Address.Response[brk.Name]){
          finaddressobj[brk.Name] = this.Company_Address.Response[brk.Name]
        }        
      })
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update(finaddressobj).then(updatedsysdoc => {
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(this.System.Monetization_BU.id).update(finaddressobj)
      })
    },
    PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : this.System[field.Name+'_Street_Number'],
            [field.Name+'_Street_Name'] : this.System[field.Name+'_Street_Name'],
            [field.Name+'_Suburb'] : this.System[field.Name+'_Suburb'],
            [field.Name+'_City'] : this.System[field.Name+'_City'],
            [field.Name+'_Postal_Code'] : this.System[field.Name+'_Postal_Code'],
            [field.Name+'_State_Province'] : this.System[field.Name+'_State_Province'],
            [field.Name+'_Country_Region'] : this.System[field.Name+'_Country_Region']
        }
        //console.log(field)
        }, 
    SaveROEandRoyalties(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
        Base_App_Royalties: this.System.Base_App_Royalties,
        Credit_Tokens_ROE: this.System.Credit_Tokens_ROE,
        Payout_Schedule: this.System.Payout_Schedule,
        Payout_Minimum: this.System.Payout_Minimum
      })
    },
      GetPaidGroupMonetizationPackages(cat){
        console.log(this.GroupMonetizationPackages,cat)
          return this.GroupMonetizationPackages
          .filter(pack => {
              let packscope = []
              if(pack.PaidCategoryScope){
                  packscope = pack.PaidCategoryScope.map(scope => {return scope.ID})
              }
              return packscope.includes(cat.ID)
          })
      },
      CancelSystemCurrencyDialog(){
        this.SystemCurrencyDialog = false
        this.SystemDefaultCurrency = Object.assign({},this.$store.state.DefaultCurrency)
      },
      UpdateSystemCurrency(){
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
          DefaultCurrency: this.SystemDefaultCurrency
        })
        this.CancelSystemCurrencyDialog()
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      console.log(this.field,FieldObject,this.Record)
    //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
    //   alert('should have passed updateemit')
    },
      SaveGroupFeaturePaidOptions(){
        this.GroupFeatures.map(item => {
            let prop = 'Group_'+item.split(' ').join('_')+'_Paid'
            if(typeof this.System[prop] !== 'undefined'){
                db.collection("SystemConfig")
                    .doc(process.env.VUE_APP_RA_SYSTEM_ID)
                    .update({
                        [prop]: this.System[prop]
                    })
            }
        })  
        if(this.System.Group_Keywords_Order_Qty && this.System.Group_Keywords_Order_Price){
          db.collection("SystemConfig")
                    .doc(process.env.VUE_APP_RA_SYSTEM_ID)
                    .update({
                        Group_Keywords_Order_Qty: this.System.Group_Keywords_Order_Qty,
                        Group_Keywords_Order_Price: this.System.Group_Keywords_Order_Price
                    })
        }
      },
      SaveGroupContentScopePaidOptions(){
        this.ComputedGroupContentTypes.map(item => {
            if(this.System[item.Prop] !== 'undefined'){
                db.collection("SystemConfig")
                    .doc(process.env.VUE_APP_RA_SYSTEM_ID)
                    .update({
                        [item.Prop]: this.System[item.Prop]
                    })
            }
        })  
      },
      SaveSiteContentMonetization(){
        this.ComputedSiteContentTypes.map(item => {
            if(this.System[item.Prop] !== 'undefined'){
                db.collection("SystemConfig")
                    .doc(process.env.VUE_APP_RA_SYSTEM_ID)
                    .update({
                        [item.Prop]: this.System[item.Prop]
                    })
            }
        })  
      },
    RelevantSiteMonetizationPackages(Type) {
      return this.SiteMonetizationPackages.filter((item) => {
        return item.Type === Type;
      });
    },
    RelevantGroupMonetizationPackages(Type) {
      return this.GroupMonetizationPackages.filter((item) => {
        return item.Type === Type;
      });
    },
    SaveGroupCategoryInformation() {
      console.log(this.GroupCategories, this.System.Group_Categories);     
        db.collection("SystemConfig")
        .doc(process.env.VUE_APP_RA_SYSTEM_ID)
        .update({
          Group_Categories: this.System.Group_Categories,
        });
    },
    
    SaveMonetizationPackage(keepdialog) {
      let ref = this.PackagesRef.doc()
      let newdocid = ref.id
      if(this.editedSubscriptionPackage.id){
        newdocid = this.editedSubscriptionPackage.id
      }
      if(this.editedSubscriptionPackage.Type.Name === 'Suite Plugin Package'){
        this.editedSubscriptionPackage.Value = this.SuitePluginValue
      }
      this.PackagesRef.doc(newdocid).set(this.editedSubscriptionPackage)
      if(!keepdialog){
        this.CancelMonetizaitonPackageDialog();
      }      
    },
    CancelMonetizaitonPackageDialog() {
      this.MonetizationPackageDialog = false;
      this.editedSubscriptionPackage = Object.assign({},
        this.defaultMonetizationPackage
      );
      this.MonetizationArrayProp = ''
      this.MonetizationIntegerProp = ''
    },
    ActivateMonetizationPackagesDialog(ArrayProp,IntegerProp,Type,item, index) {
      //console.log('this.ActiveSuiteModule.Features',this.ActiveSuiteModule.Features)
      if (item) {
        this.editedSubscriptionPackage = item;
        if(this.MemberGroupsView){
           if(!this.editedSubscriptionPackage.KeyPoints){
              this.editedSubscriptionPackage.KeyPoints = []
            }
            let newkeypoints = this.PackageKeyPoints.map(kp => {
              let kpobj = Object.assign({},kp)
              let match = this.editedSubscriptionPackage.KeyPoints.find(obj => obj.id === kp.id)
              if(match){
                kpobj.Active = match.Active
              }
              else{
                kpobj.Active = false
              }
              return kpobj
            })
            this.editedSubscriptionPackage.KeyPoints = newkeypoints
        }
        //editedSubscriptionPackage.Type && editedSubscriptionPackage.Type.Name === 'Group Ownership'"
        //console.log('BaseGroupContentTypes',this.BaseGroupContentTypes,item)
        if(!item.GroupContentMonetization && item.Type && item.Type.Name === 'Group Ownership'){
          
          this.editedSubscriptionPackage.GroupContentMonetization = this.BaseGroupContentTypes.map(type => {
            let typeobj = {
              Name: type,
              Can_Monetize: false,
              Exempt_Royalties: false
            }
            return typeobj
          })
        }
        if(this.ActiveSuiteModule && item.Type.Name === 'Suite Plugin Package'){
        this.editedSubscriptionPackage.Module = {
          Name: this.ActiveSuiteModule.Name,
          CatMatches: this.ActiveSuiteModule.CatMatches,
          Features: this.ActiveSuiteModule.Features.map(opt => {
        let optobj = {
          id: opt.id,
          Price: opt.Price,
          Name: opt.Name,
          Voucher_Based: false,
          Qty: 1
        }
        if(opt.Voucher_Based){
          optobj.Voucher_Based = opt.Voucher_Based
        }
        if(opt.Config_Options){
          optobj.Config_Options = opt.Config_Options
        }
        return optobj
      })
        }
      }
        this.editedMonetizaitonIndex = index;
      }
      else{
        let typeobj = this.PackageTypes.find(obj => obj.Name === Type)
          this.editedSubscriptionPackage.Type = typeobj
          if(this.MemberGroupsView){
           if(!this.editedSubscriptionPackage.KeyPoints){
              this.editedSubscriptionPackage.KeyPoints = []
            }
            let newkeypoints = this.PackageKeyPoints.map(kp => {
              let kpobj = Object.assign({},kp)
              let match = this.editedSubscriptionPackage.KeyPoints.find(obj => obj.id === kp.id)
              if(match){
                kpobj.Active = match.Active
              }
              else{
                kpobj.Active = false
              }
              return kpobj
            })
            this.editedSubscriptionPackage.KeyPoints = newkeypoints
        }
      }
      if(this.ActiveSuiteModule && Type === 'Suite Plugin Package'){
        this.editedSubscriptionPackage.Module = {
          Name: this.ActiveSuiteModule.Name,
          CatMatches: this.ActiveSuiteModule.CatMatches,
          Features: this.ActiveSuiteModule.Features.map(opt => {
        let optobj = {
          id: opt.id,
          Price: opt.Price,
          Name: opt.Name,
          Voucher_Based: false,
          Qty: 1
        }
        if(opt.Voucher_Based){
          optobj.Voucher_Based = opt.Voucher_Based
        }
        if(opt.Config_Options){
          optobj.Config_Options = opt.Config_Options
        }
        return optobj
      })
        }
      }
      this.MonetizationArrayProp = ArrayProp
      this.MonetizationIntegerProp = IntegerProp
      this.MonetizationPackageDialog = true;
    },
  },
};
</script>

<style>
</style>
