<template>
  <v-list>
              <!--   -->
              <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;"
               @click="ActivateVacancy(vac)">
                  <v-list-item-avatar class="white--text" :color="vac.Color" v-if="!vac.Company || vac.Company && !vac.Company.Logo"
                  :size="MiniView ? 25 : 50" tile >
                    {{vac.AvatarTitle}}
                  </v-list-item-avatar>
                  <v-list-item-avatar class="white--text" v-if="vac.Company && vac.Company.Logo" :size="MiniView ? 25 : 50" tile >
                    <img style="object-fit: cover;"
                    :src="vac.Company.Logo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                    {{vac.Title}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{vac.Company.Company_Name}}
                    </v-list-item-subtitle>
                     <v-list-item-subtitle>
                      {{vac.Location_City}}
                    </v-list-item-subtitle>
                  </v-list-item-content>    
                  <v-list-item-action >
                    <v-avatar tile  style="border-radius: 10px!important;" :color="vac.PublishStatus === 'Draft' ? 'red' : 'green'">
                      <v-icon dark>
                        {{vac.PublishStatus === 'Draft' ? 'mdi-pencil' : 'mdi-check'}}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>                
              </v-list-item>
              <v-list-item class="justify-end">
               <v-chip><v-icon>mdi-clock-outline</v-icon>{{vac.PublishAgeing}}
                    </v-chip>
                    <v-chip v-if="vac.Renumeration"><v-icon>mdi-account-cash</v-icon>{{vac.Renumeration}}
                    </v-chip>
                    <v-chip><v-icon>mdi-briefcase</v-icon>{{vac.Employment_Type.Name}}
                    </v-chip>
              </v-list-item>
          </v-list>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','vac','DirView'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ActivateVacancy(vac){
            if(this.DirView){
                this.$router.push('/Vacancy/'+vac.id)
            }
            else{
                this.$emit('ActivateVacancy',vac)
            }
        }
    }
}
</script>

<style>

</style>



