<template>
  <v-card flat tile class="transparent">
    <!-- :style="MobileView ? 'top:50px;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'" -->
       <v-list :style="MobileView ? 'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'"
              dark>               
              <v-list-item v-for="sect in ComputedActiveButtonMenuItems" :key="sect.itemObjKey" @click="ActivateButtonMenuSection(sect)" :class="sect.Class">          
                  <v-list-item-action>
                  <v-icon>{{sect.Icon}}</v-icon>
                  </v-list-item-action>          
                  <v-list-item-content>
                  {{sect.Name}}
                  </v-list-item-content>
              </v-list-item>
          </v-list>	
      <v-card-subtitle class="background darken-1 mediumoverline">
              Image
          </v-card-subtitle>
          <v-list v-if="ActiveButtonMenuSection.Name === 'Apply'">
            <v-btn @click="ConfirmApplytoPeers('Image',EditedTab)">Apply to All</v-btn>
          </v-list>
          <v-list v-if="ActiveButtonMenuSection.Name === 'Standard'">
            <!-- honestly depending the selection. When I have Rotating Images, or comparing images I first need to add them, but now they becomes avail through assets not img so this kind of disappears...
            furthermore...yeah just a bit of a mess now... -->
            <v-card-text style="background: linear-gradient(180deg, rgba(217,232,240,1) 0%, rgba(217,232,240,0.3) 100%);padding:0px;">
              <AssetsDirectory v-if="EditedTab.ComparingImages || EditedTab.IMGRotation" :GalAssetsCall="true"
              :Assets="EditedTab.Assets" :Cols="6" :HoverPreview="false" :HideSelect="true" :EditedTab="EditedTab"
               :ViewFullGalleryTop="true" @ActivateFullGallery="ActivateGalleryDialog" :ViewFullGalleryText="'Add More'"
                                />
              <v-list-item v-if="EditedTab.Name === 'Card Row Single' && EditedTab.Type === 'Bootstrap Boxes'">
                  <v-text-field v-model="EditedTab.CardIcon" label="Icon"/>
              </v-list-item>
          <v-img v-if="EditedTab.IMG && !EditedTab.ComparingImages && !EditedTab.IMGRotation && !EditedTab.IMGMaskReveal" contain :src="EditedTab.IMG" height="200">
            <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog('IMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            <v-chip outlined color="red" @click="ConfirmClearTabIMG('IMG')">Clear</v-chip>
            </v-row>
          </v-img>
      <v-img v-if="!EditedTab.IMG && !EditedTab.ComparingImages && !EditedTab.IMGRotation && !EditedTab.IMGMaskReveal" contain :src="$vuetify.theme.dark ? require('@/assets/ImageHolder_dark.png') : require('@/assets/ImageHolder.png')" height="200">
      <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog('IMG')"   :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
          </v-img>
          <div v-if="EditedTab.IMGMaskReveal">
          <div v-for="imgprp in MaskRevealProps" :key="imgprp.itemObjKey">
            <v-img v-if="EditedTab[imgprp]" contain :src="EditedTab[imgprp]" height="200">
            <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog(imgprp)" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            <v-chip outlined color="red" @click="ConfirmClearTabIMG(imgprp)">Clear</v-chip>
            </v-row>
          </v-img>
          <v-img v-if="!EditedTab[imgprp]" contain :src="$vuetify.theme.dark ? require('@/assets/ImageHolder_dark.png') : require('@/assets/ImageHolder.png')" height="200">
          <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                <v-chip @click="ActivateGalleryDialog(imgprp)"   :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                </v-row>
              </v-img>
              <div v-if="imgprp === 'MaskIMG'">
                 <v-text-field  label="Mask Frames"
                  v-model.number="EditedTab.MaskIMGFrames"
                  class="mt-0 pt-0"
                  type="number"
                /> 
                <v-text-field  label="Mask Duration (ms)"
                  v-model.number="EditedTab.MaskAnimDuration"
                  class="mt-0 pt-0"
                  type="number"
                /> 
                <v-text-field  label="Mask Delay (ms)"
                  v-model.number="EditedTab.MaskAnimDelay"
                  class="mt-0 pt-0"
                  type="number"
                /> 
              </div>
          </div>
          </div>
      <v-list-item v-if="EditedTab.Name === 'Parallax'">
        <v-switch  v-model="EditedTab.FullHeight" label="Full Height"/>
    </v-list-item>
      <v-list-item dense v-if="TabImageHasSizing(EditedTab) && !EditedTab.ComparingImages && !EditedTab.IMGRotation">
         <v-text-field clearable
                  v-model.number="EditedTab.Height"
                  class="mt-0 pt-0"
                  type="number"
                >  
                <template v-slot:append>
                    <v-btn small
                    :disabled="EditedTab.Height === '10%'"
                    @click="Twopropemit('ImageHeight', EditedTab, 'down')"
                    >50
                    <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                    <v-btn small
                    :disabled="EditedTab.Height === '100%'"
                    @click="Twopropemit('ImageHeight', EditedTab, 'up')"
                    >50
                    <v-icon> mdi-chevron-up </v-icon>
                    </v-btn>
                    </template>              
                </v-text-field>
              
      </v-list-item>
      <v-list v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Row Single' && EditedTab.InfographicBox">
        <v-list-item>
        <v-switch v-model="EditedTab.IMGonTop" label="IMG on Top"/>
        </v-list-item>
        <v-list-item>
        <v-switch @change="CheckIMGHasTitle(EditedTab)" v-model="EditedTab.IMGHasTitle" label="IMG has Title"/>
        </v-list-item>
        <v-list-item>
           IMG BG Color
           <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-icon  v-on="on">mdi-palette-advanced</v-icon>
            </template>
            <v-color-picker v-model="EditedTab.IMGBGColor"></v-color-picker>
           
          </v-menu>
              </v-list-item>
      </v-list>
      
          </v-card-text>
     <!-- <v-card-subtitle class="my-3 background darken-1 mediumoverline">
              Image Styling
          </v-card-subtitle> -->
          <v-list-item v-if="EditedTab.Name !== 'Title' && EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'HTML Box'">
             <v-switch 
          label="Comparing Images" @change="CheckIMGRotation(EditedTab)"
          v-model="EditedTab.ComparingImages"
        />
          </v-list-item>
          <v-list-item v-if="!EditedTab.ComparingImages && EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'HTML Box'">
             <v-switch v-if="EditedTab.Name !== 'Title'"
          label="Rotating Images" @change="CheckIMGRotation(EditedTab)"
          v-model="EditedTab.IMGRotation"
        />
          </v-list-item>
          <v-list-item v-if="!EditedTab.ComparingImages && EditedTab.Name !== 'HTML Box'">
              <v-switch v-if="EditedTab.Name !== 'Title'"
            label="Mask Reveal" @change="CheckIMGMaskReveal(EditedTab)"
            v-model="EditedTab.IMGMaskReveal"
          />
          </v-list-item>
          <v-list-item v-if="EditedTab.IMGRotation && !EditedTab.ComparingImages && EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'HTML Box'">
         <v-text-field label="Cycle Time (ms)"
                  v-model.number="EditedTab.CycleTime"
                  class="mt-0 pt-0"
                  type="number"
                />  
          </v-list-item>
      
      
          </v-list>
           <v-list v-if="ActiveButtonMenuSection.Name === 'Styling'">
             <v-list-item dense v-if="EditedTab.Name !== 'Parallax'">
        <v-switch v-model="EditedTab.DisableContain" label="Disable Contain"
        />
       </v-list-item>
           
        <v-list-item dense v-if="EditedTab.Name === 'Hero Banner'">
        <v-select :items="['Start','Center','End']" label="Align" v-model="EditedTab.IMGFlexAlign" />
      </v-list-item>
       <v-list-item dense v-if="EditedTab.Name !== 'Parallax'">
        <v-text-field label="Custom Style" v-model="EditedTab.IMGCustomStyle" />
      </v-list-item>
      <v-list-item dense v-if="TabImageHasSizing(EditedTab) && EditedTab.Name !== 'Parallax'">
        <v-text-field type="number" label="Width" v-model="EditedTab.Width" />
      </v-list-item>
      <v-list-item dense v-if="EditedTab.Name === 'Title'">
                <v-switch label="Contain IMG" v-model="EditedTab.ContainIMG" />
            </v-list-item>
      <v-list-item dense v-if="EditedTab.Name === 'Title'">
              <v-switch
          v-if="EditedTab.Name === 'Title'"
          label="Tile"
          v-model="EditedTab.Tile"
        />
      </v-list-item>
      <v-list-item dense v-if="TabImageHasSizing(EditedTab) && EditedTab.Name !== 'Title'">
         
        <v-switch 
          label="Has Gradient"
          v-model="EditedTab.HasParallaxGradient"
        />
      </v-list-item>
       <v-list-item dense v-if="TabImageHasSizing(EditedTab) && EditedTab.Name !== 'Title'">
        
        <v-text-field
          label="Gradient"
          v-model="EditedTab.ParallaxGradient"
          v-if="EditedTab.HasParallaxGradient"
        />
        <a v-if="EditedTab.HasParallaxGradient" href="https://cssgradient.io/" target="_blank">Get Code</a>
          <!-- </v-col> -->
      </v-list-item>
      <!-- <v-list-item v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">									
                          <v-switch label="Clipped" v-model="EditedTab.Clipped" />									
                      </v-list-item>									 -->
            <v-list-item v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">									
                <v-switch @change="UpdateRowTransparency(EditedTab)" label="Transparent" v-model="EditedTab.Transparent" />									
            </v-list-item>	
      <v-list-item
        dense
        v-if="
          EditedTab.Name === 'Image' ||
          (EditedTab.HasImage && !EditedTab.DataViewName)
        "
      >
        <v-select clearable v-model="EditedTab.IMGBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
      </v-list-item>
          </v-list>
          <v-list v-if="ActiveButtonMenuSection.Name === 'Title'">
             <v-list-item>
        <v-text-field clearable v-model="EditedTab.IMGTitle" label="IMG Title"
        />
        </v-list-item>
        <v-list-item>
        <v-slider v-model="EditedTab.IMGTitleFontSize" label="Font Size" min="50"
            max="500" thumb-label="always"									
            />
            </v-list-item>
        <v-list-item>
           Title Color
           <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-icon  v-on="on">mdi-palette-advanced</v-icon>
            </template>
            <v-color-picker v-model="EditedTab.IMGTitleColor"></v-color-picker>
           
          </v-menu>
              </v-list-item>
        <v-list-item>
           <v-icon right v-if="EditedTab.IMGTitleColor" @click="EditedTab.IMGTitleColor = ''">mdi-close</v-icon>
             </v-list-item>
        <v-list-item>
          Title BG Color
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-palette-advanced</v-icon>
            </template>
            <v-color-picker v-model="EditedTab.IMGTitleBGColor"></v-color-picker>
          </v-menu>
          </v-list-item>
          <v-list-item>
           <v-icon right v-if="EditedTab.IMGTitleColor" @click="EditedTab.IMGTitleColor = ''">mdi-close</v-icon>
             </v-list-item>
        <v-list-item>
          <v-slider v-model="EditedTab.IMGBorderRadius" label="Border Radius" min="0"
            max="250" thumb-label="always"									
            />
            </v-list-item>
        <v-list-item>
             <v-text-field clearable v-model="EditedTab.IMGTitleStyle" label="IMG Title Style"
        />
        <v-menu
            :close-on-content-click="false"
            :nudge-left="200"
            transition="scale-transition"
            offset-y top
            min-width="200px"
          >
           <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-help</v-icon>
           </template>
           <v-card>
            <v-card-title>
              CSS Styling Examples
            </v-card-title>
            <v-card-text>
              <v-list dark>
                <v-list-item v-for="samp in CSSExamples" :key="samp.itemObjKey" :style="samp.PreviewCode" @click="ConfirmActivateCSSExample(samp,EditedTab,'IMGTitleStyle')">
                  {{samp.Name}}
                </v-list-item>
                <!-- <v-list-item v-for="samp in CSSExamples" :key="samp.itemObjKey" :style="IMGTitleStyle(EditedTab)" @click="ConfirmActivateCSSExample(samp)">
                  {{EditedTab.IMGTitle}}
                </v-list-item> -->
              </v-list>
            </v-card-text>
           </v-card>
        </v-menu>
        
        <!-- align-self only gonna work on "display: flex;" whihc also won't work here anyway within a card...
        <v-select v-model="EditedTab.IMGTitleAlign" label="Title Align" :items="CSSAlignmentOptions"
        item-text="value"
        >
        <template v-slot:item="{ item }">
              <span>
               {{item.name}}
              </span>
            </template>
        </v-select> -->
      </v-list-item>
          </v-list>
  </v-card>
</template>






<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets','GroupData',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,AssetsDirectory},
    data() {
        return {
          MaskRevealProps: ['MaskIMG','FirstIMG','RevealIMG'],
            CSSExamples: [
              {PreviewCode: 'background: #ff0000;border-radius: 130px;height: 130px;line-height: 130px;width: 130px;text-align: center;', 
              Code: 'height: 130px;line-height: 130px;width: 130px;text-align: center;',Name: 'Circled Text'}
            ],
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
        ActiveButtonMenuSection: {Name: 'Standard'},
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
      CSSAlignmentOptions(){
        return this.$store.state.CSSAlignmentOptions
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      IMGProp(){
        return this.$store.state.IMGProp
      },
      ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 12
        }
        else if(this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.xl){
          return 2
        }
      }, 
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      ComputedActiveButtonMenuItems(){
          return this.ActiveButtonMenuItems.filter(item => {
                  if(!this.EditedTab || this.EditedTab && this.EditedTab.Name !== 'HTML Box' && this.EditedTab.Name !== 'Card Row Single'
                   || this.EditedTab && this.EditedTab.Name === 'HTML Box' && !this.EditedTab.IMGHasTitle || 
                   this.EditedTab.Name === 'Card Row Single' && !this.EditedTab.InfographicBox || this.EditedTab.Name === 'Card Row Single' && this.EditedTab.InfographicBox && !this.EditedTab.IMGHasTitle){
                    return item.Name !== 'Title'
                   }
                   else{
                    return item
                   }
                }).filter(item => {
              let peerfixtypes = ['Card Row Single']
              return peerfixtypes.includes(this.EditedTab.Name) || item.Name !== 'Apply'
          })
      },
      ActiveButtonMenuItems(){
                return [
                    {Icon: 'mdi-image',Name: 'Standard',Class: 'grey'},
                    {Icon: 'mdi-image-size-select-large',Name: 'Styling',Class: 'grey'},
                   {Icon: 'mdi-format-title',Name: 'Title',Class: 'grey'},
                    {Icon: 'mdi-auto-fix',Name: 'Apply',Class: 'red'},
                ]
            },
    },
    watch: {
      SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['AssignImageandCloseTab']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      //console.log(this.SocialItemInteractMethod)
    },
    methods:{
        ConfirmApplytoPeers(type,EditedTab){
            confirm('This will apply these settings to all items in the Parent Tab. Are you sure?') && this.ApplytoPeers(type,EditedTab)
        },
        ApplytoPeers(type,EditedTab){
            this.$emit('ApplytoPeers',type,EditedTab)
        },
      ConfirmActivateCSSExample(samp,item,prop){
        confirm('Activate this example now?') && this.ActivateCSSExample(samp,item,prop)
      },
      ActivateCSSExample(samp,item,prop){
        item[prop] = samp.Code
      },
      IMGTitleStyle(elmnt) {
            //IMGHasTitle - IMGTitle - IMGTitleColor - IMGTitleFontSize - IMGBorderRadius - IMGTitleStyle
            let style = ''
                if(elmnt.IMGTitleColor){
                    style = style+'color: '+elmnt.IMGTitleColor.hexa+';'
                }
                if(elmnt.IMGBorderRadius){
                    style = style+'border-radius: '+elmnt.IMGBorderRadius+'px;'
                }
                if(elmnt.IMGTitleBGColor){
                    style = style+'background: '+elmnt.IMGTitleBGColor.hexa+';'
                }
                if(elmnt.IMGTitleFontSize){
                    style = style+'font-size: '+elmnt.IMGTitleFontSize+'px;'
                }
                if(elmnt.IMGTitleStyle){
                    //height: 130px;line-height: 130px;width: 130px;text-align: center;
                    style = style+elmnt.IMGTitleStyle
                }
            return style
        },
      CheckIMGHasTitle(tab){
        if(typeof tab.IMGTitle === 'undefined'){
          tab.IMGTitleStyle = 'height: 130px;line-height: 130px;width: 130px;text-align: center;'
          tab.IMGTitle = '3'
          tab.IMGTitleFontSize = 130
          tab.IMGTitleColor = { "alpha": 1, "hex": "#FFFFFF", "hexa": "#FFFFFFFF", "hsla": { "h": 0, "s": 0, "l": 1, "a": 1 }, "hsva": { "h": 0, "s": 0, "v": 1, "a": 1 }, "hue": 0, "rgba": { "r": 255, "g": 255, "b": 255, "a": 1 } }
          tab.IMGTitleBGColor = { "alpha": 1, "hex": "#13BDFF", "hexa": "#13BDFFFF", "hsla": { "h": 196.77966101694915, "s": 1, "l": 0.5372549019607843, "a": 1 }, "hsva": { "h": 196.77966101694915, "s": 0.9254901960784314, "v": 1, "a": 1 }, "hue": 196.77966101694915, "rgba": { "r": 19, "g": 189, "b": 255, "a": 1 } }
          tab.IMGBorderRadius = 130
        }
      },
      ActivateButtonMenuSection(sect){
            this.ActiveButtonMenuSection = sect
        },
      ConfirmClearTabIMG(imgprp){
        confirm('Are you sure you want to remove this image from this element?') && this.ClearTabIMG(imgprp)
      },
      ClearTabIMG(imgprp){
        this.EditedTab[imgprp] = ''
      },
      CheckIMGMaskReveal(tab){
        this.MaskRevealProps.map(prp => {
          if(!tab[prp] && prp !== 'MaskIMG'){
            tab[prp] = tab.IMG
          }
        })
        if(!tab.MaskIMG){
          //how ????Do we provide def mask?
          tab.MaskIMG = ''
          tab.MaskIMGFrames = 9
          tab.MaskAnimDuration = 1000
          tab.MaskAnimDelay = 2000
        }
      },
      CheckIMGRotation(tab){
        if(!tab.Assets){
          tab.Assets = []
        }
        if(typeof tab.CycleTime === 'undefined'){
          tab.CycleTime = 3000
        }
        if(tab.ComparingImages){
          tab.IMGRotation = true
        }
      },
      UpdateRowTransparency(tab){
        if(tab.Transparent){
          tab.RowTransparency = 'transparent'
        }
        else{
          tab.RowTransparency = ''
        }
      },
      ActivateGalleryDialog(prp){
        //console.log('ActivateGalleryDialog',this.GalleryLibraries)
        this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
        this.$store.commit('setGalleryAssets',this.SystemAssets)
        this.$store.commit('setEditedTab',this.EditedTab)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
        
        //GalleryLibraries
        //methods ? SelectGalleryAssets
      },
        AssignGalleryAssets(EditedTab){
          this.Twopropemit('AssignGalleryAssets',EditedTab,this.SelectedImage)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" && !EditedTab.FullHeight ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            EditedTab.Name === "Card Row Single" && EditedTab.InfographicBox ||
            EditedTab.Name === "Card Row Single" && EditedTab.Type === 'Bootstrap Boxes' ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single" && !EditedTab.InfographicBox &&
              EditedTab.Name !== "Title")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImageandCloseTab(){
          //console.log('AssignImageandCloseTab',this.SelectedImage,this.IMGProp,this.EditedTab)
          if(this.IMGProp === 'LibraryAsset' || this.EditedTab.IMGRotation || this.EditedTab.ComparingImages){
            //console.log('as LibraryAsset')
            this.AssignGalleryAssets(this.EditedTab,this.SelectedImage)
              this.NoPropsemit('DeactivateTabDialog')
          }
          else{
            //console.log('as else')
              this.AssignImage(this.EditedTab,this.IMGProp,this.SelectedImage)
              this.NoPropsemit('DeactivateTabDialog')
          }
         
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>
</style>
