
<template>
<v-footer :class="'transparent '+tab.FooterJustify"
    :style="'width: 100%;margin-top: 0px;padding: 12px;'+TabFooterStyle(tab)"
    padless
  >
  
  <v-flex :class="FooterClass(tab)">
  <!-- BG Grad background: linear-gradient(90deg, rgba(68,68,68,0.8) 0%, rgba(71,71,71,0.8) 100%);bottom: 0px;width: 100%; -->
   <HeroFooterForm v-if="tab.FooterType === 'Footer Form' && tab.FooterFormFields"
    :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
        :System="System"
        />	
  </v-flex>
  </v-footer>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import HeroFooterForm from '@/components/WebPages/RenderComponents/HeroBanner/HeroFooter/HeroFooterTypes/HeroFooterForm';
export default {
    props: ['System','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {
      HeroFooterForm
        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        TitleLines(){
            return this.tab.TitleLines ? 
            this.tab.TitleLines :
            []
        },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        FooterClass(tab){
            let xl = 12
            let lg = 12
            let md = 12
            if(typeof tab.FooterFlexXLRG !== 'undefined'){
              xl = tab.FooterFlexXLRG   
            }
            if(typeof tab.FooterFlexLarge !== 'undefined'){
              lg = tab.FooterFlexLarge   
            }
            if(typeof tab.FooterFlexMedium !== 'undefined'){
              md = tab.FooterFlexMedium   
            }
            //console.log('xl'+xl+' lg'+lg+' md'+md+' sm12 xs12')
            return 'xl'+xl+' lg'+lg+' md'+md+' sm12 xs12'
        },
      TabFooterStyle(tab){
        let style = ''
        if(tab.FooterGradient){
            style = style+tab.FooterGradient
        }
        if(tab.FooteratBottom){
            style = style+'align-self: self-end;'
        }
        return style
      }

    }    
}
</script>

<style>
</style>