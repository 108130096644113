<template>
<div>
  <v-btn outlined v-if="OptionsOnly" @click="ActivateOptionSetDialog(field)">Options</v-btn>
  <v-dialog max-width="600" v-model="MappingDialog">                               
        <v-card>
            <v-card-title class="overline recordtoolbar white--text">
               Mapping Dialog
               
            </v-card-title>
            <v-card-text>
                <v-btn @click="AddMappingtoParentField()">Add Mapping</v-btn>
                <v-list v-if="field.MappedValues">
                    <v-list-item v-for="mapping in field.MappedValues" :key="mapping.itemObjKey">                        
                        <v-list-item-content>
                            <v-select @change="ToggleMappingDialog()" :items="AllEntityFields" label="Field to Obtain" item-text="DisplayName" return-object v-model="mapping.OutputField">                                
                                 <template v-slot:prepend-item>
                                <v-list-item class="recordtoolbar white--text overline">
                                  {{CurrentEntity.DisplayName}}
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px;">
                                            {{item.DisplayName}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle  style="font-size: 12px;">
                                            {{item.Type}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                            </template>
                            </v-select> 
                        </v-list-item-content>
                        <v-list-item-content>
                            <v-select @change="ToggleMappingDialog()" :items="SelectedEntityFields(mapping)" label="Field from Parent" item-text="DisplayName" return-object v-model="mapping.InputField">
                                 <template v-slot:prepend-item>                                  
                                <v-list-item class="recordtoolbar white--text overline">
                                    {{field.RelatedLocation}}
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px;">
                                            {{item.DisplayName}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle  style="font-size: 12px;">
                                            {{item.Type}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                            </template>
                            </v-select>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon color="red" @click="DeleteMappingVerify(mapping)">mdi-delete-forever</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>     
            </v-card-text>
            <v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelMappingDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="SaveMapping()">
                   
                    {{'Add Mapped Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>  
 <v-dialog v-model="OptionSetDialog" persistent width="800px">
  <v-card class="slightlypadded">
    <v-img src='@/assets/RASectionBG.jpeg'>
      <v-card-title class="headline blue-grey darken-2 white--text">
                {{EditOptionSet.Name}} - Configuration
                <!-- <v-btn fab @click="SaveDefaultStatus()" icon>SaveDefaultStatus</v-btn> -->
                <v-layout class="justify-end">
      <v-btn fab @click="SaveOptionSets(true)" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelOptionSetDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn>
      </v-layout>
                </v-card-title>
             
        <v-tabs >
          <v-tab>Options</v-tab>
          <v-tab-item>
           
            <v-layout row  color="rgb(236, 60, 126, 0.7)">
      
              <v-col :cols="EditOptionSet.IsNumeric ? 12 : 4">
                <v-list >
                  <v-list-item v-if="!TopLevelOptionSet">                    
                    <v-switch                                 
                        v-model="EditOptionSet.IsNumeric"
                        class="mx-2"
                        label="Is Numeric"                                
                      ></v-switch>
                  </v-list-item>
                 
                  <v-list-item v-if="EditOptionSet.IsNumeric">
                    <v-list-item-content>
                      <v-text-field type="number" v-model.number="EditOptionSet.Intervals" label="Intervals" />
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-text-field type="number" v-model.number="EditOptionSet.StartingInt" label="Starting Int" />
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-text-field type="number" v-model.number="EditOptionSet.EndingInt" label="Ending Int" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-btn v-if="EditOptionSetHasIntervals"
                    @click="PopulateOptionSetViaIntervals(EditOptionSet)">Populate</v-btn>
                  </v-list-item>
                </v-list>
                <v-list v-if="!EditOptionSet.IsNumeric">
                  <v-list-item>
                    <v-list-item-content class="listtitle">
                    Options
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu 
                    :close-on-content-click="false"                            
                    transition="scale-transition"                            
                    >
                    <template v-slot:activator="{ on }">
                        <v-btn fab icon v-on="on"><v-icon>mdi-plus-thick</v-icon></v-btn>
                      </template>
                      <v-card width="400px">
                        <v-card-title>
                          New Option for {{EditOptionSet.Name}}
                          </v-card-title>
                          <v-list dense>
                            <v-list-item dense>
                              <v-text-field v-model="OptionName" label="Name"></v-text-field>  
                            </v-list-item>
                            <v-list-item dense>
                              <v-textarea label="Description (Optional)" v-model="OptionDescription"
                            /> 
                            </v-list-item>
                            <v-list-item dense>
                              <v-text-field label="Icon (Optional)" v-model="OptionIcon"/>                              
                            <v-icon v-if="OptionIcon">{{OptionIcon}}</v-icon>
                            </v-list-item>
                            <v-list-item dense>
                              <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                            <template v-slot:activator="{ on }">
                            <v-list-item v-on="on">
                                <v-avatar :color="OptionColor ? OptionColor.hexa : 'black'" size="30">
                                </v-avatar>
                                <!-- <v-icon left>mdi-palette-advanced</v-icon> -->
                                Color (Optional)
                                </v-list-item>
                                </template>
                            <v-color-picker v-model="OptionColor"></v-color-picker>
                            </v-menu> 
                            </v-list-item>
                          </v-list>                                         
                            <v-card-actions>
                                <v-btn v-if="OptionName !== ''" @click="AddOptionSetOption()">Save</v-btn><v-btn @click="CancelGlobalOptionSetOption()">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                      </v-list-item-action>
                      <v-list-item-action>
                      <v-dialog 
                    max-width="400"                         
                    >
                    <template v-slot:activator="{ on }">
                        <v-btn fab icon v-on="on"><v-icon>mdi-application-import</v-icon></v-btn>
                      </template>
                      <v-card width="400">
                        <v-card-title class="recordtoolbar white--text">
                          Import Options
                          </v-card-title>
                          <v-card-subtitle style="padding-top: 10px;" class="recordtoolbar white--text">
                            <v-btn small dark @click="DownloadOptionSetOptionsImportTemplate()">Download Template</v-btn> 
                          </v-card-subtitle>
                          <v-card-text class="background" style="padding-top: 10px;">
                          <input v-if="!ImportFile"
                                        type="file"
                                        @change="onImportfileSelected($event)"
                                        ref="ImportFileinputter"
                                        id="fileUpload"
                                        >
                                  
                          </v-card-text>                                                  
                            <v-card-actions>
                                <v-btn v-if="ImportFile" @click="saveImport()">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  
                  <v-list>
                     <v-btn @click="ConfirmSortOptionsAlphateically(EditOptionSet.Options)">Sort<v-icon>mdi-sort-alphabetical-ascending</v-icon></v-btn>
                  <v-list-item v-for="optionsetoption in EditOptionSet.Options" :key="optionsetoption.itemObjKey" class="listoutline" @click="OptionNameSelect(optionsetoption)">
                    <v-list-item-content >
                      {{optionsetoption.Name}}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col v-if="!EditOptionSet.IsNumeric">
                      <v-card outlined  height="100%" width="100%" color="rgb(255, 255, 255, 0.9)" v-if="EditOptionSet.Name">                                
                          <v-card-title class="headline blue-grey darken-2 white--text" v-if="Option.Name">
                          Option - {{Option.Name}}
                              <v-switch dark
                                v-if="!TopLevelOptionSet && EditOptionSet.Levels && LevelisAllowed"
                                v-model="Option.HasDeepHierarchy"
                                class="mx-2"
                                label="Has Hierarchy"                                
                              ></v-switch>
                            <span v-if="!TopLevelOptionSet && EditOptionSet.Levels &&!LevelisAllowed" class="caption">You have {{EditOptionSet.Levels.length}} Levels in your hierarchy, and this Option is at the bottom level. If you want to add Options within the Option, you first add another level in your hierarchy</span>
                            <span v-if="TopLevelOptionSet && TopLevelOptionSet.Levels &&!LevelisAllowed" class="caption">You have {{TopLevelOptionSet.Levels.length}} Levels in your hierarchy, and this Option is at the bottom level. If you want to add Options within the Option, you first add another level in your hierarchy</span>
                           
                            <v-switch dark
                              v-if="TopLevelOptionSet && TopLevelOptionSet.Levels && LevelisAllowed"
                              v-model="Option.HasDeepHierarchy"
                              class="mx-2"
                              label="Has Hierarchy"
                              
                            ></v-switch>
                            
                            <v-icon  v-if="Option.HasDeepHierarchy" @click="ConfigureHasDeepHierarchy(Option)">mdi-cogs</v-icon>                            
                          </v-card-title>
                          <v-card-text >
                            <p v-if="Option.ID">Option ID: {{Option.ID}}</p>
                            <v-text-field label="Option Name" v-model="Option.Name" v-if="Option.Name"></v-text-field>
                            <!-- EditOptionSet.HasColors {{EditOptionSet.HasColors}} -->
                            <v-autocomplete 
                              v-if="EditOptionSet.HasColors && Option.Name || EditOptionSet.IsBoard && Option.Name"
                              v-model="Option.Color"
                              class="mx-2"
                              :items="ComputedLibraryColors"
                              label="Color"                              
                            >
                             <template v-slot:item="{ item }">
                                <span :class="item">{{item}}
                                    <p style="font-size:12px;">{{item}}
                                    </p>
                                    </span>
                            </template>
                            </v-autocomplete>
                            <v-layout class="justify-end" v-if="Option.Name"><v-icon @click="DeleteOption(Option)" color="red">mdi-delete-forever</v-icon></v-layout>     
                            <v-text-field v-if="Option.Name" label="Icon" v-model="Option.Icon"/>                              
                            <v-icon v-if="Option.Icon">{{Option.Icon}}</v-icon>   
                            <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                            <template v-slot:activator="{ on }">
                            <v-list-item v-if="Option.Name" v-on="on">
                                <v-avatar :color="Option.Color ? Option.Color.hexa : 'black'" size="30">
                                </v-avatar>
                                <!-- <v-icon left>mdi-palette-advanced</v-icon> -->
                                Color
                                </v-list-item>
                                </template>
                            <v-color-picker v-model="Option.Color"></v-color-picker>
                            </v-menu>                 
                            <v-textarea v-if="Option.Name" label="Description" v-model="Option.Description"
                            />                            
                          </v-card-text>
                          <v-card-text v-if="typeof Option.Name === 'undefined'">
                            Add an option to the left<span v-if="EditOptionSet.Options.length>0">, or select an existing option to change its name</span>...                                                    
                          </v-card-text>                                                  
                      </v-card>
                  </v-col>
              </v-layout>
            </v-tab-item>
            <v-tab>Settings</v-tab>
              <v-tab-item>
                <v-select item-text="Name" :items="EditOptionSet.Options" return-object v-model="EditOptionSet.DefaultOption" label="Default Option">                  
                </v-select>
                <v-select item-text="Name" v-if="EditOptionSet.HasDeepHierarchy && EditOptionSet.Levels && EditOptionSet.DefaultOption && EditOptionSet.DefaultOption.Options" :items="EditOptionSet.DefaultOption.Options" return-object v-model="EditOptionSet.DefaultLevel2Option" label="Default Level 2 Option"/>
                <v-switch 
                v-model="EditOptionSet.HasDeepHierarchy"
                class="mx-2 red--text"
                label="Has Hierarchy"
              ></v-switch>
                </v-tab-item>
                <v-tab v-if="EditOptionSet.HasDeepHierarchy">Hierarchy</v-tab>
              <v-tab-item v-if="EditOptionSet.HasDeepHierarchy">
                <v-list>
                  <v-dialog             			
                      max-width="400px">							
                      <template v-slot:activator="{ on }">
                      <v-btn v-on="on">Add Level</v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        New Level Name
                        </v-card-title>
                        <v-card-text>
                          <v-text-field v-model="EditOptionSet.NewFieldLevelName" label="Name"/>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn>Cancel</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn @click="AddNewFieldLevel()">Save</v-btn>
                        </v-card-actions>  
                    </v-card>
                  </v-dialog>         
                  <v-treeview
                    :items="EditOptionSet.LevelTree"
                  ></v-treeview>
                </v-list>
                </v-tab-item>
                <v-tab v-if="EditOptionSet.HasDeepHierarchy">Illustrated Hierarchy</v-tab>
              <v-tab-item v-if="EditOptionSet.HasDeepHierarchy">
                <v-list>                                      
                  <v-treeview
                  activatable
                    :items="ComputedOptionSetLevelTree"
                  ></v-treeview>
                </v-list>
                </v-tab-item>
          </v-tabs>    
      <v-layout class="justify-end">
      <v-btn fab @click="DeleteFieldOffTabSectionANDRecordType(EditTab,EditSection,EditOptionSet)" icon><v-icon color="red" x-large>mdi-delete-forever</v-icon></v-btn>
      </v-layout>
      </v-img>
     
    </v-card>
  </v-dialog>
  <v-tabs v-if="!OptionsOnly">
    <v-tab>Basic</v-tab>
    <v-tab-item>
      <v-list-item v-if="!NewField || WebForm">
            <v-text-field dense :filled="!WebForm" :disabled="!WebForm" v-model="field.Name" label="Name" />            
        </v-list-item>
        <v-list-item>
            <v-text-field dense v-model="field.DisplayName" label="Display Name" />            
        </v-list-item>
        <v-list-item>
          <v-dialog v-model="IconDialog" max-width="1000px" fullscreen>
          <template v-slot:activator="{ on }">
                   <v-text-field dense v-model="field.Icon" v-on="on" label="Icon" />	
              </template>
              <v-card min-height="600">
                <v-card-text>
                      <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="IconDialog = !IconDialog">Close</v-btn>
                </v-card-actions>
              </v-card>
          </v-dialog>           
        </v-list-item>
        <v-list>
        <v-list-item v-if="field.Type === 'Common Field' && field.DisplayName">
            <v-select dense @change="AssignFieldCommonFieldType(field)" :items="['Physical Address','Postal Address','Email','URL Link', 'QR Link','Telephone Number']" v-model="field.CommonFieldType" label="Common Field Type" />            
        </v-list-item>
        <v-list-item v-if="field.Type === 'Common Field' && !field.DisplayName">
           Please insert a field name in order to select Common Field Type
        </v-list-item>
        <v-list v-if="field.CommonFieldType === 'QR Link' && !field.Refreshing">
          <v-list-item>
              <v-select dense @change="RefreshField(field)" :items="['Current Record','Related Record','Link Field','Static URL']" v-model="field.QRLinkFieldType" label="QR Link Field Type" />            
          </v-list-item>
          <v-list-item v-if="field.QRLinkFieldType === 'Static URL'">
              <v-text-field dense v-model="field.StaticQRURL" label="Insert URL here"></v-text-field>           
          </v-list-item>
          <v-list-item dense v-if="field.QRLinkFieldType === 'Link Field'">
              <v-select dense item-text="Name" return-object :items="CurrentEntityLinkFields" v-model="field.QRLinkFieldLink" label="Insert URL here"></v-select>           
          </v-list-item>
        </v-list>
        <v-list v-if="field.Type === 'Calculated Field'">
      <v-btn @click="AddCalcFieldFormula()">Add</v-btn>
      <v-list-item v-for="(formula,formulaindex) in field.FieldFormulas" :key="formula.itemObjKey">
          <v-list-item-content>
            <v-select @change="RefreshField(field)" v-if="formulaindex !== 0" :items="['integer','field','percentage field']" v-model="formula.input" label="input"></v-select>
            <v-select @change="RefreshField(field)" v-if="formulaindex === 0" :items="['integer','field']" v-model="formula.input" label="input"></v-select>
            </v-list-item-content>
            <v-list-item-content v-if="formula.input === 'field'">
            <v-select  label="field" :items="NonPercentageNumberFields" item-text="DisplayName" v-model="formula.fieldName" return-object></v-select>
            </v-list-item-content>
            <v-list-item-content v-if="formula.input === 'percentage field'">
            <v-select  label="field" :items="PercentageNumberFields" item-text="DisplayName" v-model="formula.fieldName" return-object></v-select>
            </v-list-item-content>                          
          <v-list-item-content v-if="formula.input==='integer'">
            <v-text-field  label="number" type="number" v-model="formula.integer"></v-text-field>
            <!-- <v-text-field label="number" v-if="formula.fieldName" v-model="formula.sampleinteger"></v-text-field> -->
            </v-list-item-content>
            <v-list-item-content>
            <v-checkbox label="add operator" v-model="formula.hasoperation"></v-checkbox>
            </v-list-item-content>
            <v-list-item-content>
            <v-select :items="['+','-','*','/','=']" v-model="formula.operation" @change="CheckOperator(formula.operation,formulaindex-1+2===field.FieldFormulas.length)"></v-select>
            </v-list-item-content>
        </v-list-item>
        
      </v-list>
      <v-list v-if="field.Type === 'Rollup Calculation'">
      <v-list-item>
        <v-list-item-content>
            <v-select @change="RefreshField(field)" :items="['SubCollection','Related Data']" v-model="field.TableType" label="Table Type"></v-select>
            </v-list-item-content>
          <v-list-item-content v-if="field.TableType">
            <v-select @change="RefreshField(field)" :items="RelatedTableOptions" v-model="field.RelatedTable" item-text="Entityid" label="Table"></v-select>
            </v-list-item-content>
        </v-list-item>        
      </v-list>
      <v-list v-if="field.Type === 'Rollup Calculation'">
      <v-btn @click="AddCalcFieldFormula()">Add</v-btn>
      <v-list-item v-for="(formula,formulaindex) in field.FieldFormulas" :key="formula.itemObjKey">
          <v-list-item-content>
            <v-select @change="RefreshField(field)" v-if="formulaindex !== 0" :items="['integer','field','percentage field']" v-model="formula.input" label="input"></v-select>
            <v-select @change="RefreshField(field)" v-if="formulaindex === 0" :items="['integer','field']" v-model="formula.input" label="input"></v-select>
            </v-list-item-content>
            <v-list-item-content v-if="formula.input === 'field'">
            <v-select  label="field" :items="RelatedTableNonPercentageNumberFields" item-text="DisplayName" v-model="formula.fieldName" return-object></v-select>
            </v-list-item-content>
            <v-list-item-content v-if="formula.input === 'percentage field'">
            <v-select  label="field" :items="RelatedTablePercentageNumberFields" item-text="DisplayName" v-model="formula.fieldName" return-object></v-select>
            </v-list-item-content>                          
          <v-list-item-content v-if="formula.input==='integer'">
            <v-text-field  label="number" type="number" v-model="formula.integer"></v-text-field>
            <!-- <v-text-field label="number" v-if="formula.fieldName" v-model="formula.sampleinteger"></v-text-field> -->
            </v-list-item-content>
            <v-list-item-content>
            <v-checkbox label="add operator" v-model="formula.hasoperation"></v-checkbox>
            </v-list-item-content>
            <v-list-item-content>
            <v-select :items="['+','-','*','/','=','Average']" v-model="formula.operation" @change="CheckOperator(formula.operation,formulaindex-1+2===field.FieldFormulas.length)"></v-select>
            </v-list-item-content>
        </v-list-item>
        
      </v-list>
        
    </v-list>
     
     
    <v-row class="justify-center mx-3" v-if="field.Type === 'Route Path'">
      <v-select label="Path Filter" v-model="field.PathFilter" :items="['None','Non Guest Routes','Guest Routes','Public Pages','Data Tables','Internal Pages']" />
    </v-row>
      <v-card-text v-if="field.Type === 'Keyword'">
     <v-select clearable label="Type" placeholder="Select a Keyword Type" outlined dense :items="SiteKeywordTypes" return-object item-text="Name"
     v-model="field.KWType"></v-select>
     <!-- <v-select clearable label="Keyword" placeholder="Select a Keyword from the Type" 
     outlined dense v-if="field.KWType" :items="SiteKeyWordOptionsbyType" return-object
     item-text="Name"
     v-model="field.KeyWord"></v-select> -->
      </v-card-text>
    <v-row class="justify-center" v-if="field.Type === 'Option Set'">
     <v-checkbox @change="UpdateField(field,'IsDynamic',field.IsDynamic)"
        v-model="field.IsDynamic" dense
        label="Is Dynamic"
        hide-details
    ></v-checkbox>
    </v-row>
    
    <v-row class="justify-center" v-if="field.Type === 'Option Set' || field.Type === 'Radio Group' || field.Type === 'Checklist'">
     <v-btn outlined @click="ActivateOptionSetDialog(field)">Options</v-btn>
    </v-row>
    
    <v-row class="justify-center" v-if="field.Type === 'Parent Field'">
        <!-- v-model="field.LookupData" -->
       <v-combobox
              dense
              v-model="field.RelatedRecord"
              :items="RelatedLookupBuilds"
              item-text="RelationshipName"
              hide-selected
              return-object
              label="Parent Table"
              small-chips
              solo
              flat
            ></v-combobox>
            <v-combobox v-if="field.RelatedRecord" dense
              v-model="field.ParentFieldObj"           
              :items="LookupBuildParentFieldTypes"
              item-text="DisplayName"
              hide-selected
              label="Table Field"
              small-chips
              solo
              flat
            ></v-combobox>
    </v-row>
    <v-row class="justify-center" v-if="field.Type === 'Lookup'">
        <!-- v-model="field.LookupData" -->
        <v-select dense
            @change="UpdateRelated(field,field.RelatedBuildID)"
            v-model="field.RelatedBuildID"
            :items="PotentialLookupBuilds"
            class="mx-2"
            item-text="id"
            label="Related Build"
        ></v-select>
    </v-row>
    <v-row class="justify-center" v-if="field.Type === 'Social Lookup'">
        <!-- v-model="field.LookupData" -->
        <v-select dense
            @change="UpdateRelated(field,field.RelatedBuildID)"
            v-model="field.RelatedBuildID"
            :items="$store.state.SocialEntities"
            class="mx-2"
            item-text="id"
            label="Related Build"
        ></v-select>
    </v-row>
    </v-tab-item>
    <v-tab>Configuration</v-tab>
    <v-tab-item>
       <v-btn v-if="field.Type === 'Lookup'" @click="ActivateMappingDialog()">
          Mapping
      </v-btn>
      <v-card-text>
         <v-row class="justify-center">
          <v-col
            v-for="opt in PrimaryOptions" :key="opt.itemObjKey"
            cols="12"
            sm="4"
            md="3"
          >
          <v-checkbox @change="UpdateField(field,opt.value,field[opt.value])"
              v-model="field[opt.value]"
              :label="opt.text"
              hide-details dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="justify-center" v-if="field.Type === 'Single Line Text'">
          <v-checkbox @change="UpdateField(field,'IsPassword',field.IsPassword)"
            v-model="field.IsPassword"
            label="Is Password"
            hide-details
        ></v-checkbox>
        </v-row>
        <v-row class="justify-center" v-if="field.Type === 'Date'">
          <v-switch dense
              @change="UpdateField(field,'GraphicDisplay',field.GraphicDisplay)"
                  v-model="field.GraphicDisplay"
                  :label="'Graphic Display'"
                  hide-details
                ></v-switch>
                <v-select v-if="field.GraphicDisplay" v-model="field.GraphicColor" label="Color"
                :items="LibraryColors"
                />
          <v-checkbox @change="UpdateField(field,'IsPassword',field.IsPassword)"
            v-model="field.IsPassword"
            label="Is Password"
            hide-details
        ></v-checkbox>
        </v-row>
        <v-row class="justify-center">
              <v-col
                v-for="opt in SecondaryOptions" :key="opt.itemObjKey"
                cols="12"
                sm="4"
                md="3"
              >
              <v-switch dense
              @change="UpdateField(field,opt.value,field[opt.value])"
                  v-model="field[opt.value]"
                  :label="opt.text"
                  hide-details
                ></v-switch>
              </v-col>
        </v-row>        
        <v-row class="justify-center" v-if="field.Type === 'Number Field' && field.IsSlider">
          <v-text-field v-model.number="field.Min" label="Min"/>
          <v-text-field v-model.number="field.Max" label="Max"/>
        </v-row>
        <v-row class="justify-center" v-if="field.IsBoard">
          <v-select v-model="field.BoardFilterDates" :items="DateFields" item-text="id"
           multiple></v-select>
        </v-row>
        </v-card-text>
    </v-tab-item>
    <v-tab>Rendering</v-tab>
    <v-tab-item>
      <v-card-subtitle>Tooltip</v-card-subtitle>
      <v-card-text>
      <ContentEditableField
            :FieldObject="field" :FieldName="'Tooltip'"
            :FieldValue="field.Tooltip" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
      </v-card-text>
      <v-list-item>
            <v-list-item-content>
            <v-switch dense
                v-model="field.IsConditionalRender"
                class="mx-2"
                label="Is Conditional Render"
            ></v-switch>
            </v-list-item-content>
            <v-list-item-content v-if="field.IsConditionalRender">
            <v-select :items="['Boolean Match','Option Set Match']" v-model="field.RenderConditionType" label="Render Condition Type"/>
            </v-list-item-content>
            </v-list-item>
        <v-list dense v-if="field.IsConditionalRender && field.RenderConditionType === 'Boolean Match'">
               <v-btn @click="AddConditionalBoolean(field)">Add Conditional Boolean</v-btn>
              <v-list-item v-for="boolean in field.ConditionalBooleans" :key="boolean.itemObjKey">
                <v-list-item-content>
                  <v-select :items="BooleanFields" item-text="Name" v-model="boolean.Field" label="Render Condition Type"/>
                </v-list-item-content>
                <v-list-item-content>
                 <v-switch dense
                  v-model="boolean.Value"
                  class="mx-2"
                  label="Render on True?"
                ></v-switch>
                </v-list-item-content>
                <!-- Okay actually here we can add a boolean but we need to 1. add the boolean field, 2. define if boolean must be "true"  or "false" that's it -->
                
                <!-- <v-select :items="BooleanFields" v-model="field.BooleanConditions" label="Render Condition Type"/> -->
              </v-list-item>
        </v-list>  
        <v-list dense v-if="field.IsConditionalRender && field.RenderConditionType === 'Option Set Match'">
            <v-select :items="OptionSetFields" item-text="id" v-model="field.ConditionalOptionField" label="Option Matches"/>
            <v-select v-if="field.ConditionalOptionField" :items="ConditionalOptionFieldOptions(field.ConditionalOptionField)" multiple item-text="ID" v-model="field.ConditionalOptions" label="Option Matches">
                <template v-slot:item="{ item }">
                              {{item.Name}}
                            </template>
            </v-select>
        </v-list> 
    </v-tab-item>
    <v-tab>Emailing</v-tab>
    <v-tab-item>
      <v-card-text>
      <v-switch label="Email on Update" v-model="field.EmailonUpdate"
      />
      <v-list dense v-if="field.EmailonUpdate">
        <v-list-item>
          <v-select :items="['User','Related User','Email Field','Static']" v-model="field.RecipientType" label="Email From Type"/>
        </v-list-item>
        <v-list-item v-if="field.RecipientType === 'User'">
          <v-autocomplete return-object item-text="Full_Name" :items="EmailUsersArray" v-model="field.RecipientValue" label="Recipient"/>
        </v-list-item>
        <v-list-item v-if="field.RecipientType === 'Related User'">
          <v-select return-object item-text="DisplayName" :items="UserFields" v-model="field.RecipientValue" label="Recipient"/>
        </v-list-item>
         <v-list-item v-if="field.RecipientType === 'Email Field'">
          <v-select return-object item-text="DisplayName" :items="EmailFields" v-model="field.RecipientValue" label="Recipient"/>
        </v-list-item>
         <v-list-item v-if="field.RecipientType === 'Static'">
          <v-text-field :rules="[$store.state.formrules.email]" v-model="field.RecipientValue" label="Recipient"/>
        </v-list-item>
        <v-list-item v-if="field.RecipientValue">
          <v-text-field v-model="field.EmailSubject" label="Email Subject"/>
        </v-list-item>
        <v-list-item>Greeting Line
        </v-list-item>
        <v-list-item class="justify-center">
           <ContentEditableField
            :FieldObject="field" :FieldName="'EmailGreetingLine'"
            :FieldValue="field.EmailGreetingLine" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
         
        </v-list-item>
        <v-list-item>
          <v-autocomplete multiple item-text="id" :items="AllRelatedfields" v-model="field.EmailFormFields" label="Email Object"/>
        </v-list-item>
         <v-list-item>
         <v-switch label="Include Link to Record" v-model="field.EmailincludesLinktoRecord"
      />
        </v-list-item>
        <v-list-item>
         <v-switch label="Include Updating User Name" v-model="field.EmailincludesUpdatingUserName"
      />
        </v-list-item>
        <v-card-text v-if="field.EmailFormFields && field.EmailSubject && field.RecipientValue">
          <div v-html="FieldEmailPreview"/>
        </v-card-text>
       
      </v-list>
      </v-card-text>
    </v-tab-item>
  </v-tabs>
    
</div>
</template>

<script>
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'

export default {
    props: ['field','NewField','System','SystemEntities','CurrentEntity','WebForm','OptionsOnly'],
    components: {
      ContentEditableField
    },
    data(){
        return {
          rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
          showcondmethod: false,
          showtooltip: false,
          show: false,
          reveal: false,

          LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
          IconDialog: false,
          
            MappingDialog: false,
            OptionSetDialog: false,									
            EditOptionSet: '',	
            TopLevelOptionSet: '',								
            OptionName: '',	
            OptionDescription: '',			
            OptionIcon: '',
            OptionColor: '',					
            Option: {},				
            ImportFileSession: {},
            ImportFile: '',
            ImportHeaders: [
              { text: 'Name', value: 'Name'},
              { text: 'dud', value: 'dud'},
            ],
            NewImportsArray: [],
            PartialFailuresArray: [],	
        }
    },
    computed: {
      SiteKeyWordOptionsbyType(){
        return this.SiteKeyWordOptions.filter(kw => {
          return this.field && this.field.KWType ? 
          kw.Type && kw.Type.ID === this.field.KWType.ID : kw
        })
      },
      SiteKeyWordOptions(){
        return this.$store.state.SiteKeyWordOptions
      },
      SiteKeywordTypes(){
        return this.$store.state.SiteKeywordTypes
      },
      DateFields(){
        return this.AllEntityFields.filter(field => {
          return field.Type === 'Date'
        })
      },
      AllEntityFields(){
        return this.CurrentEntity.AllFields.map(field => {
          let fieldobj = Object.assign({},field)
          delete fieldobj.Array
          delete fieldobj.MappedValues
          delete fieldobj.RecordData
          return fieldobj
        })
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      FormFields(){
        return this.field.EmailFormFields.map(field => {
          let fieldobj = this.AllRelatedfields.find(obj => obj.id === field)
          return fieldobj
        })
      },
      FieldEmailPreview(){
        let EmailBody = `<html>
<body>
<head></head>
<h2>`+this.field.EmailSubject+`</h2>`+
`<p>`+this.field.EmailGreetingLine+`</p>
<table>`
             let length = this.FormFields.length
            this.FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.DisplayName+`:</th>
<td align="left">`+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>`
                 } 
            })
          if(this.field.EmailincludesLinktoRecord){
          EmailBody = EmailBody+`<p>To view the details more details, click <a>here</a></p>`  
          }
           if(this.field.EmailincludesUpdatingUserName){
          EmailBody = EmailBody+`<i>This update was made by `+this.userLoggedIn.Full_Name+`</i>`  
          }
          EmailBody = EmailBody+`
</body>
</html>`  
            return EmailBody
      },
      AllRelatedfields(){
        return this.CurrentEntity.AllFields
      },
      EmailFields(){
        return this.CurrentEntity.AllFields.filter(field => {
          return field.Type === 'Common Field' && field.CommonFieldType === 'Email'
        }).map(field => {
          let fieldobj = {
            DisplayName: field.DisplayName,
            Name: field.Name,
            id: field.id
          }
          return fieldobj
        })
      },
      UserFields(){
        return this.CurrentEntity.AllFields.filter(field => {
          return field.Type === 'Lookup' && field.RelatedBuildID === 'Users'
        }).map(field => {
          let fieldobj = {
            DisplayName: field.DisplayName,
            Name: field.Name,
            id: field.id
          }
          return fieldobj
        })
      },
      EmailUsersArray(){
        return this.$store.state.UsersArray.map(user => {
          let userobj = {
            id: user.id,
            Full_Name: user.Full_Name,
            Email: user.Email
          }
          return userobj
        })
      },
      LookupBuildParentFieldTypes(){
        console.log('this.$store.state',this.$store.state,this.field.RelatedRecord)
        if(this.field.RelatedRecord){
          let array = []
          if(this.field.RelatedRecord.RelationshipField.Type === 'Lookup'){
            array = this.SystemEntities
          }
          else if(this.field.RelatedRecord.RelationshipField.Type === 'Social Lookup'){
            array = this.$store.state.SocialEntities
          }
          let entity = array.find(obj => obj.id === this.field.RelatedRecord.id)
          if(entity){
            return entity.AllFields
            // .filter(field => {
            //   return field.Type !== 'Lookup'
            // })
          }
          else{
            return []
          }
        }
        else{
          return []
        }
      },
      RelatedLookupBuilds() {
          let lookupfields = this.CurrentEntity.AllFields.filter(field => {return field.Type === 'Lookup' || field.Type === 'Social Lookup'})          
          lookupfields = lookupfields.map(lufield => {
            let lufieldobj = Object.assign({},lufield)
            delete lufieldobj.Array
            let entityobj ={
              id: lufield.RelatedBuildID,
              DisplayName: lufield.RelatedBuildID.split('_').join(''),
              Primary_Field_Name: lufield.LookupFieldName,
              RelationshipName: lufield.RelatedLocation+' ('+lufield.DisplayName+')',
              RelationshipField: lufieldobj
            }
            console.log('entityobj',entityobj)
            return entityobj
          })
          return lookupfields
      }, 
      RelatedChildBuilds() {
      return this.SystemEntities.filter(build => {
        let lookupfields = build.AllFields.filter(field => {return field.Type === 'Lookup' && field.RelatedBuildID === this.CurrentEntity.id})
        //console.log('lookupfields',lookupfields,build.AllFields,this.CurrentEntity)
        return lookupfields.length > 0
      })
      .map(build => {
          let lookupfields = build.AllFields.filter(field => {return field.Type === 'Lookup' && field.RelatedBuildID === this.CurrentEntity.id})
          
            lookupfields = lookupfields.map(lufield => {
              let entityobj ={
                id: build.id,
                DisplayName: build.DisplayName,
                Primary_Field_Name: build.Primary_Field_Name,
                RelationshipName: build.DisplayName+'('+lufield.DisplayName+')',
                RelationshipField: lufield
              }
              console.log('entityobj',entityobj)
              return entityobj
            }).flat()
            return lookupfields
        })
      }, 
      NonPercentageNumberFields(){
        return this.CurrentEntity.AllFields.filter(field => {
          return field.Type === 'Number Field' && !field.IsPercentage || field.Type === 'Parent Field' && field.ParentFieldObj.Type === 'Number Field' && !field.ParentFieldObj.IsPercentage
        })
      },
      PercentageNumberFields(){
        return this.CurrentEntity.AllFields.filter(field => {
          return field.Type === 'Number Field' && field.IsPercentage
        })
      },
      CurrentEntityLinkFields(){
        if(this.CurrentEntity && this.CurrentEntity.AllFields){
          return this.CurrentEntity.AllFields.filter(field => {
            return field.Type === 'Common Field' && field.Type === 'URL Link'
          })
        }
        else{
          return []
        }
      },
      ComputedLibraryColors(){
      let arr1 = this.LibraryColors
      let arr2 = [
        'primary',
        'accent',
        'secondary',
        'pop',
        'morning',
        'night',
        'fieldcard',
        'links',
        'background',
      ]
      return arr1.concat(arr2)
    },
      AdvancedComputedLibraryColors(){
      let arr1 = this.LibraryColors
      let arr2 = [
        'primary',
        'accent',
        'secondary',
        'pop',
        'morning',
        'night',
        'fieldcard',
        'links',
        'background',
      ]
      let arr3 = arr1.concat(arr2)
      let arr4 = []
      arr3.map(color => {
        arr4.push(color)
        arr4.push(color+' lighten-1')
        arr4.push(color+' lighten-2')
        arr4.push(color+' lighten-3')
        arr4.push(color+' lighten-4')
        arr4.push(color+' lighten-5')
        arr4.push(color+' darken-1')
        arr4.push(color+' darken-2')
        arr4.push(color+' darken-3')
        arr4.push(color+' darken-4')
        arr4.push(color+' darken-5')
        arr4.push(color+' accent-1')
        arr4.push(color+' accent-2')
        arr4.push(color+' accent-3')
        arr4.push(color+' accent-4')
      })
      return arr4
    },
      EditOptionSetHasIntervals(){
        if(this.EditOptionSet){
          return  typeof this.EditOptionSet.Intervals !== 'undefined' && typeof this.EditOptionSet.StartingInt !== 'undefined' && typeof this.EditOptionSet.EndingInt !== 'undefined'
        }
      },
      HeaderNames() {
        return this.ImportHeaders.map(header => {
          return header.value.split('.')[0]
        }).filter(header => {
          return header !== 'id'
        })
      },
        HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
        let correctheader = header
          return correctheader
        })
      },
      LevelisAllowed(){ 
      console.log(this.Option)
      console.log(this.TopLevelOptionSet)
      if(!this.TopLevelOptionSet){
          console.log(this.EditOptionSet)
          if(this.EditOptionSet && this.EditOptionSet.Levels && this.Option && this.Option.Level && this.Option.Level.id < this.EditOptionSet.Levels.length || this.EditOptionSet && this.EditOptionSet.Levels && this.Option && !this.Option.Level){
          return true
          }
          else{
          return false
          }
      }
      else if(this.TopLevelOptionSet && this.TopLevelOptionSet.Levels && this.Option && this.Option.Level && this.Option.Level.id < this.TopLevelOptionSet.Levels.length){
          
          return true
          }
          else{
          return false
          }
      },
      BooleanFields(){
          return this.CurrentEntity.AllFields.filter(field => {
              return field.Type === 'Boolean'
          })
      },
      OptionSetFields(){
          return this.CurrentEntity.AllFields.filter(field => {
              return field.Type === 'Option Set'
          })
      },
      RelatedTableNonPercentageNumberFields(){
        return this.RelatedTableFields.filter(field => {
          return field.Type === 'Number Field' && !field.IsPercentage || field.Type === 'Calculated Field' && !field.IsPercentage
        })
      },
      RelatedTablePercentageNumberFields(){
        return this.RelatedTableFields.filter(field => {
          return field.Type === 'Number Field' && field.IsPercentage
        })
      },
      RelatedTableFields(){
        if(this.field.RelatedTable && this.field.TableType === 'Related Data'){
          let entity = this.SystemEntities.find(obj => obj.id === this.field.RelatedTable)
          if(entity){
            return entity.AllFields.filter(field => {
              return field.Type === 'Number Field' || field.Type === 'Calculated Field'
            })
          }
        }
        else if(this.field.RelatedTable && this.field.TableType === 'SubCollection'){
          let entity = this.CurrentEntity.SubCollections.find(obj => obj.id === this.field.RelatedTable)
          console.log('entity',entity)
          if(entity){
            return entity.Headers.filter(field => {
              return field.Type === 'Number Field' || field.Type === 'Calculated Field'
            })
          }
        }
      },
      RelatedTableOptions(){
        if(this.field.TableType){
          console.log(this.SubCollectionTabs,this.CurrentEntity,this.CurrentEntity.SingleBuildTabs)
          return this[this.field.TableType.split(' ').join('')+'Tabs']
        }
      },
      RelatedDataTabs(){
        return this.CurrentEntity.SingleBuildTabs.map(tab => {
          return tab.Elements
        }).flat()
        .filter(elmnt => {
          return elmnt.DataViewName === 'Data Single Related Data'
        })
      },
      SubCollectionTabs(){
        return this.CurrentEntity.SingleBuildTabs.map(tab => {
          return tab.Elements
        }).flat()
        .filter(elmnt => {
          return elmnt.DataViewName === 'Data Single SubCollection'
        })
      },
      PotentialLookupBuilds(){
          return this.SystemEntities
          .filter(entity => {
              return entity.RecordPrimaryField
          })
          
          // .map(entity => {
          //     let lookupobj = {Name: entity.DisplayName, LookupFieldName: entity.RecordPrimaryField.Name, RelatedRecord: entity.DisplayName.split(' ').join(''), RelatedLocation: entity.SingleName.split(' ').join(''), RelatedBuildID: entity.id, IsRelationshipField:true, IsHyperlink: true}
          //     return lookupobj
          // })
      },
      PrimaryOptions(){
          return [
              {
                  text: 'Is Mandatory',
                  value: 'IsMandatory'
              },
              {
                  text: 'Is Search',
                  value: 'IsSearch'
              },
              {
                  text: 'Is Header',
                  value: 'IsHeader'
              },
              {
                  text: 'Read Only',
                  value: 'ReadOnly'
              },
              
          ]
      },
      IsStoreBrandEntity(){
        let boolean = false
        this.SystemEntities.filter(ent => {
          return ent.Entity_Type && ent.Entity_Type.Name === 'Inventory Article'
        })
        .map(ent => {
          let match = ent.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id && obj.id === 'Store_Product_Brand')
          if(match){
            boolean = true
          }
        })
        return boolean
      },
      SecondaryOptions(){
          let field = this.field
          if(field.Type === 'Option Set' || field.Type === 'Global Option Set'){
              return [
                  {
                  text: 'Secondary',
                  value: 'IsSecondary'
                  },
                  {
                      text: 'Has Colors',
                      value: 'HasColors'
                  },
                  {
                      text: 'Is Board',
                      value: 'IsBoard'
                  },
                  {
                      text: 'Is Filter',
                      value: 'IsFilter'
                  },
                  {
                      text: 'Primary Description',
                      value: 'PrimaryDescription'
                  },
              ]
          }
          else if(field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Option Set'){
              return [
                  {
                      text: 'Is Filter',
                      value: 'IsFilter'
                  },
              ]
          }
          else if(field.Type === 'Lookup'){
            return [
              {
                text: 'Is Multiple',
                value: 'IsMultiple'
              }
            ]
          }
          else if(field.Type === 'Multiple Lines Text'){
              let arr1 =  [
                  {
                      text: 'Is Content Editable',
                      value: 'IsContentEditable'
                  },
                  {
                      text: 'Primary Description',
                      value: 'PrimaryDescription'
                  },
              ]
              let arr2 = []
              if(this.IsStoreBrandEntity){
                arr2 = 
                  [{
                      text: 'Store Writeup',
                      value: 'Store_Writeup'
                  }]
                
              }
              return arr1.concat(arr2)
          }
          else if(field.Type === 'Number Field' || field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation' ||
          field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Number Field' ||
          field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Calculated Field'){
              return [
                  {
                      text: 'Is Percentage',
                      value: 'IsPercentage'
                  },
                  {
                      text: 'Is Currency',
                      value: 'IsCurrency'
                  },
                  {
                      text: 'Is Slider',
                      value: 'IsSlider'
                  }
              ]
          }
          else if(field.Type === 'Date'){
              return [
                  {
                      text: 'Has Time Input',
                      value: 'HasTimeInput'
                  },
                  
              ]
          }
          else if(field.Type === 'Shared Doc Link'){
              return [
                  {
                      text: 'Allows Posts',
                      value: 'AllowsPosts'
                  },
                  {
                      text: 'Allows Historic',
                      value: 'AllowsHistoric'
                  },
              ]
          }
            else if(field.Type === 'Single File Upload'){
              let arr1= [
                  {
                      text: 'Featured Photo',
                      value: 'FeaturedPhoto'
                  },
              ]
              let arr2 = []
              if(this.IsStoreBrandEntity && field.FeaturedPhoto){
                arr2.push({
                      text: 'Store Brand Logo',
                      value: 'Store_Brand_Logo'
                  },)
              }
              return arr1.concat(arr2)
          }
          else if(field.Type === 'Common Field' && field.CommonFieldType === 'URL Link' && this.IsStoreBrandEntity){
            return [{
                      text: 'Store Read More',
                      value: 'Store_Read_More'
                  }]
          }
          
      }
    },
    created(){

    },
    methods:{
      ConfirmSortOptionsAlphateically(Options){
        confirm("This will sort these Options alphabetically but the ID's will remain the same. Continue?") && this.SortOptionsAlphateically(Options)
      },
      SortOptionsAlphateically(Options){
        Options = Options.sort((a, b) => {
            var key1 = a.Name;
            var key2 = b.Name;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },
      DeleteOption(option){
      let index = this.EditOptionSet.Options.indexOf(option)
      confirm('Are you sure you want to remove this option set? There is no going back, and any existing data with this value assigned will have no data') && this.EditOptionSet.Options.splice(index,1)
      this.Option = {}
      this.SaveOptionSets(true)
    },
      DeleteMappingVerify(mapping){
            confirm('Do you want to delete this mapping?') && this.ProcessDeleteMapping()
        },
        ProcessDeleteMapping(mapping){
            let index = this.field.MappedValues.indexOf(mapping)
            this.field.MappedValues.splice(index,1)
        },
        SelectedEntityFields(mapping){
            if(this.field && mapping.OutputField){
                let entity = this.SystemEntities.find(obj => obj.id === this.field.RelatedBuildID)
                return entity.AllFields
                .filter(field => {
                    return field.Type === mapping.OutputField.Type
                })
                .filter(field => {
                    if(field.Type === 'Lookup'){
                        return field.RelatedBuildID === mapping.OutputField.RelatedBuildID
                    }
                    else if(field.Type === 'Common Field'){
                        return field.CommonFieldType === mapping.OutputField.CommonFieldType
                    }
                    else{
                        return field
                    }
                })
                .map(field => {
                  let fieldobj = Object.assign({},field)
                  delete fieldobj.Array
                  delete fieldobj.MappedValues
                  delete fieldobj.RecordData
                  return fieldobj
                })
            }
            else{
                return []
            }
        },
        AddMappingtoParentField(){
            if(!this.field.MappedValues){
                this.field.MappedValues = []
            }
            let newmapping = {

            }
            this.field.MappedValues.push(newmapping)
            this.ToggleMappingDialog()
        },
        SaveMapping(){
            this.MappingDialog = false
        },
        CancelMappingDialog(){
            this.MappingDialog = false
        },
        ActivateMappingDialog(){
            this.MappingDialog = true
            
        },
        ToggleMappingDialog(){
            this.MappingDialog = false
            setTimeout(() => {
                this.MappingDialog = true
            }, 50);
        },
      AddCalcFieldFormula(){
      let NewFormula = {input: '',integer: 0,operation: '',hasoperation: true}
      if(this.field && this.field.FieldFormulas){
        this.field.FieldFormulas.push(NewFormula)
        console.log(this.field.FieldFormulas)
      }
      else{
        this.field.FieldFormulas = []
      this.field.FieldFormulas.push(NewFormula)
      console.log(this.field.FieldFormulas)
      }
      this.RefreshField(this.field)
      // this.CalcualtedFieldDialog = false
      // setTimeout(() => {
      //  this.CalcualtedFieldDialog = true 
      // }, 1000);
      
    },
    CheckOperator(formulaoperation,formulaend){
      if(formulaoperation === '=' || formulaoperation === 'Average'){
        this.RefreshField(this.field)
      }
      else{
        if(formulaend){
          this.AddCalcFieldFormula(this.field)
        }
        
      }
    },
      RefreshField(field){
        this.$emit('RefreshField',field)
      },
      AssignFieldCommonFieldType(field){
        if(!field.Name){
          field.Name = field.DisplayName.split(' ').join('_')
        }
        let fieldtype = field.Type
        if(fieldtype === 'Common Field'){
          delete field.FieldBreakdown
          delete field.StaticQRURL
          delete field.QRLinkFieldType
          delete field.QRLinkFieldLookup
          delete field.QRLinkFieldLink
       field.CommonFieldType = field.CommonFieldType
      
        if(field.CommonFieldType === 'Physical Address'){
          if(!field.Name){
            field.Name = field.DisplayName.split(' ').join('_')
          }
        this.AddPhysicalAddressBreakdown(field)
          
        }
        if(field.CommonFieldType === 'Postal Address'){
          this.AddPostalAddressBreakdown(field)
        }
        if(field.CommonFieldType === 'Email'){
          // field.DisplayName = 'Primary Email'
        }
        if(field.CommonFieldType === 'Telephone Number'){
          // field.DisplayName = 'Primary Email'
        }
        if(field.CommonFieldType === 'URL Link'){
          // field.DisplayName = 'Primary Email'
        }
        if(field.CommonFieldType === 'QR Link'){
          if(this.StaticQRURL){
            field.StaticQRURL = this.StaticQRURL
          }
          if(this.QRLinkFieldType){
          field.QRLinkFieldType = this.QRLinkFieldType            
          }
          if(this.QRLinkFieldLookup){
          field.QRLinkFieldLookup = this.QRLinkFieldLookup            
          }
          if(this.QRLinkFieldLink){
          field.QRLinkFieldLink = this.QRLinkFieldLink            
          }
          // field.DisplayName = 'Primary Email'
        }
      }
      },
      AddPhysicalAddressBreakdown(field){
      // field.Name = 'Physical Address'
      //     field.DisplayName = 'Physical Address'
          let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
          let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
          let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
          let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
          let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
          let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
          let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
          field.FieldBreakdown = []
          field.FieldBreakdown.push(StreetNumber)
          field.FieldBreakdown.push(StreetName)
          field.FieldBreakdown.push(Suburb)
          field.FieldBreakdown.push(City)
          field.FieldBreakdown.push(PostalCode)
          field.FieldBreakdown.push(StateProvince)
          field.FieldBreakdown.push(CountryRegion)
          console.log(field)
    },
    AddPostalAddressBreakdown(field){
      // field.Name = 'Postal Address'
      //     field.DisplayName = 'Postal Address'
          let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
          let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
          let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
          let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
          let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
          let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
          let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
          field.FieldBreakdown = []
          field.FieldBreakdown.push(StreetNumber)
          field.FieldBreakdown.push(StreetName)
          field.FieldBreakdown.push(Suburb)
          field.FieldBreakdown.push(City)
          field.FieldBreakdown.push(PostalCode)
          field.FieldBreakdown.push(StateProvince)
          field.FieldBreakdown.push(CountryRegion)
    },
      PopulateOptionSetViaIntervals(EditOptionSet){
        let diff = EditOptionSet.EndingInt - EditOptionSet.StartingInt
        let length = diff/EditOptionSet.Intervals
        EditOptionSet.Options = []
        for(var x = 0; x <= length; x++){
          let int = 0
          // Still actualy need to check if interval is 0, in case range goes from below 0 to over 0
          // if(x === 0){
          //   int = EditOptionSet.StartingInt
          // }
          // else{
          //   int = EditOptionSet.StartingInt+((x-1+2)*EditOptionSet.Intervals)
          // }
          int = EditOptionSet.StartingInt+((x)*EditOptionSet.Intervals)
          let option = {
            ID: 1000000+x-1+2,
            Name: int
          }
          EditOptionSet.Options.push(option)
        }
        console.log(EditOptionSet)
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      console.log(this.field,FieldObject,this.Record)
    //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
    //   alert('should have passed updateemit')
    },
      onImportfileSelected(event){
      this.ImportFile = event.target.files[0]
    },
    DownloadOptionSetOptionsImportTemplate(){
    let copyText = this.HeaderNamesSplit

    var csvfilename = 'FieldsImportTemplate.csv'
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
    element.setAttribute('download', csvfilename)
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // }
  },
    saveImport() {
      let importLocalURL = URL.createObjectURL(this.ImportFile)
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this
      reader.addEventListener('load', function(e) {
            var text = e.target.result;
            vm.CSVConvertToJSON(text)
      });
    },
    closeImport(){

      this.ImportFile = null
      this.ImportFileSession.Name = ''
    },
    CSVConvertToJSON(text){
     
      this.NewImportsArray = []
      this.PartialFailuresArray = []
      
      let lines = text.split("\n")
      this.CheckDelimiter(lines)
      
    },

    CheckDelimiter(lines){
      console.log(lines)
      let headertest = lines[0].split("|");
      console.log('headertest')
      console.log(headertest)
      if(headertest.length === 1){
        console.log('used ,')
        this.ConversionCSVtoJJSON(lines,'|')
        
      }
      else{
        console.log('used ;')
        this.ConversionCSVtoJJSON(lines, '|')
        
      }
      
    },
    ConversionCSVtoJJSON(lines,delimiter){
      let dataonly = Object.assign([],lines)
      dataonly.splice(0,1)
      console.log(dataonly)
      console.log('delimiter')
      console.log(delimiter)
      this.CSVResult = []
      this.ImportHeaders=lines[0].split(delimiter);
      console.log('this.ImportHeaders')
      console.log(this.ImportHeaders)
      for(var i=1;i-1+2<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)


    }
      this.CSVResult.map((res,i) => {          
          delete res.dud
          console.log('res')
          console.log(res)
          this.AddOptionSetOption(true,res)       
        
      })
    },
        ActivateOptionSetDialog(field,ContentBlockObject){									
            this.EditOptionSet = field									
            if(!this.EditOptionSet.Options){									
            this.EditOptionSet.Options = []									
            }									
            this.OptionSetDialog = true
            console.log(this.EditOptionSet)									
        },									
        AddOptionSetOption(imp,imopt){
      //console.log(this.EditOptionSet,this.TopLevelOptionSet)
      let integer = 0
      let level1 = ''
      let level2 = ''
      let level3 = ''
      let level4 = ''
      if(this.EditOptionSet.Levels){
        level1 = this.EditOptionSet.Levels.find(obj => obj.id === 1)
        level2 = this.EditOptionSet.Levels.find(obj => obj.id === 2)
        level3 = this.EditOptionSet.Levels.find(obj => obj.id === 3)
        level4 = this.EditOptionSet.Levels.find(obj => obj.id === 4)
      }
      else if(this.TopLevelOptionSet && this.TopLevelOptionSet.Levels){
        level1 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 1)
        level2 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 2)
        level3 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 3)
        level4 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 4)
      }
      
      
      if(!this.TopLevelOptionSet){
        //alert('this is top'+this.EditOptionSet.lastassignedinteger)
        if(typeof this.EditOptionSet.lastassignedinteger === 'undefined'){
          this.EditOptionSet.lastassignedinteger = 1000000        
        }
        integer = this.EditOptionSet.lastassignedinteger-1+2
        this.EditOptionSet.lastassignedinteger = integer
        // if(this.EditOptionSet.HasDeepHierarchy){          
        //   this.EditOptionSet.Level = level1
        // }
      }
      else{
        //alert('this is NOT top'+this.TopLevelOptionSet.lastassignedinteger)
        integer = this.TopLevelOptionSet.lastassignedinteger-1+2
        this.TopLevelOptionSet.lastassignedinteger = integer
      }
           
       
       let NewOption = {ID: integer}
       if(this.EditOptionSet.HasDeepHierarchy || this.EditOptionSet.Level){
         if(!this.TopLevelOptionSet){
            NewOption.Level = level1
            NewOption.LevelFieldName = level1.FieldName
         }
         else if(this.EditOptionSet.Level && this.EditOptionSet.Level.id === 1){
            NewOption.Level = level2
            NewOption.LevelFieldName = level2.FieldName
          }
          else if(this.EditOptionSet.Level && this.EditOptionSet.Level.id === 2){
            NewOption.Level = level3
            NewOption.LevelFieldName = level3.FieldName
          }
          else if(this.EditOptionSet.Level && this.EditOptionSet.Level.id === 3){
            NewOption.Level = level4
            NewOption.LevelFieldName = level4.FieldName
          }
      }
      if(imp){
        NewOption.Name = imopt.Name
      }
      else{
        NewOption.Name = this.OptionName
        if(this.OptionDescription){
          NewOption.Description = this.OptionDescription
        }
        if(this.OptionIcon){
          NewOption.Icon = this.OptionIcon
        }
        if(this.OptionColor){
          NewOption.Color = this.OptionColor
        }
        
      }
      
      this.EditOptionSet.Options.push(NewOption)
      this.OptionName = ''  
      this.OptionDescription = ''    			
      this.OptionIcon = ''
      this.OptionColor = ''
      // console.log(this.TopLevelOptionSet)
      // console.log(this.SingleBuildTabs)
      this.ImportFile = ''
     
    },
    AddNewFieldLevel(){
      if(!this.EditOptionSet.LevelTree){
        this.EditOptionSet.LevelTree = []
        this.EditOptionSet.Levels = []
      }
      let level1 = this.EditOptionSet.LevelTree.find(obj => obj.id === 1)
      let level2 = ''
      let level3 = ''
      let level4 = ''
      if(level1){
        level2 = level1.children.find(obj => obj.id === 2)
        if(level2){
          level3 = level2.children.find(obj => obj.id === 3)
          if(level3){
            level4 = level3.children.find(obj => obj.id === 4)
          }
        }
      }
      let NewLevel = {        
        name: this.EditOptionSet.NewFieldLevelName,
        FieldName: this.EditOptionSet.NewFieldLevelName.split(' ').join('_'),
        children: []
      }
      if(!this.EditOptionSet.LastLevel){
       
        NewLevel.id = 1
        this.EditOptionSet.LastLevel = 1
        this.EditOptionSet.Levels.push(NewLevel)
        this.EditOptionSet.LevelTree.push(NewLevel)
      }
      else if(this.EditOptionSet.LastLevel === 1){
          NewLevel.id = 2
          this.EditOptionSet.LastLevel = 2
          this.EditOptionSet.Levels.push(NewLevel)
          level1.children.push(NewLevel)
        }  
      else if(this.EditOptionSet.LastLevel === 2){
          NewLevel.id = 3
          this.EditOptionSet.LastLevel = 3
          this.EditOptionSet.Levels.push(NewLevel)
          level2.children.push(NewLevel)
        } 
        else if(this.EditOptionSet.LastLevel === 3){
          NewLevel.id = 4
          this.EditOptionSet.LastLevel = 4
          this.EditOptionSet.Levels.push(NewLevel)
          level3.children.push(NewLevel)
        }
        
      delete this.EditOptionSet.NewFieldLevelName
      this.OptionSetDialog = false
      setTimeout(() => {
            this.OptionSetDialog = true
      }, 300);
      
    },
    ConfigureHasDeepHierarchy(optionsetoption){
      let level1 = ''
      let level2 = ''
      let level3 = ''
      let level4 = ''
      if(this.EditOptionSet.Levels){
        level1 = this.EditOptionSet.Levels.find(obj => obj.id === 1)
        level2 = this.EditOptionSet.Levels.find(obj => obj.id === 2)
        level3 = this.EditOptionSet.Levels.find(obj => obj.id === 3)
        level4 = this.EditOptionSet.Levels.find(obj => obj.id === 4)
      }
      else if(this.TopLevelOptionSet && this.TopLevelOptionSet.Levels){
        level1 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 1)
        level2 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 2)
        level3 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 3)
        level4 = this.TopLevelOptionSet.Levels.find(obj => obj.id === 4)
      }
      if(!this.TopLevelOptionSet){
      this.TopLevelOptionSet = this.EditOptionSet  
      optionsetoption.Level = level1
      optionsetoption.LevelFieldName = level1.FieldName
      }
       else if(this.EditOptionSet.Level === 1){
        optionsetoption.Level = level2
        optionsetoption.LevelFieldName = level2.FieldName
      }
      else if(this.EditOptionSet.Level === 2){
        optionsetoption.Level = level3
        optionsetoption.LevelFieldName = level3.FieldName
      }
      else if(this.EditOptionSet.Level === 3){
        optionsetoption.Level = level4
        optionsetoption.LevelFieldName = level4.FieldName
      }
      if(!optionsetoption.Options){
        optionsetoption.Options = []
      }
      // if(!optionsetoption.DeepHierarchyName){
      //   optionsetoption.DeepHierarchyName = optionsetoption.DeepHierarchyDisplayName.split(' ').join('_')
      // }
      
      this.EditOptionSet = Object.assign({},optionsetoption)
      console.log(this.TopLevelOptionSet)
      console.log(this.EditOptionSet)
      this.Option = ''
    },									
        OptionNameSelect(option){									
        this.Option = option									
        },									
        CancelOptionSetDialog(){
            this.OptionSetDialog = false
            this.EditOptionSet = {}
            this.TopLevelOptionSet = ''
            this.Option = {}
            },								
        SaveOptionSets(fieldconfig){	
            if(!fieldconfig){
            let fieldobj = this.EditedTab.FormFields.find(obj => obj.Name === this.EditOptionSet.Name)									
            let fieldindex = this.EditedTab.FormFields.indexOf(fieldobj)									
            this.EditedTab.FormFields[fieldindex].Options = this.EditOptionSet.Options									
            this.OptionSetDialog = false									
            this.NewContentDialog = false									
            this.NewContentDialog = true
            }	
            else{
                this.CancelOptionSetDialog()
            }							
                                                
        },	
        ConditionalOptionFieldOptions(optionset){
          console.log(optionset)
            let field = this.OptionSetFields.find(obj => obj.id === optionset.split('.Name').join(''))
            return field ? field.Options : []
        },
        AddConditionalBoolean(field){
            if(!field.ConditionalBooleans){
                field.ConditionalBooleans = []
            }
            field.ConditionalBooleans.push({
                Field: '',
                Value: true
            })
            field.IsConditionalRender = false
            setTimeout(() => {
                field.IsConditionalRender = true
            }, 200);
            console.log(field)
            },
        UpdateRelated(field,RelatedBuildID){
            this.$emit('UpdateRelated',field,RelatedBuildID)
        },
        Onepropemit(MethodName,prop){
            this.$emit('Onepropemit',MethodName,prop)
        },
        UpdateFieldOptions(field){
            console.log(field)
            let intname = 'lastassignedinteger'
            field[intname] = field[intname]-1+2
            field.Options = field.Options.map(option => {
                let optionobj = Object.assign({},option)
                if(!option.ID){
                optionobj = {
                ID: field[intname],
                Name: option
                }
                }        
                return optionobj
            }) 
        },
        UpdateField(field,prop,value){
          if(field.Type === 'Date' && prop === 'GraphicDisplay' && !field.GraphicColor){
            field.GraphicColor = 'grey'
          }
            this.$emit('UpdateField',field,prop,value)
        }
    }

}
</script>

<style>

</style>