
<template>
    <v-main>
        <!-- <v-layout column class="chat-container" id="chatbtn" v-if="$route.name !== 'ChatbotBuilder'">
    <v-btn fab @click="OpenMessageDialog()" dark color="green">
      <v-icon>mdi-robot</v-icon>
    </v-btn>
  </v-layout> -->
      <v-dialog v-model="SearchView" max-width="1400">
        <SiteSearch :UserRecord="UserRecord"  :ActiveSearch="ActiveSearch" />
      </v-dialog>
          <v-card id="messagebox" class="messagebox" :style="MessageBoxPadRight" :width="MiniView ? 270 : 400" v-if="MessageDialog">
  
          <v-card class="messageboxheader" >
            <!-- <v-img height="80" src="@/assets/RABaseBG.jpeg"> -->
            <v-list two-line class="transparent">
              <v-list-item>
                
                  <v-list-item-avatar  size="35">
              <v-img contain :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img contain src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="MessagetoUser.Full_Name"></v-list-item-title>
                    <v-list-item-subtitle v-text="MessagetoUser.onlinestatus"></v-list-item-subtitle>
                  </v-list-item-content>
            
            <v-spacer>
                </v-spacer>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-phone</v-icon></v-btn>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-video</v-icon></v-btn>
                  <v-btn icon @click="ToggleChatbotActive()"><v-icon color="red">mdi-close</v-icon></v-btn>
                  
        
              </v-list-item>
              
            </v-list>
            <!-- </v-img> -->
          </v-card>
      <!--  :style="BGImageCurrent" -->
             <v-list class="messagelist" height="70%" id="messagelist" flat>
               <v-list-item >
                 <v-list flat class="transparent" width="100%">
                   <v-list-item :dark="AppisDarkMode" class="justify-center">
                       
                 <v-list-item-avatar size="100" style="align-self:flex-start;">
                   <v-img contain :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                 </v-list-item-avatar>              
                   </v-list-item>
                   <v-list-item >
                 <v-list-item-content class="grey lighten-3">
                   <span style="padding-left:10px;padding-right:1px;">
                      Hello, I am a Chatbot here to help you.
                      
                      <p class="caption" >
                           I can do plenty, you should really start by typing Hello:
                       </p>
                       
                       <!-- <v-row style="align-self:flex-end;" class="justify-start mx-0 my-10" v-if="message.options && message.options.length>0">
                           <v-chip @click="CheckMeOut(opt,false)" dark color="blue" small>None of these Match</v-chip>
                       </v-row>
                       <v-select v-if="message.dynamicoptions" v-model="message.selectedoption" item-text="Name" :items="message.dynamicoptions" :label="message.DynamicLabel" return-object />
                        <span v-if="message.selectedoption" v-html="message.selectedoption.message">
                        </span> -->
                   </span>
                   
                 </v-list-item-content>
               </v-list-item>
              
                 </v-list>
               </v-list-item>
               <v-list-item :class="message.ReadType === 'Received' ? 'justify-start' :  'justify-end'" style="width:100%;" v-for="message in ConversationAscending" :key="message.itemObjKey">
                 <v-list flat class="transparent" >
                   
                   <v-list-item :dark="AppisDarkMode" class="justify-center"  v-if="message.ReadType === 'Received'">
                       
                 <v-list-item-avatar size="100"  style="align-self:flex-start;">
                   <v-img contain :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img contain src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                 </v-list-item-avatar>
              
                   </v-list-item>
                   
                   <v-list-item :style="message.ReadType === 'Received' ? '' : 'padding-top:20px;'" :dark="message.dark" width="100%" >
                 <v-list-item-content :class="message.Color">
                   <v-list-item-title style="margin-bottom:10px;padding:10px;" class="blue white--text mediumoverline justify-center" v-if="message.Type && message.Type.Name && message.Type.Name === 'Virtual Assist'">
                         <span ><v-icon color="white" class="actionicon">mdi-home-analytics</v-icon>Virtual Assist Mode</span>                         
                       </v-list-item-title>                     
                   <span style="padding-left:10px;padding-right:1px;">
                    
                       <span v-html="message.message">
                       </span>
                       
                       <span class="caption" v-if="message.options && message.options.length>0 && !message.message">
                           Try one of the options below:
                       </span>
                       <div style="padding-top:40px;" v-if="message.options && message.options.length>0">
                         <v-list-item-subtitle>
                           Options:
                         </v-list-item-subtitle>
                        <v-divider></v-divider> 
                          <v-chip-group dark>
                            <v-chip x-small color="blue">General</v-chip><v-chip x-small color="pink">How To</v-chip><v-chip x-small color="purple">System Technical</v-chip><v-chip x-small color="green">Virtual Assist</v-chip>
                          </v-chip-group> 
                          <v-divider></v-divider>
                        <v-chip-group column class="justify-start mx-0 my-1">
                           <v-chip @click="CheckMeOut(opt,true)" dark :color="opt.ChipColor" small
                            v-for="opt in message.options" :key="opt.itemObjKey">{{opt.Title}}</v-chip>
                        </v-chip-group>
                        <v-divider></v-divider>
                       </div>
                       <v-row style="align-self:flex-end;" class="justify-start mx-0 my-10" v-if="message.options && message.options.length>0">
                           <v-chip @click="NoComprehendoMSG(SentMessages[SentMessages.length-1].message)" dark color="links">None of these Match</v-chip>
                       </v-row>
                       <v-select v-if="message.dynamicoptions" v-model="message.selectedoption" item-text="Name" :items="message.dynamicoptions" :label="message.DynamicLabel" return-object />
                        <span v-if="message.selectedoption" v-html="message.selectedoption.message">
                        </span>
                   </span>
                   <v-list-item>
                 <span v-if="message.CanSearch">
                         <p class="caption">
                           Did you want to perform a data search?
                         </p>
                         <v-text-field dense v-model="message.DefaultSearch" label="Enter Search"/>
                           <v-btn x-small @click="ActivateSearch(message.DefaultSearch)">go</v-btn>
                       </span>
               </v-list-item>
                 </v-list-item-content>
               </v-list-item>
               
                 </v-list>
               </v-list-item>   
               <v-list-item  v-if="VirtualAssistThinking">
                 <v-list flat class="transparent" width="100%">
                   
                   <v-list-item class="justify-center">
                       
                 <v-list-item-avatar size="100"  style="align-self:flex-start;">
                   <v-img contain :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                 </v-list-item-avatar>
              
                   </v-list-item>
                   
                   <v-list-item >
                 <v-list-item-content class="grey lighten-3">
                   <v-list-item-title style="margin-bottom:10px;padding:10px;" class="blue white--text mediumoverline justify-center">
                         <span ><v-icon color="white" class="actionicon">mdi-home-analytics</v-icon>Virtual Assist Mode</span>                         
                       </v-list-item-title>                     
                   <span style="padding-left:10px;padding-right:1px;">
                       
                      <div class="col-3 justify-center">
                      <div class="snippet" data-title=".dot-flashing">
                        <div class="stage">
                          <div class="dot-flashing"></div>
                        </div>
                      </div>
                    </div>
                      
                   </span>
                   
                 </v-list-item-content>
               </v-list-item>
               <v-list-item>
                 <v-list-item-subtitle  style="font-size:10px">
                   Processing...
                 </v-list-item-subtitle>
               </v-list-item>
                 </v-list>
               </v-list-item>        
             </v-list>
             <v-list>
                <v-list-item>
                  
                <input  class="messagepost mx-3" style="width:80%" type="text" id="messageinputchip"  @keydown.enter="CreateMessage(MessagetoUser,UserRecord)"  placeholder="Type Question">
                <v-icon>mdi-share</v-icon>
                </v-list-item>
                <v-list-item>
                <v-btn @click="ClearChat()" small color="blue" dark>Clear Chat</v-btn>
               </v-list-item>
                 </v-list>
             
        </v-card>
    </v-main>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
import SiteSearch from '@/components/SuitePlugins/SiteSearch/SiteSearch'
export default {
  props: ['AppisDarkMode','CurrentRecordName','FriendsList','ActiveSession'],
  components: {SystemTicketsLogging,SiteSearch},
  data() {
    return {
      ActiveSearch: 'kawasaki',
      SearchView: false,
      GlossaryItems: [],
      VirtualAssistThinking: false,
      DefaultChatbotGreeting: '',
       UserRecord: '',
      RecordName: '',
      SocialQuickNavTop: '225px',
      socialquicknav: false,
      MessageDialog: true,
      MessagetoUser: {
            id: 1000001,
            Full_Name: 'Chatbot',
            Profile_Photo: require('@/assets/logo.png')
        },
      groups: [], 
      UsersArray: [],
      ReceivedMessages: [],
      SentMessages: [],
      Notifications: [],
      drawer:false,
      search: '',
      ResponseTypes: [
          {
            Name: 'Quirky',
            Triggers: [
                'opinion'
            ]
            },
            {
            Name: 'Search Result',
            Triggers: [
                'what'
            ]
            },
            {
            Color: 'purple',
            Name: 'System Technical',
            Triggers: [
                'why'
            ]
            },
          {
            Name: 'Greetings',
            Triggers: [
                'hello'
            ]
            },
            {
              Color: 'pink',
            Name: 'How To',
            Triggers: [
                'how'
            ]
            },
            {
            Name: 'Navigation',
            Triggers: [
                'where'
            ]
            },
            {
              Color: 'blue',
            Name: 'General',
            Triggers: [
                'learn'
            ]
            },
            {
            Name: 'Virtual Assist',
            Triggers: [
                'assist'
            ]
            }
            ],
      FrequentlyAskedQuestions: [],
      ChatbotResponsesOptions: [],
      ClientChatbotResponsesOptions: [],
      pubitems: [
        {
          DisplayName:'Social',
          Path:'/Social',
          SubItems: [
            {
            DisplayName: 'Members',
            Path: '/SiteMembers'
            },
            {
            DisplayName: 'Groups',
            Path: '/Groups'
            },
            {
            DisplayName: 'Blogs',
            Path: '/Blogs'
            },
            {
            DisplayName: 'Forums',
            Path: '/Forums'
            },
            {
            DisplayName: 'Polls',
            Path: '/Polls'
            },
            {
            DisplayName: 'Articles',
            Path: '/Articles'
            },
            {
            DisplayName: 'Events',
            Path: '/Events'
            },
          ]
        },
        
        {
          DisplayName:'Intranet',
          Path:'/Intranet',
        },
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  computed: {
    MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    FinalChatbotResponsesOptions(){
      return this.ChatbotResponsesOptions.concat(this.ClientChatbotResponsesOptions,this.GlossaryItems,this.FrequentlyAskedQuestions)
    },
    BGImageCurrent(){

        if(this.AppisDarkMode){
       return { backgroundImage: 'url(' + require('@/assets/RASolidA.jpeg') + ')' ,backgroundSize: 'cover'}
        }
        else{
          return { backgroundImage: 'url(' + require('@/assets/RASolidA-Light.jpeg') + ')' ,backgroundSize: 'cover'}
        }
     
  },
      ResponseExtensions(){
          return this.ChatbotResponsesOptions.map(resp => {
              return resp.Responses
          }).flat()
      },
      
    
    
    MessageBoxPadRight(){
        if (this.socialquicknav === true) {
        return 'right: 260px;'
        }
        else{
          return 'right: 0px;'
        }
    },
    Conversation(){
      let arr1 = this.SentMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.recipientid == this.MessagetoUser.id
        }      
      })
      .filter(msg => {
        
        return !msg.Cleared
      })     
      .map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock',
        msg.Style = 'width:90%;left:0px;'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.senderid == this.MessagetoUser.id
        }      
      })
      .filter(msg => {
      
        return !msg.Cleared
      })
      .map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'grey lighten-4 messageblock'
        msg.Style = 'width:90%;left:50px;'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      console.log(arr1)
      return arr1.concat(arr2)
    },

    ConversationAscending(){
      return this.Conversation.map(msg => {
        //console.log(msg.TimestampSort)
        return msg
      })
      .map(msg => {
        if(msg.options){
          msg.options.map(opt => {
            if(opt.Type && opt.Type.Name === 'How To'){
              opt.ChipColor = 'pink'
            }
            else if(opt.Type && opt.Type.Name === 'General'){
              opt.ChipColor = 'blue'
            }
            else if(opt.Type && opt.Type.Name === 'System Technical'){
              opt.ChipColor = 'purple'
            }
            else if(opt.Type && opt.Type.Name === 'Virtual Assist'){
              opt.ChipColor = 'green'
            }
          })
          
        }
        return msg
      })
      .sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      
    },
    AllUserConversations(){
      let arr1 = this.SentMessages.map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'grey lighten-4 messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      console.log(arr1)
      return arr1.concat(arr2)
    },
    AllUserConversationsAscending(){
      return this.AllUserConversations.map(msg => {
        //console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    ConversingUsers(){
      return this.UsersArray.filter(user => {
        let recipientcheck = this.AllUserConversationsAscending.find(msg => msg.recipientid == user.id)
        let sendercheck = this.AllUserConversationsAscending.find(msg => msg.senderid == user.id)
        if(sendercheck){
          user.lastMessage = Object.assign({},sendercheck)
          user.SendTime = this.TimestampFormatterSTRING(sendercheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(sendercheck.SendTime)
          return user
        }
        else if(recipientcheck){
          user.lastMessage = Object.assign({},recipientcheck)
          user.SendTime = this.TimestampFormatterSTRING(recipientcheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(recipientcheck.SendTime)
          return user
        }
        
      }).map(user => {
        let sendercheck = this.UnreadMessages.find(msg => msg.senderid == user.id)
       
        if(sendercheck){
          user.Color = ' grey lighten-2'          
        }
        else{
          user.Color = ' white'
        }
        console.log(user)
        return user
      }).filter(user => {
        return user.id !== this.UserRecord.id
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    UnreadMessages(){
      return this.AllUserConversationsAscending.filter(msg => {
        return !msg.Read === true && msg.ReadType === 'Received'
      })
    },
     UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
    ReadNotifications(){
      return this.Notifications.filter(notificaton => {
        return notificaton.Read === true
      })
    },
    AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return 'blue-grey darken-4 stickytop2'
        }
        else{
          return 'blue-grey lighten-5 stickytop2'
        }
      },
    userLoggedIn () {
      return this.$store.getters.user
    },
    },

  mounted() {
  window.addEventListener('scroll', this.handleScrollQuickNav)
  },
  beforeDestroy() {
      window.removeEventListener('scroll', this.handleScrollQuickNav);
  },
  created() {

    this.UserRecord = this.userLoggedIn
    if (this.UserRecord && this.UserRecord.id) {
        //   console.log('this.UserRecord',this.UserRecord.id)
        //   let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').where('Ownerid', '==', this.UserRecord.id)
          this.GetReponseOptions()  
          //this.GetFrequentlyAskedQuestions()
          this.GetClientResponseOptions()
          this.GetSentMessages()
          this.GetReceivedMessages()
          this.GetGlossaryItems()
          //this.getGroups()
        }
  },
  watch:{
        'search' (){
            this.CheckActivateSearch()
        },
      
  },
  methods: {
    ToggleChatbotActive(){
      this.$emit('ToggleChatbotActive')
    },
    GetFrequentlyAskedQuestions(){
      db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('frequentlyaskedquestions').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
            if (change.type === 'added') {
              let faq = {
                ...change.doc.data(),
                id: change.doc.id
            }
            faq.Responses = [
              {
                Title: faq.Question,
                Response: faq.Answer,
                LowerKeyWords: faq.LowerKeyWords,
                Type: {
                  Name: 'FAQ'
                }
              }
            ]
            
            this.FrequentlyAskedQuestions.push(faq)
            } 
        })
      })
    },
    GetGlossaryItems(){
            db.collection('glossary').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.GlossaryItems.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                    if (change.type === 'modified') {
                        let modifiedobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let obj = this.GlossaryItems.find(obj => obj.id === modifiedobj.id)
                    let index = this.GlossaryItems.indexOf(obj)
                    this.GlossaryItems[index] = modifiedobj
                    console.log(this.GlossaryItems)
                    } 
                })
                })
        },
    
    ClearChat(){
      confirm('This will clear the current chat. Are you sure?') && this.ProcessClearChat()
    },
    ProcessClearChat(){ 
      this.Conversation.map((msg) => {        
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotmessages').doc(msg.id).update({
          Cleared: true
        })
        msg.Cleared
      })
      this.ReceivedMessages = []
      this.SentMessages = []
    },
    MessageClass(message ){
      if(this.AppisDarkMode && message.ReadType === 'Received'){
        return 'messageblock grey darken-4'
      }
      else{
        return message.Color
      }
    },
      OpenMessageDialog(){
          this.MessageDialog = !this.MessageDialog
          setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            if(objDiv){
                objDiv.scrollTop = objDiv.scrollHeight; 
            }          
            
        }, 50);
      },
    GetUserGroups(){
      this.UserRecord.groups.map(group => {
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(group.id).onSnapshot(snapshot => {
          let groupdata = snapshot.data()
          groupdata.id = group.id
          this.groups.push(groupdata)
        })
      })
    },
    
    ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
    GetClientResponseOptions(){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotresponses').onSnapshot(res => {
            let listlength = res.docs.length
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                    let obj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                obj.Responses = []
                     db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotresponses').doc(change.doc.id).collection('Responses').onSnapshot(responsedocs => {
                         const respchanges = responsedocs.docChanges();
                         let resplistlength = responsedocs.docs.length
                        if(resplistlength === 0){
                            this.ClientChatbotResponsesOptions.push(obj)
                        }
                        else{
                          respchanges.forEach(respchange => {
                                if (respchange.type === 'added') {
                                    obj.Responses.push({
                                        ...respchange.doc.data(),
                                        id: respchange.doc.id
                                    })
                                }
                            })  
                            if(obj.Responses.length === resplistlength){
                                this.ClientChatbotResponsesOptions.push(obj)
                            }
                        }
                        
                     })
                
                }  
                if(this.ClientChatbotResponsesOptions.length === listlength){
                    console.log(this.ClientChatbotResponsesOptions)
                }
            })
            
        })
    },
    GetReponseOptions(){
        db.collection('defaultchatbotresponses').onSnapshot(res => {
            let listlength = res.docs.length
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                    let obj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                obj.Responses = []
                     db.collection('defaultchatbotresponses').doc(change.doc.id).collection('Responses').onSnapshot(responsedocs => {
                         const respchanges = responsedocs.docChanges();
                         let resplistlength = responsedocs.docs.length
                        if(resplistlength === 0){
                            this.ChatbotResponsesOptions.push(obj)
                        }
                        else{
                          respchanges.forEach(respchange => {
                                if (respchange.type === 'added') {
                                    obj.Responses.push({
                                        ...respchange.doc.data(),
                                        id: respchange.doc.id
                                    })
                                }
                            })  
                            if(obj.Responses.length === resplistlength){
                                this.ChatbotResponsesOptions.push(obj)
                            }
                        }
                        
                     })
                
                }  
                if(this.ChatbotResponsesOptions.length === listlength){
                    console.log(this.ChatbotResponsesOptions)
                }
            })
            
        })
    },
    stdmatchcontains(a, obj) {
            for (var i = 0; i < a.length; i++) {
              if (obj !== 'a' && obj !== 'an' && a[i].includes(obj.toLowerCase())) {
                // if (a[i].includes(obj.toLowerCase())) {
                    return true;
                }
            }
            return false;
        },
    exactmatchcontains(a, obj) {
            for (var i = 0; i < a.length; i++) {
              //console.log(a[i],obj.toLowerCase())
              if (obj !== 'a' && obj !== 'an' && a[i] === obj.toLowerCase()) {
                
                // if (a[i].includes(obj.toLowerCase())) {
                    return true;
                }
            }
            return false;
        },
        SubmitCountResponse(opt,countinteger){
          let vm = this
          let NewMessage = {
            ActiveSession: vm.ActiveSession,
                      recipientid: vm.UserRecord.id,
                      senderid: vm.MessagetoUser.id,
                      message: `<p>`+opt.Response+` is `+countinteger+`</p>
                      <p class="red--text caption">Please do note that this figure is based on your access to the database. If you are not permitted to something you will not see the correct amount here.</p>
                      <p>Do you want to see the <a href="`+opt.CollectionLocation+`" target="_blank">list</a>?</p>`,
                      // options: msgoptions,
                      Created_On: new Date(),
                      Read: false,
                      Cleared: false,
                      Type: opt.Type
                      }
                      if(opt.Responses){
                          NewMessage.options = opt.Responses.map(resp => {
                              let respobj = vm.ChatbotResponsesOptions.find(obj => obj.id === resp.id)
                              if(respobj){
                                return respobj  
                              }
                              else{
                              let subresp = vm.ResponseExtensions.find(obj => obj.id === resp.id)
                              return subresp
                              }
                              
                          })
                          console.log(NewMessage.options,NewMessage)
                      }
                      vm.VirtualAssistThinking = false
                      db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
                      })
        },
        PrepareDynamicOptionSetCountResponse(opt,countinteger){
          let vm = this
          console.log('dynamic list prepare')
          console.log(vm.$store.state[opt.CollectionArray].length)
          console.log(opt.FilterField)
          let structuredarray = vm.$store.state[opt.CollectionArray]
                            .filter(record => {
                              return record[opt.FilterField.Name] && record[opt.FilterField.Name].ID
                            })
          console.log(structuredarray,structuredarray.length)
                            let finalfiltercategoryarray = opt.FilterField.Options
                            .map(opt => {
                              let obj = {
                                Name: opt.Name,
                                id: opt.ID
                              }
                              return obj
                            })
                            
                            console.log(finalfiltercategoryarray)
                            let DynamicResponses = finalfiltercategoryarray.map(recorcat => {
                              recorcat.Count = structuredarray.filter(record => {
                                return record[opt.FilterField.Name].ID === recorcat.id
                              }).length
                              recorcat.message = `<p>`+opt.Response+` by `+opt.FilterField.DisplayName+` `+recorcat.Name+` is `+recorcat.Count+`</p>`
                              return recorcat                           
                            })
                            opt.DynamicResponses = [...new Set(DynamicResponses)];
                            console.log(opt.DynamicResponses.length)
                             let NewMessage = {
                               ActiveSession: vm.ActiveSession,
                                  recipientid: vm.UserRecord.id,
                                  senderid: vm.MessagetoUser.id,
                                  message: `<p>`+opt.Response+` is `+countinteger+`</p>
                                  <p class="red--text caption">Please do note that this figure is based on your access to the database. If you are not permitted to something you will not see the correct amount here.</p>
                                  <p class="warning--text caption">Additionally, you must consider that this is the result of "related records" which literally mean, the result you see is only as accurate as records have been linked.</p>
                                  <p>Below you can choose from the options, a total of `+opt.DynamicResponses.length+` options apply you want to see the <a href="`+opt.CollectionLocation+`" target="_blank">list</a>?</p>`,
                                  dynamicoptions: opt.DynamicResponses,
                                  Created_On: new Date(),
                                  Read: false,
                                  Cleared: false,
                                  DynamicLabel: opt.FilterField.Name,
                                  NOClick: true,
                                  Type: opt.Type
                                  }
                                   vm.VirtualAssistThinking = false
                             db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
                      })
        },
        PrepareDynamicLookupCountResponse(opt,countinteger){
          let vm = this
          console.log('dynamic list prepare')
          console.log(vm.$store.state[opt.CollectionArray].length)
          console.log(opt.FilterField)
          let structuredarray = vm.$store.state[opt.CollectionArray]
                            .filter(record => {
                              return record[opt.FilterField.Name] && record[opt.FilterField.idfield]
                            })
          console.log(structuredarray.length)
                            let filtercategoryarray = structuredarray
                            .map(record => {
                              let obj = {
                                Name: record[opt.FilterField.Name][opt.FilterField.PrimaryField],
                                id: record[opt.FilterField.idfield]
                              }
                              return obj
                            })
                            let finalfiltercategoryarray = []
                            filtercategoryarray.map(category => {
                              let match = finalfiltercategoryarray.find(obj => obj.id === category.id)
                              if(!match){
                                finalfiltercategoryarray.push(category)
                              }
                            })
                            console.log(finalfiltercategoryarray)
                            let DynamicResponses = finalfiltercategoryarray.map(record => {
                              record.Count = structuredarray.filter(recorcat => {
                                return recorcat[opt.FilterField.idfield] === record.id
                              }).length
                              record.message = `<p>`+opt.Response+` by `+opt.FilterField.Name+` `+record.Name+` is `+record.Count+`</p>`
                              return record                           
                            })
                            opt.DynamicResponses = [...new Set(DynamicResponses)];
                            console.log(opt.DynamicResponses.length)
                             let NewMessage = {
                               ActiveSession: vm.ActiveSession,
                                  recipientid: vm.UserRecord.id,
                                  senderid: vm.MessagetoUser.id,
                                  message: `<p>`+opt.Response+` is `+countinteger+`</p>
                                  <p class="red--text caption">Please do note that this figure is based on your access to the database. If you are not permitted to something you will not see the correct amount here.</p>
                                  <p class="warning--text caption">Additionally, you must consider that this is the result of "related records" which literally mean, the result you see is only as accurate as records have been linked.</p>
                                  <p>Below you can choose from the options, a total of `+opt.DynamicResponses.length+` options apply you want to see the <a href="`+opt.CollectionLocation+`" target="_blank">list</a>?</p>`,
                                  dynamicoptions: opt.DynamicResponses,
                                  Created_On: new Date(),
                                  Read: false,
                                  Cleared: false,
                                  DynamicLabel: opt.FilterField.Name,
                                  NOClick: true,
                                  Type: opt.Type
                                  }
                                   vm.VirtualAssistThinking = false
                             db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
                      })
        },
        ParentfieldCountStructure(opt,countinteger){
          let vm = this
          let structuredarray = vm.$store.state[opt.CollectionArray]
                            .filter(record => {
                              return record[opt.FilterField.ParentField] && record[opt.FilterField.ParentFieldid]
                            })
          let structuredparentarray = vm.$store.state[opt.FilterField.ParentArray]
                            .filter(record => {
                              return record[opt.FilterField.ParentLookup] && record[opt.FilterField.ParentLookupid]
                            })
          structuredarray.map(record => {
            record[opt.FilterField.ParentField] = structuredparentarray.find(obj => obj.id === record[opt.FilterField.ParentFieldid])
            return record
          })
          .filter(record => {
            return record[opt.FilterField.ParentField] && record[opt.FilterField.ParentField][opt.FilterField.ParentLookup]
          })
          .map(record => {
            // console.log(record[opt.FilterField.ParentField])
            record[opt.FilterField.Name] = record[opt.FilterField.ParentField][opt.FilterField.ParentLookup]
            record[opt.FilterField.idfield] = record[opt.FilterField.ParentField][opt.FilterField.ParentLookup+'id']
            return record
          })
          .filter(record => {
            return record[opt.FilterField.Name] && record[opt.FilterField.Name][opt.FilterField.PrimaryField]
          })
          let filtercategoryarray = structuredarray
                            .map(record => {
                              let obj = {
                                Name: record[opt.FilterField.Name][opt.FilterField.PrimaryField],
                                id: record[opt.FilterField.idfield]
                              }
                              return obj
                            })
                            let finalfiltercategoryarray = []
                            filtercategoryarray.map(category => {
                              let match = finalfiltercategoryarray.find(obj => obj.id === category.id)
                              if(!match){
                                finalfiltercategoryarray.push(category)
                              }
                            })
                            console.log(finalfiltercategoryarray)
                            let DynamicResponses = finalfiltercategoryarray.map(record => {
                              record.Count = structuredarray.filter(recorcat => {
                                return recorcat[opt.FilterField.idfield] === record.id
                              }).length
                              record.message = `<p>`+opt.Response+` by `+opt.FilterField.Name+` `+record.Name+` is `+record.Count+`</p>`
                              return record                           
                            })
                            opt.DynamicResponses = [...new Set(DynamicResponses)];
                            console.log(opt.DynamicResponses.length)
                             let NewMessage = {
                               ActiveSession: vm.ActiveSession,
                                  recipientid: vm.UserRecord.id,
                                  senderid: vm.MessagetoUser.id,
                                  message: `<p>`+opt.Response+` is `+countinteger+`</p>
                                  <p class="red--text caption">Please do note that this figure is based on your access to the database. If you are not permitted to something you will not see the correct amount here.</p>
                                  <p class="warning--text caption">Additionally, you must consider that this is the result of "related records" which literally mean, the result you see is only as accurate as records have been linked.</p>
                                  <p>Below you can choose from the options, a total of  `+opt.DynamicResponses.length+` options apply you want to see the <a href="`+opt.CollectionLocation+`" target="_blank">list</a>?</p>`,
                                  dynamicoptions: opt.DynamicResponses,
                                  Created_On: new Date(),
                                  Read: false,
                                  Cleared: false,
                                  DynamicLabel: opt.FilterField.Name,
                                  NOClick: true,
                                  Type: opt.Type
                                  }
                                   vm.VirtualAssistThinking = false
                             db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
                      })
          
        },
        GetParentFieldCount(opt,countinteger){
          let vm = this
          if(vm.$store.state[opt.FilterField.ParentArray].length>0){
            console.log(vm.$store.state[opt.CollectionArray].length)
            vm.ParentfieldCountStructure(opt,countinteger)
          }
          else{
            let payload = {                
                      query: vm.$store.state[opt.FilterField.ParentQuery],
                      arraymutation: 'set'+opt.FilterField.ParentArray,
                      getter: 'get'+opt.FilterField.ParentArray,
                      arrayname: opt.FilterField.ParentArray
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                      console.log(vm.$store.state[opt.CollectionArray].length)

                      console.log(vm.$store.state[opt.FilterField.ParentArray])
                      vm.ParentfieldCountStructure(opt,countinteger)
                    })
          }
        },
        StandardCountPrepare(opt,countinteger,dynamic){
          let vm = this
          if(vm.$store.state[opt.CollectionArray].length>0){
                    countinteger = vm.$store.state[opt.CollectionArray].length
                    if(!dynamic){
                      vm.SubmitCountResponse(opt,countinteger)
                    }
                    else{
                          if(opt.FilterField && opt.FilterField.Type === 'Lookup Field'){
                            vm.PrepareDynamicLookupCountResponse(opt,countinteger)
                          }
                          else if(opt.FilterField && opt.FilterField.Type === 'Option Set'){
                            vm.PrepareDynamicOptionSetCountResponse(opt,countinteger)
                          }
                          else if(opt.FilterField && opt.FilterField.Type === 'Parent Field'){
                            console.log(vm.$store.state[opt.FilterField.ParentArray].length)
                              console.log(opt.FilterField.ParentQuery)
                              vm.GetParentFieldCount(opt,countinteger)
                          }
                          else if(opt.FilterField && opt.FilterField.Type === 'Process'){
                            
                            }
                          
                          }                     
                  }
                  else{
                    let payload = {                
                      query: vm.$store.state[opt.CollectionQuery],
                      arraymutation: 'set'+opt.CollectionArray,
                      getter: 'get'+opt.CollectionArray,
                      arrayname: opt.CollectionArray
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.$store.state[opt.CollectionArray].length)
                          countinteger = vm.$store.state[opt.CollectionArray].length
                          if(!opt.FilterField){
                          vm.SubmitCountResponse(opt,countinteger)
                          }
                          else{
                            if(opt.FilterField && opt.FilterField.Type === 'Lookup Field'){
                              vm.PrepareDynamicLookupCountResponse(opt,countinteger)
                            }
                              else if(opt.FilterField && opt.FilterField.Type === 'Option Set'){
                              vm.PrepareDynamicOptionSetCountResponse(opt,countinteger)
                            }
                            else if(opt.FilterField && opt.FilterField.Type === 'Parent Field'){
                              console.log(vm.$store.state[opt.FilterField.ParentArray].length)
                              console.log(opt.FilterField.ParentQuery)
                              vm.GetParentFieldCount(opt,countinteger)
                            }
                            else if(opt.FilterField && opt.FilterField.Type === 'Process'){
                            
                            }
                          }
                          })
                  }
        },
        CheckMeOut(opt,boolean){
          let vm = this
            console.log(boolean,opt)
             if(boolean){
            let NewInputMessage = {
              ActiveSession: vm.ActiveSession,
                recipientid: this.MessagetoUser.id,
                senderid: this.UserRecord.id,
                message: opt.Title,
                Created_On: new Date(),
                Read: false
                }
                db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewInputMessage).then(doc => {
                    let docid = doc.id
                    //this.GenerateResponse(doc.id)
                })
           
              if(opt.Type && opt.Type.Name && opt.Type.Name !== 'Virtual Assist'){
                if(opt.Response){
                 let NewMessage = {
                   ActiveSession: this.ActiveSession,
                  recipientid: this.UserRecord.id,
                  senderid: this.MessagetoUser.id,
                  message: `<p>`+opt.Response+`</p>`,
                  // options: msgoptions,
                  Created_On: new Date(),
                  Read: false,
                  Cleared: false,
                  Type: opt.Type
                  }
                  if(opt.Responses){
                      NewMessage.options = opt.Responses.map(resp => {
                          let respobj = this.ChatbotResponsesOptions.find(obj => obj.id === resp.id)
                          if(respobj){
                            return respobj  
                          }
                          else{
                          let subresp = this.ResponseExtensions.find(obj => obj.id === resp.id)
                          return subresp
                          }
                          
                      })
                      console.log(NewMessage.options,NewMessage)
                  }
                  db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
                  })
              }
              }
              else{
                this.VirtualAssistThinking = true
                if(opt.ReturnType && opt.ReturnType === 'Count'){
                  let countinteger = 0
                  // if(!opt.DynamicFilter){
                    this.StandardCountPrepare(opt,countinteger,opt.DynamicFilter)
                  // }
                  // else{
                  //   this.DynamicCountPrepare(opt,countinteger)
                  // }
                  
                   
                  
                }
              }
                
            }
            else{
              // alert('shaem')
              //   this.NoComprehendoMSG(obj.message)
            }
            
        },
    NarrowDownResponses(ExactMatch,potentialresponses,enquiry){
      let msgoptions = potentialresponses.map(response => {
                return response.Responses
            }).flat()
           // console.log(msgoptions)
      let exactmatcharray = JSON.parse(JSON.stringify(msgoptions)) 
      let stdmatcharray = JSON.parse(JSON.stringify(msgoptions))
      //console.log(exactmatcharray)
      //console.log(stdmatcharray) 
      let exactresponses = this.PerformMatchCheck(enquiry,exactmatcharray,true)
      if(exactresponses && exactresponses.length > 0){
            console.log('got exact RESPONSES  matches')
            this.CreateResponse(ExactMatch,potentialresponses,exactresponses)                
            }
            else{
               console.log('got NO exact RESPONSES matches')
             let standardresponses = this.PerformMatchCheck(enquiry,stdmatcharray,false)
             this.CreateResponse(ExactMatch,potentialresponses,standardresponses)
            }
    },
    CreateResponse(ExactMatch,potentialresponses,NEWmsgoptions){
      let msgResponse = ''
      console.log(potentialresponses)
      let msgoptions = potentialresponses.map(response => {
                return response.Responses
            }).flat()
            console.log(msgoptions)
     
      //let enquiry = obj.message.split(' ')
      
        
            msgResponse = 
            `<div>`
            let length = potentialresponses.length
            potentialresponses.map((response,i) => {
                if(response.Response){
                    msgResponse=msgResponse +`
                    <b>`+response.Title+`</b>
                    <p class="caption">`+response.Response+`</p>`
                }
                if(i-1+2 === length){
                    `</div>`
                }
            })
            let NewMessage = {
              ActiveSession: this.ActiveSession,
          recipientid: this.UserRecord.id,
          senderid: this.MessagetoUser.id,
          message: msgResponse,
          Created_On: new Date(),
          Read: false,
          Cleared: false
        }
        if(NEWmsgoptions && NEWmsgoptions.length>0){
            NewMessage.options = NEWmsgoptions
            NewMessage.message = msgResponse
        }
        
        else{
          if(msgoptions && msgoptions.length>0){
              NewMessage.options = msgoptions
              NewMessage.message = msgResponse
          }
          else{
             console.log(NewMessage)
          console.log(msgResponse)
            if(msgResponse && msgResponse !== '<div>'){
              
                NewMessage.message = msgResponse
            }
            else{
              //this.NoComprehendoMSG(obj.message)
               NewMessage.message = `Hmm,
                I did not quite get that` 
            }
          }
         
            
        }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
            })
    },
    PerformMatchCheck(enquiry,newarray,ExactMatch){
      //return new Promise(function(resolve, reject) {
        //console.log('ExactMatch',ExactMatch)
      if(ExactMatch){
        console.log('is true now running',newarray,enquiry)
     
      return newarray.map(topic => {
        // for (var i = 0; i < enquiry.length; i++) {
        //     if(this.exactmatchcontains(topic.LowerKeyWords, enquiry[i])){
        //       console.log(enquiry[i],topic.LowerKeyWords)
        //         topic.Matched = true
        //       break;
        //     }
        // }
        enquiry.map(key => {
                    if(this.exactmatchcontains(topic.LowerKeyWords, key)){
                      //console.log(key,topic.LowerKeyWords)
                        topic.Matched = true
                    }  
                })
                return topic
            })
            .filter(topic => {
                return topic.Matched
            })
      }
      else{
        return newarray.map(topic => {
        enquiry.map(key => {
                    if(this.stdmatchcontains(topic.LowerKeyWords, key)){
                      console.log(key,topic.LowerKeyWords)
                        topic.Matched = true
                    }  
                })
                return topic
            })
            .filter(topic => {
              console.log(topic)
                return topic.Matched
            })
      }
    },
    GenerateResponse(docid){
      
      let exactmatcharray = JSON.parse(JSON.stringify(this.FinalChatbotResponsesOptions)) 
      let stdmatcharray = JSON.parse(JSON.stringify(this.FinalChatbotResponsesOptions)) 
      console.log(exactmatcharray)
      console.log(stdmatcharray)
        let obj = this.SentMessages.find(obj => obj.id === docid)
        console.log(obj)
        let enquiry = obj.message.split(' ')
        let type = ''
         let exactresponses = this.PerformMatchCheck(enquiry,exactmatcharray,true)
          if(exactresponses && exactresponses.length > 0){
            console.log('got exact matches')
              this.NarrowDownResponses(true,exactresponses,enquiry)                
            }
            else{
               console.log('got NO exact matches')
             let standardresponses = this.PerformMatchCheck(enquiry,stdmatcharray,false)
              if(standardresponses && standardresponses.length > 0){
                  this.NarrowDownResponses(false,standardresponses,enquiry)                
                }
                else{
                    this.NoComprehendoMSG(obj.message)
            }
            }
            
         
        
    },
    NoComprehendoMSG(enquiry){
          let NewMessage = {
            CanSearch: true,
            DefaultSearch: enquiry,
            ActiveSession: this.ActiveSession,
          recipientid: this.UserRecord.id,
          senderid: this.MessagetoUser.id,
          message: `Hmm,
          I did not find anything`,
          Created_On: new Date(),
          Read: false,
          Cleared: false
          }
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
      })
               
    },
    CreateMessage(userto,userfrom){
        let inputelmnt = document.getElementById('messageinputchip')
        let NewMessage = {
          ActiveSession: this.ActiveSession,
          recipientid: userto.id,
          senderid: userfrom.id,
          message: inputelmnt.value,
          Created_On: new Date(),
          Read: false,
          Cleared: false
        }
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotmessages').add(NewMessage).then(doc => {
            let docid = doc.id
            this.GenerateResponse(doc.id)
        })
        inputelmnt.value = ''
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            
        }, 50);
      },
    handleScrollQuickNav(){
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        this.SocialQuickNavTop = '115px'
        }
        else{
          this.SocialQuickNavTop = '225px'
        }
    },
    TogglesocialSiteQuickNav(){
      if(this.socialquicknav){
        this.socialquicknav = false
      }
      else{
        this.socialquicknav = true
        setTimeout(() => {
        let socialsitequicknavelmnt = document.getElementById('socialsitequicknav')
        if(socialsitequicknavelmnt){
      socialsitequicknavelmnt.style.top = this.SocialQuickNavTop
    }
    }, 50);
      }
    },
    // ActivateMessageDialog(user){
    //   sdf
    // },
    DeactivateUserMessage(){
        this.MessageDialog = false
        this.MessagetoUser = ''
        this.socialquicknav = true
      },
      ActivateMessage(user){
        this.socialquicknav = false
        this.MessageDialog = true
        this.MessagetoUser = user
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            this.ReceivedMessages.map(msg => {
              if(msg.ReadType === 'Received' && msg.Read === false){
                this.UpdateMessageasRead(msg)
              }
            })
        }, 200);
      },
      UpdateMessageasRead(msg){
        db.collection('usermessages').doc(msg.id).update({
          Read: true
        })
      },
    getGroups() {
           db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.groups.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
      })
    })

    },
    GetUsers(){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
        if (change.type === 'modified') {
          console.log('here is change')
          console.log(change.doc.data())
          let userdata = change.doc.data()
          let userobj = this.UsersArray.find(obj => obj.id == change.doc.id)
          let userindex = this.UsersArray.indexOf(userobj)
          if(this.UsersArray[userindex].onlinestatus !== userdata.onlinestatus){
            this.UsersArray[userindex].onlinestatus = userdata.onlinestatus
          }
          
        }  
      })
    })
      },
    DateFormatterwithTime(date){
      if(date){
        //console.log(date)
        //console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},

TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
    GetSentMessages(){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotmessages').where('senderid','==',this.UserRecord.id).where('Cleared','==',false).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.SentMessages.push({
              ...change.doc.data(),
              id: change.doc.id
            })
            setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            if(objDiv){
                objDiv.scrollTop = objDiv.scrollHeight; 
            }          
            
        }, 50);
            
          }  
        })
      })      
      },
      GetReceivedMessages(){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('chatbotmessages').where('recipientid','==',this.UserRecord.id).where('Cleared','==',false).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.ReceivedMessages.push({
              ...change.doc.data(),
              id: change.doc.id
            })
            setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            if(objDiv){
                objDiv.scrollTop = objDiv.scrollHeight; 
            }          
            
        }, 50);
          }  
          if (change.type === 'modified') {
          console.log('here is change')
          console.log(change.doc.data())
          let msgdata = change.doc.data()
          msgdata.id = change.doc.id
          let msgobj = this.ReceivedMessages.find(obj => obj.id == change.doc.id)
          let msgindex = this.ReceivedMessages.indexOf(msgobj)
          if(this.ReceivedMessages[msgindex] && this.ReceivedMessages[msgindex].Read !== msgdata.Read){
            this.ReceivedMessages[msgindex] = msgdata
          }
          
        }
        })
      })      
      },
    IntranetViewToggle(){
      confirm('This will take you to the data version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',false)
      },
      CancelSubmitSystemTicket(){
      this.TicketLog = false
    },
      ActivateTicketLog(){
        this.UsersArray = [this.UserRecord]
        this.TicketLog = true
      },
    GetUserNotifications(query){
      query.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.Notifications.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
    },


     NotificationRead(notification){
      if(notification.Read === false){
       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notification.id).update({
        Read: true
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = true
      this.$router.push(notification.Path)

      }

    },
    ViewItem(notification){
      this.$router.push(notification.Path)
    },

    MarkasUnread(notification){
      if(notification.Read === true && notification.Type === 'Task Created'){
       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notification.id).update({
        Read: false
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = false


      }
    },

    ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.Notifications = []
      this.UserRecord = {}
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
    CheckActivateSearch(){
      console.log(this.search === '')
      console.log(this.SearchView)
      if(this.search === '' && this.SearchView){
        console.log('conditions met bro')
      this.$emit('DeactivateSearch')
      this.SearchView = false
      }
      
    },
    ActivateSearch(search){    
      //this.$emit('DeactivateSearch')
      //console.log(this.search) 
      this.SearchView = true
      this.ActiveSearch = search
    },
    
  }

}
</script>

<style>
.messagebox{
  position: fixed;
  bottom: 0px;
  transition: right 0.5s;
  z-index: 101;
  top: 50px;
}
.messageblock {
  border: none;
  cursor: pointer;
  border-radius: 25px;
}
.messagepost{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.messagelist {
  height:600px;/* or any height you want */
  overflow-y:auto
}
.messageboxheader{
  z-index: 105;
}
.systembar{
    background-repeat: repeat;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
.waytop{
  z-index: 100;
}
.searchinput{
  background-color: white;
  color: #474747;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  top: 30px;
  z-index: 4
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 4
}

</style>
    

    