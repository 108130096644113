
<template>

<v-card :color="tab.RowColor" :class="tab.RowTransparency" tile  width="100%" flat v-if="TabReady" id="parallax">
    <!-- <v-container class="grey lighten-5"> -->
  <div :style="tab.FullHeight ? 'height: 100vh' : 'height: 100%;'">
       <v-parallax  v-if="tab.IMGMaskReveal" class="" 
       :class="tab.HalfParallaxClass ? tab.HalfParallaxClass : ''"
        :height="tab.FullHeight ? '100%' : tab.Height"
        :src="tab.FirstIMG"
        :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
      />
     <v-parallax v-if="MaskIMGClippingStyle || !tab.IMGMaskReveal"
       :class="MainParallaxClass"
        :height="tab.Height" :id="tab.ElementID+'revealimg'"
        :src="TabIMG"  @click="ActivateElementConfigComp('ImageConfiguringComponent',tab,i)"        
        :style="ParallaxStyle(tab)"
      >
       <v-overlay v-if="tab.HasParallaxGradient" class="gradoverlay" color="#ffffff00" absolute :style="tab.ParallaxGradient">
      </v-overlay>
        <PageHUDMenu v-if="tab.Name === 'Parallax' && tab.ShowComponents && tab.HasHUDItems && tab.HUDItems"
          :tab="tab" style="position: absolute;"
          @ActiveHUDItemAction="ActiveHUDItemAction"
            :System="System" :SystemEntities="SystemEntities"
          />
      <v-row v-if="tab.HasTextBox && !tab.FlipCard"
      :align="tab.Alignment.includes('flex-') ? tab.Alignment.split('flex-').join('') : tab.Alignment"
      :justify="tab.Justify.split('justify-').join('')"
    >
      <v-flex md6 sm12 class="mx-6" >
    
         <v-card :class="tab.BoxTransparency+' '+tab.CustomClass" 
         :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" :style="'padding: 10px;'+ParallaxContentMargin+tab.TextBoxStyle">
           <v-card-title :class="MiniView ? 'xtralargeoverlinemini '+tab.Center : 'xtralargeoverline '+tab.Center" @click="ActivateElementConfigComp('TitleConfiguringComponent',tab,i)" :id="tab.ElementID+'_Title'"
           :style="'color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)+'text-align:'+tab.TextAlign+';'" v-html="tab.Title">
           </v-card-title>
           <v-card-text>
            <span :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex" v-html="tab.Description"></span>
           </v-card-text>
           <v-card-actions v-if="tab.HasActionButton && tab.Fancy" :class="tab.ButtonAlignment">
            <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
                />
           </v-card-actions>
           <v-card-actions v-if="tab.HasActionButton && !tab.Fancy" :class="tab.ButtonAlignment">
             <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
                />
           </v-card-actions>
          </v-card>
          </v-flex>
      </v-row>
        <div v-else-if="tab.HasTextBox && tab.FlipCard" class="flipcard" :style="MiniView ? 'width: 100% !important;align-self: flex-end;'+'margin-top: '+tab.Height/4+'px;' : 
      'width: 50% !important;align-self: flex-end;'+'margin-top: '+tab.Height/4+'px;'">
              <div class="flipcardfront" :style="`background-image: url('`+tab.IMG+`');`">
                <div class="flipcardinner" :style="tab.LabelGradient ? tab.LabelGradient : ''">
                  <p  :style="'text-align: center;padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)+'font-size: 2rem;'" v-html="tab.Title"></p>
                  <span v-html="smart_substr(tab.Description,20)"></span>
                </div>
              </div>
              <div class="flipcardback" :style="'background: '+tab.BGColor.hex+';'">
                <div class="flipcardinner">
                  <v-list-item dense>
                    <v-list-item-avatar :tile="tab.TiledIMG">
                      <v-img  :src="tab.IMG"/>
                    </v-list-item-avatar>
                    <v-list-item-content :style="'color:'+tab.FontColor.hex+';font-weight: 300;'" v-html="tab.Title">
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <span :style="'color:'+tab.FontColor.hex">{{tab.Title}}</span> -->
                  <span :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex" v-html="tab.Description"></span>
                  <v-list-item style="padding-bottom: 10px;" v-if="tab.HasActionButton && tab.Fancy" :class="tab.ButtonAlignment">
          <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
                />
          </v-list-item>
          <v-list-item style="padding-bottom: 10px;" v-if="tab.HasActionButton && !tab.Fancy" :class="tab.ButtonAlignment">
            <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
                />
          </v-list-item>
                </div>
              </div>
          </div>
          <div v-else-if="tab.Elements && tab.Elements.length > 0">
            <v-container class="fill-height">
        <v-row
            
            >
           
            <v-col
                v-for="(elmnt,index) in tab.Elements"
                :key="elmnt.itemObjKey" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                cols="12"                            
            >   
           <InfoSheet 
                v-if="elmnt.Name === 'Info Sheet' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
            <TitleTab 
                v-if="elmnt.Name === 'Title' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
                <IFRAMETab  v-if="tab.Name === 'IFRAME' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"
                        />
            
                <GoogleMyMapsTab 
                v-if="elmnt.Name === 'Google My Maps' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
                <HTMLBox 
                v-if="elmnt.Name === 'HTML Box' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog"
                />
                <v-icon v-if="elmnt.Name === 'Web Form'" @click="OpenContentTypesDialog(elmnt,index,true,rowindex)">mdi-cogs</v-icon>
                <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" :i="i"
                />
            </v-col>
            </v-row>

    
      </v-container>
          </div>
      <!-- </v-overlay> -->
      </v-parallax>
    </div>
   
                    </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTab';
import PageHUDMenu from '@/components/WebPages/RenderComponents/PageHUDMenu';

import GoogleMyMapsTab from '@/components/WebPages/RenderComponents/GoogleMyMapsTab';
import IFRAMETab from '@/components/WebPages/RenderComponents/IFRAMETab';

import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheet';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTab';
import HTMLBox from '@/components/WebPages/RenderComponents/HTMLBox';
import ImageTab from '@/components/WebPages/RenderComponents/ImageTab';
import ParallaxTab from '@/components/WebPages/RenderComponents/ParallaxTab';
import ClippedTab from '@/components/WebPages/RenderComponents/ClippedTab';
import GalleryTab from '@/components/WebPages/RenderComponents/GalleryTab';
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbed';
export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','DefaultPageFont','System','ActivePage'],
    components: {
        ActionButtonEmbed,
        WebFormTab,
        PageHUDMenu,
        InfoSheet,
        TitleTab,
        HTMLBox,
        ImageTab,
        ParallaxTab,
        ClippedTab,
        GalleryTab,
        IFRAMETab,
        GoogleMyMapsTab
        },
    data() {
    return {
      MaskIMGClippingStyle: '',
      TabReady: false,
        justify: [
        'start',
        'center',
        'end',
        'space-around',
        'space-between',
      ],      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    watch: {        
        ParallaxClippedwithIMG (value) {
        if (value && value === true) {
            this.CheckifParallaxClipped(this.tab)
        }
      },
    },
    computed:{
      MainParallaxClass(){
        let cls = ''
        if(this.tab.HalfParallaxClass){
          cls = cls+HalfParallaxClass
        }
        if(this.tab.IMGMaskReveal){
          cls = cls+' reveal-img-parallax'
        }
        return cls
      },
      ParallaxContentMargin(){
        return !this.System.PublicNavbarStyle || this.System.PublicNavbarStyle === 'Default' ? '' : 
        this.ActivePage && this.ActivePage.ClearNavbarColor && this.ActivePage.PublishType === 'Public' && this.MiniView ? 'margin-top:90px;' : ''
      },
      TabIMG(){
        return this.tab.IMGMaskReveal ? this.tab.RevealIMG : this.tab.IMG ? this.tab.IMG : require('@/assets/parallaxsample.jpg')
      },
         ParallaxClippedwithIMG(){
            return this.tab.Clipped && typeof this.tab.ClippingIMG !== 'undefined' || 
            this.tab.IMGMaskReveal && typeof this.tab.MaskIMG !== 'undefined'
        },
      ParallaxWidth(){
          var element = document.getElementById('parallax');
          if(element){
             var positionInfo = element.getBoundingClientRect(); 
             console.log(element)
             console.log(positionInfo)
             if(positionInfo){
                 var height = positionInfo.height;
                var width = positionInfo.width;
                if(width){
                   return width  
                }
               

             }
          }
        
        
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
        this.CheckifParallaxClipped(this.tab)
         this.CheckifMaskIMGClipped(this.tab)
    },
    
    methods: {
      ActiveHUDItemAction(item){
        this.$emit('ActiveHUDItemAction',item)
      },
      ParallaxStyle(tab){
        let style = ''
        if(tab.FullHeight){
          style = 'height: 100%;'
        }
        else{
          style = 'height: '+tab.Height+'px;'
        }
        if(tab.IMGMaskReveal){
          style = style+'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'+this.MaskIMGClippingStyle
        }
        else{
          style = style+'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'+this.CheckifClipped(tab)
        }
        return style
       },
      CheckifMaskIMGClipped(tab){
        
        if(tab.IMGMaskReveal && tab.MaskIMG){
          this.GetBlob(tab.MaskIMG).then(result => {
            this.MaskIMGClippingStyle = '--anim-dur:'+tab.MaskAnimDuration+'ms;--anim-del: '+tab.MaskAnimDelay+'ms;--anim-frames:'+tab.MaskIMGFrames+';'+`-webkit-mask-image: url(`+result+`);
                                        -webkit-mask-repeat: no-repeat;-webkit-mask-size: auto;position: absolute;width: 100%;z-index: 2;top:0px;`
                                        this.TabReady = true
                                        setTimeout(() => {                                                                                   
                                        this.PlayAnimation()
                                        }, 100);
          })
          }
      },
      PlayAnimation(){
            let elmntid = this.tab.ElementID+'revealimg'
            let docelmnt = document.getElementById(elmntid)
            //console.log(elmntid,docelmnt)
            if(docelmnt){
                docelmnt.classList.add('mask-animation-parallax')
                setTimeout(() => {
                    //also only remove, if it must be removed. you get?
                    //docelmnt.classList.remove('mask-animation-parallax')
                    //timeout could be based on timeout set in tab but anywho
                }, 3000);
            }
        },
      ActivateElementConfigComp(compname,tab,i){
            this.$store.commit('setActiveElmntConfigComp',compname)
            this.OpenDialog(tab,i)
        },
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        GetBlob(asset){
        // console.log(asset)
        return new Promise(function(resolve, reject) {
            
        var img = document.createElement("IMG");
        img.setAttribute('crossOrigin','anonymous')
        img.setAttribute('src',asset)
        document.body.appendChild(img)
        
        let vm = this
        img.onload = function() {
        var c = document.createElement("canvas");
        c.setAttribute('crossOrigin','anonymous')
        var ctx = c.getContext("2d");
        c.width = img.naturalWidth
        c.height = img.naturalHeight
        ctx.drawImage(img, 0, 0);
        var dataURL = c.toDataURL("image/png");
        //console.log('dataURL',dataURL)
        document.body.removeChild(img)
        resolve(dataURL)
        //.replace(/^data:image\/(png|jpg);base64,/, "");    
        }
        })
        },
        CheckifParallaxClipped(tab){
         //console.log('CheckifParallaxClipped',tab)
        if(tab.Clipped && tab.ClippingIMG){
          this.GetBlob(tab.ClippingIMG).then(result => {
            // -webkit-mask-size: contain; changed to auto
            tab.ClippingStyle = `-webkit-mask-image: url(`+result+`);
                                        -webkit-mask-repeat: no-repeat;
                                         -webkit-mask-size: cover;
                                        -webkit-mask-position: center;`
                                        // was -webkit-mask-size: auto;
                                        // console.log('clippingstyle',clippingstyle)
                                        console.log(tab)
                                        this.TabReady = true
          })
          }
          else{
            this.TabReady = true
          }
      },
        OpenContentTypesDialog(elmnt,elmntindex,isrow,rowindex){
           
            this.$emit('OpenContentTypesDialog',elmnt,elmntindex)
            // this.OpenDialog(elmnt,elmntindex)
            this.$emit('OpenDialog',elmnt,elmntindex,true,this.i,isrow,rowindex)
        },
        ClassCheck(tab){
           var newclass = ''
           
            if(tab.Transparent){
                newclass = newclass+' red'
                tab.Color = '#ffffff'
            }
             if(tab.Clipped){
                newclass = newclass+' clipped'
            }
            return newclass
        },
     OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex){
         if(isrow){
             this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex)
         }
         else{
            this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
        
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){         
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return tab.ClippingStyle 
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px!important;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px!important;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily,this.PageHeadersFont)
       },

    }    
}
</script>

<style>
.parallaxheader{
  align-items: start;
  bottom: 0;
  justify-content: center;
    height: 100%;
  width: 100%;
  z-index:10
}
.reveal-img-parallax{
/* position: absolute;
inset: 0; */
-webkit-mask-position: 0% 0%;
mask-position: 0% 0%;
-webkit-transition: -webkit-mask-position 1s;
transition: mask-position 1s;
}
.mask-animation-parallax {
    animation-name: revIMG;
    animation-duration: var(--anim-dur);
    animation-delay: var(--anim-del);
    animation-timing-function: steps(var(--anim-frames));
    animation-fill-mode: forwards;
}
@keyframes revIMG {
    0% {
        -webkit-mask-position: 0% 0%;
        mask-position: 0% 0%
    }
    100% {
        -webkit-mask-position: 100% 0%;
        mask-position: 100% 0%
    }
}
</style>