<template>
<div>
    <v-layout class="justify-center">
        <v-img :height="150" contain :src="FieldValue && FieldValue.fileurl ? FieldValue.fileurl : 
        FieldValue ? FieldValue : 
          require('@/assets/ImageHolder.png')">
                <v-row  
                style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding:0px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">        
                <v-chip style="cursor: pointer;"  
                @click="ActivateGalleryDialog(FieldProp)" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
            </v-img> 
    </v-layout>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit','FieldProp'],
    components: {},
    data() {
        return {
          Editing: false,
          localURL: '',
          uploadfile: '',
          Refreshing: false,
        }
    },	
    computed:{
      field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.col.Field.id] ? 
        this.Record[this.col.Field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ActivateGalleryDialog(prp){
            this.$store.commit('SetSocialItemInteractMethodProp',true)
            this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
            this.$store.commit('setIMGProp',prp)
        },
        onPhotoUpload(field){
            let payload = {
                filename: this.uploadfile.name,
                file: this.uploadfile,
                field: field
            }
            //console.log('payload',payload)
            this.$emit('onPhotoUpload',payload)
            this.closeUpload()
        },
        closeUpload(){
            this.uploadfile = ''
            this.localURL = ''
            this.Editing = false
            this.Refresh()
        },
        onPhotoFileselected(event){
        this.uploadfile = event.target.files[0];
        this.localURL = URL.createObjectURL(this.uploadfile)
        //this.Record[field.Name] = {UploadFile: file, FileName: file.name}
        //field.UploadFile = file
        // this.Record[field.Name+'localURL'] = 
        //console.log(this.Record,field,file)
        //console.log('this.localURL',this.localURL)
        this.Refresh()
        },
        Refresh(){
            //alert('yay')
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 10);
        },
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },
      GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            console.log(item)
            GetSignedInURL(item).then(result => {
            let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
            })
        },

    }
}
</script>

<style>

</style>



