<template>
<v-main>
      <v-card class="transparent" style="margin-top:60px;" flat tile>
          <v-card-title class="recordtoolbar white--text largeoverline">
              {{RecordData.Name}} - Warehouses
          </v-card-title>
      <v-layout class="justify-center" row style="margin-top:60px;">
     <v-flex lg10 md10>
     <v-toolbar v-if="WarehousesSearched" rounded dark color="primary">
       <v-toolbar-title>Warehouses</v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field 
          v-model="warehousesearch"
          append-icon="mdi-file-find-outline"
          label="Search Warehouse"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetWHSearch()" v-if="warehousesearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
          <v-dialog v-model="WarehouseDialog" width="400">
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="recordtoolbar white--text">
                New Warehouse
              </v-card-title>
              <v-card-text>
                <FieldsForm :FormFields="WarehouseHeaders" :Validating="Validating" :DataTableView="true" @save="SaveWarehouse" :DataObject="editedWarehouse" :tab="{}" />
               
              </v-card-text>
               <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="CloseWarehouseDialog()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
     </v-toolbar>
     </v-flex>
   </v-layout>
    <v-layout class="justify-center" row>
     <v-flex lg10 md10>
       <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="WarehouseHeaders"
      :items="WarehousesSearched"      
     
     :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
      >
      <template v-slot:[`item.Name`]="{ item }">
        <v-btn outlined fab small :to="'/Warehouse/'+BuID+'/'+item.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.Name}}
      </template>
       <!-- <template v-slot:[`item.actions`]="{ item }">
     <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item.CanEdit">
       
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item.CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>         
      </template>-->
       </v-data-table>
      </v-flex>
  </v-layout>
      </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/Fields/FieldsForm';

export default {
    props: ['System','SystemEntities'],
    components: {FieldsForm},
    data() {
        return {
            editedwarehouseindex: -1,
            editedWarehouse: {
                Name: '',
                Description: '',
                Is_Public: false,
                Export: false,
            },
            defaultWarehouse: {
                Name: '',
                Description: '',
                Is_Public: false,
                Export: false,
            },
            WarehouseDialog: false,
            Validating: false,
            WarehouseHeaders: [
                {Name: 'Name',DisplayName: 'Name',propvalue: 'Name',text: 'Name',value: 'Name',class: 'overline',Type: 'Single Line Text',Primary: true},
                {Name: 'Description',DisplayName: 'Description',propvalue: 'Description',text: 'Description',value: 'Description',class: 'overline',Type: 'Multiple Lines Text'},
                {Name: 'Is_Public',DisplayName: 'Is Public',propvalue: 'Is_Public',text: 'Is Public',value: 'Is_Public',class: 'overline',Type: 'Boolean'},
                {Name: 'Exports',DisplayName: 'Exports',propvalue: 'Exports',text: 'Exports',value: 'Exports',class: 'overline',Type: 'Boolean'},
                {text: 'Actions',value: 'actions',class: 'overline'}
            ],
            warehousesearch: '',
        }
    },
    computed:{
        RecordData(){
            return this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.BuID)
        },
        BuID(){
            return this.$route.params.id
        },
        Warehouses(){
            return this.$store.state.WarehousesArray.filter(wh => {
                return wh.Business_Unitid === this.BuID
            })
            },
            WarehousesSearched(){
            return this.Warehouses.filter(wh => {
                if(this.warehousesearch){
                return wh.Name.toLowerCase().includes(this.warehousesearch.toLowerCase())
                }
                else{
                return wh
                }
            })
            },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
    },
    created(){
        
    },
    methods:{
        SaveWarehouse(){
      let whid = ''
      
      this.WarehouseHeaders.map(head => {
        if(typeof head.Response !== 'undefined'){
          this.editedWarehouse[head.propvalue] = head.Response
        }
      })
      this.editedWarehouse.Business_Unit = {
        Name: this.RecordData.Name,
        id: this.BuID
      }
      this.editedWarehouse.Business_Unitid = this.BuID
      if(this.editedwarehouseindex === -1){
        const functions = firebase.functions();
      const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let payload = {
            Docid: 'Warehouse_Warehouse_Number',
            Data: {},
            siteid: this.$store.state.ActiveSuiteid
          }
         AssignAutoNumber(payload).then(result => {
            whid = result.data
            this.editedWarehouse.Warehouse_Number = whid
            this.editedWarehouse.Store_Entity = this.$store.state.StoreEntity.id
            this.editedWarehouse.Primary_Appid = this.ActiveSuiteApp.id
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(whid).set(this.editedWarehouse).then(setdoc => {
              this.CloseWarehouseDialog()
            })
          })
      }
      else{
        whid = this.editedWarehouse.id
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(whid).set(this.editedWarehouse).then(setdoc => {
        this.CloseWarehouseDialog()
      })
      }      
        
    },
    CloseWarehouseDialog(){
      this.WarehouseDialog = false
      this.editedWarehouse = Object.assign({},this.defaultWarehouse)
      this.WarehouseHeaders = this.WarehouseHeaders.map(head => {
        delete head.Response
        return head
        })
    },
    ValidateForm(){
        this.Validating = true
      },
    }
}
</script>

<style>

</style>
