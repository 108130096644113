<template>
  <div style="margin-top: 10px;">
    <v-dialog v-model="VacancyDialog" persistent>
      <VacancySingle v-if="ActiveVacancy" :Directories="Directories" :Editing="false" @ConfirmPublish="ConfirmPublish"
        :ActiveVacancy="ActiveVacancy" @GoBack="DeactivateVacancy" @Edit="Edit" @SaveVacancy="SaveVacancy"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications" :QuickView="true"
        @DeactivateActivesession="DeactivateActivesession"  :SubscriptionPackages="SubscriptionPackages"
        />
    </v-dialog>
    <v-list v-for="jobapp in Applications" :key="jobapp.itemObjKey">
              <!--   -->
              <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;"
               @click="ActivateVacancy(jobapp.Vacancy)">
                  <v-list-item-avatar class="white--text" :color="jobapp.Vacancy.Color" v-if="!jobapp.Vacancy.Company || jobapp.Vacancy.Company && !jobapp.Vacancy.Company.Logo"
                  :size="MiniView ? 25 : 50" tile >
                    {{jobapp.Vacancy.AvatarTitle}}
                  </v-list-item-avatar>
                  <v-list-item-avatar class="white--text" v-if="jobapp.Vacancy.Company && jobapp.Vacancy.Company.Logo" :size="MiniView ? 25 : 50" tile >
                    <img style="object-fit: cover;"
                    :src="jobapp.Vacancy.Company.Logo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                    {{jobapp.Vacancy.Title}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{jobapp.Vacancy.Company.Company_Name}}
                    </v-list-item-subtitle>
                     <v-list-item-subtitle>
                      {{jobapp.Vacancy.Location_City}}
                    </v-list-item-subtitle>
                  </v-list-item-content>    
                  <v-list-item-action >
                    <v-btn :color="jobapp.Progress && jobapp.Progress.Color ? jobapp.Progress.Color : ''" outlined>
                    {{jobapp.Progress ? jobapp.Progress.Name : 'Received'}}</v-btn>
                  </v-list-item-action>                
              </v-list-item>
              <v-list-item class="justify-end">
               <v-chip><v-icon>mdi-clock-outline</v-icon>{{jobapp.Vacancy.PublishAgeing}}
                    </v-chip>
                    <v-chip v-if="jobapp.Vacancy.Renumeration"><v-icon>mdi-account-cash</v-icon>{{jobapp.Vacancy.Renumeration}}
                    </v-chip>
                    <v-chip><v-icon>mdi-briefcase</v-icon>{{jobapp.Vacancy.Employment_Type.Name}}
                    </v-chip>
              </v-list-item>
          </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DateRender from '@/components/Database/Fields/ReadOnly/DateRender';	
import TimelineTab from '@/components/WebPages/RenderComponents/TimelineTabReadOnly';
import VacancySingle from '@/components/SuitePlugins/Vacancies/VacancySingle';
export default {
    props: ['System','SystemEntities','Applications'],
    components: {TimelineTab,VacancySingle},
    data() {
        return {
          VacancyDialog: false,
          ActiveVacancy: ''
        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      DeactivateVacancy(){
        this.VacancyDialog = false
        this.ActiveVacancy = ''
      },
      ActivateVacancy(vac){
        this.ActiveVacancy = vac
        this.VacancyDialog = true
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
    }
}
</script>

<style>

</style>



