<template>
  <div style="padding: 20px;"> 
     <MemberCompanyDialog v-if="NewCompanyDialog" @SelectCompany="SelectCompany"
            :System="System" :SystemEntities="SystemEntities"
            @DeactivateNewCompanyDialog="DeactivateNewCompanyDialog"
                />
     <HelperComponent :HelperActive="HelperActive" :HelperElements="ComputedHelperElements" :OffsetX="'left'" :OffsetXVal="150" :OffsetY="'top'" :OffsetYVal="0"
    @DeactivateHelper="DeactivateHelper" @ActivateHelper="ActivateHelper" @StopHelper="StopHelper"  :KeepPulseIndexes=[]
    :HelperID="ActiveHelperID"
    />
    <v-card-title style="margin-bottom: 20px;" v-if="!QuickSave">
        <v-btn id="pageeditbtn" absolute @click="NavigateWebsiteBuilder()" outlined style="width:50%;left: 25%;">
            Edit Site
        </v-btn>
    </v-card-title>
     <v-list-item v-if="!System.Company && !QuickSave">
           <v-btn @click="ActivateNewCompanyDialog()" width="100%"><v-icon>mdi-plus</v-icon> Add Company</v-btn>
          </v-list-item>
           <v-list-item v-if="System.Company && !QuickSave">
           <v-btn @click="ActivateNewCompanyDialog()" width="100%">{{System.Company.Company_Name}}</v-btn>
          </v-list-item>
              <!-- <v-list-item class="justify-center">
             <QRImageFieldCard  :elmntid="progressvalue === 100 ? 'QRImage' : ''"
        :System="System" :SystemEntities="SystemEntities" :id="progressvalue === 100 ? '' : 'QRImage'"
        :col="QRFieldCol" :Record="ActiveSite" :CanEdit="false"
        @SaveRecord="SaveRecord" :CurrentEntity="ComputedActiveEntity"
    /> 
                </v-list-item> -->
                  <v-card-text>
                   <v-row class="justify-start">                
                <v-col :style="col.Type === 'Counter' ? 'flex-grow: 0!important;' : 'flex-basis: auto;'" v-for="(col,coli) in InfoColumns" :key="col.itemObjKey"
                :xl="col.FlexXLRG" :lg="col.FlexLarge" :md="col.FlexMedium" :sm="col.FlexSmall" :xs="col.FlexXSmall">
                  
                    <v-card :class="row.Transparent ? 'transparent' : ''" :tile="row.Tile" :flat="row.Flat" :elevation="row.Flat? 0 : row.Elevation"
                     height="100%" min-height="20px" width="100%" :style="row.Rounded ? 'border-radius: 25px;' : ''"
                     :color="col.Type === 'Counter' ? col.Color? col.Color : 'red' : ''"
                    >
                     <v-card-subtitle class="subtleoverline" v-if="!HeaderlessColTypes.includes(col.Type)" >{{col.Name}}
                    </v-card-subtitle>                   
                    <v-card-text>
                        <DashProgressCard v-if="col.Type === 'Progress Card'"
                         :System="System" :SystemEntities="SystemEntities" :elmntid="'progresscard'"
                          :col="col" :Record="ComputedProgressItem" :CanEdit="false"
                          @SaveRecord="SaveRecord"
                        />
                        <DashFieldGroup v-if="col.Type === 'Field Group'"
                        :System="System" :SystemEntities="SystemEntities"
                    :col="col" :Record="ActiveSite" :CanEdit="true"
                    :EntityDataRef="EntityDataRef"
                    :RelatedObj="RelatedObj"
                    @SaveRecord="SaveRecord"
                    />
                    <DashFieldCard v-if="col.Type === 'Field Card'"
                         :System="System" :SystemEntities="SystemEntities"
                        :col="col" :Record="ActiveSite" :CanEdit="true"
                        :EntityDataRef="EntityDataRef" :CurrentEntity="CurrentEntity"
                         :RelatedObj="RelatedObj" @SaveDocumentField="SaveDocumentField"
                        @SaveRecord="SaveRecord" @onPhotoUpload="onPhotoUpload"
                        />
                    </v-card-text>
                    </v-card>
                </v-col>
                   </v-row>
     <!-- <DashFieldGroup v-if="ComputedCol.Type === 'Field Group'"
            :System="System" :SystemEntities="SystemEntities"
        :col="ComputedCol" :Record="ActiveSite" :CanEdit="true"
        :EntityDataRef="EntityDataRef"
        :RelatedObj="RelatedObj"
        @SaveRecord="SaveRecord"
        /> -->
                  </v-card-text>
    <!-- </v-card> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DashFieldGroup from '@/components/Database/Fields/Hybrid/DashFieldGroup'
import DashFieldCard from '@/components/Database/Fields/Hybrid/DashFieldCard'
import DashProgressCard from '@/components/Database/Fields/Hybrid/DashProgressCard'
import QRImageFieldCard from '@/components/Database/Fields/Hybrid/FieldCards/QRImageFieldCard'
import HelperComponent from '@/components/General/HelperComponent'
import MemberCompanyDialog from '@/components/Database/Fields/Hybrid/MemberCompanyDialog'
export default {
    props: ['System','SystemEntities','Directories','QuickSave','QuickSaveFileRef'],
    components: {DashFieldGroup,DashFieldCard,DashProgressCard,QRImageFieldCard,HelperComponent,MemberCompanyDialog},
    data() {
        return {
          NewCompanyDialog: false,
            Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            ActiveHelperID: 'SiteListingBuilder',
            HelperElements: [
                    // {elmntid: 'memberdetails',Icon: 'mdi-account',Title: 'Details',Text: `Here you will capture your information needed to complete your profile.`},
                    // {elmntid: 'pageeditbtn',Icon: 'mdi-pencil',Title: 'Page Builder',Text: `Here you will build your page, once the informaiton has ben captured.`},
                    // {elmntid: 'QRImage',Icon: 'mdi-qrcode',Title: 'QR Image',Text: `Your QR Image  will appear here once completed.`},
                    {elmntid: 'progresscard',Icon: 'mdi-progress-alert',Title: 'Progress',Text: `The Progress Card will guide you through the needed steps.`},                    
            ],
            ActivatingHelper: false,
            HelperActive: false,
            ActiveProgressItem: {title: 'Site General',ViewName: 'SiteGeneralView',icon: 'mdi-cogs',StepRequirements: [
          {Check: 1, Name: 'Company Header Info',ParentObj: 'ActiveSite',Prop: {Name: 'Basic_Info',Type: 'Object',ChildProps: [
            
            {Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},
            {Name: 'SiteSlogan',DisplayName: 'Slogan',Type: 'Single Line Text',Optional: true},
            {Name: 'SiteEmail',DisplayName: 'Email',Type: 'Common Field',CommonFieldType: 'Email',Optional: true},
            {Name: 'SiteTelephoneNumber',DisplayName: 'Telephone Number',Type: 'Common Field',CommonFieldType: 'Telephone Number',Optional: true},
            {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text',IsContentEditable: true}        
        ]},
          Tootlip: `<p>This basic contact information helps people reach you.</p>`},
          {Check: 2, Name: 'Business Info',ParentObj: 'ActiveSite',Prop: {Name: 'Basic_Info',Type: 'Object',ChildProps: [
            {Name: 'Business_Hours',DisplayName: 'Business Hours',Type: 'Business Hours',IsContentEditable: true}        
        ]},
          Tootlip: `<p>Give some basic Business information.</p>`},
          {Check: 3, Name: 'Business Info',ParentObj: 'ActiveSite',Prop: {Name: 'Basic_Info',Type: 'Object',ChildProps: [
            {Name: 'GoogleMapsLocation',DisplayName: 'Google Maps Location',Type: 'Google Maps Location',Optional: true},//Google My Maps??
        ]},
          Tootlip: `<p>Give some basic Business information.</p>`},
          {Check: 4, Name: 'Menu Styling',ParentObj: 'ActiveSite',Prop: {Name: 'Menu_Styling',Type: 'Object',ChildProps: [
            {Name: 'Public_Landing_Page',DisplayName: 'Default Page',Type: 'Option Set',Check: 'Present'},
            {Name: 'PublicNavbarDark',DisplayName: 'Navbar Dark',Type: 'Boolean',Optional: true},
            {Name: 'PublicNavbarStyle',DisplayName: 'Navbar Style',Type: 'Option Set',TextOnly: true,Options:['Default','STD Bar'],Optional: true},
            {Name: 'PublicNavbarText',DisplayName: 'Navbar Text',Type: 'Color Picker',Optional: true},
            {Name: 'PublicNavbarSelectedText',DisplayName: 'Selected Item Color',Type: 'Color Picker',Optional: true},
            {Name: 'PublicNavbarItemSelectBG',DisplayName: 'Selected Item BG',Type: 'Color Picker',Optional: true},
            {Name: 'PublicNavbarItemHoverBG',DisplayName: 'Hover Item BG',Type: 'Color Picker',Optional: true},
          ]},
          Tootlip: `<p>Configure your Site's navigational system, and menu styling.</p>`},
          {Check: 5, Name: 'Review and Publish',ParentObj: 'ActiveSite',Prop: {Name: 'Publishing',DisplayName: 'Published',Type: 'Object',
          ChildProps: [
          {Name: 'Site_Category',DisplayName: 'Site Category',Type: 'Option Set',Options: []},
          ]},
          Tootlip: `<p>Let's get you signed up for a Directory!</p>`},
          {Check: 6, Name: 'Listing',ParentObj: 'ActiveSite',Prop: {Name: 'Listing_Info',Type: 'Object',ChildProps: [
            {Name: 'KeyWords',DisplayName: 'Keywords',Type: 'Option Set',IsMultiple: true,CanAdd: true,Checking: 'Present'},
            {Name: 'Logo',DisplayName: 'Site Picture',Type: 'Single File Upload',FeaturedPhoto: true}
          ],},
          Tootlip: `<p>Let's finalize your Directory listing!</p>`},
          
          ]},
            HeaderlessColTypes: [
                'Counter',
                'Field Card',
                // 'Field Group'
            ],
            row: {
                Name: 'Some Row',
                Columns: []
            },
            ActiveSite: {},
            RelatedObj: {},
            DefaultCol: {
                Flat: true,
                Transparent: true,
                Elevation: 0,
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 12,									
                FlexXSmall: 12,
                Name: 'Some Col',
            },            
            AllFieldTypes: ['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
        'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
        'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Social Lookup','Custom Object'],
            SliderFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            ProgressFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            CurrencyFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            LinkFieldTypes: ['Common Field','Shared Doc Link','Route Path','Lookup','Social Lookup','Parent Field'],
            ToggleFieldTypes: ['Boolean','Parent Field'],
            OptionsFieldTypes: ['Option Set','Parent Field','Radio Group'],
            TextFieldTypes: ['Single Line Text','Common Field','Boolean','Option Set','AutoNumber Field',
                    'Number Field','Rollup Calculation','Calculated Field','Lookup','Social Lookup',
                    'Parent Field'],
            WriteupTypes: ['Multiple Lines Text']

        }
    },	
    computed:{
        MyMemberCompanies(){
            return this.$store.state.MemberCompaniesOwned
        },
        MemberCompaniesOther(){
            return this.$store.state.MemberCompaniesOther
        },
        AllMemberCompanies(){
            return this.MyMemberCompanies.concat(this.MemberCompaniesOther)
        },
        ComputedHelperElements(){
            return this.HelperElements
            // .filter(elmnt => {
            //     return this.progressvalue === 100 ? elmnt.Title === 'QR Image' : elmnt
            // }).map(elmnt => {
            //     if(this.progressvalue === 100){
            //         elmnt.Text = 'This QR Image links directly to your profile. You can download, share and use it.'
            //     }
            //     return elmnt
            // })
            //basiclaly only QR once published
        },
        HelperHalted(){
            return this.StoppedHelpers.includes(this.ActiveHelperID)
        },
        StoppedHelpers(){
            return this.$store.state.StoppedHelpers
        },
        AppURL(){
            return this.System && this.System.ClientAppURL ? this.System.ClientAppURL : window.location.host
        },
        QRFieldCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'
            col.Field = {id: 'MemberPageQR',Name: 'MemberPageQR',DisplayName: 'Page QR',Type: 'Common Field',CommonFieldType: 'QR Link',QRLinkFieldType: 'Static URL'}
            col.Field.FieldType = this.progressvalue === 101 ? 'QRImageField' : ''
            col.Field.StaticQRURL = this.AppURL+'/FeaturedMember/'+this.$store.state.ActiveSuiteid+'/'+this.FeaturedMemberDefaultPage
            col.FieldObj = col.Field
            return col
        },
        ComputedProgressItem(){
            let item = Object.assign({},this.ActiveProgressItem)
            item.Steps = item.StepRequirements.length
            item.IncompletedSteps = item.StepRequirements.filter(req => {
            let incomplete = false
            // if(req.Name === 'Site General'){
            //     req.Prop.ChildProps = this.BasicFields
            // }
            // else if(req.Name === 'Profile Photo'){
            //     req.Prop.ChildProps = [this.AvatarField]
            // }
            // else if(req.Name === 'Personal'){
            //     req.Prop.ChildProps = this.BusinessHoursFields
            // }
            // else if(req.Name === 'Overview'){
            //     req.Prop.ChildProps = [Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Objectives'))]
            // }
            // else if(req.Name === 'Objectives'){
            //     req.Prop.ChildProps = [Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Overview'))]
            // }
                if(!this[req.ParentObj]){
                    incomplete = true
                }
                else if(this[req.ParentObj] && !this[req.ParentObj][req.Prop.Name] && req.Prop.Type !== 'Object'){
                    incomplete = true
                }
                else if(req.Prop.Type === 'Object'){
                    req.Prop.ChildProps.map(childprp => {
                        if(!this[req.ParentObj][childprp.Name] && !childprp.Optional){
                            incomplete = true
                        }
                        else if(this[req.ParentObj][childprp.Name] && this[req.ParentObj][childprp.Name].length === 0 && childprp.Type === 'Array' && childprp.Checking === 'Length'&& !childprp.Optional){
                            incomplete = true
                        }
                    })
                }
            return incomplete
            })
            item.StepRequirements = item.StepRequirements.map(req => {
                let incompletecheck = item.IncompletedSteps.find(obj => obj.Check === req.Check)
                if(!incompletecheck){
                    req.Completed = true
                }
                return req
            })
            let Incomplete = item.IncompletedSteps.length 
            item.StepsCompleted = item.Steps-Incomplete     
            item.Progress = Math.floor((item.StepsCompleted/item.Steps)*Math.floor(100))
            item.Tootlip = `All done`
            if(item.Progress < 100){
            item.Tootlip = item.IncompletedSteps[0].Tootlip
            }
            return item
        },
        progressvalue(){
        return this.ComputedProgressItem ? this.ComputedProgressItem.Progress : 0
        },
        UserMarketAccount(){
        return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
        },
        UserSiteAccount(){
        return this.IsPrimarySite ? '' : 
        this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.id === this.$store.state.ActiveSuiteid) : ''
        },
        UserBillingAccounts(){
        return this.$store.state.PrimaryUserObj && this.$store.state.PrimaryUserObj.BillingAccounts ? this.$store.state.PrimaryUserObj.BillingAccounts : []
        },
        AppMonetizeBU(){
            return this.$store.state.PrimaryMonetizeBU 
            //System.Monetization_BU ? this.System.Monetization_BU : ''
            //we now also have SiteMonetizationBU which we can work in later should user have monetizaiton occuring on their site
        },
        FeaturedMemberDefaultPage(){
          return this.$store.state.FeaturedMemberDefaultPage
        },
        InfoColumns(){
            return this.QuickSave ? [this.AvatarFieldCol,this.BasicFieldsCol,this.OverviewCol,this.BusinessHoursCol,this.BusinessAddressCol] :
            [this.ProgressCol,this.AvatarFieldCol,this.BasicFieldsCol,this.OverviewCol,this.BusinessHoursCol,this.BusinessLocationCol]//we need
            //education (at least secondary and tertiary, not just single line text, list items)
            //skils , strengths, weakenesses, languages
            //industries (ANOTHER list)
            //Work history (LIST)
        },
        BusinessHoursFields(){
            return [
                {id: 'Business_Hours',Name: 'Business_Hours',DisplayName: 'Business Hours',Type: 'Business Hours'},
            ]
            //
        },
        BusinessLocationFields(){
            return [
                {id: 'GoogleMapsLocation',Name: 'GoogleMapsLocation',DisplayName: 'Google Maps Location',Type: 'Google Maps Location'},
            ]
            //
        },
        ProgressCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Progress Card'   
            col.Name = 'Progress'         
            col.Color = 'purple'
            col.Field = {id: 'Progress',Name: 'Progress',Type: 'Number Field',DisplayName: 'Progress',IsPercentage: true}
            col.FieldObj = col.Field
            return col
        },
        BusinessAddressCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Name = 'Business Location'
            col.Type = 'Field Card'
            col.Field = Object.assign({},this.FieldCardFields.find(obj => obj.id === 'Invoicing_Address'))
            col.FieldObj = col.Field
            return col
        },
        BusinessLocationCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Name = 'Business Location'
            col.Type = 'Field Card'
            col.Field = Object.assign({},this.FieldCardFields.find(obj => obj.id === 'GoogleMapsLocation'))
            col.FieldObj = col.Field
            return col
        },
        BusinessHoursCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Name = 'Business Hours'
            col.Type = 'Field Group'
            col.Fields = this.BusinessHoursFields
            col.FieldObjects = col.Fields.map(field => {
                        let fieldobj = Object.assign({},this.AllFields.find(obj => obj.id === field.id))
                        fieldobj.FieldType = this.GetFieldType(fieldobj)
                        return fieldobj
                    })
            return col
        },
        BasicFieldsCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Name = 'Basic Information'
            col.Type = 'Field Group'
            
            col.Fields = this.BasicFields.concat(this.SiteCategoryFields)
            col.FieldObjects = col.Fields.map(field => {
                        let fieldobj = Object.assign({},this.AllFields.find(obj => obj.id === field.id))
                        fieldobj.FieldType = this.GetFieldType(fieldobj)
                        return fieldobj
                    })
                    //console.log('col',col)
            return col
        },
        AvatarFieldCol(){            
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'            
            col.Field = Object.assign({},this.FieldCardFields.find(obj => obj.id === 'Logo'))
            col.FieldObj = col.Field
            return col
        },
        OverviewCol(){
            let col = JSON.parse(JSON.stringify(this.DefaultCol))
            col.Type = 'Field Card'
             col.Field = Object.assign({},this.DescriptiveFields.find(obj => obj.id === 'Description'))
            col.Field.FieldType = this.GetFieldType(col.Field)
            col.FieldObj = col.Field
            return col
        },
        FieldCardFields(){
            return this.AllFields.map(field => {
                let fieldobj = Object.assign({},field)
                if(field.Type === 'Google Maps Location'){
                    fieldobj.FieldType = 'GoogleMapsLocation'
                }
                else if(field.Type === 'Option Set'){
                    fieldobj.FieldType = 'Option Toggle'
                }
                else if(field.Type === 'Lookup' && field.RelatedBuildID === 'Users' || field.Type === 'Social Lookup' && field.RelatedBuildID === 'Users'){
                    fieldobj.FieldType = 'User Avatar'
                }
                else if(field.Type === 'Single File Upload'){
                    //console.log('fieldobj',fieldobj)
                    fieldobj.FieldType = field.FeaturedPhoto ? 'Data Image Field' : 'Data Upload File Field'
                }
                else if(field.Type === 'Common Field'){
                    fieldobj.FieldType = field.CommonFieldType === 'QR Link' ? 'QRImageField' : 
                    field.CommonFieldType === 'Physical Address' ? 'PhysicalAddressField' : 
                    field.CommonFieldType === 'Postal Address' ? 'PostalAddressField' : ''
                }
                else if(field.Type === 'Date'){
                    fieldobj.FieldType = 'Date Graphic'
                }
                else if(field.Type === 'Multiple Lines Text'){
                    fieldobj.FieldType = 'Writeup'
                }
                return {id: fieldobj.id,FieldType: fieldobj.FieldType,DisplayName: fieldobj.DisplayName,Name: fieldobj.Name}
            }).filter(field => {
                return field.FieldType
            })
        },
        AvatarField(){
            return {
                id: 'Logo',
                Name: 'Logo',
                DisplayName: 'Photo (MUST be 400 x 400)',
                Type: 'Single File Upload',
                FeaturedPhoto: true
            }
        },
        BasicFields(){
            return this.ActiveProgressItem.StepRequirements[0].Prop.ChildProps.map(field => {
                let fieldobj = Object.assign({},field)
                fieldobj.id = field.Name
                return fieldobj
            })
        },
        DescriptiveFields(){
            let fields = [{id: 'Description',Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text',IsContentEditable: true}]
            return fields
        },
        AllFields(){
            return this.QuickSave ? this.BasicFields.concat(this.BusinessHoursFields,this.BusinessLocationFields,[this.AvatarField],this.SiteCategoryFields,[this.Invoicing_Address]) :
            this.BasicFields.concat(this.BusinessHoursFields,this.BusinessLocationFields,[this.AvatarField],this.SiteCategoryFields)
        },
        SitePackageField(){
            //console.log(this.NewGroupFeatures,this.NewGroupPaidFeatures,this.NewGroupScope,this.NewGroupPaidScope)
            let fieldobj = {id: 'Subscription_Package',Name: 'Subscription_Package',DisplayName: 'Subscription Package',value: 'Subscription_Package',text: 'Subscription Package',
            Type: 'Lookup',LookupFieldName: 'Name',Array: this.AvailableSiteSubscriptionPackages, Response: '',IsMandatory: true,
            LookupSubtitleField: {Name: 'Price',Type: 'Number Field',IsCurrency: true},
            LookupCaptionField: {Name: 'Description'},
            LookupAvatarField: {Name: 'IMG'},DialClass: 'whiteoverlay',
            Has_Selection_Dial: true,CardFlat: true,CardDark: false,DialType: 'Lookup Cards',CardWidth: '100vw',CardHeight: '100vh',CardClass: 'transparent',DialHeader: 'Select a Subscription Package',DialCaption: 'The packages available for your selections are:'}
            return fieldobj
        },
        SitesDirectory(){
            return this.Directories.find(obj => obj.id === 'Systems')
        },
        SitesCategoryField(){
            return this.SitesDirectory && this.SitesDirectory.ListPrimaryField ? this.SitesDirectory.ListPrimaryField : ''
        },
        SiteCategories(){
            let field = this.SitesDirectory.ListPrimaryField
            return field && field.Options ? 
            field.Options : []
        },
        SiteCategoryFields(){
            return [this.SitesCategoryField,this.KeyWordsField,this.SiteTypeField,this.SiteLogoBGField,this.CustomDomainField].filter(field => {
                return !this.QuickSave
            })
        },
        SiteKeyWordOptions(){
            return this.$store.state.SiteKeyWordOptions.filter(kw => {
                return kw.Type.Name === 'Site'
            })
        },
        CustomDomainField(){
            return {id: 'ClientAppURL', Name: 'ClientAppURL',DisplayName: 'Site URL',Type: 'Single Line Text'}
        },
        SiteLogoBGField(){
            return {id: 'Logo_BG_Color',Name: 'Logo_BG_Color',DisplayName: 'Logo BG Color',Type: 'Color Picker'}
        },
        SiteTypeField(){
            return {id: 'SiteType',Name: 'SiteType',DisplayName: 'Site Type',Type: 'Option Set',Options: ['Website','Profile','Web App','Network']}
        },
        KeyWordsField(){
            return {id: 'KeyWords',Name: 'KeyWords',DisplayName: 'KeyWords',Type: 'Option Set',IsMultiple: true,Options: this.SiteKeyWordOptions,CanAdd: true}
        },
        ComputedActiveEntity(){
            return {id: 'Systems',AllFields: this.AllFields,WikiFields: this.AllFields,Galleries: [],DocumentRegisters: [],SubCollections: [],Primary_Field_Name: 'Name'}
        },
        EntityDataRef(){
            return db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        System: {
        handler: function(newvalue, oldvalue) {
          if(newvalue !== oldvalue){
            this.GetActiveSite()
          }
        }
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.PrepareAddressField('Invoicing_Address')
        this.GetActiveSite()
    },
    methods:{
        SelectCompany(comp){
            let compbj = {
                Company_Name: comp.Company_Name,
                id: comp.id
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).update({
                Company: comp,
                Companyid: comp.id
            }).then(upddoc => {
                this.DeactivateNewCompanyDialog()
            })
        },
        ActivateNewCompanyDialog(){
        this.NewCompanyDialog = true
      },
      DeactivateNewCompanyDialog(){
        this.NewCompanyDialog = false
      },
      PrepareAddressField(fieldname){
        let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
      },
        NavigateWebsiteBuilder(){
            this.$emit('NavigateWebsiteBuilder')
        },
        ActivateHelper(HelperID){
            this.ActiveHelperID = HelperID
            if(!this.HelperHalted){
            this.ActivatingHelper = true
            setTimeout(() => {
            this.HelperActive = true
            this.ActivatingHelper = false
                
            }, 1000);
            }
        },
        StopHelper(HelperID){
          this.StoppedHelpers.push(HelperID)
          this.DeactivateHelper()
      },
      DeactivateHelper(){
          this.HelperActive = false            
      },
        CheckforMarketAccount(){
            let vm = this
            if(!this.UserMarketAccount && this.AppMonetizeBU){
                vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
                vm.$store.commit('setCustomProcessingDialog',true)
                const functions = firebase.functions();
                const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
                let payload = {
                    Data: vm.userLoggedIn,
                    AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
                }
                CreateMarketAccount(payload).then(result => {
                    console.log(result)
                    vm.$store.commit('setCustomProcessingDialogText','Done Creating')
                    vm.$store.commit('setCustomProcessingDialog',false)
                })
                }
        },
        SaveRecord(payload){
            if(!this.QuickSave){
            this.CheckforMarketAccount()
        //console.log('payload',payload)
        let record = payload.Record
        let fields = payload.Fields
        let ref = this.EntityDataRef
        let updateobj = {}
        updateobj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        updateobj.Modified_Byid = this.userLoggedIn.id
        updateobj.Modified_On = new Date()
        fields.map(field => {
          if(typeof record[field.id] !== 'undefined'){
            updateobj[field.id] = record[field.id]
            if(field.Type === 'Lookup'){
              updateobj[field.id+'id'] = updateobj[field.id].id
            }
            //DATES AND "TIMERSPONSE"
            //PHYSICOL ADDRESS TYPE FIELDS....ETC.
            //if owner, see DashUserAvatar, this.AssignBU() function, triggering notificaiton to owner as well etc etcetc
            //and a bunch of other stuff, seirously but okay
          }
        })
       // console.log(ref,updateobj)
        // ref.update(updateobj)
        let kwfield = fields.find(obj => obj.id === 'KeyWords')
        let compnamefield = fields.find(obj => obj.id === 'Company_Name')
        if(kwfield || compnamefield){
          this.ProcessSiteUpdate(updateobj,ref)  
        }
        else{
            ref.update(updateobj)
        }
        //this.ProcessSiteUpdate(updateobj,ref)
            }
            else{
                this.$emit('UpdateQuickSave',payload)
            }
      },
      ProcessSiteUpdate(site,siteref){
        site.KeyWords = site.KeyWords.map(kw => {
                    let kwobj = {}
                    if(!kw.id){
                        let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
                        let date = new Date()
                        kwobj.Name = kw
                        kwobj.Type = {
                            ID: 1000002,
                            Name: 'Site'
                        },
                        kwobj.Created_By = user  
                        kwobj.Created_Byid = user.id
                        kwobj.Modified_By = user         
                        kwobj.Modified_Byid = user.id
                        kwobj.Created_On = date
                        kwobj.Modified_On = date
                        kwobj.Original_Recordid = this.$store.state.ActiveSuiteid
                    }
                    else{
                        kwobj = Object.assign({},kw)
                    }
                    return kwobj
                })
        let unregisteredkeywords = site.KeyWords.filter(kw => {
                return !kw.id
            })
            //console.log('unregisteredkeywords',unregisteredkeywords)
        if(unregisteredkeywords.length > 0){
                unregisteredkeywords.map((kw,kwindex) => {
                     let path = db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords')
                        let ref = path.doc()
                        let newdocid = ref.id
                        db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords').doc(newdocid).set(kw).then(newkwdoc => {
                        let sitekwobj = site.KeyWords.find(obj => obj.Name === kw.Name)
                        sitekwobj.id = newdocid
                        if(kwindex-1+2 === unregisteredkeywords.length){
                           this.UpdateSearchandSite(site,siteref)
                        }
                    })
                })
            }
            else{
                this.UpdateSearchandSite(site,siteref)
            }
      },
      GetSiteKeyWordsSearchQuery(KeyWords,query){
        let vm = this
        return new Promise(function(resolve, reject) {
            KeyWords.map((kw,i) => {
                let title = kw.Name
                //console.log('kw',kw,title)
                    vm.$store.dispatch('CreateSearchTitle',kw.Name).then(searchtitle => {
                    query = query.concat(searchtitle)
                    if(i-1+2 === KeyWords.length){
                        resolve(query)
                    }
                    })
                })
        })
        },
      UpdateSearchandSite(site,siteref){
            let vm = this
            let query = []
            if(site.Company_Name){
            vm.$store.dispatch('CreateSearchTitle',site.Company_Name).then(searchtitle => {
                query = searchtitle
                if(site.KeyWords && site.KeyWords.length > 0){
                vm.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                    query = query.concat(kwsearch)
                    site.SearchQuery = query
                    siteref.update(site).then(upddoc => {
                        //this.CancelUpdateSiteInfo()
                    })
                })
                }
                else{
                    site.SearchQuery = query
                    siteref.update(site).then(upddoc => {
                        //this.CancelUpdateSiteInfo()
                    })
                }
                
            })
            }
            else if(site.KeyWords && site.KeyWords.length > 0){
            vm.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                query = query.concat(kwsearch)
                site.SearchQuery = query
                siteref.update(site)
            })
            }
        },
        onPhotoUpload(payload) {
            //')
            console.log('onPhotoUpload')
          let filename = payload.filename
          let file = payload.file
          let field = payload.field
          //'UserAssets/'+vm.userLoggedIn.id+'/'+new Date()
          let storepath = this.QuickSaveFileRef ? this.QuickSaveFileRef+'/'+new Date() : 'Sites/'+this.$store.state.ActiveSuiteid+'/FeaturedMembers/'+field.id+'/'+file.name+'/'+new Date()
            let vm = this
            var storageRef = firebase.storage().ref(storepath)
            var uploadTask = storageRef.put(file)
            vm.$store.commit('SetSocialItemInteractMethodProp',true) 
            vm.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
            vm.PrepareThumbnail(filename,file,storepath).then(thumbresult => {
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                 let UploadObject = {
                    fileurl: url,
                    url: url,
                    path: url,
                    Path: url,
                    ThumbURL: thumbresult,
                    FileType: file.type,
                    Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                    Created_Byid: this.userLoggedIn.id,          
                    Created_On: new Date(),	
                    Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                    Modified_Byid: vm.userLoggedIn.id,
                    Modified_On: new Date(),
                    ModifiedDate: new Date(file.lastModified),
                    Name: file.name,
                    Size: file.size,
                    StorageRef: storepath,
                    filename: filename
                    }
                
                if(!vm.QuickSave){
                    vm.EntityDataRef.update({
                            [field.Name]: UploadObject,
                            [field.Name+'ThumbURL']: UploadObject.ThumbURL
                    })   
                }
                else{
                    let payload = {
                        field: field,
                        UploadObject: UploadObject
                    }
                   vm.$emit('QuickSaveFile',payload) 
                }
                
                vm.$store.commit('SetSocialItemInteractMethodProp',false) 
                vm.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
                let snackbarcontent = {
                snackbartimeout: 4000,
                snackbartext: 'Added '+field.DisplayName+' to Record',
                snackbartop: true,
                }
                vm.$emit('ActivateSnackbar',true,snackbarcontent)
                // delete field.localURL
                // delete field.file
                    })
            })
        },
        PrepareThumbnail(filename,file,storageref){
            let vm = this
            return new Promise(function(resolve, reject) {	
            file.tmpsrc = URL.createObjectURL(file)
                //this.OutboundPhotosforUpload.push(file)
                var img = document.createElement("IMG");
                    img.setAttribute('width',150)
                    img.setAttribute('src',file.tmpsrc)
                    document.body.appendChild(img)
                    let vm = this
                    img.onload = function() {
                    var c = document.createElement("canvas");
                    var ctx = c.getContext("2d");
                    var canvasRatio = img.naturalHeight / img.naturalWidth
                    c.width = 400
                    c.height = c.width*canvasRatio
                    ctx.drawImage(img, 0, 0, c.width, c.height);
                    c.toBlob(blob => {
                    let blobfile = new File([blob], filename, {
                    type: file.type,
                });
                //console.log(blobfile)
                var storageRef = firebase.storage().ref(storageref+'_thumb')
                    document.body.removeChild(img)
                    var uploadTask = storageRef.put(blobfile)
                    uploadTask
                    .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((thumburl) => {
                        //console.log(thumburl)
                        resolve(thumburl)
                        })
                })
                    }
            })
        },
        GetFieldType(field){
            //the below validates ParentFieldObjType but does NOT prepare it, so a "Lookup" could become "text" for parent field
            let linkcommontypes = ['URL Link']
            let linkfieldtypes = this.LinkFieldTypes.filter(type => {return type !== 'Common Field'})
            if(field.Type === 'Business Hours'){
                return 'BusinessHoursField'
            }
            else if(field.Type === 'Google Maps Location'){
                return 'GoogleMapsLocation'
            }
            else if(field.Type === 'Color Picker'){
                return 'ColorField'
            }
            else if(this.SliderFieldTypes.includes(field.Type) && field.IsSlider || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.SliderFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsSlider ){
                return 'SliderField'
            }
            else if(this.ProgressFieldTypes.includes(field.Type) && field.IsPercentage || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ProgressFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsPercentage ){
                return 'ProgressField'
            }
            else if(this.CurrencyFieldTypes.includes(field.Type) && field.IsCurrency || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.CurrencyFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsCurrency ){
                return 'CurrencyField'
            }
            else if(this.ToggleFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ToggleFieldTypes.includes(field.ParentFieldObj.Type)){
                return 'ToggleField'
            }
            else if(this.WriteupTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.WriteupTypes.includes(field.ParentFieldObj.Type)){
                return 'Writeup'
            }            
            else if(this.OptionsFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.OptionsFieldTypes.includes(field.ParentFieldObj.Type)){
                return 'OptionsField'
            }
            else if(linkfieldtypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && linkfieldtypes.includes(field.ParentFieldObj.Type) || 
            linkcommontypes.includes(field.CommonFieldType) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && linkcommontypes.includes(field.ParentFieldObj.CommonFieldType)){
                return field.Type === 'Route Path' ? 'RoutePathField' : 
                field.Type === 'Social Lookup' ? 'SocialLookupField' : 
                field.CommonFieldType === 'URL Link' ? 'URLLinkField' : 'LookupField'
            }
            else if(field.Type === 'Date' || 
            field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Date'){
                return 'DateField'
            }
            else if(field.Type === 'Number Field' || 
            field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Number Field'){
                //by now only basic number field remaining
                return 'NumberField'
            }
            else if(this.TextFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.TextFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj){
                return field.CommonFieldType === 'Email' ? 'EmailField' : field.CommonFieldType === 'Telephone Number' ? 'TelephoneNumberField' : 
                field.CommonFieldType === 'Physical Address' ? 'PhysicalAddressField' : field.CommonFieldType === 'Postal Address' ? 'PostalAddressField' : 'TextField'
            }
            else{
                return 'TextField'
            }
            //DateField NumberField Option of fucking snap....this was supposed to happen...
        },
        GetActiveSite(){
            this.ActiveSite = JSON.parse(JSON.stringify(this.System))
            if(!this.ActiveSite.Business_Hours){
                this.ActiveSite.Business_Hours = {
                    WeekDays: {},
                    WeekEnds: {},
                    PublicHolidays: {}
                }
            }
            if(!this.QuickSave){
            this.ActivateHelper()
            }
    }
    }
}
</script>

<style>

</style>



