<template>
<v-row class="justify-center">
  <v-col cols="6">
    <v-card flat tile class="background">
   <!-- <v-card-subtitle class="background darken-1 mediumoverline" v-if="EditedTab.Name !== 'Card Block Single'">
            Box Shape
    </v-card-subtitle> -->
        
      <v-list-item dense
        v-if="EditedTab.Name !== 'Clipped Tab' &&
          EditedTab.Name !== 'Card Block Single'" 
      > 
        <v-switch dense v-model="EditedTab.Shaped" label="Shaped" />
      </v-list-item>
      <v-list-item dense
        v-if="EditedTab.Name !== 'Clipped Tab' &&
          EditedTab.Name !== 'Card Block Single'" 
      > 
        <v-switch dense v-model="EditedTab.Outlined" label="Outlined" />
        </v-list-item>
      <v-list-item dense
        v-if="EditedTab.Name !== 'Clipped Tab' &&
          EditedTab.Name !== 'Card Block Single'" 
      >
        <v-switch dense v-model="EditedTab.Flat" label="Flat" />
      </v-list-item>
      <v-list-item dense
        v-if="EditedTab.Name !== 'Clipped Tab' &&
          EditedTab.Name !== 'Card Block Single'" 
      >
        <v-switch dense v-model="EditedTab.Tile" label="Tile" />
      </v-list-item>
  </v-card>
  </v-col>
  <v-col cols="6">
    <v-card flat tile class="background">
       <!-- <v-card-subtitle class="background darken-1 mediumoverline">
            Box Styling
    </v-card-subtitle> -->
     <v-list-item dense
        v-if="EditedTab.Name !== 'Clipped Tab' &&
          EditedTab.Name !== 'Card Block Single'" 
      >
        <v-switch dense v-model="EditedTab.FullHeight" label="Full Height" />
     </v-list-item>
      <v-list-item dense v-if="EditedTab.Name !== 'Card Block Single'">
           <v-slider
          v-model="EditedTab.Elevation"
          min="0"
          max="24"
          label="Elevation"
        ></v-slider>
      </v-list-item>
      <v-list-item dense v-if="EditedTab.Name === 'Timeline Item'">
      
        <v-autocomplete
        :items="AdvancedComputedLibraryColors"
        label="Color"
        v-model="EditedTab.color"
      >
        <template v-slot:item="{ item }">
          <span :class="item"
            >{{ item }}
            <p style="font-size: 12px">
              The Quick brown fox jumps over the lazy dog
            </p>
          </span>
        </template>
      </v-autocomplete>
      </v-list-item>
      <v-list-item dense v-if="EditedTab.Name !== 'Timeline Item'">
      <v-switch v-if="EditedTab.Name !== 'Card Block Single'"
        @change="Onepropemit('UpdateBoxTransparency', EditedTab)"
        v-model="EditedTab.BoxTransparent"
        label="Transparent"
      />
      </v-list-item>
      <v-list-item dense v-if="EditedTab.Name !== 'Timeline Item'">
        
      <v-menu
        
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="200px"
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-palette-advanced</v-icon>
        </template>
        <v-color-picker v-model="EditedTab.BGColor"></v-color-picker>
      </v-menu>
      </v-list-item>
      <v-list-item v-if="EditedTab.Name === 'Parallax'">
      <v-text-field v-model="EditedTab.TextBoxStyle" label="TextBox Style"/>
      </v-list-item>
  </v-card>
  </v-col>
  
</v-row>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
      
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            //console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
