
<template>
      <div class="bootstraphovercard" 
      :style="'--hovcol: '+tab.HoverColor+';--stdcol: '+tab.STDColor+';--stdfontcol: '+tab.STDFontColor+';--hovfontcol: '+tab.HoverFontColor+';'">
     <v-card 
  :elevation="tab.Elevation" :tile="tab.Tile"  
  :outlined="true" :shaped="tab.Shaped"
  :style="tab.SkipWriteup ? DrawBoxStyle(tab)+'padding: 5px;' : Small ? DrawBoxStyle(tab)+'min-height:25vh;padding: 5px;' : DrawBoxStyle(tab)+'min-height:50vh;padding: 5px;'">
    <v-img  v-if="tab.HasImage && tab.IMG && !tab.CardIcon" style="margin-top: 10px;"
        :contain="!tab.DisableContain" :height="tab.Height ? tab.Height : 80"
                :src="tab.IMG">
    <v-btn  :dark="tab.TitleTagDark" v-if="tab.TitleTag" depressed style="position:absolute;top:20px;" tile :color="tab.HeaderBGColor ? tab.HeaderBGColor.hexa : 'links'"  >
      <FieldViewerComponent :field="TagField" :Record="tab"/>
      </v-btn>
       <v-btn :dark="tab.TitleTagDark" v-if="tab.TitleTag && tab.SubTitleTop" depressed style="position:absolute;bottom:0px;right: 0px;" tile 
      :color="tab.SubTitleTopColor ? tab.SubTitleTopColor.hexa : 'links'">
        {{tab.SubTitleTop}}
      </v-btn>
       <v-btn :dark="tab.TitleTagDark" v-if="tab.TitleTag && tab.SubTitleBottom" depressed style="position:absolute;bottom:40px;right: 0px;" tile 
      :color="tab.SubTitleBottomColor ? tab.SubTitleBottomColor.hexa : 'links'">
        {{tab.SubTitleBottom}}
      </v-btn>

    </v-img>
    <v-list-item class="justify-center"  v-if="tab.HasImage && tab.CardIcon">
    <v-icon style="margin-top: 10px;"
        :size="tab.Height ? tab.Height : 80"
        >
        {{tab.CardIcon}}
    </v-icon>
    </v-list-item>
     <v-card-title v-if="tab.Title && !tab.TitleTag" class="justify-center"
     :style="'text-align: center;padding-bottom: 20px;'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
          {{tab.Title}}
    </v-card-title>
    <v-list-item  v-if="!tab.SkipWriteup">
      <HTMLDescription :SkipColor="true" :SkipSize="16"
        :tab="tab" :PageContentFont="PageContentFont"
        />
    </v-list-item>
        <!-- <v-list-item class="justify-center" v-if="tab.FromAdverts && tab.Advert">
     
    </v-list-item> -->
    <v-list-item v-if="tab.HasActionButton && tab.Fancy" :class="tab.ButtonAlignment">
       <v-btn  v-if="tab.FromAdverts && tab.Advert" :dark="tab.ActionBtnDark" x-large text :style="'color: white!important;font-size:0.7em;background-color:'+tab.ButonColor.hexa"
      :disabled="!tab.Advert.TelephoneNumber" :href="'tel: '+tab.Advert.TelephoneNumber" x-large width="100" outlined>
        <v-icon style="color: white!important;" dark>mdi-phone</v-icon>
        
      </v-btn>
      <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
            :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
            />
      </v-list-item>
      <v-list-item v-if="tab.HasActionButton && !tab.Fancy" :class="tab.ButtonAlignment">
          <v-btn  v-if="tab.FromAdverts && tab.Advert" :dark="tab.ActionBtnDark" x-large text :style="'color: white!important;font-size:0.7em;background-color:'+tab.ButonColor.hexa"
      :disabled="!tab.Advert.TelephoneNumber" :href="'tel: '+tab.Advert.TelephoneNumber" x-large width="100" outlined>
        <v-icon style="color: white!important;" dark>mdi-phone</v-icon>
        
      </v-btn>
        <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"									
            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
            :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
            />
      </v-list-item>
    </v-card>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbedReadOnly';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
export default {
    props: ['System','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex',
    'rowindex','PageName','PageID','selectedIndex','Small'],
    components: {
      ActionButtonEmbed,
      HTMLDescription,
      FieldViewerComponent
        },
    data() {
    return {      
      WebFormDialogFancy: false, 
      WebFormDialogNonFancy: false,  
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      TagField(){
        return this.tab.TitleasCurrency ? 
        {id: 'Title',Name: 'Title',DisplayName: 'Title',Type: 'Number Field',IsCurrency: true} : 
        {id: 'Title',Name: 'Title',DisplayName: 'Title',Type: 'Single Line Text'}
      },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){

    },
    
    methods: {
      DrawBoxStyle(tab){
        let style = tab.BGColor ? 'background-color: '+tab.BGColor.hex+';border-radius: 15px;' : 
  'background-color: #ffffff00!important;border-radius: 15px;'
      if(tab.LabelGradient){
        style = style+tab.LabelGradient
      }
        return 'border-radius: 15px;'
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      AvatarStyle(tab){
        if(typeof tab.IMGHeight !== 'undefined'){
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:'+tab.IMGHeight+'px;' : 'height:'+tab.IMGHeight+'px;width: '+tab.IMGHeight+'px;min0width:'+tab.IMGHeight+'px;'
        }
        else{
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:150px;' : 'height:150px;width: 150px;min0width:150px;'
        }
      },
      DeactivateWebDialog(){
            this.WebFormDialogFancy = false
            this.WebFormDialogNonFancy = false
        },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
     ActionButtonOpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog){
        this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog)
      },
      DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>