<template>
  <div>
    <v-card-title class="largeoverline" style="margin-top: 35px;font-weight: 300;">
      <v-icon large>mdi-shopping</v-icon>  Store
      <v-btn @click="DeactivateActivesession()" right style="z-index: 2;" absolute outlined color="red">Exit</v-btn>
    </v-card-title>
    <v-tabs centered>
        <v-tab>Products</v-tab>
        <v-tab-item>
            <SuiteProducts :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @DeactivateActivesession="DeactivateActivesession"
        />
        </v-tab-item>
        <v-tab>Orders</v-tab>
        <v-tab-item>
           <EasyStoreOrders
           :System="System" :SystemEntities="SystemEntities"
           />
        </v-tab-item>
        <v-tab>Product Categories</v-tab>
        <v-tab-item>
        <EasyProductCategories
           :System="System" :SystemEntities="SystemEntities"
           />
        </v-tab-item>
        <v-tab disabled>Store Settings</v-tab>
        <v-tab-item>
           
        </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SuiteProducts from '@/components/SuiteBuilder/Main/SuiteProducts';
import EasyStoreOrders from '@/components/SuitePlugins/EasyProducts/EasyStoreOrders';
import EasyProductCategories from '@/components/SuitePlugins/EasyProducts/EasyProductCategories';
export default {
    props: ['System','SystemEntities','UnreadNotifications','SystemPhotoLibraries','SystemDocumentLibraries','ActiveTemplate','DocumentationLibraries','Directories'],
    components: {SuiteProducts,EasyStoreOrders,EasyProductCategories},
    data() {
        return {

        }
    },	
    computed:{
        CategoryField(){
          return this.ActiveStore && this.ActiveStore.CategoryField ? 
          this.ActiveStore.CategoryField : ''
        },
        ActiveStore(){
          return this.$store.state.ActiveStore
        },
        ProductCategories(){
            return this.CategoryField ? this.CategoryField.Options : []
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    },
    methods:{
        DeactivateActivesession(){
        this.$emit('DeactivateActivesession')
      },
    }
}
</script>

<style>

</style>



