<template>
  <span v-if="!Refreshing">
      <v-dialog v-model="OptionSetDialog" width="800px">									
  <v-card class="slightlypadded">									
    <v-img src='@/assets/RABaseBG.jpeg'>									
      <v-card-title class="headline blue-grey darken-2 white--text">									
                {{EditOptionSet.Name}} - Configuration <v-layout class="justify-end">									
      <v-btn fab @click="SaveOptionSets(true)" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelOptionSetDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn>									
      </v-layout>									
                </v-card-title>							
  <v-layout row  color="rgb(236, 60, 126, 0.7)">									
      									
    <v-col cols="4">									
      <v-list>									
        <v-list-item>									
          <v-list-item-content class="listtitle">									
          Options									
          </v-list-item-content>									
          <v-list-item-action>									
            <v-menu 									
          :close-on-content-click="false"                            									
          transition="scale-transition"                            									
          >									
          <template v-slot:activator="{ on }">									
              <v-btn fab icon v-on="on"><v-icon>mdi-plus-thick</v-icon></v-btn>									
            </template>									
            <v-card width="400px">									
              <v-card-title>									
                New Option for {{EditOptionSet.Name}}									
                </v-card-title>									
                <v-text-field v-model="OptionName" label="Name"></v-text-field>                                                        									
                  <v-card-actions>									
                      <v-btn v-if="OptionName !== ''" @click="AddOptionSetOption(OptionName)">Save</v-btn><v-btn @click="CancelGlobalOptionSetOption()">Cancel</v-btn>									
                  </v-card-actions>									
              </v-card>									
          </v-menu>									
            </v-list-item-action>									
          </v-list-item>									
        </v-list>									
        									
        <v-list>									
        <v-list-item v-for="optionsetoption in EditOptionSet.Options" :key="optionsetoption.itemObjKey" class="listoutline" @click="OptionNameSelect(optionsetoption)">									
          <v-list-item-content>									
            {{optionsetoption.Name}}									
            </v-list-item-content>									
          </v-list-item>									
        </v-list>									
      </v-col>									
      <v-col>									
        <!-- <v-scroll-y-transition mode="in-out" v-if="EditOptionSet.Name"> -->									
									
            <v-card outlined  height="100%" width="100%" color="rgb(255, 255, 255, 0.9)" v-if="EditOptionSet.Name">                                									
                <v-card-title class="headline blue-grey darken-2 white--text" v-if="Option.Name">									
                Option - {{Option.Name}}									
                  <!-- <v-spacer></v-spacer>									
                  <v-switch dark									
                    v-model="Option.IsDefault"									
                    class="mx-2"									
                    label="Is Default"									
                  ></v-switch> -->									
                </v-card-title>									
                <v-card-text >									
                  <p v-if="Option.ID">Option ID: {{Option.ID}}</p>									
                  <v-text-field label="Option Name" v-model="Option.Name" v-if="Option.Name"></v-text-field>									
                  <v-layout class="justify-end" v-if="Option.Name"><v-icon @click="DeleteOption(Option)" color="red">mdi-delete-forever</v-icon></v-layout>                                                       									
                </v-card-text>									
                <v-card-text v-if="typeof Option.Name === 'undefined'">									
                  Add an option to the left<span v-if="EditOptionSet.Options.length>0">, or select an existing option to change its name</span>...                                                    									
                </v-card-text>									
                                        									
            </v-card>									
                                									
            <!-- </v-scroll-y-transition> -->									
        </v-col>									
    </v-layout>									
    									
    <!-- <v-layout class="justify-end">									
      <v-btn fab @click="SaveGlobalOptionSets()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn>									
      </v-layout> -->									
      <v-layout class="justify-end">
          									
      <v-btn fab @click="DeleteFieldOffTabSectionANDRecordType(EditedTab,EditSection,EditOptionSet)" icon><v-icon color="red" x-large>mdi-delete-forever</v-icon></v-btn>									
      </v-layout>									
      </v-img>									
      									
    </v-card>									
  </v-dialog>
      <span v-if="field.Type === 'Route Path'">
          <v-select :items="RouteOptions(field)" item-text="path" :readonly="field.ReadOnly" :label="field[FieldDisplayProp]"  v-model="Record[field.Name]"/>
      </span>
      <span v-if="field.Type === 'Parent Field'">
          <span>You cannot edit this value, it comes from the <i>"{{field.ParentFieldObj.DisplayName}}"</i> field in the linked <b>{{field.RelatedRecord.RelationshipField.DisplayName}}</b>.</span>
          <span v-if="Record[field.RelatedRecord.RelationshipField.Name]"> The linked <i>{{field.RelatedRecord.RelationshipField.DisplayName}}</i> is <b>{{Record[field.RelatedRecord.RelationshipField.Name][field.RelatedRecord.RelationshipField.LookupFieldName]}}</b></span>
      </span>     
      <span v-if="field.Type === 'Option Set'"><span v-if="!field.IsDynamic">
                    <v-select class="smallinput" :solo="Solo" outlined dense clearable
                     :prepend-icon="Record[field.Name] ? Record[field.Name].Icon : ''" 
                     :return-object="!field.TextOnly" :multiple="field.IsMultiple" :chips="field.IsMultiple" v-if="!field.CanAdd"
                    :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.required]: []" v-model="Record[field.Name]" 
                    @change="Refresh()" :items="field.Options" :label="field[FieldDisplayProp]" item-text="Name"></v-select>
                     <v-combobox class="smallinput" :solo="Solo" :return-object="!field.TextOnly" :multiple="field.IsMultiple" :chips="field.IsMultiple" v-if="field.CanAdd"
                    :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.required]: []" v-model="Record[field.Name]" @change="Refresh()" 
                    :items="field.Options" :label="field[FieldDisplayProp]" item-text="Name"></v-combobox>
                    <v-card-subtitle style="padding: 3px;font-style: italic;text-align: right;" class="caption" v-if="Record[field.Name] && Record[field.Name].Description">
                      {{Record[field.Name].Description}}
                    </v-card-subtitle>
                    <span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1]">
                    <v-select class="smallinput" dense clearable
                    :solo="Solo"  outlined :placeholder="field.Levels[1].name+' options'"
                    :label="field.Levels[1].name" :prepend-icon="Record[field.Levels[1].FieldName] ? Record[field.Levels[1].FieldName].Icon : ''"
                    @change="UpdateEditableField(field)" 
                    v-if="Record[field.Name] && Record[field.Name].Options && Record[field.Name].Options.length > 0" :readonly="field.ReadOnly" return-object 
                    :rules="field.IsMandatory? [$store.state.formrules.required] : []" 
                    v-model="Record[field.Levels[1].FieldName]" :items="Record[field.Name].Options" 
                    item-text="Name"></v-select>
                    </span> <v-card-subtitle style="padding: 3px;font-style: italic;text-align: right;" class="caption" v-if="Record[field.Levels[1].FieldName] && Record[field.Levels[1].FieldName].Description">
                      {{Record[field.Levels[1].FieldName].Description}}
                    </v-card-subtitle>
                    <span v-if="field.Levels && field.Levels[1] && field.Levels[2] && Record[field.Levels[1].FieldName] && Record[field.Levels[1].FieldName].Options">
                   
                    <v-select class="smallinput" 
                    :solo="Solo" clearable
                    @change="UpdateEditableField(field)" 
                    v-if="Record[field.Levels[1].FieldName] && Record[field.Levels[1].FieldName].Options.length > 0" :readonly="field.ReadOnly" return-object 
                    :rules="field.IsMandatory? [$store.state.formrules.required] : []" 
                    v-model="Record[field.Levels[2].FieldName]" :items="Record[field.Levels[1].FieldName].Options" 
                    :label="field.Levels[2].name" item-text="Name"></v-select>
                    </span><v-card-subtitle style="padding: 3px;font-style: italic;text-align: right;" class="caption" v-if="Record[field.Levels[2].FieldName] && Record[field.Levels[2].FieldName].Description">
                      {{Record[field.Levels[2].FieldName].Description}}
                    </v-card-subtitle>
                    </span><span v-if="field.IsDynamic">
            <FieldConfig @UpdateField="UpdateEditableField(field)" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
            :CurrentEntity="CurrentEntity" :OptionsOnly="true"
            :SystemEntities="SystemEntities" :field="Record[field.Name]" :NewField="false" :System="System"/>
            <!-- <v-btn @click="ActivateOptionSetDialog(Record[field.Name],field)">Options</v-btn> -->
            </span></span><span v-if="field.Type === 'Single Line Text'">
                    <v-text-field class="smallinput" :solo="Solo" :type="field.IsPassword ? 'password' : 'text'"  :readonly="field.ReadOnly"  :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Name]" :label="field[FieldDisplayProp]"></v-text-field></span><span v-if="field.Type === 'Boolean'">
                    <v-switch  class="smallinput" :solo="Solo" :readonly="field.ReadOnly"  :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Name]" :label="field[FieldDisplayProp]"></v-switch></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'">
        <v-text-field class="smallinput" :solo="Solo"
        @keydown.space.prevent @input="removeWhiteSpace(Record[field.Name])" :readonly="field.ReadOnly"  :rules="field.IsMandatory? [$store.state.formrules.required,$store.state.formrules.email] : [$store.state.formrules.email]" v-model.trim="Record[field.Name]" :label="field[FieldDisplayProp]">
          </v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'URL Link'">
        <v-text-field class="smallinput" :solo="Solo"   :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.required,$store.state.formrules.url] : [$store.state.formrules.url]" v-model="Record[field.Name]" :label="field[FieldDisplayProp]"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Telephone Number'">
        <v-text-field class="smallinput" :solo="Solo"   :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.required,$store.state.formrules.telnr] : [$store.state.formrules.telnr]" v-model="Record[field.Name]" :label="field[FieldDisplayProp]"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'">
                        <v-list  >
                            <!-- <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title> -->
                            <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content>
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content >
                                <v-text-field class="smallinput" :solo="Solo" :rules="field.IsMandatory? [$store.state.formrules.required]: []" v-model="Record[breakdownfield.Name]" :label="breakdownfield.DisplayName"/>
                                </v-list-item-content>
                             
                            </v-list-item>
                        </v-list>
                        </span><span v-if="field.Type === 'Number Field' || field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation'">
                    <v-text-field class="" :filled="field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation' || field.ReadOnly"  :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.numberfield,$store.state.formrules.required] : [$store.state.formrules.numberfield]" v-model.number="Record[field.Name]" type="number" :label="field[FieldDisplayProp]"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text' && !field.IsContentEditable">
                    <v-textarea  class="smallinputspecial" :readonly="field.ReadOnly"  :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Name]"  :label='field[FieldDisplayProp]'></v-textarea></span><span v-if="field.Type === 'Multiple Lines Text' && field.IsContentEditable">
                    <ContentEditableField style="padding:15px;"
                       :FieldObject="Record" :FieldName="field.Name ? field.Name : field.DisplayName.split(' ').join('_')"
                       :FieldValue="Record[field.Name]" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" /></span><span v-if="field.Type === 'Lookup' || field.Type === 'Social Lookup'">
                         <span v-if="field.RelatedBuildID !== 'Users'">
                    <v-autocomplete  v-if="!field.Has_Selection_Dial"
                    :multiple="field.IsMultiple" :chips="field.IsMultiple"
                    class="smallinput" :solo="Solo" :items="field.Array" :item-text="'id'" :rules="field.IsMandatory? [$store.state.formrules.required] : []" 
                    :readonly="field.ReadOnly || field.RelatedReadOnly || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"
                    v-model="Record[field.Name]" return-object :label='field.Name' :filter="fieldFilter">
                      <template v-slot:no-data>
                          <i class="mx-4">no data</i>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingLookup()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewLookupDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template>
                          <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="data.item[field.LookupFieldName]"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                           <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title  v-html="data.item[field.LookupFieldName]"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.id"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="field.Has_Selection_Dial && Record[field.Name]"
                    :class="field.Has_Selection_Dial ? 'smallinput' : 'smallinput'"
                    :items="field.Array"
                    :item-text="field.LookupFieldName" v-model="Record[field.Name]" return-object :placeholder='field.Name'
                    :rules="field.IsMandatory? [$store.state.formrules.required] : []">
                     <template v-slot:item="data">
                        <template v-if="!data && field.Has_Selection_Dial">
                          <v-list-item>
                            <v-list-item-title class="caption">
                              There is no selection available
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                     <template v-else-if="field.Has_Selection_Dial">
                       <v-list-item-avatar tile size="100" v-if="field.LookupAvatarField">
                            <img v-if="data.item[field.LookupAvatarField.Name]" :src="data.item[field.LookupAvatarField.Name]" style="object-fit: cover;">
                            <img v-if="!data.item[field.LookupAvatarField.Name] && $vuetify.theme.dark"  src="@/assets/ImageHolder_dark.png" style="object-fit: cover;">
                             <img v-if="!data.item[field.LookupAvatarField.Name] && !$vuetify.theme.dark"  src="@/assets/ImageHolder.png" style="object-fit: cover;">
                          </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                         {{data.item[field.LookupFieldName]}}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="field.LookupSubtitleField">
                              <span v-if="field.LookupSubtitleField.IsCurrency">
                                {{CurrencyFormatter(data.item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                              </span>
                              <span v-else>
                                {{data.item[field.LookupSubtitleField.Name]}}
                              </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="caption" v-html="data.item[field.LookupCaptionField.Name]" v-if="field.LookupCaptionField.Name">
                            </v-list-item-subtitle>
                            <!-- {{CurrencyFormatter(PerformedCalculation,$store.state.DefaultCurrency.Currency)}} -->
                          </v-list-item-content>
                      </template>
                     </template>
                    </v-autocomplete>
                     <v-dialog v-model="SelectionLookupDialog"  :class="field.DialClass"  v-if="field.Has_Selection_Dial" fullscreen>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" class="caption" v-if="field.Has_Selection_Dial">{{field.Response ? 'Change' : 'Select'}} {{field.DisplayName}}</v-btn>
                          
                        </template>
                         <v-container style="width:100%;" v-if="field.Has_Selection_Dial" class="justify-center" :class="field.DialClass">
                        <v-card tile :flat="field.CardFlat" :class="field.CardClass" :dark="field.CardDark" style="padding: 0px;" :width="field.CardWidth" :height="field.CardHeight" v-if="field.DialType === 'Lookup Cards'">
                          <v-card-title v-if="field.DialHeader">{{field.DialHeader}}<v-spacer>
                            </v-spacer><v-icon dark style="position: absolute;right:15px;top: 15px;" large @click="SelectionLookupDialog = !SelectionLookupDialog" class="soloactionicon red">mdi-close</v-icon>
                          </v-card-title>
                           <v-card-text v-if="field.DialCaption" v-html="field.DialCaption"></v-card-text>
                          <v-card-text style="padding:25px;">
                         <v-row class="justify-center fill-height">
                          <div class="flex xs12 sm6 md4 lg3 xl2" v-for="item in field.Array" :key="item.itebObjKey">
                                <v-card>
                                  <v-card-title>
                                    {{item[field.LookupFieldName]}}
                                  </v-card-title>
                                  <v-card-subtitle v-if="field.LookupSubtitleField">
                                    <span v-if="field.LookupSubtitleField.IsCurrency">
                                    {{CurrencyFormatter(item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                                  </span>
                                  <span v-else>
                                    {{item[field.LookupSubtitleField.Name]}}
                                  </span>
                                  </v-card-subtitle>
                                  <v-card-text v-if="field.LookupAvatarField">
                                    <img width="100%" v-if="item[field.LookupAvatarField.Name] && item[field.LookupAvatarField.Name].ThumbURL" :src="item[field.LookupAvatarField.Name].ThumbURL" style="object-fit: cover;">
                                    <img width="100%" v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && $vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && $vuetify.theme.dark"  src="@/assets/ImageHolder_dark.png" style="object-fit: cover;">
                                    <img width="100%" v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && !$vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && !$vuetify.theme.dark"  src="@/assets/ImageHolder.png" style="object-fit: cover;">
                                  </v-card-text>
                                  <v-card-text v-if="field.LookupCaptionField" v-html="item[field.LookupCaptionField.Name]">
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-btn @click="SelectLookupResponse(field,Record,item)">Select</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </div>
                         </v-row>
                          </v-card-text>
                        </v-card>
                         </v-container>
                     </v-dialog>
                    </span><span v-if="field.RelatedBuildID === 'Users'">
                      <UserLookup :UsersLookupArray="field.Array"  :ModelProp="field.Name" :RecordObj="Record" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="field.DisplayName" :readonly="field.ReadOnly || Record[field.Name] && !UserCanGetLookup" :Rules="field.IsMandatory? [$store.state.formrules.required] : []"
                      />
                    </span></span><span v-if="field.Type === 'Date'">
                    <v-menu 
                    v-model="DateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="smallinput" :solo="Solo"
                        :label="field[FieldDisplayProp]"
                        prepend-icon="mdi-calendar-month"
                        readonly  :rules="field.IsMandatory? [$store.state.formrules.required] : []"
                        v-on="on"
                        v-model="Record[field.Name]"
                        ></v-text-field>
                        </template>
                    <v-date-picker  v-model="Record[field.Name]" :readonly="field.ReadOnly"
                            @input="DateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-menu v-if="field.HasTimeInput"
                        v-model="TimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        id="fieldTimeMenu"
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="field.TimeResponse"
                            :label="field.Name+' Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            :id="field.Name+'Time'"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="field.TimeResponse"
                            @input="TimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu></span><span v-if="field.Type === 'Single File Upload'"><span v-if="!field.FeaturedPhoto">
                    <input :id="field.Name"
                    @change="UploadFileSelect($event,field,Record)" style="visibility: hidden;display: none;"
                type="file">
                <label :for="field.Name"><span v-if="Record[field.Name] && Record[field.Name].UploadFile" class="texbtn btnhover elevation-1">
                  <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Change File
                  </span><span v-if="!Record[field.Name] || Record[field.Name] && !Record[field.Name].UploadFile" class="texbtn btnhover elevation-1">
                    <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Select File
                  </span>
                </label>
                <span v-if="Record[field.Name] && Record[field.Name].UploadFile">
                  {{Record[field.Name].UploadFile.name}}
                </span>
                <v-btn :href="field.localURL" v-if="field.UploadFile" @click="OpenTempDoc(field.UploadFile)" class="orange white--text" small>View {{field.FileName}}</v-btn>
                <v-btn v-if="field.UploadFile" @click="SaveDocumentField(field.UploadFile,field.Name)" class="green white--text"  small>Save</v-btn>
                </span><span v-if="field.FeaturedPhoto">
            <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> -->
           <v-avatar v-if="!NewImageType"
            color="grey"
            size="150" 
            tile>
            <input
            style="display: none"
            type="file"
            @change="onPhotoFileselected(field,$event)"
            ref="fileInputer">
            <v-btn v-if="field.localURL" @click="onPhotoUpload(field)" class="green white--text" icon small absolute bottom right><v-icon>mdi-content-save</v-icon></v-btn>
            <v-btn v-if="field.localURL" @click="closeUpload()" class="red white--text" icon small absolute bottom left><v-icon>mdi-close</v-icon></v-btn>
            <v-btn @click="$refs.fileInputer.click()" class="blue white--text" icon small absolute top right><v-icon>mdi-pencil</v-icon></v-btn>
           
           <img v-if="field.localURL" :src="field.localURL" height="150px">
            <img style="object-fit: cover;" v-if="Record[field.Name] && !field.localURL" :src="Record[field.Name].ThumbURL" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !field.localURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !field.localURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px">
        </v-avatar>
        <v-avatar v-if="NewImageType"
            color="grey"
            size="150" 
            tile>
            <input
            style="display: none"
            type="file" accept="image/*" 
            @change="onPhotoFileselected(field,$event)"
            ref="fileInputer">
            <v-btn v-if="localURL" @click="onPhotoUpload(field)" class="green white--text" icon small absolute bottom right><v-icon>mdi-content-save</v-icon></v-btn>
            <v-btn v-if="localURL" @click="CancelUpload()" class="red white--text" icon small absolute bottom left><v-icon>mdi-close</v-icon></v-btn>
            <v-btn @click="$refs.fileInputer.click()" class="blue white--text" icon small absolute top right><v-icon>mdi-pencil</v-icon></v-btn>
           
           <img v-if="localURL" :src="localURL" height="150px">
            <img style="object-fit: cover;" v-if="Record[field.Name] && !localURL" :src="Record[field.Name].ThumbURL ? Record[field.Name].ThumbURL : Record[field.Name]" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px">
        </v-avatar>
            </span></span>
            <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-list-item v-on="on" v-if="field.Type === 'Color Picker'">		
                    <v-list-item-avatar size="25" :style="Record[field.Name] ? 'background-color: '+Record[field.Name]+';' : 'background-color: #000000;'">	
                    </v-list-item-avatar>
                     {{field.DisplayName}}	
                  </v-list-item>
                  </template>
                   <v-color-picker v-model="Record[field.Name]"></v-color-picker>  	
                </v-menu>
           
            <!-- <v-color-picker v-if="field.Type === 'Color Picker'" v-model="Record[field.Name]"></v-color-picker> -->
            <span v-if="field.Type === 'Radio Group'">
                <v-select item-text="Name" dense multiple return-object :readonly="field.ReadOnly" :items="field.Options" v-model="Record[field.Name]" />
                </span><span v-if="field.Type === 'Custom Object'">
                  <!-- record by record you add properties to the record[field.Name] object. this...
                  There is howver no use case for custom object, if the object has a std set of fields, then you just need fields nothing more -->
                  
                     <v-menu 									
                        :close-on-content-click="false"                            									
                        transition="scale-transition"                            									
                        >									
                        <template v-slot:activator="{ on }">									
                            <v-btn v-on="on">Add Property<v-icon>mdi-plus-thick</v-icon></v-btn>									
                          </template>									
                          <v-card width="400px">							
                              <v-text-field v-model="PropertyLabel" label="Name"></v-text-field>
                              <v-select :items="['Single Line Text','Boolean','Number Field']" v-model="PropertyType" label="Type"></v-select> 
                                <v-card-actions>									
                                    <v-btn :disabled="!PropertyLabel || !PropertyType"
                                    @click="AddCustomObjectProp(Record,field)">Save</v-btn>								
                                </v-card-actions>									
                            </v-card>									
                        </v-menu>	
                    <v-list class="transparent" v-if="Record[field.Name]">
                        <v-list-item v-for="prop in Record[field.Name]" :key="prop.itemObjKey">
                     
                          <span v-if="prop.Type === 'Boolean'">
                              <v-switch  class="smallinput" :solo="Solo" v-model="prop.Response" :label="prop.Label"></v-switch></span>
                              <span v-if="prop.Type === 'Single Line Text'">
                              <v-text-field class="smallinput" :solo="Solo"  v-model="prop.Response" :label="prop.Label"></v-text-field></span>
                              <span v-if="prop.Type === 'Number Field'">
                              <v-text-field :rules="[$store.state.formrules.numberfield]" v-model.number="prop.Response" type="number" :label="prop.Label"></v-text-field></span>
                        </v-list-item>	
                  </v-list>
                  </span>
                  <span v-if="field.Type === 'Business Hours'">
                    <v-list>
                      <v-list-item>
                        Mon-Fri:
                      </v-list-item>
                      <v-list-item>
                       <v-menu 
                        v-model="WeekdayStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="Record[field.Name].WeekDays.Start"
                            :label="'Start Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekDays.Start"
                            @input="WeekdayStartTimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu><v-menu 
                        v-model="WeekdayEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="Record[field.Name].WeekDays.End"
                             :label="'End Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekDays.End"
                            @input="WeekdayEndTimeMenu = false"
                            type="month"
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
                      </v-list-item>                      
                      <v-list-item>
                        Sat-Sun:
                      </v-list-item>
                      <v-list-item>
                       <v-menu 
                        v-model="WeekendStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="Record[field.Name].WeekEnds.Start"
                            :label="'Start Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekEnds.Start"
                            @input="WeekendStartTimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu><v-menu 
                        v-model="WeekendEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="Record[field.Name].WeekEnds.End"
                             :label="'End Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekEnds.End"
                            @input="WeekendEndTimeMenu = false"
                            type="month"
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
                      </v-list-item>
                      <v-list-item>
                        Publ Hol:
                      </v-list-item>
                      <v-list-item>
                       <v-menu 
                        v-model="PublicHolidayStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="Record[field.Name].PublicHolidays.Start"
                            :label="'Start Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].PublicHolidays.Start"
                            @input="PublicHolidayStartTimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu><v-menu 
                        v-model="PublicHolidayEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput" :solo="Solo"
                            v-model="Record[field.Name].PublicHolidays.End"
                             :label="'End Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].PublicHolidays.End"
                            @input="PublicHolidayEndTimeMenu = false"
                            type="month"
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
                      </v-list-item>
                    </v-list>
                  </span>
                  <span v-if="field.Type === 'Google Maps Location'">
                    <v-text-field v-model="Record[field.Name]" :label="field.DisplayName"/>
                    <iframe v-if="Record[field.Name]" width="100%" :height="340" 
                  :src="Record[field.Name]"></iframe>
                  </span>
                <LookupComponent v-if="ViewExistingLookupDialog || AddNewParentLookupDialog" :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  </span>
</template>

<script>
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import LookupComponent from '@/components/Database/LookupComponent'
import FieldConfig from '@/components/Database/Fields/FieldConfig';
import UserLookup from '@/components/General/UserLookup'
import ExpiredLinkVue from '@/components/General/ExpiredLink';
export default {
    props: ['field','Record','DataTableView','PrimaryFieldName','SystemEntities','RelatedObj','CurrentEntity','IsThis','NewImageType','Solo'],
    components: {
        ContentEditableField,
        LookupComponent,
        FieldConfig,
        UserLookup
    },
    data(){
        return {
          SelectionLookupDialog: false,
          UploadFile: '',
          DateMenu: false,
          TimeMenu: false,
          WeekdayStartTimeMenu: false,
          WeekdayEndTimeMenu: false,
          WeekendStartTimeMenu: false,
          WeekendEndTimeMenu: false,
          PublicHolidayStartTimeMenu: false,
          PublicHolidayEndTimeMenu: false,
          PropertyLabel: '',
          PropertyType: '',
          PropertyValue: '',
          localURL: '',
          Refreshing: false,
            OptionSetDialog: false,									
            EditOptionSet: '',									
            OptionName: '',									
            Option: {},		
            AddNewParentLookupDialog: false,
            ViewExistingLookupDialog: false,
            FieldFilter: '',
            ParentTitle: '',
            ParentCollection:  '',
            NewParentPrimary: '',
            ParentLookup: '',
            NewParentAdditional: [],
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        }
    },
    watch: {
      
    },
    computed:{
        System(){
          return this.$store.state.SystemObj
        },
        RecordRadioOptions(){
          if(this.field.Type === 'Radio Group' && this.Record[this.field.Name]){
            return this.Record[this.field.Name].map(opt => {
              return opt.ID
            })
          }
        },
        userIsAdmin () {
        return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
        userBUID () {
        return this.$store.getters.userBUID
        },
        userBUChildren () {
        return this.$store.getters.userBUChildren
        },
        userBUParents () {
        return this.$store.getters.userBUParents
        },
        LookupRecord(){
            if(this.Record[this.field.Name] && this.field.Type === 'Lookup'){
                //console.log(this.Record[this.field.Name].id,this.field,this.Record[this.field.Name])
                let matchingrecord = this.field.Array.find(obj => obj.id === this.Record[this.field.Name].id)
                if(matchingrecord){
                    return matchingrecord
                }
            }
        },
        LookupRoute(){
            let routes = this.$router.getRoutes()
            let routeitem = routes.find(obj => obj.meta && obj.meta.RouteEventID === 1029 && obj.meta.EntityID === this.field.RelatedBuildID)
            return routeitem
        },
        LookupRouteName(){
            return this.LookupRoute ? this.LookupRoute.path.split('/').join('') : ''
        },
        LookupRouteSecurity(){
            return this.LookupRoute ? this.LookupRoute.meta.Security : ''
        },
        LookupRouteSecurityType(){
            return this.LookupRouteSecurity ? this.LookupRouteSecurity.SecurityType : ''
        },
        UserCanGetLookup(){
            let systemrecord = this.LookupRecord
            //console.log('LookupRecord',systemrecord,this.field,this.$store.state,this.LookupRouteName)
            if(systemrecord && this.LookupRouteSecurityType === 'Business Unit'){
                //console.log('LookupRouteName',this.LookupRouteName+'viewAll',this.LookupRouteName+'viewUnitdown',this.LookupRouteName+'viewUserUnit',this.LookupRouteName+'viewOwner')
                if(this.$store.state[this.LookupRouteName+'viewAll']){
                    return true
                }
                else if(this.$store.state[this.LookupRouteName+'viewUnitdown']){
                    //LookupRecord
                    return this.userBUChildren.includes(systemrecord.Business_Unitid)
                }
                else if(this.$store.state[this.LookupRouteName+'viewUserUnit']){
                    //LookupRecord
                    return systemrecord.Business_Unitid === this.userBUID
                }
                else if(this.$store.state[this.LookupRouteName+'viewOwner']){
                    //LookupRecord
                    return systemrecord.Ownerid === this.userLoggedIn.id
                }
            }
            else if(systemrecord && this.LookupRouteSecurityType === 'Custom Roles'){
                return this.$store.state[this.LookupRouteName+'CanView']
            }
        },
        FieldDisplayProp(){
            return !this.DataTableView ? 'DisplayName' : 'text'
        },
        
    },
    created(){
      //console.log(this.field,this.Record[this.field.Name])
      if(this.field && this.field.Type === 'Calculated Field'){
        this.field.ReadOnly = true
        this.PerformedCalculation()
      }
      else if(this.field && this.field.Type === 'Option Set' && this.field.IsDynamic){
        if(!this.Record[this.field.Name]){
          this.Record[this.field.Name] = this.field
        }
        else{
          this.Record[this.field.Name].id = this.field.id
          this.Record[this.field.Name].DisplayName = this.field.DisplayName
          this.Record[this.field.Name].IsDynamic = this.field.IsDynamic
          this.Record[this.field.Name].Type = this.field.Type
        }
        
      }
      
    },
    methods:{
      SelectLookupResponse(field,Record,item){
        let lookuppbj = {
          [field.LookupFieldName]: item[field.LookupFieldName],
          id: item.id
        }
        Record[field.Name] = lookuppbj
        this.SelectionLookupDialog = false
        // Record[field.Name] = lookuppbj
        // Record[field.Name+'id'] = lookupobj.id
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      fieldFilter (item, queryText, itemText) {
          const Primary = item[this.field.LookupFieldName].toLowerCase()
          const searchText = queryText.toLowerCase()
          return Primary.indexOf(searchText) > -1
    },
      AddCustomObjectProp(Record,field){
        let value = ''
        if(!Record[field.Name]){
          Record[field.Name] = {}
        }
        if(this.PropertyType === 'Boolean'){
          value = false
        }
        else if(this.PropertyType === 'Number Field'){
          value = 0
        }
        let prop = this.PropertyLabel.split(' ').join('_')
        Record[field.Name][prop] = {
          Label: this.PropertyLabel,
          Type: this.PropertyType,
          Response: value
        }
        // {
        //   Name: 'Group Directories',
        //   Config_Options: {
        //     Has_Monetization: {
        //       Prop: 'Has_Monetization',
        //       Type: 'Boolean',
        //       Value: false
        //     }
        //   }
        // }
      },
      removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
      ActivateAddNewLookupDialog(){
        this.CancelSelection()
        this.LookupArray = this.field.Array
        //console.log(this.field,this.LookupArray)
        //lookupentity headers
        let entity = this.SystemEntities.find(obj => obj.id === this.field.RelatedBuildID)
        this.LookupArrayHeaders = entity.AllFields.filter(field => {
            return field.IsHeader}).map(field => {
                field.text = field.DisplayName
                field.value = field.Name
                if(field.Type === 'Lookup'){
                    field.value = field.value+'.'+field.LookupFieldName
                }
                else if(field.Type === 'Option Set'){
                    field.value = field.value+'.Name'
                }
                field.class = 'overline'
                return field
            })
        this.ParentTitle = this.field.Name
        this.ParentCollection = entity.DisplayName.split(' ').join('').toLowerCase()
        this.NewParentAdditional = []
        this.ParentLookup = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Propid: this.field.Name+'id', Value: '',Array: this.field.Name+'Array',LookupFieldName: this.field.LookupFieldName}
        this.NewParentPrimary = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Value: '',LookupFieldName: this.field.LookupFieldName}
        this.AddNewParentLookupDialog = true
      },
      NoPathFilterRoutePass(route){
        let routernonroutes = ['404','PasswordReset','UserInvite','ExpiredLink','404','landingpagesingle','PermissionError','login']
        //SiteEventSocialSingle and sarrticles etc excluded from route.meta.type Social
        let mainnonroutes = ['FeaturedMember','BusinessMember','SiteMemberSingle','SitePostSingle','noticesingle','ClassifiedSingle','ClassifiedEnquiry',
        'GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll','GroupSingle','GroupEdit','GroupPageEditor','GroupNewPoll','GroupNewArticle','GroupNewMeetup','GroupNewBlog',
        'GroupNewPoll','GroupWebFormEditor','GroupWebFormBuilder','GroupPageBuilder',
        'LinkedInAuth','TeamMemberActivities','DashboardBuilder','PipelineManagement','PageEditor','newbannerad','newlandingpage','noticenew','MyActivitiesbyType','MyTeamMemberActivities',
        'TableViewer','EntryViewer','WebFormEditor','SurveyBuilder','DirectoryBuilder','PDFExportFunctionBuilder','WikiCollectionBuilder','DataImport','SystemTrainingDocument','FeaturedMemberEdit',
        'CustomDashboard'
        ]
        if(!routernonroutes.includes(route.name) && !mainnonroutes.includes(route.name)){
         return !route.meta || route.meta && route.meta.RouteEventID !== 1028 && route.meta.RouteEventID !== 1027 && route.meta.TopicCategory !== 'Social Page' &&
         route.meta.type !== 'Social' && route.meta.type !== 'Classified Listing Viewer' && route.meta.type !== 'Wiki Editor' && route.meta.type !== 'Wiki Viewer'
         && !route.meta.DataSingleBuilder && !route.meta.ClassifiedListingBuilder 
        }
        else{
          return false
        }        
      },
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
      toggleMultipleRadio(RecordRadioOptions,Record,field,_id) {
            //console.log(_id)
            if (RecordRadioOptions.includes(_id)) {
            Record[field.Name] = Record[field.Name].filter((i) => {
            return i.ID !== _id;
            });
        } else {
            Record[field.Name] = Record[field.Name].concat(_id);
        }
            
        },
      PerformedCalculation(){
            let result = 0
            let formulas = JSON.parse(JSON.stringify(this.field.FieldFormulas))
            formulas = formulas.map(formula => {                
                if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                    if(this.Record[formula.fieldName.Name]){
                        formula.integer = this.Record[formula.fieldName.Name]
                        if(formula.input === 'percentage field' && formula.fieldName){
                            formula.integer = this.Record[formula.fieldName.Name]
                            formula.integer = formula.integer*(formula.integer/100)
                        }
                        else if(formula.input === 'field' && formula.fieldName){
                            formula.integer = this.Record[formula.fieldName.Name]
                        } 
                    }
                }
                return formula
            })
            formulas.map((formula,i) => {
                if(i === 0){
                    result = formula.integer
                }
                else if(formulas[i-1].operation === '-'){
                    result = result-formula.integer
                }
                else if(formulas[i-1].operation === '+'){
                    result = result+formula.integer
                }
                else if(formulas[i-1].operation === '*'){
                    result = result*formula.integer
                }
                else if(formulas[i-1].operation === '/'){
                    result = result/formula.integer
                }
                else if(formulas[i-1].operation === '='){
                    result = result
                }
                else if(formulas[i-1].operation === 'Average'){
                  result = (result+formula.integer)/(i-+2)
                }
            })
            this.Record[this.field.Name] = result
            //console.log(this.Record[this.field.Name])
        },
      OpenTempDoc(file){
        //console.log(file)
        let tempurl = URL.createObjectURL(file)
        //console.log(tempurl)
        window.open(tempurl, '_blank')
      },
      SaveDocumentField(file,fieldname){
        this.$emit('SaveDocumentField',file,fieldname)
      },
      UploadFileSelect(event,field,Record){
        this.$emit('UploadFileSelect',event.target.files[0],field,Record)
        this.Refresh()
      },
      onPhotoUpload(field){
        if(this.NewImageType){
          let payload = {
                filename: this.UploadFile.name,
                file: this.UploadFile,
                field: field
            }
            //console.log('payload',payload)
            this.$emit('onPhotoUpload',payload)
        }
        else{
         this.$emit('onPhotoUpload',field) 
        }
        
      },
      CancelUpload(){
        if(this.NewImageType){
        this.localURL = ''
        this.UploadFile = ''
        }
      },
      onPhotoFileselected(field,event){
        if(this.NewImageType){
        let file = event.target.files[0]
        this.UploadFile = file
        this.localURL = URL.createObjectURL(file)
        }
        else{
          this.$emit('onPhotoFileselected',field,event.target.files[0],event.target.files,this.Record)
        this.Refresh() 
        }
       
      },
      Refresh(){
        //alert('yay')
        this.Refreshing = true
        setTimeout(() => {
            this.Refreshing = false
        }, 100);
      },
        ActivateOptionSetDialog(recordfield,field,ContentBlockObject){
            //console.log(recordfield,field)	
            if(recordfield){
              this.EditOptionSet = recordfield  
              this.EditOptionSet.Name = field.Name
            }	
            else{
                this.EditOptionSet = {Name: field.Name }
            }							
         									
         if(!this.EditOptionSet.Options){									
           this.EditOptionSet.Options = []									
         }									
         this.OptionSetDialog = true
         //console.log(this.EditOptionSet)									
       },									
       AddOptionSetOption(OptionName){									
      if(typeof this.EditOptionSet.lastassignedinteger === 'undefined'){									
        this.EditOptionSet.lastassignedinteger = 1000000									
        									
      }      									
       let integer = this.EditOptionSet.lastassignedinteger-1+2									
      									
      let NewOption = {Name: OptionName, ID: integer}									
      this.EditOptionSet.Options.push(NewOption)									
      this.OptionName = ''									
      this.EditOptionSet.lastassignedinteger = integer									
    },									
    OptionNameSelect(option){									
      this.Option = option									
    },									
    CancelOptionSetDialog(){									
		this.OptionSetDialog = false
        this.EditOptionSet = ''
        this.Option = {}							
    },									
    SaveOptionSets(fieldconfig){	
        if(!fieldconfig){
        let fieldobj = this.EditedTab.FormFields.find(obj => obj.Name === this.EditOptionSet.Name)									
        let fieldindex = this.EditedTab.FormFields.indexOf(fieldobj)									
        this.EditedTab.FormFields[fieldindex].Options = this.EditOptionSet.Options									
        this.OptionSetDialog = false									
        this.NewContentDialog = false									
        this.NewContentDialog = true
        }	
        else{
            this.OptionSetDialog = false
            this.Record[this.EditOptionSet.Name] = this.EditOptionSet
            //console.log(this.Record)
        }							
        									
    },	
        RouteOptions(field){
            let routes = this.$router.getRoutes()
            return routes.filter(route => {
                if(field.PathFilter){
                    if(field.PathFilter === 'None'){
                    return this.NoPathFilterRoutePass(route)
                    }
                    else if(field.PathFilter === 'Non Guest Routes'){
                    return route.name === 'MyDashboard' || route.name === 'MyProfile' || route.name === 'Home' || route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Guest Routes'){
                      //we had this.System.Guests_can_Social && route.meta && route.meta.RouteEventID === 1027 but then typically "store" page not allowed
                    return route.name === 'MyAccount' || route.meta && route.meta.RouteEventID === 1027 || this.System.Guests_can_Social && route.meta &&  route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Public Pages'){
                        return route.meta && route.meta.RouteEventID === 1028
                    }
                    else if(field.PathFilter === 'Internal Pages'){
                      return route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                }                
                else{
                    return route
                }
            })
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(this.field,FieldObject,this.Record)
    //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
    //   alert('should have passed updateemit')
    },
        CancelSelection(){
        this.ViewExistingLookupDialog = false
        },
        UpdateEditableField(field){
            //console.log('calling update editable field from within field edit component')
            if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
                field.FieldBreakdown.map(breakdown => {
                    this.$emit('UpdateEditableField',breakdown.Name,this.Record[breakdown.Name],this.Record)
                })
            }
            else if(field.Type === 'Lookup'){
                let lookupobj = {
                    [field.LookupFieldName]: this.Record[field.Name][field.LookupFieldName],
                    id: this.Record[field.Name].id
                }
                this.$emit('UpdateEditableField',field.Name,lookupobj,this.Record)
                let lookupobjid = this.Record[field.Name].id
                this.$emit('UpdateEditableField',field.Name+'id',lookupobjid,this.Record)
            }
            else{
                this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
                
            }
            
        },
        CancelAddParentDialog(){
          this.AddNewParentLookupDialog = false
        },
        PrepareLinkExistingLookup(){
        //this.CancelAddParentDialog()
        //this.PrepareDataParentPrimary_Contact()
        this.ViewExistingLookupDialog = true
        this.FieldFilter = this.field.DisplayName
        this.LookupArray = this.field.Array
        //console.log(this.field,this.LookupArray)
        //lookupentity headers
        let entity = this.SystemEntities.find(obj => obj.id === this.field.RelatedBuildID)
        this.LookupArrayHeaders = entity.AllFields.filter(field => {
            return field.IsHeader}).map(field => {
                field.text = field.DisplayName
                field.value = field.Name
                if(field.Type === 'Lookup'){
                    field.value = field.value+'.'+field.LookupFieldName
                }
                else if(field.Type === 'Option Set'){
                    field.value = field.value+'.Name'
                }
                field.class = 'overline'
                return field
            })
            
        //     [
        //     { text: 'Business Unit', value: 'Business_Unit.Name',class: "overline"},
        //     { text: 'Company', value: 'Company.Company_Name',class: "overline"},
        //     { text: 'Full Name', value: 'Full_Name',class: "overline"},
        //     { text: 'Owner', value: 'Owner.Full_Name',class: "overline"},
        //     { text: 'Status', value: 'Status.Name',class: "overline"},
        // ]
        this.ParentTitle = this.field.Name
        this.ParentCollection = entity.DisplayName.split(' ').join('').toLowerCase()
        this.NewParentAdditional = []
        this.ParentLookup = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Propid: this.field.Name+'id', Value: '',Array: this.field.Name+'Array',LookupFieldName: this.field.LookupFieldName}
        this.NewParentPrimary = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Value: '',LookupFieldName: this.field.LookupFieldName}
        //         let CompanyObj = {Type: 'Lookup', Name: 'Company',Prop: this.PrimaryFieldName,Array: this.CompanyArray, Propid: this.PrimaryFieldName+'id', LookupFieldName: this.PrimaryFieldName, Value: ''}
        // this.NewParentAdditional.push(CompanyObj)
},
    }
}
</script>

<style>


</style>