<template>
  <div>
    <v-card-title>
        <v-spacer>
        </v-spacer>
        <v-btn outlined @click="UpdateOptionSet()" color="success">Save</v-btn>
    </v-card-title>
    <v-dialog v-model="NewOptionDialog"          			
        max-width="400px"transition="dialog-bottom-transition">
        <CleanOptionSetConfig :AddTitle="AddTitle" :AddHint="'You will be able to edit this later as well'" :CategoryField="ListPrimaryField"
        :NewOptionLevel="NewOptionLevel" @SaveOption="SaveOption" :ParentOption="ParentOption" @CancelNewOptionDialog="CancelNewOptionDialog"
        v-if="NewOptionDialog" :PluginOptions="PluginOptions"
        />
    </v-dialog>
    <v-list class="mx-10">    
         <v-btn x-small @click="ActivateNewOptionDialog(1)">Add Option</v-btn>      
        <v-list-group
            v-for="item in ListPrimaryField.Options"
            :key="item.ID"
            v-model="item.active"
            no-action
        >
            <template v-slot:activator>
                 <v-list-item-avatar :color="item.Color">
                    <v-icon :dark="item.Color">{{item.Icon}}</v-icon>
                 </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="item.Name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.Description"></v-list-item-subtitle>
            </v-list-item-content>
            </template>
            <v-btn x-small @click="ActivateNewOptionDialog(2,item)">Add Option</v-btn>
            <v-btn x-small disabled @click="AppendFilters(1,item)">Append Filters</v-btn>
            <v-list-group
            v-for="child in item.Options"
            :key="child.ID"
           sub-group
            no-action
        >
            <template v-slot:activator>
                 <v-list-item-avatar :color="child.Color">
                    <v-icon :dark="child.Color">{{child.Icon}}</v-icon>
                 </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="child.Name"></v-list-item-title>
                <v-list-item-subtitle v-text="child.Description"></v-list-item-subtitle>
            </v-list-item-content>
            </template>
             <v-btn x-small @click="ActivateNewOptionDialog(3,child)">Add Option</v-btn>
             
            <v-btn x-small disabled @click="AppendFilters(2,child,item)">Append Filters</v-btn>

            <v-list-item
            v-for="subchild in child.Options"
            :key="subchild.ID"
            >
                 
                 <v-list-item-avatar :color="subchild.Color">
                    <v-icon :dark="subchild.Color">{{subchild.Icon}}</v-icon>
                 </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="subchild.Name"></v-list-item-title>
                <v-list-item-subtitle v-text="subchild.Description"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
            <v-btn x-small disabled @click="AppendFilters(3,subchild,child,item)">Append Filters</v-btn>
             </v-list-item-content>
            </v-list-item>
        </v-list-group>
        </v-list-group>
        </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import CleanOptionSetConfig from '@/components/Database/Fields/Input/CleanOptionSetConfig'
export default {
    props: ['System','SystemEntities'],
    components: {CleanOptionSetConfig},
    data() {
        return {
            ParentOption: '',
            NewOptionLevel: 1,
            NewOptionDialog: false,
            ParentFilter: '',
            ActiveTreeNode: [],
            //NewOptionName: '',
            ActiveTreeList: [],

        }
    },	
    computed:{    
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },    
      PluginOptions(){
        let options = ['Campaign_Builder','Featured_Members','Web_App_Builder','Site_Articles','Site_Blogs','Site_Events','Site_Meetups','Vacancies']
        return options.filter(opt => {
            return this.PluginDataBase[opt] && this.PluginDataBase[opt].Active
        })
      },
        AddTitle(){
            return this.NewOptionLevel === 1 ? 'Add an Option' : 'Add an Option to "'+this.ParentOption.DisplayName+'"'
        },
        ListPrimaryField(){
            return this.AdvertDirectory && this.AdvertDirectory.CategoryField ? 
            this.AdvertDirectory.CategoryField : ''
        },
        AdvertDirectory(){
            return this.$store.state.AdvertDirectory
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ActivateNewOptionDialog(lvl,parent){
            this.NewOptionLevel = lvl
            this.NewOptionDialog = true
            this.ParentOption = parent
        },
        CancelNewOptionDialog(){
            this.NewOptionDialog = false
            //this.NewOptionName = ''
        },
        UpdateOptionSet(){
            db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('Directories').doc('Adverts').update({
                CategoryField: this.ListPrimaryField
            })
        },
        SaveOption(NewOption,lvl,parent){
            this.ListPrimaryField.lastassignedinteger = this.ListPrimaryField.lastassignedinteger-1+2
            let newoption = {
                Name: NewOption.Name,
                DisplayName: NewOption.Name,
                AdditionalOptions: [],
                ID: this.ListPrimaryField.lastassignedinteger
            }
            let props = ['Description','Icon','Color','Pluginid','Templateid','TemplateOptions']
            props.map(prp => {
                if(typeof NewOption[prp] !== 'undefined'){
                    newoption[prp] = NewOption[prp]
                }
            })
            let level = this.ListPrimaryField.Levels[lvl-1]
            newoption.Level = level
            newoption.LevelFieldName = level.FieldName
            if(lvl < this.ListPrimaryField.LastLevel){
                newoption.Options = []
                newoption.HasDeepHierarchy
            }
            if(lvl === 1){
               this.ListPrimaryField.Options.push(newoption) 
            }
            else if(lvl !== 1){
                parent.Options.push(newoption)
            }
            //this.NewOptionName = ''
            //thus the below shows only issue is knowing which level we wokr from, easy
            //HasDeepHierarchy is lvl not exceeding
            //Level (fullobj)
            //LevelFieldName (txt)
            //Options (IFHasDeepHierarchy)
            
        }
    }
}
</script>

<style>

</style>



