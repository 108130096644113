<template>
<v-main>
  <v-card :style="PageBGGradient" :dark="$vuetify.theme.dark" flat height="100%" >
       
    <v-dialog max-width="600" v-model="MappingDialog">                               
        <v-card>
            <v-card-title class="overline recordtoolbar white--text">
               Mapping Dialog
               
            </v-card-title>
            <v-card-text>
                <v-select :items="RelatedParents" label="Selected Parent" item-text="DisplayName" return-object v-model="SelectedParentField" />
                <v-btn @click="AddMappingtoParentField()">Add Mapping</v-btn>
                <v-list v-if="SelectedParentField.MappedValues">
                    <v-list-item v-for="mapping in SelectedParentField.MappedValues" :key="mapping.itemObjKey">                        
                        <v-list-item-content>
                            <v-select @change="ToggleMappingDialog()" :items="NonFormFields" label="Field to Obtain" item-text="DisplayName" return-object v-model="mapping.OutputField">                                
                                 <template v-slot:prepend-item>
                                <v-list-item class="recordtoolbar white--text overline">
                                  {{WebForm.DisplayName}}
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px;">
                                            {{item.DisplayName}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle  style="font-size: 12px;">
                                            {{item.Type}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                            </template>
                            </v-select> 
                        </v-list-item-content>
                        <v-list-item-content>
                            <v-select @change="ToggleMappingDialog()" :items="SelectedEntityFields(mapping)" label="Field from Parent" item-text="DisplayName" return-object v-model="mapping.InputField">
                                 <template v-slot:prepend-item>                                  
                                <v-list-item class="recordtoolbar white--text overline">
                                    {{SelectedParentField.RelatedLocation}}
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px;">
                                            {{item.DisplayName}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle  style="font-size: 12px;">
                                            {{item.Type}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                            </template>
                            </v-select>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon color="red" @click="DeleteMappingVerify(mapping)">mdi-delete-forever</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>     
            </v-card-text>
            <v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelMappingDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="SaveMapping()">
                   
                    {{'Add Mapped Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>   
      <v-dialog max-width="600" v-model="NewFieldDialog">                               
        <v-card>
            <v-card-title class="overline">
                {{NewField.Type}}
               
            </v-card-title>
            <v-card-text v-html="NewField.Description">
            </v-card-text>
            <!-- <v-card-text> -->
                <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="WebForm" :WebForm="true"
                :SystemEntities="ComputedSystemEntities" :field="NewField" :NewField="true" :System="System"
                />
            <v-card-text>
                <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="ComputedSystemEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true"
                :PrimaryFieldName="''" :SingleFormEditing="false"
                :Record="SampleRecord" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="[NewField]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelNewFieldDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddWebFormEntityField(NewField)">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <v-tabs centered color="links">
        <v-tab>Form Builder
        </v-tab>
        <v-tab-item> -->
            <v-card >
                <v-card-title class="primary links--text display-1 mx-10" style="padding:50px;">
                 Web Form Builder <v-btn v-if="RAAdmin" :to="'/SurveyBuilder-New'">Surveys?</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="SaveWebForm()">Save</v-btn>
                </v-card-title>
                <v-card-text v-if="WebForm">
                    <v-layout row class="my-6 justify-center">
                    
                        <v-card height="100%" max-width="800" min-width="400">
                        <v-list dense>
                                <v-list-item>
                                <v-select :readonly="RouteID" :items="AvailableWebFormTypes" v-model="WebForm.WebFormType" label="Form Intent" />
                                </v-list-item>
                                <v-list-item v-if="DataCollectionForm">
                                <v-switch v-model="WebForm.HideUserInfoFields" label="Hide User Info Fields" />
                                </v-list-item>
                                 <v-list-item v-if="WebFormTypesrequiringLookup.includes(WebForm.WebFormType)">
                                <v-autocomplete @change="EvaluateWebFormType(WebForm.WebFormType,WebForm.ParentEntity)" :readonly="RouteID" :items="PotentialWebFormRelatedEntities"
                                 v-model="WebForm.ParentEntity" label="Related Entity">
                                 <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-title class="caption">
                                    You do not have any Entities to link this to
                                    </v-list-item-title>
                                </v-list-item>
                                </template>
                                <template v-slot:selection="data">
                                    <v-chip small
                                    >
                                    <v-avatar size="50" left>
                                       <v-icon>{{GetTableIcon(data.item)}}
                                       </v-icon>
                                    </v-avatar>
                                    {{GetTableDisplayName(data.item)}}
                                    </v-chip>
                                </template>
                                <template v-slot:item="data">
                                    <v-chip small                                    >
                                    <v-avatar size="50" left>
                                       <v-icon>{{GetTableIcon(data.item)}}
                                       </v-icon>
                                    </v-avatar>
                                    {{GetTableDisplayName(data.item)}}
                                    </v-chip>
                                </template>
                                </v-autocomplete>
                                </v-list-item>
                                <v-list-item>
                                <v-text-field :readonly="RouteID" v-model="WebForm.DisplayName" label="Display Name" />
                                </v-list-item>
                                <v-list-item>
                                <v-text-field :readonly="RouteID" v-model="WebForm.SingleName" label="Single Item Name" />
                                </v-list-item>
                                <v-list-item>
                                <v-text-field :readonly="RouteID" v-model="WebForm.Primary_Field_Name" label="Primary Field Name" />
                                </v-list-item>
                                <v-list-item>
                                <v-text-field v-model="WebForm.Table_Icon" label="Table Icon" />
                                </v-list-item>
                                <v-list-item class="justify-center">
                                    <ContentEditableField
                                :FieldObject="WebForm" :FieldName="'Description'"
                                :FieldValue="WebForm.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                                </v-list-item>
                                <v-list-item>
                                <v-chip-group
                                v-if="RouteID"
                                        active-class="primary--text"
                                        column
                                    >
                                    <v-chip
                                        @click="ActivateNewFieldDialog(field,true)"
                                        small  v-for="field in FieldTypes"
                                        :key="field.itemObjKey"
                                        >
                                        {{field.Type}}
                                        </v-chip>                           
                                    </v-chip-group>
                                </v-list-item>
                        </v-list>
                        <v-expand-transition>
                            <v-card flat tile
                                v-if="reveal"
                                class="transition-fast-in-fast-out transparent"
                               
                            >
                             <v-card-actions class="pt-0">
                                <v-btn
                                    text
                                    color="teal accent-4"
                                    @click="reveal = !reveal"
                                >
                                    Close
                                </v-btn>
                                <v-btn @click="ActivateMappingDialog()">
                                    Mapping
                                </v-btn>
                                </v-card-actions>
                                <v-card-text class="pb-0">
                                <WebFormTab  v-if="RenderedWebFormTab" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="''" :rowindex="''"									
                                :tab="RenderedWebFormTab" :i="i"									
                                />
                                </v-card-text>
                               
                            </v-card>	
                            </v-expand-transition>
                            <v-card-actions>
                            <v-btn
                                text
                                color="teal accent-4"
                                @click="reveal = !reveal"
                            >
                                {{reveal? 'Close Form' : 'Show Form'}}
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                   
                  
                    </v-layout>
                </v-card-text>
            </v-card>
        <!-- </v-tab-item>
        <v-tab>Processes
        </v-tab>
        <v-tab-item>
        </v-tab-item>
        <v-tab>Automations
        </v-tab>
        <v-tab-item>
        </v-tab-item>
    </v-tabs> -->
      
  </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTab';
import FieldConfig from '@/components/Database/Fields/FieldConfig';	
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'

export default {
    props: ['SystemEntities','System','AppisDarkMode','RADB','GroupEntities'],
    components:{
        WebFormTab,
        SingleTabFieldsSection,
        FieldConfig,
        ContentEditableField
    },
    data(){
        return {
            SelectedEntityField: '',
            SelectedParentField: '',
            MappingDialog: false,
            WebFormTypesrequiringLookup: [
                'Classified Listing Enquiry',
                'Data Collection Form'
            ],
            reveal: false,
            SampleRecord: {},
            FieldTypes: [],
            PageBGGradient: 'rgba(255,255,255,0.01); margin-top:100px;',
            WebForm: {
                Description: `<p>Some Web Form</p>`,
                ShowComponents: true,
                Table_Icon: 'mdi-form-select',
                DisplayName: '',
                SingleName: '',
                Name: '',
                Primary_Field_Name: 'Subject',
               
                Entity_Type: {
                    Name: 'Web Form',
                    ID: 1000012
                },
                Security_Structure: {
                    ID: 1000002,
                    Name: 'Business Unit'
                },
                AllgetRoles: [],
                UnitDowngetRoles: [],
                UserUnitgetRoles: [],
                OwnergetRoles: [],
                AlllistRoles: [],
                UnitDownlistRoles: [],
                UserUnitlistRoles: [],
                OwnerlistRoles: [],
                AllcreateRoles: [],
                UnitDowncreateRoles: [],
                UserUnitcreateRoles: [],
                OwnercreateRoles: [],
                AllupdateRoles: [],
                UnitDownupdateRoles: [],
                UserUnitupdateRoles: [],
                OwnerupdateRoles: [],
                AlldeleteRoles: [],
                UnitDowndeleteRoles: [],
                UserUnitdeleteRoles: [],
                OwnerdeleteRoles: [],
                datasetsecuritystructure: 'Business Unit',
                WebFormTab: {
        Name: 'Web Form',
        FormName: '',
        FromDatabase:   true,
        DarkForm: false,
        BGColor: {
              hexa: 'dbcard'},
        HeaderColor: {hex: '#000000'},
        EntityForm: true,
        RelatedEntity: {id: ''},
        FormSubmitted:false,
        SingleName:'',
        FormFields: [
            {
                        Name: 'User',
                        DisplayName: 'User',
                        Type: 'Lookup',
                        LookupFieldName: 'Full_Name',
                        UsedonWebForm: true,
                        IsHeader: true,
                        HeaderIndex: 0,
                        StrictlyLoggedIn: true,
                        "MappedField": {
                        "Primary": false,
                        "Name": "User",
                        LoggedInUser: true,
                        },
                        WebFormIndex: 0,LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users'
                    },
                    {
                        Name: 'Name',
                        DisplayName: 'Name',
                        Type: 'Single Line Text',
                        UsedonWebForm: true,
                        MappedField: {                        
                        Primary: false,
                        Name: 'Name',
                        IsHeader: true,
                        HeaderIndex: 1,
                        LoggedInUserValue: true,                        
                        },
                        WebFormIndex: 1
                    },
                     {
                        Name: 'Surname',
                        DisplayName: 'Surname',
                        Type: 'Single Line Text',
                        UsedonWebForm: true,
                        MappedField: {                        
                        Primary: false,
                        Name: 'Surname',
                        IsHeader: true,
                        HeaderIndex: 2,
                        LoggedInUserValue: true,                        
                        },
                        WebFormIndex: 2
                    },
                    {
                        Name: 'Email',
                        DisplayName: 'Email',
                        Type: 'Common Field',
                        CommonFieldType: 'Email',
                        UsedonWebForm: true,
                        MappedField: {                        
                        Primary: false,
                        Name: 'Email',
                        IsHeader: true,
                        HeaderIndex: 3,
                        LoggedInUserValue: true,                        
                        },
                        WebFormIndex: 3
                    },
                    {
                        Name: 'Phone',
                        DisplayName: 'Phone',
                        Type: 'Common Field',
                        CommonFieldType: 'Telephone Number',
                        UsedonWebForm: true,
                        MappedField: {                        
                        Primary: false,
                        Name: 'Mobile_Number',
                        
                        IsHeader: true,
                        HeaderIndex: 4,
                        LoggedInUserValue: true,                        
                        },
                        WebFormIndex: 4
                    },
        ],
        StatusDefault: {
  "Options": [
    {
      "Name": "Open",
      "Level": {
        "children": [],
        "id": 2,
        "FieldName": "Status_Reason",
        "name": "Status Reason"
      },
      "LevelFieldName": "Status_Reason",
      "ID": 1000003
    }
  ],
  "ID": 1000001,
  "HasDeepHierarchy": true,
  "Level": {
    "children": [
      {
        "name": "Status Reason",
        "children": [],
        "FieldName": "Status_Reason",
        "id": 2
      }
    ],
    "FieldName": "Status",
    "id": 1,
    "name": "Status"
  },
  "LevelFieldName": "Status",
  "Name": "Active"
},
        Status_ReasonDefault: {
  "LevelFieldName": "Status_Reason",
  "ID": 1000003,
  "Name": "Open",
  "Level": {
    "name": "Status Reason",
    "FieldName": "Status_Reason",
    "children": [],
    "id": 2
  }
},
      },
            },
            NewFieldDialog: false,
            AddingNewField: false,
            NewField: '',
        }       
    },
    computed:{
        RAAdmin(){
            return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
        },
        RAUserObj(){
            return this.$store.state.RAUserObj
        },
        DataCollectionForm(){
            return this.WebForm.WebFormType === 'Data Collection Form'
        },
        DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
        AppBuilderView(){
        return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView
        },
        BuilderView(){
        return this.$store.state.BuilderView
        },
        WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
        },
        NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        IsGroupView(){
        return this.$store.state.IsGroupView
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ComputedSystemEntities(){
            return this.GroupDB ? this.GroupEntities.filter(entity => entity.groupid === this.$route.query.GroupID) : this.SystemEntities
        },
        RelatedParents(){
            return this.WebForm && this.WebForm.WebFormTab && this.WebForm.WebFormTab.FormFields ? this.WebForm.WebFormTab.FormFields.filter(field => {
                return field.Type === 'Lookup' && field.UsedonWebForm
            }) : []
        },
        RelatedParentFields(){
            let fields = []
            if(this.DataCollectionForm && this.WebForm.ParentEntity){
                let ent = this.SystemEntities.find(obj => obj.id === this.WebForm.ParentEntity.id)
                if(ent){
                    fields = ent.AllFields
                }
            } 
            return fields
        },
        NonFormFields(){
            return this.WebForm.AllFields.filter(field => {
                return !field.UsedonWebForm
            })
            .map(field => {
                  let fieldobj = Object.assign({},field)
                  delete field.Array
                  delete field.MappedValues
                  return fieldobj
                })
        }, 
        RAUser(){
            return this.$store.state.RAUserObj
        },
        RASystemOpsDB(){
            if(this.NeedsRASync){
                return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities')
            }
        },
        RAOperationalDB(){
            if(this.NeedsRASync){
                return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.RouteID)
            }
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
            return this.$store.state.NeedsRASync && !this.$route.query || 
            this.$store.state.NeedsRASync && this.$route.query && !this.$route.query.GroupID
        },
        GroupDB(){
            return this.$route.query && this.$route.query.GroupID ? db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.query.GroupID).collection('entities') : ''
        },
        PotentialWebFormRelatedEntities(){
            switch (this.WebForm.WebFormType) {
                case 'Classified Listing Enquiry': return this.ClassifiedListingEntities.map(entity => {
                    let entityobj = {
                        id: entity.id,
                        SingleName: entity.SingleName,
                        DisplayName: entity.DisplayName,
                        Table_Icon: entity.Table_Icon,
                        Entity_Type: entity.Entity_Type,
                        Primary_Field_Name: 'Combined_Title',
                        RecordPrimaryField: entity.RecordPrimaryField
                    }
                    return entityobj
                    })        
                case 'Data Collection Form': return this.NonWebFormEntities.map(entity => {
                    let entityobj = {
                        id: entity.id,
                        SingleName: entity.SingleName,
                        DisplayName: entity.DisplayName,
                        Table_Icon: entity.Table_Icon,
                        Entity_Type: entity.Entity_Type,
                        Primary_Field_Name: entity.Primary_Field_Name,
                        RecordPrimaryField: entity.RecordPrimaryField
                    }
                    return entityobj
                    }).filter(ent => {
                        //return ent
                        return ent.id !== 'Activities' && ent.id !== 'Users' && ent.id !== 'Business_Units' && ent.id !== 'System_Tickets'
                    })
                default: return []
                }  
        },
        AvailableWebFormTypes(){
            let arr = ['Basic Enquiry','Data Collection Form']
            if(this.ClassifiedListingEntities.length > 0){
                arr.push('Classified Listing Enquiry')
            }
            return arr
        },
        ClassifiedListingEntities(){
            return this.NonWebFormEntities.filter(entity => {
                return entity.Entity_Type.Name === 'Classified Listing'
            })
        },
        NonWebFormEntities(){
            return this.ComputedSystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name !== 'Web Form'
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        RenderedWebFormTab(){
            let tabobj = this.WebForm.WebFormTab
            tabobj.FormName = this.WebForm.DisplayName
            tabobj.RelatedEntity.id = this.WebForm.DisplayName.split(' ').join('_')
            tabobj.SingleName = this.WebForm.SingleName
            if(this.WebForm.WebFormType){
                tabobj.WebFormType = this.WebForm.WebFormType
            }
            if(this.WebForm.ParentEntity){
                tabobj.ParentEntity = this.WebForm.ParentEntity
            }
            if(this.WebForm.AllFields){
              tabobj.FormFields = this.WebForm.AllFields.filter(field => {
                    return field.UsedonWebForm
                }).sort((a, b) => {			
                        // console.log('sorting again')			
                        // console.log(a.Value)			
                        var key1 = b.WebFormIndex			
                        var key2 = a.WebFormIndex			

                        if (key1 > key2) {			
                            return -1;			
                        } else if (key1 == key2) {			
                            return 0;			
                        } else {			
                            return 1;			
                        }			
                })
            }
            
            tabobj.FormFields.map((field,fieldindex) => {
                field.WebFormIndex = fieldindex
                if(field.MappedField){
                let mappedfield = field.MappedField.Name
                if(!field.MappedField.Primary && field.MappedField.LoggedInUser){
                    //alert('one')
                    field.Response = {
                        Full_Name: this.userLoggedIn.Full_Name,
                        Name: this.userLoggedIn.Name,
                        Surname: this.userLoggedIn.Surname,
                        id: this.userLoggedIn.id
                    } 
                    
                    field.Array = [field.Response]
                }
                else if(field.MappedField.LoggedInUserValue){
                    field.Response = this.userLoggedIn[mappedfield]
                    if(field.Type === 'Lookup'){
                        field.Array = [field.Response]
                    }
                }
                }
                //console.log(field)
                return field
            })
           
            //FormName
            //RelatedEntity.id
            //SingleName
            return tabobj
        },
        RouteID(){
            return this.$route.params.id
        }
    },
    watch: {
        RouteID (value){
            console.log(value)
            if(value !== 'New'){
                this.CheckRouting()
            }
        },
        GroupEntities: {
            handler: function(oldvalue, newvalue) {
              //console.log('GroupEntities watcher','oldvalue',oldvalue,'newvalue', newvalue,this.RouteID,oldvalue !== newvalue)
              if(this.RouteID){
                  let entity = this.GroupEntities.find(obj => obj.id === this.RouteID && obj.groupid === this.$route.query.GroupID)
                  //console.log('GroupEntities entity',entity)
                  if(entity){
                      this.GetWebForm()
                  }       
              }
            },
            deep: true
        },
    },
    created(){
        if(!this.AppBuilderView){
            this.$store.commit('setBuilderView',{Name: 'Website Builder'})
        }
        this.CheckRouting()
        let fieldtypes = ['Single Line Text','Common Field','Multiple Lines Text','Boolean','Radio Group','Option Set','Date','AutoNumber Field','Number Field','Single File Upload','Calculated Field','Map Location','Shared Doc Link','Lookup','Route Path']
      this.FieldTypes =fieldtypes.map(type => {
          let typeobj = {
              Type: type,
              Name: '',
              DisplayName: '',              
          }
          if(type === 'Lookup'){

          }
          else if(type === 'Option Set'){
              typeobj.Options = []
              typeobj.lastassignedinteger = 1000000
          }
          return typeobj
      })
        //alert('webformbuilder')
    },
    methods:{
        GetTableIcon(ParentEntity){
            //console.log(ParentEntityid)
            return ParentEntity.Table_Icon
        },
        GetTableDisplayName(ParentEntity){
            return ParentEntity.DisplayName
        },
        DeleteMappingVerify(mapping){
            confirm('Do you want to delete this mapping?') && this.ProcessDeleteMapping()
        },
        ProcessDeleteMapping(mapping){
            let index = this.SelectedParentField.MappedValues.indexOf(mapping)
            this.SelectedParentField.MappedValues.splice(index,1)
        },
        SelectedEntityFields(mapping){
            if(this.SelectedParentField && mapping.OutputField){
                let entity = this.ComputedSystemEntities.find(obj => obj.id === this.SelectedParentField.RelatedBuildID)
                return entity.AllFields
                .filter(field => {
                    return field.Type === mapping.OutputField.Type
                })
                .filter(field => {
                    if(field.Type === 'Lookup'){
                        return field.RelatedBuildID === mapping.OutputField.RelatedBuildID
                    }
                    else if(field.Type === 'Common Field'){
                        return field.CommonFieldType === mapping.OutputField.CommonFieldType
                    }
                    else{
                        return field
                    }
                })
                .map(field => {
                  let fieldobj = Object.assign({},field)
                  delete field.Array
                  delete field.MappedValues
                  return fieldobj
                })
            }
            else{
                return []
            }
        },
        AddMappingtoParentField(){
            if(!this.SelectedParentField.MappedValues){
                this.SelectedParentField.MappedValues = []
            }
            let newmapping = {

            }
            this.SelectedParentField.MappedValues.push(newmapping)
            this.ToggleMappingDialog()
        },
        SaveMapping(){
            this.MappingDialog = false
        },
        CancelMappingDialog(){
            this.MappingDialog = false
            this.SelectedParentField = ''
        },
        ActivateMappingDialog(){
            this.MappingDialog = true
            
        },
        ToggleMappingDialog(){
            this.MappingDialog = false
            setTimeout(() => {
                this.MappingDialog = true
            }, 50);
        },
        CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
        CheckRouting(){
            
            this.GetWebForm() 
        },
        EvaluateWebFormType(WebFormType,ParentEntity){
            console.log(this.ComputedSystemEntities,ParentEntity)
            let newfield = {}
            if(WebFormType === 'Classified Listing Enquiry'){                
                newfield.Type = 'Lookup'
                newfield.Name = ParentEntity.SingleName
                newfield.DisplayName = ParentEntity.SingleName
                newfield.RelatedLocation = ParentEntity.SingleName
                newfield.RelatedRecord = ParentEntity.DisplayName
                newfield.RelatedBuildID = ParentEntity.id
                newfield.LookupFieldName = ParentEntity.RecordPrimaryField.Name
                newfield.MappedField = {
                        Name: ParentEntity.Primary_Field_Name,
                        Primary: true
                    }    
                    this.WebForm.Description = `<b>Overview</b>
            <p>This is Web Form entity, parented to `+ParentEntity.DisplayName+` Listings.</p>
            <b>The benefits and features include:</b>
            <ul>
                <li>As a Web Form it allows capturing Enquiries on `+ParentEntity.DisplayName+` Listings.
                </li>
                <li>As a Data Table Entity it allows the full scope of features a data table offers, which include:
                    <ul>
                    <li>Adding and Managing of Processes to maintain incoming enquriies in a pipeline
                    </li>
                    <li>Fully tailorable Data Security Structures
                    </li>
                    <li>Automations, to convert from enquiry to data records (e.g. utilize Enquiry fields to convert one Enquiry to an "Account", a "Contact" linked to the "Account", and even a "Sales Order")
                    </li>
                    <li>Slotting in Tabs and sections int eh Entity Builder, e.g Document Tables, Galleries, and more 
                    </li>
                    </ul> 
                </li>
                <li>As a "Classified Listing Enquiry" Form, this Web Form has by default been slotted with a Entity Process/Pipeline called "Default Classifieds Process".
                </li>
            </ul><br>`
            this.AddWebFormEntityField(newfield)             
            }
            else if(this.DataCollectionForm){ 
                //..remember the very purpose of this form type is to allow a front end form that collects submissions to the table
                //there is no "parententity" use case where it needs to relate to an existing record, so there is no lookup field. If we want that Bob, then we add a new type
                //this type is going to allow us to capture data records. 
                //now here is the deal. We still need very basic fields and stuff added here very certainly. everything. the Basic Form Fields need to be added to the Web Form. 
                //So the deal is, FormFields already contain this
                //so either the RealtedEntity.id = Selected Parent Entity OR the web form component will refer to web form type and then web form ParentEntity (the "id") to obtain colleciotn/ which is it?
                //honestly the issue is there is no telling how far this goes right now. But on the one end 
                //you know what? it's still a web form entity. Even though it never gets a table with any submissions. 
                //...update the web form submission logic that should be the right thing then multiple forms apply all of that propogation is perfectly fit.
            }
            
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
        UpdateRelated(field,RelatedBuildID){
            let entity = this.ComputedSystemEntities.find(obj => obj.id === RelatedBuildID)
            if(entity){
                field.RelatedLocation = entity.SingleName
                field.RelatedRecord = entity.DisplayName
                field.LookupFieldName = entity.RecordPrimaryField.Name
            }
            if(entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'){
                //alert('We will map this from the given listing')
                field.MappedField = {
                    // [field.LookupFieldName]: field.LookupFieldName,
                    Name: 'Combined_Title',
                    Primary: true
                }
            }
        },
        AddWebFormEntityField(field){
            //console.log(this.NewTableOption)
            if(!field.Name){
              field.Name = field.DisplayName.split(' ').join('_')  
            }   
            else{
               field.Name = field.Name.split(' ').join('_') 
            }         
            field.text = field.DisplayName
            field.value = field.Name
            field.IsHeader = true
            field.UsedonWebForm = true
            field.WebFormIndex = this.WebForm.WebFormTab.FormFields.length-1
            //console.log(field,this.WebForm.WebFormTab,this.RenderedWebFormTab)
            this.ProcessAddField(field)
            this.CancelNewFieldDialog()
            this.WebForm.WebFormTab.FormFields.push(field)
           
            
        },
        ProcessAddField(field){
            let opsdb = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.RouteID)
            //remember Data Colleciton Form needs to sync the Web Form as well as the parent entity, no fields on web form entityt hat does not exist on parent entity either.
             if(this.GroupDB){
                    opsdb = this.GroupDB.doc(this.RouteID)
                }
                    //console.log('this.DataCollectionForm',this.DataCollectionForm)
            if(this.NeedsRASync){
                if(this.DataCollectionForm){
                    //okay the problem is it seems like we are adding a field to the parenentity onoly, and NOT to the web form...?
                    this.RASystemOpsDB.doc(this.WebForm.ParentEntity.id).collection('AllFields').doc(field.Name).set(field).then(parentrafield => {
                        this.RAOperationalDB.collection('AllFields').doc(field.Name).set(field).then(webformrafield => {
                            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.WebForm.ParentEntity.id).collection('AllFields').doc(field.Name).set(field).then(parentfield => {
                                opsdb.collection('AllFields').doc(field.Name).set(field)
                            })
                        })
                    })
                }
                else{
                this.RAOperationalDB.collection('AllFields').doc(field.Name).set(field).then(rafield => {
                   opsdb.collection('AllFields').doc(field.Name).set(field) 
                })  
                }              
            }
            else{
                if(this.DataCollectionForm){
                db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.WebForm.ParentEntity.id).collection('AllFields').doc(field.Name).set(field).then(parentfield => {
                    opsdb.collection('AllFields').doc(field.Name).set(field)
                })
                }
                else{
                    opsdb.collection('AllFields').doc(field.Name).set(field)
                }
            }
        },
        ActivateNewFieldDialog(field,AddingNewField,tableoption){
            this.NewField = Object.assign({},field)
            this.NewFieldDialog = true
            this.AddingNewField = AddingNewField
            //console.log(this.EntityCollectionRef,field)
            // if(tableoption){
            //     this.NewTableOption = tableoption 
            // }
        },
        CancelNewFieldDialog(){
            this.NewFieldDialog = false,
            this.NewField = ''
             this.AddingNewField = false
            //this.NewTableOption = ''
        },
        GetWebForm(){
            if(this.RouteID && !this.GroupDB){  
            this.WebForm = this.ComputedSystemEntities.find(obj => obj.id === this.RouteID)   
            }
            else if(this.RouteID && this.GroupDB){
             this.WebForm = this.GroupEntities.find(obj => obj.id === this.RouteID)   
            }         
            //console.log(this.WebForm,this.GroupEntities)
        },
        AddDefaultFields(docid,DefaultFields){
            let opsdb = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(docid)
            console.log(this.GroupDB)
            if(this.GroupDB){
                opsdb = this.GroupDB.doc(docid)
            }
            console.log(docid,DefaultFields)
            let fieldslength = DefaultFields.length
            DefaultFields.map((field,fieldindex) => {
                console.log(opsdb.collection('AllFields').doc(field.Name))
                opsdb.collection('AllFields').doc(field.Name).set(field)
                // .then(newfielddoc => {
                    if(fieldindex-1+2 === fieldslength){
                        this.UpdateFormFields(opsdb,docid,true,false)
                    }
                // })
            })
// {
//     "LookupFieldName": "Name",
//     "RelatedLocation": "Vehicle",
//     "IsMandatory": false,
//     "DisplayName": "Vehicle",
//     "MappedField": {
//       "Name": "Name",
//       "Primary": true
//     },
//     "Type": "Lookup",
//     "Created": true,
//     "Primary": false,
//     "UsedonCompForm": false,
//     "RelatedRecord": "Vehicles",
//     "OnWebForm": true,
//     "Name": "Vehicle",
//     "RelatedBuildID": "Vehicles",
//     "UsedonForm": true,
//     "IsHeader": false,
//     "SystemRequired": true
//   },
        },
        AddDefaultProcesses(configdb,docid,forRA){
            //alert('process')
            //ACTUALLY UNLESS USER HAS DEFINED A NEW PROCESS, THEN YES THIS APPLIES
            //BUT ALSO, USER CAN DEACTIVE THE PROCESS SO IT WOULD NOT SHOW, UNLESS USER CHECKS ALL ENTITY PROCESSES FROM PIPELINEMAAGEMENT COMPONENT
            let NewProcess = {
                Name: 'Default Enquiries',DisplayName: 'Default Enquiries', Number: 1000001, Automations: [], Type:'Process', IsHeader: true,
                ProcessStages: [
                    {Name: 'Assigned', Number: 1000001, Automations: [], Type:'Stage', IsHeader: true, Steps: []},
                    {Name: 'In Progress', Number: 1000002, Automations: [], Type:'Stage', IsHeader: true, Steps: []},
                    {Name: 'Completed', Number: 1000003, Automations: [], Type:'Stage', IsHeader: true, Steps: []}
                ],
                ProcessStageInteger: 1000003,
                Status: 'Active'
            }
            //I do believe automations would be good, but a lot of thought needes to go in, on web form types and so forth before default automations could be assigned.
            let ProcessesInteger = 1000001
            configdb.update({
                ProcessesInteger: ProcessesInteger
            })
            console.log(NewProcess)
            configdb.collection('EntityProcesses').doc(NewProcess.Number.toString()).set(NewProcess).then(setdoc => {
                if(!forRA && !this.GroupDB){
                  this.$router.push('/WebFormBuilder/'+docid)  
                }
                else if(!forRA && this.GroupDB){
                    this.$router.push('/Group/Edit/'+this.$route.query.GroupID)  
                }
                
            })            
        },
        UpdateFormFields(configdb,docid,newform,forRA){
            let fieldslength = this.WebForm.WebFormTab.FormFields.length
            if(fieldslength === 0){
               this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID) 
            }
                    this.WebForm.WebFormTab.FormFields.map((field,fieldindex) => {
                        let fieldobj = Object.assign({},field)                        
                        delete fieldobj.Response
                        delete fieldobj.Array
                        delete fieldobj.CanEdit
                        delete fieldobj.CanGet
                        delete fieldobj.CanDelete
                        delete fieldobj.CanView
                        delete fieldobj.CanCreate
                        //console.log('configdb',configdb)
                        configdb.collection('AllFields').doc(fieldobj.Name).set(fieldobj)
                        // .then(newfielddoc => {
                            if(fieldindex-1+2 === fieldslength && newform){
                                if(this.DataCollectionForm){
                                    //we don't want to assume that there is even a process involved here, it's a custom entityl, any entity, may create a clash
                                    if(!forRA && !this.GroupDB){
                                    this.$router.push('/WebFormBuilder/'+docid)  
                                    }
                                    else if(!forRA && this.GroupDB){
                                        this.$router.push('/Group/Edit/'+this.$route.query.GroupID)  
                                    }
                                }
                                else{
                                this.AddDefaultProcesses(configdb,docid,forRA)
                                }
                            }
                        // })
                    })
        },
        UpdateWebFormAllowed(configdb){
            let props = ['HideUserInfoFields','Description','WebFormType','ParentEntity']
            let updobj = {}
            props.map(prp => {
               if(typeof this.WebForm[prp] !== 'undefined'){
                updobj[prp] = this.WebForm[prp]
               }
            })
            configdb.update(updobj)            
        },
        SaveWebForm(){
            let docid = ''
            //console.log(this.WebForm)
            
            if(this.RouteID){
                docid = this.RouteID
                let opsdb = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(docid) 
                if(this.GroupDB){
                    opsdb = this.GroupDB.doc(docid)
                }
                if(this.NeedsRASync){
                    this.UpdateFormFields(this.RASystemOpsDB.doc(docid),docid,false,true)
                    this.UpdateWebFormAllowed(this.RASystemOpsDB.doc(docid))
                    this.UpdateFormFields(opsdb,docid)
                    this.UpdateWebFormAllowed(opsdb)
                }
                else {
                    this.UpdateFormFields(opsdb,docid)
                    this.UpdateWebFormAllowed(opsdb)
                }           
                
            }
            else{
                docid = this.WebForm.DisplayName.split(' ').join('_')  
                let opsdb = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(docid) 
                if(this.GroupDB){
                    opsdb = this.GroupDB.doc(docid)
                }
                let primfieldid = this.WebForm.Primary_Field_Name.split(' ').join('_').split('(').join('').split(')').join('').split('/').join('')
                this.WebForm.Primary_Field_Name = primfieldid
                let DefaultFields = [
                    {Name: primfieldid, DisplayName: this.WebForm.Primary_Field_Name, Type: 'Single Line Text',Primary: true, Created: true,IsSearch: true,IsMandatory: true,IsHeader: true,HeaderIndex: 0},
                    {Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'State_Modified_Date', DisplayName: 'State Modified Date', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    this.DefaultStatusField
                ]    
               
                delete this.WebForm.ShowComponents
                this.WebForm.WebFormTab = this.RenderedWebFormTab 
                this.WebForm.WebFormTab.FormFields = this.WebForm.WebFormTab.FormFields.map((field,fieldindex) => {
                        let fieldobj = Object.assign({},field)                        
                        delete fieldobj.Response
                        delete fieldobj.Array
                        delete fieldobj.CanEdit
                        delete fieldobj.CanGet
                        delete fieldobj.CanDelete
                        delete fieldobj.CanView
                        delete fieldobj.CanCreate
                return fieldobj
            })
                this.WebForm.Name = this.WebForm.DisplayName
                this.WebForm.datasethasrecordowner = true                
                this.WebForm.datasethasprocessstages = true
                this.WebForm.datasethasprocessmap = true
                this.WebForm.Records_Have_Owner = true
                this.WebForm.RecordPrimaryField = DefaultFields.find(obj => obj.Primary === true)
                this.WebForm.SingleBuildTabs = []
                // this.WebForm.Has_Entity_Processes = true
                // this.WebForm.Entity_Processes_Map = true
                this.WebForm.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                this.WebForm.Created_Byid = this.userLoggedIn.id
                this.WebForm.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                this.WebForm.Modified_Byid = this.userLoggedIn.id     
                //for this.DataCollectionForm THESE FIELDS shuold all EXIST ON entity ALREADY                     
                if(this.NeedsRASync){
                    let RaWebForm = Object.assign({},this.WebForm) 
                    RaWebForm.Created_By = {id: this.RAUser.id, Name: this.RAUser.Name, Surname: this.RAUser.Surname, Full_Name: this.RAUser.Full_Name}
                    RaWebForm.Created_Byid = this.RAUser.id
                    RaWebForm.Modified_By = {id: this.RAUser.id, Name: this.RAUser.Name, Surname: this.RAUser.Surname, Full_Name: this.RAUser.Full_Name}
                    RaWebForm.Modified_Byid = this.RAUser.id 
                    this.CheckObjectforUndefined(1,RaWebForm,'(RaWebForm)')
                 this.RASystemOpsDB.doc(docid).set(RaWebForm).then(radoc => {
                     this.UpdateFormFields(this.RASystemOpsDB.doc(docid),docid,true,true)
                     console.log(DefaultFields)
                     if(!this.DataCollectionForm){
                      DefaultFields.map(field => {
                        let fieldobj = Object.assign({},field)
                        console.log(fieldobj)
                        delete fieldobj.Response
                        delete fieldobj.Array
                        delete fieldobj.CanEdit
                        delete fieldobj.CanGet
                        delete fieldobj.CanDelete
                        delete fieldobj.CanView
                        delete fieldobj.CanCreate
                        this.RASystemOpsDB.doc(docid).collection('AllFields').doc(fieldobj.Name.split(' ').join('_')).set(fieldobj)
                        })   
                     }
                                        
                    this.WebForm.WebFormTab.FormFields.map(field => {
                        let fieldobj = Object.assign({},field)
                        delete fieldobj.Response
                        delete fieldobj.Array
                        delete fieldobj.CanEdit
                        delete fieldobj.CanGet
                        delete fieldobj.CanDelete
                        delete fieldobj.CanView
                        delete fieldobj.CanCreate
                        this.RASystemOpsDB.doc(docid).collection('AllFields').doc(fieldobj.Name.split(' ').join('_')).set(fieldobj)
                        })     
                        this.CheckObjectforUndefined(1,this.WebForm,'(this.WebForm)')               
                    opsdb.set(this.WebForm).then(setdoc => {
                        console.log(DefaultFields)
                        if(!this.DataCollectionForm){
                        this.AddDefaultFields(docid,DefaultFields)
                        }
                        else{
                            //yes no default fields as Data Colleciton Form, simply finish form fields
                            this.UpdateFormFields(opsdb,docid,true,false)
                        }                     
                    })
                    })
                }
                else{
                    //this.CheckObjectforUndefined(1,this.WebForm,'(this.WebForm)')
                    opsdb.set(this.WebForm).then(setdoc => {
                        console.log(DefaultFields,setdoc,this)
                        if(!this.DataCollectionForm){
                        this.AddDefaultFields(docid,DefaultFields)
                        }
                        else{
                            //yes no default fields as Data Colleciton Form, simply finish form fields
                           this.UpdateFormFields(opsdb,docid,true,false)
                        }                    
                    })
                }                
            }
            
        }
    }
}
</script>

<style>

</style>