<template>
  <v-card-text>
    
    <v-list v-if="!ActiveOrder">
         <v-card-title class="mediumoverline">
          Orders for your Store<v-spacer>
            </v-spacer>
         </v-card-title>
         <v-divider>
            </v-divider>
            <v-card-title class="largeoverline" style="font-weight: 300;">
                New
            </v-card-title>
            <v-divider>
            </v-divider>
             <EasyOrderList :System="System" :SystemEntities="SystemEntities"
            :Orders="RelevantOrders(prog,true)" @ActivateOrder="ActivateOrder"
            />
         <div v-for="prog in ProgressTypes" :key="prog.itemObjKey">
            <v-divider>
            </v-divider>
            <v-card-title class="mediumoverline">
                {{prog.Name}}
            </v-card-title>
            <v-divider>
            </v-divider>
            <EasyOrderList :System="System" :SystemEntities="SystemEntities"
            :Orders="RelevantOrders(prog)" @ActivateOrder="ActivateOrder"
            />
         </div>
        
    </v-list>
    <v-list v-if="ActiveOrder">
        <v-card-title>
             <v-menu offset-y :close-on-content-click="false" top left min-width="400px" max-height="700px">
                <template v-slot:activator="{ on }">
                    <v-chip v-on="on" outlined>
                        {{ActiveOrder.Progress && ActiveOrder.Progress.Name ? ActiveOrder.Progress.Name : 'Assign Status'}}
                        </v-chip>
                </template>
                <v-card outlined>
                    <v-card-text>
                    <v-select :items="ProgressTypes" return-object item-text="Name" v-model="ActiveOrder.Progress" @change="UpdateStatus(ActiveOrder)" />
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-spacer>
            </v-spacer>
            <v-btn outlined @click="ActiveOrder = ''">View All</v-btn>
        </v-card-title>
        <v-divider>
        </v-divider>
        <v-card-title class="mediumoverline">
            Order Information
            <v-spacer>
            </v-spacer>
            {{ActiveOrder.id}}
        </v-card-title>
        <v-divider>
        </v-divider>
        <v-card-title>
          <v-list-item class="elevation-6" style="margin-bottom: 20px;border-radius: 10px;">
            
            <v-list-item-avatar tile style="font-size: 0.75em;">
            <DateRender :Value="ActiveOrder.Created_On"
              :Display="'Card'" :Color="'grey'"
              /> 
            </v-list-item-avatar> 
            <v-list-item-content>
            <v-list-item-title>
            {{ActiveOrder.Name}} {{ActiveOrder.Surname}}
            </v-list-item-title>
            <v-list-item-subtitle>
                <div v-html="ActiveOrder.Email"/>
            </v-list-item-subtitle>
            </v-list-item-content>  
            <v-list-item-actions>
                {{CurrencyFormatter(ActiveOrder.Total,$store.state.DefaultCurrency.Currency)}}
            </v-list-item-actions>                 
        </v-list-item>
        </v-card-title>
        <v-divider>
        </v-divider>
        <v-card-title class="mediumoverline">
            Notes
        </v-card-title>
        <v-divider>
        </v-divider>
        <v-card-text>
        <NotesComponent :NotesArray="NotesArray" :CurrentEntity="CurrentEntity" :SingleRecord="true"
        :System="System" :SystemEntities="SystemEntities" :Data="[ActiveOrder]"
        />
        </v-card-text>
        <v-divider>
        </v-divider>
        <v-card-title class="mediumoverline">
            Cart Items
        </v-card-title>
        <v-divider>
        </v-divider>
        <v-card-text>
            <!-- {{ActiveOrder.Cart_Items}} -->
            <v-row class="jutify-center">
            <v-col cols="6" xl="2" lg="2" md="4" sm="6" v-for="prod in ActiveOrder.Cart_Items">
            <EasyProductDialog :key="prod.itemObjKey" :Directories="Directories" :Product="prod"
                  :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
                  :EditingProduct="false" :PriceField="'Unit_Price'" :PreventAdd="true"
                  :ProductCollectionRef="ProductCollectionRef" :ShippingGroupsRef="ShippingGroupsRef" :CategoriesRef="CategoriesRef"
              />
            </v-col>
            </v-row>
        </v-card-text>
    </v-list>
  </v-card-text>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DateRender from '@/components/Database/Fields/ReadOnly/DateRender';	
import EasyProductDialog from '@/components/SuitePlugins/EasyProducts/EasyProductDialog';
import EasyOrderList from '@/components/SuitePlugins/EasyProducts/EasyOrderList';
import NotesComponent from '@/components/General/NotesComponent';
export default {
    props: ['System','SystemEntities'],
    components: {DateRender,EasyProductDialog,EasyOrderList,NotesComponent},
    data() {
        return {
            NotesCall: '',
            NotesArray: [],
            StoreOrders: [],
            ActiveOrder: '',
            ProgressTypes: [
                {ID: 1000001, Name: 'Reviewing'},
                {ID: 1000002, Name: 'Processing'},
                {ID: 1000003, Name: 'Completed'}
            ]
        }
    },	
    computed:{
        CurrentEntity(){
            return {id: 'Store_Orders',Primary_Field_Name: 'Subject'}
        },
        ComputedStoreOrders(){
            return this.StoreOrders.map(ord => {
                let ordobj = Object.assign({},ord)
                let total = ordobj.Cart_Items.map(item => {
                    return item.Unit_Price*item.Qty
                }).reduce((a, b) => a + b, 0)                
            ordobj.Total = Number(total.toFixed(2))
                return ordobj
            })
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        OrdersRef(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Store_Orders').collection('Records')
        }
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetStoreOrders()
    },
    methods:{
        UpdateStatus(ActiveOrder){
            this.OrdersRef.doc(ActiveOrder.id).update({
                Progress: ActiveOrder.Progress
            })
        },
        RelevantOrders(prog,newonly){
            return this.ComputedStoreOrders.filter(obj => {
                return !newonly && obj.Progress && obj.Progress.ID === prog.ID || newonly && !obj.Progress
            })
        },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      ActivateOrder(prod){
        this.ActiveOrder = prod
        this.GetNotesArray()
      },
      GetNotesArray(){
        //console.log('GetNotesArray')
        this.NotesArray = []
        if(typeof this.NotesCall === 'function'){
            this.NotesCall();
        }
        this.NotesCall = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('notes').where('topicid','==',this.CurrentEntity.id).where('Records','array-contains',this.ActiveOrder.id).onSnapshot(res => {
            const changes = res.docChanges();    
            //console.log('res.docs.length',res.docs.length)             
            changes.forEach(change => {                 
              if (change.type === 'added') {
                let itemobj = {                 
                  ...change.doc.data(),                 
                  id: change.doc.id ,         
                }
                this.NotesArray.push(itemobj)
                //console.log('this.NotesArray',this.NotesArray)
              }
              if (change.type === 'modified') {
                let itemobj = {                 
                  ...change.doc.data(),                 
                  id: change.doc.id ,         
                }
                let oncheck = this.NotesArray.find(obj => obj.id === itemobj.id)
                if(oncheck){
                    let index = this.NotesArray.indexOf(oncheck)
                    this.NotesArray.splice(index,1,itemobj)
                }
              }
              if (change.type === 'removed') {
                let itemobj = {                 
                  ...change.doc.data(),                 
                  id: change.doc.id ,         
                }
                let oncheck = this.NotesArray.find(obj => obj.id === itemobj.id)
                if(oncheck){
                    let index = this.NotesArray.indexOf(oncheck)
                    this.NotesArray.splice(index,1)
                }
              }
            })              
        })
      },
        GetStoreOrders(){
            this.OrdersRef.onSnapshot(res => {
          const changes = res.docChanges();                 
            changes.forEach(change => {                 
              if (change.type === 'added') {
                let itemobj = {                 
                  ...change.doc.data(),                 
                  id: change.doc.id ,
                  Cart_Items: []                
                }
                let subcolquery = this.OrdersRef.doc(itemobj.id).collection('Cart_Items')
                subcolquery.onSnapshot(subcolres => {
                const subcolchanges = subcolres.docChanges();
                let subcollength = subcolres.docs.length
                subcolchanges.forEach((subcolchange,i) => {
                  if (subcolchange.type === 'added') {
                    itemobj.Cart_Items.push({
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id})
                    if(itemobj.Cart_Items.length === subcollength){
                      let oncheck = this.StoreOrders.find(obj => obj.id === itemobj.id)
                      if(!oncheck){
                        this.StoreOrders.push(itemobj)
                      }
                      else{
                        let ind = this.StoreOrders.indexOf(oncheck)
                        this.StoreOrders.splice(ind,1,itemobj)
                      }                      
                    }
                  }
                  if (subcolchange.type === 'modified') {
                    //will still work on this, if rmeoved works
                    let subcolobj = {
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id
                    }
                    //console.log('removed subcolrec',subcolobj.id)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      if(oncheck.Cart_Items){
                      let subcoloncheck = oncheck.Cart_Items.find(obj => obj.id === subcolobj.id)
                      if(subcoloncheck){
                        let subcolobjindex = oncheck.Cart_Items.indexOf(subcoloncheck)
                        oncheck.Cart_Items.splice(subcolobjindex,1,subcolobj)
                      }
                      }
                    }
                  }
                  if (subcolchange.type === 'removed') {
                    let subcolobj = {
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id
                    }
                    //console.log('removed subcolrec',subcolobj.id)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      if(oncheck[scol.SubcolName]){
                      let subcoloncheck = oncheck[scol.SubcolName].find(obj => obj.id === subcolobj.id)
                      if(subcoloncheck){
                        let subcolobjindex = oncheck[scol.SubcolName].indexOf(subcoloncheck)
                        oncheck[scol.SubcolName].splice(subcolobjindex,1)
                      }
                      }
                    }
                  }
                
                })
                if(subcollength === 0){
                  
                this.StoreOrders.push(itemobj)
                }
              }) 
              }
              if (change.type === 'modified') {
                let itemobj = {                 
                  ...change.doc.data(),                 
                  id: change.doc.id                 
                } 
                let match = this.StoreOrders.find(obj => obj.id === itemobj.id)
                if(match){
                  let index = this.StoreOrders.indexOf(match)
                  itemobj.Cart_Items = match.Cart_Items
                  this.StoreOrders.splice(index,1,itemobj)
                }
              }
              if (change.type === 'removed') {
                let itemobj = {                 
                  ...change.doc.data(),                 
                  id: change.doc.id                 
                } 
                let match = this.StoreOrders.find(obj => obj.id === itemobj.id)
                if(match){
                  let index = this.StoreOrders.indexOf(match)
                  this.StoreOrders.splice(index,1)
                }
              }
          })
        })
        },
    }
}
</script>

<style>

</style>



