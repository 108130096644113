<template>
  <div>
    <!-- <v-layout style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" column id="chatbtn" v-if="userIsAdmin">
   <v-btn small fab @click="ConfirmFixtoNewSuiteTemplatesStructure()" dark color="red">
      <v-icon>mdi-apps</v-icon>
    </v-btn>
    <v-btn small fab @click="ConfirmFixtoNewDBBStructure()" dark color="blue">
      <v-icon>mdi-database</v-icon>
    </v-btn>
     <v-btn small fab @click="ConfirmFixDirectoryStructure()" dark color="orange">
      <v-icon>mdi-view-list</v-icon>
    </v-btn>
    <v-btn small fab @click="ConfirmFixSocialNetworkStructure()" dark color="purple">
      <v-icon>mdi-star-face</v-icon>
    </v-btn>
    <v-btn small fab @click="ConfirmFixWebsiteStructure()" dark color="green">
      <v-icon>mdi-search-web</v-icon>
    </v-btn>
     <v-btn small fab @click="ConfirmFixLibraryStructure()" dark color="lime">
      <v-icon>mdi-library</v-icon>
    </v-btn>
  </v-layout> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','StoredDefaultDirectories','SitePages','SystemPhotoLibraries','SystemDocumentLibraries','DocumentationLibraries'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
        ActiveSuiteid(){
          return this.$store.state.ActiveSuiteid
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        DefaultThemes(){
        return this.$store.state.DefaultThemes
      },
        IsPrimarySite(){
          return this.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
        },
    MarketplaceTemplates(){
    return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
    },
    OtherMarketplaceTemplates(){
    return this.$store.state.OtherMarketplaceTemplates
    },
    MyMarketplaceTemplates(){
    return this.$store.state.MyMarketplaceTemplates
    },
    RAPlugins(){
          return this.$store.state.RAPlugins
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        // console.log('this.DefaultThemes',this.DefaultThemes)
        // console.log('this.ActiveSuiteid',this.ActiveSuiteid)
        this.FinalNewStructure()
    },
    methods:{
      FixTemplatePluginStructure(){
        this.MarketplaceTemplates.map(temp => {
          let temppath = db.collection('marketplacetemplates').doc(temp.id)
          if(!temp.Parentid && temp.Category && temp.Category.Name !== 'Database App'){
            let tempplugins = this.RAPlugins.filter(plug => {
              return temp.PluginDataBase && temp.PluginDataBase[plug.id] && temp.PluginDataBase[plug.id].Active
            })
            console.log(temp.id,tempplugins)
            tempplugins.map(plug => {
              let plugobj = temp.PluginDataBase[plug.id]
              temppath.collection('plugins').doc(plug.id).set(plugobj)
            })
          }
        })
      },
      FixTemplateNewStructure(){
        //console.log(this.MarketplaceTemplates)
        let websitetemplates = this.MarketplaceTemplates.filter(temp => {
          return temp.Category.Name === 'Website'
        }).map(temp => {
          let tempobj = JSON.parse(JSON.stringify(temp))
          if(tempobj.Children){
           tempobj.ChildTemplates = this.MarketplaceTemplates.filter(childtemp => {
            return tempobj.Children.includes(childtemp.id)
          }) 
          }
          return tempobj
        })
        let socialnetworktempaltes = this.MarketplaceTemplates.filter(temp => {
          return temp.Category.Name === 'Social Network'
        }).map(temp => {
          let tempobj = JSON.parse(JSON.stringify(temp))
         if(tempobj.Children){
           tempobj.ChildTemplates = this.MarketplaceTemplates.filter(childtemp => {
            return tempobj.Children.includes(childtemp.id)
          }) 
          }
          return tempobj
        })
        let featuredmembertemplates = this.MarketplaceTemplates.filter(temp => {
          return temp.Category.Name === 'Featured Member'
        }).map(temp => {
          let tempobj = JSON.parse(JSON.stringify(temp))
          if(tempobj.Children){
           tempobj.ChildTemplates = this.MarketplaceTemplates.filter(childtemp => {
            return tempobj.Children.includes(childtemp.id)
          }) 
          }
          return tempobj
        })
        let groupsitetemplates = this.MarketplaceTemplates.filter(temp => {
          return temp.Category.Name === 'Group Site'
        }).map(temp => {
          let tempobj = JSON.parse(JSON.stringify(temp))
          if(tempobj.Children){
           tempobj.ChildTemplates = this.MarketplaceTemplates.filter(childtemp => {
            return tempobj.Children.includes(childtemp.id)
          }) 
          }
          return tempobj
        })
        let dbtemplates = this.MarketplaceTemplates.filter(temp => {
          return temp.Category.Name === 'Database App'
        }).map(temp => {
          let tempobj = JSON.parse(JSON.stringify(temp))
          if(tempobj.Children){
           tempobj.ChildTemplates = this.MarketplaceTemplates.filter(childtemp => {
            return tempobj.Children.includes(childtemp.id)
          }) 
          }          
          return tempobj
        })
        let combinedtemplates = websitetemplates.concat(socialnetworktempaltes,featuredmembertemplates,groupsitetemplates,dbtemplates)
        let othertemplates = this.MarketplaceTemplates.filter(temp => {
          return !combinedtemplates.find(obj => obj.id === temp.id) && !combinedtemplates.find(obj => obj.id === temp.Parentid)
        }).map(temp => {
          let tempobj = JSON.parse(JSON.stringify(temp))
          return tempobj
        })
        console.log(combinedtemplates,othertemplates)
        //okay so we do know the deal and shit but the structure is a mess, it depends on absoluteley each type
        console.log('websitetemplates',websitetemplates)
        console.log('socialnetworktempaltes',socialnetworktempaltes)
        console.log('featuredmembertemplates',featuredmembertemplates)
        console.log('groupsitetemplates',groupsitetemplates)
        console.log('dbtemplates',dbtemplates)
        //website speaking "YAmG75Li5xZjoQiZS2qW" got "Assets"
        //snb speaking "fwm2QuA5qFkNbDNjH6bb" for "ChanCat","ChanCont","SocContent","sampleusers", and get this it has "Directories" (may change everything)
        //groupsite "BM992o0wNOPltIQ9aRGD" has nothing
        //featmemtemp speaking "lEtxV2bhd6t3O4lDtVz2" aka "founder template" has nothing
        //finally db app "IXHOdMpbEWwoQEEFLayl" has "entities" and "plugins"
        //so snb may be gamechanger what's the deal here?
        //well IF snb uses Directories, any other temp cat type could as well, right?
        //So if we doing this, this way...Does any of this matter? who said we had to limit subcol entry on templates?
        //What prevents it? Not really that we HAVE to limit it, is it?
        websitetemplates.map(temp => {
          let modobj = Object.assign({},temp.TemplateProps)
          let temppath = db.collection('marketplacetemplates').doc(temp.id)
          let modpath = temppath.collection('Modules').doc('Website')
          modpath.set(modobj).then(newdoc => {
            let pagespath = modpath.collection('Pages')
            temp.ChildTemplates.map(childtemp => {
              let pageobj = JSON.parse(JSON.stringify(childtemp.TemplateObj))
              let pagepath = pagespath.doc(pageobj.Name.split(' ').join('_'))
              pagepath.set(pageobj).then(pagedoc => {

              })
            })
          })
        })
        socialnetworktempaltes.map(temp => {
          let modobj = Object.assign({},temp.TemplateProps)
          let temppath = db.collection('marketplacetemplates').doc(temp.id)
          let modpath = temppath.collection('Modules').doc('SocialNetwork')
          modpath.set(modobj).then(newdoc => {
            let pagespath = modpath.collection('Pages')
            temp.ChildTemplates.map(childtemp => {
              let pageobj = JSON.parse(JSON.stringify(childtemp.TemplateObj))
              let pagepath = pagespath.doc(pageobj.Name.split(' ').join('_'))
              pagepath.set(pageobj).then(pagedoc => {
                
              })
            })
          })
        })
        groupsitetemplates.map(temp => {
          let temppath = db.collection('marketplacetemplates').doc(temp.id)
          let pagespath = temppath.collection('Pages')
          temp.ChildTemplates.map(childtemp => {
            let pageobj = JSON.parse(JSON.stringify(childtemp.TemplateObj))
            let pagepath = pagespath.doc(pageobj.Name.split(' ').join('_'))
            pagepath.set(pageobj).then(pagedoc => {
              
            })
          })
        })
        featuredmembertemplates.map(temp => {
          let temppath = db.collection('marketplacetemplates').doc(temp.id)
          let pagespath = temppath.collection('Pages')
          temp.ChildTemplates.map(childtemp => {
            let pageobj = JSON.parse(JSON.stringify(childtemp.TemplateObj))
            let pagepath = pagespath.doc(pageobj.Name.split(' ').join('_'))
            pagepath.set(pageobj).then(pagedoc => {
              
            })
          })
        })
        dbtemplates.map(temp => {
          let modobj = Object.assign({},temp.TemplateProps)
          let temppath = db.collection('marketplacetemplates').doc(temp.id)
          temppath.collection('Modules').doc('Database').set(modobj).then(newdoc => {
          })
        })
      },
      FixTemplateCats(){
        //console.log('FixTemplateCats')
        this.MarketplaceTemplates.map(temp => {
          //console.log(temp.id,'stemp.Category',temp.Category)
          let cat = this.$store.state.TemplateCategories.find(obj => obj.Name === temp.Category.Name)
          if(!cat && temp.Category.Name === 'Website' || !cat && temp.Category.Name === 'Social Network'){
            cat = this.$store.state.TemplateCategories.find(obj => obj.Name === 'Site')
          }
          //console.log(temp.id,'cat',cat)
          db.collection('marketplacetemplates').doc(temp.id).update({
            Category: cat
          })
        })
      },
      FinalNewStructure(){
        if(this.IsPrimarySite && this.userIsAdmin){
          //this.FixAutoNumbers()
          //this.FixTemplatePluginStructure()
          //this.FixTemplateNewStructure()
          this.FixAllSiteWithAllMods()
          this.FixFeaturedMemberFields()
          //this.FixTemplateCats()
          //unused?? this.FixSkillslist()
          //this.FixSitesSearchQuery()
          
        // OLD DO NOT USE THIS ONE, REFERNECING ONLY FOR WHERE WE MSUT DELETE WHAT WE DID WRONG HERE
        // let roottodbroot = ['pdfexportfunctions','clientrecords','clientliaison','membergroups','businessmembers','landingpages','bannerads',
        // 'chatbotmessages','chatbotresponses','defaultchatbotresponses','activities','purchaseinvoices','salesinvoices','salesquotes','storesuppliers',
        // 'usercompanies']
        // OLD DO NOT USE THIS ONE, REFERNECING ONLY FOR WHERE WE MSUT DELETE WHAT WE DID WRONG HERE

        
          //STEP 1 - FIX TO NEW STRUCTURE
        // let roottodbroot = ['pdfexportfunctions','clientrecords','clientliaison','businessmembers'
        // 'chatbotmessages','chatbotresponses','defaultchatbotresponses','activities']
        // roottodbroot.map(col => {
        //   this.FixRootEntriestoNewStructure(col,db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection(col))
        // })
        // let roottodbtables = ['Purchase_Invoices','Sales_Invoices','Sales_Quotes','Store_Suppliers',
        // 'User_Companies']
        // roottodbtables.map(col => {
        //   this.FixRootEntriestoNewStructure(col.split('_').join('').toLowerCase(),db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(col).collection('Records'))
        // })
        // let roottoconf = ['membergroups','users','userroles','businessunits','friendships','friendshiprequests','userinvites','notifications',
        //'landingpages','bannerads']
        // roottoconf.map(col => {
        //   this.FixRootEntriestoNewStructure(col,db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection(col))
        // })
        //harder with subcols and all billingaccounts featuredmembers (because Pages!! and other stuff)
        //this.FixRootEntriestoNewStructure('billingaccounts',db.collection('Databases').doc(this.$store.state.ActiveSuiteid),['contentpurchases','credits','debits','credittokens','subscriptions'])
        // this.FixRootEntriestoNewStructure('featuredmembers',db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers'),['Pages','storedpagepresets','Assets','Enquiries'])
        //WAS ONCE OFF!!! this.UsersColforSites()
        //this.FixRootEntriestoNewStructure('journalentries',db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('journalentries'))
        
        
        //STEP 2
        //this.RemoveOldCollections()
          
        }
     },
     FixAutoNumbers(){
      console.log('FixAutoNumbers')
      let sitesquery = db.collection('SocialNetworks').where(firebase.firestore.FieldPath.documentId(),'not-in',[process.env.VUE_APP_RA_SYSTEM_ID,'ConfigMaster'])
      sitesquery.onSnapshot(res => {
        let total = res.docs.length
        let counter = 0
        console.log('total of ',total,' on FixAutoNumbers')
        const changes = res.docChanges();
        changes.forEach(change => {
        if (change.type === 'added') {
        let defobj = {
              ...change.doc.data(),
              id: change.doc.id
          }
          this.NewSiteAutoNumbers(defobj,db).then(newsite => {
            counter++
            if(counter === total){
              console.log('done')
            }
          })
        }
        })
      })
     },
     NewSiteAutoNumbers(defobj,itemdb){
            let vm = this
            return new Promise(function(resolve, reject) {
              let autonumbers = ['StoreOrderEntityDefault','StoreSupplierEntityDefault','User_Companies_Company_ID',
              'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number',
              'GoodsReceivingVouchers_GRV_Number','PackingLists_Number',
              'Payment_Invoices_Invoice_Number','PickingSlips_Number','PurchaseRequisitions_Number',
              'Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number',
              'Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
              let total = autonumbers.length
              let counter = 0
              autonumbers.map(auto => {
                let path = db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('AutoNumbers').doc(auto)
                let clientdb = itemdb.collection('SystemConfig').doc(defobj.id).collection('AutoNumbers').doc(auto)
                path.get().then(snapshot => {
                  let docdata = snapshot.data()
                  if(docdata){
                    docdata.LastNumbers = []
                    docdata.Last_Number = 0
                    docdata.Last_Value = 0
                   clientdb.set(docdata).then(newdoc => {
                    counter++
                    if(counter === total){
                      resolve('completed autonumbers')
                    }
                  }) 
                  }
                  else{
                    counter++
                    if(counter === total){
                      resolve('completed autonumbers')
                    }                    
                  }
                  
                })
              })
            })
        },
     FixTemplateCategories(){
      //Okay here is a problem with the new categories. 
      let categories = this.$store.state.TemplateCategories
        let templateref = db.collection('marketplacetemplates')
        templateref.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
                let record = {
                      ...change.doc.data(),
                      id: change.doc.id
                  }
                  let recordref = templateref.doc(record.id)
                  let category = ''
                 if(record.Primary_Category.Name === 'Site'){
                    if(record.Secondary_Category.Name === 'Website'){
                      category = categories.find(obj => obj.Name === 'Website')
                    }
                    else if(record.Secondary_Category.Name === 'Social Network'){
                      category = categories.find(obj => obj.Name === 'Social Network')
                    }
                    else if(record.Secondary_Category.Name === 'Featured Member'){
                      category = categories.find(obj => obj.Name === 'Featured Member')
                    }
                    else if(record.Secondary_Category.Name === 'Group Site'){
                      category = categories.find(obj => obj.Name === 'Group Site')
                    }
                    else{
                      console.log('template '+record.id+' ('+record.Name+') unknown prim cat being "',record.Primary_Category.Name+'"')
                    }
                    if(category){
                      //console.log(record.id,record.Name,record.Primary_Category.Name,record.Secondary_Category.Name,recordref,category.Name)
                      recordref.update({
                      Category: category
                    })
                    }                    
                 }
                 else if(record.Primary_Category.Name === 'Page'){
                    if(record.Secondary_Category.Name === 'Website'){
                      category = categories.find(obj => obj.Name === 'Web Page')
                    }
                    else if(record.Secondary_Category.Name === 'Social Network'){
                      category = categories.find(obj => obj.Name === 'Social Page')
                    }
                    else if(record.Secondary_Category.Name === 'Featured Member'){
                      category = categories.find(obj => obj.Name === 'Featured Member Page')
                    } 
                    else if(record.Secondary_Category.Name === 'Group Site'){
                      category = categories.find(obj => obj.Name === 'Group Site Page')
                    }                    
                    else{
                      console.log('template '+record.id+'('+record.Name+') should not exist being "Page" type for "',record.Primary_Category.Name+'"')
                    }
                    if(category){
                      //console.log(record.id,record.Name,record.Primary_Category.Name,record.Secondary_Category.Name,recordref,category.Name)
                      recordref.update({
                      Category: category
                    })
                    } 
                 }
            }
            })
        })
     },
     FixFeaturedMemberFields(){
      //
     },
     FixAllSiteWithAllMods(){
        //TBD I think 1. add the mod "Active" but also add the plugins right?
       
     },
     RemoveOldCollections(){
      let rootdeletes = ['billingaccounts','calendarappointments','clientliaison','classifieds','contacts','featuredmembers','groupmembers',
          'groups','journalentries','membergroups','notifications','pdfexportfunctions','salesinvoices',
          'samplesocialsiteposts','samplessocialsiteimages','siteanalytics','sitearticles',
          'sitemoderators','systemactivities','users','userroles','usermessages']
          console.log('rootdeletes',rootdeletes)
          rootdeletes.map(col => {
            let baseref = db.collection(col)
            baseref.onSnapshot(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let record = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      let recordref = baseref.doc(record.id)
                      console.log('recordref',recordref)
                      recordref.delete()
                }
                })
            })
          })
     },
    //  FixSkillslist(){      
    //       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).COLLECITON()
    //  },
     GetSiteKeyWordsSearchQuery(KeyWords,query){
      let vm = this
      return new Promise(function(resolve, reject) {
          KeyWords.map((kw,i) => {
              let title = kw.Name
              //console.log('kw',kw,title)
                vm.$store.dispatch('CreateSearchTitle',kw.Name).then(searchtitle => {
                  query = query.concat(searchtitle)
                  if(i-1+2 === KeyWords.length){
                    resolve(query)
                  }
                })
            })
      })
     },
     FixSitesSearchQuery(){
      let vm = this
      db.collection('Websites').where(firebase.firestore.FieldPath.documentId(),'not-in',[process.env.VUE_APP_RA_SYSTEM_ID,'ConfigMaster']).onSnapshot(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let site = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      let siteref = db.collection('Websites').doc(site.id)
                      let query = []
                      if(site.Company_Name){
                        vm.$store.dispatch('CreateSearchTitle',site.Company_Name).then(searchtitle => {
                          query = searchtitle
                          if(site.KeyWords && site.KeyWords.length > 0){
                            vm.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                              query = query.concat(kwsearch)
                              //console.log(site.id,query)
                              siteref.update({
                                SearchQuery: query
                              })
                            })
                          }
                          else{
                            siteref.update({
                            SearchQuery: query
                          })
                          }
                          
                        })
                      }
                      else if(site.KeyWords && site.KeyWords.length > 0){
                        vm.GetSiteKeyWordsSearchQuery(site.KeyWords,query).then(kwsearch => {
                          query = query.concat(kwsearch)
                              //console.log(site.id,query)
                           siteref.update({
                            SearchQuery: query
                          })
                        })
                      }
                       
                }
                })
      })
     },
     UsersColforSites(){
      let ref = db.collection('SystemConfig').where(firebase.firestore.FieldPath.documentId(),'!=',process.env.VUE_APP_RA_SYSTEM_ID)
      let baseref = db.collection('SystemConfig')
      ref.onSnapshot(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let system = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      let recordref = baseref.doc(system.id)
                      console.log(system.id)
                      if(system.id !== 'ConfigMaster'){
                         recordref.collection('userroles').onSnapshot(subres => {
                          const subchanges = subres.docChanges();
                              subchanges.forEach(subchange => {
                              if (subchange.type === 'added') {
                                  let userobj = {
                                        ...subchange.doc.data(),
                                        id: subchange.doc.id
                                    }
                              recordref.collection('users').doc(userobj.id).set(userobj)
                              }
                              })
                        })
                          }
                      }
                   
                  
                })
          })
     },
      FixRootEntriestoNewStructure(col,newref,subcols){
        db.collection(col).onSnapshot(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      let currrecordref = db.collection(col).doc(item.id)
                      let newrecordref = newref.doc(item.id)
                  newrecordref.set(item).then(NEWDOC => {
                    if(subcols){
                  subcols.map(subcol => {
                    currrecordref.collection(subcol).onSnapshot(subres => {
                          const subchanges = subres.docChanges();
                              subchanges.forEach(subchange => {
                              if (subchange.type === 'added') {
                                  let subitem = {
                                        ...subchange.doc.data(),
                                        id: subchange.doc.id
                                    }
                              newrecordref.collection(subcol).doc(subitem.id).set(subitem)
                              }
                              })
                        })
                  })
                    }
                  
                })
                }
                })
          })
      },
    ConfirmFixLibraryStructure(){
      confirm('This will restructure "Libraries" , do you want to proceed?') && this.FixLibraryStructure(db,this.System,this.SystemPhotoLibraries,this.SystemDocumentLibraries,this.DocumentationLibraries)
    },
    FixLibraryStructure(database,System,PhotoLibraries,DocumentLibraries,DocumentationLibraries){
      //actually dipshit...EXCLUSIVE TO PhotoLibraries AND DocumentLibraries we should store each record as in example
      // Libraries/VUE_APP_RA_PROVIDER_ID/PhotoLibraries/System_Assets/Assets
      // also, we should actually record classifiedlistings records from "ent.id+'listings" and wikientities entries from ent.id+"wiki"
        let array = ['PhotoLibraries','DocumentLibraries','Directories','classifiedentities','wikientities']
        array.map(col => {
          let olddbref = database.collection('SystemConfig').doc(System.id).collection(col)
          let newdbref = database.collection('Libraries').doc(System.id).collection(col)
          olddbref.get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbref.doc(item.id).set(item).then(newlib => {
                        if(col === 'PhotoLibraries' || col === 'DocumentLibraries'){
                          let oldrecordcolref = db.collection(item.id.split('_').join('').toLowerCase())
                          let newrecordcolref = newdbref.doc(item.id).collection('Assets')
                          oldrecordcolref.get().then(librecordres => {
                            const librecchanges = librecordres.docChanges();
                                librecchanges.forEach(librecchange => {
                                if (librecchange.type === 'added') {
                                    let librecord = {
                                          ...librecchange.doc.data(),
                                          id: librecchange.doc.id
                                      }
                                      newrecordcolref.doc(librecord.id).set(librecord)
                                  }
                                })
                          })
                        }
                      })
                  }
                })
          })
        })
        let olddbref = database.collection('SystemConfig').doc(System.id).collection('DocumentationLibraries')
          let newdbref = database.collection('Libraries').doc(System.id).collection('DocumentationLibraries')
          olddbref.get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbref.doc(item.id).set(item)
                      olddbref.doc(item.id).collection('Pages').get().then(pageres => {
                      const pagechanges = pageres.docChanges();
                          pagechanges.forEach(pagechange => {
                          if (pagechange.type === 'added') {
                              let page = {
                                    ...pagechange.doc.data(),
                                    id: pagechange.doc.id
                                }
                                newdbref.doc(item.id).collection('Pages').doc(page.id).set(page)
                                
                            }
                          })
                  })
                      
                  }
                })
          })
    },
    ConfirmFixWebsiteStructure(){
      confirm('This will restructure "Website" , do you want to proceed?') && this.FixWebsiteStructure(db,this.System,this.DefaultThemes)
    },
    FixWebsiteStructure(database,System,DefaultThemes){
      //article and shit categories 2. ContentRecords like public articles "wiki version", 3. Site Props, replacing "System" props see sitetempaltes
      //db.collection('SocialNetworks').doc(process.env.VUE_APP_RA_SYSTEM_ID)
      let systemprops = ['PublicNavbarItems','Public_Landing_Page'].concat(DefaultThemes.map(theme => {
        return theme.Colors.filter(col => {
          return col.PublicNavbar
        }).flat()
      }))
      let sysobj = {}
      systemprops.map(prp => {
        if(typeof System[prp] !== 'undefined'){
          sysobj[prp] = System[prp]
        }
      })
      let dbbentries = ['ArticlesCategories','BlogsCategories','MeetupsCategories','NoticesCategories','PollsCategories']
      let olddbdoc = database.collection('SystemConfig').doc(System.id)
      let newdbdoc = database.collection('Websites').doc(System.id)
      newdbdoc.set(sysobj).then(newsysdoc => {
        dbbentries.map(col => {
          olddbdoc.collection(col).get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbdoc.collection(col).doc(item.id).set(item)
                  }
                })
          })
        })
      })
      console.log('this.SitePages',this.SitePages)
      let socialpages = this.SitePages.filter(page => {
        return page.PublishType === 'Public'
      })
      socialpages.map(page => {
        newdbdoc.collection('Pages').doc(page.id).set(page)
      })
      let contententries = [{id: 'sitearticleswiki',path: 'sitearticleswiki'},{id: 'siteblogswiki',path: 'siteblogswiki'},{id: 'siteeventswiki',path: 'siteeventswiki'},
      {id: 'sitemeetupswiki',path: 'sitemeetupswiki'}]
      //OKAY STOP...Rememebr now we could do this /SocialNetwork/sysid/sitearticles
      //yeah sure but...we want to so /SocialNetwork/sysid/SocialChannels/Articles....
      contententries.map(col => {
        let olddbref = database.collection(col.path)
        let newdbref = newdbdoc.collection(col.id)
        olddbref.get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbref.doc(item.id).set(item)
                  }
                })
          })
      })
    },
    ConfirmFixSocialNetworkStructure(){
      confirm('This will restructure "SocialNetwork" , do you want to proceed?') && this.FixSocialNetworkStructure(db,this.System,this.DefaultThemes)
    },
    FixSocialNetworkStructure(database,System,DefaultThemes){
      //OKAY IF WE DO GROUPS UNDER SITES...WHAT ABOUT GROUPS AND ALL THE SUBCOL SHIT BELOW IT?
      //MISSING
      //1. groups WITH SUBCOLS invites pages pageassets entities 
      //2. groupmembers WITH SUBCOLS groups subscriptions credits debits
      //3. take "dbbentries" and "contententries" below prepend "groups" just ignore "socialrecords" (posts,comments,likes etc)
      //4. add checkins under meetups AND groupmeetups
      //featuredmembers featuredgroupmembers

      //article and shit categories 2. SocialRecords, ContentRecords, 3. Site Props, replacing "System" props  see sitetempaltes
      //db.collection('SocialNetworks').doc(process.env.VUE_APP_RA_SYSTEM_ID)
      let systemprops = ['SocialNavbarItems','Social_Landing_Page','Social_Module_Name','Group_Categories','Base_App_Royalties','Credit_Tokens_ROE',
      'Social_NavBar_Header_IMG','Social_NavBar_IMG','Social_Navbar_Subtitle','Monetization_BU','Member_Ribbon','Payout_Minimum','Payout_Schedule'].concat(DefaultThemes.map(theme => {
        return theme.Colors.filter(col => {
          return col.Social
        }).flat()
      }))
      let sysobj = {}
      systemprops.map(prp => {
        if(typeof System[prp] !== 'undefined'){
          sysobj[prp] = System[prp]
        }
      })
      let dbbentries = ['ArticlesCategories','BlogsCategories','MeetupsCategories','NoticesCategories','PollsCategories']
      let olddbdoc = database.collection('SystemConfig').doc(System.id)
      let newdbdoc = database.collection('SocialNetworks').doc(System.id)
      newdbdoc.set(sysobj).then(newsysdoc => {
        dbbentries.map(col => {
          olddbdoc.collection(col).get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbdoc.collection(col).doc(item.id).set(item)
                  }
                })
          })
        })
      })
      let socialpages = this.SitePages.filter(page => {
        return page.PublishType === 'Members Only'
      })
      socialpages.map(page => {
        newdbdoc.collection('Pages').doc(page.id).set(page)
      })
      //this.FixtoNewTableStructure(database,this.SystemEntities)
      let contententries = [{id: 'sitearticles',path: 'sitearticles'},{id: 'siteblogs',path: 'siteblogs'},{id: 'siteevents',path: 'siteevents'},
      {id: 'siteforumdiscussions',path: 'siteforumdiscussions'},{id: 'siteforumcomments',path: 'siteforumcomments'},{id: 'siteforumreplies',path: 'siteforumreplies'},
      {id: 'siteforumreplies',path: 'siteforumreplies'},{id: 'glossary',path: 'glossary'},{id: 'sitemeetups',path: 'sitemeetups'},{id: 'sitepolls',path: 'sitepolls'},{id: 'sitepollvotes',path: 'sitepollvotes'},
      {id: 'socialsiteposts',path: 'socialsiteposts'},{id: 'socialsitelikes',path: 'socialsitelikes'},{id: 'socialsiteimages',path: 'socialsiteimages'},{id: 'socialsitecomments',path: 'socialsitecomments'},
      {id: 'sitemoderators',path: 'sitemoderators'},{id: 'systemactivities',path: 'systemactivities'},{id: 'sitemaptags',path: 'sitemaptags'}]
      //OKAY STOP...Rememebr now we could do this /SocialNetwork/sysid/sitearticles
      //yeah sure but...we want to so /SocialNetwork/sysid/SocialChannels/Articles....
      contententries.map(col => {
        let olddbref = database.collection(col.path)
        let newdbref = newdbdoc.collection(col.id)
        olddbref.get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbref.doc(item.id).set(item).then(newdoc => {
                        if(col.id === 'Events'){
                            olddbref.doc(item.id).collection('Attending_Users').get().then(evres => {
                                const evchanges = evres.docChanges();
                                    evchanges.forEach(evchange => {
                                    if (evchange.type === 'added') {
                                        let evitem = {
                                            ...evchange.doc.data(),
                                            id: evchange.doc.id
                                        }
                                        newdbref.doc(evitem.id).collection('Attending_Users').doc(evitem.id).set(evitem)
                                    }
                                    })
                            })
                        }
                      })
                  }
                })
          })
      })
      let oldgroupsref = db.collection('groups')
      let newgroupsref = db.collection('SocialNetworks').doc(System.id).collection('groups')
      let oldgroupmembersref = db.collection('groupmembers')
      let newgroupmembersref = db.collection('SocialNetworks').doc(System.id).collection('groupmembers')
      oldgroupsref.get().then(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newgroupsref.doc(item.id).set(item).then(newdoc => {
                        let array = ['invites','pages','pageassets','entities','invites','ArticlesCategories','BlogsCategories','MeetupsCategories','NoticesCategories','PollsCategories','SubscriptionPackages']
                        array.map(arr => {
                          let oldarrref = oldgroupsref.doc(item.id).collection(arr)
                          let newarrref = newgroupsref.doc(item.id).collection(arr)
                          oldarrref.get().then(arrres => {
                              const arrchanges = arrres.docChanges();
                              arrchanges.forEach(arrchange => {
                              if (arrchange.type === 'added') {
                                let arritem = {
                                      ...arrchange.doc.data(),
                                      id: arrchange.doc.id
                                  }
                                  newarrref.doc(arritem.id).set(arritem).then(addedarrdoc => {

                                  })
                              }
                          })
                          })
                        })
                     })
                }
                })                
        })
        oldgroupmembersref.get().then(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newgroupmembersref.doc(item.id).set(item).then(newdoc => {
                        let array = ['groups']
                        array.map(arr => {
                          let oldarrref = oldgroupmembersref.doc(item.id).collection(arr)
                          let newarrref = newgroupmembersref.doc(item.id).collection(arr)
                          oldarrref.get().then(arrres => {
                              const arrchanges = arrres.docChanges();
                              arrchanges.forEach(arrchange => {
                              if (arrchange.type === 'added') {
                                let arritem = {
                                      ...arrchange.doc.data(),
                                      id: arrchange.doc.id
                                  }
                                  newarrref.doc(arritem.id).set(arritem).then(addedarrdoc => {
                                    
                                  })
                              }
                          })
                          })
                        })
                     })
                }
                })                
        })
    },
    ConfirmFixDirectoryStructure(){
      confirm('This will restructure "CategoricalNavlist" to "Directories" , do you want to proceed?') && this.FixDirectoryStructure(db,this.StoredDefaultDirectories,this.System)
    },
    FixDirectoryStructure(database,StoredDefaultDirectories,System){
      StoredDefaultDirectories.map(list => {
        database.collection('SystemConfig').doc(System.id).collection('Directories').doc(list.id).set(list)
      })
    },
    ConfirmFixtoNewSuiteTemplatesStructure(){
      confirm('This will restructure to the new db layout, do you want to proceed?') && this.FixtoNewSuiteTemplatesStructure(db,this.SystemEntities,this.System,this.DefaultThemes)
    },
    FixtoNewSuiteTemplatesStructure(database,Entities,System,DefaultThemes){
      let colref = database.collection('suitetemplates')
      colref.get().then(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      let itemref = colref.doc(item.id)
                      //not that straightforward now...
                      //we need to realize that currently there are 1. Templates, 2. Assets...nothing more
                      //the "templates" need to be studied. if Primary_Category" is "Site" and "Secondary_Category" is "Social Network" then
                      //1. all children are pages 2. "TemplateProps" is actually what should be stored under "SocialNetworks" subcol for same
                      //what about "assets"? needs to go to "SystemAssets" I guess? OR...We could just leave it alone. I am having doubts about the usefulness of a PhotoLibary and it's tagwords and crap anyway to be honest.
                      //right so "item" is the template and actually NOTHING changes on it so let's go
                      itemref.collection('templates').get().then(tempres => {
                        const tempchanges = tempres.docChanges();
                            tempchanges.forEach(tempchange => {
                            if (tempchange.type === 'added') {
                                let tempitem = {
                                      ...tempchange.doc.data(),
                                      id: tempchange.doc.id
                                  }
                                  let configref = itemref
                                  let newtempref = ''
                                  console.log('tempitem',tempitem)
                                  let newstoreobj = {}
                                  if(tempitem.Primary_Category.Name === 'Site'){
                                    if(tempitem.Secondary_Category.Name === 'Social Network'){
                                      newtempref = itemref.collection('SocialNetworks').doc(item.id)
                                      newstoreobj = {
                                        PluginDataBase: tempitem.PluginDataBase,
                                        Templateid: tempitem.Templateid,
                                        SocialNavbarItems: tempitem.TemplateProps.Navlist,
                                      }
                                    }
                                    else if(tempitem.Secondary_Category.Name === 'Website'){
                                      newtempref = itemref.collection('Websites').doc(item.id)
                                      newstoreobj = {
                                        PluginDataBase: tempitem.PluginDataBase,
                                        Templateid: tempitem.Templateid,
                                        PublicNavbarItems: tempitem.TemplateProps.Navlist,
                                      }
                                    }
                                    for(var prop in tempitem.TemplateProps) {
                                      if (tempitem.TemplateProps.hasOwnProperty(prop)) {
                                         if(typeof tempitem.TemplateProps[prop] !== 'undefined'){
                                          newstoreobj[prop] = tempitem.TemplateProps[prop]
                                         }
                                     }
                                    }
                                  }
                                  else if(tempitem.Primary_Category.Name === 'Page'){
                                    if(tempitem.Secondary_Category.Name === 'Social Network'){
                                      newtempref = itemref.collection('SocialNetworks').doc(item.id).collection('Pages').doc(tempitem.TemplateObj.Name.split(' ').join('_').split('-').join('_'))
                                      newstoreobj = {...tempitem.TemplateObj}
                                    }
                                    else if(tempitem.Secondary_Category.Name === 'Website'){
                                      newtempref = itemref.collection('Websites').doc(item.id).collection('Pages').doc(tempitem.TemplateObj.Name.split(' ').join('_').split('-').join('_'))
                                      newstoreobj = {...tempitem.TemplateObj}
                                    }
                                  }
                                  console.log(newstoreobj)
                                  newtempref.set(newstoreobj)
                              }
                            })
                      })
                  }
                })
          })
    },
    ConfirmFixtoNewDBBStructure(){
      confirm('This will restructure to the new db layout, do you want to proceed?') && this.FixtoNewDBBStructure(db,this.SystemEntities,this.System,this.DefaultThemes)
    },
    FixtoNewDBBStructure(database,Entities,System,DefaultThemes){
      //extra subcols identified possible requirement
      //DBNavlists SystemTableFilters RMsecurityroles
      //also need to lose classifiedentities wikientities this within libraries go alongside directories you get
      let systemprops = ['DBNavbarItems','DefaultStatusField','DB_SideBar_IMG'].concat(DefaultThemes.map(theme => {
        return theme.Colors.filter(col => {
          return col.DataTables
        }).flat()
      }))
      let sysobj = {}
      systemprops.map(prp => {
        if(typeof System[prp] !== 'undefined'){
          sysobj[prp] = System[prp]
        }
      })
      let dbbentries = ['BUsecurityroles','Customsecurityroles','Ledgers','LiaisonTopics','AutoNumbers','DBNavlists','SystemTableFilters']
      let olddbdoc = database.collection('SystemConfig').doc(System.id)
      let newdbdoc = database.collection('Databases').doc(System.id)
      newdbdoc.set(sysobj).then(newsysdoc => {
        dbbentries.map(col => {
          olddbdoc.collection(col).get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbdoc.collection(col).doc(item.id).set(item)
                  }
                })
          })
        })
      })
      Entities.map(ent => {
        let entobj = Object.assign({},ent)
        let entprops = ['SubCollections','DocumentRegisters','Galleries','AllFields','DigitizedForms','EntityProcesses','automations','LoanPackages']
        //actuall revesrse GetAllEntitiesProps
        entprops.map(prp => {
            delete entobj[prp]
          })
        newdbdoc.collection('entities').doc(ent.id).set(entobj).then(newent => {
          entprops.map(prp => {
            if(ent[prp]){
              //because not all have LoanPackages
            let items = ent[prp]
            items.map(item => {
              newdbdoc.collection('entities').doc(ent.id).collection(prp).doc(item.id).set(item)
            })
            }
             
          })
           
        })
      })
      let contententries = [{id: 'customdashboards',path: 'customdashboards'},{id: 'dataimports',path: 'dataimports'}]
      //OKAY STOP...Rememebr now we could do this /SocialNetwork/sysid/sitearticles
      //yeah sure but...we want to so /SocialNetwork/sysid/SocialChannels/Articles....
      contententries.map(col => {
        let olddbref = database.collection(col.path)
        let newdbref = newdbdoc.collection(col.id)
        olddbref.get().then(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      newdbref.doc(item.id).set(item)
                  }
                })
          })
      })
      this.FixtoNewTableStructure(database,Entities,System)
    },
    FixtoNewTableStructure(database,Entities,System){
      //console.log(database,Entities)
      //Please please please think clearly
      //do this right we can replicate for all systems!
        //purpose of method is to transfer all operational data table records into the new structure
        //based on these methods used during "getentityalldata we know what to collect and transfer alongside the record"
        
      //GetEntitySubCollections - subcolid is storage location PASS
      //GetEntityProcesses - simply "process" PASS
      //GetDigitizedForms - DigitizedForms...WTF? this stores FormObj to a liaison, completley unsure where it stores on a data record actually SKIP
      //GetDocumentRegisters - regid PASS
      //GetGalleries - galid PASS
      //GetEntityAutomations - we actually don't store anything SKIP
      //GetLoanPackages - cahs loans only but yes, this does NOT store against record SKIP

      //sorry but there is an additional issue here. This is RA. great we start with Ra but this is RA is also includes subcols or subcols
      //systems has subcol entities that have all of the F above
      //also systems have these that are not registered anywhere
      //plugins
      //apps
      //Options which are email settings

      //additionally Bob yes also this...
      //InternalLiaison
      //uhm....could I do const promises = [] then promises.push(? 

      Entities.map(ent => {
        let roottableentities = ['Systems','Entities','Plugins','Activities','Billing_Accounts','Business_Units','Purchase_Invoices','Sales_Invoices','Sales_Quotes','Store_Suppliers','System_Tickets','User_Companies','Users']
        if(!roottableentities.includes(ent.id)){
        let subcolentries = [
          {id: 'InternalLiaison',path: 'InternalLiaison'},
          {id: 'processes',path: 'processes'},
        ].concat(ent.SubCollections.map(subcol => {
          return {ArrayName: 'SubCollections',id: subcol.id,path: subcol.id,LookupBuilds: subcol.LookupBuilds}
        }),ent.DocumentRegisters.map(subcol => {
          return {ArrayName: 'DocumentRegisters',id: subcol.id,path: subcol.id.split('_').join('').split(' ').join('').toLowerCase()}
        }),ent.Galleries.map(subcol => {
          return {ArrayName: 'Galleries',id: subcol.id,path: subcol.id.split('_').join('').split(' ').join('').toLowerCase()}
        }))
        let oldopspath = database.collection(ent.id.split('_').join('').toLowerCase())
        let newopspath = database.collection('Databases').doc(System.id).collection('entities').doc(ent.id).collection('Records')
        
            //if systems still get plugins and also get apps and their plugins
            // if(ent.id === 'Systems' && this.SystemisRA){
            //   subcolentries = subcolentries.concat([{id: 'plugins',path: 'plugins'},{id: 'apps',path: 'apps',LookupBuilds: [{SubCollections: ['plugins']}]}])
            // }
            oldopspath.get().then(res => {
              const changes = res.docChanges();
              let recordslength = res.docs.length
              let recordcounter = 0
                changes.forEach(change => {
                if (change.type === 'added') {
                    let record = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    newopspath.doc(record.id).set(record).then(newdoc => {
                      recordcounter++
                          // See Promise.all below but could be var linepromises = subcolentries.map
                          subcolentries.map(subcol => {
                            let oldsubcolrecordpath = oldopspath.doc(record.id).collection(subcol.path)
                            //deliberately all without ArrayName become straight records again. thus no "main record" whereby stats recorded
                            //do NOT user this newsubcolpath to shorten newsubcolrecordpath
                            let newsubcolpath = subcol.ArrayName ? newopspath.doc(record.id).collection(subcol.ArrayName).doc(subcol.id) : ''
                            //TableRecords/{entityid}/Records/{recordid}/DocumentRegisters/{cabinetid}/Items/{itemid}
                            let newsubcolrecordpath = subcol.ArrayName ? newopspath.doc(record.id).collection(subcol.ArrayName).doc(subcol.id).collection('Items') : newopspath.doc(record.id).collection(subcol.id)
                            let subcolparent = {
                              Total_Entries: [],
                              Creators: [],
                              Editors: []
                            }
                            //let Items = []
                            //okay so for this to truly work...we need to return subcolentries in counters in a promise
                            //ALTERNATIVELY - since we only calling records that Do in fact exist and we call nothing if it does not exist..
                            //we COULD potentially first just push Items when length met, done
                            //nope that's the problem not knowing there is a match is the problem
                            //You don't KNOw there WILL be records there only ARE records IF...you get?
                            //could we revisit picking slips to learn from array promises hone our skills in it then also would help fixing up financial functions, cause they UGLY!
                            oldsubcolrecordpath.get().then(subcolres => { 
                                const subcolchanges = subcolres.docChanges();
                                subcolparent.Total_Entries = subcolres.docs.length
                                if(subcolparent.Total_Entries === 0 && newsubcolpath){
                                  newsubcolpath.set(subcolparent)
                                }
                                let subcolrecordscounter = 0
                                  subcolchanges.forEach(scolchange => {
                                  if (scolchange.type === 'added') {
                                      let subcolrecord = {
                                          ...scolchange.doc.data(),
                                          id: scolchange.doc.id
                                      }
                                      if(newsubcolpath && subcolrecord.Created_By && subcolrecord.Created_By.id){
                                        let oncheck = subcolparent.Creators.find(obj => obj.User.id === subcolrecord.Created_By.id)
                                        if(!oncheck){
                                          subcolparent.Creators.push({
                                            User: subcolrecord.Created_By,
                                            Counter: 1,  
                                          })
                                        }
                                        else{
                                          oncheck.Counter++
                                        }
                                      }
                                      if(newsubcolpath && subcolrecord.Modified_By && subcolrecord.Modified_By.id && subcolrecord.Created_By && subcolrecord.Created_By.id && subcolrecord.Created_On && subcolrecord.Modified_On){
                                        //yeah don't forget though if Created_On and Modified_On are same, there was no modification
                                        let createdate = subcolrecord.Created_On.toDate()
                                        let modifydate = subcolrecord.Modified_On.toDate()
                                        let createtime = createdate.getTime()
                                        let modifytime = modifydate.getTime()
                                        if(modifytime > createtime){
                                        let oncheck = subcolparent.Editors.find(obj => obj.User.id === subcolrecord.Modified_By.id)
                                          if(!oncheck){
                                            subcolparent.Editors.push({
                                              User: subcolrecord.Modified_By,
                                              Counter: 1,
                                            })
                                          }
                                          else{
                                            oncheck.Counter++
                                          }
                                        }
                                      }
                                      
                                      newsubcolrecordpath.doc(subcolrecord.id).set(subcolrecord).then(newdoc => {
                                          subcolrecordscounter++
                                          if(subcolrecordscounter === subcolparent.Total_Entries && newsubcolpath){
                                            newsubcolpath.set(subcolparent)
                                          }
                                          if(subcol.LookupBuilds){
                                          subcol.LookupBuilds.map(lubuild => {
                                            if(lubuild.SubCollections){
                                              //just note, no counter or states on these
                                              //by example AllFields typically have not createdby and whatever info anyway
                                              lubuild.SubCollections.map(subcolchild => {
                                                let oldchildsubcolref = oldsubcolrecordpath.doc(subcolrecord.id).collection(subcolchild)
                                                let newchildsubcolref = newsubcolrecordpath.doc(subcolrecord.id).collection(subcolchild)
                                                
                                                oldchildsubcolref.get().then(subcolchildres => { 
                                                  const subcolchildchanges = subcolchildres.docChanges();
                                                  let subcolchildrecordslength = res.docs.length
                                                  let subcolchildrecordcounter = 0
                                                    subcolchildchanges.forEach(subcolchildchange => {
                                                    if (subcolchildchange.type === 'added') {
                                                        let subcolchildrecord = {
                                                            ...subcolchildchange.doc.data(),
                                                            id: subcolchildchange.doc.id
                                                        }
                                                        newchildsubcolref.doc(subcolchildrecord.id).set(subcolchildrecord).then(newdoc => {
                                                            subcolchildrecordcounter++
                                                        })
                                                    }
                                                    })
                                                })
                                              })
                                              
                                            }
                                          })
                                        }
                                      })
                                  }
                                  })
                            })
                          })
                          //  return Promise.all(linepromises).then(() => 
                          //   resolve(pickingslips)
                          //   );
                          
                        })
                }
                })
            })
        }
      })
    },
    }
}
</script>

<style>

</style>



