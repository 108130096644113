<template>
  <div>
      <v-card  class="transition-fast-in-fast-out">
          <v-card-title class="recordtoolbar white--text">
            Confirmed Booking
          </v-card-title>
          <v-list-item>
                    
                    <!-- <v-list-item-content > -->
                    <v-list-item-avatar class="blue" tile size="80">
                      <img v-if="BookedUser.Profile_Photo "
                      :src="BookedUser.Profile_Photo"
                      style="object-fit: cover;"              
                      >
                      <img v-if="!BookedUser.Profile_Photo "
                        src="@/assets/BlankProfilePic.png"  
                        style="object-fit: cover;"            
                      >                      
                      </v-list-item-avatar>
                    <!-- </v-list-item-content> -->
                    <v-list-item-content >
                      <v-list-item-title>{{BookedUser.Full_Name}}</v-list-item-title>                    
                      <v-list-item-subtitle>
                        <router-link  :to="'/SiteMember/'+BookedUser.id">View Profile</router-link>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{BookedUser.Email}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Online: Online Now
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
              <v-divider>
              </v-divider>
          <v-card-text style="padding:20px;">
            <!-- <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="{}"
                :sectionindex="''" :tabindex="''" :SampleOnly="true" :System="System"
                :PrimaryFieldName="''" :SingleFormEditing="false" :CurrentEntity="CurrentEntity"
                :Record="BookedUser.BookingObj" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="BookingHeaders" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                /> -->
            <FieldsForm :FormFields="BookingHeaders" @UploadFileSelect="UploadFileSelect" @GetShortLivedURL="GetShortLivedURL"
            :DataTableView="true"  :DataObject="BookedUser.BookingObj" :tab="{}" />
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn @click="UpdateBookedUser(BookedUser)">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/Fields/FieldsForm';	
export default {
    props: ['System','SystemEntities','BookedUser','BookingHeaders'],
    components: {FieldsForm},
    data() {
        return {

        }
    },	
    computed:{
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        UpdateBookedUser(BookedUser){
            this.$emit('UpdateBookedUser',BookedUser)
        },
    }
}
</script>

<style>

</style>



