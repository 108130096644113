
<template>
    
             <v-card  :style="BoxStyle(tab)" :class="tab ? tab.BoxTransparency+' '+tab.CustomClass+' mx-3 footermargin' : 'transparent footermargin'" :color="tab && tab.BGColor ? tab.BGColor.hexa : ''"   
             :elevation="tab ? tab.Elevation : 0" :tile="tab ? tab.Tile : true" :outlined="tab ? tab.Outlined : false" :shaped="tab ? tab.Shaped : false"
             :flat="!tab">
            <v-dialog persistent v-model="dialog"	max-width="400"		
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                            									
                    >		
                    <v-card v-if="$store.state.TemplateView" tile>
                      <v-card-title class="black white--text">
                          New Sample Meetup
                      </v-card-title>
                      <v-card-text>
                        <v-form  ref="form">
                          <v-text-field :rules="[$store.state.formrules.required]" v-model="editedItem.title" label="Title"></v-text-field>
                           <UserLookup v-if="$store.state.TemplateView" :UsersLookupArray="TemplateUsers"  :ModelProp="'Creator'" :RecordObj="editedItem" @UpdateUserLookupProp="UpdateUserLookupProp"
                            :FieldLabel="'Author'" :Rules="[$store.state.formrules.required]"
                            /> 
                          <v-textarea :rules="[$store.state.formrules.required]" v-model="editedItem.caption" label="caption"></v-textarea>
                           <ContentEditableField style="padding:15px;"
                          :FieldObject="editedItem" :FieldName="'description'"
                          :FieldValue="editedItem.description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                         
                           <v-list-item >
                                    <v-list-item-content>
                                      <v-menu
                                v-model="EventstartdateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px" 
                                id="EventStartDatemenu"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="EventStartDate"
                                    label="Start Date"
                                    prepend-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                    id="EventStartDate"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker dense v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
                              </v-menu>             
                                    </v-list-item-content>
                                    <v-list-item-content> 
                                      <v-menu v-if="EventStartDate"
                                v-model="EventStartTimeMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px" 
                                id="EventStartTimeMenu"
                              
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="EventStartTime"
                                    label="Start Time"
                                    prepend-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                    id="EventStartTime"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                dense
                                  v-model="EventStartTime"
                                  @input="EventStartTimeMenu = false"
                                  type="month"
                                  width="290"
                                  class="ml-4"
                                ></v-time-picker>
                              </v-menu>            
                                    </v-list-item-content>
                                    <v-list-item-content>
                                      <v-checkbox dense v-model="AllDayEvent" label="All day Event"></v-checkbox>
                                          </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                    <v-list-item-content>  
                                      <v-menu
                                        v-if="EventStartDate"
                                          v-model="EventenddateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="290px" 
                                          id="EventEndDatemenu"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="EventEndDate"
                                              label="End Date"
                                              prepend-icon="mdi-calendar-month"
                                              readonly
                                              v-on="on"
                                              id="EventEndDate"
                                              clearable
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker dense v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
                                        </v-menu>           
                                    </v-list-item-content>
                                    <v-list-item-content>  
                                            <v-menu  v-if="EventEndDate && EventStartDate && EventStartTime"
                                              v-model="EventEndTimeMenu"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="290px" 
                                              id="EventEndTimeMenu"
                                              
                                            >
                                              <template v-slot:activator="{ on }">
                                                <v-text-field
                                                  v-model="EventEndTime"
                                                  label="End Time"
                                                  prepend-icon="mdi-calendar-month"
                                                  readonly
                                                  v-on="on"
                                                  id="EventEndTime"
                                                  clearable
                                                ></v-text-field>
                                              </template>
                                              <v-time-picker
                                                v-model="EventEndTime"
                                                @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                                                type="month"
                                                width="290"
                                                class="ml-4"
                                                dense
                                              :allowed-minutes="AllowedEndTimeMinutes"
                                              :allowed-hours="AllowedEndTimeHours"
                                              ></v-time-picker>
                                            </v-menu>
                                          </v-list-item-content>
                                        </v-list-item>
                            <v-img class="black" height="200" contain									
                                :src="editedItem.coverimage ? editedItem.coverimage : require('@/assets/ImageHolder.png')"									
                                >
                                <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                            
                                    <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteMeetupCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                                </v-row>
                          </v-img>	
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn dark @click="Closedialog()" outlined color="warning">Cancel</v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="save()" dark outlined color="success">Save</v-btn>
                      </v-card-actions>
                    </v-card>  	
            </v-dialog>
              <v-btn class="red--text" outlined @click="OpenAboutDialog('AboutSiteMeetups')" fab small absolute top right><v-icon>mdi-help</v-icon>
        </v-btn>
        <v-btn style="margin-right:40px;" v-if="UserisTemplateOwner" class="green--text" outlined @click="Activatedialog()" fab small absolute top right><v-icon>mdi-plus</v-icon>
        </v-btn>
            
              <div v-if="UserRecord">
              <div  v-if="SliderView">
                
                  <v-carousel v-model="model" height="400" cycle>
                      <v-carousel-item
                        v-for="article in RelatedMeetups.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          :style="tab && tab.OverlayGradient ? tab.OverlayGradient : 'background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);'"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                             <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'"
                             :style="tab && tab.ArticleTitleColor ? 'padding-left:25px;color: '+tab.ArticleTitleColor.hexa+'!important;' : 'padding-left:25px;'">
                                {{ article.title }}
                              </v-card-subtitle>
                              
                               <v-card-text  :style="ArticleCaptionStyle" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'">
                                 {{article.caption}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn class="links--text" 
                              :to="ItemPath+'/Meetup/'+article[ItemProp]" :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'black'" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
        
                  <div  v-if="!SliderView">
                    <v-list class="transparent">
     <v-list-item  v-for="article in RelatedMeetups" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                :contain="!tab || tab && !tab.WideIMG" :style="tab.IMGBGColor ? 'background-color: '+tab.IMGBGColor.hexa : ''"
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title :class="tab && tab.OverlayGradient ? 'headline' : 'headline black white--text'"
                        :style="NonSliderTitleStyle"
                        >
                          <v-icon class="soloactionicon" left :style="NonSliderIconStyle">mdi-human-greeting-proximity</v-icon>
                          {{ article.title }}
                          <v-spacer></v-spacer>                          
                        <!-- <v-chip small  :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'secondary'" class="white--text">
                          {{article.author}}
                        </v-chip> -->
                          </v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  <v-chip color="success">Starting</v-chip>{{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="smart_substr(article.description,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :to="ItemPath+'/Meetup/'+article[ItemProp]"
                     class="links--text" :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'black'">
                     <v-icon v-if="MiniView">mdi-glasses</v-icon>
                     {{MiniView? '' : 'Read More'}}
                     </v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedMeetups.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
               <div v-if="!UserRecord">
                  <div  v-if="SliderView">
                  <v-carousel v-model="model" height="400">
                      <v-carousel-item
                        v-for="article in RelatedMeetups.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                             <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'">
                                 {{article.caption}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="ItemPath+'/Meetup/'+article[ItemProp]" color="links black--text"  :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedMeetups" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                 <v-chip color="success">Starting</v-chip> {{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="smart_substr(article.description,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-chip small  :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'secondary'" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <!-- <v-btn class="accent" dark :to="'/Meetup/'+article.id">Read More</v-btn> -->
                  <v-btn :to="ItemPath+'/Meetup/'+article[ItemProp]"
                     class="links--text" :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'black'">
                     <v-icon v-if="MiniView">mdi-glasses</v-icon>
                     {{MiniView? '' : 'Read More'}}
                     </v-btn></v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedMeetups.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
           
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
import UserLookup from '@/components/General/UserLookup'
export default {
  props: ['UserRecord','Origin','clientDB','Filter','Excerpt','Slice','SliderView','UserView','UserID','Editor','tab'],
    components: {ContentEditableField,UserLookup
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
          EventenddateMenu: false,
          EventEndDate: '',
          EventStartTime: '',
          EventStartTimeMenu: false,
          EventstartdateMenu: false,
          AllDayEvent: false,
          EventEndTime: '',
          EventEndTimeMenu: false,
          EventStartDate: '',
          CheckAheadMinutes: 30,
            model: 0,
            groupmeetups: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
        groupid(){
          return this.$route.params.id && !this.$store.state.TemplateView ? this.$route.params.id : ''
        },
        TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveMarketTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
      ItemProp(){
        if(this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'){
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
        else{
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
      },
      ItemPath(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '' : '/Group-Blog/'+this.$route.params.id
        }
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      NonSliderIconStyle(){
        let style = ''
        if(this.tab && this.tab.ArticleTitleColor){
          style = style+'color: '+this.tab.ArticleTitleColor.hexa+'!important;'
        }
        return style
      },
      NonSliderTitleStyle(){
        let style = ''
        if(this.tab && this.tab.OverlayGradient){
          style = style+this.tab && this.tab.OverlayGradient
        }
        if(this.tab && this.tab.ArticleTitleColor){
          style = style+'color: '+this.tab.ArticleTitleColor.hexa+'!important;'
        }
        return style
      },
      ArticleCaptionStyle(){
        let style = 'font-size: 10px;padding-left:25px;'
        if(this.tab && this.tab.ArticleCaptionGradient){
          style = style+this.tab.ArticleCaptionGradient
        }
        else if(this.System && this.System.Social_Parallax_Gradient){
          style = style+System.Social_Parallax_Gradient
        }
        else{
          style = style+'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);font-size: 10px;padding-left:25px;'
        }
        return style
      } , 
      AllowedEndTimeHours(){

        let Hours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
      return Hours.filter(hr => {
        if(this.EventStartTime){
          return hr >= Number(this.EventStartTime.split(':')[0])
        }
        else{
          return []
        }
        })
       //console.log(this.AllowedEndTimeHours)
      },
      AllowedEndTimeMinutes(){
        let Minutes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        let starttimehour = Number(this.EventStartTime.split(':')[0])
        let endtimehour = Number(this.EventEndTime.split(':')[0])  
       // console.log(starttimehour,endtimehour)        
        return Minutes.filter(min => {
          if(this.EventStartTime && this.EventEndTime){
            
            if(starttimehour === endtimehour){
              return min >= Number(this.EventStartTime.split(':')[1])
            }
            else{
              return Minutes
            }
          }
          else{
            return Minutes
          }
        })
      },    
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },      
      CollectionRef(){
        if(this.TemplateRootPath){
          return this.TemplateRootPath.collection('samplesitemeetups')
        }
        else if(this.NonGroupRoute){
         return this.userLoggedIn ? this.ContentRootPath.collection('sitemeetups') : db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('sitemeetupswiki') 
        }
        else{
          return this.ContentRootPath.collection('groupmeetups').where('groupid','==',this.$route.params.id)
        }
        
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      CollectionQuery(){
        let ref = this.CollectionRef
        if(this.CompanyOnlyFilter){
          ref = ref.where('Companyid','==',this.UserCompanyid)
        }
        return ref
      },
      userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        RelatedMeetups () {
            return this.groupmeetups.map(article => {
          if(!article.pubDate){
            //still need to convert this.$store.dispatch('FullSTDDate',article.PublishDate.toDate()).then(res => {
            article.pubDate = this.TimestampFormatterSTRINGDayText(article.start.toDate())
          }
          
      
          return article
        })
            // .filter(meetup => {
            //     let meetupgroup = this.groups.find(obj => obj.id === meetup.Groupid)
            //     meetup.category = meetupgroup.GroupCategory
            //     console.log(meetup.grouptype)
            //     let meetupmatch = this.Filter.find(obj => obj.Name === meetup.grouptype)
            //     if(meetupmatch){
            //         return meetup
            //     }
            // })
        },

        MeetupExcerpt () {
          return this.RelatedMeetups.filter(meetup => {
            return meetup.content === this.meetup.content
          })
        }
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleSiteMeetupCoverIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {
            
        this.UserRecord = this.userLoggedIn
      if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.GetSamples(this.CollectionQuery,'groupmeetups')
      }
      else if(this.UserView){
        //console.log('is user view',this.UserID)
        if(this.UserID === this.UserRecord.id){
          let CollectionRef = this.CollectionQuery.where('Creatorid','==',this.UserID)
          if(!this.ChannelRestricted || this.userIsAdmin){
          //backend allows read if moderator, but here we did not check if dude is moderator. not yet anyway
          //so unless we wanna pass that test first, then well let's skip any calls if restricted
          //we should consider member group config, to remove restrictions where moderator
          this.getGroupMeetups(CollectionRef)
          }
        }
        else{
          if(this.$store.state.UserisGuest){
          this.getGroupMeetups(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            if(!this.ChannelRestricted || this.userIsAdmin){
            this.getGroupMeetups(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true)) 
            }          
          }
          else{
             if(!this.ChannelRestricted || this.userIsAdmin){
            this.getGroupMeetups(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
             }
             else{
              this.getGroupMeetups(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
             }
          }
        }
        
        
      }
      else{
        if(this.UserRecord){
          let CollectionRef = this.CollectionQuery
          if(this.$store.state.UserisGuest && this.$route.name !== 'GroupPage'){
            this.getGroupMeetups(CollectionRef.where('PublishStatus','==','Published (Public)'))
            this.getGroupMeetups(CollectionRef.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))        
          }
          else{
            this.getGroupMeetups(CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
          }
        }
        else{
          let CollectionRef = this.CollectionQuery.where('PublishStatus','==','Published (Public)')
          this.getGroupMeetups(CollectionRef)
        }
        
        
      }
        //console.log(this.userid)
},
    methods: {
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
      BoxStyle(tab){
        if(tab){
        if(tab.FullHeight){
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'
        }
        }
        else{
          return ''
        }
       },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      AssignEventEndDate(){
        //console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
      CheckAllowedMinutes(){       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
    GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      RefreshDialog(){
        this.dialog = false
        setTimeout(() => {
            this.dialog = true
        }, 20);
      },
      AssignSampleSiteMeetupCoverIMG(){
        this.editedItem.coverimage = this.SelectedImage.fileurl
        this.editedItem.CoverStorageRef = this.SelectedImage.StorageRef
        this.$store.commit('setIMGProp','')
        this.RefreshDialog()
      },
         getGroupMeetups(CollectionRef) {
         
           CollectionRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.groupmeetups.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     Activatedialog(){
        this.dialog = true
      },
      OpenEditdialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
      ToggleEdit(item,dialprop){
      if(this.$store.state.TemplateOwner && this.Editor && this.$store.state.TemplateView){
        this.editedItem = Object.assign({},item)
        this.OpenEditdialog()
      }
      else if(this.$store.state.TemplateView && !this.Editor){
        //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
        this.OpenAboutDialog(dialprop)
      }
      
    } ,  
      OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
    onFileselected(event) {
        this.selectedFile = event.target.files[0]
    },
    save() {
        if(this.$refs.form.validate()) {
          let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
          let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
          let NewJavaStartDate = new Date(NewStartDateString)
          let NewJavaEndDate = new Date(NewEndDateString)
          let Creator = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
            let MaskedUser = ''
            if(this.$store.state.TemplateView && this.editedItem.Creator){
              Creator = {Full_Name: this.editedItem.Creator.Full_Name,id: this.editedItem.Creator.id,Name: this.editedItem.Creator.Name,Surname: this.editedItem.Creator.Surname}
              MaskedUser = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
            }
          let NewMeetup = {
            Likes: [],
            Shares: [],
            tags: [],
            title: this.editedItem.title,
            Creator: {Full_Name: Creator.Full_Name,id: Creator.id},          
            Creatorid: Creator.id,
            author: Creator.Full_Name,
            authorid: Creator.id,
            description: this.editedItem.description,
            createdon: new Date(),
            Comments: [],
            start: NewJavaStartDate,
            end: NewJavaEndDate,
            PublishStatus: 'Draft',
            Moderators: [],
            moderatorrolesarrayDBRules: {},
            moderatorrolesarrayQuery: [],
            CheckAheadMinutes: this.CheckAheadMinutes,
            GuestsIncluded: false,
            caption: this.editedItem.caption
          }
          if(MaskedUser){
            NewMeetup.MaskedUser = MaskedUser
            NewMeetup.MaskedUserid = MaskedUser.id
          }
          
              if(this.groupid){
                NewMeetup.groupid = this.groupid
              }
              if(!NewMeetup.groupid && vm.userLoggedIn && vm.userLoggedIn.Company){
                NewMeetup.Company = vm.userLoggedIn.Company
                NewMeetup.Companyid = vm.userLoggedIn.Companyid
              }
           
            if(Creator.Profile_Photo){
              NewMeetup.creatorimg = Creator.Profile_Photo
            }
            NewMeetup.coverimage = this.editedItem.coverimage
            NewMeetup.CoverStorageRef = this.editedItem.CoverStorageRef
            this.$store.dispatch('CreateSearchTitle',NewMeetup.title).then(searchtitle => {
            NewMeetup.titleQuery = searchtitle
            console.log(this.CollectionRef)
                      this.CollectionRef.add(NewMeetup).then(() => {
                          })
                  this.Closedialog()
            })
                      }
          }
      },


}
</script>