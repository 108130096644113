<template>
<v-main tile flat class="transparent" height="100%" width="100%" style="padding-bottom:100px;">
   <v-dialog v-if="userLoggedIn" v-model="PromoServiceDialog" max-width="500">
       <v-card v-if="MatchedPromotedActiveServices[promostep]"
    class="mx-auto"
    max-width="500"
  >
    <v-img 
          height="300"
          class="grey lighten-2"
           v-bind:src="MatchedPromotedActiveServices[promostep].Image"
          ></v-img>
    <v-window v-model="promostep">       
      <v-window-item :value="notice.itemObjKey" v-for="notice in MatchedPromotedActiveServices" :key="notice.itemObjKey">       
          <v-card-text v-html="notice.Description">
          </v-card-text>
      </v-window-item>
    </v-window>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        :disabled="promostep === 0"
        text
        @click="promostep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="promostep-1+2 === MatchedPromotedActiveServices.length"
        color="primary"
        depressed
        @click="promostep++"
      >
        Next
      </v-btn>  
      <v-btn 
        color="lime"
        
        @click="ActivateServicePromoMethod(MatchedPromotedActiveServices[promostep])"
      >
        Go Now
      </v-btn>     
    </v-card-actions>
  </v-card>
     </v-dialog>
     
    <v-dialog v-if="userLoggedIn" v-model="MustReadNoticeDialog" max-width="500">
       <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between">
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>

    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
        <v-list-item :to="'/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
     </v-dialog>
     <v-btn absolute left v-if="MobileView && Activesession" @click="Activesession = ''" outlined>Back</v-btn>
    <v-parallax 
    :height="SuiteBuilderNavItem ? 150 : 250" v-if="ParallaxActive"
        :src="System.Guest_Navbar_Parallax ? System.Guest_Navbar_Parallax : require('@/assets/RASolidA.jpeg')"
    >
   <v-card-title class="largeoverline font-weight-thin" style="height: 100%; align-items: baseline;z-index:1;">{{Activesession ? Activesession.name : System.Name+' Portal'}}
    <v-spacer></v-spacer>
            <v-btn dark absolute right v-if="Activesession" @click="Activesession = ''" outlined>Back</v-btn>
            <v-menu v-if="!Activesession && SiteContentAdmin"
             v-model="AdminMenu"
             >
                <template v-slot:activator="{ on,attrs }">
                  <v-btn dark absolute fab icon v-on="on" :v-bind="attrs"
                  right v-if="SiteContentAdmin">
                  <v-icon>mdi-menu
                  </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="ActivateSiteListing()" v-if="!System.SkipListing && !BuilderView">
                    <!-- and if "Website" but NOT listing? -->
                    <v-list-item-avatar>
                      <v-icon>mdi-web
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      Site/Listing
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-menu>
             <v-menu v-if="!Activesession && !SiteContentAdmin"
             v-model="AdminMenu"
             >
                <template v-slot:activator="{ on,attrs }">
                  <v-btn dark absolute fab icon v-on="on" :v-bind="attrs"
                  right v-if="AdminToggleActive">
                  <v-icon>mdi-menu
                  </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="subtleoverline">
                    Site Administration
                  </v-list-item>
                  
                   <v-list-item v-if="SuiteBuilderNavItem" @click="DeactivateSuiteBuilderNavItem()" >
                    <v-list-item-avatar>
                      <v-icon>mdi-cancel
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Cancel
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="ActivateSiteGeneral()" >
                    <v-list-item-avatar>
                      <v-icon>mdi-application-settings
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Quick Build
                    </v-list-item-content>
                  </v-list-item> -->
                  <!-- basically now if "skip listing" do not show this -->
                  <v-list-item @click="ActivateSiteListing()" v-if="!System.SkipListing && !BuilderView">
                    <!-- and if "Website" but NOT listing? -->
                    <v-list-item-avatar>
                      <v-icon>mdi-search-web
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Site/Listing
                    </v-list-item-content>
                  </v-list-item>
                  
                   <v-list-item v-if="!BuilderView" @click="PrepareSelectSuiteBuilderNavItem('Quick Builder')" >
                    <v-list-item-avatar>
                      <v-icon>mdi-cogs
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Site Build
                    </v-list-item-content>
                  </v-list-item>
                  <!-- ({ title: 'Store', icon: 'mdi-shopping',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,Accepted: ['Easy_Products']}) -->
                  <v-list-item v-if="!BuilderView && EasyProductsActive" @click="ActivateSiteStore()" >
                    <v-list-item-avatar>
                      <v-icon>mdi-shopping
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Store
                    </v-list-item-content>
                  </v-list-item>    
                   </v-list-item> 
                  <!-- <v-list-item v-if="!BuilderView && AdvertDirectoryActive" @click="ActivateAdvertDirectory()" >
                    <v-list-item-avatar>
                      <v-icon>mdi-monitor-star
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Adverts
                    </v-list-item-content>
                  </v-list-item>   -->
                  <v-list-item v-if="!BuilderView" @click="ToggleNewAppDialog()" >
                    <v-list-item-avatar>
                      <v-icon>mdi-apps
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                     Add Apps
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
             </v-menu>
           
            <v-btn v-if="RAAdmin && !IsPrimarySite && !System.Offsiteid" dark top style="margin-top: 40px;"
            absolute right @click="ActivateSuiteTemplateInstallerDialog()" color="pink"><v-icon>mdi-cart</v-icon><span v-if="!MiniView">Activate</span></v-btn>
            <!-- <v-btn v-if="RAAdmin && !IsPrimarySite && System.Offsiteid" dark top style="margin-top: 40px;"
            absolute right @click="ReplicatetoClientSuite()" color="pink"><v-icon>mdi-transfer</v-icon><span v-if="!MiniView">Replicate</span></v-btn> -->
        </v-card-title>
        <v-card-title v-if="userIsAdmin">
          Total Users - {{this.UsersArray.length}}
        </v-card-title>
       <v-card-subtitle  class="BoldBuilderGradient" v-if="Activesession.name === 'Org Directory'">
            In order to create a listing in the Directory you would need a Group Account for the Organisation you wish to list. Should there be any costs involved, you will be prompted during the account creation process.
            During the creation process you will be provided with the neccesary information on which packages are available.  
          </v-card-subtitle>
          <v-card-subtitle class="BoldBuilderGradient" v-if="Activesession.name === 'Social Groups'">
            In order to create a Social Group you would need an account for it first. Should there be any costs involved, you will be prompted during the account creation process.
            During the creation process you will be provided with the neccesary information on which packages are available.  
          </v-card-subtitle>
           <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Articles'">
           Below you will find a list of all the Articles you have created. In order to view all Articles you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Articles'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Blogs'">
           Below you will find a list of all the Blogs you have created. In order to view all Blogs you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Blogs'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Classifieds'">
           Below you will find a list of all the Classifieds you have created. In order to view all Classifieds you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Classifieds'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Events'">
           Below you will find a list of all the Events you have created. In order to view all Events you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Events'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Forums'">
           Below you will find a list of all the Forums you have created. In order to view all Forums you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Forums'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Meetups'">
           Below you will find a list of all the Forums you have created. In order to view all Meetups you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Meetups'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Polls'">
           Below you will find a list of all the Polls you have created. In order to view all Polls you need to go to the classifieds Page. Go there now? .
           <v-btn :to="'/Polls'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'Market Templates' && UserMarketAccount">
           Below is you Market Account. You can also go to you Marketplace Templates. Go there now? .
           <v-btn :to="'/MyMarketplaceTemplates'" small dark outlined>View</v-btn>  
          </v-card-subtitle>
           <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'Market Templates' && !UserMarketAccount">
           Let's first create your Market Account. Click "Add Account" below.  
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'My Sites' && UserWebAppAccounts.length > 0">
           Below are your Site Accounts, as well as the list of Sites you own (or manage).
          </v-card-subtitle>
          <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'Featured Member' && UserMarketAccount">
           Below is you Market Account. You can also go to you Marketplace Templates. Go there now? .
           <v-btn :to="FeaturedMemberDefaultPage ? '/FeaturedMember/'+this.userLoggedIn.id+'/'+this.FeaturedMemberDefaultPage : '/FeaturedMemberCreator/'+this.userLoggedIn.id" small dark outlined>View</v-btn>  
          </v-card-subtitle>
           <v-card-subtitle style="z-index:1;" class="BoldBuilderGradient" v-if="Activesession.name === 'Featured Member' && !UserMarketAccount">
           Let's first create your Market Account. Click "Add Account" below.  
          </v-card-subtitle>
          
      <div class="moving-clouds" 
    :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
     <div class="moving-clouds2" 
    :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
    </v-parallax>
    <v-row  class="justify-center" v-if="SuiteBuilderNavItem">
      <!-- style="padding: 30px;"  -->
      <div :style="SuiteBuilderNavItem.title === 'Statistics' ? '' : 'padding: 20px;'" class="flex xl12 lg12 md12 sm12 xs12">
        <SuiteMainHome v-if="SuiteBuilderNavItem.title === 'Home'"
        :System="System" :SystemEntities="SystemEntities" @ActivateProcessing="ActivateProcessing"
        />   
        <SuiteStatistics v-if="SuiteBuilderNavItem.title === 'Statistics'"
        :System="System" :SystemEntities="SystemEntities"
        />  
        <FeaturedPluginMain v-if="SuiteBuilderNavItem.TypeFilter"
        :System="System" :SystemEntities="SystemEntities" :SuiteBuilderNavItem="SuiteBuilderNavItem"
        /> 
        <SuiteSettings v-if="SuiteBuilderNavItem.title === 'Settings'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <SuiteSecurity v-if="SuiteBuilderNavItem.title === 'Security'"
        :System="System" :SystemEntities="SystemEntities"
        />
        <SuiteStore v-if="SuiteBuilderNavItem.title === 'Store'" :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        />
        <SuiteLibraries v-if="SuiteBuilderNavItem.title === 'Libraries' ||
        SuiteBuilderNavItem.Module_Category && SuiteBuilderNavItem.Module_Category.Name === 'Module' && SuiteBuilderNavItem.id === 'Library_Builder'"
        :System="System" :SystemEntities="SystemEntities"
        :Directories="Directories" :DocumentationLibraries="DocumentationLibraries"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
        /> 
         <SuiteWebForms v-if="SuiteBuilderNavItem.title === 'Web Forms'"
        :System="System" :SystemEntities="SystemEntities"ActiveTemplate
        /> 
        <SuiteSubscriptions v-if="SuiteBuilderNavItem.title === 'Subscriptions'"
        :System="System" :SystemEntities="SystemEntities" @ActivateSnackbar="ActivateSnackbar"
        /> 
        <SiteOverview v-if="SuiteBuilderNavItem.title === 'Quick Builder'" @ActivateProcessing="ActivateProcessing"
          :System="System" :SystemEntities="SystemEntities"
          :Directories="Directories" @NavigateWebsiteBuilder="NavigateWebsiteBuilder"
         />
         
        <SuiteMembers v-if="SuiteBuilderNavItem.title === 'Members'" :DBNavLists="DBNavLists"
        :System="System" :SystemEntities="SystemEntities" :SystemTableFilters="SystemTableFilters"
        />
        
        <SuiteModule v-if="SuiteBuilderNavItem.Module_Category && SuiteBuilderNavItem.Module_Category.Name === 'Module' && SuiteBuilderNavItem.id !== 'Library_Builder'"
        :SitePages="SitePages"
          :Module="SuiteBuilderNavItem"
        :Directories="Directories" :DocumentationLibraries="DocumentationLibraries"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
         :UsersArray="UsersArray" @PickPlugin="PickPlugin"
          @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
          :MyGoalsisEmpty="MyGoalsisEmpty" :MyActivitiesArray="MyActivitiesArray"
          :TeamView="true" :Notifications="Notifications" :SystemGuides="SystemGuides"
          :System="System" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
          :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" @ActivateProcessing="ActivateProcessing"
          :SiteModerators="SiteModerators" :SubscriptionPackages="SubscriptionPackages"
        />  
    </div>
    </v-row>
    <v-layout row v-if="!SuiteBuilderNavItem">

    <!-- <v-card tile height="100%" width="100%"> -->
       <div :class="CanSocial && !Activesession || CanSocial && Activesession && Activesession.SocialComponent ? 'flex xl10 lg9 md8 sm12 xs12' : 'flex xl12 lg12 md12 sm12 xs12'">
        <v-row class="justify-start" style="padding:20px;" v-if="!Activesession || Activesession && Activesession.SocialComponent && !MiniView">
            <v-col :cols="DefaultServiceCols" v-for="defser in ComputedDefaultServices" :key="defser.itemObjKey">
                <v-card height="150" >
                   <v-btn dark small depressed
                  fab absolute top right :class="defser.NotificationCount === 0? 'grey' : 'red actionbounce'" icon style="top:10px;z-index:1;opacity: 0.9;">{{defser.NotificationCount}}</v-btn>
                    <!-- <v-card-title  style="font-weight: 300;">
                            {{defser.Name}}
                    </v-card-title> -->
                    <v-list-item  @click="ActivateSession(defser)" style="height: 130px;"> 
                        <v-list-item-avatar size="80" tile>
                            <v-icon size="50">{{defser.icon}}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content> 
                            <v-list-item-title>
                                {{defser.name}}
                            </v-list-item-title> 
                        <v-list-item-subtitle class="largeoverline">
                            {{defser.NotificationCount ? defser.NotificationCount : 0}}
                        </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <v-card-title v-if="!Activesession && NavUserFavorites.length > 0" class="mediumoverline font-weight-thick justify-end">
          <v-spacer></v-spacer>{{!Activesession ? 'My Favorites' : Activesession.name}}
            <!-- <v-btn v-if="Activesession" @click="Activesession = ''" outlined>Back</v-btn> -->
        </v-card-title>
         <v-card class="transparent" tile flat v-if="!Activesession">
        <v-layout>
            
            <v-row class="justify-end" style="padding:20px;width: 100%;" v-if="!Activesession">
                <v-col style="flex-grow: 0!important;" v-for="ser in NavUserFavorites" :key="ser.itemObjKey">
                  <v-card :id="ser.name" elevation="4" v-if="ser.name !== 'Add Service'" 
                  :color="ser.Color ? ser.Color: 'red'" :to="ser.Path" 
                  :height="ServiceBlockSize" :width="ServiceBlockSize" tile dark
                    
                    >
                    <v-card-title class="justify-center" style="padding-bottom: 0px;">
                        <v-icon size="70">{{ser.icon}}</v-icon>
                    </v-card-title>
                    <v-card-title class="white--text font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                        {{ser.name}}
                    </v-card-title>
                    </v-card>
                </v-col>
                </v-row>
                
        </v-layout>
         </v-card>
        <v-card-title v-if="!Activesession && ActiveUserServices.length > 0 || !Activesession && InactiveServices.length > 0" class="mediumoverline font-weight-thin justify-end">{{!Activesession ? 'My Services' : Activesession.name}}<v-spacer></v-spacer>
            <v-btn v-if="Activesession" @click="Activesession = ''" outlined>Back</v-btn>
        </v-card-title>
         <v-card class="SleekLimeBuilderGradient" tile flat v-if="!Activesession && ActiveUserServices.length > 0">
        <v-layout>
            
            <v-row class="justify-start" style="padding:20px;width: 100%;" v-if="!Activesession">
                <v-col style="flex-grow: 0!important;" v-for="ser in ComputedServices" :key="ser.itemObjKey">
                  <v-card elevation="4"  v-if="ser.name === 'Add Service'" :color="ser.Color ? ser.Color: 'red'" v-on="on" :height="ServiceBlockSize" :width="ServiceBlockSize" tile 
                    :style="'border: 1px dotted;'" @click="ShowInactiveServices = !ShowInactiveServices"
                    >
                    <v-card-title class="justify-center" style="padding-bottom: 0px;">
                        <v-icon size="70">{{ser.name === 'Add Service' && ShowInactiveServices ? 'mdi-cancel' : ser.icon}}</v-icon>
                    </v-card-title>
                    <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                        {{ser.name === 'Add Service' && ShowInactiveServices ? 'Cancel' : ser.name}}
                    </v-card-title>
                    </v-card>
                  <v-card :id="ser.name" elevation="4" v-if="ser.name !== 'Add Service'" :color="ser.Color ? ser.Color: 'red'" v-on="on" @click="ser.InActive ? ActivateUserService(ser) : ActivateSession(ser)" 
                  :height="ServiceBlockSize" :width="ServiceBlockSize" tile dark
                    
                    >
                    <v-card-title class="justify-center" style="padding-bottom: 0px;">
                        <v-icon size="70">{{ser.icon}}</v-icon>
                    </v-card-title>
                    <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                        {{ser.name}}
                    </v-card-title>
                    </v-card>
                </v-col>
                </v-row>
        </v-layout>
         </v-card>
         <v-card-title  v-if="!Activesession && ActiveServices.length > 0 || Activesession && Activesession.SocialComponent && !MiniView" class="mediumoverline font-weight-thin">{{!Activesession ? 'Feed' : Activesession.name}}<v-spacer></v-spacer>
            <v-btn v-if="Activesession" @click="Activesession = ''" outlined>Back</v-btn>
        </v-card-title>
       <div v-if="!Activesession || Activesession && Activesession.SocialComponent">
     
        <v-layout>
          
         <v-row class="justify-start" style="padding:20px;width: 100%;" v-if="!Activesession">
            <v-col style="flex-grow: 0!important;" v-for="ser in ActiveServices" :key="ser.itemObjKey">
                <v-card elevation="4" :color="ser.Color ? ser.Color: 'red'" @click="ActivateSession(ser)" :height="ser.Height ? ser.Height : ServiceBlockSize" :width="ser.Width ? ser.Width : ServiceBlockSize" tile dark>
                    
                      
                   <v-card-title class="justify-center" style="padding-bottom: 0px;">
                    <v-icon size="70">{{ser.icon}}</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                    {{ser.name}}
                   </v-card-title>
                <v-btn v-if="ser.NotificationCount > 0"
                 fab absolute top right :class="ser.NotificationCount === 0? 'grey' : 'red actionbounce'" icon style="z-index:1;">{{ser.NotificationCount}}</v-btn>
                </v-card>
            </v-col>
            </v-row>
        </v-layout>
        
             
            <div v-if="Activesession && Activesession.SocialComponent">
              
            <SiteArticleFeed
                v-if="Activesession.Name === 'Site Articles'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="Activesession" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                :SliderView="Activesession.SliderView" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="Activesession.Excerpt ? Activesession.Excerpt : 250" :Slice="Activesession.Slice ? Activesession.Slice : 6" :Filter="''"
                />
                    <!-- TypeCategories -->
                <!-- okay my opinion we must reinstate site groups WITHOUT catnavlist...for purposes, when not a directory page. This view below is freaking horrible and stupid
                However for now let's cater for it, may make sense later to have full directory on a standard "non CatNavList" page -->
                     
                <SiteGroupsFeed
                v-if="Activesession.Name === 'Groups Feed'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="Activesession" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode"
                :SliderView="Activesession.SliderView" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="Activesession.Excerpt ? Activesession.Excerpt : 250" :Slice="Activesession.Slice ? Activesession.Slice : 6" :Filter="''"
                /> 
                <SiteBlogsFeed
                v-if="Activesession.Name === 'Site Blogs'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="Activesession" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                :SliderView="Activesession.SliderView" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="Activesession.Excerpt ? Activesession.Excerpt : 250" :Slice="Activesession.Slice ? Activesession.Slice : 6" :Filter="''"
                />                            
                <SiteEventsFeed
                v-if="Activesession.Name === 'Site Events'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="Activesession" @OpenDialog="OpenDialog" :FeedView="true" :AppisDarkMode="AppisDarkMode" :System="System"
                />
                <SiteMeetupsFeed
                v-if="Activesession.Name === 'Site Meetups'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="Activesession" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                :SliderView="Activesession.SliderView" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="Activesession.Excerpt ? Activesession.Excerpt : 250" :Slice="Activesession.Slice ? Activesession.Slice : 6" :Filter="''"
                />
            </div>
       
       </div>
        <div v-if="Activesession && !Activesession.SocialComponent">
           <SuiteStore v-if="Activesession.name === 'Store'" :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @DeactivateActivesession="DeactivateActivesession"
        />
         
        <SuiteAdvertDirectoryConfig v-if="Activesession.name === 'My Adverts'" :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @DeactivateActivesession="DeactivateActivesession"  :SubscriptionPackages="SubscriptionPackages"
        />
        <MyVacancies v-if="Activesession.name === 'My Vacancies'" :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :UnreadNotifications="UnreadNotifications"
        @DeactivateActivesession="DeactivateActivesession"  :SubscriptionPackages="SubscriptionPackages"
        />
        <JobApplications v-if="Activesession.name === 'My Job Applications'" :Directories="Directories"
        :System="System" :SystemEntities="SystemEntities" :Applications="MyJobApplications"
        @DeactivateActivesession="DeactivateActivesession"
        />
        
            <DirectoryViewer style="padding-left: 10px;padding-right:10px;" v-if="Activesession && Activesession.CategoricalListType"
                :ProvidedAdditionalFilters="Activesession.AdditionalFilters" :SystemEntities="SystemEntities" :System="System" 
            :ProvidedNavList="Activesession.NavList" :ProvidedCurrentEntity="Activesession"
            @ActivateTreeNode="ActivateTreeNode" :ProvidedFeatureList="Activesession.FeatureList" :style="FinalPageStyling"
            />
          <SiteListing v-if="Activesession.name === 'Site Listing'" @ActivateProcessing="ActivateProcessing"
          :System="System" :SystemEntities="SystemEntities"
          :Directories="Directories" @NavigateWebsiteBuilder="NavigateWebsiteBuilder"
         />
         <FeaturedMemberOverview v-if="Activesession.name === 'Featured Member'" @ActivateProcessing="ActivateProcessing"
          :System="System" :SystemEntities="SystemEntities" :FeaturedMemberid="userLoggedIn.id"
         />
            <MyBillingAccounts v-if="Activesession.name === 'My Accounts' 
            || Activesession.name === 'My Sites' || Activesession.name === 'Market Templates'
            || Activesession.name === 'Org Directory' || Activesession.name === 'Social Groups'" 
            @ActivateProcessing="ActivateProcessing"  :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="ClientStoreOrders"
            :SubscriptionPackages="SubscriptionPackages" :Directories="Directories" style="margin-top: 12px;"
            :TypeFilters="BATypeFilters" :ActiveSession="Activesession"
            @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
               <!-- <SuiteTemplates
                v-if="Activesession && Activesession.name === 'My Sites'"
                :System="System" :SystemEntities="SystemEntities"
                />  -->
            <MyCompanyComp v-if="Activesession.name === 'My Company'" @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="ClientStoreOrders"
            :SubscriptionPackages="SubscriptionPackages"
            @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
            <MyProfile v-if="Activesession.name === 'My Profile'" @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System"
            @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"  style="margin-top:-48px;" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
             <component v-if="Activesession && Activesession.ComponentName"  :is="ActiveSessionComponent"  :MenuItem="Activesession" :UsersArray="UsersArray"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="Activesession.ComponentName === 'DashboardBuilder' ? SystemEntities : DashboardSystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="TeamView" :ActiveSession="Activesession" :Notifications="Notifications" :IsUserView="true"
            :GroupLandingPageViews="GroupLandingPageViews" :EntityRecord="Activesession.EntityRecord" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
            :EnquiriesCollection="'classifiedenquiries'"
            />
            <!-- for classifieds we need to add props
            ,'IntranetView','IsUserView','EnquiriesCollection','ClassifiedsClasses','mainclass','subclass','ActiveFilters','AdditionalFilters','NavList','Editor','System'
            OR just call dirview? on the other hand, in this particular part right here, it is NOT dir it is just dasboard-->
            <div v-if="Activesession.IsTable">
            <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
                    </v-card-title>
                    <DataCollectionViewComponent class="background" :System="System"  :EntityRecord="Activesession" :AppisDarkMode="AppisDarkMode" @ActivateProcessing="ActivateProcessing" @ActivateSnackbar="ActivateSnackbar"/>
            </div>
            <div v-if="Activesession.IsSchedule">
            <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
                    </v-card-title>
                    <DataCollectionViewComponent class="background" :System="System" :EntityRecord="Activesession" :AppisDarkMode="AppisDarkMode" @ActivateProcessing="ActivateProcessing" @ActivateSnackbar="ActivateSnackbar"/>
            </div>
            <div v-if="Activesession.WikiData">
              <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
                    </v-card-title>
              <v-list>
                <v-list-item class="detailslistoutline" v-for="wiki in Activesession.WikiData" :key="wiki.itemObjKey" @click="RouteToItem('/'+Activesession.SingleName.split(' ').join('')+'-Wiki/'+wiki[Activesession.RecordPrimaryField.Name])">
                  {{wiki[Activesession.RecordPrimaryField.Name]}}
                </v-list-item>
              </v-list>
            </div>
            <div v-if="Activesession.Data" class="white">
                <v-dialog max-width="1000" v-model="OpenFormComponent">
                    <DigitizedFormComponent v-if="OpenFormComponent && ActiveForm" @SubmitForm="SubmitForm" :ActiveForm="ActiveForm" :comment="ActiveComment" :userLoggedIn="userLoggedIn"/>
                </v-dialog>
                    <v-list v-if="!ActiveRecord">
                        <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
                        </v-card-title>
                        <v-list-item class="detailslistoutline" v-for="record in Activesession.Data" :key="record.itemObjKey" @click="ActivateActiveRecord(record)">
                        <v-badge
                            bordered
                            :color="record.NotifyColor"
                            overlap
                            > <span slot="badge"> {{record.Notifications.length}}</span>
                            <v-list-item-content>
                            
                            {{Activesession.Entity_Type && Activesession.Entity_Type.Name === 'Store Order' ? record.id : record.name}}
                            </v-list-item-content>
                            </v-badge>
                        </v-list-item>
                    </v-list>

                    
                    <v-card-title v-if="ActiveRecord" class="mediumoverline">
                    <v-btn v-if="ActiveRecord" @click="ActivateSession(Activesession),ToTop()">Back</v-btn>
                        </v-card-title>
                        <v-card-title v-if="ActiveRecord" class="mediumoverline">
                    Update
                        </v-card-title>

                    <v-timeline class="background"
                    dense
                    clipped
                    align-top
                    v-if="ActiveRecord"
                    >
                    <v-timeline-item
                        fill-dot
                        class="white--text mb-12"
                        color="orange"
                        large
                    >
                        <template v-slot:icon>
                        <span>{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}</span>
                        </template>
                        <v-text-field
                        v-model="input"
                        hide-details
                        flat
                        label="Leave a comment..."
                        solo
                        @keydown.enter="comment(ActiveRecord,null,LiaisonUploadFile)"
                        >
                        <template v-slot:append>
                        <v-menu
                            
                            top
                            offset-y
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-link</v-icon>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-text-field v-model="NewLink" label="link" :rules="[$store.state.formrules.url]"/>
                                </v-list-item>
                                <v-list-item-action>
                                    <v-btn @click="link(NewLink,record)">Save</v-btn>
                                </v-list-item-action>
                            </v-list>
                            </v-menu>

                            <input v-if="!LiaisonUploadFile"
                                style="display:none"
                                type="file"
                                @change="onFileselected($event)"
                                id="fileInputer">
                            <v-icon
                            class="mx-0"
                            depressed
                            @click="FileInput('fileInputer')"
                            >
                            mdi-paperclip
                            </v-icon>
                        
                            <v-btn
                            class="mx-0"
                            
                            @click="comment(ActiveRecord,null,LiaisonUploadFile)"
                            >
                            Post
                            </v-btn>
                        </template>
                        </v-text-field>
                    </v-timeline-item>

                    <v-slide-x-transition
                        group
                    >
                        <v-timeline-item
                        v-for="event in timeline"
                        :key="event.id"
                        class="mb-4"
                        color="pink"
                        small
                        >
                        <v-row justify="space-between">
                            <v-col
                            cols="7"
                            v-text="event.text"
                            ></v-col>
                            <v-col
                            class="text-right"
                            cols="5"
                            v-text="event.time"
                            ></v-col>
                        </v-row>
                        </v-timeline-item>
                    </v-slide-x-transition>

                    <v-timeline-item
                        style="padding:20px;"
                        class="mb-4"
                        color="grey"
                        icon-color="grey lighten-2"
                        small
                        :hide-dot="comment.hidedot"
                        v-for="comment in SortClientLiaison()" :key="comment.itemObjKey">
                        <template v-slot:icon>
                            <v-avatar>
                            <img v-if="comment.ProfPic" :src="comment.ProfPic" style="object-fit: cover;" >
                            <v-chip v-if="!comment.ProfPic"
                            class="white--text ml-0"
                            :color="comment.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
                            label
                            small
                            >
                            {{comment.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{comment.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-chip>
                            </v-avatar>
                        </template>
                        <span class="caption">
                        <span v-if="comment.Created_On.toDate">
                                {{comment.Created_On.toDate()}}
                            </span>
                            <span v-if="!comment.Created_On.toDate && comment.Created_On.seconds">
                                {{new Date(comment.Created_On.seconds*1000)}}
                            </span></span>
                        <v-card class="elevation-2">
                            <v-card-text v-html="comment.description">                            
                            </v-card-text>
                            <v-card-text v-if="comment.ThumbURL">
                                <v-avatar tile size="150">
                            <v-img contain
                                :src="comment.ThumbURL"
                                    />
                            </v-avatar>                            
                            </v-card-text>
                            <v-card-actions  v-if="comment.fileurl">
                                <v-btn small v-if="!comment.TokenURL"
                                download
                                @click="GetShortLivedURL(comment)"
                                class="blue--text mx-0"
                                >
                                Get Token <v-icon small>mdi-security</v-icon>
                                </v-btn><v-spacer></v-spacer>
                                <v-btn small v-if="comment.TokenURL"
                                :href="comment.TokenURL" target="_blank"
                                class="orange--text mx-0"
                                >
                                View <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions  v-if="comment.FormObj">
                                <v-btn
                                    @click="OpenForm(comment.FormObj,comment)"
                                    class="blue--text mx-0"
                                    >
                                    Open Form
                                    </v-btn>
                            </v-card-actions>
                        </v-card>
                        
                    </v-timeline-item>
                    <v-timeline-item>
                            <v-btn @click="slice = slice+4">More</v-btn>
                            <v-btn v-if="slice>4" @click="slice = slice-4">Less</v-btn>
                        </v-timeline-item>

                    </v-timeline>
                </div>
        </div>
        <v-card color="red" dark tile flat width="100%" v-if="WebAppBuilderActive && !Activesession || Activesession && Activesession.name === 'My Sites'">
          <!--  :light="$vuetify.theme.dark" :dark="!$vuetify.theme.dark" -->
          <v-layout row class="justify-start" style="padding-top:50px;">
            <div class="flex xs12 sm12 md12 lg12 xl12" v-if="MiniView">
          <v-card-title style="float: left;padding-left: 50px" class="mediumoverline font-weight-thin" >My Sites<v-spacer></v-spacer>
            
        </v-card-title>
         <v-row class="justify-start" style="padding:20px;width: 100%;margin-left:5px;">
            <v-col style="flex-grow: 0!important;" v-for="site in ComputedUserSites" :key="site.itemObjKey">
                <v-card elevation="4" :color="site.Color ? site.Color: '#1E1E1E'" @click="ConfirmToggleSite(site)" :height="ServiceBlockSize" :width="ServiceBlockSize" tile dark>
                    
                   <v-card-title class="justify-start" style="padding-bottom: 0px;">
                    <v-icon size="70">{{site.Icon}}</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;color: white;">
                    {{site.Name}}
                   </v-card-title>
                <v-btn v-if="site.NotificationCount > 0"
                 fab absolute top right :class="site.NotificationCount === 0? 'grey' : 'red actionbounce'" icon style="z-index:1;">{{site.NotificationCount}}</v-btn>
                </v-card>
            </v-col>
            </v-row>
             </div>
             <div class="flex xs12 sm12 md12 lg12 xl12" v-if="!MiniView">
          <v-card-title style="float: left;margin-left:30px;" class="mediumoverline font-weight-thin" >My Sites<v-spacer></v-spacer>
        </v-card-title>
         <v-row class="justify-start" style="padding:20px;width: 100%;margin-left:30px;">
            <v-col style="flex-grow: 0!important;" v-for="site in ComputedUserSites" :key="site.itemObjKey">
                <v-card elevation="4" :color="site.Color ? site.Color: '#1E1E1E'"  @click="ConfirmToggleSite(site)" :height="ServiceBlockSize" :width="ServiceBlockSize" tile dark>
                   <v-card-title class="justify-center" style="padding-bottom: 0px;">
                    <v-icon size="70">{{site.Icon}}</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;color: white;">
                    {{site.Name}}
                   </v-card-title>
                <v-btn v-if="site.NotificationCount > 0"
                 fab absolute top right :class="site.NotificationCount === 0? 'grey' : 'red actionbounce'" icon style="z-index:1;">{{site.NotificationCount}}</v-btn>
                </v-card>
            </v-col>
            </v-row>
             </div>
          </v-layout>
        </v-card>
        <v-card dark tile flat width="100%" v-if="!Activesession">
          <!--  :light="$vuetify.theme.dark" :dark="!$vuetify.theme.dark" -->
          <v-layout row class="justify-end" style="padding-top:50px;">
            <div class="flex xs12 sm12 md12 lg12 xl12" v-if="!Activesession && !UserisGuest && MiniView">
          <v-card-title style="float: left;padding-left: 50px" class="mediumoverline font-weight-thin" >Apps<v-spacer></v-spacer>
            <v-btn v-if="Activesession" @click="Activesession = ''" outlined>Back</v-btn>
        </v-card-title>
         <v-row class="justify-end" style="padding:20px;width: 100%;">
            <v-col style="flex-grow: 0!important;" v-for="app in ComputedUserApps" :key="app.itemObjKey">
              <!-- app {{app}} -->
                <v-card elevation="4" :color="app.Color ? app.Color: 'red'" @click="app.id ? '' : ToggleNewAppDialog()" :to="app.id? '/App/'+app.id : ''" 
                :height="ServiceBlockSize" :width="ServiceBlockSize" tile dark>
                    
                      
                   <v-card-title class="justify-center" style="padding-bottom: 0px;">
                    <v-icon size="70">{{app.Icon}}</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;color: white;">
                    {{app.Name}}
                   </v-card-title>
                <v-btn v-if="app.NotificationCount > 0"
                 fab absolute top right :class="app.NotificationCount === 0? 'grey' : 'red actionbounce'" icon style="z-index:1;">{{app.NotificationCount}}</v-btn>
                </v-card>
            </v-col>
            </v-row>
             </div>
            <div class="flex xs8 sm8 md4 lg6 xl6" style="place-self: center;">
          <v-list-item class="justify-center">
          <img :height="LargeView? 200 : 140" src="@/assets/logo.png">
          </v-list-item>
          <v-list-item class="justify-center">
          <span>Powered by</span><pre> </pre><a href="https://www.rapidapps.co.za/" style="color:white" target="_blank" class="txtlink">Rapid<span class="font-weight-light">Apps™</span></a>
          </v-list-item>
            </div>
             <div class="flex xs6 sm6 md8 lg6 xl6" v-if="!Activesession && !UserisGuest && !MiniView">
          <v-card-title style="float: right;margin-right:30px;" class="mediumoverline font-weight-thin" >Apps<v-spacer></v-spacer>
            <v-btn v-if="Activesession" @click="Activesession = ''" outlined>Back</v-btn>
        </v-card-title>
         <v-row class="justify-end" style="padding:20px;width: 100%;">
            <v-col style="flex-grow: 0!important;" v-for="app in ComputedUserApps" :key="app.itemObjKey">
                <v-card elevation="4" :color="app.Color ? app.Color: 'red'" @click="app.id ? '' : ToggleNewAppDialog()" :to="app.id? '/App/'+app.id : ''"
                :height="ServiceBlockSize" :width="ServiceBlockSize" tile dark>
                    
                      
                   <v-card-title class="justify-center" style="padding-bottom: 0px;">
                    <v-icon size="70">{{app.Icon}}</v-icon>
                   </v-card-title>
                  <v-card-title class="font-weight-thin justify-center" style="padding-top: 10px;text-align: center;font-size: 0.9em;color: white;">
                    {{app.Name}}
                   </v-card-title>
                <v-btn v-if="app.NotificationCount > 0"
                 fab absolute top right :class="app.NotificationCount === 0? 'grey' : 'red actionbounce'" icon style="z-index:1;">{{app.NotificationCount}}</v-btn>
                </v-card>
            </v-col>
            </v-row>
             </div>
          </v-layout>
        </v-card>
        </div>
         <div v-if="CanSocial && !Activesession || CanSocial && Activesession && Activesession.SocialComponent" class="flex xl2 lg3 md4 sm12 xs12">
            <v-card-subtitle style="padding-top: 30px;padding-bottom: 30px;">
                All Activities
            </v-card-subtitle>
            <SystemActivitiesFeed :AppisDarkModeCard="AppisDarkModeCard" :AppisDarkMode="AppisDarkMode" :UsersRecord="userLoggedIn" :Origin="'Page'" :Excerpt="14" :HasFilter="false" />
        </div>
    </v-layout>
    <!-- </v-card> -->
</v-main>
</template>

<script>
// import VehiclesWiki from '@/views/VehiclesWiki'
// import VehicleApplicationsWiki from '@/views/VehicleApplicationsWiki'
import MyArticles from '@/components/SuitePlugins/Articles/MyArticles'
import MyMeetups from '@/components/SuitePlugins/Meetups/MyMeetups'
import MyBlogs from '@/components/SuitePlugins/Blogs/MyBlogs'
import MyClassifieds from '@/components/SuitePlugins/Classifieds/Classifieds'
import MyEvents from '@/components/SuitePlugins/Events/MyEvents'
import MyPolls from '@/components/SuitePlugins/Polls/MyPolls'
import MyForumThreads from '@/components/SuitePlugins/Forums/MyForumThreads'
import MyNoticeBoardPosts from '@/components/SuitePlugins/DigitalNoticeBoard/MyNoticeBoardPosts'

import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';
import DigitizedFormComponent from '@/components/Client/DigitizedFormComponent'
import MyBillingAccounts from '@/components/Client/MyBillingAccounts'
import MyCompanyComp from '@/components/Client/MyCompanyComp'
import SystemActivitiesFeed from '@/components/SocialNetwork/SystemActivitiesFeed';
import SiteArticleFeed from '@/components/SuitePlugins/Articles/SiteArticleFeed';
import SiteBlogsFeed from '@/components/SuitePlugins/Blogs/SiteBlogsFeed';
import SiteEventsFeed from '@/components/SuitePlugins/Events/SiteEvents';
import SiteMeetupsFeed from '@/components/SuitePlugins/Meetups/SiteMeetupsFeed';
import SiteGroupsFeed from '@/components/SuitePlugins/Groups/GroupsFeed';
import SuiteTemplates from '@/components/SuitePlugins/SuiteTemplates/SuiteTemplates';

import FeaturedMemberOverview from '@/components/SuitePlugins/FeaturedMembers/FeaturedMemberOverview';
import SiteOverview from '@/components/SuitePlugins/WebAppBuilder/SiteOverview';
import SiteListing from '@/components/SuitePlugins/WebAppBuilder/SiteListing';

import SuiteMainHome from '@/components/SuiteBuilder/Main/SuiteMainHome';	
import SuiteStatistics from '@/components/SuiteBuilder/Main/SuiteStatistics';
import FeaturedPluginMain from '@/components/SuiteBuilder/FeaturedPlugins/FeaturedPluginMain';	
import SuiteSettings from '@/components/SuiteBuilder/Main/SuiteSettings';
import SuiteSecurity from '@/components/SuiteBuilder/Main/SuiteSecurity';
import SuiteLibraries from '@/components/SuiteBuilder/Main/SuiteLibraries';
import SuiteStore from '@/components/SuiteBuilder/Main/SuiteStore';
import SuiteAdvertDirectoryConfig from '@/components/SuitePlugins/AdvertDirectory/SuiteAdvertDirectoryConfig';
import SuiteWebForms from '@/components/SuiteBuilder/Main/SuiteWebForms';
import SuiteSubscriptions from '@/components/SuiteBuilder/Main/SuiteSubscriptions';	
import SuiteMembers from '@/components/SuiteBuilder/Main/SuiteMembers';		
import SuiteModule from '@/components/SuiteBuilder/Main/SuiteModule';	
import MyVacancies from '@/components/SuitePlugins/Vacancies/MyVacancies';
import JobApplications from '@/components/SuitePlugins/Vacancies/JobApplications';

import MyProfile from '@/views/MyProfile'
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import DirectoryViewer from '@/components/Directories/DirectoryViewer';
export default {
    props: [{ScheduleEntityComponent: {			
			type: String,
			required: true
		}},'MyActivitiesArray','MyGoals','MyGoalsisEmpty','SystemGuides','SystemPhotoLibraries','DocumentationLibraries','SystemDocumentLibraries','Directories',
    'AppisDarkMode','Notifications','SitePages','NotificationObj','SystemEntities','System','SubscriptionPackages','StoredDefaultDirectories','SiteModerators'],
    components: {
      SiteOverview,
      SiteListing,
      FeaturedMemberOverview,
      SuiteStore,
      SuiteAdvertDirectoryConfig,
      MyVacancies,
      JobApplications,
      SuiteMainHome,SuiteStatistics,FeaturedPluginMain,SuiteSettings,SuiteSecurity,SuiteLibraries,SuiteWebForms,SuiteSubscriptions,SuiteMembers,SuiteModule,
      SuiteTemplates,
        MyArticles,
        MyMeetups,
        MyBlogs,
        MyClassifieds,
        MyEvents,
        MyPolls,
        MyForumThreads,
        MyNoticeBoardPosts,

        SystemActivitiesFeed,
        MyProfile,
        DataCollectionViewComponent,
        DigitizedFormComponent,
        MyBillingAccounts,
        MyCompanyComp,
        SiteArticleFeed,
        SiteBlogsFeed,
        SiteGroupsFeed,
        SiteEventsFeed,
        SiteMeetupsFeed,
        DirectoryViewer
        // VehiclesWiki,
        // VehicleApplicationsWiki
    },
    data(){
        return {
          AdminMenu: false,
          SuiteItems: [
                { title: 'Home', icon: 'mdi-home',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},  
                { title: 'Subscriptions', icon: 'mdi-webpack',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},
                { title: 'Quick Builder', icon: 'mdi-application-settings',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},
                { title: 'Libraries', icon: 'mdi-library-shelves',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},   
                { title: 'Web Forms', icon: 'mdi-form-select',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,Accepted: ['Web_Forms']},               
                 { title: 'Settings', icon: 'mdi-cogs',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},
                 { title: 'Members', icon: 'mdi-account',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},
                 { title: 'Security', icon: 'mdi-server-security',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},
                 { title: 'Statistics', icon: 'mdi-chart-box',Menu: false,Method: 'SelectSuiteBuilderNavItem',PropisSelf: true,},
          ],
          SuiteBuilderNavItem: '',
        mustreadnotices: [],
        noticestep: 0,
        PromoServiceDialog: false,
        promostep: 0,
        MustReadNoticeDialog: false,
          ShowInactiveServices: false,
            SocialDashItems: [
            //{ title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
            //{ title: 'Group Monetization',Pluginid: 'Group_Monetization', icon: 'mdi-cash-register',ComponentName: 'GroupDirectoriesDashboard', MonetizationOfficer: true },
            { name: 'My Events',Color:'blue', title: 'Events',Pluginid: 'Site_Events', icon: 'mdi-calendar-star',ComponentName: 'MyEvents',sitecollectionname: 'siteevents',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupevents',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { name: 'My Blogs',Color:'purple', title: 'Blogs',Pluginid: 'Site_Blogs', icon: 'mdi-newspaper',ComponentName: 'MyBlogs',sitecollectionname: 'siteblogs',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupblogs',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { name: 'My Articles',Color:'pink', title: 'Articles',Pluginid: 'Site_Articles', icon: 'mdi-newspaper',ComponentName: 'MyArticles',sitecollectionname: 'sitearticles',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouparticles',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { name: 'My Classifieds',Color:'green accent-4', title: 'Classifieds',Pluginid: 'Classifieds', icon: 'mdi-monitor-star',ComponentName: 'MyClassifieds',sitecollectionname: 'classifieds',sitecolfield: 'Created_Byid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupclassifieds',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { name: 'My Forums',Color:'orange', title: 'Forums',Pluginid: 'Site_Forums', icon: 'mdi-forum',ComponentName: 'MyForumThreads',sitecollectionname: 'siteforumdiscussions',sitecolfield: 'Creatorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupforumdiscussions',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { name: 'My Meetups',Color:'lime accent-4', title: 'Meetups',Pluginid: 'Site_Meetups', icon: 'mdi-human-greeting-proximity',ComponentName: 'MyMeetups',sitecollectionname: 'sitemeetups',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupmeetups',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { name: 'My Notices',Color:'red', title: 'Notices',Pluginid: 'Digital_NoticeBoard', icon: 'mdi-billboard',ComponentName: 'MyNoticeBoardPosts',sitecollectionname: 'notices',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupnotices',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { name: 'My Polls',Color:'slateblue', title: 'Polls',Pluginid: 'Site_Polls', icon: 'mdi-vote',ComponentName: 'MyPolls',sitecollectionname: 'sitepolls',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouppolls',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            ],
             MyFeautredMemberNavItem: {
                            name: 'My Feature',
                            icon: 'mdi-account-star',
                            Color: 'goldgradbganim'
                        },
            MyMarketplaceTemplatesNavItem: {
                            name: 'Market Templates',
                            icon: 'mdi-book-open-page-variant',
                            Color: 'brown'
                        },
            MySuiteNavItem: {
                            name: 'My Sites',
                            icon: 'mdi-folder-home-outline',
                            Color: 'BuilderGradient'
                        },
            DefaultServices: [
                // {Icon: 'mdi-ticket-confirmation',Name: 'Support Tickets',Count: 1},
                // {Icon: 'mdi-message',Name: 'Liaison',Count: 1},
                // should messages be revmoed? should we rather do "liaison" focus here?
                // {Icon: 'mdi-bank',Name: 'Accounts',Count: 1},
                // {Icon: 'mdi-message',Name: 'Support Tickets',Count: 1}                     
                //what about liaison now? 
                {
                    name: 'My Profile',
                    icon: 'mdi-star-face'
                },
                // {
                //     name: 'Support Tickets',
                //     icon: 'mdi-ticket-confirmation',
                //     Pluginid: 'System_Tickets'
                // },
                //still feeling like the absolute simplest resolve would be liaison right here though
                // {
                //     name: 'Liaison',
                //     icon: 'mdi-message'
                // },
                
                     {
                    name: 'My Company',
                    icon: 'mdi-badge-account-horizontal'
                },   
                {
                    name: 'My Accounts',
                    icon: 'mdi-bank'
                },
            ],
          UserDashboards: [],
          rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
          //PageBGGradient: 'background: linear-gradient(150deg, rgba(46,95,144,0.6) 0%, rgba(192,77,139,0.6) 35%, rgba(238,147,126,0.6) 71%);',
          NewLink: '',
          slice: 4,
          UploadingFileProgress: 0,
          LiaisonUploadFile: '',
          OpenFormComponent: false,
          ActiveForm: '',
          ActiveComment: '',
            MyVehicles: [],
            events: [],
      input: '',
      nonce: 0,
            VehicleApplications: [
            ],
            ActiveRecord: '',
            // Activesession: {name: 'My Profile'},
            Activesession: '',
            VehiclesLinksGroupsearch: '',
      VehiclesWikiViewType: '',
      VehiclesWikiViewbyOptions: [],
            VehiclesWikiMode: false,
            storedrawernav: false,
            storedrawerdialog: false,
            ActiveProductFilter: '',
            items: [
                {
                    name: 'My Account',
                    ID: 1001,
                    Children: [
                        {
                            name: 'My Profile',
                            icon: 'mdi-star-face'
                        },
                        {
                            name: 'My Company',
                            icon: 'mdi-badge-account-horizontal'
                        },                        
                        {
                            name: 'My Accounts',
                            icon: 'mdi-bank'
                        }
                    ]
                },
                {
                  name: 'Schedules',
                  ID: 1005,
                  Children: [],
                  icon: 'mdi-calendar',
                  IsSchedule: true,
                },
                {
                  name: 'My Records',
                  ID: 1006,
                  Children: [],
                  icon: 'mdi-file-table',
                  IsTable: true,
                }
                
                // {
                //     name: 'Notifications',
                //     ID: 1004,
                //     Children: []
                // },
                
            ],
            SocialTabElements: [
                 {
            Color: 'orange',
                BoxTransparency: 'transparent',
                Elevation: 0,
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-center',
            RowTransparency: 'transparent',
            Name: 'Groups Feed',
            Type: 'Groups Feed',
            SocialComponent: true,
            SocialCompPluginid: 'Site_Groups',
            DisplayName: 'Groups Feed',
            HasAlignment: true,
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-language-html5',
            Title: 'Groups',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus.`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:9,
            FlexLarge: 9,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },
            
            {
                Color: 'pink',
                BoxTransparency: 'transparent',
                Elevation: 0,
                StaticVariables: [],
            Category: 'Feeds',
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-start',
            RowTransparency: 'transparent',
            SocialComponent: true,
            SocialCompPluginid: 'Site_Articles',
            SocialCompPublicBoolean: 'Site_Articles_Public',
            GroupParamsProp: 'ArticlesPrivacy',
            PublicPages: false,
            Name: 'Site Articles',
            Type: 'Site Articles',
            DisplayName: 'Site Articles',
            OverlayGradient: 'background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);',
            HasAlignment: true,
            SliderView: false,
            Excerpt: 250,
            Slice: 6,
            TypeCategories: [],
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-email-newsletter',
            Title: 'Articles',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus.`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:9,
            FlexLarge: 9,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },
            {
                Color: 'purple',
                BoxTransparency: 'transparent',
                Elevation: 0,
                StaticVariables: [],
            Category: 'Feeds',
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-start',
            RowTransparency: 'transparent',
            SocialComponent: true,
            SocialCompPluginid: 'Site_Blogs',
            SocialCompPublicBoolean: 'Site_Blogs_Public',
            GroupParamsProp: 'BlogsPrivacy',
            Name: 'Site Blogs',
            Type: 'Site Blogs',
            DisplayName: 'Site Blogs',
            HasAlignment: true,
            SliderView: false,
            Excerpt: 250,
            Slice: 6,
            TypeCategories: [],
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-typewriter',
            Title: 'Blogs',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus.`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:9,
            FlexLarge: 9,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },
            {
                Color: 'blue',
                BoxTransparency: 'transparent',
                Elevation: 0,
                StaticVariables: [],
            Category: 'Feeds',
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-center',
            RowTransparency: 'transparent',
            SocialComponent: true,
            SocialCompPluginid: 'Site_Events',
            SocialCompPublicBoolean: 'Site_Events_Public',            
            GroupParamsProp: 'EventsPrivacy',
            PublicPages: false,
            Name: 'Site Events',
            Type: 'Site Events',
            DisplayName: 'Site Events',
            HasAlignment: false,
            FeedView: true,
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-calendar-star',
            Title: 'Events',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus.`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:9,
            FlexLarge: 9,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },
            {
                Color: 'lime',
                BoxTransparency: 'transparent',
                Elevation: 0,
                StaticVariables: [],
            Category: 'Feeds',
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-start',
            RowTransparency: 'transparent',
            SocialComponent: true,
            SocialCompPluginid: 'Site_Meetups',
            SocialCompPublicBoolean: 'Site_Meetups_Public',            
            GroupParamsProp: 'MeetupsPrivacy',
            PublicPages: false,
            Name: 'Site Meetups',
            Type: 'Site Meetups',
            DisplayName: 'Site Meetups',
            HasAlignment: true,
            SliderView: false,
            Excerpt: 250,
            Slice: 6,
            TypeCategories: [],
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-human-greeting-proximity',
            Title: 'Meetups',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus.`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:9,
            FlexLarge: 9,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },
            ]
        }
    },
    computed:{      
      MyJobApplications(){
        return this.$store.state.MyJobApplications
      },
      ParallaxActive(){
        return this.MobileView ? false : !this.Activesession || this.Activesession && this.Activesession.name !== 'Store'
      },
      UserFavorites(){
      return this.userLoggedIn && this.userLoggedIn.FavoriteLinks ? this.userLoggedIn.FavoriteLinks : []
    },
      NavUserFavorites(){
      return this.UserFavorites.map(fav => {
        let favobj = {
          name: fav.DisplayName,
          icon: fav.Icon,
          Path: fav.Path,
          IsRoute: true,
          RouteBlank: false
        }
        if(fav.Color){
          favobj.Color = fav.Color
        }
        return favobj
      })
    },
      SiteContentAdmin(){
        return this.userLoggedIn.SiteContentAdmin
      },
      BuilderView(){
        return this.$store.state.BuilderView
      },
      UsercanAdministrateSite(){
        return this.userLoggedIn.id === this.System.Ownerid || this.userLoggedIn.id === this.System.Adminid || this.userIsAdmin
      },
      AdminToggleActive(){
        return !this.Activesession && this.userIsAdmin
      },
      RegisteredApps(){
            return this.$store.state.AppsDataBase
        },
      ComputedSuiteItems(){
        return this.SuiteItems.concat(this.ModulePluginsMenuItems)
      },
      ModulePluginsMenuItems(){
            return this.ModulePlugins.map(plug => {
                let plugobj = Object.assign({},plug)
                plugobj.title = plug.Name
                plugobj.PropisSelf = true
                plugobj.Method = 'SelectSuiteBuilderNavItem'
                return plugobj
            }).filter(plug=>{
                // if(plug.id === 'Database_Builder'){
                //     return this.RegisteredApps.length > 0
                // }
                // else{
                //     return plug
                // }
                return plug
            })
        },
      ModulePlugins(){
          return this.RAPluginsMatched(['Module'])
        },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
      RAAdmin(){
          return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
      },
      RAUserObj(){
          return this.$store.state.RAUserObj
      },
      MatchedPromotedActiveServices(){
        //this.mustreadnotices
        //console.log(this.SystemPromotedServices,this.AllAvailableServices)
        return this.SystemPromotedServices.filter(ser => {
          let active = this.AllAvailableServices.find(obj => obj.name === ser.name)
          if(!active && ser.name === 'Web Apps'){
              active = this.AllAvailableServices.find(obj => obj.name === 'My Sites')
          }
          return active
        })
      },
      SystemPromotedServices(){
        return this.System.ActiveServices ? this.System.ActiveServices.filter(ser => {
          return ser.IsPromoted
        }) : []
      },
      IsPrimarySite(){
        return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
      },
      UserWebAppAccounts(){
        return this.UserBillingAccounts ? this.UserBillingAccounts.filter(acct => {
          return acct.Account_Type && acct.Account_Type.Name === 'Site Account'
        }) : []
      },
      UserMarketAccount(){
        return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
        },
        ComputedUserSites(){
          return this.UserSites.map(site=> {
            let siteobj = Object.assign({},site)
            if(!siteobj.Icon){
              siteobj.Icon = 'mdi-folder-home'
            }
            return siteobj
          })
        },
        UserSites(){
          return this.IsPrimarySite ? this.RelatedSuiteTemplates.filter(temp => {
            //console.log('userLoggedIn.Sites',this.userLoggedIn.Sites)
            return this.userLoggedIn.Sites.find(obj => obj.id === temp.id)
          }) : this.userLoggedIn.Sites
        },
      ComputedUserApps(){
        return this.userIsAdmin ? 
        this.UserApps.concat([
            {Name: 'Add App',Icon: 'mdi-plus', Color: '#FF000055'}
          ]) :
        this.UserApps
      },
      UserApps(){
        //console.log('this.AppsDataBase',this.AppsDataBase)
        return this.AppsDataBase.filter(item => {
          return this.userLoggedIn.Apps.includes(item.id) || this.userIsAdmin
          // && this.IsPrimarySite
        })
      },
      AppsDataBase(){
        //console.log('this.$store.state.AppsDataBase',this.$store.state.AppsDataBase)
            return this.$store.state.AppsDataBase
        },
      BATypeFilters(){
        //remember must be clear in order to NOT filter
        return this.Activesession ? this.Activesession.name === 'Org Directory' || this.Activesession.name === 'Social Groups' ? ['Group Account'] : 
        this.Activesession.name === 'Market Templates' || this.Activesession.name === 'Featured Member' ? ['Market Account'] : 
        this.Activesession.name === 'My Sites' ? ['Site Account'] : '' :''
      },
      LiaisonTopicItems(){
        return this.NotificationsNavItemChildren.map(child => {
          //console.log('child',child)
          child.Data = child.Data.map(record => {
            let recordobj = Object.assign({},record)
            recordobj.NotificationCount = this.UnreadNotifications.filter(not => {
              return not.clientrecords === record.id
            }).length
            return recordobj
          }) 
          child.NotificationCount = this.UnreadNotifications.filter(not => {
              return not.topicid === child.id
            }).length
          return child
        })
      },
      NotificationsNavItemChildren(){
        //crazy confusing but...Maybe we should get away from this structure?
        //this.Activesession = menuobj.Children.find(obj => obj.id === this.NotificationObj.ActiveSessionid)
        //this.ActiveRecord = this.Activesession.Data.find(obj => obj.id === this.NotificationObj.ActiveRecord)
        //Okay here is the deal...We don't even have liaison topics mind you. I have no F clue how any of this would work. 
        //I think wehen client lookup field is assigned we get LiaisonTopics, that's one...
        //the ClientStoreOrders really is how we identify the orders we use it in BillingAccounts view, which makes sense.
        //But the ONLY think we got LiaisonTopics on...well..really right now there is nothing. not on test case. So there is no way to test this
        return this.NotificationsNavItem? this.NotificationsNavItem.Children : []
      },
      UnreadLiaisonTopicNotifications(){
        return this.UnreadNotifications.filter(not => {
          return this.LiaisonTopicItems.find(obj => obj.id === not.topicid)
        })
      },
      UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
        NotificationsNavItem(){
            return this.items.find(obj => obj.ID === 1004)
            //actually this.items.find(obj => obj.ID === 1004).Children
            //even more I think we need a special box fox this that looks kick ass. but we need a working verison to know for sure
        },
        //ubtouched is 1004 Notifications also inclusive of ClientStoreOrders...and then 1002 that adds custom this.System.GuestNavbarItems
        ActiveSessionComponent() {
            return this.Activesession.ComponentName
        },
        ComputedDefaultServices(){
            //untouched ? system tickets, I don't quite know about it...
            //also my accounts now officially look KAK
            return this.DefaultServices.concat(this.LiaisonTopicItems).filter(child => {
              if(this.UserCompaniesActive && this.UserisCompanyAdmin){
                return child
              }
              else{
                return child.name !== 'My Company'
              }
            }).map(item => {
                let itemobj = Object.assign({},item)
                if(itemobj.title === 'Liaison'){
                    itemobj = this.NotificationsNavItem
                }
                 if(typeof item.NotificationCount !== 'number'){
                  itemobj.NotificationCount = 0
                }
                return itemobj
            })
        },
        CustomScheduledTables(){
            return this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name === 'Booking Schedule' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsSchedule = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase()
            return entity
          })
        },
        CustomNonScheduleTables(){
            return this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name !== 'Booking Schedule' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsTable = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase()
            // ,cancelling this right?
            // entity.RouteQueries = [
            //     {Prop: 'TableType', Value: 'Standard'},
            //     {Prop: 'TableName', Value: entity.RouteName }
            //   ]
            return entity
          })
        },
        CustomTableItems(){
            //technically though we could split these up
            // 2. Custom Tables (per ent appendedfilters??) but like repair jobs, gym bookings, my rentals, cash loans etc.
        // BTW - these are  the "lists", dude that like process "cash loan" is ONLY gonna see...from his my accounts. maybe then a tennant...well TBD 
            return this.CustomScheduledTables.concat(this.CustomNonScheduleTables.filter(table => {
              return table.id !== 'Systems'
              //just for now, communication should eb handled via "suiteliaisonchannel" anyway right?
            }))
        },
        AvailableDirectories(){
            //this.Directories
            // 3. Available Directories (per avail dir)
            return this.StoredDefaultDirectories
            .map(dir => {
                let dirobj = Object.assign({},dir)
                dirobj.name = dir.Name
                if(dirobj.id === 'Featured_Members'){
                    dirobj.icon = 'mdi-card-account-details-star'
                    dirobj.Color = 'orange'
                }
                else if(dirobj.id === 'Templates'){
                    dirobj.icon = 'mdi-book-open-page-variant'
                    dirobj.Color = 'brown'
                }
                else if(dirobj.id === 'Vacancies'){
                    dirobj.icon = 'mdi-account-search'
                    dirobj.Color = 'orange'
                }
                else if(dirobj.id === 'Classifieds'){
                    dirobj.icon = 'mdi-monitor-star'
                    dirobj.Color = 'green accent-5'
                    // dirobj.Height = 300
                    // dirobj.Width = 300
                }
                else if(dirobj.id === 'Systems'){
                    dirobj.icon = 'mdi-folder-home'
                    dirobj.Color = 'purple accent-5'
                    // dirobj.Height = 300
                    // dirobj.Width = 300
                }
                return dirobj
            }).filter(item => {
              return item.id !== 'Featured_Members' || 
              item.id === 'Featured_Members' && this.AllowedBATypes.find(obj => obj.Name === 'Site Account') && this.FeaturedMemberActive || 
              item.id === 'Featured_Members' && this.userLoggedIn.IsWebDeveloper && this.FeaturedMemberActive || 
              item.id === 'Featured_Members' && this.userIsAdmin && this.FeaturedMemberActive
            }).filter(item => {
              return item.id !== 'Classifieds' || 
              item.id === 'Classifieds' && !this.ChannelRestricted('Classifieds') && this.ClassifiedsActive
            }).filter(item => {
              return item.id !== 'Systems' || 
              item.id === 'Systems' && this.WebAppPluginActive
            })
        },
        UserSiteModerator(){
            return this.SiteModerators.find(obj => obj.id === this.userLoggedIn.id) || this.userIsAdmin && this.IsPrimarySite
        },
        SocialModeratorChannels(){
            // 4.  SocialModerator Channels (per mod, per chan, this aimed towards creating and dashbaords)
            //create new so maybe "col" but then also dashboard, prefer dashboard
            //would this include "my groups"?
            return this.SocialDashItems.filter(socialcomp => {           
                if(socialcomp.Pluginid){
                  return this.PluginDataBase[socialcomp.Pluginid] && 
                  this.PluginDataBase[socialcomp.Pluginid].Active && 
                  this.userLoggedIn && this.userLoggedIn.ModerationPermissions && 
                  this.userLoggedIn.ModerationPermissions.includes(socialcomp.title)  ||
                  this.PluginDataBase[socialcomp.Pluginid] && 
                  this.PluginDataBase[socialcomp.Pluginid].Active && 
                  this.userIsAdmin
                  // && this.IsPrimarySite
                }
                else if(socialcomp.title === 'Notices'){
                    return this.userLoggedIn && this.userLoggedIn.ModerationPermissions && 
                    this.userLoggedIn.ModerationPermissions.includes(socialcomp.title)
                }
                else if(socialcomp.MonetizationOfficer){
                    return this.userLoggedIn.MonetizationOfficer || this.userIsAdmin && this.IsPrimarySite
                }
                else{
                    return socialcomp
                }
            })
        },
        SocialChannelsFeed(){
            // 5. Social Guest Channel (the "feed" version per chan permitted)
            return this.SystemSocialReady && this.CanSocial ? 
            this.SocialTabElements.filter(item => {
                return this.PluginDataBase[item.SocialCompPluginid] && this.PluginDataBase[item.SocialCompPluginid].Active
            }).map(item => {
                item.name = item.Title
                item.icon = item.Icon
                //console.log('item',item)
                return item
            }) : 
            []
            //Please do remember to remove items you are a moderator of, otherwise duplicate...right? or is it truly liks we said, dashboards only?
        },
        SpecialModeratorChannels(){
             // 6. Special moderator (suite templates/marketplacetemplates)
            //cannot be return [this.ComputedSuiteNavItem] ??
            let suitetemplates = this.WebAppBuilderActive && this.userLoggedIn.IsSiteCreator || 
            this.WebAppBuilderActive && this.userIsAdmin && this.IsPrimarySite?  
            [this.ComputedSuiteNavItem] : []
            let marketplacetemlates = this.MarketplaceTemplatesActive && this.userLoggedIn.IsWebDeveloper  
            || this.MarketplaceTemplatesActive && this.userIsAdmin && this.IsPrimarySite ? 
            [this.ComputedMarketTempaltesNavItem] : []
            return suitetemplates.concat(marketplacetemlates)
        },
        ClienttoClientChannels(){
            return []
            //We don't need this, it's only relevant if you can create My Sites which you can if you are client of WD type either way
            //return this.WebAppBuilderActive && this.RelatedSuiteTemplates.length > 0 ? [this.ComputedSuiteNavItem] : []
        },
        CustomDashboards(){
            //follow the trail on UserDashboards
            return this.UserDashboards.map(dash => {
                let dashboarditem = {
                      icon: 'mdi-desktop-mac-dashboard',
                      name: dash.DashboardName,
                      Path: '/CustomDashboard/'+dash.id,
                      target: 'self',
                      id: dash.id
                    }
                return dashboarditem
            })
        },
        EasyProductsActive(){
          return this.PluginDataBase.Easy_Products && this.PluginDataBase.Easy_Products.Active
        },
        VacanciesDirectoryActive(){
          return this.PluginDataBase.Vacancies && this.PluginDataBase.Vacancies.Active
        },
        AdvertDirectoryActive(){
          return this.PluginDataBase.Advert_Directory && this.PluginDataBase.Advert_Directory.Active
        },
        GroupDirectoriesActive(){
          return this.PluginDataBase.Group_Directories && this.PluginDataBase.Group_Directories.Active
        },        
        AccountTypes(){
            return this.$store.state.AccountTypes
        },
        AllowedBATypes(){
            return this.ActiveMemberGroup && this.ActiveMemberGroup.AllowedBATypes ? this.AccountTypes.filter(type => {
                return this.ActiveMemberGroup.AllowedBATypes.includes(type.Name)
            }) : []
        },
        GroupDirectoriesAllowed(){
          return this.userLoggedIn && this.GroupDirectoriesActive && this.AllowedBATypes.find(obj => obj.Name === 'Group Account')
        },
        ClassifiedsActive(){
          return this.PluginDataBase.Classifieds && this.PluginDataBase.Classifieds.Active
        },
        DirectoryListings(){
        let arr = [
                    {
                            name: 'My Adverts',
                            icon: 'mdi-monitor-star',
                            Color: 'green'
                        },
                        {
                            name: 'My Vacancies',
                            icon: 'mdi-account-search',
                            Color: 'blue'
                        },
                        {
                            name: 'My Job Applications',
                            icon: 'mdi-briefcase',
                            Color: 'cyan'
                        },
                    {
                        name: 'Featured Member',
                        icon: 'mdi-account-star',
                        Color: 'goldgradbg'
                    },
                    {
                        name: 'Org Directory',
                        icon: 'mdi-office-building',
                        Color: 'blue'
                    },
                    {
                        name: 'Classifieds',
                        icon: 'mdi-monitor-star',
                        Color: 'red'
                    }]
        return arr.filter(item => {
          return item.name !== 'My Vacancies' || item.name === 'My Vacancies' && this.VacanciesDirectoryActive && this.userLoggedIn.VacancyOfficerClient || 
          item.name === 'My Vacancies' && !this.UserisGuest
        }).filter(item => {
          return item.name !== 'My Job Applications' || item.name === 'My Job Applications' && this.VacanciesDirectoryActive
        })
        .filter(item => {
          return item.name !== 'My Adverts' || item.name === 'My Adverts' && this.AdvertDirectoryActive
        }).filter(item => {
          return item.name !== 'Featured Member' || item.name === 'Featured Member' && this.FeaturedMembersAllowed
        }).filter(item => {
          return item.name !== 'Org Directory' || item.name === 'Org Directory' && this.GroupDirectoriesAllowed
        }).filter(item => {
          return item.name !== 'Classifieds' || item.name === 'Classifieds' && this.ClassifiedsActive
        })
        },
        FeaturedMemberDefaultPage(){
          return this.$store.state.FeaturedMemberDefaultPage
        },
        FeaturedMemberActive(){
          //console.log('this.PluginDataBase',this.PluginDataBase)
            return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
        },
        FeaturedMembersAllowed(){
            return this.FeaturedMemberActive && this.userLoggedIn.IsFeaturedMember || this.FeaturedMemberActive && this.userIsAdmin
            // && this.userIsAdmin || this.FeaturedMemberActive && this.System && this.System.Featured_Members_Self_Manage
        },
        ActiveDashboards(){
            //untouched? seems like nothing?

            //1. Custom Dashboards 2.SocialModerator channels, My Listings as in Dir I champion or my featmember etc, 4.
            //need to get cracking...recompute from what would have been menu
        //      These Services are now either 
        // 6. Special moderator (suite templates/marketplacetemplates)
        // 7. special client (suite template etc)
            //return this.DefaultServices.concat(this.DefaultServices,this.DefaultServices,this.DefaultServices)
            return this.DirectoryListings.concat(this.SpecialModeratorChannels,this.CustomDashboards,this.SocialModeratorChannels).filter(item => {
                return this.Activesession && this.Activesession.SocialComponent ? item.SocialComponent : item
            })
        },
        AllAvailableServices(){
          return this.ActiveUserServices.concat(this.InactiveServices.map(ser => {
            let serobj = Object.assign({},ser)
            serobj.Color = 'grey'
            serobj.InActive = true
            return serobj
          }))
        },
        InactiveServices(){
          return this.MemberGroupAvailableServices.filter(ser => {
            return !this.UserActiveServices.find(obj => obj.name === ser.name)
          })
        },
        ComputedServices(){
          return this.ShowInactiveServices ? this.AllAvailableServices : this.ActiveUserServices
        },
        ActiveUserServices(){
          //okay just check if MemberGroupAvailableServices has anything user does not have then add the "Add Service" item which we catch on ActivateSession
          return this.InactiveServices.length > 0 ? this.UserActiveServices.concat([
            {name: 'Add Service',icon: 'mdi-plus', Color: '#FF000000'},
          ]) : this.UserActiveServices
        },
        UserActiveServices(){
          //basically replacing ActiveDashboards and when we add one, we push it. so...?
          //also could refer to MemberGroupDefaultServices here and add any missing, maybe the best way - remember DEfaultServices and AvilableServices differ
          return this.userIsAdmin ? this.ActiveDashboards : this.userLoggedIn.Services.map(item => {
            //console.log('item',item)
              let itemobj = Object.assign({},item)
              
              let socialitem = this.SocialDashItems.find(obj => obj.name === itemobj.name)
              if(socialitem){
                itemobj = socialitem
              }
              if(typeof item.NotificationCount !== 'number'){
                itemobj.NotificationCount = 0
              }
              return itemobj
            })
        },
        ActiveServices(){
            //untouched? Custom Entities also done so...nothing?

            //need to get cracking...recompute from what would have been menu
        //      These Services are now either 
        // 6. Special moderator (suite templates/marketplacetemplates)
        // 7. special client (suite template etc)
            //return this.DefaultServices.concat(this.DefaultServices,this.DefaultServices,this.DefaultServices)
            return this.CustomTableItems.concat(this.ClienttoClientChannels,this.AvailableDirectories,this.SocialChannelsFeed).filter(item => {
                return this.Activesession && this.Activesession.SocialComponent ? item.SocialComponent : item
            }).map(item => {
              let itemobj = Object.assign({},item)
              if(typeof item.NotificationCount !== 'number'){
                itemobj.NotificationCount = 0
              }
              return itemobj
            })
        },
        LargeView(){
          return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg
        },
        ServiceBlockSize(){
            return this.MobileView? 150 : this.MiniView ? 150 : this.$vuetify.breakpoint.md ? 150 : this.$vuetify.breakpoint.lg ? 150 : 150
        },
        DefaultServiceCols(){
            return this.MobileView? 12 : this.MiniView ? 6 : 3
        },
        ComputedMarketTempaltesNavItem(){
        let obj = Object.assign({},this.MyMarketplaceTemplatesNavItem)
        let notifications = this.Notifications.filter(item => {
          return item.topicid === 'Marketplace_Templates' && !item.Read
        })        
        obj.NotificationCount = notifications.length
        if(obj.NotifyColor> 0){
          obj.NotifyColor = 'red'
        }
        else{
          obj.NotifyColor = 'grey'
        }
        return obj
      },
      ComputedMyFeautredMemberNavItem(){
        let obj = Object.assign({},this.MyFeautredMemberNavItem)
        let notifications = this.Notifications.filter(item => {
          return item.topicid === 'Featured_Member' && !item.Read
        })        
        obj.NotificationCount = notifications.length
        if(obj.NotifyColor> 0){
          obj.NotifyColor = 'red'
        }
        else{
          obj.NotifyColor = 'grey'
        }
        return obj
      },
        ComputedSuiteNavItem(){
        let obj = Object.assign({},this.MySuiteNavItem)
        //obj.Height = 300
        // obj.Width = 400
        let notifications = this.Notifications.filter(item => {
          return item.topicid === 'Suite_Templates' && !item.Read
        })        
        obj.NotificationCount = notifications.length
        if(obj.NotifyColor> 0){
          obj.NotifyColor = 'red'
        }
        else{
          obj.NotifyColor = 'grey'
        }
        return obj
      },
      MarketplaceTemplatesActive(){
        return this.PluginDataBase && this.PluginDataBase.Marketplace_Templates && this.PluginDataBase.Marketplace_Templates.Active
      },
        WebAppBuilderActive(){
        return this.PluginDataBase && this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active && this.RelatedSuiteTemplates.length > 0 || !this.IsPrimarySite
      },
      WebAppPluginActive(){
        return this.PluginDataBase && this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active
      },
        MarketplaceTemplates(){
        return this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        RelatedSuiteTemplates(){
        return this.MySuiteTemplates.concat(this.ClientSuiteTemplates) 
        },
        SuiteTemplates(){
        return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
        },
        OtherSuiteTemplates(){
        return this.$store.state.OtherSuiteTemplates
        },
        MySuiteTemplates(){
        return this.$store.state.MySuiteTemplates
        },
        ClientSuiteTemplates(){
        return this.$store.state.ClientSuiteTemplates
        },
        MemberGroupAvailableServices(){
          return this.ActiveMemberGroup && this.ActiveMemberGroup.AvailableServices ? this.ActiveMemberGroup.AvailableServices : []
        },
        MemberGroupDefaultServices(){
          return this.ActiveMemberGroup && this.ActiveMemberGroup.DefaultServices ? this.ActiveMemberGroup.DefaultServices : []
        },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
      SystemSocialReady(){
        return this.PluginDataBase && this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active && this.System.Social_Landing_Page
      },
      CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial || !this.UserisGuest && !this.ActiveMemberGroup || 
        this.userIsAdmin
      },
      SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      MobileView(){
            return this.$vuetify.breakpoint.xs
        },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ClientStoreOrders(){
        let menuitem = this.items.find(obj => obj.ID === 1004)
        return menuitem.Children.map(Activesession => {
          return Activesession
        }).flat().filter(Activesession => {
          return Activesession.Entity_Type && Activesession.Entity_Type.Name === 'Store Order'
        }).map(channel => {
          return channel.Data
        }).flat()
      },
      UsersArray(){
        //console.log(this.$store.state.UsersArray,this.$store.state.UsersArray,this.$store.state)
        return this.$store.state.UsersArray
      },
      NavStyling(){

      if(this.System.Guest_SideBar_IMG && this.System.Guest_SideBar_Gradient){
                return { backgroundImage: this.System.Guest_SideBar_Gradient.split('background: ')[1].split(';')[0]+`,url('`+this.System.Guest_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.Guest_SideBar_IMG && !this.System.Guest_SideBar_Gradient){
                return { backgroundImage: `url('`+this.System.Guest_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.Guest_SideBar_Gradient){
                return { backgroundImage: this.System.Guest_SideBar_Gradient.split('background: ')[1].split(';')[0]}
            }
            else if(this.PageBGColor){
                return { backgroundColor: this.PageBGColor+ `!important`}
            }
            else{
                return ''
            }
    },
      ScheduleEntityComponent(){
        if(this.Activesession && this.Activesession.IsSchedule){			
        return () => import(`@/views/${this.Activesession.name.split('_').join('')}.vue`);			
      }
      },
      UserBillingAccounts(){
        return this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
      UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
      MyCompany(){
      return this.$store.state.MyCompany
    },
      UserisCompanyAdmin(){
        return this.$store.state.UserisCompanyAdmin
      },
      Computeditems(){
        return this.items
        .map(item => {
          if(item.name === 'My Account'){
            item.Children = item.Children.filter(child => {
              if(this.UserCompaniesActive && this.UserisCompanyAdmin){
                return child
              }
              else{
                return child.name !== 'My Company'
              }
            })
          }
          return item
        })
        .map(item => {
          if(item.ID === 1001){
            if(!this.UserBillingAccounts){
               item.Children = item.Children.filter(child => {
                return child.Name !== 'My Accounts'
              })
            }
            else{
              // item.Children = item.Children.map(child => {
              //   if(child.Name === 'My Accounts'){
              //    child.Data = this.UserBillingAccounts 
              //   }
              //   return child
                
              // })
            }
          }
          if(item.ID === 1005){
            item.Children = this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name === 'Booking Schedule' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsSchedule = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase()
            return entity
          })
          }
          else if(item.ID === 1006){
            item.Children = this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name !== 'Booking Schedule' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsTable = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase(),
            entity.RouteQueries = [
                {Prop: 'TableType', Value: 'Standard'},
                {Prop: 'TableName', Value: entity.RouteName }
              ]
            return entity
          })
          }
          else if(item.ID === 1002){
            item.Children = this.System.GuestNavbarItems.map(item => {
              let childobj ={
                name: item.title,
                icon: item.icon,
                IsPage: true,
                Path: item.Path,
              }
              return childobj
            })
          }
          
            item.Children = item.Children.map(child => {
                //console.log(child.Data)
              if(child.Data){
               child.Data = child.Data.map(record => {
                 if(record.ClientLiaison){
                   record.ClientLiaison = record.ClientLiaison.map(liase => {
                    liase.createdon = this.TimestampFormatterSTRING(liase.Created_On.toDate())
                    liase.TimestampSort = this.DateFormatterwithTime(liase.createdon)                    
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                 }                  
                  return record
                }) 
              }           
            return child
          })
          return item
        }).filter(item => {
          return item.Children.length > 0
        })
      },
      timeline () {
        return this.events.slice().reverse()
      },
        userLoggedIn () {
      return this.$store.getters.user
    },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
        ProductsClassesNEW(){
            return this.StoreProductsEntities
            .filter(entity => {
                return entity.HierarchyField
            })
            .map(entity => {
                let HierarchyField = entity.AllFields.find(obj => obj.id === entity.HierarchyField.id)
                 if(HierarchyField && HierarchyField.Levels && HierarchyField.Options){
                let array = JSON.parse(JSON.stringify(HierarchyField.Options))      
                return array
                .map(option => {
                    //console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    id: option.ID,
                    EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                })
                
            }
            }).flat()  
        },        
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
      PromoServiceDialog: {
        handler: function(newvalue, oldvalue) {
          if(newvalue !== oldvalue){
            this.promostep = 0
          }
        }
      },
      MatchedPromotedActiveServices: {
        handler: function(newvalue, oldvalue) {
            if(oldvalue !== newvalue && newvalue.length > 0){
              this.CheckForServicePromo()
            }
        },deep: true
        },
        NotificationObj (value) {
            if (value) {
                this.CheckNotificationObj()
            }
        },
        Activesession: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue && !newvalue){
                  this.CheckForServicePromo()
                }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateSession','DeactivateActivesession','SelectSuiteBuilderNavItem','PrepareSelectSuiteBuilderNavItem']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
        
    },
    created(){
      this.ScrolltoTop()
      //console.log(this.$store.state,this.UsersArray)
      if(!this.IsPrimarySite){
        //is user admin or owner?
        if(this.UsercanAdministrateSite){
          this.ActivateSiteBuilder()
        }
      }
      this.CheckForServicePromo()
      let colref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').where('PublishStatus','==','Published (Internal)').where('MustRead','==',true)
      if(this.$store.state.UserisGuest){
          colref = colref.where('GuestsIncluded','==',true)
      }
      //this.GetMustReadNotices(colref)
      this.$store.commit('setBuilderView','') 
      this.GetAllUserDashboards()
      this.$emit('IntranetViewToggle',false)
      if(!this.MiniView){
        this.storedrawernav = true
      }
      if(this.NotificationObj){
           this.CheckNotificationObj()
        }
      if(this.System.GuestNavbarItems){
        let pagesobj = {
              name: 'Pages',
              ID: 1002,
              Children: []
          }
        this.items.push(pagesobj)
      }
      // console.log('userLoggedIn',this.userLoggedIn)
      this.GetClientTopics()
      //this.GetClientWikiTabs()
      // this.GetMyVehicles()
    },
    methods: {
      ActivateSiteListing(){
        this.Activesession = {name: 'Site Listing'}        
      },
      ActivateAdvertDirectory(){
        this.Activesession = {name: 'Advert Directory'}        
      },
      ActivateSiteStore(){
        this.Activesession = {name: 'Store'}        
      },
      ActivateSiteBuilder(){
        //this.PrepareSelectSuiteBuilderNavItem('Quick Builder')
      },
      PickPlugin(SelectedPlugin){
            //console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        // let raplugins = this.$store.state.RAPluginPackages
        // let pluginpackages = raplugins.filter(pack => {
        //     let packplugs = pack.Plugins.map(plug => {
        //         return plug.id
        //     })
        //     return packplugs.includes(SelectedPlugin.id)
        // })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          let pluginpackages = [pluginpack]
          //console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
        },
      RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        //console.log(this.RAPlugins,categorymatches)
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          //console.log(plug.id,plugcat,finalcategories)
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        })
      },
      DeactivateSuiteBuilderNavItem(){
        this.$store.commit('setBuilderView','')
        this.SuiteBuilderNavItem = ''
      },
      PrepareSelectSuiteBuilderNavItem(name){
        let item = this.ComputedSuiteItems.find(obj => obj.title === name)    
        //console.log(name,item,this.ComputedSuiteItems)    
        this.$store.commit('setBuilderView',{Name: 'Suite Builder'})
        this.SelectSuiteBuilderNavItem(item)
      },
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      SelectSuiteBuilderNavItem(item){
        //console.log(item)
        if(item.title === 'Website Builder'){
          // this.DeactivateSuiteBuilderNavItem()
          // this.NavigateWebsiteBuilder()
          this.SuiteBuilderNavItem = item
        }
        else{
        this.SuiteBuilderNavItem = item
        }
        this.ScrolltoTop()
      },
      MemberGroupAllow(channelname){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes(channelname) || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(channelname){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow(channelname) || this.ActiveMemberGroup && !this.MemberGroupAllow(channelname)
      },
      ReplicatetoClientSuite(){
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','ReplicatetoClientSuite')
      },
      ActivateSuiteTemplateInstallerDialog(){
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','ActivateSuiteTemplateInstallerDialog')
      },
      NavigateWebsiteBuilder(){
        let relevantroute = ''
        relevantroute = this.SitePages.find(obj => obj.PublishType === 'Public')
        if(this.System.Public_Landing_Page){
          relevantroute = this.SitePages.find(obj => obj.Name === this.System.Public_Landing_Page.split('/').join('').split('-').join(' '))
        }
        if(relevantroute){
          this.$router.push('/PageEditor/'+relevantroute.id)
        }
        else{
          //build site dialog
          this.$store.commit('SetSocialItemInteractMethodProp','Public') 
          this.$store.commit('SetSocialItemInteractMethod','ToggleNewPageDialog')
        }
      },
      CheckForServicePromo(){
        if(this.MatchedPromotedActiveServices.length > 0 && !this.Activesession){
          this.PromoServiceDialog = true
        }
      },
      ActivateServicePromoMethod(ser){
        // console.log(ser)
        this.PromoServiceDialog = false
        let active = this.ActiveUserServices.find(obj => obj.name === ser.name)
        if(!active && ser.name === 'Web Apps'){
            active = this.ActiveUserServices.find(obj => obj.name === 'My Sites')
        }
        if(active){
        this.ActivateSession(active)
        }
        else{
          this.ActivateUserService(ser)
        }
      },
      GetMustReadNotices(colref) {
           colref.onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(noticeobj.id).collection('responses').doc(this.userLoggedIn.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead || noticeobj.MustConfirm && !noticeobj.UserConfirmed){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                      
                  })
                  
                
              }  
              

            })
          })

          },
      ToggleNewAppDialog(){
        this.$store.commit('setBuilderView',{Name: 'Suite Builder'})
        //console.log('this.$store.state.BuilderView',this.$store.state.BuilderView)
        setTimeout(() => {
         this.$store.commit('SetSocialItemInteractMethod','ToggleNewAppDialog')
        //console.log('should toggle app dial by now') 
        }, 500);
        
      },
      ConfirmToggleSite(site){
        if(this.$store.state.ActiveSuiteid === site.id){
          confirm('This will navigate away from Site '+site.Name+' to the main Site. Are you sure?') && this.ActivateSite(process.env.VUE_APP_RA_SYSTEM_ID)
        }
        else{
          confirm('You will no move away from '+this.System.Name+' and navigate to '+site.Name+'. Are you sure?') && this.ActivateSite(site.id,site)
        }
        
      },
      ActivateSite(siteid,site){
        this.$store.commit('setActiveSuiteid',siteid)
      },
      ActivateUserService(ser){
        let newser = this.InactiveServices.find(obj => obj.name === ser.name)
        this.userLoggedIn.Services.push(newser)
        setTimeout(() => {
        let elmnt = document.getElementById(ser.name)
        elmnt.classList.add('actionbounce')
        setTimeout(() => {
            elmnt.classList.remove('actionbounce')
        }, 1500);
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).update({
            Services: this.userLoggedIn.Services
          })
        }, 300);        
        //really need to save it but okay
      },
      GetAllUserDashboards(){
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
    },
    GetUserDashboards(query){
      let dashboardsitem = this.items.find(obj => obj.ID === 1001)
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let dashboardobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            this.UserDashboards.push(dashboardobj)
            let dashboarditem = {
                      icon: 'mdi-desktop-mac-dashboard',
                      name: dashboardobj.DashboardName,
                      Path: '/CustomDashboard/'+dashboardobj.id,
                      target: 'self',
                      id: dashboardobj.id
                    }
                    let oncheck = dashboardsitem.Children.find(obj => obj.id === dashboarditem.id)
                    if(!oncheck){
                      dashboardsitem.Children.push(dashboarditem)
                    }
          }
        })
      })
    },
      IntranetViewToggle(boolean1,boolean2){
        this.$emit('IntranetViewToggle',boolean1,boolean2)
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      RouteToItem(route){
        this.Activesession = ''
        this.ActiveRecord = ''
        this.$router.push(route)
      },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },
      GetShortLivedURL(item){
        //console.log(item)
        const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          //console.log(result)
          item.TokenURL = result.data[0]
          setTimeout(() => {
                item.TokenURL = ''
                let url = item.fileurl
                item.fileurl = ''
                item.fileurl = url
          }, 120000);
          let url = item.fileurl
          item.fileurl = ''
          item.fileurl = url
        })
        },
      CheckNotificationObj(){
        let menuobj = this.Computeditems.find(obj => obj.ID === 1004)
        //console.log(menuobj)
        this.Activesession = menuobj.Children.find(obj => obj.id === this.NotificationObj.ActiveSessionid)
        this.ActiveRecord = this.Activesession.Data.find(obj => obj.id === this.NotificationObj.ActiveRecord)
        //ActivateActiveRecord
        console.log(this.ActiveRecord,this.ActiveRecord.Notifications,this.NotificationObj)
        if(this.ActiveRecord.Notifications){
          this.ActiveRecord.Notifications.map(not => {
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).update({
                Read: true
              })
            })        }
        
        },
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean)
      },
      link(url,record) {
        this.input = `See this link <a href="`+url+`" target="_blank">here</a>`
        document.execCommand("createLink", false, url);
        record.TimelineDialog = false
        record.TimelineDialog = true
        this.NewLink = ''
        },
      ToTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      FileInput(elid){
            let elmnt = document.getElementById(elid)
            elmnt.click()
        },
      onFileselected(event){
        this.LiaisonUploadFile = event.target.files[0]
        this.LiaisonUploadFile.FileModifiedDate = new Date(this.LiaisonUploadFile.lastModified)
        setTimeout(() => {
          let newinput = 'Please see attached file - '+this.LiaisonUploadFile.name 
          if(this.input){
            newinput = '. '+newinput
          }
         this.input = this.input+newinput  
         //console.log(this.input) 
        }, 300);
        
        
        },
      SaveNewcomms(NewComms,record){
        NewComms.recipientid = record.Owner.id
        //console.log(NewComms)
           db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').add(NewComms).then(doc => {
            //console.log('created new doc '+doc.id)

            const NewNotification = {
                ActiveProcessNumber: record.ActiveProcessNumber,
                itemidprop: 'clientrecords',
                itemid: NewComms.relatedid,
                Type: 'Feedback',
                topicid: NewComms.topicid,
                Header: 'Response on '+record.name,
                Content: NewComms.description,
                CreatorFullName: NewComms.Created_By.Full_Name,
                CreatorID: NewComms.Created_By.id,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Read: false,
                IsLiaison: true,
                Liaisonid: doc.id,
                Path: '/MyDashboard',
                Message: NewComms.Created_By.Full_Name+' has responded on '+record.name,
                }
            this.$store.dispatch('createNotification',NewNotification)
            this.LiaisonUploadFile = ''
            }) 
      },
      UploadFileandAttahctoNewComms(NewComms,record){
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        let filename = newdocid+'_'+this.LiaisonUploadFile.name
        //console.log(filename)
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = NewComms.relatedtype.split(' ').join('')+'/'+NewComms.relatedid+'/ClientLiaison/'+ filename
        NewComms.Uploadfilename = this.LiaisonUploadFile.name
        var storageRef = firebase.storage().ref(path)
        NewComms.StorageRef = path
        NewComms.newdocid = newdocid
        NewComms.FileType = this.LiaisonUploadFile.type
        NewComms.lastModifiedDate = this.LiaisonUploadFile.FileModifiedDate
          var uploadTask = storageRef.put(this.LiaisonUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.LiaisonUploadFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
                NewComms.fileurl = downloadURL
                if(NewComms.FileType.includes('image')){
                  vm.$emit('ActivateProcessing',true)
                  vm.PrepareThumbnail(NewComms.Uploadfilename,vm.LiaisonUploadFile,NewComms.StorageRef).then(thumbresult => {
                        NewComms.ThumbURL = thumbresult
                        vm.$emit('ActivateProcessing',false)
                        vm.SaveNewcomms(NewComms,record)
                    })
                }
                else{
                  vm.SaveNewcomms(NewComms,record)
                }
                
              });
            })
                
        }, 300);
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
      Downloadfile(StorageRef,fileurl,Uploadfilename){
            

        var element = document.createElement('img')
            element.setAttribute('href',fileurl)
            element.setAttribute('download', this.ActiveRecord.name+' - '+Uploadfilename)
            // element.style.display = 'none';
          document.body.appendChild(element);
          //console.log(element)
          element.click();
          document.body.removeChild(element);
      },
      SubmitForm(form,comment){
        //console.log(form,comment)
        this.ActiveForm = ''
        this.ActiveComment = ''
        this.OpenFormComponent = false
        this.input = 'Submitted'
        form.readonly = true
        this.comment(this.ActiveRecord,form)
      },
      OpenForm(form,comment){
        this.ActiveForm = form
        this.ActiveComment = comment
        this.OpenFormComponent = true
      },
      DateFormatterwithTime(date){
      if(date){
        //console.log(date)
        //console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        let hrnumber = Number(hr)
        let minr = Number(min)
        let secnr = Number(sec)
        var ampm = "am";
        if(isNaN(secnr)){
          secnr = 0
        }
        //console.log(yearnumber,monthnumber,daynumber,hrnumber,minr,secnr)
        let newdate = new Date(yearnumber, monthnumber-1, daynumber,hrnumber,minr,secnr)
        //console.log(newdate)
        return newdate
        }
      else{
        return null
      }
},
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      GetClientWikiTabs(){
        let menuitem =  {
              name: 'My Products',
              ID: 1003,
              Children: []
          }
           db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('ClientWikiTables').onSnapshot(res => {
                  let listlength = res.docs.length
                  if(listlength === 0){
                    this.items.push(menuitem)
                  }
                  const changes = res.docChanges();
                  changes.forEach(change => {
                      if(change.type === 'added'){
                        let obj = {
                          ...change.doc.data(),
                          id: change.doc.id
                        }
                        obj.WikiData = []
                        obj.collectionname = obj.id.split('_').join('').toLowerCase()+'wiki'
                        obj.ClientField = obj.ClientLookupField.Name
                        obj.Clientid = obj.ClientField+'id'
                        obj.ClientValueField = obj.ClientLookupField.LookupFieldName
                        db.collection(obj.collectionname).where(obj.Clientid,'==',this.userLoggedIn.id).onSnapshot(wikires => {
                          let wikilength = wikires.docs.length
                          if(wikilength === 0){
                            menuitem.Children.push(obj)
                            if(menuitem.Children.length === listlength){                              
                              this.items.push(menuitem)
                            }
                          }
                          const wikichanges = wikires.docChanges();
                          wikichanges.forEach(wikichange => {
                            if(wikichange.type === 'added'){
                              let wikiobj = {
                                ...wikichange.doc.data(),
                                id: wikichange.id
                              }
                              obj.WikiData.push(wikiobj)
                            }
                            if(obj.WikiData.length === wikilength){
                              menuitem.Children.push(obj)
                              if(menuitem.Children.length === listlength){
                                this.items.push(menuitem)
                              }
                            }
                          })
                        })
                      }
                  });
           })
      },
      GetClientTopics(){
        //console.log(this.SystemEntities)
        let menuobj =  {
                    name: 'Notifications',
                    ID: 1004,
                    Children: []
                }
                this.items.push(menuobj)
                let menuitem = this.items.find(obj => obj.ID === 1004)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('LiaisonTopics').onSnapshot(topicres => {
              // alert('boohooo')
                    const topicchanges = topicres.docChanges(); 
                    let topicslength = topicres.docs.length 
                      if(topicslength === 0){
                        // this.items.push(menuitem)
                      }
                    
                    if(topicslength !== 0){
                      // let commslength = commres.docs.length                 
                      topicchanges.forEach(topicchange => {
                        if (topicchange.type === 'added') {
                          let topicchild = {
                            ...topicchange.doc.data(),
                            id: topicchange.doc.id
                          }
                          let ent = this.SystemEntities.find(obj => obj.id === topicchild.id)
                          if(ent && ent.Entity_Type){
                            topicchild.Entity_Type = ent.Entity_Type
                          }
                          menuitem.Children.push(topicchild)
                          let obj = menuitem.Children.find(item => item.id === topicchange.doc.id)
                          obj.Data = []
                          obj.NotificationCount = 0  
                              db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').where('Clientid','==',this.userLoggedIn.id).where('topicid','==',obj.id).onSnapshot(res => {
                              const changes = res.docChanges();        
                              let recordslength = res.docs.length  
                              if(recordslength === 0){
                                // menuitem.Children.push(obj) 
                                if(menuitem.Children.length === topicslength){
                                  // this.items.push(menuitem)
                                }
                              }          
                              changes.forEach(change => {
                                if (change.type === 'added') {
                                  obj.Data.push({
                                    ...change.doc.data(),
                                    id: change.doc.id
                                  })
                                  let dataobj = obj.Data.find(item => item.id === change.doc.id)
                                  dataobj.Notifications = this.Notifications.filter(notobj => {return notobj.clientrecords === dataobj.id && notobj.Read === false})
                                  obj.NotificationCount = obj.NotificationCount+dataobj.Notifications.length
                                  dataobj.ClientLiaison = []
                                  let senderquery = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('senderid','==',this.userLoggedIn.id).where('relatedid','==',dataobj.id)
                                  let recipientquery = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison').where('recipientid','==',this.userLoggedIn.id).where('relatedid','==',dataobj.id)

                                  senderquery.onSnapshot(commres => {
                                    const comchanges = commres.docChanges();  
                                    comchanges.forEach(comchange => {
                                      if (comchange.type === 'added') {
                                            dataobj.ClientLiaison.push({
                                          ...comchange.doc.data(),
                                          id: comchange.doc.id
                                        })
                                    
                                      }
                                    })                    
                                    })
                                  recipientquery.onSnapshot(commres => {
                                    const comchanges = commres.docChanges();  
                                    comchanges.forEach(comchange => {
                                      if (comchange.type === 'added') {
                                            dataobj.ClientLiaison.push({
                                          ...comchange.doc.data(),
                                          id: comchange.doc.id
                                        })
                                    
                                      }
                                    })                    
                                    })
                                }
                              })                    
                              })                   
                        }
                      })  
                    }
                                      
                    })
        
      },

      
        comment(record,form,LiaisonUploadFile) {
          //console.log(this.Activesession)
        let NewComms = {
          description: this.input,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.Activesession.id.split('_').join(' '),
          topicid: this.Activesession.id,
        }
        if(form){
            NewComms.FormObj = form
            if(form.readonly){
              NewComms.readonly = true
            }
        }
         if(!LiaisonUploadFile){
         this.SaveNewcomms(NewComms,record)  
        }
        else{
          // console.log(this.ActiveSession)
            //first upload the file, return url and include url in NewComms
            this.UploadFileandAttahctoNewComms(NewComms,record)
        }

        this.input = null
      },
        SortClientLiaison(){
          return this.ActiveRecord.ClientLiaison.map(liase => {
            if(liase.Created_Byid){
                     let userobj = this.UsersArray.find(obj => obj.id === liase.Created_Byid)
                     if(userobj && userobj.Profile_Photo){
                         liase.ProfPic = userobj.Profile_Photo
                     }
                 }
                    liase.createdon = liase.Created_On.toDate()
                    liase.TimestampSort = liase.createdon.getTime()
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  }).slice(0,this.slice)
        },
        ActivateActiveRecord(record){
            this.ActiveRecord = Object.assign({},record)
            this.ActiveRecord.Notifications.map(not => {
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).update({
                Read: true
              })
            })
            
            // this.storedrawer = false
        },
        DeactivateActivesession(){
          this.Activesession = ''
        },
        ActivateSession(item){
          if(item.name === 'Featured Member'){
            let path = this.FeaturedMemberDefaultPage ? '/FeaturedMember/'+this.userLoggedIn.id+'/'+this.FeaturedMemberDefaultPage : '/FeaturedMemberCreator/'+this.userLoggedIn.id
            //this.$router.push(path)
            //FIRST let's get you eidting your info
            this.Activesession = item
          }
          else if(this.$route.name !== 'MyAccount'){
            //console.log('pushin back')
            this.$router.push('/MyAccount')
          }
          else{
            this.Activesession = ''
            if(this.MiniView){
                this.storedrawernav = false
            }
            if(item.IsStore){
                this.$router.push(item.Path)
            }
            else if(item.Path){
                this.$router.push(item.Path)
            }
            else if(item.RouteQueries){
                let queryobj = {}
                item.RouteQueries.map(query => {
                queryobj[query.Prop] = query.Value
                })
                this.$router.replace({ name: this.$route.name, query: queryobj})
                this.Activesession = item
                this.ActiveRecord = ''

            }
            else{
                if(this.$route.query && this.$route.query.SomeParams){
                let newroute = { path: this.$route.path}
                this.$router.push(newroute)
                }
                this.Activesession = item
                this.ActiveRecord = ''
            }
            this.ToTop()
          }
        },
    }
}
</script>

<style>

</style>