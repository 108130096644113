<template>
  <div>
    <v-card rounded>
        <v-card-title >
            {{AddTitle}}
        </v-card-title>
        <v-card-subtitle style="padding-top: 10px;">
            {{AddHint}}
        </v-card-subtitle>
        <v-card-title class="justify-end">
            <v-spacer>
            </v-spacer>
           <v-btn-toggle v-model="OptionTypeFilter">
            <v-btn small >
                <v-icon small>mdi-view-list</v-icon>
            </v-btn>
            <v-btn small >
                <v-icon small>mdi-format-color-fill</v-icon>
            </v-btn>
             <!-- <v-btn v-if="AllowPluginOptions" small >
                <v-icon small>mdi-power-plug</v-icon>
            </v-btn> -->
            </v-btn-toggle>
        </v-card-title>
            <v-card-text v-if="OptionTypeFilter === 0">
                 <v-text-field outlined dense placeholder="Option Name"
            label="Type in option name"
            v-model="NewOption.Name" :rules="[$store.state.formrules.required]"/>
            <v-text-field outlined dense placeholder="Option Icon" 
            :prepend-icon="NewOption.Icon"
            label="Select Icon for the option (Optional)"
            v-model="NewOption.Icon"/>
            <v-textarea outlined dense placeholder="Option Description"
            label="Type in option Description (Optional)"
            v-model="NewOption.Description"/>            
            </v-card-text>
            <v-card-text v-if="OptionTypeFilter === 1">
                <v-avatar :color="NewOption.Color"/>
                <v-color-picker v-model="NewOption.Color"
                
                />
            </v-card-text>
             <!-- <v-card-text v-if="OptionTypeFilter === 2">
                <v-autocomplete placeholder="Option Plugin" outlined dense
                label="Select option plugin" item-text="id" v-model="NewOption.Pluginid" :items="PluginOptions"/>
                 <v-autocomplete placeholder="Plugin Template" outlined dense
                label="Select plugin template" item-text="id" v-model="NewOption.Templateid" :items="PluginTemplates">
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                            <v-list-item-title>{{item.Name}} <v-chip small outlined>{{item.Category.Name}}</v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{item.id}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-html="item.Description">{{item.Description}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                </template>
                <template v-slot:selection="{ item }">
                    <span >{{item.Name}}</span>
                </template>
                 </v-autocomplete>
                 <v-autocomplete placeholder="Template Options" outlined dense
                label="Select plugin template options" multiple item-text="id" v-model="NewOption.TemplateOptions" :items="PluginTemplates">
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                            <v-list-item-title>{{item.Name}} <v-chip small outlined>{{item.Category.Name}}</v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{item.id}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-html="item.Description">{{item.Description}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                </template>
                <template v-slot:selection="{ item }">
                    <v-chip>{{item.Name}}</v-chip>
                </template>
                 </v-autocomplete> -->
             </v-card-text>
            <v-card-actions>
            <v-btn @click="CancelNewOptionDialog()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="SaveOption(NewOption,NewOptionLevel,ParentOption)">Save</v-btn>
            </v-card-actions>  
        </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','AddTitle','AddHint','CategoryField','NewOptionLevel','ParentOption','PluginOptions'],
    components: {},
    data() {
        return {
            NewOption: {
                Name: '',
                Description: '',
                Icon: '',
                Color: '#FF0000'
            },
            OptionTypeFilter: 0,
        }
    },	
    computed:{
         MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        PluginTemplates(){
            return this.MarketplaceTemplates.filter(temp => {
                //technically now if Pluginid, and if type "feat mem" blablabla
                return temp.PublishStatus === 'Published'
            })
        },
        AllowPluginOptions(){
            return this.PluginOptions && this.PluginOptions.length > 0
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        CancelNewOptionDialog(){
            this.$emit('CancelNewOptionDialog')
        },
        SaveOption(NewOption,lvl,parent){
            this.$emit('SaveOption',NewOption,lvl,parent)
        },
    }
}
</script>

<style>

</style>



