
<template>

   <v-card flat tile :class="!NoticeSelect ? 'transparent' : 'transparent'"
    >
    <v-dialog v-model="dialog"  width="400" >
          <v-card>
            <v-card-title>
            <v-btn @click="ToggleNewHelp()" color="#e4003b" dark 
                fab
                x-small><v-icon>mdi-help</v-icon></v-btn>  New Group
            </v-card-title>
             <v-card-text>               
            <v-form  ref="form">
                  <v-text-field :rules="[$store.state.formrules.required]" v-model="editedItem.Name" label="Name"></v-text-field>
                   <v-select :items="GroupCategoryTypes" :rules="[$store.state.formrules.required]"
                    label="Group Category" v-model="editedItem.GroupCategory"
                    item-text="Name"
                    return-object
                    />  
                    <v-card-text v-if="!editedItem.GroupCategory || editedItem.GroupCategory && !editedItem.GroupCategory.IsPaid">
                    <v-select :rules="editedItem.GroupCategory.IsPaid ? [$store.state.formrules.required] : []" v-if="editedItem.GroupCategory && editedItem.GroupCategory.IsPaid && GroupOwnershipPackages && GroupOwnershipPackages.length > 0" :items="GroupOwnershipPackages"
                    label="Choose your package" v-model="editedItem.Subscription_Package"
                    item-text="Name"
                    return-object>
                     <template v-slot:item="{ item }">
                       <v-list-item-content>
                         <v-list-item-title>
                            {{item.Name}}
                         </v-list-item-title>
                         <v-list-item-subtitle>
                           {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                         </v-list-item-subtitle>
                         <v-list-item-subtitle v-html="item.Description">
                         </v-list-item-subtitle>
                       </v-list-item-content>
                        </template>
                    </v-select>
                    <v-select :rules="[$store.state.formrules.required]" :items="editedItem.GroupCategory.Options" v-if="editedItem.GroupCategory && editedItem.GroupCategory.HasDeepHierarchy && editedItem.GroupCategory.Options"
                    label="Group Class" v-model="editedItem[System.Group_Categories.Levels[1].FieldName]"
                    item-text="Name"
                    return-object
                    /> 
                    
                     <v-select :rules="[$store.state.formrules.required]" :items="editedItem[System.Group_Categories.Levels[1].FieldName].Options" v-if="editedItem.GroupCategory && editedItem.GroupCategory.HasDeepHierarchy && editedItem[System.Group_Categories.Levels[1].FieldName] && editedItem[System.Group_Categories.Levels[1].FieldName].HasDeepHierarchy && editedItem[System.Group_Categories.Levels[1].FieldName].Options"
                    label="Group Type" v-model="editedItem[System.Group_Categories.Levels[2].FieldName]"
                    item-text="Name"
                    return-object
                    /> 
                                <v-select :items="ComputedPrivacyTypes" :rules="[$store.state.formrules.required]"
                  label="Privacy" v-model="editedItem.GroupPrivacy"
                  
                  :value="editedItem.GroupCategory"
                  >
                                </v-select> 
                                 <v-textarea :rules="[$store.state.formrules.required]" v-model="editedItem.Description" label="Description"></v-textarea>
                    </v-card-text>
                    <v-card-text v-if="editedItem.GroupCategory && editedItem.GroupCategory.IsPaid">
                      You cannot add this type from here.<br>Please visit <v-btn outlined small :to="'/MyAccount'">My Account</v-btn>
                    </v-card-text>
                  <v-card-actions>
                        <v-btn dark color="#70cbf3" @click="Closedialog()">
                        Cancel
                    </v-btn>
                          <v-btn :disabled="editedItem.GroupCategory && editedItem.GroupCategory.IsPaid" dark color="#e4003b" @click="save()">
                        Save
                    </v-btn>
                  </v-card-actions>
            </v-form>
             </v-card-text>
                  </v-card>
              </v-dialog>

          <!-- <v-toolbar>
            

          </v-toolbar>
          <h1 class="blue white--text align--center">GROUPS</h1>  
     -->

<v-bottom-sheet v-model="helpnewsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="helpnewsheet = !helpnewsheet"
        >close</v-btn>
        <div>Here we can start to create your <v-icon>mdi-account-group</v-icon> Group. Once you click on "save" you will be taken to the page where you finalize your Group and publish it as per your needs</div>
      </v-sheet>
    </v-bottom-sheet>
      <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>Here you can find a list of <v-icon>mdi-account-group</v-icon> Groups and find likeminded movements and organizations that you can <v-icon>pan_tool</v-icon> volunteer at or <v-icon>supervisor_account</v-icon> partner with</div>
      </v-sheet>
    </v-bottom-sheet>
               <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new Group.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      
 <v-layout class="justify-center">

    <v-flex xs11 sm11 md10 lg10 v-if="!NoticeSelect">
      
      <v-card class="transparent" flat>
         <v-toolbar flat dark color="socialappsearchbar">
             <v-toolbar-title>Groups <span v-if="userIsMonetizationOfficer">({{unpublishedgroups.length}} Unpublished)</span></v-toolbar-title>

      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
 <!-- <v-img align="left"
          :src="require('@/assets/elb_twirl.png')"
          class="my-3"
          
          height="40px"
        ></v-img> -->
      <v-spacer></v-spacer>
      <v-text-field dark
        v-model="search"
        append-icon="mdi-file-find-outline"
        label="Search"
        single-line
        hide-details
      ></v-text-field>      
      <v-btn color="#e4003b" @click="Opendialog()"  v-if="UserModerator">
          Add New
      </v-btn>
          </v-toolbar>
          <v-expansion-panels tile flat v-if="!$store.state.TemplateView">
            <v-expansion-panel class="socialappsearchbar white--text">
              <v-expansion-panel-header>Filters</v-expansion-panel-header>
              <v-expansion-panel-content>
          <v-row class="my-1 justify-center">
         <v-chip-group column >
              <v-chip @click="ActivateCatFilter(lvl)" v-for="lvl in GroupCategoryTop" :key="lvl.itemObjKey">
                  {{lvl.Name}}
              </v-chip>
          </v-chip-group>
          </v-row>
          <v-row class="justify-center" v-if="TopLevelFilter && TopLevelFilter.Options">
          <v-chip-group column >
              <v-chip @click="ActivateClssFilter(lvl)" v-for="lvl in TopLevelFilter.Options" :key="lvl.itemObjKey">
                  {{lvl.Name}}
              </v-chip>
          </v-chip-group>
          </v-row>
           <v-row class="justify-center" v-if="ClassLevelFilter && ClassLevelFilter.Options">
          <v-chip-group column >
              <v-chip @click="ActivateTypeFilter(lvl)" v-for="lvl in ClassLevelFilter.Options" :key="lvl.itemObjKey">
                  {{lvl.Name}}
              </v-chip>
          </v-chip-group>
          </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
            <v-card class="mx-auto transparent" flat>
              <v-card-title class="justify-end" style="padding: 0px;">
              <v-btn outlined color="red" @click="OpenAboutDialog('AboutGroupsDialog')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Groups</v-btn>
              </v-card-title>
              <v-btn style="top:0px;" @click="ToggleHelp()" color="#e4003b" dark 
                                fab
                                
                                small
                                absolute
                                top
                                left><v-icon>mdi-help</v-icon></v-btn>
                                <v-btn v-if="userIsMonetizationOfficer" @click="ViewUnpublishedGroups = !ViewUnpublishedGroups" color="success" dark 
                                fab
                                style="left: 70px;top:0px;"
                                small
                                absolute
                                top
                                left><v-icon>mdi-check</v-icon><v-badge :color="unpublishedgroups.length > 0 ? 'red' : 'grey'" 
                              >
                              
                                
                            <span slot="badge"> {{unpublishedgroups.length}} </span>
                            </v-badge> </v-btn>
                             
               
                          
             <div v-if="!ViewUnpublishedGroups && ViewReady">
               
     <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
               <v-col cols="12" xl="6"
          v-for="group in filteredGroups"
          :key="group.itemObjKey"
        >
        
          <v-card height="150"
            color="socialappsearchbar"
            dark :to="group.DefaultPage && group.DefaultPage.Type === 'Group Page' ? '/Group-Page/'+group.id+'/' + group.DefaultPage.Name : SingleRoutePrefix+group.id"
          ><v-img :src="group.logo"  :gradient="GroupsGradient" height="150">
            
            <v-list-item three-line>
              <v-list-item-content class="align-self-start">
                <v-container grid-list-xl>
                <v-layout row wrap>
                  
                   <!-- <v-layout row> -->
                  <!-- <v-flex xs12 s12 md6 lg6> -->
                    <v-flex xs12 s12 md3 lg2>
                  <v-layout column class="mx-1"> 
                  <v-avatar tile><v-img contain :src="group.logo"></v-img></v-avatar></v-layout>

                </v-flex>
                                  <!-- <v-layout column> -->
                   <v-layout row>
                                 <h2> <v-list-item-title class="white--text mx-3"
                 
                  v-text="group.name.substr(0,20)" 
                ></v-list-item-title></h2>
                   </v-layout>
                <!-- </v-layout> -->
                   <!-- </v-layout> -->
                 <v-layout column>
                   <v-flex xs12 s12 md10 lg8>
                  <h6 id="Grouptitle" class="mx-3"> {{group.GroupCategory ? group.GroupCategory.Name : ''}} <v-icon v-if="group.EndorsingNetwork" light color="white" medium>cloud_done</v-icon></h6></v-flex>
                    </v-layout>
                    <v-layout column>                 
                     <h5 class="mx-3">Group has a Privacy of {{group.GroupPrivacy}} <v-icon>{{group.GroupPrivacyIcon}}</v-icon></h5>
                    </v-layout> 
                                         <v-layout row wrap>

                <!-- <v-list-item-avatar 
                size="125"
                tile
              >
                <v-img :src="group.logo"></v-img>
              </v-list-item-avatar> -->
               </v-layout>            
                </v-layout><v-divider></v-divider><br>
                <v-layout row wrap class="mx-3">
                <v-list-item-action-text v-text="group.Description.substr(0,250)"></v-list-item-action-text></v-layout>
                </v-container>   
              </v-list-item-content>  
            </v-list-item></v-img>
          </v-card>
        </v-col>
      </v-row>
             </div>
      <div v-if="ViewUnpublishedGroups">
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
               <v-col cols="12" xl="6"
          v-for="group in unpublishedgroupsfiltered"
          :key="group.itemObjKey"
        >
        
          <v-card height="150"
            color="background darken-2"
            :to="group.DefaultPage && group.DefaultPage.Type === 'Group Page' ? '/Group-Page/'+group.id+'/' + group.DefaultPage.Name : SingleRoutePrefix+group.id"
          >
          <!-- <v-img :src="group.logo"  :gradient="GroupsGradient" height="150"> -->
            
            <v-list-item three-line>
              <v-list-item-content class="align-self-start">
                <v-container grid-list-xl>
                <v-layout row wrap>
                  
                   <!-- <v-layout row> -->
                  <!-- <v-flex xs12 s12 md6 lg6> -->
                    <v-flex xs12 s12 md3 lg2>
                  <v-layout column class="mx-1"> 
                  <v-avatar tile><v-img contain :src="group.logo"></v-img></v-avatar></v-layout>

                </v-flex>
                                  <!-- <v-layout column> -->
                   <v-layout row>
                                 <h2> <v-list-item-title class="mx-3"
                 
                  v-text="group.name.substr(0,20)" 
                ></v-list-item-title></h2>
                   </v-layout>
                <!-- </v-layout> -->
                   <!-- </v-layout> -->
                 <v-layout column>
                   <v-flex xs12 s12 md10 lg8>
                  <h6 id="Grouptitle" class="mx-3"> {{group.GroupCategory.Name}} <v-icon v-if="group.EndorsingNetwork" light color="white" medium>cloud_done</v-icon></h6></v-flex>
                    </v-layout>
                    <v-layout column>                 
                     <h5 class="mx-3">Group has a Privacy of {{group.GroupPrivacy}} <v-icon>{{group.GroupPrivacyIcon}}</v-icon></h5>
                    </v-layout> 
                                         <v-layout row wrap>

                <!-- <v-list-item-avatar 
                size="125"
                tile
              >
                <v-img :src="group.logo"></v-img>
              </v-list-item-avatar> -->
               </v-layout>            
                </v-layout><v-divider></v-divider><br>
                <v-layout class="mx-3" row wrap>
                <v-list-item-action-text v-text="group.Description.substr(0,250)"></v-list-item-action-text></v-layout>
                </v-container>   
              </v-list-item-content>  
            </v-list-item>
            <!-- </v-img> -->
          </v-card>
        </v-col>
      </v-row>
      </div>
            </v-card>
 
      </v-card>
    </v-flex>
    <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="filteredGroups" item-text="name" v-model="selectedSocialItem" return-object label="Group"/>
 </v-layout>


   </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['NoticeSelect','System','SubscriptionPackages'],
    data () {
        return {
          TopLevelFilter: '',
          ClassLevelFilter: '',
          TypeLevelFilter: '',
          rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
          InternalLinkType: '',
          LinkComponent: '',
          SharedCollection: '',
          selectedSocialItem: '',
          NoticeCoverIMGProp: 'logo',
          PrivacyTypes: [
        { index: 1, text: "Invite Only" },
        { index: 2, text: "Non Guest Site Members" },
        { index: 3, text: "All Site Members" },
        { index: 4, text: "Public" },
      ],
          tempid: 'new',
          //userid: this.$store.state.user.id,
        // GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            groups: [],
            unpublishedgroups: [],
            ViewUnpublishedGroups: false,
            ViewReady: false,
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                logo: null,
                                
            },
            UserRecord: {},           

        }
    },
    computed: {
      
      AccountTypes(){
          return this.$store.state.AccountTypes
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
       DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
      GroupLevelNames(){
            return this.System.Group_Categories && this.System.Group_Categories.Levels ? this.System.Group_Categories.Levels : []
        },
      GroupCategoryTop(){
        return this.GroupCategoryTypes.filter(opt => {
          if(this.TopLevelFilter){
            return opt.Level && opt.Level.id === 1
            && opt.ID === this.TopLevelFilter.ID
          }
          else{
            return opt.Level && opt.Level.id === 1
          }
        }).filter(opt => {
          if(this.ViewUnpublishedGroups){
            let oncheck = this.unpublishedgroups.find(obj => obj[this.TopLevelFieldName] && obj[this.TopLevelFieldName].ID === opt.ID)
            return oncheck
          }
          else{
            return opt
          }
        })
      },
      unpublishedgroupsfiltered(){
        return this.unpublishedgroups.filter(group => {
        return this.search ? group.name.toLowerCase().includes(this.search.toLowerCase()) : group
      }).filter(group => {
        if(this.TopLevelFilter){
          return group[this.TopLevelFieldName] && group[this.TopLevelFieldName].ID === this.TopLevelFilter.ID
        }
        else{
          return group
        }
      }).filter(group => {
        if(this.ClassLevelFilter){
          return group[this.ClassLevelFieldName] && group[this.ClassLevelFieldName].ID === this.ClassLevelFilter.ID
        }
        else{
          return group
        }
      })
      .filter(group => {
        if(this.TypeLevelFilter){
          return group[this.TypeLevelFieldName] && group[this.TypeLevelFieldName].ID === this.TypeLevelFilter.ID
        }
        else{
          return group
        }
      })
      },
      userIsMonetizationOfficer(){
        return this.userIsAdmin || this.userLoggedIn && this.userLoggedIn.MonetizationOfficer
      },
      ComputedGroups(){
        return this.userLoggedIn && this.ViewReady && !this.$store.state.TemplateView ? this.$store.state.SiteGroupsArray : this.groups
      },
      GroupsGradient(){
      return this.$vuetify.theme.dark ? 'to top, '+this.System.Light_Social_App_SearchBar.hex+'EE, '+this.System.Dark_Social_App_SearchBar.hex+'DD': 'to top, '+this.System.Dark_Social_App_SearchBar.hex+'DD, '+this.System.Light_Social_App_SearchBar.hex+'DD' 
      },
      ComputedPrivacyTypes(){
      return this.PrivacyTypes.filter(type => {
        if(!this.WebPageBuilderAvailable){
          return type.text !== 'Public'
        }
        else{
          return type
        }
      }).filter(type => {
        if(type.text === 'Non Guest Site Members'){
          return !this.$store.state.UserisGuest
        }
        else{
          return type
        }
      })
    },
    WebPageBuilderAvailable(){
      return this.System.Group_Web_Page_Builder_Paid && this.editedItem && this.editedItem.Subscription_Package && this.editedItem.Subscription_Package.FeatureScope.includes('Web Page Builder') || !this.System.Group_Web_Page_Builder_Paid
    },
    GroupOwnershipPackages(){
      return this.SubscriptionPackages.filter(pack => {
        return pack.Type && pack.Type.Name === 'Group Ownership'
      }).filter(pack => {
        if(this.editedItem && this.editedItem.GroupCategory && this.editedItem.GroupCategory.IsPaid){
          let MonetizationPackages = []
          if(this.editedItem.GroupCategory.MonetizationPackages){
            MonetizationPackages = this.editedItem.GroupCategory.MonetizationPackages
          }
          return MonetizationPackages.includes(pack.id)
        }
        else{
          return pack
        }
      })
    },
      MonetizationPackages(){
        if(this.editedItem.GroupCategory && this.editedItem.GroupCategory.MonetizationPackages && this.System.GroupMonetizationPackages){
          return this.System.GroupMonetizationPackages.filter(item => {
            return this.editedItem.GroupCategory.MonetizationPackages.includes(item.ID)
          })
        }
        else{
          return []
        }
      },
      GroupCategoryTypes(){
        if(this.System && this.System.Group_Categories &&  this.System.Group_Categories.Options){
          return this.System.Group_Categories.Options
        }
        else{
          return []
        }
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserModerator(){
        if(this.userLoggedIn.ModerationPermissions){
          let match = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Groups')
          if(match){
            return true
          }
          else{
            return this.userIsAdmin
          }
        }
        else{
          return this.userIsAdmin
        }
      },
            filteredGroups() {
      
      return this.ComputedGroups.filter(group => {
        return this.search ? group.name.toLowerCase().includes(this.search.toLowerCase()) : group
      }).filter(group => {
        if(this.TopLevelFilter){
          return group[this.TopLevelFieldName] && group[this.TopLevelFieldName].ID === this.TopLevelFilter.ID
        }
        else{
          return group
        }
      }).filter(group => {
        if(this.ClassLevelFilter){
          return group[this.ClassLevelFieldName] && group[this.ClassLevelFieldName].ID === this.ClassLevelFilter.ID
        }
        else{
          return group
        }
      }).filter(group => {
        if(this.TypeLevelFilter){
          return group[this.TypeLevelFieldName] && group[this.TypeLevelFieldName].ID === this.TypeLevelFilter.ID
        }
        else{
          return group
        }
      })
    },
    TopLevelFieldName(){
      return this.GroupLevelNames && this.GroupLevelNames[0] ? this.GroupLevelNames[0].FieldName : ''
    },
    ClassLevelFieldName(){
      return this.GroupLevelNames && this.GroupLevelNames[1] ? this.GroupLevelNames[1].FieldName : ''
    },
    TypeLevelFieldName(){
      return this.GroupLevelNames && this.GroupLevelNames[2] ? this.GroupLevelNames[2].FieldName : ''
    },
    SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.ActiveMarketTemplate.Name ? this.ActiveMarketTemplate.Name : this.System.Name
        },
      SingleRoutePrefix(){
        //$route.name !== 'GroupSingle' ? '/Forum/'+forum.id : '/Group-Forum/'+$route.params.id+'/Discussion/'+forum.id
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Group/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Group/'
        }
        else{
          return '/Group/'
        }
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
    },
    watch: {
    ComputedGroups: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                //alert('oh the value she changes')
              }
            },deep: true
        },
    },
    created() {
      if(this.$store.state.TemplateView){
        this.GetSamples(this.ConfigPath.collection('samplegroups'),'groups')
        this.ViewReady = true
      }
      else{
      // this.$store.dispatch('GetUserStateChange')
      //console.log('store',this.$store.state)
    this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.GetRequestingUser()
      }
},
    methods: {
      OpenAboutDialog(dialprop){      
        this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
        this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
      },
       GetSamples(query,array){     
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      ActivateCatFilter(value){
            if(value === this.TopLevelFilter){
                this.TopLevelFilter = ''
                this.ClassLevelFilter = ''
                this.TypeLevelFilter = ''
            }
            else{
             this.TopLevelFilter = value   
            }
            
        },
      ActivateClssFilter(value){
            if(value === this.ClassLevelFilter){
                this.ClassLevelFilter = ''
                this.TypeLevelFilter = ''
            }
            else{
             this.ClassLevelFilter = value   
            }
            
        },
      ActivateTypeFilter(value){
            if(value === this.TypeLevelFilter){
                this.TypeLevelFilter = ''
            }
            else{
             this.TypeLevelFilter = value   
            }
            
        },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }     
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }    
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
      GetRequestingUser(){
          if(this.userLoggedIn){
            this.UserRecord = this.userLoggedIn
            this.UserRecord.id = this.userLoggedIn.id
            //console.log(this.UserRecord)
            let usergroupslength = this.UserRecord.groups.length
            if(usergroupslength === 0){
              this.PreparegetGroups()
            }
            else{
              this.PreparegetGroups()
              // this.UserRecord.groups.map((group,i) => {
              //   if(i-1+2 === usergroupslength){
              //     this.CallUserGroup(group.id,true)
              //   }
              //   else{
              //     this.CallUserGroup(group.id,false)
              //   }
                
              // })
            }
              
              
                
            
          }
          else{
            this.GetPublicGroupsOnly()
          }

      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
          CallUserGroup(groupid,finalcall){
            // db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(groupid).onSnapshot(snapshot =>{
            //   let groupdata = snapshot.data()
            //   if(groupdata){
            //    groupdata.id = groupid
            //     this.groups.push(groupdata)
                
            //   }
            //   if(finalcall){
                  
                  this.PreparegetGroups()
            //     } 
              
            // })
          },
          GetPublicGroupsOnly() {
           alert('calling public')
           db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPrivacy','==','Public').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          },
        error => {
          console.log('error',error.message)
        })
          },
          PreparegetGroups(){
            let vm = this
            if(this.$store.state.SiteGroupsQuery){
              if(this.$store.state.SiteGroupsArray && this.$store.state.SiteGroupsArray.length > 0){
                  this.ViewReady = true
              }
              else{
                let length = this.$store.state.SiteGroupsQuery.length
                console.log('query length',length)
                // this.$store.state.SiteGroupsQuery.map((query,queryindex) => {
                     let storepayload = {
                            query: this.$store.state.SiteGroupsQuery,
                            arrayname: 'SiteGroupsArray'
                        }
                        //console.log(storepayload,this.$store.state)
                        this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                            console.log('result',result)
                            this.ViewReady = true
                            // if(queryindex-1+2 === length){
                              // alert('potential')
                              //vm.groups = vm.groups.concat(vm.$store.state.SiteGroupsArray)
                               //vm.RefreshView() 
                            // }
                        })
                    // })   
              }
            }
            if(this.userIsMonetizationOfficer){
               //GroupPublishStatus === 'Request Publish'
              this.getGroupsbyQuery(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Request Publish'))
            }
            // if(this.$store.state.UserisGuest){
            //   this.getGroupsbyQuery(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'))
            //   this.getGroupsbyQuery(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members'))              
            // }
            // else{
            //   this.getGroupsbyQuery(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'))
            //   this.getGroupsbyQuery(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members'))
            //   this.getGroupsbyQuery(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Non Guest Site Members'))
            // }
          },
          getGroupsbyQuery(query){
            query.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let match = this.groups.find(obj => obj.id === change.doc.id)
                if(!match){
                  this.unpublishedgroups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                }
                
              }
            })
          })
          },
         getGroups() {
     //console.log('calling the groups after user groups')
           db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let match = this.groups.find(obj => obj.id === change.doc.id)
                if(!match){
                  this.groups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                }
                
              }
            })
          })

          db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              let match = this.groups.find(obj => obj.id === change.doc.id)
                if(!match){
                  this.groups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                }
            })
          })
// UseronGroupInviteList(groupid) || GroupPrivacyType(groupid,'Invite Only') && UserisGroupMember(groupid)
          // db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Invite Only').onSnapshot(res => {

          //   const changes = res.docChanges();
          //   changes.forEach(change => {
          //     if (change.type === 'added') {
          //       this.groups.push({
          //         ...change.doc.data(),
          //         id: change.doc.id
          //       })
          //     }
          //   })
          // })

          // db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Invite Only').onSnapshot(res => {

          //   const changes = res.docChanges();
          //   changes.forEach(change => {
          //     if (change.type === 'added') {
          //       this.groups.push({
          //         ...change.doc.data(),
          //         id: change.doc.id
          //       })
          //     }
          //   })
          // })
          
         

    },
    Opendialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {        
        // const functions = firebase.functions();        
        // const CreateSchedule = functions.httpsCallable('CreateRecurringMonetizationPaymentSchedule');
        // let ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc();																			
        // let newdocid = ref.id;
           if(this.$refs.form.validate()) {
                    this.name = this.editedItem.Name
                    this.Description = this.editedItem.Description
                    this.GroupCategory = Object.assign({},this.editedItem.GroupCategory)
                        const group = { 
                              name: this.name,
                                Description: this.Description,
                                Created_By: {Full_Name: this.UserRecord.Full_Name, id: this.UserRecord.id},
                                Created_Byid: this.UserRecord.id,
                                Owner: {Full_Name: this.UserRecord.Full_Name, id: this.UserRecord.id},
                                Ownerid: this.UserRecord.id,
                                Created_On: new Date(),
                                GroupCategory: {ID: this.GroupCategory.ID,Name: this.GroupCategory.Name},
                                GroupPrivacy: this.editedItem.GroupPrivacy,
                                GroupPrivacyIcon: 'mdi-lock',
                                GroupPublishStatus: 'Draft',
                                EventsPrivacy: 'Members Only',
                                ArticlesPrivacy: 'Members Only',
                                BlogPrivacy: 'Members Only',
                                PollsPrivacy: 'Members Only',
                                MembersPrivacy: 'Members Only',
                                NewsPrivacy: 'Members Only',
                                ForumPrivacy: 'Members Only',
                                MembersPrivacy: 'Members Only',
                                MeetupsPrivacy: 'Members Only',
                                TeamMembers: [this.UserRecord.id]
                                // logo: this.FileURL
                                
                            }
                            group.AssignedGroupScope = []
                            this.System.Group_Categories.Levels.map(lvl => {
                              if(lvl.id === 1){
                                this.editedItem[lvl.FieldName] = group.GroupCategory
                              }
                              if(this.editedItem[lvl.FieldName]){
                                group[lvl.FieldName] = {ID: this.editedItem[lvl.FieldName].ID,Name: this.editedItem[lvl.FieldName].Name}
                              }
                            })
                            this.$store.dispatch('CreateSearchTitle',group.name).then(searchtitle => {
                              group.nameQuery = searchtitle 
                              group.Administrators = [this.userLoggedIn.id]
                               let vm = this
                              if(this.editedItem.Subscription_Package){
                                group.Subscription_Package = Object.assign({},this.editedItem.Subscription_Package)
                                group.IsPaid = true
                              }
                              else{
                                group.IsPaid = false
                              }
                              
                          //     if(group.Subscription_Package){
                          //       let item = Object.assign({},group.Subscription_Package)
                          //         item.Created_By = group.Created_By
                          //         item.Created_Byid = group.Created_Byid
                          //         let date = new Date()
                          //         item.Created_OnDateNumber = date.getTime()
                          //         item.Related_Recordid = newdocid 
                          //         vm.$emit('ActivateProcessing',true)
                          //         CreateSchedule(item).then(result => {
                          //           console.log(result)
                          //           group.MonetizingScheduleID = result
                          //           vm.FinalizeGroupAdd(group,newdocid)
                          //         })
                          //     }                                
                          // else{       
                          //   vm.FinalizeGroupAdd(group,newdocid)
                          // }
                          vm.FinalizeGroupAdd(group)
                            })
           }
      },
      FinalizeGroupAdd(group){
        let vm = this 
        const functions = firebase.functions()
        vm.$store.commit('setCustomProcessingDialog',true)
        vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')
        const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
        let autopayload = {
          Docid: 'BillingAccounts_Customer_ID',
          Data: {Account_Name: group.name},
            siteid: vm.$store.state.ActiveSuiteid
        }
        console.log(autopayload)
        AssignAutoNumber(autopayload).then(result => {
          let autonumber = result.data
          console.log(group,autonumber)
          //the issue is....
          //1. do we now manage adding of groups and packages from here as well as from My Accounts? I don't think we should
          //2. If this is only for "FREE" category types do we filter out "paid"..and do we add a BA? I think we should. 
          //3. CONCLUSION - EVERY GROUP NEEDS A BA, BUT PAID GROUPS CANNOT BE ADDED FROM HERE.
          //first add BA
          let Status = vm.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
          let newaccount = {
            Created_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
            Created_Byid: vm.userLoggedIn.id,
            Created_On: new Date(),
            Type_Default: false,
            Modified_By: {Full_Name: vm.userLoggedIn.Full_Name, id: vm.userLoggedIn.id},
            Modified_Byid: vm.userLoggedIn.id,
            Modified_On: new Date(),
            PaymentSchedule: 'None',
            IsFinal: false,
            Subscription_Package: {id: 'FREE',Name: 'FREE'},
            Outstanding_Balance: 0,
            Total_Invoiced: 0,
            Total_Paid: 0,
            Status: Status,
            Status_Reason: Status.Options.find(obj => obj.Name === 'Open'),
            Account_Website: vm.System.ClientAppURL+'/SiteMember/'+vm.userLoggedIn.id,
            Account_Type: vm.AccountTypes.find(obj => obj.Name === 'Group Account')
          }
          newaccount.User = {
              id: vm.userLoggedIn.id,
              Full_Name: vm.userLoggedIn.Full_Name,
              Name: vm.userLoggedIn.Name,
              Surname: vm.userLoggedIn.Surname
          }
          newaccount.Billing_Type = {ID: 1000001, Name: 'COD'}
          newaccount.Business_Unit = {Name: vm.$store.state.PrimaryMonetizeBU.Name, id: vm.$store.state.PrimaryMonetizeBU.id}
          newaccount.Business_Unitid = vm.$store.state.PrimaryMonetizeBU.id
          newaccount.Userid = vm.userLoggedIn.id
          newaccount.Group = {name: group.name,id: autonumber},
          newaccount.Groupid = autonumber
          newaccount.Customer_ID = autonumber
          newaccount.Client_Reference = 'My FREE Account for '+group.name
          if(vm.userLoggedIn.Email){
            newaccount.Account_Email = vm.userLoggedIn.Email
          }
          if(vm.userLoggedIn.Mobile){
            newaccount.Account_Phone = vm.userLoggedIn.Mobile
          }
          vm.$store.commit('setCustomProcessingDialogText','Adding Account')
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(autonumber).set(newaccount).then(newacctdoc => {
            vm.$store.commit('setCustomProcessingDialogText','Adding Group')
            if(vm.userLoggedIn && vm.userLoggedIn.Company){
              group.Company = vm.userLoggedIn.Company
              group.Companyid = vm.userLoggedIn.Companyid
            }
            db.collection('SocialNetworks').doc(vm.$store.state.ActiveSuiteid).collection('groups').doc(autonumber).set(group).then(function(doc) {     
              vm.$store.commit('setCustomProcessingDialogText','Adding Administrator')       
              const GroupMember = { 
              Userid: vm.UserRecord.id,
              Groupid: autonumber,
              Follower: true,
              Administrator: true,
              BlogsModerator: false,
              ArticlesModerator: false,
              EventsModerator: false,
              PollsModerator: false,
              MeetupsModerator: false,
              ForumsModerator: false,
              UserRole: 'Creator',
              Status: 'Active',
              GroupPrivacy: group.GroupPrivacy,
              UserName: vm.UserRecord.Full_Name
              }
              console.log(GroupMember)
              GroupMember.MembershipDate = new Date()
                  db.collection('SocialNetworks').doc(vm.$store.state.ActiveSuiteid).collection('groupmembers').doc(vm.UserRecord.id).collection('groups').doc(autonumber).set(GroupMember).then(doc => {
                    vm.$store.commit('setCustomProcessingDialog',false)
                    console.log(autonumber)
                    vm.$router.push('/Group/'+GroupMember.Groupid)
                    vm.Closedialog()
                    vm.snackbar = true
                })
          
          
            })
          })
        })        
      },
    }


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}
</style>


    