
    <template>
   <v-layout row class="justify-center" :style="MiniView ? 'padding:15px;padding-bottom: 80px' : 'padding: 15px;padding-bottom: 80px'">
    <!-- SubscriptionPackages {{SubscriptionPackages}} -->
    <!-- <v-flex xl8 lg9 md10 sm12> -->
      <SignupPackages style="padding:15px;" v-if="PackageSelection"
        @SignupwithPackage="SignupwithPackage" :SignUpLabel="'Sign Up'"
        :System="System" :SystemEntities="SystemEntities" :SubscriptionPackages="ComputedSubscriptionPackages"/>
      
  <v-card flat max-width="600px" min-width="400px" :style="Smallscreen" v-if="!System.Billing_Account_on_Signup && !PackageSelection && Ready">
   <v-img height="100" contain class="black" :src="require('@/assets/logo.png')">
            <v-card style="background-color: #110022bb;" tile height="100%" width="100%">
                   <v-card-title style=""
              class="mediumoverline white--text">
              {{ SelectedPackage ? SelectedPackage.Name+' (Package)' : 'New User Registration' }}
                <v-spacer>
                </v-spacer>
                
               <v-btn v-if="SelectedPackage" @click="DeactivateSelectedPackage()" large dark outlined>Change</v-btn>
              </v-card-title> 
              <!-- <v-card-text class="white--text">
                {{currentNSDescription}}
              </v-card-text> -->
              </v-card>
            </v-img>
     <v-form style="padding:20px;">
    <!-- <v-card-title v-if="!SelectedPackage" class="title">New User Registration</v-card-title>
    
  <v-card-title class="purple white--text" v-if="SelectedPackage">
    {{SelectedPackage.Name}} Registration<v-spacer></v-spacer>
    <v-btn @click="DeactivateSelectedPackage()"  dark outlined>Change</v-btn>
  </v-card-title> -->
            <v-card-text class="bootstrap-form-input">
                <v-select dense outlined
                        placeholder="Title"
                        v-model="Title"
                        :rules="[$store.state.formrules.required]"
                        :items="['Mr','Mrs','Ms']"
                    ></v-select> 
              <v-text-field outlined dense placeholder="Name"  @input="removeWhiteSpace(Name)" :rules="[$store.state.formrules.required]" v-model="Name"></v-text-field>
                  <v-text-field outlined dense placeholder="Surname"  @input="removeWhiteSpace(Surname)" :rules="[$store.state.formrules.required]" v-model="Surname"></v-text-field>
              <v-text-field outlined dense @keydown.space.prevent @input="removeWhiteSpace(email)" v-model.trim="email" :rules="[$store.state.formrules.email]" placeholder="Email"></v-text-field>
                    <!-- need to add , $store.state.formrules.passwordValidates later -->
                    <v-text-field outlined dense
                        placeholder="Password"
                        type="password"
                        :rules="[$store.state.formrules.required, $store.state.formrules.min8Chars]"
                        v-model.trim="password"
                    ></v-text-field>
                    <v-text-field outlined dense
                        placeholder="Confirm Password"
                        type="password"
                        v-model.trim="rePassword"
                        :rules="[$store.state.formrules.required, $store.state.formrules.min8Chars, passwordConfirmationRule]"
                    ></v-text-field>
                    </v-card-text>
            <!-- <v-card-actions style="padding-right:30px;">
              <v-spacer></v-spacer>         
              <v-btn class="pop" large style="padding:20px;" dark @click="signUp()">Sign Up</v-btn>
            </v-card-actions> -->
            <v-card-text style="padding: 0px!important;">
              <v-btn class="pop" large style="padding:20px;width: 100%;" dark @click="signUp()">Sign Up</v-btn>
            <v-layout class="justify-center">
              <span class="overline">OR</span>                      
            </v-layout>
            <v-divider></v-divider>
             <!-- <v-layout class="justify-center">
              <v-btn width="100%" dark class="accent" @click="$router.push('PhoneLogin')">Use Phone <v-icon>mdi-phone</v-icon></v-btn>                     
            </v-layout> -->
            <v-layout class="justify-center">
              <v-btn width="100%" dark class="success" @click="$router.push('Login')">Back to Login <v-icon>mdi-lock-open</v-icon></v-btn>                     
            </v-layout>                  
            </v-card-text> 
     </v-form>
  </v-card>
  <v-card flat max-width="600px" min-width="400px" :style="Smallscreen" v-if="System.Billing_Account_on_Signup">
     <v-form style="padding:20px;">
    <v-card-title class="title">New Account Registration</v-card-title>
          <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
              <span>{{ currentTitle }}</span>
              <v-avatar
                color="primary lighten-2"
                class="subheading white--text"
                size="24"
                v-text="step"
              ></v-avatar>
            </v-card-title>

            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                   <FieldsForm :FormFields="AccountFields" :Validating="Validating" :DataTableView="false" @save="ProgressWindow" :DataObject="BillingAccount" :tab="{}" />
                </v-card-text>
              </v-window-item>

              <v-window-item :value="2">
                <v-card-text >
                  <v-select dense
                        placeholder="Title"
                        v-model="Title"
                        :rules="[$store.state.formrules.required]"
                        :items="['Mr','Mrs','Ms']"
                    ></v-select> 
                  <v-text-field dense placeholder="Name"  :rules="[$store.state.formrules.required]" v-model="Name"></v-text-field>
                  <v-text-field dense placeholder="Surname"  :rules="[$store.state.formrules.required]" v-model="Surname"></v-text-field>
              <v-text-field @keydown.space.prevent @input="removeWhiteSpace(email)" v-model.trim="email" :rules="[$store.state.formrules.email]" placeholder="Email"></v-text-field>
                    <v-text-field dense
                        placeholder="Password"
                        type="password"
                        v-model="password"
                        :rules="[$store.state.formrules.required, $store.state.formrules.min8Chars]"
                    ></v-text-field>
                    <v-text-field dense
                        placeholder="Confirm Password"
                        type="password"
                        v-model="rePassword"
                        :rules="[$store.state.formrules.required, passwordConfirmationRule]"
                    ></v-text-field>
                    </v-card-text>
              </v-window-item>

              <v-window-item :value="3">
                <div class="pa-4 text-center">
                  <v-img
                    class="mb-4"
                    contain
                    height="128"
                    src="@/assets/logo.png"
                  ></v-img>
                  <h3 class="text-h6 font-weight-light mb-2">
                    Welcome to {{System.Name}}
                  </h3>
                  <span class="text-caption grey--text">Thanks for signing up!</span>
                  <v-btn width="100%" dark class="success" @click="$router.push('Login')">Log in now<v-icon>mdi-lock-open</v-icon></v-btn>
                </div>
              </v-window-item>
            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                :disabled="step === 1"
                text
                @click="step--"
              >
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="step === 3"
                color="primary"
                depressed
                @click="ValidateForm()"
              >
                Next
              </v-btn>
            </v-card-actions>
             <v-divider></v-divider>
             <v-card-actions>
             <v-layout class="justify-center">
              <v-btn width="100%" dark class="accent" @click="$router.push('PhoneLogin')">Use Phone <v-icon>mdi-phone</v-icon></v-btn>                     
            </v-layout>
            <v-layout class="justify-center">
              <v-btn width="100%" dark class="success" @click="$router.push('Login')">Back to Login <v-icon>mdi-lock-open</v-icon></v-btn>                     
            </v-layout>  
             </v-card-actions>
          </v-card>
     </v-form>
  </v-card>
   </v-layout>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import FieldsForm from '@/components/Database/Fields/FieldsForm';
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'	
  export default {
    props: ['System'],
    components: {FieldsForm,SignupPackages},
    name: 'signUp',
    data() {
      return {
        Ready: false,
        SelectedPackage: '',
        PackageSelection: false,
        SubscriptionPackages: [],
        AccountFields: [
          {Name: 'Account_Name',DisplayName: 'Account Name',Type: 'Single Line Text', IsMandatory: true},
          {Name: 'Account_Email',DisplayName: 'Account Email',Type: 'Common Field',CommonFieldType: 'Email', IsMandatory: true},
          {Name: 'Account_Phone',DisplayName: 'Account Phone',Type: 'Common Field',CommonFieldType: 'Telephone Number', IsMandatory: true},
          {Name: 'Account_Website',DisplayName: 'Account Website (Optional)',Type: 'Common Field',CommonFieldType: 'URL Link'},
        ],
        BillingAccount: {},
        Validating: false,
        step: 1,
        Step1Validating: false,
        Step2Validating: false,
        Step3Validating: false,
        Step4Validating: false,
        Step5Validating: false,
        rules: {
          
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
        email: '',
        password: '',
        rePassword: '',
        Title: '',

        Name: '',
        Surname: '',
        BusinessUnit: null,
        EmployeeCode: '',
        Position: '',
        TitleArray: [
          {index: 0, Name: 'Mr'},
          {index: 1, Name: 'Mrs'},
          {index: 2, Name: 'Ms'},

        ],
        GenderArray: [
          {index: 0, Name: 'Male'},
          {index: 1, Name: 'Female'},

        ],
        Gender: null
      }
    },

    computed: {
      ComputedSubscriptionPackages(){
        return this.SubscriptionPackages.filter(pack => {
          return !pack.Hidden
        })
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      Smallscreen(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs? 'padding-bottom:50px;margin-top:-100px;' : 'padding-bottom:50px;'
      },
      currentTitle () {
        switch (this.step) {
          case 1: return 'Register Account'
          case 2: return 'Create a password'
          default: return 'Successfully Registered'
        }
      },

      passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Password must match'
            },
      UserFullName(){
      return this.Name+' '+this.Surname
    },
      userLoggedIn () {
      return this.$store.getters.user
    }
    },

    watch: {
      userLoggedIn (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },

    created() {
      this.GetAppMembershipSubscriptions()
      this.ScrolltoTop()
    },

    methods: {
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      DeactivateSelectedPackage(){
        this.PackageSelection = true
        this.SelectedPackage = ''
      },
      SignupwithPackage(pack){
        this.SelectedPackage = pack
        this.PackageSelection = false
        if(pack.Price === 0){
          this.BillingAccount
          //???
        }
        this.ScrolltoTop()
      },
      GetAppMembershipSubscriptions(){
        let PackagesRef = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('SubscriptionPackages').where('Type.Name','==','App Membership')
      PackagesRef.onSnapshot(res => {
        if(res.docs.length === 0){
          this.Ready = true
        }
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.SubscriptionPackages.push(item)
                    if(!this.SelectedPackage){
                      this.PackageSelection = true
                    }
                  if(this.SubscriptionPackages.length === res.docs.length){
                    this.Ready = true
                  }
                }
                else if (change.type === 'modified') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.SubscriptionPackages.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = this.SubscriptionPackages.indexOf(oncheck)
                      this.SubscriptionPackages.splice(arrayobjindex, 1, item);
                    }
                   
                } 

                })
      })
      },
      removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },

      ValidateForm(){
        if(this.step === 1){
          this.Validating = false
          setTimeout(() => {
            this.Validating = true
          }, 50);
        }
        else{
          this.ProcessSignup()
        }
      },
      ProgressWindow(){       
        
        if(this.step === 1){
          let length = this.AccountFields.length
          this.AccountFields.map((field,fieldindex) => {
            if(typeof field.Response !== 'undefined'){
              this.BillingAccount[field.Name] = field.Response
            }
            if(fieldindex-1+2 === length){
              this.step++
              this.Validating = false
              console.log(this.BillingAccount)
            }
          })
        }
        else if(this.step === 2){
          this.ProcessSignup()
        }
      },
      ProcessSignup(){
        let vm = this
        if(this.System.Default_Billing_Account_Type){
          this.BillingAccount.Account_Type = this.System.Default_Billing_Account_Type
        }
        else{
          this.BillingAccount.Account_Type = {
            ID: 1000001,
            Name: 'Store Account'
          }
        }
        this.$emit('ActivateProcessing',true)
        const user = {
        Email: this.email,
        password: this.password,
        Title: this.Title,
        Name: this.Name,
        Surname: this.Surname,
        Full_Name: this.Name+' '+this.Surname,
        Gender: this.Gender,
        IsGuestUser: true,
        BillingAccount: this.BillingAccount,
        siteid: this.$store.state.ActiveSuiteid
      }
        console.log(user)
      this.$store.dispatch('signUpAction', user).then(response => {
        console.log(response)
        if(response){
          vm.$emit('ActivateProcessing',false)
          vm.step = 3
        }
      })
      },
      SignupwithFacebook(){
        let FBprovider = new firebase.auth.FacebookAuthProvider();
        let vm = this
        firebase
        .auth()
          .signInWithPopup(FBprovider)
            .then((result) => {
              var credential = result.credential;
              var user = result.user;
              console.log(user)
            })
      },

     signUp() {
      let vm = this
      const user = {
        Email: this.email,
        password: this.password,
        Title: this.Title,
        Name: this.Name,
        Surname: this.Surname,
        Gender: this.Gender,
        IsGuestUser: true,
        Full_Name: this.Name+' '+this.Surname,
        siteid: this.$store.state.ActiveSuiteid
      }
      if(this.System.Default_Member_Group){
        user.MemberGroup = this.System.Default_Member_Group
      }
      if(this.SelectedPackage && this.SelectedPackage.MemberGroup){
        user.MemberGroup = this.SelectedPackage.MemberGroup
      }
      //console.log(user)
      vm.$emit('ActivateProcessing',true)
      this.$store.dispatch('signUpAction', user).then(result => {
        vm.$emit('ActivateProcessing',false)
      })

    }

    }
  }

</script>


 <style scoped>
  .sign-up {
    margin-top: 40px;
  }
  input {
    margin: 10px 0;
    width: 20%;
    padding: 15px;
  }
  button {
    margin-top: 10px;
    width: 10%;
    cursor: pointer;
  }
  span {
    display: block;
    margin-top: 20px;
    font-size: 11px;
  }
</style>

  


  

  